import {
    GET_COMMITCRITERIA_ERROR, GET_COMMITCRITERIA_SUCCESS, GET_COMMITCRITERIA_REQUEST,
    ADD_COMMITCRITERIA_ERROR, ADD_COMMITCRITERIA_REQUEST, ADD_COMMITCRITERIA_SUCCESS,
    UPDATE_COMMITCRITERIA_ERROR, UPDATE_COMMITCRITERIA_SUCCESS, UPDATE_COMMITCRITERIA_REQUEST,
    DELETE_COMMITCRITERIA_SUCCESS, DELETE_COMMITCRITERIA_ERROR, DELETE_COMMITCRITERIA_REQUEST,
} from "./types";


import { BASEURL } from "../../constant/config.js";

const axios = require("axios");




export const getCommitCriteria = (token, id) => (dispatch) => {
    dispatch({ type: GET_COMMITCRITERIA_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/commitCriteria`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { id }
    })
        .then((response) => {
            return dispatch({ type: GET_COMMITCRITERIA_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: GET_COMMITCRITERIA_ERROR,
                payload: error.response,
            });
        });
};



export const addCommitCriteria = (token, data, id) => (dispatch) => {
    dispatch({ type: ADD_COMMITCRITERIA_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}create/commitCriteria`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data,
    })
        .then((response) => {
            return dispatch({ type: ADD_COMMITCRITERIA_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: ADD_COMMITCRITERIA_ERROR,
                payload: error.response,
            });
        });
};



export const updateCommitCriteria = (token, data, id) => (dispatch) => {
    dispatch({ type: UPDATE_COMMITCRITERIA_REQUEST });
    return axios({
        method: "PUT",
        url: `${BASEURL}update/commitCriteria/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data,
    })
        .then((response) => {
            return dispatch({ type: UPDATE_COMMITCRITERIA_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: UPDATE_COMMITCRITERIA_ERROR,
                payload: error.response,
            });
        });
};




export const deleteCommitCriteria = (token, id) => (dispatch) => {
    dispatch({ type: DELETE_COMMITCRITERIA_REQUEST });
    return axios({
        method: "DELETE",
        url: `${BASEURL}delete/commitCriteria/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
    })
        .then((response) => {
            return dispatch({ type: DELETE_COMMITCRITERIA_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: DELETE_COMMITCRITERIA_ERROR,
                payload: error.response,
            });
        });
};
