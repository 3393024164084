import React, { useState, useEffect, useRef } from "react";

import { Select, DatePicker, Form, Table, Spin, Button, Input } from "antd";
import { useSelector, useDispatch } from "react-redux";

import { getPaymentTerm } from "../../redux/actions/paymentterm.action";
import { getAllCompany } from "../../redux/actions/companyAccess";
import { getRegion } from "../../redux/actions/region.action";
import { getGender } from "../../redux/actions/gender.action";
import { ReadCookie } from "../../utils/readCookie";

import { useLocation, Link, useHistory, useParams } from "react-router-dom";

import logo from "../../assets/images/logo.png";
import btnPrint from "../../assets/images/button_print.gif";
import xlsx from "../../assets/images/ico_xls_sm.gif";

import OmsRegionStateReport from "./OmsRegionStateReport";
import OmsRegionReport from "./OmsRegionReport";
import OmsReportByCustomer from "./OmsReportByCustomer";
import moment from "moment";

import "../OMSBooking/styleSheet.css";
import { getOmsReturnReport } from "../../redux/actions/omsReturnReport.action";
import { getReturnOmsStateReport } from "../../redux/actions/omsReturnRegionStateReport.action";
import { Excel } from "antd-table-saveas-excel";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { useDownloadExcel } from "react-export-table-to-excel";
import { SearchOutlined, FilterFilled } from "@ant-design/icons";
import numeral from "numeral";
import { getCustomerType } from "../../redux/actions/customerType.action";
import { TabTitle } from "../../utils/GeneralFunction";

const { Option } = Select;
const dateFormatList = "MM-DD-YYYY";
const OmsReturn = ({ token }) => {
  TabTitle("Return Region Wise")
  const menuId = ReadCookie("menuId"); 
  const userName = ReadCookie("name");
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const history = useHistory();
  const { gender, region, companyAll, omsReturnReport, customerTypes } = useSelector(
    (state) => state
  );
  const [submit, setSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({});
  const [propsData, setPropsData] = useState({});
  const { submited } = useParams();
  const [excelToogle, setExcelToogle] = useState(true);
  const tableRef = useRef(null);
  const initialDate = moment('2023-01-01')
  const initialLastDate = moment('2026-12-31')

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();
  let check = query.get("submited");
  let company_id = query.get("company_id");
  let date_type = query.get("date_type");
  let gender_id = query.get("gender_id");
  let customer_type_id = query.get("customer_type_id");
  let from_date = query.get("from_date");
  let to_date = query.get("to_date");
  let region_id = query.get("region_id");
  let selected_id = query.get("selected_id");
  let report = query.get("report");
  const customerTypeID = parseInt(localStorage.getItem("customer_type_id"))
  const genderID = parseInt(localStorage.getItem("gender_id"))

  useEffect(() => {
    dispatch(getRegion(token, menuId));
    dispatch(getGender(token, menuId, genderID));
    dispatch(getPaymentTerm(token, menuId));
    dispatch(getAllCompany(token));
    dispatch(getCustomerType(token, menuId, customerTypeID));
  }, [dispatch, menuId, token]);

  useEffect(() => {
    if (check) {
      form.setFieldsValue({
        company_id: Number(company_id),
        date_type: date_type,
        gender_id: gender_id === "All" ? gender_id : Number(gender_id),
        region_id: region_id === "All" ? region_id : Number(region_id),
        customer_type_id: customer_type_id === "All" ? customer_type_id : Number(customer_type_id),
        from_date: moment(from_date),
        to_date: moment(to_date),
      });
      
      dispatch(
        getOmsReturnReport(token, {
          company_id,
          date_type,
          gender_id,
          from_date,
          to_date,
          customer_type_id,
          region_id,
        })
      );
    }
  }, []);

  const handleChange = (value) => {

  };

  const onFinish = async (e) => {
    setSubmit(true);
    setLoading(true);
    await form.validateFields().then((values) => {
      setValues(values);
      setPropsData({
        company_id,
        date_type,
        gender_id,
        customer_type_id,
        from_date,
        to_date,
      });
      history.push({
        pathname: "/return-region-wise",
        search: `?submited=${true}&report=${1}&company_id=${values.company_id
          }&gender_id=${values.gender_id}&region_id=${values.region_id}&customer_type_id=${values.customer_type_id
          }&from_date=${moment(values.from_date).format(
            "MM-DD-YYYY"
          )}&to_date=${moment(values.to_date).format("MM-DD-YYYY")}`,
      });
      dispatch(getOmsReturnReport(token, values)).then((res) => {
        setLoading(false);
      });
    });
  };

  const columns = [
    {
      title: "S.No",
      dataIndex: "serial_no",
      align: "left",
      key: "serial_no",
      width: 50,
    },
    {
      title: "Region Name",
      dataIndex: "region_name",
      key: "region_id",
      width: 180,
      render: (record, text) => {
        return (
          <Link
            style={{ color: "blue", textDecoration: "underline" }}
            to={{
              pathname: `/return-region-wise`,
              search: `?submited=${true}&report=${2}&company_id=${values.company_id
                }&gender_id=${values.gender_id}&region_id=${values.region_id
                }&customer_type_id=${values.customer_type_id}&from_date=${moment(
                  values.from_date
                ).format("MM-DD-YYYY")}&to_date=${moment(values.to_date).format(
                  "MM-DD-YYYY"
                )}&selected_Region=${true}&selected_id=${text.region_id}`,
            }}
          >
            {" "}
            {record}
          </Link>
        );
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-220"
              
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.region_name !== null) {
          return record.region_name
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        if ((a.region_name !== null) & (b.region_name !== null)) {
          return a.region_name.localeCompare(b.region_name);
        }
      },
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      width: 100,
      align: "right",
      render: (text) => {
        return (
          <span style={{ textAlign: "right" }}> {numeral(text).format('0,0')}</span>
        )
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-220"
              
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.quantity !== null) {
          return record.quantity
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        return (
          a.quantity.replaceAll(',', '') - b.quantity.replaceAll(',', '')
        )
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      width: 130,
      align: "right",
      render: (text) => {
        return (
          <span style={{ textAlign: "right" }}> ${numeral(text).format('0,0.00')}</span>
        )
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-220"
              
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.amount !== null) {
          return record.amount
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        return (
          a.amount.replaceAll(',', '') - b.amount.replaceAll(',', '')
        )
      },
    },
    {
      title: "AUC",
      dataIndex: "auc",
      key: "auc",
      width: 120,
      align: "right",
      render: (text) => <span style={{ textAlign: "right" }}>${parseFloat(text).toFixed(2)}</span>,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-220"
              
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.auc !== null) {
          return record.auc
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        return (
          a.auc.replaceAll(',', '') - b.auc.replaceAll(',', '')
        )
      },
    },
    {
      title: "%",
      align: "right",
      dataIndex: "percentage",
      key: "percentage",
      width: 70,
      render: text => <span style={{ textAlign: "right" }}> {text}% </span >,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-220"
              
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) => {
        if (record.percentage !== null) {
          return record.percentage
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      },
      sorter: (a, b) => {
        return (
          a.percentage.replaceAll(',', '') - b.percentage.replaceAll(',', '')
        )
      },
    },
  ];
  const handlePrint = () => {
    window.print();
  };
  const excelReport = omsReturnReport?.payload?.data;
  let sumExcelQty =
    excelReport &&
    excelReport.reduce(function (previousValue, currentValue) {
      return (previousValue += parseFloat(
        currentValue.quantity.replace(/,/g, "")
      ));
    }, 0);
  let sumExcelAmount =
    excelReport &&
    excelReport.reduce(function (previousValue, currentValue) {
      return (previousValue += parseFloat(
        currentValue.net_amount.replace(/\$|,/g, "")
      ));
    }, 0);

  let sumExcelLocalize =
    sumExcelAmount && sumExcelAmount.toLocaleString("en-US");
  const totalSumExcel = {
    region_name: "Total",
    quantity: sumExcelQty,
    net_amount: `$ ${sumExcelLocalize}`,
  };
  let concatenate = excelReport && excelReport.concat(totalSumExcel);
  const handleExcelExport = () => {
    if (check && report === "1") {
      if (concatenate.length > 3000) {
        const excel = new Excel();
        excel
          .addSheet("test")
          .addColumns(columsPageOne)
          .addDataSource(concatenate, {
            str2Percent: true,
          })
          .setTHeadStyle()
          .saveAs("Excel.xlsx");
      } else {
        setExcelToogle(true);
      }
    }
    if (check && report === "2") {
      if (getReturnOmsStateReport?.payload?.data.length > 30) {
        const excel = new Excel();
        excel
          .addSheet("test")
          .addColumns(columsPageTwo)
          .addDataSource(getReturnOmsStateReport?.payload?.data, {
            str2Percent: true,
          })
          .setTHeadStyle()
          .saveAs("Excel.xlsx");
        setExcelToogle(false);
      } else {
        setExcelToogle(true);
      }
    }

    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    

    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
  };
  const columsPageOne = [
    {
      title: "Serial No",
      dataIndex: "serial_no",
      key: "serial_no",
    },
    {
      title: "Region Name",
      dataIndex: "region_name",
      key: "region_name",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Amount",
      dataIndex: "net_amount",
      key: "net_amount",
    },
    {
      title: "Percentage",
      dataIndex: "percentage",
      key: "percentage",
    },
  ];
  const columsPageTwo = [
    {
      title: "Region Name",
      dataIndex: "region_name",
      key: "region_name",
    },
    {
      title: "State Name",
      dataIndex: "state_name",
      key: "state_name",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Amount",
      dataIndex: "net_amount",
      key: "net_amount",
    },
    {
      title: "Percentage",
      dataIndex: "percentage",
      key: "percentage",
    },
  ];
  const columsPageThree = [
    {
      title: "Serial No",
      dataIndex: "serial_number",
      key: "serial_number",
    },
    {
      title: "Customer Name",
      dataIndex: "customer_name",
      key: "customer_name",
    },
    {
      title: "State Name",
      dataIndex: "state_name",
      key: "state_name",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Amount",
      dataIndex: "net_amount",
      key: "net_amount",
    },
    {
      title: "Percentage",
      dataIndex: "percentage",
      key: "percentage",
    },
  ];
  const columsPageFour = [
    {
      title: "Serial No",
      dataIndex: "serial_number",
      key: "serial_number",
    },
    {
      title: "Order",
      dataIndex: "order_header_no",
      key: "order_header_no",
    },
    {
      title: "Order Date",
      dataIndex: "order_date",
      key: "order_date",
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "start_date",
    },
    {
      title: "Cancel Date",
      dataIndex: "cancel_date",
      key: "cancel_date",
    },
    {
      title: "P0#",
      dataIndex: "reference_no",
      key: "reference_no",
    },
    {
      title: "Sales Person",
      dataIndex: "salesperson_name",
      key: "salesperson_name",
    },
    {
      title: "Term",
      dataIndex: "term_name",
      key: "term_name",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Amount",
      dataIndex: "net_amount",
      key: "net_amount",
    },
  ];

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <div className="report-wrapper-main header_class">
              <div className="row justify-content-between">
                <div className="col-3 d-flex align-items-start  box-space">
                  <div className="logobrand">
                    <img id="ImgLogo" alt="logo" src={logo} />
                  </div>
                </div>
                <div className="col-3 box-space right-content-header text-sm-right">
                  <div className=" d-flex justify-content-between">
                    <div className=" d-flex">
                      <div className="print_icon printing_class">
                        <i
                          id="Image1"
                          title="Print"
                          alt="Print"
                          src={btnPrint}
                          onClick={handlePrint}
                          className="fa-solid fa-print"
                        ></i>
                      </div>
                      <div className="print_icon printing_class">

                        {excelToogle ? (
                          <DownloadTableExcel
                            filename="users table"
                            sheet="users"
                            currentTableRef={tableRef.current}
                          >
                            <input
                              type="image"
                              name="ImgExcel"
                              alt="Export to excel"
                              id="ImgExcel"
                              title="Export to excel"
                              src={xlsx}
                              style={{ borderWidth: "0px", height: "14px" }}
                            />
                          </DownloadTableExcel>
                        ) : (
                          <input
                            type="image"
                            name="ImgExcel"
                            alt="Export to excel"
                            id="ImgExcel"
                            title="Export to excel"
                            src={xlsx}
                            onClick={handleExcelExport}
                            style={{ borderWidth: "0px", height: "14px" }}
                          />
                        )}
                      </div>
                    </div>
                    <span style={{ fontSize: "small", fontWeight: "bolder" }}>
                      Region-Wise Return
                      <br />

                      <div>
                        Rpt # 006
                        <br />
                        Login as:
                        <span id="lblSalesPerson" style={{ fontWeight: "bold" }}>
                          {" "}
                          {userName}
                        </span>
                      </div>
                    </span>
                  </div>

                </div>
              </div>
              {report < 2 && (
                <>
                  <div className="row">
                    <div className="col">
                      <Form
                        form={form}
                        name="control-hooks"
                        onFinish={onFinish}
                      >
                        <div className="main_form row">
                          <div className="col-6">
                            <div className=" d-flex flex-row align-items-baseline">
                              <label htmlFor="" className=" width30">Featuring:</label>
                              <div className=" w-100">
                                <Form.Item
                                  name="gender_id"
                                  initialValue={
                                    submited
                                      ? form.getFieldValue().gender_id
                                      : genderID ? genderID : "All"
                                  }
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please Select Gender",
                                    },
                                  ]}
                                >
                                  <Select
                                    filterOption={(input, option) =>
                                      option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                    showSearch
                                    loading={gender?.loading}
                                    defaultValue={form.getFieldValue().gender_id}
                                    size="small"
                                    className={"input-width"}
                                  >
                                    {!genderID && <Option value="All">All</Option>}
                                    {gender?.payload?.data.map((item) => {
                                      return (
                                        <Option
                                          defaultValue={"All"}
                                          key={item.gender_id}
                                          value={item.gender_id}
                                        >
                                          {item.gender_name}
                                        </Option>
                                      );
                                    })}
                                  </Select>
                                </Form.Item>
                              </div>
                            </div>
                            <div className=" d-flex flex-row align-items-baseline">
                              <label htmlFor="" className=" width30">Region:</label>
                              <div className=" w-100">
                                <Form.Item
                                  name="region_id"
                                  initialValue={
                                    submited
                                      ? form.getFieldValue().gender_id
                                      : "All"
                                  }
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please Select Region",
                                    },
                                  ]}
                                >
                                  <Select
                                    filterOption={(input, option) =>
                                      option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                    showSearch
                                    
                                    defaultValue={
                                      form.getFieldValue().region_id
                                    }
                                    loading={region?.loading}
                                    size="small"
                                    className={"input-width"}
                                    onChange={handleChange}
                                  >
                                    <Option value="All">
                                      -- All Regions --
                                    </Option>
                                    {region?.payload?.data.map((item) => {
                                      return (
                                        <Option
                                          defaultValue={"All"}
                                          key={item.region_id}
                                          value={item.region_id}
                                        >
                                          {item.region_name}
                                        </Option>
                                      );
                                    })}
                                  </Select>
                                </Form.Item>
                              </div>
                            </div>
                            <div className=" d-flex flex-row align-items-baseline">
                              <label htmlFor="" className="width30">From:</label>
                              <div className=" w-100">
                                <Form.Item
                                  name="from_date"
                                  className="full_date"
                                  initialValue={initialDate}
                                >
                                  <DatePicker
                                    size="small"
                                    defaultValue={moment().format("MM-DD-YYYY")}
                                    format={dateFormatList}
                                    className={"input-width"}
                                  />
                                </Form.Item>
                              </div>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className=" d-flex flex-row align-items-baseline">
                              <label htmlFor="" className=" width30">Customer Type: </label>
                              <div className=" w-100">
                                <Form.Item
                                  name="customer_type_id"
                                  initialValue={
                                    submited
                                      ? form.getFieldValue().customer_type_id
                                      : customerTypeID ? customerTypeID : "All"
                                  }
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please Select Customer Type",
                                    },
                                  ]}
                                >
                                  <Select
                                    size='small'
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, options) => options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    defaultValue={form.getFieldValue().customer_type_id}
                                    filterSort={(optionA, optionB) =>
                                      optionB?.value!=="All"?
                                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()):''
                                  }
                                    className={"input-width"}
                                  >
                                    {!customerTypeID && <Option value="All">All</Option>}
                                    {
                                      customerTypes?.payload?.data.map((val) => {
                                        return (
                                          <Option value={val.customer_type_id}>{val.customer_type_name}</Option>
                                        )
                                      })
                                    }
                                  </Select>
                                </Form.Item>
                              </div>
                            </div>
                            <div className=" d-flex flex-row align-items-baseline">
                              <label htmlFor="" className=" width30">Brand :</label>
                              <div className=" w-100">
                                <Form.Item
                                  name="company_id"
                                  initialValue={
                                    submited
                                      ? form.getFieldValue().company_id
                                      : 2
                                  }
                                >
                                  <Select
                                    filterOption={(input, option) =>
                                      option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                    showSearch
                                    loading={companyAll?.loading}
                                    size="small"
                                    defaultValue={
                                      form.getFieldValue().company_id
                                    }
                                    className={"input-width"}
                                  >
                                    {companyAll?.payload?.data.map((item) => {
                                      return (
                                        <Option
                                          defaultValue={"All"}
                                          key={item.company_id}
                                          value={item.company_id}
                                        >
                                          {item.company_name}
                                        </Option>
                                      );
                                    })}
                                  </Select>
                                </Form.Item>
                              </div>
                            </div>
                            <div className=" d-flex flex-row align-items-baseline">
                              <label htmlFor="" className="width30">To:</label>
                              <div className="w-100">
                                <Form.Item
                                  name="to_date"
                                  className="full_date"
                                  initialValue={initialLastDate}
                                >
                                  <DatePicker
                                    size="small"
                                    defaultValue={moment().format("MM-DD-YYYY")}
                                    format={dateFormatList}
                                    className={"input-width"}
                                  
                                  />
                                </Form.Item>
                              </div>
                            </div>
                            <div>
                              <Button
                                htmlType="submit"
                                type="primary"
                                className="submit-btn-btm "
                              >
                                Submit
                              </Button>
                            </div>
                          </div>
                        </div>
                      </Form>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="row" ref={tableRef}>
              <div className="col">
                <div className="w-100 mt-1">
                  {check && report === "1" && (
                    <>
                      {/* Report 1 */}
                      {loading ? (
                        <div className="report-wrapper-main d-flex align-items-center justify-content-center spin-style mt-3">
                          <Spin />
                        </div>
                      ) : (
                        <Table
                          showSorterTooltip={false}
                          rowKey={"serial_no"}
                          className="report-wrapper-main test04"
                          size="small"
                          pagination={{ pageSize: 100 }}
                          summary={(pageData) => {
                            let sumQty = pageData.reduce(function (
                              previousValue,
                              currentValue
                            ) {
                              return (previousValue += parseFloat(
                                currentValue.quantity
                              ));
                            },
                              0);
                            let sumAmount = pageData.reduce(function (
                              previousValue,
                              currentValue
                            ) {
                              return (previousValue += parseFloat(
                                currentValue.amount
                              ));
                            },
                              0);

                            return (
                              <>
                                <Table.Summary.Row>
                                  <Table.Summary.Cell></Table.Summary.Cell>
                                  <Table.Summary.Cell>
                                    <p
                                      style={{
                                        paddingTop: "10px",
                                        fontWeight: "bolder",
                                      }}
                                    >
                                      Total
                                    </p>
                                  </Table.Summary.Cell>
                                  <Table.Summary.Cell>
                                    <p
                                      style={{
                                        fontWeight: "bolder",
                                        textAlign: "right",
                                        width: "100%",
                                        borderTop: "1px solid black",
                                        borderBottom: "1px solid black",
                                      }}
                                    >
                                      {numeral(sumQty).format("0,0")}
                                    </p>
                                  </Table.Summary.Cell>
                                  <Table.Summary.Cell>
                                    <p
                                      style={{
                                        fontWeight: "bolder",
                                        textAlign: "right",
                                        width: "100%",
                                        borderTop: "1px solid black",
                                        borderBottom: "1px solid black",
                                      }}
                                    >
                                      $ {numeral(sumAmount).format("0,0.00")}
                                    </p>
                                  </Table.Summary.Cell>
                                </Table.Summary.Row>
                              </>
                            );
                          }}
                          bordered
                          columns={columns}
                          dataSource={
                            omsReturnReport?.payload?.data
                              ? omsReturnReport?.payload?.data
                              : []
                          }
                        />
                      )}
                    </>
                  )}
                  {check && report === "2" && (
                    <>
                      {/* Report 2 */}
                      <OmsRegionStateReport
                        id={selected_id}
                        propsData={propsData}
                        formData={values}
                        token={token}
                      />
                    </>
                  )}
                  {check && report === "3" && (
                    <>
                      {/* Report 3 */}
                      <OmsRegionReport
                        id={selected_id}
                        propsData={propsData}
                        formData={values}
                        token={token}
                      />
                    </>
                  )}
                  {check && report === "4" && (
                    <>
                      {/* Report 4 */}
                      <OmsReportByCustomer
                        reportTittle="Sale Order Report"
                        reportName="SaleOrderNewReport.rptdesign"
                        id={selected_id}
                        propsData={propsData}
                        formData={values}
                        token={token}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
};
export default OmsReturn;
