import {   GET_STYLE_DISTRO_REQUEST,
	GET_STYLE_DISTRO_SUCCESS,
	GET_STYLE_DISTRO_ERROR } from "../actions/types";

export default function (state = [], action) {
	const { type, payload } = action;
	switch (type) {
		case GET_STYLE_DISTRO_REQUEST:
			return {
				loading: true,
			};
		case GET_STYLE_DISTRO_SUCCESS:
			return {
				...state,
				loading: false,
				payload
						};
		case GET_STYLE_DISTRO_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};

		default:
			return state;
	}
}
