import { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getReturnType,
  addReturnType,
  deleteReturnType,
  updateReturnType,
} from "../../redux/actions/ReturnType.action";

import { getLog } from "../../redux/actions/log.action";
import { getRights } from "../../redux/actions/userRights.action";

import PageHeader from "../../components/PageHeader/PageHeader";
import CrudButtons from "../../components/CrudButtons/CrudButtons";
import { ReadCookie } from "../../utils/readCookie";
import Tables from "../../components/Table/Tables";
import { Button, Row, Col, Form, Input, Checkbox, Tabs, Divider } from "antd";

import { logColumns, returnTypeColumns } from "../../utils/cols";
import notification from "../../utils/notification";
import "../Style/style.css";
import { TabTitle } from "../../utils/GeneralFunction";
const { TabPane } = Tabs;
export default function ReturnType({ token }) {
  TabTitle("Return Type")
  const menuId = ReadCookie("menuId");

  const ReturnTypeNameInput = useRef(null);
  const [form] = Form.useForm();
  const [returnTypeObj, setReturnTypeObj] = useState(null);
  const dispatch = useDispatch();
  const { returnType, log, userRights } = useSelector((state) => state);
  const [minimize, setMinimize] = useState(false);
  const [check, setCheck] = useState(false);
  const [returnTypeName, setReturnTypeName] = useState("");
  const [disable, setDisable] = useState(false);
  const [formState, setFormState] = useState("view");

  useEffect(() => {
    dispatch(getReturnType(token, menuId));
    dispatch(
      getLog(token, {
        admin_return_type: "admin_return_type",
      })
    );
  }, [token]);

  useLayoutEffect(() => {
    dispatch(getRights(token, menuId)).then((res) => {
      if (res.type === "GET_USER_RIGHTS_SUCCESS") {
        if (!res?.payload.can_add) {
          setFormState("table");
        }
      }
    });
  }, []);

  useEffect(() => {
    if (returnTypeObj) {
      setFormState("view");
      form.setFieldsValue({
        id: returnTypeObj.return_type_id,
        returnType: returnTypeObj.return_type_name,
        active: returnTypeObj.is_active,
      });
      setReturnTypeName(returnTypeObj.return_type_name);
      setCheck(returnTypeObj.is_active);
    }
  }, [returnTypeObj]);

  useEffect(() => {
    if (returnTypeName === "") {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [returnTypeName]);

  const handleTab = (key) => {
    if (key === "logs") {
      dispatch(
        getLog(token, {
          admin_return_type: "admin_return_type",

        })
      );
    }
  };
  useEffect(() => {
    if (formState === "add") {
      ReturnTypeNameInput.current.focus();
      form.setFieldsValue({
        returnTypeName: null,
        check: null,
      });
      setCheck(true);
      setReturnTypeName("");
      setCheck(null);
      setReturnTypeObj(null);
    }
  }, [formState]);
  const formSave = () => {
    if (formState === "add") {
      dispatch(addReturnType(token, { returnTypeName, check }, menuId)).then((res) => {
        if (res.type === "ADD_RETURNTYPE_ERROR") {
          notification("error", "Not Created", res?.payload?.data?.data);
        }
        if (res.type === "ADD_RETURNTYPE_SUCCESS") {
          notification("success", "ReturnType Created", "Return type created successfully!");
          form.setFieldsValue({
            returnTypeName: "",
            check: "",
          });
          setFormState("view");
          setReturnTypeName("");
          setCheck(null);
          setReturnTypeObj(null);
        }
        dispatch(getReturnType(token, menuId));
      });






      
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      

      


      
      
    }

    if (formState === "edit") {
      dispatch(
        updateReturnType(
          token,
          { returnTypeName, check },
          returnTypeObj.return_type_id
        )
      ).then((res) => {
        if (res.type === "UPDATE_RETURNTYPE_ERROR") {
          notification("error", "Update", "Something went wrong");
        }
        if (res.type === "UPDATE_RETURNTYPE_SUCCESS") {
          notification(
            "success",
            "ReturnType Updated",
            "Record Update Successfully"
          );
        }
        dispatch(getReturnType(token, menuId));
      });
    }
  };

  const handleDelete = () => {
    if (returnTypeObj) {
      dispatch(deleteReturnType(token, returnTypeObj.return_type_id)).then(
        (res) => {
          if (res.type === "DELETE_RETURNTYPE_SUCCESS") {
            notification(
              "success",
              "ReturnType Deleted",
              "ReturnType Deleted Successfully!"
            );
          }
          if (res.type === "DELETE_RETURNTYPE_ERROR") {
            notification(
              "error",
              res?.payload?.request?.statusText,
              res?.payload?.data?.data
            );
          }
          form.setFieldsValue({
            returnType: "",
          });
          
          setReturnTypeName("");
          setReturnTypeObj(null);
          setFormState("");
          dispatch(getReturnType(token, menuId));
        }
      );
    }
  };

  const filterReset = () => {
    setFormState("view");
  };

  return (
    <div className="styleContainer">
      <div className="styleBody h-100">
        <PageHeader
          title={"Return Type"}
          minimize={setMinimize}
          check={minimize}
        />
        {!minimize && (
          <div className="bodyStyle">
            <div className="crudDiv">
              <CrudButtons
                rights={userRights?.payload}
                reset={filterReset}
                formType={formState}
                save={formSave}
                disable={disable}
                formState={setFormState}
                onDelete={handleDelete}
              />
            </div>

            <div className="styleContent">
              <Row className="tables-main-row-new">
                <Col xs={24} sm={24} md={10} lg={8} xl={6}>
                  <div className="styleTable">
                    <Tables
                      type="returnType"
                      returnTypeObj={setReturnTypeObj}
                      scrollX={"20rem"}
                      scroll={602}
                      perPage={25}
                      source={returnType?.payload?.data}
                      col={returnTypeColumns}
                      paginate
                      load={returnType.loading}
                    />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={14} lg={16} xl={18}>
                  <div className="styleForm">
                    <Form form={form} name="basic" labelCol={{ span: 8 }}>
                      <div className="customRow styleFormUpper ">
                        {returnTypeObj !== null && !(formState === "add") && (
                          <div className="customCols slc">
                            <Form.Item
                              label="ID"
                              name="id"
                              initialValue={form.getFieldValue().id}
                              labelCol={{ span: 10 }}
                              wrapperCol={{ span: 14 }}
                            >
                              <Input
                                size="small"
                                className="styleInput id-field-style"
                                readOnly
                              />
                            </Form.Item>
                          </div>
                        )}
                        <div className="customCols slc">
                          <Form.Item
                            label="ReturnType"
                            name="returnType"
                            labelCol={{ span: 10 }}
                            wrapperCol={{ span: 14 }}
                            initialValue={form.getFieldValue().style}
                          >
                            <Input
                              size="small"
                              
                              disabled={
                                !(formState === "add" || formState === "edit")
                              }
                              ref={ReturnTypeNameInput}
                              onChange={(e) =>
                                setReturnTypeName(e.target.value)
                              }
                              className="styleInput"
                            />
                          </Form.Item>
                        </div>
                        <div className="customCols slc">
                          <Form.Item
                            label="Active"
                            name="active"
                            labelCol={{ span: 10 }}
                            wrapperCol={{ span: 14 }}
                          >
                            <Checkbox
                              disabled={
                                !(formState === "add" || formState === "edit")
                              }
                              checked={check}
                              onChange={(e) => setCheck(e.target.checked)}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Form>
                    <Divider />
                    <div className="styleTab">
                      <Tabs
                        type="card"
                        tabBarGutter={2}
                        defaultActiveKey="1"
                        centered
                        onChange={(key) => handleTab(key)}
                      >
                        <TabPane tab="Log Info" key="logs">
                          <div className="tabs">
                            <Tables
                              type="company"
                              scrollX={"50rem"}
                              scroll={360}
                              perPage={15}
                              source={log?.payload?.data}
                              col={logColumns}
                              load={log.loading}
                              paginate
                            />
                          </div>
                        </TabPane>
                      </Tabs>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        )}
      </div>
      {minimize && (
        <div className="styleFooter">
          <Button
            className="minimize-button-style"
            onClick={() => setMinimize(false)}
          >
            Style
          </Button>
        </div>
      )}
    </div>
  );
}
