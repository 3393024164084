import { GET_CUT_REQUEST, GET_CUT_SUCCESS, GET_CUT_ERROR, ADD_CUT_REQUEST, ADD_CUT_SUCCESS, ADD_CUT_ERROR, UPDATE_CUT_REQUEST, UPDATE_CUT_SUCCESS, UPDATE_CUT_ERROR, DELETE_CUT_REQUEST, DELETE_CUT_ERROR, DELETE_CUT_SUCCESS } from "./types";

import { BASEURL } from "../../constant/config.js";
const axios = require("axios");

export const getCut = (token, menu_id) => (dispatch) => {
    dispatch({ type: GET_CUT_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/cut`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { menu_id }
    }).then((response) => {
        return dispatch({ type: GET_CUT_SUCCESS, payload: response.data });
    }).catch((error) => {
        return dispatch({
            type: GET_CUT_ERROR,
            payload: error.response
        });
    });
}

export const addCut = (token, data, menu_id) => (dispatch) => {
    dispatch({ type: ADD_CUT_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}create/cut`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { cut_name: data.cutName, is_active: data.check, menu_id },
    })
        .then((response) => {

            return dispatch({ type: ADD_CUT_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: ADD_CUT_ERROR,
                payload: error.response,
            });
        });
};

export const updateCut = (token, data, id) => (dispatch) => {
    dispatch({ type: UPDATE_CUT_REQUEST });
    return axios({
        method: "PUT",
        url: `${BASEURL}update/cut/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { cut_name: data.cutName, is_active: data.check },
    })
        .then((response) => {

            return dispatch({ type: UPDATE_CUT_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: UPDATE_CUT_ERROR,
                payload: error.response,
            });
        });
};

export const deleteCut = (token, id) => (dispatch) => {
    dispatch({ type: DELETE_CUT_REQUEST });
    return axios({
        method: "DELETE",
        url: `${BASEURL}delete/cut/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
    })
        .then((response) => {

            return dispatch({ type: DELETE_CUT_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: DELETE_CUT_ERROR,
                payload: error.response,
            });
        });
};
