import {
    GET_UPC_TO_ITEMCODE_REQUEST,
    GET_UPC_TO_ITEMCODE_SUCCESS,
    GET_UPC_TO_ITEMCODE_ERROR,

} from "./types";

import { BASEURL } from "../../constant/config.js";
const axios = require("axios");

export const upcToItemCode = (token, data) => (dispatch) => {


    
    
    
    
    

    dispatch({ type: GET_UPC_TO_ITEMCODE_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/skuUpcCode`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: {
            "upc_code": data
        },
    })
        .then((response) => {

            return dispatch({
                type: GET_UPC_TO_ITEMCODE_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) => {
            return dispatch({
                type: GET_UPC_TO_ITEMCODE_ERROR,
                payload: error.response,
            });
        });
};


