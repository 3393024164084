import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Input, Modal, Select } from "antd";
import { Row as Brow, Col as Bcol } from "reactstrap";

const { confirm } = Modal;
const { Option } = Select;

export default function AddDepositModal({ token, formType, depositList, setDepositList, setVisible, visible, singleDeposit, setSingleDeposit }) {
  const { name, chartOfAccount, paymentMethod } = useSelector(state => {
    return state;
  });


  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [depositorName, setDepositorName] = useState("");
  const [depositorAccount, setDepositorAccount] = useState("");
  const [entityID, setEntityID] = useState("");
  const [accountID, setAccountID] = useState("");
  const [paymentMethodName, setPaymentMethodName] = useState("");


  const onChangeName = (value, obj) => {
    let name = obj.children;
    let entityId = obj.key;
    setDepositorName(name)
    setEntityID(entityId)
  };



  const onChangeAccount = (value, obj) => {
    
    
    let account_name = obj.children;
    let account_id=obj.key;
    setDepositorAccount(account_name);
    setAccountID(account_id);
  };

  const onChangePaymentMethod = (value, obj) => {
    let paymentMethod = obj.children;
    setPaymentMethodName(paymentMethod)
  };

  const onSearch = val => { };


  const handleOk = async e => {

    if (Object.keys(singleDeposit).length) {
      await form.validateFields().then(values => {

        let editDepositList = depositList.map((item) => {
          
          if (singleDeposit.id === item.id) {
            item.amount = values.amount;
            item.instrument_no = values.instrument_no;
            item.memo = values.memo;
            item.entity_type = depositorName;
            item.entity_id = values.entity_id;
            item.account_name =  depositorAccount !== "" ? depositorAccount : item.account_name;
            item.account_id =  accountID !== "" ? accountID : item.account_id;
            
          }

          return {
            ...item
          }
        })
        setDepositList(editDepositList);

        setVisible(false);
        form.setFieldsValue({
          entity_type: null,
          amount: null,
          account_name: null,
          payment_method_name: null,
          number: null,
          location: null,
          memo: null,
        });
      });


    }
    else {
      await form.validateFields().then(values => {

        let list = { ...values, entity_type: depositorName, account_id: accountID, paymentMethodId: values.payment_method_name, account_name: depositorAccount, payment_method_name: paymentMethodName, id: Math.floor(new Date().valueOf() * Math.random()), entity_id: entityID }
        setDepositList([...depositList, list]);
        setVisible(false);
        form.setFieldsValue({
          entity_type: null,
          amount: null,
          account_name: null,
          payment_method_name: null,
          instrument_no: null,
          location: null,
          memo: null,
          entity_id: null,
        });
      });
    }
  };

  const handleCancel = () => {
    setVisible(false);
    setSingleDeposit([])
    form.setFieldsValue({
      entity_type: null,
      amount: null,
      account_name: null,
      payment_method_name: null,
      instrument_no: null,
      location: null,
      memo: null,
      entity_id: null,
    });
  };

  useEffect(() => {
    if (singleDeposit) {
      form.setFieldsValue({
        
        
        
        
        
        
        
        entity_type: singleDeposit.entity_type,
        amount: singleDeposit.amount,
        account_name: singleDeposit.account_name,
        payment_method_name: singleDeposit.payment_method_name,
        instrument_no: singleDeposit.instrument_no,
        location: singleDeposit.location,
        memo: singleDeposit.memo,
        entity_id: singleDeposit.entity_id,
      });
    }

  }, [singleDeposit]);
  return (
    <>
      <div>
        <Modal className="modal-40" visible={visible} title="Deposit Form" okText="Save" onOk={data => handleOk(data)} onCancel={handleCancel}>
          <Form form={form} layout="vertical" name="form_in_modal" initialValues={{ modifier: "public" }}>
            <div className="container-fluid p-0 modal-form-main">
              <Brow>
                <Bcol lg={6} xl={6} xxl={6}>
                  <Form.Item name="entity_type" label="Name" rules={[{ message: "Entity is required", required: true }]}>
                    <Select
                      size="small"
                      showSearch
                      
                      optionFilterProp="children"
                      value={formType === "edit" ? form.getFieldValue().entity_id : null}
                      onChange={onChangeName}
                      onSearch={onSearch}
                      loading={name.loading}
                      filterSort={(optionA, optionB) =>
                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                  }
                    
                    >

                      {name?.payload?.data &&
                        name?.payload?.data?.map(name => {
                          
                          return (
                            <Option key={name?.entity_id} value={name?.entity_id}>
                              {name?.entity_name}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Bcol>
                <Bcol lg={6} xl={6} xxl={6}>
                  <Form.Item name="amount" label="Amount " >
                    <Input size="small" className="b-radius-10" />
                  </Form.Item>
                </Bcol>
                
                <Bcol lg={6} xl={6} xxl={6}>
                  <Form.Item name="account_name" label="Account" rules={[{ message: "Account is required", required: true }]}>
                    <Select
                      size="small"
                      showSearch
                      
                      optionFilterProp="children"
                      value={formType === "edit" ? form.getFieldValue().account_id : null}
                      onChange={onChangeAccount}
                      onSearch={onSearch}
                      loading={chartOfAccount?.loading}
                      filterSort={(optionA, optionB) =>
                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                  }
                    
                    >
                      {chartOfAccount?.payload?.data?.map(item => {
                        return (
                          <Option key={item.account_id} value={item.account_id}>
                            {item.account_name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Bcol>
                <Bcol lg={6} xl={6} xxl={6}>
                  <Form.Item name="instrument_no" label="Number" rules={[{ pattern: new RegExp(/^[0-9\b]+$/), message: "Must be a Number" }]}>
                    <Input size="small" className="b-radius-10" />
                  </Form.Item>
                </Bcol>
                <Bcol lg={6} xl={6} xxl={6}>
                  <Form.Item name="memo" label="Memo " rules={[{ message: "Memo is required", required: true }]}>
                    <Input size="small" className="b-radius-10" />
                  </Form.Item>
                </Bcol>
                <Bcol lg={6} xl={6} xxl={6}>
                  <Form.Item name="payment_method_name" label="Payment Method" rules={[{ message: "Payment Method is required", required: true }]}>
                    <Select
                      size="small"
                      showSearch
                      
                      filterSort={(optionA, optionB) =>
                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                  }
                      optionFilterProp="children"
                      value={formType === "edit" ? form.getFieldValue().payment_method_name : null}
                      onChange={onChangePaymentMethod}
                      onSearch={onSearch}
                      loading={paymentMethod?.loading}
                    
                    >
                      {paymentMethod?.payload?.data?.map(item => {
                        return (
                          <Option key={item?.payment_method_id} value={item?.payment_method_id}>
                            {item?.payment_method_name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Bcol>
              </Brow>
            </div>
          </Form>
        </Modal>
      </div>
    </>
  );
}
