
import {
  GET_NET_SHIP_STYLE_WISE_REQUEST,
  GET_NET_SHIP_STYLE_WISE_SUCCESS,
  GET_NET_SHIP_STYLE_WISE_ERROR,
} from "../actions/types";

export const netShipStyleWise = (state = [], action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_NET_SHIP_STYLE_WISE_REQUEST:
      return {
        loading: true,
      };
    case GET_NET_SHIP_STYLE_WISE_SUCCESS:
      return {
        ...state,
        loading: false,
        payload,
      };
    case GET_NET_SHIP_STYLE_WISE_ERROR:
      return {
        ...state,
        loading: false,
        payload,
      };

    default: {
      return state;
    }
  }
};