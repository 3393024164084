import React, { useCallback, useEffect, useState } from "react";
import { Spin, Select, DatePicker, Checkbox, Form, Input, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getOmsStockSummary } from "../../redux/actions/omsStockSummary.action";
import { getGenderCategory, getGenderCategoryChange } from "../../redux/actions/genderCategory.action";
import { getProductStatus } from "../../redux/actions/productStatus.actions";
import { getProductClass } from "../../redux/actions/productClass.action";
import { getFitCategory } from "../../redux/actions/fitCategory.action";
import { getProductType } from "../../redux/actions/productType.action";
import { getSeason } from "../../redux/actions/season.action";
import { getGender } from "../../redux/actions/gender.action";
import { getSize } from "../../redux/actions/size.action";
import { getSku } from "../../redux/actions/sku.action"
import { ReadCookie } from "../../utils/readCookie";
import { getWashName } from "../../redux/actions/wash.action";
import { getStyle, getStyleChange } from "../../redux/actions/style";
import moment from "moment";
import btnPrint from "../../assets/images/button_print.gif";
import xlsx from "../../assets/images/ico_xls_sm.gif";
import logo from "../../assets/images/logo.png";
import "../Distributor/Distributor.css";
import { Link, useHistory } from "react-router-dom";
import { Excel } from "antd-table-saveas-excel";
import Loader from "react-loader-spinner";
import numeral from "numeral";
import { TabTitle } from "../../utils/GeneralFunction";
import { CSVLink } from "react-csv";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { getSeasonYear } from "../../redux/actions/seasonYear.action";
import { getOmsStyleReport } from "../../redux/actions/omsStyleReport.action";
const dateFormatList = ["MM-DD-YYYY"];

const OMSStyleReport = ({ token }) => {
  TabTitle("OMS Style Report")
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const {
    style,
    getWiseStockReport,
    productType,
    gender,
    genderCategory,
    season,
    productStatus,
    productClass,
    size,
    wash,
    fitCategory,
    omsStyle,
    genderCategoryChange,
    styleChange,
    Sku,
    seasonYear
  } = useSelector((state) => state);
  const history = useHistory();
  const menuId = ReadCookie("menuId");
  const userName = ReadCookie("name");
  const [productClassID, setProductClassID] = useState([]);
  const [seasonID, setSeasonID] = useState([]);
  const [isChecked, setIsChecked] = useState([]);
  const [isChecked2, setIsChecked2] = useState([]);
  const [allSesion, setAllSesion] = useState(true);
  const [allStatus, setAllStatus] = useState(true);
  const [isPCChecked, setIsPCChecked] = useState([]);
  const [allPC, setAllPC] = useState(true);
  const [loading, setLoading] = useState(false);
  const [BgradeCheck, setBgradeCheck] = useState(false);
  const [check, setCheck] = useState(false);
  const [data, setData] = useState([])
  const [excelData, setExcelData] = useState([])
  const location = useLocation();


  

  let result =
    getWiseStockReport &&
    getWiseStockReport?.payload?.data?.reduce(function (r, a) {
      r[a.item_code] = r[a.item_code] || [];
      r[a.item_code].push(a);
      return r;
    }, Object.create(null));

  const wiseStockReport = result && Object.values(result);

  const values = {
    item_id: null,
    location_id: null,
  };

  const { Option } = Select;
  let pendingDate = "";

  const onChangeDate = (date, dateString) => {
    pendingDate = dateString;
  };

  const sesionSelectAll = (e) => {
    setAllSesion(e.target.checked);
  };
  const statusSelectAll = (e) => {
    setAllStatus(e.target.checked);
  };

  
  
  
  
  
  
  

  const isCheckboxChecked = (id, value) => {
    const filterCheckBox =
      season && season?.payload?.data.find((val) => val.season_id === id);
    const checkId = isChecked.includes(id);
    if (!checkId) {
      setIsChecked((current) => [...current, filterCheckBox.season_id]);
    }
    if (!value) {
      const removeUnCheck = isChecked.filter(
        (item) => item !== filterCheckBox.season_id
      );
      setIsChecked(removeUnCheck);
    }
    setAllSesion(false);
  };


  const isCheckboxChecked2 = (id, value) => {
    const filterCheckBox =
      productStatus && productStatus?.payload?.data.find((val) => val.product_status_id === id);
    const checkId = isChecked2.includes(id);
    if (!checkId) {
      setIsChecked2((current) => [...current, filterCheckBox.product_status_id]);
    }
    if (!value) {
      const removeUnCheck = isChecked2.filter(
        (item) => item !== filterCheckBox.product_status_id
      );
      setIsChecked2(removeUnCheck);
    }
    setAllStatus(false);
  };

  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  

  
  

  

  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  


  const onFinish = async (e) => {
    setLoading(true)
    await form.validateFields().then((values) => {
      
      dispatch(
        getOmsStyleReport(token, values, isChecked, isChecked2, isPCChecked, BgradeCheck)).then((res => {
          setLoading(false)
          setData(res?.payload?.data)
          downloadExcelFileData(res?.payload?.data);
        }));
    });
  };

  function downloadExcelFileData(data) {
    setExcelData(
      data.map((v) => {
        return {
          "Style": v.style,
          "noofSKU": v.noofsku,
          "BERGEN": v.bergan,
          "TRNS": v.transit,
          "PK": v.pakistan,
          "PROD": v.production,
          "PEND": v.pending,
          "INT": v.international,
          "OTS": v.ots,
        };
      })
    )
  }

  useEffect(() => {
    dispatch(getStyle(token, menuId));
    dispatch(getProductType(token, values));
    dispatch(getGenderCategory(token, menuId));
    dispatch(getSeason(token, menuId));
    dispatch(getProductStatus(token, menuId));
    dispatch(getProductClass(token, menuId));
    dispatch(getSize(token, menuId));
    dispatch(getGender(token, menuId));
    dispatch(getFitCategory(token, menuId));
    dispatch(getWashName(token, menuId));
    dispatch(getSeasonYear(token, menuId));


  }, [dispatch, menuId, token]);

  useEffect(() => {
    if (allSesion) {
      setIsChecked([]);
    }
  }, [allSesion])

  useEffect(() => {
    if (allStatus) {
      setIsChecked2([]);
    }
  }, [allStatus])

  useEffect(() => {
    if (allPC) {
      setIsPCChecked([]);
    }
  }, [allPC])



  const handlePrint = () => {
    window.print();
  };

  const handleExcelExport = () => {
    const excel = new Excel();
    excel
      .addSheet("test")
      .addColumns(columsPageOne)
      .addDataSource(omsStyle?.payload?.data, {
        str2Percent: true,
      })
      .setTHeadStyle()
      .saveAs("Excel.xlsx");
  };

  const columsPageOne = [
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
    },
    {
      title: "Gender Cateogry",
      dataIndex: "gender_cateogry",
      key: "gender_cateogry",
    },
    {
      title: "Current Season",
      dataIndex: "current_season",
      key: "current_season",
    },
    {
      title: "Fabric",
      dataIndex: "fabric",
      key: "fabric",
    },
    {
      title: "Inseam",
      dataIndex: "inseam",
      key: "inseam",
    },
    {
      title: "SKU",
      dataIndex: "item_code",
      key: "item_code",
    },
    {
      title: "Style",
      dataIndex: "style",
      key: "style",
    },
    {
      title: "Wash",
      dataIndex: "wash",
      key: "wash",
    },
    {
      title: "Fit Category",
      dataIndex: "fit_category",
      key: "fit_category",
    },
    {
      title: "Production",
      dataIndex: "production",
      key: "producti  on",
    },
    {
      title: "PK",
      dataIndex: "pakistan",
      key: "pakistan",
    },
    {
      title: "Transit",
      dataIndex: "transit",
      key: "transit",
    },
    {
      title: "Bergen",
      dataIndex: "bergan",
      key: "bergan",
    },
    {
      title: "Pending",
      dataIndex: "pending",
      key: "pending",
    },
    {
      title: "Intl",
      dataIndex: "international",
      key: "international",
    },
    {
      title: "OTS",
      dataIndex: "ots",
      key: "ots",
    },
    {
      title: "Retail",
      dataIndex: "retail_price",
      key: "retail_price",
    },
    {
      title: "Cost Price",
      dataIndex: "ws_price",
      key: "ws_price",
    },
  ];



  

  let tableData = omsStyle?.payload?.data;


  function totals() {
    let wsTotal = 0;
    let rtsTotal = 0;
    let berganTotal = 0;
    let transitTotal = 0;
    let pkTotal = 0;
    let productionTotal = 0;
    let pendingTotal = 0;
    let intTotal = 0;
    let otsTotal = 0;
    let ecomTotal = 0;
    let nordTotal = 0;
    let neminTotal = 0;
    let saksTotal = 0;
    let verishopTotal = 0;
    let bgsTotal = 0;
    let podTotal = 0;

    
    tableData && tableData.forEach((item) => {
      berganTotal += item.bergan;
      transitTotal += item.transit;
      pkTotal += item.pakistan;
      productionTotal += item.production;
      pendingTotal += item.pending;
      intTotal += item.international;
      otsTotal += item.ots;
      ecomTotal += item.ecommerce;
      nordTotal += item.nord;
      neminTotal += item.neiman;
      saksTotal += item.saks;
      verishopTotal += item.verishop_canada;
      bgsTotal += item.bgs;
      podTotal += item.pod;
    })

    return {
      berganTotal: berganTotal,
      transitTotal: transitTotal,
      pkTotal: pkTotal,
      productionTotal: productionTotal,
      pendingTotal: pendingTotal,
      intTotal: intTotal,
      otsTotal: otsTotal,
      ecomTotal: ecomTotal,
      nordTotal: nordTotal,
      neminTotal: neminTotal,
      saksTotal: saksTotal,
      verishopTotal: verishopTotal,
      bgsTotal: bgsTotal,
      podTotal: podTotal,
    }

  }


  const handleGender = (gender_id) => {
    dispatch(getGenderCategoryChange(token, gender_id));
    dispatch(getStyleChange(token, gender_id, null, null));
    form.setFieldsValue({
      gender_category_id: "All",
      style_id: "All",
      sku: "All"
    })
  }


  const handleStyle = (gender_category_id) => {
    dispatch(getStyleChange(token, form.getFieldValue().gender_id, gender_category_id, null))
    form.setFieldsValue({
      style_id: "All",
      sku: "All"
    })
  }

  const handleSku = (sku_id) => {
    if (sku_id === null) {
      dispatch(getSku(token, null, null, null));
    } else {
      dispatch(getSku(token, form.getFieldValue().gender_id, form.getFieldValue().gender_category_id, sku_id));
    }
    form.setFieldsValue({
      sku: "All"
    })
  }


  function debounce(cb, delay = 1000) {
    let timeout
    return (...args) => {
      clearTimeout(timeout)
      timeout = setTimeout(() => {
        cb(...args)
      }, delay)
    }
  }



  const handleSkuSearch = () => {
    dispatch(getSku(token, null, null, null));
  }

  const optimizedFn = useCallback(debounce(handleSkuSearch), []);


  const sortBySku = (n) => {
    
    const userCopy = [...data];
    if (!check) {
      setCheck(true);
      if (n === "style") {
        userCopy.sort((a, b) => {
          if (a.style !== null || undefined && b.style !== null || undefined) {
            return a.style.localeCompare(b.style);
          }
        });
      }
      else if (n === "noofsku") {
        userCopy.sort((a, b) => {
          return Number(a.noofsku) - Number(b.noofsku);
        });
      }
      else if (n === "bergan") {
        userCopy.sort((a, b) => {
          return Number(a.bergan) - Number(b.bergan);
        });
      }
      else if (n === "transit") {
        userCopy.sort((a, b) => {
          return Number(a.transit) - Number(b.transit);
        });
      }
      else if (n === "pakistan") {
        userCopy.sort((a, b) => {
          return Number(a.pakistan) - Number(b.pakistan);
        });
      }
      else if (n === "production") {
        userCopy.sort((a, b) => {
          return Number(a.production) - Number(b.production);
        });
      }
      else if (n === "pending") {
        userCopy.sort((a, b) => {
          return Number(a.pending) - Number(b.pending);
        });
      }
      else if (n === "international") {
        userCopy.sort((a, b) => {
          return Number(a.international) - Number(b.international);
        });
      }
      else if (n === "ots") {
        userCopy.sort((a, b) => {
          return Number(a.ots) - Number(b.ots);
        });
      }
    }
    else {
      setCheck(false);
      if (n === "style") {
        userCopy.sort((a, b) => {
          if (b.style !== null || undefined && a.style !== null || undefined) {
            return b.style.localeCompare(a.style);
          }
        });
      }
      else if (n === "noofsku") {
        userCopy.sort((a, b) => {
          return Number(b.noofsku) - Number(a.noofsku);
        });
      }
      else if (n === "bergan") {
        userCopy.sort((a, b) => {
          return Number(b.bergan) - Number(a.bergan);
        });
      }
      else if (n === "transit") {
        userCopy.sort((a, b) => {
          return Number(b.transit) - Number(a.transit);
        });
      }
      else if (n === "pakistan") {
        userCopy.sort((a, b) => {
          return Number(b.pakistan) - Number(a.pakistan);
        });
      }
      else if (n === "production") {
        userCopy.sort((a, b) => {
          return Number(b.production) - Number(a.production);
        });
      }
      else if (n === "pending") {
        userCopy.sort((a, b) => {
          return Number(b.pending) - Number(a.pending);
        });
      }
      else if (n === "international") {
        userCopy.sort((a, b) => {
          return Number(b.international) - Number(a.international);
        });
      }
      else if (n === "ots") {
        userCopy.sort((a, b) => {
          return Number(b.ots) - Number(a.ots);
        });
      }
    }
    setData(userCopy)
  }


  return (
    <div className="header_class">
      <div className="row justify-content-between business_class_oms distributor" style={{ margin: "0 auto" }}>
        <div className="col-3 d-flex align-items-start box-space">
          <div className="logobrand">
            <img id="ImgLogo" alt="logo" src={logo} />
          </div>
        </div>
        {/* <div className="col-sm-6 box-space pl-sm-0">
                    <div className="brand-para">
                        <div>DL1961 Premium Denim Inc.</div>
                        <div>121 Varick Street</div>
                        <div>4th Floor</div>
                        <div>New York, NY 10013</div>
                        <div>(P) 646-514-9736</div>
                        <div>(F) 212-991-9989</div>
                        <div>(E) sales@DL1961.com</div>
                    </div>
                </div> */}
        <div className="col-4 box-space right-content-header text-right">
          <div className=" d-flex justify-content-end">
            <div className=" d-flex justify-content-end mr-8">
              <div className="print_icon printing_class">
                <i
                  onClick={handlePrint}
                  id="Image1"
                  title="Print"
                  alt="Print"
                  src={btnPrint}
                  className="fa-solid fa-print"
                ></i>
              </div>
              <div className="print_icon printing_class">
                <CSVLink
                  filename={"Oms-Style-Report.csv"}
                  data={excelData}
                  onClick={() => {

                  }}
                >
                  <input
                    type="image"
                    name="ImgExcel"
                    alt="Export to excel"
                    id="ImgExcel"
                    title="Export to excel"
                    src={xlsx}
                  
                  />
                </CSVLink>
              </div>
            </div>
            <span style={{ fontSize: "small", fontWeight: "bolder" }}>
              OMS Style Report
              <br />
              Rpt # 489
              <br />
              <p className=" mb-0">Rpt # 001_V4</p>
              Login as:{" "}
              <span id="lblSalesPerson" style={{ fontWeight: "bold" }}>
                {userName}
              </span>
            </span>
          </div>
        </div>
      </div>


      <div className="business_class_oms mb-2 distributor">
        <Form form={form} name="control-hooks" onFinish={onFinish}>
          <div className="main_form row">
            <div className="col-12">
              {/* <hr className="mt-0 mb-2" />
              <div>
                <label htmlFor="">Classification</label>
                <div className="checkboxes">
                  <div className="PC_Chekbox">
                    <Checkbox
                      size="large"
                      checked={allPC}
                      onChange={PCSelectAll}
                    >
                      All
                    </Checkbox>
                  </div>
                  {productClass &&
                    productClass?.payload?.data.map((pcls, index) => {
                      return (
                        <div key={index} className="checkbox_content">
                          <Form.Item name="product_class_id">
                            <Checkbox
                              size="large"
                              checked={allPC ? false : isPCChecked.includes(pcls.product_class_id)}
                              onChange={(e) =>
                                isPCBoxChecked(
                                  pcls.product_class_id,
                                  e.target.checked
                                )
                              }
                            >
                              {pcls.product_class_name}
                            </Checkbox>
                          </Form.Item>
                        </div>
                      );
                    })}
                </div>
              </div> */}
              <hr className="my-1" />
              <div>
                <label htmlFor="">Product Status</label>
                <div className="checkboxes">
                  <div className="checkbox_content">
                    <Form.Item name="current_status_id" className=" mb-0">
                      <Checkbox checked={allStatus} onChange={statusSelectAll}>
                        All
                      </Checkbox>
                    </Form.Item>
                  </div>
                  {productStatus &&
                    productStatus?.payload?.data.map((it, index) => {
                      return (
                        <div key={index} className="checkbox_content">
                          <Form.Item name="current_status_id">
                            <Checkbox
                              checked={allStatus ? false : isChecked2.includes(it.product_status_id)}
                              onChange={(e) =>
                                isCheckboxChecked2(
                                  it.product_status_id,
                                  e.target.checked
                                )
                              }
                            >
                              {it.product_status_name}
                            </Checkbox>
                          </Form.Item>
                        </div>
                      );
                    })}
                </div>
              </div>
              {/* <hr className="my-1" />
              <div>
                <label htmlFor="">Season</label>
                <div className="checkboxes">
                  <div className="checkbox_content">
                    <Form.Item name="current_season_id" className=" mb-0">
                      <Checkbox checked={allSesion} onChange={sesionSelectAll}>
                        All
                      </Checkbox>
                    </Form.Item>
                  </div>
                  {season &&
                    season?.payload?.data.map((it, index) => {
                      return (
                        <div key={index} className="checkbox_content">
                          <Form.Item name="current_season_id">
                            <Checkbox
                              checked={allSesion ? false : isChecked.includes(it.season_id)}
                              onChange={(e) =>
                                isCheckboxChecked(
                                  it.season_id,
                                  e.target.checked
                                )
                              }
                            >
                              {it.season_name}
                            </Checkbox>
                          </Form.Item>
                        </div>
                      );
                    })}
                </div>
              </div> */}
              <hr className="my-1" />
              <div className="main_form row">
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width44" htmlFor="">Gender</label>
                    <div className="w-100">
                      <Form.Item name="gender_id" initialValue={"All"}>
                        <Select
                          className={"select_Inputs"}
                          showSearch
                          
                          optionFilterProp="children"
                          size="small"
                          onChange={(e) => handleGender(e)}
                        >
                          <Option value={null}>All</Option>
                          {gender &&
                            gender?.payload?.data.map((it, index) => {
                              return (
                                <Option key={index} value={it.gender_id}>
                                  {it.gender_name}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width44" htmlFor="">Season</label>
                    <div className=" w-100">
                      <Form.Item name="season_id" initialValue={'All'}>
                        <Select
                          defaultValue={form.getFieldValue().season_id}
                          className={"select_Inputs"}
                          showSearch
                          
                          optionFilterProp="children"
                          size="small"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                        >
                          <Option value={null}>All</Option>
                          {season &&
                            season?.payload?.data?.map(
                              (status, index) => {
                                return (
                                  <Option
                                    key={index}
                                    value={status.season_id}
                                  >
                                    {status.season_name}
                                  </Option>
                                );
                              }
                            )}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width44" htmlFor="">Season Year</label>
                    <div className="w-100">
                      <Form.Item name="new_season_year_id" initialValue={"All"}>
                        <Select
                          className={"select_Inputs"}
                          defaultValue={form.getFieldValue().new_season_year_id}
                          showSearch
                          optionFilterProp="children"
                          size="small"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                        >
                          <Option value={"All"}>All</Option>
                          {seasonYear?.payload?.data?.map((res) => <Option key={res.season_year_id} value={res.season_year_id}>{res.season_year}</Option>
                          )}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </div>
                {/*Second*/}
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width44" htmlFor="">Category</label>
                    <div className="w-100">
                      <Form.Item initialValue={"All"} name="gender_category_id">
                        <Select
                          className={"select_Inputs"}
                          showSearch
                          
                          optionFilterProp="children"
                          size="small"
                          onChange={(e) => handleStyle(e)}
                        
                        
                        
                        
                        
                        >
                          <Option value={null}>All</Option>
                          {
                            genderCategoryChange &&
                              genderCategoryChange?.payload?.data.length >= 0 ? (
                              genderCategoryChange &&
                              genderCategoryChange?.payload?.data.map((it, index) => {
                                return (
                                  <Option
                                    key={index}
                                    value={it.gender_category_id}
                                  >
                                    {it.gender_category_name}
                                  </Option>
                                );
                              })
                            ) : (
                              genderCategory &&
                              genderCategory?.payload?.data.map((it, index) => {
                                return (
                                  <Option
                                    key={index}
                                    value={it.gender_category_id}
                                  >
                                    {it.gender_category_name}
                                  </Option>
                                );
                              })
                            )
                          }
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
              <div className="main_form row">
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width44" htmlFor="">Sku</label>
                    <div className="w-100">
                      <Form.Item name="sku" initialValue={"All"}>
                        <Select
                          notFoundContent={Sku.loading ? <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Loader type="Oval" color="#3c4b58" height={20} width={20} />
                          </div> : <div style={{ display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center" }}>No Data</div>}
                          className={"select_Inputs"}
                          showSearch
                          onSearch={(value) => {
                            optimizedFn(value)
                          }}
                          
                          optionFilterProp="children"
                          size="small"

                        >
                          <Option value={null}>All</Option>
                          {Sku &&
                            Sku?.payload?.data.map((it, index) => {
                              return (
                                
                                <Option key={index} value={it.sku}>
                                  {it.sku}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width44" htmlFor="">Style</label>
                    <div className="w-100">
                      <Form.Item initialValue={"All"} name="style_id">
                        <Select
                          className={"select_Inputs"}
                          showSearch
                          
                          optionFilterProp="children"

                          size="small"
                          onChange={(e) => handleSku(e)}
                        
                        
                        
                        
                        
                        >
                          <Option value={null}>All</Option>
                          {
                            styleChange && styleChange?.payload?.data.length >= 0 ? (
                              styleChange && styleChange?.payload?.data.map((style, index) => {
                                return (
                                  <Option key={index} value={style.style_id}>
                                    {style.style_name}
                                  </Option>
                                );
                              })
                            ) : (
                              style && style?.payload?.data.map((style, index) => {
                                return (
                                  <Option key={index} value={style.style_id}>
                                    {style.style_name}
                                  </Option>
                                );
                              })
                            )
                          }
                          {/* {style &&
                            style?.payload?.data.map((style, index) => {
                              return (
                                <Option key={index} value={style.style_id}>
                                  {style.style_name}
                                </Option>
                              );
                            })} */}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width44" htmlFor="">Wash</label>
                    <div className="w-100">
                      <Form.Item name="wash_id" initialValue={"All"}>
                        <Select
                          defaultValue={form.getFieldValue().wash_id}
                          className={"select_Inputs"}
                          showSearch
                          
                          optionFilterProp="children"
                          size="small"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                        >
                          <Option value={null}>All</Option>
                          {wash &&
                            wash?.payload?.data?.map(
                              (status, index) => {
                                return (
                                  <Option
                                    key={index}
                                    value={status.wash_id}
                                  >
                                    {status.wash_name}
                                  </Option>
                                );
                              }
                            )}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width44" htmlFor="">Pending Till</label>
                    <div className="w-100">
                      <Form.Item
                        name="as_on_date"
                        className="full_date"
                        initialValue={moment().endOf('year')}
                      >
                        <DatePicker
                          size="small"
                          onChange={onChangeDate}
                          format={dateFormatList}

                          defaultValue={moment().format("MM-DD-YYYY")}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
              <div className="main_form row" style={{ justifyContent: "space-between" }}>
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width44" htmlFor="">Sort on</label>
                    <div className="w-100">
                      <Form.Item name="sortOn" initialValue={"All"}>
                        <Select
                          defaultValue={form.getFieldValue().openStock}
                          className={"select_Inputs"}
                          showSearch
                          optionFilterProp="children"
                          size="small"
                        >
                          <Option value={"Style"}>Style</Option>
                          <Option value={"Bergen"}>Bergen</Option>
                          <Option value={"Transit"}>Transit</Option>
                          <Option value={"PK"}>PK</Option>
                          <Option value={"Production"}>Production</Option>
                          <Option value={"Pending"}>Pending</Option>
                          <Option value={"INT Pending"}>INT Pending</Option>
                          <Option value={"OTS"}>OTS</Option>
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3" style={{ display: "flex", marginTop: "3px", float: "right", justifyContent: "flex-end" }}>
                  {/* <label className=" no_visibility">none</label> */}
                  <Button
                    style={{ width: "fit-content" }}
                    type="primary"
                    htmlType="submit"
                    className="submit-btn-btm m-0"
                  >
                    Generate
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>
      <div>
        <div className="overflow distributor"
          style={{
            
            height: "57vh",
            
          }}
        >
          {omsStyle?.payload?.data !== undefined ?
            (
              data.length > 0 ? (
                <table
                  className="table_distributor-data stock_table table_content_oms oms_stock_summary_table"
                
                
                >
                  <thead>
                    <tr
                      style={{
                        background: "#dedede",
                        border: " 2px solid",
                        height: " 34px",
                        position: "sticky",
                        top: "0px",
                        fontSize: "12px"
                      }}
                    >
                      <th>#</th>
                      <th onClick={() => sortBySku('style')}>Style</th>
                      <th onClick={() => sortBySku('noofsku')} style={{ textAlign: "right" }}>No of SKU</th>
                      <th onClick={() => sortBySku('bergan')} style={{ textAlign: "right" }}>BERGEN</th>
                      <th onClick={() => sortBySku('transit')} style={{ textAlign: "right" }}>TRNS</th>
                      <th onClick={() => sortBySku('pakistan')} style={{ textAlign: "right" }}>PK</th>
                      <th onClick={() => sortBySku('production')} style={{ textAlign: "right" }}>PROD</th>
                      <th onClick={() => sortBySku('pending')} style={{ textAlign: "right" }}>PEND</th>
                      <th onClick={() => sortBySku('international')} style={{ textAlign: "right" }}>INT</th>
                      <th onClick={() => sortBySku('ots')} style={{ textAlign: "right" }}>OTS</th>

                    </tr>
                  </thead>
                  <tbody>

                    {data.map((data, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{data.style}</td>
                          <td style={{ textAlign: "right" }}>{data.noofsku}</td>
                          <td style={{ textAlign: "right" }}>{data.bergan}</td>
                          <td style={{ textAlign: "right" }}>{data.transit}</td>
                          <td style={{ textAlign: "right" }}>{data.pakistan}</td>
                          <td style={{ textAlign: "right" }}>{data.production}</td>
                          <td style={{ textAlign: "right" }}><Link
                            style={{
                              color: "blue",
                              textDecoration: "underline",
                              textAlign: "right"
                            }}
                            to={{
                              pathname: '/pending-detail-summary-report',
                              state: data
                            }}
                          >{data.pending}</Link></td>
                          <td style={{ textAlign: "right" }}>{data.international}</td>
                          <td style={{
                            textAlign: "right"
                          }}>{data.ots}</td>
                        </tr>
                      );
                    })}
                    <tr >
                      <td colSpan={2}></td>
                      <td>Total</td>
                      <td style={{ textAlign: "right" }}>{numeral(totals().berganTotal).format("0,00,0")}</td>
                      <td style={{ textAlign: "right" }}>{numeral(totals().transitTotal).format("0,00,0")}</td>
                      <td style={{ textAlign: "right" }}>{numeral(totals().pkTotal).format("0,00,0")}</td>
                      <td style={{ textAlign: "right" }}>{numeral(totals().productionTotal).format("0,00,0")}</td>
                      <td style={{ textAlign: "right" }}>{numeral(totals().pendingTotal).format("0,00,0")}</td>
                      <td style={{ textAlign: "right" }}>{numeral(totals().intTotal).format("0,00,0")}</td>
                      <td style={{ textAlign: "right" }}>{numeral(totals().otsTotal).format("0,00,0")}</td>
                    </tr>
                  </tbody>
                </table>
              ) : (
                <div className="no-Data">
                  {/* {} */}
                  <h1>No Data</h1>
                </div>

              )
            ) : (
              loading && (
                <div className="loader" style={{ marginTop: "20px" }}>
                  <Spin size="medium" />
                </div>
              )
            )
          }
        </div>
      </div>
    </div>
  );
};

export default OMSStyleReport;
