import React from "react";
import {
  PlusOutlined,
  FileDoneOutlined,
  UndoOutlined,
  SearchOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { Button, Tooltip, Divider, Tag } from "antd";
import { useHistory, Link } from "react-router-dom";

import "../../../components/CrudButtons/crud.css";

export default function CrudButtons({
  userRights,
  save,
  disable,
  setDisable,
  formState,
  formType,
  onDelete,
  reset,
  rights,
  mode,
  name,
  return_id,
  receipt_header_id,
  formData,
  user_type,
  transactionLock,
}) {
  const history = useHistory();

  const confirmDelete = () => {
    onDelete();
  };

  function disableBtns() {
    let result = false;
    
    
    
    if (!userRights?.can_edit || formState === "add") {
      result = true;
    }
    if (user_type !== "PK") {
      result = true;
    } else {
      result = false;
    }
    return result;
  }

  const handleFormState = () => {
    if (formType === "Return") {
      return (
        <>
          <Tooltip placement="bottom" title="Search" color="#fbfbfb">
            <Button
              disabled={userRights.can_view == true ? false : true}
              onClick={() => history.push("/item-receipt/list")}
              className="iconBtn crudBTN"
              shape="circle"
              icon={<SearchOutlined />}
            />
          </Tooltip>
          {/*Add Button*/}
          <Tooltip placement="bottom" title="Save" color="#fbfbfb">
            <Button
              disabled={disable ? true : false}
              onClick={() => save()}
              className="iconBtn crudBTN"
              shape="circle"
              icon={<FileDoneOutlined />}
            />
          </Tooltip>
        </>
      );
    } else if (formType === "view") {
      return (
        <>
          <Tooltip placement="bottom" title="Search" color="#fbfbfb">
            <Button
              disabled={userRights.can_view == true ? false : true}
              onClick={() => history.push("/item-receipt/list")}
              className="iconBtn crudBTN"
              shape="circle"
              icon={<SearchOutlined />}
            />
          </Tooltip>
        </>
      );
    } else if (formType === "add") {
      return (
        <>
          <Tooltip placement="bottom" title="Search" color="#fbfbfb">
            <Button
              disabled={userRights.can_view == true ? false : true}
              onClick={() => history.push("/item-receipt/list")}
              className="iconBtn crudBTN"
              shape="circle"
              icon={<SearchOutlined />}
            />
          </Tooltip>
          <Tooltip placement="bottom" title="Save" color="#fbfbfb">
            <Button
              disabled={
                !(
                  ((formType === "add" || formType === "edit") && !disable)
                  
                  
                  
                  
                  
                  
                  
                )
              }
              onClick={() => save()}
              className="iconBtn crudBTN"
              shape="circle"
              icon={<FileDoneOutlined />}
            />
          </Tooltip>
        </>
      );
    } else if (formType === "edit") {
      return (
        <>
          <Tooltip placement="bottom" title="Search" color="#fbfbfb">
            <Button
              disabled={userRights.can_view == true ? false : true}
              onClick={() => history.push("/item-receipt/list")}
              className="iconBtn crudBTN"
              shape="circle"
              icon={<SearchOutlined />}
            />
          </Tooltip>
          <Tooltip placement="bottom" title="Save" color="#fbfbfb">
            <Button
              disabled={
                !(
                  ((formType === "add" || formType === "edit") && !disable)
                  
                  
                  
                  
                  
                  
                  
                )
              }
              onClick={() => save()}
              className="iconBtn crudBTN"
              shape="circle"
              icon={<FileDoneOutlined />}
            />
          </Tooltip>
        </>
      );
    } else if (formType === "formView") {
      return (
        <>
          <Tooltip placement="bottom" color=" #fbfbfb">
            <Button
              disabled={disableBtns()}
              
              type="primary"
              onClick={() =>
                history.push(
                  `/credit-note/edit/${formData?.note_header_id}?edit=yes`
                )
              }
              className="modalButton"
            >
              Credit Note
            </Button>
          </Tooltip>
          {userRights.can_print ? (
            <Link
              style={{ paddingLeft: "10px", textDecoration: "none" }}
              target="_blank"
              rel="noopener noreferrer"
              to={{
                pathname: `/credit-memo-report`,
                search: `?note_header_id=${formData?.note_header_id}`,
              }}
            >
              View Report
            </Link>
          ) : (
            ""
          )}

          <Tooltip placement="bottom" title="Search" color="#fbfbfb">
            <Button
              disabled={userRights.can_view == true ? false : true}
              onClick={() => history.push("/item-receipt/list")}
              className="iconBtn crudBTN"
              shape="circle"
              icon={<SearchOutlined />}
            />
          </Tooltip>
          <Tooltip placement="bottom" title="Edit" color="#fbfbfb">
            <Button
              
              
              
              
              
              
              disabled={
                disable ||
                formState === "add" ||
                formState === "view" ||
                (userRights.can_edit === true
                  ? false
                  : true)
				  || transactionLock
              }
              onClick={() => formState("edit")}
              className="iconBtn crudBTN"
              shape="circle"
              icon={<EditOutlined />}
            />
          </Tooltip>
        </>
      );
    }
  };
  return (
    <>
      <div className="crudContainer">
        <div>
          {formType === "" ? null : (
            <div className="buttons-div">
              <Tag color={"green"} key={1}>
                {formType.toUpperCase()}
              </Tag>
              {formType !== "view" && (
                <Button
                  onClick={() => reset()}
                  className="iconBtn"
                  shape="circle"
                  icon={<UndoOutlined className="fspx-12" />}
                />
              )}
              {transactionLock && (
                <Tag color={"red"} key={2}>
                  LOCKED
                </Tag>
              )}
            </div>
          )}
        </div>
        <div>{handleFormState()}</div>
      </div>
      <Divider />
    </>
  );
}
