
import { getAllSalesOrderAdjustmentHeader } from "../../redux/actions/saleOrderAdjustment.action";
import { getAllCustomerPaymentHeaders } from "../../redux/actions/customerPaymnetHeader.action";
import { getAllCustomerRefundHeaders } from "../../redux/actions/customerRefundHeader.action";
import { getAllInvAdjustmentHeader } from "../../redux/actions/inventoryAdjustment.action";
import { getAllOrderShipmentHeader } from "../../redux/actions/orderShipment.action";
import { getAllItemTransferHeader } from "../../redux/actions/itemTransfer.action";
import { getAllSalesReturnHeader } from "../../redux/actions/salesReturn.action";
import { getAllInvoiceHeader } from "../../redux/actions/invoiceHeader.action";
import { getAllSalesOrderHeader } from "../../redux/actions/salesOrder.action";
import { getAllReceiptHeader } from "../../redux/actions/receipt.action";
import { getAllCreditHeader } from "../../redux/actions/credit.action";
import { getAllBillHeader } from "../../redux/actions/bill.action";
import { getAllBillCreditHeader } from "../../redux/actions/billCredit.action";
import { getAllDepositHeaders } from "../../redux/actions/depositHeader.action";
import { getFundTransfer } from "../../redux/actions/fundTransfer.action";
import { getAllBillDirectPayment } from "../../redux/actions/billDirectPayments.action";
import { getAllBillPayment } from "../../redux/actions/billPayments.action";
import { getAllCreditMemoHeader } from "../../redux/actions/creditMemo.action";
import { getVendors } from "../../redux/actions/vendor.action";
import moment from "moment";
import Swal from "sweetalert2";
import { loadSalePerson } from "../../redux/actions/salePerson/salePersonForm.action";

export function checkDate(toDate, fromDate) {
    if (moment(fromDate).isAfter(toDate)) {
        Swal.fire({ icon: "error", title: "Oops...", text: "From Date or To Date Cannot be less then or Greater Then" });
    }
    else if (moment(fromDate).isSame(toDate)) {
        Swal.fire({ icon: "error", title: "Oops...", text: "From Date can not be Same  To Date" })
    }
}

export function dateChecker(fromDate, toDate, pagelist, token, values, dispatch) {
    const difffer = moment(fromDate).diff(moment(toDate))
    if (difffer < 0) {
        if (pagelist === "saleOrderList") {
            dispatch(getAllSalesOrderHeader(token, values));
        } else if (pagelist === "orderShipmentList") {
            dispatch(getAllOrderShipmentHeader(token, values))
        } else if (pagelist === "saleOrderAdjustmentList") {
            dispatch(getAllSalesOrderAdjustmentHeader(token, values))
        } else if (pagelist === "itemTransferList") {
            dispatch(getAllItemTransferHeader(token, values))
        } else if (pagelist === "itemReceiptList") {
            dispatch(getAllReceiptHeader(token, values))
        } else if (pagelist === "creditList") {
            dispatch(getAllCreditHeader(token, values))
        } else if (pagelist === "invAdjustmentList") {
            dispatch(getAllInvAdjustmentHeader(token, values))
        } else if (pagelist === "returnAuthorizationList") {
            dispatch(getAllSalesReturnHeader(token, values))
        } else if (pagelist === "invoiceList") {
            dispatch(getAllInvoiceHeader(token, values))
        } else if (pagelist === "customerRefundList") {
            dispatch(getAllCustomerRefundHeaders(token, values))
        } else if (pagelist === "paymentHeaderList") {
            dispatch(getAllCustomerPaymentHeaders(token, values))
        } else if (pagelist === "billList") {
            dispatch(getAllBillHeader(token, values))
        } else if (pagelist === "billCreditList") {
            dispatch(getAllBillCreditHeader(token, values))
        } else if (pagelist === "depositList") {
            dispatch(getAllDepositHeaders(token, values))
        } else if (pagelist === "fundTransferList") {
            dispatch(getFundTransfer(token, values))
        } else if (pagelist === "billDirectPaymentList") {
            dispatch(getAllBillDirectPayment(token, values))
        } else if (pagelist === "billPaymentList") {
            dispatch(getAllBillPayment(token, values))
        } else if (pagelist === "creditMemoList") {
            dispatch(getAllCreditMemoHeader(token, values))
        } else if (pagelist === "salesPersonList") {
            dispatch(loadSalePerson(token, values))
        }
        else if (pagelist === "vendorList") {
            dispatch(getVendors(token, values))
        }
    }
    else {
        Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "From Date or To Date Cannot be less then or Greater Then",
        });
    }

}