import { ADMIN_MENU_REQUEST, ADMIN_MENU_SUCCESS, ADMIN_MENU_ERROR } from "./types"
import { BASEURL } from "../../constant/config.js"

const axios = require("axios");
export const getMenu = (token) => dispatch => {
    dispatch({ type: ADMIN_MENU_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/menu`,
        headers: {
            Accept: "application/json",
            'Authorization': 'Bearer ' + token
        }
    })
        .then(response => {
            return dispatch({ type: ADMIN_MENU_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: ADMIN_MENU_ERROR,
                payload: error.response,
            });
        })
}
