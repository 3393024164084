import {
  GET_ALL_CUSTOMER_VENDOR_ERROR, GET_ALL_CUSTOMER_VENDOR_SUCCESS, GET_ALL_CUSTOMER_VENDOR_REQUEST, ADD_CUSTOMER_VENDOR_REQUEST, ADD_CUSTOMER_VENDOR_ERROR, ADD_CUSTOMER_VENDOR_SUCCES,
  DELETE_CUSTOMER_VENDOR_REQUEST, DELETE_CUSTOMER_VENDOR_SUCCESS, DELETE_CUSTOMER_VENDOR_ERROR, UPDATE_CUSTOMER_VENDOR_REQUEST, UPDATE_CUSTOMER_VENDOR_SUCCESS, UPDATE_CUSTOMER_VENDOR_ERROR,
} from "../actions/types";

export default function (state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case GET_ALL_CUSTOMER_VENDOR_REQUEST:
      return {
        loading: true,
      };
    case GET_ALL_CUSTOMER_VENDOR_SUCCESS:
      return {
        ...state,
        loading: false,
        payload,
      };
    case GET_ALL_CUSTOMER_VENDOR_ERROR:
      return {
        ...state,
        loading: false,
        payload,
      };
    case UPDATE_CUSTOMER_VENDOR_REQUEST:
      return {
        loading: true,
      };
    case UPDATE_CUSTOMER_VENDOR_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: [payload],
      };
    case UPDATE_CUSTOMER_VENDOR_ERROR:
      return {
        ...state,
        loading: false,
      };

    case ADD_CUSTOMER_VENDOR_REQUEST:
      return {
        loading: true,
      };
    case ADD_CUSTOMER_VENDOR_SUCCES:
      return {
        ...state,
        loading: false,
        payload: [payload],
      };
    case ADD_CUSTOMER_VENDOR_ERROR:
      return {
        ...state,
        loading: false,
      };

    case DELETE_CUSTOMER_VENDOR_REQUEST:
      return {
        loading: true,
      };
    case DELETE_CUSTOMER_VENDOR_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: [payload],
      };
    case DELETE_CUSTOMER_VENDOR_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
