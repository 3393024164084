import { GET_EXPENSE_BILL_REQUEST, GET_EXPENSE_BILL_SUCCESS, GET_EXPENSE_BILL_ERROR } from "./types";
import { BASEURL } from "../../constant/config.js";
const axios = require("axios");
export const getExpenseBill = (token, company_id, account_no) => (dispatch) => {
	dispatch({ type: GET_EXPENSE_BILL_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}getAll/accountExpense`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: { company_id, account_no },
	})
		.then((response) => {
			return dispatch({ type: GET_EXPENSE_BILL_SUCCESS, payload: response.data });
		})
		.catch((error) => {
			return dispatch({
				type: GET_EXPENSE_BILL_ERROR,
				payload: error.response,
			});
		});
};