import {
    GET_FABRICTYPE_REQUEST,
    GET_FABRICTYPE_SUCCESS,
    GET_FABRICTYPE_ERROR,
    ADD_FABRICTYPE_REQUEST,
    ADD_FABRICTYPE_ERROR,
    ADD_FABRICTYPE_SUCCESS,
    UPDATE_FABRICTYPE_REQUEST,
    UPDATE_FABRICTYPE_SUCCESS,
    UPDATE_FABRICTYPE_ERROR,
    DELETE_FABRICTYPE_ERROR,
    DELETE_FABRICTYPE_REQUEST,
    DELETE_FABRICTYPE_SUCCESS,
  } from "../actions/types";
  export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
      case GET_FABRICTYPE_REQUEST:
        return {
          loading: true,
        };
      case GET_FABRICTYPE_SUCCESS:
        return {
          ...state,
          loading: false,
          payload,
        };
      case GET_FABRICTYPE_ERROR:
        return {
          ...state,
          loading: false,
        };
      case ADD_FABRICTYPE_REQUEST:
        return {
          loading: true,
        };
      case ADD_FABRICTYPE_SUCCESS:
        return {
          ...state,
          loading: false,
          payload: [payload],
        };
      case ADD_FABRICTYPE_ERROR:
        return {
          ...state,
          loading: false,
        };
      case DELETE_FABRICTYPE_REQUEST:
        return {
          loading: true,
        };
      case DELETE_FABRICTYPE_SUCCESS:
        return {
          ...state,
          loading: false,
          payload: [payload],
        };
      case DELETE_FABRICTYPE_ERROR:
        return {
          ...state,
          loading: false,
        };
      case UPDATE_FABRICTYPE_REQUEST:
        return {
          loading: true,
        };
      case UPDATE_FABRICTYPE_SUCCESS:
        return {
          ...state,
          loading: false,
          payload: [payload],
        };
      case UPDATE_FABRICTYPE_ERROR:
        return {
          ...state,
          loading: false,
        };
  
      default:
        return state;
    }
  }
  