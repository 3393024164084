import {
    GET_ADMIN_DISCOUNT_REQUEST, GET_ADMIN_DISCOUNT_ERROR, GET_ADMIN_DISCOUNT_SUCCESS,
    ADD_ADMIN_DISCOUNT_REQUEST, ADD_ADMIN_DISCOUNT_ERROR, ADD_ADMIN_DISCOUNT_SUCCESS,
    UPDATE_ADMIN_DISCOUNT_REQUEST, UPDATE_ADMIN_DISCOUNT_ERROR, UPDATE_ADMIN_DISCOUNT_SUCCESS,
    DELETE_ADMIN_DISCOUNT_REQUEST, DELETE_ADMIN_DISCOUNT_ERROR, DELETE_ADMIN_DISCOUNT_SUCCESS,
} from "./types";

import { BASEURL } from "../../constant/config.js";

const axios = require("axios");

export const getAdminDiscount = (token, menu_id) => (dispatch) => {
    dispatch({ type: GET_ADMIN_DISCOUNT_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/adminDiscount`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { menu_id }
    })
        .then((response) => {
            return dispatch({ type: GET_ADMIN_DISCOUNT_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: GET_ADMIN_DISCOUNT_ERROR,
                payload: error.response,
            });
        });
};

export const addAdminDiscount = (token, data, menu_id) => (dispatch) => {



    dispatch({ type: ADD_ADMIN_DISCOUNT_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}create/adminDiscount`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { discount_name: data.adminDiscountName, is_active: data.check, menu_id },
    })
        .then((response) => {

            return dispatch({ type: ADD_ADMIN_DISCOUNT_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: ADD_ADMIN_DISCOUNT_ERROR,
                payload: error.response,
            });
        });
};

export const updateAdminDiscount = (token, data, id) => (dispatch) => {
    dispatch({ type: UPDATE_ADMIN_DISCOUNT_REQUEST });
    return axios({
        method: "PUT",
        url: `${BASEURL}update/adminDiscount/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { discount_name: data.adminDiscountName, is_active: data.check },
    })
        .then((response) => {

            return dispatch({ type: UPDATE_ADMIN_DISCOUNT_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: UPDATE_ADMIN_DISCOUNT_ERROR,
                payload: error.response,
            });
        });
};

export const deleteAdminDiscount = (token, id) => (dispatch) => {
    dispatch({ type: DELETE_ADMIN_DISCOUNT_REQUEST });
    return axios({
        method: "DELETE",
        url: `${BASEURL}delete/adminDiscount/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
    })
        .then((response) => {

            return dispatch({ type: DELETE_ADMIN_DISCOUNT_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: DELETE_ADMIN_DISCOUNT_ERROR,
                payload: error.response,
            });
        });
};
