import {
  GET_STYLES_ERROR,
  GET_STYLES_REQUEST,
  GET_STYLES_SUCCESS,
  ADD_STYLES_ERROR,
  ADD_STYLES_REQUEST,
  ADD_STYLES_SUCCESS,
  DELETE_STYLES_REQUEST,
  DELETE_STYLES_SUCCESS,
  DELETE_STYLES_ERROR,
  UPDATE_STYLES_ERROR,
  UPDATE_STYLES_REQUEST,
  UPDATE_STYLES_SUCCESS,
  GET_STYLE_REQUEST,
  GET_STYLE_SUCCESS,
  GET_STYLE_ERROR
} from "./types";
import { BASEURL } from "../../constant/config.js";

const axios = require("axios");

export const getStyle = (token, menu_id) => (dispatch) => {
  dispatch({ type: GET_STYLES_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}getAll/getAllStyle`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: { menu_id, company_id: 2 }
  })
    .then((response) => {
      return dispatch({ type: GET_STYLES_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      return dispatch({
        type: GET_STYLES_ERROR,
        payload: error.response,
      });
    });
};

export const addStyle = (token, data, menu_id) => (dispatch) => {
  dispatch({ type: ADD_STYLES_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}create/style`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: { style_name: data.styleName, is_active: data.check, menu_id },
  })
    .then((response) => {

      return dispatch({ type: ADD_STYLES_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      return dispatch({
        type: ADD_STYLES_ERROR,
        payload: error.response,
      });
    });
};

export const updateStyle = (token, data, id) => (dispatch) => {
  dispatch({ type: UPDATE_STYLES_REQUEST });
  return axios({
    method: "PUT",
    url: `${BASEURL}update/style/${id}`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: { style_name: data.styleName, is_active: data.check },
  })
    .then((response) => {

      return dispatch({ type: UPDATE_STYLES_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      return dispatch({
        type: UPDATE_STYLES_ERROR,
        payload: error.response,
      });
    });
};

export const deleteStyle = (token, id) => (dispatch) => {
  dispatch({ type: DELETE_STYLES_REQUEST });
  return axios({
    method: "DELETE",
    url: `${BASEURL}delete/style/${id}`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {

      return dispatch({ type: DELETE_STYLES_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      return dispatch({
        type: DELETE_STYLES_ERROR,
        payload: error.response,
      });
    });
};


export const getStyleChange = (token, gender_id, gender_category_id) => (dispatch) => {
  dispatch({ type: GET_STYLE_REQUEST });
  let data = {
    gender_id: gender_id === "All" ? null : gender_id,
    gender_category_id: gender_category_id === null ? null : gender_category_id
  }
  return axios({
    method: "POST",
    url: `${BASEURL}getAll/getstyle`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: data
  })
    .then((response) => {
      return dispatch({ type: GET_STYLE_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      return dispatch({
        type: GET_STYLE_ERROR,
        payload: error.response,
      });
    });
};