import {
    GET_PRODUCTTYPE_ERROR, GET_PRODUCTTYPE_REQUEST, GET_PRODUCTTYPE_SUCCESS,
    ADD_PRODUCTTYPE_ERROR, ADD_PRODUCTTYPE_REQUEST, ADD_PRODUCTTYPE_SUCCESS,
    DELETE_PRODUCTTYPE_REQUEST, DELETE_PRODUCTTYPE_SUCCESS, DELETE_PRODUCTTYPE_ERROR,
    UPDATE_PRODUCTTYPE_ERROR, UPDATE_PRODUCTTYPE_REQUEST, UPDATE_PRODUCTTYPE_SUCCESS

} from "../actions/types"
export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case GET_PRODUCTTYPE_REQUEST:
            return {
                loading: true,
            };
        case GET_PRODUCTTYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_PRODUCTTYPE_ERROR:
            return {
                ...state,
                loading: false
            };
        case ADD_PRODUCTTYPE_REQUEST:
            return {
                loading: true,
            };
        case ADD_PRODUCTTYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case ADD_PRODUCTTYPE_ERROR:
            return {
                ...state,
                loading: false
            };
        case DELETE_PRODUCTTYPE_REQUEST:
            return {
                loading: true,
            };
        case DELETE_PRODUCTTYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case DELETE_PRODUCTTYPE_ERROR:
            return {
                ...state,
                loading: false
            };
        case UPDATE_PRODUCTTYPE_REQUEST:
            return {
                loading: true,
            };
        case UPDATE_PRODUCTTYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case UPDATE_PRODUCTTYPE_ERROR:
            return {
                ...state,
                loading: false
            };

        default:
            return state;
    }
}