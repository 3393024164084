import React, { useState, useEffect, useLayoutEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { deleteInvoiceHeader, getOneInvoiceHeader, getOneInvoiceHeaderDetail } from "../../redux/actions/invoiceHeader.action";
import CrudButtons from "./components/CrudButtons";
import InvoiceForm from "./components/Form/InvoiceForm";
import PageHeader from "../../components/PageHeader/PageHeader";
import { Button } from "antd";
import { ReadCookie } from "../../utils/readCookie";
import notification from "../../utils/notification";
import { useHistory } from "react-router-dom";
import "../../screens/Product/product.css";
import "../Style/style.css";
import Loader from "react-loader-spinner";
import { getRights } from "../../redux/actions/userRights.action";
import { TabTitle } from "../../utils/GeneralFunction";
import { getTransationLock } from "../../redux/actions/transactionLock.action";
import moment from "moment";


export default function InvoiceNew({ token }) {
	TabTitle("Invoice")
	const { id, customerCode, shipHeaderNo } = useParams();
	const dispatch = useDispatch();
	const dCompanyId = ReadCookie("defaultCompany");
	
	const { invoiceHeaderFormDataReducer, log, invoiceHeader, userType } = useSelector((state) => state);
	const [minimize, setMinimize] = useState(false);
	const [formState, setFormState] = useState("view");
	const [saleOrderID, setSaleOrderID] = useState(id);
	const [flag, setFlag] = useState(false);
	const [companyId, setCompanyId] = useState(ReadCookie("defaultCompany"));
	const [disable, setDisable] = useState(false);
	const [submit, setSubmit] = useState(false);
	const [orderID, setOrderID] = useState(null);
	const [shipHeaderID, setShipHederID] = useState(null)
	const [invoiceHeaderID, setInvoiceHeaderID] = useState(null)
	const queryParams = new URLSearchParams(window.location.search);
	const mode = queryParams.get("mode");
	const order_header_id = queryParams.get("order_header_id");
	const invoice_header_id = queryParams.get("invoice_header_id");
	const customer_id = queryParams.get("customer_id");
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const [userRights, setUserRights] = useState({})
	const [transactionLock, setTransactionLock] = useState(false)

	const[menuId, setMenuId] = useState(184);

	
	useLayoutEffect(() => {
		dispatch(getRights(token, menuId)).then((res) => {
			if (res.type === "GET_USER_RIGHTS_SUCCESS") {
				setUserRights(res?.payload)
			}
		});
	}, [token])


	useEffect(() => {
		if (mode === "invoice") {
			dispatch(getOneInvoiceHeaderDetail(token, order_header_id)).then((res) => {
				setShipHederID(res?.payload[0]?.ship_header_id);
				setFormState("add");
			});
		}
		else if (mode === "edit") {
			dispatch(getOneInvoiceHeader(token, invoice_header_id));
			if (invoiceHeader?.payload?.invoice_date) {
				let transaction_date = moment(invoiceHeader?.payload?.invoice_date).format("YYYY-MM-DD")
				dispatch(getTransationLock(token, transaction_date)).then((r)=> {
					if (r.payload.data.length > 0) {
						setTransactionLock(true)
					}
				});
			}
			setFormState("formView");
		}
	}, []);

	
	const filterReset = () => {
		setFormState("formView");
	};

	
	const handleDelete = () => {
		setLoading(true);
		dispatch(deleteInvoiceHeader(token, invoice_header_id)).then((res) => {
			if (res.type === "DELETE_INVOICE_HEADER_SUCCESS") {
				setLoading(false);
				notification("success", "Invoice Header deleted", res.payload);
				setFlag(true);
				history.push("/invoice-list");
			}
		});
	};

	
	const formSave = () => {
		setSubmit(true);
	};


	return (
		<>
			<div className="styleContainer">
				
				<div className="styleBody" style={minimize ? {} : { height: "100%" }}>
					<PageHeader title={"Invoice"} minimize={setMinimize} check={minimize} />
					<div className="bodyStyle">
						<div className="crudDiv">
							<CrudButtons
								user_type={userType?.payload?.data[0]?.coalesce}
								customer_id={customer_id}
								order_header_id={order_header_id}
								invoice_header_id={invoice_header_id}
								reportTittle="Sale Order Report"
								name={"invoice"}
								reset={filterReset}
								userRights={userRights}
								formType={formState}
								save={formSave}
								disable={disable}
								formState={setFormState}
								onDelete={handleDelete}
								saleOrderID={saleOrderID}
								invoiceId={customerCode}
								mode={mode}
								formData={invoiceHeader?.payload}
								orderID={orderID}
								invoiceHeaderID={invoiceHeaderID}
								transactionLock= {transactionLock}
							/>
							<div className="mainProductContainer">
								<InvoiceForm
									user_type={userType?.payload?.data[0]?.coalesce}
									userRights={userRights}
									setInvoiceHeaderID={setInvoiceHeaderID}
									invoiceHeaderID={invoiceHeaderID}
									mode={mode}
									shipHeaderID={shipHeaderID}
									invoice_header_id={invoice_header_id}
									customer_id={customer_id}
									order_header_id={order_header_id}
									loading={loading}
									dCompany={dCompanyId}
									defaultCompany={companyId}
									companyId={setCompanyId}
									submit={setSubmit}
									val={submit}
									formData={invoiceHeader?.payload}
									formState={formState}
									data={invoiceHeaderFormDataReducer}
									changeState={setFormState}
									setDisable={setDisable}
									token={token}
									checkFlag={flag}
									setFlag={setFlag}
									logs={log}
									orderHeaderId={saleOrderID}
									shipHeaderId={customerCode}
									customerCode={shipHeaderNo}
									shipHeaderNo={shipHeaderNo}

								/>
							</div>
						</div>
					</div>
				</div>
				{minimize && (
					<div className="styleFooter">
						{" "}
						<Button className="fw-600 b-radius-5" onClick={() => setMinimize(false)}>
							{" "}
							Sales Order{" "}
						</Button>{" "}
					</div>
				)}
			</div>
		</>
	);
}
