import React from 'react'
import "./subtitle.css"
export default function SubTitle({name}) {
    return (
        <div className='subtitle'>
            <div className='subtitleHeading'>
                <h4>{name}</h4>
            </div>
        </div>
    )
}
