import {
    GET_ALL_PARENT_MENU_REQUEST, GET_ALL_PARENT_MENU_SUCCESS, GET_ALL_PARENT_MENU_ERROR

} from "../actions/types"
export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {

        case GET_ALL_PARENT_MENU_REQUEST:
            return {
                loading: true,
            };
        case GET_ALL_PARENT_MENU_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_ALL_PARENT_MENU_ERROR:
            return {
                ...state,
                loading: false
            };
        default:
            return state;
    }
}