import React, { useEffect, useState, useRef } from "react";
import { Spin, Select, Checkbox, Form, Button, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getGenderCategory } from "../../redux/actions/genderCategory.action";
import { getProductStatus } from "../../redux/actions/productStatus.actions";
import { getSeason } from "../../redux/actions/season.action";
import { getGender } from "../../redux/actions/gender.action";
import { ReadCookie } from "../../utils/readCookie";
import { getStyle } from "../../redux/actions/style";
import btnPrint from "../../assets/images/button_print.gif";
import xlsx from "../../assets/images/ico_xls_sm.gif";
import logo from "../../assets/images/logo.png";
import "../Distributor/Distributor.css";
import Loader from "react-loader-spinner";
import { TabTitle } from "../../utils/GeneralFunction";
import { CSVLink } from "react-csv";
import { getSku } from "../../redux/actions/sku.action";
import { useCallback } from "react";
import { getUPCReport } from "../../redux/actions/upcReport.action";
const { Option } = Select;

const UPCReport = ({ token }) => {
  TabTitle("UPC Report")
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const {
    style,
    gender,
    genderCategory,
    productStatus,
    Sku,
    upcReport
  } = useSelector((state) => state);
  const menuId = ReadCookie("menuId");
  const userName = ReadCookie("name");
  const [isChecked2, setIsChecked2] = useState([]);
  const [allStatus, setAllStatus] = useState(true);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([])
  const tableRef = useRef(null);
  const [excelData, setExcelData] = useState([])




  const statusSelectAll = (e) => {
    setAllStatus(e.target.checked);
  };

  const isCheckboxChecked2 = (id, value) => {
    const filterCheckBox =
      productStatus && productStatus?.payload?.data.find((val) => val.product_status_id === id);
    const checkId = isChecked2.includes(id);
    if (!checkId) {
      setIsChecked2((current) => [...current, filterCheckBox.product_status_id]);
    }
    if (!value) {
      const removeUnCheck = isChecked2.filter(
        (item) => item !== filterCheckBox.product_status_id
      );
      setIsChecked2(removeUnCheck);
    }
    setAllStatus(false);
  };

  const onFinish = async (e) => {
    setLoading(true)
    await form.validateFields().then((values) => {
      
      dispatch(
        getUPCReport(token, values, isChecked2)).then((res => {
          setLoading(false)
          setData(res?.payload?.data)
          downloadExcelFileData(res?.payload?.data);
        }));
    });
  };

  function downloadExcelFileData(data) {
    setExcelData(
      data.map((v) => {
        return {
          "SKU": v.item_code,
          "Inseam": v.inseam,
          "Size": v.size,
          "Item Code": v.complete_sku,
          "Style": v.style_name,
          "Wash": v.wash_name,
          "Gender": v.gender_name,
          "Gender Category": v.gender_category_name,
          "UPC": v.upc_code,
          "NRF Size Code": v.nrf_size_code,
          "NRF Color Code": v.nrf_color_code,
        };
      })
    )
  }

  useEffect(() => {
    dispatch(getSeason(token, menuId));
    dispatch(getProductStatus(token, menuId));
    dispatch(getGender(token, menuId));
    dispatch(getGenderCategory(token, menuId));
    dispatch(getStyle(token, menuId));
  }, [dispatch, menuId, token]);

  const handleSkuSearch = () => {
    dispatch(getSku(token, null, null, null));
  }

  function debounce(cb, delay = 1000) {
    let timeout
    return (...args) => {
      clearTimeout(timeout)
      timeout = setTimeout(() => {
        cb(...args)
      }, delay)
    }
  }

  const optimizedFn = useCallback(debounce(handleSkuSearch), []);

  useEffect(() => {
    if (allStatus) {
      setIsChecked2([]);
    }
  }, [allStatus])


  const handlePrint = () => {
    window.print();
  };

  const column = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 40,
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: "SKU",
      dataIndex: "item_code",
      key: "item_code",
    },
    {
      title: "Inseam",
      dataIndex: "inseam",
      key: "inseam",
    },
    {
      title: "Size",
      dataIndex: "size",
      key: "size",
    },
    {
      title: "Item Code",
      dataIndex: "complete_sku",
      key: "complete_sku",
    },
    {
      title: "Style",
      dataIndex: "style_name",
      key: "style_name",
    },
    {
      title: "Wash",
      dataIndex: "wash_name",
      key: "wash_name",
    },
    {
      title: "Gender",
      dataIndex: "gender_name",
      key: "gender_name",
    },
    {
      title: "Gender Category",
      dataIndex: "gender_category_name",
      key: "gender_category_name",
    },
    {
      title: "UPC",
      dataIndex: "upc_code",
      key: "upc_code",
    },
    {
      title: "NRF Size Code",
      dataIndex: "nrf_size_code",
      key: "nrf_size_code",
    },
    {
      title: "NRF Color Code",
      dataIndex: "nrf_color_code",
      key: "nrf_color_code",
    },
  ];


  return (
    <div className="header_class business_header_oms">
      <div className="row justify-content-between business_class_oms distributor">
        <div className="col-3 d-flex align-items-start box-space">
          <div className="logobrand">
            <img id="ImgLogo" alt="logo" src={logo} />
          </div>
        </div>
        <div className="col-4 box-space right-content-header text-right">
          <div className=" d-flex justify-content-end">
            <div className=" d-flex justify-content-end mr-8">
              <div className="print_icon printing_class">
                <i
                  onClick={handlePrint}
                  id="Image1"
                  title="Print"
                  alt="Print"
                  src={btnPrint}
                  className="fa-solid fa-print"
                ></i>
              </div>
              <div className="print_icon printing_class">
                <CSVLink
                  filename={"UPC-Report.csv"}
                  data={excelData}
                  onClick={() => {
                  }}
                >
                  <input
                    type="image"
                    name="ImgExcel"
                    alt="Export to excel"
                    id="ImgExcel"
                    title="Export to excel"
                    src={xlsx}
                  />
                </CSVLink>
              </div>
            </div>
            <span style={{ fontSize: "small", fontWeight: "bolder" }}>
              UPC Report
              <br />
              Rpt # 489
              <br />
              <p className=" mb-0">Rpt # 001_V4</p>
              Login as:{" "}
              <span id="lblSalesPerson" style={{ fontWeight: "bold" }}>
                {userName}
              </span>
            </span>
          </div>
        </div>
      </div>
      <div className="business_class_oms mb-2 distributor">
        <Form form={form} name="control-hooks" onFinish={onFinish}>
          <div className="main_form row">
            <div className="col-12">
              <hr className="my-1" />
              <div>
                <label htmlFor="">Product Status</label>
                <div className="checkboxes">
                  <div className="checkbox_content">
                    <Form.Item name="current_status_id" className=" mb-0">
                      <Checkbox checked={allStatus} onChange={statusSelectAll}>
                        All
                      </Checkbox>
                    </Form.Item>
                  </div>
                  {productStatus &&
                    productStatus?.payload?.data.map((it, index) => {
                      return (
                        <div key={index} className="checkbox_content">
                          <Form.Item name="current_status_id">
                            <Checkbox
                              checked={allStatus ? false : isChecked2.includes(it.product_status_id)}
                              onChange={(e) =>
                                isCheckboxChecked2(
                                  it.product_status_id,
                                  e.target.checked
                                )
                              }
                            >
                              {it.product_status_name}
                            </Checkbox>
                          </Form.Item>
                        </div>
                      );
                    })}
                </div>
              </div>
              <hr className="my-1" />
              <div className="main_form row">
                {/*First*/}
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width44" htmlFor="">Gender</label>
                    <div className="w-100">
                      <Form.Item name="gender_id" initialValue={"All"}>
                        <Select
                          className={"select_Inputs"}
                          showSearch
                          
                          optionFilterProp="children"
                          size="small"
                          filterSort={(optionA, optionB) =>
                            optionB?.value!==null?
                              optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()):''
                        }
                        >
                          <Option value={null}>All</Option>
                          {gender &&
                            gender?.payload?.data.map((it, index) => {
                              return (
                                <Option key={index} value={it.gender_id}>
                                  {it.gender_name}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </div>
                {/*Second*/}
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width44" htmlFor="">Category</label>
                    <div className="w-100">
                      <Form.Item name="category_id" initialValue={'All'}>
                        <Select
                          defaultValue={form.getFieldValue().season_id}
                          className={"select_Inputs"}
                          showSearch
                          
                          optionFilterProp="children"
                          size="small"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          filterSort={(optionA, optionB) =>
                            optionB?.value!==null?
                              optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()):''
                        }
                        >
                          <Option value={null}>All</Option>
                          {genderCategory &&
                            genderCategory?.payload?.data.map((it, index) => {
                              return (
                                <Option
                                  key={index}
                                  value={it.gender_category_id}
                                >
                                  {it.gender_category_name}
                                </Option>
                              );
                            }
                            )}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </div>
                {/*Third*/}
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width44" htmlFor="">Style</label>
                    <div className="w-100">
                      <Form.Item name="style_id" initialValue={'All'}>
                        <Select
                          defaultValue={form.getFieldValue().season_id}
                          className={"select_Inputs"}
                          showSearch
                          
                          optionFilterProp="children"
                          size="small"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          filterSort={(optionA, optionB) =>
                            optionB?.value!==null?
                              optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()):''
                        }
                        >
                          <Option value={null}>All</Option>
                          {style && style?.payload?.data.map((style, index) => {
                            return (
                              <Option key={index} value={style.style_id}>
                                {style.style_name}
                              </Option>
                            );
                          }
                          )}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </div>
                {/*Fourth*/}
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width44" htmlFor="">Sku</label>
                    <div className="w-100">
                      <Form.Item name="sku" initialValue={"All"}>
                        <Select
                          notFoundContent={Sku.loading ? <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Loader type="Oval" color="#3c4b58" height={20} width={20} />
                          </div> : <div style={{ display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center" }}>No Data</div>}
                          className={"select_Inputs"}
                          showSearch
                          onSearch={(value) => {
                            optimizedFn(value)
                          }}
                          optionFilterProp="children"
                          size="small"
                        >
                          <Option value={null}>All</Option>
                          {Sku &&
                            Sku?.payload?.data.map((it, index) => {
                              return (
                                <Option key={index} value={it.sku}>
                                  {it.sku}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                  <div className="justify-content-end" style={{ display: "flex", alignItems: "end" }}>
                    <label className=" no_visibility">none</label>
                    <Button
                      style={{ width: "fit-content" }}
                      type="primary"
                      htmlType="submit"
                      className="submit-btn-btm m-0 float-end"
                    >
                      Generate
                    </Button>
                  </div>
                </div>
                <div className="table_content customer_ledger_table_content ledger_print"
                  style={{ maxWidth: '1160px' }}  >
                  <div className="">
                    {upcReport?.payload?.data !== undefined ?
                      (
                        upcReport?.payload?.data.length > 0 ? (
                          <Table
                             className="// maxWidth test04 mt-3 sales_report_res_table_intransit ledger_print"
                            ref={tableRef}
                            size="small"
                            bordered={false}
                            type="company"
                            headerClassName='abc'
                            dataSource={data && data}
                            columns={column}
                            loading={upcReport.loading}
                            pagination={{
                              defaultCurrent: 1,
                              defaultPageSize: 100,
                              pageSizeOptions: [100, 500, 1000, 2000, 2500, 3000]
                            }}
                            scroll={{ x: 'max-content' }}
                          />
                        ) : (
                          <div className="no-Data">
                            <h1>No Data</h1>
                          </div>
                        )
                      ) : (
                        loading && (
                          <div className="loader" style={{ marginTop: "20px" }}>
                            <Spin size="medium" />
                          </div>
                        )
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>
      <div>
        <div ref={tableRef} className="overflow" style={{ marginBottom: "30px" }}>
        </div>
      </div>
    </div>
  );
};

export default UPCReport;
