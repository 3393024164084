import {
    GET_ALL_PRODUCT_ERROR, GET_ALL_PRODUCT_REQUEST, GET_ALL_PRODUCT_SUCCESS,
} from "./types";

import { BASEURL } from "../../constant/config.js";

const axios = require("axios");


export const getProducts = (token, menu_id, item_Code) => (dispatch) => {
    dispatch({ type: GET_ALL_PRODUCT_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/getAllViewProduct`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { menu_id, item_Code: item_Code }
    })
        .then((response) => {
            return dispatch({ type: GET_ALL_PRODUCT_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: GET_ALL_PRODUCT_ERROR,
                payload: error.response,
            });
        });
};