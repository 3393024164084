import { GET_PRODUCTSALE_REQUEST, GET_PRODUCTSALE_SUCCESS, GET_PRODUCTSALE_ERROR } from "./types"
import { BASEURL } from "../../constant/config.js";
const axios = require("axios")
export const getProductSale = (token, company_id, item_code, location_id) => (dispatch) => {
    dispatch({ type: GET_PRODUCTSALE_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/productsSale`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { company_id, item_code, location_id }
    })
        .then((response) => {
            return dispatch({ type: GET_PRODUCTSALE_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: GET_PRODUCTSALE_ERROR,
                payload: error.response,
            });
        });
};