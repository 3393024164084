import { Modal } from 'antd';
import React from 'react';

function ModalContainer({ title = '', children, open = false, close = () => { }, ...props }) {
    return (
        <>
            <Modal title={title} visible={open} onCancel={close} {...props}>
                {/* <div className='modal_header'>
                    <span role='button' onClick={close}>
                        &times;
                    </span>
                </div> */}
                <div className='modal_body'>
                    {children}
                </div>
            </Modal>
        </>
    );
}

export default ModalContainer;