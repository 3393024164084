import {
    GET_SKU_REQUEST,
    GET_SKU_SUCCESS,
    GET_SKU_ERROR,

} from "../actions/types"
export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case GET_SKU_REQUEST:
            return {
                loading: true,
            };
        case GET_SKU_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_SKU_ERROR:
            return {
                ...state,
                loading: false
            };
        default:
            return state;
    }
}