import React from 'react'
import {
    Chart as ChartJS, CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
} from "chart.js";
import { Doughnut, Bar } from "react-chartjs-2";

ChartJS.register(CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend);



export default function SalesByYearly() {
    return (
        <div>
            <Bar data={{
                labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                datasets: [
                    {
                        size: "large",
                        id: 1,
                        label: 'Jan',
                        data: [10, 6, 2, 7, 6, 3, 9, 4, 6, 1, 8, 3],
                        backgroundColor: 'rgba(255, 99, 132, 0.5)',

                    },
                    {
                        id: 2,
                        label: 'Feb',
                        data: [10, 6, 2, 7, 2, 3, 7, 4, 2, 7, 8, 6],
                        backgroundColor: 'rgba(215, 49, 132, 0.5)',

                    },
                    {
                        id: 3,
                        label: 'Mar',
                        data: [10, 6, 2, 7, 2, 3, 7, 4, 2, 7, 8, 6],
                        backgroundColor: 'rgba(215, 69, 112, 0.5)',

                    },
                    {
                        id: 4,
                        label: 'Apr',
                        data: [3, 12, 9],
                        backgroundColor: 'rgba(115, 78, 21, 0.5)',

                    },
                    {
                        id: 5,
                        label: 'May',
                        data: [5, 8, 1],
                        backgroundColor: 'rgba(123, 12, 76, 0.5)',

                    },
                    {
                        id: 6,
                        label: 'Jun',
                        data: [10, 6, 2, 7, 6, 3, 9, 4, 6, 1, 8, 3],
                        backgroundColor: 'rgba(75, 231, 34, 0.5)',

                    },
                    {
                        id: 7,
                        label: 'Jul',
                        data: [10, 6, 2, 7, 2, 3, 7, 4, 2, 7, 8, 6],
                        backgroundColor: 'rgba(123, 256, 23, 0.5)',

                    },
                    {
                        id: 8,
                        label: 'Aug',
                        data: [2, 1, 9, 4, 8, 1, 5, 4, 3, 0, 9, 10],
                        backgroundColor: 'rgba(123, 34, 35, 0.5)',

                    },
                    {
                        id: 9,
                        label: 'Sep',
                        data: [2, 1, 9, 4, 8, 2, 5, 4, 3, 0, 9, 10],
                        backgroundColor: 'rgba(122, 65, 76, 0.5)',

                    },
                    {
                        id: 10,
                        label: 'Oct',
                        data: [2, 1, 9, 1, 8, 1, 5, 9, 3, 0, 9, 1],
                        backgroundColor: 'rgba(54, 69, 23, 0.5)',

                    },
                    {
                        id: 11,
                        label: 'Nov',
                        data: [10, 6, 2, 7, 6, 3, 9, 4, 6, 1, 8, 3],
                        backgroundColor: 'rgba(215, 34, 23, 0.5)',

                    },
                    {
                        id: 12,
                        label: 'Dec',
                        data: [10, 6, 2, 7, 2, 3, 7, 4, 2, 7, 8, 6],
                        backgroundColor: 'rgba(24, 69, 12, 0.5)',

                    },
                ],
            }} />
        </div>
    )
}
