import React from 'react'
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
} from "chart.js";
import { Doughnut, Bar, Line, Chart } from "react-chartjs-2";
ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController
);



export default function MultiTypeChart() {
    return (
        <div>
            <Chart
                type="bar"
                data={{
                    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                    datasets: [
                        {
                            id: 1,
                            type: 'line',
                            label: '2020',
                            borderColor: 'rgb(255, 99, 132)',
                            borderWidth: 2,
                            fill: false,
                            data: [10, 6, 2, 7, 6, 3, 9, 4, 6, 1, 8, 3],
                        },
                        {
                            id: 2,
                            type: 'bar',
                            label: '2021',
                            backgroundColor: 'rgb(75, 192, 192)',
                            data: [10, 6, 2, 7, 2, 3, 7, 4, 2, 7, 8, 6],
                            borderColor: 'white',
                            borderWidth: 2,
                        },
                        {
                            id: 3,
                            type: 'bar',
                            label: '2022',
                            backgroundColor: 'rgb(53, 162, 235)',
                            data: [2, 1, 9, 4, 8, 1, 5, 4, 3, 0, 9, 10],
                        },
                    ],
                }} />
        </div>
    )
}
