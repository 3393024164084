import {
    ADD_ADMIN_MENU_REQUEST, ADD_ADMIN_MENU_SUCCESS, ADD_ADMIN_MENU_ERROR,
    GET_ALL_ADMIN_MENU_REQUEST, GET_ALL_ADMIN_MENU_SUCCESS, GET_ALL_ADMIN_MENU_ERROR,
    DELETE_ADMIN_MENU_REQUEST, DELETE_ADMIN_MENU_SUCCESS, DELETE_ADMIN_MENU_ERROR,
    UPDATE_ADMIN_MENU_REQUEST, UPDATE_ADMIN_MENU_SUCCESS, UPDATE_ADMIN_MENU_ERROR,
    GET_ALL_PARENT_MENU_REQUEST, GET_ALL_PARENT_MENU_SUCCESS, GET_ALL_PARENT_MENU_ERROR
} from "./types";

import { BASEURL } from "../../constant/config.js";
const axios = require("axios");

export const createAdminMenu = (token, data) => (dispatch) => {
    dispatch({ type: ADD_ADMIN_MENU_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}create/adminMenu`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: {
            menu_name: data.menuName,
            module_id: data.adminModule,
            menu_type_id: data.adminMenuType,
            runtime_name: data.runtimeName,
            seq: data.sequence,
            is_active: data.check,
            parent_menu_id: data.parentMenuSelect
        }
    })
        .then((response) => {
            return dispatch({ type: ADD_ADMIN_MENU_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: ADD_ADMIN_MENU_ERROR,
                payload: error.response,
            });
        });
};

export const updateAdminMenu = (token, menuName, adminModule, adminMenuType, adminMenuSelect, check, sequence, runtimeName, parentMenuSelect, menuID) => (dispatch) => {
    dispatch({ type: UPDATE_ADMIN_MENU_REQUEST });
    return axios({
        method: "PUT",
        url: `${BASEURL}update/adminMenu/${menuID}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: {
            menu_name: menuName,
            module_id: adminModule,
            menu_type_id: adminMenuType,
            runtime_name: runtimeName,
            seq: sequence,
            is_active: check,
            parent_menu_id: parentMenuSelect
        }
    })
        .then((response) => {
            return dispatch({ type: UPDATE_ADMIN_MENU_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: UPDATE_ADMIN_MENU_ERROR,
                payload: error.response,
            });
        });
};

export const getAllAdminMenu = (token) => (dispatch) => {
    dispatch({ type: GET_ALL_ADMIN_MENU_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/adminMenu`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
    })
        .then((response) => {
            return dispatch({ type: GET_ALL_ADMIN_MENU_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: GET_ALL_ADMIN_MENU_ERROR,
                payload: error.response,
            });
        });
};

export const deleteAdminMenu = (token, id) => (dispatch) => {
    dispatch({ type: DELETE_ADMIN_MENU_REQUEST });
    return axios({
        method: "DELETE",
        url: `${BASEURL}delete/adminMenu/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },

    })
        .then((response) => {
            return dispatch({ type: DELETE_ADMIN_MENU_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: DELETE_ADMIN_MENU_ERROR,
                payload: error.response,
            });
        });
};

export const getAllParentMenu = (token, data, type) => (dispatch) => {

    dispatch({ type: GET_ALL_PARENT_MENU_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/parentMenu`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: {
            module_id: type ? data.module_id : data.adminModule,
            menu_type_id: type ? data.menu_type_id : data.adminMenuType
        }
    })
        .then((response) => {
            return dispatch({ type: GET_ALL_PARENT_MENU_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: GET_ALL_PARENT_MENU_ERROR,
                payload: error.response,
            });
        });
};