import {
  PUT_INVOICE_LINE_DATA_ERROR,
  PUT_INVOICE_LINE_DATA_REQUEST,
  PUT_INVOICE_LINE_DATA_SUCCESS,
  GET_ONE_INVOICE_RELATED_RECORD_REQUEST,
  GET_ONE_INVOICE_RELATED_RECORD_SUCCESS,
  GET_ONE_INVOICE_RELATED_RECORD_ERROR
} from "./types";
import axios from "axios";
import { BASEURL } from "../../constant/config.js";

/// Update invoice Id 
export const updateInvoiceLine = (token, lineId, data) => (dispatch) => {

  dispatch({ type: PUT_INVOICE_LINE_DATA_REQUEST });

  data.map((v) => {
    delete v.balance_qty
    return v;
  })

  return axios({
    method: "PUT",
    url: `${BASEURL}update/invoiceLine/${lineId}`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data,
  })
    .then((response) => {
      return dispatch({
        type: PUT_INVOICE_LINE_DATA_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: PUT_INVOICE_LINE_DATA_ERROR,
        payload: [],
      });

    });
};



export const getOneInvoiceRelatedRecord = (token, id) => (dispatch) => {
  dispatch({ type: GET_ONE_INVOICE_RELATED_RECORD_REQUEST });
  return axios({
    method: "GET",
    url: `${BASEURL}getOne/invoiceRelatedRecord/${id}`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {
      return dispatch({
        type: GET_ONE_INVOICE_RELATED_RECORD_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_ONE_INVOICE_RELATED_RECORD_ERROR,
        payload: error.response,
      });
    });
};