import {
    GET_ALL_RELOCATEITEMS_ERROR, GET_ALL_RELOCATEITEMS_REQUEST, GET_ALL_RELOCATEITEMS_SUCCESS,ADD_ALL_RELOCATEITEMS_SUCCESS,ADD_ALL_RELOCATEITEMS_REQUEST,ADD_ALL_RELOCATEITEMS_ERROR
} from "../actions/types";

export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case ADD_ALL_RELOCATEITEMS_REQUEST:
			return {
				loading: true,
			};
		case ADD_ALL_RELOCATEITEMS_SUCCESS:
			return {
				...state,
				loading: false,
				payload
			};
		case ADD_ALL_RELOCATEITEMS_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};
        case GET_ALL_RELOCATEITEMS_REQUEST:
            return {
                loading: true,
            };
        case GET_ALL_RELOCATEITEMS_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_ALL_RELOCATEITEMS_ERROR:
            return {
                ...state,
                loading: false
            };
        default:
            return state;
    }
}