import {
  ADD_ALL_TABLESCHEMA_SUCCESS,
  ADD_ALL_TABLESCHEMA_REQUEST,
  ADD_ALL_TABLESCHEMA_ERROR,
} from "./types";

import { BASEURL } from "../../constant/config.js";
import axios from "axios";

export const getTableSchema = (token) => (dispatch) => {
  dispatch({ type: ADD_ALL_TABLESCHEMA_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}getAll/getTableSchema`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {
      return dispatch({
        type: ADD_ALL_TABLESCHEMA_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: ADD_ALL_TABLESCHEMA_ERROR,
        payload: error.response,
      });
    });
};
