import { GET_USER_TYPE_REUQEST, GET_USER_TYPE_SUCCESS, GET_USER_TYPE_ERROR } from "./types";
import { BASEURL } from "../../constant/config.js";

const axios = require("axios");
export const getUserType = (token,) => (dispatch) => {
    dispatch({ type: GET_USER_TYPE_REUQEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/getUserType`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        
    })
        .then((response) => {
            return dispatch({ type: GET_USER_TYPE_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: GET_USER_TYPE_ERROR,
                payload: error.response,
            });
        });
};
