import React, { useEffect, useRef, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getWashName } from "../../redux/actions/wash.action";
import { Button, Form, Select, DatePicker, Radio, Input } from "antd";
 
import { CloseCircleOutlined } from "@ant-design/icons";
import { getSeason } from "../../redux/actions/season.action";
import { useHistory } from "react-router-dom";
import "../ProducList/productList.css";
import moment from "moment";
import { Row as Brow, Col as Bcol } from "reactstrap";
import { loadCustomers } from "../../redux/actions/customerForm";
import { ReadCookie } from "../../utils/readCookie";
import { getAllSaleperson } from "../../redux/actions/salesPerson";
import { getWashType } from "../../redux/actions/washType.action";
import { getReportIP } from "../../redux/actions/reportIP.action";
import { getGender } from "../../redux/actions/gender.action";
import { getStyle } from "../../redux/actions/style";
import { getGenderCategory } from "../../redux/actions/genderCategory.action";

const { RangePicker } = DatePicker;
const ProductionDetailReport = ({ token }) => {
	const { wash, style, genderCategory, season, gender } = useSelector((state) => state);
	const [date, setDate] = useState([moment().startOf("year").format("MM/DD/YYYY"), moment().format("MM/DD/YYYY")]);
	const [genderCategoryID, setGenderCategoryID] = useState("All");
	const [washTypeID, setWashTypeID] = useState("All"); 
	const [seasonID, setSeasonID] = useState("All");
	const [genderID, setGenderID] = useState("All");
	const [washID, setWashID] = useState("All");
	const [styleID, setStyle] = useState("All");
	const focusInput = useRef(null);
	const [selectedParameters, setSelectedParameters] = useState({
		season: "",
		style: "",
		washName: "",
		gender: "",
		genderCategory: "",
	});

	const [sku, setSKU] = useState("");

	const menuId = ReadCookie("menuId");
	const userName = ReadCookie("name");
	const dispatch = useDispatch();
	const [form] = Form.useForm();

	const [showGroup, setShowGroup] = useState(false);
	const [reportIP, setReportIP] = useState("");
	const [groupName, setGroupName] = useState("Sales Person");
	const { Option } = Select;
	let history = useHistory();

	useEffect(() => {
		dispatch(getReportIP(token)).then((res) => {
			setReportIP(res.payload.data[0].report_server_ip);
		});
		dispatch(getAllSaleperson(token, menuId));
		dispatch(getSeason(token, menuId));
		dispatch(getSeason(token, menuId));
		dispatch(getWashName(token, menuId));
		dispatch(getWashType(token, menuId));
		dispatch(getGender(token, menuId));
		dispatch(getStyle(token, menuId));
		dispatch(getSeason(token, menuId));
		dispatch(getGenderCategory(token, menuId));
	}, []);
	
	const getReportName = () => {
			return "ReturnGroupReport.rptdesign";
	};
		const onGenderChange = (e, f) => {
		setGenderID(e);
		setSelectedParameters({ ...selectedParameters, gender: f.children });
	};
	const onSeasonChange = (e, f) => {
		setSeasonID(e);
		setSelectedParameters({ ...selectedParameters, season: f.children });
	};
	const onGenderCategory = (e, f) => {
		setGenderCategoryID(e);
		setSelectedParameters({ ...selectedParameters, genderCategory: f.children });
	};
	const onStyleChange = (e, f) => {
		setStyle(e);
		setSelectedParameters({ ...selectedParameters, style: f.children });
	};
	const onWashNameChange = (e, f) => {
		setWashID(e);
		setSelectedParameters({ ...selectedParameters, washName: f.children });
	};
		const onFinish = (values) => {
		window.open(
			`${reportIP}birt/frameset?__report=${getReportName()}&__format=html&__svg=true&__locale=en_US&__timezone=PLT&__masterpage=true&__rtl=false&__cubememsize=10&__resourceFolder=C%3A%5CUsers%5Cadm%5Ceclipse%5Crcp-2021-09%5Ceclipse%5Cworkspace%5CERP-Reports&__parameterpage=false&-632080003&${`p_from_date=${date[0]}`}&${`p_to_date=${date[1]}`}&${
				washID === "All" ? "__isnull=p_wash_name_id" : `p_wash_name_id=${washID}`
			}&${styleID === "All" ? "__isnull=p_style_id" : `p_style_id=${styleID}`}&${sku === "" ? "__isnull=p_sku" : `p_sku=${sku}`}&${washTypeID === "All" ? "__isnull=p_wash_type_id" : `p_wash_type_id=${washTypeID}`}&
			${genderID === "All" ? "__isnull=p_season_id" : `p_season_id=${genderID}`}}${showGroup && `p_group_name=${groupName}`}&user_name=${userName}&selectedParameters=${`Selected Filters : Start Date : ${date[0]}, End Date : ${
				date[1]}, Customer : ${selectedParameters.customer ? selectedParameters.customer : "All"}, Sales Person : ${selectedParameters.spName ? selectedParameters.spName : "All"} Fit/Cut : ${
				selectedParameters.cut ? selectedParameters.cut : "All"
			}, Style : ${selectedParameters.style ? selectedParameters.style : "All"}, Wash Name : ${selectedParameters.washName ? selectedParameters.washName : "All"}, Wash Type : ${
				selectedParameters.washType ? selectedParameters.washType : "All"
			}, Fabric : ${selectedParameters.fabric ? selectedParameters.fabric : "All"},Gender : ${selectedParameters.gender ? selectedParameters.gender : "All"}, SKU : ${sku ? sku : "All"},`}`
		);
	};
	const clearFields = (event, type) => {
		event.preventDefault();
		if (type === "washName") {
			setWashID("All");
			setSelectedParameters({ ...selectedParameters, washName: "" });
		} else if (type === "style") {
			setStyle("All");
			setSelectedParameters({ ...selectedParameters, style: "" });
		} else if (type === "sku") {
			setSKU("");
		} else if (type === "washType") {
			setWashTypeID("All");
			setSelectedParameters({ ...selectedParameters, washType: "" });
		} else if (type === "gender") {
			setGenderID("All");
			setSelectedParameters({ ...selectedParameters, gender: "" });
		} else if (type === "genderCategory") {
			setGenderCategoryID("All");
			setSelectedParameters({ ...selectedParameters, genderCategory: "" });
		}
	};
	return (
		<>
			<div
				className="product-detail-main-wrapper"
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center"}}
			>
				<div className="product-detail-main" style={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}>
					<div className="pageHead ">
						<h4 className="prod-det-title-hd mb-0">Production Detail</h4>
						<div>
							<Button className="pos-right-10 iconBtn crudBTN" shape="circle" icon={<CloseCircleOutlined className="color-red" />} onClick={() => history.push("/")} />
						</div>
					</div>
					<div className="product-detail-content">
						<Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} layout="horizontal" form={form} name="control-hooks" onFinish={onFinish}>
							<Brow>
								<Bcol lg={8} xl={8} xxl={8}>
									<div className="date-filter">
										<h3 className="filter-title">Date Range</h3>
										<Form.Item name="date" label="Date">
											<RangePicker
												defaultValue={[moment(date[0]), moment(date[1])]}
												format="MM/DD/YYYY"
												onChange={(dates) => {
													let sDate = moment(dates[0]._d).format("MM/DD/YYYY");
													let eDate = moment(dates[1]._d).format("MM/DD/YYYY");
													setDate([sDate, eDate]);
												}}
											/>
										</Form.Item>
									</div>
									<div className="date-filter">
										<h3 className="filter-title">Filters</h3>
										<Form.Item name="Gender" label="Gender">
											<Select
												showSearch
												optionFilterProp="children"
												className="filter-fields"
												onChange={(e, f) => onGenderChange(e, f)}
												loading={gender?.loading}
												
												defaultValue="All"
												value={genderID}
											>
												{gender?.payload?.data.map((item) => {
													return (
														<Option defaultValue="All" key={item.gender_id} value={item.gender_id}>
															{item.gender_name}
														</Option>
													);
												})}
											</Select>
											<button className="auto-complete-text-btn" onClick={(e) => clearFields(e, "customer")}>
												CLEAR
											</button>
										</Form.Item>
										<Form.Item name="season" label="Current Season">
											<Select
												showSearch
												defaultValue={form.getFieldValue().sp}
												optionFilterProp="children"
												onChange={(e, f) => onSeasonChange(e, f)}
												className="filter-fields"
												
												loading={season?.loading}
												
												value={seasonID}
											>
												{season?.payload?.data.map((item) => {
													return (
														<Option defaultValue="All" key={item.season_id} value={item.season_id}>
															{item.season_name}
														</Option>
													);
												})}
											</Select>
											<button className="auto-complete-text-btn" onClick={(e) => clearFields(e, "salesPerson")}>
												CLEAR
											</button>
										</Form.Item>
										<Form.Item name="genderCat" label="Gender Category">
											<Select
												showSearch
												optionFilterProp="children"
												onChange={(e, f) => onGenderCategory(e, f)}
												className="filter-fields"
												
												loading={genderCategory?.loading}
												defaultValue="All"
												value={genderCategoryID}
											>
												{genderCategory?.payload?.data.map((item) => {
													return (
														<Option defaultValue="All" key={item.gender_category_id} value={item.gender_category_id}>
															{item.gender_category_name}
														</Option>
													);
												})}
											</Select>
											<button className="auto-complete-text-btn" onClick={(e) => clearFields(e, "washType")}>
												CLEAR
											</button>
										</Form.Item>
										<Form.Item name="style" label="Style">
											<Select
												showSearch
												optionFilterProp="children"
												onChange={(e, f) => onStyleChange(e, f)}
												className="filter-fields"
												
												loading={style?.loading}
												defaultValue="All"
												value={styleID}
											>
												{style?.payload?.data.map((item) => {
													return (
														<Option defaultValue="All" key={item.style_id} value={item.style_id}>
															{item.style_name}
														</Option>
													);
												})}
											</Select>
											<button className="auto-complete-text-btn" onClick={(e) => clearFields(e, "washName")}>
												CLEAR
											</button>
										</Form.Item>
										<Form.Item name="washName" label="Wash">
											<Select
												showSearch
												optionFilterProp="children"
												onChange={(e, f) => onWashNameChange(e, f)}
												className="filter-fields"
												
												loading={wash?.loading}
												defaultValue="All"
												value={styleID}
											>
												{wash?.payload?.data.map((item) => {
													return (
														<Option defaultValue="All" key={item.wash_id} value={item.wash_id}>
															{item.wash_name}
														</Option>
													);
												})}
											</Select>
											<button className="auto-complete-text-btn" onClick={(e) => clearFields(e, "washName")}>
												CLEAR
											</button>
										</Form.Item>
										<Form.Item name="sku" label="SKU">
											{/* <Input placeholder="Enter SKU" className="filter-fields" onChange={(e) => setSKU(e.target.value)} value={sku} /> */}
											<button className="auto-complete-text-btn" onClick={(e) => clearFields(e, "sku")}>
												CLEAR
											</button>
										</Form.Item>
									</div>
								</Bcol>
								<Bcol lg={4} xl={4} xxl={4}>
									<div className="date-filter">
										<h3 className="filter-title">Active</h3>
										<Form.Item name="group">
											<Radio.Group onChange={(e) => setShowGroup(e.target.value)} defaultValue={showGroup} style={{ width: 500 }}>
												<Radio value={true}>Yes</Radio>
												<Radio value={false}>No</Radio>
											</Radio.Group>
										</Form.Item>
									</div>
									<div>
										<Form.Item>
											<Button type="primary" htmlType="submit" className="w-100">
												Search
											</Button>
										</Form.Item>
									</div>
								</Bcol>
							</Brow>
						</Form>
					</div>
				</div>
			</div>
		</>
	);
};

export default ProductionDetailReport;
