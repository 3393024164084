import { GET_ORDERSTATUS_REQUEST, GET_ORDERSTATUS_SUCCESS, GET_ORDERSTATUS_ERROR, ADD_ORDERSTATUS_REQUEST, ADD_ORDERSTATUS_ERROR, ADD_ORDERSTATUS_SUCCESS, UPDATE_ORDERSTATUS_REQUEST, UPDATE_ORDERSTATUS_SUCCESS, UPDATE_ORDERSTATUS_ERROR, DELETE_ORDERSTATUS_ERROR, DELETE_ORDERSTATUS_REQUEST, DELETE_ORDERSTATUS_SUCCESS } from "./types"
import { BASEURL } from "../../constant/config.js";

const axios = require("axios");

export const getOrderStatus = (token, menu_id) => (dispatch) => {

    dispatch({ type: GET_ORDERSTATUS_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/orderStatus`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },

    }).then((response) => {
        return dispatch({ type: GET_ORDERSTATUS_SUCCESS, payload: response.data })
    }).catch((error) => {
        return dispatch({ type: GET_ORDERSTATUS_ERROR, payload: error.response })
    })
}

export const addOrderStatus = (token, data, menu_id) => (dispatch) => {
    dispatch({ type: ADD_ORDERSTATUS_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}create/orderStatus`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { status_name: data.orderStatusName, is_active: data.check },
    })
        .then((response) => {

            return dispatch({ type: ADD_ORDERSTATUS_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: ADD_ORDERSTATUS_ERROR,
                payload: error.response,
            });
        });
};

export const updateOrderStatus = (token, data, id) => (dispatch) => {
    dispatch({ type: UPDATE_ORDERSTATUS_REQUEST });
    return axios({
        method: "PUT",
        url: `${BASEURL}update/orderStatus/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { status_name: data.orderStatusName, is_active: data.check },
    })
        .then((response) => {

            return dispatch({ type: UPDATE_ORDERSTATUS_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: UPDATE_ORDERSTATUS_ERROR,
                payload: error.response,
            });
        });
};

export const deleteOrderStatus = (token, id) => (dispatch) => {
    dispatch({ type: DELETE_ORDERSTATUS_REQUEST });
    return axios({
        method: "DELETE",
        url: `${BASEURL}delete/orderStatus/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
    })
        .then((response) => {

            return dispatch({ type: DELETE_ORDERSTATUS_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: DELETE_ORDERSTATUS_ERROR,
                payload: error.response,
            });
        });
};
