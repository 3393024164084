import {
    GET_COMBINATION_FORM_REQUEST, GET_COMBINATION_FORM_SUCCESS, GET_COMBINATION_FORM_ERROR,
} from "../actions/types"
export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case GET_COMBINATION_FORM_REQUEST:
            return {
                loading: true,
            };
        case GET_COMBINATION_FORM_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_COMBINATION_FORM_ERROR:
            return {
                ...state,
                loading: false
            };
        default:
            return state;
    }
}