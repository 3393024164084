import {
    GET_ACCOUNT_TYPE_REQUEST, GET_ACCOUNT_TYPE_ERROR, GET_ACCOUNT_TYPE_SUCCESS, ADD_ACCOUNT_TYPE_ERROR, ADD_ACCOUNT_TYPE_REQUEST, ADD_ACCOUNT_TYPE_SUCCESS
    , UPDATE_ACCOUNT_TYPE_ERROR, UPDATE_ACCOUNT_TYPE_REQUEST, UPDATE_ACCOUNT_TYPE_SUCCESS, DELETE_ACCOUNT_TYPE_ERROR, DELETE_ACCOUNT_TYPE_REQUEST, DELETE_ACCOUNT_TYPE_SUCCESS
} from "./types";
import axios from "axios";
import { BASEURL } from "../../constant/config.js";

export const getAccountType = (token, id) => (dispatch) => {
    dispatch({ type: GET_ACCOUNT_TYPE_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/accountType`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { id }
    })
        .then((response) => {
            return dispatch({ type: GET_ACCOUNT_TYPE_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: GET_ACCOUNT_TYPE_ERROR,
                payload: error.response,
            });
        });
};



export const addAccountType = (token, data, id) => (dispatch) => {
    dispatch({ type: ADD_ACCOUNT_TYPE_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}create/accountType`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data,
    })
        .then((response) => {

            return dispatch({ type: ADD_ACCOUNT_TYPE_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: ADD_ACCOUNT_TYPE_ERROR,
                payload: error.response,
            });
        });
};



export const updateAccountType = (token, data, id) => (dispatch) => {
    dispatch({ type: UPDATE_ACCOUNT_TYPE_REQUEST });
    return axios({
        method: "PUT",
        url: `${BASEURL}update/accountType/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data,
    })
        .then((response) => {

            return dispatch({ type: UPDATE_ACCOUNT_TYPE_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: UPDATE_ACCOUNT_TYPE_ERROR,
                payload: error.response,
            });
        });
};


export const deleteAccountType = (token, id) => (dispatch) => {
    dispatch({ type: DELETE_ACCOUNT_TYPE_REQUEST });
    return axios({
        method: "DELETE",
        url: `${BASEURL}delete/accountType/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
    })
        .then((response) => {
            return dispatch({ type: DELETE_ACCOUNT_TYPE_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: DELETE_ACCOUNT_TYPE_ERROR,
                payload: error.response,
            });
        });
};
