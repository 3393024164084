import {
    CREATE_ITEM_INSEAM_REQUEST, CREATE_ITEM_INSEAM_SUCCESS, CREATE_ITEM_INSEAM_ERROR,
    CREATE_ITEM_SIZE_REQUEST, CREATE_ITEM_SIZE_SUCCESS, CREATE_ITEM_SIZE_ERROR,
    CREATE_COMBINATION_REQUEST, CREATE_COMBINATION_SUCCESS, CREATE_COMBINATION_ERROR,
    GET_COMBINATION_ERROR, GET_COMBINATION_REQUEST, GET_COMBINATION_SUCCESS
} from "./types"
import { BASEURL } from "../../constant/config.js"

const axios = require("axios");


export const createItemInseam = (token, id) => dispatch => {
    dispatch({ type: CREATE_ITEM_INSEAM_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}create/updateInseam`,
        headers: {
            Accept: "application/json",
            'Authorization': 'Bearer ' + token
        },
        data: { item_inseam_sno: id }
    })
        .then(response => {
            return dispatch({ type: CREATE_ITEM_INSEAM_SUCCESS, payload: response.data.data[0] });
        })
        .catch((error) => {
            return dispatch({
                type: CREATE_ITEM_INSEAM_ERROR,
                payload: error.response,
            });
        })
}
export const createSizeInseam = (token, data) => dispatch => {
    dispatch({ type: CREATE_ITEM_SIZE_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}create/updateSize`,
        headers: {
            Accept: "application/json",
            'Authorization': 'Bearer ' + token
        },
        data: { item_size_sno: data }
    })
        .then(response => {
            return dispatch({ type: CREATE_ITEM_SIZE_SUCCESS, payload: response.data.data[0] });
        })
        .catch((error) => {
            return dispatch({
                type: CREATE_ITEM_SIZE_ERROR,
                payload: error.response,
            });
        })
}
export const createCombination = (token, id) => dispatch => {
    dispatch({ type: CREATE_COMBINATION_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}create/generate`,
        headers: {
            Accept: "application/json",
            'Authorization': 'Bearer ' + token
        },
        data: { item_id: id }
    })
        .then(response => {
            return dispatch({ type: CREATE_COMBINATION_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: CREATE_COMBINATION_ERROR,
                payload: error.response,
            });
        })
}
export const getCombination = (token, id) => dispatch => {
    dispatch({ type: GET_COMBINATION_REQUEST });
    return axios({
        method: "GET",
        url: `${BASEURL}getOne/getProductCombination/${id}`,
        headers: {
            Accept: "application/json",
            'Authorization': 'Bearer ' + token
        },
    })
        .then(response => {
            return dispatch({ type: GET_COMBINATION_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: GET_COMBINATION_ERROR,
                payload: error.response,
            });
        })
}
