import {   GET_INVENTORY_VALUATION_REQUEST,
	GET_INVENTORY_VALUATION_SUCCESS,
	GET_INVENTORY_VALUATION_ERROR } from "../actions/types";

export default function (state = [], action) {
	const { type, payload } = action;
	switch (type) {
		case GET_INVENTORY_VALUATION_REQUEST:
			return {
				loading: true,
			};
		case GET_INVENTORY_VALUATION_SUCCESS:
			return {
				...state,
				loading: false,
				payload
						};
		case GET_INVENTORY_VALUATION_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};

		default:
			return state;
	}
}
