import {
  GET_EXPENSE_ACCOUTN_REQUEST,
  GET_EXPENSE_ACCOUTN_SUCCESS,
  GET_EXPENSE_ACCOUTN_ERROR,

} from "./types";
import { BASEURL } from "../../constant/config.js";

const axios = require("axios");


export const getExpenseAccount = (token) => (dispatch) => {
  dispatch({ type: GET_EXPENSE_ACCOUTN_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}getAll/BillDirectPay`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {
      return dispatch({ type: GET_EXPENSE_ACCOUTN_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      return dispatch({
        type: GET_EXPENSE_ACCOUTN_ERROR,
        payload: error.response,
      });
    });
};


