import {
  GET_PICK_TICKET_COLUMN_VALUE_REQUEST,
  GET_PICK_TICKET_COLUMN_VALUE_SUCCESS,
  GET_PICK_TICKET_COLUMN_VALUE_ERROR,
    } from "../actions/types";


    export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
      case GET_PICK_TICKET_COLUMN_VALUE_REQUEST:
        return {
          loading: true,
        };
      case GET_PICK_TICKET_COLUMN_VALUE_SUCCESS:
        return {
          ...state,
          loading: false,
          payload,
        };
      case GET_PICK_TICKET_COLUMN_VALUE_ERROR:
        return {
          ...state,
          loading: false,
          payload,
        };
  
      default: {
        return state;
      }
    }
  };