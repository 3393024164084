import { GET_SCM_TARGET_YEAR_REQUEST,GET_SCM_TARGET_YEAR_SUCCESS,GET_SCM_TARGET_YEAR_ERROR } from "../actions/types";



export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
      
        case GET_SCM_TARGET_YEAR_REQUEST:
            return {
                loading: true,
            };
        case GET_SCM_TARGET_YEAR_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_SCM_TARGET_YEAR_ERROR:
            return {
                ...state,
                loading: false
            };
            default:
                return state;
    }
  }
  