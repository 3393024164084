import {
    GET_INTERLOG_ERROR,
    GET_INTERLOG_REQUEST,
    GET_INTERLOG_SUCCESS
} from "./types";

import { BASEURL } from "../../constant/config.js";

const axios = require("axios");

export const getInterLog = (token, values) => (dispatch) => {
    dispatch({ type: GET_INTERLOG_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/InterLog`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: values
    })
        .then((response) => {
            return dispatch({ type: GET_INTERLOG_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: GET_INTERLOG_ERROR,
                payload: error.response,
            });
        });
};

