import {
    GET_PAYMENT_TYPE_ERROR, GET_PAYMENT_TYPE_SUCCESS, GET_PAYMENT_TYPE_REQUEST,
    ADD_PAYMENT_TYPE_REQUEST, ADD_PAYMENT_TYPE_SUCCESS, ADD_PAYMENT_TYPE_ERROR,
    UPDATE_PAYMENT_TYPE_REQUEST, UPDATE_PAYMENT_TYPE_SUCCESS, UPDATE_PAYMENT_TYPE_ERROR,
    DELETE_PAYMENT_TYPE_SUCCESS, DELETE_PAYMENT_TYPE_REQUEST, DELETE_PAYMENT_TYPE_ERROR,
} from "../actions/types"

export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case GET_PAYMENT_TYPE_REQUEST:
            return {
                loading: true,
            };
        case GET_PAYMENT_TYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_PAYMENT_TYPE_ERROR:
            return {
                ...state,
                loading: false
            };
        case ADD_PAYMENT_TYPE_REQUEST:
            return {
                loading: true,
            };
        case ADD_PAYMENT_TYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case ADD_PAYMENT_TYPE_ERROR:
            return {
                ...state,
                loading: false
            };
        case DELETE_PAYMENT_TYPE_REQUEST:
            return {
                loading: true,
            };
        case DELETE_PAYMENT_TYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case DELETE_PAYMENT_TYPE_ERROR:
            return {
                ...state,
                loading: false
            };
        case UPDATE_PAYMENT_TYPE_REQUEST:
            return {
                loading: true,
            };
        case UPDATE_PAYMENT_TYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case UPDATE_PAYMENT_TYPE_ERROR:
            return {
                ...state,
                loading: false
            };

        default:
            return state;
    }
}
