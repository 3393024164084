import {
    GET_VENDOR_LEDGER_REQUEST,
    GET_VENDOR_LEDGER_SUCCESS,
    GET_VENDOR_LEDGER_ERROR
} from "../actions/types"
export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case GET_VENDOR_LEDGER_REQUEST:
            return {
                loading: true,
            };
        case GET_VENDOR_LEDGER_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_VENDOR_LEDGER_ERROR:
            return {
                ...state,
                loading: false
            };
        default:
            return state;
    }
}