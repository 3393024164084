import {
	
	UPDATE_TRANSFER_RATE_ERROR,
	UPDATE_TRANSFER_RATE_REQUEST,
	UPDATE_TRANSFER_RATE_SUCCESS,

	


} from "./types.js";
import { BASEURL } from "../../constant/config.js";
const axios = require("axios");

export const updateTransferRate = (token, BillId) => (dispatch) => {
	dispatch({ type: UPDATE_TRANSFER_RATE_REQUEST });
	return axios({
		method: "post",
		url: `${BASEURL}create/billTransferRateUpdate`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: {
			bill_header_id: BillId,
		},
	})
		.then((response) => {
			return dispatch({
				type: UPDATE_TRANSFER_RATE_SUCCESS,
				payload: response?.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: UPDATE_TRANSFER_RATE_ERROR,
				payload: error.response,
			});
		});
};

