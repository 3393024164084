import {
	GET_WASH_REQUEST,
	GET_WASH_SUCCESS,
	GET_WASH_ERROR,
	ADD_WASH_REQUEST,
	ADD_WASH_SUCCESS,
	ADD_WASH_ERROR,
	UPDATE_WASH_REQUEST,
	UPDATE_WASH_SUCCESS,
	UPDATE_WASH_ERROR,
	DELETE_WASH_REQUEST,
	DELETE_WASH_SUCCESS,
	DELETE_WASH_ERROR,
} from "../actions/types";

export default function (state = [], action) {
	const { type, payload } = action;

	switch (type) {
		case GET_WASH_REQUEST:
			return {
				loading: true,
			};
		case GET_WASH_SUCCESS:
			return {
				...state,
				loading: false,
				payload,
			};

		case GET_WASH_ERROR:
			return {
				...state,
				payload,
				loading: false,
			};

		case ADD_WASH_REQUEST:
			return {
				loading: true,
			};
		case ADD_WASH_SUCCESS:
			return {
				...state,
				loading: false,
				payload: [payload],
			};
		case ADD_WASH_ERROR:
			return {
				...state,
				loading: false,
			};
		case DELETE_WASH_REQUEST:
			return {
				loading: true,
			};
		case DELETE_WASH_SUCCESS:
			return {
				...state,
				loading: false,
				payload: [payload],
			};
		case DELETE_WASH_ERROR:
			return {
				...state,
				loading: false,
			};
		case UPDATE_WASH_REQUEST:
			return {
				loading: true,
			};
		case UPDATE_WASH_SUCCESS:
			return {
				...state,
				loading: false,
				payload: [payload],
			};
		case UPDATE_WASH_ERROR:
			return {
				...state,
				loading: false,
			};

		default:
			return state;
	}
}
