import { useState, useEffect, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import SkeletonLoad from "../../components/Skeleton/SkeletonLoad";
import PageHeader from "../../components/PageHeader/PageHeader";

import Tables from "../../components/Table/Tables";
import { payCustomerPaymentCols } from "../../utils/cols";
import moment from "moment";
import { dateChecker } from "../../components/CheckDate/checkDate";
import "../../screens/Product/product.css";
import "../Style/style.css";
import { useHistory } from "react-router-dom";
import { CaretRightOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, Select, DatePicker, Collapse, Row, Col } from "antd";
import { CSVLink } from "react-csv";
import { getAdminPaymentTypes } from "../../redux/actions/adminPaymentType.action";
import { getRights } from "../../redux/actions/userRights.action";
import jsPDF from "jspdf";
import pdf from "../../assets/images/PDF_file_icon.svg.png";

export default function PaymentHeaderList({ token }) {
	const [form] = Form.useForm();
	const [dateOP, setDateOP] = useState("");
	const [userRights, setUserRights] = useState({})
	const history = useHistory();
	const dispatch = useDispatch();
	const { Option } = Select;
	const dateFormat = "MM-DD-YYYY";
	const { payCustomerPaymentHeaderList, adminPaymentType } = useSelector((state) => state);
	const [menuId, setMenuId] = useState(408);

	const worker = {
		customer: null,
		from_date: moment().startOf('year'),
		to_date: moment().endOf('year'),
		paymentNo: null,
		postingPeriod: null,
		arAccount: null,
		debitAccount: null,
		paymentTerm: null,
		paymentType: null,
		paymentMethod: null,
		genderCategory: null,
	};

	const { Panel } = Collapse;
	const onFinishFilter = async (e) => {
		await form.validateFields().then((values) => {
			dateChecker(values.from_date, values.to_date, "paymentHeaderList", token, values, dispatch);

		})
	};

	function downloadExcelFileData(data) {
		if (data) {
			return data.map((v) => {
				return {
					"Payment Header No": v.payment_header_no,
					"Payment Date": moment(v.payment_date).format("MM-DD-YYYY"),
					"Customer": v.customer_name,
					"Posting Period": v.period_name,
					"Debit Account": v.debit_account_name,
					"AR Account": v.credit_account_name,
					"Payment Amount": v.applied,
				};
			})
		}
	}

	useEffect(() => {
		dispatch(getAdminPaymentTypes(token))
	}, [])



	useLayoutEffect(() => {
		dispatch(getRights(token, menuId)).then((res) => {
			if (res.type === "GET_USER_RIGHTS_SUCCESS") {
				setUserRights(res?.payload)
			}
		});
	}, [token])

	function generatePDF() {
		var doc = new jsPDF();

		var columns = ["Payment Header No ", "Payment Date", "Customer", "Payment Amount", "Payment Term",];
		var rows = [];

		payCustomerPaymentHeaderList?.payload?.data.forEach(item => {
			var row = [item.payment_header_no, moment(item.payment_date).format("MM-DD-YYYY"), item.customer_name, "$" + parseFloat(item.applied).toFixed(2), item.term_name];
			rows.push(row);
		});

		const amount = payCustomerPaymentHeaderList?.payload?.data.reduce((acc, item, index) => acc + parseFloat(item.applied), 0)


		rows.push([" ", " ", "Total: ", "$" + amount.toLocaleString("en-US"), " "])

		doc.autoTable({
			head: [columns],
			body: rows,
			styles: { fontSize: 7, align: 'center' },
		})
		doc.save("Customer-Payment.pdf");
	}



	return (
        <div className="styleContainer">
			<div className="styleBody h-100">
				<PageHeader title={"Customer Payment List"} />
				<div className="bodyStyle">
					<Collapse style={{ width: "99%", margin: "10px" }} bordered={false} defaultActiveKey={["1"]} expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}>
						<Panel
							header="Filters"
							key="1"
							className="site-collapse-custom-panel"
							extra={
								<Button
									disabled={userRights.can_add === true ? false : true}
									onClick={() => {
										history.push("/customer-payment/add?mode=add");
									}}
									className="iconBtn crudBTN"
									shape="circle"
									icon={<PlusOutlined />}
								/>
							}
						>
							<Form
								form={form}
								labelCol={{ span: 8 }}
								wrapperCol={{ span: 16 }}
								initialValues={worker}
								onFinish={onFinishFilter}
								autoComplete="off">
								<Row>
									<Col xs={24} xl={8}>
										<Form.Item name="paymentNo" label="Payment NO#" rules={[{ pattern: new RegExp(/^(?!0*[.,]0*$|[.,]0*$|0*$)\d+[,.]?\d{0,6}$/), message: "Must Be a Number." }]}>
											<Input
												allowClear
												size="small"
											/>
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item name="customer" label="Customer Name" >
											<Input
												allowClear
												size="small"
											/>
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item label="From Date" name="from_date"
										>
											<DatePicker
												size="small"
												id="fromDate"
												defaultValue={form.getFieldValue().fromDate} format={dateFormat} className="dateInput customInputs" />
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item label="To Date" name="to_date"
										>
											<DatePicker
												size="small"
												id="toDate"
												defaultPickerValue={form.getFieldValue().toDate} format={dateFormat} className="dateInput ustomInputs" />
										</Form.Item>
									</Col>

									<Col xs={24} xl={8}>
										<Form.Item name="postingPeriod" label="Posting Period">
											<Input
												allowClear
												size="small"
											/>
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item name="arAccount" label="A/R Account" >
											<Input
												allowClear
												size="small"
											/>
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item name="debitAccount" label="Debit Account">
											<Input
												allowClear
												size="small"
											/>
										</Form.Item>
									</Col>

									<Col xs={24} xl={8}>
										<Form.Item name="paymentTerm" label="Payment Term" >
											<Input
												allowClear
												size="small"
											/>
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										{/* <Form.Item name="paymentType" label="Payment Type">
											<Input
												allowClear
												size="small"
											/>
										</Form.Item> */}
										<Form.Item name="paymentType" label="Payment Type">
											<Select
												showSearch
												allowClear
												size="small"
												className="styleInput"
												loading={adminPaymentType.loading}
												optionFilterProp="children"
												filterSort={(optionA, optionB) =>
													optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
												}
											>
												{adminPaymentType?.payload?.data?.map((acc) => {
													return <Option key={acc.payment_type_id} value={acc.payment_type_id}>{acc.payment_type_name}</Option>;
												})}
											</Select>
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item name="paymentMethod" label="Payment Method">
											<Input
												allowClear
												size="small"
											/>
										</Form.Item>
									</Col>

									<Col xs={24} xl={8}>
										<Form.Item name="genderCategory" label="Gender Category">
											<Input
												allowClear
												size="small"
											/>
										</Form.Item>
									</Col>
								</Row>
								<div style={{ display: "flex", justifyContent: "flex-end" }}>
									<Button type="primary" htmlType="submit" disabled={payCustomerPaymentHeaderList.loading}>
										Search
									</Button>
								</div>
							</Form>
						</Panel>
					</Collapse>
					{payCustomerPaymentHeaderList.loading ? (
						<div className="mainProductContainer">
							<SkeletonLoad />
						</div>
					) : (
						<div className="mainProductContainer">
							<div style={{ padding: 10 }}>
								{
									payCustomerPaymentHeaderList?.payload?.data && (
										<>
											<div style={{ display: "flex", justifyContent: "left", marginBottom: "5px" }}>
												<div className="print_icon printing_class" >
													<CSVLink
														filename={"Customer-Payment.csv"}
														data={downloadExcelFileData(payCustomerPaymentHeaderList?.payload?.data) || []}
														onClick={() => {
														}}
													>
														Download Excel
													</CSVLink>
												</div>
												<div className="print_icon printing_class" >
													<input
														type="image"
														name="ImgPDF"
														alt="Export to PDF"
														id="ImgPDF"
														title="Export to PDF"
														src={pdf}
														onClick={generatePDF}
														
														style={{ borderWidth: "0px", height: "24px", float: "inline-end", margin: "0px 0px 0px 12px", }}
													/>
												</div>
											</div>
										</>
									)
								}
								<Tables
									loadHeight={40}
									loadWidth={40}
									type={"customer"}
									
									
									scroll={570}
									perPage={25}
									scrollX={"80rem"}
									col={payCustomerPaymentCols}
									source={payCustomerPaymentHeaderList?.payload?.data}
									load={payCustomerPaymentHeaderList.loading}
									paginate
								/>
								{/* <span className="records-text">
                      {dataLen === 0 ? <>No of Records:  <span className="fw-500">  0</span> </> : < > No of Records: <span className="fw-500"> {show ? dataLen : dataLen ? dataLen : "0"}</span>  </>}
                    </span> */}
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
    );
}
