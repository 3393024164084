import {
    GET_FACTOR_REQUEST,GET_FACTOR_ERROR,GET_FACTOR_SUCCESS,   
ADD_FACTOR_REQUEST,ADD_FACTOR_ERROR,ADD_FACTOR_SUCCESS,
UPDATE_FACTOR_REQUEST,UPDATE_FACTOR_ERROR,UPDATE_FACTOR_SUCCESS,
DELETE_FACTOR_REQUEST,DELETE_FACTOR_ERROR,DELETE_FACTOR_SUCCESS
} from "../actions/types"
export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case GET_FACTOR_REQUEST:
            return {
                loading: true,
            };
        case GET_FACTOR_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_FACTOR_ERROR:
            return {
                ...state,
                loading: false
            };
        case ADD_FACTOR_REQUEST:
            return {
                loading: true,
            };
        case ADD_FACTOR_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case ADD_FACTOR_ERROR:
            return {
                ...state,
                loading: false
            };
        case DELETE_FACTOR_REQUEST:
            return {
                loading: true,
            };
        case DELETE_FACTOR_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case DELETE_FACTOR_ERROR:
            return {
                ...state,
                loading: false
            };
        case UPDATE_FACTOR_REQUEST:
            return {
                loading: true,
            };
        case UPDATE_FACTOR_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case UPDATE_FACTOR_ERROR:
            return {
                ...state,
                loading: false
            };

        default:
            return state;
    }
}