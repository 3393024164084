import React, { useState, useLayoutEffect } from "react";
import logo from "../../assets/images/logo.png";
import btnPrint from "../../assets/images/button_print.gif";
import xlsx from "../../assets/images/ico_xls_sm.gif";
import "../OMSBooking/styleSheet.css";
import { Select, DatePicker, Form, Table, Spin, Button } from "antd";
import { ReadCookie } from "../../utils/readCookie";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { useEffect } from "react";
import { getRegion } from "../../redux/actions/region.action";
import { getPaymentTerm } from "../../redux/actions/paymentterm.action";
import { getGender } from "../../redux/actions/gender.action";
import { getOmsReport } from "../../redux/actions/omsReport.action";
import { getAllCompany } from "../../redux/actions/companyAccess";
import { Link, useParams, useLocation } from "react-router-dom";
import { getOmsRegionStateReport } from "../../redux/actions/omsRegionReport.action";
import { getOmsReturnReportByCustomer } from "../../redux/actions/omsReturnByCustomerRegionState.action";
import numeral from "numeral";
import { getReturnOmsStateReport } from "../../redux/actions/omsReturnRegionStateReport.action";
import { getOmsReturnRegionStateReport } from "../../redux/actions/omsReturnRegionReport.action";

const OmsRegionReport = ({ token, history, id, formData, propsData }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const paramsData = useParams();
  const [data, setData] = useState([]);
  const {
    omsReturnRegionStateReport,
    gender,
    region, getSaleOmsReturnCustomer,
    companyAll,
    omsReturnRegionReport,
    getReturnRegionStateCustomer,
    customerTypes
  } = useSelector((state) => state);
  const [submit, setSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState(null);
  const [regionID, setRegionID] = useState(null);

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }


  let query = useQuery();
  let company_id = query.get("company_id");
  
  let gender_id = query.get("gender_id");
  let customer_type_id = query.get("customer_type_id");
  let from_date = query.get("from_date");
  let to_date = query.get("to_date");
  let region_id = query.get("region_id");
  let selected_id = query.get("selected_id");
  let select = query.get("select");
  let state_id = query.get("state_id");
  let state_name = query.get("state_name");
  let region_name = query.get("region_name");
  
  
  
  
  
  useLayoutEffect(() => {
    setValues(history?.location?.values);
    setRegionID(history?.location?.state?.region_id);
    setLoading(true);
    
    
    
    
    dispatch(getOmsReturnRegionStateReport(token, id, {
      from_date,
      to_date,
      gender_id,
      selected_id,
      customer_type_id,
      company_id,
      state_id,
      select
    })
    ).then((res) => {
      setData(res?.payload?.data)
      setSubmit(true);
      setLoading(false);
    });
  }, []);

  const columns = [
    {
      title: "S.No",
      dataIndex: "serial_no",
      align: "left",
      key: "serial_no",
      width: 50,
      render: (text, object, index) => index + 1,
    },
    {
      title: "Customer Name",
      dataIndex: "customer_name",
      key: "customer_id",
      render: (record, text) => {
        return (
          <Link
            style={{ color: "blue", textDecoration: "underline" }}
            to={{
              pathname: `/return-region-wise`,
              search: `?submited=${true}&report=${4}&company_id=${company_id}&gender_id=${gender_id}&region_id=${region_id}&region_name=${region_name}&customer_type_id=${customer_type_id}&from_date=${
                from_date}&to_date=${to_date
              }&select=${select === "true" ? true : false
                }&selected_id=${selected_id}&customer_id=${text.customer_id
                }&customer_name=${text.customer_name}&state_name=${text.state_name
                }&state_id=${state_id}`,
              state: { ...text, region_id: regionID },
              values,
            }}
          >
            {" "}
            {record}
          </Link>
        );
      },
    },
    {
      title: "State",
      dataIndex: "state_name",
      align: "left",
      key: "state_id",
      width: 70,
    },

    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      width: 100,
      align: "right",
      render: (text) => {
        return (
          <span style={{ textAlign: "right" }}> {numeral(text).format('0,0')}</span>
        )
      },
    },
    {
      title: "Amount",
      dataIndex: "net_amount",
      key: "net_amount",
      width: 130,
      align: "right",
      render: (text) => <span style={{ textAlign: "right" }}> $ {numeral(text).format("0,0.00")}</span>,
    },
    {
      title: "AUC",
      dataIndex: "auc",
      key: "auc",
      width: 120,
      align: "right",
      render: (text) => <span style={{ textAlign: "right" }}>${parseFloat(text).toFixed(2)}</span>,
    },
    {
      title: "%",
      align: "right",
      dataIndex: "percentage",
      key: "percentage",
      width: 70,
    },
  ];

  const filterGender = gender.payload?.data.find((item) => {
    return item.gender_id === Number(gender_id);
  });

  const filterRegion = region.payload?.data.find((item) => {
    return item.region_id === Number(region_id);
  });

  const filterCustomerType = customerTypes.payload?.data.find((item) => {
    return item.customer_type_id === Number(customer_type_id);
})

  const totalSum = () => {
    let sumAmount = data.reduce(function (previousValue, currentValue) {
      return previousValue += Number(currentValue.net_amount.replace(/\$|,/g, ''));
    }, 0);
    return sumAmount;
  }

  const totalQuantity = () => {
    let sumQty = data.reduce(function (previousValue, currentValue) {
      return previousValue += Number(currentValue.quantity.replace(/\$|,/g, ''));
    }, 0);
    return sumQty;
  }


  return (
    <>
      <div className="row report-wrapper-main">
        <div className="col p-0">
          <table
            id="Table1"
            cellPadding="3"
            cellSpacing="0"
            align="center"
            className="tablestyleControls"
          >
            <tbody>
              <tr>
                <td width="103">Featuring:</td>
                <td>
                  {gender_id === "All"
                    ? "All"
                    : filterGender && filterGender.gender_name}
                </td>
                <td width="103">State:</td>
                <td>{select === "true" ? "All" : state_name}</td>
              </tr>
              <tr>
                <td>Customer Type:</td>
                {customer_type_id === "All" ? "All" : filterCustomerType && filterCustomerType.customer_type_name}
                <td style={{ width: "20px" }} className="no-border-btm">
                  Region:
                </td>
                <td>
                  {selected_id === "All"
                    ? "All"
                    : region_name && region_name}
                </td>
              </tr>
              <tr>
                <td>From:</td>
                <td>{from_date}</td>
                <td style={{ width: "60px" }} className="no-border-btm">
                  To:
                </td>
                <td>{to_date}</td>
              </tr>
              <tr>
                {/* <td style={{ width: "80px" }}>
                                    Show Orders By:
                                </td>
                                <td>
                                    {date_type === "B" ? "Booking Date" : "Start Date"}
                                </td> */}
                <td className="no-border-btm">Brand :</td>
                <td>{company_id === "2" ? "DL1961 : DL1961(USA) " : ""}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {loading ? (
        <div className="w-100 d-flex align-items-center justify-content-center spin-style mt-3">
          <Spin />
        </div>
      ) : (
        <>
          {submit ? (
            <div className="report-wrapper-main mt-3">
              <Table
                showSorterTooltip={false}
                
                className="w-100 test04"
                size="small"
                
                pagination={{ pageSize: 1000 }}
                summary={(pageData) => {
                  let sumQty = pageData.reduce(function (
                    previousValue,
                    currentValue
                  ) {
                    return (previousValue += parseFloat(
                      currentValue.quantity
                    ));
                  },
                    0);
                  let sumAmount = pageData.reduce(function (
                    previousValue,
                    currentValue
                  ) {
                    return (previousValue += parseFloat(
                      currentValue.net_amount
                    ));
                  },
                    0);
                  return (
                    <>
                      <Table.Summary.Row>
                        <Table.Summary.Cell colSpan={2}>Total Quantity : {numeral(totalQuantity()).format("0,00,0")}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <p
                            style={{ paddingTop: "10px", fontWeight: "bolder" }}
                          >
                            Total
                          </p>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <p
                            style={{
                              fontWeight: "bolder",
                              textAlign: "right",
                              width: "100%",
                              borderTop: "1px solid black",
                              borderBottom: "1px solid black",
                            }}
                          >
                            {numeral(sumQty).format("0,0")}
                          </p>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <p
                            style={{
                              fontWeight: "bolder",
                              textAlign: "right",
                              width: "100%",
                              borderTop: "1px solid black",
                              borderBottom: "1px solid black",
                            }}
                          >
                            $ {numeral(sumAmount).format("0,0.00")}
                          </p>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                      <Table.Summary.Row>
                        <Table.Summary.Cell colSpan={2} style={{ paddingTop: "10px", fontWeight: "bolder" }}>Total Amount : ${numeral(totalSum()).format("0,0.00")}
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </>
                  );
                }}
                bordered
                columns={columns}
                dataSource={
                  omsReturnRegionStateReport &&
                  omsReturnRegionStateReport?.payload?.data
                }
              />
            </div>
          ) : (
            <table
              className="tablestyle"
              cellSpacing="0"
              cellPadding="2"
              align="Center"
              rules="all"
              border="1"
              id="GridView1"
              style={{
                fontSize: "8pt",
                width: "100%",
                borderCollapse: "collapse",
                marginTop: "20px",
              }}
            >
              <tbody>
                <tr
                  style={{
                    backgroundColor: "#CCCCCC",
                    borderWidth: "1pt",
                    borderStyle: "Dashed",
                    fontWeight: "bold",
                  }}
                >
                  <td colSpan="5">No record(s)</td>
                </tr>
              </tbody>
            </table>
          )}
        </>
      )}
    </>
  );
};
export default OmsRegionReport;
