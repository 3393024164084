import { GETALL_PAY_CUSTOMER_PAYMENT_HEADER_SUCCESS, GETALL_PAY_CUSTOMER_PAYMENT_HEADER_REQUEST, GETALL_PAY_CUSTOMER_PAYMENT_HEADER_ERROR } from "../actions/types";
export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case GETALL_PAY_CUSTOMER_PAYMENT_HEADER_REQUEST:
            return {
                loading: true,
            };
        case GETALL_PAY_CUSTOMER_PAYMENT_HEADER_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: payload,
            };
        case GETALL_PAY_CUSTOMER_PAYMENT_HEADER_ERROR:
            return {
                ...state,
                loading: false,
            };

        default:
            return state;
    }
}
