import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getTermForPrint, addTermForPrint, updateTermForPrint, deleteTermForPrint } from "../../redux/actions/termForPrint.action";
import { getPaymentTerm } from '../../redux/actions/paymentterm.action';
import { getOneDefaultCompany } from "../../redux/actions/defaultCompany";
import { getLog } from "../../redux/actions/log.action";
import { getRights } from "../../redux/actions/userRights.action";
import PageHeader from "../../components/PageHeader/PageHeader";
import CrudButtons from "../../components/CrudButtons/CrudButtons";
import Tables from "../../components/Table/Tables";
import { ReadCookie } from "../../utils/readCookie";
import { Button, Row, Col, Form, Input, Checkbox, Tabs, Divider, Select } from "antd";
import { logColumns, termForPrintColumns } from "../../utils/cols";
import notification from "../../utils/notification";
import "../Style/style.css";
import { TabTitle } from "../../utils/GeneralFunction";

const { TabPane } = Tabs;

const { Option } = Select;

export default function TermForPrint({ token }) {
  TabTitle("Term For Print")
  const menuId = ReadCookie("menuId");
  const dCompanyId = ReadCookie("defaultCompany");
  const termForPrintNameInput = useRef(null);
  const [form] = Form.useForm();
  const [termForPrintObj, setTermForPrintObj] = useState(null);
  const dispatch = useDispatch();
  const { termForPrint, log, userRights, paymentterm } = useSelector((state) => state);
  const [minimize, setMinimize] = useState(false);
  const [check, setCheck] = useState(false);
  const [termForPrintName, setTermForPrintName] = useState("");
  const [disable, setDisable] = useState(false);
  const [formState, setFormState] = useState("view");
  const [term_id, setTermid] = useState()

  useEffect(() => {
    dispatch(getTermForPrint(token, menuId));
    dispatch(getLog(token, { scm_term_for_print: "scm_term_for_print" }));
  }, [token]);

  useEffect(() => {
    dispatch(getPaymentTerm(token, menuId))
  }, [dispatch, token])

  

  
  
  
  
  
  
  

  useLayoutEffect(() => {
    dispatch(getRights(token, menuId)).then((res) => {
      if (res.type === "GET_USER_RIGHTS_SUCCESS") {
        if (!res?.payload.can_add) {
          setFormState("table");
        }
      }
    });
  }, []);

  useEffect(() => {
    if (termForPrintObj) {

      setFormState("view");
      form.setFieldsValue({
        id: termForPrintObj.term_id,
        termForPrint: termForPrintObj.term_name,
        scmtermID: termForPrintObj.scm_term_id,
        active: termForPrintObj.is_active,
      });
      setTermForPrintName(termForPrintObj.term_name);
      setCheck(termForPrintObj.is_active);
    }
  }, [termForPrintObj]);

  useEffect(() => {
    if (termForPrintName === "") {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [termForPrintName]);


  const formSave = () => {
    if (formState === "add") {
      let menu = parseInt(menuId)

      dispatch(addTermForPrint(token, { termForPrintName, check, menu, term_id })).then(
        (res) => {
          if (res.type === "ADD_TERM_FOR_PRINT_ERROR") {
            notification(
              "error",
              res?.payload?.request?.statusText,
              res?.payload?.data?.data
            );
          }
          if (res.type === "ADD_TERM_FOR_PRINT_SUCCESS") {
            notification(
              "success",
              "Term For Print Created",
              "Term For Print created successfully!"
            );
            form.setFieldsValue({
              termForPrint: "",
            });
            setFormState("");
            
            setTermForPrintName("");
            setTermForPrintObj(null);
          }
          dispatch(getTermForPrint(token, menuId));
        }
      );
    }

    if (formState === "edit") {
      dispatch(
        updateTermForPrint(
          token,
          { termForPrintName, check, menuId, term_id },
          termForPrintObj.term_id
        )
      ).then((res) => {
        if (res.type === "UPDATE_TERM_FOR_PRINT_ERROR") {
          notification("error", "Update", "Something went wrong");
        }
        if (res.type === "UPDATE_TERM_FOR_PRINT_SUCCESS") {
          notification(
            "success",
            "Term For Print Updated",
            "Record updated successfully"
          );
        }
        dispatch(getTermForPrint(token, menuId));
      });
    }
  };

  const handleDelete = () => {
    if (termForPrintObj) {
      dispatch(deleteTermForPrint(token, termForPrintObj.term_id)).then(
        (res) => {
          if (res.type === "DELETE_TERM_FOR_PRINT_SUCCESS") {
            notification(
              "success",
              "Term For Print Deleted",
              "Term For Print deleted successfully!"
            );
          }
          if (res.type === "DELETE_TERM_FOR_PRINT_ERROR") {
            notification(
              "error",
              res?.payload?.request?.statusText,
              res?.payload?.data?.data
            );
          }
          form.setFieldsValue({
            termForPrint: "",
          });
          
          setTermForPrintName("");
          setTermForPrintObj(null);
          setFormState("");
          dispatch(getTermForPrint(token, menuId));
        }
      );
    }
  };

  useEffect(() => {
    if (formState === "add") {
      dispatch(getOneDefaultCompany(token, dCompanyId));
      termForPrintNameInput.current.focus();
      form.setFieldsValue({
        termForPrint: "",
      });
      setCheck(true);
      setTermForPrintName("");
      setTermForPrintObj(null);
    }
  }, [formState]);

  const filterReset = () => {
    setFormState("view");
  };

  return (
    <div className="styleContainer">
      <div className="styleBody h-100">
        <PageHeader
          title={"Term For Print"}
          minimize={setMinimize}
          check={minimize}
        />
        {!minimize && (
          <div className="bodyStyle">
            <div className="crudDiv">
              <CrudButtons
                rights={userRights?.payload}
                reset={filterReset}
                formType={formState}
                save={formSave}
                disable={disable}
                formState={setFormState}
                onDelete={handleDelete}
              />
            </div>

            <div className="styleContent">
              <Row className="tables-main-row-new">
                <Col xs={24} sm={24} md={10} lg={8} xl={6}>
                  <div className="styleTable">
                    <Tables
                      type="termForPrint"
                      termForPrintObj={setTermForPrintObj}
                      scrollX={"20rem"}
                      scroll={602}
                      perPage={25}
                      source={termForPrint?.payload?.data}
                      col={termForPrintColumns}
                      paginate
                      load={termForPrint.loading}
                    />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={14} lg={16} xl={18}>
                  <div className="styleForm">
                    <Form form={form} name="basic" labelCol={{ span: 8 }}>
                      <div className="customRow styleFormUpper ">
                        {termForPrintObj !== null && !(formState === "add") && (
                          <div className="customCols slc">
                            <Form.Item
                              label="ID"
                              labelCol={{ span: 10 }}
                              wrapperCol={{ span: 14 }}
                              name="id"
                              initialValue={form.getFieldValue().id}
                            >
                              <Input
                                className="styleInput id-field-style"
                                readOnly
                              />
                            </Form.Item>
                          </div>
                        )}
                        <div className="customCols slc">
                          <Form.Item
                            label="Term For Print"
                            name="termForPrint"
                            labelCol={{ span: 10 }}
                            wrapperCol={{ span: 14 }}
                            initialValue={form.getFieldValue().termForPrint}
                          >
                            <Input
                              size="small"
                              disabled={
                                !(formState === "add" || formState === "edit")
                              }
                              ref={termForPrintNameInput}
                              onChange={(e) =>
                                setTermForPrintName(e.target.value)
                              }
                              className="styleInput"
                            />
                          </Form.Item>
                          <Form.Item
                            name="scmtermID"
                            label="SCM Term ID"
                            labelCol={{ span: 10 }}
                            wrapperCol={{ span: 14 }}
                          >
                            <Select
                              size="small"
                              autoComplete={"nope"}
                              allowClear
                              showSearch
                              optionFilterProp="children"
                              className="filter-fields"
                              loading={paymentterm?.loading}
                              disabled={
                                !(formState === "add" || formState === "edit")
                              }
                              defaultValue={form.getFieldValue().scmtermID}
                              filterSort={(optionA, optionB) =>
                                  optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                            }
                              
                              onChange={(e) => setTermid(e)}
                            >
                              {paymentterm?.payload?.data.map(
                                (item) => {
                                  return (
                                    <Option
                                      key={item.term_id
                                      }
                                      disabled={!item.is_active}
                                      value={item.term_id}
                                    >
                                      {item.term_name}
                                    </Option>
                                  );
                                }
                              )}
                            </Select>
                          </Form.Item>
                        </div>
                        <div className="customCols slc">
                          <Form.Item
                            label="Active"
                            name="active"
                            labelCol={{ span: 10 }}
                            wrapperCol={{ span: 14 }}
                          >
                            <Checkbox
                              disabled={
                                !(formState === "add" || formState === "edit")
                              }
                              checked={check}
                              onChange={(e) => setCheck(e.target.checked)}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Form>

                    <Divider />
                    <div className="styleTab">
                      <Tabs
                        type="card"
                        tabBarGutter={2}
                        defaultActiveKey="1"
                        centered
                      >
                        <TabPane tab="Log Info" key="1">
                          <div className="tabs">
                            <Tables
                              type="company"
                              scrollX={"50rem"}
                              scroll={360}
                              perPage={15}
                              source={log?.payload?.data}
                              col={logColumns}
                              load={log.loading}
                              paginate
                            />
                          </div>
                        </TabPane>
                      </Tabs>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        )}
      </div>
      {minimize && (
        <div className="styleFooter">
          <Button
            className="minimize-button-style"
            onClick={() => setMinimize(false)}
          >
            Style
          </Button>
        </div>
      )}
    </div>
  );
}
