import PageHeader from '../../components/PageHeader/PageHeader';
import "../Style/style.css";
import { Row, Col, Form, Input, Checkbox, Tabs, Divider } from 'antd';
import CrudButtons from "../../components/CrudButtons/CrudButtons";
import { useState, useLayoutEffect, useRef, useEffect } from 'react';
import { getRights } from "../../redux/actions/userRights.action";
import { useSelector, useDispatch } from 'react-redux';
import { ReadCookie, } from '../../utils/readCookie';
import Tables from "../../components/Table/Tables"
import { logColumns, shipMethodColumns } from "../../utils/cols";
import { getLog } from "../../redux/actions/log.action"
import notification from '../../utils/notification'
import { loadShippingMethod, addShippingMethod, deleteShippingMethod, updateShippingMethod } from '../../redux/actions/shippingMethod';
import { loadFreightTerm } from '../../redux/actions/freightTerm';
import { TabTitle } from '../../utils/GeneralFunction';


const { TabPane } = Tabs;

const ShippingMethod = ({ token }) => {
    TabTitle("Shipping Method")
    const { FreightTerm, ShippingMethod, userRights, log, } = useSelector(state => state)
    const [minimize, setMinimize] = useState(false);
    const [formState, setFormState] = useState('view');
    const [description, setDescription] = useState('');
    const [form] = Form.useForm();
    const shipMethodInput = useRef(null);
    const [disable, setDisable] = useState(false);
    const [shipMethodObj, setShipMethodObj] = useState(null);
    const [check, setCheck] = useState(true);
    const dispatch = useDispatch();
    const id = ReadCookie('menuId');



    useEffect(() => {
        dispatch(loadShippingMethod(token))
    }, [token, dispatch])

    const handleDelete = () => {
        if (shipMethodObj) {
            dispatch(deleteShippingMethod(token, shipMethodObj.shipping_method_id)).then((res) => {
                if (res.type === "DELETE_SHIPPING_METHOD_SUCCESS") {
                    notification("success", "Ship Method Deleted", "Record deleted successfully!");
                }
                if (res.type === "DELETE_SHIPPING_METHOD_ERROR") {
                    notification("error", res?.payload?.request?.statusText, res?.payload?.data?.data);
                }
                form.setFieldsValue({
                    name: ""
                })
                
                setDescription("")
                setShipMethodObj(null)
                setFormState('view')
                dispatch(loadShippingMethod(token, id))

            })
        }
        setFormState("view")
    }

    useEffect(() => {

        if (shipMethodObj) {
            setFormState('view')
            form.setFieldsValue({
                id: shipMethodObj.shipping_method_id,
                name: shipMethodObj.shipping_method_name,
                active: shipMethodObj.is_active,

            });

            setDescription(shipMethodObj.shipping_method_name)
            setCheck(shipMethodObj.is_active)


        }

    }, [shipMethodObj]);

    useEffect(() => {
        if (description === '') {
            setDisable(true)
        } else {
            setDisable(false)
        }
    }, [description])




    useLayoutEffect(() => {
        dispatch(getRights(token, id)).then((res) => {
            if (res.type === "GET_USER_RIGHTS_SUCCESS") {
                if (!res?.payload.can_add) {
                    setFormState("table")
                }
            }
        });
    }, [])

    useEffect(() => {

        dispatch(getLog(token, { admin_shipping_method: "admin_shipping_method" }))
    }, [dispatch, token])


    const formSave = async () => {

        await form.validateFields().then((values) => {
            if (formState === "add") {
                dispatch(addShippingMethod(token, { shipping_method_name: description, is_active: check }, id)).then((res) => {

                    if (res.type === "ADD_SHIPPING_METHOD_ERROR") {
                        notification("error", "Not Created", res?.payload?.data?.data)
                    }
                    if (res.type === "ADD_SHIPPING_METHOD_SUCCESS") {
                        notification("success", "Ship Method Created", "Ship Method created successfully!");
                        form.setFieldsValue({
                            name: ""
                        })
                        setFormState("view")
                        setDescription("")
                        setShipMethodObj(null)
                    }
                    dispatch(loadShippingMethod(token, id))
                })
            }
            if (formState === "edit") {
                dispatch(updateShippingMethod(token, { shipping_method_name: description, is_active: check }, shipMethodObj.shipping_method_id)).then((res) => {
                    if (res.type === "UPDATE_SHIPPING_METHOD_ERROR") {
                        notification("error", "Not update", "Something went wrong")
                    }
                    if (res.type === "UPDATE_SHIPPING_METHOD_SUCCESS") {
                        notification("success", "Ship Method Updated", "Record updated successfully")
                    }
                    dispatch(loadShippingMethod(token, id))
                })
            }

        }).catch((err) => {

        })
    }

    useEffect(() => {
        if (formState === "add") {
            shipMethodInput.current.focus()
            form.setFieldsValue({
                id: null,
                name: null,
            })
            setCheck(true)
            setDescription("")
            setShipMethodObj(null)
        }
    }, [formState]);
    const filterReset = () => {
        setFormState("view")
        setShipMethodObj(null)
        setCheck(true)
        form.setFieldsValue({
            id: null,
            name: null,
        })
        setDescription(null)
    }


    return (
        <div className='styleContainer'>
            <div className='styleBody h-100' >
                <PageHeader title={"Shipping Method"} minimize={setMinimize} check={minimize} />
                {
                    !minimize &&
                    <div className='bodyStyle' >
                        <div className='crudDiv' ><CrudButtons formType={formState} rights={userRights?.payload} formState={setFormState} disable={disable} reset={filterReset} save={formSave} onDelete={handleDelete} /></div>
                        <div className='styleContent' >
                            <Row className='tables-main-row-new' >
                                <Col xs={24} sm={24} md={10} lg={8} xl={6}>
                                    <div className='styleTable' >
                                        <Tables type="shipMethod" formState={formState} scrollX={"20rem"} shipMethodObj={setShipMethodObj} source={ShippingMethod?.payload?.data} col={shipMethodColumns} paginate scroll={500} perPage={25} load={ShippingMethod.loading} rowKey="shipping_method_id" />
                                    </div>
                                </Col>
                                <Col xs={24} sm={24} md={14} lg={16} xl={18}>
                                    <div className='styleForm'  >
                                        <div className='testStyle' >
                                            <Form
                                                form={form}
                                                name="basic"
                                            

                                            >
                                                <div className='customRow styleFormUpper ' >
                                                    {shipMethodObj !== null && !(formState === "add") &&
                                                        <div className='customCols slc'>
                                                            <Form.Item
                                                                label="ID"
                                                                name="id"
                                                                initialValue={form.getFieldValue().id}
                                                                labelCol={{ span: 10 }}
                                                                wrapperCol={{ span: 14 }}

                                                            >
                                                                <Input size='small' className='styleInput id-field-style' readOnly />

                                                            </Form.Item>
                                                        </div>
                                                    }

                                                    {/*
<div className="customCols slc">
 <div >
   <Form.Item
     name="cid"
     label="Country "
     labelAlign='left'
     labelCol={{ span: 8 }}
     wrapperCol={{ span: 16 }}
     rules={[
       {
         required: true,
         message: "Country id is required",
       },
     ]}
   >
     <Select
     showSearch
     ref={countryNameInput}
       loading={country.loading}
       disabled={
         !(formState === "add" || formState === "edit")
       }
       optionFilterProp="children"
       onChange={(v)=>
    
    
    
    
    
       className="w-100"
       placeholder="Country"
  
       filterOption={(input, option) =>
         option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        onSearch={(v)=>
 
    onSelect={(e)=>selectHandler(e)}
       allowClear
    
     >
       {country?.payload?.data.map((country) => {
         return (
           <Option value={country.country_id}>
             {country.country_name}
           </Option>
         );
       })} 
     </Select>
   </Form.Item>
 </div>
</div> */}
                                                    <div className='customCols slc'>
                                                        <Form.Item
                                                            label="Method Name"
                                                            name="name"
                                                            initialValue={form.getFieldValue().desc}
                                                            labelCol={{ span: 10 }}
                                                            wrapperCol={{ span: 14 }}
                                                        >

                                                            <Input size='small' disabled={!(formState === "add" || formState === "edit")} ref={shipMethodInput} onChange={(e) => setDescription(e.target.value)} className='styleInput' />
                                                        </Form.Item>
                                                    </div>
                                                    <div className='customCols slc'>
                                                        <Form.Item
                                                            label="Active"
                                                            name="active"
                                                            labelCol={{ span: 10 }}
                                                            wrapperCol={{ span: 14 }}
                                                        >
                                                            <Checkbox disabled={!(formState === "add" || formState === "edit")} checked={check} onChange={(e) => setCheck(e.target.checked)} />
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                            </Form>
                                        </div>

                                        <div className='modalStyle' >


                                        </div>
                                        <Divider />
                                        <div className='styleTab' >
                                            <Tabs type='card' tabBarGutter={2} defaultActiveKey="1" centered  /**onChange={(key) => handleTab(key)} */ >
                                                <TabPane tab="Log Info" key="logs"  >
                                                    <div className='tabs' >
                                                        <Tables type="company" scrollX={"50rem"} scroll={800} perPage={10} source={log?.payload?.data} col={logColumns} load={log.loading} paginate />
                                                    </div>
                                                </TabPane>
                                            </Tabs>
                                        </div>
                                    </div>

                                </Col>

                            </Row>
                        </div>
                    </div>
                }
            </div>
        </div>





    )
}


export default ShippingMethod