import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getInseam } from "../../redux/actions/inseam.action";
import { getSize } from "../../redux/actions/size.action";
import { ReadCookie } from "../../utils/readCookie";
import { Row as Brow, Col as Bcol } from "reactstrap";
import { getCombination } from "../../redux/actions/generateCombination.action";
import { Button, Form, Select } from "antd";
const { Option } = Select;

export default function GenerateForm({
  itemId,
  token,
  type,
  inseamIds,
  sizeIds,
  sku,
  flag,
  userRights,
  formState,
  genderCategory,
  newSize,
  newInseam,
  inseams,
  setInseams,
  sizes,
  setSizes,
  arrSizes,
  setArrSizes,
  arr,
  setArr,
  setSizePayload,
  sizeChart,
  setSizeChart,
}) {
  const menuId = ReadCookie("menuId");
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { inseam, size } = useSelector((state) => state);
  const [setinseam, setInseam] = useState([]);
  const [setSize, setSizeArr] = useState([]);
  const [newInseamIds, setNewInseamIds] = useState([]);
  const [newSizeIds, setNewSizeIds] = useState([]);
  let inseamarray = [];
  let uniqueinseamarray = [];
  let uniquesizearray = [];

  useEffect(() => {
    if (type === "add") {
      inseamarray = [...setinseam, ...inseams];
      uniqueinseamarray = [...new Set(inseamarray)];
    } else if (type === "formView" || type === "edit") {
      const newar = newInseam && newInseam.toString().split(",");
      const newarSizes = newSize && newSize.toString().split(",");
      newar && setArr([...newar]);
      newarSizes && setArrSizes([...newarSizes]);
      newar && setInseams([...newar]);
      newarSizes && setSizes([...newarSizes]);
      newar && uniqueinseamarray.push(...newar);
      newarSizes && uniquesizearray.push(...newarSizes);
    }
  }, [type, newInseam]);

  useEffect(() => {
    form.setFieldsValue({
      size: "",
    });
    setSizes([]);
    if (itemId) {
      dispatch(getCombination(token, itemId)).then((res) => {
        if (res.type === "GET_COMBINATION_SUCCESS") {
          let inseamArr = [];
          let sizeArr = [];
          setinseam.push(...new Set(inseamArr));
          setSize.push(...new Set(sizeArr));
        }
      });
    }
  }, [genderCategory, itemId]);

  useEffect(() => {
    dispatch(getInseam(token, menuId));
    dispatch(getSize(token, menuId));
  }, []);
  useEffect(() => {
    setSizePayload(size);
  }, [size]);

  function handleChange(value, type, opt) {
    if (type === "inseam") {
      var newIdsforInseam = value.map((inseam_name) => {
        const matchingItem = inseam?.payload?.data.find(
          (item) => item.inseam_name === inseam_name
        );
        return matchingItem ? String(matchingItem.inseam_id) : null;
      });

      setNewInseamIds(newIdsforInseam);
      setInseams(value);
      setArr(value);
    }
    if (type === "size") {
      setSizes(value);
      setArrSizes(value);

      setNewSizeIds(value);
    }
  }

  const submit = async () => {
    inseamIds(inseams);
    sizeIds(sizes);
    flag(true);
  };

  return (
    <div className="formProduct p-0">
      <Form name="basic">
        <div className="container-fluid pbpx-5 ptpx-5">
          <Brow>
            <Bcol lg={6} xl={4} xxl={3}>
              <div className="customColsGenerat">
                <Form.Item
                  name="inseam"
                  label="Inseam"
                  labelAlign="left"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}
                >
                  <div className="relative">
                    <Select
                      size="small"
                      disabled={!(type === "add" || type === "edit")}
                      loading={inseam.loading}
                      mode="multiple"
                      className="w-100"
                      value={arr}
                      onChange={(value) => handleChange(value, "inseam")}
                      optionLabelProp="label"
                    >
                      {inseam?.payload?.data?.map((single) => {
                        return (
                          <Option
                            value={`${single.inseam_name}`}
                            key={single.inseam_id}
                            label={single.inseam_name}
                          >
                            {single.inseam_name}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                </Form.Item>
              </div>
            </Bcol>
            <Bcol lg={6} xl={4} xxl={3}>
              <div className="customColsGenerat">
                <Form.Item
                  name="size"
                  label="Size"
                  labelAlign="left"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}
                >
                  <div className="relative">
                    <Select
                      size="small"
                      mode="multiple"
                      value={arrSizes.length === 0 ? [] : arrSizes}
                      name="size"
                      placeholder={
                        formState === "add" ||
                        formState === "edit" ||
                        formState === "view"
                          ? "Select Size"
                          : null
                      }
                      onChange={(value) => handleChange(value, "size")}
                      optionLabelProp="Size"
                    >
                      {sizeChart?.map((single, index) => {
                        return <Option key={single}>{single}</Option>;
                      })}
                    </Select>
                  </div>
                </Form.Item>
              </div>
            </Bcol>
            
            <Bcol lg={6} xl={4} xxl={3}>
              <div className="customColsGenerat">
                <Form.Item wrapperCol={{ span: 16 }}>
                  <Button
                    type="primary"
                    size="small"
                    block
                    disabled={
                      !sku ||
                      inseams.length === 0 ||
                      sizes.length === 0 ||
                      userRights?.can_add === false
                    }
                    className={
                      !(!sku || inseams.length === 0 || sizes.length === 0)
                        ? "generateBTN"
                        : ""
                    }
                    onClick={() => submit()}
                  >
                    Generate
                  </Button>
                </Form.Item>
              </div>
            </Bcol>
          </Brow>
        </div>
      </Form>
    </div>
  );
}