import {
    GET_COMPANY_ACCESS_GRID_REQUEST, GET_COMPANY_ACCESS_GRID_SUCCESS, GET_COMPANY_ACCESS_GRID_ERROR,
    UPDATE_COMPANY_ACCESS_GRID_ERROR, UPDATE_COMPANY_ACCESS_GRID_REQUEST, UPDATE_COMPANY_ACCESS_GRID_SUCCESS,
    DELETE_COMPANY_ACCESS_GRID_ERROR, DELETE_COMPANY_ACCESS_GRID_REQUEST, DELETE_COMPANY_ACCESS_GRID_SUCCESS,
    CREATE_COMPANY_ACCESS_GRID_ERROR, CREATE_COMPANY_ACCESS_GRID_REQUEST, CREATE_COMPANY_ACCESS_GRID_SUCCESS

} from "../actions/types"
export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case GET_COMPANY_ACCESS_GRID_REQUEST:
            return {
                loading: true,
            };
        case GET_COMPANY_ACCESS_GRID_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_COMPANY_ACCESS_GRID_ERROR:
            return {
                ...state,
                loading: false
            };
        case UPDATE_COMPANY_ACCESS_GRID_REQUEST:
            return {
                loading: true,
            };
        case UPDATE_COMPANY_ACCESS_GRID_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case UPDATE_COMPANY_ACCESS_GRID_ERROR:
            return {
                ...state,
                loading: false
            };
        case DELETE_COMPANY_ACCESS_GRID_REQUEST:
            return {
                loading: true,
            };
        case DELETE_COMPANY_ACCESS_GRID_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case DELETE_COMPANY_ACCESS_GRID_ERROR:
            return {
                ...state,
                loading: false
            };
        case CREATE_COMPANY_ACCESS_GRID_REQUEST:
            return {
                loading: true,
            };
        case CREATE_COMPANY_ACCESS_GRID_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case CREATE_COMPANY_ACCESS_GRID_ERROR:
            return {
                ...state,
                loading: false
            };
        default:
            return state;
    }
}