import {
    GET_NEW_SEASON_DATA_ERROR,
    GET_NEW_SEASON_DATA_REQUEST,
    GET_NEW_SEASON_DATA_SUCCESS,
} from "../actions/types"


export const getNewSeasonReducer = (state = [], action) => {
    const { type, payload } = action;
    switch (type) {

        case GET_NEW_SEASON_DATA_REQUEST:
            return {
                loading: true,
            };
        case GET_NEW_SEASON_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_NEW_SEASON_DATA_ERROR:
            return {
                ...state,
                loading: false
            };
        default:
            return state;
    }
}