import { GET_ALL_COMPANY_ACCESS_GRID_REQUEST, GET_ALL_COMPANY_ACCESS_GRID_SUCCESS, GET_ALL_COMPANY_ACCESS_GRID_ERROR } from "../actions/types"
export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case GET_ALL_COMPANY_ACCESS_GRID_REQUEST:
            return {
                loading: true,
            };
        case GET_ALL_COMPANY_ACCESS_GRID_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_ALL_COMPANY_ACCESS_GRID_ERROR:
            return {
                ...state,
                loading: false
            };

        default:
            return state;
    }
}