import React from "react";
import { PlusOutlined, FileDoneOutlined, UndoOutlined, SearchOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Button, Tooltip, Divider, Tag, Popconfirm } from "antd";
import { useHistory, Link } from "react-router-dom";
import "../../../components/CrudButtons/crud.css";
import Swal from "sweetalert2";

export default function CrudButtons({ save, disable, formState, formType, onDelete, reset, rights, mode, name, return_id, formData, userRights, user_type }) {
  const history = useHistory();

  const confirmDelete = () => {
    if (formData?.receipt_no) {
      Swal.fire({
        icon: "error",
        text: "Item Reciept is Already Created, You need to Delete Item Reciept First."
      })
    } else {
      onDelete();
    }
  };



  function disableBtns() {
    let result = false;
    
    
    
    if (!userRights?.can_edit || formState === "add") {
      result = true;
    }
    if (user_type !== "PK") {
      result = true;
    }
    else {
      result = false;
    }
    return result;
  }


  const handleFormState = () => {
    if (formType === "return") {
      return (
        <>
          <Tooltip placement="bottom" title="Search" color="#fbfbfb">
            <Button
              disabled={userRights.can_view == true ? false : true}
              onClick={() => history.push("/return-authorization-list")}
              className="iconBtn crudBTN"
              shape="circle"
              icon={<SearchOutlined />}
            />
          </Tooltip>
          {/*Add Button*/}
          <Tooltip placement="bottom" title="Add" color="#fbfbfb">
            <Button
              
              disabled={userRights.can_add === true ? false : true}
              onClick={() => {
                if (formType === "add") {
                  formState("add");
                } else {
                  history.push("/return-authorization?mode=add");
                  formState("add");
                }
              }}
              className="iconBtn crudBTN"
              shape="circle"
              icon={<PlusOutlined />}
            />
          </Tooltip>
          <Tooltip placement="bottom" title="Save" color="#fbfbfb">
            <Button
              onClick={() => save()}
              className="iconBtn crudBTN"
              shape="circle"
              icon={<FileDoneOutlined />}
            />
          </Tooltip>
        </>
      )
    }
    else if (formType === "view") {
      return (
        <>
          <Tooltip placement="bottom" title="Search" color="#fbfbfb">
            <Button
              disabled={userRights.can_view == true ? false : true}
              onClick={() => history.push("/return-authorization-list")}
              className="iconBtn crudBTN"
              shape="circle"
              icon={<SearchOutlined />}
            />
          </Tooltip>
          {/*Add Button*/}
          <Tooltip placement="bottom" title="Add" color="#fbfbfb">
            <Button
              
              disabled={userRights.can_add === true ? false : true}
              onClick={() => {
                if (formType === "add") {
                  formState("add");
                } else {
                  history.push("/return-authorization?mode=add");
                  formState("add");
                }
              }}
              className="iconBtn crudBTN"
              shape="circle"
              icon={<PlusOutlined />}
            />
          </Tooltip>
        </>
      )
    }
    else if (formType === "add") {
      return (
        <>
          <Tooltip placement="bottom" title="Search" color="#fbfbfb">
            <Button
              disabled={userRights.can_view == true ? false : true}
              onClick={() => history.push("/return-authorization-list")}
              className="iconBtn crudBTN"
              shape="circle"
              icon={<SearchOutlined />}
            />
          </Tooltip>
          <Tooltip placement="bottom" title="Save" color="#fbfbfb">
            <Button
              disabled={
                !(
                  (formType === "add" || formType === "edit") && !disable
                  
                  
                  
                  
                  
                  
                  
                  
                )
              }
              onClick={() => save()}
              className="iconBtn crudBTN"
              shape="circle"
              icon={<FileDoneOutlined />}
            />
          </Tooltip>
        </>
      )
    }
    else if (formType === "edit") {
      return (
        <>
          {
            formData && formData.invoice_header_id && (
              <Tooltip placement="bottom" title="Receipt" color="#fbfbfb">
                <Button
                  disabled={disableBtns() || formData?.is_receipt === true ? false : true}
                  type="primary"
                  onClick={() => history.push(`/item-receipt?mode=creating${return_id}`)}
                  className="modalButton"
                >
                  Receipt
                </Button>
              </Tooltip>
            )
          }

          <Tooltip placement="bottom" title="Search" color="#fbfbfb">
            <Button
              disabled={userRights.can_view == true ? false : true}
              onClick={() => history.push("/return-authorization-list")}
              className="iconBtn crudBTN"
              shape="circle"
              icon={<SearchOutlined />}
            />
          </Tooltip>

          <Tooltip placement="bottom" title="Add" color="#fbfbfb">
            <Button
              
              disabled={userRights.can_add === true ? false : true}
              onClick={() => {
                if (formType === "add") {
                  formState("add");
                } else {
                  history.push("/return-authorization?mode=add");
                  formState("add");
                }
              }}
              className="iconBtn crudBTN"
              shape="circle"
              icon={<PlusOutlined />}
            />
          </Tooltip>
          <Tooltip placement="bottom" title="Save" color="#fbfbfb">
            <Button
              disabled={
                !(
                  (formType === "add" || formType === "edit") && !disable
                  
                  
                  
                  
                  
                  
                  
                  
                )
              }
              onClick={() => save()}
              className="iconBtn crudBTN"
              shape="circle"
              icon={<FileDoneOutlined />}
            />
          </Tooltip>


        </>
      )
    }
    else if (formType === "formView" && mode === "edit") {
      return (
        <>
          {
            formData && formData.invoice_header_id && (
              <Tooltip placement="bottom" title="Receipt" color="#fbfbfb">
                <Button
                  disabled={disableBtns() || formData?.is_receipt === true ? false : true}
                  
                  type="primary"
                  onClick={() => history.push(`/item-receipt?mode=return&return_id=${return_id}`)}
                  className="modalButton"
                >
                  Receipt
                </Button>
              </Tooltip>
            )
          }

          {
            userRights.can_print ? (
              <Link
                style={{ paddingLeft: "10px", textDecoration: "none" }}
                target="_blank"
                rel="noopener noreferrer"
                to={{
                  pathname: `/sales-return`,
                  search: `?return_header_id=${return_id}`,
                }}
              >
                View Report
              </Link>
            ) : ""
          }


          <Tooltip placement="bottom" title="Search" color="#fbfbfb">
            <Button
              disabled={userRights.can_view == true ? false : true}
              onClick={() => history.push("/return-authorization-list")}
              className="iconBtn crudBTN"
              shape="circle"
              icon={<SearchOutlined />}
            />
          </Tooltip>
          <Tooltip placement="bottom" title="Add" color="#fbfbfb">
            <Button
              
              disabled={userRights.can_add === true ? false : true}
              onClick={() => {
                if (formType === "add") {
                  formState("add");
                } else {
                  history.push("/return-authorization?mode=add");
                  formState("add");
                }
              }}
              className="iconBtn crudBTN"
              shape="circle"
              icon={<PlusOutlined />}
            />
          </Tooltip>
          <Tooltip placement="bottom" title="Edit" color="#fbfbfb">
            <Button
              disabled={
                disable ||
                formType === "view" ||
                formType === "add" ||
                (userRights?.can_edit === true ? false : true)
              }
              onClick={() => formState("edit")}
              className="iconBtn crudBTN"
              shape="circle"
              icon={<EditOutlined />}
            />
          </Tooltip>



        </>
      )
    }

  }
  return (
    <>
      <div className="crudContainer">
        <div>
          {formType === "" ? null : (
            <div className="buttons-div">
              <Tag color={"green"} key={1}>
                {formType.toUpperCase()}
              </Tag>
              {formType !== "view" && (
                <Button
                  onClick={() => reset()}
                  className="iconBtn"
                  shape="circle"
                  icon={<UndoOutlined className="fspx-12" />}
                />
              )}
            </div>
          )}
        </div>
        <div>

          {handleFormState()}

          {/*Delete Button*/}
          {
            formType === "formView" && (
              <Tooltip placement="bottom" title="Delete" color="#fbfbfb">
                {rights?.can_delete === false ? (
                  <Button
                    disabled={userRights.can_delete === true ? false : true}
                    className="iconBtn crudBTN"
                    shape="circle"
                    icon={<DeleteOutlined />}
                  />
                ) : (
                  <Popconfirm
                    placement="topLeft"
                    disabled={userRights.can_delete === true ? false : true}
                    title={`Are you sure to delete RA# ${formData?.return_header_no}?`}
                    onConfirm={confirmDelete}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button
                      disabled={userRights.can_delete === true ? false : true}
                      className="iconBtn crudBTN"
                      shape="circle"
                      icon={<DeleteOutlined />}
                    />
                  </Popconfirm>
                )}
              </Tooltip>
            )
          }

        </div>
      </div>
      <Divider />
    </>
  );
}