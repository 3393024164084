import { GET_PRODUCTAVAILABLE_REQUEST, GET_PRODUCTAVAILABLE_SUCCESS, GET_PRODUCTAVAILABLE_ERROR } from "../actions/types";
export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case GET_PRODUCTAVAILABLE_REQUEST:
            return {
                loading: true,
            }
        case GET_PRODUCTAVAILABLE_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            }
        case GET_PRODUCTAVAILABLE_ERROR:
            return {
                ...state,
                loading: false,
            }
        default:
            return state
    }
}