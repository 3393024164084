import React, { useState, useEffect, useRef, useLayoutEffect } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { getRiseLabel, addRiseLabel, deleteRiseLabel, updateRiseLabel } from "../../redux/actions/riseLabel.action";
import { getDefaultCompany, getOneDefaultCompany } from "../../redux/actions/defaultCompany";
import { getCompanyAccess, updateCompanyAccess, deleteCompanyAccess, getAllCompany, createCompanyAccess } from "../../redux/actions/companyAccess"
import { getLog } from "../../redux/actions/log.action"
import { getRights } from "../../redux/actions/userRights.action";

import PageHeader from "../../components/PageHeader/PageHeader"
import CrudButtons from "../../components/CrudButtons/CrudButtons";
import Tables from "../../components/Table/Tables"
import { ReadCookie } from "../../utils/readCookie";
import { Button, Row, Col, Form, Input, Checkbox, Tabs, Tooltip, Popconfirm, Modal, Divider } from 'antd';
import { CloseCircleOutlined, DeleteOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { logColumns, riseLabelColumns } from "../../utils/cols";
import notification from '../../utils/notification';
import "../Style/style.css";
import { TabTitle } from '../../utils/GeneralFunction';

const { TabPane } = Tabs;

export default function RiseLabel({ token }) {
    TabTitle("Rise Label")
    const menuId = ReadCookie('menuId')
    const dCompanyId = ReadCookie('defaultCompany')
    const riseLabelNameInput = useRef(null);
    const [visible, setVisible] = useState(false);
    const [form] = Form.useForm();
    const [riseLabelObj, setRiseLabelObj] = useState(null);
    const dispatch = useDispatch();
    const { riseLabel, getDemoDefaultCompany, companyAccess, companyAll, log, userRights } = useSelector(state => state)
    
    const [minimize, setMinimize] = useState(false);
    const [check, setCheck] = useState(false);
    const [riseLabelName, setRiseLabelName] = useState('');
    const [disable, setDisable] = useState(false);
    const [formState, setFormState] = useState('view');

    const handleEdit = (record, type) => {
        if (type === "active") {
            dispatch(updateCompanyAccess(token, record)).then((res) => dispatch(getCompanyAccess(token, riseLabelObj.rise_label_id, menuId)));
        }
        if (type === "delete") {
            dispatch(deleteCompanyAccess(token, record)).then((res) => dispatch(getCompanyAccess(token, riseLabelObj.rise_label_id, menuId)));
        }
    }

    const createAssignCompany = (record) => {
        dispatch(createCompanyAccess(token, record, riseLabelObj.rise_label_id, menuId)).then((res) => dispatch(getCompanyAccess(token, riseLabelObj.rise_label_id, menuId)))
    }

    const companyCol = [
        { title: 'Company ID', dataIndex: 'company_id', key: 'company_id', },
        { title: 'Company Name', dataIndex: 'company_name', key: 'company_name', },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <>
                    <Tooltip title="Minimize" color="#fbfbfb"  >
                    </Tooltip>
                    {
                        record.is_active ?
                            <Tooltip title="Activate" color="#fbfbfb"  ><CheckCircleOutlined className='color-green' onClick={() => handleEdit(record, "active")} /></Tooltip> : <Tooltip title="Deactivate" color="#fbfbfb"  ><CloseCircleOutlined className="color-red" onClick={() => handleEdit(record, "active")} /></Tooltip>}
                    {
                        dCompanyId === record.company_id.toString() ?
                            <Tooltip title="Default company can't be deleted" color="#fbfbfb"  >
                                <DeleteOutlined className='delete-Button'  disabled twoToneColor="red"  />
                            </Tooltip> :
                            <Tooltip title="delete" color="#fbfbfb"  >
                                <Popconfirm placement="top" title={"Are you sure to delete this?"} onConfirm={() => handleEdit(record, "delete")} okText="Yes" cancelText="No">
                                    <DeleteOutlined className='delete-Button'  twoToneColor="red"  />
                                </Popconfirm>
                            </Tooltip>
                    }
                </>
            ),
        },
    ];
    const companyAccessCol = [
        { title: 'ID', dataIndex: 'company_id', key: 'company_id', },
        { title: 'Company Name', dataIndex: 'company_name', key: 'company_name', },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <>
                    {
                        companyAccess?.payload?.data?.find(m => m.company_id === record?.company_id) ?
                            <>
                                <Tooltip title="Already assigned" color="#fbfbfb"  >
                                    <Button className='modalActionBTN' disabled shape='circle' icon={<CheckCircleOutlined className="color-red" />} />
                                </Tooltip>
                            </> :

                            <Tooltip title="Click to assign" color="#fbfbfb"  >
                                <Button className='modalActionBTN' onClick={() => createAssignCompany(record)} shape='circle' icon={<CloseCircleOutlined className='color-green' />} />
                            </Tooltip>
                    }
                </>
            ),
        },
    ];

    
    
    

    useEffect(() => {
        dispatch(getRiseLabel(token, menuId))
    }, [token])

    useLayoutEffect(() => {
        dispatch(getRights(token, menuId)).then((res) => {
            if (res.type === "GET_USER_RIGHTS_SUCCESS") {
                if (!res?.payload.can_add) {
                    setFormState("table")
                }
            }
        });
    }, [])

    useEffect(() => {

        if (riseLabelObj) {
            setFormState('view')
            form.setFieldsValue({
                id: riseLabelObj.rise_label_id,
                riseLabel: riseLabelObj.rise_label_name,
                active: riseLabelObj.is_active
            });
            setRiseLabelName(riseLabelObj.rise_label_name)
            setCheck(riseLabelObj.is_active)
            dispatch(getCompanyAccess(token, riseLabelObj.rise_label_id, menuId))

        }
    }, [riseLabelObj]);

    useEffect(() => {
        if (riseLabelName === '') {
            setDisable(true)
        } else {
            setDisable(false)
        }
    }, [riseLabelName])

    const showModal = () => {
        dispatch(getAllCompany(token));
        setVisible(true);
    };

    const handleOk = () => {
        setVisible(false);
    };

    const handleCancel = () => {
        setVisible(false);
    };
    const handleTab = (key) => {
        if (key === "logs") {
            
            dispatch(getLog(token, { inv_rise_label: "inv_rise_label", admin_company_entity_access: "admin_company_entity_access" }))
        }
    }

    const formSave = () => {
        if (formState === "add") {
            dispatch(addRiseLabel(token, { riseLabelName, check }, menuId)).then((res) => {

                if (res.type === "ADD_RISELABEL_ERROR") {
                    notification("error", res?.payload?.request?.statusText, res?.payload?.data?.data)
                }
                if (res.type === "ADD_RISELABEL_SUCCESS") {
                    let data = {
                        company_id: dCompanyId
                    }
                    dispatch(createCompanyAccess(token, data, res?.payload?.rise_label_id, menuId)).then((res) => dispatch(getCompanyAccess(token, riseLabelObj.rise_label_id, menuId)))
                    notification("success", "Rise Label Created", "Rise Label created successfully!");
                    form.setFieldsValue({
                        riseLabel: ""
                    })
                    setFormState("")
                    
                    setRiseLabelName("")
                    setRiseLabelObj(null)
                }
                dispatch(getRiseLabel(token, menuId))

            })

        }

        if (formState === "edit") {
            dispatch(updateRiseLabel(token, { riseLabelName, check }, riseLabelObj.rise_label_id)).then((res) => {
                if (res.type === "UPDATE_RISELABEL_ERROR") {
                    notification("error", "Update", "Something went wrong")
                }
                if (res.type === "UPDATE_RISELABEL_SUCCESS") {
                    notification("success", "Rise Label Updated", "Record updated successfully")
                }
                dispatch(getRiseLabel(token, menuId))
            })


        }


    }

    const handleDelete = () => {
        if (riseLabelObj) {
            dispatch(deleteRiseLabel(token, riseLabelObj.rise_label_id)).then((res) => {
                if (res.type === "DELETE_RISELABEL_SUCCESS") {
                    notification("success", "Rise Label Deleted", "Rise Label deleted successfully!");
                }
                if (res.type === "DELETE_RISELABEL_ERROR") {
                    notification("error", res?.payload?.request?.statusText, res?.payload?.data?.data);
                }
                form.setFieldsValue({
                    riseLabel: ""
                })
                
                setRiseLabelName("")
                setRiseLabelObj(null)
                setFormState('')
                dispatch(getRiseLabel(token, menuId))

            })
        }
    }

    useEffect(() => {
        if (formState === "add") {
            dispatch(getOneDefaultCompany(token, dCompanyId));
            riseLabelNameInput.current.focus();
            form.setFieldsValue({
                riseLabel: ""
            })
            setCheck(true)
            setRiseLabelName("")
            setRiseLabelObj(null)
        }
    }, [formState]);

    const filterReset = () => {
        setFormState("view")
    }
    return (
        <div className='styleContainer' >
            <div className='styleBody h-100'  >
                <PageHeader title={"Rise Label"} minimize={setMinimize} check={minimize} />
                {
                    !minimize &&
                    <div className='bodyStyle' >
                        <div className='crudDiv' ><CrudButtons rights={userRights?.payload} reset={filterReset} formType={formState} save={formSave} disable={disable} formState={setFormState} onDelete={handleDelete} /></div>

                        <div className='styleContent' >
                            <Row className='tables-main-row-new' >
                            <Col xs={24} sm={24} md={10} lg={8} xl={6}>
                                    <div className='styleTable' >
                                        <Tables type="riseLabel" riseLabelObj={setRiseLabelObj} scrollX={"20rem"} scroll={602} perPage={25} source={riseLabel?.payload?.data} col={riseLabelColumns} paginate load={riseLabel.loading} />
                                    </div>
                                </Col>
                                <Col xs={24} sm={24} md={14} lg={16} xl={18}>
                                    <div className='styleForm'  >
                                        <Form
                                            form={form}
                                            name="basic"
                                            labelCol={{ span: 10 }}
                                        >
                                            <div className='customRow styleFormUpper ' >
                                            {riseLabelObj !== null && !(formState === "add") &&
                                             <div className='customCols slc'>
                                                <Form.Item
                                                    label="ID"
                                                    name="id"
                                                    labelCol={{ span: 10 }}
                                                    wrapperCol={{ span: 14 }}
                                                    initialValue={form.getFieldValue().id}
                                                >
                                                    <Input size='small' className='styleInput id-field-style' readOnly />

                                                </Form.Item>
                                                </div>
                                            }
                                             <div className='customCols slc'>
                                            <Form.Item
                                                label="Rise Label"
                                                name="riseLabel"
                                                labelCol={{ span: 10 }}
                                                wrapperCol={{ span: 14 }}
                                                initialValue={form.getFieldValue().riseLabel}
                                            >

                                                <Input size='small' disabled={!(formState === "add" || formState === "edit")} ref={riseLabelNameInput} onChange={(e) => setRiseLabelName(e.target.value)} className='styleInput' />
                                            </Form.Item>
                                            </div>
                                            <div className='customCols slc'>
                                            <Form.Item
                                                label="Active"
                                                labelCol={{ span: 10 }}
                                                wrapperCol={{ span: 14 }}
                                                name="active"
                                            >
                                                <Checkbox disabled={!(formState === "add" || formState === "edit")} checked={check} onChange={(e) => setCheck(e.target.checked)} />
                                            </Form.Item>
                                            </div>
                                            </div>
                                        </Form>
                                        <div className='modalStyle' >

                                            {riseLabelObj === null ?
                                                <Tooltip title="Please select style first" color="#fbfbfb"  >
                                                    <Button disabled={riseLabelObj === null ? true : false} className='' onClick={showModal} >Assign Companies</Button>
                                                </Tooltip>
                                                : <Button type="primary" className='modalButton' onClick={showModal} >Assign Companies</Button>}

                                        </div>
                                        <Divider />
                                        <div className='styleTab' >
                                            <Tabs type='card' tabBarGutter={2} defaultActiveKey="1" centered onChange={(key) => handleTab(key)}>
                                                <TabPane tab="Companies" key="1">
                                                    <div className='tabs' >
                                                        <Tables type="company" scrollX={"25rem"} scroll={340} perPage={12}
                                                            source={(formState === "add") ? getDemoDefaultCompany?.payload?.data : (formState === "edit" || formState === "view" ? companyAccess?.payload?.data : [])}
                                                            loading={(formState === "add") ? getDemoDefaultCompany.loading : companyAccess.loading} col={companyCol} />
                                                    </div>
                                                </TabPane>
                                                <TabPane tab="Log Info" key="logs"  >
                                                    <div className='tabs' >
                                                        <Tables type="company" scrollX={"50rem"} scroll={360} perPage={15} source={log?.payload?.data} col={logColumns} load={log.loading} paginate />
                                                    </div>
                                                </TabPane>
                                            </Tabs>
                                        </div>
                                        <div className='modalForm' >
                                            <Modal
                                                title="Assign Companies"
                                                visible={visible}
                                                onOk={handleOk}
                                                onCancel={handleCancel}
                                                footer={null}
                                            >
                                                <Tables type="companyAccess" scroll={334} perPage={12} source={companyAll?.payload?.data} col={companyAccessCol} load={companyAll.loading} />
                                            </Modal>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                }

            </div>
            {
                minimize &&
                <div className='styleFooter' >
                    <Button className='minimize-button-style' onClick={() => setMinimize(false)}  >Style</Button>
                </div>
            }
        </div >
    )
}