
import {
  SEND_BERGEN_EMAIL_REQUEST,
SEND_BERGEN_EMAIL_SUCCESS,
SEND_BERGEN_EMAIL_ERROR
} from "../actions/types";

export const sendBergenEmail = (state = [], action) => {
  const { type, payload } = action;
  switch (type) {
    case SEND_BERGEN_EMAIL_REQUEST:
      return {
        loading: true,
      };
    case SEND_BERGEN_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        payload,
      };
    case SEND_BERGEN_EMAIL_ERROR:
      return {
        ...state,
        loading: false,
        payload,
      };

    default: {
      return state;
    }
  }
};