
import {
  GET_DL_SHOWROOM_REQUEST,
  GET_DL_SHOWROOM_SUCCESS,
  GET_DL_SHOWROOM_ERROR
} from "../actions/types";

export const dlShowroom = (state = [], action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_DL_SHOWROOM_REQUEST:
      return {
        loading: true,
      };
    case GET_DL_SHOWROOM_SUCCESS:
      return {
        ...state,
        loading: false,
        payload,
      };
    case GET_DL_SHOWROOM_ERROR:
      return {
        ...state,
        loading: false,
        payload,
      };

    default: {
      return state;
    }
  }
};