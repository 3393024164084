import {
	ADD_ORDER_SHIPMENT_LINE_ERROR,
	ADD_ORDER_SHIPMENT_LINE_REQUEST,
	ADD_ORDER_SHIPMENT_LINE_SUCCESS,
	DELETE_ORDER_SHIPMENT_LINE_ERROR,
	DELETE_ORDER_SHIPMENT_LINE_REQUEST,
	DELETE_ORDER_SHIPMENT_LINE_SUCCESS,
	GET_ALL_ORDER_SHIPMENT_LINE_ERROR,
	GET_ALL_ORDER_SHIPMENT_LINE_REQUEST,
	GET_ALL_ORDER_SHIPMENT_LINE_SUCCESS,
	GET_ONE_ORDER_SHIPMENT_LINE_DETAIL_ERROR,
	GET_ONE_ORDER_SHIPMENT_LINE_DETAIL_REQUEST,
	GET_ONE_ORDER_SHIPMENT_LINE_DETAIL_SUCCESS,
	GET_ONE_ORDER_SHIPMENT_LINE_ERROR,
	GET_ONE_ORDER_SHIPMENT_LINE_REQUEST,
	GET_ONE_ORDER_SHIPMENT_LINE_SUCCESS,
	UPDATE_ORDER_SHIPMENT_LINE_ERROR,
	UPDATE_ORDER_SHIPMENT_LINE_REQUEST,
	UPDATE_ORDER_SHIPMENT_LINE_SUCCESS,
} from "./types";
import { BASEURL } from "../../constant/config.js";
const axios = require("axios");

export const getAllOrderShipmentLine = (token) => (dispatch) => {
	dispatch({ type: GET_ALL_ORDER_SHIPMENT_LINE_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}getAll/orderShipLines`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: {
			startDate: "1-1-2022",
			endDate: "1-31-2022",
		},
	})
		.then((response) => {
			return dispatch({
				type: GET_ALL_ORDER_SHIPMENT_LINE_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_ALL_ORDER_SHIPMENT_LINE_ERROR,
				payload: error.response,
			});
		});
};

export const addOrderShipmentLine = (token, object) => (dispatch) => {
	
	dispatch({ type: ADD_ORDER_SHIPMENT_LINE_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}create/orderShipLines`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: {data: object},
	})
		.then((response) => {
			return dispatch({
				type: ADD_ORDER_SHIPMENT_LINE_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: ADD_ORDER_SHIPMENT_LINE_ERROR,
				payload: error.response,
			});
		});
};

export const updateOrderShipmentLine = (token, shipHeaderId, object) => (dispatch) => {
	dispatch({ type: UPDATE_ORDER_SHIPMENT_LINE_REQUEST });
	return axios({
		method: "PUT",
		url: `${BASEURL}update/orderShipLines/${shipHeaderId}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: { dataLine: object },
	})
		.then((response) => {
			return dispatch({
				type: UPDATE_ORDER_SHIPMENT_LINE_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: UPDATE_ORDER_SHIPMENT_LINE_ERROR,
				payload: error.response,
			});
		});
};

export const getOneOrderShipmentLine = (token, id) => (dispatch) => {
	dispatch({ type: GET_ONE_ORDER_SHIPMENT_LINE_REQUEST });
	return axios({
		method: "GET",
		url: `${BASEURL}getOne/orderShipmentLine/${id}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
	})
		.then((response) => {
			return dispatch({
				type: GET_ONE_ORDER_SHIPMENT_LINE_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_ONE_ORDER_SHIPMENT_LINE_ERROR,
				payload: error.response,
			});
		});
};

export const getOneOrderShipmentLineDetail = (token, id) => (dispatch) => {
	dispatch({ type: GET_ONE_ORDER_SHIPMENT_LINE_DETAIL_REQUEST });
	return axios({
		method: "GET",
		url: `${BASEURL}getOne/orderShipLines/${id}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
	})
		.then((response) => {
			return dispatch({
				type: GET_ONE_ORDER_SHIPMENT_LINE_DETAIL_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_ONE_ORDER_SHIPMENT_LINE_DETAIL_ERROR,
				payload: error.response,
			});
		});
};

export const deleteOrderShipmentLine = (token, id) => (dispatch) => {
	dispatch({ type: DELETE_ORDER_SHIPMENT_LINE_REQUEST });
	return axios({
		method: "DELETE",
		url: `${BASEURL}delete/orderShipLines/${id}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
	})
		.then((response) => {
			return dispatch({
				type: DELETE_ORDER_SHIPMENT_LINE_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: DELETE_ORDER_SHIPMENT_LINE_ERROR,
				payload: error.response,
			});
		});
};



