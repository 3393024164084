import {
  GET_TRANSACTION_LOCK_REQUEST,
  GET_TRANSACTION_LOCK_SUCCESS,
  GET_TRANSACTION_LOCK_ERROR,
  ADD_TRANSACTION_LOCK_REQUEST,
  ADD_TRANSACTION_LOCK_SUCCESS,
  ADD_TRANSACTION_LOCK_ERROR,
  UPDATE_TRANSACTION_LOCK_REQUEST,
  UPDATE_TRANSACTION_LOCK_SUCCESS,
  UPDATE_TRANSACTION_LOCK_ERROR,
  DELETE_TRANSACTION_LOCK_REQUEST,
  DELETE_TRANSACTION_LOCK_SUCCESS,
  DELETE_TRANSACTION_LOCK_ERROR,
  GET_ALL_TRANSACTION_LOCK_REQUEST,
  GET_ALL_TRANSACTION_LOCK_SUCCESS,
  GET_ALL_TRANSACTION_LOCK_ERROR,
  TRANSACTION_LOCK_LOG_REQUEST,
  TRANSACTION_LOCK_LOG_SUCCESS,
  TRANSACTION_LOCK_LOG_ERROR
} from "./types.js";
import moment from "moment";
import { BASEURL } from "../../constant/config.js";
const axios = require("axios");


// This Action is for All Forms..
export const getTransationLock = (token, transaction_date) => (dispatch) => {
  dispatch({ type: GET_TRANSACTION_LOCK_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}getAll/transactionLockByDate`,
    headers: { Accept: "application/json", Authorization: "Bearer " + token },
    data: {
      transaction_date,
    },
  })
    .then((response) => {
      return dispatch({
        type: GET_TRANSACTION_LOCK_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_TRANSACTION_LOCK_ERROR,
        payload: error.response,
      });
    });
};


export const addTransationLock = (token, formData) => (dispatch) => {


  const data = {
    year: moment(formData.year).format("YYYY"),
    month: moment(formData.month).format("MM"),
    lock: formData.lock
  }

  dispatch({ type: ADD_TRANSACTION_LOCK_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}create/addTransactionLock`,
    headers: { Accept: "application/json", Authorization: "Bearer " + token },
    data: {
      data,
    },
  })
    .then((response) => {
      return dispatch({
        type: ADD_TRANSACTION_LOCK_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: ADD_TRANSACTION_LOCK_ERROR,
        payload: error.response,
      });
    });
};


export const getAllTransationLock = (token, data) => (dispatch) => {
  
  dispatch({ type: GET_ALL_TRANSACTION_LOCK_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}getAll/transactionLock`,
    headers: { Accept: "application/json", Authorization: "Bearer " + token },
    data: {
      data,
    },
  })
    .then((response) => {
      return dispatch({
        type: GET_ALL_TRANSACTION_LOCK_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_ALL_TRANSACTION_LOCK_ERROR,
        payload: error.response,
      });
    });
};


export const updateTransationLock = (token, data, id) => (dispatch) => {
  
  dispatch({ type: UPDATE_TRANSACTION_LOCK_REQUEST });
  return axios({
    method: "PUT",
    url: `${BASEURL}update/transactionLock/${id}`,
    headers: { Accept: "application/json", Authorization: "Bearer " + token },
    data: {
      data,
    },
  })
    .then((response) => {
      return dispatch({
        type: UPDATE_TRANSACTION_LOCK_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: UPDATE_TRANSACTION_LOCK_ERROR,
        payload: error.response,
      });
    });
};


export const TransationLockLog = (token, data) => (dispatch) => {
  
  dispatch({ type: TRANSACTION_LOCK_LOG_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}create/TransactionLockLog`,
    headers: { Accept: "application/json", Authorization: "Bearer " + token },
    data
  })
    .then((response) => {
      return dispatch({
        type: TRANSACTION_LOCK_LOG_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: TRANSACTION_LOCK_LOG_ERROR,
        payload: error.response,
      });
    });
};


export const deleteTransationLock = (token, data) => (dispatch) => {
  
  dispatch({ type: DELETE_TRANSACTION_LOCK_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}getAll/transactionLock`,
    headers: { Accept: "application/json", Authorization: "Bearer " + token },
    data: {
      data,
    },
  })
    .then((response) => {
      return dispatch({
        type: DELETE_TRANSACTION_LOCK_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: DELETE_TRANSACTION_LOCK_ERROR,
        payload: error.response,
      });
    });
};
