import {
    GET_TAX_SETUP_REQUEST, GET_TAX_SETUP_SUCCESS, GET_TAX_SETUP_ERROR, ADD_TAX_SETUP_ERROR, ADD_TAX_SETUP_REQUEST, ADD_TAX_SETUP_SUCCESS,
    DELETE_TAX_SETUP_ERROR, DELETE_TAX_SETUP_REQUEST, DELETE_TAX_SETUP_SUCCESS, UPDATE_TAX_SETUP_ERROR, UPDATE_TAX_SETUP_REQUEST, UPDATE_TAX_SETUP_SUCCESS
} from "./types";
import axios from "axios";
import { BASEURL } from "../../constant/config.js"


export const getAdminTax = (token, id) => dispatch => {
    dispatch({ type: GET_TAX_SETUP_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/adminTax`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { id }
    })
        .then((response) => {
            return dispatch({ type: GET_TAX_SETUP_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: GET_TAX_SETUP_ERROR,
                payload: error.response,
            });
        });
};


export const addAdminTax = (token, data, id) => (dispatch) => {
    dispatch({ type: ADD_TAX_SETUP_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}create/adminTax`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data,
    })
        .then((response) => {

            return dispatch({ type: ADD_TAX_SETUP_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: ADD_TAX_SETUP_ERROR,
                payload: error.response,
            });
        });
};



export const updateAdminTax = (token, data, id) => (dispatch) => {
    dispatch({ type: UPDATE_TAX_SETUP_REQUEST });
    return axios({
        method: "PUT",
        url: `${BASEURL}update/adminTax/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data,
    })
        .then((response) => {

            return dispatch({ type: UPDATE_TAX_SETUP_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: UPDATE_TAX_SETUP_ERROR,
                payload: error.response,
            });
        });
};



export const deleteAdminTax = (token, id) => (dispatch) => {
    dispatch({ type: DELETE_TAX_SETUP_REQUEST });
    return axios({
        method: "DELETE",
        url: `${BASEURL}delete/adminTax/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
    })
        .then((response) => {
            return dispatch({ type: DELETE_TAX_SETUP_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: DELETE_TAX_SETUP_ERROR,
                payload: error.response,
            });
        });
};
