import {
    GET_SALESPERSON_REQUEST,
    GET_SALESPERSON_SUCCESS,
    GET_SALESPERSON_ERROR,
    GET_ALL_SALEPERSON_REQUEST,
    GET_ALL_SALEPERSON_SUCCESS,
    GET_ALL_SALEPERSON_ERROR,
  

} from "./types"
import axios from "axios";
import { BASEURL } from "../../constant/config.js"


///GET CUSTOMER TYPE
export const loadSalesPersons = (token,id) => dispatch => {
    dispatch({ type: GET_SALESPERSON_REQUEST });
    return axios({
        method: "GET",
        url: `${BASEURL}getOne/salesPerson/${id}`,
        headers: {
            Accept: "application/json",
            'Authorization': 'Bearer ' + token
        }
    })
        .then(response => {
           
            return dispatch({ type: GET_SALESPERSON_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            if(error.response.data.data === "No Record Found !")
            {
              return dispatch({
                type: GET_SALESPERSON_ERROR,
                payload: [],
              }); 
          }
            return dispatch({
                type: GET_SALESPERSON_ERROR,
                payload: error.response,
            });
        })
};


export const getAllSaleperson = (token, id) => dispatch => {
    dispatch({ type: GET_ALL_SALEPERSON_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/salesPerson`,
        headers: {
            Accept: "application/json",
            'Authorization': 'Bearer ' + token
        }
    })
        .then(response => {
            return dispatch({ type: GET_ALL_SALEPERSON_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: GET_ALL_SALEPERSON_ERROR,
                payload: error.response,
            });
        })
}



  