import {
  GET_COMPANY_ACCESS_GRID_REQUEST,
  GET_COMPANY_ACCESS_GRID_SUCCESS,
  GET_COMPANY_ACCESS_GRID_ERROR,
  UPDATE_COMPANY_ACCESS_GRID_ERROR,
  UPDATE_COMPANY_ACCESS_GRID_REQUEST,
  UPDATE_COMPANY_ACCESS_GRID_SUCCESS,
  DELETE_COMPANY_ACCESS_GRID_ERROR,
  DELETE_COMPANY_ACCESS_GRID_REQUEST,
  DELETE_COMPANY_ACCESS_GRID_SUCCESS,
  GET_ALL_COMPANY_ACCESS_GRID_REQUEST,
  GET_ALL_COMPANY_ACCESS_GRID_SUCCESS,
  GET_ALL_COMPANY_ACCESS_GRID_ERROR,
  CREATE_COMPANY_ACCESS_GRID_ERROR,
  CREATE_COMPANY_ACCESS_GRID_REQUEST,
  CREATE_COMPANY_ACCESS_GRID_SUCCESS,
} from "./types";
import { BASEURL } from "../../constant/config.js";

const axios = require("axios");
export const getCompanyAccess = (token, data, menu_id) => (dispatch) => {

  dispatch({ type: GET_COMPANY_ACCESS_GRID_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}getAll/get_company_access_grid`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: { form_id: data, menu_id },
  })
    .then((response) => {
      return dispatch({
        type: GET_COMPANY_ACCESS_GRID_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_COMPANY_ACCESS_GRID_ERROR,
        payload: error.response,
      });
    });
};

export const updateCompanyAccess = (token, data) => (dispatch) => {
  dispatch({ type: UPDATE_COMPANY_ACCESS_GRID_REQUEST });
  return axios({
    method: "PUT",
    url: `${BASEURL}update/update_company_access_grid/${data.company_entity_access_id}`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: { is_active: !data.is_active, company_id: data.company_id },
  })
    .then((response) => {
      return dispatch({
        type: UPDATE_COMPANY_ACCESS_GRID_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: UPDATE_COMPANY_ACCESS_GRID_ERROR,
        payload: error.response,
      });
    });
};
export const deleteCompanyAccess = (token, data) => (dispatch) => {
  dispatch({ type: DELETE_COMPANY_ACCESS_GRID_REQUEST });
  return axios({
    method: "DELETE",
    url: `${BASEURL}delete/company_access/${data.company_entity_access_id}`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {
      return dispatch({
        type: DELETE_COMPANY_ACCESS_GRID_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: DELETE_COMPANY_ACCESS_GRID_ERROR,
        payload: error.response,
      });
    });
};

export const getAllCompany = (token) => (dispatch) => {
  dispatch({ type: GET_ALL_COMPANY_ACCESS_GRID_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}getAll/get_company_access`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {
      return dispatch({
        type: GET_ALL_COMPANY_ACCESS_GRID_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_ALL_COMPANY_ACCESS_GRID_ERROR,
        payload: error.response,
      });
    });
};

export const createCompanyAccess = (token, data, id, menu_id) => (dispatch) => {

  dispatch({ type: CREATE_COMPANY_ACCESS_GRID_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}create/company_access`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: {
      form_id: id,
      is_active: "Y",
      company_id: data.company_id,
      menu_id
    },
  })
    .then((response) => {
      return dispatch({
        type: CREATE_COMPANY_ACCESS_GRID_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: CREATE_COMPANY_ACCESS_GRID_ERROR,
        payload: error.response,
      });
    });
};

