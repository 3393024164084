import { AutoComplete, Input } from 'antd';
import React, { useState, useCallback } from 'react';
import { getGloabalSearch } from '../../redux/actions/globalSearch.action';
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams, useLocation } from 'react-router-dom'
import { Empty } from 'antd';
import Loader from "react-loader-spinner";

const GlobalSearchOms = ({ token }) => {

    const { globalSearch } = useSelector((state) => state);
    const { submited } = useParams();
    const [options, setOptions] = useState([]);
    const dispatch = useDispatch();

    function debounce(cb, delay = 1000) {
        let timeout;
        return (...args) => {
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                cb(...args);
            }, delay);
        };
    }

    const handleChanges = (val) => {
        dispatch(getGloabalSearch(token, val)).then((res) => { setOptions(res?.payload?.data) })
    };

    const optimizedFn = useCallback(debounce(handleChanges), []);
    return (
        <AutoComplete
            notFoundContent={false ? <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Loader type="Oval" color="#3c4b58" height={20} width={20} />
            </div> : <div style={{ display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center" }}>No Data</div>}
            dropdownMatchSelectWidth={252}
            style={{ border: "1px solid #000", width: 500 }}
            options={options?.length > 0
                ? options?.map((item) => {
                    return {
                        label: (
                            <div
                                style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span>
                                    <a
                                        href=
                                        {`${window.location.origin}/${item?.menu_id === 22 ? `sale-order?mode=edit&order_id=${item.document_pk}&customer_id=${item.customer_id}`
                                            : item?.menu_id === 23 ? `order-shipment?mode=edit&order_id=${item.document_pk}&customer_id=${item.customer_id}`
                                                : item?.menu_id === 24 ? `invoice?mode=edit&order_header_id=${item.ref_id}&invoice_header_id=${item.document_pk}&customer_id=${item.customer_id}`
                                                    : item?.menu_id === 25 ? `return-authorization?mode=edit&return_id=${item.document_pk}&customer_id=${item.customer_id}`
                                                        : item?.menu_id === 26 ? `item-receipt-new?mode=edit&return_id=${item.document_pk}`
                                                            : item?.menu_id === 27 ? `inventory-adjustment/edit/${item.document_pk}?edit=yes`
                                                                : item?.menu_id === 28 ? `item-transfer/edit/${item.document_pk}?edit=yes`
                                                                    : item?.menu_id === 29 ? `sale-order-adjustment-new?mode=edit&order_id=${item.document_pk}&customer_id=${item.customer_id}`
                                                                        : item?.menu_id === 30 ? `product-info?mode=edit&product_id=${item.document_pk}`
                                                                            : item?.menu_id === 31 ? `customer-New-Info/edit/${item.document_pk}?edit=yes`//
                                                                                : ``
                                            } 
                                                                                `}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {item.p_result}
                                    </a>
                                </span>
                            </div>
                        ),
                    };
                }) : []}
            onSearch={optimizedFn}
        >
            <Input  placeholder="Search" />
        </AutoComplete>
    );
};

export default GlobalSearchOms;