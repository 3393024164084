import {
    ADD_JOURNAL_VOUCHER_HEADER_REQUEST    ,
    ADD_JOURNAL_VOUCHER_HEADER_SUCCESS    ,
    ADD_JOURNAL_VOUCHER_HEADER_ERROR      ,
    GET_ONE_JOURNAL_VOUCHER_HEADER_REQUEST,
    GET_ONE_JOURNAL_VOUCHER_HEADER_SUCCESS,
    GET_ONE_JOURNAL_VOUCHER_HEADER_ERROR  ,
    UPDATE_JOURNAL_VOUCHER_HEADER_REQUEST ,
    UPDATE_JOURNAL_VOUCHER_HEADER_SUCCESS ,
    UPDATE_JOURNAL_VOUCHER_HEADER_ERROR   ,
} from "../actions/types"
export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case ADD_JOURNAL_VOUCHER_HEADER_REQUEST:
            return {
                loading: true,
            };
        case ADD_JOURNAL_VOUCHER_HEADER_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case ADD_JOURNAL_VOUCHER_HEADER_ERROR:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_ONE_JOURNAL_VOUCHER_HEADER_REQUEST:
            return {
                loading: true,
            };
        case GET_ONE_JOURNAL_VOUCHER_HEADER_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_ONE_JOURNAL_VOUCHER_HEADER_ERROR:
            return {
                ...state,
                loading: false,
                payload,
            };
        case UPDATE_JOURNAL_VOUCHER_HEADER_REQUEST:
            return {
                loading: true,
            };
        case UPDATE_JOURNAL_VOUCHER_HEADER_SUCCESS:
            return {
                ...state,
                loading: false,
                payloadUpdate: [payload],
            };
        case UPDATE_JOURNAL_VOUCHER_HEADER_ERROR:
            return {
                ...state,
                loading: false,
                payloadUpdate: [payload],
            };

        default:
            return state;
    }
}
