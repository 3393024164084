import {

	DELETE_CREDIT_MEMO_INVOICE_REQUEST,
	DELETE_CREDIT_MEMO_SUCCESS,
	DELETE__CREDIT_MEMO_ERROR,
	
	
	
	
	
	
	
	
	
	GET_ONE_CREDIT_MEMO_INVOICE_ERROR,
	GET_ONE_CREDIT_MEMO_INVOICE_REQUEST,
	GET_ONE_CREDIT_MEMO_INVOICE_SUCCESS,
	UPDATE_CREDIT_MEMO_INVOICE_REQUEST,
	UPDATE_CREDIT_MEMO_INVOICE_SUCCESS,
	UPDATE_CREDIT_MEMO_INVOICE_ERROR,
} from "./types";
import { BASEURL } from "../../constant/config.js";
import moment from "moment";
const axios = require("axios");










































export const updateCreditInvoice = (token, data, billId) => (dispatch) => {
	data.map((e) => {
		let invoice_id = e.invoice_header_id;
		delete e.invoice_header_id

		return ({
			...e,
			invoice_id: invoice_id
		})
	})
	dispatch({ type: UPDATE_CREDIT_MEMO_INVOICE_REQUEST });
	return axios({
		method: "PUT",
		url: `${BASEURL}update/arcreditmemoinvoice/${billId}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: { dataLine: data },
	})
		.then((response) => {

			return dispatch({
				type: UPDATE_CREDIT_MEMO_INVOICE_SUCCESS,
				payloadUpdate: response?.data?.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: UPDATE_CREDIT_MEMO_INVOICE_ERROR,
				payloadUpdate: error.response,
			});
		});
};









































export const getOneCreditMemoInvoice = (token, id) => (dispatch) => {
	
	dispatch({ type: GET_ONE_CREDIT_MEMO_INVOICE_REQUEST });
	return axios({
		method: "GET",
		url: `${BASEURL}getOne/arcreditmemoinvoice/${id}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
	})
		.then((response) => {
			return dispatch({
				type: GET_ONE_CREDIT_MEMO_INVOICE_SUCCESS,
				payload: response?.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_ONE_CREDIT_MEMO_INVOICE_ERROR,
				payload: error.response,
			});
		});
};

























export const deleteCreditMemoInvoice = (token, id, data) => (dispatch) => {
	dispatch({ type: DELETE_CREDIT_MEMO_INVOICE_REQUEST });
	return axios({
		method: "DELETE",
		url: `${BASEURL}delete/arcreditmemoinvoice/${id}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: { data: data }
	})
		.then((response) => {
			return dispatch({
				type: DELETE_CREDIT_MEMO_SUCCESS,
				payload: response?.data.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: DELETE__CREDIT_MEMO_ERROR,
				payload: error.response,
			});
		});
};
































