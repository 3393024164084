import {
    GET_SKU_REQUEST,
    GET_SKU_SUCCESS,
    GET_SKU_ERROR,
} from "./types";

import { BASEURL } from "../../constant/config.js";

const axios = require("axios");

export const getSku = (token, gender_id, gender_category_id, style_id) => (dispatch) => {
    dispatch({ type: GET_SKU_REQUEST });

    const data = {
        gender_id: gender_id === "All" ? null : gender_id,
        gender_category_id: gender_category_id === "All" ? null : gender_category_id,
        style_id: style_id === "All" ? null : style_id
    }
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/productstyle`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: data
    })
        .then((response) => {
            return dispatch({ type: GET_SKU_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: GET_SKU_ERROR,
                payload: error.response,
            });
        });
};