import React, { useEffect, useState } from "react";
import { Table, Input, InputNumber, Form, Button, Modal,Select } from "antd";
import { useSelector, useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { getProductSale } from "../../redux/actions/productSale.action";
const { Option } = Select;
const EditableTable = ({ token, companyId, modalValue, setIsModalVisible, setOrderItems, setShowGrid }) => {
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const { ProductSale,invoiceGlPeriod } = useSelector((state) => state);
	const [data, setData] = useState([]);
	const [selectedId, setSelectedId] = useState(null);
	const [selectedItems, setSelectedItems] = useState([]);
	const [selected, setSelected] = useState(false);
	const [filteredItems, setFilteredItems] = useState([]);
	const [selectedRowKey, setSelectedRowKey] = useState([]);

	const updateQuantity = (type, id, value) => {
		let arr = [...data];
		const filter = arr.map((item) => {
			if (item.item_code === id) {
				if (type === "up") {
					if (parseInt(value) > parseInt(item.quantity_available)) {
						Swal.fire({
							icon: "error",
							title: "Oops...",
							text: "Quantity is greater than available quantity",
						});
						item.quantity = item.quantity_available;
						return item;
					} else {
						item.quantity = value;
						item.amount = parseFloat(item.quantity * item.rate).toFixed(2);
						item.quantity_backorder = parseInt(item.quantity) - parseInt(item.quantity_available) > 0 ? parseInt(item.quantity) - parseInt(item.quantity_available) : 0;
						item.quantity_committed =
							parseInt(item.quantity) - (parseInt(item.quantity) - parseInt(item.quantity_available) > 0 ? parseInt(item.quantity) - parseInt(item.quantity_available) : 0);
						return item;
					}
				}

				if (type === "down") {
					item.quantity = value;
					item.amount = parseFloat(item.quantity * item.rate).toFixed(2);
					item.quantity_backorder = parseInt(item.quantity) - parseInt(item.quantity_available) > 0 ? parseInt(item.quantity) - parseInt(item.quantity_available) : 0;
					item.quantity_committed =
						parseInt(item.quantity) - (parseInt(item.quantity) - parseInt(item.quantity_available) > 0 ? parseInt(item.quantity) - parseInt(item.quantity_available) : 0);
					return item;
				}
			} else {
				return item;
			}
		});
		let selectItem = filter.filter((f) => f.isSelected);
		setFilteredItems(selectItem);
		setData(filter);
		setSelected(false);
	};

	useEffect(() => {
		let arr = [...data];
		let newArr = [...selectedItems];
		let onlyKey = [...newArr].map((sItem) => sItem.item_code);
		arr = [...arr].map((item) => {
			if (onlyKey.indexOf(item.item_code) != -1) {
				return {
					...newArr[onlyKey.indexOf(item.item_code)],
				};
			}
			return item;
		});
		setData(arr);
	}, [selectedItems]);

	useEffect(() => {
		let arr = [...data];
		arr = [...arr].map((item, i) => {
			if (selectedRowKey.indexOf(item.item_code) === -1) {
				item.isSelected = false;
				return item;
			} else {
				item.isSelected = true;
				return item;
			}
		});
	}, [selectedRowKey]);

	useEffect(() => {
		if (!modalValue) {
			setData([]);
			setSelectedId(null);
			setSelectedItems([]);
		}
	}, [modalValue]);

	const handleGenerate = () => {
		dispatch(getProductSale(token, companyId, selectedId)).then((res) => {
			if (res.type === "GET_PRODUCTSALE_SUCCESS") {
				setData(res.payload);
			}
		});
	};

	const handleOk = () => {
		addOrderItems();
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	const addOrderItems = () => {
		setOrderItems(filteredItems);
		setData([]);
		setSelectedId(null);
		setShowGrid(true);
	};

	const rowSelection = {
		onChange: (selectedRowKeys, selectedRows) => {
			for (let i = 0; i < selectedRows.length; i++) {
				selectedRows[i].isSelected = true;
				selectedRows[i].quantity = selectedRows[i].hasOwnProperty("quantity") ? selectedRows[i].quantity : 1;
				selectedRows[i].amount = parseFloat(selectedRows[i].quantity * selectedRows[i].rate).toFixed(2);
				selectedRows[i].quantity_backorder = 0;
				selectedRows[i].quantity_committed = 1;
			}
			setSelected(false);
			setSelectedItems(selectedRows);
			setFilteredItems(selectedRows);
			setSelectedRowKey(selectedRowKeys);
		},
	};

	const columns = [
		{
			title: "Debit",
			dataIndex: "item_code",
		},
		{
			title: "Credit",
			dataIndex: "style_name",
		},
		{
			title: "Memo",
			dataIndex: "wash_name",
		},
		{
			title: "Name",
			dataIndex: "inseam",
			width: "10%",
		},
		{
			title: "Location",
			dataIndex: "size",
			width: "8%",
		},
		
		
	];

	return (
		<Modal
			okButtonProps={{
				style: {
					display: selectedItems.length > 0 ? "" : "none",
				},
			}}
			okText="Add Order"
			destroyOnClose={true}
			width={1200}
			centered={true}
			title="Products Selection"
			visible={modalValue}
			onOk={handleOk}
      cancelText={'Close'}
			onCancel={handleCancel}
		>
			<Form form={form} component={false}>
				<div style={{ padding: "10px 0", display: "flex", gap: "2px" }}>
					{/* <Input
						size="small"
						onPressEnter={(e) => {
							if (e.target.value.length) {
								handleGenerate();
							}
						}}
						className="w-100"
						value={selectedId}
						placeholder="Search Item"
						name="searchItem"
						onChange={(e) => {
							setSelectedId(e.target.value);
						}}
					/> */}
					<Form.Item  size="small"
					
						onPressEnter={(e) => {
							if (e.target.value.length) {
								handleGenerate();
							}
						}}
						className="w-100"
						value={selectedId}
						
						name="searchItem"
						onChange={(e) => {
							setSelectedId(e.target.value);
						}}>
												<Select
													size="small"
													tabIndex={18}
													autoComplete={"nope"}
													allowClear
													
													loading={invoiceGlPeriod.loading}
													defaultValue={form.getFieldValue().postingPeriod}
													
													
													onChange={(value) => {
														form.setFieldsValue({
															postingPeriod: value,
														});
													}}
												>
													{invoiceGlPeriod?.payload?.map((item) => {
														return (
															<Option key={item.period_id} value={item.period_id}>
																{item.period_name}
															</Option>
														);
													})}
												</Select>
											</Form.Item>
					<Button className="customInputs" type="primary" disabled={!selectedId} onClick={() => handleGenerate()}>
						View
					</Button>
				</div>
				<Table
					size="small"
					loading={ProductSale.loading}
					
					scroll={{ x: "50rem", y: 360 }}
					rowSelection={{ ...rowSelection }}
					bordered
					dataSource={data.map((it) => ({ ...it, key: it.item_code }))}
					columns={columns}
					rowClassName="editable-row"
					pagination={false}
				/>
			</Form>
		</Modal>
	);
};
export default EditableTable;