import { GET_STOCK_STYLE_ERROR, GET_STOCK_STYLE_REQUEST, GET_STOCK_STYLE_SUCCESS } from "./types";
import { BASEURL } from "../../constant/config.js";
import moment from "moment";

const axios = require("axios");
export const getOmsStyleStock = (token, data, seasonID, PCID, BgradeCheck) => (dispatch) => {


    dispatch({ type: GET_STOCK_STYLE_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/getOtsSummaryOmsReport`,

        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: {
            as_on_date: moment(data.as_on_date).format("YYYY-MM-DD"),
            sku: data.sku === "" ? null : Number(data.sku),
            season_id: seasonID.length > 0 ? seasonID.toString() : null,
            category_id: data.gender_category_id === "All" ? null : data.gender_category_id,
            fit_category_id: data.fit_category_id === "All" ? null : data.fit_category_id,
            style_id: data.style_id === "All" ? null : data.style_id,
            gender_id: data.gender_id === "All" ? null : data.gender_id,
            product_status_id: data.product_status_id,
            product_class_id: PCID.length > 0 ? String(PCID) : null,
            sort_on: data.openStock,
            include_bgrade: "abc"
        }
    })
        .then((response) => {
            return dispatch({ type: GET_STOCK_STYLE_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            if (error.response.data.data === "No Record Found !") {
                return dispatch({
                    type: GET_STOCK_STYLE_ERROR,
                    payload: [],
                });
            }
            return dispatch({
                type: GET_STOCK_STYLE_ERROR,
                payload: error.response,
            });
        });
};
