import {
  GET_SEND_BERGEN_EDI_REQUEST,
  GET_SEND_BERGEN_EDI_SUCCESS,
  GET_SEND_BERGEN_EDI_ERROR,
} from "./types";
import axios from "axios";
import { BASEURL } from "../../constant/config.js";
import moment from "moment";

export const sendBergenEdi = (token, values) => (dispatch) => {
  dispatch({ type: GET_SEND_BERGEN_EDI_REQUEST });
  const newData = {
    values,
  };
  return axios({
    method: "POST",
    url: `${BASEURL}create/sendBergenEdi`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: newData,
  })
    .then((response) => {
      return dispatch({
        type: GET_SEND_BERGEN_EDI_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_SEND_BERGEN_EDI_ERROR,
        payload: [],
      });
    });
};
