import {
  SEND_TO_BERGEN_REQUEST,
SEND_TO_BERGEN_SUCCESS,
SEND_TO_BERGEN_ERROR
} from "./types";
import axios from "axios";
import { BASEURL } from "../../constant/config.js";

///Post Bussiness dashboard
export const sendToBergen = (token, values) => (dispatch) => {
  dispatch({ type: SEND_TO_BERGEN_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}create/sendtobergen`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data:  {ship_header_id: values} ,
  })
    .then((response) => {
      return dispatch({
        type: SEND_TO_BERGEN_SUCCESS,
        payload: response,
      });
    })
    .catch((error) => {
      dispatch({
        type: SEND_TO_BERGEN_ERROR,
        payload: [],
      });
    });
};
