import {
	GET_ORDER_SHIPMENT_FORM_DATA_REQUEST,
	GET_ORDER_SHIPMENT_FORM_DATA_SUCCESS,
	GET_ORDER_SHIPMENT_FORM_DATA_ERROR,
	ADD_ORDER_SHIPMENT_HEADER_REQUEST,
	ADD_ORDER_SHIPMENT_HEADER_SUCCESS,
	ADD_ORDER_SHIPMENT_HEADER_ERROR,
	GET_ALL_ORDER_SHIPMENT_REQUEST,
	GET_ALL_ORDER_SHIPMENT_SUCCESS,
	GET_ALL_ORDER_SHIPMENT_ERROR,
	DELETE_ORDER_SHIPMENT_REQUEST,
	DELETE_ORDER_SHIPMENT_SUCCESS,
	DELETE_ORDER_SHIPMENT_ERROR,
	GET_ONE_ORDER_SHIPMENT_SUCCESS,
	GET_ONE_ORDER_SHIPMENT_REQUEST,
	GET_ONE_ORDER_SHIPMENT_ERROR,
	UPDATE_ORDER_SHIPMENT_HEADER_REQUEST,
	UPDATE_ORDER_SHIPMENT_HEADER_SUCCESS,
	UPDATE_ORDER_SHIPMENT_HEADER_ERROR,
	GET_ONE_ORDER_SHIPMENT_DETAIL_REQUEST,
	GET_ONE_ORDER_SHIPMENT_DETAIL_SUCCESS,
	GET_ONE_ORDER_SHIPMENT_DETAIL_ERROR,
	DELETE_ORDER_SHIPMENT_LINE_REQUEST,
	DELETE_ORDER_SHIPMENT_LINE_SUCCESS,
	DELETE_ORDER_SHIPMENT_LINE_ERROR,
	MARK_SHIP_REQUEST,
	MARK_SHIP_SUCCESS,
	MARK_SHIP_ERROR,
} from "./types";
import moment from "moment";
import { BASEURL } from "../../constant/config.js";
const axios = require("axios");

export const getOrderShipmentFormData = (token, clear) => (dispatch) => {
	dispatch({ type: GET_ORDER_SHIPMENT_FORM_DATA_REQUEST });

	if (clear) {
		return dispatch({
			type: GET_ORDER_SHIPMENT_FORM_DATA_SUCCESS,
			payload: {
				berganPaymentTerm: [],
				termForPrint: [],
				factorDetail: [],
				customerDetail: [],
				adminShipTaxCode: [],
				adminShipMethod: [],
				shipVia: [],
				customerTerm: [],
				freightTerm: [],
				berganBilling: [],
				salesPersonDetail: [],
				adminRegionDetail: [],
				orderStatusDetail: [],
				gender: [],
				locationDetail: [],
			},
		});
	}
	return axios({
		method: "POST",
		url: `${BASEURL}getAll/shipHeaderFormList`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
	})
		.then((response) => {
			return dispatch({
				type: GET_ORDER_SHIPMENT_FORM_DATA_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_ORDER_SHIPMENT_FORM_DATA_ERROR,
				payload: error.response,
			});
		});
};

export const getOneOrderShipmentHeader = (token, id) => (dispatch) => {
	dispatch({ type: GET_ONE_ORDER_SHIPMENT_REQUEST });
	return axios({
		method: "GET",
		url: `${BASEURL}getOne/orderShipment/${id}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
	})
		.then((response) => {
			return dispatch({
				type: GET_ONE_ORDER_SHIPMENT_SUCCESS,
				payload: response?.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_ONE_ORDER_SHIPMENT_ERROR,
				payload: error.response,
			});
		});
};

export const getOneOrderShipmentHeaderDetail = (token, id) => (dispatch) => {
	dispatch({ type: GET_ONE_ORDER_SHIPMENT_DETAIL_REQUEST });
	return axios({
		method: "GET",
		url: `${BASEURL}getOne/orderShipHeader/${id}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
	})
		.then((response) => {
			return dispatch({
				type: GET_ONE_ORDER_SHIPMENT_DETAIL_SUCCESS,
				payload: response?.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_ONE_ORDER_SHIPMENT_DETAIL_ERROR,
				payload: error.response,
			});
		});
};

export const addOrderShipmentHeader = (token, formData, dbData) => (dispatch) => {
	let newData = {
		order_header_id: dbData.order_header_id,
		ship_date: moment(formData.shipDate).format("MM-DD-YYYY"),
		billing_address_id: dbData.billingCustomer,
		
		pt_memo: formData.ptMemo ? formData.ptMemo : null,
		status_id: dbData.status_id,
		pt_status_id: dbData.pt_status_id,
		reference_no: dbData.reference_no,
		term_id: dbData.term_id,
		gender_id: dbData.gender_id,
		gender_category: dbData.gender_category,
		sp_region_category_id: dbData.sp_region_category_id,
		sales_person_id: dbData.sales_person_id,
		region_id: dbData.region_id,
		customer_id: dbData.customer_id,
		location_id: dbData.location_id,
		company_id: dbData.company_id,
		payment_term_id: formData.paymentTermId,
		bergan_payment_term_id: formData.berganPaymentTermId,
		ship_via_id: formData.shipViaId,
		shipping_method_id: formData.shippingMethodId,
		freight_term_id: formData.freightTermId,
		bergan_billing_id: formData.berganBillingId,
		shipping_account_no: formData.shippingAccountNo,
		shipping_cost: formData.shippingCost,
		shipping_address_id: dbData.shippingCustomer,
		remarks: dbData.remarks,
		status_id: formData.status,
		pt_status_id: formData.ptStatusId,
		freight_amount: formData.freightAmount,
		freight_charges: formData.freightCharges,
		shipping_tax_code: formData.shippingTaxCode,
		shipping_tax_rate: formData.shippingTaxRate,
		cs_comment : formData.cs_comment ? formData.cs_comment : null,
		special_instructions: formData.specialInstructions ? formData.specialInstructions : "",
		bill_to_non_us: formData.bill_to_non_us ? formData.bill_to_non_us : null,
		ship_to_non_us: formData.ship_to_non_us ? formData.ship_to_non_us : null,
		order_type_id: formData.order_type ? formData.order_type : null,
		tax_duties: formData.tax_duties ? formData.tax_duties : null,
		
	};
	dispatch({ type: ADD_ORDER_SHIPMENT_HEADER_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}create/orderShipHeader`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: newData,
	})
		.then((response) => {
			return dispatch({
				type: ADD_ORDER_SHIPMENT_HEADER_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: ADD_ORDER_SHIPMENT_HEADER_ERROR,
				payload: error.response,
			});
		});
};

export const updateOrderShipmentHeader = (token, formData, dbData, saleOrderID) => (dispatch) => {


	let newData = {
		order_header_id: dbData.order_header_id,
		ship_date: moment(formData.shipDate).format("MM-DD-YYYY"),
		billing_address_id: dbData.billingCustomer,
		
		pt_memo: formData.ptMemo,
		status_id: dbData.status_id,
		pt_status_id: dbData.pt_status_id,
		reference_no: dbData.reference_no,
		term_id: dbData.term_id,
		gender_id: dbData.gender_id,
		gender_category: dbData.gender_category,
		sp_region_category_id: dbData.sp_region_category_id,
		sales_person_id: dbData.sales_person_id,
		region_id: dbData.region_id,
		customer_id: dbData.customer_id,
		location_id: dbData.location_id,
		company_id: dbData.company_id,
		payment_term_id: formData.paymentTermId,
		bergan_payment_term_id: formData.berganPaymentTermId,
		ship_via_id: formData.shipViaId,
		shipping_method_id: formData.shippingMethodId,
		freight_term_id: formData.freightTermId,
		bergan_billing_id: formData.berganBillingId,
		shipping_account_no: formData.shippingAccountNo,
		shipping_cost: formData.shippingCost,
		shipping_address_id: dbData.shippingCustomer,
		remarks: dbData.remarks,
		status_id: formData.status,
		pt_status_id: formData.ptStatusId,
		freight_amount: formData.freightAmount,
		freight_charges: formData.freightCharges,
		shipping_tax_code: formData.shippingTaxCode,
		shipping_tax_rate: formData.shippingTaxRate,
		sent_to_bergen: dbData.sent_to_bergen,
		special_instructions: formData.specialInstructions ? formData.specialInstructions : "",
		cs_comment : formData.cs_comment  ? formData.cs_comment  : "",

		bill_to_non_us: formData.bill_to_non_us ? formData.bill_to_non_us : null,
		ship_to_non_us: formData.ship_to_non_us ? formData.ship_to_non_us : null,
		order_type_id: formData.order_type ? formData.order_type : null,
		tax_duties: formData.tax_duties ? formData.tax_duties : null,
		
	};
	dispatch({ type: UPDATE_ORDER_SHIPMENT_HEADER_REQUEST });
	return axios({
		method: "PUT",
		url: `${BASEURL}update/orderShipHeader/${saleOrderID}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: newData,
	})
		.then((response) => {
			return dispatch({
				type: UPDATE_ORDER_SHIPMENT_HEADER_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: UPDATE_ORDER_SHIPMENT_HEADER_ERROR,
				payload: error.response,
			});
		});
};

export const getAllOrderShipmentHeader = (token, data) => (dispatch) => {
	dispatch({ type: GET_ALL_ORDER_SHIPMENT_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}getAll/shipmintlist`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: {
			ship_header_id: null,
			ship_header_no: data.ptNo === "" ? null : data.ptNo,
			order_header_no: data.sono === "" ? null : data.sono,
			from_date: moment(data.from_date).format("YYYY-MM-DD"),
			to_date: moment(data.to_date).format("YYYY-MM-DD"),
			po_no: data.PO_no === "" ? null : data.PO_no,
			customer_code: data.customer_code === "" ? null : data.customer_code,
			sales_person_name: data.saleperson_name === "" ? null : data.saleperson_name,
			region_name: data.region === "" ? null : data.region,
			gender_name: data.gender === "" ? null : data.gender,
			sp_region_category_id: null,
			term_name: data.term === "" ? null : data.term,
			company_id: 2,
		},
	})
		.then((response) => {
			return dispatch({
				type: GET_ALL_ORDER_SHIPMENT_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_ALL_ORDER_SHIPMENT_ERROR,
				payload: [],
			});
		});
};

export const deleteOrderShipmentLine = (token, data, id) => (dispatch) => {


	data.map((val) => {
		delete val.available_quantity
		delete val.balance
		delete val.created_by
		delete val.created_date
		delete val.deleted_by
		delete val.deleted_date
		delete val.discount
		delete val.discount_id
		delete val.discount_percent
		delete val.fulfill
		delete val.gross_amount
		delete val.item_code
		delete val.item_id
		delete val.key
		delete val.last_updated_by
		delete val.last_updated_date
		delete val.net_amount
		delete val.order_line_id
		delete val.order_quantity
		delete val.parent_item_id
		delete val.quantity
		delete val.rate
		delete val.ship_header_id
		delete val.style_id
		delete val.style_name
		delete val.tax_amount
		delete val.tax_id
		delete val.tax_percent
		delete val.wash_id
		delete val.wash_name

	})
	dispatch({ type: DELETE_ORDER_SHIPMENT_LINE_REQUEST });
	return axios({
		method: "DELETE",
		url: `${BASEURL}delete/deletePT/${id}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: { data: data }
	})
		.then((response) => {
			return dispatch({
				type: DELETE_ORDER_SHIPMENT_LINE_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: DELETE_ORDER_SHIPMENT_LINE_ERROR,
				payload: error.response,
			});
		});
};

export const deleteOrderShipmentHeader = (token, id) => (dispatch) => {
	dispatch({ type: DELETE_ORDER_SHIPMENT_REQUEST });
	return axios({
		method: "DELETE",
		url: `${BASEURL}delete/orderShipHeader/${id}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
	})
		.then((response) => {
			return dispatch({
				type: DELETE_ORDER_SHIPMENT_SUCCESS,
				payload: response?.data.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: DELETE_ORDER_SHIPMENT_ERROR,
				payload: error.response,
			});
		});
};


export const markShip = (token, id) => (dispatch) => {
	dispatch({ type: MARK_SHIP_REQUEST });
	return axios({
		method: "GET",
		url: `${BASEURL}getOne/MarkShip/${id}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
	})
		.then((response) => {
			return dispatch({
				type: MARK_SHIP_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: MARK_SHIP_ERROR,
				payload: error.response,
			});
		});
};