import {


    GET_ONE_SALEPERSON_REQUEST,
    GET_ONE_SALEPERSON_ERROR,
    GET_ONE_SALEPERSON_SUCCESS,



  } from "../../actions/types";
  export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
          case GET_ONE_SALEPERSON_REQUEST:
            return {
                loading: true,
            };
        case GET_ONE_SALEPERSON_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_ONE_SALEPERSON_ERROR:
            return {
                ...state,
                loading: false,
                payload
            };
    
      default:
        return state;
    }
  }
  