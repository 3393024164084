
import { useSelector } from "react-redux";

const CreditPrintReportFooter = () => {
  const { creditPrintReport } = useSelector((state) => state);




  const headerData = creditPrintReport && creditPrintReport?.payload?.data[0];


  return (
    <div className='order_report_footer' 
    style={{width: "874px"}}
    >
      <div style={{display :"flex", justifyContent : "space-between"}}>
        <div className="barcode" style={{ width: "200px" }}>
        </div>
    

      </div>

    </div>

  )
}

export default CreditPrintReportFooter;