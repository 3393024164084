import {
  GET_SALE_RA_PRINT_REQUEST,
  GET_SALE_RA_PRINT_SUCCESS,
  GET_SALE_RA_PRINT_ERROR,
  GET_SALE_RA_COLUMN_REQUEST,
  GET_SALE_RA_COLUMN_SUCCESS,
  GET_SALE_RA_COLUMN_ERROR,
  GET_SALE_RA_COLUMN_VALUE_REQUEST,
  GET_SALE_RA_COLUMN_VALUE_SUCCESS,
  GET_SALE_RA_COLUMN_VALUE_ERROR,
  GET_SALE_RA_ITEM_REQUEST,
  GET_SALE_RA_ITEM_SUCCESS,
  GET_SALE_RA_ITEM_ERROR

} from "./types";
import axios from "axios";
import { BASEURL } from "../../constant/config.js";


export const getRAReportItem = (token, values) => (dispatch) => {
  dispatch({ type: GET_SALE_RA_ITEM_REQUEST });

  return axios({
    method: "POST",
    url: `${BASEURL}getAll/getSaleReturnitem`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },

    data: values,
  })
    .then((response) => {


      return dispatch({
        type: GET_SALE_RA_ITEM_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_SALE_RA_ITEM_ERROR,
        payload: [],
      });
    });
};

export const getRAReportPrint = (token, values) => (dispatch) => {
  dispatch({ type: GET_SALE_RA_PRINT_REQUEST });

  return axios({
    method: "POST",
    url: `${BASEURL}getAll/getSaleReturnPrint`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },

    data: values,
  })
    .then((response) => {

      return dispatch({
        type: GET_SALE_RA_PRINT_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_SALE_RA_PRINT_ERROR,
        payload: [],
      });
    });
};

export const getRAReportColumnValue = (token, values) => (dispatch) => {
  dispatch({ type: GET_SALE_RA_COLUMN_VALUE_REQUEST });

  return axios({
    method: "POST",
    url: `${BASEURL}getAll/getSaleReturnColumnsValue`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },

    data: values,
  })
    .then((response) => {
      return dispatch({
        type: GET_SALE_RA_COLUMN_VALUE_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_SALE_RA_COLUMN_VALUE_ERROR,
        payload: [],
      });
    });
};


export const getRAReportColumn = (token, values) => (dispatch) => {
  dispatch({ type: GET_SALE_RA_COLUMN_REQUEST });

  return axios({
    method: "POST",
    url: `${BASEURL}getAll/getSaleReturnColumns`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },

    data: values,
  })
    .then((response) => {
      return dispatch({
        type: GET_SALE_RA_COLUMN_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_SALE_RA_COLUMN_ERROR,
        payload: [],
      });
    });
};
