import {
    ADD_CUSTOMER_REFUND_LINE_REQUEST, ADD_CUSTOMER_REFUND_LINE_SUCCESS, ADD_CUSTOMER_REFUND_LINE_ERROR,
    GET_ONE_CUSTOMER_REFUND_LINE_ERROR, GET_ONE_CUSTOMER_REFUND_LINE_SUCCESS, GET_ONE_CUSTOMER_REFUND_LINE_REQUEST,
    GET_CUSTOMER_REFUND_DETAIL_REQUEST, GET_CUSTOMER_REFUND_DETAIL_SUCCESS, GET_CUSTOMER_REFUND_DETAIL_ERROR,
    UPDATE_CUSTOMER_REFUND_LINE_REQUEST, UPDATE_CUSTOMER_REFUND_LINE_SUCCESS, UPDATE_CUSTOMER_REFUND_LINE_ERROR,
    DELETE_CUSTOMER_REFUND_LINE_REQUEST, DELETE_CUSTOMER_REFUND_LINE_SUCCESS, DELETE_CUSTOMER_REFUND_LINE_ERROR,
    CUSTOMER_PAYMENT_ACCESS_REQUEST,
    CUSTOMER_PAYMENT_ACCESS_SUCCESS,
    CUSTOMER_PAYMENT_ACCESS_ERROR
} from "./types";
import { BASEURL } from "../../constant/config.js";
const axios = require("axios");

export const addCustomerRefundLine = (token, object) => (dispatch) => {

    let data = object.filter((select) => select.is_selected === true);

    
    
    
    

    dispatch({ type: ADD_CUSTOMER_REFUND_LINE_REQUEST });

    return axios({
        method: "POST",
        url: `${BASEURL}create/CustomerRefundLine`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { data },
    })
        .then((response) => {
            return dispatch({
                type: ADD_CUSTOMER_REFUND_LINE_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) => {
            return dispatch({
                type: ADD_CUSTOMER_REFUND_LINE_ERROR,
                payload: error.response,
            });
        });
};

export const getOneCustomerRefundLine = (token, customerRefundId) => (dispatch) => {

    dispatch({ type: GET_ONE_CUSTOMER_REFUND_LINE_REQUEST });
    return axios({
        method: "GET",
        url: `${BASEURL}getOne/CustomerRefundLine/${customerRefundId}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
    })
        .then((response) => {
            return dispatch({
                type: GET_ONE_CUSTOMER_REFUND_LINE_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) => {
            return dispatch({
                type: GET_ONE_CUSTOMER_REFUND_LINE_ERROR,
                payload: error.response,
            });
        });
};

export const getCustomerRefundDetails = (token, customerID) => (dispatch) => {
    dispatch({ type: GET_CUSTOMER_REFUND_DETAIL_REQUEST });
    let newData = {
        customer_id: customerID,
    };
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/CustomerNoteForRefund`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: newData,
    })
        .then((response) => {
            return dispatch({
                type: GET_CUSTOMER_REFUND_DETAIL_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) => {
            return dispatch({
                type: GET_CUSTOMER_REFUND_DETAIL_ERROR,
                payload: error.response,
            });
        });
};


export const updateCustomerRefundLine = (token, data, id) => (dispatch) => {

    
    
    
    
    
    
    
    
    dispatch({ type: UPDATE_CUSTOMER_REFUND_LINE_REQUEST });
    return axios({
        method: "PUT",
        url: `${BASEURL}update/CustomerRefundLine/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { data },

    })
        .then((response) => {
            return dispatch({
                type: UPDATE_CUSTOMER_REFUND_LINE_SUCCESS,
                payload: response?.data,
            });
        })
        .catch((error) => {
            return dispatch({
                type: UPDATE_CUSTOMER_REFUND_LINE_ERROR,
                payload: error.response,
            });
        });
};


export const deleteCustomerRefundLine = (token, data, id) => (dispatch) => {
    dispatch({ type: DELETE_CUSTOMER_REFUND_LINE_REQUEST });


    data.map((val) => {
        delete val.amount;
        delete val.applied_amount;
        delete val.created_by;
        delete val.created_date;
        delete val.is_deleted;
        delete val.is_selected;
        delete val.key;
        delete val.last_updated_by;
        delete val.last_updated_date;
        delete val.note_date;
        delete val.note_header_id;
        delete val.note_header_no;
        delete val.refund_header_id;
        delete val.remaining_amount;
    })

    return axios({
        method: "DELETE",
        url: `${BASEURL}delete/CustomerRefundLine/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { data: data }
    })
        .then((response) => {
            return dispatch({
                type: DELETE_CUSTOMER_REFUND_LINE_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) => {
            return dispatch({
                type: DELETE_CUSTOMER_REFUND_LINE_ERROR,
                payload: error.response,
            });
        });
};



export const customerPaymentAccess = (token, id) => (dispatch) => {
    dispatch({ type: CUSTOMER_PAYMENT_ACCESS_REQUEST });

    


    return axios({
        method: "POST",
        url: `${BASEURL}create/customerAccessPayment`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { transaction_id: id }
    })
        .then((response) => {
            return dispatch({
                type: CUSTOMER_PAYMENT_ACCESS_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) => {
            return dispatch({
                type: CUSTOMER_PAYMENT_ACCESS_ERROR,
                payload: error.response,
            });
        });
};