import {
    GET_STYLE_COMBINATION_REQUEST, GET_STYLE_COMBINATION_SUCCESS, GET_STYLE_COMBINATION_ERROR,
    GET_ONE_STYLE_COMBINATION_REQUEST, GET_ONE_STYLE_COMBINATION_SUCCESS, GET_ONE_STYLE_COMBINATION_ERROR,
    ADD_STYLE_COMBINATION_REQUEST, ADD_STYLE_COMBINATION_SUCCESS, ADD_STYLE_COMBINATION_ERROR,
    EDIT_STYLE_COMBINATION_REQUEST, EDIT_STYLE_COMBINATION_SUCCESS, EDIT_STYLE_COMBINATION_ERROR,
    DELETE_STYLE_COMBINATION_REQUEST, DELETE_STYLE_COMBINATION_SUCCESS, DELETE_STYLE_COMBINATION_ERROR
} from "../actions/types"
export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case GET_STYLE_COMBINATION_REQUEST:
            return {
                loading: true,
            };
        case GET_STYLE_COMBINATION_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_STYLE_COMBINATION_ERROR:
            return {
                ...state,
                loading: false
            };
        case GET_ONE_STYLE_COMBINATION_REQUEST:
            return {
                loading: true,
            };
        case GET_ONE_STYLE_COMBINATION_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_ONE_STYLE_COMBINATION_ERROR:
            return {
                ...state,
                loading: false
            };
        case ADD_STYLE_COMBINATION_REQUEST:
            return {
                loading: true,
            };
        case ADD_STYLE_COMBINATION_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case ADD_STYLE_COMBINATION_ERROR:
            return {
                ...state,
                loading: false
            };
        case DELETE_STYLE_COMBINATION_REQUEST:
            return {
                loading: true,
            };
        case DELETE_STYLE_COMBINATION_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case DELETE_STYLE_COMBINATION_ERROR:
            return {
                ...state,
                loading: false
            };
        case EDIT_STYLE_COMBINATION_REQUEST:
            return {
                loading: true,
            };
        case EDIT_STYLE_COMBINATION_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case EDIT_STYLE_COMBINATION_ERROR:
            return {
                ...state,
                loading: false
            };

        default:
            return state;
    }
}