
import {
  GET_AR_AGING_SUMMARY_REQUEST,
GET_AR_AGING_SUMMARY_SUCCESS,
GET_AR_AGING_SUMMARY_ERROR
} from "../actions/types";

export const arAgingSummary = (state = [], action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_AR_AGING_SUMMARY_REQUEST:
      return {
        loading: true,
      };
    case GET_AR_AGING_SUMMARY_SUCCESS:
      return {
        ...state,
        loading: false,
        payload,
      };
    case GET_AR_AGING_SUMMARY_ERROR:
      return {
        ...state,
        loading: false,
        payload,
      };

    default: {
      return state;
    }
  }
};