import React, { useState, useEffect, useRef } from "react";
import PageHeader from "../../components/PageHeader/PageHeader";
import { getTableSchema } from "../../redux/actions/tableSchema.action";
import { getColumnSchema } from "../../redux/actions/columnSchema.action";
import { useDispatch } from "react-redux";
import { ReadCookie } from "../../utils/readCookie";
import { CaretRightOutlined } from "@ant-design/icons";
import { Collapse, Select, Table } from "antd";
import * as XLSX from "xlsx/xlsx.mjs";
import Swal from "sweetalert2";
import Loader from "react-loader-spinner";
import { getAllSelectQuery } from "../../redux/actions/getAllSelectQuery.action";
import { getQueryBuilder } from "../../redux/actions/queryBuilder.action";
import { TabTitle } from "../../utils/GeneralFunction";
import { useSelector } from "react-redux";
import { QueryBuilderColumns } from "../../utils/cols";
import Tables from "../../components/Table/Tables";
import { getQueries } from "../../redux/actions/querytool.action";

const { Option } = Select;
const SelectQuery = () => {
  TabTitle("Select-Query");
  const [tableName, setTableName] = useState();
  const [selectTable, setSelectTable] = useState([]);
  const [saveColumn, setSaveColumn] = useState([]);
  const [selectCol, setSelectCol] = useState([]);
  const [selectFieldTwo, setSelectFieldTwo] = useState([]);
  const [result, setResult] = useState([]);
  const [response, setResponse] = useState([]);
  const [excel, setExcel] = useState([]);
  const [keys, setKeys] = useState([]);
  const [loading, setloading] = useState(false);
  const [queryData, setQueryData] = useState([]);
  const [joinData, setJoinData] = useState([]);
  const [operationJoin, setOperationJoin] = useState(false);
  const [operationQuery, setOperationQuery] = useState(false);
  const dispatch = useDispatch();
  const token = ReadCookie("token");
  const [selectTableJoin, setSelectTableJoin] = useState([]);
  const [getResponse, setReponse] = useState(false);
  const [joinQuery, setJoinQuery] = useState([]);
  const [forJoinResult, setForJoinResult] = useState(null);

  const [theArray, setTheArray] = useState([]);
  const [theArrayFieldOne, setTheArrayFieldOne] = useState([]);

  const [toogle, setToogle] = useState(false);
  const [check, setCheck] = useState(false);
  const [tableCheck, setTableCheck] = useState(false);
  const [firstTable, setFirstTable] = useState([]);
  const [whereData, setWhereData] = useState([]);
  const queryInput = document.getElementById("queryInput");
  const setQueryButton = document.getElementById("setQueryButton");
  const { queryBuilder } = useSelector((state) => state);
  const [queryList, setQueryList] = useState([]);

  let cloumns = [];
  queryList.length > 0 &&
    Object.keys(queryList[0])?.map((key, index) => (
      <span>
        {cloumns.push({
          title: key,
          dataIndex: key,
          sorter: true,
        })}
      </span>
    ));
  let array = [];
  const handleQueryCheckboxChange = () => {
    setOperationQuery(true);
    setOperationJoin(false);
  };

  const handleJoinCheckboxChange = () => {
    setOperationQuery(false);
    setOperationJoin(true);
  };

  const handleOnChange = (e) => {
    
    if (excel === "") {
      Swal.fire({
        title: "Please Insert Excel File First!",
        icon: "warning",
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 1500,
      });
      setTimeout(() => {
        window.location.reload(false);
      }, 1500);
    }
    setSelectTable(e);
    setSelectCol([]);
  };

  const handleOnTableJoin = (e, i) => {
    setTableCheck(true);
    let copyArr = [...joinData];
    copyArr[i].tableJoin = e.target.value;
    setJoinData(copyArr);

    setSelectTableJoin(e.target.value);
    const tableJoinBody = {
      column: e.target.value,
    };

    dispatch(getColumnSchema(token, tableJoinBody))
      .then((res) => {
        let field1 = [...joinData];

        field1[i] = { ...field1[i], response: res?.payload?.data };

        setReponse(true);
        setJoinData([...field1]);
      })
      .catch((error) => {});
  };
  const handleOnSubmit = (e, data, m) => {
    array = [...result];
    let arrayIndex;
    const found = array.find((element, index) => {
      arrayIndex = index;
      return element.column_name === data;
    });
    if (array.includes(data)) {
      return;
    } else {
      if (found) {
        array[arrayIndex].push(data);
      } else {
        array.push(data);
      }
    }
    setResult(array);
  };
  const handleOnWhereSubmit = (e, data, m) => {
    setCheck(true);
    array = [...whereData];
    let arrayIndex;
    const found = array.find((element, index) => {
      arrayIndex = index;
      return element.column_name === data;
    });
    if (array.includes(data)) {
      return;
    } else {
      if (found) {
        array[arrayIndex].push(data);
      } else {
        array.push(data);
      }
    }
    setWhereData(array);
  };

  useEffect(() => {
    dispatch(getTableSchema(token))
      .then((res) => {
        let sortedDates = res?.payload?.data.sort((p1, p2) =>
          p1.tablename > p2.tablename ? 1 : p1.tablename < p2.tablename ? -1 : 0
        );
        setTableName(sortedDates);
      })
      .catch((error) => {});
  }, [dispatch, token]);

  let body = {
    column: selectTable,
  };

  useEffect(() => {
    dispatch(getColumnSchema(token, body))
      .then((res) => {
        setSelectCol(res?.payload?.data);
        const filteredArray = selectFieldTwo.filter(
          (array) => array.length > 0
        );
        setSelectFieldTwo([...filteredArray, res?.payload?.data]);

        if (res?.payload?.data[0] !== undefined) {
          setTheArray((prevArray) => [...prevArray, res?.payload?.data[0]]);
          if (theArrayFieldOne.length == 0) {
            setTheArrayFieldOne((prevArray) => [
              ...prevArray,
              res?.payload?.data[0],
            ]);
          }

          setToogle(true);
        }
        if (firstTable.length == 0) {
          setFirstTable(res?.payload?.data);
        }
      })
      .catch((error) => {});
  }, [selectTable]);

  useEffect(() => {
    if (toogle) {
      joinData.map((v, index) => {
        v.fieldTwo = theArray[index].column_name;

        if (!tableCheck) {
          v.tableJoin = joinData[0].table;
        }
        if (!v.value) {
          v.value = "inner Join";
        }
        
        
        
        return v;
      });
    }
  }, [joinData, toogle]);

  useEffect(() => {
    if (toogle) {
      joinData.map((v, index) => {
        if (!tableCheck) {
          v.fieldOne = theArrayFieldOne[0].column_name;
        }
        return v;
      });
    }
  }, [toogle, joinData]);

  const fieldTwoHandler = (e, index) => {
    setToogle(false);
    let copyArr = [...joinData];
    copyArr.map((v, i) => {
      if (i === index) {
        v.fieldTwo = e.target.value;
      }
      return v;
    });
    setJoinData(copyArr);
  };

  
  let arr = [];
  let arr2 = [];
  let json;
  let jsonArray = [];
  const readUploadFile = (e) => {
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "array" });

        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];

        json = XLSX.utils.sheet_to_json(worksheet, { raw: false, defval: "" });

        jsonArray = json;
        excel.push(jsonArray);
        setExcel(jsonArray);

        keys.push(Object.keys(excel[0][0]));
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };
  const handleOnUpdate = async () => {
    let body = {
      data: [
        { tableName: selectTable },
        { columns: saveColumn }, 
        { excel: excel },
      ],
    };

    dispatch(getAllSelectQuery(token, body)).then((res) => {
      setloading(false);
      setResponse(res?.payload?.data);
      Swal.fire({
        title: "Good Job!",
        text: "Your Query is submitted!",
        icon: "success",
        timer: 5000,
        timerProgressBar: true,
        showConfirmButton: false,
      });
    });
  };
  const handleOnForJoin = async (e) => {
    
    let data = "";
    let tableData = "";
    let test1 = "";
    joinData.forEach((v, i) => {
     
      if (i == 1) {
        tableData += ` ${joinData[i - 1].table} ${joinData[i - 1].value}  ${
          joinData[i].table
        } on ${joinData[i].table}.${joinData[i].fieldTwo} = ${
          joinData[i].tableJoin
        }.${joinData[i].fieldOne}`;
      }
      if (i > 1) {
        tableData += ` ${joinData[i - 1].value}  ${joinData[i].table}   on ${
          joinData[i].table
        }.${joinData[i].fieldTwo} = ${joinData[i].tableJoin}.${
          joinData[i].fieldOne
        }   `;
        
        
        
      }
    });

    result.forEach((e) => {
      
if(!e.group){
  data += `${e.table}.${e.column},`;
}
if(e.group){
  e.group==="group"?  data += `${e.table}.${e.column},`:
  data += `${e.group}(${e.table}.${e.column}) ${e.column},`;
}

    });
    
    whereData.forEach((e) => {
      
      if (e.input !== undefined) {
        test1 += `${e.table}.${e.column}=${e.input} and `;
      } else {
        test1 += `${e.table}.${e.column}=' ' and `;
      }
    });

    function term(str, char, int) {
      var xStr = str.substring(0, str.length - int);
      return xStr + char;
    }
    

    let query = `SELECT ${term(data, " ", 1)} FROM ${tableData}`;
    queryInput.value = query;
    
    setQueryButton.addEventListener("click", function () {
      
      query = queryInput.value;
      if (query) {
        dispatch(getQueries(token, query)).then((res) => {
          if (res?.type === "GET_ALL_QUERY_SUCCESS") {
            setQueryList(res?.payload?.data);
          }
          if (res?.type === "GET_ALL_QUERY_ERROR") {
            Swal.fire({
              title: "Wrong Query",
              icon: "error",
              text: res?.payload?.data,
              showConfirmButton: false,
              timerProgressBar: true,
              timer: 5000,
            });
            
          }
        });
      }
    });
    if (test1) {
      query += ` WHERE ${term(test1, "", 4)}`;
      queryInput.value = query;
      setQueryButton.addEventListener("click", function () {
        query = queryInput.value;
        dispatch(getQueries(token, query)).then((res) => {
          if (res?.type === "GET_ALL_QUERY_SUCCESS") {
            setQueryList(res?.payload?.data);
          }
          if (res?.type === "GET_ALL_QUERY_ERROR") {
          }
        });
      });
    }
    const finalQuery = query;

    setForJoinResult(finalQuery);
  };

  return (
    <div className="styleContainer">
      <PageHeader title={"Select Query"} />
      {loading ? (
        <div className="loaderDiv">
          <Loader type="Grid" color="#212121" height={40} width={40} />
        </div>
      ) : null}
      {
        <div>
          <Collapse
            style={{ width: "99%", margin: "10px" }}
            bordered={false}
            defaultActiveKey={["1"]}
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
          >
            <div className="">
              <div className="steps" style={{ width: "300px" }}>
                <label className="upload_label">Step 1 - Select a Table</label>
                {/* <select className="choose_table" onChange={handleOnChange}>
                  <option>-Please Choose a Table-</option>
                  {tableName &&
                    tableName.map((val, index) => {
                      return <option key={index}>{val.tablename}</option>;
                    })}
                </select> */}
                	<Select
                  onChange={handleOnChange}
									size="small"
									tabIndex={18}
									autoComplete={"nope"}
									style={{ width: 250 }}
									
									showSearch
									optionFilterProp="children"
									className="filter-fields"
									loading={tableName?.loading}
                  
								>
                      <Option>-Please Choose a Table-</Option>
									{tableName?.map(
                    (val,index) => {
                      return (
                        <Option
                        key={val?.tablename}
                        
												>
                          {/* <Option></Option> */}
													{`${val?.tablename}`}
												</Option>
											);
										}
									)}
								</Select>
              </div>
              <br />
              {/* For Operation */}
              <div className="steps">
                <label>Step 2 - OperationPerform</label>
                <div className="column_div">
                  <div className="choose_columns">
                    <input
                      className="upload_checkbox"
                      type="checkbox"
                      checked={operationQuery}
                      onChange={handleQueryCheckboxChange}
                    />
                    <p>for Query</p>
                  </div>
                  <div className="choose_columns">
                    <input
                      className="upload_checkbox"
                      type="checkbox"
                      checked={operationJoin}
                      onChange={handleJoinCheckboxChange}
                    />
                    <p>for Join</p>
                  </div>
                </div>
              </div>
              {/* column Selection */}
              {/* Query Column */}
              <div className="steps">
                {operationQuery === true && (
                  <label>Step 3 - Select Columns</label>
                )}
                <div className="column_div">
                  {operationQuery === true &&
                    selectCol.map((val, i) => {
                      return (
                        <div key={i} className="choose_columns">
                          <input
                            className="upload_checkbox"
                            type="checkbox"
                            onChange={(e) => {
                              if (e.target.checked === true) {
                                let testing = {
                                  table: selectTable,
                                  column: val.column_name,
                                };
                                setQueryData([...queryData, testing]);

                                arr = [...saveColumn];
                                arr2.push(selectTable);

                                arr.push(val.column_name);
                                setSaveColumn(arr);
                              } else {
                                arr = [...saveColumn];
                                const index = arr.indexOf(val.column_name);
                                if (index > -1) {
                                  arr.splice(index, 1);
                                }
                                setSaveColumn(arr);
                              }
                              for (let i = 0; i < result.length; i++) {
                                if (result[i].column_name === saveColumn[i]) {
                                  result[i] = result.pop();
                                } else {
                                }
                              }
                            }}
                          />
                          <p>{val.column_name}</p>
                        </div>
                      );
                    })}
                </div>
              </div>
              {/* Join Column */}
              <div className="steps">
                {operationJoin === true && (
                  <label>Step 3 - Select Columns</label>
                )}
                <div className="column_div">
                  {operationJoin === true &&
                    selectCol?.map((val, i) => {
                      return (
                        <div key={i} className="choose_columns">
                          <input
                            className="upload_checkbox"
                            type="checkbox"
                            onChange={(e) => {
                              let testing = {
                                table: selectTable,
                                column: val.column_name,
                              };
                              setJoinQuery([...joinQuery, testing]);
                              if (e.target.checked === true) {
                                let testing = {
                                  table: selectTable,
                                  column: val.column_name,
                                };
                                if (
                                  joinData.some((v) => v.table === selectTable)
                                ) {
                                  return;
                                } else {
                                  setJoinData([...joinData, testing]);
                                }
                                arr = [...saveColumn];
                                arr2.push(selectTable);

                                arr.push(val.column_name);
                                setSaveColumn(arr);
                              } else {
                                arr = [...saveColumn];
                                const index = arr.indexOf(val.column_name);
                                if (index > -1) {
                                  arr.splice(index, 1);
                                }
                                setSaveColumn(arr);
                              }
                              for (let i = 0; i < result.length; i++) {
                                if (result[i].column_name === saveColumn[i]) {
                                  result[i] = result.pop();
                                } else {
                                }
                              }
                            }}
                          />
                          <p>{val.column_name}</p>
                        </div>
                      );
                    })}
                </div>
              </div>
              {/*column End  */}
              {/* Table Join */}
              <div className="steps">
                <br />
                <div>
                  {operationJoin === true ? (
                    <>
                      <table id="tables">
                        <thead>
                          <tr>
                            {joinData?.length > 0 ? <th>Join</th> : null}
                            <th>Database Table Name</th>
                            {joinData?.length > 1 ? <th>Table Join</th> : null}
                            {joinData?.length > 1 ? <th>Field1</th> : null}
                            {joinData?.length > 1 ? <th>Field2</th> : null}
                            {/* <th>Field2</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {joinData.length > 0 ? (
                            <>
                              {joinData.map((data, index) => {
                                return (
                                  <tr key={index}>
                                    {index === 0 ? (
                                      <td></td>
                                    ) : (
                                      <td>
                                        <select
                                          className="choose_table"
                                          onChange={(e) => {
                                            let copyArr = [...joinData];
                                            setJoinData(copyArr);
                                            let test = copyArr[index - 1];
                                            test.index = index - 1;
                                            test.value = e.target.value;
                                          }}
                                        >
                                          {/* <option>
                                            -Please Choose a Joint-
                                          </option> */}
                                          <option
                                            key={index}
                                            value={"inner Join"}
                                          >
                                            Inner Join
                                          </option>
                                          ;
                                          <option
                                            key={index}
                                            value={"left Outer Join"}
                                          >
                                            left Outer Join
                                          </option>
                                          ;
                                        </select>
                                      </td>
                                    )}
                                    {index === 0 ? (
                                      <td>{data.table}</td>
                                    ) : (
                                      <td>{data.table}</td>
                                    )}

                                    {joinData?.length > 1 ? (
                                      index === 0 ? (
                                        <td></td>
                                      ) : (
                                        <td>
                                          <select
                                            className="choose_table"
                                            onChange={(e) => {
                                              handleOnTableJoin(e, index);
                                            }}
                                          >
                                            {joinData
                                              .filter(
                                                (v) => v.table !== data.table
                                              )
                                              .map((val, index) => {
                                                return (
                                                  <option key={index}>
                                                    {val.table}
                                                  </option>
                                                );
                                              })}
                                          </select>
                                        </td>
                                      )
                                    ) : null}

                                    {joinData?.length > 1 ? (
                                      index === 0 ? (
                                        <td></td>
                                      ) : (
                                        <td>
                                          <select
                                            className="choose_table"
                                            onChange={(e) => {
                                              let copyArr = [...joinData];
                                              copyArr[index].fieldOne =
                                                e.target.value;
                                              setJoinData(copyArr);
                                            }}
                                          >
                                            {joinData
                                              .filter((v, i) => index === i)
                                              .map((val) => {
                                                return val?.response !==
                                                  undefined
                                                  ? val?.response?.map(
                                                      (elem) => {
                                                        return (
                                                          <option>
                                                            {elem.column_name}
                                                          </option>
                                                        );
                                                      }
                                                    )
                                                  : firstTable.map((val) => {
                                                      return (
                                                        <option>
                                                          {val.column_name}
                                                        </option>
                                                      );
                                                    });
                                              })}
                                          </select>
                                        </td>
                                      )
                                    ) : null}

                                    {joinData?.length > 1 ? (
                                      index === 0 ? (
                                        <td></td>
                                      ) : (
                                        <td>
                                          <select
                                            className="choose_table"
                                            onChange={(e) => {
                                              fieldTwoHandler(e, index);
                                            }}
                                          >
                                            {selectFieldTwo
                                              .filter((v, i) => index === i)
                                              .map((value1) => {
                                                return value1 !== undefined
                                                  ? value1.map((respon, i) => {
                                                      return (
                                                        <option key={i}>
                                                          {respon.column_name}
                                                        </option>
                                                      );
                                                    })
                                                  : null;
                                              })}
                                          </select>
                                        </td>
                                      )
                                    ) : null}
                                  </tr>
                                );
                              })}
                            </>
                          ) : (
                            <></>
                          )}
                        </tbody>
                      </table>
                      {
                        
                        <div className="steps">
                          <br />
                          <div>
                            {operationJoin === true ? (
                              <>
                                <table id="tables">
                                  <thead>
                                    <tr>
                                      <th>Database Table Name</th>
                                      <th>Database Column Name</th>
                                      <th>Where</th>
                                      <th>Select</th>
                                      <th>Value</th>
                                      <th>Group</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {joinQuery.length > 0 ? (
                                      <>
                                        {joinQuery.map((data, index) => {
                                          return (
                                            <tr key={index}>
                                              <td>{data.table}</td>
                                              <td>{data.column}</td>
                                              <td>
                                                <input
                                                  type="checkbox"
                                                  value={"where"}
                                                  name={index}
                                                  onChange={(e) =>
                                                    handleOnWhereSubmit(e, data)
                                                  }
                                                />
                                              </td>
                                              <td>
                                                <input
                                                  type="checkbox"
                                                  value={"select"}
                                                  name={index}
                                                  onChange={(e) =>
                                                    handleOnSubmit(e, data)
                                                  }
                                                />
                                              </td>
                                            
                                              <td>
                                                <input
                                                  type="input"
                                                  onChange={(e) => {
                                                    let copArr = [...whereData];
                                                    copArr[index].input =
                                                      e.target.value;
                                                    setWhereData(copArr);
                                                  }}
                                                />
                                              </td>

                                              <td>
                                        <select
                                          className="choose_table"
                                          onChange={(e) => {
                                            let copyArr = [...result];
                                            setResult(copyArr);
                                            let groups = copyArr[index];
                                            groups.index = index;
                                            groups.group = e.target.value;
                                          }}
                                        >
                                          {/* <option>
                                            -Please Choose a Joint-
                                          </option> */}
                                          <option
                                            key={index}
                                            value={"group"}
                                          >
                                            Group
                                          </option>
                                          <option
                                            key={index}
                                            value={"sum"}
                                          >
                                            Sum
                                          </option>
                                          ;
                                          <option
                                            key={index}
                                            value={"max"}
                                          >
                                            Max
                                          </option>
                                          <option
                                            key={index}
                                            value={"min"}
                                          >
                                            Min
                                          </option>
                                          ;
                                        </select>
                                      </td>
                                            </tr>
                                          );
                                        })}
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </tbody>
                                </table>
                                <br />
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      }
                      <br />
                      <div className=" submit_div">
                        {result[0] == undefined ? (
                          <></>
                        ) : (
                          <button
                            className="submit_btn"
                            onClick={handleOnForJoin}
                          >
                            Submit
                          </button>
                        )}
                      </div>
                      {/* {result?.length>0? */}
                      <div>
                        <textarea
                          type="text"
                          id="queryInput"
                          placeholder="Enter your query"
                          style={{ width: "100%" }}
                        />

                        {/* {forJoinResult} */}
                      </div>

                      {/* {result?.length>0? */}
                      <div>
                        <button className="submit_btn" id="setQueryButton">
                          Run Query
                        </button>
                      </div>
                      <Table
                        className="tableBorder"
                        loading={queryList.loading}
                        size="small"
                        bordered={false}
                        dataSource={queryList}
                        columns={cloumns}
                        pagination={{ pageSize: 1000 }}
                      />
                    </>
                  ) : (
                    " "
                  )}
                </div>
              </div>
              {/* Table Query */}
              <div className="steps">
                <br />
                <div>
                  {operationQuery === true ? (
                    <>
                      <table id="tables">
                        <thead>
                          <tr>
                            <th>Database Table Name</th>
                            <th>Database Column Name</th>
                            <th>Where</th>
                            <th>Select</th>
                            <th>Value</th>
                          </tr>
                        </thead>
                        <tbody>
                          {queryData.length > 0 ? (
                            <>
                              {queryData.map((data, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{data.table}</td>
                                    <td>{data.column}</td>
                                    <td>
                                      <input
                                        type="radio"
                                        value={"where"}
                                        name={index}
                                        onChange={(e) =>
                                          handleOnSubmit(e, data)
                                        }
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="radio"
                                        value={"select"}
                                        name={index}
                                        onChange={(e) =>
                                          handleOnSubmit(e, data)
                                        }
                                      />
                                    </td>

                                    <td>
                                      <input
                                        type="input"
                                        onChange={(e) =>
                                          handleOnSubmit(e, data)
                                        }
                                      />
                                    </td>
                                  </tr>
                                );
                              })}
                            </>
                          ) : (
                            <></>
                          )}
                        </tbody>
                      </table>
                      <br />
                      <div className=" submit_div">
                        {result[0] == undefined ? (
                          <></>
                        ) : (
                          <button
                            className="submit_btn"
                            onClick={handleOnUpdate}
                          >
                            Submit
                          </button>
                        )}
                      </div>
                      <div>{response}</div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </Collapse>
        </div>
      }
    </div>
  );
};

export default SelectQuery;
