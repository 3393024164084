import {
    GET_ALL_ITEM_ERROR, GET_ALL_ITEM_REQUEST, GET_ALL_ITEM_SUCCESS,
    GET_ONE_ITEM_ERROR, GET_ONE_ITEM_REQUEST, GET_ONE_ITEM_SUCCESS,
    ADD_ITEM_ERROR, ADD_ITEM_REQUEST, ADD_ITEM_SUCCESS,
    DELETE_ITEM_ERROR, DELETE_ITEM_REQUEST, DELETE_ITEM_SUCCESS,
    UPDATE_ITEM_ERROR, UPDATE_ITEM_REQUEST, UPDATE_ITEM_SUCCESS

} from "../actions/types"

export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case UPDATE_ITEM_REQUEST:
            return {
                loading: true,
            };
        case UPDATE_ITEM_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case UPDATE_ITEM_ERROR:
            return {
                ...state,
                loading: false,
                payload
            };
        case DELETE_ITEM_REQUEST:
            return {
                loading: true,
            };
        case DELETE_ITEM_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case DELETE_ITEM_ERROR:
            return {
                ...state,
                loading: false,
                payload
            };
        case ADD_ITEM_REQUEST:
            return {
                loading: true,
            };
        case ADD_ITEM_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case ADD_ITEM_ERROR:
            return {
                ...state,
                loading: false,
                payload
            };
        case GET_ALL_ITEM_REQUEST:
            return {
                loading: true,
            };
        case GET_ALL_ITEM_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_ALL_ITEM_ERROR:
            return {
                ...state,
                loading: false,
                payload
            };
        case GET_ONE_ITEM_REQUEST:
            return {
                loading: true,
            };
        case GET_ONE_ITEM_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_ONE_ITEM_ERROR:
            return {
                ...state,
                loading: false,
                payload
            };
        default:
            return state;
    }
}