import {
    GET_EXPENSE_REQUEST,
    GET_EXPENSE_SUCCESS,
    GET_EXPENSE_ERROR
} from "../actions/types";
import { BASEURL } from "../../constant/config.js";
import moment from "moment";

const axios = require("axios");
export const getExpense = (token, data) => (dispatch) => {
    
    dispatch({ type: GET_EXPENSE_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/Expense`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: {
            from_date: moment(data.from_date).format("MM-DD-YYYY"),
            to_date: moment(data.to_date).format("MM-DD-YYYY"),
            account_id: data.account_id === "All" || data.account_id === undefined ? null : data.account_id
        }
    })
        .then((response) => {
            return dispatch({ type: GET_EXPENSE_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            if (error.response.data.data === "No Record Found !") {
                return dispatch({
                    type: GET_EXPENSE_ERROR,
                    payload: [],
                });
            }
            return dispatch({
                type: GET_EXPENSE_ERROR,
                payload: error.response,
            });
        });
};