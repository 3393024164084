import {
    GET_SHIPPING_METHOD_REQUEST,
    GET_SHIPPING_METHOD_SUCCESS,
    GET_SHIPPING_METHOD_ERROR,
    ADD_SHIPPING_METHOD_ERROR,
    ADD_SHIPPING_METHOD_REQUEST,
    ADD_SHIPPING_METHOD_SUCCESS,
    DELETE_SHIPPING_METHOD_ERROR,
    DELETE_SHIPPING_METHOD_REQUEST,
    DELETE_SHIPPING_METHOD_SUCCESS,
    UPDATE_SHIPPING_METHOD_ERROR,
    UPDATE_SHIPPING_METHOD_REQUEST,
    UPDATE_SHIPPING_METHOD_SUCCESS
  } from "../actions/types";
  
  export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
      case GET_SHIPPING_METHOD_REQUEST:
        return {
          loading: true,
        };
      case GET_SHIPPING_METHOD_SUCCESS:
        return {
          ...state,
          loading: false,
          payload,
        };
      case GET_SHIPPING_METHOD_ERROR:
        return {
          ...state,
          loading: false,
          payload,
        };
        case ADD_SHIPPING_METHOD_REQUEST:
      return {
        loading: true,
      };
    case ADD_SHIPPING_METHOD_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: [payload],
      };
    case ADD_SHIPPING_METHOD_ERROR:
      return {
        ...state,
        loading: false
      };
    case UPDATE_SHIPPING_METHOD_REQUEST:
      return {
        loading: true,
      };
    case UPDATE_SHIPPING_METHOD_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: [payload],
      };
    case UPDATE_SHIPPING_METHOD_ERROR:
      return {
        ...state,
        loading: false
      };
    case DELETE_SHIPPING_METHOD_REQUEST:
      return {
        loading: true,
      };
    case DELETE_SHIPPING_METHOD_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: [payload],
      };
    case DELETE_SHIPPING_METHOD_ERROR:
      return {
        ...state,
        loading: false
      };



      default:
        return state;
    }
  }
  