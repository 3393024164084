import {
	GET_ALL_BILL_REQUEST,
	GET_ALL_BILL_SUCCESS,
	GET_ALL_BILL_ERROR,
	GET_ONE_BILL_HEADER_REQUEST,
	GET_ONE_BILL_HEADER_SUCCESS,
	GET_ONE_BILL_HEADER_ERROR,
	GET_ONE_BILL_HEADER_DETAIL_REQUEST,
	GET_ONE_BILL_HEADER_DETAIL_SUCCESS,
	GET_ONE_BILL_HEADER_DETAIL_ERROR,
	DELETE_BILL_HEADER_REQUEST,
	DELETE_BILL_HEADER_SUCCESS,
	DELETE_BILL_HEADER_ERROR,
	ADD_BILL_HEADER_REQUEST,
	ADD_BILL_HEADER_SUCCESS,
	ADD_BILL_HEADER_ERROR,
	UPDATE_BILL_HEADER_REQUEST,
	UPDATE_BILL_HEADER_SUCCESS,
	UPDATE_BILL_HEADER_ERROR,
} from "../actions/types";

export default function (state = [], action) {
	const { type, payload } = action;
	switch (type) {
		case ADD_BILL_HEADER_REQUEST:
			return {
				loading: true,
			};
		case ADD_BILL_HEADER_SUCCESS:
			return {
				...state,
				loading: false,
				payloadSuccess: [payload],
			};
		case ADD_BILL_HEADER_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};
		case UPDATE_BILL_HEADER_REQUEST:
			return {
				loading: true,
			};
		case UPDATE_BILL_HEADER_SUCCESS:
			return {
				...state,
				loading: false,
				payloadUpdate: [payload],
			};
		case UPDATE_BILL_HEADER_ERROR:
			return {
				...state,
				loading: false,
				payload: [payload],
			};
		case GET_ALL_BILL_REQUEST:
			return {
				loading: true,
			};
		case GET_ALL_BILL_SUCCESS:
			return {
				...state,
				loading: false,
				payload: [payload],
			};
		case GET_ALL_BILL_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};

		case DELETE_BILL_HEADER_REQUEST:
			return {
				loading: true,
			};
		case DELETE_BILL_HEADER_SUCCESS:
			return {
				...state,
				loading: false,
				payloadDelete: [payload],
			};
		case DELETE_BILL_HEADER_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};
		case GET_ONE_BILL_HEADER_REQUEST:
			return {
				loading: true,
			};
		case GET_ONE_BILL_HEADER_SUCCESS:
			return {
				...state,
				loading: false,
				payload: payload,
			};
		case GET_ONE_BILL_HEADER_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};
		case GET_ONE_BILL_HEADER_DETAIL_REQUEST:
			return {
				loading: true,
			};
		case GET_ONE_BILL_HEADER_DETAIL_SUCCESS:
			return {
				...state,
				loading: false,
				payload: payload?.data[0],
			};
		case GET_ONE_BILL_HEADER_DETAIL_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};
		default:
			return state;
	}
}