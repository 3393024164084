import {
    GET_ALL_STYLE_PENDING_REPORT_REQUEST, GET_ALL_STYLE_PENDING_REPORT_SUCESS, GET_ALL_STYLE_PENDING_REPORT_ERROR
} from "../actions/types";

export const pendingStyleWise = (state = [], action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_ALL_STYLE_PENDING_REPORT_REQUEST:
            return {
                loading: true,
            };
        case GET_ALL_STYLE_PENDING_REPORT_SUCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_ALL_STYLE_PENDING_REPORT_ERROR:
            return {
                ...state,
                loading: false,
                payload,
            };

        default: {
            return state;
        }
    }
};
