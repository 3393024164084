import {
    GET_PICKTICKET_STATUS_REQUEST, GET_PICKTICKET_STATUS_ERROR, GET_PICKTICKET_STATUS_SUCCESS, ADD_PICKTICKET_STATUS_REQUEST, ADD_PICKTICKET_STATUS_ERROR, ADD_PICKTICKET_STATUS_SUCCESS,
    UPDATE_PICKTICKET_STATUS_REQUEST, UPDATE_PICKTICKET_STATUS_ERROR, UPDATE_PICKTICKET_STATUS_SUCCESS, DELETE_PICKTICKET_STATUS_REQUEST, DELETE_PICKTICKET_STATUS_ERROR, DELETE_PICKTICKET_STATUS_SUCCESS
} from "./types"
import { BASEURL } from "../../constant/config.js";

const axios = require("axios");

export const getPickTicketStatus = (token, menu_id) => (dispatch) => {

    dispatch({ type: GET_PICKTICKET_STATUS_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/ptStatus`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },

    }).then((response) => {
        return dispatch({ type: GET_PICKTICKET_STATUS_SUCCESS, payload: response.data })
    }).catch((error) => {
        return dispatch({ type: GET_PICKTICKET_STATUS_ERROR, payload: error.response })
    })
}

export const addPickTestStatus = (token, data) => (dispatch) => {
    dispatch({ type: ADD_PICKTICKET_STATUS_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}create/ptStatus`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { pt_status_name: data.pickTicketStatusName, is_active: data.check },
    })
        .then((response) => {

            return dispatch({ type: ADD_PICKTICKET_STATUS_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: ADD_PICKTICKET_STATUS_ERROR,
                payload: error.response,
            });
        });
};

export const updatePickTicketStatus = (token, data, id) => (dispatch) => {
    dispatch({ type: UPDATE_PICKTICKET_STATUS_REQUEST });
    return axios({
        method: "PUT",
        url: `${BASEURL}update/ptStatus/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { pt_status_name: data.pickTicketStatusName, is_active: data.check },
    })
        .then((response) => {

            return dispatch({ type: UPDATE_PICKTICKET_STATUS_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: UPDATE_PICKTICKET_STATUS_ERROR,
                payload: error.response,
            });
        });
};

export const deletePickTicketStatus = (token, id) => (dispatch) => {
    dispatch({ type: DELETE_PICKTICKET_STATUS_REQUEST });
    return axios({
        method: "DELETE",
        url: `${BASEURL}delete/ptStatus/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
    })
        .then((response) => {

            return dispatch({ type: DELETE_PICKTICKET_STATUS_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: DELETE_PICKTICKET_STATUS_ERROR,
                payload: error.response,
            });
        });
};
