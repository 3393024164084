import React, { useState, useEffect, useRef, useLayoutEffect } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { getProductType, addProductType, deleteProductType, updateProductType } from "../../redux/actions/productType.action";
import { getDefaultCompany, getOneDefaultCompany } from "../../redux/actions/defaultCompany";
import { getCompanyAccess, updateCompanyAccess, deleteCompanyAccess, getAllCompany, createCompanyAccess } from "../../redux/actions/companyAccess"
import { getLog } from "../../redux/actions/log.action"
import { getRights } from "../../redux/actions/userRights.action";

import PageHeader from "../../components/PageHeader/PageHeader"
import CrudButtons from "../../components/CrudButtons/CrudButtons";
import Tables from "../../components/Table/Tables"
import { ReadCookie } from "../../utils/readCookie";
import { Button, Row, Col, Form, Input, Checkbox, Tabs, Tooltip, Popconfirm, Modal, Divider } from 'antd';
import { CloseCircleOutlined, DeleteOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { logColumns, ProductTypeColumns } from "../../utils/cols";
import notification from '../../utils/notification';
import "../Style/style.css";
import { TabTitle } from '../../utils/GeneralFunction';

const { TabPane } = Tabs;

export default function ProductType({ token }) {
    TabTitle("Product Type")
    const menuId = ReadCookie('menuId')
    const dCompanyId = ReadCookie('defaultCompany')
    const productTypeNameInput = useRef(null);
    const [visible, setVisible] = useState(false);
    const [form] = Form.useForm();
    const [productTypeObj, setproductTypeObj] = useState(null);
    const dispatch = useDispatch();
    const { productType, getDemoDefaultCompany, companyAccess, companyAll, log, userRights } = useSelector(state => state)
    
    const [minimize, setMinimize] = useState(false);
    const [check, setCheck] = useState(false);
    const [productTypeName, setproductTypeName] = useState('');
    const [disable, setDisable] = useState(false);
    const [formState, setFormState] = useState('view');




    useEffect(() => {
        dispatch(getProductType(token, menuId));
        
        dispatch(getLog(token, { inv_product_type: "inv_product_type" }))
    }, [token]);


    
    
    


    useEffect(() => {
        dispatch(getProductType(token, menuId))
    }, [token])

    useLayoutEffect(() => {
        dispatch(getRights(token, menuId)).then((res) => {
            if (res.type === "GET_USER_RIGHTS_SUCCESS") {
                if (!res?.payload.can_add) {
                    setFormState("table")
                }
            }
        });
    }, [])

    useEffect(() => {

        if (productTypeObj) {
            setFormState('view')
            form.setFieldsValue({
                id: productTypeObj.product_type_id,
                productType: productTypeObj.product_type_name,
                active: productTypeObj.is_active
            });
            setproductTypeName(productTypeObj.product_type_name)
            setCheck(productTypeObj.is_active)
            dispatch(getCompanyAccess(token, productTypeObj.product_type_id, menuId))

        }
    }, [productTypeObj]);

    useEffect(() => {
        if (productTypeName === '') {
            setDisable(true)
        } else {
            setDisable(false)
        }
    }, [productTypeName])

    const showModal = () => {
        dispatch(getAllCompany(token));
        setVisible(true);
    };

    const handleOk = () => {
        setVisible(false);
    };

    const handleCancel = () => {
        setVisible(false);
    };
    const handleTab = (key) => {
        if (key === "logs") {
            dispatch(getLog(token, { inv_product_type: "inv_product_type" }))
        }
    }


    const formSave = () => {
        if (formState === "add") {
            dispatch(addProductType(token, { productTypeName, check }, menuId)).then((res) => {

                if (res.type === "ADD_PRODUCTTYPE_ERROR") {
                    notification("error", res?.payload?.request?.statusText, res?.payload?.data?.data)
                }
                if (res.type === "ADD_PRODUCTTYPE_SUCCESS") {
                    let data = {
                        company_id: dCompanyId
                    }
                    notification("success", "Product Type Created", "Record created successfully!");
                    form.setFieldsValue({
                        itemType: ""
                    })
                    setFormState("")
                    
                    setproductTypeName("")
                    setproductTypeObj(null)
                }
                dispatch(getProductType(token, menuId))

            })

        }

        if (formState === "edit") {
            dispatch(updateProductType(token, { productTypeName, check }, productTypeObj.product_type_id)).then((res) => {
                if (res.type === "UPDATE_PRODUCTTYPE_ERROR") {
                    notification("error", "Update", "Something went wrong")
                }
                if (res.type === "UPDATE_PRODUCTTYPE_SUCCESS") {
                    notification("success", "Product Type Updated", "Record updated successfully")
                }
                dispatch(getProductType(token, menuId))
            })


        }


    }

    const handleDelete = () => {
        if (productTypeObj) {
            dispatch(deleteProductType(token, productTypeObj.product_type_id)).then((res) => {
                if (res.type === "DELETE_PRODUCTTYPE_SUCCESS") {
                    notification("success", "Product Type Deleted", "Record deleted successfully!");
                }
                if (res.type === "DELETE_PRODUCTTYPE_ERROR") {
                    notification("error", res?.payload?.request?.statusText, res?.payload?.data?.data);
                }
                form.setFieldsValue({
                    itemType: ""
                })
                
                setproductTypeName("")
                setproductTypeObj(null)
                setFormState('')
                dispatch(getProductType(token, menuId))

            })
        }
    }
    useEffect(() => {
        if (formState === "add") {
            dispatch(getOneDefaultCompany(token, dCompanyId));
            productTypeNameInput.current.focus();
            form.setFieldsValue({
                itemType: ""
            })
            setCheck(true)
            setproductTypeName("")
            setproductTypeObj(null)
        }
    }, [formState]);

    const filterReset = () => {
        setFormState("view")
    }
    return (
        <div className='styleContainer' >
            <div className='styleBody h-100'  >
                <PageHeader title={"Product Type"} minimize={setMinimize} check={minimize} />
                {
                    !minimize &&
                    <div className='bodyStyle' >
                        <div className='crudDiv' ><CrudButtons rights={userRights?.payload} reset={filterReset} formType={formState} save={formSave} disable={disable} formState={setFormState} onDelete={handleDelete} /></div>

                        <div className='styleContent' >
                            <Row className='tables-main-row-new' >
                                <Col xs={24} sm={24} md={10} lg={8} xl={6}>
                                    <div className='styleTable' >
                                        <Tables type="productType" productTypeObj={setproductTypeObj} scrollX={"20rem"} scroll={602} perPage={25} source={productType?.payload?.data} col={ProductTypeColumns} paginate load={productType.loading} />
                                    </div>
                                </Col>
                                <Col xs={24} sm={24} md={14} lg={16} xl={18}>
                                    <div className='styleForm'  >
                                        <Form
                                            form={form}
                                            name="basic"
                                            labelCol={{ span: 12 }}
                                        >
                                            <div className='customRow styleFormUpper ' >
                                                {productTypeObj !== null && !(formState === "add") &&
                                                    <div className='customCols slc'>
                                                        <Form.Item
                                                            label="ID"
                                                            name="id"
                                                            labelCol={{ span: 10 }}
                                                            wrapperCol={{ span: 14 }}
                                                            initialValue={form.getFieldValue().id}
                                                        >
                                                            <Input size='small' className='styleInput id-field-style' readOnly />

                                                        </Form.Item>
                                                    </div>
                                                }
                                                <div className='customCols slc'>
                                                    <Form.Item
                                                        label="Product Type"
                                                        name="productType"
                                                        labelCol={{ span: 10 }}
                                                        wrapperCol={{ span: 14 }}
                                                        initialValue={form.getFieldValue().productType}
                                                    >

                                                        <Input size='small' disabled={!(formState === "add" || formState === "edit")} ref={productTypeNameInput} onChange={(e) => setproductTypeName(e.target.value)} className='styleInput' />
                                                    </Form.Item>
                                                </div>
                                                <div className='customCols slc'>
                                                    <Form.Item
                                                        label="Active"
                                                        labelCol={{ span: 10 }}
                                                        wrapperCol={{ span: 14 }}
                                                        name="active"
                                                    >
                                                        <Checkbox disabled={!(formState === "add" || formState === "edit")} checked={check} onChange={(e) => setCheck(e.target.checked)} />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Form>

                                        <Divider />
                                        <div className='styleTab' >
                                            <Tabs type='card' tabBarGutter={2} defaultActiveKey="1" centered onChange={(key) => handleTab(key)}>

                                                <TabPane tab="Log Info" key="logs"  >
                                                    <div className='tabs' >
                                                        <Tables type="company" scrollX={"50rem"} scroll={360} perPage={15} source={log?.payload?.data} col={logColumns} load={log.loading} paginate />
                                                    </div>
                                                </TabPane>
                                            </Tabs>
                                        </div>

                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                }

            </div>
            {
                minimize &&
                <div className='styleFooter' >
                    <Button className='minimize-button-style' onClick={() => setMinimize(false)}  >Style</Button>
                </div>
            }
        </div >
    )
}