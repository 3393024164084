import { useCallback, useEffect, useRef, useState, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import { DeleteOutlined } from "@ant-design/icons";
import Loader from "react-loader-spinner";
import moment from "moment";
import {
    DatePicker,
    Input,
    Tabs,
    Button,
    Form,
    Select,
    Tooltip,
    Table,
    Popconfirm,
    Checkbox,
    InputNumber,
} from "antd";
import { Row as Brow, Col as Bcol } from "reactstrap";
import { getOneInvoiceGlPeriod } from "../../../../redux/actions/invoiceHeader.action";
import { getSystemLog } from "../../../../redux/actions/systemLog.action";
import { ReadCookie } from "../../../../utils/readCookie";
import EditableTable from "../../EditableTable";
import notification from "../../../../utils/notification";
import Tables from "../../../../components/Table/Tables";
import SubTitles from "../../../../components/SubTitle/SubTitle";
import "../../../../screens/SaleOrder/salesOrder.css";
import { getAllCompany } from "../../../../redux/actions/companyAccess";
import { loadCustomers } from "../../../../redux/actions/customerForm";
import { getARAccounts, getCustomerPayment } from "../../../../redux/actions/AR_Account.action";
import { getAdminPaymentTypes } from "../../../../redux/actions/adminPaymentType.action";
import {
    addCustomerRefundHeader,
    getOneCustomerRefundHeader,
    updateCustomerRefundHeader,
} from "../../../../redux/actions/customerRefundHeader.action";
import { addCustomerRefundLine, customerPaymentAccess, deleteCustomerRefundLine, getCustomerRefundDetails, getOneCustomerRefundLine, updateCustomerRefundLine } from "../../../../redux/actions/customerRefundLine.action";
import { getGLImpact } from "../../../../redux/actions/gl_impact.action";
import { useParams } from "react-router-dom";
import numeral from "numeral";
import { addCustomerRefundImpact } from "../../../../redux/actions/customerrefundimpact";
import { getTransationLock } from "../../../../redux/actions/transactionLock.action";



const { Option } = Select;
const { TabPane } = Tabs;

export default function CustomerRefundForm({ formData, val, token, formState, submit, setDisable, changeState, checkFlag }) {

	const dispatch = useDispatch();
	const submitButton = useRef(null);
	const focusInput = useRef(null);
	const { id } = useParams();

	const [form] = Form.useForm();
	const { systemLog, customerRefundFormData, invoiceGlPeriod, customerForms, companyAll, arAccount, glImpact, customrPayment } = useSelector((state) => state);
	const [companyId, setCompanyId] = useState(ReadCookie("defaultCompany"));
	const [oDate, setOrderDate] = useState(moment());
	
	const [customer, setCustomer] = useState([])
	const [menuId, setMenuId] = useState(870);
	
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [visibleItems, setVisibleItems] = useState([]);
	const [orderItems, setOrderItems] = useState([]);
	const [deleteItems, setDeleteItems] = useState([]);
	const [selectedRowKey, setSelectedRowKey] = useState([]);
	const [customerPaymentNote, setcustomerPaymentNote] = useState([]);
	const [showGrid, setShowGrid] = useState(false);
	const [loading, setLoading] = useState(false);
	const [updatedList, setUpdatedList] = useState([])
	const [checkboxState, setCheckboxState] = useState([{ key: 1, name: false }]);
	const [value4, setValue4] = useState('U');
	const [list1, setList] = useState([]);
	const [flag, setFlag] = useState("");
	const [refundHeaderID, setRefundHeaderID] = useState(null);
	const [filteredItems, setFilteredItems] = useState([]);
	const [cutomerRefundID, setCustomerRefundId] = useState(null);
	const [currentDate, setCurrentDate] = useState(moment().format('MMM YYYY'));
	const history = useHistory();
	const location = useLocation();
	const data = location.state;

	useEffect(() => {
		dispatch(getAllCompany(token));
		dispatch(getARAccounts(token));
		dispatch(getAdminPaymentTypes(token));
		dispatch(getCustomerPayment(token, menuId));
	}, []);

	useLayoutEffect(() => {
		dispatch(getOneInvoiceGlPeriod(token)).then((res) => {
			let result = res?.payload?.find((item) => {
				return item.period_name === currentDate
			});
			setCurrentDate(result?.period_id);
			form.setFieldsValue({ postingPeriod: result?.period_id });
		});
	}, [token, dispatch]);


	useEffect(() => {
		if (formState === "formView") {
			dispatch(getGLImpact(token, { "transaction_header_id": id, "transaction_type": "CUSTREFUND" }));
		}
	}, [formState]);

	useEffect(() => {
		if (checkFlag === true) {
			form.setFieldsValue({
				applied_amount: null,
			});
			setVisibleItems([]);
			setFlag(false);
		}
	}, [checkFlag]);

	useEffect(() => {
		if (data?.state && formState === "add") {
			form.setFieldsValue({
				
				postingPeriod: currentDate,
				memo: null,
				remarks: null,
				
				date: oDate,
				subsidiary: 2,
				customerID: data?.state?.customer_name,
				transactionNo: null,
				arAccount: null,
				debit_account_id: null
			});
			setOrderItems([]);
			onSelectCustomer(data?.state?.customer_id, { memo_header_no: data?.state?.memo_header_no });
		} else if (formState === "add") {
			form.setFieldsValue({
				postingPeriod: currentDate,
				memo: null,
				remarks: null,
				
				date: oDate,
				subsidiary: 2,
				customerID: data?.state?.customer_name,
				transactionNo: null,
				arAccount: null
			});
			setOrderItems([]);
		}
	}, [formState]);

	useEffect(() => {
		if (formState === "formView" && id) {
			setLoading(true)
			dispatch(getOneCustomerRefundLine(token, id)).then((res) => {
				if (res.type === "GET_ONE_CUSTOMER_REFUND_LINE_SUCCESS") {
					setOrderItems(res.payload.data);
					setList(res.payload.data);
					setLoading(false)
				}
			});
		}
	}, [formState]);



	useEffect(() => {
		if (formData) {
			dispatch(loadCustomers(token, null, null, null, null, null, null, formData[0]?.customer_id, null, null, null, null, null, null, null, null, null, null, null))

			form.setFieldsValue({
				date: moment(formData[0]?.refund_date),
				postingPeriod: formData[0]?.gl_period_id,
				memo: formData[0]?.memo,
				remarks: formData[0]?.remarks,
				customerID: formData[0]?.customer_id,
				subsidiary: formData[0]?.company_id,
				paymentType: formData[0]?.payment_type_id,
				arAccount: formData[0]?.ar_account_id,
				
				transactionNo: Number(formData[0]?.refund_header_no),
				debit_account_id: formData[0]?.account_id
			});
		}
	}, [formData]);

	useEffect(() => {
		let filtered = orderItems.filter((f) => f.isSelected === true);
		setVisibleItems(filtered);

	}, [orderItems]);


	const totalSumHandler = () => {
		let total = 0;
		const orderItemsSum = orderItems.filter((v) => v.is_selected === true);
		orderItemsSum.reduce((accum, value) => {
			return (
				
				total = accum + parseFloat(value?.applied_amount)
			);
		}, 0);

		return {
			total: total
		}
	}



	useEffect(() => {
		let filtered = customerPaymentNote.filter((g) => g.isSelected === true);
		setVisibleItems(filtered);
	}, [customerPaymentNote]);

	useEffect(() => {
		if (val) {
			submitButton.current.click();
		}
	}, [val]);

	const handleDeleteOrder = (record) => {
		const dataSource = [...orderItems];
		const deleteDataSource = [...deleteItems];
		
		deleteDataSource.push(record);
		setDeleteItems(deleteDataSource);
		

		
		if (dataSource.length > 0) {
			setOrderItems(dataSource.filter((item) => item.refund_line_id !== record.refund_line_id));
		} else {
			const dataSource = [...visibleItems];
			setOrderItems(dataSource.filter((item) => item.refund_line_id !== record.refund_line_id));
		}

		notification("success", "Customer Refund Deleted");
	};


	const onFinish = async (values) => {
		submit(false);
		await form.validateFields().then((values) => {
			setLoading(true);
			setDisable(true);
			if (formState === "add") {
				dispatch(addCustomerRefundHeader(token, values, parseInt(companyId))).then((res) => {

					if (res.type === "ADD_CUSTOMER_REFUND_HEADER_SUCCESS") {
						dispatch(getOneCustomerRefundHeader(token, res?.payload?.refund_header_id));
						let refundID = res?.payload?.refund_header_id;
						setCustomerRefundId(refundID);

						dispatch(addCustomerRefundLine(token, orderItems.map((v) => (
							delete v.note_date,
							delete v.note_header_no,
							delete v.note_amount,
							delete v.remaining_amount,
							delete v.created_date,
							delete v.is_deleted,
							delete v.note_date,
							delete v.gender_name,
							delete v.term_name,
							delete v.return_header_no,
							delete v.receipt_header_no,
							delete v.ra_no,
							delete v.invoice_header_no,
							{
								...v,
								refund_header_id: res.payload.refund_header_id,
								
								applied_amount: v.applied_amount,
								
								is_selected: v.is_selected,
								note_header_id: v.note_header_id,
								transaction_type: v.transaction_type
							})
						))

						).then((res) => {
							if (res.type === "ADD_CUSTOMER_REFUND_LINE_SUCCESS") {
								dispatch(addCustomerRefundImpact(token, refundID))
								dispatch(getOneCustomerRefundLine(token, refundID));
								notification("success", "Customer Refund", "Customer Refund created successfully!");
								
								setLoading(false)
								setDisable(false)
								history.push({
									pathname: `/customer-refund/edit/${refundID}`,
									search: `?edit=yes`,
								});
								
								changeState("formView")

								

							} else if (res.type === "ADD_CUSTOMER_REFUND_LINE_ERROR") {
								Swal.fire({
									icon: "error",
									title: "Oops...",
									text: res?.payload?.data?.data,
								});
							}
						});
					} else if (res.type === "ADD_CUSTOMER_REFUND_HEADER_ERROR") {
						Swal.fire({
							icon: "error",
							title: "Oops...",
							text: res?.payload?.data?.data,
						});
					}
				});
			}
			else if (formState === "edit") {
				
				if (deleteItems.length > 0) {
					dispatch(deleteCustomerRefundLine(token, deleteItems, 123));
				}
				dispatch(updateCustomerRefundHeader(token, values, id)).then((res) => {
					if (res.type === "UPDATE_CUSTOMER_REFUND_HEADER_SUCCESS") {
						notification("success", "Customer Refund Updated");
						
						dispatch(updateCustomerRefundLine(token, orderItems.map(item => {
							return {
								refund_line_id: item.refund_line_id,
								is_selected: item.is_selected,
								applied_amount: item.applied_amount,
								transaction_type: item.transaction_type,
								term_name: item.term_name,
							}
						}), id)).then((res) => {
							if (res.type === "UPDATE_CUSTOMER_REFUND_LINE_REQUEST") {
								setLoading(false);
								setDisable(false);
								notification("success", "Customer Refund Updated");
							}
						});
					}
					else if (res.type === "UPDATE_CUSTOMER_REFUND_LINE_ERROR") {
						setLoading(false);
						setDisable(false);
						Swal.fire({
							icon: "error",
							title: "Oops...",
							text: res?.payload?.data?.data,
						})
					}
				})
				setLoading(false);
				setDisable(false);
			}
		})
	};

	const handleTab = (key) => {
		if (key === "header") {
			dispatch(getSystemLog(token, { table_name: "ar_customer_refund_header", record_id: id }));
		}
		if (key === "line") {
			dispatch(getSystemLog(token, { table_name: "ar_customer_refund_lines", record_id: id }));
		}
	}

	const glImpcatCols = [

		{
			title: "Account Name",
			dataIndex: "account_name",
		},
		{
			title: "Debit",
			dataIndex: "debit",
			render: (text, record, index) => {
				return (
					<>
						$ {numeral(record.debit).format("0,0.00")}
					</>
				)
			},
		},
		{
			title: "Credit",
			dataIndex: "credit",
			render: (text, record, index) => {
				return (
					<>
						$ {numeral(record.credit).format("0,0.00")}
					</>
				)
			},
		},

		{
			title: "created_date",
			dataIndex: "created_date",
		}
	]

	const onSelectCustomer = (e) => {
		setCustomer(e);
		dispatch(getCustomerRefundDetails(token, e)).then((res) => {
			if (res.type === "GET_CUSTOMER_REFUND_DETAIL_SUCCESS") {
				setOrderItems(res.payload.data);
				setList(res.payload.data);

			}
		});
	};

	const addedItems = (value) => {
		const fillArr = [...orderItems];
		const onlyKey = [...value].map((sItem) => sItem.item_code);
		const indexofArr = fillArr.findIndex((f) => {
			return onlyKey.find((i) => i === f.item_code);
		});
		fillArr.splice(indexofArr, 1);
		if (orderItems.length > 0) {
			let uniqueOrderItems = [...orderItems, ...value];
			
			uniqueOrderItems = uniqueOrderItems.filter((v, i, a) => a.findIndex((t) => t.item_code === v.item_code) === i);
			setOrderItems(uniqueOrderItems);
		} else {
			
			let uniqueOrderItems = [...visibleItems, ...value];
			uniqueOrderItems = uniqueOrderItems.filter((v, i, a) => a.findIndex((t) => t.item_code === v.item_code) === i);
			setOrderItems(uniqueOrderItems);
		}
	};

	const onFinishFailed = () => {
		let errors = form.getFieldsError().filter((item) => item.errors.length > 0);
		Swal.fire({
			icon: "error",
			title: "Oops...",
			text: errors[0]?.errors[0],
		});
	};

	const handleCustomerChanges = (val) => {
		if (val === "" || val === 'All') return
		setCustomer(val);
		dispatch(loadCustomers(token, null, null, null, null, null, val, null, null, null, null, null, null, null, null, null, null, null, null)).then(
			(res) => {
				if (res?.type === "GET_ALL_CUSTOMERS_SUCCESS") {
					setCustomer(res?.payload?.data)
				}
			}
		);
	};

	function debounce(cb, delay = 1000) {
		let timeout
		return (...args) => {
			clearTimeout(timeout)
			timeout = setTimeout(() => {
				cb(...args)
			}, delay)
		}
	}

	const optimizedFn = useCallback(debounce(handleCustomerChanges), []);

	const changingSelect = (rowIndex, selected, record) => event => {
		
		
		
		
		
		

		
		
		
		
		
		
		
		
		const copyArr = [...orderItems];
		
		if (event.target.checked) {
			copyArr[rowIndex].applied_amount = record.remaining_amount;
			copyArr[rowIndex].is_selected = true;

		} else {
			copyArr[rowIndex].applied_amount = 0;
			copyArr[rowIndex].is_selected = false;
		}
		setOrderItems(copyArr);

	};

	const changingPaymentSelect = (rowIndex, columnKey, record) => event => {
		const newrightsColumns = [...list1];
		const index = newrightsColumns.findIndex(ele => ele.is_selected === record.is_selected)
		
		newrightsColumns[rowIndex][columnKey] = event.target.checked;
		setList(newrightsColumns);
		const index1 = updatedList.findIndex(ele => ele.is_selected === record.is_selected)
		const ispresent = updatedList[index1]
		if (ispresent) {
			updatedList[ispresent][columnKey] = event.target.checked
		} else {
			updatedList.push(record)
		}
	};

	const updateAmount = (type, id, value) => {
		let arr = [...orderItems];
		value = value;
		const filter = arr.map((item) => {
			if (item.note_header_id === id) {
				if (parseInt(value) === 0) {
					setSelectedRowKey(
						selectedRowKey.filter((items) => items !== item.note_header_id)
					);
				} else {
					setSelectedRowKey([...selectedRowKey, item.note_header_id]);
				}
				if (value > item.remaining_amount) {
					Swal.fire({
						icon: "error",
						title: "Oops...",
						text: "Refund Amount should not be greater then remaining amount",
					});
				}
				if (type === "up") {
					item.applied_amount = value > item.remaining_amount ? item.remaining_amount : value;
					
					
					return item;
				}
			} else {
				return item;
			}
		});
		let selectItem = filter.filter((f) => f.fulfill);
		
		setFilteredItems(selectItem);
		setVisibleItems(filter);
	};
	const columns = [
		{
			title: "Selected",
			dataIndex: "is_selected",
			key: "is_selected",
			render: (value, record, rowIndex) => (
				<Checkbox
					checked={record?.is_selected}
					
					onChange={changingSelect(orderItems.findIndex((v) => v.note_header_id === record?.note_header_id), "is_selected", record)}

				/>
			)
		},

		{
			title: "Note Header No",
			dataIndex: "note_header_no",
		},
		{
			title: "Transaction Type",
			dataIndex: "transaction_type",
		},

		{
			title: "Amount",
			dataIndex: "amount",
			editable: true,
			render: (value, record) => (
				<>{numeral(record.amount).format("00.00")}</>
			)
		},
		{
			title: "Remaining Amount",
			dataIndex: "remaining_amount",
			editable: true,
			render: (value, record) => (
				<>{numeral(record.remaining_amount).format("00.00")}</>
			)
		},
		{
			title: "Note Date",
			dataIndex: "note_date",
			render: (value, record) => (
				<>{moment(record.note_date).format("MM-DD-YYYY")}</>
			)
		},
		{
			title: "RA #",
			dataIndex: "ra_no",
			editable: true,
			render: (text, record, index) => {
				return <>{record.ra_no}</>;
			},
		},

		{
			title: "Invoice #",
			dataIndex: "invoice_header_no",
			editable: true,
			render: (text, record, index) => {
				return <>{record.invoice_header_no}</>;
			},
		},
		{
			title: "Applied Amount",
			dataIndex: "applied_amount",
			
			render: (text, record) => {
				return (
					<InputNumber
						size="small"
						min="0"
						
						onStep={(value, info) => {
							updateAmount(info.type, record.note_header_id, value)
						}}
						value={record.applied_amount}
						onChange={(value) => updateAmount("up", record.note_header_id, value)}
						defaultValue={0}
						disabled={!(formState === "add" || formState === "edit")}
					/>
				);
			},
		},
		
		{
			title: "Reciept #",
			dataIndex: "receipt_header_no",
			editable: true,
			
			
			
		},
		{
			title: "Gender Name",
			dataIndex: "gender_name",
			editable: true,
			
			
			
		},
		{
			title: "Term Name",
			dataIndex: "term_name",
			editable: true,
			
			
			
		},
		{
			title: "Access",
			dataIndex: "",
			editable: true,
			render: (value, record) => (
				<> {value?.transaction_type === "Payment" && formState === "edit" ? <Button onClick={() =>
					dispatch(customerPaymentAccess(token, value?.note_header_id)).then((res) => {
						if (res.type === "CUSTOMER_PAYMENT_ACCESS_SUCCESS") {
							notification("success", res?.payload?.message);
						}
						if (res.type === "CUSTOMER_PAYMENT_ACCESS_ERROR") {
							Swal.fire({
								icon: "error",
								title: "Oops...",
								text: res?.payload?.data?.message,
							});
						}
					})
				}>Add</Button> : null}</>
			)
		},
		{
			title: formState === "edit" && "Operation",
			dataIndex: formState === "edit" && "operation",
			render: (_, record) => (
				<Tooltip title="Delete" color={"white"}>
					{
						formState === "edit" ? (
							<Popconfirm title={`Are you sure to delete ?`} onConfirm={() => handleDeleteOrder(record)}>
								<DeleteOutlined className="delete-Button" />
							</Popconfirm>
						) : null
					}
				</Tooltip>
			),
		},
	];

	const renderColumns = () => {
		const keys = Object.keys(systemLog?.payload?.data[0])
		return keys.map((it, i) => {
			return {
				title: it,
				dataIndex: it,
				key: i.toString(),
			}
		})
	}
	const onChange4 = ({ target: { value } }) => {
		setValue4(value);
	};



	useEffect(() => {
		let result = invoiceGlPeriod?.payload?.find((item) => {
			return item.period_name === currentDate
		});
		setCurrentDate(result?.period_id);
	}, []);


	function checkTransactionDate(e) {
		let transaction_date = moment(e).format("YYYY-MM-DD")
		setLoading(true)
		dispatch(getTransationLock(token, transaction_date)).then((r) => {
			setLoading(false)
			if (r.payload.data.length > 0) {
				setOrderDate(moment())
				form.setFieldsValue({
					date: moment()
				})
				Swal.fire({ icon: "error", title: "Oops...", text: "Selected date is in locked period" })
			}
			else {
				setOrderDate(moment(e))
			}
		});
	}


	return (
		<>
			<EditableTable
				token={token}
				companyId={companyId}
				modalValue={isModalVisible}
				setIsModalVisible={setIsModalVisible}
				setOrderItems={addedItems}
				orderItems={orderItems}
				setShowGrid={setShowGrid}
			/>
			<Form requiredMark={false} autoComplete="off" onFinish={onFinish} form={form} onFinishFailed={onFinishFailed} name="basic">
				{customerRefundFormData.loading ||
					(loading && (
						<div className="loaderDiv">
							<Loader type="Grid" color="#212121" height={40} width={40} />
						</div>
					))}
				<div className="saleOrderForm">
					<SubTitles name={"Primary Information"} />
					<div className="primaryInfo container-fluid pbpx-0 ptpx-10">
						<Brow>
							<Bcol md={12} xl={9} xxl={8} className="pbpx-10">
								<Brow>

									<Bcol lg={6} xxl={6}>
										<div className="firstSectionRo">
											<Form.Item name="transactionNo" label="Transaction #" labelAlign="left" labelCol={{ span: 5 }} defaultValue={form.getFieldValue().transactionNo} >
												<Input disabled={true} tabIndex={18} size="small" className="customInputs" readOnly />
											</Form.Item>
										</div>
									</Bcol>

									<Bcol lg={6} xxl={6}>
										<div className="firstSectionRo">
											<Form.Item label="Date" name="date" labelAlign="left" labelCol={{ span: 5 }} rules={[{ required: true, message: "Date is required" }]}>

												<DatePicker
													size="small"
													disabled={!(formState === "add" || formState === "edit")}
													format="MM-DD-YYYY"
													
													allowClear
													className="dateInput customInputs"
													
													
													
													
													
													onChange={(e) => {

														setOrderDate(moment(e))
														let result = invoiceGlPeriod?.payload?.find((item) => {
															return item.period_name === moment(e).format("MMM YYYY")
														});
														setCurrentDate(result?.period_id);
														form.setFieldsValue({ postingPeriod: result?.period_id });
														checkTransactionDate(e);
													}}
												/>
											</Form.Item>
										</div>
									</Bcol>
									<Bcol lg={6} xxl={6}>
										<div className="firstSectionRo">
											{/* <Form.Item label="Posting Period" name="PostingPeriod" labelAlign="left" labelCol={{ span: 5 }} defaultValue={form.getFieldValue().postingPeriod}> */}
											<Form.Item label="Posting Period" name="postingPeriod" labelAlign="left" labelCol={{ span: 5 }} defaultValue={form.getFieldValue().postingPeriod} rules={[{ required: true, message: "PostingPeriod is required" }]}>
												<Select
													size="small"
													autoComplete={"nope"}
													allowClear
													tabIndex={18}
													showSearch
													optionFilterProp="children"
													className="filter-fields"
													disabled={!(formState === "add" || formState === "edit")}
													loading={invoiceGlPeriod.loading}
													defaultValue={form.getFieldValue().postingPeriod}
													
													
													onChange={(value) => {
														form.setFieldsValue({
															postingPeriod: value,
														});
													}}
												>
													{invoiceGlPeriod?.payload?.map((item) => {
														return (
															<Option key={item.period_id} value={item.period_id}>
																{item.period_name}
															</Option>
														);
													})}
												</Select>
											</Form.Item>
										</div>
									</Bcol>
									{/* <Bcol lg={6} xxl={6}>
										<div className="firstSectionRo">
											<Form.Item label="Account" name="account" labelAlign="left" labelCol={{ span: 5 }} defaultValue={form.getFieldValue().account} rules={[{ required: true, message: "Account is required" }]}>
												<Select
													size="small"
													autoComplete={"nope"}
													allowClear
													tabIndex={18}
													showSearch
													optionFilterProp="children"
													className="filter-fields"
													disabled={!(formState === "add" || formState === "edit")}
													loading={arAccount.loading}
													defaultValue={form.getFieldValue().account}
													
													onChange={(value) => {
														form.setFieldsValue({
															account: value,
														});
													}}
												>
													{arAccount?.payload?.data.map((item) => {
														return (
															<Option key={item.account_id} value={item.account_id}>
																{item.account_name}
															</Option>
														);
													})}
												</Select>
											</Form.Item>
										</div>
									</Bcol> */}
									<Bcol lg={6} xxl={6}>
										<div className="firstSectionRo">
											<Form.Item name="memo" label="Memo" labelAlign="left" labelCol={{ span: 5 }} >
												<Input tabIndex={18} size="small" className="customInputs" disabled={!(formState === "add" || formState === "edit")} />
											</Form.Item>
										</div>
									</Bcol>
									<Bcol lg={6} xxl={6}>
										<div className="firstSectionRo">
											<Form.Item
												labelAlign="left"
												name="customerID"
												label="Customer"
												labelCol={{ span: 5 }}
												rules={[{ required: true, message: "Customer is required" }]}
											>
												<Select
													notFoundContent={customerForms?.loading ? <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
														<Loader type="Oval" color="#3c4b58" height={20} width={20} />
													</div> : <div style={{ display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center" }}>No Data</div>}
													size="small"
													tabIndex={18}
													autoComplete={"nope"}
													allowClear
													showSearch
													disabled={!(formState === "add" || formState === "edit")}
													optionFilterProp="children"
													className="filter-fields"
													onSelect={(e) => onSelectCustomer(e)}
													loading={customerForms?.loading}
													
													onSearch={(value) => {
														optimizedFn(value)
													}}
													ref={focusInput}
													value={customer}
													filterSort={(optionA, optionB) =>
														optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
													}
												>
													{customerForms?.payload?.data?.map(
														(item) => {
															return (
																<Option
																	key={item.customer_id}
																	value={item.customer_id}
																>
																	{`${item.customer_code} ${item.customer_name}`}
																</Option>
															);
														}
													)}
												</Select>
											</Form.Item>

										</div>
									</Bcol>
									<Bcol lg={6} xxl={6}>
										<div className="firstSectionRo">
											<Form.Item label="A/R Account" name="arAccount" labelAlign="left" labelCol={{ span: 5 }} defaultValue={form.getFieldValue().arAccount} rules={[{ required: true, message: "A/R Account is required" }]}>
												<Select
													size="small"
													autoComplete={"nope"}
													allowClear
													tabIndex={18}
													showSearch
													optionFilterProp="children"
													className="filter-fields"
													disabled={(!(formState === "add" || formState === "edit"))}
													loading={arAccount?.loading}
													defaultValue={form.getFieldValue().arAccount}
													
													onChange={(value) => {
														form.setFieldsValue({
															arAccount: value,
														});
													}}
													filterSort={(optionA, optionB) =>
														optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
													}
												>
													{arAccount?.payload?.data?.map((item) => {
														return (
															<Option key={item.account_id} value={item.account_id}>
																{item.account_name}
															</Option>
														);
													})}
												</Select>
											</Form.Item>

										</div>
									</Bcol>
									<Bcol lg={6} xxl={6}>
										<div className="firstSectionRo">
											<Form.Item
												label="Debit Account"
												name="debit_account_id"
												labelAlign="left"
												labelCol={{ span: 5 }}
												defaultValue={form.getFieldValue().debit_account_id}
												rules={[
													{
														required: true,
														message: "Debit Account is required",
													},
												]}
											>
												<Select
													size="small"
													autoComplete={"nope"}
													allowClear
													tabIndex={18}
													showSearch
													optionFilterProp="children"
													className="filter-fields"
													
													disabled={
														!(formState === "add" || formState === "edit")
													}
													loading={customrPayment?.loading}
													filterSort={(optionA, optionB) =>
														optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
													}
												
												
												
												
												
												
												
												>
													{customrPayment?.payload?.data?.map((item) => {
														return (
															<Option
																key={item.account_id}
																value={item.account_id}
															>
																{item.account_name}
															</Option>
														);
													})}
												</Select>
											</Form.Item>
										</div>
									</Bcol>
									<Bcol lg={6} xxl={6}>
										<div className="firstSectionRo">
											<Form.Item name="total" label="Total" labelAlign="left" labelCol={{ span: 5 }} >
												<p>{totalSumHandler().total}</p>
												{/* <Input readOnly tabIndex={18} size="small" className="customInputs" disabled={true} /> */}
											</Form.Item>
										</div>
									</Bcol>
								</Brow>
							</Bcol>

						</Brow>
						<Button htmlType="submit" style={{ display: "none" }} ref={submitButton} onClick={onFinish} />
					</div>
					<Tabs type="card" size="small" defaultActiveKey="1" centered onChange={(key) => handleTab(key)}>

					</Tabs>
				</div>
				<SubTitles name={"Classification"} />
				<div className="primaryInfo container-fluid pbpx-0 ptpx-10">
					<Brow>
						<Bcol md={12} xl={9} xxl={8} className="pbpx-10">
							<Brow>

								<Bcol lg={6} xxl={6}>
									<div className="firstSectionRo">

										<Form.Item label="Subsidiary" name="subsidiary" labelAlign="left" labelCol={{ span: 5 }} defaultValue={form.getFieldValue().subsidiary} rules={[{ required: true, message: "Subsidiary is required" }]}>
											<Select
												size="small"
												tabIndex={18}
												autoComplete={"nope"}
												allowClear
												showSearch
												optionFilterProp="children"
												className="filter-fields"
												disabled={!(formState === "add" || formState === "edit")}
												loading={invoiceGlPeriod.loading}
												defaultValue={form.getFieldValue().subsidiary}
												
												
												onChange={(value) => {
													form.setFieldsValue({
														subsidiary: value,
													});
												}}
												filterSort={(optionA, optionB) =>
													optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
												}
											>
												{companyAll?.payload?.data.map((item) => {
													return (
														<Option key={item.company_id} value={item.company_id}>
															{item.company_name}
														</Option>
													);
												})}
											</Select>
										</Form.Item>
									</div>
								</Bcol>

								<Bcol lg={6} xxl={6}>
									<div className="firstSectionRo">
										<Form.Item name="remarks" label="Remarks" labelAlign="left" labelCol={{ span: 5 }} >
											<Input tabIndex={18} size="small" className="customInputs" disabled={!(formState === "add" || formState === "edit")} />
										</Form.Item>
									</div>
								</Bcol>
							</Brow>
						</Bcol>
					</Brow>
					<Button htmlType="submit" style={{ display: "none" }} ref={submitButton} onClick={onFinish} />
					<Tabs type="card" size="small" defaultActiveKey="1" centered onChange={(key) => handleTab(key)}>
						<TabPane tab="Apply" key="1">
							{(orderItems.length > 0 || visibleItems.length > 0) && (
								<Table
									
									type={"company"}
									loading={orderItems.loading}
									disabled={!(formState === "add" || formState === "edit")}
									scroll={({ x: 600 }, { y: 400 })}
									size="small"
									bordered
									
									dataSource={orderItems.map((it) => ({
										...it,
										key: it.note_header_id,
									}))}
									columns={columns}
									pagination={true}

								/>
							)}
						</TabPane>
						{(formState === "edit" || formState === "formView") &&
							<TabPane tab="GL Impact" key="gl_impact">
								<div className="tabs">
									<Tables
										type="company"
										scrollX={"50rem"}
										scroll={360}
										perPage={15}
										source={glImpact?.payload?.data}
										col={glImpcatCols}
										load={glImpact?.loading}
										paginate
									/>
								</div>
							</TabPane>}
						{(formState === "edit" || formState === "formView") &&
							<TabPane tab="System Information" key="logs" >
								<Tabs
									type="card"
									size="small"
									defaultActiveKey="1"
									centered
									onChange={(key) => handleTab(key)}
								>
									<TabPane tab="Header" key="header">
										<div className="tabs">
											{systemLog?.payload?.data?.length > 0 && <Tables
												type="company"
												scrollX={"50rem"}
												scroll={360}
												perPage={15}
												source={systemLog?.payload?.data}
												dataSource={[]}
												col={renderColumns()}
												load={systemLog.loading}
												paginate
											/>}
										</div>
									</TabPane>
									<TabPane tab="Line" key="line">
										<div className="tabs">
											{systemLog?.payload?.data?.length > 0 && <Tables
												type="company"
												scrollX={"50rem"}
												scroll={360}
												perPage={15}
												source={systemLog?.payload?.data}
												dataSource={[]}
												col={renderColumns()}
												load={systemLog.loading}
												paginate
											/>}
										</div>
									</TabPane>
								</Tabs>
							</TabPane>}
					</Tabs>
				</div>
			</Form >
		</>
	);
}
