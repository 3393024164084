import PageHeader from '../../components/PageHeader/PageHeader';
import "../Style/style.css";
import { Row, Col, Form, Input, Tabs, Divider, Checkbox, Select, } from 'antd';
import CrudButtons from "../../components/CrudButtons/CrudButtons";
import { ReadCookie } from '../../utils/readCookie';
import { useRef, useState, useEffect, useLayoutEffect } from 'react';
import { getRights } from '../../redux/actions/userRights.action';
import { useDispatch, useSelector } from 'react-redux';
import { loadAdminUser } from '../../redux/actions/adminUser.actions';
import { getAdminModule } from '../../redux/actions/adminModule.action';
import { getMenuRights, createMenuRights } from '../../redux/actions/menuRights.actions';
import { getMenu } from '../../redux/actions/menus';
import notification from '../../utils/notification'
import '../User/user.css'
import { SearchOutlined, FilterFilled } from '@ant-design/icons';

import '../salesPerson/saleperson.css'
import Loader from 'react-loader-spinner';
import '../Product/product.css'
import Tables from '../../components/Table/Tables';
import { TabTitle } from '../../utils/GeneralFunction';

const { TabPane } = Tabs;
const { Option } = Select;





const UserRights = ({ token }) => {
    TabTitle("User Rights")

    const id = ReadCookie('menuId');
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [minimize, setMinimize] = useState(false);
    const [formState, setFormState] = useState('view');
    const { users, module, menuRights, userRights } = useSelector(state => state);
    const [list1, setList] = useState([]);
    const userNameInput = useRef(null);
    const moduleNameInput = useRef(null);
    const [menuId, setMenuId] = useState(null);
    const [singleMenu, setSingleMenu] = useState(null);
    const [userId, setUserId] = useState(null);
    const [allMenus, setAllMenus] = useState([]);
    const [oneMenu, setOneMenu] = useState(null);
    const [updatedList, setUpdatedList] = useState([])
    const [viewCheck, setViewCheck] = useState({ column: "can_view", value: false })
    const [addCheck, setAddCheck] = useState({ column: "can_add", value: false })
    const [editCheck, setEditCheck] = useState({ column: "can_edit", value: false })
    const [deleteCheck, setDeleteCheck] = useState({ column: "can_delete", value: false })
    const [printCheck, setPrintCheck] = useState({ column: "can_print", value: false })
    
    
    
    
    

    
    
    
    
    
    
    




    const rightsColumns = [

        {
            title: "Form Menu Id",
            dataIndex: "menu_id",
            key: "menu_id",
            width: "25%",
            height: "10px",
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => {
                return (
                    <>
                        <Input
                            allowClear
                            autoFocus
                            className='pop-search-gen pop-170'
                            
                            value={selectedKeys[0]}
                            onChange={(e) => {
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                                confirm({ closeDropdown: false })
                            }}
                            onPressEnter={() => {
                                confirm();
                            }}
                            onBlur={() => {
                                confirm();
                            }}
                        />
                    </>
                );
            },
            filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
            onFilter: (value, record) =>
                record.menu_id.toString().toLowerCase().includes(value.toLowerCase()),

        },
        {
            title: "Form Name",
            dataIndex: "menu_name",
            key: "menu_name",
            width: "25%",
            height: "10px",
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => {
                return (
                    <>
                        <Input
                            allowClear
                            autoFocus
                            className='pop-search-gen pop-170'
                            
                            value={selectedKeys[0]}
                            onChange={(e) => {
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                                confirm({ closeDropdown: false })
                            }}
                            onPressEnter={() => {
                                confirm();
                            }}
                            onBlur={() => {
                                confirm();
                            }}
                        />
                    </>
                );
            },
            filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
            onFilter: (value, record) =>
                record.menu_name.toString().toLowerCase().includes(value.toLowerCase()),

        },
        {

            title: () => {
                return (
                    <div className='d-flex align-items-center'><Checkbox disabled={list1.length === 0} checked={viewCheck.value} onChange={selectingALL("can_view")} /><div className='user-right-table-txt'>View</div></div>
                )
            },
            dataIndex: "can_view",
            key: "can_view",
            width: "15%",
            height: "10px",



            render: (value, record, rowIndex) => (
                <Checkbox

                    disabled={formState === "view" || formState === "table"}
                    checked={value}
                    onChange={changingTable(rowIndex, "can_view", record)}

                />
            )
        }
        ,
        {
            title: () => {
                return (
                    <div className='d-flex align-items-center'><Checkbox disabled={list1.length === 0} checked={addCheck.value} onChange={selectingALL("can_add")} /><div className='user-right-table-txt'>Add</div></div>
                )
            },
            dataIndex: "can_add",
            key: "can_add",
            width: "15%",
            height: "10px",

            render: (value, record, rowIndex) => (
                <Checkbox

                    disabled={formState === "view" || formState === "table"}
                    checked={value}
                    onChange={changingTable(rowIndex, "can_add", record)}
                />
            )
        }, {

            title: () => {
                return (
                    <div className='d-flex align-items-center'><Checkbox disabled={list1.length === 0} checked={deleteCheck.value} onChange={selectingALL("can_delete")} /><div className='user-right-table-txt'>Delete</div></div>
                )
            },
            dataIndex: "can_delete",
            key: "can_delete",
            width: "15%",
            height: "10px",
            rowSelection: true,
            render: (value, record, rowIndex) => (
                <Checkbox

                    disabled={formState === "view" || formState === "table"}
                    checked={value}
                    onChange={changingTable(rowIndex, "can_delete", record)}
                />
            )
        },
        {

            title: () => {
                return (
                    <div className='d-flex align-items-center'><Checkbox disabled={list1.length === 0} checked={editCheck.value} onChange={selectingALL("can_edit")} /><div className='user-right-table-txt'>Edit</div></div>
                )
            },
            dataIndex: "can_edit",
            key: "can_edit",
            width: "15%",
            height: "10px",
            render: (value, record, rowIndex) => (

                <Checkbox

                    checked={value}
                    disabled={formState === "view" || formState === "table"}
                    onChange={changingTable(rowIndex, "can_edit", record)}
                />
            )
        }, {
            title: () => {
                return (
                    <div className='d-flex align-items-center'><Checkbox disabled={list1.length === 0} checked={printCheck.value} onChange={selectingALL("can_print")} /><div className='user-right-table-txt'>Print</div></div>
                )
            },
            dataIndex: "can_print",
            key: "can_print",
            width: "15%",
            height: "10px",
            render: (value, record, rowIndex) => (
                <Checkbox
                    disabled={formState === "view" || formState === "table"}
                    checked={value}
                    onChange={changingTable(rowIndex, "can_print", record)}

                />
            )


        }

    ]
    useLayoutEffect(() => {
        dispatch(getRights(token, id)).then((res) => {
            if (res.type === "GET_USER_RIGHTS_SUCCESS") {
                if (!res?.payload.can_add) {
                    setFormState("table")
                }
            }
        });
    }, [])

    useEffect(() => {
        dispatch(loadAdminUser(token))
    }, [token])
    useEffect(() => {
        dispatch(getAdminModule(token, id))
    }, [token])
    useEffect(() => {
        if (menuId === null) {
            return
        } else {
            dispatch(getMenuRights(token, userId, menuId)).then((res) => {
                if (res.type === "GET_MENU_RIGHTS_SUCCESS") {

                    setList(res?.payload)

                }
            })
        }
    }, [token, menuId, userId])


    const changingTable = (rowIndex, columnKey, record) => event => {
        const newrightsColumns = [...list1];
        const index = newrightsColumns.findIndex(ele => ele.menu_name === record.menu_name)
        newrightsColumns[index][columnKey] = event.target.checked
        
        setList(newrightsColumns);
        const index1 = updatedList.findIndex(ele => ele.menu_name === record.menu_name)
        const ispresent = updatedList[index1]
        if (ispresent) {
            updatedList[ispresent][columnKey] = event.target.checked
        } else {
            updatedList.push(record)
        }


    };



    const formSave = () => {
        form.validateFields().then((values) => {
            dispatch(createMenuRights(token, userId, updatedList)).then((res) => {
                if (res.type === "ADD_MENU_RIGHTS_SUCCESS") {
                    notification("success", "Created Successfully", "Record inserted successfully")
                    dispatch(getMenu(token))

                } if (res.type === "ADD_MENU_RIGHTS_ERROR") {
                    notification("error", "Not Created", "Something went wrong")
                }
                setUpdatedList([])
                setViewCheck({ value: false, column: "can_view" })
                setAddCheck({ value: false, column: "can_add" })
                setEditCheck({ value: false, column: "can_edit" })
                setDeleteCheck({ value: false, column: "can_delete" })
                setPrintCheck({ value: false, column: "can_print" })


            })



        }).catch((err) => {

        })
    }


    const selectHandler = (e) => {
        setMenuId(e)
        setAllMenus([])
        setSingleMenu(null)
        setOneMenu(null)
        form.setFieldsValue({
            fid: null,

        })

    }
    const selectHandlerforUser = (e) => {
        setUserId(e)
    }
    const tableLoading = {
        spinning: menuRights?.loading,
        indicator: <Loader width={22} height={22} type="Grid" color='rgba(44, 62, 80,0.8)' />
    }
    const selectHandlerforModule = (e) => {

        setAllMenus([])

        setOneMenu(e.split(",")[0])

    }
    const selectingALL = (columnKey) => event => {
        switch (columnKey) {
            case ("can_view"):
                setViewCheck({ value: event.target.checked, column: "can_print" })
                break;
            case ("can_add"):
                setAddCheck({ value: event.target.checked, column: "can_add" })
                break;
            case ("can_edit"):
                setEditCheck({ value: event.target.checked, column: "can_edit" })
                break;
            case ("can_delete"):
                setDeleteCheck({ value: event.target.checked, column: "can_delete" })
                break;
            default:
                setPrintCheck({ value: event.target.checked, column: "can_print" })
                break;
        }
        const newrightsColumns = [...list1];
        newrightsColumns.forEach((f) => {
            f[columnKey] = event.target.checked
        })
        setList(newrightsColumns)
        setUpdatedList(newrightsColumns)


    }
    const filterReset = () => {
        setFormState("view")

        form.setFieldsValue({
            mid: null,
            uid: null,
            fid: null,
        })

        setAllMenus([])
        setUserId(null)
        setMenuId(null)
        setSingleMenu(null)
        setOneMenu(null)
        setList([])




    }
    return (
        <div className='styleContainer' >
            <div className='styleBody h-100' >
                <PageHeader title={"USER RIGHTS"} minimize={setMinimize} check={minimize} />
                {
                    !minimize &&
                    <div className='bodyStyle'  >
                        <div className='crudDiv' ><CrudButtons formType={formState} formState={setFormState} save={formSave} reset={filterReset} /**  rights={userRights?.payload}  disable={disable}  onDelete={handleDelete} */ /></div>
                        <div style={{ padding: "10px 10px 0 10px" }} >
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <div className='styleForm'  >
                                        <div className='testStyle' >
                                            <Form
                                                form={form}
                                                name="basic"
                                            >
                                                <div className='customRow styleFormUpper ' ></div>

                                                <div className="customCols slc">
                                                    <div className='relative'>
                                                        <Form.Item
                                                            name="uid"
                                                            label="User"
                                                            labelAlign='left'
                                                            labelCol={{ span: 8 }}
                                                            wrapperCol={{ span: 16 }}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: "User is required",
                                                                },
                                                            ]}
                                                        >
                                                            <Select
                                                                size='small'
                                                                ref={userNameInput}
                                                                showSearch
                                                                loading={users.loading}
                                                                disabled={
                                                                    !(formState === "add" || formState === "edit")
                                                                }
                                                                optionFilterProp="children"
                                                                className="w-100"
                                                                

                                                                filterOption={(input, option) =>
                                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                }

                                                                onSelect={(e) => selectHandlerforUser(e)}
                                                                allowClear
                                                                filterSort={(optionA, optionB) =>
                                                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                              }
                                                            >
                                                                {users?.payload?.data.map((user) => {
                                                                    return (
                                                                        <Option value={user.user_id}>
                                                                            {user.login_id}
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                                <div className="customCols slc">
                                                    <div className='relative'>
                                                        <Form.Item
                                                            name="mid"
                                                            label="Module"
                                                            labelAlign='left'
                                                            labelCol={{ span: 8 }}
                                                            wrapperCol={{ span: 16 }}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: "Module is required",
                                                                },
                                                            ]}
                                                        >
                                                            <Select
                                                                size='small'
                                                                ref={moduleNameInput}
                                                                showSearch
                                                                loading={users.loading}
                                                                disabled={userId === null}
                                                                optionFilterProp="children"
                                                                className="w-100"
                                                                filterOption={(input, option) =>
                                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                }
                                                                onSelect={(e) => selectHandler(e)}
                                                                allowClear
                                                                filterSort={(optionA, optionB) =>
                                                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                              }
                                                            >
                                                                {module?.payload?.data.map((mod) => {
                                                                    return (
                                                                        <Option value={mod.module_id}>
                                                                            {mod.module_name}
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                        </Form.Item>
                                                    </div>
                                                </div>


                                            </Form>
                                        </div>
                                        <Divider />
                                        <div className='styleTab' >
                                            <Tabs type='card' tabBarGutter={2} defaultActiveKey="1" centered  >
                                                <TabPane tab="User Rights"   >
                                                    {/* <Table dataSource={list1} columns={rightsColumns} size="small" pagination={{ defaultPageSize: 13 }} bordered={true} scroll={false} loading={menuRights?.loading && tableLoading} /> */}
                                                    <Tables type="rights" scrollX={"50rem"} source={list1} col={rightsColumns} load={menuRights.loading} size="small" paginate={true} perPage={13} scroll={false} />




                                                </TabPane>
                                            </Tabs>
                                        </div>

                                    </div>

                                </Col>
                            </Row>
                        </div>
                    </div>

                }
            </div>
        </div>


    )


}


export default UserRights



