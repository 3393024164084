import {
  GET_CATEGORY_ERROR, GET_CATEGORY_REQUEST, GET_CATEGORY_SUCCESS, ADD_CATEGORY_REQUEST, ADD_CATEGORY_SUCCESS, ADD_CATEGORY_ERROR, DELETE_CATEGORY_REQUEST,
  DELETE_CATEGORY_SUCCESS, DELETE_CATEGORY_ERROR, UPDATE_CATEGORY_REQUEST, UPDATE_CATEGORY_SUCCESS, UPDATE_CATEGORY_ERROR, GET_ALL_CATEGORY_REQUEST, GET_ALL_CATEGORY_SUCCESS, GET_ALL_CATEGORY_ERROR, ADD_CUSTOMER_CATEGORY_REQUEST, ADD_CUSTOMER_CATEGORY_SUCCESS, ADD_CUSTOMER_CATEGORY_ERROR
} from "../actions/types";

export default function (state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case GET_ALL_CATEGORY_REQUEST:
      return {
        loading: true,
      };
    case GET_ALL_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        payload,
      };
    case GET_ALL_CATEGORY_ERROR:
      return {
        ...state,
        loading: false,
        payload,
      };
    case GET_CATEGORY_REQUEST:
      return {
        loading: true,
      };
    case GET_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        payload,
      };
    case GET_CATEGORY_ERROR:
      return {
        ...state,
        loading: false,
        payload,
      };
    case ADD_CATEGORY_REQUEST:
      return {
        loading: true,
      };
    case ADD_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: [payload],
      };
    case ADD_CATEGORY_ERROR:
      return {
        ...state,
        loading: false,
      };
    case ADD_CUSTOMER_CATEGORY_REQUEST:
      return {
        loading: true,
      };
    case ADD_CUSTOMER_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: [payload],
      };
    case ADD_CUSTOMER_CATEGORY_ERROR:
      return {
        ...state,
        loading: false,
      };

    case DELETE_CATEGORY_REQUEST:
      return {
        loading: true,
      };
    case DELETE_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: [payload],
      };
    case DELETE_CATEGORY_ERROR:
      return {
        ...state,
        loading: false,
      };

    case UPDATE_CATEGORY_REQUEST:
      return {
        loading: true,
      };
    case UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: [payload],
      };
    case UPDATE_CATEGORY_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
