import {
    GET_PO_LOOKUP_ERROR,
    GET_PO_LOOKUP_REQUEST,
    GET_PO_LOOKUP_SUCCESS,
} from "../actions/types"
export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {

        case GET_PO_LOOKUP_REQUEST:
            return {
                loading: true,
            };
        case GET_PO_LOOKUP_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_PO_LOOKUP_ERROR:
            return {
                ...state,
                loading: false
            };
        default:
            return state;
    }
}