import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import { store } from "./store";
import "bootstrap/dist/css/bootstrap.min.css";
import "./app.scss";
import "antd/dist/antd.variable.min.css";
import "./index.css";
import { ConfigProvider } from "antd";

ConfigProvider.config({
	theme: {
		primaryColor: "#3c4b58",
	},
});

ReactDOM.render(
	<Provider store={store}>
		<App />
	</Provider>,
	document.getElementById("root")
);
