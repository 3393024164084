import {
    GET_BERGEN_BILLING_REQUEST,
    GET_BERGEN_BILLING_SUCCESS,
    GET_BERGEN_BILLING_ERROR,
    ADD_BERGEN_BILLING_ERROR,
    ADD_BERGEN_BILLING_REQUEST,
    ADD_BERGEN_BILLING_SUCCESS,
    UPDATE_BERGEN_BILLING_SUCCESS,
    UPDATE_BERGEN_BILLING_ERROR,
    UPDATE_BERGEN_BILLING_REQUEST,
    DELETE_BERGEN_BILLING_ERROR,
    DELETE_BERGEN_BILLING_REQUEST,
    DELETE_BERGEN_BILLING_SUCCESS
  } from "../actions/types";
  
  export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
      case GET_BERGEN_BILLING_REQUEST:
        return {
          loading: true,
        };
      case GET_BERGEN_BILLING_SUCCESS:
        return {
          ...state,
          loading: false,
          payload,
        };
      case GET_BERGEN_BILLING_ERROR:
        return {
          ...state,
          loading: false,
          payload,
        };
        case ADD_BERGEN_BILLING_REQUEST:
          return {
              loading: true,
          };
      case ADD_BERGEN_BILLING_SUCCESS:
          return {
              ...state,
              loading: false,
              payload: [payload],
          };
      case ADD_BERGEN_BILLING_ERROR:
          return {
              ...state,
              loading: false
          };
          case UPDATE_BERGEN_BILLING_REQUEST:
            return {
                loading: true,
            };
        case UPDATE_BERGEN_BILLING_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case UPDATE_BERGEN_BILLING_ERROR:
            return {
                ...state,
                loading: false
            };
            case DELETE_BERGEN_BILLING_REQUEST:
              return {
                  loading: true,
              };
          case DELETE_BERGEN_BILLING_SUCCESS:
              return {
                  ...state,
                  loading: false,
                  payload: [payload],
              };
          case DELETE_BERGEN_BILLING_ERROR:
              return {
                  ...state,
                  loading: false
              };


      default:
        return state;
    }
  }
  