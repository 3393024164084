import {
    GET_WASHTYPE_REQUEST, GET_WASHTYPE_SUCCESS, GET_WASHTYPE_ERROR,
    ADD_WASHTYPE_REQUEST, ADD_WASHTYPE_SUCCESS, ADD_WASHTYPE_ERROR,
    UPDATE_WASHTYPE_REQUEST, UPDATE_WASHTYPE_SUCCESS, UPDATE_WASHTYPE_ERROR,
    DELETE_WASHTYPE_REQUEST, DELETE_WASHTYPE_SUCCESS, DELETE_WASHTYPE_ERROR,
} from "../actions/types"
export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case GET_WASHTYPE_REQUEST:
            return {
                loading: true,
            };
        case GET_WASHTYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_WASHTYPE_ERROR:
            return {
                ...state,
                loading: false
            };
        case ADD_WASHTYPE_REQUEST:
            return {
                loading: true,
            };
        case ADD_WASHTYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case ADD_WASHTYPE_ERROR:
            return {
                ...state,
                loading: false
            };
        case DELETE_WASHTYPE_REQUEST:
            return {
                loading: true,
            };
        case DELETE_WASHTYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case DELETE_WASHTYPE_ERROR:
            return {
                ...state,
                loading: false
            };
        case UPDATE_WASHTYPE_REQUEST:
            return {
                loading: true,
            };
        case UPDATE_WASHTYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case UPDATE_WASHTYPE_ERROR:
            return {
                ...state,
                loading: false
            };

        default:
            return state;
    }
}