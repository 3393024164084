import {
	GET_ONE_B_GRADE_STOCK_REQUEST,
	GET_ONE_B_GRADE_STOCK_SUCCESS,
	GET_ONE_B_GRADE_STOCK_ERROR,
} from "../actions/types";
export default function (state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case GET_ONE_B_GRADE_STOCK_REQUEST:
      return {
        loading: true,
      };
    case GET_ONE_B_GRADE_STOCK_SUCCESS:
      return {
        ...state,
        loading: false,
        payload,
      };
    case GET_ONE_B_GRADE_STOCK_ERROR:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
