import {
    GET_TRACKING_STATUS_REQUEST,
GET_TRACKING_STATUS_SUCCESS,
GET_TRACKING_STATUS_ERROR

} from "./types";
import axios from "axios";
import { BASEURL } from "../../constant/config.js";


export const getTrackingStatus= (token) => (dispatch) => {
    dispatch({ type: GET_TRACKING_STATUS_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/TrackingStatus`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
    })
        .then((response) => {
            return dispatch({
                type: GET_TRACKING_STATUS_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) => {
            dispatch({
                type: GET_TRACKING_STATUS_ERROR,
                payload: [],
            });
            
            
            
            
            
            
            
            
            
            
        });
};
