import {
  GET_FABRICUSABLE_REQUEST,
  GET_FABRICUSABLE_SUCCESS,
  GET_FABRICUSABLE_ERROR,
  ADD_FABRICUSABLE_REQUEST,
  ADD_FABRICUSABLE_ERROR,
  ADD_FABRICUSABLE_SUCCESS,
  UPDATE_FABRICUSABLE_REQUEST,
  UPDATE_FABRICUSABLE_SUCCESS,
  UPDATE_FABRICUSABLE_ERROR,
  DELETE_FABRICUSABLE_ERROR,
  DELETE_FABRICUSABLE_REQUEST,
  DELETE_FABRICUSABLE_SUCCESS,
} from "./types";

import { BASEURL } from "../../constant/config.js";

const axios = require("axios");

export const getFabricUsable = (token, menu_id) => (dispatch) => {
  dispatch({ type: GET_FABRICUSABLE_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}getAll/fabricUsable`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: { menu_id },
  })
    .then((response) => {
      return dispatch({ type: GET_FABRICUSABLE_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      return dispatch({ type: GET_FABRICUSABLE_ERROR, payload: error.response });
    });
};

export const addFabricUsable = (token, data) => (dispatch) => {
  
  dispatch({ type: ADD_FABRICUSABLE_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}create/fabricUsable`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: { fabric_usable_name: data.fabricUsableName, is_active: data.check },
  })
    .then((response) => {
      
      return dispatch({ type: ADD_FABRICUSABLE_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      return dispatch({
        type: ADD_FABRICUSABLE_ERROR,
        payload: error.response,
      });
    });
};
export const updateFabricUsable = (token, data, id) => (dispatch) => {
  dispatch({ type: UPDATE_FABRICUSABLE_REQUEST });
  return axios({
    method: "PUT",
    url: `${BASEURL}update/fabricUsable/${id}`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: { fabric_usable_name: data.fabricUsableName, is_active: data.check },
  })
    .then((response) => {

      return dispatch({
        type: UPDATE_FABRICUSABLE_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: UPDATE_FABRICUSABLE_ERROR,
        payload: error.response,
      });
    });
};

export const deleteFabricUsable = (token, id) => (dispatch) => {
  dispatch({ type: DELETE_FABRICUSABLE_REQUEST });
  return axios({
    method: "DELETE",
    url: `${BASEURL}delete/fabricUsable/${id}`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {
      
      return dispatch({
        type: DELETE_FABRICUSABLE_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: DELETE_FABRICUSABLE_ERROR,
        payload: error.response,
      });
    });
};
