import {
	GET_CREDIT_MEMO_LINE_REQUEST,
	GET_CREDIT_MEMO_LINE_SUCCESS,
	GET_CREDIT_MEMO_LINE_ERROR,

	GET_ONE_CREDIT_MEMO_LINE_REQUEST,
	GET_ONE_CREDIT_MEMO_LINE_SUCCESS,
	GET_ONE_CREDIT_MEMO_LINE_ERROR,

	ADD_CREDIT_MEMO_LINE_REQUEST,
	ADD_CREDIT_MEMO_LINE_SUCCESS,
	ADD_CREDIT_MEMO_LINE_ERROR,

	UPDATE_CREDIT_MEMO_LINE_REQUEST,
	UPDATE_CREDIT_MEMO_LINE_SUCCESS,
	UPDATE_CREDIT_MEMO_LINE_ERROR,

	DELETE_CREDIT_MEMO_LINE_REQUEST,
	DELETE_CREDIT_MEMO_LINE_SUCCESS,
	DELETE_CREDIT_MEMO_LINE_ERROR
} from "../actions/types";

export default function (state = [], action) {
	const { type, payload } = action;
	switch (type) {
		case ADD_CREDIT_MEMO_LINE_REQUEST:
			return {
				loading: true,
			};
		case ADD_CREDIT_MEMO_LINE_SUCCESS:
			return {
				...state,
				loading: false,
				payload: payload,
			};
		case ADD_CREDIT_MEMO_LINE_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};

		case UPDATE_CREDIT_MEMO_LINE_REQUEST:
			return {
				loading: true,
			};
		case UPDATE_CREDIT_MEMO_LINE_SUCCESS:
			return {
				...state,
				loading: false,
				payloadLine: payload,
			};
		case UPDATE_CREDIT_MEMO_LINE_ERROR:
			return {
				...state,
				loading: false,
				payloadLine: payload,
			};

		case DELETE_CREDIT_MEMO_LINE_REQUEST:
			return {
				loading: true,
			};
		case DELETE_CREDIT_MEMO_LINE_SUCCESS:
			return {
				...state,
				loading: false,
				payload,
			};
		case DELETE_CREDIT_MEMO_LINE_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};

		case GET_ONE_CREDIT_MEMO_LINE_REQUEST:
			return {
				loading: true,
			};
		case GET_ONE_CREDIT_MEMO_LINE_SUCCESS:
			return {
				...state,
				loading: false,
				payload,
			};
		case GET_ONE_CREDIT_MEMO_LINE_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};

		case GET_CREDIT_MEMO_LINE_REQUEST:
			return {
				loading: true,
			};
		case GET_CREDIT_MEMO_LINE_SUCCESS:
			return {
				...state,
				loading: false,
				payload,
			};
		case GET_CREDIT_MEMO_LINE_ERROR:
			return {
				...state,
				loading: false,

			};

		default:
			return state;
	}
}