import { GET_SALE_RETURN_REPORT_REQUEST, GET_SALE_RETURN_REPORT_ERROR, GET_SALE_RETURN_REPORT_SUCCESS } from "./types";
import { BASEURL } from "../../constant/config.js";
const axios = require("axios");

export const getSaleReturnReport = (token, data) => (dispatch) => {
    dispatch({ type: GET_SALE_RETURN_REPORT_REQUEST });

    return axios({
        method: "POST",
        url: `${BASEURL}getAll/funcGetSaleReturnPrint`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: data,
    })
        .then((response) => {
            return dispatch({
                type: GET_SALE_RETURN_REPORT_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) => {
            dispatch({
                type: GET_SALE_RETURN_REPORT_ERROR,
                payload: [],
            });
        });
};