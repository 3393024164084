import {
    GET_GENDER_REQUEST, GET_GENDER_SUCCESS, GET_GENDER_ERROR,
    ADD_GENDER_REQUEST, ADD_GENDER_SUCCESS, ADD_GENDER_ERROR,
    UPDATE_GENDER_REQUEST, UPDATE_GENDER_SUCCESS, UPDATE_GENDER_ERROR,
    DELETE_GENDER_REQUEST, DELETE_GENDER_SUCCESS, DELETE_GENDER_ERROR,
} from "./types";

import { BASEURL } from "../../constant/config.js";

const axios = require("axios");

export const getGender = (token, menu_id, genderID) => (dispatch) => {
    dispatch({ type: GET_GENDER_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/gender`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { menu_id: menu_id ? menu_id : null, gender_id: genderID ? genderID : null }
    })
        .then((response) => {
            return dispatch({ type: GET_GENDER_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: GET_GENDER_ERROR,
                payload: error.response,
            });
        });
};

export const addGender = (token, data, menu_id) => (dispatch) => {
    dispatch({ type: ADD_GENDER_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}create/gender`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { gender_name: data.genderName, is_active: data.check, menu_id },
    })
        .then((response) => {

            return dispatch({ type: ADD_GENDER_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: ADD_GENDER_ERROR,
                payload: error.response,
            });
        });
};

export const updateGender = (token, data, id) => (dispatch) => {
    dispatch({ type: UPDATE_GENDER_REQUEST });
    return axios({
        method: "PUT",
        url: `${BASEURL}update/gender/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { gender_name: data.genderName, is_active: data.check },
    })
        .then((response) => {

            return dispatch({ type: UPDATE_GENDER_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: UPDATE_GENDER_ERROR,
                payload: error.response,
            });
        });
};

export const deleteGender = (token, id) => (dispatch) => {
    dispatch({ type: DELETE_GENDER_REQUEST });
    return axios({
        method: "DELETE",
        url: `${BASEURL}delete/gender/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
    })
        .then((response) => {
            return dispatch({ type: DELETE_GENDER_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: DELETE_GENDER_ERROR,
                payload: error.response,
            });
        });
};
