import {
	ADD_ITEM_TRANSFER_LINE_REQUEST, ADD_ITEM_TRANSFER_LINE_SUCCESS, ADD_ITEM_TRANSFER_LINE_ERROR, UPDATE_ITEM_TRANSFER_LINE_REQUEST, UPDATE_ITEM_TRANSFER_LINE_SUCCESS,
	UPDATE_ITEM_TRANSFER_LINE_ERROR, GET_ALL_SALES_RETURN_LINE_REQUEST, GET_ALL_SALES_RETURN_LINE_SUCCESS, GET_ALL_SALES_RETURN_LINE_ERROR, DELETE_SALES_RETURN_LINE_ERROR, DELETE_SALES_RETURN_LINE_SUCCESS, DELETE_SALES_RETURN_LINE_REQUEST, GET_ONE_ITEM_TRANSFER_LINE_REQUEST, GET_ONE_ITEM_TRANSFER_LINE_SUCCESS, GET_ONE_ITEM_TRANSFER_LINE_ERROR, GET_ONE_ITEM_TRANSFER_LINE_DETAIL_REQUEST, GET_ONE_ITEM_TRANSFER_LINE_DETAIL_SUCCESS, GET_ONE_ITEM_TRANSFER_LINE_DETAIL_ERROR,
	GET_ALL_PRODUCT_FOR_INT_REQUEST,
	GET_ALL_PRODUCT_FOR_INT_SUCCESS,
	GET_ALL_PRODUCT_FOR_INT_ERROR
} from "../actions/types";

export default function (state = [], action) {
	const { type, payload } = action;
	switch (type) {
		case ADD_ITEM_TRANSFER_LINE_REQUEST:
			return {
				loading: true,
			};
		case ADD_ITEM_TRANSFER_LINE_SUCCESS:
			return {
				...state,
				loading: false,
				payload: payload,
			};
		case ADD_ITEM_TRANSFER_LINE_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};

		case UPDATE_ITEM_TRANSFER_LINE_REQUEST:
			return {
				loading: true,
			};
		case UPDATE_ITEM_TRANSFER_LINE_SUCCESS:
			return {
				...state,
				loading: false,
				payloadLine: payload,
			};
		case UPDATE_ITEM_TRANSFER_LINE_ERROR:
			return {
				...state,
				loading: false,
				payloadLine: payload,
			};

		case DELETE_SALES_RETURN_LINE_REQUEST:
			return {
				loading: true,
			};
		case DELETE_SALES_RETURN_LINE_SUCCESS:
			return {
				...state,
				loading: false,
				payload,
			};
		case DELETE_SALES_RETURN_LINE_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};

		case GET_ALL_SALES_RETURN_LINE_REQUEST:
			return {
				loading: true,
			};
		case GET_ALL_SALES_RETURN_LINE_SUCCESS:
			return {
				...state,
				loading: false,
				payload,
			};
		case GET_ALL_SALES_RETURN_LINE_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};
		case GET_ONE_ITEM_TRANSFER_LINE_REQUEST:
			return {
				loading: true,
			};
		case GET_ONE_ITEM_TRANSFER_LINE_SUCCESS:
			return {
				...state,
				loading: false,
				payload,
			};
		case GET_ONE_ITEM_TRANSFER_LINE_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};

		case GET_ONE_ITEM_TRANSFER_LINE_DETAIL_REQUEST:
			return {
				loading: true,
			};
		case GET_ONE_ITEM_TRANSFER_LINE_DETAIL_SUCCESS:
			return {
				...state,
				loading: false,
				payload,
			};
		case GET_ONE_ITEM_TRANSFER_LINE_DETAIL_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};
		case GET_ALL_PRODUCT_FOR_INT_REQUEST:
			return {
				loading: true,
			};
		case GET_ALL_PRODUCT_FOR_INT_SUCCESS:
			return {
				...state,
				loading: false,
				payload,
			};
		case GET_ALL_PRODUCT_FOR_INT_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};
		default:
			return state;
	}
}