import {
    GET_CREDIT_NOTE_APPLY_REQUEST,
    GET_CREDIT_NOTE_APPLY_SUCCESS,
    GET_CREDIT_NOTE_APPLY_ERROR,
    UPDATE_CREDIT_NOTE_APPLY_REQUEST,
    UPDATE_CREDIT_NOTE_APPLY_SUCCESS,
    UPDATE_CREDIT_NOTE_APPLY_ERROR,
    DELETE_CREDIT_NOTE_APPLY_REQUEST,
    DELETE_CREDIT_NOTE_APPLY_SUCCESS,
    DELETE_CREDIT_NOTE_APPLY_ERROR,
    
} from "../actions/types";




export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case GET_CREDIT_NOTE_APPLY_REQUEST:
            return {
                loading: true,
            };
        case GET_CREDIT_NOTE_APPLY_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_CREDIT_NOTE_APPLY_ERROR:
            return {
                ...state,
                loading: false
            };
        case UPDATE_CREDIT_NOTE_APPLY_REQUEST:
            return {
                loading: true,
            };
        case UPDATE_CREDIT_NOTE_APPLY_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case UPDATE_CREDIT_NOTE_APPLY_ERROR:
            return {
                ...state,
                loading: false
            };
        case DELETE_CREDIT_NOTE_APPLY_REQUEST:
            return {
                loading: true,
            };
        case DELETE_CREDIT_NOTE_APPLY_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case DELETE_CREDIT_NOTE_APPLY_ERROR:
            return {
                ...state,
                loading: false
            };


        default:
            return state;
    }
}
