import React, { useLayoutEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Form, Input, DatePicker, Collapse, Row, Col } from "antd";
import { FilterOutlined, CaretRightOutlined } from "@ant-design/icons";
import { dateChecker } from "../../components/CheckDate/checkDate";
import { getRights } from "../../redux/actions/userRights.action";
import SkeletonLoad from "../../components/Skeleton/SkeletonLoad";
import PageHeader from "../../components/PageHeader/PageHeader";
import { orderShipmentColumns } from "../../utils/cols";
import { ReadCookie } from "../../utils/readCookie";
import Tables from "../../components/Table/Tables";
import "../../screens/Product/product.css";
import moment from "moment";
import "../Style/style.css";
import { CSVLink } from "react-csv";

export default function OrderShipmentListNew({ token }) {
	const { orderShipmentList } = useSelector((state) => state);
	
	const dateFormat = "MM-DD-YYYY";
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const { Panel } = Collapse;

	const [menuId, setMenuId] = useState(1254);

	
	const worker = {
		ptNo: null,
		sono: null,
		from_date: moment().startOf("year"),
		to_date: moment().endOf("year"),
		PO_no: null,
		customer_code: null,
		saleperson_name: null,
		region: null,
		gender: null,
		term: null,
	};

	
	useLayoutEffect(() => {
		dispatch(getRights(token, menuId)).then((res) => { });
	}, []);

	
	const onFinishFilter = async (e) => {
		await form.validateFields().then((values) => {
			dateChecker(values.from_date, values.to_date, "orderShipmentList", token, values, dispatch);
		})
	};

	function downloadExcelFileData(data) {
		if (data) {
			return data.map((v) => {
				return {
					"PT No": v.ship_header_no,
					"SO No": v.order_header_no,
					"PT Date": moment(v.ship_date).format("MM-DD-YYYY"),
					"PO No": v.po_no,
					"Customer Name": v.customer_name,
					"Sale Person Name": v.salesperson_name,
					"Region Name": v.region_name,
					"Gender Name": v.gender_name,
					"Term": v.payment_term_name
				};
			})
		}
	}

	return (
		<div className="styleContainer">
			<div className="styleBody h-100">
				<PageHeader title={"Order Shipment List"} />
				<div className="bodyStyle">
					<Collapse
						style={{ width: "100%", padding: "10px" }}
						bordered={false}
						defaultActiveKey={['1']}
						expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
					>
						<Panel header="Filters" key="1" className="site-collapse-custom-panel" extra={<FilterOutlined />}>
							<Form
								form={form}
								labelCol={{ span: 8 }}
								wrapperCol={{ span: 16 }}
								initialValues={worker}
								onFinish={onFinishFilter}
								autoComplete="off">
								<Row>
									<Col xs={24} xl={8}>
										<Form.Item label="PT NO" name="ptNo" rules={[{ pattern: new RegExp(/^(?!0*[.,]0*$|[.,]0*$|0*$)\d+[,.]?\d{0,6}$/), message: "Must Be a Number." }]}>
											<Input allowClear size="small" />
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item label="SO NO" name="sono" rules={[{ pattern: new RegExp(/^(?!0*[.,]0*$|[.,]0*$|0*$)\d+[,.]?\d{0,6}$/), message: "Must Be a Number." }]}>
											<Input allowClear size="small" />
										</Form.Item>
									</Col>

									<Col xs={24} xl={8}>
										<Form.Item label="From Date" name="from_date"
										>
											<DatePicker
												size="small"
												id="fromDate"
												
												 format={dateFormat} 
												 className="dateInput customInputs" 
												 />
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item label="To Date" name="to_date"
										>
											<DatePicker
												size="small"
												id="toDate"
												
												format={dateFormat} 
												className="dateInput ustomInputs" 
												/>
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item label="PO NO" name="PO_no" >
											<Input allowClear size="small" />
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item label="Customer" name="customer_code" >
											<Input allowClear size="small" />
										</Form.Item>
									</Col>

									<Col xs={24} xl={8}>
										<Form.Item label="Sale Person Name" name="saleperson_name">
											<Input allowClear size="small" />
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item label="Region" name="region" >
											<Input allowClear size="small" />
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item label="Gender" name="gender" rules={[{ pattern: new RegExp(/^[a-zA-Z]*$/), message: "Must be Characters." }]}>
											<Input allowClear size="small" />
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item label="Terms" name="term" >
											<Input allowClear size="small" />
										</Form.Item>
									</Col>
								</Row>
								<div style={{ display: "flex", justifyContent: "flex-end" }}>
									<Button type="primary" htmlType="submit" disabled={orderShipmentList.loading}>
										Search
									</Button>
								</div>
							</Form>
						</Panel>
					</Collapse>
					{orderShipmentList.loading ?
						(<div className="mainProductContainer">
							<SkeletonLoad />
						</div>) :
						(<div className="mainProductContainer">
							<div style={{ padding: 10 }}>
							{
									orderShipmentList?.payload?.data && (
										<CSVLink
											filename={"Pick-Ticket.csv"}
											data={downloadExcelFileData(orderShipmentList?.payload?.data) || []}
											onClick={() => {
											}}
										>
											Download Excel
										</CSVLink>
									)
								}
								<Tables
									loadHeight={40}
									loadWidth={40}
									type={"customer"}
									scroll={570}
									perPage={100}
									scrollX={"80rem"}
									col={orderShipmentColumns}
									source={orderShipmentList?.payload?.data}
									load={orderShipmentList.loading}
									paginate
								/>
							</div>
						</div>)}
				</div>
			</div>
		</div>
	)
}

