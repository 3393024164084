import {
	DELETE_RECEIPT_LINE_ERROR,
	DELETE_RECEIPT_LINE_SUCCESS,
	DELETE_RECEIPT_LINE_REQUEST,
	GET_ONE_RECEIPT_LINE_REQUEST,
	GET_ONE_RECEIPT_LINE_SUCCESS,
	GET_ONE_RECEIPT_LINE_ERROR,
	GET_ONE_RECEIPT_LINE_DETAIL_REQUEST,
	GET_ONE_RECEIPT_LINE_DETAIL_SUCCESS,
	GET_ONE_RECEIPT_LINE_DETAIL_ERROR,
	ADD_RECEIPT_LINE_REQUEST,
	ADD_RECEIPT_LINE_SUCCESS,
	ADD_RECEIPT_LINE_ERROR,
	UPDATE_RECEIPT_LINE_REQUEST,
	UPDATE_RECEIPT_LINE_SUCCESS,
	UPDATE_RECEIPT_LINE_ERROR,
} from "./types";
import { BASEURL } from "../../constant/config.js";
const axios = require("axios");

export const addReceiptLine = (token, object) => (dispatch) => {
	dispatch({ type: ADD_RECEIPT_LINE_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}create/receiptLine`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: object,
	})
		.then((response) => {
			return dispatch({
				type: ADD_RECEIPT_LINE_SUCCESS,
				payload: response?.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: ADD_RECEIPT_LINE_ERROR,
				payload: error.response,
			});
		});
};

export const updateReceiptLine = (token, shipHeaderId, object) => (dispatch) => {
	dispatch({ type: UPDATE_RECEIPT_LINE_REQUEST });
	return axios({
		method: "PUT",
		url: `${BASEURL}update/receiptLine/${shipHeaderId}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: {
			dataLine: object,
		},
	})
		.then((response) => {
			return dispatch({
				type: UPDATE_RECEIPT_LINE_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: UPDATE_RECEIPT_LINE_ERROR,
				payload: error.response,
			});
		});
};

export const getOneReceiptLine = (token, id) => (dispatch) => {
	dispatch({ type: GET_ONE_RECEIPT_LINE_REQUEST });

	return axios({
		method: "GET",
		url: `${BASEURL}getOne/receiptLines/${id}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
	})
		.then((response) => {
			return dispatch({
				type: GET_ONE_RECEIPT_LINE_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_ONE_RECEIPT_LINE_ERROR,
				payload: error.response,
			});
		});
};

export const getOneReceiptLineDetail = (token, id) => (dispatch) => {
	dispatch({ type: GET_ONE_RECEIPT_LINE_DETAIL_REQUEST });
	return axios({
		method: "GET",
		url: `${BASEURL}getOne/receiptLinesDetail/${id}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
	})
		.then((response) => {

			return dispatch({
				type: GET_ONE_RECEIPT_LINE_DETAIL_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_ONE_RECEIPT_LINE_DETAIL_ERROR,
				payload: error.response,
			});
		});
};

export const deleteSalesLine = (token, id) => (dispatch) => {
	dispatch({ type: DELETE_RECEIPT_LINE_REQUEST });
	return axios({
		method: "DELETE",
		url: `${BASEURL}delete/saleReturnLine/${id}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
	})
		.then((response) => {
			return dispatch({
				type: DELETE_RECEIPT_LINE_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: DELETE_RECEIPT_LINE_ERROR,
				payload: error.response,
			});
		});
};
