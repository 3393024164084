import { GET_ALL_ORDER_ADJ_DETAIL_REQUEST, GET_ALL_ORDER_ADJ_DETAIL_SUCCESS, GET_ALL_ORDER_ADJ_DETAIL_ERROR } from "../actions/types"
export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case GET_ALL_ORDER_ADJ_DETAIL_REQUEST:
            return {
                loading: true,
            };
        case GET_ALL_ORDER_ADJ_DETAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_ALL_ORDER_ADJ_DETAIL_ERROR:
            return {
                ...state,
                loading: false,
                payload,
            };

        default: {
            return state;
        }
    }
};
