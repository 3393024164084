import {
  GET_REGIONCATG_REQUEST,
  GET_REGIONCATG_SUCCESS,
  GET_REGIONCATG_ERROR,

} from "../actions/types";

export default function (state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case GET_REGIONCATG_REQUEST:
      return {
        loading: true,
      };
    case GET_REGIONCATG_SUCCESS:
      return {
        ...state,
        loading: false,
        payload,
      };
    case GET_REGIONCATG_ERROR:
      return {
        ...state,
        loading: false,
        payload,
      };

    default:
      return state;
  }
}
