import {

	GET_ONE_CREDIT_MEMO_INVOICE_REQUEST,
	GET_ONE_CREDIT_MEMO_INVOICE_SUCCESS,
	GET_ONE_CREDIT_MEMO_INVOICE_ERROR,

	DELETE_CREDIT_MEMO_INVOICE_REQUEST,
	DELETE_CREDIT_MEMO_SUCCESS,
	DELETE__CREDIT_MEMO_ERROR,

	UPDATE_CREDIT_MEMO_INVOICE_REQUEST,
	UPDATE_CREDIT_MEMO_INVOICE_SUCCESS,
	UPDATE_CREDIT_MEMO_INVOICE_ERROR
} from "../actions/types";

export default function (state = [], action) {
	const { type, payload } = action;
	switch (type) {
		case GET_ONE_CREDIT_MEMO_INVOICE_REQUEST:
			return {
				loading: true,
			};
		case GET_ONE_CREDIT_MEMO_INVOICE_SUCCESS:
			return {
				...state,
				loading: false,
				payload,
			};
		case GET_ONE_CREDIT_MEMO_INVOICE_ERROR:
			return {
				...state,
				loading: false
			};
		case DELETE_CREDIT_MEMO_INVOICE_REQUEST:
			return {
				loading: true,
			};
		case DELETE_CREDIT_MEMO_SUCCESS:
			return {
				...state,
				loading: false,
				payload: [payload],
			};
		case DELETE__CREDIT_MEMO_ERROR:
			return {
				...state,
				loading: false
			};
		case UPDATE_CREDIT_MEMO_INVOICE_REQUEST:
			return {
				loading: true,
			};
		case UPDATE_CREDIT_MEMO_INVOICE_SUCCESS:
			return {
				...state,
				loading: false,
				payload: [payload],
			};
		case UPDATE_CREDIT_MEMO_INVOICE_ERROR:
			return {
				...state,
				loading: false
			};
		default:
			return state;
	}
}
