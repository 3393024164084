import {
    GET_RETURN_RESAON_ERROR, GET_RETURN_RESAON_REQUEST, GET_RETURN_RESAON_SUCCESS, ADD_RETURN_REASON_REQUEST, ADD_RETURN_REASON_ERROR, ADD_RETURN_REASON_SUCCESS,
    UPDATE_RETURN_REASON_ERROR, UPDATE_RETURN_REASON_REQUEST, UPDATE_RETURN_REASON_SUCCESS, DELETE_RETURN_REASON_ERROR, DELETE_RETURN_REASON_REQUEST, DELETE_RETURN_REASON_SUCCESS
} from "./types";
import { BASEURL } from "../../constant/config.js";
import axios from "axios";


export const getReturnResaon = (token, id) => (dispatch) => {
    dispatch({ type: GET_RETURN_RESAON_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/adminReturnReason`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { id }
    })
        .then((response) => {
            return dispatch({ type: GET_RETURN_RESAON_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: GET_RETURN_RESAON_ERROR,
                payload: error.response,
            });
        });
};


export const addReturnReason = (token, data, id) => (dispatch) => {
    dispatch({ type: ADD_RETURN_REASON_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}create/adminReturnReason`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data,
    })
        .then((response) => {

            return dispatch({ type: ADD_RETURN_REASON_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: ADD_RETURN_REASON_ERROR,
                payload: error.response,
            });
        });
};



export const updateReturnReason = (token, data, id) => (dispatch) => {
    dispatch({ type: UPDATE_RETURN_REASON_REQUEST });
    return axios({
        method: "PUT",
        url: `${BASEURL}update/adminReturnReason/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data,
    })
        .then((response) => {

            return dispatch({ type: UPDATE_RETURN_REASON_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: UPDATE_RETURN_REASON_ERROR,
                payload: error.response,
            });
        });
};



export const deleteReturnReason = (token, id) => (dispatch) => {
    dispatch({ type: DELETE_RETURN_REASON_REQUEST });
    return axios({
        method: "DELETE",
        url: `${BASEURL}delete/adminReturnReason/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
    })
        .then((response) => {
            return dispatch({ type: DELETE_RETURN_REASON_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: DELETE_RETURN_REASON_ERROR,
                payload: error.response,
            });
        });
};
