import {
    GET_CHARGEBACKTYPE_ERROR, GET_CHARGEBACKTYPE_SUCCESS, GET_CHARGEBACKTYPE_REQUEST,
    ADD_CHARGEBACKTYPE_ERROR, ADD_CHARGEBACKTYPE_REQUEST, ADD_CHARGEBACKTYPE_SUCCESS,
    UPDATE_CHARGEBACKTYPE_ERROR, UPDATE_CHARGEBACKTYPE_SUCCESS, UPDATE_CHARGEBACKTYPE_REQUEST,
    DELETE_CHARGEBACKTYPE_SUCCESS, DELETE_CHARGEBACKTYPE_ERROR, DELETE_CHARGEBACKTYPE_REQUEST,
} from "./types";


import { BASEURL } from "../../constant/config.js";

const axios = require("axios");




export const getChargeBackType = (token, id) => (dispatch) => {
    dispatch({ type: GET_CHARGEBACKTYPE_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/cashBack`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { id }
    })
        .then((response) => {
            return dispatch({ type: GET_CHARGEBACKTYPE_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: GET_CHARGEBACKTYPE_ERROR,
                payload: error.response,
            });
        });
};



export const addChargeBackType = (token, data, id) => (dispatch) => {
    dispatch({ type: ADD_CHARGEBACKTYPE_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}create/cashBack`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data,
    })
        .then((response) => {
            return dispatch({ type: ADD_CHARGEBACKTYPE_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: ADD_CHARGEBACKTYPE_ERROR,
                payload: error.response,
            });
        });
};



export const updateChargeBackType = (token, data, id) => (dispatch) => {
    dispatch({ type: UPDATE_CHARGEBACKTYPE_REQUEST });
    return axios({
        method: "PUT",
        url: `${BASEURL}update/cashBack/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data,
    })
        .then((response) => {
            return dispatch({ type: UPDATE_CHARGEBACKTYPE_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: UPDATE_CHARGEBACKTYPE_ERROR,
                payload: error.response,
            });
        });
};




export const deleteChargeBackType = (token, id) => (dispatch) => {
    dispatch({ type: DELETE_CHARGEBACKTYPE_REQUEST });
    return axios({
        method: "DELETE",
        url: `${BASEURL}delete/cashBack/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
    })
        .then((response) => {
            return dispatch({ type: DELETE_CHARGEBACKTYPE_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: DELETE_CHARGEBACKTYPE_ERROR,
                payload: error.response,
            });
        });
};
