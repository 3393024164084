import {
  GET_SHIPPING_ERROR,
  GET_SHIPPING_REQUEST,
  GET_SHIPPING_SUCCESS,
  ADD_SHIPPING_REQUEST,
  ADD_SHIPPING_SUCCESS,
  ADD_SHIPPING_ERROR,
  DELETE_SHIPPING_REQUEST,
  DELETE_SHIPPING_SUCCESS,
  DELETE_SHIPPING_ERROR,
  UPDATE_SHIPPING_REQUEST,
  UPDATE_SHIPPING_SUCCESS,
  UPDATE_SHIPPING_ERROR,
} from "../actions/types";

export default function (state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case GET_SHIPPING_REQUEST:
      return {
        loading: true,
      };
    case GET_SHIPPING_SUCCESS:
      return {
        ...state,
        loading: false,
        payload,
      };
    case GET_SHIPPING_ERROR:
      return {
        ...state,
        loading: false,
        payload,
      };

    case ADD_SHIPPING_REQUEST:
      return {
        loading: true,
      };
    case ADD_SHIPPING_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: [payload],
      };
    case ADD_SHIPPING_ERROR:
      return {
        ...state,
        loading: false,
      };


      case DELETE_SHIPPING_REQUEST:
        return {
          loading: true,
        };
      case DELETE_SHIPPING_SUCCESS:
        return {
          ...state,
          loading: false,
          payload: [payload],
        };
      case DELETE_SHIPPING_ERROR:
        return {
          ...state,
          loading: false,
        };
  
        case UPDATE_SHIPPING_REQUEST:
        return {
          loading: true,
        };
      case UPDATE_SHIPPING_SUCCESS:
        return {
          ...state,
          loading: false,
          payload: [payload],
        };
      case UPDATE_SHIPPING_ERROR:
        return {
          ...state,
          loading: false,
        };


    default:
      return state;
  }
}
