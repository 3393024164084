import React, { useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CaretRightOutlined, PlusOutlined } from "@ant-design/icons";
import SkeletonLoad from "../../components/Skeleton/SkeletonLoad";
import PageHeader from "../../components/PageHeader/PageHeader";
import {
  Button,
  Form,
  Input,
  DatePicker,
  Collapse,
  Row,
  Col,
  Select,
} from "antd";
import Tables from "../../components/Table/Tables";
import { salesPersonListColumns } from "../../utils/cols";
import { getRights } from "../../redux/actions/userRights.action";
import moment from "moment";
import "../../screens/Product/product.css";
import "../Style/style.css";
import { useHistory } from "react-router-dom";
import { ReadCookie } from "../../utils/readCookie";
import { dateChecker } from "../../components/CheckDate/checkDate";
import { salePersonList } from "../../redux/actions/salePerson/salePersonList.action";
const { Option } = Select;
export default function SalesPersonList({ token }) {
  const [form] = Form.useForm();
  const history = useHistory();
  const dispatch = useDispatch();
  const menuId = ReadCookie("menuId");
  const { salePersonInfo } = useSelector((state) => {
    return state;
  });
  const { Panel } = Collapse;

  const worker = {
    hireDate: moment().startOf("year"),
    employeeId: null,
    name: null,
    email: null,
    is_salesperson: null,
    jobTitle: null,
    superVisor: null,
    reportCurrency: null,
  };

  useLayoutEffect(() => {
    dispatch(getRights(token, menuId)).then((res) => {});
  }, []);

  const onFinishFilter = async (e) => {
    await form.validateFields().then((values) => {
      
	  dispatch(salePersonList(token, values)).then(
		(res) => {
		
		
		
		
		
		
		
		
		
		
		}
	  );

      
    });
  };

  return (
    <div className="styleContainer">
      <div className="styleBody h-100">
        <PageHeader title={"Sales Person List"} />
        <div className="bodyStyle">
          <Collapse
            style={{ width: "99%", margin: "10px" }}
            bordered={false}
            defaultActiveKey={["1"]}
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
          >
            <Panel
              header="Filters"
              key="1"
              className="site-collapse-custom-panel"
              extra={
                <Button
                  onClick={() => {
                    history.push("/sales-person");
                  }}
                  className="iconBtn crudBTN"
                  shape="circle"
                  icon={<PlusOutlined />}
                />
              }
            >
              <Form
                form={form}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                initialValues={worker}
                onFinish={onFinishFilter}
                autoComplete="off"
              >
                <Row>
                  <Col xs={24} xl={8}>
                    <Form.Item
                      name="employeeId"
                      label="Employee Id"
                      rules={[
                        {
                          pattern: new RegExp(
                            /^(?!0*[.,]0*$|[.,]0*$|0*$)\d+[,.]?\d{0,6}$/
                          ),
                          message: "Must Be a Number.",
                        },
                      ]}
                    >
                      <Input allowClear size="small" />
                    </Form.Item>
                  </Col>
                  {/* <Col xs={24} xl={8}>
                    <Form.Item label="Hire Date" name="hireDate">
                      <DatePicker
                        size="small"
                        id="hireDate"
                        format="MM-DD-YYYY"
                        allowClear
                        className="dateInput customInputs"
                      />
                    </Form.Item>
                  </Col> */}
                  <Col xs={24} xl={8}>
                    <Form.Item name="name" label="Name">
                      <Input allowClear size="small" />
                    </Form.Item>
                  </Col>
				  <Col xs={24} xl={8}>
                    <Form.Item name="email" label="Email">
                      <Input allowClear size="small" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>

                  <Col xs={24} xl={8}>
                    <Form.Item name="is_salesperson" label="Is SalesPerson" >
                      <Select
                        size="small"
						
                        
                        
                        optionLabelProp="Size"
                      >
                        <Option value={'All'}>All</Option>
                        <Option value={'Yes'}>Yes</Option>
                        <Option value={'No'}>No</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                {/* <Row>
									<Col xs={24} xl={8}>
										<Form.Item
											label="Job Title"
											name="jobTitle"
										>
											<Input

												allowClear
												size="small" />
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item
											label="SuperVisor"
											name="superVisor"
										>

											<Input
												allowClear
												size="small"
											/>
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item
											name="reportCurrency"
											label="Report Currency"
										>
											<Input
												allowClear
												size="small" />
										</Form.Item>
									</Col>

								</Row> */}
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button type="primary" htmlType="submit">
                    Search
                  </Button>
                </div>
              </Form>
            </Panel>
          </Collapse>

          {salePersonInfo?.loading ? (
            <div className="mainProductContainer">
              <SkeletonLoad />
            </div>
          ) : (
            <div className="mainProductContainer">
              <div style={{ padding: 10 }}>
                <Tables
                  loadWidth={40}
                  type={"customer"}
                  scroll={450}
                  perPage={100}
                  scrollX={"80rem"}
                  col={salesPersonListColumns}
                  source={salePersonInfo?.payload?.data}
                  load={salePersonInfo?.loading}
                  paginate
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

