
































import {
    GET_CUSTOMER_TYPE_ERROR,
    GET_CUSTOMER_TYPE_REQUEST,
    GET_CUSTOMER_TYPE_SUCCESS,
    ADD_CUSTOMER_TYPE_ERROR,
    ADD_CUSTOMER_TYPE_REQUEST,
    ADD_CUSTOMER_TYPE_SUCCESS,
    DELETE_CUSTOMER_TYPE_REQUEST,
    DELETE_CUSTOMER_TYPE_SUCCESS,
    DELETE_CUSTOMER_TYPE_ERROR,
    UPDATE_CUSTOMER_TYPE_ERROR,
    UPDATE_CUSTOMER_TYPE_REQUEST,
    UPDATE_CUSTOMER_TYPE_SUCCESS,
} from "./types";

import { BASEURL } from "../../constant/config.js";

const axios = require("axios");

export const getCustomerType = (token, menu_id, customerTypeID) => (dispatch) => {
    dispatch({ type: GET_CUSTOMER_TYPE_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/customerType`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { menu_id, customer_type_id: customerTypeID ? customerTypeID : null }
    })
        .then((response) => {
            return dispatch({ type: GET_CUSTOMER_TYPE_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: GET_CUSTOMER_TYPE_ERROR,
                payload: error.response,
            });
        });
};

export const addCustomerType = (token, data, menu_id) => (dispatch) => {
    dispatch({ type: ADD_CUSTOMER_TYPE_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}create/customerType`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { customer_type_name: data.customerTypeName, is_active: data.check, menu_id },
    })
        .then((response) => {
            return dispatch({ type: ADD_CUSTOMER_TYPE_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: ADD_CUSTOMER_TYPE_ERROR,
                payload: error.response,
            });
        });
};

export const updateCustomerType = (token, data, id) => (dispatch) => {
    dispatch({ type: UPDATE_CUSTOMER_TYPE_REQUEST });
    return axios({
        method: "PUT",
        url: `${BASEURL}update/customerType/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { customer_type_name: data.customerTypeName, is_active: data.check },
    })
        .then((response) => {
            return dispatch({ type: UPDATE_CUSTOMER_TYPE_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: UPDATE_CUSTOMER_TYPE_ERROR,
                payload: error.response,
            });
        });
};

export const deleteCustomerType = (token, id) => (dispatch) => {
    dispatch({ type: DELETE_CUSTOMER_TYPE_REQUEST });
    return axios({
        method: "DELETE",
        url: `${BASEURL}delete/customerType/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
    })
        .then((response) => {
            return dispatch({ type: DELETE_CUSTOMER_TYPE_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: DELETE_CUSTOMER_TYPE_ERROR,
                payload: error.response,
            });
        });
};


