import React, { useEffect, useState } from "react";

import { Spin, Select, DatePicker, Checkbox, Form, Input, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { getOmsStockSummary } from "../../redux/actions/omsStockSummary.action";
import { getGenderCategory } from "../../redux/actions/genderCategory.action";
import { getProductStatus } from "../../redux/actions/productStatus.actions";
import { getProductClass } from "../../redux/actions/productClass.action";
import { getFitCategory } from "../../redux/actions/fitCategory.action";
import { getProductType } from "../../redux/actions/productType.action";
import { getSeason } from "../../redux/actions/season.action";
import { getGender } from "../../redux/actions/gender.action";
import { getSize } from "../../redux/actions/size.action";

import { ReadCookie } from "../../utils/readCookie";
import { getStyle } from "../../redux/actions/style";
import moment from 'moment';

import btnPrint from "../../assets/images/button_print.gif";
import xlsx from "../../assets/images/ico_xls_sm.gif";
import logo from "../../assets/images/logo.png";
import "../Distributor/Distributor.css";
import { Link } from "react-router-dom";
import { getOmsStyleStock } from "../../redux/actions/onsStockStyle.action";

const dateFormatList = ["MM/DD/YYYY"];

const OmsStockSummaryInitial = ({ token }) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const { style, getWiseStockReport, productType, gender, genderCategory, season, productStatus, productClass, size, fitCategory, omsStockSummary, omsStockStyle } = useSelector((state) => state);
    const menuId = ReadCookie("menuId");
    const userName = ReadCookie("name");
    const [productClassID, setProductClassID] = useState([]);
    const [seasonID, setSeasonID] = useState([]);
    const [isChecked, setIsChecked] = useState([]);
    const [allSesion, setAllSesion] = useState(true);
    const [isPCChecked, setIsPCChecked] = useState([]);
    const [allPC, setAllPC] = useState(true);
    const [genderID, setGenderID] = useState(null);
    const [BgradeCheck, setBgradeCheck] = useState(false);
    let result = getWiseStockReport && getWiseStockReport?.payload?.data?.reduce(function (r, a) {
        r[a.item_code] = r[a.item_code] || [];
        r[a.item_code].push(a);
        return r;
    }, Object.create(null));

    const wiseStockReport = result && Object.values(result);

    const values = {
        item_id: null,
        location_id: null,
    };

    const { Option } = Select;
    let pendingDate = "";
    const onChangeDate = (date, dateString) => {
        pendingDate = dateString;
    };

    const sesionSelectAll = (e) => {
        setAllSesion(e.target.checked);
    };

    const PCSelectAll = (e) => {
        if (allPC) {
            setAllPC(e.target.checked);
        }
        else if (!allPC) {
            setAllPC()
        }

    };

    const isCheckboxChecked = (id, value) => {
        const filterCheckBox =
            season && season?.payload?.data.find((val) => val.season_id === id);
        const checkId = isChecked.includes(id);
        if (!checkId) {
            setIsChecked((current) => [...current, filterCheckBox.season_id]);
        }
        if (!value) {
            const removeUnCheck = isChecked.filter(
                (item) => item !== filterCheckBox.season_id
            );
            setIsChecked(removeUnCheck);
        }
        setAllSesion(false);
    };

    const isPCBoxChecked = (id, value) => {
        const filterCheckBox =
            productClass && productClass?.payload?.data.find((val) => val.product_class_id === id);
        const checkId = isPCChecked.includes(id);
        if (!checkId) {
            setIsPCChecked((current) => [...current, filterCheckBox.product_class_id]);
        }
        if (!value) {
            const removeUnCheck = isPCChecked.filter(
                (item) => item !== filterCheckBox.product_class_id
            );
            setAllPC(true)
            setIsPCChecked(removeUnCheck);
        }

        setAllPC(false);
    };

    const onFinish = async (e) => {
        await form.validateFields().then((values) => {

            dispatch(getOmsStyleStock(token, values, isChecked, isPCChecked, BgradeCheck))

        });
    };

    useEffect(() => {
        dispatch(getStyle(token, menuId));
        dispatch(getProductType(token, values));
        dispatch(getGenderCategory(token, menuId));
        dispatch(getSeason(token, menuId));
        dispatch(getProductStatus(token, menuId));
        dispatch(getProductClass(token, menuId))
        dispatch(getSize(token, menuId))
        dispatch(getGender(token, menuId))
        dispatch(getFitCategory(token, menuId))
    }, [dispatch, menuId, token]);

    let styleGroup = omsStockStyle && omsStockStyle?.payload?.data?.reduce(function (r, a) {
        r[a.style_id] = r[a.style_id] || []; r[a.style_id].push(a);
        return r;
    }, Object.create(null));

    const styleGroupBy = styleGroup && Object.values(styleGroup);


    const handlePrint = () => {
        window.print();
    };


    return (<div className="distributor header_class business_header_oms">
        <div className="row justify-content-between business_class_oms">
            <div className="col-3 d-flex align-items-start box-space">
                <div className="logobrand">
                    <img id="ImgLogo" alt="logo" src={logo} />
                </div>
            </div>
            {/* <div className="col-sm-6 box-space pl-sm-0">
                <div className="brand-para">
                    <div>DL1961 Premium Denim Inc.</div>
                    <div>121 Varick Street</div>
                    <div>4th Floor</div>
                    <div>New York, NY 10013</div>
                    <div>(P) 646-514-9736</div>
                    <div>(F) 212-991-9989</div>
                    <div>(E) sales@DL1961.com</div>
                </div>
            </div> */}
            <div className="col-4 box-space right-content-header text-right">
                <div className=" d-flex justify-content-end">
                    <div className=' d-flex justify-content-end mr-8 printing_class'>
                        <div className='print_icon printing_class'>
                            <i id="Image1" title="Print" alt='Print' src={btnPrint} onClick={handlePrint} className="fa-solid fa-print printing_class"></i>
                        </div>
                        <div className='print_icon printing_class' name="ImgExcel" alt='Export to excel' id="ImgExcel" title="Export to excel" src={xlsx}>
                            <i className="fa-solid fa-file-excel"></i>
                        </div>
                    </div>
                    <span style={{ fontSize: "small", fontWeight: "bolder" }}>
                        Stock Report Summary
                        <br />
                        Rpt # 489
                        <br />
                        <p className=" mb-0">Rpt # 001_V4</p>
                        Login as: {" "}
                        <span id="lblSalesPerson" style={{ fontWeight: "bold" }}>
                            {userName}
                        </span>
                    </span>
                </div>
            </div>
        </div>
        {/* <div className="row business_class_oms">
            <div className="col text-sm-right pb-1">
                Login as: {" "}
                <span id="lblSalesPerson" style={{ fontWeight: "bold" }}>
                    {userName}
                </span>
            </div>
        </div> */}

        <div className="business_class_oms mb-4">
            <Form form={form} name="control-hooks" onFinish={onFinish}>
                <div className='main_form row'>
                    <div className="col-12">
                        {/* <hr className="mt-0 mb-2" />

                        <div>
                            <label htmlFor="">Classification</label>
                            <div className="checkboxes">
                                <div className="PC_Chekbox">
                                    <Checkbox
                                        size="large"

                                        checked={allPC}
                                        onChange={PCSelectAll}
                                    >
                                        All
                                    </Checkbox>
                                </div>
                                {productClass &&
                                    productClass?.payload?.data.map((pcls, index) => {
                                        return (
                                            <div key={index} className="checkbox_content">
                                                <Form.Item name="product_class_id" >
                                                    <Checkbox
                                                        size="large"
                                                        onChange={(e) =>
                                                            isPCBoxChecked(
                                                                pcls.product_class_id,
                                                                e.target.checked
                                                            )
                                                        }
                                                    >
                                                        {pcls.product_class_name}
                                                    </Checkbox>
                                                </Form.Item>
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>
                        <hr className="my-1" />
                        <div>
                            <label htmlFor="">Season</label>
                            <div className="checkboxes">
                                <div className="checkbox_content">
                                    <Form.Item name="current_season_id" className=" mb-0">
                                        <Checkbox

                                            checked={allSesion}
                                            onChange={sesionSelectAll}
                                        >
                                            All
                                        </Checkbox>
                                    </Form.Item>
                                </div>
                                {season &&
                                    season?.payload?.data.map((it, index) => {
                                        return (
                                            <div key={index} className="checkbox_content">
                                                <Form.Item name="current_season_id">
                                                    <Checkbox
                                                        onChange={(e) =>
                                                            isCheckboxChecked(
                                                                it.season_id,
                                                                e.target.checked
                                                            )
                                                        }
                                                    >
                                                        {it.season_name}
                                                    </Checkbox>
                                                </Form.Item>
                                            </div>
                                        );
                                    })}

                            </div>
                        </div> */}
                        <hr className="my-1" />
                        <div className="row">
                            <div className="col-3">
                                <div>
                                    <label htmlFor="">Product Category</label>
                                    <div>
                                        <Form.Item initialValue={"All"} name="gender_category_id">
                                            <Select
                                                allowClear
                                                className={"select_Inputs"}
                                                showSearch
                                                
                                                optionFilterProp="children"
                                                size="small"
                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                            >
                                                {genderCategory &&
                                                    genderCategory?.payload?.data.map((it, index) => {
                                                        return (
                                                            <Option

                                                                key={index}
                                                                value={it.gender_category_id}
                                                            >
                                                                {it.gender_category_name}
                                                            </Option>
                                                        );
                                                    })}
                                            </Select>
                                        </Form.Item>
                                    </div>
                                </div>
                                <div>
                                    <label htmlFor="">Pending till</label>
                                    <div>
                                        <Form.Item name="as_on_date" className="full_date" initialValue={moment("12-31-2022")}>
                                            <DatePicker
                                                size="small"
                                                format={dateFormatList}

                                                onChange={onChangeDate}
                                                defaultValue={moment().format(('MM/DD/YYYY'))}
                                            />
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                            {/*Second*/}
                            <div className="col-3">
                                <div>
                                    <label htmlFor="">Gender</label>
                                    <div>
                                        <Form.Item
                                            name="gender_id" initialValue={"All"}>
                                            <Select
                                                allowClear
                                                className={"select_Inputs"}
                                                showSearch
                                                
                                                optionFilterProp="children"
                                                size="small"
                                            >
                                                {gender &&
                                                    gender?.payload?.data.map((it, index) => {
                                                        return (
                                                            <Option
                                                                key={index}
                                                                value={it.gender_id}
                                                            >
                                                                {it.gender_name}
                                                            </Option>
                                                        );
                                                    })}
                                            </Select>
                                        </Form.Item>
                                    </div>
                                </div>
                                <div>
                                    <label htmlFor="">Sort on</label>
                                    <div>
                                        <Form.Item name="openStock" initialValue={"SOS"}>
                                            <Select
                                                allowClear
                                                defaultValue={form.getFieldValue().openStock}
                                                className={"select_Inputs"}
                                                showSearch
                                                
                                                optionFilterProp="children"
                                                size="small"
                                            >

                                                <Option value={"SOS"}>Style / Open Stock</Option>
                                                <Option value={"OS"}>Open Stock</Option>
                                            </Select>
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                            {/*Third*/}
                            <div className="col-3">
                                <div>
                                    <label htmlFor="">Style</label>
                                    <div>
                                        <Form.Item initialValue={"All"} name="style_id">
                                            <Select
                                                allowClear
                                                className={"select_Inputs"}
                                                showSearch
                                                
                                                optionFilterProp="children"
                                                size="small"
                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                            >
                                                {style &&
                                                    style?.payload?.data.map((style, index) => {
                                                        return (
                                                            <Option key={index} value={style.style_id}>
                                                                {style.style_name}
                                                            </Option>
                                                        );
                                                    })}
                                            </Select>
                                        </Form.Item>
                                    </div>
                                </div>
                                <div>
                                    <label htmlFor="">Product Status</label>
                                    <div>
                                        <Form.Item name="product_status_id" initialValue={1}>
                                            <Select
                                                allowClear
                                                defaultValue={form.getFieldValue().product_status_id}
                                                className={"select_Inputs"}
                                                showSearch
                                                
                                                optionFilterProp="children"
                                                size="small"
                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                            >
                                                {productStatus &&
                                                    productStatus?.payload?.data?.map((status, index) => {
                                                        return (
                                                            <Option
                                                                key={index}
                                                                value={status.product_status_id}
                                                            >
                                                                {status.product_status_name}
                                                            </Option>
                                                        );
                                                    })}
                                            </Select>
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                            {/*Fourth*/}
                            <div className="col-3">
                                <div>
                                    <label htmlFor="">Sku</label>
                                    <div>
                                        <Form.Item name="sku" className="sku_input">
                                            <Input
                                                size="small"
                                                
                                                className={"select_Inputs"}
                                            />
                                        </Form.Item>
                                    </div>
                                </div>
                                <div>
                                    <label htmlFor="">Style Category</label>
                                    <div>
                                        <Form.Item name="fit_category_id" initialValue={"All"}>
                                            <Select
                                                allowClear
                                                className={"select_Inputs"}
                                                showSearch
                                                
                                                optionFilterProp="children"
                                                size="small"
                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                            >
                                                {fitCategory &&
                                                    fitCategory?.payload?.data?.map((it, index) => {
                                                        return (
                                                            <Option key={index} value={it.fit_category_id}>
                                                                {it.fit_category_name}
                                                            </Option>
                                                        );
                                                    })}
                                            </Select>
                                        </Form.Item>
                                    </div>
                                </div>

                                <div>
                                    <label className=" no_visibility">none</label>
                                    <Button type="primary" htmlType="submit" className='submit-btn-btm m-0 float-end' disabled={omsStockStyle?.loading}>
                                        Generate
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </div >
        <div>
            <div className="business_class_oms " style={{ overflow: "auto", display: "flex", justifyContent: "center" }}>
                {omsStockStyle?.loading ?
                    <>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}>
                            <div >
                                <Spin size="small" />
                            </div>
                        </div>
                    </> :

                    <>
                        {styleGroupBy && styleGroupBy ?
                            <table className="table_distributor-data stock_table table_content_oms" style={{ margin: "10px 0px", paddingBottom: "10px" }}>
                                <tbody>
                                    <tr style={{ background: "rgb(222, 222, 222)", border: " 2px solid #3c4b58 ", height: " 34px", color: "black" }}>
                                        <td>#</td>
                                        <td>Style</td>
                                        <td>Production</td>
                                        <td>PaKistan</td>
                                        <td>Transit</td>
                                        <td>Bergan</td>
                                        <td>Total</td>
                                        <td>Pending</td>
                                        <td>International</td>
                                        <td>OTS</td>
                                    </tr>
                                    {
                                        styleGroupBy && styleGroupBy.map((data, index) => {

                                            let sumProduction = 0;
                                            let sumPk = 0;
                                            let sumTransit = 0;
                                            let sumBergen = 0;
                                            let sumPending = 0;
                                            let sumInternational = 0;
                                            let sumOts = 0;

                                            data.map((value) => {
                                                sumProduction += value.production;
                                                sumPk += value.pakistan;
                                                sumTransit += value.transit;
                                                sumBergen += value.bergan;
                                                sumPending += value.pending;
                                                sumInternational += value.international;
                                                sumOts += value.ots;
                                            })


                                            return (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td >
                                                        <Link style={{ color: "blue", textDecoration: "underline" }}
                                                            to={{
                                                                pathname: `/oms-stock-style-inventory-detail`,
                                                                search: `?style_id=${data[0].style_id}&as_on_date=${moment(form.getFieldValue().as_on_date).format("MM-DD-YYYY")}&gender_category_id=${form.getFieldValue().gender_category_id}&gender_id=${form.getFieldValue().gender_id}&openStock=${form.getFieldValue().openStock}&sku=${form.getFieldValue().sku === undefined ? "" : form.getFieldValue().sku}&fit_category_id=${form.getFieldValue().fit_category_id}&product_status_id=${form.getFieldValue().product_status_id}`,
                                                            }}>

                                                            {data[0].style}
                                                        </Link>
                                                    </td>
                                                    <td>{sumProduction}</td>
                                                    <td>{sumPk}</td>
                                                    <td>{sumTransit}</td>
                                                    <td>{sumBergen}</td>
                                                    <td>{sumProduction + sumPk + sumTransit + sumBergen}</td>
                                                    <td>{sumPending}</td>
                                                    <td>{sumInternational}</td>
                                                    <td>{sumOts}</td>
                                                </tr>
                                            )
                                        })

                                    }
                                </tbody>
                            </table> :
                            <tbody>
                                <tr style={{ backgroundColor: "#CCCCCC", borderWidth: "1pt", borderStyle: "Dashed", fontWeight: "bold", width: "100%" }} >
                                    <td colSpan="5">
                                        No record(s)</td>
                                </tr>
                            </tbody>}
                    </>
                }
            </div >
        </div>
    </div >
    );
};

export default OmsStockSummaryInitial;
