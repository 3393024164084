import {
    GET_PAYMENTMETHODTYPE_REQUEST,GET_PAYMENTMETHODTYPE_ERROR,GET_PAYMENTMETHODTYPE_SUCCESS,
ADD_PAYMENTMETHODTYPE_REQUEST,ADD_PAYMENTMETHODTYPE_ERROR,ADD_PAYMENTMETHODTYPE_SUCCESS,
UPDATE_PAYMENTMETHODTYPE_REQUEST,UPDATE_PAYMENTMETHODTYPE_ERROR,UPDATE_PAYMENTMETHODTYPE_SUCCESS,
DELETE_PAYMENTMETHODTYPE_REQUEST,DELETE_PAYMENTMETHODTYPE_ERROR,DELETE_PAYMENTMETHODTYPE_SUCCESS,
} from "../actions/types"
export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case GET_PAYMENTMETHODTYPE_REQUEST:
            return {
                loading: true,
            };
        case GET_PAYMENTMETHODTYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_PAYMENTMETHODTYPE_ERROR:
            return {
                ...state,
                loading: false
            };
        case ADD_PAYMENTMETHODTYPE_REQUEST:
            return {
                loading: true,
            };
        case ADD_PAYMENTMETHODTYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case ADD_PAYMENTMETHODTYPE_ERROR:
            return {
                ...state,
                loading: false
            };
        case DELETE_PAYMENTMETHODTYPE_REQUEST:
            return {
                loading: true,
            };
        case DELETE_PAYMENTMETHODTYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case DELETE_PAYMENTMETHODTYPE_ERROR:
            return {
                ...state,
                loading: false
            };
        case UPDATE_PAYMENTMETHODTYPE_REQUEST:
            return {
                loading: true,
            };
        case UPDATE_PAYMENTMETHODTYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case UPDATE_PAYMENTMETHODTYPE_ERROR:
            return {
                ...state,
                loading: false
            };

        default:
            return state;
    }
}