import {
  GET_SEARCH_PACKING_LIST_REQUEST,
  GET_SEARCH_PACKING_LIST_SUCCESS,
  GET_SEARCH_PACKING_LIST_ERROR
} from "./types";
import axios from "axios";
import { BASEURL } from "../../constant/config.js";
import moment from "moment";

export const searchPackingList = (token, values) => (dispatch) => {
  dispatch({ type: GET_SEARCH_PACKING_LIST_REQUEST });
  const newData = {
    packing_id: values.packing_id,
    expected_date: values.startDate ? values.startDate : moment().format("MM-DD-YYYY"),
    carrier: values.carrier ? values.carrier : null,
    awb: values.awb ? values.awb : null,
    customer_po: values.customer_po ? values.customer_po : null,
    memo: values.memo ? values.memo : null,
    company: values.company ? values.company : null,
    brand: values.brand ? values.brand : null,
    ctn_from: values.ctn_from ? values.ctn_from : 1,
    ctn_to: values.ctn_to ? values.ctn_to : 100,
};
  return axios({
    method: "POST",
    url: `${BASEURL}getAll/SearchPackingList`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: newData
  })
    .then((response) => {
      return dispatch({
        type: GET_SEARCH_PACKING_LIST_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_SEARCH_PACKING_LIST_ERROR,
        payload: [],
      });
    });
};
