import React, { useState, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAllBillHeader } from "../../redux/actions/bill.action";
import SkeletonLoad from "../../components/Skeleton/SkeletonLoad";
import PageHeader from "../../components/PageHeader/PageHeader";
import Tables from "../../components/Table/Tables";
import { vendorColumns } from "../../utils/cols";
import moment from "moment";
import { dateChecker } from "../../components/CheckDate/checkDate";
import "../../screens/Product/product.css";
import "../Style/style.css";
import { useHistory } from "react-router-dom";
import { CaretRightOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, DatePicker, Collapse, Row, Col } from "antd";
import { getRights } from "../../redux/actions/userRights.action";
import { CSVLink } from "react-csv";
import { TabTitle } from "../../utils/GeneralFunction";
export default function VendorList({ token }) {
	
	TabTitle("Vendor List")
	const dispatch = useDispatch();
	const { vendor } = useSelector((state) => state);
	const dateFormat = "MM-DD-YYYY";
	const [show, setShow] = useState(false);
	const [dataLen, setDataLen] = useState(0);
	const { Panel } = Collapse;
	const history = useHistory();
	const [userRights, setUserRights] = useState({})
	const [form] = Form.useForm();
	const worker = {
		vendorId: null,
		from_date: moment().startOf('year'),
		to_date: moment().endOf('year'),
		vendorCode: null,
		vendorName: null,
		vendorCategory: null,
		webAddress: null,
		email: null,
		officePhoneNo: null,
		mobileNo: null,
		fax: null,

	};
	function downloadExcelFileData(data) {
		if (data) {
			return data.map((v) => {
				
				return {
					"Bill Header No": v.bill_header_no,
					"Vendor Name": v.vendor_name,
					"Posting Period": v.gl_period_name,
					"Account Name": v.account_name,
					"Currency": v.currency_name,
					"Amount": v.amount,
					"Memo": v.memo,
				};
			});
		}
	}
	useLayoutEffect(() => {
		dispatch(getRights(token, 189)).then((res) => {
			
			if (res.type === "GET_USER_RIGHTS_SUCCESS") {
				setUserRights(res?.payload)
			}
		});
	}, [token])
	const onFinishFilter = async (e) => {
		await form.validateFields().then((values) => dateChecker(values.from_date, values.to_date, "vendorList", token, values, dispatch))
	};
	const handleFilter = (pagination, filters, sorter, extra) => {

		setShow(true);
	};
	return (
		<div className="styleContainer">
			<div className="styleBody h-100">
				<PageHeader title={"Vendor List"} />
				<div className="bodyStyle">
					<Collapse style={{ width: "99%", margin: "10px" }} bordered={false} defaultActiveKey={["1"]} expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}>
						<Panel
							header="Filters"
							key="1"
							className="site-collapse-custom-panel"
							extra={
								<Button
									disabled={userRights.can_add === true ? false : true}
									onClick={() => {
										history.push("/vendor");
									}}
									className="iconBtn crudBTN"
									shape="circle"
									icon={<PlusOutlined />}
								/>
							}
						>
							<Form
								labelCol={{
									span: 8,
								}}
								wrapperCol={{
									span: 16,
								}}
								
								
								
								form={form}
								onFinish={onFinishFilter}
								initialValues={worker}
								
								autoComplete="off"
							>
								<Row>
									<Col xs={24} xl={8}>
										<Form.Item name="vendorId" label="Vendor Id" rules={[{ pattern: new RegExp(/^(?!0*[.,]0*$|[.,]0*$|0*$)\d+[,.]?\d{0,6}$/), message: "Must Be a Number." }]}>
											<Input allowClear size="small" />
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item name="mobileNo" label="Mobile Phone " >
											<Input allowClear size="small" />
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item name="fax" label="Fax " rules={[{ pattern: new RegExp(/^(?!0*[.,]0*$|[.,]0*$|0*$)\d+[,.]?\d{0,6}$/), message: "Must Be a Number." }]}>
											<Input allowClear size="small" />
										</Form.Item>
									</Col>
								</Row>
								<Row>
									<Col xs={24} xl={8}>
										<Form.Item name="vendorCode" label="Vendor Code" >
											<Input allowClear size="small" />
										</Form.Item>
									</Col>

									<Col xs={24} xl={8}>
										<Form.Item name="vendorName" label="Vendor Name">
											<Input allowClear size="small" />
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item name="vendorCategory" label="Vendor Category">
											<Input allowClear size="small" />
										</Form.Item>
									</Col>
								</Row>

								<Row>
									<Col xs={24} xl={8}>
										<Form.Item name="webAddress" label="web Address" >
											<Input allowClear size="small" />
										</Form.Item>
									</Col>

									<Col xs={24} xl={8}>
										<Form.Item name="email" label="Email">
											<Input allowClear size="small" />
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item name="officePhoneNo" label="Office Phone" >
											<Input allowClear size="small" />
										</Form.Item>
									</Col>
								</Row>
								<Row>
									<Col xs={24} xl={8}>
										<Form.Item label="From Date" name="from_date"
										>
											<DatePicker
												size="small"
												id="fromDate"
												format={dateFormat}
												className="dateInput customInputs"
											/>
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item label="To Date" name="to_date"
										>
											<DatePicker size="small" id="toDate" format={dateFormat} className="dateInput ustomInputs" />
										</Form.Item>
									</Col>
								</Row>

								<div style={{ display: "flex", justifyContent: "flex-end" }}>
									<Button type="primary" htmlType="submit" disabled={vendor.loading}>
										Search
									</Button>
								</div>
							</Form>
						</Panel>
					</Collapse>


					{vendor?.loading ? (
						<div className="mainProductContainer">
							<SkeletonLoad />
						</div>
					) : (
						<div className="mainProductContainer">
							<div style={{ padding: 10 }}>
								{vendor?.payload?.data.length > 0 && (
									<CSVLink
										filename={"Bill.csv"}
										data={
											downloadExcelFileData(vendor?.payload?.data) || []
										}
										onClick={() => { }}
									>
										Download Excel
									</CSVLink>
								)}
								<Tables
									loadWidth={40}
									type={"customer"}
									scroll={450}
									perPage={100}
									scrollX={"80rem"}
									col={vendorColumns}
									source={vendor?.payload?.data}
									load={vendor?.loading}
									paginate
								/>
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}
