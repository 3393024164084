import {
    GET_TERM_FOR_PRINT_REQUEST,
    GET_TERM_FOR_PRINT_SUCCESS,
    GET_TERM_FOR_PRINT_ERROR,
    ADD_TERM_FOR_PRINT_REQUEST,
    ADD_TERM_FOR_PRINT_ERROR,
    ADD_TERM_FOR_PRINT_SUCCESS,
    UPDATE_TERM_FOR_PRINT_REQUEST,
    UPDATE_TERM_FOR_PRINT_ERROR,
    UPDATE_TERM_FOR_PRINT_SUCCESS,
    DELETE_TERM_FOR_PRINT_REQUEST,
    DELETE_TERM_FOR_PRINT_ERROR,
    DELETE_TERM_FOR_PRINT_SUCCESS,
} from "../actions/types"

export default function (state = [], action) {
  const { type, payload } = action;
  switch (type) {
      case GET_TERM_FOR_PRINT_REQUEST:
          return {
              loading: true,
          };
      case GET_TERM_FOR_PRINT_SUCCESS:
          return {
              ...state,
              loading: false,
              payload,
          };
      case GET_TERM_FOR_PRINT_ERROR:
          return {
              ...state,
              loading: false
          };
      case ADD_TERM_FOR_PRINT_REQUEST:
          return {
              loading: true,
          };
      case ADD_TERM_FOR_PRINT_SUCCESS:
          return {
              ...state,
              loading: false,
              payload: [payload],
          };
      case ADD_TERM_FOR_PRINT_ERROR:
          return {
              ...state,
              loading: false
          };
      case DELETE_TERM_FOR_PRINT_REQUEST:
          return {
              loading: true,
          };
      case DELETE_TERM_FOR_PRINT_SUCCESS:
          return {
              ...state,
              loading: false,
              payload: [payload],
          };
      case DELETE_TERM_FOR_PRINT_ERROR:
          return {
              ...state,
              loading: false
          };
      case UPDATE_TERM_FOR_PRINT_REQUEST:
          return {
              loading: true,
          };
      case UPDATE_TERM_FOR_PRINT_SUCCESS:
          return {
              ...state,
              loading: false,
              payload: [payload],
          };
      case UPDATE_TERM_FOR_PRINT_ERROR:
          return {
              ...state,
              loading: false
          };

      default:
          return state;
  }
}