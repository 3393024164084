import {
	GET_ONE_SALE_RETURN_HEADER_REQUEST,
	GET_ONE_SALE_RETURN_HEADER_SUCCESS,
	GET_ONE_SALE_RETURN_HEADER_ERROR,
	GET_ONE_SALE_RETURN_HEADER_DETAIL_REQUEST,
	GET_ONE_SALE_RETURN_HEADER_DETAIL_SUCCESS,
	GET_ONE_SALE_RETURN_HEADER_DETAIL_ERROR,
	DELETE_SALE_RETURN_HEADER_REQUEST,
	DELETE_SALE_RETURN_HEADER_SUCCESS,
	DELETE_SALE_RETURN_HEADER_ERROR,
	ADD_SALE_RETURN_HEADER_REQUEST,
	ADD_SALE_RETURN_HEADER_SUCCESS,
	ADD_SALE_RETURN_HEADER_ERROR,
	UPDATE_SALE_RETURN_HEADER_REQUEST,
	UPDATE_SALE_RETURN_HEADER_SUCCESS,
	UPDATE_SALE_RETURN_HEADER_ERROR,
	DELETE_SALE_RETURN_LINE_REQUEST,
	DELETE_SALE_RETURN_LINE_SUCCESS,
	DELETE_SALE_RETURN_LINE_ERROR,


} from "../actions/types";

export default function (state = [], action) {
	const { type, payload } = action;
	switch (type) {
		case ADD_SALE_RETURN_HEADER_REQUEST:
			return {
				loading: true,
			};
		case ADD_SALE_RETURN_HEADER_SUCCESS:
			return {
				...state,
				loading: false,
				payload: [payload],
			};
		case ADD_SALE_RETURN_HEADER_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};

		case UPDATE_SALE_RETURN_HEADER_REQUEST:
			return {
				loading: true,
			};
		case UPDATE_SALE_RETURN_HEADER_SUCCESS:
			return {
				...state,
				loading: false,
				payload: [payload],
			};
		case UPDATE_SALE_RETURN_HEADER_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};

		case UPDATE_SALE_RETURN_HEADER_REQUEST:
			return {
				loading: true,
			};
		case UPDATE_SALE_RETURN_HEADER_SUCCESS:
			return {
				...state,
				loading: false,
				payloadSuccess: payload,
			};
		case UPDATE_SALE_RETURN_HEADER_ERROR:
			return {
				...state,
				loading: false,
				errorPayload: payload,
			};
		case DELETE_SALE_RETURN_HEADER_REQUEST:
			return {
				loading: true,
			};
		case DELETE_SALE_RETURN_HEADER_SUCCESS:
			return {
				...state,
				loading: false,
				payload: [payload],
			};
		case DELETE_SALE_RETURN_HEADER_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};
		case DELETE_SALE_RETURN_LINE_REQUEST:
			return {
				loading: true,
			};
		case DELETE_SALE_RETURN_LINE_SUCCESS:
			return {
				...state,
				loading: false,
				payload: [payload],
			};
		case DELETE_SALE_RETURN_LINE_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};
		case GET_ONE_SALE_RETURN_HEADER_REQUEST:
			return {
				loading: true,
			};
		case GET_ONE_SALE_RETURN_HEADER_SUCCESS:
			return {
				...state,
				loading: false,
				payload: payload,
			};
		case GET_ONE_SALE_RETURN_HEADER_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};
		case GET_ONE_SALE_RETURN_HEADER_DETAIL_REQUEST:
			return {
				loading: true,
			};
		case GET_ONE_SALE_RETURN_HEADER_DETAIL_SUCCESS:
			return {
				...state,
				loading: false,
				payload: payload,
			};
		case GET_ONE_SALE_RETURN_HEADER_DETAIL_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};
		default:
			return state;
	}
}
