import React, { useState, useLayoutEffect } from 'react'
import logo from "../../assets/images/logo.png"
import btnPrint from "../../assets/images/button_print.gif"
import xlsx from "../../assets/images/ico_xls_sm.gif"
import "./styleSheet.css"
import { Select, DatePicker, Form, Table, Spin, Button } from 'antd';
import { ReadCookie } from "../../utils/readCookie";
import { useSelector, useDispatch } from "react-redux";
import moment from 'moment';
import { useEffect } from 'react'
import { getRegion } from '../../redux/actions/region.action'
import { getPaymentTerm } from '../../redux/actions/paymentterm.action'
import { getGender } from '../../redux/actions/gender.action'
import { getOmsReport } from '../../redux/actions/omsReport.action'
import { getAllCompany } from '../../redux/actions/companyAccess'
import { Link, useParams, useLocation } from 'react-router-dom'
import { getOmsRegionStateReport } from '../../redux/actions/omsRegionReport.action'

const OmsRegionReport = ({ token, history, id, formData, propsData, }) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const paramsData = useParams();
    const [data, setData] = useState([])
    const { gender, region, companyAll, regionStateReport } = useSelector((state) => state);
    const [submit, setSubmit] = useState(false)
    const [loading, setLoading] = useState(false)
    const [values, setValues] = useState(null)
    const [regionID, setRegionID] = useState(null)

    function useQuery() {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    let query = useQuery();
    let check = query.get("submited")
    let company_id = query.get("company_id")
    let date_type = query.get("date_type")
    let gender_id = query.get("gender_id")
    let term_id = query.get("term_id")
    let from_date = query.get("from_date")
    let to_date = query.get("to_date")
    let region_id = query.get("region_id")
    let selected_Region = query.get("selected_Region")
    let selected_id = query.get("selected_id")
    let series = query.get("series")
    let select = query.get("select")
    let state_id = query.get("state_id")
    let customer_id = query.get("customer_id")
    let state_name = query.get("state_name")
    let region_name = query.get("region_name")

    useLayoutEffect(() => {
        setValues(history?.location?.values)
        setRegionID(history?.location?.state?.region_id)
        setLoading(true)
        dispatch(getOmsRegionStateReport(token, { id, company_id, date_type, gender_id, from_date, from_date, to_date, term_id, region_id, state_id, select, selected_id })).then((res) => {
            setSubmit(true)
            setLoading(false)
        });
    }, []);
    const columns = [
        {
            title: "#",
            dataIndex: "serial_no",
            align: 'left',
            key: 'serial_no',
            width: 50,
            render: (text, object, index) => index + 1

        },
        {
            title: 'Customer Name',
            dataIndex: 'customer_name',
            key: 'customer_id',
            render: (record, text) => {
                return <Link style={{ color: "blue", textDecoration: "underline" }}
                    to={{
                        pathname: `/oms-booking`,
                        search: `?submited=${true}&report=${4}&company_id=${company_id}&date_type=${date_type}&gender_id=${gender_id}&region_id=${region_id}&term_id=${term_id}&from_date=${moment(from_date).format("MM-DD-YYYY")}&to_date=${moment(to_date).format("MM-DD-YYYY")}&select=${select === "true" ? true : false}&selected_id=${selected_id}&customer_id=${text.customer_id}&customer_name=${text.customer_name}&state_name=${text.state_name}&state_id=${text.state_id}`,
                    }}
                > {record}</Link >
            }


        },
        {
            title: "State",
            dataIndex: "state_name",
            align: 'left',
            key: "state_id",
            width: 70,

        },

        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
            width: 100,
            align: "right"

        },
        {
            title: 'Amount',
            dataIndex: 'net_amount',
            key: 'net_amount',
            width: 130,
            align: "right",
            render: text => <span style={{ textAlign: "right" }}> {text}</span >,

        },
        {
            title: '%',
            align: 'right',
            dataIndex: 'percentage',
            key: 'percentage',
            width: 70,

        },
    ];

    const filterGender = gender.payload?.data.find((item) => {
        return item.gender_id === Number(gender_id)
    })

    const filterRegion = region.payload?.data.find((item) => {
        return item.region_id === Number(selected_id)
    })


    return (
        <>

            <div className='row'>
                <div className='col'>
                    <table id="Table1" cellPadding="3" cellSpacing="0" align="center" className="tablestyleControls">
                        <tbody>
                            <tr>
                                <td width="103">
                                    Featuring:
                                </td>
                                <td>
                                    <b>
                                        {gender_id === "All" ? "All" : filterGender && filterGender.gender_name}
                                    </b>
                                </td>
                                <td width="103">
                                    State:
                                </td>
                                <td>
                                    <b>
                                        {select === "true" ? "All" : state_name}
                                    </b>
                                </td>
                            </tr>
                            <tr>
                                <td >
                                    Payment Term:
                                </td>
                                <td>
                                    <b>
                                        {term_id === "0" ? "Without FOC" : "FOC"}
                                    </b>
                                </td>
                                <td style={{ width: "20px" }} className="no-border-btm" >
                                    Region:
                                </td>
                                <td>
                                    <b>
                                        {filterRegion && filterRegion.region_name}
                                    </b>

                                </td>
                            </tr>
                            <tr>
                                <td>
                                    From:
                                </td>
                                <td>
                                    <b>
                                        {from_date}
                                    </b>

                                </td>
                                <td style={{ width: "60px" }} className="no-border-btm" >
                                    To:
                                </td>
                                <td>
                                    <b>
                                        {to_date}
                                    </b>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ width: "80px" }}>
                                    Show Orders By:
                                </td>
                                <td>
                                    <b>
                                        {date_type === "B" ? "Booking Date" : "Start Date"}
                                    </b>
                                </td>
                                <td className="no-border-btm" >
                                    Brand :
                                </td>
                                <td>
                                    <b>
                                        {company_id === "2" ? "DL1961 : DL1961(USA) " : ""}
                                    </b>
                                </td>
                            </tr>
                        </tbody >
                    </table >
                </div>
            </div>

            {loading ?
                <div className='w-100 d-flex align-items-center justify-content-center spin-style mt-3' >
                    <Spin />
                </div> :
                regionStateReport?.payload?.data.length ?
                    <Table
                        scroll={{ x: 200 }}
                        className='w-100 test04 mt-3'
                        size='small'
                        pagination={{ pageSize: 3000 }}
                        summary={pageData => {
                            let sumQty = pageData.reduce(function (previousValue, currentValue) {
                                return previousValue += parseFloat(currentValue.quantity.replace(/,/g, ''))
                            }, 0);
                            let sumAmount = pageData.reduce(function (previousValue, currentValue) {
                                return previousValue += parseFloat(currentValue.net_amount.replace(/\$|,/g, ''))
                            }, 0);
                            return (
                                <>
                                    <Table.Summary.Row >
                                        <Table.Summary.Cell>
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell>
                                            <p style={{ paddingTop: "10px", fontWeight: "bolder" }}>Total</p>
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell>
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell>
                                            <p style={{
                                                fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black",
                                                borderBottom: "1px solid black"
                                            }}>{sumQty.toLocaleString("en-US")}</p>
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell>
                                            <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black" }}>$ {sumAmount.toLocaleString("en-US")}</p>
                                        </Table.Summary.Cell>
                                    </Table.Summary.Row>
                                </>
                            );
                        }}
                        bordered
                        columns={columns}
                        dataSource={regionStateReport?.payload?.data}
                    />
                    :
                    <div style={{
                        backgroundColor: "#CCCCCC", borderWidth: "1pt", borderStyle: "Dashed", fontWeight: "bold", height: "25px", display: "flex", alignItems: "center", justifyContent: "center"
                    }} >

                        No record(s)
                    </div>
            }
        </>
    )
}
export default OmsRegionReport;
