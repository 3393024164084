import {
  GET_CHARGE_BACK_DETAIL_REQUEST,
  GET_CHARGE_BACK_DETAIL_SUCCESS,
  GET_CHARGE_BACK_DETAIL_ERROR,
} from "./types";
import axios from "axios";
import { BASEURL } from "../../constant/config.js";
import moment from "moment";

export const getChargeBackDetail = (token, values) => (dispatch) => {
  dispatch({ type: GET_CHARGE_BACK_DETAIL_REQUEST });
  const newData = {
    from_date: moment(values.from_date).format("MM-DD-YYYY"),
    to_date: moment(values.to_date).format("MM-DD-YYYY"),
    customer_id: values.customer_id === undefined || values.customer_id === "All" || values.customer_id.length  === 0 ? null : values.customer_id,
    company_id: 2,
};
  return axios({
    method: "POST",
    url: `${BASEURL}getAll/chargeBackDetail`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: newData
  })
    .then((response) => {
      return dispatch({
        type: GET_CHARGE_BACK_DETAIL_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_CHARGE_BACK_DETAIL_ERROR,
        payload: [],
      });
    });
};
