import { useLocation } from "react-router-dom";
import moment from 'moment';
import numeral from 'numeral';

const CheckPay = () => {
    const { state: { state: { formData } } } = useLocation();

    function numberToWords(number) {
        const units = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'];
        const teens = ['Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];
        const tens = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];
        const scales = ['', 'Thousand', 'Million', 'Billion', 'Trillion', 'Quadrillion', 'Quintillion'];

        
        function convertLessThanOneThousand(num) {
            let words = '';
            if (num >= 100) {
                words += units[Math.floor(num / 100)] + ' Hundred ';
                num %= 100;
            }
            if (num >= 20) {
                words += tens[Math.floor(num / 10)] + ' ';
                num %= 10;
            }
            if (num > 0) {
                if (num < 10) {
                    words += units[num] + ' ';
                } else {
                    words += teens[num - 10] + ' ';
                }
            }
            return words.trim();
        }

        
        function convertToWords(num) {
            if (num === 0) {
                return 'Zero';
            }

            let words = '';
            let scaleIndex = 0;

            while (num > 0) {
                if (num % 1000 !== 0) {
                    const segmentWords = convertLessThanOneThousand(num % 1000);
                    words = segmentWords + scales[scaleIndex] + ' ' + words;
                }
                num = Math.floor(num / 1000);
                scaleIndex++;
            }

            return words.trim();
        }


        
        const [integerPart = '0', decimalPart = ''] = (formData?.payment_amount ?? '').toString().split('.');

        
        let integerWords = convertToWords(parseInt(integerPart, 10));
        if (integerWords === 'Zero' && decimalPart) {
            integerWords = '';
        }

        
        let decimalWords = '';
        if (decimalPart) {
            decimalWords = ',';
            for (let i = 0; i < decimalPart.length; i++) {
                decimalWords += ' ' + units[parseInt(decimalPart[i], 10)];
            }
        }

        
        const resultWords = integerWords + decimalWords;
        return resultWords.trim();
    }

    
    const number_words = numberToWords(formData?.payment_amount);




    return (
        <div
            style={{ width: "820px", display: "flex", justifyContent: "center", margin: "0 auto" }}
        >
            <div style={{
                
                width: "800px", height: "1000px", objectFit: "contain", backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "cover", position: "relative"
            }}>
                <p style={{ position: "absolute", top: "75px", left: "741px" }}>{formData?.check_no.slice(2)}</p>
                <p style={{ position: "absolute", top: "198px", left: "110px" }}>{formData?.vendor_name}</p>
                <p style={{ position: "absolute", top: "145px", left: "670px" }}>{moment(formData?.payment_date).format("MM-DD-YYYY")}</p>
                <p style={{ position: "absolute", top: "235px", left: "40px" }}>{number_words}</p>
                <p style={{ position: "absolute", top: "200px", left: "670px" }}>{numeral(formData?.payment_amount).format("00.00")}</p>
                <div style={{ position: "absolute", top: "288px", left: "73px", lineHeight: "0.2" }}>
                    <p>Franchise Tax Board</p>
                    <p>PO BOX 942857</p>
                    <p>sacramento, CA 94257-0531</p>
                </div>
                <p style={{ position: "absolute", top: "358px", left: "74px" }}>{formData?.memo}</p>
                <p style={{ position: "absolute", top: "380px", left: "180px" }}>{formData?.check_no.slice(2)}</p>

            </div>
        </div>
    )
}

export default CheckPay;