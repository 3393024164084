import {
    GET_PRODUCT_INFORMATION_BY_SIZE_REPORT_REQUEST,
    GET_PRODUCT_INFORMATION_BY_SIZE_REPORT_SUCCESS,
    GET_PRODUCT_INFORMATION_BY_SIZE_REPORT_ERROR

} from "./types";
import axios from "axios";
import { BASEURL } from "../../constant/config.js";


export const getProductInfoBySize = (token, values) => (dispatch) => {
    dispatch({ type: GET_PRODUCT_INFORMATION_BY_SIZE_REPORT_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/productinformationbysize`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { ...values },
    })
        .then((response) => {
            return dispatch({
                type: GET_PRODUCT_INFORMATION_BY_SIZE_REPORT_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) => {
            dispatch({
                type: GET_PRODUCT_INFORMATION_BY_SIZE_REPORT_ERROR,
                payload: [],
            });
            
            
            
            
            
            
            
            
            
            
        });
};
