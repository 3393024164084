import React, { useLayoutEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Form, Input, DatePicker, Collapse, Row, Col } from "antd";
import { CaretRightOutlined, PlusOutlined } from "@ant-design/icons";
import { dateChecker } from "../../components/CheckDate/checkDate";
import SkeletonLoad from "../../components/Skeleton/SkeletonLoad";
import { getRights } from "../../redux/actions/userRights.action";
import { saleOrderNewColumns } from "../../utils/saleOrderCols";
import PageHeader from "../../components/PageHeader/PageHeader";
import Tables from "../../components/Table/Tables";
import { useHistory } from "react-router-dom";
import "../../screens/Product/product.css";
import moment from "moment";
import "../Style/style.css";
import { CSVLink } from "react-csv";
import { ReadCookie } from "../../utils/readCookie";

const { Panel } = Collapse;
export default function SaleOrderList({ token }) {
	const [form] = Form.useForm();
	const history = useHistory();
	const dispatch = useDispatch();
	const { salesOrderList } = useSelector((state) => state);
	const dateFormat = "MM-DD-YYYY";
	const [userRights, setUserRights] = useState({})

	const[menuId, setMenuId] = useState(53);
	
	
	const worker = {
		from_date: moment().startOf('year'),
		to_date: moment().endOf('year'),
		order_header_no: null,
		po_no: null,
		customer_code: null,
		sales_person_name: null,
		region_name: null,
		gender_name: null,
		term_name: null
	};

	
	useLayoutEffect(() => {
		dispatch(getRights(token, menuId)).then((res) => {
			if (res.type === "GET_USER_RIGHTS_SUCCESS") {
				setUserRights(res?.payload)
			}
		});
	}, [token])

	
	const onFinishFilter = async (e) => {
		await form.validateFields().then((values) => {
			dateChecker(values.from_date, values.to_date, "saleOrderList", token, values, dispatch);
		})
	};



	function downloadExcelFileData(data) {
		if (data) {
			return data.map((v) => {
				return {
					"Order ID": v.order_header_id,
					"Order No": v.order_header_no,
					"Order Date": moment(v.order_date).format("MM-DD-YYYY"),
					"PO #": v.reference_no,
					"Customer": v.customer_name,
					"Term": v.term_name,
					"Region Name": v.region_name,
					"SalePerson Name": v.salesperson_name,
					"Gender Name": v.gender_name,
				};
			})
		}
	}


	return (
		<div className="styleContainer">
			<div className="styleBody h-100">
				<PageHeader title={"Sales Order List"} />
				<div className="bodyStyle">
					<Collapse
						style={{ width: "100%", padding: "10px" }}
						bordered={false} defaultActiveKey={["1"]}
						expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}>
						<Panel
							header="Filters"
							key="1"
							className="site-collapse-custom-panel"
							extra={
								<Button
									disabled={userRights.can_add === true ? false : true}
									onClick={() => history.push("/sale-order?mode=add")}
									className="iconBtn crudBTN"
									shape="circle"
									icon={<PlusOutlined />}
								/>
							}
						>
							<Form
								form={form}
								labelCol={{ span: 8 }}
								wrapperCol={{ span: 16 }}
								initialValues={worker}
								onFinish={onFinishFilter}
								autoComplete="off">
								<Row>
									<Col xs={24} xl={8}>
										<Form.Item label="SO NO" name="order_header_no" rules={[{ pattern: new RegExp(/^(?!0*[.,]0*$|[.,]0*$|0*$)\d+[,.]?\d{0,6}$/), message: "Must Be a Number." }]}
										>
											<Input allowClear size="small" />
										</Form.Item>
									</Col>

									<Col xs={24} xl={8}>
										<Form.Item label="From Date" name="from_date"
										>
											<DatePicker
												size="small"
												id="fromDate"
												format={dateFormat}
												className="dateInput customInputs"
											/>
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item label="To Date" name="to_date"
										>
											<DatePicker size="small" id="toDate" format={dateFormat} className="dateInput ustomInputs" />
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item label="PO #" name="po_no">
											<Input allowClear size="small" />
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item label="Customer" name="customer_code" >
											<Input allowClear size="small" />
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item label="Sale Person Name" name="sales_person_name" >
											<Input allowClear size="small" />
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item label="Region" name="region_name">
											<Input allowClear size="small" />
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item label="Gender" name="gender_name" rules={[{ pattern: new RegExp(/^[a-zA-Z]*$/), message: "Must be Characters." }]}>
											<Input allowClear size="small" />
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item label="Terms" name="term_name">
											<Input allowClear size="small" />
										</Form.Item>
									</Col>
								</Row>
								<div style={{ display: "flex", justifyContent: "flex-end" }}>
									<Button type="primary" htmlType="submit" disabled={salesOrderList.loading}>
										Search
									</Button>
								</div>
							</Form>
						</Panel>
					</Collapse>
					{salesOrderList.loading ? (
						<div className="mainProductContainer">
							<SkeletonLoad />
						</div>
					) : (
						<div className="mainProductContainer">
							<div style={{ padding: 10 }}>
								{
									salesOrderList?.payload?.data && (
										<CSVLink
											filename={"Sale-Order.csv"}
											data={downloadExcelFileData(salesOrderList?.payload?.data) || []}
											onClick={() => {
											}}
										>
											Download Excel
										</CSVLink>
									)
								}
								<Tables
									loadWidth={40}
									type={"saleOrderList"}
									scroll={450}
									perPage={100}
									scrollX={"80rem"}
									col={saleOrderNewColumns}
									source={salesOrderList?.payload?.data}
									load={salesOrderList.loading}
									paginate
								/>
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}
