import { GET_CUSTOMER_TERM_REQUEST, GET_CUSTOMER_TERM_SUCCESS, GET_CUSTOMER_TERM_ERROR } from "./types"
import { BASEURL } from "../../constant/config.js"
import axios from "axios";

export const getCustomerTerm = (token) => dispatch => {
    dispatch({ type: GET_CUSTOMER_TERM_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/customerTerm`,
        headers: {
            Accept: "application/json",
            'Authorization': 'Bearer ' + token
        }
    })
        .then(response => {

            return dispatch({ type: GET_CUSTOMER_TERM_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: GET_CUSTOMER_TERM_ERROR,
                payload: error.response,
            });
        })
}

