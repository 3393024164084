import {

  GET_SALESPERSONLIST_REQUEST, GET_SALESPERSONLIST_SUCCESS, GET_SALESPERSONLIST_ERROR

} from "../actions/types";

export default function (state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case GET_SALESPERSONLIST_REQUEST:
      return {
        loading: true,
      };
    case GET_SALESPERSONLIST_SUCCESS:
      return {
        ...state,
        loading: false,
        payload,
      };
    case GET_SALESPERSONLIST_ERROR:
      return {
        ...state,
        loading: false,
        payload,
      };

    default:
      return state;
  }
}
