import { GET_ADMIN_LOCATION_SUCCESS,GET_ADMIN_LOCATION_ERROR,GET_ADMIN_LOCATION_REQUEST,ADD_ADMIN_LOCATION_ERROR,ADD_ADMIN_LOCATION_REQUEST,ADD_ADMIN_LOCATION_SUCCESS,
    UPDATE_ADMIN_LOCATION_ERROR,UPDATE_ADMIN_LOCATION_REQUEST,UPDATE_ADMIN_LOCATION_SUCCESS,DELETE_ADMIN_LOCATION_ERROR,DELETE_ADMIN_LOCATION_REQUEST,DELETE_ADMIN_LOCATION_SUCCESS
 } from "../actions/types";



 
export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
      case GET_ADMIN_LOCATION_REQUEST:
        return {
          loading: true,
        };
      case GET_ADMIN_LOCATION_SUCCESS:
        return {
          ...state,
          loading: false,
          payload,
        };
      case GET_ADMIN_LOCATION_ERROR:
        return {
          ...state,
          loading: false,
          payload,
        };
        case ADD_ADMIN_LOCATION_REQUEST:
            return {
                loading: true,
            };
        case ADD_ADMIN_LOCATION_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case ADD_ADMIN_LOCATION_ERROR:
            return {
                ...state,
                loading: false
            };
            case UPDATE_ADMIN_LOCATION_REQUEST:
              return {
                  loading: true,
              };
          case UPDATE_ADMIN_LOCATION_SUCCESS:
              return {
                  ...state,
                  loading: false,
                  payload: [payload],
              };
          case UPDATE_ADMIN_LOCATION_ERROR:
              return {
                  ...state,
                  loading: false
              };
              case DELETE_ADMIN_LOCATION_REQUEST:
                return {
                    loading: true,
                };
            case DELETE_ADMIN_LOCATION_SUCCESS:
                return {
                    ...state,
                    loading: false,
                    payload: [payload],
                };
            case DELETE_ADMIN_LOCATION_ERROR:
                return {
                    ...state,
                    loading: false
                };
            default:
                return state;
    }
  }
  