import React, { useEffect, useState } from "react";
import { Table, Input, Select, InputNumber, Form, Button, Modal, Empty, Checkbox } from "antd";
import notification from "../../utils/notification";
import { useSelector, useDispatch } from "react-redux";
import { getProductSale } from "../../redux/actions/productSale.action";
import { getCommitCriteria } from "../../redux/actions/commitCriteria.action";
import numeral from "numeral";
import Swal from "sweetalert2";
const { Option } = Select;

const EditableTable = ({ token, companyId, modalValue, setIsModalVisible, setOrderItems, setShowGrid, orderItems, headerNo, filteredItems, setFilteredItems, adjustmentLocation, adjLoc }) => {
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const { ProductSale, commitCriteria } = useSelector((state) => state);
	const [data, setData] = useState([]);
	const [selectedId, setSelectedId] = useState(null);
	const [selectedItems, setSelectedItems] = useState([]);
	const [arr, setArr] = useState([]);
	const [selectedRowKey, setSelectedRowKey] = useState([]);
	const [rate, setRate] = useState("");
	const [costPrice, setCostPrice] = useState(null);
	const [quantity, setQuantity] = useState(null);




	useEffect(() => {
		dispatch(getCommitCriteria(token)).then((res) => {
		});
	}, []);


	const updateCostPrice = (type, id, value) => {
		setCostPrice(value);
		let arr = [...data];
		const filter = arr.map((item) => {
			if (item.item_code === id) {
				if (type === "up") {
					item.rate = value;
					item.amount = parseFloat(item.quantity * item.rate).toFixed(2);
					return item;
				}
				if (type === "down") {
					item.rate = value;
					item.amount = parseFloat(item.quantity * item.rate).toFixed(2);
					return item;
				}
			} else {
				return item;
			}
		});
		let selectItem = filter.filter((f) => f.isSelected);
		setFilteredItems(selectItem);
		setData(filter);
	};

	const updateQuantity = (type, id, value) => {

		setQuantity(value);
		let arr = [...data];
		const filter = arr.map((item) => {



			if (item.item_code === id) {
				if (type === "up") {

					item.quantity = value;
					item.amount = numeral(item.quantity * item.rate).format('0.00');
					if (item.commit_status == 3) {
						item.quantity_backorder = item.quantity;
					}
					else {
						item.quantity_backorder =
							parseInt(item.quantity) - parseInt(item.quantity_available) > 0
								? parseInt(item.quantity) - parseInt(item.quantity_available)
								: 0;
					}

					if (item.commit_status == 3) {
						item.quantity_committed = 0;
					}
					else {


						item.quantity_committed =
							parseInt(item.quantity) -
							(parseInt(item.quantity) - parseInt(item.quantity_available) > 0
								? parseInt(item.quantity) - parseInt(item.quantity_available)
								: 0);
					}

					return item;
				}
				if (type === "down") {
					item.quantity = value;
					item.amount = numeral(item.quantity * item.rate).format('0,0.00');

					if (item.commit_status == 3) {
						item.quantity_backorder = item.quantity;
					}
					else {
						item.quantity_backorder =
							parseInt(item.quantity) - parseInt(item.quantity_available) > 0
								? parseInt(item.quantity) - parseInt(item.quantity_available) : 0;
					}
					if (item.commit_status == 3) {
						item.quantity_committed = 0;
					}
					else {
						item.quantity_committed =
							parseInt(item.quantity) -
							(parseInt(item.quantity) - parseInt(item.quantity_available) > 0
								? parseInt(item.quantity) - parseInt(item.quantity_available)
								: 0);
					}

					return item;
				}
			} else {
				return item;
			}
		});
		let selectItem = filter.filter((f) => f.isSelected);
		setFilteredItems(selectItem);
		setData(filter);
	};

	useEffect(() => {
		let arr = [...data];
		let newArr = [...selectedItems];
		let onlyKey = [...newArr].map((sItem) => sItem.item_code);

		arr = [...arr].map((item) => {
			if (onlyKey.indexOf(item.item_code) != -1) {
				return {
					...newArr[onlyKey.indexOf(item.item_code)],
				};
			}
			return item;
		});
		setData(arr);
	}, [selectedItems]);
	useEffect(() => {
		if (!modalValue) {
			setData([]);
			setSelectedId(null);
			setSelectedItems([]);
		}
	}, [modalValue]);

	const handleGenerate = () => {
		
		dispatch(getProductSale(token, companyId, selectedId, adjustmentLocation ? adjustmentLocation : adjLoc)).then((res) => {
			if (res.type === "GET_PRODUCTSALE_SUCCESS") {

				const arr = res?.payload?.map(items => {
					let found = orderItems.find(f => f.item_id === items.item_id)
					if (found) {
						found.quantity_available = items.quantity_available
						found.size = items.size
						found.gender_category_name = items.gender_category_name
						found.style_name = items.style_name
						found.wash_name = items.wash_name
						found.already = true
						return found
					} else {
						items.commit_status = 1;
						return items
					}
				})


				setData(arr);
			} else if (res.type === "GET_PRODUCTSALE_ERROR") {
				Swal.fire({
					icon: "error",
					title: "Oops...",
					text: "Select Location",
				});
			}
		});
	};

	const handleRate = (rate) => {
		let arr = [...data];
		arr.map((item) => {
			item.rate = rate;
		});
		setData(arr);
		setRate(rate);
	};

	const handleCommitStatus = (id, value) => {
		let arr = [...data];
		const filter = arr.map((item) => {
			if (item.item_code === id) {
				item.commit_status = value;
				if (value == 3) {
					item.quantity_backorder = item.quantity;
					item.quantity_committed = 0;
				}
				return item;
			} else {
				return item;
			}
		});
		let selectItem = filter.filter((f) => f.isSelected);
		setFilteredItems(selectItem);
		setData(filter);
	};

	const handleOk = () => {
		addOrderItems();
	};

	const handleCancel = () => {
		setIsModalVisible(false);
		setRate("");
	};


	const addOrderItems = () => {
		setOrderItems(filteredItems);
		setData([]);
		setSelectedId(null);
	};



	useEffect(() => {

	}, [])
	const rowSelection = (event, record, index) => {
		let copyArr = [...data];
		arr.push(record);
		if (event.target.checked) {
			copyArr[index].isSelected = true;
			copyArr[index].quantity = copyArr[index].hasOwnProperty("quantity") ? copyArr[index].quantity : 1;
			copyArr[index].tax_amount = copyArr[index].hasOwnProperty("tax_amount") ? copyArr[index].tax_amount : 0;
			copyArr[index].discount = copyArr[index].hasOwnProperty("discount") ? copyArr[index].discount : 0;
			copyArr[index].discount_percent = copyArr[index].hasOwnProperty(
				"discount_percent"
			)
				? copyArr[index].discount_percent
				: 0;
			copyArr[index].tax_percent = copyArr[index].hasOwnProperty(
				"taxt_percent"
			)
				? copyArr[index].tax_percent
				: 0;
			copyArr[index].quantity = 1;
			copyArr[index].amount = numeral(copyArr[index].quantity * copyArr[index].rate).format('0.00');
			copyArr[index].net_amount = numeral(copyArr[index].quantity * copyArr[index].rate -
				copyArr[index].discount
			).format('0.00');


			let avail = copyArr[index].quantity_available;
			if (avail > copyArr[index].quantity) {
				copyArr[index].quantity_backorder = 0;
				copyArr[index].quantity_committed = copyArr[index].quantity;

			}
			else if (avail < copyArr[index].quantity) {
				copyArr[index].quantity_backorder = copyArr[index].quantity - avail;
				copyArr[index].quantity_committed = avail;

			}


		} else {
			copyArr[index].quantity = 0;
			copyArr[index].quantity_committed = 0;
			copyArr[index].isSelected = false;
		}
		const selectedItem = copyArr.filter((v) => v.isSelected === true);
		setData(copyArr);
		setFilteredItems(selectedItem);
		
	}



	const columns = [
		{
			title: "Selected",
			render: (text, record, index) => {
				return <Checkbox
					defaultChecked={record.already}
					disabled={record.already}
					onChange={(event) => rowSelection(event, record, index)}></Checkbox>
			},
		},
		{
			title: "Item Code",
			dataIndex: "item_code",
		},
		{
			title: "Style Name",
			dataIndex: "style_name",
		},
		{
			title: "Wash Name",
			dataIndex: "wash_name",
		},

		{
			title: "Inseam",
			dataIndex: "inseam",
		},
		{
			title: "Size",
			dataIndex: "size",
		},

		{
			title: "Rate",
			dataIndex: "rate",
			render: (text, record, index) => {
				return (
					<InputNumber
						readOnly
						size="small"
						disabled={!record?.isSelected || record.already}
						value={rate ? record.rate : costPrice}
						defaultValue={rate ? rate : record.rate}
						onChange={(value) => updateCostPrice("up", record.item_code, value, index)}
					/>
				);
			},
		},
		{
			title: "Quantity",
			dataIndex: "quantity",
			editable: true,
			render: (text, record, index) => {
				const res = orderItems.filter((item) => item.item_code === record.item_code)
				return (
					<InputNumber
						size="small"
						disabled={!record?.isSelected}
						onChange={(value) => updateQuantity("up", record.item_code, value, index)}
						value={data.length > 0 && data[index]?.quantity}
					/>
				);
			},
		},
		{
			title: "Amount",
			editable: false,
			render: (text, record) => {
				return (
					<>
						{parseFloat(
							record.quantity ? record.quantity * record.rate : 0
						).toFixed(2)}
					</>
				);
			},
		},

	];

	return (
		<Modal
			okButtonProps={{
				style: {
					display: arr.length > 0 ? "" : "none",
				},
			}}
			okText="Add Item"
			destroyOnClose={true}
			width={"100%"}
			centered={true}
			title="Products Selection"
			visible={modalValue}
			onOk={handleOk}
			cancelText="Close"
			onCancel={handleCancel}
		>
			<Form form={form} component={false}>
				<div style={{ padding: "10px 0", display: "flex", gap: "2px" }}>
					<Input
						size="small"
						onPressEnter={(e) => {

							if (e.target.value.length) {
								handleGenerate();
							}


						}}
						placeholder="SKU"
						className="w-100"
						value={selectedId}
						name="searchItem"
						onChange={(e) => {
							setSelectedId(e.target.value);
						}}
					/>

					<Input
						size="small"
						className="w-100"
						value={rate}
						name="searchItem"
						placeholder="Rate"

						onChange={(e) => {
							handleRate(e.target.value);
						}}
					/>
					<Button
						className="customInputs"
						type="primary"
						disabled={!selectedId}
						onClick={() => handleGenerate()}
					>
						Views
					</Button>
				</div>
				<Table
					size="small"
					loading={ProductSale.loading}
					bordered
					dataSource={data.map((it) => ({ ...it, key: it.item_code, }))}
					columns={columns}
					rowClassName="editable-row"
					pagination={false}
				/>
			</Form>
		</Modal >
	);
};
export default EditableTable;




