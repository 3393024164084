import { GET_PRODUCTSALETRANSFER_REQUEST, GET_PRODUCTSALETRANSFER_SUCCESS, GET_PRODUCTSALETRANSFER_ERROR } from "../actions/types";
export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case GET_PRODUCTSALETRANSFER_REQUEST:
            return {
                loading: true,
            }
        case GET_PRODUCTSALETRANSFER_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            }
        case GET_PRODUCTSALETRANSFER_ERROR:
            return {
                ...state,
                loading: false,
            }
        default:
            return state
    }
}