import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getFabricComposition,
  addFabricComposition,
  deleteFabricComposition,
  updateFabricComposition,
} from "../../redux/actions/FabricComposition.action";

import { getLog } from "../../redux/actions/log.action";
import { getRights } from "../../redux/actions/userRights.action";

import PageHeader from "../../components/PageHeader/PageHeader";
import CrudButtons from "../../components/CrudButtons/CrudButtons";
import { ReadCookie } from "../../utils/readCookie";
import Tables from "../../components/Table/Tables";
import { Button, Row, Col, Form, Input, Checkbox, Tabs, Divider } from "antd";

import { logColumns, fabricCompositionColumns } from "../../utils/cols";
import notification from "../../utils/notification";
import "../Style/style.css";
import { TabTitle } from "../../utils/GeneralFunction";
const { TabPane } = Tabs;
export default function FabricComposition({ token }) {
  TabTitle("Fabric Composition")
  const menuId = ReadCookie("menuId");

  const FabricCompositionNameInput = useRef(null);
  const [form] = Form.useForm();
  const [fabricCompositionObj, setFabricCompositionObj] = useState(null);
  const dispatch = useDispatch();
  const { fabricComposition, log, userRights } = useSelector((state) => state);
  const [minimize, setMinimize] = useState(false);
  const [check, setCheck] = useState(false);
  const [fabricCompositionName, setFabricCompositionName] = useState("");
  const [disable, setDisable] = useState(false);
  const [formState, setFormState] = useState("view");

  useEffect(() => {
    dispatch(getFabricComposition(token, menuId));
    
    dispatch(getLog(token, { inv_fabric_composition: "inv_fabric_composition" }))
  }, [token]);

  useLayoutEffect(() => {
    dispatch(getRights(token, menuId)).then((res) => {
      if (res.type === "GET_USER_RIGHTS_SUCCESS") {
        if (!res?.payload.can_add) {
          setFormState("table");
        }
      }
    });
  }, []);

  useEffect(() => {
    if (fabricCompositionObj) {
      setFormState("view");
      form.setFieldsValue({
        id: fabricCompositionObj.fabric_composition_id,
        fabricComposition: fabricCompositionObj.composition_name,
        active: fabricCompositionObj.is_active,
      });
      setFabricCompositionName(fabricCompositionObj.composition_name);
      setCheck(fabricCompositionObj.is_active);
    }
  }, [fabricCompositionObj]);

  useEffect(() => {
    if (fabricCompositionName === "") {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [fabricCompositionName]);

  const handleTab = (key) => {
    if (key === "logs") {
      dispatch(
        getLog(token, {
          inv_fabric_composition: "inv_fabric_composition",
          admin_company_entity_access: "admin_company_entity_access",
        })
      );
    }
  };

  const formSave = () => {
    if (formState === "add") {
      dispatch(addFabricComposition(token, { fabricCompositionName, check }, menuId)).then(
        (res) => {
          if (res.type === "ADD_FABRICCOMPOSITION_ERROR") {
            notification(
              "error",
              res?.payload?.request?.statusText,
              res?.payload?.data?.data
            );
            form.setFieldsValue({
              fabricComposition: ""
          })
          setFormState("")
          
          setFabricCompositionName("")
          setFabricCompositionObj(null)
          }
          if (res.type === "ADD_FABRICCOMPOSITION_SUCCESS") {
            notification(
              "success",
              "Fabric Composition Created",
              "Record updated successfully"
            );
            form.setFieldsValue({
              fabricComposition: ""
          })
          setFormState("")
          
          setFabricCompositionName("")
          setFabricCompositionObj(null)
          }
          dispatch(getFabricComposition(token));
        }
      );
    }

    if (formState === "edit") {
      dispatch(
        updateFabricComposition(
          token,
          { fabricCompositionName, check },
          fabricCompositionObj.fabric_composition_id
        )
      ).then((res) => {
        if (res.type === "UPDATE_FABRICCOMPOSITION_ERROR") {
          notification("error", "Update", "Something went wrong");
        }
        if (res.type === "UPDATE_FABRICCOMPOSITION_SUCCESS") {
          notification(
            "success",
            "Fabric Composition Updated",
            "Record updated successfully"
          );
        }
        dispatch(getFabricComposition(token));
      });
    }
  };

  const handleDelete = () => {
    if (fabricCompositionObj) {
      dispatch(deleteFabricComposition(token, fabricCompositionObj.fabric_composition_id)).then(
        (res) => {
          if (res.type === "DELETE_FABRICCOMPOSITION_SUCCESS") {
            notification(
              "success",
              "Fabric Composition Deleted",
              "Fabric Composition deleted successfully!"
            );
          }
          if (res.type === "DELETE_FABRICCOMPOSITION_ERROR") {
            notification(
              "error",
              res?.payload?.request?.statusText,
              res?.payload?.data?.data
            );
          }
          form.setFieldsValue({
            fabricComposition: "",
          });
          
          setFabricCompositionName("");
          setFabricCompositionObj(null);
          setFormState("");
          dispatch(getFabricComposition(token));
        }
      );
    }
  };

  useEffect(() => {
    if (formState === "add") {
       
        FabricCompositionNameInput.current.focus();
        form.setFieldsValue({
            fabricComposition: ""
        })
        setCheck(true)
        setFabricCompositionName("")
        setFabricCompositionObj(null)
    }
}, [formState]);


  const filterReset = () => {
    setFormState("view");
  };

  return (
    <div className="styleContainer">
      <div className="styleBody h-100">
        <PageHeader
          title={"Fabric Composition"}
          minimize={setMinimize}
          check={minimize}
        />
        {!minimize && (
          <div className="bodyStyle">
            <div className="crudDiv">
              <CrudButtons
                rights={userRights?.payload}
                reset={filterReset}
                formType={formState}
                save={formSave}
                disable={disable}
                formState={setFormState}
                onDelete={handleDelete}
              />
            </div>

            <div className="styleContent">
              <Row className="tables-main-row-new">
                <Col xs={24} sm={24} md={10} lg={8} xl={6}>
                  <div className="styleTable">
                    <Tables
                      type="fabricComposition"
                      fabricCompositionObj={setFabricCompositionObj}
                      scrollX={"20rem"}
                      scroll={602}
                      perPage={25}
                      source={fabricComposition?.payload?.data}
                      col={fabricCompositionColumns}
                      paginate
                      load={fabricComposition.loading}
                    />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={14} lg={16} xl={18}>
                  <div className="styleForm">
                    <Form form={form} name="basic" labelCol={{ span: 8 }}>
                      <div className="customRow styleFormUpper ">
                        {fabricCompositionObj !== null && !(formState === "add") && (
                          <div className="customCols slc">
                            <Form.Item
                              label="ID"
                              name="id"
                              initialValue={form.getFieldValue().id}
                              labelCol={{ span: 10 }}
                              wrapperCol={{ span: 14 }}
                            >
                              <Input
                                size="small"
                                className="styleInput id-field-style"
                                readOnly
                              />
                            </Form.Item>
                          </div>
                        )}
                        <div className="customCols slc">
                          <Form.Item
                            label="Fabric Composition"
                            name="fabricComposition"
                            labelCol={{ span: 10 }}
                            wrapperCol={{ span: 14 }}
                            initialValue={form.getFieldValue().style}
                          >
                            <Input
                              size="small"
                              
                              disabled={
                                !(formState === "add" || formState === "edit")
                              }
                              ref={FabricCompositionNameInput}
                              onChange={(e) =>
                                setFabricCompositionName(e.target.value)
                              }
                              className="styleInput"
                            />
                          </Form.Item>
                        </div>
                        <div className="customCols slc">
                          <Form.Item
                            label="Active"
                            name="active"
                            labelCol={{ span: 10 }}
                            wrapperCol={{ span: 14 }}
                          >
                            <Checkbox
                              disabled={
                                !(formState === "add" || formState === "edit")
                              }
                              checked={check}
                              onChange={(e) => setCheck(e.target.checked)}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Form>
                    <Divider />
                    <div className="styleTab">
                      <Tabs
                        type="card"
                        tabBarGutter={2}
                        defaultActiveKey="1"
                        centered
                        onChange={(key) => handleTab(key)}
                      >
                        <TabPane tab="Log Info" key="logs">
                          <div className="tabs">
                            <Tables
                              type="company"
                              scrollX={"50rem"}
                              scroll={360}
                              perPage={15}
                              source={log?.payload?.data}
                              col={logColumns}
                              load={log.loading}
                              paginate
                            />
                          </div>
                        </TabPane>
                      </Tabs>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        )}
      </div>
      {minimize && (
        <div className="styleFooter">
          <Button
            className="minimize-button-style"
            onClick={() => setMinimize(false)}
          >
            Style
          </Button>
        </div>
      )}
    </div>
  );
}
