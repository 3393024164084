import { GET_SCM_TARGET_MONTH_REQUEST,GET_SCM_TARGET_MONTH_ERROR,GET_SCM_TARGET_MONTH_SUCCESS } from "../actions/types";

 
export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
      
        case GET_SCM_TARGET_MONTH_REQUEST:
            return {
                loading: true,
            };
        case GET_SCM_TARGET_MONTH_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_SCM_TARGET_MONTH_ERROR:
            return {
                ...state,
                loading: false
            };
            default:
                return state;
    }
  }
  