import {
	GET_SALES_TARGET_FIGURE_REQUEST,
	GET_SALES_TARGET_FIGURE_ERROR,
	GET_SALES_TARGET_FIGURE_SUCCESS,
	DELETE_SCM_FIGURE_REQUEST,
	DELETE_SCM_FIGURE_ERROR,
	DELETE_SCM_FIGURE_SUCCESS,
	ADD_SCM_FIGURE_REQUEST,
	ADD_SCM_FIGURE_ERROR,
	ADD_SCM_FIGURE_SUCCESS,
	UPDATE_SCM_FIGURE_SUCCESS,
	UPDATE_SCM_FIGURE_ERROR,
	UPDATE_SCM_FIGURE_REQUEST,
} from "./types";

import { BASEURL } from "../../constant/config.js";

const axios = require("axios");

export const getScmFigure = (token, id) => (dispatch) => {
	dispatch({ type: GET_SALES_TARGET_FIGURE_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}getAll/scmFigure`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: { id },
	})
		.then((response) => {
			return dispatch({ type: GET_SALES_TARGET_FIGURE_SUCCESS, payload: response?.data });
		})
		.catch((error) => {
			return dispatch({
				type: GET_SALES_TARGET_FIGURE_ERROR,
				payload: error.response,
			});
		});
};

export const deleteScmFigure = (token, id) => (dispatch) => {
	dispatch({ type: DELETE_SCM_FIGURE_REQUEST });
	return axios({
		method: "DELETE",
		url: `${BASEURL}delete/scmFigure/${id}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: { id },
	})
		.then((response) => {

			return dispatch({ type: DELETE_SCM_FIGURE_SUCCESS, payload: response?.data?.data });
		})
		.catch((error) => {

			return dispatch({
				type: DELETE_SCM_FIGURE_ERROR,
				payload: error.response,
			});
		});
};

export const addScmFigure = (token, data) => (dispatch) => {
	dispatch({ type: ADD_SCM_FIGURE_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}create/scmFigure`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: { figure_name: data.scmFigureTypeName, is_active: data.check },
	})
		.then((response) => {
			return dispatch({ type: ADD_SCM_FIGURE_SUCCESS, payload: response?.data?.data });
		})
		.catch((error) => {
			return dispatch({
				type: ADD_SCM_FIGURE_ERROR,
				payload: error.response,
			});
		});
};
export const updateScmFigure = (token, data, id) => (dispatch) => {
	dispatch({ type: UPDATE_SCM_FIGURE_REQUEST });
	return axios({
		method: "PUT",
		url: `${BASEURL}update/scmFigure/${id}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: {
			figure_name: data.scmFigureTypeName, is_active: data.check, figure_id: id
		},
	})
		.then((response) => { return dispatch({ type: UPDATE_SCM_FIGURE_SUCCESS, payload: response?.data?.data }) })
		.catch((error) => {
			return dispatch({
				type: UPDATE_SCM_FIGURE_ERROR,
				payload: error.response?.data,
			})
		})
}