import {
  PUT_INVOICE_LINE_DATA_ERROR,
  PUT_INVOICE_LINE_DATA_REQUEST,
  PUT_INVOICE_LINE_DATA_SUCCESS,
  GET_ONE_INVOICE_RELATED_RECORD_REQUEST,
  GET_ONE_INVOICE_RELATED_RECORD_SUCCESS,
  GET_ONE_INVOICE_RELATED_RECORD_ERROR
} from "../actions/types";

export const updateInvoiceLine = (state = [], action) => {
  const { type, payload } = action;
  switch (type) {
    case PUT_INVOICE_LINE_DATA_REQUEST:
      return {
        loading: true,
      };
    case PUT_INVOICE_LINE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        payload,
      };
    case PUT_INVOICE_LINE_DATA_ERROR:
      return {
        ...state,
        loading: false,
      };
    case GET_ONE_INVOICE_RELATED_RECORD_REQUEST:
      return {
        loading: true,
      };
    case GET_ONE_INVOICE_RELATED_RECORD_SUCCESS:
      return {
        ...state,
        loading: false,
        payload,
      };
    case GET_ONE_INVOICE_RELATED_RECORD_ERROR:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
