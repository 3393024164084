import {
  GET_PICKTICKET_SUMMARY_REQUEST, 
  GET_PICKTICKET_SUMMARY_SUCCESS,
  GET_PICKTICKET_SUMMARY_ERROR   
  } from "../actions/types";
  
  export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
      case GET_PICKTICKET_SUMMARY_REQUEST:
        return {
          loading: true,
        };
      case GET_PICKTICKET_SUMMARY_SUCCESS:
        return {
          ...state,
          loading: false,
          payload,
        };
      case GET_PICKTICKET_SUMMARY_ERROR:
        return {
          ...state,
          loading: false,
          payload,
        };
  
      default: {
        return state;
      }
    }
  };
  