import {
	GET_ALL_CREDIT_REQUEST,
	GET_ALL_CREDIT_SUCCESS,
	GET_ALL_CREDIT_ERROR,
	GET_ONE_CREDIT_HEADER_REQUEST,
	GET_ONE_CREDIT_HEADER_SUCCESS,
	GET_ONE_CREDIT_HEADER_ERROR,
	GET_ONE_CREDIT_HEADER_DETAIL_REQUEST,
	GET_ONE_CREDIT_HEADER_DETAIL_SUCCESS,
	GET_ONE_CREDIT_HEADER_DETAIL_ERROR,
	DELETE_CREDIT_HEADER_REQUEST,
	DELETE_CREDIT_HEADER_SUCCESS,
	DELETE_CREDIT_HEADER_ERROR,
	ADD_CREDIT_HEADER_REQUEST,
	ADD_CREDIT_HEADER_SUCCESS,
	ADD_CREDIT_HEADER_ERROR,
	UPDATE_CREDIT_HEADER_REQUEST,
	UPDATE_CREDIT_HEADER_SUCCESS,
	UPDATE_CREDIT_HEADER_ERROR,
} from "./types";

import { BASEURL } from "../../constant/config.js";
import moment from "moment";
const axios = require("axios");

export const addCreditHeader = (token, formData, dbData) => (dispatch) => {
	
	
	dispatch({ type: ADD_CREDIT_HEADER_REQUEST });
	let newData = {
		note_date: moment(formData.noteDate).format("MM-DD-YYYY"),
		return_header_id: dbData.return_header_id,
		invoice_header_id: dbData.invoice_header_id,
		receipt_header_id: dbData.receipt_header_id,
		location_id: dbData.location_id,
		ship_header_id: dbData.ship_header_id,
		order_header_id: dbData.order_header_id,
		gl_period_id: dbData.gl_period_id,
		customer_id: dbData.customer_id,
		company_id: dbData.company_id,
		gl_period_id: formData.postingPeriod,
		
		memo: formData.memo,
	};
	return axios({
		method: "POST",
		url: `${BASEURL}create/creditHeader`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: newData,
	})
		.then((response) => {
			return dispatch({
				type: ADD_CREDIT_HEADER_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: ADD_CREDIT_HEADER_ERROR,
				payload: error.response,
			});
		});
};

export const updateCreditHeader = (token, formData, dbData) => (dispatch) => {
	let newData = {
		note_date: moment(formData?.noteDate).format("MM-DD-YYYY"),
		gl_period_id: formData?.postingPeriod,
		memo: formData?.memo,
		re_stock: formData?.reStock,
		other_charges: formData?.otherCharges
	};
	dispatch({ type: UPDATE_CREDIT_HEADER_REQUEST });
	return axios({
		method: "PUT",
		url: `${BASEURL}update/creditHeader/${formData?.noteHeaderId}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: newData,
	})
		.then((response) => {

			return dispatch({
				type: UPDATE_CREDIT_HEADER_SUCCESS,
				payloadUpdate: response?.data?.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: UPDATE_CREDIT_HEADER_ERROR,
				payloadUpdate: error.response,
			});
		});
};

export const getAllCreditHeader = (token, data) => (dispatch) => {
	dispatch({ type: GET_ALL_CREDIT_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}getAll/creditHeader`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: {
			note_header_no: data.noteHeaderNo,
			
			location_name: data.locationName,
			gl_period_name: data.postingPeriod,
			customer_name: data.customerName,
			receipt_no: data.receiptNo,
			
			
			
			
			
			
			from_date: moment(data.from_date).format("MM-DD-YYYY"),
			to_date: moment(data.to_date).format("MM-DD-YYYY"),
		},
	})
		.then((response) => {
			return dispatch({
				type: GET_ALL_CREDIT_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_ALL_CREDIT_ERROR,
				payload: error.response,
			});
		});
};

export const getOneCreditHeader = (token, id) => (dispatch) => {
	dispatch({ type: GET_ONE_CREDIT_HEADER_REQUEST });
	return axios({
		method: "GET",
		url: `${BASEURL}getOne/creditHeaderDetail/${id}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
	})
		.then((response) => {
			return dispatch({
				type: GET_ONE_CREDIT_HEADER_SUCCESS,
				payload: response?.data[0],
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_ONE_CREDIT_HEADER_ERROR,
				payload: error.response,
			});
		});
};

export const getOneCreditHeaderDetail = (token, id) => (dispatch) => {
	dispatch({ type: GET_ONE_CREDIT_HEADER_DETAIL_REQUEST });
	return axios({
		method: "GET",
		url: `${BASEURL}getOne/creditHeader/${id}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
	})
		.then((response) => {
			return dispatch({
				type: GET_ONE_CREDIT_HEADER_DETAIL_SUCCESS,
				payload: response?.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_ONE_CREDIT_HEADER_DETAIL_ERROR,
				payload: error.response,
			});
		});
};

export const deleteCreditHeader = (token, id) => (dispatch) => {
	dispatch({ type: DELETE_CREDIT_HEADER_REQUEST });
	return axios({
		method: "DELETE",
		url: `${BASEURL}delete/creditHeader/${id}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
	})
		.then((response) => {
			return dispatch({
				type: DELETE_CREDIT_HEADER_SUCCESS,
				payload: response?.data.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: DELETE_CREDIT_HEADER_ERROR,
				payload: error.response,
			});
		});
};
