import {
  GET_UPC_SETUP_REQUEST,
  GET_UPC_SETUP_SUCCESS,
  GET_UPC_SETUP_ERROR,

} from "./types";

import { BASEURL } from "../../constant/config.js";
const axios = require("axios");

export const upcSetup = (token, data) => (dispatch) => {


  
  
  
  
  

  dispatch({ type: GET_UPC_SETUP_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}getAll/upcCode`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: {
      data
    },
  })
    .then((response) => {

      return dispatch({
        type: GET_UPC_SETUP_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_UPC_SETUP_ERROR,
        payload: error.response,
      });
    });
};


