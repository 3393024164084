import React, { useState, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAllSalesReturnHeader } from "../../redux/actions/salesReturn.action";
import { Button, Form, Input, DatePicker, Collapse, Row, Col, } from "antd";
import { CaretRightOutlined, PlusOutlined } from "@ant-design/icons";
import SkeletonLoad from "../../components/Skeleton/SkeletonLoad";
import PageHeader from "../../components/PageHeader/PageHeader";
import { saleReturnColumns } from "../../utils/cols";
import Tables from "../../components/Table/Tables";
import { useHistory } from "react-router-dom";
import "../../screens/Product/product.css";
import moment from "moment";
import "../Style/style.css";
import { dateChecker } from "../../components/CheckDate/checkDate";
import { getRights } from "../../redux/actions/userRights.action";
import { CSVLink } from "react-csv";
import { TabTitle } from "../../utils/GeneralFunction";

const { Panel } = Collapse;
export default function ReturnAuthList({ token }) {
	TabTitle("Return Authorization")
	const dispatch = useDispatch();
	const { salesReturnList } = useSelector((state) => state);
	const [form] = Form.useForm();
	const dateFormat = "MM-DD-YYYY";
	const history = useHistory();
	const [userRights, setUserRights] = useState({})

	const[menuId, setMenuId] = useState(56);

	useLayoutEffect(() => {
		dispatch(getRights(token, menuId)).then((res) => {
			if (res.type === "GET_USER_RIGHTS_SUCCESS") {
				setUserRights(res?.payload)
			}
		});
	}, [token])

	const worker = {
		from_date: moment().startOf('year'),
		to_date: moment().endOf('year'),
		raNo: null,
		returnNo: null,
		invoiceNo: null,
		orderNumber: null,
		customer: null,
		memo: null,
		gender: null,
		region: null,
		po: null,
		location: null,
		salesPerson: null,
		paymentTermId: null,
	};
	const onFinishFilter = async (e) => {
		await form.validateFields().then((values) => {

			let formDateCopy = document.getElementById("fromDate").value;
			let toDateCopy = document.getElementById("toDate").value;

			dateChecker(formDateCopy, toDateCopy, "returnAuthorizationList", token, values, dispatch);

		})
	};

	function downloadExcelFileData(data) {
		if (data) {
			return data.map((v) => {
				return {
				"Return#": v.return_header_no,
				"RA Approval#": v.ra_no,
				"Return Date": moment(v.return_date).format("MM-DD-YYYY"),
				"Invoice#": v.invoice_header_no,
				"Memo": v.memo,
				"Order#": v.order_header_no,
				"PO#": v.reference_no,
				"Customer": v.customer_name,
				"Sales Person": v.salesperson_name,
				"Gender": v.gender_name,
				"Region": v.region_name,
				"Location": v.location_name,
				"Term": v.payment_term_name
				};
			})
		}
	}


	return (
		<div className="styleContainer">
			<div className="styleBody h-100">
				<PageHeader title={"Sale Return List"} />
				<div className="bodyStyle">
					<Collapse
						style={{ width: "99%", margin: "10px" }}
						bordered={false}
						defaultActiveKey={['1']}
						expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
					>

						<Panel header="Filters" key="1" className="site-collapse-custom-panel" extra={
							<Button
								disabled={userRights.can_add === true ? false : true}
								onClick={() => history.push("/return-authorization")}
								className="iconBtn crudBTN"
								shape="circle"
								icon={<PlusOutlined />}
							/>}>

							<Form
								form={form}
								labelCol={{ span: 8 }}
								wrapperCol={{ span: 16 }}
								initialValues={worker}
								onFinish={onFinishFilter}
								autoComplete="off">
								<Row>
									<Col xs={24} xl={8}>
										<Form.Item
											name="raNo"
											label="RA Approval#"

										>
											<Input
												allowClear
												size="small" />
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item
											name="returnNo"
											label="Return#"
										>
											<Input
												allowClear
												size="small" />
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item
											name="invoiceNo"
											label="Invoice#"
											rules={[{ pattern: new RegExp(/^(?!0*[.,]0*$|[.,]0*$|0*$)\d+[,.]?\d{0,6}$/), message: "Must Be a Number." }]}
										>
											<Input
												allowClear
												size="small" />
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item
											name="orderNumber"
											label="Order#"
											rules={[{ pattern: new RegExp(/^(?!0*[.,]0*$|[.,]0*$|0*$)\d+[,.]?\d{0,6}$/), message: "Must Be a Number." }]}
										>
											<Input
												allowClear
												size="small" />
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item label="From Date" name="from_date"
										>
											<DatePicker
												size="small"
												id="fromDate"
												defaultValue={form.getFieldValue().fromDate} format={dateFormat} className="dateInput customInputs" />
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item label="To Date" name="to_date"
										>
											<DatePicker
												size="small"
												id="toDate"
												defaultPickerValue={form.getFieldValue().toDate} format={dateFormat} className="dateInput ustomInputs" />
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item
											name="customer"
											label="Customer Name"
										>
											<Input
												allowClear
												size="small" />
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item
											name="memo"
											label="Memo"
										>
											<Input
												allowClear
												size="small" />
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item label="Gender" name="gender" rules={[{ pattern: new RegExp(/^[a-zA-Z]*$/), message: "Must be Characters." }]}>
											<Input
												allowClear
												size="small"
											/>
										</Form.Item>
									</Col>

									<Col xs={24} xl={8}>
										<Form.Item
											name="region"
											label="Region"
										>
											<Input
												allowClear
												size="small" />
										</Form.Item>
									</Col>

									<Col xs={24} xl={8}>
										<Form.Item
											name="po"
											label="P.O #"
										>
											<Input
												allowClear
												size="small" />
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item
											name="location"
											label="Location"
										>
											<Input
												allowClear
												size="small" />
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item
											name="salesPerson"
											label="Sales Person"
										>
											<Input
												allowClear
												size="small" />
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item label="PaymentTerm" name="paymentTermId">
											<Input
												allowClear
												size="small"
											/>
										</Form.Item>
									</Col>
								</Row>
								<div style={{ display: "flex", justifyContent: "flex-end" }}>
									<Button type="primary" htmlType="submit" disabled={salesReturnList.loading}>
										Search
									</Button>
								</div>
							</Form>
						</Panel>
					</Collapse>
					{salesReturnList.loading ? (
						<div className="mainProductContainer">
							<SkeletonLoad />
						</div>
					) : (
						<div className="mainProductContainer">
							<div style={{ padding: 10 }}>
								{
									salesReturnList?.payload?.data && (
										<CSVLink
											filename={"Sale-Return.csv"}
											data={downloadExcelFileData(salesReturnList?.payload?.data) || []}
											onClick={() => {
											}}
										>
											Download Excel
										</CSVLink>
									)
								}
								<Tables
									loadHeight={40}
									loadWidth={40}
									type={"salesReturnList"}
									scroll={570}
									perPage={50}
									scrollX={"80rem"}
									col={saleReturnColumns}
									source={salesReturnList?.payload?.data ? salesReturnList?.payload?.data : []}
									load={salesReturnList.loading}
									paginate
								/>
							</div>
						</div>
					)}
				</div>
			</div>
		</div >

	);
}