import {
    GET_STATE_REQUEST,
    GET_STATE_SUCCESS,
    GET_STATE_ERROR,


} from "./types"
import axios from "axios";
import { BASEURL } from "../../constant/config.js"


export const loadState = (token, id) => dispatch => {
    dispatch({ type: GET_STATE_REQUEST });
    return axios({
        method: "GET",
        url: `${BASEURL}getOne/adminState/${id}`,
        headers: {
            Accept: "application/json",
            'Authorization': 'Bearer ' + token
        }
    })
        .then(response => {
            return dispatch({ type: GET_STATE_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            if (error.response.data.data === "No Record Found !") {
                return dispatch({
                    type: GET_STATE_ERROR,
                    payload: [],
                });
            }
            return dispatch({
                type: GET_STATE_ERROR,
                payload: error.response,
            });
        })
};

