import {
    GET_FISCALYEAR_REQUEST, GET_FISCALYEAR_ERROR, GET_FISCALYEAR_SUCCESS, ADD_FISCALYEAR_REQUEST, ADD_FISCALYEAR_ERROR, ADD_FISCALYEAR_SUCCESS,
    UPDATE_FISCALYEAR_REQUEST, UPDATE_FISCALYEAR_ERROR, UPDATE_FISCALYEAR_SUCCESS, DELETE_FISCALYEAR_REQUEST, DELETE_FISCALYEAR_ERROR, DELETE_FISCALYEAR_SUCCESS

} from "../actions/types"
export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case GET_FISCALYEAR_REQUEST:
            return {
                loading: true,
            };
        case GET_FISCALYEAR_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_FISCALYEAR_ERROR:
            return {
                ...state,
                loading: false
            };
        case ADD_FISCALYEAR_REQUEST:
            return {
                loading: true,
            };
        case ADD_FISCALYEAR_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case ADD_FISCALYEAR_ERROR:
            return {
                ...state,
                loading: false
            };
        case DELETE_FISCALYEAR_REQUEST:
            return {
                loading: true,
            };
        case DELETE_FISCALYEAR_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case DELETE_FISCALYEAR_ERROR:
            return {
                ...state,
                loading: false
            };
        case UPDATE_FISCALYEAR_REQUEST:
            return {
                loading: true,
            };
        case UPDATE_FISCALYEAR_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case UPDATE_FISCALYEAR_ERROR:
            return {
                ...state,
                loading: false
            };

        default:
            return state;
    }
}