import { GET_RETURN_RESAON_ERROR,GET_RETURN_RESAON_REQUEST,GET_RETURN_RESAON_SUCCESS,ADD_RETURN_REASON_REQUEST,ADD_RETURN_REASON_ERROR,ADD_RETURN_REASON_SUCCESS,
UPDATE_RETURN_REASON_ERROR,UPDATE_RETURN_REASON_REQUEST,UPDATE_RETURN_REASON_SUCCESS,DELETE_RETURN_REASON_ERROR,DELETE_RETURN_REASON_REQUEST,DELETE_RETURN_REASON_SUCCESS } from "../actions/types";


export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
      case GET_RETURN_RESAON_REQUEST:
        return {
          loading: true,
        };
      case GET_RETURN_RESAON_SUCCESS:
        return {
          ...state,
          loading: false,
          payload,
        };
      case GET_RETURN_RESAON_ERROR:
        return {
          ...state,
          loading: false,
          payload,
        };
        case ADD_RETURN_REASON_REQUEST:
            return {
                loading: true,
            };
        case ADD_RETURN_REASON_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case ADD_RETURN_REASON_ERROR:
            return {
                ...state,
                loading: false
            };
            case UPDATE_RETURN_REASON_REQUEST:
              return {
                  loading: true,
              };
          case UPDATE_RETURN_REASON_SUCCESS:
              return {
                  ...state,
                  loading: false,
                  payload: [payload],
              };
          case UPDATE_RETURN_REASON_ERROR:
              return {
                  ...state,
                  loading: false
              };
              case DELETE_RETURN_REASON_REQUEST:
                return {
                    loading: true,
                };
            case DELETE_RETURN_REASON_SUCCESS:
                return {
                    ...state,
                    loading: false,
                    payload: [payload],
                };
            case DELETE_RETURN_REASON_ERROR:
                return {
                    ...state,
                    loading: false
                };
            default:
                return state;
    }
  }
  