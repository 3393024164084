import { useCallback, useEffect, useRef, useState, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { DeleteOutlined } from "@ant-design/icons";
import Loader from "react-loader-spinner";
import moment from "moment";
import { DatePicker, Input, Tabs, Button, Form, Select, Tooltip, Popconfirm, Table, InputNumber } from "antd";
import { Row as Brow, Col as Bcol } from "reactstrap";
import { getOneInvoiceGlPeriod } from "../../../../redux/actions/invoiceHeader.action";
import { ReadCookie } from "../../../../utils/readCookie";
import EditableTable from "../../EditableTable";
import notification from "../../../../utils/notification";
import SubTitles from "../../../../components/SubTitle/SubTitle";
import numeral from "numeral";
import "../../../../screens/SaleOrder/salesOrder.css";
import { getAllCompany } from "../../../../redux/actions/companyAccess";
import { getExpenseAccount } from "../../../../redux/actions/expenseAccount.action";
import { loadCustomers } from "../../../../redux/actions/customerForm";
import Tables from "../../../../components/Table/Tables";
import { getSystemLog } from "../../../../redux/actions/systemLog.action";
import { getOneBillDirectPaymentItem, addBillDirectPaymentItem, updateBillDirectPaymentItem, deleteBillDirectPaymentItem } from "../../../../redux/actions/billDirectPaymentItem.action";
import { addBillDirectPayment, updateBillDirectPayment } from "../../../../redux/actions/billDirectPayments.action";
import { addBillDirectPaymentLines, addBillDirectPaymentLine, updateBillPaymentLine, getBillDirectPaymentLine, getOneBillDirectPaymentLine, deleteBillDirectPaymentLine } from "../../../../redux/actions/billDirectPaymentLine.action";
import { getVendors } from "../../../../redux/actions/vendor.action";
import { getGLImpact } from "../../../../redux/actions/gl_impact.action";
import { getCustomerPayment } from "../../../../redux/actions/AR_Account.action";
import { getTransationLock } from "../../../../redux/actions/transactionLock.action";
const { Option } = Select;
const { TabPane } = Tabs;

export default function BillDirectPaymentForm({ formData, val, token, formState, submit, DirectPaymentID, changeState, checkFlag, transactionLock, setDisable }) {

	const dispatch = useDispatch();
	const submitButton = useRef(null);
	const focusInput = useRef(null);
	const [form] = Form.useForm();
	const [headerId, setHeaderId] = useState(null);
	const [voucherID, setVoucherID] = useState("");
	const { journalVoucherFormData, vendor, invoiceGlPeriod, customerForms, companyAll, journalVoucherHeader, expenseAccount, systemLog, billDirectPaymentline, billDirectPaymentItem, customrPayment, glImpact } = useSelector((state) => state);
	const [companyId, setCompanyId] = useState(ReadCookie("defaultCompany"));
	const [oDate, setOrderDate] = useState(moment());
	const [customer, setCustomer] = useState([])
	const [lineId, setLineId] = useState("")
	const [lines, setLines] = useState([]);
	const [newLineCheck, setNewLineCheck] = useState(false)
	const [linesid, setLinesId] = useState(null)
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [visibleItems, setVisibleItems] = useState([]);
	const [orderItems, setOrderItems] = useState([]);
	const [customerPaymentNote, setcustomerPaymentNote] = useState([]);
	const [showGrid, setShowGrid] = useState(false);
	const [loading, setLoading] = useState(false);
	const [list1, setList] = useState([]);
	const [Lineidd, setLineIdd] = useState(null);
	const history = useHistory();
	const [id, setId] = useState(null);
	const [updateRecord, setUpdateRecord] = useState(false);
	const [deletedItems, setDeletedItems] = useState([]);
	const [deletedItemsInformation, setDeletedItemsInformation] = useState([]);
	const [deletedExpenseInformation, setDeletedExpenseInformation] = useState([]);
	const [currentDate, setCurrentDate] = useState(moment().format('MMM YYYY'));
	const [dueeDate, setDueDate] = useState(moment());
	useLayoutEffect(() => {
		dispatch(getOneInvoiceGlPeriod(token)).then((res) => {
			let result = res?.payload?.find((item) => {
				return item.period_name === currentDate
			});
			setCurrentDate(result?.period_id);
			form.setFieldsValue({ postingPeriod: result?.period_id });
		});
	}, [token, dispatch]);

	useEffect(() => {
		dispatch(getAllCompany(token));
		dispatch(getVendors(token));
		dispatch(getExpenseAccount(token));
		dispatch(getCustomerPayment(token));
	}, []);

	useEffect(() => {
		if (formState === "add") {
			form.setFieldsValue({
				referenceNo: null,
				paymentdate: oDate,
				payee: null,
				postingPeriod: currentDate,
				memo: null,
				dueDate: dueeDate,
				checkNo: null,
				amount: null,
				subsidiary: 2,
				account: null,
				RefNo: null,

			});
			setOrderItems([]);

		}
	}, [formState]);

	useEffect(() => {
		if (formState === "formView") {
			setLoading(true)
			dispatch(getOneBillDirectPaymentItem(token, DirectPaymentID)).then((res) => {
				if (res.type === "GET_ONE_BILL_DIRECT_PAYMENT_ITEM_SUCCESS") {
					setOrderItems(res.payload.data);
					setLoading(false)
				}
			});
			dispatch(getGLImpact(token, { "transaction_header_id": DirectPaymentID, "transaction_type": "BILLDIRECTPAY" }));
			dispatch(getSystemLog(token, { table_name: "pay_bill_direct_payment_header", record_id: DirectPaymentID }));
		}
	}, [formState]);
	useEffect(() => {
		if (formState === "formView") {
			setLoading(true)
			dispatch(getOneBillDirectPaymentLine(token, DirectPaymentID)).then((res) => {
				if (res.type === "GET_ONE_BILL_DIRECT_PAYMENT_LINE_SUCCESS") {
					setLines(res.payload.data);
					setLoading(false)
				}
			});
		}
	}, [formState]);

	useEffect(() => {
		if (formData) {
			setHeaderId(formData[0]?.direct_payment_header_id)
			form.setFieldsValue({
				referenceNo: formData[0].direct_payment_header_no,
				paymentdate: moment(formData[0]?.payment_date),
				account: formData[0].account_id,
				payee: formData[0].vendor_id,
				subsidiary: formData[0].company_id,
				postingPeriod: formData[0].gl_period_id,
				memo: formData[0].memo,
				checkNo: formData[0]?.check_no,
				amount: formData[0]?.payment_amount,
				dueDate: moment(formData?.due_date),
				RefNo: formData[0].reference_no
			});
		}
	}, [formData]);

	useEffect(() => {
		if (linesid) {
			form.setFieldsValue({
			});
		}
	}, [linesid]);


	useEffect(() => {
		let filtered = orderItems.filter((f) => f.isSelected === true);
		setVisibleItems(filtered);
	}, [orderItems]);

	useEffect(() => {
		let filtered = customerPaymentNote.filter((g) => g.isSelected === true);
		setVisibleItems(filtered);
	}, [customerPaymentNote]);

	useEffect(() => {
		if (val) {
			submitButton.current.click();
		}
	}, [val]);

	window.addEventListener('keydown', function (e) { if (e.keyIdentifier == 'U+000A' || e.keyIdentifier == 'Enter' || e.keyCode == 13) { if (e.target.nodeName == 'INPUT' && e.target.type == 'text') { e.preventDefault(); return false; } } }, true);

	const handleDeleteOrder = (data) => {
		dispatch(deleteBillDirectPaymentLine(token, Lineidd || data.direct_payment_expense_id)).then(
			(res) => {
				if (res.type === "DELETE_BILL_DIRECT_PAYMENT_LINE_SUCCESS") {
					dispatch(getOneBillDirectPaymentLine(token, DirectPaymentID)).then((res) => {
						if (res.type === "GET_ONE_BILL_DIRECT_PAYMENT_LINE_SUCCESS") {
							setVisibleItems(res.payload.data);
							setOrderItems(res.payload.data);
						}
					})
					const deleteArr = lines.filter((val) => val.id !== data.id);
					setLines(deleteArr);
				}
			}
		);














	};


	function handleChange(value, type) {
		if (type === "company") {
			companyId(value);
			form.setFieldsValue({ company: value });
		}
	}
	const glImpcatCols = [

		{
			title: "Account Name",
			dataIndex: "account_name",
		},
		{
			title: "Debit",
			dataIndex: "debit",
			render: (text, record, index) => {
				return (
					<>
						$ {numeral(record.debit).format("0,0.00")}
					</>
				)
			},
		},
		{
			title: "Credit",
			dataIndex: "credit",
			render: (text, record, index) => {
				return (
					<>
						$ {numeral(record.credit).format("0,0.00")}
					</>
				)
			},
		},

		{
			title: "created_date",
			dataIndex: "created_date",
		}
	]
	const renderColumns = () => {
		const keys = Object.keys(systemLog?.payload?.data[0])
		return keys.map((it, i) => {
			return {
				title: it,
				dataIndex: it,
				key: i.toString(),
			}
		})
	}
	const columns = [
		{
			title: "Item Code",
			dataIndex: "item_code",
		},
		{
			title: "Item Name",
			dataIndex: "item_name",
		},

		{
			title: "Quantity",
			dataIndex: "quantity",
			editable: true,
		},






		{
			title: "Operation",
			dataIndex: "operation",
			render: (_, record) => (

				<Tooltip title="Delete" color={"white"}>
					<Popconfirm title={`Are you sure to delete the item ${record.direct_payment_item_id}?`} onConfirm={() => handleDeleteOrders(record)}>
						<DeleteOutlined className="delete-Button" />
					</Popconfirm>
				</Tooltip>
			),
		},
	];
	const LinesColumns = [
		{
			title: "Account",
			dataIndex: "account_id",
			render: (text, record) => {
				return (
					<Bcol lg={3} xxl={2}>
						<div className="firstSectionRo">
							<Form.Item
								labelAlign="left"
								labelCol={{ span: 6 }}
							>
								<Select
									size="small"

									tabIndex={18}
									autoComplete={"nope"}
									style={{ width: 250 }}
									allowClear
									showSearch
									disabled={!(formState === "add" || formState === "edit")}
									optionFilterProp="children"
									className="filter-fields"

									onSelect={(e) => {
										if (formState === "add") {


											let arr = [...lines];
											let found = arr.find(i => i.id === record.id);
											if (found) {
												found.account_id = e
											}
											setLines(arr.map(f => {
												if (f.id === found.id) {
													return found
												} else {
													return f
												}
											}))
										} else if (formState === "edit") {
											let arr = [...lines];
											let found = arr.find(i => i.direct_payment_expense_id === record.direct_payment_expense_id);
											if (found) {
												found.account_id = e
											}
											setLines(arr.map(f => {
												if (f.direct_payment_expense_id === found.direct_payment_expense_id) {
													return found
												} else {
													return f
												}
											}))
										}
									}}
									loading={expenseAccount?.loading}

									onSearch={(value) => {
										optimizedFn(value)
									}}
									ref={focusInput}
									value={record?.account_id}
								>
									{expenseAccount?.payload?.data.map(
										(item) => {
											return (
												<Option
													key={item.account_id}
													value={item.account_id}
												>
													{`${item.account_name}`}
												</Option>
											);
										}
									)}
								</Select>
							</Form.Item>
						</div>
					</Bcol>
				)
			}
		},


















































































		{
			title: "Debit",
			dataIndex: "debit",
			render: (text, record) => {

				return (
					<Bcol lg={3} xxl={2}>
						<div className="firstSectionRo">
							<Form.Item labelAlign="left" labelCol={{ span: 9 }}
								value={record.debit}
								style={{ width: 250 }}
							>
								<InputNumber
									onChange={(e) => {
										if (formState === "add") {
											let arr = [...lines];
											let found = arr.find(i => i.id === record.id);


											if (found) {
												found.debit = e
											}
											setLinesId(found)
											setLines(arr.map(f => {
												if (f.id === found.id) {
													return found
												} else {
													return f
												}
											}))
										} else if (formState === "edit") {
											let arr = [...lines];
											let found = arr.find(i => i.direct_payment_expense_id === record.direct_payment_expense_id);


											if (found) {
												found.debit = e
											}
											setLinesId(found)
											setLines(arr.map(f => {
												if (f.direct_payment_expense_id === found.direct_payment_expense_id) {
													return found
												} else {
													return f
												}
											}))
										}
									}}
									value={record?.debit === "" ? null : record?.debit}
									tabIndex={18} size="small" className="customInputs" disabled={
										!(formState === "add" || formState === "edit")

									} />
							</Form.Item>
						</div>
					</Bcol>
				)
			}
		},
		{
			title: "Credit",
			dataIndex: "credit",
			render: (text, record) => {
				return (
					<Bcol lg={3} xxl={2}>
						<div className="firstSectionRo">
							<Form.Item labelAlign="left" labelCol={{ span: 9 }}
								value={record.credit}
								style={{ width: 250 }}
							>
								<InputNumber

									onChange={(e) => {

										if (formState === "add") {
											let arr = [...lines];

											let found = arr.find(i => i.id === record.id);
											if (found) {
												found.credit = e
											}
											setLinesId(found)
											setLines(arr.map(f => {
												if (f.id === found.id) {
													return found
												} else {
													return f
												}
											}))
										} else if (formState === "edit") {
											let arr = [...lines];

											let found = arr.find(i => i.direct_payment_expense_id === record.direct_payment_expense_id);
											if (found) {
												found.credit = e
											}
											setLinesId(found)
											setLines(arr.map(f => {
												if (f.direct_payment_expense_id === found.direct_payment_expense_id) {
													return found
												} else {
													return f
												}
											}))
										}
									}}
									value={record?.credit === "" ? null : record?.credit}
									tabIndex={18} size="small" className="customInputs" disabled={
										!(formState === "add" || formState === "edit")

									} />
							</Form.Item>
						</div>
					</Bcol>
				)
			}
		},
		{
			title: "Memo",
			dataIndex: "memo",
			render: (text, record) => {
				return (
					<Bcol lg={3} xxl={2}>
						<div className="firstSectionRo">
							<Form.Item labelAlign="left" labelCol={{ span: 9 }}
								value={record.memo}
							>
								<Input
									style={{ width: 250 }}
									onChange={(e) => {
										if (formState === "add") {
											let arr = [...lines];

											let found = arr.find(i => i.id === record.id);

											if (found) {
												found.memo = e.target.value
											}
											setLinesId(found)
											setLines(arr.map(f => {

												if (f.id === found.id) {
													return found
												} else {
													return f
												}
											}))
										} else if (formState === "edit") {
											let arr = [...lines];

											let found = arr.find(i => i.direct_payment_expense_id === record.direct_payment_expense_id);

											if (found) {
												found.memo = e.target.value
											}
											setLinesId(found)
											setLines(arr.map(f => {

												if (f.direct_payment_expense_id === found.direct_payment_expense_id) {
													return found
												} else {
													return f
												}
											}))
										}
									}
									}
									value={record?.memo === "" ? null : record?.memo}
									tabIndex={18} size="small" className="customInputs" disabled={!(formState === "add" || formState === "edit")} />
							</Form.Item>
						</div>
					</Bcol>
				)
			}
		},
		{
			title: "Customer",
			dataIndex: "customer_id",
			render: (text, record) => {

				const findCustomer = customerForms && customerForms?.payload?.data;


				return (
					<Bcol lg={3} xxl={2}>
						<div className="firstSectionRo">
							<Form.Item
								labelAlign="left"
								labelCol={{ span: 6 }}
							>
								<Select
									size="small"

									tabIndex={18}
									autoComplete={"nope"}
									style={{ width: 250 }}
									allowClear
									showSearch
									disabled={!(formState === "add" || formState === "edit")}
									optionFilterProp="children"
									className="filter-fields"

									onSelect={(e) => {
										if (formState === "add") {


											let arr = [...lines];
											let found = arr.find(i => i.id === record.id);
											if (found) {
												found.customer_id = e
											}
											setLines(arr.map(f => {
												if (f.id === found.id) {
													return found
												} else {
													return f
												}
											}))
										} else if (formState === "edit") {
											let arr = [...lines];
											let found = arr.find(i => i.direct_payment_expense_id === record.direct_payment_expense_id);
											if (found) {
												found.customer_id = e
											}
											setLines(arr.map(f => {
												if (f.direct_payment_expense_id === found.direct_payment_expense_id) {
													return found
												} else {
													return f
												}
											}))
										}
									}}
									loading={customerForms?.loading}

									onSearch={(value) => {
										optimizedFn(value)
									}}
									ref={focusInput}
									value={record.customer_name}
								>
									{customerForms?.payload?.data.map(
										(item) => {
											return (
												<Option
													key={item.customer_id}
													value={item.customer_id}
												>
													{`${item.customer_code} ${item.customer_name}`}
												</Option>
											);
										}
									)}
								</Select>
							</Form.Item>
						</div>
					</Bcol>
				)
			}
		},
		{
			title: "operation",
			render: (record, index) => (

				<Tooltip title="Delete" color={"white"}>
					{formState === "formView" ? "" :
						<Popconfirm title={`Are you sure to delete the item ${record.direct_payment_expense_id}?`} onConfirm={() => deleteItemRow(record)}>
							<DeleteOutlined className="delete-Button" />
						</Popconfirm>
					}
				</Tooltip >
			)
		}
	];

	const handleDeleteOrders = (record) => {
		const dataSource = [...orderItems];
		const deleteSource = [...deletedItemsInformation];
		deleteSource.push(record);

		setDeletedItemsInformation(deleteSource);



		if (dataSource.length > 0) {
			setOrderItems(dataSource.filter((item) => item.item_id !== record.item_id));
		} else {
			const dataSource = [...visibleItems];
			setOrderItems(dataSource.filter((item) => item.item_id !== record.item_id));
		}







	};





	function handleChange(value, type) {
		if (type === "company") {
			companyId(value);
			form.setFieldsValue({ company: value });
		}
	}
	const onFinish = async (values) => {
		submit(false);
		await form.validateFields().then((values) => {
			setLoading(true);
			setDisable(true);
			if (formState === "add") {
				if (lines.length > 0 || orderItems.length > 0) {
					dispatch(addBillDirectPayment(token, values, parseInt(companyId))).then((res) => {
						setId(res?.payload?.direct_payment_header_id)
						let headerID = res?.payload?.direct_payment_header_id
						setHeaderId(headerID)
						setVoucherID(headerID)
						if (res.type === "ADD_BILL_DIRECT_PAYMENT_HEADER_SUCCESS") {
							dispatch(
								addBillDirectPaymentItem(
									token,
									orderItems.map((item) => {
										delete item.style_name;
										delete item.cut_name;
										delete item.fabric_name;
										delete item.gender_category_name;
										delete item.gender_name;
										delete item.inseam;
										delete item.isSelected;
										delete item.quantity_available;
										delete item.quantity_backorder;
										delete item.quantity_committed;
										delete item.quantity_on_hand;
										delete item.rate;
										delete item.size;
										delete item.style_id;
										delete item.wash_id;
										delete item.wash_name;
										delete item.item_name;
										delete item.amount;
										delete item.quantity_packed;
										delete item.quantity_shipped;
										delete item.quantity_billed;
										delete item.direct_payment_item_id;
										return { ...item, direct_payment_header_id: res.payload.direct_payment_header_id };
									})
								)
							).then((res) => {
								if (res.type === "ADD_BILL_DIRECT_PAYMENT_ITEM_SUCCESS") {
									notification("success", "Bill Direct Payment", "Bill Direct Payment created successfully!");

									setLoading(false);
									setDisable(false);

									history.push({
										pathname: `/bill-direct-payments/edit/${headerID}`,
										search: `?_id=${headerID}`
									})
								}
							});

							setVoucherID(headerID)

							if (headerID) {
								let rest = lines.map(({ id, amount, ...rest }) => {
									return {
										...rest, direct_payment_header_id: headerID,

										amount: (amount === "") ? 0 : amount,
									}
								});
								dispatch(addBillDirectPaymentLines(token, rest)).then((res) => {
									if (res.type === "ADD_BILL_DIRECT_PAYMENT_LINE_SUCCESS") {
										dispatch(getOneBillDirectPaymentLine(token, headerID)).then((res) => {
											let arr = [...lines]
											let count = res?.payload?.data?.length;
											for (let i = 0; i < count; i++) {
												arr.shift()
											}
											let values = res?.payload?.data?.reverse()
											for (let i = 0; i < count; i++) {
												arr.unshift({ ...values[i], id: i + 1 })
											}
											setLines(arr)
										});

										notification("success", "Bill Direct Payment Created", res?.payload?.data?.data);
										setLineId(res?.payload?.direct_payment_expense_id)
										setLoading(false)
										setDisable(false)
										history.push({
											pathname: `/bill-direct-payments/edit/${headerID}`,
											search: `?_id=${headerID}`
										})
									}

								});
							}
						} else if (res.type === "ADD_BILL_DIRECT_PAYMENT_HEADER_ERROR") {
							Swal.fire({
								icon: "error",
								title: "Oops...",
								text: res?.payload?.data?.data,
							});
						}
					})
				}
				else {
					setLoading(false);
					setDisable(false);
					Swal.fire({
						icon: "error",
						title: "Oops...",
						text: "select atleast one BillDirect Payment line",
					});
					return
				}
			}
			else if (formState === "edit") {
				if (deletedItems.length > 0) {
					dispatch(deleteBillDirectPaymentLine(token, 123, deletedItems));
				}
				if (deletedItemsInformation.length > 0) {
					dispatch(deleteBillDirectPaymentItem(token, 123, deletedItemsInformation));
				}
				let rest = lines.map(({ id, ...rest }) => {
					return {
						...rest,

					}
				});
				const oldLines = rest.filter((value) => {
					delete value.full_name;
					delete value.account_no;
					if (value.direct_payment_expense_id) {
						return value
					}
				})

				dispatch(updateBillPaymentLine(token, oldLines, DirectPaymentID)).then((res) => {
					if (res.type === "UPDATE_BILL_DIRECT_PAYMENT_LINE_SUCCESS") {
						setLoading(false);
						setDisable(false);
						changeState("formView")
						notification("success", "Bill Direct Payment Updated Successfully");
					}
				});
				dispatch(updateBillDirectPayment(token, values, id ? id : DirectPaymentID)).then((res) => {
					if (res.type === "UPDATE_BILL_DIRECT_PAYMENT_HEADER_SUCCESS") {

						dispatch(updateBillDirectPaymentItem(token, orderItems, DirectPaymentID)).then((res) => {
							if (res.type === "UPDATE_BILL_DIRECT_PAYMENT_ITEM_SUCCESS") {
								dispatch(getOneBillDirectPaymentItem(token, DirectPaymentID)).then((res) => {
									if (res.type === "GET_ONE_BILL_DIRECT_PAYMENT_ITEM_SUCCESS") {
										setOrderItems(res.payload.data);
									}
								});
								setLoading(false);
								setDisable(false);

							}
						});
						Object.keys(values).forEach(key => {
							if (values[key] === undefined) {
								delete values[key];
							}
						});
					}
				})
				// const newLines = rest.filter((value) => {
				// 	if (!value.direct_payment_expense_id) {
				// 		setNewLineCheck(true);
				// 		return (
				// 			DirectPaymentID,
				// 			value)
				// 	}

				// })
				// let checkNewLine = rest.filter((item) => {
				// 	if (item.direct_payment_expense_id && item.account_id) {
				// 		return item
				// 	}
				// 	return item
				// })
				// if (checkNewLine.length > 0) {
				// 	dispatch(addBillDirectPaymentLine(token, newLines, DirectPaymentID, DirectPaymentID)).then((res) => {
				// 		setLineIdd(res?.payload?.direct_payment_expense_id)
				// 		delete rest.direct_payment_expense_id
				// 		if (res.type === "ADD_BILL_DIRECT_PAYMENT_LINE_SUCCESS") {
				// 			setLoading(false);

				// 			setVoucherID(DirectPaymentID)
				// 			setUpdateRecord(!updateRecord);
				// 		}
				// 	});

				// }
				setLoading(false);
				setDisable(false);
			}
		})
	};

	const handleTab = (key) => {
		if (key === "header") {
			dispatch(getSystemLog(token, { table_name: "pay_bill_direct_payment_header", record_id: DirectPaymentID }));
		} else if (key === "line") {
			dispatch(getSystemLog(token, { table_name: "pay_bill_direct_payment_lines", record_id: DirectPaymentID }));
		}
	};

	const showModal = () => {
		setIsModalVisible(true);
	};

	const onSelectCustomer = (e) => {
		setCustomer(e);
		dispatch(getBillDirectPaymentLine(token, e)).then((res) => {
			if (res.type === "GET_BILL_DIRECT_PAYMENT_LINE_SUCCESS") {
				setOrderItems(res.payload.data);
				setList(res.payload.data);

			}
		});
	};

	const addedItems = (value) => {
		const fillArr = [...orderItems];
		const onlyKey = [...value].map((sItem) => sItem.item_code);
		const indexofArr = fillArr.findIndex((f) => {
			return onlyKey.find((i) => i === f.item_code);
		});
		fillArr.splice(indexofArr, 1);
		if (orderItems.length > 0) {
			let uniqueOrderItems = [...orderItems, ...value];

			uniqueOrderItems = uniqueOrderItems.filter((v, i, a) => a.findIndex((t) => t.item_code === v.item_code) === i);
			setOrderItems(uniqueOrderItems);
		} else {

			let uniqueOrderItems = [...visibleItems, ...value];
			uniqueOrderItems = uniqueOrderItems.filter((v, i, a) => a.findIndex((t) => t.item_code === v.item_code) === i);
			setOrderItems(uniqueOrderItems);
		}
	};

	const onFinishFailed = () => {
		let errors = form.getFieldsError().filter((item) => item.errors.length > 0);
		Swal.fire({ icon: "error", title: "Oops...", text: errors[0]?.errors[0] });
		setDisable(false);
	};

	const handleCustomerChanges = (val) => {
		if (val === "" || val === 'All') return
		setCustomer(val);
		dispatch(loadCustomers(token, null, null, null, null, null, val, null, null, null, null, null, null, null, null, null, null)).then(
			(res) => {
				if (res?.type === "GET_ALL_CUSTOMERS_SUCCESS") {
					setCustomer(res?.payload?.data)
				}
			}
		);
	};

	function debounce(cb, delay = 1000) {
		let timeout
		return (...args) => {
			clearTimeout(timeout)
			timeout = setTimeout(() => {
				cb(...args)
			}, delay)
		}
	}

	const optimizedFn = useCallback(debounce(handleCustomerChanges), []);

	const current = new Date();
	const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;
	const result = journalVoucherHeader.payload && journalVoucherHeader.payload[0]?.refund_header_no;

	const addLines = () => {
		const data = [...lines];
		const iterateId = Math.floor(Math.random() * 100);
		const newItem = { id: iterateId, credit: 0, debit: 0, memo: '', customer_id: '', account_id: '' }
		if (lines.length <= 50) {
			data.push(newItem);
			setLines(data);

		} else {
			notification("error", "Only 50 Lines Item can be Edit");
		}
	}


	const deleteItemRow = (record) => {

		const data = [...lines];
		const deleteSource = [...deletedItems];
		deleteSource.push(record);
		setDeletedExpenseInformation(deleteSource);
		if (data.length > 0) {


			setLines(data.filter((val) => val.direct_payment_expense_id !== record.direct_payment_expense_id));
			setDeletedItems(deleteSource);
		} else {
			const data = [...visibleItems];
			setLines(data.filter((item) => item.item_id !== record.item_id));
		}

	}


	function checkTransactionDate(e) {
		let transaction_date = moment(e).format("YYYY-MM-DD")
		setLoading(true)
		dispatch(getTransationLock(token, transaction_date)).then((r) => {
			setLoading(false)
			if (r.payload.data.length > 0) {
				setOrderDate(moment())
				form.setFieldsValue({
					paymentdate: moment()
				})
				Swal.fire({ icon: "error", title: "Oops...", text: "Selected date is in locked period" })
			}
			else {
				setOrderDate(moment(e))
			}
		});
	}





	return (
		<>
			<EditableTable
				token={token}
				companyId={companyId}
				modalValue={isModalVisible}
				setIsModalVisible={setIsModalVisible}
				setOrderItems={addedItems}
				orderItems={orderItems}
				setShowGrid={setShowGrid}
			/>
			<Form requiredMark={false} autoComplete="off" onFinish={onFinish} form={form} onFinishFailed={onFinishFailed} name="basic">
				{journalVoucherFormData.loading ||
					(loading && (
						<div className="loaderDiv">
							<Loader type="Grid" color="#212121" height={40} width={40} />
						</div>
					))
				}
				<div className="saleOrderForm">
					<SubTitles name={"Primary Information"} />
					<div className="primaryInfo container-fluid pbpx-0 ptpx-10">
						<Brow>
							<Bcol md={12} xl={9} xxl={10} className="pbpx-10">
								<Brow>
									<Bcol lg={6} xxl={4}>
										<div className="firstSectionRo">
											<Form.Item
												name="referenceNo"
												label="Bill Direct Header #"
												labelAlign="left"
												labelCol={{ span: 6 }}
												defaultValue={form.getFieldValue().referenceNo}
											>
												<Input tabIndex={1} size="small" className="customInputs" disabled={true} />
											</Form.Item>
										</div>
									</Bcol>

									<Bcol lg={6} xxl={4}>
										<div className="firstSectionRo">
											<Form.Item label="Payment Date" name="paymentdate" labelAlign="left" labelCol={{ span: 6 }} rules={[{ required: true, message: "Bill Date is required" }]}>
												{/* <DatePicker
													size="small"
													disabled={!(formState === "add" || formState === "edit")}
													format="MM-DD-YYYY"
													
													allowClear
													className="dateInput customInputs"
												/> */}
												<DatePicker
													size="small"
													disabled={!(formState === "add" || formState === "edit")}
													format="MM-DD-YYYY"

													allowClear
													className="dateInput customInputs"
													defaultValue={
														formState === "edit"
															? form.getFieldValue().paymentdate
															: moment(oDate)
													}
													onChange={(e) => {
														setOrderDate(moment(e))
														let result = invoiceGlPeriod?.payload?.find((item) => {
															return item.period_name === moment(e).format("MMM YYYY")
														});
														setCurrentDate(result?.period_id);
														form.setFieldsValue({ postingPeriod: result?.period_id })
														checkTransactionDate(e);
													}}
												/>
											</Form.Item>
										</div>
									</Bcol>
									<Bcol lg={6} xxl={4}>
										<div className="firstSectionRo">
											<Form.Item label="Posting Period" name="postingPeriod" labelAlign="left" labelCol={{ span: 6 }} defaultValue={form.getFieldValue().postingPeriod}>
												<Select
													size="small"
													tabIndex={18}
													autoComplete={"nope"}
													allowClear
													showSearch
													optionFilterProp="children"
													className="filter-fields"
													disabled={!(formState === "add" || formState === "edit")}
													loading={invoiceGlPeriod.loading}
													defaultValue={form.getFieldValue().postingPeriod}
													onChange={(value) => {
														form.setFieldsValue({
															postingPeriod: value,
														});
													}}
												>
													{invoiceGlPeriod?.payload?.map((item) => {
														return (
															<Option key={item.period_id} value={item.period_id}>
																{item.period_name}
															</Option>
														);
													})}
												</Select>
											</Form.Item>
										</div>
									</Bcol>

									<Bcol lg={6} xxl={4}>
										<div className="firstSectionRo">
											<Form.Item name="payee" label="PAYEE" labelAlign="left" labelCol={{ span: 6 }} rules={[{ required: true, message: "Payee is required" }]}>
												<Select
													size="small"
													autoComplete={"nope"}
													tabIndex={18}
													filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
													showSearch
													allowClear
													loading={vendor.loading}
													disabled={!(formState === "add" || formState === "edit")}
													defaultValue={form.getFieldValue().payee}

													onChange={(value) => handleChange(value, "payee")}
													filterSort={(optionA, optionB) =>
														optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
													}

												>
													{vendor?.payload?.data.map((item) => {
														return (
															<Option key={item.vendor_id} value={item.vendor_id}>
																{item.vendor_name}
															</Option>
														);
													})}
												</Select>
											</Form.Item>
										</div>
									</Bcol>
									<Bcol lg={6} xxl={4}>
										<div className="firstSectionRo">
											<Form.Item label="ACCOUNT" name="account" labelAlign="left" labelCol={{ span: 6 }} defaultValue={form.getFieldValue().account}>
												<Select
													size="small"
													tabIndex={18}
													autoComplete={"nope"}
													allowClear
													showSearch
													optionFilterProp="children"
													className="filter-fields"
													disabled={!(formState === "add" || formState === "edit")}
													loading={customrPayment.loading}
													defaultValue={form.getFieldValue().account}

													onChange={(value) => {
														form.setFieldsValue({
															account: value,
														});
													}}
													filterSort={(optionA, optionB) =>
														optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
													}
												>

													{customrPayment?.payload?.data.map((item) => {
														console.log("item", item)
														return (
															<Option key={item.account_id} value={item.account_id}>
																{item.account_name}
															</Option>
														);
													})}
												</Select>
											</Form.Item>
										</div>
									</Bcol>
									<Bcol lg={6} xxl={4}>
										<div className="firstSectionRo">
											<Form.Item name="RefNo" label="Reference" labelAlign="left" labelCol={{ span: 6 }} rules={[{ required: true, message: "Reference is required" }]}>
												<Input tabIndex={18} size="small" className="customInputs" disabled={!(formState === "add" || formState === "edit")} />
											</Form.Item>
										</div>
									</Bcol>
									<Bcol lg={6} xxl={4}>
										<div className="firstSectionRo">
											<Form.Item name="memo" label="Memo" labelAlign="left" labelCol={{ span: 6 }} >
												<Input tabIndex={18} size="small" className="customInputs" disabled={!(formState === "add" || formState === "edit")} />
											</Form.Item>
										</div>
									</Bcol>
									<Bcol lg={6} xxl={4}>
										<div className="firstSectionRo">
											<Form.Item label="Due Date" name="dueDate" labelAlign="left" labelCol={{ span: 6 }} rules={[{ required: true, message: "Due Date is required" }]}>

												<DatePicker
													size="small"
													disabled={!(formState === "add" || formState === "edit")}
													format="MM-DD-YYYY"

													allowClear
													className="dateInput customInputs"
													defaultValue={
														formState === "edit"
															? form.getFieldValue().dueDate
															: moment(oDate)
													}
													onChange={(e) => setDueDate(moment(e))}
												/>
											</Form.Item>
										</div>
									</Bcol>
									<Bcol lg={6} xxl={4}>
										<div className="firstSectionRo">
											<Form.Item name="checkNo" label="Check No" labelAlign="left" labelCol={{ span: 6 }} >
												<Input tabIndex={1} size="small" className="customInputs" disabled={!(formState === "add" || formState === "edit")} />
											</Form.Item>
										</div>
									</Bcol>
									<Bcol lg={6} xxl={4}>
										<div className="firstSectionRo">
											<Form.Item name="amount" label="Amount" labelAlign="left" labelCol={{ span: 6 }} >
												<Input tabIndex={1} size="small" className="customInputs" disabled={!(formState === "add" || formState === "edit")} />
											</Form.Item>
										</div>
									</Bcol>
								</Brow>
							</Bcol>
						</Brow>
						<Button htmlType="submit" style={{ display: "none" }} ref={submitButton} onClick={onFinish} />
					</div>
				</div>
				<SubTitles name={"Classification"} />
				<div className="primaryInfo container-fluid pbpx-0 ptpx-10">
					<Brow>
						<Bcol md={12} xl={9} xxl={10} className="pbpx-10">
							<Brow>

								<Bcol lg={6} xxl={4}>
									<div className="firstSectionRo">
										{/* <Form.Item label="Posting Period" name="PostingPeriod" labelAlign="left" labelCol={{ span: 5 }} defaultValue={form.getFieldValue().postingPeriod}> */}
										<Form.Item label="Subsidiary" name="subsidiary" labelAlign="left" labelCol={{ span: 6 }} defaultValue={form.getFieldValue().subsidiary} rules={[{ required: true, message: "Subsidiary is required" }]}>
											<Select
												size="small"
												tabIndex={18}
												autoComplete={"nope"}
												allowClear
												disabled={!(formState === "add" || formState === "edit")}
												loading={invoiceGlPeriod.loading}
												defaultValue={form.getFieldValue().subsidiary}
												onChange={(value) => {
													form.setFieldsValue({
														subsidiary: value,
													});
												}}
												filterSort={(optionA, optionB) =>
													optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
												}
											>
												{companyAll?.payload?.data.map((item) => {
													return (
														<Option key={item.company_id} value={item.company_id}>
															{item.company_name}
														</Option>
													);
												})}
											</Select>
										</Form.Item>
									</div>
								</Bcol>

							</Brow>
						</Bcol>
						{/* <Bcol md={4} xl={3} xxl={4}>
								<Summary orderItems={orderItems.length > 0 ? orderItems : visibleItems} shippingCost={form.getFieldValue().shippingCost ? form.getFieldValue().shippingCost : 0} />
							</Bcol> */}
					</Brow>
					<Button htmlType="submit" style={{ display: "none" }} ref={submitButton} onClick={onFinish} />
					<Tabs type="card" size="small" defaultActiveKey="1" centered onChange={(key) => handleTab(key)}>
						<TabPane tab="Expenses" key="1">
							<Button type="primary" className="modalButton" disabled={!(formState === "edit" || formState === "add")} onClick={addLines}>
								Add Expense
							</Button>
							{/* <VoucherSubTitles account={"Account"} amount={"Amount"} memo={"Memo"} /> */}
							{(lines.length > 0) && (
								<Table
									type={"company"}
									loading={billDirectPaymentline.loading}
									scroll={({ x: 600 }, { y: 400 })}
									size="small"
									bordered
									style={{ paddingTop: 10 }}

									dataSource={lines}

									columns={LinesColumns}
									pagination={false}
								/>
							)}

						</TabPane>

						<TabPane tab="Items" key="2">
							<div className="pbpx-20">
								<Button type="primary" className="modalButton" disabled={!(formState === "edit" || formState === "add")} onClick={showModal}>
									Add Item
								</Button>
							</div>
							{(orderItems.length > 0 || visibleItems.length > 0) && (
								<Table
									type={"company"}
									loading={billDirectPaymentItem.loading}
									scroll={({ x: 600 }, { y: 400 })}
									size="small"
									bordered
									dataSource={orderItems}
									columns={columns}
									pagination={false}
								/>
							)}
						</TabPane>
						{(formState === "edit" || formState === "formView") &&
							<TabPane tab="GL Impact" key="gl_impact">
								<div className="tabs">
									<Tables
										type="company"
										scrollX={"50rem"}
										scroll={360}
										perPage={15}
										source={glImpact?.payload?.data}
										col={glImpcatCols}
										load={glImpact.loading}
										paginate
									/>
								</div>
							</TabPane>}
						{(formState === "edit" || formState === "formView") &&
							<TabPane tab="System Information" key="logs" >
								<Tabs
									type="card"
									size="small"
									defaultActiveKey="1"
									centered
									onChange={(key) => handleTab(key)}
								>
									<TabPane tab="Header" key="header">
										<div className="tabs">
											{systemLog?.payload?.data?.length > 0 && <Tables
												type="company"
												scrollX={"50rem"}
												scroll={360}
												perPage={15}
												source={systemLog?.payload?.data}
												dataSource={[]}
												col={renderColumns()}
												load={systemLog.loading}
												paginate
											/>}
										</div>
									</TabPane>
									<TabPane tab="Line" key="line">
										<div className="tabs">
											{systemLog?.payload?.data?.length > 0 && <Tables
												type="company"
												scrollX={"50rem"}
												scroll={360}
												perPage={15}
												source={systemLog?.payload?.data}
												dataSource={[]}
												col={renderColumns()}
												load={systemLog.loading}
												paginate
											/>}
										</div>
									</TabPane>
								</Tabs>
							</TabPane>}
					</Tabs>
				</div>
			</Form>
		</>
	);
}