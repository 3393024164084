import {
    GET_ADMIN_USER_REQUEST,GET_ADMIN_USER_ERROR,GET_ADMIN_USER_SUCCESS,
    ADD_ADMIN_USER_ERROR,ADD_ADMIN_USER_REQUEST,ADD_ADMIN_USER_SUCCESS,
    UPDATE_ADMIN_USER_ERROR,UPDATE_ADMIN_USER_REQUEST,UPDATE_ADMIN_USER_SUCCESS,
    DELETE_ADMIN_USER_ERROR,DELETE_ADMIN_USER_REQUEST,DELETE_ADMIN_USER_SUCCESS,
  } from "../actions/types";
  
  export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
      case GET_ADMIN_USER_REQUEST:
        return {
          loading: true,
        };
      case GET_ADMIN_USER_SUCCESS:
        return {
          ...state,
          loading: false,
          payload,
        };
      case GET_ADMIN_USER_ERROR:
        return {
          ...state,
          loading: false,
          payload,
        };
        case ADD_ADMIN_USER_REQUEST:
          return {
              loading: true,
          };
      case ADD_ADMIN_USER_SUCCESS:
          return {
              ...state,
              loading: false,
              payload: [payload],
          };
      case ADD_ADMIN_USER_ERROR:
          return {
              ...state,
              loading: false
          };
          case UPDATE_ADMIN_USER_REQUEST:
            return {
                loading: true,
            };
        case UPDATE_ADMIN_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case UPDATE_ADMIN_USER_ERROR:
            return {
                ...state,
                loading: false
            };
            case DELETE_ADMIN_USER_REQUEST:
              return {
                  loading: true,
              };
          case DELETE_ADMIN_USER_SUCCESS:
              return {
                  ...state,
                  loading: false,
                  payload: [payload],
              };
          case DELETE_ADMIN_USER_ERROR:
              return {
                  ...state,
                  loading: false
              };

      default:
        return state;
    }
  }
  