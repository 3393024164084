
import {
  GET_CREDIT_MEMO_PRINT_REQUEST,
  GET_CREDIT_MEMO_PRINT_SUCCESS,
  GET_CREDIT_MEMO_PRINT_ERROR,
} from "../actions/types";

export const creditMemoReportPrint = (state = [], action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_CREDIT_MEMO_PRINT_REQUEST:
      return {
        loading: true,
      };
    case GET_CREDIT_MEMO_PRINT_SUCCESS:
      return {
        ...state,
        loading: false,
        payload,
      };
    case GET_CREDIT_MEMO_PRINT_ERROR:
      return {
        ...state,
        loading: false,
        payload,
      };

    default: {
      return state;
    }
  }
};