import {

  GET_SALE_ADJUSTMENT_PRINT_REQUEST,
  GET_SALE_ADJUSTMENT_PRINT_SUCCESS,
  GET_SALE_ADJUSTMENT_PRINT_ERROR,
  GET_SALE_ADJUSTMENT_COLUMN_REQUEST,
  GET_SALE_ADJUSTMENT_COLUMN_SUCCESS,
  GET_SALE_ADJUSTMENT_COLUMN_ERROR,
  GET_SALE_ADJUSTMENT_COLUMN_VALUE_REQUEST,
  GET_SALE_ADJUSTMENT_COLUMN_VALUE_SUCCESS,
  GET_SALE_ADJUSTMENT_COLUMN_VALUE_ERROR,
  GET_SALE_ADJUSTMENT_ITEM_REQUEST,
  GET_SALE_ADJUSTMENT_ITEM_SUCCESS,
  GET_SALE_ADJUSTMENT_ITEM_ERROR,

} from "./types";
import axios from "axios";
import { BASEURL } from "../../constant/config.js";

///Post getSalesReport
export const getSalesAdjustmentReportItem = (token, values) => (dispatch) => {
  dispatch({ type: GET_SALE_ADJUSTMENT_ITEM_REQUEST });

  return axios({
    method: "POST",
    url: `${BASEURL}getAll/getSaleOrderAdjitem`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },

    data: values,
  })
    .then((response) => {


      return dispatch({
        type: GET_SALE_ADJUSTMENT_ITEM_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_SALE_ADJUSTMENT_ITEM_ERROR,
        payload: [],
      });
    });
};

export const getSalesAdjustmentReportPrint = (token, values) => (dispatch) => {
  dispatch({ type: GET_SALE_ADJUSTMENT_PRINT_REQUEST });

  return axios({
    method: "POST",
    url: `${BASEURL}getAll/getSaleOrderAdjPrint`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },

    data: values,
  })
    .then((response) => {

      return dispatch({
        type: GET_SALE_ADJUSTMENT_PRINT_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_SALE_ADJUSTMENT_PRINT_ERROR,
        payload: [],
      });
    });
};

export const getSalesAdjustmentReportColumnValue = (token, values) => (dispatch) => {
  dispatch({ type: GET_SALE_ADJUSTMENT_COLUMN_VALUE_REQUEST });

  return axios({
    method: "POST",
    url: `${BASEURL}getAll/getSaleOrderAdjColumnsValue`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },

    data: values,
  })
    .then((response) => {
      return dispatch({
        type: GET_SALE_ADJUSTMENT_COLUMN_VALUE_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_SALE_ADJUSTMENT_COLUMN_VALUE_ERROR,
        payload: [],
      });
    });
};


export const getSalesAdjustmentReportColumn = (token, values) => (dispatch) => {
  dispatch({ type: GET_SALE_ADJUSTMENT_COLUMN_REQUEST });

  return axios({
    method: "POST",
    url: `${BASEURL}getAll/getSaleOrderAdjColumns`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },

    data: values,
  })
    .then((response) => {
      return dispatch({
        type: GET_SALE_ADJUSTMENT_COLUMN_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_SALE_ADJUSTMENT_COLUMN_ERROR,
        payload: [],
      });
    });
};
