import {
    GET_ONE_CUSTOMER_REQUEST,
    GET_ONE_CUSTOMER_SUCCESS,
    GET_ONE_CUSTOMER_ERROR,
} from "./types";
import axios from "axios";
import { BASEURL } from "../../constant/config.js";

export const getOneCustomer = (token, id, clear) => dispatch => {
    dispatch({ type: GET_ONE_CUSTOMER_REQUEST });
    if (clear) {
        return dispatch({ type: GET_ONE_CUSTOMER_SUCCESS, payload: {} });
    }
    return axios({
        method: "GET",
        url: `${BASEURL}getOne/customer/${id}`,
        headers: {
            Accept: "application/json",
            'Authorization': 'Bearer ' + token
        }
    })
        .then(response => {
            return dispatch({ type: GET_ONE_CUSTOMER_SUCCESS, payload: response.data.data[0] });
        })
        .catch((error) => {
            return dispatch({
                type: GET_ONE_CUSTOMER_ERROR,
                payload: error.response,
            });
        })
}
