import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getGender } from "../../redux/actions/gender.action";
import {
  getGenderCategory,
  addGenderCategory,
  updateGenderCategory,
  deleteGenderCategory,
} from "../../redux/actions/genderCategory.action";
import {
  getDefaultCompany,
  getOneDefaultCompany,
} from "../../redux/actions/defaultCompany";
import {
  getCompanyAccess,
  updateCompanyAccess,
  deleteCompanyAccess,
  getAllCompany,
  createCompanyAccess,
} from "../../redux/actions/companyAccess";
import { getLog } from "../../redux/actions/log.action";
import { getRights } from "../../redux/actions/userRights.action";

import PageHeader from "../../components/PageHeader/PageHeader";
import { ReadCookie } from "../../utils/readCookie";
import CrudButtons from "../../components/CrudButtons/CrudButtons";
import Tables from "../../components/Table/Tables";
import {
  Button,
  Row,
  Col,
  Form,
  Input,
  Checkbox,
  Tabs,
  Tooltip,
  Popconfirm,
  Modal,
  Divider,
  Select,
} from "antd";
import {
  CloseCircleOutlined,
  DeleteOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import { logColumns, genderCategoryColumns } from "../../utils/cols";
import notification from "../../utils/notification";
import "../Style/style.css";
import "./genderCategory.css";
import { getSize } from "../../redux/actions/size.action";
import { TabTitle } from "../../utils/GeneralFunction";
const { TabPane } = Tabs;
const { Option } = Select;

export default function GenderCategory({ token }) {
  TabTitle("Gender Category")
  const menuId = ReadCookie("menuId");
  const dCompanyId = ReadCookie("defaultCompany");
  const genderCategoryNameInput = useRef(null);
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const [genderCategoryObj, setGenderCategoryObj] = useState(null);
  const dispatch = useDispatch();
  const {
    gender,
    getDemoDefaultCompany,
    genderCategory,
    companyAccess,
    companyAll,
    log,
    userRights,
    size,
  } = useSelector((state) => state);
  
  const [minimize, setMinimize] = useState(false);
  const [genderId, setGenderId] = useState(null);
  const [check, setCheck] = useState(false);
  const [genderCategoryName, setGenderCategoryName] = useState("");
  const [disable, setDisable] = useState(false);
  const [formState, setFormState] = useState("view");
  const [sizes, setSizes] = useState();
  const [emp, setEmp] = useState([]);



  useEffect(() => {
    dispatch(getSize(token, menuId));
  }, []);
  function handleChange(value, type) {
    if (type === "size") {
      
      setSizes(value);
    }
  }
  
  const handleEdit = (record, type) => {
    if (type === "active") {
      dispatch(updateCompanyAccess(token, record)).then((res) =>
        dispatch(
          getCompanyAccess(token, genderCategoryObj.gender_category_id, menuId)
        )
      );
    }
    if (type === "delete") {
      dispatch(deleteCompanyAccess(token, record)).then((res) =>
        dispatch(
          getCompanyAccess(token, genderCategoryObj.gender_category_id, menuId)
        )
      );
    }
  };

  const createAssignCompany = (record) => {
    dispatch(
      createCompanyAccess(
        token,
        record,
        genderCategoryObj.gender_category_id,
        menuId
      )
    ).then((res) =>
      dispatch(
        getCompanyAccess(token, genderCategoryObj.gender_category_id, menuId)
      )
    );
  };

  useEffect(() => {
    dispatch(getGender(token, menuId));
  }, [token]);

  const companyCol = [
    { title: "Company ID", dataIndex: "company_id", key: "company_id" },
    { title: "Company Name", dataIndex: "company_name", key: "company_name" },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <>
          <Tooltip title="Minimize" color="#fbfbfb"></Tooltip>
          {record.is_active ? (
            <Tooltip title="Activate" color="#fbfbfb">
              <CheckCircleOutlined
                className="color-green"
                onClick={() => handleEdit(record, "active")}
              />
            </Tooltip>
          ) : (
            <Tooltip title="Deactivate" color="#fbfbfb">
              <CloseCircleOutlined
                className="color-red"
                onClick={() => handleEdit(record, "active")}
              />
            </Tooltip>
          )}
          {dCompanyId === record.company_id.toString() ? (
            <Tooltip title="Default company can't be deleted" color="#fbfbfb">
              <DeleteOutlined
                className="delete-Button"
                disabled
                twoToneColor="red"
              />
            </Tooltip>
          ) : (
            <Tooltip title="delete" color="#fbfbfb">
              <Popconfirm
                placement="top"
                title={"Are you sure to delete this?"}
                onConfirm={() => handleEdit(record, "delete")}
                okText="Yes"
                cancelText="No"
              >
                <DeleteOutlined className="delete-Button" twoToneColor="red" />
              </Popconfirm>
            </Tooltip>
          )}
        </>
      ),
    },
  ];
  const companyAccessCol = [
    { title: "ID", dataIndex: "company_id", key: "company_id" },
    { title: "Company Name", dataIndex: "company_name", key: "company_name" },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <>
          {companyAccess?.payload?.data?.find(
            (m) => m.company_id === record?.company_id
          ) ? (
            <>
              <Tooltip title="Already assigned" color="#fbfbfb">
                <Button
                  className="modalActionBTN"
                  disabled
                  shape="circle"
                  icon={<CheckCircleOutlined className="color-red" />}
                />
              </Tooltip>
            </>
          ) : (
            <Tooltip title="Click to assign" color="#fbfbfb">
              <Button
                className="modalActionBTN"
                onClick={() => createAssignCompany(record)}
                shape="circle"
                icon={<CloseCircleOutlined className="color-green" />}
              />
            </Tooltip>
          )}
        </>
      ),
    },
  ];

  
  
  

  useEffect(() => {
    dispatch(getGenderCategory(token, menuId));
  }, [token]);

  useLayoutEffect(() => {
    dispatch(getRights(token, menuId)).then((res) => {
      if (res.type === "GET_USER_RIGHTS_SUCCESS") {
        if (!res?.payload.can_add) {
          setFormState("table");
        }
      }
    });
  }, []);

  let sizearray = [];
  if (sizes !== undefined) {
    sizearray = sizes.toString().split(',');
  }

  useEffect(() => {
    if (genderCategoryObj) {
      setFormState("view");
      
      
      
      form.setFieldsValue({
        id: genderCategoryObj.gender_category_id,
        genderCategory: genderCategoryObj.gender_category_name,
        size: genderCategoryObj.size_chart,
        gender: genderCategoryObj.gender_id,
        active: genderCategoryObj.is_active,
      });
      setGenderCategoryName(genderCategoryObj.gender_category_name);
      setSizes(genderCategoryObj.size_chart);
      setCheck(genderCategoryObj.is_active);
      setGenderId(genderCategoryObj.gender_id)
      dispatch(
        getCompanyAccess(token, genderCategoryObj.gender_category_id, menuId)
      );

    }

    
    
    
    
    
    
    
    
  }, [genderCategoryObj]);

  useEffect(() => {
    if (genderCategoryName === "") {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [genderCategoryName]);

  const showModal = () => {
    dispatch(getAllCompany(token));
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };
  const handleTab = (key) => {
    if (key === "logs") {
      
      dispatch(
        getLog(token, {
          inv_style: "inv_style",
          admin_company_entity_access: "admin_company_entity_access",
        })
      );
    }
  };

  const formSave = () => {
    if (formState === "add") {
      dispatch(
        addGenderCategory(
          token,
          { genderCategoryName, check, genderId, sizes },
          menuId
        )
      ).then((res) => {
        if (res.type === "ADD_GENDERCATEGORY_ERROR") {
          notification(
            "error",
            res?.payload?.request?.statusText,
            res?.payload?.data?.data
          );
        }
        if (res.type === "ADD_GENDERCATEGORY_SUCCESS") {
          let data = {
            company_id: dCompanyId,
          };
          dispatch(
            createCompanyAccess(
              token,
              data,
              res?.payload?.gender_category_id,
              menuId
            )
          ).then((res) =>
            dispatch(
              getCompanyAccess(
                token,
                genderCategoryObj.gender_category_id,
                menuId
              )
            )
          );
          notification(
            "success",
            "Gender Category Created",
            "Record created successfully!"
          );
          form.setFieldsValue({
            genderCategory: "",
            size: "",
          });
          setFormState("");
          
          setGenderCategoryName("");
          setSizes([]);
          setGenderCategoryObj(null);
          
        }
        dispatch(getGenderCategory(token, menuId));
      });
    }

    if (formState === "edit") {
      dispatch(
        updateGenderCategory(
          token,
          { genderCategoryName, check, genderId, sizes },
          genderCategoryObj.gender_category_id
        )
      ).then((res) => {
        if (res.type === "UPDATE_GENDERCATEGORY_ERROR") {
          notification("error", "Update", "Something went wrong");
        }
        if (res.type === "UPDATE_GENDERCATEGORY_SUCCESS") {
          notification(
            "success",
            "Gender Category Updated",
            "Record updated successfully"
          );
        }
        dispatch(getGenderCategory(token, menuId));
      });

      setSizes([]);
    }
  };

  const handleDelete = () => {
    if (genderCategoryObj) {
      dispatch(
        deleteGenderCategory(token, genderCategoryObj.gender_category_id)
      ).then((res) => {
        if (res.type === "DELETE_GENDERCATEGORY_SUCCESS") {
          notification(
            "success",
            "Gender Category Deleted",
            "Record deleted successfully!"
          );
        }
        if (res.type === "DELETE_GENDERCATEGORY_ERROR") {
          notification(
            "error",
            res?.payload?.request?.statusText,
            res?.payload?.data?.data
          );
        }
        form.setFieldsValue({
          genderCategory: "",
          size: "",
        });
        
        setGenderCategoryName("");
        setSizes([]);
        setGenderCategoryObj(null);
        setFormState("");
        dispatch(getGenderCategory(token, menuId));
      });
    }
  };

  useEffect(() => {
    if (formState === "add") {
      dispatch(getOneDefaultCompany(token, dCompanyId));
      genderCategoryNameInput.current.focus();
      form.setFieldsValue({
        genderCategory: "",
        size: "",
      });
      setCheck(true);
      setGenderCategoryName("");

      setGenderCategoryObj(null);
    }
    
  }, [formState]);

  const filterReset = () => {
    setFormState("view");
  };
  function handleGenderId(value, id) {
    setGenderId(id.key);
  }

  const items =
    form.getFieldValue().size === null ? [] : form.getFieldValue().size;
  
  return (
    <div className="styleContainer">
      <div className="styleBody h-100">
        <PageHeader
          title={"Gender Category"}
          minimize={setMinimize}
          check={minimize}
        />
        {!minimize && (
          <div className="bodyStyle">
            <div className="crudDiv">
              <CrudButtons
                rights={userRights?.payload}
                reset={filterReset}
                formType={formState}
                save={formSave}
                disable={disable}
                formState={setFormState}
                onDelete={handleDelete}
              />
            </div>

            <div className="styleContent">
              <Row className="tables-main-row-new">
                <Col xs={24} sm={24} md={10} lg={8} xl={6}>
                  <div className="styleTable">
                    <Tables
                      type="genderCategory"
                      genderCategoryObj={setGenderCategoryObj}
                      scrollX={"20rem"}
                      scroll={602}
                      perPage={25}
                      source={genderCategory?.payload?.data}
                      col={genderCategoryColumns}
                      paginate
                      load={genderCategory.loading}
                    />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={14} lg={16} xl={18}>
                  <div className="genderCategoryForm ">
                    <Col xs={24} sm={24} md={24} lg={18} xl={24}>
                      <Form form={form} name="basic" labelCol={{ span: 10 }} className="d-flex flex-row flex-wrap justify-content-start">
                        {genderCategoryObj !== null &&
                          !(formState === "add") && (
                            <Form.Item
                              className="width400"
                              label="ID"
                              name="id"
                              labelCol={{ span: 10 }}
                              wrapperCol={{ span: 14 }}
                              initialValue={form.getFieldValue().id}
                            >
                              <Input
                                size="small"
                                className="styleInput id-field-style"
                                readOnly
                              />
                            </Form.Item>
                          )}
                        <Form.Item
                          label="Gender Category"
                          name="genderCategory"
                          className="width400"
                          labelCol={{ span: 10 }}
                          wrapperCol={{ span: 14 }}
                          initialValue={form.getFieldValue().genderCategory}
                        >
                          <Input
                            size="small"
                            
                            disabled={
                              !(formState === "add" || formState === "edit")
                            }
                            ref={genderCategoryNameInput}
                            onChange={(e) =>
                              setGenderCategoryName(e.target.value)
                            }
                            className=""
                          />
                        </Form.Item>
                        <Form.Item
                          className="width400"
                          label="Gender"
                          name="gender"
                          labelCol={{ span: 10 }}
                          wrapperCol={{ span: 14 }}
                          initialValue={form.getFieldValue().gender}
                            defaultValue = {form.getFieldValue().gender}
                        >
                          <Select
                            size="small"

                            defaultValue={form.getFieldValue().gender}
                            showSearch
                            optionFilterProp="children"
                            className="filter-fields"
                            disabled={
                              !(formState === "add" || formState === "edit")
                            }
                            
                            onChange={handleGenderId}
                          >
                            {gender?.payload?.data.map((val, id) => (
                              <Option value={val.gender_id} key={val.gender_id}>
                                {" "}
                                {val.gender_name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                        {/* Select Size */}

                        <Form.Item
                          name="size"
                          label="Size"
                          className="width400"
                          labelCol={{ span: 10 }}
                          wrapperCol={{ span: 14 }}
                        >
                          <div>

                            <Select
                              value={sizearray == "null" ? [] : sizearray.filter((a) => a)}
                              size="small"
                              disabled={
                                !(formState === "add" || formState === "edit")
                              }
                              initialvalue={emp}
                              
                              loading={size.loading}
                              mode="multiple"
                              
                              placeholder={
                                formState === "add" ||
                                  formState === "edit" ||
                                  formState === "view"
                                  ? "Select Size"
                                  : null
                              }
                              onChange={(value) => handleChange(value, "size")}
                              optionLabelProp="Size"
                            >
                              {size?.payload?.data?.map((single) => {
                                return (
                                  <Option
                                    
                                    value={`${single.size_name}`}
                                    key={single.size_id}
                                    label={single.size_name}
                                  >
                                    {single.size_name}
                                  </Option>
                                );
                              })}
                            </Select>
                          </div>
                        </Form.Item>
                        <Form.Item
                          label="Active"
                          name="active"
                          className="width400"
                          labelCol={{ span: 10 }}
                          wrapperCol={{ span: 14 }}
                        >
                          <Checkbox
                            disabled={
                              !(formState === "add" || formState === "edit")
                            }
                            checked={check}
                            onChange={(e) => setCheck(e.target.checked)}
                          />
                        </Form.Item>

                      </Form>
                    </Col>

                    <div className="modalStyle">
                      {genderCategoryObj === null ? (
                        <Tooltip
                          title="Please select style first"
                          color="#fbfbfb"
                        >
                          <Button
                            disabled={genderCategoryObj === null ? true : false}
                            className=""
                            onClick={showModal}
                          >
                            Assign Companies
                          </Button>
                        </Tooltip>
                      ) : (
                        <Button
                          type="primary"
                          className="modalButton"
                          onClick={showModal}
                        >
                          Assign Companies
                        </Button>
                      )}
                    </div>
                    <Divider />
                    <div className="styleTab">
                      <Tabs
                        type="card"
                        tabBarGutter={2}
                        defaultActiveKey="1"
                        centered
                        onChange={(key) => handleTab(key)}
                      >
                        <TabPane tab="Companies" key="1">
                          <div className="tabs">
                            <Tables
                              type="company"
                              scrollX={"25rem"}
                              scroll={340}
                              perPage={12}
                              source={
                                formState === "add"
                                  ? formState === ""
                                    ? []
                                    : getDemoDefaultCompany?.payload?.data
                                  : companyAccess?.payload?.data
                              }
                              loading={
                                formState === "add"
                                  ? getDemoDefaultCompany.loading
                                  : companyAccess.loading
                              }
                              col={companyCol}
                            />
                          </div>
                        </TabPane>
                        <TabPane tab="Log Info" key="logs">
                          <div className="tabs">
                            <Tables
                              type="company"
                              scrollX={"50rem"}
                              scroll={360}
                              perPage={15}
                              source={log?.payload?.data}
                              col={logColumns}
                              load={log.loading}
                              paginate
                            />
                          </div>
                        </TabPane>
                      </Tabs>
                    </div>
                    <div className="modalForm">
                      <Modal
                        title="Assign Companies"
                        visible={visible}
                        onOk={handleOk}
                        onCancel={handleCancel}
                        footer={null}
                      >
                        <Tables
                          type="companyAccess"
                          scroll={334}
                          perPage={12}
                          source={companyAll?.payload?.data}
                          col={companyAccessCol}
                          load={companyAll.loading}
                        />
                      </Modal>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        )}
      </div>
      {minimize && (
        <div className="styleFooter">
          <Button
            className="minimize-button-style"
            onClick={() => setMinimize(false)}
          >
            Style
          </Button>
        </div>
      )}
    </div>
  );
}
