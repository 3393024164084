import {
    GET_CITY_ERROR,
    GET_CITY_REQUEST,
    GET_CITY_SUCCESS,
    ADD_CITY_ERROR, ADD_CITY_REQUEST, ADD_CITY_SUCCESS, UPDATE_CITY_ERROR, UPDATE_CITY_REQUEST, UPDATE_CITY_SUCCESS, DELETE_CITY_ERROR, DELETE_CITY_REQUEST, DELETE_CITY_SUCCESS
} from "../actions/types"

import axios from "axios";
import { BASEURL } from "../../constant/config.js"



export const getCity = (token, id) => dispatch => {
    dispatch({ type: GET_CITY_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/adminCity`,
        headers: {
            Accept: "application/json",
            'Authorization': 'Bearer ' + token
        }
    })
        .then(response => {

            return dispatch({ type: GET_CITY_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            if (error.response.data.data === "No Record Found !") {
                return dispatch({
                    type: GET_CITY_ERROR,
                    payload: [],
                });
            }
            return dispatch({
                type: GET_CITY_ERROR,
                payload: error.response,
            });
        })
};


export const addCity = (token, data, id) => (dispatch) => {
    dispatch({ type: ADD_CITY_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}create/adminCity`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data,
    })
        .then((response) => {

            return dispatch({ type: ADD_CITY_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: ADD_CITY_ERROR,
                payload: error.response,
            });
        });
};



export const updateCity = (token, data, id) => (dispatch) => {
    dispatch({ type: UPDATE_CITY_REQUEST });
    return axios({
        method: "PUT",
        url: `${BASEURL}update/adminCity/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data,
    })
        .then((response) => {

            return dispatch({ type: UPDATE_CITY_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: UPDATE_CITY_ERROR,
                payload: error.response,
            });
        });
};



export const deleteCity = (token, id) => (dispatch) => {
    dispatch({ type: DELETE_CITY_REQUEST });
    return axios({
        method: "DELETE",
        url: `${BASEURL}delete/adminCity/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
    })
        .then((response) => {
            return dispatch({ type: DELETE_CITY_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: DELETE_CITY_ERROR,
                payload: error.response,
            });
        });
};

