import { MinusCircleOutlined, CloseCircleOutlined } from "@ant-design/icons"
import { useHistory } from "react-router-dom";
import { Button, Divider, Tooltip } from "antd"
import React from 'react'
import "./pageHead.css"
export default function PageHeader({ title, minimize, check, type, userRights, changeState }) {
    let history = useHistory();
    const handleClose = () => {

    }
    return (
        <>
            <div className='pageHead' >
                <h3>{title}</h3>
                {userRights?.can_add === false ?
                    <div>
                        <Tooltip title="Minimize" color="#fbfbfb"  >
                            <Button onClick={() => minimize(true)} className="iconBtn" disabled={check} shape="circle" icon={<MinusCircleOutlined />} />
                        </Tooltip>
                        <Tooltip title="Close" color="#fbfbfb"  >
                            <Button onClick={() => history.push("/")} className="iconBtn" shape="circle" icon={<CloseCircleOutlined className="color-red" />} />
                        </Tooltip>
                    </div>
                    :
                    <>
                        {type !== "table" &&
                            <div>
                                <Tooltip title="Close" color="#fbfbfb"  >
                                    <Button onClick={() => history.push("/")} className="iconBtn" shape="circle" icon={<CloseCircleOutlined className="color-red" />} />
                                </Tooltip>
                            </div>
                        }
                    </>
                }
            </div>
            <Divider />
        </>
    )
}
