import {
    GET_RISE_ERROR, GET_RISE_REQUEST, GET_RISE_SUCCESS,
    ADD_RISE_ERROR, ADD_RISE_REQUEST, ADD_RISE_SUCCESS,
    DELETE_RISE_REQUEST, DELETE_RISE_SUCCESS, DELETE_RISE_ERROR,
    UPDATE_RISE_ERROR, UPDATE_RISE_REQUEST, UPDATE_RISE_SUCCESS

} from "../actions/types"
export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case GET_RISE_REQUEST:
            return {
                loading: true,
            };
        case GET_RISE_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_RISE_ERROR:
            return {
                ...state,
                loading: false
            };
        case ADD_RISE_REQUEST:
            return {
                loading: true,
            };
        case ADD_RISE_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case ADD_RISE_ERROR:
            return {
                ...state,
                loading: false
            };
        case DELETE_RISE_REQUEST:
            return {
                loading: true,
            };
        case DELETE_RISE_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case DELETE_RISE_ERROR:
            return {
                ...state,
                loading: false
            };
        case UPDATE_RISE_REQUEST:
            return {
                loading: true,
            };
        case UPDATE_RISE_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case UPDATE_RISE_ERROR:
            return {
                ...state,
                loading: false
            };

        default:
            return state;
    }
}