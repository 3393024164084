	import {
		GET_SALES_TARGET_FIGURE_REQUEST,
		GET_SALES_TARGET_FIGURE_ERROR,
		GET_SALES_TARGET_FIGURE_SUCCESS,
		ADD_SCM_FIGURE_REQUEST,
		ADD_SCM_FIGURE_ERROR,
		ADD_SCM_FIGURE_SUCCESS,
		UPDATE_SCM_FIGURE_REQUEST,
		UPDATE_SCM_FIGURE_ERROR,
		UPDATE_SCM_FIGURE_SUCCESS,
		DELETE_SCM_FIGURE_REQUEST,
		DELETE_SCM_FIGURE_ERROR,
		DELETE_SCM_FIGURE_SUCCESS,
	} from "../actions/types";
	
	 
	export default function (state = [], action) {
		const { type, payload } = action;
		switch (type) {
		  
			case GET_SALES_TARGET_FIGURE_REQUEST:
				return {
					loading: true,
				};
			case GET_SALES_TARGET_FIGURE_SUCCESS:
				return {
					...state,
					loading: false,
					payload,
				};
			case GET_SALES_TARGET_FIGURE_ERROR:
				return {
					...state,
					loading: false
				};
			case ADD_SCM_FIGURE_REQUEST:
				return{
					loading: true,
				}
			case ADD_SCM_FIGURE_SUCCESS:
				return{
				...state,
				loading: false,
				payload:[payload],
				}
			case ADD_SCM_FIGURE_ERROR:
				return{
					...state,
					loading: false
				}
			case UPDATE_SCM_FIGURE_REQUEST:
				return{
					loading: true,
				}
			case UPDATE_SCM_FIGURE_SUCCESS:
				return{
					...state,
					loading: false,
					payload,
				}
			case UPDATE_SCM_FIGURE_ERROR:
				return{
					...state,
					loading: false
				}
			case DELETE_SCM_FIGURE_REQUEST:
				return{
					loading: true,
				}
			case DELETE_SCM_FIGURE_SUCCESS:
				return{
					...state,
					loading: false,
					payload,
				}
			case DELETE_SCM_FIGURE_ERROR:
				return{
					...state,
					loading: false
				}
				default:
				return state;
			
		}
	  }
	  