import {
  GET_ONE_CUSTOMER_CATEGORIES_REQUEST,
  GET_ONE_CUSTOMER_CATEGORIES_SUCCESS,
  GET_ONE_CUSTOMER_CATEGORIES_ERROR
} from "../actions/types";

export default function (state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case GET_ONE_CUSTOMER_CATEGORIES_REQUEST:
      return {
        loading: true,
      };
    case GET_ONE_CUSTOMER_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        payload,
      };
    case GET_ONE_CUSTOMER_CATEGORIES_ERROR:
      return {
        ...state,
        loading: false,
        payload,
      };
    default:
      return state;
  }
}
