import { ADD_SALES_TARGET_REQUEST,ADD_SALES_TARGET_ERROR,ADD_SALES_TARGET_SUCCESS,
    DELETE_SALES_TARGET_ERROR,DELETE_SALES_TARGET_REQUEST,DELETE_SALES_TARGET_SUCCESS, GET_SALES_TARGET_ERROR,GET_SALES_TARGET_REQUEST,GET_SALES_TARGET_SUCCESS,
UPDATE_SALES_TARGET_REQUEST,UPDATE_SALES_TARGET_SUCCESS,UPDATE_SALES_TARGET_ERROR} from "../actions/types";



    

 
export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case GET_SALES_TARGET_REQUEST:
            return{
                loading:true
            }
            case GET_SALES_TARGET_SUCCESS:
                return{
                    ...state,
                    loading:false,
                    payload
                }
                case GET_SALES_TARGET_ERROR:
                    return{
                        ...state,
                        loading:false
                    }

      
        case ADD_SALES_TARGET_REQUEST:
            return {
                loading: true,
            };
        case ADD_SALES_TARGET_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case ADD_SALES_TARGET_ERROR:
            return {
                ...state,
                loading: false
            };
            case UPDATE_SALES_TARGET_REQUEST:
      return {
        loading: true,
      };
    case UPDATE_SALES_TARGET_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: [payload],
      };
    case UPDATE_SALES_TARGET_ERROR:
      return {
        ...state,
        loading: false
      };
              case DELETE_SALES_TARGET_REQUEST:
                return {
                    loading: true,
                };
            case DELETE_SALES_TARGET_SUCCESS:
                return {
                    ...state,
                    loading: false,
                    payload: [payload],
                };
            case DELETE_SALES_TARGET_ERROR:
                return {
                    ...state,
                    loading: false
                };
            default:
                return state;
    }
  }
  