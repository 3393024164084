import {
  GET_ALL_CANADA_ORDER_REQUEST,
  GET_ALL_CANADA_ORDER_SUCCESS,
  GET_ALL_CANADA_ORDER_ERROR,
} from "../actions/types";

export const canadaOrder = (state = [], action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_ALL_CANADA_ORDER_REQUEST:
      return {
        loading: true,
      };
    case GET_ALL_CANADA_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        payload,
      };
    case GET_ALL_CANADA_ORDER_ERROR:
      return {
        ...state,
        loading: false,
        payload,
      };

    default: {
      return state;
    }
  }
};
