import {
    GET_SHIPSTATUS_REQUEST, GET_SHIPSTATUS_ERROR, GET_SHIPSTATUS_SUCCESS, ADD_SHIPSTATUS_SUCCESS, ADD_SHIPSTATUS_REQUEST, ADD_SHIPSTATUS_ERROR,
    UPDATE_SHIPSTATUS_REQUEST, UPDATE_SHIPSTATUS_ERROR, UPDATE_SHIPSTATUS_SUCCESS, DELETE_SHIPSTATUS_REQUEST, DELETE_SHIPSTATUS_ERROR, DELETE_SHIPSTATUS_SUCCESS
} from "./types"
import { BASEURL } from "../../constant/config.js";

const axios = require("axios");

export const getShipStatus = (token, menu_id) => (dispatch) => {

    dispatch({ type: GET_SHIPSTATUS_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/shipStatus`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { menu_id }
    }).then((response) => {
        return dispatch({ type: GET_SHIPSTATUS_SUCCESS, payload: response.data })
    }).catch((error) => {
        return dispatch({ type: GET_SHIPSTATUS_ERROR, payload: error.response })
    })
}

export const addShipStatus = (token, data, menu_id) => (dispatch) => {
    dispatch({ type: ADD_SHIPSTATUS_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}create/shipStatus`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { ship_status_name: data.shipStatusName, is_active: data.check, menu_id },
    })
        .then((response) => {
            return dispatch({ type: ADD_SHIPSTATUS_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: ADD_SHIPSTATUS_ERROR,
                payload: error.response,
            });
        });
};
export const updateShipStatus = (token, data, id) => (dispatch) => {
    dispatch({ type: UPDATE_SHIPSTATUS_REQUEST });
    return axios({
        method: "PUT",
        url: `${BASEURL}update/shipStatus/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { ship_status_name: data.shipStatusName, is_active: data.check },
    })
        .then((response) => {

            return dispatch({ type: UPDATE_SHIPSTATUS_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: UPDATE_SHIPSTATUS_ERROR,
                payload: error.response,
            });
        });
};

export const deleteShipStatus = (token, id) => (dispatch) => {
    dispatch({ type: DELETE_SHIPSTATUS_REQUEST });
    return axios({
        method: "DELETE",
        url: `${BASEURL}delete/shipStatus/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
    })
        .then((response) => {

            return dispatch({ type: DELETE_SHIPSTATUS_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: DELETE_SHIPSTATUS_ERROR,
                payload: error.response,
            });
        });
};
