import { useState, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import SkeletonLoad from "../../components/Skeleton/SkeletonLoad";
import PageHeader from "../../components/PageHeader/PageHeader";
import Tables from "../../components/Table/Tables";
import { billCreditColumns } from "../../utils/cols";
import moment from "moment";
import { dateChecker } from "../../components/CheckDate/checkDate";
import "../../screens/Product/product.css";
import "../Style/style.css";
import { useHistory } from "react-router-dom";
import { CaretRightOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, DatePicker, Collapse, Row, Col } from "antd";
import { getRights } from "../../redux/actions/userRights.action";
import { CSVLink } from "react-csv";
export default function BillCreditList({ token }) {
	const dispatch = useDispatch();
	const { billCreditList } = useSelector((state) => state);
	const [form] = Form.useForm();
	const [show, setShow] = useState(false);
	const [filteredInfo, setFilteredInfo] = useState(null);
	const [sortedInfo, setSortedInfo] = useState(null);
	const { Panel } = Collapse;
	const history = useHistory();
	const [userRights, setUserRights] = useState({})
	const dateFormat = "MM-DD-YYYY";
	const worker = {
		transactionNo: null,
		account: null,
		postingPeriod: null,
		vendor: null,
		fromAmount: null,
		toAmount: null,
		currency: null,
		referenceNo: null,
		from_date: moment().startOf('year'),
		to_date: moment().endOf('year'),
	};
	function downloadExcelFileData(data) {
		if (data) {
		  return data.map((v) => {
			return {
			  "Transaction No": v.bill_credit_header_no,
			  "Vendor Name": v.vendor_name,
			  "Posting Period": v.gl_period_name,
			  "Account Name": v.account_name,
			  "Currency": v.currency_name,
			  
			};
		  });
		}
	  }
	useLayoutEffect(() => {
		dispatch(getRights(token, 59)).then((res) => {
			if (res.type === "GET_USER_RIGHTS_SUCCESS") {
				setUserRights(res?.payload)
			}
		});
	}, [token])

	const onFinishFilter = async (e) => {
		await form.validateFields().then((values) => dateChecker(values.from_date, values.to_date, "billCreditList", token, values, dispatch))
	};
	const handleFilter = (pagination, filters, sorter, extra) => {
		setFilteredInfo(filters);
		setSortedInfo(sorter);
		setShow(true);
	};
	return (
        <div className="styleContainer">
			<div className="styleBody h-100">
				<PageHeader title={"Bill Credit List"} />
				<div className="bodyStyle">
					<Collapse style={{ width: "99%", margin: "10px" }} bordered={false} defaultActiveKey={["1"]} expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}>
						<Panel
							header="Filters"
							key="1"
							className="site-collapse-custom-panel"
							extra={
								<Button
									disabled={userRights.can_add === true ? false : true}
									onClick={() => {
										history.push("/bill-credit/add?mode=add");
									}}
									className="iconBtn crudBTN"
									shape="circle"
									icon={<PlusOutlined />}
								/>
							}
						>
							<Form
								labelCol={{
									span: 8,
								}}
								wrapperCol={{
									span: 16,
								}}
								initialValues={worker}
								form={form}
								onFinish={onFinishFilter}
								
								autoComplete="off"
							>
								<Row>
									<Col xs={24} xl={8}>
										<Form.Item name="transactionNo" label="Bill Header No" rules={[{ pattern: new RegExp(/^(?!0*[.,]0*$|[.,]0*$|0*$)\d+[,.]?\d{0,6}$/), message: "Must Be a Number." }]}>
											<Input allowClear size="small" />
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item label="From Date" name="from_date"
										>
											<DatePicker
												size="small"
												id="fromDate"
												format={dateFormat}
												className="dateInput customInputs"
											/>
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item label="To Date" name="to_date"
										>
											<DatePicker size="small" id="toDate" format={dateFormat} className="dateInput ustomInputs" />
										</Form.Item>
									</Col>
								</Row>
								<Row>
									<Col xs={24} xl={8}>
										<Form.Item name="postingPeriod" label="Posting Period" >
											<Input allowClear size="small" />
										</Form.Item>
									</Col>

									<Col xs={24} xl={8}>
										<Form.Item name="currency" label="Currency" >
											<Input allowClear size="small" />
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item name="account" label="Account" >
											<Input allowClear size="small" />
										</Form.Item>
									</Col>
								</Row>

								<Row>
									<Col xs={24} xl={8}>
										<Form.Item name="vendor" label="Vendor" >
											<Input allowClear size="small" />
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item name="fromAmount" label="From Amount">
											<Input allowClear size="small" />
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item name="toAmount" label="From Amount">
											<Input allowClear size="small" />
										</Form.Item>
									</Col>


								</Row>
								<Row>
									<Col xs={24} xl={8}>
										<Form.Item name="referenceNo" label="Reference No ">
											<Input allowClear size="small" />
										</Form.Item>
									</Col>

								</Row>
								<div style={{ display: "flex", justifyContent: "flex-end" }}>
									<Button type="primary" htmlType="submit" disabled={billCreditList.loading}>
										Search
									</Button>
								</div>
							</Form>
						</Panel>
					</Collapse>


{billCreditList?.loading ? (
            <div className="mainProductContainer">
              <SkeletonLoad />
            </div>
          ) : (
            <div className="mainProductContainer">
              <div style={{ padding: 10 }}>
                {billCreditList?.payload?.data?.length > 0 && (
                  <CSVLink
                    filename={"BillCredit.csv"}
                    data={
                      downloadExcelFileData(billCreditList?.payload?.data) || []
                    }
                    onClick={() => {}}
                  >
                    Download Excel
                  </CSVLink>
                )}
                <Tables
                  loadWidth={40}
                  type={"customer"}
                  scroll={450}
                  perPage={100}
                  scrollX={"80rem"}
                  col={billCreditColumns}
                  source={billCreditList?.payload?.data}
                  load={billCreditList?.loading}
                  paginate
                />
              </div>
            </div>
          )}
				</div>
			</div>
		</div>
    );
}
