import {
    GET_FABRICCLASS2_REQUEST,
    GET_FABRICCLASS2_SUCCESS,
    GET_FABRICCLASS2_ERROR,
    ADD_FABRICCLASS2_REQUEST,
    ADD_FABRICCLASS2_ERROR,
    ADD_FABRICCLASS2_SUCCESS,
    UPDATE_FABRICCLASS2_REQUEST,
    UPDATE_FABRICCLASS2_SUCCESS,
    UPDATE_FABRICCLASS2_ERROR,
    DELETE_FABRICCLASS2_ERROR,
    DELETE_FABRICCLASS2_REQUEST,
    DELETE_FABRICCLASS2_SUCCESS,
  } from "../actions/types";
  export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
      case GET_FABRICCLASS2_REQUEST:
        return {
          loading: true,
        };
      case GET_FABRICCLASS2_SUCCESS:
        return {
          ...state,
          loading: false,
          payload,
        };
      case GET_FABRICCLASS2_ERROR:
        return {
          ...state,
          loading: false,
        };
      case ADD_FABRICCLASS2_REQUEST:
        return {
          loading: true,
        };
      case ADD_FABRICCLASS2_SUCCESS:
        return {
          ...state,
          loading: false,
          payload: [payload],
        };
      case ADD_FABRICCLASS2_ERROR:
        return {
          ...state,
          loading: false,
        };
      case DELETE_FABRICCLASS2_REQUEST:
        return {
          loading: true,
        };
      case DELETE_FABRICCLASS2_SUCCESS:
        return {
          ...state,
          loading: false,
          payload: [payload],
        };
      case DELETE_FABRICCLASS2_ERROR:
        return {
          ...state,
          loading: false,
        };
      case UPDATE_FABRICCLASS2_REQUEST:
        return {
          loading: true,
        };
      case UPDATE_FABRICCLASS2_SUCCESS:
        return {
          ...state,
          loading: false,
          payload: [payload],
        };
      case UPDATE_FABRICCLASS2_ERROR:
        return {
          ...state,
          loading: false,
        };
  
      default:
        return state;
    }
  }
  