import {
    GET_GENDER_ERROR, GET_GENDER_SUCCESS, GET_GENDER_REQUEST,
    ADD_GENDER_REQUEST, ADD_GENDER_SUCCESS, ADD_GENDER_ERROR,
    UPDATE_GENDER_REQUEST, UPDATE_GENDER_SUCCESS, UPDATE_GENDER_ERROR,
    DELETE_GENDER_SUCCESS, DELETE_GENDER_REQUEST, DELETE_GENDER_ERROR,
} from "../actions/types"

export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case GET_GENDER_REQUEST:
            return {
                loading: true,
            };
        case GET_GENDER_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_GENDER_ERROR:
            return {
                ...state,
                loading: false
            };
        case ADD_GENDER_REQUEST:
            return {
                loading: true,
            };
        case ADD_GENDER_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case ADD_GENDER_ERROR:
            return {
                ...state,
                loading: false
            };
        case DELETE_GENDER_REQUEST:
            return {
                loading: true,
            };
        case DELETE_GENDER_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case DELETE_GENDER_ERROR:
            return {
                ...state,
                loading: false
            };
        case UPDATE_GENDER_REQUEST:
            return {
                loading: true,
            };
        case UPDATE_GENDER_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case UPDATE_GENDER_ERROR:
            return {
                ...state,
                loading: false
            };

        default:
            return state;
    }
}
