import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getAllCreditHeader, deleteCreditHeader, getOneCreditHeaderDetail } from "../../redux/actions/credit.action";
import CrudButtons from "./components/CrudButtons";
import ItemCreditForm from "./components/Form/ItemCreditForm";
import PageHeader from "../../components/PageHeader/PageHeader";
import { Button } from "antd";
import { ReadCookie } from "../../utils/readCookie";
import notification from "../../utils/notification";
import { useHistory } from "react-router-dom";

import "../../screens/Product/product.css";
import "../Style/style.css";

export default function CreditNote({ token }) {
	const { saleReturnId } = useParams();
	const dispatch = useDispatch();
	const dCompanyId = ReadCookie("defaultCompany");
	const { salesReturnFormDataReducer, log, creditNote } = useSelector((state) => state);
	const [minimize, setMinimize] = useState(false);
	const [formState, setFormState] = useState("view");
	const [saleOrderID, setSaleOrderID] = useState(saleReturnId);
	const [flag, setFlag] = useState(false);
	const [companyId, setCompanyId] = useState(ReadCookie("defaultCompany"));
	const [disable, setDisable] = useState(false);
	const [submit, setSubmit] = useState(false);
	const [loading, setLoading] = useState(false);
	const queryParams = new URLSearchParams(window.location.search);
	const mode = queryParams.get("mode");
	const edit = queryParams.get("edit");
	const return_id= queryParams.get("return_id");
	const receipt_header_id= queryParams.get("receipt_header_id");
	const history = useHistory();
	const startDateList = "01-1-2022";
	const endDateList = "06-01-2022";

	const filterReset = () => {
		setFormState("view");
	};

	const handleDelete = () => {
		setLoading(true);
		dispatch(deleteCreditHeader(token, saleReturnId)).then((res) => {
			if (res.type === "DELETE_CREDIT_HEADER_SUCCESS") {
				setLoading(false);
				dispatch(getAllCreditHeader(token, startDateList, endDateList));
				notification("success", "Credit Note Header deleted", res.payload);
				setFlag(true);
				history.push("/credit-note/list");
			}
		});
	};

	const formSave = () => {
		setSubmit(true);
	};

	useEffect(() => {
		if (mode === "add") {
			setFormState("add");
		}
	}, []);

	useEffect(() => {
		if (mode === "edit") {
			dispatch(getOneCreditHeaderDetail(token, receipt_header_id));
		} else {
			dispatch(getOneCreditHeaderDetail(token, saleReturnId));
		}
	}, []);

	return (
		<>
			<div className="styleContainer">
				<div className="styleBody" style={minimize ? {} : { height: "100%" }}>
					<PageHeader title={"Item Credit Note"} minimize={setMinimize} check={minimize} />
					<div className="bodyStyle">
						<div className="crudDiv">
							<CrudButtons
								name={"invoice"}
								reset={filterReset}
								formType={formState}
								save={formSave}
								disable={disable}
								formState={setFormState}
								onDelete={handleDelete}
								saleOrderID={saleOrderID}
								edit={edit}
								formData={creditNote?.payload}
							/>
							<div className="mainProductContainer">
								<ItemCreditForm
								receipt_header_id={receipt_header_id}
									loading={loading}
									dCompany={dCompanyId}
									defaultCompany={companyId}
									companyId={setCompanyId}
									submit={setSubmit}
									val={submit}
									formData={creditNote?.payload}
									formState={formState}
									data={salesReturnFormDataReducer}
									changeState={setFormState}
									token={token}
									checkFlag={flag}
									setFlag={setFlag}
									logs={log}
									edit={edit}
									saleReturnId={saleReturnId}
								/>
							</div>
						</div>
					</div>
				</div>
				{minimize && (
					<div className="styleFooter">
						{" "}
						<Button className="fw-600 b-radius-5" onClick={() => setMinimize(false)}>
							{" "}
							Sales Order{" "}
						</Button>{" "}
					</div>
				)}
			</div>
		</>
	);
}
