import {  
    GETALL_JOURNAL_VOUCHER_HEADER_REQUEST,
    GETALL_JOURNAL_VOUCHER_HEADER_SUCCESS,
    GETALL_JOURNAL_VOUCHER_HEADER_ERROR  , } from "../actions/types";
export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case GETALL_JOURNAL_VOUCHER_HEADER_REQUEST:
            return {
                loading: true,
            };
        case GETALL_JOURNAL_VOUCHER_HEADER_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GETALL_JOURNAL_VOUCHER_HEADER_ERROR:
            return {
                ...state,
                loading: false,
            };

        default:
            return state;
    }
}
