import React, { useEffect, useState, useRef, useCallback } from "react";
import "../Distributor/Distributor.css"
import logo from "../../assets/images/logo.png";
import btnPrint from "../../assets/images/button_print.gif";
import xlsx from "../../assets/images/ico_xls_sm.gif";
import { Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getProductType } from "../../redux/actions/productType.action";
import { ReadCookie } from "../../utils/readCookie";
import { Spin, Select, Form, Input } from "antd";
import { getStyle } from "../../redux/actions/style";
import { getGenderCategory } from "../../redux/actions/genderCategory.action";
import { getNewSeason } from "../../redux/actions/newSeason.action";
import { getProductStatus } from "../../redux/actions/productStatus.actions";
import { getAdminLocation } from "../../redux/actions/adminLocation.action";
import { useDownloadExcel } from "react-export-table-to-excel";
import Loader from "react-loader-spinner";
import { getRegion } from "../../redux/actions/region.action";
import { getGender } from "../../redux/actions/gender.action";
import { getSeason } from "../../redux/actions/season.action";
import { loadSalePerson } from "../../redux/actions/salePerson/salePersonForm.action";
import { getDefaultCompany } from "../../redux/actions/defaultCompany";
import { getWashName } from "../../redux/actions/wash.action";
import { getWashType } from "../../redux/actions/washType.action";
import { getProductClass } from "../../redux/actions/productClass.action";
import { getProductReport } from "../../redux/actions/productReport.action";

const ProductReport = ({ token }) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const { Option } = Select;
    const { style, gender, productReport, wash, washType, genderCategory, productClass, getNewSeasonReducer, defaultCompany, season, } = useSelector((state) => state);
    const menuId = ReadCookie("menuId");
    const userName = ReadCookie("name");
    const [loading, setLoading] = useState(false);
    const tableRef = useRef(null);

    const { onDownload } = useDownloadExcel({
        currentTableRef: tableRef.current,
        filename: "Users table",
        sheet: "Users",
    });

    useEffect(() => {
        dispatch(getStyle(token, menuId));
        dispatch(getProductClass(token, menuId));
        dispatch(loadSalePerson(token));
        dispatch(getDefaultCompany(token));
        dispatch(getProductType(token, menuId));
        dispatch(getWashName(token, menuId));
        dispatch(getWashType(token, menuId));
        dispatch(getGenderCategory(token, menuId));
        dispatch(getNewSeason(token, menuId));
        dispatch(getProductStatus(token, menuId));
        dispatch(getAdminLocation(token, menuId));
        dispatch(getRegion(token, menuId));
        dispatch(getGender(token, menuId));
        dispatch(getSeason(token, menuId));
    }, [dispatch, menuId, token]);

    let result =
        productReport &&
        productReport?.payload?.data?.reduce(function (r, a) {
            r[a.item_id] = r[a.item_id] || [];
            r[a.item_id].push(a);
            return r;
        }, Object.create(null));

    const wiseStockReport = result && Object.values(result);

    const onFinish = async (e) => {
        await form.validateFields().then((values) => {
            setLoading(true);
            const params = {
                item_id: null,
                item_code: values.sku ? values.sku : null,
                company_id: 2,
                season_id: values.season === "All" ? null : values.season,
                new_season_id: values.newSeason === "All" ? null : values.newSeason,
                style_id: values.style === "All" ? null : values.style,
                wash_id: values.wash === "All" ? null : values.wash,
                gender_id: values.gender === "All" ? null : values.gender,
                wash_type_id: values.washType === "All" ? null : values.washType,
                gender_category_id: values.genderCategory === "All" ? null : values.genderCategory,
                product_class_id: values.productType === "All" ? null : values.productType,
                product_type_id: null,
                fit_category_id: null,
                cut_id: null,
                parent_item_id: null
            };
            dispatch(getProductReport(token, params)).then((res) => {
                setLoading(false);
            });
            
        });
    };


    return (
        <div className="distributor header_class">
            <div className="row justify-content-between pb-2">
                <div className="col-3 d-flex align-items-start box-space">
                    <div className="logobrand">
                        <img id="ImgLogo" alt="logo" src={logo} />
                    </div>
                </div>
                <div className="col-3 box-space right-content-header text-right pb-0">
                    <span style={{ fontWeight: "bolder" }}>
                        Product Report
                        <br />
                        <div className=" d-flex justify-content-between mlpx-35">
                            <div className=" d-flex printing_class">
                                <div className="print_icon printing_class">
                                    <i
                                        id="Image1"
                                        title="Print"
                                        alt="Print"
                                        src={btnPrint}
                                        className="fa-solid fa-print"
                                    ></i>
                                </div>
                                <div
                                    className="print_icon printing_class"
                                    name="ImgExcel"
                                    alt="Export to excel"
                                    id="ImgExcel"
                                    title="Export to excel"
                                    src={xlsx}
                                >

                                    {wiseStockReport ? (
                                        <input
                                            type="image"
                                            name="ImgExcel"
                                            alt="Export to excel"
                                            id="ImgExcel"
                                            title="Export to excel"
                                            src={xlsx}
                                            onClick={onDownload}
                                            style={{
                                                borderWidth: "0px",
                                                height: "20px",
                                                cursor: "pointer",
                                            }}
                                        />
                                    ) : (
                                        <input
                                            type="image"
                                            name="ImgExcel"
                                            alt="Export to excel"
                                            id="ImgExcel"
                                            title="Export to excel"
                                            src={xlsx}
                                            style={{ borderWidth: "0px", height: "20px" }}
                                        />
                                    )}
                                </div>
                            </div>
                            <div>
                                Rpt # 489
                                <br />
                                Login as:{" "}
                                <span id="lblSalesPerson" style={{ fontWeight: "bold" }}>
                                    {userName}
                                </span>
                            </div>
                        </div>


                    </span>
                </div>
            </div>
            {/* <div className="row">
                <div className="col text-sm-right pb-1">
                    Login as:{" "}
                    <span id="lblSalesPerson" style={{ fontWeight: "bold" }}>
                        {userName}
                    </span>
                </div>
            </div> */}
            <div>
                <Form form={form} name="control-hooks" onFinish={onFinish}>
                    <div className="main_form row">
                        <div className=" col-12">
                            <hr className="mt-0 mb-2" />
                            <div className="row">
                                <div className="col-12 col-md-3">
                                    <div>
                                        <label>Style</label>
                                        <div>
                                            <Form.Item initialValue={"All"} name="style">
                                                <Select
                                                    className={"select_Inputs"}
                                                    showSearch
                                                    allowClear
                                                    optionFilterProp="children"
                                                    size="large"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                >
                                                    {style &&
                                                        style?.payload?.data.map((res) => {
                                                            return (
                                                                <Option key={res.style_id} value={res.style_id}>
                                                                    {res.style_name}
                                                                </Option>
                                                            );
                                                        })}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                {/* Second */}
                                <div className="col-12 col-md-3">
                                    <div>
                                        <label>Gender</label>
                                        <div>
                                            <Form.Item initialValue={"All"} name="gender">
                                                <Select
                                                    className={"select_Inputs"}
                                                    showSearch
                                                    placeholder="Select Gender"
                                                    optionFilterProp="children"
                                                    size="large"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                >
                                                    {gender &&
                                                        gender?.payload?.data.map(
                                                            (res) => {
                                                                return (
                                                                    <Option
                                                                        key={res.gender_id}
                                                                        value={res.gender_id}
                                                                    >
                                                                        {res.gender_name}
                                                                    </Option>
                                                                );
                                                            }
                                                        )}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                {/* Third */}
                                <div className="col-12 col-md-3">
                                    <div>
                                        <label>Gender Category</label>
                                        <div>
                                            <Form.Item initialValue={"All"} name="genderCategory">
                                                <Select
                                                    loading={genderCategory?.loading}
                                                    className={"select_Inputs"}
                                                    showSearch
                                                    placeholder="Select Gender"
                                                    optionFilterProp="children"
                                                    size="large"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                >
                                                    {genderCategory &&
                                                        genderCategory?.payload?.data.map(
                                                            (res) => {
                                                                return (
                                                                    <Option
                                                                        key={res.gender_category_id}
                                                                        value={res.gender_category_id}
                                                                    >
                                                                        {res.gender_category_name}
                                                                    </Option>
                                                                );
                                                            }
                                                        )}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                {/* Fourth */}
                                <div className="col-12 col-md-3">
                                    <div>
                                        <label>SKU</label>
                                        <div className="full_date">
                                            <Form.Item name="sku" className="sku_input">
                                                <Input
                                                    size="large"
                                                    placeholder="Enter Sku"
                                                    className={"select_Inputs"}
                                                />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-md-3">
                                    <div>
                                        <label>Company</label>
                                        <div>
                                            <Form.Item name="company" initialValue={2}>
                                                <Select
                                                    className={"select_Inputs"}
                                                    showSearch
                                                    defaultValue={defaultCompany?.payload?.company_id}
                                                    
                                                    optionFilterProp="children"
                                                    size="large"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                >

                                                    <Option
                                                        key={defaultCompany?.payload?.company_id}
                                                        value={defaultCompany?.payload?.company_id}
                                                    >
                                                        {defaultCompany?.payload?.company_name}
                                                    </Option>
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div>
                                        <label> New Season</label>
                                        <div>
                                            <Form.Item initialValue={"All"} name="newSeason">
                                                <Select
                                                    loading={getNewSeasonReducer?.loading}
                                                    className={"select_Inputs"}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    size="large"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                >
                                                    {getNewSeasonReducer &&
                                                        getNewSeasonReducer?.payload?.data?.map(
                                                            (res) => {
                                                                return (
                                                                    <Option
                                                                        key={res.season_id}
                                                                        value={res.season_id}
                                                                    >
                                                                        {res.season_name}
                                                                    </Option>
                                                                );
                                                            }
                                                        )}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-3">
                                    <div>
                                        <label>Wash</label>
                                        <div>
                                            <Form.Item initialValue={"All"} name="wash">
                                                <Select
                                                    loading={wash?.loading}
                                                    className={"select_Inputs"}
                                                    showSearch
                                                    allowClear
                                                    optionFilterProp="children"
                                                    size="large"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                >
                                                    {wash &&
                                                        wash?.payload?.data.map(
                                                            (res) => {
                                                                return (
                                                                    <Option
                                                                        key={res.wash_id}
                                                                        value={res.wash_id}
                                                                    >
                                                                        {res.wash_name}
                                                                    </Option>
                                                                );
                                                            }
                                                        )}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div>
                                        <label>Product Class</label>
                                        <div>
                                            <Form.Item initialValue={"All"} name="productType">
                                                <Select
                                                    loading={productClass?.loading}
                                                    className={"select_Inputs"}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    size="large"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                >
                                                    {productClass &&
                                                        productClass?.payload?.data?.map(
                                                            (res) => {
                                                                return (
                                                                    <Option
                                                                        key={res.product_class_id}
                                                                        value={res.product_class_id}
                                                                    >
                                                                        {res.product_class_name}
                                                                    </Option>
                                                                );
                                                            }
                                                        )}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                {/* Second */}
                                <div className="col-12 col-md-3">
                                    <div>
                                        <label>Wash Type</label>
                                        <div>
                                            <Form.Item initialValue={"All"} name="washType">
                                                <Select
                                                    className={"select_Inputs"}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    size="large"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                >
                                                    {washType &&
                                                        washType?.payload?.data.map((res) => {
                                                            return (
                                                                <Option key={res.wash_type_id} value={res.wash_type_id}>
                                                                    {res.wash_type_name}
                                                                </Option>
                                                            );
                                                        })}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div>
                                        <label className=" no_visibility">none</label>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            className="submit-btn-btm m-0 pt-1.5 pb-1.5"
                                        >
                                            Generate
                                        </Button>
                                    </div>
                                </div>
                                {/* Third */}
                                <div className="col-12 col-md-3">
                                    <div>
                                        <label>Season</label>
                                        <div className="full_date">
                                            <Form.Item initialValue={"All"} name="season">
                                                <Select
                                                    className={"select_Inputs"}
                                                    showSearch
                                                    placeholder="Select Season"
                                                    optionFilterProp="children"
                                                    size="large"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                >
                                                    {season &&
                                                        season?.payload?.data?.map(
                                                            (res) => {
                                                                return (
                                                                    <Option
                                                                        key={res.season_id}
                                                                        value={res.season_id}
                                                                    >
                                                                        {res.season_name}
                                                                    </Option>
                                                                );
                                                            }
                                                        )}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </Form>
            </div >
            <div ref={tableRef}>
                {wiseStockReport ? (
                    wiseStockReport.length > 0 ? (
                        wiseStockReport.map((data, index) => {
                            const sizes = data;
                            let qty1 = null;
                            let qty2 = null;
                            let qty3 = null;
                            let qty4 = null;
                            let qty5 = null;
                            let qty6 = null;
                            let qty7 = null;
                            let qty8 = null;
                            let qty9 = null;
                            let qty10 = null;
                            let qty11 = null;
                            let qty12 = null;
                            let qty13 = null;
                            let qty14 = null;
                            let qty15 = null;
                            let qty16 = null;

                            for (let i = 0; i < data.length; i++) {
                                qty1 += parseInt(data[i].qty_1);
                                qty2 += parseInt(data[i].qty_2);
                                qty3 += parseInt(data[i].qty_3);
                                qty4 += parseInt(data[i].qty_4);
                                qty5 += parseInt(data[i].qty_5);
                                qty6 += parseInt(data[i].qty_6);
                                qty7 += parseInt(data[i].qty_7);
                                qty8 += parseInt(data[i].qty_8);
                                qty9 += parseInt(data[i].qty_9);
                                qty10 += parseInt(data[i].qty_10);
                                qty11 += parseInt(data[i].qty_11);
                                qty12 += parseInt(data[i].qty_12);
                                qty13 += parseInt(data[i].qty_13);
                                qty14 += parseInt(data[i].qty_14);
                                qty15 += parseInt(data[i].qty_15);
                                qty16 += parseInt(data[i].qty_16);
                            }

                            let sumQ = 0;

                            for (let i = 0; i < data.length; i++) {
                                sumQ += parseInt(data[i].sum_quantity);
                            }
                            return (
                                <div key={index}>
                                    <div className="table_data-Heading">
                                        {data[0].item_code}
                                    </div>
                                    <table className="table_distributor-data">
                                        <tbody>
                                            <tr className=" font_weight">
                                                <td className="font-weig">#</td>
                                                <td className="font-weig">Description</td>
                                                <td className="font-weig">Style</td>
                                                <td className="font-weig">Cut</td>
                                                <td className="font-weig">Fabric Composition</td>
                                                <td className="font-weig">Gender</td>
                                                <td className="font-weig">Gender Category</td>
                                                <td className="font-weig">Season Name</td>
                                                <td className="font-weig">Season Year</td>
                                                <td className="font-weig">New Season </td>
                                                <td className="font-weig">New Season Year</td>
                                                <td className="font-weig">Wash Name</td>
                                                <td className="font-weig">Wash Type</td>
                                                <td className="font-weig">Inseam</td>
                                                <td className="font-weig">Product Class</td>
                                                <td className="font-weig">Product Type</td>
                                                <td className="font-weig">Rate</td>


                                            </tr>
                                            {
                                                data.map((values, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{index++}</td>
                                                            <td>{values.description}</td>
                                                            <td>{values.cut_name}</td>
                                                            <td>{values.style_name}</td>
                                                            <td>{values.fabric_composition}</td>
                                                            <td>{values.gender_name}</td>
                                                            <td>{values.gender_category_name}</td>
                                                            <td>{values.season_name}</td>
                                                            <td>{values.season_year}</td>
                                                            <td>{values.newseason}</td>
                                                            <td>{values.new_season_year}</td>
                                                            <td>{values.wash_name}</td>
                                                            <td>{values.wash_type_name}</td>
                                                            <td>{values.inseam === null ? "N/A" : values.inseam}</td>
                                                            <td>{values.product_class_name}</td>
                                                            <td>{values.product_type_name}</td>
                                                            <td>${values.rate}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            );
                        })
                    ) : (
                        <div className="no-Data">
                            <h1>No Data</h1>
                        </div>
                    )
                ) : (
                    loading && (
                        <div className="loader" style={{ marginTop: "20px" }}>
                            <Spin size="medium" />
                        </div>
                    )
                )}
            </div>
        </div >
    );
};

export default ProductReport;
