import {
    GET_INVOICE_REPORT_ERROR, GET_INVOICE_REPORT_REQUEST, GET_INVOICE_REPORT_SUCCESS,
    GET_INVOICE_PRINT_REQUEST, GET_INVOICE_PRINT_SUCCESS, GET_INVOICE_PRINT_ERROR,
    GET_INVOICE_COLUMNS_REQUEST, GET_INVOICE_COLUMNS_SUCCESS, GET_INVOICE_COLUMNS_ERROR,
    GET_INVOICE_COLUMNSVALUES_REQUEST, GET_INVOICE_COLUMNSVALUES_SUCCESS, GET_INVOICE_COLUMNSVALUES_ERROR

} from "./types";
import { BASEURL } from "../../constant/config.js";
import axios from "axios";

export const getInvoiceReportData = (token, id) => (dispatch) => {
    dispatch({ type: GET_INVOICE_REPORT_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/saleInvoiceReport`,
        headers: { Accept: "application/json", Authorization: "Bearer " + token },
        data: { "invoice_header_id": id },
    }).then((response) => {
        return dispatch({
            type: GET_INVOICE_REPORT_SUCCESS,
            payload: response.data,
        });
    }).catch((error) => {
        dispatch({
            type: GET_INVOICE_REPORT_ERROR,
            payload: [],
        });

    });
};


export const getInvoicePrint = (token, id) => (dispatch) => {
    dispatch({ type: GET_INVOICE_PRINT_REQUEST });

    return axios({
        method: "POST",
        url: `${BASEURL}getAll/invoicePrint`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },

        data: { "invoice_header_id": id },
    })
        .then((response) => {

            return dispatch({
                type: GET_INVOICE_PRINT_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) => {
            dispatch({
                type: GET_INVOICE_PRINT_ERROR,
                payload: [],
            });
        });
};

export const getInvoiceColumnValue = (token, id) => (dispatch) => {
    dispatch({ type: GET_INVOICE_COLUMNSVALUES_REQUEST });

    return axios({
        method: "POST",
        url: `${BASEURL}getAll/invoiceColumnsValues`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },

        data: { "invoice_header_id": id },
    })
        .then((response) => {
            return dispatch({
                type: GET_INVOICE_COLUMNSVALUES_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) => {
            dispatch({
                type: GET_INVOICE_COLUMNSVALUES_ERROR,
                payload: [],
            });
        });
};


export const getInvoiceColumns = (token, id) => (dispatch) => {
    dispatch({ type: GET_INVOICE_COLUMNS_REQUEST });

    return axios({
        method: "POST",
        url: `${BASEURL}getAll/invoiceColumns`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },

        data: { "invoice_header_id": id },
    })
        .then((response) => {
            return dispatch({
                type: GET_INVOICE_COLUMNS_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) => {
            dispatch({
                type: GET_INVOICE_COLUMNS_ERROR,
                payload: [],
            });
        });
};