import {
    GET_PAYMENTMETHODTYPE_REQUEST, GET_PAYMENTMETHODTYPE_ERROR, GET_PAYMENTMETHODTYPE_SUCCESS,
    ADD_PAYMENTMETHODTYPE_REQUEST, ADD_PAYMENTMETHODTYPE_ERROR, ADD_PAYMENTMETHODTYPE_SUCCESS,
    UPDATE_PAYMENTMETHODTYPE_REQUEST, UPDATE_PAYMENTMETHODTYPE_ERROR, UPDATE_PAYMENTMETHODTYPE_SUCCESS,
    DELETE_PAYMENTMETHODTYPE_REQUEST, DELETE_PAYMENTMETHODTYPE_ERROR, DELETE_PAYMENTMETHODTYPE_SUCCESS,
} from "./types";
import { BASEURL } from "../../constant/config.js";

const axios = require("axios");

export const getPaymentMethodType = (token, menu_id) => (dispatch) => {
    dispatch({ type: GET_PAYMENTMETHODTYPE_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/AdminPaymentMethodType`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { menu_id }
    })
        .then((response) => {
            return dispatch({ type: GET_PAYMENTMETHODTYPE_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: GET_PAYMENTMETHODTYPE_ERROR,
                payload: error.response,
            });
        });
};

export const addPaymentMethodType = (token, data, menu_id) => (dispatch) => {
    dispatch({ type: ADD_PAYMENTMETHODTYPE_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}create/AdminPaymentMethodType`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { method_type_name: data.paymentMethodTypeName, is_active: data.check, menu_id },
    })
        .then((response) => {

            return dispatch({ type: ADD_PAYMENTMETHODTYPE_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: ADD_PAYMENTMETHODTYPE_ERROR,
                payload: error.response,
            });
        });
};

export const updatePaymentMethodType = (token, data, id) => (dispatch) => {

    dispatch({ type: UPDATE_PAYMENTMETHODTYPE_REQUEST });
    return axios({
        method: "PUT",
        url: `${BASEURL}update/AdminPaymentMethodType/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { method_type_name: data.paymentMethodTypeName, is_active: data.check },
    })
        .then((response) => {

            return dispatch({ type: UPDATE_PAYMENTMETHODTYPE_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: UPDATE_PAYMENTMETHODTYPE_ERROR,
                payload: error.response,
            });
        });
};

export const deletePaymentMethodType = (token, id) => (dispatch) => {
    dispatch({ type: DELETE_PAYMENTMETHODTYPE_REQUEST });
    return axios({
        method: "DELETE",
        url: `${BASEURL}delete/AdminPaymentMethodType/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
    })
        .then((response) => {

            return dispatch({ type: DELETE_PAYMENTMETHODTYPE_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: DELETE_PAYMENTMETHODTYPE_ERROR,
                payload: error.response,
            });
        });
};
