import { GET_BILLINGSHIPPINGDETAILS_REQUEST, GET_BILLINGSHIPPINGDETAILS_SUCCESS, GET_BILLINGSHIPPINGDETAILS_ERROR } from "../actions/types";

export default function (state = null, action) {
    const { type, payload } = action;
    switch (type) {
        case GET_BILLINGSHIPPINGDETAILS_REQUEST:
            return {
                loading: true,
            };
        case GET_BILLINGSHIPPINGDETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_BILLINGSHIPPINGDETAILS_ERROR:
            return {
                ...state,
                loading: false
            };
        default:
            return state
    }
}