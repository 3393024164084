import {
    GET_PAY_VENDOR_BILL_FOR_PAYMENT_REQUEST,
    GET_PAY_VENDOR_BILL_FOR_PAYMENT_SUCCESS,
    GET_PAY_VENDOR_BILL_FOR_PAYMENT_ERROR,  
} from "./types";

import { BASEURL } from "../../constant/config.js";

const axios = require("axios");

export const getPayVendorBill = (token, id) => (dispatch) => {
    dispatch({ type: GET_PAY_VENDOR_BILL_FOR_PAYMENT_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/payVendorBillForPayment`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { vendor_id: id }
    })
        .then((response) => {
            return dispatch({ type: GET_PAY_VENDOR_BILL_FOR_PAYMENT_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: GET_PAY_VENDOR_BILL_FOR_PAYMENT_ERROR,
                payload: error.response,
            });
        });
};
