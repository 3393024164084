import {    GET_ADMIN_MODULE_ERROR,    GET_ADMIN_MODULE_REQUEST,    GET_ADMIN_MODULE_SUCCESS,
    ADD_ADMIN_MODULE_ERROR,    ADD_ADMIN_MODULE_REQUEST,    ADD_ADMIN_MODULE_SUCCESS,
    DELETE_ADMIN_MODULE_REQUEST,    DELETE_ADMIN_MODULE_SUCCESS,    DELETE_ADMIN_MODULE_ERROR,
    UPDATE_ADMIN_MODULE_ERROR,    UPDATE_ADMIN_MODULE_REQUEST,    UPDATE_ADMIN_MODULE_SUCCESS,
} from "./types";

import { BASEURL } from "../../constant/config.js";

const axios = require("axios");

export const getAdminModule = (token) => (dispatch) => {
    dispatch({ type: GET_ADMIN_MODULE_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/admin_module`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
    })
        .then((response) => {
            return dispatch({ type: GET_ADMIN_MODULE_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: GET_ADMIN_MODULE_ERROR,
                payload: error.response,
            });
        });
};

export const addAdminModule = (token, data, menu_id) => (dispatch) => {
    dispatch({ type: ADD_ADMIN_MODULE_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}create/admin_module`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { module_name: data.adminModuleName, is_active: data.check, menu_id },
    })
        .then((response) => {
            return dispatch({ type: ADD_ADMIN_MODULE_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: ADD_ADMIN_MODULE_ERROR,
                payload: error.response,
            });
        });
};

export const updateAdminModule = (token, data, id) => (dispatch) => {
    dispatch({ type: UPDATE_ADMIN_MODULE_REQUEST });
    return axios({
        method: "PUT",
        url: `${BASEURL}update/admin_module/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { module_name: data.adminModuleName, is_active: data.check },
    })
        .then((response) => {
            return dispatch({ type: UPDATE_ADMIN_MODULE_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: UPDATE_ADMIN_MODULE_ERROR,
                payload: error.response,
            });
        });
};

export const deleteAdminModule = (token, id) => (dispatch) => {
    dispatch({ type: DELETE_ADMIN_MODULE_REQUEST });
    return axios({
        method: "DELETE",
        url: `${BASEURL}delete/admin_module/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
    })
        .then((response) => {
            return dispatch({ type: DELETE_ADMIN_MODULE_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: DELETE_ADMIN_MODULE_ERROR,
                payload: error.response,
            });
        });
};
