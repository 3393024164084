import {
    GET_REGIONCATG_BYID_REQUEST,
    GET_REGIONCATG_BYID_SUCCESS,
    GET_REGIONCATG_BYID_ERROR,

} from "./types"
import axios from "axios";
import { BASEURL } from "../../constant/config.js"


///GET CUSTOMER TYPE
export const getRegionCategory = (token,id) => dispatch => {
    dispatch({ type: GET_REGIONCATG_BYID_REQUEST });
    return axios({
        method: "GET",
        url: `${BASEURL}getOne/regionCategory/${id}`,
        headers: {
            Accept: "application/json",
            'Authorization': 'Bearer ' + token
        }
    })
        .then(response => {
           
            return dispatch({ type: GET_REGIONCATG_BYID_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            if(error.response.data.data === "No Record Found !")
            {
              return dispatch({
                type: GET_REGIONCATG_BYID_ERROR,
                payload: [],
              }); 
          }
            return dispatch({
                type: GET_REGIONCATG_BYID_ERROR,
                payload: error.response,
            });
        })
};

