import { useState, useEffect, useLayoutEffect } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getOneBillDirectPayment, deleteBillDirectPaymentHeader } from "../../redux/actions/billDirectPayments.action";
import CrudButtons from "./components/CrudButtons";
import BillDirectPaymentForm from "./components/Form/BillDirectPaymentForm";
import PageHeader from "../../components/PageHeader/PageHeader";
import { Button } from "antd";
import { ReadCookie } from "../../utils/readCookie";
import notification from "../../utils/notification";
import "../../screens/Product/product.css";
import "../Style/style.css";
import { getRights } from "../../redux/actions/userRights.action";
import { TabTitle } from "../../utils/GeneralFunction";
import { getTransationLock } from "../../redux/actions/transactionLock.action";
import moment from "moment";

export default function BillDirectPayment({ token }) {
	TabTitle("BillDirectPayment")
	const dispatch = useDispatch();
	const history = useHistory();
	const dCompanyId = ReadCookie("defaultCompany");
	const { log, billDirectPayment } = useSelector((state) => state);
	const [minimize, setMinimize] = useState(false);
	const [formState, setFormState] = useState("view");
	const [flag, setFlag] = useState(false);
	const { id } = useParams();
	const [companyId, setCompanyId] = useState(ReadCookie("defaultCompany"));
	const [disable, setDisable] = useState(false);
	const [submit, setSubmit] = useState(false);
	const queryParams = new URLSearchParams(window.location.search);
	const mode = queryParams.get("mode");
	const [userRights, setUserRights] = useState({});

	const [transactionLock, setTransactionLock] = useState(false)

	useLayoutEffect(() => {
		dispatch(getRights(token, 1068)).then((res) => {

			if (res.type === "GET_USER_RIGHTS_SUCCESS") {
				setUserRights(res?.payload)
			}
		});
	}, [token])



	const filterReset = () => {
		setFormState("view");
	};

	const handleDelete = () => {
		dispatch(deleteBillDirectPaymentHeader(token, id)).then((res) => {
			if (res.type === "DELETE_BILL_DIRECT_PAYMENT_HEADER_SUCCESS") {
				notification("success", "Bill Direct Payment Deleted", res.payload);
				setFlag(true);
				history.push("/bill-direct-payments/list");
			}
		});
	};

	const formSave = () => {
		setSubmit(true);
		setDisable(true)
	};

	useEffect(() => {
		if (mode === "add") {
			setFormState("add");
		}
	}, [mode]);

	useEffect(() => {
		if (id) {
			setFormState("formView");
			dispatch(getOneBillDirectPayment(token, id)).then((res) => {
				if (res?.type === "GET_ONE_BILL_DIRECT_PAYMENT_HEADER_SUCCESS") {
					if (res?.payload?.data[0]?.payment_date) {

						let transaction_date = moment(res?.payload.data[0]?.payment_date).format("YYYY-MM-DD");
						dispatch(getTransationLock(token, transaction_date)).then((r) => {

							if (r.payload.data.length > 0) {
								setTransactionLock(true);
							}
						});
					}
				}
			});
		}
	}, [id]);
	return (
		<>
			<div className="styleContainer">
				<div className="styleBody h-100">
					<PageHeader title={"Bill Direct Payment"} minimize={setMinimize} check={minimize} />
					<div className="bodyStyle">
						<div className="crudDiv">
							<CrudButtons
								id={id}
								name={"saleOrder"}
								reset={filterReset}
								userRights={userRights}
								formType={formState}
								save={formSave}
								disable={disable}
								formState={setFormState}
								onDelete={handleDelete}
								DirectPaymentID={id}
								formData={billDirectPayment?.payload?.data}
								transactionLock={transactionLock}
							/>
							<div className="mainProductContainer">
								<BillDirectPaymentForm
									setDisable={setDisable}
									dCompany={dCompanyId}
									defaultCompany={companyId}
									companyId={setCompanyId}
									submit={setSubmit}
									val={submit}
									formData={(formState === "formView" || formState === "edit") ? billDirectPayment?.payload?.data && billDirectPayment?.payload?.data : null}
									formState={formState}
									changeState={setFormState}
									token={token}
									checkFlag={flag}
									setFlag={setFlag}
									logs={log}
									DirectPaymentID={id}
									transactionLock={transactionLock}
								/>
							</div>
						</div>
					</div>
				</div>
				{minimize && (
					<div className="styleFooter">
						{" "}
						<Button className="minimize-button-style" onClick={() => setMinimize(false)}>
							{" "}
							Item Transfer{" "}
						</Button>{" "}
					</div>
				)}
			</div>
		</>
	);
}
