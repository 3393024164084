import { GET_SALEORDERADJUSTMENTFORMDATA_SUCCESS, GET_SALEORDERADJUSTMENTFORMDATA_REQUEST, GET_SALEORDERADJUSTMENTFORMDATA_ERROR } from "../actions/types";
export default function (state = [], action) {
	const { type, payload } = action;
	switch (type) {
		case GET_SALEORDERADJUSTMENTFORMDATA_REQUEST:
			return {
				loading: true,
			};
		case GET_SALEORDERADJUSTMENTFORMDATA_SUCCESS:
			return {
				...state,
				loading: false,
				payload,
			};
		case GET_SALEORDERADJUSTMENTFORMDATA_ERROR:
			return {
				...state,
				loading: false,
			};
		default:
			return state;
	}
}
