import {   GET_AVALARA_INVOICE_REQUEST,
	GET_AVALARA_INVOICE_SUCCESS,
	GET_AVALARA_INVOICE_ERROR,} from "../actions/types";

export default function (state = [], action) {
	const { type, payload } = action;
	switch (type) {
		case GET_AVALARA_INVOICE_REQUEST:
			return {
				loading: true,
			};
		case GET_AVALARA_INVOICE_SUCCESS:
			return {
				...state,
				loading: false,
				payload
						};
		case GET_AVALARA_INVOICE_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};

		default:
			return state;
	}
}
