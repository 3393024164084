import {
    GET_SEASON_ERROR, GET_SEASON_REQUEST, GET_SEASON_SUCCESS,
    ADD_SEASON_ERROR, ADD_SEASON_REQUEST, ADD_SEASON_SUCCESS,
    DELETE_SEASON_REQUEST, DELETE_SEASON_SUCCESS, DELETE_SEASON_ERROR,
    UPDATE_SEASON_ERROR, UPDATE_SEASON_REQUEST, UPDATE_SEASON_SUCCESS

} from "../actions/types"
export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case GET_SEASON_REQUEST:
            return {
                loading: true,
            };
        case GET_SEASON_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_SEASON_ERROR:
            return {
                ...state,
                loading: false
            };
        case ADD_SEASON_REQUEST:
            return {
                loading: true,
            };
        case ADD_SEASON_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case ADD_SEASON_ERROR:
            return {
                ...state,
                loading: false
            };
        case DELETE_SEASON_REQUEST:
            return {
                loading: true,
            };
        case DELETE_SEASON_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case DELETE_SEASON_ERROR:
            return {
                ...state,
                loading: false
            };
        case UPDATE_SEASON_REQUEST:
            return {
                loading: true,
            };
        case UPDATE_SEASON_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case UPDATE_SEASON_ERROR:
            return {
                ...state,
                loading: false
            };

        default:
            return state;
    }
}