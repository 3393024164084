import {
    GET_ALL_PRODUCT_ERROR, GET_ALL_PRODUCT_REQUEST, GET_ALL_PRODUCT_SUCCESS,
} from "../actions/types";

export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case GET_ALL_PRODUCT_REQUEST:
            return {
                loading: true,
            };
        case GET_ALL_PRODUCT_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_ALL_PRODUCT_ERROR:
            return {
                ...state,
                loading: false
            };

        default:
            return state;
    }
}