import {
  GET_COMBINATION_FORM_REQUEST, GET_COMBINATION_FORM_SUCCESS, GET_COMBINATION_FORM_ERROR,
  GET_STYLE_COMBINATION_REQUEST, GET_STYLE_COMBINATION_SUCCESS, GET_STYLE_COMBINATION_ERROR,
  GET_ONE_STYLE_COMBINATION_REQUEST, GET_ONE_STYLE_COMBINATION_SUCCESS, GET_ONE_STYLE_COMBINATION_ERROR,
  ADD_STYLE_COMBINATION_REQUEST, ADD_STYLE_COMBINATION_SUCCESS, ADD_STYLE_COMBINATION_ERROR,
  EDIT_STYLE_COMBINATION_REQUEST, EDIT_STYLE_COMBINATION_SUCCESS, EDIT_STYLE_COMBINATION_ERROR,
  DELETE_STYLE_COMBINATION_REQUEST, DELETE_STYLE_COMBINATION_SUCCESS, DELETE_STYLE_COMBINATION_ERROR
} from "./types";
import { BASEURL } from "../../constant/config.js";

const axios = require("axios");

export const getCombinationForm = (token, company_id) => (dispatch) => {
  dispatch({ type: GET_COMBINATION_FORM_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}getAll/combinationForm`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: { company_id }
  })
    .then((response) => {
      return dispatch({ type: GET_COMBINATION_FORM_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      return dispatch({
        type: GET_COMBINATION_FORM_ERROR,
        payload: error.response,
      });
    });
};

export const getStyleCombination = (token, id) => (dispatch) => {
  dispatch({ type: GET_STYLE_COMBINATION_REQUEST });
  return axios({
    method: "GET",
    url: `${BASEURL}getOne/styleCombination/${id}?filter=Y`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    }
  })
    .then((response) => {
      return dispatch({ type: GET_STYLE_COMBINATION_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      return dispatch({
        type: GET_STYLE_COMBINATION_ERROR,
        payload: error.response,
      });
    });
};

export const addStyleCombination = (token, data, style) => (dispatch) => {
  let newData = {
    style_id: style.style_id,
    rise_id: data.rise,
    rise_label_id: data.riseLabel,
    cut_id: data.fit,
    gender_id: data.gender,
    gender_category_id: data.genderCategory,
    company_id: data.company_id,
    fit_category_id: data.fitCategory,
    is_default: data.is_default
  }
  dispatch({ type: ADD_STYLE_COMBINATION_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}create/styleCombination`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: newData
  })
    .then((response) => {
      return dispatch({ type: ADD_STYLE_COMBINATION_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      return dispatch({
        type: ADD_STYLE_COMBINATION_ERROR,
        payload: error.response,
      });
    });
};

export const getOneStyleCombination = (token, id,) => (dispatch) => {
  dispatch({ type: GET_ONE_STYLE_COMBINATION_REQUEST });
  return axios({
    method: "GET",
    url: `${BASEURL}getOne/styleCombination/${id}?filter=N`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    }
  })
    .then((response) => {
      return dispatch({ type: GET_ONE_STYLE_COMBINATION_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      return dispatch({
        type: GET_ONE_STYLE_COMBINATION_ERROR,
        payload: error.response,
      });
    });
};
export const deleteStyleCombination = (token, id) => (dispatch) => {
  dispatch({ type: DELETE_STYLE_COMBINATION_REQUEST });
  return axios({
    method: "DELETE",
    url: `${BASEURL}delete/styleCombination/${id}`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    }
  })
    .then((response) => {
      return dispatch({ type: DELETE_STYLE_COMBINATION_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      return dispatch({
        type: DELETE_STYLE_COMBINATION_ERROR,
        payload: error.response,
      });
    });
};
export const editStyleCombination = (token, id, data) => (dispatch) => {
  let newData = {
    rise_id: data.rise,
    rise_label_id: data.riseLabel,
    cut_id: data.fit,
    gender_id: data.gender,
    gender_category_id: data.genderCategory,
    company_id: data.company_id,
    fit_category_id: data.fitCategory,
    style_id: data.style_id,
    is_default: data.is_default,
  }
  dispatch({ type: EDIT_STYLE_COMBINATION_REQUEST });
  return axios({
    method: "PUT",
    url: `${BASEURL}update/styleCombination/${id}`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: newData
  })
    .then((response) => {
      return dispatch({ type: EDIT_STYLE_COMBINATION_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      return dispatch({
        type: EDIT_STYLE_COMBINATION_ERROR,
        payload: error.response,
      });
    });
};

