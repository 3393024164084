import {
    GET_INSEAM_ERROR, GET_INSEAM_REQUEST, GET_INSEAM_SUCCESS,
    ADD_INSEAM_ERROR, ADD_INSEAM_REQUEST, ADD_INSEAM_SUCCESS,
    DELETE_INSEAM_REQUEST, DELETE_INSEAM_SUCCESS, DELETE_INSEAM_ERROR,
    UPDATE_INSEAM_ERROR, UPDATE_INSEAM_REQUEST, UPDATE_INSEAM_SUCCESS

} from "../actions/types"
export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case GET_INSEAM_REQUEST:
            return {
                loading: true,
            };
        case GET_INSEAM_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_INSEAM_ERROR:
            return {
                ...state,
                loading: false
            };
        case ADD_INSEAM_REQUEST:
            return {
                loading: true,
            };
        case ADD_INSEAM_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case ADD_INSEAM_ERROR:
            return {
                ...state,
                loading: false
            };
        case DELETE_INSEAM_REQUEST:
            return {
                loading: true,
            };
        case DELETE_INSEAM_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case DELETE_INSEAM_ERROR:
            return {
                ...state,
                loading: false
            };
        case UPDATE_INSEAM_REQUEST:
            return {
                loading: true,
            };
        case UPDATE_INSEAM_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case UPDATE_INSEAM_ERROR:
            return {
                ...state,
                loading: false
            };

        default:
            return state;
    }
}