import {
    GET_REGION_STATE_REPORT_BY_CUSTOMER_ERROR,
    GET_REGION_STATE_REPORT_BY_CUSTOMER_REQUEST,
    GET_REGION_STATE_REPORT_BY_CUSTOMER_SUCCESS,
} from "./types";
import { BASEURL } from "../../constant/config.js";
import moment from "moment";

const axios = require("axios");
export const getOmsReportByCustomer = (token, data) => (dispatch) => {


    dispatch({ type: GET_REGION_STATE_REPORT_BY_CUSTOMER_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/getSaleOrderOmsByCustomerReport`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: {
            from_date: data.from_date,
            to_date: data.to_date,
            gender_id: data.gender_id === "All" ? null : data.gender_id,
            date_type: data.date_type === "All" ? null : data.date_type,
            customer_type_id: data.customer_type_id === "All" ? null : data.customer_type_id,
            region_id: data.id,
            company_id: data.company_id,
            state_id: data.state_id,
            customer_id: data.customer_id
        }
    })
        .then((response) => {
            return dispatch({ type: GET_REGION_STATE_REPORT_BY_CUSTOMER_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            if (error.response.data.data === "No Record Found !") {
                return dispatch({
                    type: GET_REGION_STATE_REPORT_BY_CUSTOMER_ERROR,
                    payload: [],
                });
            }
            return dispatch({
                type: GET_REGION_STATE_REPORT_BY_CUSTOMER_ERROR,
                payload: error.response,
            });
        });
};
