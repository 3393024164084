import {
  GET_SALES_REPORT_ORDER_ITEM_REQUEST,
  GET_SALES_REPORT_ORDER_ITEM_SUCCESS,
  GET_SALES_REPORT_ORDER_ITEM_ERROR,
  GET_SALES_REPORT_ORDER_PRINT_REQUEST,
  GET_SALES_REPORT_ORDER_PRINT_SUCCESS,
  GET_SALES_REPORT_ORDER_PRINT_ERROR,
  GET_SALES_REPORT_ORDER_COLUMNSVALUE_REQUEST,
  GET_SALES_REPORT_ORDER_COLUMNSVALUE_SUCCESS,
  GET_SALES_REPORT_ORDER_COLUMNSVALUE_ERROR,

  GET_SALES_REPORT_ORDER_COLUMN_REQUEST,
  GET_SALES_REPORT_ORDER_COLUMN_SUCCESS,
  GET_SALES_REPORT_ORDER_COLUMN_ERROR,

  GET_NEW_SALES_REPORT_SUCCESS,
  GET_NEW_SALES_REPORT_REQUEST,
  GET_NEW_SALES_REPORT_ERROR

} from "./types";
import axios from "axios";
import { BASEURL } from "../../constant/config.js";

///Post getSalesReport
export const getSalesReportOrderItem = (token, values) => (dispatch) => {
  dispatch({ type: GET_SALES_REPORT_ORDER_ITEM_REQUEST });

  return axios({
    method: "POST",
    url: `${BASEURL}getAll/getSaleOrderitem`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },

    data: values,
  })
    .then((response) => {


      return dispatch({
        type: GET_SALES_REPORT_ORDER_ITEM_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_SALES_REPORT_ORDER_ITEM_ERROR,
        payload: [],
      });
    });
};

export const getSalesReportOrderPrint = (token, values) => (dispatch) => {
  dispatch({ type: GET_SALES_REPORT_ORDER_PRINT_REQUEST });

  return axios({
    method: "POST",
    url: `${BASEURL}getAll/getSaleOrderPrint`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },

    data: values,
  })
    .then((response) => {

      return dispatch({
        type: GET_SALES_REPORT_ORDER_PRINT_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_SALES_REPORT_ORDER_PRINT_ERROR,
        payload: [],
      });
    });
};

export const getSalesReportColumnValue = (token, values) => (dispatch) => {
  dispatch({ type: GET_SALES_REPORT_ORDER_COLUMNSVALUE_REQUEST });

  return axios({
    method: "POST",
    url: `${BASEURL}getAll/getSaleOrderColumnsValue`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },

    data: values,
  })
    .then((response) => {
      return dispatch({
        type: GET_SALES_REPORT_ORDER_COLUMNSVALUE_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_SALES_REPORT_ORDER_COLUMNSVALUE_ERROR,
        payload: [],
      });
    });
};


export const getSalesReportColumn = (token, values) => (dispatch) => {
  dispatch({ type: GET_SALES_REPORT_ORDER_COLUMN_REQUEST });

  return axios({
    method: "POST",
    url: `${BASEURL}getAll/getSaleOrderColumns`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },

    data: values,
  })
    .then((response) => {
      return dispatch({
        type: GET_SALES_REPORT_ORDER_COLUMN_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_SALES_REPORT_ORDER_COLUMN_ERROR,
        payload: [],
      });
    });
};

export const getNewSalesReport = (token, values) => (dispatch) => {
  dispatch({ type: GET_NEW_SALES_REPORT_REQUEST });

  return axios({
    method: "POST",
    url: `${BASEURL}getAll/getNewSaleOrder`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },

    data: values,
  })
    .then((response) => {
      return dispatch({
        type: GET_NEW_SALES_REPORT_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_NEW_SALES_REPORT_ERROR,
        payload: [],
      });
    });
};
