import { GET_FABRICCLASS_REQUEST, GET_FABRICCLASS_SUCCESS, GET_FABRICCLASS_ERROR, ADD_FABRICCLASS_REQUEST, ADD_FABRICCLASS_ERROR, ADD_FABRICCLASS_SUCCESS, UPDATE_FABRICCLASS_REQUEST, UPDATE_FABRICCLASS_SUCCESS, UPDATE_FABRICCLASS_ERROR, DELETE_FABRICCLASS_ERROR, DELETE_FABRICCLASS_REQUEST, DELETE_FABRICCLASS_SUCCESS } from "./types"

import { BASEURL } from "../../constant/config.js";

const axios = require("axios");

export const getFabricClass = (token, menu_id) => (dispatch) => {

    dispatch({ type: GET_FABRICCLASS_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/fabricClass`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { menu_id }
    }).then((response) => {
        return dispatch({ type: GET_FABRICCLASS_SUCCESS, payload: response.data })
    }).catch((error) => {
        return dispatch({ type: GET_FABRICCLASS_ERROR, payload: error.response })
    })
}

export const addFabricClass = (token, data) => (dispatch) => {
    
    dispatch({ type: ADD_FABRICCLASS_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}create/fabricClass`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { fabric_class_name: data.fabricClassName, is_active: data.check },
    })
        .then((response) => {
            
            return dispatch({ type: ADD_FABRICCLASS_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: ADD_FABRICCLASS_ERROR,
                payload: error.response,
            });
        });
};
export const updateFabricClass = (token, data, id) => (dispatch) => {
    dispatch({ type: UPDATE_FABRICCLASS_REQUEST });
    return axios({
        method: "PUT",
        url: `${BASEURL}update/fabricClass/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { fabric_class_name: data.fabricClassName, is_active: data.check },
    })
        .then((response) => {

            return dispatch({ type: UPDATE_FABRICCLASS_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: UPDATE_FABRICCLASS_ERROR,
                payload: error.response,
            });
        });
};

export const deleteFabricClass = (token, id) => (dispatch) => {
    dispatch({ type: DELETE_FABRICCLASS_REQUEST });
    return axios({
        method: "DELETE",
        url: `${BASEURL}delete/fabricClass/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
    })
        .then((response) => {
            
            return dispatch({ type: DELETE_FABRICCLASS_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: DELETE_FABRICCLASS_ERROR,
                payload: error.response,
            });
        });
};
