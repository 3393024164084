import {
  GET_WISE_STOCK_REPORT_REQUEST,
  GET_WISE_STOCK_REPORT_SUCCESS,
  GET_WISE_STOCK_REPORT_ERROR,
  GET_STYLE_WISE_STOCK_REPORT_REQUEST,
  GET_STYLE_WISE_STOCK_REPORT_SUCCESS,
  GET_STYLE_WISE_STOCK_REPORT_ERROR,
  GET_NEGATIVE_OTS_ORDER_WISE_REQUEST,
  GET_NEGATIVE_OTS_ORDER_WISE_SUCCESS,
  GET_NEGATIVE_OTS_ORDER_WISE_ERROR,
} from "./types";
import axios from "axios";
import { BASEURL } from "../../constant/config.js";


export const getWise_Stock_Report = (token, values) => (dispatch) => {
  dispatch({ type: GET_WISE_STOCK_REPORT_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}getAll/styleWiseStockReportPk`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: { ...values },
  })
    .then((response) => {
      return dispatch({
        type: GET_WISE_STOCK_REPORT_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_WISE_STOCK_REPORT_ERROR,
        payload: [],
      });










    });
};

export const getNegativeOtsOrderWise = (token, values) => (dispatch) => {
  dispatch({ type: GET_NEGATIVE_OTS_ORDER_WISE_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}getAll/negativeOtsOrderWise`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: { ...values },
  })
    .then((response) => {
      return dispatch({
        type: GET_NEGATIVE_OTS_ORDER_WISE_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_NEGATIVE_OTS_ORDER_WISE_ERROR,
        payload: [],
      });
    });
};

export const getStyleWiseStockReport = (token, values) => (dispatch) => {
  dispatch({ type: GET_STYLE_WISE_STOCK_REPORT_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}getAll/getStyleWiseStockReport`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: { ...values },
  })
    .then((response) => {
      return dispatch({
        type: GET_STYLE_WISE_STOCK_REPORT_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_STYLE_WISE_STOCK_REPORT_ERROR,
        payload: [],
      });
    });
};
