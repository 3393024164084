import {
    GET_ONE_INVOICE_LINE_DATA_ERROR,
    GET_ONE_INVOICE_LINE_DATA_REQUEST,
    GET_ONE_INVOICE_LINE_DATA_SUCCESS,
  } from "../actions/types";
  
  export const getOneInvoice = (state = [], action) => {
    const { type, payload } = action;
    switch (type) {
      case GET_ONE_INVOICE_LINE_DATA_REQUEST:
        return {
          loading: true,
        };
      case GET_ONE_INVOICE_LINE_DATA_SUCCESS:
        return {
          ...state,
          loading: false,
          payload,
        };
      case GET_ONE_INVOICE_LINE_DATA_ERROR:
        return {
          ...state,
          loading: false,
        };
      default:
        return state;
    }
  };
  