import {
    GET_TRANSACTION_NUMBER_REQUEST,
    GET_TRANSACTION_NUMBER_SUCCESS,
    GET_TRANSACTION_NUMBER_ERROR,
} from "./types";
import axios from "axios";
import { BASEURL } from "../../constant/config.js";


export const getTransactionNo = (token, dTransactionNo) => (dispatch) => {
    
    dispatch({ type: GET_TRANSACTION_NUMBER_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/getTransactionNo`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: {transaction_no:dTransactionNo},
    })
        .then((response) => {
            return dispatch({
                type: GET_TRANSACTION_NUMBER_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) => {
            dispatch({
                type: GET_TRANSACTION_NUMBER_ERROR,
                payload: [],
            });
            
            
            
            
            
            
            
            
            
            
        });
};
