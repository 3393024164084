import {
    GET_TRACKING_STATUS_REQUEST,
    GET_TRACKING_STATUS_SUCCESS,
    GET_TRACKING_STATUS_ERROR,
} from "../actions/types";

export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case GET_TRACKING_STATUS_REQUEST:
            return {
                loading: true,
            };
        case GET_TRACKING_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_TRACKING_STATUS_ERROR:
            return {
                ...state,
                loading: false,
                payload,
            };

        default: {
            return state;
        }
    }
};
