import {
    GET_JOBTITLE_REQUEST,
    GET_JOBTITLE_SUCCESS,
    GET_JOBTITLE_ERROR,

} from "../../actions/types";

  export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
      case GET_JOBTITLE_REQUEST:
        return {
          loading: true,
        };
      case GET_JOBTITLE_SUCCESS:
        return {
          ...state,
          loading: false,
          payload,
        };
      case GET_JOBTITLE_ERROR:
        return {
          ...state,
          loading: false,
          payload,
        };

      default:
        return state;
    }
  }
  