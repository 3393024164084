import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import PageHeader from "../../components/PageHeader/PageHeader";
import { Row as Brow, Col as Bcol } from "reactstrap";
import {
  Button,
  Form,
  Select,
  Table, Spin, DatePicker, Input
} from "antd";
import { ReadCookie } from "../../utils/readCookie";
import numeral from "numeral";
import { CSVLink } from "react-csv";
import moment from "moment";
import xlsx from "../../assets/images/ico_xls_sm.gif";
import csv from "../../assets/images/csv.png";
import pdf from "../../assets/images/PDF_file_icon.svg.png";
import { loadCustomers } from "../../redux/actions/customerForm";
import { useCallback } from "react";
import { getArAgingSummary } from "../../redux/actions/arAgingSummary.action";
import { getRegion } from "../../redux/actions/region.action";
import { getPaymentTerm } from "../../redux/actions/paymentterm.action";
import { getAllCompany } from "../../redux/actions/companyAccess";
import { loadSalePerson } from "../../redux/actions/salePerson/salePersonForm.action";
import { getGender } from "../../redux/actions/gender.action";
import Loader from "react-spinners/BarLoader";
import { getFactor } from "../../redux/actions/factor.action";
import { TabTitle } from "../../utils/GeneralFunction";
import { SearchOutlined, FilterFilled } from '@ant-design/icons';
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import ExcelJS from "exceljs";

const { Option } = Select;

const ArAgingSummary = () => {
  TabTitle("Customer Aging")
  const [form] = Form.useForm();
  const [minimize, setMinimize] = useState(false);
  const [toogle, setToogle] = useState(false);
  const submitButton = useRef(null);
  const dispatch = useDispatch();
  const menuId = ReadCookie("menuId");
  const token = ReadCookie("token");
  const [dscoInvoiceList, setDscoInvoiceList] = useState([]);
  const [loading, setLoading] = useState(false);
  const tableRef = useRef(null);
  const focusInput = useRef(null);
  const companyId = ReadCookie("defaultCompany");
  const company_id = ReadCookie("defaultCompany");
  const {
    gender,
    salePersonForm,
    paymentterm,
    customerForms,
    arAgingSummary,
    factor
  } = useSelector((state) => state);
  const [excelData, setExcelData] = useState([])
  const [customer, setCustomer] = useState("All");

  useEffect(() => {
    dispatch(getRegion(token, menuId));
    dispatch(getGender(token, menuId));
    dispatch(getPaymentTerm(token, menuId));
    dispatch(getAllCompany(token));
    dispatch(loadSalePerson(token));
    dispatch(getFactor(token, menuId));
  }, [dispatch, menuId, token]);


  
  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      
      render: (text, record, index) => {
        return index + 1;
      },
      width: 40,
    },
    {
      title: "Customer",
      dataIndex: "customer_name",
      key: "customer_name",
      width: 250,
      render: (text, record) => {
        return <Link
          style={{ color: "blue", textDecoration: "underline", padding: "10px", textDecoration: "none" }}
          target="_blank"
          rel="noopener noreferrer"
          to={{ pathname: `/customer-payment-ledger`, search: `?customer_id=${record?.customer_id}&customer_name=${record?.customer_name}` }}
        >{record.customer_name}
        </Link>
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              allowClear
              className='pop-search-gen pop-170'
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.customer_name?.toString()?.toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        if ((a.customer_name !== null) & (b.customer_name !== null)) {
          return a.customer_name.localeCompare(b.customer_name);
        }
      }
    },
    {
      title: "SalesPerson",
      dataIndex: "sales_person",
      key: "sales_person",
    },
    {
      title: "Gender",
      dataIndex: "gender_name",
      key: "gender_name",
    },
    {
      title: "Current",
      dataIndex: "current_balance",
      key: "current_balance",
      render: (text, record) => {
        return <>${record.sales_person === "Credits" ? 0.00 : record.current_balance}
        </>
      },
      align: "right"
    },
    
    {
      title: "1-30 Days",
      dataIndex: "one_to_thirty",
      key: "one_to_thirty",
      render: (text, record) => {
        return <>$ {numeral(text).format("0,00.00")}</>
      },
      align: "right"
    },
    {
      title: "31-60 Days",
      dataIndex: "thirty_to_sixty",
      key: "thirty_to_sixty",
      render: (text, record) => {
        return <>$ {numeral(text).format("0,00.00")}</>
      },
      align: "right"
    },
    {
      title: "61-90 Days",
      dataIndex: "sixty_to_ninety",
      key: "sixty_to_ninety",
      render: (text, record) => {
        return <>$ {numeral(text).format("0,00.00")}</>
      },
      align: "right"
    },
    {
      title: "> 90 Days",
      dataIndex: "greter_than_ninety",
      key: "greter_than_ninety",
      render: (text, record) => {
        return <>$ {numeral(text).format("0,00.00")}</>
      },
      align: "right"
    },
    {
      title: "Total",
      dataIndex: "totalbalance",
      key: "totalbalance",
      render: (text, record) => {
        return <>$ {record.sales_person === "Credits" ? 0.00 : record.totalbalance}</>
      },
      align: "right"
    },
    {
      title: "Available Credit",
      dataIndex: "available_credit",
      key: "available_credit",
      render: (text, record) => {
        return <>$ {numeral(text).format("0,00.00")}</>
      },
      align: "right"
    },
    {
      title: "Net",
      dataIndex: "netbalance",
      key: "netbalance",
      render: (text, record) => {
        return <>$ {numeral(text).format("0,00.00")}</>
      },
      align: "right"
    },

  ];

  const onFinish = async () => {
    setLoading(true);
    await form.validateFields().then((values) => {
      
      dispatch(getArAgingSummary(token, values)).then((res) => {
        setToogle(true);
        setLoading(false);
        setDscoInvoiceList(res?.payload?.data);
        downloadExcelFileData(res?.payload?.data);
      });
    }).catch((error) => {
      setLoading(false);
    })
  }

  function downloadExcelFileData(data) {
    setExcelData(
      data.map((v) => {
        return {
          "Customer": v.customer_name,
          "SalesPerson": v.sales_person,
          "Gender": v.gender_name,
          "Current": `$ ${numeral(v.current_balance).format("0,00.00")}`,
          "1-30 Days": `$ ${numeral(v.one_to_thirty).format("0,00.00")}`,
          "31-60 Days": `$ ${numeral(v.thirty_to_sixty).format("0,00.00")}`,
          "61-90 Days": `$ ${numeral(v.sixty_to_ninety).format("0,00.00")}`,
          "> 90 Days": `$ ${numeral(v.greter_than_ninety).format("0,00.00")}`,
          "Total": `$ ${numeral(v.totalbalance).format("0,00.00")}`,
          "Available Credit": `$ ${numeral(v.available_credit).format("0,00.00")}`,
          "Net": `$ ${numeral(v.netbalance).format("0,00.00")}`,
        };
      })
    )
  }

  const exportToExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet 1');

    worksheet.columns = [
      { header: 'Customer', key: 'customer_name', width: 30 },
      { header: 'SalesPerson', key: 'sales_person', width: 30 },
      { header: 'Gender', key: 'gender_name', width: 30 },
      { header: 'Current', key: 'current_balance', width: 30 },
      { header: '1-30 Days', key: 'one_to_thirty', width: 30 },
      { header: '31-60 Days', key: 'thirty_to_sixty', width: 30 },
      { header: '61-90 Days', key: 'sixty_to_ninety', width: 30 },
      { header: '> 90 Days', key: 'greter_than_ninety', width: 30 },
      { header: 'Total', key: 'totalbalance', width: 15 },
      { header: 'Available Credit', key: 'available_credit', width: 15 },
      { header: 'Net', key: 'netbalance', width: 15 },
    ];

    let totalBalance = 0;
    let onetoThirty = 0;
    let toSixty = 0;
    let toNinety = 0;
    let greaterNinety = 0;
    let total = 0;
    let availableCredit= 0;
    let net= 0;

    
    arAgingSummary?.payload?.data.forEach((item) => {
      worksheet.addRow({ customer_name: item.customer_name, sales_person: item.sales_person, gender_name: item.gender_name, current_balance: `$ ${numeral(item.current_balance).format("0,00.00")}`, one_to_thirty: `$ ${numeral(item.one_to_thirty).format("0,00.00")}`, thirty_to_sixty: `$ ${numeral(item.thirty_to_sixty).format("0,00.00")}`, sixty_to_ninety: `$ ${numeral(item.sixty_to_ninety).format("0,00.00")}`, greter_than_ninety: `$ ${numeral(item.greter_than_ninety).format("0,00.00")}`, totalbalance: `$ ${numeral(item.totalbalance).format("0,00.00")}`, available_credit: `$ ${numeral(item.available_credit).format("0,00.00")}`, netbalance: `$ ${numeral(item.netbalance).format("0,00.00")}` });
      totalBalance += Number(item.current_balance);
      onetoThirty += Number(item.one_to_thirty);
      toSixty += Number(item.thirty_to_sixty);
      toNinety += Number(item.sixty_to_ninety);
      greaterNinety += Number(item.greter_than_ninety);
      total += Number(item.totalbalance);
      availableCredit += Number(item.available_credit);
      net += Number(item.netbalance);
    });

    worksheet.addRow({ current_balance: totalBalance, one_to_thirty: onetoThirty, thirty_to_sixty: toSixty, sixty_to_ninety: toNinety, greter_than_ninety: greaterNinety, totalbalance: total, available_credit: availableCredit, netbalance: net});

    try {
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      
      const filename = 'A/R-Aging-Summary.xlsx';

      
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      link.click();

      
    } catch (error) {
      console.error(error);
    }
  };

  function generatePDF() {
    var doc = new jsPDF();

    
    var columns = ["Customer", "SalesPerson", "Gender", "Current", "1-30 Days", "31-60 Days", "61-90 Days", "> 90 Days", "Total", "Available Credit", "Net"];
    var rows = [];

    
    arAgingSummary?.payload?.data.forEach(item => {
      var row = [item.customer_name, item.sales_person, item.gender_name, "$" + numeral(item.current_balance).format("0,00.00"), "$" + numeral(item.one_to_thirty).format("0,00.00"), "$" + numeral(item.thirty_to_sixty).format("0,00.00"), "$" + numeral(item.sixty_to_ninety).format("0,00.00"), "$" + numeral(item.greter_than_ninety).format("0,00.00"), "$" + numeral(item.totalbalance).format("0,00.00"), "$" + numeral(item.available_credit).format("0,00.00"), "$" + numeral(item.netbalance).format("0,00.00")];
      rows.push(row);
    });

    const current = arAgingSummary?.payload?.data.reduce((acc, item, index) => acc + parseFloat(item.current_balance), 0)
    const onetoThirty = arAgingSummary?.payload?.data.reduce((acc, item, index) => acc + parseFloat(item.one_to_thirty), 0)
    const toSixty = arAgingSummary?.payload?.data.reduce((acc, item, index) => acc + parseFloat(item.thirty_to_sixty), 0)
    const toNinety = arAgingSummary?.payload?.data.reduce((acc, item, index) => acc + parseFloat(item.sixty_to_ninety), 0)
    const greaterNinety = arAgingSummary?.payload?.data.reduce((acc, item, index) => acc + parseFloat(item.greter_than_ninety), 0)
    const total = arAgingSummary?.payload?.data.reduce((acc, item, index) => acc + parseFloat(item.totalbalance), 0)
    const availableCredit = arAgingSummary?.payload?.data.reduce((acc, item, index) => acc + parseFloat(item.available_credit), 0)
    const net = arAgingSummary?.payload?.data.reduce((acc, item, index) => acc + parseFloat(item.netbalance), 0)

    
    doc.autoTable({
      head: [columns],
      body: rows,
      styles: { fontSize: 6 },
    })

    const startY = doc.lastAutoTable.finalY || 10; 
    const footerRow = ["", "", "", "", "", "", "", "", "","", "", "", "", "", "Total:", "$" + numeral(current).format("0,00.00"), "$" + numeral(onetoThirty).format("0,00.00"), "$" + numeral(toSixty).format("0,00.00"), "$" + numeral(toNinety).format("0,00.00"), "$" + numeral(greaterNinety).format("0,00.00"), "$" + numeral(total).format("0,00.00"), "$" + numeral(availableCredit).format("0,00.00"), "$" + numeral(net).format("0,00.00")];
    doc.autoTable({
      body: [footerRow],
      startY: startY + 2,
      styles: { fontSize: 6 },
    });

    
    doc.save("A/R-Aging-Summary.pdf");
  }



  function debounce(cb, delay = 1000) {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        cb(...args);
      }, delay);
    };
  }

  const handleChanges = (val) => {
    if (val === "" || val === "All") return;
    setCustomer(val);
    dispatch(loadCustomers(token, null, null, null, null, null, val, null, null, null, null, null, null, null, null, null, null, null, null)).then(
      (res) => {
        if (res?.type === "GET_ALL_CUSTOMERS_SUCCESS") {
          setCustomer(res?.payload?.data);
        }
      }
    );
  };

  const optimizedFn = useCallback(debounce(handleChanges), []);


  const current = dscoInvoiceList.reduce((acc, item, index) => {
    if (item.sales_person === "Credits") {
      item.current_balance = 0;
    }
    return acc + parseFloat(item.current_balance);
  }, 0);
  const onetoThirty = dscoInvoiceList.reduce((acc, item, index) => acc + parseFloat(item.one_to_thirty), 0)
  const toSixty = dscoInvoiceList.reduce((acc, item, index) => acc + parseFloat(item.thirty_to_sixty), 0)
  const toNinety = dscoInvoiceList.reduce((acc, item, index) => acc + parseFloat(item.sixty_to_ninety), 0)
  const greaterNinety = dscoInvoiceList.reduce((acc, item, index) => acc + parseFloat(item.greter_than_ninety), 0)
  const total = dscoInvoiceList.reduce((acc, item, index) => {
    if (item.sales_person === "Credits") {
      item.totalbalance = 0;
    }
    return acc + parseFloat(item.totalbalance)
  }, 0)
  const availableCredit = dscoInvoiceList.reduce((acc, item, index) => acc + parseFloat(item.available_credit), 0)
  const net = dscoInvoiceList.reduce((acc, item, index) => acc + parseFloat(item.netbalance), 0)


  return (
    <div className="styleContainer">
      <div className="styleBody h-100">
        <PageHeader
          title={"A/R Aging Summary"}
          minimize={setMinimize}
          check={minimize}
        />
        {!minimize && (
          <div className="bodyStyle">
            <div className="formProduct p-0">
              <Form onFinish={onFinish} form={form} name="control-hooks">
                <div className="container-fluid pbpx-5 ptpx-5">
                  <Brow>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div className="slc">
                        <Form.Item
                          name="gender"
                          label="Gender"
                          labelAlign="left"
                          labelCol={{ span: 8 }}
                          wrapperCol={{ span: 16 }}
                          initialValue={"All"}
                        >
                          <Select
                            size='small'
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, options) => options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            className="styleInput w-100"
                            defaultValue={form.getFieldValue().region}
                            filterSort={(optionA, optionB) =>
                              optionB?.value!==null?
                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()):''
                          }
                          >
                            <Option value={null}>All</Option>
                            {
                              gender?.payload?.data.map((val) => {
                                return (
                                  <Option key={val.gender_id} value={val.gender_id}>{val.gender_name}</Option>
                                )
                              })
                            }

                          </Select>
                        </Form.Item>
                      </div>
                    </Bcol>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div className="slc">
                        <Form.Item
                          name="sale_person"
                          label="Sale Person"
                          labelAlign="left"
                          labelCol={{ span: 8 }}
                          wrapperCol={{ span: 16 }}
                          initialValue={"All"}
                        >
                          <Select
                            size='small'
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, options) => options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            className="styleInput w-100"
                            defaultValue={form.getFieldValue().sale_person}
                            filterSort={(optionA, optionB) =>
                              optionB?.value!==null?
                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()):''
                          }
                          >
                            <Option value={null}>All</Option>
                            <Option value={"-1"}>Credit</Option>
                            {
                              salePersonForm?.payload?.data.map((val, index) => {
                                return (
                                  <Option key={val.sales_person_id} value={val.sales_person_id}>{val.salesperson_name}</Option>
                                )
                              })
                            }

                          </Select>
                        </Form.Item>
                      </div>
                    </Bcol>
                    {/* <Bcol lg={6} xl={4} xxl={3}>
                      <div className="">
                        <div className="relative">
                          <Form.Item
                            name="from_date"
                            label="From Date:"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            initialValue={moment().startOf('year')}
                          >
                            <DatePicker
                              size='small'
                              className="styleInput w-100"
                              format={"MM-DD-YYYY"}
                              defaultValue={moment().format("MM-DD-YYYY")}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol> */}
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div >
                        <div className="relative">
                          <Form.Item
                            name="to_date"
                            label="Till Date:"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            initialValue={moment().endOf('year')}
                          >
                            <DatePicker
                              size='small'
                              className="styleInput w-100"
                              format={"MM-DD-YYYY"}
                              defaultValue={moment().format("MM-DD-YYYY")}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div className="slc">
                        <Form.Item
                          name="customer_id"
                          label="Customer"
                          labelAlign="left"
                          labelCol={{ span: 8 }}
                          wrapperCol={{ span: 16 }}
                          initialValue={"All"}
                        
                        
                        
                        
                        
                        
                        >
                          <Select
                            notFoundContent={
                              customerForms.loading ? (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <Loader
                                    type="Oval"
                                    color="#3c4b58"
                                    height={20}
                                    width={20}
                                  />
                                </div>
                              ) : (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    textAlign: "center",
                                  }}
                                >
                                  No Data
                                </div>
                              )
                            }
                            className="styleInput w-100"
                            autoComplete={"nope"}
                            allowClear
                            size="small"
                            showSearch
                            optionFilterProp="children"
                            loading={customerForms.loading}
                            
                            defaultValue={form.getFieldValue().customer}
                            placeholder="Select Customer"
                            onSearch={(value) => {
                              optimizedFn(value);
                            }}
                          >
                            <Option value="All">All</Option>
                            {customerForms?.payload?.data.map(
                              (item) => {
                                return (
                                  <Option
                                    defaultValue={"All"}
                                    key={item.customer_id}
                                    value={item.customer_id}
                                  >
                                    {" "}
                                    {`${item.customer_code} - ${item.customer_name}`}
                                  </Option>
                                );
                              }
                            )}
                          </Select>
                          {/* <Select
                            showSearch
                            optionFilterProp="children"
                            className="styleInput w-100"
                            loading={customerForms.loading}
                            size="small"
                            onSearch={(value) => {
                              optimizedFn(value);
                            }}
                            ref={focusInput}
                            value={customer}
                          >
                            {customerForms?.payload?.data.map((item) => {
                              return (
                                <Option
                                  key={item.customer_id}
                                  value={item.customer_id}
                                >
                                  {" "}
                                  {`${item.customer_code} ${item.customer_name}`}
                                </Option>
                              );
                            })}
                          </Select> */}
                        </Form.Item>
                      </div>
                    </Bcol>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div className="slc">
                        <Form.Item
                          name="terms"
                          label="Terms"
                          labelAlign="left"
                          labelCol={{ span: 8 }}
                          wrapperCol={{ span: 16 }}
                          initialValue={"All"}
                        >
                          <Select
                            size='small'
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, options) => options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            className="styleInput w-100"
                            defaultValue={form.getFieldValue().terms}
                            filterSort={(optionA, optionB) =>
                              optionB?.value!==null?
                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()):''
                          }
                          >
                            <Option value={null}>All</Option>
                            {
                              paymentterm?.payload?.data.map((val) => {
                                return (
                                  <Option value={val.term_id}>{val.term_name}</Option>
                                )
                              })
                            }

                          </Select>
                        </Form.Item>
                      </div>
                    </Bcol>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div className="slc">
                        <Form.Item
                          label="Payment Class"
                          name="paymentClass"
                          labelAlign="left"
                          labelCol={{ span: 8 }}
                          wrapperCol={{ span: 16 }}
                          initialValue={"All"}
                        >
                          <Select
                            size="small"
                            autoComplete={"nope"}
                            className="styleInput w-100"
                            filterOption={(input, option) => option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0}
                            showSearch
                            allowClear
                            loading={factor.loading}
                          >
                            {factor?.payload?.data.map(
                              (item) => {
                                return (
                                  <Select.Option
                                    key={item.factor_id}
                                    
                                    value={item.factor_id}
                                  >
                                    {item.factor_name}
                                  </Select.Option>
                                );
                              }
                            )}
                          </Select>
                        </Form.Item>
                      </div>
                    </Bcol>
                  </Brow>
                </div>
              </Form>
              <Button
                htmlType="submit"
                type="primary"
                className="submit-btn-btm "
                onClick={onFinish}
              >
                Submit
              </Button>
            </div>
          </div>
        )}
      </div>
      <div style={{ marginTop: 10 }}>
        {
          loading ? (
            <div className=" report-wrapper-main d-flex align-items-center justify-content-center spin-style mt-3">
              <Spin size='large' />
            </div>
          ) : (
            dscoInvoiceList.length > 0 ? (
              <Table
                title={() =>
                  <>
                    <div style={{ display: "flex", justifyContent: "left" }}>
                      <div className="print_icon printing_class" >
                        <CSVLink
                          filename={"A/R-Aging-Summary.csv"}
                          data={excelData}
                          onClick={() => {
                          }}
                        >
                          <input
                            type="image"
                            name="ImgCSV"
                            alt="Export to CSV"
                            id="ImgCSV"
                            title="Export to CSV"
                            src={csv}
                            
                            
                            style={{ borderWidth: "0px", height: "24px", float: "inline-end", margin: "12px" }}
                          />
                        </CSVLink>
                      </div>
                      <div className="print_icon printing_class" >
                        <input
                          type="image"
                          name="ImgExcel"
                          alt="Export to Excel"
                          id="ImgExcel"
                          title="Export to Excel"
                          src={xlsx}
                          onClick={exportToExcel}
                          
                          style={{ borderWidth: "0px", height: "24px", float: "inline-end", margin: "12px" }}
                        />
                      </div>
                      <div className="print_icon printing_class" >
                        <input
                          type="image"
                          name="ImgPDF"
                          alt="Export to PDF"
                          id="ImgPDF"
                          title="Export to PDF"
                          src={pdf}
                          onClick={generatePDF}
                          
                          style={{ borderWidth: "0px", height: "24px", float: "inline-end", margin: "12px" }}
                        />
                        {/* <button onClick={() => generatePDF()}>Generate PDF</button> */}
                      </div>
                    </div>
                  </>
                }
                ref={tableRef}
                size="small"
                bordered={false}
                type="company"
                perPage={15}
                scroll={{
                  x: 'calc(150px + 90%)',
                  y: 450,
                }}
                
                dataSource={dscoInvoiceList}
                columns={columns}
                loading={arAgingSummary.loading}
                pagination={{
                  defaultCurrent: 1,
                  defaultPageSize: 100,
                  pageSizeOptions: [100, 500, 1000, 2000, 2500, 3000]
                }}
                summary={(pageData) => {
                  return (
                    <>
                      <Table.Summary.Row>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <p style={{ textAlign: "right" }} className="totalquantity">
                            $ {current.toLocaleString("en-US")}
                          </p>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <p style={{ textAlign: "right" }} className="totalquantity">
                            $ {onetoThirty.toLocaleString("en-US")}
                          </p>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <p style={{ textAlign: "right" }} className="totalquantity">
                            $ {toSixty.toLocaleString("en-US")}
                          </p>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <p style={{ textAlign: "right" }} className="totalquantity">
                            $ {toNinety.toLocaleString("en-US")}
                          </p>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <p style={{ textAlign: "right" }} className="totalquantity">
                            $ {greaterNinety.toLocaleString("en-US")}
                          </p>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <p style={{ textAlign: "right" }} className="totalquantity">
                            $ {total.toLocaleString("en-US")}
                          </p>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <p style={{ textAlign: "right" }} className="totalquantity">
                            $ {availableCredit.toLocaleString("en-US")}
                          </p>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <p style={{ textAlign: "right" }} className="totalquantity">
                            $ {net.toLocaleString("en-US")}
                          </p>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </>
                  );
                }}
              />
            ) : <div
              style={{
                backgroundColor: "#CCCCCC",
                borderWidth: "1pt",
                borderStyle: "Dashed",
                fontWeight: "bold",
                height: "25px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              No record(s)
            </div>
          )
        }
      </div>
    </div>
  )
}

export default ArAgingSummary;
