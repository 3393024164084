
import {

    GET_CREDIT_MEMO_LINE_REQUEST, GET_CREDIT_MEMO_LINE_SUCCESS, GET_CREDIT_MEMO_LINE_ERROR,
    GET_ONE_CREDIT_MEMO_LINE_REQUEST,
    GET_ONE_CREDIT_MEMO_LINE_SUCCESS,
    GET_ONE_CREDIT_MEMO_LINE_ERROR,
    ADD_CREDIT_MEMO_LINE_REQUEST,
    ADD_CREDIT_MEMO_LINE_SUCCESS,
    ADD_CREDIT_MEMO_LINE_ERROR,
    UPDATE_CREDIT_MEMO_LINE_REQUEST,
    UPDATE_CREDIT_MEMO_LINE_SUCCESS,
    UPDATE_CREDIT_MEMO_LINE_ERROR,
    DELETE_CREDIT_MEMO_LINE_REQUEST,
    DELETE_CREDIT_MEMO_LINE_SUCCESS,
    DELETE_CREDIT_MEMO_LINE_ERROR

} from "./types";
import { BASEURL } from "../../constant/config.js";
const axios = require("axios");

export const addCreditMemoLine = (token, rest, journalVoucherId, id) => (dispatch) => {
    
    let data = rest;


    
    
    
    
    
    
    
    
    

    
    
    dispatch({ type: ADD_CREDIT_MEMO_LINE_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}create/arcreditmemoline`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { data }
    })
        .then((response) => {
            return dispatch({
                type: ADD_CREDIT_MEMO_LINE_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) => {
            return dispatch({
                type: ADD_CREDIT_MEMO_LINE_ERROR,
                payload: error.response,
            });
        });
};
export const addCreditMemoLines = (token, data) => (dispatch) => {
    data = data.map((item) => {
        if (item.amount === '') {
            item.amount = 0
        }
        if (item.customer_id === '') {
            item.customer_id = null
        }
        return item;
    }
    )

    dispatch({ type: ADD_CREDIT_MEMO_LINE_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}create/PayBillDirectPaymentExpense`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { data }
    })
        .then((response) => {
            return dispatch({
                type: ADD_CREDIT_MEMO_LINE_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) => {
            return dispatch({
                type: ADD_CREDIT_MEMO_LINE_ERROR,
                payload: error.response,
            });
        });
};

export const getOneCreditMemoLine = (token, headerId) => (dispatch) => {

    dispatch({ type: GET_ONE_CREDIT_MEMO_LINE_REQUEST });
    return axios({
        method: "GET",
        url: `${BASEURL}getOne/arcreditmemoline/${headerId}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
    })
        .then((response) => {
            return dispatch({
                type: GET_ONE_CREDIT_MEMO_LINE_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) => {
            return dispatch({
                type: GET_ONE_CREDIT_MEMO_LINE_ERROR,
                payload: error.response,
            });
        });
};

export const getCreditMemoLine = (token, customerID) => (dispatch) => {
    dispatch({ type: GET_CREDIT_MEMO_LINE_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/PayBillDirectPaymentExpense`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
    })
        .then((response) => {
            return dispatch({
                type: GET_CREDIT_MEMO_LINE_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) => {
            return dispatch({
                type: GET_CREDIT_MEMO_LINE_ERROR,
                payload: error.response,
            });
        });
};


export const updateCreditMemoLine = (token, rest, journalVoucherId) => (dispatch) => {


    dispatch({ type: UPDATE_CREDIT_MEMO_LINE_REQUEST });
    return axios({
        method: "PUT",
        url: `${BASEURL}update/arcreditmemoline/${journalVoucherId}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { dataLine: rest },

    })
        .then((response) => {
            return dispatch({
                type: UPDATE_CREDIT_MEMO_LINE_SUCCESS,
                payload: response?.data,
            });
        })
        .catch((error) => {
            return dispatch({
                type: UPDATE_CREDIT_MEMO_LINE_ERROR,
                payload: error.response,
            });
        });
};


export const deleteCreditMemoLine = (token, Lineidd, data) => (dispatch) => {

    dispatch({ type: DELETE_CREDIT_MEMO_LINE_REQUEST });
    return axios({
        method: "DELETE",
        url: `${BASEURL}delete/arcreditmemoline/${Lineidd}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { data: data }
    })
        .then((response) => {
            return dispatch({
                type: DELETE_CREDIT_MEMO_LINE_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) => {
            return dispatch({
                type: DELETE_CREDIT_MEMO_LINE_ERROR,
                payload: error.response,
            });
        });
};