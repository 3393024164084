import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getOneDefaultCompany } from "../../redux/actions/defaultCompany";
import { getCompanyAccess } from "../../redux/actions/companyAccess";

import { addScmBrand, getAllScmBrand, deleteScmBrand, updateScmBrand } from "../../redux/actions/scmBrand.action";
import { getRights } from "../../redux/actions/userRights.action";
import { getLog } from "../../redux/actions/log.action";

import CrudButtons from "../../components/CrudButtons/CrudButtons";
import PageHeader from "../../components/PageHeader/PageHeader";

import { Row, Col, Form, Input, Checkbox, Tabs, Divider } from "antd";
import { logColumns, ScmBrandColumn } from "../../utils/cols";
import Tables from "../../components/Table/Tables";
import { ReadCookie } from "../../utils/readCookie";
import notification from "../../utils/notification";
import "../Style/style.css";

const { TabPane } = Tabs;
export default function ScmBrand({ token }) {
	const menuId = ReadCookie("menuId");
	const dCompanyId = ReadCookie("defaultCompany");
	const scmBrandNameInput = useRef(null);
	const [form] = Form.useForm();
	const [scmBrandTypeObj, setscmBrandTypeObj] = useState(null);
	const dispatch = useDispatch();
	const { scmBrand, log, userRights } = useSelector((state) => state);
	const [minimize, setMinimize] = useState(false);
	const [check, setCheck] = useState(false);
	const [scmBrandTypeName, setscmBrandTypeName] = useState("");
	const [disable, setDisable] = useState(false);
	const [formState, setFormState] = useState("view");

	useEffect(() => {
		dispatch(getAllScmBrand(token, menuId));
		dispatch(getLog(token, { scm_brand: "scm_brand" }));
	}, [token]);

	useLayoutEffect(() => {
		dispatch(getRights(token, menuId)).then((res) => {
			if (res.type === "GET_USER_RIGHTS_SUCCESS") {
				if (!res?.payload.can_add) {
					setFormState("table");
				}
			}
		});
	}, []);

	useEffect(() => {
		if (scmBrandTypeObj) {
			setFormState("view");
			form.setFieldsValue({
				id: scmBrandTypeObj.brand_id,
				scmBTName: scmBrandTypeObj.brand_name,
				active: scmBrandTypeObj.is_active,
			});
			setscmBrandTypeName(scmBrandTypeObj.brand_name);
			setCheck(scmBrandTypeObj.is_active);
			dispatch(getCompanyAccess(token, scmBrandTypeObj.brand_id, menuId));
		}
	}, [scmBrandTypeObj]);

	useEffect(() => {
		if (scmBrandTypeName === "") {
			setDisable(true);
		} else {
			setDisable(false);
		}
	}, [scmBrandTypeName]);

	const formSave = () => {
		if (formState === "add") {
			dispatch(addScmBrand(token, { scmBrandTypeName, check }, menuId)).then((res) => {
				
				if (res.type === "ADD_SCM_BRAND_ERROR") {
					notification("error", res?.payload?.request?.statusText, res?.payload?.data?.data);
				}
				if (res.type === "ADD_SCM_BRAND_SUCCESS") {
					notification("success", "Scm Brand created", "Scm Brand created successfully!");
					form.setFieldsValue({
						scmBTName: "",
					});
					setFormState("");
					
					setscmBrandTypeName("");
					setscmBrandTypeObj(null);
				}
				dispatch(getAllScmBrand(token, menuId));
			});
		}

		if (formState === "edit") {
			dispatch(updateScmBrand(token, { scmBrandTypeName, check }, scmBrandTypeObj.brand_id)).then((res) => {
				if (res.type === "UPDATE_SCM_BRAND_ERROR") {
					notification("error", "Update", "Something went wrong");
				}
				if (res.type === "UPDATE_SCM_BRAND_SUCCESS") {
					notification("success", "Scm Brand update", "Record updated successfully");
				}
				dispatch(getAllScmBrand(token, menuId));
			});
		}
	};

	const handleDelete = () => {
		if (scmBrandTypeObj) {
			dispatch(deleteScmBrand(token, scmBrandTypeObj.brand_id)).then((res) => {
				if (res.type === "DELETE_SCM_BRAND_SUCCESS") {
					notification("success", "SCM Brand", "Brand deleted successfully!");
				}
				if (res.type === "DELETE_SCM_BRAND_ERROR") {
					notification("error", res?.payload?.request?.statusText, res?.payload?.data?.data);
				}
				form.setFieldsValue({
					scmBTName: "",
				});
				setCheck(false);
				setscmBrandTypeName("");
				setscmBrandTypeObj(null);
				setFormState("");
				dispatch(getAllScmBrand(token, menuId));
			});
		}
	};

	useEffect(() => {
		if (formState === "add") {
			dispatch(getOneDefaultCompany(token, dCompanyId));
			scmBrandNameInput.current.focus();
			form.setFieldsValue({
				scmBTName: "",
			});
			setCheck(true);
			setscmBrandTypeName("");
			setscmBrandTypeObj(null);
		}
	}, [formState]);

	const filterReset = () => {
		setFormState("view");
	};

	return (
		<div className="styleContainer">
			<div className="styleBody h-100">
				<PageHeader title={"Scm Brand"} minimize={setMinimize} check={minimize} />
				{!minimize && (
					<div className="bodyStyle">
						<div className="crudDiv">
							<CrudButtons rights={userRights?.payload} reset={filterReset} formType={formState} save={formSave} disable={disable} formState={setFormState} onDelete={handleDelete} />
						</div>
						<div className="styleContent">
							<Row className="tables-main-row-new">
								<Col xs={24} sm={24} md={10} lg={8} xl={6}>
									<div className="styleTable">
										<Tables
											type="ScmBrandType"
											scmBrandTypeObj={setscmBrandTypeObj}
											scrollX={"20rem"}
											scroll={602}
											perPage={25}
											source={scmBrand?.payload?.data}
											col={ScmBrandColumn}
											paginate
											load={scmBrand.loading}
										/>
									</div>
								</Col>
								<Col xs={24} sm={24} md={14} lg={16} xl={18}>
									<div className="styleForm">
										<Form form={form} name="basic" labelCol={{ span: 8 }}>
											<div className="customRow styleFormUpper ">
												{scmBrandTypeObj !== null && !(formState === "add") && (
													<div className="customCols slc">
														<Form.Item label="ID" labelCol={{ span: 10 }} wrapperCol={{ span: 14 }} name="id" initialValue={form.getFieldValue().id}>
															<Input className="styleInput id-field-style" readOnly />
														</Form.Item>
													</div>
												)}
												<div className="customCols slc">
													<Form.Item label="Brand Name" name="scmBTName" labelCol={{ span: 10 }} wrapperCol={{ span: 14 }} initialValue={form.getFieldValue().scmBTName}>
														<Input
															size="small"
															disabled={!(formState === "add" || formState === "edit")}
															ref={scmBrandNameInput}
															onChange={(e) => setscmBrandTypeName(e.target.value)}
															className="styleInput"
														/>
													</Form.Item>
												</div>
												<div className="customCols slc">
													<Form.Item label="Active" name="active" labelCol={{ span: 10 }} wrapperCol={{ span: 14 }}>
														<Checkbox disabled={!(formState === "add" || formState === "edit")} checked={check} onChange={(e) => setCheck(e.target.checked)} />
													</Form.Item>
												</div>
											</div>
										</Form>
										<Divider />
										<div className="styleTab">
											<Tabs type="card" tabBarGutter={2} defaultActiveKey="logs" centered>
												<TabPane tab="Log Info" key="logs">
													<div className="tabs">
														<Tables type="company" scrollX={"50rem"} scroll={360} perPage={15} source={log?.payload?.data} col={logColumns} load={log.loading} paginate />
													</div>
												</TabPane>
											</Tabs>
										</div>
									</div>
								</Col>
							</Row>
						</div>
					</div>
				)}
			</div>
		</div>
	);
}
