import {
    GET_FREIGHT_TERM_REQUEST,
    GET_FREIGHT_TERM_SUCCESS,
    GET_FREIGHT_TERM_ERROR,
    ADD_FREIGHT_TERM_ERROR,
    ADD_FREIGHT_TERM_REQUEST,
    ADD_FREIGHT_TERM_SUCCESS,
    UPDATE_FREIGHT_TERM_ERROR,
    UPDATE_FREIGHT_TERM_REQUEST,
    UPDATE_FREIGHT_TERM_SUCCESS,
    DELETE_FREIGHT_TERM_ERROR,
    DELETE_FREIGHT_TERM_REQUEST,
    DELETE_FREIGHT_TERM_SUCCESS
  } from "../actions/types";
  
  export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
      case GET_FREIGHT_TERM_REQUEST:
        return {
          loading: true,
        };
      case GET_FREIGHT_TERM_SUCCESS:
        return {
          ...state,
          loading: false,
          payload,
        };
      case GET_FREIGHT_TERM_ERROR:
        return {
          ...state,
          loading: false,
          payload,
        };
        case ADD_FREIGHT_TERM_REQUEST:
          return {
            loading: true,
          };
        case ADD_FREIGHT_TERM_SUCCESS:
          return {
            ...state,
            loading: false,
            payload: [payload],
          };
        case ADD_FREIGHT_TERM_ERROR:
          return {
            ...state,
            loading: false
          };
        case UPDATE_FREIGHT_TERM_REQUEST:
          return {
            loading: true,
          };
        case UPDATE_FREIGHT_TERM_SUCCESS:
          return {
            ...state,
            loading: false,
            payload: [payload],
          };
        case UPDATE_FREIGHT_TERM_ERROR:
          return {
            ...state,
            loading: false
          };
        case DELETE_FREIGHT_TERM_REQUEST:
          return {
            loading: true,
          };
        case DELETE_FREIGHT_TERM_SUCCESS:
          return {
            ...state,
            loading: false,
            payload: [payload],
          };
        case DELETE_FREIGHT_TERM_ERROR:
          return {
            ...state,
            loading: false
          };
    
    

      default:
        return state;
    }
  }
  