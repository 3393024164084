import {
    GET_INSEAMLABEL_ERROR, GET_INSEAMLABEL_REQUEST, GET_INSEAMLABEL_SUCCESS,
    ADD_INSEAMLABEL_ERROR, ADD_INSEAMLABEL_REQUEST, ADD_INSEAMLABEL_SUCCESS,
    DELETE_INSEAMLABEL_REQUEST, DELETE_INSEAMLABEL_SUCCESS, DELETE_INSEAMLABEL_ERROR,
    UPDATE_INSEAMLABEL_ERROR, UPDATE_INSEAMLABEL_REQUEST, UPDATE_INSEAMLABEL_SUCCESS

} from "../actions/types"
export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case GET_INSEAMLABEL_REQUEST:
            return {
                loading: true,
            };
        case GET_INSEAMLABEL_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_INSEAMLABEL_ERROR:
            return {
                ...state,
                loading: false
            };
        case ADD_INSEAMLABEL_REQUEST:
            return {
                loading: true,
            };
        case ADD_INSEAMLABEL_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case ADD_INSEAMLABEL_ERROR:
            return {
                ...state,
                loading: false
            };
        case DELETE_INSEAMLABEL_REQUEST:
            return {
                loading: true,
            };
        case DELETE_INSEAMLABEL_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case DELETE_INSEAMLABEL_ERROR:
            return {
                ...state,
                loading: false
            };
        case UPDATE_INSEAMLABEL_REQUEST:
            return {
                loading: true,
            };
        case UPDATE_INSEAMLABEL_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case UPDATE_INSEAMLABEL_ERROR:
            return {
                ...state,
                loading: false
            };

        default:
            return state;
    }
}