import {
    GET_ALL_FISCALYEAR_PERIOD_REQUEST, GET_ALL_FISCALYEAR_PERIOD_ERROR, GET_ALL_FISCALYEAR_PERIOD_SUCCESS, GET_ONE_FISCALYEAR_PERIOD_REQUEST, GET_ONE_FISCALYEAR_PERIOD_ERROR, GET_ONE_FISCALYEAR_PERIOD_SUCCESS,
    ADD_FISCALYEAR_PERIOD_REQUEST, ADD_FISCALYEAR_PERIOD_ERROR, ADD_FISCALYEAR_PERIOD_SUCCESS, UPDATE_FISCALYEAR_PERIOD_REQUEST, UPDATE_FISCALYEAR_PERIOD_ERROR, UPDATE_FISCALYEAR_PERIOD_SUCCESS, DELETE_FISCALYEAR_PERIOD_REQUEST, DELETE_FISCALYEAR_PERIOD_ERROR, DELETE_FISCALYEAR_PERIOD_SUCCESS
} from "./types"
import { BASEURL } from "../../constant/config.js";

const axios = require("axios");


export const getAllFiscalYear = (token) => (dispatch) => {

    dispatch({ type: GET_ALL_FISCALYEAR_PERIOD_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/fiscalYearPeriod`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },

    }).then((response) => {
        return dispatch({ type: GET_ALL_FISCALYEAR_PERIOD_SUCCESS, payload: response.data })
    }).catch((error) => {
        return dispatch({ type: GET_ALL_FISCALYEAR_PERIOD_ERROR, payload: error.response })
    })
}
export const getOneFiscalYear = (token, id) => (dispatch) => {

    dispatch({ type: GET_ONE_FISCALYEAR_PERIOD_REQUEST });
    return axios({
        method: "GET",
        url: `${BASEURL}getOne/fiscalYearPeriod/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },

    }).then((response) => {
        return dispatch({ type: GET_ONE_FISCALYEAR_PERIOD_SUCCESS, payload: response.data })
    }).catch((error) => {
        return dispatch({ type: GET_ONE_FISCALYEAR_PERIOD_ERROR, payload: error.response })
    })
}

export const createFiscalYearPeriod = (token, data, ID) => (dispatch) => {
    dispatch({ type: ADD_FISCALYEAR_PERIOD_REQUEST });
    let dataPayload = data.map((item) => {
        return { ...item, fiscal_year_id: ID }
    })
    return axios({
        method: "POST",
        url: `${BASEURL}create/fiscalYearPeriod`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { "data": dataPayload },
    })
        .then((response) => {
            return dispatch({ type: ADD_FISCALYEAR_PERIOD_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: ADD_FISCALYEAR_PERIOD_ERROR,
                payload: error.response,
            });
        });
};

export const updateFiscalYearPeriod = (token, data, id) => (dispatch) => {

    data.map((val) => {
        delete val.last_updated_by;
        delete val.last_updated_date;
    })
    dispatch({ type: UPDATE_FISCALYEAR_PERIOD_REQUEST });
    return axios({
        method: "PUT",
        url: `${BASEURL}update/fiscalYearPeriod/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { data },
    })
        .then((response) => {
            return dispatch({ type: UPDATE_FISCALYEAR_PERIOD_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: UPDATE_FISCALYEAR_PERIOD_ERROR,
                payload: error.response,
            });
        });
};

export const deleteFiscalYearPeriod = (token, id) => (dispatch) => {
    dispatch({ type: DELETE_FISCALYEAR_PERIOD_REQUEST });
    return axios({
        method: "DELETE",
        url: `${BASEURL}delete/fiscalYearPeriod/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
    })
        .then((response) => {
            return dispatch({ type: DELETE_FISCALYEAR_PERIOD_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: DELETE_FISCALYEAR_PERIOD_ERROR,
                payload: error.response,
            });
        });
};
