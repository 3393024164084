import {
	ADD_INVENTORY_STOCK_ADJUSTMENT_LINE_REQUEST,
	ADD_INVENTORY_STOCK_ADJUSTMENT_LINE_SUCCESS,
	ADD_INVENTORY_STOCK_ADJUSTMENT_LINE_ERROR,
	
} from "../actions/types";


export default function (state = [], action) {
	const { type, payload } = action;
	switch (type) {

		case ADD_INVENTORY_STOCK_ADJUSTMENT_LINE_REQUEST:
			return {
				loading: true,
			};
		case ADD_INVENTORY_STOCK_ADJUSTMENT_LINE_SUCCESS:
			return {
				...state,
				loading: false,
				payload: payload,
			};
		case ADD_INVENTORY_STOCK_ADJUSTMENT_LINE_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};
		
		default:
			return state;
	}
}
