import { GET_RETURNTYPE_REQUEST, GET_RETURNTYPE_SUCCESS, GET_RETURNTYPE_ERROR, ADD_RETURNTYPE_REQUEST, ADD_RETURNTYPE_ERROR, ADD_RETURNTYPE_SUCCESS, UPDATE_RETURNTYPE_REQUEST, UPDATE_RETURNTYPE_SUCCESS, UPDATE_RETURNTYPE_ERROR, DELETE_RETURNTYPE_ERROR, DELETE_RETURNTYPE_REQUEST, DELETE_RETURNTYPE_SUCCESS } from "./types"
import { BASEURL } from "../../constant/config.js";

const axios = require("axios");

export const getReturnType = (token, menu_id) => (dispatch) => {
    dispatch({ type: GET_RETURNTYPE_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/adminReturnType`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { menu_id }
    }).then((response) => {
        return dispatch({ type: GET_RETURNTYPE_SUCCESS, payload: response.data })
    }).catch((error) => {
        return dispatch({ type: GET_RETURNTYPE_ERROR, payload: error.response })
    })
}

export const addReturnType = (token, data, menu_id) => (dispatch) => {

    dispatch({ type: ADD_RETURNTYPE_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}create/adminReturnType`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { return_type_name: data.returnTypeName, is_active: data.check, menu_id },
    })
        .then((response) => {
            // 
            return dispatch({ type: ADD_RETURNTYPE_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: ADD_RETURNTYPE_ERROR,
                payload: error.response,
            });
        });
};

export const updateReturnType = (token, data, id) => (dispatch) => {
    dispatch({ type: UPDATE_RETURNTYPE_REQUEST });
    return axios({
        method: "PUT",
        url: `${BASEURL}update/adminReturnType/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { return_type_name: data.returnTypeName, is_active: data.check },
    })
        .then((response) => {

            return dispatch({ type: UPDATE_RETURNTYPE_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: UPDATE_RETURNTYPE_ERROR,
                payload: error.response,
            });
        });
};

export const deleteReturnType = (token, id) => (dispatch) => {
    dispatch({ type: DELETE_RETURNTYPE_REQUEST });
    return axios({
        method: "DELETE",
        url: `${BASEURL}delete/adminReturnType/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
    })
        .then((response) => {

            return dispatch({ type: DELETE_RETURNTYPE_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: DELETE_RETURNTYPE_ERROR,
                payload: error.response,
            });
        });
};
