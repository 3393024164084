import {

  GET_SALESPERSON_REQUEST, GET_SALESPERSON_SUCCESS, GET_SALESPERSON_ERROR, GET_ALL_SALEPERSON_ERROR, GET_ALL_SALEPERSON_REQUEST, GET_ALL_SALEPERSON_SUCCESS,

} from "../actions/types";

export default function (state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case GET_SALESPERSON_REQUEST:
      return {
        loading: true,
      };
    case GET_SALESPERSON_SUCCESS:
      return {
        ...state,
        loading: false,
        payload,
      };
    case GET_SALESPERSON_ERROR:
      return {
        ...state,
        loading: false,
        payload,
      };
    case GET_ALL_SALEPERSON_REQUEST:
      return {
        loading: true,
      };
    case GET_ALL_SALEPERSON_SUCCESS:
      return {
        ...state,
        loading: false,
        payload,
      };
    case GET_ALL_SALEPERSON_ERROR:
      return {
        ...state,
        loading: false,
        payload,
      };


    default:
      return state;
  }
}
