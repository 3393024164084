import {
    GET_PRODUCT_REPORT_REQUEST,
    GET_PRODUCT_REPORT_SUCCES,
    GET_PRODUCT_REPORT_ERROR,

} from "./types"
import axios from "axios";
import { BASEURL } from "../../constant/config.js"


export const getProductReport = (token, values) => (dispatch) => {
    dispatch({ type: GET_PRODUCT_REPORT_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/funGetViewProduct`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { ...values },
    })
        .then((response) => {
            return dispatch({
                type: GET_PRODUCT_REPORT_SUCCES,
                payload: response.data,
            });
        })
        .catch((error) => {
            dispatch({
                type: GET_PRODUCT_REPORT_ERROR,
                payload: [],
            });
        });
};

