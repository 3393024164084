import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Form, Select, DatePicker, Spin } from "antd";
import { Link, useHistory, useParams } from "react-router-dom";
import { ReadCookie } from "../../utils/readCookie";
import numeral from "numeral";
import moment from "moment";
import logo from "../../assets/images/logo.png";
import xlsx from "../../assets/images/ico_xls_sm.gif";
import "../ProducList/productList.css";
import { TabTitle } from "../../utils/GeneralFunction.js";
import { getBalanceSheet } from "../../redux/actions/incomeStatement.action.js";
import { useDownloadExcel } from "react-export-table-to-excel";
const BalanceSheet = ({ token }) => {
  TabTitle("Balance Sheet")
  const { saleTargetReport, incomeStatement, balanceSheet } = useSelector((state) => state);
  const menuId = ReadCookie("menuId");
  const userName = ReadCookie("name");
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { Option } = Select;
  const history = useHistory();
  var date = moment();

  const [dateTypeId, setDateTypeId] = useState("mte");
  const [monthToId, setMonthToId] = useState(date.format("M"));
  const [yearToId, setYearToId] = useState(date.format("Y"));
  const [formState, setFormState] = useState({});
  const [loading, setLoading] = useState(false);
  const { submited } = useParams();

  const tableRef = useRef(null);

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Balance Sheet",
    sheet: "Balance Sheet",
  });

  const handlePrint = () => {
    window.print();
  };


  useEffect(() => {
    let yearto = yearToId;
    let monthto = monthToId;
    let currentYearEndDate = moment().endOf("year").format("MM/DD/YYYY");
    if (dateTypeId === "mte") {
      currentYearEndDate = monthto + "/" + moment(`${yearto} / ${monthto}`, "YYYY/MM").daysInMonth() + "/" + yearto;
    }
    const newState = {
      cy_end_date: currentYearEndDate,
    };
    setFormState(newState);
  }, [dateTypeId, monthToId, yearToId]);


  const onFinish = () => {
    setLoading(true);
    dispatch(getBalanceSheet(token, formState)).then((res) => {
      if (res?.type === "GET_SALE_TARGET_REPORT_SUCCESS") {
        setLoading(false);
      }
    });
  };


  let result =
    balanceSheet &&
    balanceSheet?.payload?.data?.reduce(function (r, a) {
      r[a.head_account + a.sub_account + a.accttype] =
        r[a.head_account + a.sub_account + a.accttype] || [];
      r[a.head_account + a.sub_account + a.accttype].push(a);
      return r;
    }, Object.create(null));

  const groupAccountWise = result && Object.values(result);

  

  const Total = groupAccountWise && groupAccountWise.reduce((acc, item, index) => acc + parseFloat(item.balance), 0)

  



  let balance =
    balanceSheet &&
    balanceSheet?.payload?.data?.reduce(function (r, a) {
      r[a.head_account] =
        r[a.head_account] || [];
      r[a.head_account].push(a);
      return r;
    }, Object.create(null));

  const grouped = balance && Object.values(balance);


  let grouped_Sub_Account =
    balanceSheet &&
    balanceSheet?.payload?.data?.reduce(function (r, a) {
      r[a.sub_account] =
        r[+ a.sub_account] || [];
      r[a.sub_account].push(a);
      return r;
    }, Object.create(null));
  const groupedSubAccount = grouped_Sub_Account && Object.values(grouped_Sub_Account);


  let arrSUM = [];
  if (grouped) {
    for (const group of grouped && grouped) {
      const balance = group && group.reduce((acc, obj) => acc + parseFloat(obj.balance), 0);

      arrSUM.push(balance)
      
    };
  }


  const totalBalances = [];
  if (groupAccountWise) {
    for (const subList of groupAccountWise) {
      let totalBalance = 0.0; 

      for (const account of subList) {
        totalBalance += parseFloat(account.balance);
      }
      
      totalBalances.push(totalBalance);
    }
  }


  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <div className="report-wrapper-main header_class">
              <div className="row justify-content-between pb-0">
                <div className="col-3 d-flex align-items-start  box-space">
                  <div className="logobrand">
                    <img id="ImgLogo" alt="logo" src={logo} />
                  </div>
                </div>
                <div className="col-3 box-space right-content-header text-right">
                  <div className=" d-flex justify-content-end">
                    <div className=" d-flex justify-content-end printing_class">
                      <div className="print_icon printing_class">
                        <i
                          id="Image1"
                          title="Print"
                          alt="Print"
                          className="fa-solid fa-print printing_class"
                          onClick={handlePrint}
                        ></i>
                      </div>
                      <div className="print_icon printing_class">
                        {grouped ? (
                          <input
                            type="image"
                            name="ImgExcel"
                            alt="Export to excel"
                            id="ImgExcel"
                            title="Export to excel"
                            src={xlsx}
                            onClick={onDownload}
                            style={{
                              borderWidth: "0px",
                              height: "14px",
                              cursor: "pointer",
                            }}
                          />
                        ) : (
                          <input
                            type="image"
                            name="ImgExcel"
                            alt="Export to excel"
                            id="ImgExcel"
                            title="Export to excel"
                            src={xlsx}
                            style={{ borderWidth: "0px", height: "14px" }}
                          />
                        )}
                      </div>
                    </div>
                    <span style={{ fontWeight: "bolder" }}>
                      Balance Sheet
                      <br />
                      Rpt # 006
                      <br />
                      Login as:
                      <span id="lblSalesPerson" style={{ fontWeight: "bold" }}>
                        {" "}
                        {userName}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              {
                <div className="row pb-2">
                  <div className="col">
                    <Form
                      labelCol={{ span: 6 }}
                      wrapperCol={{ span: 18 }}
                      layout="horizontal"
                      form={form}
                      name="control-hooks"
                      onFinish={onFinish}
                    >
                      <div className="main_form row">
                        <div className=" col-6">
                          <div className=" d-flex flex-row align-items-baseline">
                            <label htmlFor="gender_id" className="labels width30">
                              Year To:
                            </label>
                            <div style={{ marginBottom: "2px" }} className=" w-100">

                              <DatePicker
                                size="small"
                                className="filter-fields full_date"
                                value={moment(yearToId)}
                                
                                onChange={(e) =>
                                  setYearToId(moment(e).format("YYYY"))
                                }
                                picker="year"
                              />
                            </div>
                          </div>
                        </div>
                        <div className=" col-6">
                          <div className=" d-flex flex-row align-items-baseline">
                            <label className="labels width30">Month To:</label>
                            <div style={{ marginBottom: "2px" }} className=" w-100">
                              <DatePicker
                                size="small"
                                className="filter-fields full_date"
                                value={moment(monthToId)}
                                
                                onChange={(e) =>
                                  setMonthToId(moment(e).format("MM"))
                                }
                                picker="month"
                                format={"MM"}
                                mode="month"
                              />
                            </div>
                          </div>
                          <div style={{ marginTop: "0px", display: "flex", justifyContent: "end" }} >
                            {/* <label className=" no_visibility">none</label> */}
                            <Button type="primary" htmlType="submit" className='submit-btn-btm m-0'
                            
                            >
                              Search
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>}
            </div>
          </div>
        </div>
      </div>
      {
        <div style={{ display: "none" }}>
          <div className="report-box-main" ref={tableRef} >
            {
              grouped ? (
                grouped.length > 0 ? (
                  <>
                    {
                      grouped && (
                        <>
                          <table>
                            <tr >
                              <td style={{ fontSize: "20px", fontWeight: 900, textAlign: "center", width: "700px" }} colSpan={2}>DL1961</td>
                            </tr>
                            <tr>
                              <td style={{ fontSize: "20px", fontWeight: 900, textAlign: "center", width: "700px" }} colSpan={2}>DL1961 : DL1961 (USA)</td>
                            </tr>
                            <tr>
                              <td style={{ fontSize: "20px", fontWeight: 900, textAlign: "center", width: "700px" }} colSpan={2}>Balance Sheet</td>
                            </tr>
                            <tr>
                              <td style={{ fontSize: "20px", fontWeight: 900, textAlign: "center", width: "700px" }} colSpan={2}>End of {moment(monthToId, 'MM').format('MMMM')} {yearToId}</td>
                            </tr>
                          </table>
                          <table className="report-table-main" style={{ fontSize: "10px" }} >
                            <tr className="report-row-head">
                              <td className="report-column-head" style={{ backgroundColor: "#d3d3d3", fontWeight: 900, width: "500px" }}>Financial New</td>
                              <td className="report-column-head" style={{ backgroundColor: "#d3d3d3", fontWeight: 900, textAlign: "right" }}>
                                Amount
                              </td>
                            </tr>
                            {
                              grouped.map((val, idx) => {
                                
                                let arr = [];
                                
                                groupedSubAccount.map((v) => {
                                  return v.filter((value) => {
                                    if (value.head_account === "Assets") {
                                      arr.push({ "Assets": value.sub_account });
                                    }
                                    if (value.head_account === "Liabilities & Equity") {
                                      arr.push({ "Liabilities & Equity": value.sub_account });
                                    }
                                  })
                                })
                                let arrData = [];
                                groupAccountWise.map((v) => {
                                  arrData.push(v[0]);
                                })

                                return (
                                  <div>
                                    <tr>
                                      <td className="report-hd" style={{ fontWeight: 900 }}>{val[0]?.head_account}</td>
                                    </tr>
                                    <div>
                                      {
                                        arr.map((v) => {
                                          
                                          let keys = Object.keys(v);
                                          let values = Object.values(v);
                                          
                                          if (keys.includes(val[0]?.head_account)) {
                                            
                                            return values.map((v) => {
                                              return (
                                                <>
                                                  <tr>
                                                    <td className="report-hd" style={{ padding: "0 16px", fontWeight: 900 }}>{v}</td>
                                                  </tr>
                                                  {
                                                    arrData.map((arr, index) => {
                                                      if (arr.sub_account === v) {
                                                        return (
                                                          <div>
                                                            <tr>
                                                              <td className="report-hd" style={{ padding: "0 30px", fontWeight: 900 }}>{arr?.accttype}</td>
                                                            </tr>
                                                            {
                                                              balanceSheet && balanceSheet?.payload?.data?.map((val, id) => {
                                                                if (val?.accttype === arr?.accttype) {
                                                                  return (
                                                                    <>
                                                                      <tr>
                                                                        <td style={{ padding: "0 50px" }}>
                                                                          {val?.account_display_name}
                                                                        </td>
                                                                        <td className="report-value text-right" style={{ textAlign: "right", fontSize: "10px" }}><span style={{ fontSize: "9px", textAlign: "right" }}>＄</span>{(numeral(val?.balance).format("0,00.00"))}</td>
                                                                      </tr>
                                                                    </>
                                                                  )
                                                                }

                                                              })
                                                            }
                                                            <tr>
                                                              <td style={{ padding: "0 30px", position: "relative" }} >
                                                                <div className="report-hd" style={{ fontWeight: 900 }}>
                                                                  Total {arr?.accttype}
                                                                </div>
                                                              </td>
                                                              <td style={{
                                                                float: "right"
                                                              }} >
                                                                <div className="report-value text-right report-hd"
                                                                  style={{ textAlign: "right", }}>
                                                                  <span style={{ fontSize: "9px", fontWeight: 500 }}>＄</span><span style={{ fontSize: "10px", fontWeight: 900 }}>{numeral(totalBalances[index]).format("0,00.00")}</span>
                                                                </div>
                                                              </td>
                                                            </tr>
                                                          </div>
                                                        )
                                                      }
                                                    })
                                                  }
                                                </>
                                              )
                                            })
                                          }
                                        })
                                      }
                                    </div>
                                    <tr>
                                      <td className="report-hd">
                                        {/* <hr style={{ margin: 2 }} /> */}
                                        <div className="report-hd" style={{ fontWeight: 900 }}>
                                          <div className="report-hd">
                                            Total {val[0]?.head_account}
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        {/* <hr style={{ margin: 2 }} /> */}
                                        <div className="report-hd" style={{ fontWeight: 900, textAlign: "right" }}>
                                          <span style={{ fontSize: "9px", textAlign: "right", fontWeight: 500 }}>＄</span>{numeral(arrSUM[idx]).format("0,00.00")}
                                        </div>
                                      </td>
                                    </tr>
                                  </div>
                                )
                              })
                            }
                          </table>
                        </>
                      )
                    }
                  </>) :
                  (
                    <div className="no-Data">
                      <h1>No Data</h1>
                    </div>
                  )
              )
                : (
                  loading && (
                    <div className="loader" style={{ marginTop: "20px" }}>
                      <Spin size="medium" />
                    </div>
                  )
                )
            }
          </div>
        </div>}
      {
        <div>
          <div className="report-box-main" >
            {
              grouped ? (
                grouped.length > 0 ? (
                  <>
                    {
                      grouped && (
                        <table className="report-table-main" >
                          <tr className="report-row-head">
                            <td className="report-column-head first-head text-right">
                              Amount
                            </td>
                          </tr>

                          {
                            grouped.map((val, idx) => {
                              let arr = [];
                              groupedSubAccount.map((v) => {
                                return v.filter((value) => {
                                  if (value.head_account === "Assets") {
                                    arr.push({ "Assets": value.sub_account });
                                  }
                                  if (value.head_account === "Liabilities & Equity") {
                                    arr.push({ "Liabilities & Equity": value.sub_account });
                                  }
                                })
                              })
                              let arrData = [];
                              groupAccountWise.map((v) => {
                                arrData.push(v[0]);
                              });

                              const sortOrder = ["Current Liabilities", "Other Current Liability", "Long Term Liability", "Equity"];
                              
                              const customSort = (a, b) => {
                                if (a.hasOwnProperty("Liabilities & Equity") && b.hasOwnProperty("Liabilities & Equity")) {
                                  return sortOrder.indexOf(a["Liabilities & Equity"]) - sortOrder.indexOf(b["Liabilities & Equity"]);
                                } else if (a.hasOwnProperty("Assets") && b.hasOwnProperty("Assets")) {
                                  return sortOrder.indexOf(a["Assets"]) - sortOrder.indexOf(b["Assets"]);
                                } else {
                                  return 0; 
                                }
                              };
                              arr.sort(customSort);
                              
                              
                              return (
                                <>
                                  <tr>
                                    <td className="report-hd">{val[0]?.head_account}</td>
                                  </tr>

                                  <tr>
                                    {
                                      arr.map((v) => {
                                        let keys = Object.keys(v);
                                        let values = Object.values(v);
                                        if (keys.includes(val[0]?.head_account)) {
                                          return values.map((v) => {
                                            return (
                                              <>
                                                <tr>
                                                  <td className="report-hd" style={{ padding: "0 16px" }}>{v}</td>
                                                </tr>
                                                {
                                                  arrData.map((arr, index) => {
                                                    if (arr.sub_account === v) {
                                                      return (
                                                        <>
                                                          <tr>
                                                            <td className="report-hd" style={{ padding: "0 40px" }}>{arr?.accttype}</td>
                                                          </tr>
                                                          {
                                                            balanceSheet && balanceSheet?.payload?.data?.map((val, id) => {
                                                              if (val?.accttype === arr?.accttype) {
                                                                return (
                                                                  <>
                                                                    <tr>
                                                                      <td style={{ padding: "0 50px", width: "800px" }}>
                                                                        <Link
                                                                          style={{ color: "blue", textDecoration: "underline", padding: "10px", textDecoration: "none" }}
                                                                          target="_blank"
                                                                          rel="noopener noreferrer"
                                                                          to={{ pathname: `/balance-detail`, search: `?account_id=${val?.account_id}&date_to=${formState?.cy_end_date}` }}
                                                                        >{val?.account_display_name}
                                                                        </Link>
                                                                      </td>
                                                                      <td style={{ width: "800px" }} className="report-value text-right">${numeral(val?.balance).format("0,00.00")}</td>
                                                                    </tr>
                                                                  </>
                                                                )
                                                              }

                                                            })
                                                          }
                                                          <tr style={{}}>
                                                            <td style={{ padding: "0 50px", width: "800px", position: "relative" }} >
                                                              <div className="report-hd" style={{
                                                                borderTop: "1px solid",
                                                                position: "absolute",
                                                                right: "20%",
                                                                top: "0",
                                                                left: "0",
                                                              }}>
                                                                Total {arr?.accttype}
                                                              </div>
                                                            </td>
                                                            <td style={{
                                                              float: "right"
                                                            }} >
                                                              <div className="report-value text-right report-hd"
                                                                style={{
                                                                  borderTop: "1px solid",
                                                                  width: "fit-content"
                                                                }}
                                                              >
                                                                ${numeral(totalBalances[index]).format("0,00.00")}
                                                              </div>
                                                            </td>
                                                          </tr>
                                                        </>
                                                      )
                                                    }
                                                  })
                                                }
                                              </>
                                            )
                                          })
                                        }
                                      })
                                    }
                                  </tr>
                                  <tr>
                                    <td className="report-hd">
                                      <hr style={{ margin: 2 }} />
                                      <div className="report-hd" style={{ display: "flex", justifyContent: "space-between" }}>
                                        <div className="report-hd">
                                          Total {val[0]?.head_account}
                                        </div>
                                        <div className="report-hd">
                                          ${numeral(arrSUM[idx]).format("0,00.00")}
                                        </div>
                                      </div>
                                      <hr style={{ margin: 2 }} />
                                    </td>
                                  </tr>

                                </>
                              )
                            })
                          }
                        </table>
                      )
                    }
                  </>) :
                  (
                    <div className="no-Data">
                      <h1>No Data</h1>
                    </div>
                  )
              )
                : (
                  loading && (
                    <div className="loader" style={{ marginTop: "20px" }}>
                      <Spin size="medium" />
                    </div>
                  )
                )
            }
          </div>
        </div>}
    </>
  );
};

export default BalanceSheet;
