import {
    UPDATE_ITEMS_SUCCESS,
    UPDATE_ITEMS_REQUEST,
    UPDATE_ITEMS_ERROR,
  } from "../actions/types";
  
  export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
      case UPDATE_ITEMS_REQUEST:
        return {
          loading: true,
        };
      case UPDATE_ITEMS_SUCCESS:
        return {
          ...state,
          loading: false,
          payload: payload,
        };
      case UPDATE_ITEMS_ERROR:
        return {
          ...state,
          loading: false,
          payload,
        };
      default:
        return state;
    }
  }
  