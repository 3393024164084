import {
    ADD_CUSTOMER_PAYMENT_IMPACT_REQUEST,
    ADD_CUSTOMER_PAYMENT_IMPACT_SUCCESS,
    ADD_CUSTOMER_PAYMENT_IMPACT_ERROR
} from "../actions/types";

export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {


        case ADD_CUSTOMER_PAYMENT_IMPACT_REQUEST:
            return {
                loading: true,
            };
        case ADD_CUSTOMER_PAYMENT_IMPACT_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case ADD_CUSTOMER_PAYMENT_IMPACT_ERROR:
            return {
                ...state,
                loading: false,
            };

        default:
            return state;
    }
}
