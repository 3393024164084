import {
    GET_GENDERCATEGORY_REQUEST, GET_GENDERCATEGORY_SUCCESS, GET_GENDERCATEGORY_ERROR,
    ADD_GENDERCATEGORY_REQUEST, ADD_GENDERCATEGORY_ERROR, ADD_GENDERCATEGORY_SUCCESS,
    UPDATE_GENDERCATEGORY_REQUEST, UPDATE_GENDERCATEGORY_SUCCESS, UPDATE_GENDERCATEGORY_ERROR,
    DELETE_GENDERCATEGORY_REQUEST, DELETE_GENDERCATEGORY_SUCCESS, DELETE_GENDERCATEGORY_ERROR,
    GET_GENDER_CATEGORY_REQUEST,
GET_GENDER_CATEGORY_SUCCESS,
GET_GENDER_CATEGORY_ERROR,
} from "./types";

import { BASEURL } from "../../constant/config.js";

const axios = require("axios");

export const getGenderCategory = (token, menu_id) => (dispatch) => {
    dispatch({ type: GET_GENDERCATEGORY_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/gender_category`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { menu_id }
    })
        .then((response) => {
            return dispatch({ type: GET_GENDERCATEGORY_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: GET_GENDERCATEGORY_ERROR,
                payload: error.response,
            });
        });
};

export const addGenderCategory = (token, data, menu_id) => (dispatch) => {
    dispatch({ type: ADD_GENDERCATEGORY_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}create/gender_category`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { gender_category_name: data.genderCategoryName, is_active: data.check, gender_id: data.genderId, menu_id, size_chart: data.sizes },
    })
        .then((response) => {

            return dispatch({ type: ADD_GENDERCATEGORY_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: ADD_GENDERCATEGORY_ERROR,
                payload: error.response,
            });
        });
};
export const updateGenderCategory = (token, data, id) => (dispatch) => {

    dispatch({ type: UPDATE_GENDERCATEGORY_REQUEST });
    return axios({
        method: "PUT",
        url: `${BASEURL}update/gender_category/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { gender_category_name: data.genderCategoryName, is_active: data.check, gender_id: data.genderId, size_chart: data.sizes },
    })
        .then((response) => {

            return dispatch({ type: UPDATE_GENDERCATEGORY_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: UPDATE_GENDERCATEGORY_ERROR,
                payload: error.response,
            });
        });
};

export const deleteGenderCategory = (token, id) => (dispatch) => {
    dispatch({ type: DELETE_GENDERCATEGORY_REQUEST });
    return axios({
        method: "DELETE",
        url: `${BASEURL}delete/gender_category/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
    })
        .then((response) => {

            return dispatch({ type: DELETE_GENDERCATEGORY_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: DELETE_GENDERCATEGORY_ERROR,
                payload: error.response,
            });
        });
};

export const getGenderCategoryChange = (token, gender_id) => (dispatch) => {
    dispatch({ type: GET_GENDER_CATEGORY_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/gendercategory`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { gender_id }
    })
        .then((response) => {
            return dispatch({ type: GET_GENDER_CATEGORY_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: GET_GENDER_CATEGORY_ERROR,
                payload: error.response,
            });
        });
};