import {
  GET_CHARGEBACKTYPE_REQUEST,
  GET_CHARGEBACKTYPE_SUCCESS,
  GET_CHARGEBACKTYPE_ERROR,
  ADD_CHARGEBACKTYPE_ERROR,
  ADD_CHARGEBACKTYPE_REQUEST,
  ADD_CHARGEBACKTYPE_SUCCESS,
  UPDATE_CHARGEBACKTYPE_REQUEST,
  UPDATE_CHARGEBACKTYPE_SUCCESS,
  UPDATE_CHARGEBACKTYPE_ERROR,
  DELETE_CHARGEBACKTYPE_REQUEST,
  DELETE_CHARGEBACKTYPE_SUCCESS,
  DELETE_CHARGEBACKTYPE_ERROR,
  
} from "../actions/types";

export default function (state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case GET_CHARGEBACKTYPE_REQUEST:
      return {
        loading: true,
      };
    case GET_CHARGEBACKTYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        payload,
      };
    case GET_CHARGEBACKTYPE_ERROR:
      return {
        ...state,
        loading: false,
        payload,
      };
    case ADD_CHARGEBACKTYPE_REQUEST:
      return {
        loading: true,
      };
    case ADD_CHARGEBACKTYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: [payload],
      };
    case ADD_CHARGEBACKTYPE_ERROR:
      return {
        ...state,
        loading: false
      };
    case UPDATE_CHARGEBACKTYPE_REQUEST:
      return {
        loading: true,
      };
    case UPDATE_CHARGEBACKTYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: [payload],
      };
    case UPDATE_CHARGEBACKTYPE_ERROR:
      return {
        ...state,
        loading: false
      };
    case DELETE_CHARGEBACKTYPE_REQUEST:
      return {
        loading: true,
      };
    case DELETE_CHARGEBACKTYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: [payload],
      };
    case DELETE_CHARGEBACKTYPE_ERROR:
      return {
        ...state,
        loading: false
      };



    default:
      return state;
  }
}
