import {
    ADD_ADMIN_MENU_REQUEST, ADD_ADMIN_MENU_SUCCESS, ADD_ADMIN_MENU_ERROR, GET_ALL_ADMIN_MENU_REQUEST, GET_ALL_ADMIN_MENU_SUCCESS, GET_ALL_ADMIN_MENU_ERROR,
    DELETE_ADMIN_MENU_REQUEST, DELETE_ADMIN_MENU_SUCCESS, DELETE_ADMIN_MENU_ERROR, UPDATE_ADMIN_MENU_REQUEST, UPDATE_ADMIN_MENU_SUCCESS, UPDATE_ADMIN_MENU_ERROR
} from "../actions/types"
export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {

        case ADD_ADMIN_MENU_REQUEST:
            return {
                loading: true,
            };
        case ADD_ADMIN_MENU_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case ADD_ADMIN_MENU_ERROR:
            return {
                ...state,
                loading: false
            };
        case UPDATE_ADMIN_MENU_REQUEST:
            return {
                loading: true,
            };
        case UPDATE_ADMIN_MENU_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case UPDATE_ADMIN_MENU_ERROR:
            return {
                ...state,
                loading: false
            };
        case GET_ALL_ADMIN_MENU_REQUEST:
            return {
                loading: true,
            };
        case GET_ALL_ADMIN_MENU_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_ALL_ADMIN_MENU_ERROR:
            return {
                ...state,
                loading: false
            };
        case DELETE_ADMIN_MENU_REQUEST:
            return {
                loading: true,
            };
        case DELETE_ADMIN_MENU_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case DELETE_ADMIN_MENU_ERROR:
            return {
                ...state,
                loading: false
            };

        default:
            return state;
    }
}