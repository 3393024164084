import {
    GET_VENDOR_CATEGORY_REQUEST, GET_VENDOR_CATEGORY_SUCCESS, GET_VENDOR_CATEGORY_ERROR, ADD_VENDOR_CATEGORY_REQUEST, ADD_VENDOR_CATEGORY_SUCCESS, ADD_VENDOR_CATEGORY_ERROR,
    UPDATE_VENDOR_CATEGORY_REQUEST, UPDATE_VENDOR_CATEGORY_SUCCESS, UPDATE_VENDOR_CATEGORY_ERROR, DELETE_VENDOR_CATEGORY_REQUEST, DELETE_VENDOR_CATEGORY_ERROR, DELETE_VENDOR_CATEGORY_SUCCESS,

} from "../actions/types"
export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case GET_VENDOR_CATEGORY_REQUEST:
            return {
                loading: true,
            };
        case GET_VENDOR_CATEGORY_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_VENDOR_CATEGORY_ERROR:
            return {
                ...state,
                loading: false
            };
        case ADD_VENDOR_CATEGORY_REQUEST:
            return {
                loading: true,
            };
        case ADD_VENDOR_CATEGORY_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case ADD_VENDOR_CATEGORY_ERROR:
            return {
                ...state,
                loading: false
            };
        case DELETE_VENDOR_CATEGORY_REQUEST:
            return {
                loading: true,
            };
        case DELETE_VENDOR_CATEGORY_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case DELETE_VENDOR_CATEGORY_ERROR:
            return {
                ...state,
                loading: false
            };
        case UPDATE_VENDOR_CATEGORY_REQUEST:
            return {
                loading: true,
            };
        case UPDATE_VENDOR_CATEGORY_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case UPDATE_VENDOR_CATEGORY_ERROR:
            return {
                ...state,
                loading: false
            };

        default:
            return state;
    }
}