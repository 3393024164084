import {
	ADD_BILL_CREDIT_HEADER_ERROR,
	ADD_BILL_CREDIT_HEADER_REQUEST,
	ADD_BILL_CREDIT_HEADER_SUCCESS,
	DELETE_BILL_CREDIT_HEADER_ERROR,
	DELETE_BILL_CREDIT_HEADER_REQUEST,
	DELETE_BILL_CREDIT_HEADER_SUCCESS,
	GET_ALL_BILL_CREDIT_ERROR,
	GET_ALL_BILL_CREDIT_REQUEST,
	GET_ALL_BILL_CREDIT_SUCCESS,
	GET_BILL_CREDIT_FORM_DATA_ERROR,
	GET_BILL_CREDIT_FORM_DATA_REQUEST,
	GET_BILL_CREDIT_FORM_DATA_SUCCESS,
	GET_ONE_BILL_CREDIT_HEADER_DETAIL_ERROR,
	GET_ONE_BILL_CREDIT_HEADER_DETAIL_REQUEST,
	GET_ONE_BILL_CREDIT_HEADER_DETAIL_SUCCESS,
	GET_ONE_BILL_CREDIT_HEADER_ERROR,
	GET_ONE_BILL_CREDIT_HEADER_REQUEST,
	GET_ONE_BILL_CREDIT_HEADER_SUCCESS,
	UPDATE_BILL_CREDIT_HEADER_ERROR,
	UPDATE_BILL_CREDIT_HEADER_REQUEST,
	UPDATE_BILL_CREDIT_HEADER_SUCCESS,
} from "./types";
import { BASEURL } from "../../constant/config.js";
import moment from "moment";
const axios = require("axios");

export const addBillCreditHeader = (token, formData, companyId) => (dispatch) => {
	
	dispatch({ type: ADD_BILL_CREDIT_HEADER_REQUEST });
	let newData = {
		is_approved: formData.approveStatus,
		account_id: formData.account,
		amount: formData.amount,
		bill_date: moment(formData.billDate).format("MM-DD-YYYY"),
		company_id: companyId,
		gl_period_id: formData.postingPeriod,
		remarks: formData.memo,
		reference_no: formData.referenceNo,
		vendor_id: formData.adjustmentLocation,
		ship_via: formData.shipVia,
		currency: formData.currency,
		due_date: moment(formData.dueDate).format("MM-DD-YYYY"),
		delivery_date: moment(formData.dileveryDate).format("MM-DD-YYYY"),
	};
	return axios({
		method: "POST",
		url: `${BASEURL}create/payPurchaseBillCreditHeader`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: newData,
	})
		.then((response) => {
			return dispatch({
				type: ADD_BILL_CREDIT_HEADER_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: ADD_BILL_CREDIT_HEADER_ERROR,
				payload: error.response,
			});
		});
};

export const updateBillCreditHeader = (token, formData, billId, companyId) => (dispatch) => {
	let newData = {
		is_approved: formData.approveStatus,
		account_id: formData.account,
		amount: formData.amount,
		bill_date: moment(formData.billDate).format("MM-DD-YYYY"),
		company_id: companyId,
		gl_period_id: formData.postingPeriod,
		location_id: formData.shipVia,
		remarks: formData.memo,
		reference_no: formData.referenceNo,
		vendor_id: formData.vendor,
	};
	dispatch({ type: UPDATE_BILL_CREDIT_HEADER_REQUEST });
	return axios({
		method: "PUT",
		url: `${BASEURL}update/payPurchaseBillCreditHeader/${billId}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: newData,
	})
		.then((response) => {

			return dispatch({
				type: UPDATE_BILL_CREDIT_HEADER_SUCCESS,
				payloadUpdate: response?.data?.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: UPDATE_BILL_CREDIT_HEADER_ERROR,
				payloadUpdate: error.response,
			});
		});
};

export const getAllBillCreditHeader = (token, data) => (dispatch) => {
	dispatch({ type: GET_ALL_BILL_CREDIT_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}getAll/payPurchaseBillCreditHeader`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: {
			bill_credit_header_no: data.transactionNo === "" ? null : data.transactionNo,
			from_date: moment(data.from_date).format("MM-DD-YYYY"),
			to_date: moment(data.to_date).format("MM-DD-YYYY"),
			account_name: data.account === "" ? null : data.account,
			from_amount: data.fromAmount === "" ? null : data.fromAmount,
			to_amount: data.toAmount === "" ? null : data.toAmount,
			gl_period_name: data.postingPeriod === "" ? null : data.postingPeriod,
			vendor_name: data.vendor === "" ? null : data.vendor,
			reference_no: data.referenceNo === "" ? null : data.referenceNo,
			exchange_rate: data.currency === "" ? null : data.currency,
			bill_credit_header_id: null,
			location_name: null,
			is_approved: null,
			company_name: null,
		},
	})
		.then((response) => {
			return dispatch({
				type: GET_ALL_BILL_CREDIT_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_ALL_BILL_CREDIT_ERROR,
				payload: error.response,
			});
		});
};

export const getOneBillCreditHeader = (token, id) => (dispatch) => {
	dispatch({ type: GET_ONE_BILL_CREDIT_HEADER_REQUEST });
	return axios({
		method: "GET",
		url: `${BASEURL}getOne/payPurchaseBillCreditHeader/${id}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
	})
		.then((response) => {
			return dispatch({
				type: GET_ONE_BILL_CREDIT_HEADER_SUCCESS,
				payload: response?.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_ONE_BILL_CREDIT_HEADER_ERROR,
				payload: error.response,
			});
		});
};

export const getOneBillCreditDetail = (token, id) => (dispatch) => {
	dispatch({ type: GET_ONE_BILL_CREDIT_HEADER_DETAIL_REQUEST });
	return axios({
		method: "GET",
		url: `${BASEURL}getOne/payPurchaseBillHeader/${id}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
	})
		.then((response) => {
			return dispatch({
				type: GET_ONE_BILL_CREDIT_HEADER_DETAIL_SUCCESS,
				payload: response?.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_ONE_BILL_CREDIT_HEADER_DETAIL_ERROR,
				payload: error.response,
			});
		});
};

export const deleteBillCreditHeader = (token, id) => (dispatch) => {
	dispatch({ type: DELETE_BILL_CREDIT_HEADER_REQUEST });
	return axios({
		method: "DELETE",
		url: `${BASEURL}delete/payPurchaseBillCreditHeader/${id}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
	})
		.then((response) => {
			return dispatch({
				type: DELETE_BILL_CREDIT_HEADER_SUCCESS,
				payload: response?.data.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: DELETE_BILL_CREDIT_HEADER_ERROR,
				payload: error.response,
			});
		});
};

export const getBillCreditFormData = (token, clear) => (dispatch) => {
	dispatch({ type: GET_BILL_CREDIT_FORM_DATA_REQUEST });

	if (clear) {
		return dispatch({
			type: GET_BILL_CREDIT_FORM_DATA_SUCCESS,
			payload: {
				location: [],
			},
		});
	}
	return axios({
		method: "POST",
		url: `${BASEURL}getAll/payPurchaseBillHeaderFormData`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
	})
		.then((response) => {
			return dispatch({
				type: GET_BILL_CREDIT_FORM_DATA_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_BILL_CREDIT_FORM_DATA_ERROR,
				payload: error.response,
			});
		});
};