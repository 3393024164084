import { GET_ADMIN_PAYMENT_TYPES_REQUEST, GET_ADMIN_PAYMENT_TYPES_SUCCESS, GET_ADMIN_PAYMENT_TYPES_ERROR } from "./types"
import { BASEURL } from "../../constant/config.js"

const axios = require("axios");
export const getAdminPaymentTypes = (token) => (dispatch) => {
    dispatch({ type: GET_ADMIN_PAYMENT_TYPES_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/adminPaymentTypes`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
    })
        .then((response) => {
            return dispatch({ type: GET_ADMIN_PAYMENT_TYPES_SUCCESS, payload: response.data });
        })
        .catch((error) => {

            return dispatch({
                type: GET_ADMIN_PAYMENT_TYPES_ERROR,
                payload: error.response,
            });
        });
};
