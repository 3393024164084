import {
  GET_DEFAULT_COMPANY_ERROR,
  GET_DEFAULT_COMPANY_REQUEST,
  GET_DEFAULT_COMPANY_SUCCESS,
  GET_ONE_DEFAULT_COMPANY_REQUEST,
  GET_ONE_DEFAULT_COMPANY_SUCCESS,
  GET_ONE_DEFAULT_COMPANY_ERROR

} from "./types";
import { BASEURL } from "../../constant/config.js";

const axios = require("axios");

export const getDefaultCompany = (token) => (dispatch) => {
  dispatch({ type: GET_DEFAULT_COMPANY_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}getAll/userCompany`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {
      return dispatch({
        type: GET_DEFAULT_COMPANY_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_DEFAULT_COMPANY_ERROR,
        payload: error.response,
      });
    });
};

export const getOneDefaultCompany = (token, data) => (dispatch) => {
  dispatch({ type: GET_ONE_DEFAULT_COMPANY_REQUEST });
  return axios({
    method: "GET",
    url: `${BASEURL}getOne/admin_company/${data}`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {
      return dispatch({
        type: GET_ONE_DEFAULT_COMPANY_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_ONE_DEFAULT_COMPANY_ERROR,
        payload: [error.response],
      });
    });
};
