import {
	GET_ALL_CREDIT_MEMO_REQUEST,
	GET_ALL_CREDIT_MEMO_SUCCESS,
	GET_ALL_CREDIT_MEMO_ERROR,

	GET_ONE_CREDIT_MEMO_HEADER_REQUEST,
	GET_ONE_CREDIT_MEMO_HEADER_SUCCESS,
	GET_ONE_CREDIT_MEMO_HEADER_ERROR,

	GET_ONE_CREDIT_MEMO_HEADER_DETAIL_REQUEST,
	GET_ONE_CREDIT_MEMO_HEADER_DETAIL_SUCCESS,
	GET_ONE_CREDIT_MEMO_HEADER_DETAIL_ERROR,

	DELETE_CREDIT_MEMO_HEADER_REQUEST,
	DELETE_CREDIT_MEMO_HEADER_SUCCESS,
	DELETE_CREDIT_MEMO_HEADER_ERROR,

	ADD_CREDIT_MEMO_HEADER_REQUEST,
	ADD_CREDIT_MEMO_HEADER_SUCCESS,
	ADD_CREDIT_MEMO_HEADER_ERROR,

	UPDATE_CREDIT_MEMO_HEADER_REQUEST,
	UPDATE_CREDIT_MEMO_HEADER_SUCCESS,
	UPDATE_CREDIT_MEMO_HEADER_ERROR,

	GET_CREDIT_MEMO_FORM_DATA_ERROR,
	GET_CREDIT_MEMO_FORM_DATA_REQUEST,
	GET_CREDIT_MEMO_FORM_DATA_SUCCESS,

} from "../actions/types";

export default function (state = [], action) {
	const { type, payload } = action;
	switch (type) {
		case ADD_CREDIT_MEMO_HEADER_REQUEST:
			return {
				loading: true,
			};
		case ADD_CREDIT_MEMO_HEADER_SUCCESS:
			return {
				...state,
				loading: false,
				payloadSuccess: [payload],
			};
		case ADD_CREDIT_MEMO_HEADER_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};
		case UPDATE_CREDIT_MEMO_HEADER_REQUEST:
			return {
				loading: true,
			};
		case UPDATE_CREDIT_MEMO_HEADER_SUCCESS:
			return {
				...state,
				loading: false,
				payloadUpdate: [payload],
			};
		case UPDATE_CREDIT_MEMO_HEADER_ERROR:
			return {
				...state,
				loading: false,
				payload: [payload],
			};
		case GET_ALL_CREDIT_MEMO_REQUEST:
			return {
				loading: true,
			};
		case GET_ALL_CREDIT_MEMO_SUCCESS:
			return {
				...state,
				loading: false,
				payload: [payload],
			};
		case GET_ALL_CREDIT_MEMO_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};

		case DELETE_CREDIT_MEMO_HEADER_REQUEST:
			return {
				loading: true,
			};
		case DELETE_CREDIT_MEMO_HEADER_SUCCESS:
			return {
				...state,
				loading: false,
				payloadDelete: [payload],
			};
		case DELETE_CREDIT_MEMO_HEADER_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};
		case GET_ONE_CREDIT_MEMO_HEADER_REQUEST:
			return {
				loading: true,
			};
		case GET_ONE_CREDIT_MEMO_HEADER_SUCCESS:
			return {
				...state,
				loading: false,
				payload: payload,
			};
		case GET_ONE_CREDIT_MEMO_HEADER_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};
		case GET_ONE_CREDIT_MEMO_HEADER_DETAIL_REQUEST:
			return {
				loading: true,
			};
		case GET_ONE_CREDIT_MEMO_HEADER_DETAIL_SUCCESS:
			return {
				...state,
				loading: false,
				payload: payload?.data[0],
			};
		case GET_ONE_CREDIT_MEMO_HEADER_DETAIL_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};
		default:
			return state;
	}
}