import {
	
	
	
	DELETE_INVENTORYADJUSTMENT_HEADER_ERROR,
	DELETE_INVENTORYADJUSTMENT_HEADER_REQUEST,
	DELETE_INVENTORYADJUSTMENT_HEADER_SUCCESS,
	GET_ONE_INVENTORYADJUSTMENT_HEADER_DETAIL_ERROR,
	GET_ONE_INVENTORYADJUSTMENT_HEADER_DETAIL_REQUEST,
	GET_ONE_INVENTORYADJUSTMENT_HEADER_DETAIL_SUCCESS,
	GET_ONE_INVENTORYADJUSTMENT_HEADER_ERROR,
	GET_ONE_INVENTORYADJUSTMENT_HEADER_REQUEST,
	GET_ONE_INVENTORYADJUSTMENT_HEADER_SUCCESS,
	UPDATE_INVENTORYADJUSTMENT_HEADER_ERROR,
	UPDATE_INVENTORYADJUSTMENT_HEADER_REQUEST,
	UPDATE_INVENTORYADJUSTMENT_HEADER_SUCCESS,
	GET_ALL_INVENTORYADJUSTMENT_HEADER_ERROR,
	GET_ALL_INVENTORYADJUSTMENT_HEADER_REQUEST,
	GET_ALL_INVENTORYADJUSTMENT_HEADER_SUCCESS,
	GET_ALL_ADJUSTMENTACCOUNT_HEADER_SUCCESS,
	GET_ALL_ADJUSTMENTACCOUNT_HEADER_ERROR,
	GET_ALL_ADJUSTMENTACCOUNT_HEADER_REQUEST
} from "../actions/types";


export default function (state = [], action) {
	const { type, payload } = action;
	switch (type) {
		case GET_ALL_ADJUSTMENTACCOUNT_HEADER_REQUEST:
			return {
				loading: true,
			};
		case GET_ALL_ADJUSTMENTACCOUNT_HEADER_SUCCESS:
			return {
				...state,
				loading: false,
				payload: payload,
			};
		case GET_ALL_ADJUSTMENTACCOUNT_HEADER_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};
		case GET_ALL_INVENTORYADJUSTMENT_HEADER_REQUEST:
			return {
				loading: true,
			};
		case GET_ALL_INVENTORYADJUSTMENT_HEADER_SUCCESS:
			return {
				...state,
				loading: false,
				payload: payload,
			};
		case GET_ALL_INVENTORYADJUSTMENT_HEADER_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		case UPDATE_INVENTORYADJUSTMENT_HEADER_REQUEST:
			return {
				loading: true,
			};
		case UPDATE_INVENTORYADJUSTMENT_HEADER_SUCCESS:
			return {
				...state,
				loading: false,
				payload,
			};
		case UPDATE_INVENTORYADJUSTMENT_HEADER_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};


		case DELETE_INVENTORYADJUSTMENT_HEADER_REQUEST:
			return {
				loading: true,
			};
		case DELETE_INVENTORYADJUSTMENT_HEADER_SUCCESS:
			return {
				...state,
				loading: false,
				payloadDelete: [payload],
			};
		case DELETE_INVENTORYADJUSTMENT_HEADER_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};
		case GET_ONE_INVENTORYADJUSTMENT_HEADER_REQUEST:
			return {
				loading: true,
			};
		case GET_ONE_INVENTORYADJUSTMENT_HEADER_SUCCESS:
			return {
				...state,
				loading: false,
				payload: payload,
			};
		case GET_ONE_INVENTORYADJUSTMENT_HEADER_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};
		case GET_ONE_INVENTORYADJUSTMENT_HEADER_DETAIL_REQUEST:
			return {
				loading: true,
			};
		case GET_ONE_INVENTORYADJUSTMENT_HEADER_DETAIL_SUCCESS:
			return {
				...state,
				loading: false,
				payload: payload?.data[0],
			};
		case GET_ONE_INVENTORYADJUSTMENT_HEADER_DETAIL_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};
		default:
			return state;
	}
}
