import {
    GET_ALL_STYLE_WISE_SHIPPED_REPORT_REQUEST,
    GET_ALL_STYLE_WISE_SHIPPED_REPORT_ERROR,
    GET_ALL_STYLE_WISE_SHIPPED_REPORT_SUCCESS
} from "../actions/types";

export default function (state = [], action)  {
    const { type, payload } = action;
    switch (type) {
        case GET_ALL_STYLE_WISE_SHIPPED_REPORT_REQUEST:
            return {
                loading: true,
            };
        case GET_ALL_STYLE_WISE_SHIPPED_REPORT_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_ALL_STYLE_WISE_SHIPPED_REPORT_ERROR:
            return {
                ...state,
                loading: false,
                payload,
            };

        default: {
            return state;
        }
    }
};
