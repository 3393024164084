import {
    ADD_DEPOSIT_HEADER_REQUEST,
    ADD_DEPOSIT_HEADER_SUCCESS,
    ADD_DEPOSIT_HEADER_ERROR,
    GETALL_DEPOSIT_HEADER_REQUEST,
    GETALL_DEPOSIT_HEADER_SUCCESS,
    GETALL_DEPOSIT_HEADER_ERROR,
    GET_ONE_DEPOSIT_HEADER_REQUEST,
    GET_ONE_DEPOSIT_HEADER_SUCCESS,
    GET_ONE_DEPOSIT_HEADER_ERROR,
    DELETE_DEPOSIT_HEADER_REQUEST,
    DELETE_DEPOSIT_HEADER_SUCCESS,
    DELETE_DEPOSIT_HEADER_ERROR,
    UPDATE_DEPOSIT_HEADER_REQUEST,
    UPDATE_DEPOSIT_HEADER_SUCCESS,
    UPDATE_DEPOSIT_HEADER_ERROR,
} from "./types";
import { BASEURL } from "../../constant/config.js";
import moment from "moment";

const axios = require("axios");

export const addDepositHeader = (token, formData) => (dispatch) => {
    
    
    dispatch({ type: ADD_DEPOSIT_HEADER_REQUEST });
    let newData = {

        
        
        
        
        
        
        
        gl_period_id: formData.postingPeriod,
        account_id: formData.account,
        deposit_date: formData.date,
        memo: formData.memo,
        
        company_id: formData.subsidiary,
        
        
        
    };
    return axios({
        method: "POST",
        url: `${BASEURL}create/PayBankDepositHeader`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: newData,
    })
        .then((response) => {
            return dispatch({
                type: ADD_DEPOSIT_HEADER_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) => {
            return dispatch({
                type: ADD_DEPOSIT_HEADER_ERROR,
                payload: error.response,
            });
        });
};

export const getAllDepositHeaders = (token, data) => (dispatch) => {
    dispatch({ type: GETALL_DEPOSIT_HEADER_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/PayBankDepositHeader`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: {
            deposit_header_no: data.transactionNo === "" ? null : data.transactionNo,
            from_date: moment(data.from_date).format("MM-DD-YYYY"),
            to_date: moment(data.to_date).format("MM-DD-YYYY"),
            account_name: data.account === "" ? null : data.account,
            company_name: data.subsidiary === "" ? null : data.subsidiary,
            gl_period_name: data.postingPeriod === "" ? null : data.postingPeriod,
            memo: data.memo === "" ? null : data.memo,
            vendor_name: data.vendor === "" ? null : data.vendor,
            company_id: null
        },
    })
        .then((response) => {
            return dispatch({
                type: GETALL_DEPOSIT_HEADER_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) => {
            return dispatch({
                type: GETALL_DEPOSIT_HEADER_ERROR,
                payload: error.response,
            });
        });
};

export const getOneDepositHeader = (token, id) => (dispatch) => {
    dispatch({ type: GET_ONE_DEPOSIT_HEADER_REQUEST });
    return axios({
        method: "GET",
        url: `${BASEURL}getOne/PayBankDepositHeader/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
    })
        .then((response) => {
            return dispatch({
                type: GET_ONE_DEPOSIT_HEADER_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) => {
            return dispatch({
                type: GET_ONE_DEPOSIT_HEADER_ERROR,
                payload: error.response,
            });
        });
};

export const updateDepositHeader = (token, formData, id) => (dispatch) => {
    let newData = {
        deposit_date: moment(formData.date),
        
        gl_period_id: formData.postingPeriod,
        memo: formData.memo,
        
        
        company_id: formData.subsidiary,
        
        account_id: formData.account,
    };
    dispatch({ type: UPDATE_DEPOSIT_HEADER_REQUEST });
    return axios({
        method: "PUT",
        url: `${BASEURL}update/PayBankDepositHeader/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: newData,
    })
        .then((response) => {
            
            return dispatch({

                type: UPDATE_DEPOSIT_HEADER_SUCCESS,
                payloadUpdate: response?.data?.data,
            });
        })
        .catch((error) => {
            return dispatch({
                type: UPDATE_DEPOSIT_HEADER_ERROR,
                payloadUpdate: error.response,
            });
        });
};

export const deleteDepositHeader = (token, id) => (dispatch) => {
    dispatch({ type: DELETE_DEPOSIT_HEADER_REQUEST });
    return axios({
        method: "DELETE",
        url: `${BASEURL}delete/PayBankDepositHeader/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
    })
        .then((response) => {
            return dispatch({
                type: DELETE_DEPOSIT_HEADER_SUCCESS,
                payload: response?.data.data,
            });
        })
        .catch((error) => {
            return dispatch({
                type: DELETE_DEPOSIT_HEADER_ERROR,
                payload: error.response,
            });
        });
};
