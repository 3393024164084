import {
    UPDATE_ITEMS_SUCCESS,
    UPDATE_ITEMS_REQUEST,
    UPDATE_ITEMS_ERROR,
  } from "./types";
  
  import { BASEURL } from "../../constant/config.js";
  import axios from "axios";
  
  export const updateItems = (token, payload, items, userId) => (dispatch) => {
    dispatch({ type: UPDATE_ITEMS_REQUEST });
    return axios({
      method: "PUT",
      url: `${BASEURL}update/updateItems/1`,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
      data: {payload, item_ids: items, user_id: userId},
    })
      .then((response) => {
        return dispatch({
          type: UPDATE_ITEMS_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        return dispatch({
          type: UPDATE_ITEMS_ERROR,
          payload: error.response,
        });
      });
  };
  