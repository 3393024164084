import {
	GET_ONE_BILL_PAYMENT_LINE_REQUEST,
	GET_ONE_BILL_PAYMENT_LINE_SUCCESS,
	GET_ONE_BILL_PAYMENT_LINE_ERROR,
	ADD_BILL_PAYMENT_LINE_REQUEST,
	ADD_BILL_PAYMENT_LINE_SUCCESS,
	ADD_BILL_PAYMENT_LINE_ERROR,
	UPDATE_BILL_PAYMENT_LINE_REQUEST,
	UPDATE_BILL_PAYMENT_LINE_SUCCESS,
	UPDATE_BILL_PAYMENT_LINE_ERROR,
	DELETE_BILL_PAYMENT_LINE_REQUEST,
	DELETE_BILL_PAYMENT_LINE_SUCCESS,
	DELETE_BILL_PAYMENT_LINE_ERROR,
	GET_ALL_PENDING_BILL_CREADIT_REQUEST,
	GET_ALL_PENDING_BILL_CREADIT_SUCCESS,
	GET_ALL_PENDING_BILL_CREADIT_ERROR,
	ADD_PAYABLE_PAYMENT_CREDIT_TAKE_REQUEST,
	ADD_PAYABLE_PAYMENT_CREDIT_TAKE_SUCCESS,
	ADD_PAYABLE_PAYMENT_CREDIT_TAKE_ERROR

} from "../actions/types";

export default function (state = [], action) {
	const { type, payload } = action;
	switch (type) {
		case ADD_BILL_PAYMENT_LINE_REQUEST:
			return {
				loading: true,
			};
		case ADD_BILL_PAYMENT_LINE_SUCCESS:
			return {
				...state,
				loading: false,
				payload: payload,
			};
		case ADD_BILL_PAYMENT_LINE_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};

		case UPDATE_BILL_PAYMENT_LINE_REQUEST:
			return {
				loading: true,
			};
		case UPDATE_BILL_PAYMENT_LINE_SUCCESS:
			return {
				...state,
				loading: false,
				payloadLine: payload,
			};
		case UPDATE_BILL_PAYMENT_LINE_ERROR:
			return {
				...state,
				loading: false,
				payloadLine: payload,
			};

		case DELETE_BILL_PAYMENT_LINE_REQUEST:
			return {
				loading: true,
			};
		case DELETE_BILL_PAYMENT_LINE_SUCCESS:
			return {
				...state,
				loading: false,
				payload,
			};
		case DELETE_BILL_PAYMENT_LINE_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};

		case GET_ONE_BILL_PAYMENT_LINE_REQUEST:
			return {
				loading: true,
			};
		case GET_ONE_BILL_PAYMENT_LINE_SUCCESS:
			return {
				...state,
				loading: false,
				payload,
			};
		case GET_ONE_BILL_PAYMENT_LINE_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};
		case GET_ALL_PENDING_BILL_CREADIT_REQUEST:
			return {
				loading: true,
			};
		case GET_ALL_PENDING_BILL_CREADIT_SUCCESS:
			return {
				...state,
				loading: false,
				payload,
			};
		case GET_ALL_PENDING_BILL_CREADIT_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};

		case ADD_PAYABLE_PAYMENT_CREDIT_TAKE_REQUEST:
			return {
				loading: true,
			};
		case ADD_PAYABLE_PAYMENT_CREDIT_TAKE_SUCCESS:
			return {
				...state,
				loading: false,
				payload,
			};
		case ADD_PAYABLE_PAYMENT_CREDIT_TAKE_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};

		default:
			return state;
	}
}