import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CaretRightOutlined, PlusOutlined , FilterFilled,
    SearchOutlined} from "@ant-design/icons";
import SkeletonLoad from "../../components/Skeleton/SkeletonLoad";
import PageHeader from "../../components/PageHeader/PageHeader";
import { Button, Form,  DatePicker, Collapse, Row, Col, Select, Table, Spin , Input} from "antd";
import moment from "moment";
import "../../screens/Product/product.css";
import "../Style/style.css";
import { useHistory } from "react-router-dom";
import { ReadCookie } from "../../utils/readCookie";
import { useEffect } from "react";
import { getOrderStatus } from "../../redux/actions/orderStatus.action";
import { getFactor } from "../../redux/actions/factor.action";
import { createInvoicePending, getInvoicePending } from "../../redux/actions/invoiceEmail.action";
import notification from "../../utils/notification";
import { CSVLink } from "react-csv";
import { TabTitle } from "../../utils/GeneralFunction";
import { getPaymentTerm } from "../../redux/actions/paymentterm.action";

const InvoicePending = ({ token }) => {
    TabTitle("Invoice Printing")
    const [form] = Form.useForm();
    const history = useHistory();
    const dispatch = useDispatch();
    const { factor, invoicePending, paymentterm } = useSelector((state) => state);
    const { Panel } = Collapse;
    const companyId = ReadCookie("defaultCompany");
    const menuId = ReadCookie("menuId");
    const [lines, setLines] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const [excelData, setExcelData] = useState([])

    useEffect(() => {
        dispatch(getOrderStatus(token));
        dispatch(getFactor(token, menuId));
        dispatch(getPaymentTerm(token, menuId));
    }, [dispatch, token]);


    const onFinish = async (e) => {
        await form.validateFields().then((values) => {
            setLoading(true);
            dispatch(getInvoicePending(token, values)).then((res) => {
                if (res?.type === "GET_INVOICE_PENDING_SUCCESS") {
                    setLoading(false);
                    downloadExcelFileData(res?.payload?.data);
                    setLines(res?.payload?.data);
                }
            }).catch((error) => {
                setLoading(false);
            })
        })
    }

    function downloadExcelFileData(data) {
        setExcelData(
            data.map((v) => {
                
                return {
                    "Invoice No)": v["invoice_header_no"],
                    "Invoice Date": v["invoice_date"],
                    "Customer Name": v["customer_name"],
                    "Email": v["email"],
                    "Term": v["term"],
                };
            })
        )
    }

    const columns = [
        {
            title: "Invoice No",
            dataIndex: "invoice_header_no",
            width: 100,
            fixed: 'left',
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
              }) => {
                return (
                  <>
                    <Input
                      allowClear
                      autoFocus
                      className="pop-search-gen pop-220"
                      placeholder="Type text here"
                      value={selectedKeys[0]}
                      onChange={(e) => {
                        setSelectedKeys(e.target.value ? [e.target.value] : []);
                        confirm({ closeDropdown: false });
                      }}
                      onPressEnter={() => {
                        confirm();
                      }}
                      onBlur={() => {
                        confirm();
                      }}
                    />
                  </>
                );
              },
              filterIcon: (filtered) =>
                filtered ? <FilterFilled /> : <SearchOutlined />,
              onFilter: (value, record) => {
                if (record.invoice_header_no !== null) {
                  return record.invoice_header_no
                    .toString()
                    .toLowerCase()
                    .includes(value.toLowerCase());
                }
              },
              sorter: (a, b) => {
                if ((a.invoice_header_no !== null) & (b.invoice_header_no !== null)) {
                  return a.invoice_header_no.localeCompare(b.invoice_header_no);
                }
              },
        },
        {
            title: "Invoice Date",
            dataIndex: "invoice_date",
            width: 120,
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
              }) => {
                return (
                  <>
                    <Input
                      allowClear
                      autoFocus
                      className="pop-search-gen pop-220"
                      placeholder="Type text here"
                      value={selectedKeys[0]}
                      onChange={(e) => {
                        setSelectedKeys(e.target.value ? [e.target.value] : []);
                        confirm({ closeDropdown: false });
                      }}
                      onPressEnter={() => {
                        confirm();
                      }}
                      onBlur={() => {
                        confirm();
                      }}
                    />
                  </>
                );
              },
              filterIcon: (filtered) =>
                filtered ? <FilterFilled /> : <SearchOutlined />,
              onFilter: (value, record) => {
                if (record.invoice_date !== null) {
                  return record.invoice_date
                    .toString()
                    .toLowerCase()
                    .includes(value.toLowerCase());
                }
              },
              sorter: (a, b) => {
                if ((a.invoice_date !== null) & (b.invoice_date !== null)) {
                  return a.invoice_date.localeCompare(b.invoice_date);
                }
              },
        },
        {
            title: "Customer Name",
            dataIndex: "customer_name",
            width: 140,
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
              }) => {
                return (
                  <>
                    <Input
                      allowClear
                      autoFocus
                      className="pop-search-gen pop-220"
                      placeholder="Type text here"
                      value={selectedKeys[0]}
                      onChange={(e) => {
                        setSelectedKeys(e.target.value ? [e.target.value] : []);
                        confirm({ closeDropdown: false });
                      }}
                      onPressEnter={() => {
                        confirm();
                      }}
                      onBlur={() => {
                        confirm();
                      }}
                    />
                  </>
                );
              },
              filterIcon: (filtered) =>
                filtered ? <FilterFilled /> : <SearchOutlined />,
              onFilter: (value, record) => {
                if (record.customer_name !== null) {
                  return record.customer_name
                    .toString()
                    .toLowerCase()
                    .includes(value.toLowerCase());
                }
              },
              sorter: (a, b) => {
                if ((a.customer_name !== null) & (b.customer_name !== null)) {
                  return a.customer_name.localeCompare(b.customer_name);
                }
              },
        },
        {
            title: "Email",
            dataIndex: "email",
            width: 140,
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
              }) => {
                return (
                  <>
                    <Input
                      allowClear
                      autoFocus
                      className="pop-search-gen pop-220"
                      placeholder="Type text here"
                      value={selectedKeys[0]}
                      onChange={(e) => {
                        setSelectedKeys(e.target.value ? [e.target.value] : []);
                        confirm({ closeDropdown: false });
                      }}
                      onPressEnter={() => {
                        confirm();
                      }}
                      onBlur={() => {
                        confirm();
                      }}
                    />
                  </>
                );
              },
              filterIcon: (filtered) =>
                filtered ? <FilterFilled /> : <SearchOutlined />,
              onFilter: (value, record) => {
                if (record.email !== null) {
                  return record.email
                    .toString()
                    .toLowerCase()
                    .includes(value.toLowerCase());
                }
              },
              sorter: (a, b) => {
                if ((a.email !== null) & (b.email !== null)) {
                  return a.email.localeCompare(b.email);
                }
              },
        },
        {
            title: "Term",
            dataIndex: "term",
            width: 140,
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
              }) => {
                return (
                  <>
                    <Input
                      allowClear
                      autoFocus
                      className="pop-search-gen pop-220"
                      placeholder="Type text here"
                      value={selectedKeys[0]}
                      onChange={(e) => {
                        setSelectedKeys(e.target.value ? [e.target.value] : []);
                        confirm({ closeDropdown: false });
                      }}
                      onPressEnter={() => {
                        confirm();
                      }}
                      onBlur={() => {
                        confirm();
                      }}
                    />
                  </>
                );
              },
              filterIcon: (filtered) =>
                filtered ? <FilterFilled /> : <SearchOutlined />,
              onFilter: (value, record) => {
                if (record.term !== null) {
                  return record.term
                    .toString()
                    .toLowerCase()
                    .includes(value.toLowerCase());
                }
              },
              sorter: (a, b) => {
                if ((a.term !== null) & (b.term !== null)) {
                  return a.term.localeCompare(b.term);
                }
              },
        },
        {
            title: "Email",
            dataIndex: "emailsent",
            width: 140,
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
              }) => {
                return (
                  <>
                    <Input
                      allowClear
                      autoFocus
                      className="pop-search-gen pop-220"
                      placeholder="Type text here"
                      value={selectedKeys[0]}
                      onChange={(e) => {
                        setSelectedKeys(e.target.value ? [e.target.value] : []);
                        confirm({ closeDropdown: false });
                      }}
                      onPressEnter={() => {
                        confirm();
                      }}
                      onBlur={() => {
                        confirm();
                      }}
                    />
                  </>
                );
              },
              filterIcon: (filtered) =>
                filtered ? <FilterFilled /> : <SearchOutlined />,
              onFilter: (value, record) => {
                if (record.emailsent !== null) {
                  return record.emailsent
                    .toString()
                    .toLowerCase()
                    .includes(value.toLowerCase());
                }
              },
              sorter: (a, b) => {
                if ((a.emailsent !== null) & (b.emailsent !== null)) {
                  return a.emailsent.localeCompare(b.emailsent);
                }
              },
        },
    ]

  
    
    
    
    
    
    
    

    


    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRows(selectedRows);
        },
        
        
        
        
        
    };

    

    const sendEmail = () => {
        dispatch(createInvoicePending(token, selectedRows)).then((res) => {
            if (res?.type === "CREATE_INVOICE_PENDING_SUCCESS") {
                notification("success", res?.payload?.data);
                setSelectedRows([]);
                setLines([]);
            }
        });
    }


    return (
        <div className="styleContainer">
            <div className="styleBody h-100">
                <PageHeader title={"Invoice Printing"} />
                <div className="bodyStyle">
                    <Collapse
                        style={{ width: "99%", margin: "10px" }}
                        bordered={false}
                        defaultActiveKey={['1']}
                        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                    >
                        <Panel header="Filters" key="1" className="site-collapse-custom-panel" extra={<Button
                            onClick={() => {
                                history.push("/saleperson");
                            }}
                            className="iconBtn crudBTN"
                            shape="circle"
                        />}>
                            <Form
                                form={form}
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 16 }}
                                onFinish={onFinish}
                                autoComplete="off"
                            >
                                <Row>
                                    <Col xs={24} xl={8}>
                                        <Form.Item label="From Date" name="fromDate"
                                        >
                                            <DatePicker
                                                size="small"
                                                id="fromData"
                                                format="MM-DD-YYYY"
                                                allowClear
                                                className="dateInput customInputs"
                                                defaultValue={moment()}
                                                value={moment()}

                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} xl={8}>
                                        <Form.Item label="To Date" name="toDate"
                                        >
                                            <DatePicker
                                                size="small"
                                                id="toDate"
                                                format="MM-DD-YYYY"
                                                allowClear
                                                className="dateInput customInputs"
                                                defaultValue={moment()}
                                                value={moment()}
                                            />
                                        </Form.Item>
                                    </Col>
                                    
                                    <Col xs={24} xl={8}>
                                        <Form.Item
                                            name="terms"
                                            label="Terms"
                                        >
                                            <Select
                                                size='small'
                                                showSearch
                                                allowClear
                                                optionFilterProp="children"
                                                filterOption={(input, options) => options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                defaultValue={form.getFieldValue().terms}
                                            >
                                                {/* <Option value={null}>All</Option> */}
                                                {
                                                    paymentterm?.payload?.data.map((val) => {
                                                        return (
                                                            <Select.Option value={val.term_id}>{val.term_name}</Select.Option>
                                                        )
                                                    })
                                                }

                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={24} xl={8}>
                                        <Form.Item
                                            label="Payment Class"
                                            name="paymentClass"
                                        >
                                            <Select
                                                size="small"
                                                autoComplete={"nope"}
                                                filterOption={(input, option) => option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0}
                                                showSearch
                                                allowClear
                                                loading={factor.loading}
                                            
                                            
                                            
                                            
                                            >
                                                {factor?.payload?.data.map(
                                                    (item) => {
                                                        return (
                                                            <Select.Option
                                                                key={item.factor_id}
                                                                
                                                                value={item.factor_id}
                                                            >
                                                                {item.factor_name}
                                                            </Select.Option>
                                                        );
                                                    }
                                                )}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <Button type="primary" htmlType="submit">
                                        Search
                                    </Button>
                                </div>
                                {
                                    selectedRows.length > 0 ? (
                                        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 10 }}>
                                            <Button
                                                disabled={invoicePending.loading ? true : false}
                                                type="primary" onClick={sendEmail}>
                                                Send Email {selectedRows.length}
                                            </Button>
                                        </div>
                                    ) : null
                                }
                            </Form>
                        </Panel>
                    </Collapse>
                    <div className="mainProductContainer">
                        <div style={{ padding: 10 }}>
                            {
                                loading ? (
                                    <div className=" report-wrapper-main d-flex align-items-center justify-content-center spin-style mt-3">
                                        <Spin size='large' />
                                    </div>
                                ) : (
                                    lines.length > 0 ? (
                                        <Table
                                            title={() =>
                                                <CSVLink
                                                    filename={"invoice-Printing.csv"}
                                                    data={excelData}
                                                    onClick={() => {

                                                    }}
                                                >
                                                    Download Excel
                                                </CSVLink>
                                            }
                                            rowSelection={{
                                                ...rowSelection,
                                            }}
                                            rowKey="invoice_header_id"
                                            scroll={450}
                                            perPage={100}
                                            columns={columns}
                                            dataSource={lines.length > 0 ? lines : []}
                                            loading={lines?.loading}
                                            paginate
                                            pagination={{
                                                defaultCurrent: 1,
                                                defaultPageSize: 3000,
                                                pageSizeOptions: [100, 500, 1000, 2000, 2500, 3000],
                                              }}
                                        />
                                    ) : <div
                                        style={{
                                            backgroundColor: "#CCCCCC",
                                            borderWidth: "1pt",
                                            borderStyle: "Dashed",
                                            fontWeight: "bold",
                                            height: "25px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        No record(s)
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InvoicePending;