import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAllReceiptHeader } from "../../redux/actions/receipt.action";

import SkeletonLoad from "../../components/Skeleton/SkeletonLoad";
import PageHeader from "../../components/PageHeader/PageHeader";

import Tables from "../../components/Table/Tables";
import { receiptColumns } from "../../utils/cols";
import moment from "moment";
import { checkDate, dateChecker } from "../../components/CheckDate/checkDate";
import "../../screens/Product/product.css";
import "../Style/style.css";
import { FilterFilled, SearchOutlined, UndoOutlined, FilterOutlined, CaretRightOutlined, FileAddOutlined, CloudDownloadOutlined, CloudUploadOutlined } from "@ant-design/icons";
import { Modal, Button, Form, Input, Checkbox, Tabs, Tooltip, Divider, Select, DatePicker, Collapse, Row, Col, Space, Upload } from "antd";
import Swal from "sweetalert2";
import { CSVLink } from "react-csv";
import { TabTitle } from "../../utils/GeneralFunction";
const { Panel } = Collapse;

export default function ReceiptList({ token }) {
	TabTitle("Item Receipt")
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const { receiptList } = useSelector((state) => state);
	const dateFormat = "MM-DD-YYYY";
	const worker = {
		from_date: moment().startOf('year'),
		to_date: moment().endOf('year'),
		returnNo: null,
		receiptNo: null,
		postingPeriod: null,
		customerName: null,
		CNno: null,
		InvoiceNo: null,
		raNo: null,
		lotNo: null,

	};


	const onFinishFilter = async (e) => {
		await form.validateFields().then((values) => {



			
			
			
			dateChecker(values.from_date, values.to_date, "itemReceiptList", token, values, dispatch);

			
			
			
			
			
			
			
			
			
			
			
		})
	};


	function downloadExcelFileData(data) {
		if (data) {
			return data.map((v) => {
				return {
					"Item Receipt #": v.receipt_header_no,
					"Receipt Date": moment(v.receipt_date).format("MM-DD-YYYY"),
					"CN #": v.note_header_no,
					"Return #": v.return_header_no,
					"RA Approval #": v.ra_no,
					"Invoice #": v.invoice_header_no,
					"Customer Name": v.customer_name,
					"Lot #": v.lot_no,
					"Posting Period": v.period_name
				};
			})
		}
	}


	return (
		<div className="styleContainer">
			<div className="styleBody h-100">
				<PageHeader title={"Item Receipt List"} />
				<div className="bodyStyle">
					<Collapse style={{ width: "99%", margin: "10px" }} bordered={false} defaultActiveKey={["1"]} expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}>
						<Panel header="Filters" key="1" className="site-collapse-custom-panel" extra={<FilterOutlined />}>
							<Form
								form={form}
								labelCol={{ span: 8 }}
								wrapperCol={{ span: 16 }}
								initialValues={worker}
								onFinish={onFinishFilter}
								autoComplete="off">
								<Row>
									<Col xs={24} xl={8}>
										<Form.Item name="returnNo" label="Return #" rules={[{ pattern: new RegExp(/^(?!0*[.,]0*$|[.,]0*$|0*$)\d+[,.]?\d{0,6}$/), message: "Must Be a Number." }]}>
											<Input
												allowClear
												size="small"
											/>
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item name="receiptNo" label="Item Receipt#" rules={[{ pattern: new RegExp(/^(?!0*[.,]0*$|[.,]0*$|0*$)\d+[,.]?\d{0,6}$/), message: "Must Be a Number." }]}>
											<Input
												allowClear
												size="small"
											/>
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item label="From Date" name="from_date"
										>
											<DatePicker
												size="small"
												id="fromDate"
												defaultValue={form.getFieldValue().fromDate} format={dateFormat} className="dateInput customInputs" />
										</Form.Item>
									</Col>
								</Row>
								<Row>
									<Col xs={24} xl={8}>
										<Form.Item label="To Date" name="to_date"
										>
											<DatePicker
												size="small"
												id="toDate"
												defaultPickerValue={form.getFieldValue().toDate} format={dateFormat} className="dateInput ustomInputs" />
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item name="postingPeriod" label="Posting Period">
											<Input
												allowClear
												size="small"
											/>
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item name="customerName" label="Customer Name">
											<Input
												allowClear
												size="small"
											/>
										</Form.Item>
									</Col>
								</Row>
								<Row>
									<Col xs={24} xl={8}>
										<Form.Item name="CNno" label="CN #">
											<Input
												allowClear
												size="small"
											/>
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item name="InvoiceNo" label="Invoice #" rules={[{ pattern: new RegExp(/^(?!0*[.,]0*$|[.,]0*$|0*$)\d+[,.]?\d{0,6}$/), message: "Must Be a Number." }]}>
											<Input
												allowClear
												size="small"
											/>
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item name="raNo" label="RA Approval #">
											<Input
												allowClear
												size="small"
											/>
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item name="lotNo" label="Lot #">
											<Input
												allowClear
												size="small"
											/>
										</Form.Item>
									</Col>
								</Row>
								<div style={{ display: "flex", justifyContent: "flex-end" }}>
									<Button type="primary" htmlType="submit" disabled={receiptList.loading}>
										Search
									</Button>
								</div>
							</Form>
						</Panel>
					</Collapse>

					{receiptList.loading ? (
						<div className="mainProductContainer">
							<SkeletonLoad />
						</div>
					) : (
						<div className="mainProductContainer">
							<div style={{ padding: 10 }}>
								{
									receiptList?.payload?.data && (
										<CSVLink
											filename={"Item-Reciept.csv"}
											data={downloadExcelFileData(receiptList?.payload?.data) || []}
											onClick={() => {
											}}
										>
											Download Excel
										</CSVLink>
									)
								}
								<Tables
									loadHeight={40}
									loadWidth={40}
									type={"customer"}
									scroll={570}
									perPage={25}
									scrollX={"80rem"}
									col={receiptColumns}
									source={receiptList?.payload?.data}
									load={receiptList.loading}
									paginate
								/>
								{/* <span className="records-text">
                      {dataLen === 0 ? <>No of Records:  <span className="fw-500">  0</span> </> : < > No of Records: <span className="fw-500"> {show ? dataLen : dataLen ? dataLen : "0"}</span>  </>}
                    </span> */}
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}



























































