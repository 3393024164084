import React, { useEffect } from "react";
import "./SalesReport.css";
import CreditMemoReportHeader from "./CreditMemoReportHeader/CreditMemoReportHeader";
import CreditMemoReportFooter from "./CreditMemoReportFooter/CreditMemoReportFooter";
import { useDispatch, useSelector } from "react-redux";
import { ReadCookie } from "../../utils/readCookie";
import { getCreditMemoReportPrint } from "../../redux/actions/creditMemoReport.action";
import { Spin } from "antd";
import { useLocation } from "react-router-dom";
import numeral from "numeral";
import { TabTitle } from "../../utils/GeneralFunction";

const CreditMemoReport = () => {
  TabTitle("Credit Memo Report")
  const dispatch = useDispatch();
  const menuId = ReadCookie("menuId");
  const token = ReadCookie("token");
  const {
    salesReportOrderItem,
    salesReportOrderPrint,
    salesReportColumnValue,
    salesReportOrderColumn,
    creditMemoReportPrint,
  } = useSelector((state) => state);

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();

  let note_header_id = query.get("note_header_id");

  const data = creditMemoReportPrint && creditMemoReportPrint?.payload?.data;

  const values = {
    
    note_header_id: note_header_id,
  };

  useEffect(() => {
    dispatch(getCreditMemoReportPrint(token, values));
  }, []);

  let sumAmount = creditMemoReportPrint?.payload?.data.reduce(function (
    previousValue,
    currentValue
  ) {
    return (previousValue += parseFloat(currentValue.amount));
  },
  0);


  return (
    <div>
      {data ? (
        <div
          className="order_report_view"
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <table>
            <thead>
              <tr>
                <td className="footer">
                  <div>
                    <CreditMemoReportHeader />
                  </div>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className="content-block">
                    <div className="sales-report-Table-data">
                      <table className="sales-report-table">
                        <tbody>
                          <tr>
                            <td
                              className="font_weight"
                              style={{
                                width: "88px",
                                border: "none",
                                height: "30px",
                                backgroundColor: "rgb(184, 184, 184)",
                              }}
                            >
                              Quantity
                            </td>
                            <td
                              className="font_weight"
                              style={{
                                width: "110px",
                                border: "none",
                                backgroundColor: "rgb(184, 184, 184)",
                              }}
                            >
                              Item
                            </td>
                            <td
                              className="font_weight"
                              style={{
                                width: "500px",
                                border: "none",
                                backgroundColor: "rgb(184, 184, 184)",
                              }}
                            >
                              Description
                            </td>
                            <td
                              className="font_weight"
                              style={{
                                width: "110px",
                                border: "none",
                                textAlign: "right",
                                backgroundColor: "rgb(184, 184, 184)",
                              }}
                            >
                              Rate
                            </td>
                            <td
                              className="font_weight"
                              style={{
                                width: "110px",
                                border: "none",
                                textAlign: "right",
                                backgroundColor: "rgb(184, 184, 184)",
                              }}
                            >
                              Discount
                            </td>
                            {/* <td
                              className="font_weight"
                              style={{
                                width: "110px",
                                border: "none",
                                textAlign: "right",
                                backgroundColor: "rgb(184, 184, 184)",
                              }}
                            >
                              Tax
                            </td> */}
                            <td
                              className="font_weight"
                              style={{
                                width: "110px",
                                border: "none",
                                textAlign: "right",
                                paddingRight: "5px",
                                backgroundColor: "rgb(184, 184, 184)",
                              }}
                            >
                              Amount
                            </td>
                          </tr>
                          {data?.map((size, index) => {
                            return (
                              <tr>
                                <td style={{border: "none",height: "20px",}}>{numeral(size.quantity).format("0")}</td>
                                <td
                                  style={{
                                    marginLeft: "0",
                                    border: "none",
                                  }}
                                >
                                  {size.sku}
                                </td>
                                <td style={{border: "none",}}>{size.description}</td>
                                {/* <td className="text-center pl-0">$ {size.rate && size.rate}</td> */}
                                <td style={{border: "none",textAlign: "right",}}>${size.rate && size.rate}</td>
                                <td style={{border: "none",textAlign: "right",}}>${size.discount && size.discount}</td>
                                {/* <td style={{border: "none",textAlign: "right",}}>${size.tax_amount && size.tax_amount}</td> */}
                                <td style={{border: "none",textAlign: "right",paddingRight: "5px",}}>${size.amount && size.amount}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                  <hr/>
                    </div>
                  </div>
                  <table className="sales-report-table-total2">
                    <tbody>
                      <tr className="font_weight">
                        <td
                          style={{
                            width: "118px",
                            textAlign: "right",
                            paddingRight: "10px",
                            border: "none",
                            backgroundColor: "rgb(184, 184, 184)" 
                          }}
                          className="font_weight total_cell"
                        >
                          Total
                        </td>
                        <td
                          className="sum_cell_credit"
                          style={{
                            width: "150px",
                            border: "none",
                            textAlign: "right",
                            paddingRight: "5px",
                            backgroundColor: "rgb(184, 184, 184)" 
                          }}
                        >
                          ${sumAmount && numeral(sumAmount).format("00.00")}
                          
                        </td>
                      </tr>
                      <tr className="font_weight" style={{borderBottom: "1px solid"}}>
                        <td
                          style={{
                            width: "118px",
                            textAlign: "right",
                            paddingRight: "10px",
                            border: "none",
                            backgroundColor: "rgb(184, 184, 184)" 
                          }}
                          className="font_weight total_cell"
                        >
                          Re-Stocking fee
                        </td>
                        <td
                          className="sum_cell_credit"
                          style={{
                            width: "150px",
                            border: "none",
                            textAlign: "right",
                            paddingRight: "5px",
                            backgroundColor: "rgb(184, 184, 184)" 
                          }}
                        >
                          ${data && numeral(data[0].re_stock).format("00.00")}
                        </td>
                      </tr>
                      <tr className="font_weight">
                        <td
                          style={{
                            width: "118px",
                            textAlign: "right",
                            paddingRight: "10px",
                            border: "none",
                            backgroundColor: "rgb(184, 184, 184)" 
                          }}
                          className="font_weight total_cell"
                        >
                          Net Total
                        </td>
                        <td
                          className="sum_cell_credit"
                          style={{
                            width: "150px",
                            border: "none",
                            textAlign: "right",
                            paddingRight: "5px",
                            backgroundColor: "rgb(184, 184, 184)" 
                          }}
                        >
                          ${data && numeral(sumAmount - data[0].re_stock).format("00.00")}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
            <tfoot>
                <tr>
                  <td className="footer2">

                    <div className="sales-report-footer">
                      <CreditMemoReportFooter />
                    </div>
                  </td>
                </tr>
              </tfoot>
          </table>
        </div>
      ) : (
        <div className="spin">
          <Spin size="large" />
        </div>
      )}
    </div>
  );
};

export default CreditMemoReport;
