export const ADMIN_LOGIN_REQUEST = "ADMIN_LOGIN_REQUEST";
export const ADMIN_LOGIN_ERROR = "ADMIN_LOGIN_ERROR";
export const ADMIN_LOGIN_SUCCESS = "ADMIN_LOGIN_SUCCESS";
export const ADMIN_LOGOUT = "ADMIN_LOGOUT";

export const ADMIN_MENU_REQUEST = "ADMIN_MENU_REQUEST";
export const ADMIN_MENU_ERROR = "ADMIN_MENU_ERROR";
export const ADMIN_MENU_SUCCESS = "ADMIN_MENU_SUCCESS";

export const GET_STYLES_REQUEST = "GET_STYLES_REQUEST";
export const GET_STYLES_ERROR = "GET_STYLES_ERROR";
export const GET_STYLES_SUCCESS = "GET_STYLES_SUCCESS";

export const ADD_STYLES_REQUEST = "ADD_STYLES_REQUEST";
export const ADD_STYLES_ERROR = "ADD_STYLES_ERROR";
export const ADD_STYLES_SUCCESS = "ADD_STYLES_SUCCESS";

export const UPDATE_STYLES_REQUEST = "UPDATE_STYLES_REQUEST";
export const UPDATE_STYLES_ERROR = "UPDATE_STYLES_ERROR";
export const UPDATE_STYLES_SUCCESS = "UPDATE_STYLES_SUCCESS";

export const DELETE_STYLES_REQUEST = "DELETE_STYLES_REQUEST";
export const DELETE_STYLES_ERROR = "DELETE_STYLES_ERROR";
export const DELETE_STYLES_SUCCESS = "DELETE_STYLES_SUCCESS";

export const GET_COMPANY_ACCESS_GRID_REQUEST = "GET_COMPANY_ACCESS_GRID_REQUEST";
export const GET_COMPANY_ACCESS_GRID_ERROR = "GET_COMPANY_ACCESS_GRID_ERROR";
export const GET_COMPANY_ACCESS_GRID_SUCCESS = "GET_COMPANY_ACCESS_GRID_SUCCESS";

export const UPDATE_COMPANY_ACCESS_GRID_REQUEST = "UPDATE_COMPANY_ACCESS_GRID_REQUEST";
export const UPDATE_COMPANY_ACCESS_GRID_ERROR = "UPDATE_COMPANY_ACCESS_GRID_ERROR";
export const UPDATE_COMPANY_ACCESS_GRID_SUCCESS = "UPDATE_COMPANY_ACCESS_GRID_SUCCESS";

export const DELETE_COMPANY_ACCESS_GRID_REQUEST = "DELETE_COMPANY_ACCESS_GRID_REQUEST";
export const DELETE_COMPANY_ACCESS_GRID_ERROR = "DELETE_COMPANY_ACCESS_GRID_ERROR";
export const DELETE_COMPANY_ACCESS_GRID_SUCCESS = "DELETE_COMPANY_ACCESS_GRID_SUCCESS";

export const GET_ALL_COMPANY_ACCESS_GRID_REQUEST = "GET_ALL_COMPANY_ACCESS_GRID_REQUEST";
export const GET_ALL_COMPANY_ACCESS_GRID_ERROR = "GET_ALL_COMPANY_ACCESS_GRID_ERROR";
export const GET_ALL_COMPANY_ACCESS_GRID_SUCCESS = "GET_ALL_COMPANY_ACCESS_GRID_SUCCESS";

export const CREATE_COMPANY_ACCESS_GRID_REQUEST = "CREATE_COMPANY_ACCESS_GRID_REQUEST";
export const CREATE_COMPANY_ACCESS_GRID_ERROR = "CREATE_COMPANY_ACCESS_GRID_ERROR";
export const CREATE_COMPANY_ACCESS_GRID_SUCCESS = "CREATE_COMPANY_ACCESS_GRID_SUCCESS";

export const GET_LOG_REQUEST = "GET_LOG_REQUEST";
export const GET_LOG_ERROR = "GET_LOG_ERROR";
export const GET_LOG_SUCCESS = "GET_LOG_SUCCESS";

export const GET_DEFAULT_COMPANY_REQUEST = "GET_DEFAULT_COMPANY_REQUEST";
export const GET_DEFAULT_COMPANY_ERROR = "GET_DEFAULT_COMPANY_ERROR";
export const GET_DEFAULT_COMPANY_SUCCESS = "GET_DEFAULT_COMPANY_SUCCESS";

export const GET_DETAIL_REQUEST = "GET_DETAIL_REQUEST";
export const GET_DETAIL_ERROR = "GET_DETAIL_ERROR";
export const GET_DETAIL_SUCCESS = "GET_DETAIL_SUCCESS";

export const GET_ALL_ITEM_REQUEST = "GET_ALL_ITEM_REQUEST";
export const GET_ALL_ITEM_ERROR = "GET_ALL_ITEM_ERROR";
export const GET_ALL_ITEM_SUCCESS = "GET_ALL_ITEM_SUCCESS";

export const GET_ONE_ITEM_REQUEST = "GET_ONE_ITEM_REQUEST";
export const GET_ONE_ITEM_ERROR = "GET_ONE_ITEM_ERROR";
export const GET_ONE_ITEM_SUCCESS = "GET_ONE_ITEM_SUCCESS";

export const ADD_ITEM_REQUEST = "ADD_ITEM_REQUEST";
export const ADD_ITEM_ERROR = "ADD_ITEM_ERROR";
export const ADD_ITEM_SUCCESS = "ADD_ITEM_SUCCESS";

export const DELETE_ITEM_REQUEST = "DELETE_ITEM_REQUEST";
export const DELETE_ITEM_ERROR = "DELETE_ITEM_ERROR";
export const DELETE_ITEM_SUCCESS = "DELETE_ITEM_SUCCESS";

export const UPDATE_ITEM_REQUEST = "UPDATE_ITEM_REQUEST";
export const UPDATE_ITEM_ERROR = "UPDATE_ITEM_ERROR";
export const UPDATE_ITEM_SUCCESS = "UPDATE_ITEM_SUCCESS";


export const GET_ONE_DEFAULT_COMPANY_REQUEST = "GET_ONE_DEFAULT_COMPANY_REQUEST";
export const GET_ONE_DEFAULT_COMPANY_SUCCESS = "GET_ONE_DEFAULT_COMPANY_SUCCESS";
export const GET_ONE_DEFAULT_COMPANY_ERROR = "GET_ONE_DEFAULT_COMPANY_ERROR";


export const GET_WASH_REQUEST = "GET_WASH_REQUEST";
export const GET_WASH_ERROR = "GET_WASH_ERROR";
export const GET_WASH_SUCCESS = "GET_WASH_SUCCESS";

export const ADD_WASH_REQUEST = "ADD_WASH_REQUEST";
export const ADD_WASH_ERROR = "ADD_WASH_ERROR";
export const ADD_WASH_SUCCESS = "ADD_WASH_SUCCESS";

export const UPDATE_WASH_REQUEST = "UPDATE_WASH_REQUEST";
export const UPDATE_WASH_ERROR = "UPDATE_WASH_ERROR";
export const UPDATE_WASH_SUCCESS = "UPDATE_WASH_SUCCESS";

export const DELETE_WASH_REQUEST = "DELETE_WASH_REQUEST";
export const DELETE_WASH_ERROR = "DELETE_WASH_ERROR";
export const DELETE_WASH_SUCCESS = "DELETE_WASH_SUCCESS";


export const GET_WASHTYPE_REQUEST = "GET_WASHTYPE_REQUEST";
export const GET_WASHTYPE_ERROR = "GET_WASHTYPE_ERROR";
export const GET_WASHTYPE_SUCCESS = "GET_WASHTYPE_SUCCESS";

export const ADD_WASHTYPE_REQUEST = "ADD_WASHTYPE_REQUEST";
export const ADD_WASHTYPE_ERROR = "ADD_WASHTYPE_ERROR";
export const ADD_WASHTYPE_SUCCESS = "ADD_WASHTYPE_SUCCESS";

export const UPDATE_WASHTYPE_REQUEST = "UPDATE_WASHTYPE_REQUEST";
export const UPDATE_WASHTYPE_ERROR = "UPDATE_WASHTYPE_ERROR";
export const UPDATE_WASHTYPE_SUCCESS = "UPDATE_WASHTYPE_SUCCESS";

export const DELETE_WASHTYPE_REQUEST = "DELETE_WASHTYPE_REQUEST";
export const DELETE_WASHTYPE_ERROR = "DELETE_WASHTYPE_ERROR";
export const DELETE_WASHTYPE_SUCCESS = "DELETE_WASHTYPE_SUCCESS";


export const GET_CUT_REQUEST = "GET_CUT_REQUEST";
export const GET_CUT_ERROR = "GET_CUT_ERROR";
export const GET_CUT_SUCCESS = "GET_CUT_SUCCESS";

export const ADD_CUT_REQUEST = "ADD_CUT_REQUEST";
export const ADD_CUT_ERROR = "ADD_CUT_ERROR";
export const ADD_CUT_SUCCESS = "ADD_CUT_SUCCESS";

export const UPDATE_CUT_REQUEST = "UPDATE_CUT_REQUEST";
export const UPDATE_CUT_ERROR = "UPDATE_CUT_ERROR";
export const UPDATE_CUT_SUCCESS = "UPDATE_CUT_SUCCESS";

export const DELETE_CUT_REQUEST = "DELETE_CUT_REQUEST";
export const DELETE_CUT_ERROR = "DELETE_CUT_ERROR";
export const DELETE_CUT_SUCCESS = "DELETE_CUT_SUCCESS";


export const GET_FABRIC_REQUEST = "GET_FABRIC_REQUEST";
export const GET_FABRIC_ERROR = "GET_FABRIC_ERROR";
export const GET_FABRIC_SUCCESS = "GET_FABRIC_SUCCESS";

export const ADD_FABRIC_REQUEST = "ADD_FABRIC_REQUEST";
export const ADD_FABRIC_ERROR = "ADD_FABRIC_ERROR";
export const ADD_FABRIC_SUCCESS = "ADD_FABRIC_SUCCESS";

export const UPDATE_FABRIC_REQUEST = "UPDATE_FABRIC_REQUEST";
export const UPDATE_FABRIC_ERROR = "UPDATE_FABRIC_ERROR";
export const UPDATE_FABRIC_SUCCESS = "UPDATE_FABRIC_SUCCESS";

export const DELETE_FABRIC_REQUEST = "DELETE_FABRIC_REQUEST";
export const DELETE_FABRIC_ERROR = "DELETE_FABRIC_ERROR";
export const DELETE_FABRIC_SUCCESS = "DELETE_FABRIC_SUCCESS";


export const GET_FITCATEGORY_REQUEST = "GET_FITCATEGORY_REQUEST";
export const GET_FITCATEGORY_ERROR = "GET_FITCATEGORY_ERROR";
export const GET_FITCATEGORY_SUCCESS = "GET_FITCATEGORY_SUCCESS";

export const ADD_FITCATEGORY_REQUEST = "ADD_FITCATEGORY_REQUEST";
export const ADD_FITCATEGORY_ERROR = "ADD_FITCATEGORY_ERROR";
export const ADD_FITCATEGORY_SUCCESS = "ADD_FITCATEGORY_SUCCESS";

export const UPDATE_FITCATEGORY_REQUEST = "UPDATE_FITCATEGORY_REQUEST";
export const UPDATE_FITCATEGORY_ERROR = "UPDATE_FITCATEGORY_ERROR";
export const UPDATE_FITCATEGORY_SUCCESS = "UPDATE_FITCATEGORY_SUCCESS";

export const DELETE_FITCATEGORY_REQUEST = "DELETE_FITCATEGORY_REQUEST";
export const DELETE_FITCATEGORY_ERROR = "DELETE_FITCATEGORY_ERROR";
export const DELETE_FITCATEGORY_SUCCESS = "DELETE_FITCATEGORY_SUCCESS";


export const GET_GENDER_REQUEST = "GET_GENDER_REQUEST";
export const GET_GENDER_ERROR = "GET_GENDER_ERROR";
export const GET_GENDER_SUCCESS = "GET_GENDER_SUCCESS";

export const ADD_GENDER_REQUEST = "ADD_GENDER_REQUEST";
export const ADD_GENDER_ERROR = "ADD_GENDER_ERROR";
export const ADD_GENDER_SUCCESS = "ADD_GENDER_SUCCESS";

export const UPDATE_GENDER_REQUEST = "UPDATE_GENDER_REQUEST";
export const UPDATE_GENDER_ERROR = "UPDATE_GENDER_ERROR";
export const UPDATE_GENDER_SUCCESS = "UPDATE_GENDER_SUCCESS";

export const DELETE_GENDER_REQUEST = "DELETE_GENDER_REQUEST";
export const DELETE_GENDER_ERROR = "DELETE_GENDER_ERROR";
export const DELETE_GENDER_SUCCESS = "DELETE_GENDER_SUCCESS";


export const GET_GENDERCATEGORY_REQUEST = "GET_GENDERCATEGORY_REQUEST";
export const GET_GENDERCATEGORY_ERROR = "GET_GENDERCATEGORY_ERROR";
export const GET_GENDERCATEGORY_SUCCESS = "GET_GENDERCATEGORY_SUCCESS";

export const ADD_GENDERCATEGORY_REQUEST = "ADD_GENDERCATEGORY_REQUEST";
export const ADD_GENDERCATEGORY_ERROR = "ADD_GENDERCATEGORY_ERROR";
export const ADD_GENDERCATEGORY_SUCCESS = "ADD_GENDERCATEGORY_SUCCESS";

export const UPDATE_GENDERCATEGORY_REQUEST = "UPDATE_GENDERCATEGORY_REQUEST";
export const UPDATE_GENDERCATEGORY_ERROR = "UPDATE_GENDERCATEGORY_ERROR";
export const UPDATE_GENDERCATEGORY_SUCCESS = "UPDATE_GENDERCATEGORY_SUCCESS";

export const DELETE_GENDERCATEGORY_REQUEST = "DELETE_GENDERCATEGORY_REQUEST";
export const DELETE_GENDERCATEGORY_ERROR = "DELETE_GENDERCATEGORY_ERROR";
export const DELETE_GENDERCATEGORY_SUCCESS = "DELETE_GENDERCATEGORY_SUCCESS";


export const GET_RISE_REQUEST = "GET_RISE_REQUEST";
export const GET_RISE_ERROR = "GET_RISE_ERROR";
export const GET_RISE_SUCCESS = "GET_RISE_SUCCESS";

export const ADD_RISE_REQUEST = "ADD_RISE_REQUEST";
export const ADD_RISE_ERROR = "ADD_RISE_ERROR";
export const ADD_RISE_SUCCESS = "ADD_RISE_SUCCESS";

export const UPDATE_RISE_REQUEST = "UPDATE_RISE_REQUEST";
export const UPDATE_RISE_ERROR = "UPDATE_RISE_ERROR";
export const UPDATE_RISE_SUCCESS = "UPDATE_RISE_SUCCESS";

export const DELETE_RISE_REQUEST = "DELETE_RISE_REQUEST";
export const DELETE_RISE_ERROR = "DELETE_RISE_ERROR";
export const DELETE_RISE_SUCCESS = "DELETE_RISE_SUCCESS";


export const GET_PRODUCTCLASS_REQUEST = "GET_PRODUCTCLASS_REQUEST";
export const GET_PRODUCTCLASS_ERROR = "GET_PRODUCTCLASS_ERROR";
export const GET_PRODUCTCLASS_SUCCESS = "GET_PRODUCTCLASS_SUCCESS";

export const ADD_PRODUCTCLASS_REQUEST = "ADD_PRODUCTCLASS_REQUEST";
export const ADD_PRODUCTCLASS_ERROR = "ADD_PRODUCTCLASS_ERROR";
export const ADD_PRODUCTCLASS_SUCCESS = "ADD_PRODUCTCLASS_SUCCESS";

export const UPDATE_PRODUCTCLASS_REQUEST = "UPDATE_PRODUCTCLASS_REQUEST";
export const UPDATE_PRODUCTCLASS_ERROR = "UPDATE_PRODUCTCLASS_ERROR";
export const UPDATE_PRODUCTCLASS_SUCCESS = "UPDATE_PRODUCTCLASS_SUCCESS";

export const DELETE_PRODUCTCLASS_REQUEST = "DELETE_PRODUCTCLASS_REQUEST";
export const DELETE_PRODUCTCLASS_ERROR = "DELETE_PRODUCTCLASS_ERROR";
export const DELETE_PRODUCTCLASS_SUCCESS = "DELETE_PRODUCTCLASS_SUCCESS";


export const GET_PRODUCTTYPE_REQUEST = "GET_PRODUCTTYPE_REQUEST";
export const GET_PRODUCTTYPE_ERROR = "GET_PRODUCTTYPE_ERROR";
export const GET_PRODUCTTYPE_SUCCESS = "GET_PRODUCTTYPE_SUCCESS";

export const ADD_PRODUCTTYPE_REQUEST = "ADD_PRODUCTTYPE_REQUEST";
export const ADD_PRODUCTTYPE_ERROR = "ADD_PRODUCTTYPE_ERROR";
export const ADD_PRODUCTTYPE_SUCCESS = "ADD_PRODUCTTYPE_SUCCESS";

export const UPDATE_PRODUCTTYPE_REQUEST = "UPDATE_PRODUCTTYPE_REQUEST";
export const UPDATE_PRODUCTTYPE_ERROR = "UPDATE_PRODUCTTYPE_ERROR";
export const UPDATE_PRODUCTTYPE_SUCCESS = "UPDATE_PRODUCTTYPE_SUCCESS";

export const DELETE_PRODUCTTYPE_REQUEST = "DELETE_PRODUCTTYPE_REQUEST";
export const DELETE_PRODUCTTYPE_ERROR = "DELETE_PRODUCTTYPE_ERROR";
export const DELETE_PRODUCTTYPE_SUCCESS = "DELETE_PRODUCTTYPE_SUCCESS";


export const GET_INSEAMLABEL_REQUEST = "GET_INSEAMLABEL_REQUEST";
export const GET_INSEAMLABEL_ERROR = "GET_INSEAMLABEL_ERROR";
export const GET_INSEAMLABEL_SUCCESS = "GET_INSEAMLABEL_SUCCESS";

export const ADD_INSEAMLABEL_REQUEST = "ADD_INSEAMLABEL_REQUEST";
export const ADD_INSEAMLABEL_ERROR = "ADD_INSEAMLABEL_ERROR";
export const ADD_INSEAMLABEL_SUCCESS = "ADD_INSEAMLABEL_SUCCESS";

export const UPDATE_INSEAMLABEL_REQUEST = "UPDATE_INSEAMLABEL_REQUEST";
export const UPDATE_INSEAMLABEL_ERROR = "UPDATE_INSEAMLABEL_ERROR";
export const UPDATE_INSEAMLABEL_SUCCESS = "UPDATE_INSEAMLABEL_SUCCESS";

export const DELETE_INSEAMLABEL_REQUEST = "DELETE_INSEAMLABEL_REQUEST";
export const DELETE_INSEAMLABEL_ERROR = "DELETE_INSEAMLABEL_ERROR";
export const DELETE_INSEAMLABEL_SUCCESS = "DELETE_INSEAMLABEL_SUCCESS";


export const GET_PRODUCTSTATUS_REQUEST = "GET_PRODUCTSTATUS_REQUEST";
export const GET_PRODUCTSTATUS_ERROR = "GET_PRODUCTSTATUS_ERROR";
export const GET_PRODUCTSTATUS_SUCCESS = "GET_PRODUCTSTATUS_SUCCESS";

export const ADD_PRODUCTSTATUS_REQUEST = "ADD_PRODUCTSTATUS_REQUEST";
export const ADD_PRODUCTSTATUS_ERROR = "ADD_PRODUCTSTATUS_ERROR";
export const ADD_PRODUCTSTATUS_SUCCESS = "ADD_PRODUCTSTATUS_SUCCESS";

export const UPDATE_PRODUCTSTATUS_REQUEST = "UPDATE_PRODUCTSTATUS_REQUEST";
export const UPDATE_PRODUCTSTATUS_ERROR = "UPDATE_PRODUCTSTATUS_ERROR";
export const UPDATE_PRODUCTSTATUS_SUCCESS = "UPDATE_PRODUCTSTATUS_SUCCESS";

export const DELETE_PRODUCTSTATUS_REQUEST = "DELETE_PRODUCTSTATUS_REQUEST";
export const DELETE_PRODUCTSTATUS_ERROR = "DELETE_PRODUCTSTATUS_ERROR";
export const DELETE_PRODUCTSTATUS_SUCCESS = "DELETE_PRODUCTSTATUS_SUCCESS";


export const GET_RISELABEL_REQUEST = "GET_RISELABEL_REQUEST";
export const GET_RISELABEL_ERROR = "GET_RISELABEL_ERROR";
export const GET_RISELABEL_SUCCESS = "GET_RISELABEL_SUCCESS";

export const ADD_RISELABEL_REQUEST = "ADD_RISELABEL_REQUEST";
export const ADD_RISELABEL_ERROR = "ADD_RISELABEL_ERROR";
export const ADD_RISELABEL_SUCCESS = "ADD_RISELABEL_SUCCESS";

export const UPDATE_RISELABEL_REQUEST = "UPDATE_RISELABEL_REQUEST";
export const UPDATE_RISELABEL_ERROR = "UPDATE_RISELABEL_ERROR";
export const UPDATE_RISELABEL_SUCCESS = "UPDATE_RISELABEL_SUCCESS";

export const DELETE_RISELABEL_REQUEST = "DELETE_RISELABEL_REQUEST";
export const DELETE_RISELABEL_ERROR = "DELETE_RISELABEL_ERROR";
export const DELETE_RISELABEL_SUCCESS = "DELETE_RISELABEL_SUCCESS";


export const GET_SEASON_REQUEST = "GET_SEASON_REQUEST";
export const GET_SEASON_ERROR = "GET_SEASON_ERROR";
export const GET_SEASON_SUCCESS = "GET_SEASON_SUCCESS";

export const ADD_SEASON_REQUEST = "ADD_SEASON_REQUEST";
export const ADD_SEASON_ERROR = "ADD_SEASON_ERROR";
export const ADD_SEASON_SUCCESS = "ADD_SEASON_SUCCESS";

export const UPDATE_SEASON_REQUEST = "UPDATE_SEASON_REQUEST";
export const UPDATE_SEASON_ERROR = "UPDATE_SEASON_ERROR";
export const UPDATE_SEASON_SUCCESS = "UPDATE_SEASON_SUCCESS";

export const DELETE_SEASON_REQUEST = "DELETE_SEASON_REQUEST";
export const DELETE_SEASON_ERROR = "DELETE_SEASON_ERROR";
export const DELETE_SEASON_SUCCESS = "DELETE_SEASON_SUCCESS";


export const GET_INSEAM_REQUEST = "GET_INSEAM_REQUEST";
export const GET_INSEAM_ERROR = "GET_INSEAM_ERROR";
export const GET_INSEAM_SUCCESS = "GET_INSEAM_SUCCESS";

export const ADD_INSEAM_REQUEST = "ADD_INSEAM_REQUEST";
export const ADD_INSEAM_ERROR = "ADD_INSEAM_ERROR";
export const ADD_INSEAM_SUCCESS = "ADD_INSEAM_SUCCESS";

export const UPDATE_INSEAM_REQUEST = "UPDATE_INSEAM_REQUEST";
export const UPDATE_INSEAM_ERROR = "UPDATE_INSEAM_ERROR";
export const UPDATE_INSEAM_SUCCESS = "UPDATE_INSEAM_SUCCESS";

export const DELETE_INSEAM_REQUEST = "DELETE_INSEAM_REQUEST";
export const DELETE_INSEAM_ERROR = "DELETE_INSEAM_ERROR";
export const DELETE_INSEAM_SUCCESS = "DELETE_INSEAM_SUCCESS";


export const GET_SIZE_REQUEST = "GET_SIZE_REQUEST";
export const GET_SIZE_ERROR = "GET_SIZE_ERROR";
export const GET_SIZE_SUCCESS = "GET_SIZE_SUCCESS";

export const CREATE_ITEM_INSEAM_REQUEST = "CREATE_ITEM_INSEAM_REQUEST";
export const CREATE_ITEM_INSEAM_ERROR = "CREATE_ITEM_INSEAM_ERROR";
export const CREATE_ITEM_INSEAM_SUCCESS = "CREATE_ITEM_INSEAM_SUCCESS";

export const CREATE_ITEM_SIZE_REQUEST = "CREATE_ITEM_SIZE_REQUEST";
export const CREATE_ITEM_SIZE_ERROR = "CREATE_ITEM_SIZE_ERROR";
export const CREATE_ITEM_SIZE_SUCCESS = "CREATE_ITEM_SIZE_SUCCESS";

export const CREATE_COMBINATION_REQUEST = "CREATE_COMBINATION_REQUEST";
export const CREATE_COMBINATION_ERROR = "CREATE_COMBINATION_ERROR";
export const CREATE_COMBINATION_SUCCESS = "CREATE_COMBINATION_SUCCESS";

export const GET_COMBINATION_REQUEST = "GET_COMBINATION_REQUEST";
export const GET_COMBINATION_ERROR = "GET_COMBINATION_ERROR";
export const GET_COMBINATION_SUCCESS = "GET_COMBINATION_SUCCESS";
export const ADD_SIZE_REQUEST = "ADD_SIZE_REQUEST";
export const ADD_SIZE_ERROR = "ADD_SIZE_ERROR";
export const ADD_SIZE_SUCCESS = "ADD_SIZE_SUCCESS";

export const UPDATE_SIZE_REQUEST = "UPDATE_SIZE_REQUEST";
export const UPDATE_SIZE_ERROR = "UPDATE_SIZE_ERROR";
export const UPDATE_SIZE_SUCCESS = "UPDATE_SIZE_SUCCESS";

export const DELETE_SIZE_REQUEST = "DELETE_SIZE_REQUEST";
export const DELETE_SIZE_ERROR = "DELETE_SIZE_ERROR";
export const DELETE_SIZE_SUCCESS = "DELETE_SIZE_SUCCESS";
export const DELETE_CUSTOMERS = "DELETE_CUSTOMERS";
export const GET_CUSTOMERS_TYPE = "GET_CUSTOMERS_TYPE";
export const GET_ORDER_STATUS_REQUEST = "GET_ORDER_STATUS_REQUEST";
export const GET_ORDER_ERROR = "GET_ORDER_ERROR";
export const GET_ORDER_SUCCESS = "GET_ORDER_SUCCESS";
export const GET_CUSTOMER_BY_ID = "GET_CUSTOMER_BY_ID";
export const DELETE_CUSTOMER_REQUEST = "DELETE_CUSTOMER_REQUEST";
export const DELETE_CUSTOMER_SUCCESS = "DELETE_CUSTOMER_SUCCESS";
export const DELETE_CUSTOMER_ERROR = "DELETE_CUSTOMER_ERROR";
export const UPDATE_CUSTOMER_REQUEST = "UPDATE_CUSTOMER_REQUEST";
export const UPDATE_CUSTOMER_SUCCESS = "UPDATE_CUSTOMER_SUCCESS";
export const UPDATE_CUSTOMER_ERROR = "UPDATE_CUSTOMER_ERROR";

export const GET_ALL_CUSTOMERS_REQUEST = "GET_ALL_CUSTOMERS_REQUEST";
export const GET_ALL_CUSTOMERS_SUCCESS = "GET_ALL_CUSTOMERS_SUCCESS";
export const GET_ALL_CUSTOMERS_ERROR = "GET_ALL_CUSTOMERS_ERROR";
export const ADD_CUSTOMER_REQUEST = "ADD_CUSTOMER_REQUEST";
export const ADD_CUSTOMER_SUCCESS = "ADD_CUSTOMER_SUCCESS";
export const ADD_CUSTOMER_ERROR = "ADD_CUSTOMER_ERROR";
export const GET_ONE_CUSTOMER_REQUEST = "GET_ONE_CUSTOMER_REQUEST";
export const GET_ONE_CUSTOMER_SUCCESS = "GET_ONE_CUSTOMER_SUCCESS";
export const GET_ONE_CUSTOMER_ERROR = "GET_ONE_CUSTOMER_ERROR";

export const ADD_BILLING_REQUEST = "ADD_BILLING_REQUEST";
export const ADD_BILLING_SUCCESS = "ADD_BILLING_SUCCESS";
export const ADD_BILLING_ERROR = "ADD_BILLING_ERROR";

export const ADD_SHIPPING_REQUEST = "ADD_SHIPPING_REQUEST";
export const ADD_SHIPPING_SUCCESS = "ADD_SHIPPING_SUCCESS";
export const ADD_SHIPPING_ERROR = "ADD_SHIPPING_ERROR";





export const GET_SHIPPING_ERROR = "GET_SHIPPING_ERROR";
export const GET_SHIPPING_REQUEST = "GET_SHIPPING_REQUEST";
export const GET_SHIPPING_SUCCESS = "GET_SHIPPING_SUCCESS";

export const GET_BILLING_ERROR = "GET_BILLING_ERROR";
export const GET_BILLING_REQUEST = "GET_BILLING_REQUEST";
export const GET_BILLING_SUCCESS = "GET_BILLING_SUCCESS";

export const DELETE_BILLING_REQUEST = "DELETE_BILLING_REQUEST";
export const DELETE_BILLING_SUCCESS = "DELETE_BILLING_SUCCESS";
export const DELETE_BILLING_ERROR = "DELETE_BILLING_ERROR";

export const UPDATE_BILLING_REQUEST = "UPDATE_BILLING_REQUEST";
export const UPDATE_BILLING_SUCCESS = "UPDATE_BILLING_SUCCESS";
export const UPDATE_BILLING_ERROR = "UPDATE_BILLING_ERROR";

export const DELETE_SHIPPING_REQUEST = "DELETE_SHIPPING_REQUEST";
export const DELETE_SHIPPING_SUCCESS = "DELETE_SHIPPING_SUCCESS";
export const DELETE_SHIPPING_ERROR = "DELETE_SHIPPING_ERROR";

export const UPDATE_SHIPPING_REQUEST = "UPDATE_SHIPPING_REQUEST";
export const UPDATE_SHIPPING_SUCCESS = "UPDATE_SHIPPING_SUCCESS";
export const UPDATE_SHIPPING_ERROR = "UPDATE_SHIPPING_ERROR";



export const GET_COUNTRY_REQUEST = "GET_COUNTRY_REQUEST";
export const GET_COUNTRY_SUCCESS = "GET_COUNTRY_SUCCESS";
export const GET_COUNTRY_ERROR = "GET_COUNTRY_ERROR";

export const ADD_COUNTRY_REQUEST = "ADD_COUNTRY_REQUEST";
export const ADD_COUNTRY_SUCCESS = "ADD_COUNTRY_SUCCESS";
export const ADD_COUNTRY_ERROR = "ADD_COUNTRY_ERROR";

export const UPDATE_COUNTRY_REQUEST = "UPDATE_COUNTRY_REQUEST";
export const UPDATE_COUNTRY_SUCCESS = "UPDATE_COUNTRY_SUCCESS";
export const UPDATE_COUNTRY_ERROR = "UPDATE_COUNTRY_ERROR";

export const DELETE_COUNTRY_REQUEST = "DELETE_COUNTRY_REQUEST";
export const DELETE_COUNTRY_SUCCESS = "DELETE_COUNTRY_SUCCESS";
export const DELETE_COUNTRY_ERROR = "DELETE_COUNTRY_ERROR";

export const GET_STATE_REQUEST = "GET_STATE_REQUEST";
export const GET_STATE_SUCCESS = "GET_STATE_SUCCESS";
export const GET_STATE_ERROR = "GET_STATE_ERROR";

export const ADD_STATE_REQUEST = "ADD_STATE_REQUEST";
export const ADD_STATE_SUCCESS = "ADD_STATE_SUCCESS";
export const ADD_STATE_ERROR = "ADD_STATE_ERROR";

export const UPDATE_STATE_REQUEST = "UPDATE_STATE_REQUEST";
export const UPDATE_STATE_SUCCESS = "UPDATE_STATE_SUCCESS";
export const UPDATE_STATE_ERROR = "UPDATE_STATE_ERROR";

export const DELETE_STATE_REQUEST = "DELETE_STATE_REQUEST";
export const DELETE_STATE_SUCCESS = "DELETE_STATE_SUCCESS";
export const DELETE_STATE_ERROR = "DELETE_STATE_ERROR";

export const GET_CITY_REQUEST = "GET_CITY_REQUEST";
export const GET_CITY_SUCCESS = "GET_CITY_SUCCESS";
export const GET_CITY_ERROR = "GET_CITY_ERROR";

export const GET_REGION_REQUEST = "GET_REGION_REQUEST";
export const GET_REGION_SUCCESS = "GET_REGION_SUCCESS";
export const GET_REGION_ERROR = "GET_REGION_ERROR";

export const GET_SALESPERSON_REQUEST = "GET_SALESPERSON_REQUEST";
export const GET_SALESPERSON_SUCCESS = "GET_SALESPERSON_SUCCESS";
export const GET_SALESPERSON_ERROR = "GET_SALESPERSON_ERROR";

export const DELETE_SALEPERSON_REQUEST = "DELETE_SALEPERSON_REQUEST";
export const DELETE_SALEPERSON_SUCCESS = "DELETE_SALEPERSON_SUCCESS";
export const DELETE_SALEPERSON_ERROR = "DELETE_SALEPERSON_ERROR,";

export const UPDATE_SALEPERSON_REQUEST = "UPDATE_SALEPERSON_REQUEST";
export const UPDATE_SALEPERSON_SUCCESS = "UPDATE_SALEPERSON_SUCCESS";
export const UPDATE_SALEPERSON_ERROR = "UPDATE_SALEPERSON_ERROR";

export const ADD_SALEPERSON_REQUEST = "ADD_SALEPERSON_REQUEST";
export const ADD_SALEPERSON_SUCCESS = "ADD_SALEPERSON_SUCCESS";
export const ADD_SALEPERSON_ERROR = "ADD_SALEPERSON_ERROR";

export const GET_ONE_SALEPERSON_REQUEST = "GET_ONE_SALEPERSON_REQUEST";
export const GET_ONE_SALEPERSON_SUCCESS = "GET_ONE_SALEPERSON_SUCCESS";
export const GET_ONE_SALEPERSON_ERROR = "GET_ONE_SALEPERSON_ERROR";

export const GET_ALL_SALEPERSON_REQUEST = "GET_ALL_SALEPERSON_REQUEST";
export const GET_ALL_SALEPERSON_SUCCESS = "GET_ALL_SALEPERSON_SUCCESS";
export const GET_ALL_SALEPERSON_ERROR = "GET_ALL_SALEPERSON_ERROR";

export const GET_SUPERVISOR_REQUEST = "GET_SUPERVISOR_REQUEST";
export const GET_SUPERVISOR_SUCCESS = "GET_SUPERVISOR_SUCCESS";
export const GET_SUPERVISOR_ERROR = "GET_SUPERVISOR_ERROR";

export const GET_JOBTITLE_REQUEST = "GET_JOBTITLE_REQUEST";
export const GET_JOBTITLE_SUCCESS = "GET_JOBTITLE_SUCCESS";
export const GET_JOBTITLE_ERROR = "GET_JOBTITLE_ERROR";

export const GET_REPORT_CURRENCY_REQUEST = "GET_REPORT_CURRENCY_REQUEST";
export const GET_REPORT_CURRENCY_SUCCESS = "GET_REPORT_CURRENCY_SUCCESS";
export const GET_REPORT_CURRENCY_ERROR = "GET_REPORT_CURRENCY_ERROR";

export const GET_REGIONCATG_REQUEST = "GET_REGIONCATG_REQUEST";
export const GET_REGIONCATG_SUCCESS = "GET_REGIONCATG_SUCCESS";
export const GET_REGIONCATG_ERROR = "GET_REGIONCATG_ERROR";

export const ADD_REGIONCATG_REQUEST = "ADD_REGIONCATG_REQUEST";
export const ADD_REGIONCATG_SUCCESS = "ADD_REGIONCATG_SUCCESS";
export const ADD_REGIONCATG_ERROR = "ADD_REGIONCATG_ERROR";

export const UPDATE_REGIONCATG_REQUEST = "UPDATE_REGIONCATG_REQUEST";
export const UPDATE_REGIONCATG_SUCCESS = "UPDATE_REGIONCATG_SUCCESS";
export const UPDATE_REGIONCATG_ERROR = "UPDATE_REGIONCATG_ERROR";

export const DELETE_REGIONCATG_REQUEST = "DELETE_REGIONCATG_REQUEST";
export const DELETE_REGIONCATG_SUCCESS = "DELETE_REGIONCATG_SUCCESS";
export const DELETE_REGIONCATG_ERROR = "DELETE_REGIONCATG_ERROR";

export const GET_CATEGORY_ERROR = "GET_CATEGORY_ERROR";
export const GET_CATEGORY_REQUEST = "GET_CATEGORY_REQUEST";
export const GET_CATEGORY_SUCCESS = "GET_CATEGORY_SUCCESS";

export const GET_ALL_CATEGORY_ERROR = "GET_ALL_CATEGORY_ERROR ";
export const GET_ALL_CATEGORY_REQUEST = "GET_ALL_CATEGORY_REQUEST ";
export const GET_ALL_CATEGORY_SUCCESS = "GET_ALL_CATEGORY_SUCCESS ";

export const ADD_CUSTOMER_CATEGORY_SUCCESS = "ADD_CUSTOMER_CATEGORY_SUCCESS";
export const ADD_CUSTOMER_CATEGORY_REQUEST = "ADD_CUSTOMER_CATEGORY_REQUEST";
export const ADD_CUSTOMER_CATEGORY_ERROR = "ADD_CUSTOMER_CATEGORY_ERROR ";

export const ADD_CATEGORY_REQUEST = "ADD_CATEGORY_REQUEST";
export const ADD_CATEGORY_SUCCESS = "ADD_CATEGORY_SUCCESS";
export const ADD_CATEGORY_ERROR = "ADD_CATEGORY_ERROR";

export const DELETE_CATEGORY_REQUEST = "DELETE_CATEGORY_REQUEST";
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS";
export const DELETE_CATEGORY_ERROR = "DELETE_CATEGORY_ERROR";

export const UPDATE_CATEGORY_REQUEST = "UPDATE_CATEGORY_REQUEST";
export const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS";
export const UPDATE_CATEGORY_ERROR = "UPDATE_CATEGORY_ERROR";

export const GET_SHIP_VIA_REQUEST = "GET_SHIP_VIA_REQUEST";
export const GET_SHIP_VIA_SUCCESS = "GET_SHIP_VIA_SUCCESS";
export const GET_SHIP_VIA_ERROR = "GET_SHIP_VIA_ERROR";

export const GET_COMMIT_CRITERIA_REQUEST = "GET_COMMIT_CRITERIA_REQUEST";
export const GET_COMMIT_CRITERIA_SUCCESS = "GET_COMMIT_CRITERIA_SUCCESS";
export const GET_COMMIT_CRITERIA_ERROR = "GET_COMMIT_CRITERIA_ERROR";

export const GET_SHIPPING_METHOD_REQUEST = "GET_SHIPPING_METHOD_REQUEST";
export const GET_SHIPPING_METHOD_SUCCESS = "GET_SHIPPING_METHOD_SUCCESS";
export const GET_SHIPPING_METHOD_ERROR = "GET_SHIPPING_METHOD_ERROR";

export const ADD_SHIPPING_METHOD_REQUEST = "ADD_SHIPPING_METHOD_REQUEST";
export const ADD_SHIPPING_METHOD_SUCCESS = "ADD_SHIPPING_METHOD_SUCCESS";
export const ADD_SHIPPING_METHOD_ERROR = "ADD_SHIPPING_METHOD_ERROR";

export const UPDATE_SHIPPING_METHOD_REQUEST = "UPDATE_SHIPPING_METHOD_REQUEST";
export const UPDATE_SHIPPING_METHOD_SUCCESS = "UPDATE_SHIPPING_METHOD_SUCCESS";
export const UPDATE_SHIPPING_METHOD_ERROR = "UPDATE_SHIPPING_METHOD_ERROR";

export const DELETE_SHIPPING_METHOD_REQUEST = "DELETE_SHIPPING_METHOD_REQUEST";
export const DELETE_SHIPPING_METHOD_SUCCESS = "DELETE_SHIPPING_METHOD_SUCCESS";
export const DELETE_SHIPPING_METHOD_ERROR = "DELETE_SHIPPING_METHOD_ERROR";

export const GET_FREIGHT_TERM_REQUEST = "GET_FREIGHT_TERM_REQUEST";
export const GET_FREIGHT_TERM_SUCCESS = "GET_FREIGHT_TERM_SUCCESS";
export const GET_FREIGHT_TERM_ERROR = "GET_FREIGHT_TERM_ERROR";

export const ADD_FREIGHT_TERM_REQUEST = "ADD_FREIGHT_TERM_REQUEST";
export const ADD_FREIGHT_TERM_SUCCESS = "ADD_FREIGHT_TERM_SUCCESS";
export const ADD_FREIGHT_TERM_ERROR = "ADD_FREIGHT_TERM_ERROR";

export const UPDATE_FREIGHT_TERM_REQUEST = "UPDATE_FREIGHT_TERM_REQUEST";
export const UPDATE_FREIGHT_TERM_SUCCESS = "UPDATE_FREIGHT_TERM_SUCCESS";
export const UPDATE_FREIGHT_TERM_ERROR = "UPDATE_FREIGHT_TERM_ERROR";

export const DELETE_FREIGHT_TERM_REQUEST = "DELETE_FREIGHT_TERM_REQUEST";
export const DELETE_FREIGHT_TERM_SUCCESS = "DELETE_FREIGHT_TERM_SUCCESS";
export const DELETE_FREIGHT_TERM_ERROR = "DELETE_FREIGHT_TERM_ERROR";

export const GET_BERGEN_BILLING_REQUEST = "GET_BERGEN_BILLING_REQUEST";
export const GET_BERGEN_BILLING_SUCCESS = "GET_BERGEN_BILLING_SUCCESS";
export const GET_BERGEN_BILLING_ERROR = "GET_BERGEN_BILLING_ERROR";

export const ADD_BERGEN_BILLING_REQUEST = "ADD_BERGEN_BILLING_REQUEST";
export const ADD_BERGEN_BILLING_SUCCESS = "ADD_BERGEN_BILLING_SUCCESS";
export const ADD_BERGEN_BILLING_ERROR = "ADD_BERGEN_BILLING_ERROR";

export const UPDATE_BERGEN_BILLING_REQUEST = "UPDATE_BERGEN_BILLING_REQUEST";
export const UPDATE_BERGEN_BILLING_SUCCESS = "UPDATE_BERGEN_BILLING_SUCCESS";
export const UPDATE_BERGEN_BILLING_ERROR = "UPDATE_BERGEN_BILLING_ERROR";

export const DELETE_BERGEN_BILLING_REQUEST = "GET_DELETE_BILLING_REQUEST";
export const DELETE_BERGEN_BILLING_SUCCESS = "GET_DELETE_BILLING_SUCCESS";
export const DELETE_BERGEN_BILLING_ERROR = "GET_DELETE_BILLING_ERROR";

export const GET_REGIONCATG_BYID_REQUEST = "GET_REGIONCATG_BYID_REQUEST";
export const GET_REGIONCATG_BYID_SUCCESS = "GET_REGIONCATG_BYID_SUCCESS";
export const GET_REGIONCATG_BYID_ERROR = "GET_REGIONCATG_BYID_ERROR";

export const GET_USER_RIGHTS_REQUEST = "GET_USER_RIGHTS_REQUEST";
export const GET_USER_RIGHTS_SUCCESS = "GET_USER_RIGHTS_SUCCESS";
export const GET_USER_RIGHTS_ERROR = "GET_USER_RIGHTS_ERROR";



export const GET_COMBINATION_FORM_REQUEST = "GET_COMBINATION_FORM_REQUEST";
export const GET_COMBINATION_FORM_SUCCESS = "GET_COMBINATION_FORM_SUCCESS";
export const GET_COMBINATION_FORM_ERROR = "GET_COMBINATION_FORM_ERROR";

export const GET_STYLE_COMBINATION_REQUEST = "GET_STYLE_COMBINATION_REQUEST";
export const GET_STYLE_COMBINATION_SUCCESS = "GET_STYLE_COMBINATION_SUCCESS";
export const GET_STYLE_COMBINATION_ERROR = "GET_STYLE_COMBINATION_ERROR";

export const GET_ONE_STYLE_COMBINATION_REQUEST = "GET_ONE_STYLE_COMBINATION_REQUEST";
export const GET_ONE_STYLE_COMBINATION_SUCCESS = "GET_ONE_STYLE_COMBINATION_SUCCESS";
export const GET_ONE_STYLE_COMBINATION_ERROR = "GET_ONE_STYLE_COMBINATION_ERROR";

export const ADD_STYLE_COMBINATION_REQUEST = "ADD_STYLE_COMBINATION_REQUEST";
export const ADD_STYLE_COMBINATION_SUCCESS = "ADD_STYLE_COMBINATION_SUCCESS";
export const ADD_STYLE_COMBINATION_ERROR = "ADD_STYLE_COMBINATION_ERROR";

export const DELETE_STYLE_COMBINATION_REQUEST = "DELETE_STYLE_COMBINATION_REQUEST";
export const DELETE_STYLE_COMBINATION_SUCCESS = "DELETE_STYLE_COMBINATION_SUCCESS";
export const DELETE_STYLE_COMBINATION_ERROR = "DELETE_STYLE_COMBINATION_ERROR";

export const EDIT_STYLE_COMBINATION_REQUEST = "EDIT_STYLE_COMBINATION_REQUEST";
export const EDIT_STYLE_COMBINATION_SUCCESS = "EDIT_STYLE_COMBINATION_SUCCESS";
export const EDIT_STYLE_COMBINATION_ERROR = "EDIT_STYLE_COMBINATION_ERROR";





export const GET_ALL_SALESORDERLINE_REQUEST = "GET_ALL_SALESORDERLINE_REQUEST";
export const GET_ALL_SALESORDERLINE_ERROR = "GET_ALL_SALESORDERLINE_ERROR";
export const GET_ALL_SALESORDERLINE_SUCCESS = "GET_ALL_SALESORDERLINE_SUCCESS";

export const GET_ONE_SALESORDERLINE_REQUEST = "GET_ONE_SALESORDERLINE_REQUEST";
export const GET_ONE_SALESORDERLINE_ERROR = "GET_ONE_SALESORDERLINE_ERROR";
export const GET_ONE_SALESORDERLINE_SUCCESS = "GET_ONE_SALESORDERLINE_SUCCESS";

export const GET_ONE_LINES_REQUEST = "GET_ONE_LINES_REQUEST";
export const GET_ONE_LINES_ERROR = "GET_ONE_LINES_ERROR";
export const GET_ONE_LINES_SUCCESS = "GET_ONE_LINES_SUCCESS";

export const UPDATE_ONE_LINES_REQUEST = "UPDATE_ONE_LINES_REQUEST";
export const UPDATE_ONE_LINES_ERROR = "UPDATE_ONE_LINES_ERROR";
export const UPDATE_ONE_LINES_SUCCESS = "UPDATE_ONE_LINES_SUCCESS";





export const DELETE_SALESORDERLINE_REQUEST = "DELETE_SALESORDERLINE_REQUEST";
export const DELETE_SALESORDERLINE_ERROR = "DELETE_SALESORDERLINE_ERROR";
export const DELETE_SALESORDERLINE_SUCCESS = "DELETE_SALESORDERLINE_SUCCESS";



export const GET_SALEORDERFORMDATA_REQUEST = "GET_SALEORDERFORMDATA_REQUEST";
export const GET_SALEORDERFORMDATA_ERROR = "GET_SALEORDERFORMDATA_ERROR";
export const GET_SALEORDERFORMDATA_SUCCESS = "GET_SALEORDERFORMDATA_SUCCESS";


export const ADD_SALEORDER_HEADER_REQUEST = "ADD_SALEORDER_HEADER_REQUEST";
export const ADD_SALEORDER_HEADER_SUCCESS = "ADD_SALEORDER_HEADER_SUCCESS";
export const ADD_SALEORDER_HEADER_ERROR = "ADD_SALEORDER_HEADER_ERROR";

export const GET_ALL_SALEORDER_SUCCESS = "GET_ALL_SALEORDER_SUCCESS";
export const GET_ALL_SALEORDER_REQUEST = "GET_ALL_SALEORDER_REQUEST";
export const GET_ALL_SALEORDER_ERROR = "GET_ALL_SALEORDER_ERROR";

export const GET_ONE_SALEORDERHEADER_SUCCESS = "GET_ONE_SALEORDERHEADER_SUCCESS";
export const GET_ONE_SALEORDERHEADER_REQUEST = "GET_ONE_SALEORDERHEADER_REQUEST";
export const GET_ONE_SALEORDERHEADER_ERROR = "GET_ONE_SALEORDERHEADER_ERROR";

export const UPDATE_SALEORDER_HEADER_REQUEST = "UPDATE_SALEORDER_HEADER_REQUEST";
export const UPDATE_SALEORDER_HEADER_ERROR = "UPDATE_SALEORDER_HEADER_ERROR";
export const UPDATE_SALEORDER_HEADER_SUCCESS = "UPDATE_SALEORDER_HEADER_SUCCESS";

export const DELETE_SALEORDERHEADER_SUCCESS = "DELETE_SALEORDERHEADER_SUCCESS";
export const DELETE_SALEORDERHEADER_REQUEST = "DELETE_SALEORDERHEADER_REQUEST";
export const DELETE_SALEORDERHEADER_ERROR = "DELETE_SALEORDERHEADER_ERROR";



export const GET_ONE_CUSTOMER_BILL_SUCCESS = "GET_ONE_CUSTOMER_BILL_SUCCESS";
export const GET_ONE_CUSTOMER_BILL_REQUEST = "GET_ONE_CUSTOMER_BILL_REQUEST";
export const GET_ONE_CUSTOMER_BILL_ERROR = "GET_ONE_CUSTOMER_BILL_ERROR";



export const GET_ONE_CUSTOMER_SHIP_SUCCESS = "GET_ONE_CUSTOMER_SHIP_SUCCESS";
export const GET_ONE_CUSTOMER_SHIP_REQUEST = "GET_ONE_CUSTOMER_SHIP_REQUEST";
export const GET_ONE_CUSTOMER_SHIP_ERROR = "GET_ONE_CUSTOMER_SHIP_ERROR";


export const GET_GENDER_HEADER_DATA_REQUEST = "GET_GENDER_HEADER_DATA_REQUEST";
export const GET_GENDER_HEADER_DATA_SUCCESS = "GET_GENDER_HEADER_DATA_SUCCESS";
export const GET_GENDER_HEADER_DATA_ERROR = "GET_GENDER_HEADER_DATA_ERROR";



export const GET_BILLINGSHIPPINGDETAILS_REQUEST = "GET_BILLINGSHIPPINGDETAILS_REQUEST";
export const GET_BILLINGSHIPPINGDETAILS_SUCCESS = "GET_BILLINGSHIPPINGDETAILS_SUCCESS";
export const GET_BILLINGSHIPPINGDETAILS_ERROR = "GET_BILLINGSHIPPINGDETAILS_ERROR";



export const GET_CUSTOMER_SINGLE_BILLING_REQUEST = "GET_CUSTOMER_SINGLE_BILLING_REQUEST";
export const GET_CUSTOMER_SINGLE_BILLING_SUCCESS = "GET_CUSTOMER_SINGLE_BILLING_SUCCESS";
export const GET_CUSTOMER_SINGLE_BILLING_ERROR = "GET_CUSTOMER_SINGLE_BILLING_ERROR";



export const GET_CUSTOMER_SINGLE_SHIPPING_REQUEST = "GET_CUSTOMER_SINGLE_SHIPPING_REQUEST";
export const GET_CUSTOMER_SINGLE_SHIPPING_SUCCESS = "GET_CUSTOMER_SINGLE_SHIPPING_SUCCESS";
export const GET_CUSTOMER_SINGLE_SHIPPING_ERROR = "GET_CUSTOMER_SINGLE_SHIPPING_ERROR";



export const GET_PRODUCTSALE_REQUEST = "GET_PRODUCTSALE_REQUEST";
export const GET_PRODUCTSALE_SUCCESS = "GET_PRODUCTSALE_SUCCESS";
export const GET_PRODUCTSALE_ERROR = "GET_PRODUCTSALE_ERROR";




export const GET_PRODUCTSALETRANSFER_REQUEST = "GET_PRODUCTSALETRANSFER_REQUEST";
export const GET_PRODUCTSALETRANSFER_SUCCESS = "GET_PRODUCTSALETRANSFER_SUCCESS";
export const GET_PRODUCTSALETRANSFER_ERROR = "GET_PRODUCTSALETRANSFER_ERROR";




export const GET_ADMIN_REGION_REQUEST = "GET_ADMIN_REGION_REQUEST";
export const GET_ADMIN_REGION_SUCCESS = "GET_ADMIN_REGION_SUCCESS ";
export const GET_ADMIN_REGION_ERROR = "GET_ADMIN_REGION_ERROR";

export const ADD_ADMIN_REGION_REQUEST = "ADD_ADMIN_REGION_REQUEST";
export const ADD_ADMIN_REGION_SUCCESS = "ADD_ADMIN_REGION_SUCCESS ";
export const ADD_ADMIN_REGION_ERROR = "ADD_ADMIN_REGION_ERROR ";

export const UPDATE_ADMIN_REGION_REQUEST = "ADD_ADMIN_REGION_REQUEST ";
export const UPDATE_ADMIN_REGION_SUCCESS = "UPDATE_ADMIN_REGION_SUCCESS ";
export const UPDATE_ADMIN_REGION_ERROR = "UPDATE_ADMIN_REGION_ERROR ";

export const DELETE_ADMIN_REGION_REQUEST = "DELETE_ADMIN_REGION_REQUEST ";
export const DELETE_ADMIN_REGION_SUCCESS = "DELETE_ADMIN_REGION_SUCCESS ";
export const DELETE_ADMIN_REGION_ERROR = "DELETE_ADMIN_REGION_ERROR ";







export const ADD_CITY_REQUEST = "ADD_CITY_REQUEST";
export const ADD_CITY_SUCCESS = "ADD_CITY_SUCCESS";
export const ADD_CITY_ERROR = "ADD_CITY_ERROR";

export const UPDATE_CITY_REQUEST = "ADD_CITY_REQUEST";
export const UPDATE_CITY_SUCCESS = "UPDATE_CITY_SUCCESS";
export const UPDATE_CITY_ERROR = "UPDATE_CITY_ERROR";

export const DELETE_CITY_REQUEST = "DELETE_CITY_REQUEST";
export const DELETE_CITY_SUCCESS = "DELETE_CITY_SUCCESS";
export const DELETE_CITY_ERROR = "DELETE_CITY_ERROR";







export const ADD_SHIP_VIA_REQUEST = "ADD_SHIP_VIA_REQUEST";
export const ADD_SHIP_VIA_SUCCESS = "ADD_SHIP_VIA_SUCCESS";
export const ADD_SHIP_VIA_ERROR = "ADD_SHIP_VIA_ERROR";

export const UPDATE_SHIP_VIA_REQUEST = "UPDATE_SHIP_VIA_REQUEST";
export const UPDATE_SHIP_VIA_SUCCESS = "UPDATE_SHIP_VIA_SUCCESS";
export const UPDATE_SHIP_VIA_ERROR = "UPDATE_SHIP_VIA_ERROR";

export const DELETE_SHIP_VIA_REQUEST = "DELETE_SHIP_VIA_REQUEST";
export const DELETE_SHIP_VIA_SUCCESS = "DELETE_SHIP_VIA_SUCCESS";
export const DELETE_SHIP_VIA_ERROR = "DELETE_SHIP_VIA_ERROR";

export const GET_COURIER_SERVICE_REQUEST = "GET_COURIER_SERVICE_REQUEST";
export const GET_COURIER_SERVICE_SUCCESS = "GET_COURIER_SERVICE_SUCCESS";
export const GET_COURIER_SERVICE_ERROR = "GET_COURIER_SERVICE_ERROR";

export const ADD_COURIER_SERVICE_REQUEST = "ADD_COURIER_SERVICE_REQUEST";
export const ADD_COURIER_SERVICE_SUCCESS = "ADD_COURIER_SERVICE_SUCCESS";
export const ADD_COURIER_SERVICE_ERROR = "ADD_COURIER_SERVICE_ERROR";

export const DELETE_COURIER_SERVICE_REQUEST = "DELETE_COURIER_SERVICE_REQUEST";
export const DELETE_COURIER_SERVICE_SUCCESS = "DELETE_COURIER_SERVICE_SUCCESS";
export const DELETE_COURIER_SERVICE_ERROR = "DELETE_COURIER_SERVICE_ERROR";

export const UPDATE_COURIER_SERVICE_REQUEST = "UPDATE_COURIER_SERVICE_REQUEST";
export const UPDATE_COURIER_SERVICE_SUCCESS = "UPDATE_COURIER_SERVICE_SUCCESS";
export const UPDATE_COURIER_SERVICE_ERROR = "UPDATE_COURIER_SERVICE_ERROR";

export const GET_ADMIN_USER_REQUEST = "GET_ADMIN_USER_REQUEST";
export const GET_ADMIN_USER_SUCCESS = "GET_ADMIN_USER_SUCCESS";
export const GET_ADMIN_USER_ERROR = "GET_ADMIN_USER_ERROR";

export const ADD_ADMIN_USER_REQUEST = "ADD_ADMIN_USER_REQUEST";
export const ADD_ADMIN_USER_SUCCESS = "ADD_ADMIN_USER_SUCCESS";
export const ADD_ADMIN_USER_ERROR = "ADD_ADMIN_USER_ERROR";

export const UPDATE_ADMIN_USER_REQUEST = "UPDATE_ADMIN_USER_REQUEST";
export const UPDATE_ADMIN_USER_SUCCESS = "UPDATE_ADMIN_USER_SUCCESS";
export const UPDATE_ADMIN_USER_ERROR = "UPDATE_ADMIN_USER_ERROR";

export const DELETE_ADMIN_USER_REQUEST = "DELETE_ADMIN_USER_REQUEST";
export const DELETE_ADMIN_USER_SUCCESS = "DELETE_ADMIN_USER_SUCCESS";
export const DELETE_ADMIN_USER_ERROR = "DELETE_ADMIN_USER_ERROR";

export const GET_SINGLE_ADMIN_MENU_REQUEST = "GET_SINGLE_ADMIN_MENU_REQUEST";
export const GET_SINGLE_ADMIN_MENU_SUCCESS = "GET_SINGLE_ADMIN_MENU_SUCCESS";
export const GET_SINGLE_ADMIN_MENU_ERROR = "GET_SINGLE_ADMIN_MENU_ERROR";

export const GET_MENU_RIGHTS_REQUEST = "GET_MENU_RIGHTS_REQUEST";
export const GET_MENU_RIGHTS_SUCCESS = "GET_MENU_RIGHTS_SUCCESS";
export const GET_MENU_RIGHTS_ERROR = "GET_MENU_RIGHTS_ERROR";

export const ADD_MENU_RIGHTS_REQUEST = "ADD_MENU_RIGHTS_REQUEST";
export const ADD_MENU_RIGHTS_SUCCESS = "ADD_MENU_RIGHTS_SUCCESS";
export const ADD_MENU_RIGHTS_ERROR = "ADD_MENU_RIGHTS_ERROR";

export const UPDATE_MENU_RIGHTS_REQUEST = "UPDATE_MENU_RIGHTS_REQUEST";
export const UPDATE_MENU_RIGHTS_SUCCESS = "UPDATE_MENU_RIGHTS_SUCCESS";
export const UPDATE_MENU_RIGHTS_ERROR = "UPDATE_MENU_RIGHTS_ERROR";
export const GET_ADMIN_MENU_FORM_REQUEST = "ADMIN_MENU_FORM_REQUEST";
export const GET_ADMIN_MENU_FORM_SUCCESS = "ADMIN_MENU_FORM_SUCCESS";
export const GET_ADMIN_MENU_FORM_ERROR = "ADMIN_MENU_FORM_ERROR";

export const GET_ALL_PARENT_MENU_REQUEST = "GET_ALL_PARENT_MENU_REQUEST";
export const GET_ALL_PARENT_MENU_SUCCESS = "GET_ALL_PARENT_MENU_SUCCESS";
export const GET_ALL_PARENT_MENU_ERROR = "GET_ALL_PARENT_MENU_ERROR";

export const ADD_ADMIN_MENU_REQUEST = "ADD_ADMIN_MENU_REQUEST";
export const ADD_ADMIN_MENU_SUCCESS = "ADD_ADMIN_MENU_SUCCESS";
export const ADD_ADMIN_MENU_ERROR = "ADD_ADMIN_MENU_ERROR";

export const GET_ALL_ADMIN_MENU_REQUEST = "GET_ALL_ADMIN_MENU_REQUEST";
export const GET_ALL_ADMIN_MENU_SUCCESS = "GET_ALL_ADMIN_MENU_SUCCESS";
export const GET_ALL_ADMIN_MENU_ERROR = "GET_ALL_ADMIN_MENU_ERROR";

export const DELETE_ADMIN_MENU_REQUEST = "DELETE_ADMIN_MENU_REQUEST";
export const DELETE_ADMIN_MENU_SUCCESS = "DELETE_ADMIN_MENU_SUCCESS";
export const DELETE_ADMIN_MENU_ERROR = "DELETE_ADMIN_MENU_ERROR";

export const UPDATE_ADMIN_MENU_REQUEST = "ADD_ADMIN_MENU_REQUEST";
export const UPDATE_ADMIN_MENU_SUCCESS = "UPDATE_ADMIN_MENU_SUCCESS";
export const UPDATE_ADMIN_MENU_ERROR = "UPDATE_ADMIN_MENU_ERROR";

export const GET_PAYMENT_TERMS_REQUEST = "GET_PAYMENT_TERM_REQUEST";
export const GET_PAYMENT_TERMS_SUCCESS = "GET_PAYMENT_TERM_SUCCESS";
export const GET_PAYMENT_TERMS_ERROR = "GET_PAYMENT_TERM_ERROR";

export const ADD_PAYMENT_TERMS_REQUEST = "ADD_PAYMENT_TERM_REQUEST";
export const ADD_PAYMENT_TERMS_SUCCESS = "ADD_PAYMENT_TERM_SUCCESS";
export const ADD_PAYMENT_TERMS_ERROR = "ADD_PAYMENT_TERM_ERROR";

export const UPDATE_PAYMENT_TERMS_REQUEST = "UPDATE_PAYMENT_TERM_REQUEST";
export const UPDATE_PAYMENT_TERMS_SUCCESS = "UPDATE_PAYMENT_TERM_SUCCESS";
export const UPDATE_PAYMENT_TERMS_ERROR = "UPDATE_PAYMENT_TERM_ERROR";

export const DELETE_PAYMENT_TERMS_REQUEST = "DELETE_PAYMENT_TERM_REQUEST";
export const DELETE_PAYMENT_TERMS_SUCCESS = "DELETE_PAYMENT_TERM_SUCCESS";
export const DELETE_PAYMENT_TERMS_ERROR = "DELETE_PAYMENT_TERM_ERROR";

export const GET_TAX_SETUP_REQUEST = "GET_TAX_SETUP_REQUEST";
export const GET_TAX_SETUP_SUCCESS = "GET_TAX_SETUP_SUCCESS";
export const GET_TAX_SETUP_ERROR = "GET_TAX_SETUP_ERROR";

export const ADD_TAX_SETUP_REQUEST = "ADD_TAX_SETUP_REQUEST";
export const ADD_TAX_SETUP_SUCCESS = "ADD_TAX_SETUP_SUCCESS";
export const ADD_TAX_SETUP_ERROR = "ADD_TAX_SETUP_ERROR";

export const UPDATE_TAX_SETUP_REQUEST = "UPDATE_TAX_SETUP_REQUEST";
export const UPDATE_TAX_SETUP_SUCCESS = "UPDATE_TAX_SETUP_SUCCESS";
export const UPDATE_TAX_SETUP_ERROR = "UPDATE_TAX_SETUP_ERROR";

export const DELETE_TAX_SETUP_REQUEST = "DELETE_TAX_SETUP_REQUEST";
export const DELETE_TAX_SETUP_SUCCESS = "DELETE_TAX_SETUP_SUCCESS";
export const DELETE_TAX_SETUP_ERROR = "DELETE_TAX_SETUP_ERROR";

export const GET_ALL_ORDER_SHIPMENT_SUCCESS = "GET_ALL_ORDER_SHIPMENT_SUCCESS";
export const GET_ALL_ORDER_SHIPMENT_REQUEST = "GET_ALL_ORDER_SHIPMENT_REQUEST";
export const GET_ALL_ORDER_SHIPMENT_ERROR = "GET_ALL_ORDER_SHIPMENT_ERROR";

export const GET_ONE_ORDER_SHIPMENT_SUCCESS = "GET_ONE_ORDER_SHIPMENT_SUCCESS";
export const GET_ONE_ORDER_SHIPMENT_REQUEST = "GET_ONE_ORDER_SHIPMENT_REQUEST";
export const GET_ONE_ORDER_SHIPMENT_ERROR = "GET_ONE_ORDER_SHIPMENT_ERROR";

export const GET_ALL_ORDER_SHIPMENT_LINE_REQUEST = "GET_ALL_ORDER_SHIPMENT_LINE_REQUEST";
export const GET_ALL_ORDER_SHIPMENT_LINE_SUCCESS = "GET_ALL_ORDER_SHIPMENT_LINE_SUCCESS";
export const GET_ALL_ORDER_SHIPMENT_LINE_ERROR = "GET_ALL_ORDER_SHIPMENT_LINE_ERROR";

export const GET_ONE_ORDER_SHIPMENT_LINE_REQUEST = "GET_ONE_ORDER_SHIPMENT_LINE_REQUEST";
export const GET_ONE_ORDER_SHIPMENT_LINE_SUCCESS = "GET_ONE_ORDER_SHIPMENT_LINE_SUCCESS";
export const GET_ONE_ORDER_SHIPMENT_LINE_ERROR = "GET_ONE_ORDER_SHIPMENT_LINE_ERROR";

export const DELETE_ORDER_SHIPMENT_LINE_REQUEST = "DELETE_ORDER_SHIPMENT_LINE_REQUEST";
export const DELETE_ORDER_SHIPMENT_LINE_SUCCESS = "DELETE_ORDER_SHIPMENT_LINE_SUCCESS";
export const DELETE_ORDER_SHIPMENT_LINE_ERROR = "DELETE_ORDER_SHIPMENT_LINE_ERROR";

export const MARK_SHIP_REQUEST = "MARK_SHIP_REQUEST";
export const MARK_SHIP_SUCCESS = "MARK_SHIP_SUCCESS";
export const MARK_SHIP_ERROR = "MARK_SHIP_ERROR";

export const GET_ORDER_SHIPMENT_FORM_DATA_REQUEST = "GET_ORDER_SHIPMENT_FORM_DATA_REQUEST";
export const GET_ORDER_SHIPMENT_FORM_DATA_SUCCESS = "GET_ORDER_SHIPMENT_FORM_DATA_SUCCESS";
export const GET_ORDER_SHIPMENT_FORM_DATA_ERROR = "GET_ORDER_SHIPMENT_FORM_DATA_ERROR";

export const ADD_ORDER_SHIPMENT_HEADER_REQUEST = "ADD_ORDER_SHIPMENT_HEADER_REQUEST";
export const ADD_ORDER_SHIPMENT_HEADER_SUCCESS = "ADD_ORDER_SHIPMENT_HEADER_SUCCESS";
export const ADD_ORDER_SHIPMENT_HEADER_ERROR = "ADD_ORDER_SHIPMENT_HEADER_ERROR";

export const UPDATE_ORDER_SHIPMENT_HEADER_REQUEST = "UPDATE_ORDER_SHIPMENT_HEADER_REQUEST";
export const UPDATE_ORDER_SHIPMENT_HEADER_SUCCESS = "UPDATE_ORDER_SHIPMENT_HEADER_SUCCESS";
export const UPDATE_ORDER_SHIPMENT_HEADER_ERROR = "UPDATE_ORDER_SHIPMENT_HEADER_ERROR";

export const ADD_ORDER_SHIPMENT_LINE_REQUEST = "ADD_ORDER_SHIPMENT_LINE_REQUEST";
export const ADD_ORDER_SHIPMENT_LINE_SUCCESS = "ADD_ORDER_SHIPMENT_LINE_SUCCESS";
export const ADD_ORDER_SHIPMENT_LINE_ERROR = "ADD_ORDER_SHIPMENT_LINE_ERROR";

export const DELETE_ORDER_SHIPMENT_REQUEST = "DELETE_ORDER_SHIPMENT_REQUEST";
export const DELETE_ORDER_SHIPMENT_SUCCESS = "DELETE_ORDER_SHIPMENT_SUCCESS";
export const DELETE_ORDER_SHIPMENT_ERROR = "DELETE_ORDER_SHIPMENT_ERROR";
export const GET_CHART_OF_ACCOUNT_REQUEST = "GET_CHART_OF_ACCOUNT_REQUEST";
export const GET_CHART_OF_ACCOUNT_SUCCESS = "GET_CHART_OF_ACCOUNT_SUCCESS";
export const GET_CHART_OF_ACCOUNT_ERROR = "GET_CHART_OF_ACCOUNT_ERROR";

export const ADD_CHART_OF_ACCOUNT_REQUEST = "ADD_CHART_OF_ACCOUNT_REQUEST";
export const ADD_CHART_OF_ACCOUNT_SUCCESS = "ADD_CHART_OF_ACCOUNT_SUCCESS";
export const ADD_CHART_OF_ACCOUNT_ERROR = "ADD_CHART_OF_ACCOUNT_ERROR";

export const UPDATE_CHART_OF_ACCOUNT_REQUEST = "UPDATE_CHART_OF_ACCOUNT_REQUEST";
export const UPDATE_CHART_OF_ACCOUNT_SUCCESS = "UPDATE_CHART_OF_ACCOUNT_SUCCESS";
export const UPDATE_CHART_OF_ACCOUNT_ERROR = "UPDATE_CHART_OF_ACCOUNT_ERROR";

export const DELETE_CHART_OF_ACCOUNT_REQUEST = "DELETE_CHART_OF_ACCOUNT_REQUEST";
export const DELETE_CHART_OF_ACCOUNT_SUCCESS = "DELETE_CHART_OF_ACCOUNT_SUCCESS";
export const DELETE_CHART_OF_ACCOUNT_ERROR = "DELETE_CHART_OF_ACCOUNT_ERROR";





export const GET_ALL_INVOICE_HEADER_REQUEST = "GET_ALL_INVOICE_HEADER_REQUEST";
export const GET_ALL_INVOICE_HEADER_SUCCESS = "GET_ALL_INVOICE_HEADER_SUCCESS";
export const GET_ALL_INVOICE_HEADER_ERROR = "GET_ALL_INVOICE_HEADER_ERROR";

export const DELETE_INVOICE_HEADER_REQUEST = "DELETE_INVOICE_HEADER_REQUEST";
export const DELETE_INVOICE_HEADER_SUCCESS = "DELETE_INVOICE_HEADER_SUCCESS";
export const DELETE_INVOICE_HEADER_ERROR = "DELETE_INVOICE_HEADER_ERROR";

export const GET_ONE_INVOICE_HEADER_REQUEST = "GET_ONE_INVOICE_HEADER_REQUEST";
export const GET_ONE_INVOICE_HEADER_SUCCESS = "GET_ONE_INVOICE_HEADER_SUCCESS";
export const GET_ONE_INVOICE_HEADER_ERROR = "GET_ONE_INVOICE_HEADER_ERROR";

export const ADD_INVOICE_HEADER_REQUEST = "ADD_INVOICE_HEADER_REQUEST";
export const ADD_INVOICE_HEADER_SUCCESS = "ADD_INVOICE_HEADER_SUCCESS";
export const ADD_INVOICE_HEADER_ERROR = "ADD_INVOICE_HEADER_ERROR";

export const UPDATE_INVOICE_HEADER_REQUEST = "UPDATE_INVOICE_HEADER_REQUEST";
export const UPDATE_INVOICE_HEADER_SUCCESS = "UPDATE_INVOICE_HEADER_SUCCESS";
export const UPDATE_INVOICE_HEADER_ERROR = "UPDATE_INVOICE_HEADER_ERROR";

export const GET_INVOICE_FORM_DATA_REQUEST = "GET_INVOICE_FORM_DATA_REQUEST";
export const GET_INVOICE_FORM_DATA_SUCCESS = "GET_INVOICE_FORM_DATA_SUCCESS";
export const GET_INVOICE_FORM_DATA_ERROR = "GET_INVOICE_FORM_DATA_ERROR";

export const GET_ALL_INVOICE_HEADER_LINE_REQUEST = "GET_ALL_INVOICE_HEADER_LINE_REQUEST";
export const GET_ALL_INVOICE_HEADER_LINE_SUCCESS = "GET_ALL_INVOICE_HEADER_LINE_SUCCESS";
export const GET_ALL_INVOICE_HEADER_LINE_ERROR = "GET_ALL_INVOICE_HEADER_LINE_ERROR";

export const DELETE_INVOICE_HEADER_LINE_ERROR = "DELETE_INVOICE_HEADER_LINE_ERROR";
export const DELETE_INVOICE_HEADER_LINE_SUCCESS = "DELETE_INVOICE_HEADER_LINE_SUCCESS";
export const DELETE_INVOICE_HEADER_LINE_REQUEST = "DELETE_INVOICE_HEADER_LINE_REQUEST";

export const GET_ONE_INVOICE_HEADER_LINE_REQUEST = "GET_ONE_INVOICE_HEADER_LINE_REQUEST";
export const GET_ONE_INVOICE_HEADER_LINE_SUCCESS = "GET_ONE_INVOICE_HEADER_LINE_SUCCESS";
export const GET_ONE_INVOICE_HEADER_LINE_ERROR = "GET_ONE_INVOICE_HEADER_LINE_ERROR";

export const ADD_INVOICE_HEADER_LINE_REQUEST = "ADD_INVOICE_HEADER_LINE_REQUEST";
export const ADD_INVOICE_HEADER_LINE_SUCCESS = "ADD_INVOICE_HEADER_LINE_SUCCESS";
export const ADD_INVOICE_HEADER_LINE_ERROR = "ADD_INVOICE_HEADER_LINE_ERROR";

export const GET_ONE_INVOICE_HEADER_LINE_DETAIL_REQUEST = "GET_ONE_INVOICE_HEADER_LINE_DETAIL_REQUEST";
export const GET_ONE_INVOICE_HEADER_LINE_DETAIL_SUCCESS = "GET_ONE_INVOICE_HEADER_LINE_DETAIL_SUCCESS";
export const GET_ONE_INVOICE_HEADER_LINE_DETAIL_ERROR = "GET_ONE_INVOICE_HEADER_LINE_DETAIL_ERROR";

export const GET_ONE_INVOICE_GL_PERIOD_REQUEST = "GET_ONE_INVOICE_GL_PERIOD_REQUEST";
export const GET_ONE_INVOICE_GL_PERIOD_SUCCESS = "GET_ONE_INVOICE_GL_PERIOD_SUCCESS";
export const GET_ONE_INVOICE_GL_PERIOD_ERROR = "GET_ONE_INVOICE_GL_PERIOD_ERROR";

export const GET_ADMIN_COMPANY_REQUEST = "GET_ADMIN_COMPANY_REQUEST";
export const GET_ADMIN_COMPANY_SUCCESS = "GET_ADMIN_COMPANY_SUCCESS";
export const GET_ADMIN_COMPANY_ERROR = "GET_ADMIN_COMPANY_ERROR";

export const ADD_ADMIN_COMPANY_REQUEST = "ADD_ADMIN_COMPANY_REQUEST";
export const ADD_ADMIN_COMPANY_SUCCESS = "ADD_ADMIN_COMPANY_SUCCESS";
export const ADD_ADMIN_COMPANY_ERROR = "ADD_ADMIN_COMPANY_ERROR";

export const UPDATE_ADMIN_COMPANY_REQUEST = "UPDATE_ADMIN_COMPANY_REQUEST";
export const UPDATE_ADMIN_COMPANY_SUCCESS = "UPDATE_ADMIN_COMPANY_SUCCESS";
export const UPDATE_ADMIN_COMPANY_ERROR = "UPDATE_ADMIN_COMPANY_ERROR";

export const DELETE_ADMIN_COMPANY_REQUEST = "DELETE_ADMIN_COMPANY_REQUEST";
export const DELETE_ADMIN_COMPANY_SUCCESS = "DELETE_ADMIN_COMPANY_SUCCESS";
export const DELETE_ADMIN_COMPANY_ERROR = "DELETE_ADMIN_COMPANY_ERROR";

export const GET_ACCOUNT_TYPE_REQUEST = "GET_ACCOUNT_TYPE_REQUEST";
export const GET_ACCOUNT_TYPE_SUCCESS = "GET_ACCOUNT_TYPE_SUCCESS";
export const GET_ACCOUNT_TYPE_ERROR = "GET_ACCOUNT_TYPE_ERROR";

export const GET_ONE_ORDER_SHIPMENT_LINE_DETAIL_REQUEST = "GET_ONE_ORDER_SHIPMENT_LINE_DETAIL_REQUEST";
export const GET_ONE_ORDER_SHIPMENT_LINE_DETAIL_SUCCESS = "GET_ONE_ORDER_SHIPMENT_LINE_DETAIL_SUCCESS";
export const GET_ONE_ORDER_SHIPMENT_LINE_DETAIL_ERROR = "GET_ONE_ORDER_SHIPMENT_LINE_DETAIL_ERROR";

export const ADD_ACCOUNT_TYPE_REQUEST = "ADD_ACCOUNT_TYPE_REQUEST";
export const ADD_ACCOUNT_TYPE_SUCCESS = "ADD_ACCOUNT_TYPE_SUCCESS";
export const ADD_ACCOUNT_TYPE_ERROR = "ADD_ACCOUNT_TYPE_ERROR";

export const UPDATE_ACCOUNT_TYPE_REQUEST = "UPDATE_ACCOUNT_TYPE_REQUEST";
export const UPDATE_ACCOUNT_TYPE_SUCCESS = "UPDATE_ACCOUNT_TYPE_SUCCESS";
export const UPDATE_ACCOUNT_TYPE_ERROR = "UPDATE_ACCOUNT_TYPE_ERROR";

export const DELETE_ACCOUNT_TYPE_REQUEST = "DELETE_ACCOUNT_TYPE_REQUEST";
export const DELETE_ACCOUNT_TYPE_SUCCESS = "DELETE_ACCOUNT_TYPE_SUCCESS";
export const DELETE_ACCOUNT_TYPE_ERROR = "DELETE_ACCOUNT_TYPE_ERROR";
export const GET_ONE_ORDER_SHIPMENT_DETAIL_REQUEST = "GET_ONE_ORDER_SHIPMENT_DETAIL_REQUEST";
export const GET_ONE_ORDER_SHIPMENT_DETAIL_SUCCESS = "GET_ONE_ORDER_SHIPMENT_DETAIL_SUCCESS";
export const GET_ONE_ORDER_SHIPMENT_DETAIL_ERROR = "GET_ONE_ORDER_SHIPMENT_DETAIL_ERROR";

export const GET_ONE_INVOICE_HEADER_DETAIL_REQUEST = "GET_ONE_INVOICE_HEADER_DETAIL_REQUEST";
export const GET_ONE_INVOICE_HEADER_DETAIL_SUCCESS = "GET_ONE_INVOICE_HEADER_DETAIL_SUCCESS";
export const GET_ONE_INVOICE_HEADER_DETAIL_ERROR = "GET_ONE_INVOICE_HEADER_DETAIL_ERROR";

export const GET_RETURN_RESAON_SUCCESS = "GET_RETURN_RESAON_SUCCESS";
export const GET_RETURN_RESAON_REQUEST = "GET_RETURN_RESAON_REQUEST";
export const GET_RETURN_RESAON_ERROR = "GET_RETURN_RESAON_ERROR";

export const ADD_RETURN_REASON_SUCCESS = "ADD_RETURN_REASON_SUCCESS";
export const ADD_RETURN_REASON_REQUEST = "ADD_RETURN_REASON_REQUEST";
export const ADD_RETURN_REASON_ERROR = "ADD_RETURN_REASON_ERROR";

export const UPDATE_RETURN_REASON_SUCCESS = "UPDATE_RETURN_REASON_SUCCESS";
export const UPDATE_RETURN_REASON_REQUEST = "UPDATE_RETURN_REASON_REQUEST";
export const UPDATE_RETURN_REASON_ERROR = "UPDATE_RETURN_REASON_ERROR";

export const DELETE_RETURN_REASON_SUCCESS = "DELETE_RETURN_REASON_SUCCESS";
export const DELETE_RETURN_REASON_ERROR = "DELETE_RETURN_REASON_ERROR";
export const DELETE_RETURN_REASON_REQUEST = "DELETE_RETURN_REASON_REQUEST";

export const GET_ADMIN_LOCATION_SUCCESS = "GET_ADMIN_LOCATION_SUCCESS";
export const GET_ADMIN_LOCATION_REQUEST = "GET_ADMIN_LOCATION_REQUEST";
export const GET_ADMIN_LOCATION_ERROR = "GET_ADMIN_LOCATION_ERROR";

export const ADD_ADMIN_LOCATION_REQUEST = "ADD_ADMIN_LOCATION_REQUEST";
export const ADD_ADMIN_LOCATION_SUCCESS = "ADD_ADMIN_LOCATION_SUCCESS";
export const ADD_ADMIN_LOCATION_ERROR = "ADD_ADMIN_LOCATION_ERROR";

export const UPDATE_ADMIN_LOCATION_REQUEST = "UPDATE_ADMIN_LOCATION_REQUEST";
export const UPDATE_ADMIN_LOCATION_SUCCESS = "UPDATE_ADMIN_LOCATION_SUCCESS";
export const UPDATE_ADMIN_LOCATION_ERROR = "UPDATE_ADMIN_LOCATION_ERROR";

export const DELETE_ADMIN_LOCATION_REQUEST = "DELETE_ADMIN_LOCATION_REQUEST";
export const DELETE_ADMIN_LOCATION_SUCCESS = "DELETE_ADMIN_LOCATION_SUCCESS";
export const DELETE_ADMIN_LOCATION_ERROR = "DELETE_ADMIN_LOCATION_ERROR";

export const ADD_SALES_TARGET_REQUEST = "ADD_SALES_TARGET_REQUEST";
export const ADD_SALES_TARGET_SUCCESS = "ADD_SALES_TARGET_SUCCESS";
export const ADD_SALES_TARGET_ERROR = "ADD_SALES_TARGET_ERROR";

export const UPDATE_SALES_TARGET_REQUEST = "UPDATE_SALES_TARGET_REQUEST";
export const UPDATE_SALES_TARGET_SUCCESS = "UPDATE_SALES_TARGET_SUCCESS";
export const UPDATE_SALES_TARGET_ERROR = "UPDATE_SALES_TARGET_ERROR";

export const DELETE_SALES_TARGET_REQUEST = "DELETE_SALES_TARGET_REQUEST";
export const DELETE_SALES_TARGET_SUCCESS = "DELETE_SALES_TARGET_SUCCESS";
export const DELETE_SALES_TARGET_ERROR = "DELETE_SALES_TARGET_ERROR";

export const GET_SALES_TARGET_BRAND_REQUEST = "GET_SALES_TARGET_BRAND_REQUEST";
export const GET_SALES_TARGET_BRAND_SUCCESS = "GET_SALES_TARGET_BRAND_SUCCESS";
export const GET_SALES_TARGET_BRAND_ERROR = "GET_SALES_TARGET_BRAND_ERROR";

export const GET_SALES_TARGET_FIGURE_REQUEST = "GET_SALES_TARGET_FIGURE_REQUEST";
export const GET_SALES_TARGET_FIGURE_SUCCESS = "GET_SALES_TARGET_FIGURE_SUCCESS";
export const GET_SALES_TARGET_FIGURE_ERROR = "GET_SALES_TARGET_FIGURE_ERROR";

export const ADD_SCM_FIGURE_REQUEST = "ADD_SCM_FIGURE_REQUEST";
export const ADD_SCM_FIGURE_SUCCESS = "ADD_SCM_FIGURE_SUCCESS";
export const ADD_SCM_FIGURE_ERROR = "ADD_SCM_FIGURE_ERROR";

export const UPDATE_SCM_FIGURE_REQUEST = "UPDATE_SCM_FIGURE_REQUEST";
export const UPDATE_SCM_FIGURE_SUCCESS = "UPDATE_SCM_FIGURE_SUCCESS";
export const UPDATE_SCM_FIGURE_ERROR = "UPDATE_SCM_FIGURE_ERROR";

export const DELETE_SCM_FIGURE_REQUEST = "DELETE_SCM_FIGURE_REQUEST";
export const DELETE_SCM_FIGURE_SUCCESS = "DELETE_SCM_FIGURE_SUCCESS";
export const DELETE_SCM_FIGURE_ERROR = "DELETE_SCM_FIGURE_ERROR";

export const GET_SCM_TARGET_MONTH_REQUEST = "GET_SCM_TARGET_MONTH_REQUEST";
export const GET_SCM_TARGET_MONTH_SUCCESS = "GET_SCM_TARGET_MONTH_SUCCESS";
export const GET_SCM_TARGET_MONTH_ERROR = "GET_SCM_TARGET_MONTH_ERROR";

export const GET_SCM_TARGET_YEAR_SUCCESS = "GET_SCM_TARGET_YEAR_SUCCESS";
export const GET_SCM_TARGET_YEAR_REQUEST = "GET_SCM_TARGET_YEAR_REQUEST";
export const GET_SCM_TARGET_YEAR_ERROR = "GET_SCM_TARGET_YEAR_ERROR";

export const GET_SALES_TARGET_REQUEST = "GET_SALES_TARGET_REQUEST";
export const GET_SALES_TARGET_SUCCESS = "GET_SALES_TARGET_SUCCESS";
export const GET_SALES_TARGET_ERROR = "GET_SALES_TARGET_ERROR";

export const UPDATE_ORDER_SHIPMENT_LINE_REQUEST = "UPDATE_ORDER_SHIPMENT_LINE_REQUEST";
export const UPDATE_ORDER_SHIPMENT_LINE_SUCCESS = "UPDATE_ORDER_SHIPMENT_LINE_SUCCESS";
export const UPDATE_ORDER_SHIPMENT_LINE_ERROR = "UPDATE_ORDER_SHIPMENT_LINE_ERROR";


export const GET_ORDERSTATUS_REQUEST = "GET_ORDERSTATUS_REQUEST";
export const GET_ORDERSTATUS_ERROR = "GET_ORDERSTATUS_ERROR";
export const GET_ORDERSTATUS_SUCCESS = "GET_ORDERSTATUS_SUCCESS";

export const ADD_ORDERSTATUS_REQUEST = "ADD_ORDERSTATUS_REQUEST";
export const ADD_ORDERSTATUS_ERROR = "ADD_ORDERSTATUS_ERROR";
export const ADD_ORDERSTATUS_SUCCESS = "ADD_ORDERSTATUS_SUCCESS";

export const UPDATE_ORDERSTATUS_REQUEST = "UPDATE_ORDERSTATUS_REQUEST";
export const UPDATE_ORDERSTATUS_ERROR = "UPDATE_ORDERSTATUS_ERROR";
export const UPDATE_ORDERSTATUS_SUCCESS = "UPDATE_ORDERSTATUS_SUCCESS";

export const DELETE_ORDERSTATUS_REQUEST = "DELETE_ORDERSTATUS_REQUEST";
export const DELETE_ORDERSTATUS_ERROR = "DELETE_ORDERSTATUS_ERROR";
export const DELETE_ORDERSTATUS_SUCCESS = "DELETE_ORDERSTATUS_SUCCESS";


export const GET_PICKTICKET_STATUS_REQUEST = "GET_PICKTICKET_STATUS_REQUEST";
export const GET_PICKTICKET_STATUS_ERROR = "GET_PICKTICKET_STATUS_ERROR";
export const GET_PICKTICKET_STATUS_SUCCESS = "GET_PICKTICKET_STATUS_SUCCESS";

export const ADD_PICKTICKET_STATUS_REQUEST = "ADD_PICKTICKET_STATUS_REQUEST";
export const ADD_PICKTICKET_STATUS_ERROR = "ADD_PICKTICKET_STATUS_ERROR";
export const ADD_PICKTICKET_STATUS_SUCCESS = "ADD_PICKTICKET_STATUS_SUCCESS";

export const UPDATE_PICKTICKET_STATUS_REQUEST = "UPDATE_PICKTICKET_STATUS_REQUEST";
export const UPDATE_PICKTICKET_STATUS_ERROR = "UPDATE_PICKTICKET_STATUS_ERROR";
export const UPDATE_PICKTICKET_STATUS_SUCCESS = "UPDATE_PICKTICKET_STATUS_SUCCESS";

export const DELETE_PICKTICKET_STATUS_REQUEST = "DELETE_PICKTICKET_STATUS_REQUEST";
export const DELETE_PICKTICKET_STATUS_ERROR = "DELETE_PICKTICKET_STATUS_ERROR";
export const DELETE_PICKTICKET_STATUS_SUCCESS = "DELETE_PICKTICKET_STATUS_SUCCESS";


export const GET_SHIPSTATUS_REQUEST = "GET_SHIPSTATUS_REQUEST";
export const GET_SHIPSTATUS_ERROR = "GET_SHIPSTATUS_ERROR";
export const GET_SHIPSTATUS_SUCCESS = "GET_SHIPSTATUS_SUCCESS";

export const ADD_SHIPSTATUS_REQUEST = "ADD_SHIPSTATUS_REQUEST";
export const ADD_SHIPSTATUS_ERROR = "ADD_SHIPSTATUS_ERROR";
export const ADD_SHIPSTATUS_SUCCESS = "ADD_SHIPSTATUS_SUCCESS";

export const UPDATE_SHIPSTATUS_REQUEST = "UPDATE_SHIPSTATUS_REQUEST";
export const UPDATE_SHIPSTATUS_ERROR = "UPDATE_SHIPSTATUS_ERROR";
export const UPDATE_SHIPSTATUS_SUCCESS = "UPDATE_SHIPSTATUS_SUCCESS";

export const DELETE_SHIPSTATUS_REQUEST = "DELETE_SHIPSTATUS_REQUEST";
export const DELETE_SHIPSTATUS_ERROR = "DELETE_SHIPSTATUS_ERROR";
export const DELETE_SHIPSTATUS_SUCCESS = "DELETE_SHIPSTATUS_SUCCESS";

export const GET_ALL_SALERETURN_REQUEST = "GET_ALL_SALERETURN_REQUEST";
export const GET_ALL_SALERETURN_SUCCESS = "GET_ALL_SALERETURN_SUCCESS";
export const GET_ALL_SALERETURN_ERROR = "GET_ALL_SALERETURN_ERROR";

export const GET_ONE_SALE_RETURN_HEADER_REQUEST = "GET_ONE_SALE_RETURN_HEADER_REQUEST";
export const GET_ONE_SALE_RETURN_HEADER_SUCCESS = "GET_ONE_SALE_RETURN_HEADER_SUCCESS";
export const GET_ONE_SALE_RETURN_HEADER_ERROR = "GET_ONE_SALE_RETURN_HEADER_ERROR";

export const GET_ONE_SALE_RETURN_HEADER_DETAIL_REQUEST = "GET_ONE_SALE_RETURN_HEADER_DETAIL_REQUEST";
export const GET_ONE_SALE_RETURN_HEADER_DETAIL_SUCCESS = "GET_ONE_SALE_RETURN_HEADER_DETAIL_SUCCESS";
export const GET_ONE_SALE_RETURN_HEADER_DETAIL_ERROR = "GET_ONE_SALE_RETURN_HEADER_DETAIL_ERROR";

export const DELETE_SALE_RETURN_HEADER_REQUEST = "DELETE_SALE_RETURN_HEADER_REQUEST";
export const DELETE_SALE_RETURN_HEADER_SUCCESS = "DELETE_SALE_RETURN_HEADER_SUCCESS";
export const DELETE_SALE_RETURN_HEADER_ERROR = "DELETE_SALE_RETURN_HEADER_ERROR";

export const GET_SALE_RETURN_FORM_DATA_REQUEST = "GET_SALE_RETURN_FORM_DATA_REQUEST";
export const GET_SALE_RETURN_FORM_DATA_SUCCESS = "GET_SALE_RETURN_FORM_DATA_SUCCESS";
export const GET_SALE_RETURN_FORM_DATA_ERROR = "GET_SALE_RETURN_FORM_DATA_ERROR";

export const ADD_SALE_RETURN_HEADER_REQUEST = "ADD_SALE_RETURN_HEADER_REQUEST";
export const ADD_SALE_RETURN_HEADER_SUCCESS = "ADD_SALE_RETURN_HEADER_SUCCESS";
export const ADD_SALE_RETURN_HEADER_ERROR = "ADD_SALE_RETURN_HEADER_ERROR";

export const UPDATE_SALE_RETURN_HEADER_REQUEST = "UPDATE_SALE_RETURN_HEADER_REQUEST";
export const UPDATE_SALE_RETURN_HEADER_SUCCESS = "UPDATE_SALE_RETURN_HEADER_SUCCESS";
export const UPDATE_SALE_RETURN_HEADER_ERROR = "UPDATE_SALE_RETURN_HEADER_ERROR";

export const GET_ALL_SALES_RETURN_LINE_REQUEST = "GET_ALL_SALES_RETURN_LINE_REQUEST";
export const GET_ALL_SALES_RETURN_LINE_SUCCESS = "GET_ALL_SALES_RETURN_LINE_SUCCESS";
export const GET_ALL_SALES_RETURN_LINE_ERROR = "GET_ALL_SALES_RETURN_LINE_ERROR";

export const DELETE_SALES_RETURN_LINE_REQUEST = "DELETE_SALES_RETURN_LINE_REQUEST";
export const DELETE_SALES_RETURN_LINE_SUCCESS = "DELETE_SALES_RETURN_LINE_SUCCESS";
export const DELETE_SALES_RETURN_LINE_ERROR = "DELETE_SALES_RETURN_LINE_ERROR";

export const GET_ONE_SALES_RETURN_LINE_REQUEST = "GET_ONE_SALES_RETURN_LINE_REQUEST";
export const GET_ONE_SALES_RETURN_LINE_SUCCESS = "GET_ONE_SALES_RETURN_LINE_SUCCESS";
export const GET_ONE_SALES_RETURN_LINE_ERROR = "GET_ONE_SALES_RETURN_LINE_ERROR";

export const ADD_SALES_RETURN_LINE_REQUEST = "ADD_SALES_RETURN_LINE_REQUEST";
export const ADD_SALES_RETURN_LINE_SUCCESS = "ADD_SALES_RETURN_LINE_SUCCESS";
export const ADD_SALES_RETURN_LINE_ERROR = "ADD_SALES_RETURN_LINE_ERROR";

export const UPDATE_SALES_RETURN_LINE_REQUEST = "UPDATE_SALES_RETURN_LINE_REQUEST";
export const UPDATE_SALES_RETURN_LINE_SUCCESS = "UPDATE_SALES_RETURN_LINE_SUCCESS";
export const UPDATE_SALES_RETURN_LINE_ERROR = "UPDATE_SALES_RETURN_LINE_ERROR";

export const GET_ONE_SALES_RETURN_LINE_DETAIL_REQUEST = "GET_ONE_SALES_RETURN_LINE_DETAIL_REQUEST";
export const GET_ONE_SALES_RETURN_LINE_DETAIL_SUCCESS = "GET_ONE_SALES_RETURN_LINE_DETAIL_SUCCESS";
export const GET_ONE_SALES_RETURN_LINE_DETAIL_ERROR = "GET_ONE_SALES_RETURN_LINE_DETAIL_ERROR";

export const GET_FISCALYEAR_REQUEST = "GET_FISCALYEAR_REQUEST";
export const GET_FISCALYEAR_ERROR = "GET_FISCALYEAR_ERROR";
export const GET_FISCALYEAR_SUCCESS = "GET_FISCALYEAR_SUCCESS";

export const ADD_FISCALYEAR_REQUEST = "ADD_FISCALYEAR_REQUEST";
export const ADD_FISCALYEAR_ERROR = "ADD_FISCALYEAR_ERROR";
export const ADD_FISCALYEAR_SUCCESS = "ADD_FISCALYEAR_SUCCESS";

export const UPDATE_FISCALYEAR_REQUEST = "UPDATE_FISCALYEAR_REQUEST";
export const UPDATE_FISCALYEAR_ERROR = "UPDATE_FISCALYEAR_ERROR";
export const UPDATE_FISCALYEAR_SUCCESS = "UPDATE_FISCALYEAR_SUCCESS";

export const DELETE_FISCALYEAR_REQUEST = "DELETE_FISCALYEAR_REQUEST";
export const DELETE_FISCALYEAR_ERROR = "DELETE_FISCALYEAR_ERROR";
export const DELETE_FISCALYEAR_SUCCESS = "DELETE_FISCALYEAR_SUCCESS";


export const GET_ALL_FISCALYEAR_PERIOD_REQUEST = "GET_ALL_FISCALYEAR_PERIOD_REQUEST";
export const GET_ALL_FISCALYEAR_PERIOD_ERROR = "GET_ALL_FISCALYEAR_PERIOD_ERROR";
export const GET_ALL_FISCALYEAR_PERIOD_SUCCESS = "GET_ALL_FISCALYEAR_PERIOD_SUCCESS";

export const GET_ONE_FISCALYEAR_PERIOD_REQUEST = "GET_ONE_FISCALYEAR_PERIOD_REQUEST";
export const GET_ONE_FISCALYEAR_PERIOD_ERROR = "GET_ONE_FISCALYEAR_PERIOD_ERROR";
export const GET_ONE_FISCALYEAR_PERIOD_SUCCESS = "GET_ONE_FISCALYEAR_PERIOD_SUCCESS";

export const ADD_FISCALYEAR_PERIOD_REQUEST = "ADD_FISCALYEAR_PERIOD_REQUEST";
export const ADD_FISCALYEAR_PERIOD_ERROR = "ADD_FISCALYEAR_PERIOD_ERROR";
export const ADD_FISCALYEAR_PERIOD_SUCCESS = "ADD_FISCALYEAR_PERIOD_SUCCESS";

export const UPDATE_FISCALYEAR_PERIOD_REQUEST = "UPDATE_FISCALYEAR_PERIOD_REQUEST";
export const UPDATE_FISCALYEAR_PERIOD_ERROR = "UPDATE_FISCALYEAR_PERIOD_ERROR";
export const UPDATE_FISCALYEAR_PERIOD_SUCCESS = "UPDATE_FISCALYEAR_PERIOD_SUCCESS";

export const DELETE_FISCALYEAR_PERIOD_REQUEST = "DELETE_FISCALYEAR_PERIOD_REQUEST";
export const DELETE_FISCALYEAR_PERIOD_ERROR = "DELETE_FISCALYEAR_PERIOD_ERROR";
export const DELETE_FISCALYEAR_PERIOD_SUCCESS = "DELETE_FISCALYEAR_PERIOD_SUCCESS";

export const GET_ONE_RECEIPT_LINE_REQUEST = "GET_ONE_RECEIPT_LINE_REQUEST";
export const GET_ONE_RECEIPT_LINE_SUCCESS = "GET_ONE_RECEIPT_LINE_SUCCESS";
export const GET_ONE_RECEIPT_LINE_ERROR = "GET_ONE_RECEIPT_LINE_ERROR";

export const GET_ONE_RECEIPT_LINE_DETAIL_REQUEST = "GET_ONE_RECEIPT_LINE_DETAIL_REQUEST";
export const GET_ONE_RECEIPT_LINE_DETAIL_SUCCESS = "GET_ONE_RECEIPT_LINE_DETAIL_SUCCESS";
export const GET_ONE_RECEIPT_LINE_DETAIL_ERROR = "GET_ONE_RECEIPT_LINE_DETAIL_ERROR";

export const GET_ONE_RECEIPT_HEADER_REQUEST = "GET_ONE_RECEIPT_HEADER_REQUEST";
export const GET_ONE_RECEIPT_HEADER_SUCCESS = "GET_ONE_RECEIPT_HEADER_SUCCESS";
export const GET_ONE_RECEIPT_HEADER_ERROR = "GET_ONE_RECEIPT_HEADER_ERROR";

export const GET_ONE_RECEIPT_HEADER_DETAIL_REQUEST = "GET_ONE_RECEIPT_HEADER_DETAIL_REQUEST";
export const GET_ONE_RECEIPT_HEADER_DETAIL_SUCCESS = "GET_ONE_RECEIPT_HEADER_DETAIL_SUCCESS";
export const GET_ONE_RECEIPT_HEADER_DETAIL_ERROR = "GET_ONE_RECEIPT_HEADER_DETAIL_ERROR";

export const ADD_RECEIPT_HEADER_REQUEST = "ADD_RECEIPT_HEADER_REQUEST";
export const ADD_RECEIPT_HEADER_SUCCESS = "ADD_RECEIPT_HEADER_SUCCESS";
export const ADD_RECEIPT_HEADER_ERROR = "ADD_RECEIPT_HEADER_ERROR";

export const GET_ALL_RECEIPT_REQUEST = "GET_ALL_RECEIPT_REQUEST";
export const GET_ALL_RECEIPT_SUCCESS = "GET_ALL_RECEIPT_SUCCESS";
export const GET_ALL_RECEIPT_ERROR = "GET_ALL_RECEIPT_ERROR";

export const ADD_RECEIPT_LINE_REQUEST = "ADD_RECEIPT_LINE_REQUEST";
export const ADD_RECEIPT_LINE_SUCCESS = "ADD_RECEIPT_LINE_SUCCESS";
export const ADD_RECEIPT_LINE_ERROR = "ADD_RECEIPT_LINE_ERROR";

export const UPDATE_RECEIPT_HEADER_REQUEST = "UPDATE_RECEIPT_HEADER_REQUEST";
export const UPDATE_RECEIPT_HEADER_SUCCESS = "UPDATE_RECEIPT_HEADER_SUCCESS";
export const UPDATE_RECEIPT_HEADER_ERROR = "UPDATE_RECEIPT_HEADER_ERROR";

export const DELETE_RECEIPT_HEADER_ERROR = "DELETE_RECEIPT_HEADER_ERROR";
export const DELETE_RECEIPT_HEADER_SUCCESS = "DELETE_RECEIPT_HEADER_SUCCESS";
export const DELETE_RECEIPT_HEADER_REQUEST = "DELETE_RECEIPT_HEADER_REQUEST";

export const UPDATE_RECEIPT_LINE_REQUEST = "UPDATE_RECEIPT_LINE_REQUEST";
export const UPDATE_RECEIPT_LINE_SUCCESS = "UPDATE_RECEIPT_LINE_SUCCESS";
export const UPDATE_RECEIPT_LINE_ERROR = "UPDATE_RECEIPT_LINE_ERROR";

export const DELETE_RECEIPT_LINE_ERROR = "DELETE_RECEIPT_LINE_ERROR";
export const DELETE_RECEIPT_LINE_SUCCESS = "DELETE_RECEIPT_LINE_SUCCESS";
export const DELETE_RECEIPT_LINE_REQUEST = "DELETE_RECEIPT_LINE_REQUEST";

export const GET_VENDORS_REQUEST = "GET_VENDORS_REQUEST";
export const GET_VENDORS_ERROR = "GET_VENDORS_ERROR";
export const GET_VENDORS_SUCCESS = "GET_VENDORS_SUCCESS";

export const GET_ONE_VENDOR_REQUEST = "GET_ONE_VENDOR_REQUEST";
export const GET_ONE_VENDOR_ERROR = "GET_ONE_VENDOR_ERROR";
export const GET_ONE_VENDOR_SUCCESS = "GET_ONE_VENDOR_SUCCESS";

export const ADD_VENDOR_REQUEST = "ADD_VENDOR_REQUEST";
export const ADD_VENDOR_ERROR = "ADD_VENDOR_ERROR";
export const ADD_VENDOR_SUCCESS = "ADD_VENDOR_SUCCESS";

export const UPDATE_VENDOR_REQUEST = "UPDATE_VENDOR_REQUEST";
export const UPDATE_VENDOR_ERROR = "UPDATE_VENDOR_ERROR";
export const UPDATE_VENDOR_SUCCESS = "UPDATE_VENDOR_SUCCESS";

export const DELETE_VENDOR_REQUEST = "DELETE_VENDOR_REQUEST";
export const DELETE_VENDOR_ERROR = "DELETE_VENDOR_ERROR";
export const DELETE_VENDOR_SUCCESS = "DELETE_VENDOR_SUCCESS";


export const GET_VENDOR_BILLING_ERROR = "GET_VENDOR_BILLING_ERROR";
export const GET_VENDOR_BILLING_REQUEST = "GET_VENDOR_BILLING_REQUEST";
export const GET_VENDOR_BILLING_SUCCESS = "GET_VENDOR_BILLING_SUCCESS";

export const ADD_VENDOR_BILLING_REQUEST = "ADD_VENDOR_BILLING_REQUEST";
export const ADD_VENDOR_BILLING_SUCCESS = "ADD_VENDOR_BILLING_SUCCESS";
export const ADD_VENDOR_BILLING_ERROR = "ADD_VENDOR_BILLING_ERROR";

export const DELETE_VENDOR_BILLING_REQUEST = "DELETE_VENDOR_BILLING_REQUEST";
export const DELETE_VENDOR_BILLING_SUCCESS = "DELETE_VENDOR_BILLING_SUCCESS";
export const DELETE_VENDOR_BILLING_ERROR = "DELETE_VENDOR_BILLING_ERROR";

export const UPDATE_VENDOR_BILLING_REQUEST = "UPDATE_VENDOR_BILLING_REQUEST";
export const UPDATE_VENDOR_BILLING_SUCCESS = "UPDATE_VENDOR_BILLING_SUCCESS";
export const UPDATE_VENDOR_BILLING_ERROR = "UPDATE_VENDOR_BILLING_ERROR";


export const ADD_VENDOR_SHIPPING_REQUEST = "ADD_VENDOR_SHIPPING_REQUEST";
export const ADD_VENDOR_SHIPPING_SUCCESS = "ADD_VENDOR_SHIPPING_SUCCESS";
export const ADD_VENDOR_SHIPPING_ERROR = "ADD_VENDOR_SHIPPING_ERROR";

export const GET_VENDOR_SHIPPING_ERROR = "GET_VENDOR_SHIPPING_ERROR";
export const GET_VENDOR_SHIPPING_REQUEST = "GET_VENDOR_SHIPPING_REQUEST";
export const GET_VENDOR_SHIPPING_SUCCESS = "GET_VENDOR_SHIPPING_SUCCESS";

export const DELETE_VENDOR_SHIPPING_REQUEST = "DELETE_VENDOR_SHIPPING_REQUEST";
export const DELETE_VENDOR_SHIPPING_SUCCESS = "DELETE_VENDOR_SHIPPING_SUCCESS";
export const DELETE_VENDOR_SHIPPING_ERROR = "DELETE_VENDOR_SHIPPING_ERROR";

export const UPDATE_VENDOR_SHIPPING_REQUEST = "UPDATE_VENDOR_SHIPPING_REQUEST";
export const UPDATE_VENDOR_SHIPPING_SUCCESS = "UPDATE_VENDOR_SHIPPING_SUCCESS";
export const UPDATE_VENDOR_SHIPPING_ERROR = "UPDATE_VENDOR_SHIPPING_ERROR";

export const GET_ALL_CREDIT_REQUEST = "GET_ALL_CREDIT_REQUEST";
export const GET_ALL_CREDIT_SUCCESS = "GET_ALL_CREDIT_SUCCESS";
export const GET_ALL_CREDIT_ERROR = "GET_ALL_CREDIT_ERROR";

export const GET_ONE_CREDIT_HEADER_REQUEST = "GET_ONE_CREDIT_HEADER_REQUEST";
export const GET_ONE_CREDIT_HEADER_SUCCESS = "GET_ONE_CREDIT_HEADER_SUCCESS";
export const GET_ONE_CREDIT_HEADER_ERROR = "GET_ONE_CREDIT_HEADER_ERROR";

export const GET_ONE_CREDIT_HEADER_DETAIL_REQUEST = "GET_ONE_CREDIT_HEADER_DETAIL_REQUEST";
export const GET_ONE_CREDIT_HEADER_DETAIL_SUCCESS = "GET_ONE_CREDIT_HEADER_DETAIL_SUCCESS";
export const GET_ONE_CREDIT_HEADER_DETAIL_ERROR = "GET_ONE_CREDIT_HEADER_DETAIL_ERROR";

export const DELETE_CREDIT_HEADER_REQUEST = "DELETE_CREDIT_HEADER_REQUEST";
export const DELETE_CREDIT_HEADER_SUCCESS = "DELETE_CREDIT_HEADER_SUCCESS";
export const DELETE_CREDIT_HEADER_ERROR = "DELETE_CREDIT_HEADER_ERROR";

export const ADD_CREDIT_HEADER_REQUEST = "ADD_CREDIT_HEADER_REQUEST";
export const ADD_CREDIT_HEADER_SUCCESS = "ADD_CREDIT_HEADER_SUCCESS";
export const ADD_CREDIT_HEADER_ERROR = "ADD_CREDIT_HEADER_ERROR";

export const UPDATE_CREDIT_HEADER_REQUEST = "UPDATE_CREDIT_HEADER_REQUEST";
export const UPDATE_CREDIT_HEADER_SUCCESS = "UPDATE_CREDIT_HEADER_SUCCESS";
export const UPDATE_CREDIT_HEADER_ERROR = "UPDATE_CREDIT_HEADER_ERROR";

export const DELETE_CREDIT_LINE_ERROR = "DELETE_CREDIT_LINE_ERROR";
export const DELETE_CREDIT_LINE_SUCCESS = "DELETE_CREDIT_LINE_SUCCESS";
export const DELETE_CREDIT_LINE_REQUEST = "DELETE_CREDIT_LINE_REQUEST";

export const GET_ONE_CREDIT_LINE_REQUEST = "GET_ONE_CREDIT_LINE_REQUEST";
export const GET_ONE_CREDIT_LINE_SUCCESS = "GET_ONE_CREDIT_LINE_SUCCESS";
export const GET_ONE_CREDIT_LINE_ERROR = "GET_ONE_CREDIT_LINE_ERROR";

export const GET_ONE_CREDIT_LINE_DETAIL_REQUEST = "GET_ONE_CREDIT_LINE_DETAIL_REQUEST";
export const GET_ONE_CREDIT_LINE_DETAIL_SUCCESS = "GET_ONE_CREDIT_LINE_DETAIL_SUCCESS";
export const GET_ONE_CREDIT_LINE_DETAIL_ERROR = "GET_ONE_CREDIT_LINE_DETAIL_ERROR";

export const ADD_CREDIT_LINE_REQUEST = "ADD_CREDIT_LINE_REQUEST";
export const ADD_CREDIT_LINE_SUCCESS = "ADD_CREDIT_LINE_SUCCESS";
export const ADD_CREDIT_LINE_ERROR = "ADD_CREDIT_LINE_ERROR";

export const UPDATE_CREDIT_LINE_REQUEST = "UPDATE_CREDIT_LINE_REQUEST";
export const UPDATE_CREDIT_LINE_SUCCESS = "UPDATE_CREDIT_LINE_SUCCESS";
export const UPDATE_CREDIT_LINE_ERROR = "UPDATE_CREDIT_LINE_ERROR";

export const GET_ALL_ITEM_TRANSFER_REQUEST = "GET_ALL_ITEM_TRANSFER_REQUEST";
export const GET_ALL_ITEM_TRANSFER_SUCCESS = "GET_ALL_ITEM_TRANSFER_SUCCESS";
export const GET_ALL_ITEM_TRANSFER_ERROR = "GET_ALL_ITEM_TRANSFER_ERROR";

export const GET_ONE_ITEM_TRANSFER_HEADER_REQUEST = "GET_ONE_ITEM_TRANSFER_HEADER_REQUEST";
export const GET_ONE_ITEM_TRANSFER_HEADER_SUCCESS = "GET_ONE_ITEM_TRANSFER_HEADER_SUCCESS";
export const GET_ONE_ITEM_TRANSFER_HEADER_ERROR = "GET_ONE_ITEM_TRANSFER_HEADER_ERROR";

export const GET_ONE_ITEM_TRANSFER_HEADER_DETAIL_REQUEST = "GET_ONE_ITEM_TRANSFER_HEADER_DETAIL_REQUEST";
export const GET_ONE_ITEM_TRANSFER_HEADER_DETAIL_SUCCESS = "GET_ONE_ITEM_TRANSFER_HEADER_DETAIL_SUCCESS";
export const GET_ONE_ITEM_TRANSFER_HEADER_DETAIL_ERROR = "GET_ONE_ITEM_TRANSFER_HEADER_DETAIL_ERROR";

export const DELETE_ITEM_TRANSFER_HEADER_REQUEST = "DELETE_ITEM_TRANSFER_HEADER_REQUEST";
export const DELETE_ITEM_TRANSFER_HEADER_SUCCESS = "DELETE_ITEM_TRANSFER_HEADER_SUCCESS";
export const DELETE_ITEM_TRANSFER_HEADER_ERROR = "DELETE_ITEM_TRANSFER_HEADER_ERROR";

export const ADD_ITEM_TRANSFER_HEADER_REQUEST = "ADD_ITEM_TRANSFER_HEADER_REQUEST";
export const ADD_ITEM_TRANSFER_HEADER_SUCCESS = "ADD_ITEM_TRANSFER_HEADER_SUCCESS";
export const ADD_ITEM_TRANSFER_HEADER_ERROR = "ADD_ITEM_TRANSFER_HEADER_ERROR";

export const UPDATE_ITEM_TRANSFER_HEADER_REQUEST = "UPDATE_ITEM_TRANSFER_HEADER_REQUEST";
export const UPDATE_ITEM_TRANSFER_HEADER_SUCCESS = "UPDATE_ITEM_TRANSFER_HEADER_SUCCESS";
export const UPDATE_ITEM_TRANSFER_HEADER_ERROR = "UPDATE_ITEM_TRANSFER_HEADER_ERROR";

export const DELETE_ITEM_TRANSFER_LINE_ERROR = "DELETE_ITEM_TRANSFER_LINE_ERROR";
export const DELETE_ITEM_TRANSFER_LINE_SUCCESS = "DELETE_ITEM_TRANSFER_LINE_SUCCESS";
export const DELETE_ITEM_TRANSFER_LINE_REQUEST = "DELETE_ITEM_TRANSFER_LINE_REQUEST";

export const GET_ONE_ITEM_TRANSFER_LINE_REQUEST = "GET_ONE_ITEM_TRANSFER_LINE_REQUEST";
export const GET_ONE_ITEM_TRANSFER_LINE_SUCCESS = "GET_ONE_ITEM_TRANSFER_LINE_SUCCESS";
export const GET_ONE_ITEM_TRANSFER_LINE_ERROR = "GET_ONE_ITEM_TRANSFER_LINE_ERROR";

export const GET_ONE_ITEM_TRANSFER_LINE_DETAIL_REQUEST = "GET_ONE_ITEM_TRANSFER_LINE_DETAIL_REQUEST";
export const GET_ONE_ITEM_TRANSFER_LINE_DETAIL_SUCCESS = "GET_ONE_ITEM_TRANSFER_LINE_DETAIL_SUCCESS";
export const GET_ONE_ITEM_TRANSFER_LINE_DETAIL_ERROR = "GET_ONE_ITEM_TRANSFER_LINE_DETAIL_ERROR";

export const ADD_ITEM_TRANSFER_LINE_REQUEST = "ADD_ITEM_TRANSFER_LINE_REQUEST";
export const ADD_ITEM_TRANSFER_LINE_SUCCESS = "ADD_ITEM_TRANSFER_LINE_SUCCESS";
export const ADD_ITEM_TRANSFER_LINE_ERROR = "ADD_ITEM_TRANSFER_LINE_ERROR";

export const UPDATE_ITEM_TRANSFER_LINE_REQUEST = "UPDATE_ITEM_TRANSFER_LINE_REQUEST";
export const UPDATE_ITEM_TRANSFER_LINE_SUCCESS = "UPDATE_ITEM_TRANSFER_LINE_SUCCESS";
export const UPDATE_ITEM_TRANSFER_LINE_ERROR = "UPDATE_ITEM_TRANSFER_LINE_ERROR";

export const GET_ITEM_TRANSFER_FORM_DATA_REQUEST = "GET_ITEM_TRANSFER_FORM_DATA_REQUEST";
export const GET_ITEM_TRANSFER_FORM_DATA_SUCCESS = "GET_ITEM_TRANSFER_FORM_DATA_SUCCESS";
export const GET_ITEM_TRANSFER_FORM_DATA_ERROR = "GET_ITEM_TRANSFER_FORM_DATA_ERROR";

export const GET_CUSTOMER_TERM_REQUEST = "GET_CUSTOMER_TERM_REQUEST";
export const GET_CUSTOMER_TERM_SUCCESS = "GET_CUSTOMER_TERM_SUCCESS";
export const GET_CUSTOMER_TERM_ERROR = "GET_CUSTOMER_TERM_ERROR";

export const GET_VENDOR_CATEGORY_REQUEST = "GET_VENDOR_CATEGORY_REQUEST";
export const GET_VENDOR_CATEGORY_ERROR = "GET_VENDOR_CATEGORY_ERROR";
export const GET_VENDOR_CATEGORY_SUCCESS = "GET_VENDOR_CATEGORY_SUCCESS";

export const ADD_VENDOR_CATEGORY_REQUEST = "ADD_VENDOR_CATEGORY_REQUEST";
export const ADD_VENDOR_CATEGORY_ERROR = "ADD_VENDOR_CATEGORY_ERROR";
export const ADD_VENDOR_CATEGORY_SUCCESS = "ADD_VENDOR_CATEGORY_SUCCESS";

export const UPDATE_VENDOR_CATEGORY_REQUEST = "UPDATE_VENDOR_CATEGORY_REQUEST";
export const UPDATE_VENDOR_CATEGORY_ERROR = "UPDATE_VENDOR_CATEGORY_ERROR";
export const UPDATE_VENDOR_CATEGORY_SUCCESS = "UPDATE_VENDOR_CATEGORY_SUCCESS";

export const DELETE_VENDOR_CATEGORY_REQUEST = "DELETE_VENDOR_CATEGORY_REQUEST";
export const DELETE_VENDOR_CATEGORY_ERROR = "DELETE_VENDOR_CATEGORY_ERROR";
export const DELETE_VENDOR_CATEGORY_SUCCESS = "DELETE_VENDOR_CATEGORY_SUCCESS";

export const ADD_BILL_HEADER_ERROR = "ADD_BILL_HEADER_ERROR";
export const ADD_BILL_HEADER_REQUEST = "ADD_BILL_HEADER_REQUEST";
export const ADD_BILL_HEADER_SUCCESS = "ADD_BILL_HEADER_SUCCESS";

export const DELETE_BILL_HEADER_ERROR = "DELETE_BILL_HEADER_ERROR";
export const DELETE_BILL_HEADER_REQUEST = "DELETE_BILL_HEADER_REQUEST";
export const DELETE_BILL_HEADER_SUCCESS = "DELETE_BILL_HEADER_SUCCESS";

export const GET_ALL_BILL_ERROR = "GET_ALL_BILL_ERROR";
export const GET_ALL_BILL_REQUEST = "GET_ALL_BILL_REQUEST";
export const GET_ALL_BILL_SUCCESS = "GET_ALL_BILL_SUCCESS";

export const GET_BILL_FORM_DATA_ERROR = "GET_BILL_FORM_DATA_ERROR";
export const GET_BILL_FORM_DATA_REQUEST = "GET_BILL_FORM_DATA_REQUEST";
export const GET_BILL_FORM_DATA_SUCCESS = "GET_BILL_FORM_DATA_SUCCESS";

export const GET_ONE_BILL_HEADER_DETAIL_ERROR = "GET_ONE_BILL_HEADER_DETAIL_ERROR";
export const GET_ONE_BILL_HEADER_DETAIL_REQUEST = "GET_ONE_BILL_HEADER_DETAIL_REQUEST";
export const GET_ONE_BILL_HEADER_DETAIL_SUCCESS = "GET_ONE_BILL_HEADER_DETAIL_SUCCESS";

export const GET_ONE_BILL_HEADER_ERROR = "GET_ONE_BILL_HEADER_ERROR";
export const GET_ONE_BILL_HEADER_REQUEST = "GET_ONE_BILL_HEADER_REQUEST";
export const GET_ONE_BILL_HEADER_SUCCESS = "GET_ONE_BILL_HEADER_SUCCESS";

export const UPDATE_BILL_HEADER_ERROR = "UPDATE_BILL_HEADER_ERROR";
export const UPDATE_BILL_HEADER_REQUEST = "UPDATE_BILL_HEADER_REQUEST";
export const UPDATE_BILL_HEADER_SUCCESS = "UPDATE_BILL_HEADER_SUCCESS";

export const ADD_BILL_LINE_ERROR = "ADD_BILL_LINE_ERROR";
export const ADD_BILL_LINE_REQUEST = "ADD_BILL_LINE_REQUEST";
export const ADD_BILL_LINE_SUCCESS = "ADD_BILL_LINE_SUCCESS";

export const DELETE_BILL_LINE_ERROR = "DELETE_BILL_LINE_ERROR";
export const DELETE_BILL_LINE_REQUEST = "DELETE_BILL_LINE_REQUEST";
export const DELETE_BILL_LINE_SUCCESS = "DELETE_BILL_LINE_SUCCESS";

export const GET_ONE_BILL_LINE_DETAIL_ERROR = "GET_ONE_BILL_LINE_DETAIL_ERROR";
export const GET_ONE_BILL_LINE_DETAIL_REQUEST = "GET_ONE_BILL_LINE_DETAIL_REQUEST";
export const GET_ONE_BILL_LINE_DETAIL_SUCCESS = "GET_ONE_BILL_LINE_DETAIL_SUCCESS";

export const GET_ONE_BILL_LINE_ERROR = "GET_ONE_BILL_LINE_ERROR";
export const GET_ONE_BILL_LINE_REQUEST = "GET_ONE_BILL_LINE_REQUEST";
export const GET_ONE_BILL_LINE_SUCCESS = "GET_ONE_BILL_LINE_SUCCESS";

export const UPDATE_BILL_LINE_ERROR = "UPDATE_BILL_LINE_ERROR";
export const UPDATE_BILL_LINE_REQUEST = "UPDATE_BILL_LINE_REQUEST";
export const UPDATE_BILL_LINE_SUCCESS = "UPDATE_BILL_LINE_SUCCESS";

export const ADD_EXPENSE_LINE_ERROR = "ADD_EXPENSE_LINE_ERROR";
export const ADD_EXPENSE_LINE_REQUEST = "ADD_EXPENSE_LINE_REQUEST";
export const ADD_EXPENSE_LINE_SUCCESS = "ADD_EXPENSE_LINE_SUCCESS";

export const DELETE_EXPENSE_LINE_ERROR = "DELETE_EXPENSE_LINE_ERROR";
export const DELETE_EXPENSE_LINE_REQUEST = "DELETE_EXPENSE_LINE_REQUEST";
export const DELETE_EXPENSE_LINE_SUCCESS = "DELETE_EXPENSE_LINE_SUCCESS";

export const GET_ONE_EXPENSE_LINE_DETAIL_ERROR = "GET_ONE_EXPENSE_LINE_DETAIL_ERROR";
export const GET_ONE_EXPENSE_LINE_DETAIL_REQUEST = "GET_ONE_EXPENSE_LINE_DETAIL_REQUEST";
export const GET_ONE_EXPENSE_LINE_DETAIL_SUCCESS = "GET_ONE_EXPENSE_LINE_DETAIL_SUCCESS";

export const UPDATE_EXPENSE_LINE_ERROR = "UPDATE_EXPENSE_LINE_ERROR";
export const UPDATE_EXPENSE_LINE_REQUEST = "UPDATE_EXPENSE_LINE_REQUEST";
export const UPDATE_EXPENSE_LINE_SUCCESS = "UPDATE_EXPENSE_LINE_SUCCESS";

export const GET_EXPENSE_BILL_REQUEST = "GET_EXPENSE_BILL_REQUEST";
export const GET_EXPENSE_BILL_SUCCESS = "GET_EXPENSE_BILL_SUCCESS";
export const GET_EXPENSE_BILL_ERROR = "GET_EXPENSE_BILL_ERROR";

export const GET_RELATED_TRANSAACTION_REQUEST = "GET_RELATED_TRANSAACTION_REQUEST";
export const GET_RELATED_TRANSACTION_SUCCESS = "GET_RELATED_TRANSACTION_SUCCESS";
export const GET_RELATED_TRANSACTION_ERROR = "GET_RELATED_TRANSACTION_ERROR";


export const GET_RETURNTYPE_REQUEST = "GET_RETURNTYPE_REQUEST";
export const GET_RETURNTYPE_ERROR = "GET_RETURNTYPE_ERROR";
export const GET_RETURNTYPE_SUCCESS = "GET_RETURNTYPE_SUCCESS";

export const ADD_RETURNTYPE_REQUEST = "ADD_RETURNTYPE_REQUEST";
export const ADD_RETURNTYPE_ERROR = "ADD_RETURNTYPE_ERROR";
export const ADD_RETURNTYPE_SUCCESS = "ADD_RETURNTYPE_SUCCESS";

export const UPDATE_RETURNTYPE_REQUEST = "UPDATE_RETURNTYPE_REQUEST";
export const UPDATE_RETURNTYPE_ERROR = "UPDATE_RETURNTYPE_ERROR";
export const UPDATE_RETURNTYPE_SUCCESS = "UPDATE_RETURNTYPE_SUCCESS";

export const DELETE_RETURNTYPE_REQUEST = "DELETE_RETURNTYPE_REQUEST";
export const DELETE_RETURNTYPE_ERROR = "DELETE_RETURNTYPE_ERROR";
export const DELETE_RETURNTYPE_SUCCESS = "DELETE_RETURNTYPE_SUCCESS";


export const GET_REPORT_IP_REQUEST = "GET_REPORT_IP_REQUEST";
export const GET_REPORT_IP_SUCCESS = "GET_REPORT_IP_SUCCESS";
export const GET_REPORT_IP_ERROR = "GET_REPORT_IP_ERROR";



export const GET_SALEORDERADJUSTMENTFORMDATA_REQUEST = "GET_SALEORDERADJUSTMENTFORMDATA_REQUEST";
export const GET_SALEORDERADJUSTMENTFORMDATA_ERROR = "GET_SALEORDERADJUSTMENTFORMDATA_ERROR";
export const GET_SALEORDERADJUSTMENTFORMDATA_SUCCESS = "GET_SALEORDERADJUSTMENTFORMDATA_SUCCESS";


export const ADD_SALEORDERADJUSTMENT_HEADER_REQUEST = "ADD_SALEORDERADJUSTMENT_HEADER_REQUEST";
export const ADD_SALEORDERADJUSTMENT_HEADER_SUCCESS = "ADD_SALEORDERADJUSTMENT_HEADER_SUCCESS";
export const ADD_SALEORDERADJUSTMENT_HEADER_ERROR = "ADD_SALEORDERADJUSTMENT_HEADER_ERROR";

export const GET_ALL_SALEORDERADJUSTMENT_SUCCESS = "GET_ALL_SALEORDERADJUSTMENT_SUCCESS";
export const GET_ALL_SALEORDERADJUSTMENT_REQUEST = "GET_ALL_SALEORDERADJUSTMENT_REQUEST";
export const GET_ALL_SALEORDERADJUSTMENT_ERROR = "GET_ALL_SALEORDERADJUSTMENT_ERROR";

export const GET_ONE_SALEORDERADJUSTMENTHEADER_SUCCESS = "GET_ONE_SALEORDERADJUSTMENTHEADER_SUCCESS";
export const GET_ONE_SALEORDERADJUSTMENTHEADER_REQUEST = "GET_ONE_SALEORDERADJUSTMENTHEADER_REQUEST";
export const GET_ONE_SALEORDERADJUSTMENTHEADER_ERROR = "GET_ONE_SALEORDERADJUSTMENTHEADER_ERROR";

export const UPDATE_SALEORDERADJUSTMENT_HEADER_REQUEST = "UPDATE_SALEORDERADJUSTMENT_HEADER_REQUEST";
export const UPDATE_SALEORDERADJUSTMENT_HEADER_ERROR = "UPDATE_SALEORDERADJUSTMENT_HEADER_ERROR";
export const UPDATE_SALEORDERADJUSTMENT_HEADER_SUCCESS = "UPDATE_SALEORDERADJUSTMENT_HEADER_SUCCESS";

export const DELETE_SALEORDERADJUSTMENTHEADER_SUCCESS = "DELETE_SALEORDERADJUSTMENTHEADER_SUCCESS";
export const DELETE_SALEORDERADJUSTMENTHEADER_REQUEST = "DELETE_SALEORDERADJUSTMENTHEADER_REQUEST";
export const DELETE_SALEORDERADJUSTMENTHEADER_ERROR = "DELETE_SALEORDERADJUSTMENTHEADER_ERROR";


export const GET_FABRICCOMPOSITION_REQUEST = "GET_FABRICCOMPOSITION_REQUEST";
export const GET_FABRICCOMPOSITION_ERROR = "GET_FABRICCOMPOSITION_ERROR";
export const GET_FABRICCOMPOSITION_SUCCESS = "GET_FABRICCOMPOSITION_SUCCESS";

export const ADD_FABRICCOMPOSITION_REQUEST = "ADD_FABRICCOMPOSITION_REQUEST";
export const ADD_FABRICCOMPOSITION_ERROR = "ADD_FABRICCOMPOSITION_ERROR";
export const ADD_FABRICCOMPOSITION_SUCCESS = "ADD_FABRICCOMPOSITION_SUCCESS";

export const UPDATE_FABRICCOMPOSITION_REQUEST = "UPDATE_FABRICCOMPOSITION_REQUEST";
export const UPDATE_FABRICCOMPOSITION_ERROR = "UPDATE_FABRICCOMPOSITION_ERROR";
export const UPDATE_FABRICCOMPOSITION_SUCCESS = "UPDATE_FABRICCOMPOSITION_SUCCESS";

export const DELETE_FABRICCOMPOSITION_REQUEST = "DELETE_FABRICCOMPOSITION_REQUEST";
export const DELETE_FABRICCOMPOSITION_ERROR = "DELETE_FABRICCOMPOSITION_ERROR";
export const DELETE_FABRICCOMPOSITION_SUCCESS = "DELETE_FABRICCOMPOSITION_SUCCESS";


export const GET_ITEMTYPE_REQUEST = "GET_ITEMTYPE_REQUEST";
export const GET_ITEMTYPE_ERROR = "GET_ITEMTYPE_ERROR";
export const GET_ITEMTYPE_SUCCESS = "GET_ITEMTYPE_SUCCESS";

export const ADD_ITEMTYPE_REQUEST = "ADD_ITEMTYPE_REQUEST";
export const ADD_ITEMTYPE_ERROR = "ADD_ITEMTYPE_ERROR";
export const ADD_ITEMTYPE_SUCCESS = "ADD_ITEMTYPE_SUCCESS";

export const UPDATE_ITEMTYPE_REQUEST = "UPDATE_ITEMTYPE_REQUEST";
export const UPDATE_ITEMTYPE_ERROR = "UPDATE_ITEMTYPE_ERROR";
export const UPDATE_ITEMTYPE_SUCCESS = "UPDATE_ITEMTYPE_SUCCESS";

export const DELETE_ITEMTYPE_REQUEST = "DELETE_ITEMTYPE_REQUEST";
export const DELETE_ITEMTYPE_ERROR = "DELETE_ITEMTYPE_ERROR";
export const DELETE_ITEMTYPE_SUCCESS = "DELETE_ITEMTYPE_SUCCESS";


export const GET_FABRICCLASS_REQUEST = "GET_FABRICCLASS_REQUEST";
export const GET_FABRICCLASS_ERROR = "GET_FABRICCLASS_ERROR";
export const GET_FABRICCLASS_SUCCESS = "GET_FABRICCLASS_SUCCESS";

export const ADD_FABRICCLASS_REQUEST = "ADD_FABRICCLASS_REQUEST";
export const ADD_FABRICCLASS_ERROR = "ADD_FABRICCLASS_ERROR";
export const ADD_FABRICCLASS_SUCCESS = "ADD_FABRICCLASS_SUCCESS";

export const UPDATE_FABRICCLASS_REQUEST = "UPDATE_FABRICCLASS_REQUEST";
export const UPDATE_FABRICCLASS_ERROR = "UPDATE_FABRICCLASS_ERROR";
export const UPDATE_FABRICCLASS_SUCCESS = "UPDATE_FABRICCLASS_SUCCESS";

export const DELETE_FABRICCLASS_REQUEST = "DELETE_FABRICCLASS_REQUEST";
export const DELETE_FABRICCLASS_ERROR = "DELETE_FABRICCLASS_ERROR";
export const DELETE_FABRICCLASS_SUCCESS = "DELETE_FABRICCLASS_SUCCESS";


export const GET_FABRICCLASS2_REQUEST = "GET_FABRICCLASS2_REQUEST";
export const GET_FABRICCLASS2_ERROR = "GET_FABRICCLASS2_ERROR";
export const GET_FABRICCLASS2_SUCCESS = "GET_FABRICCLASS2_SUCCESS";

export const ADD_FABRICCLASS2_REQUEST = "ADD_FABRICCLASS2_REQUEST";
export const ADD_FABRICCLASS2_ERROR = "ADD_FABRICCLASS2_ERROR";
export const ADD_FABRICCLASS2_SUCCESS = "ADD_FABRICCLASS2_SUCCESS";

export const UPDATE_FABRICCLASS2_REQUEST = "UPDATE_FABRICCLASS2_REQUEST";
export const UPDATE_FABRICCLASS2_ERROR = "UPDATE_FABRICCLASS2_ERROR";
export const UPDATE_FABRICCLASS2_SUCCESS = "UPDATE_FABRICCLASS2_SUCCESS";

export const DELETE_FABRICCLASS2_REQUEST = "DELETE_FABRICCLASS2_REQUEST";
export const DELETE_FABRICCLASS2_ERROR = "DELETE_FABRICCLASS2_ERROR";
export const DELETE_FABRICCLASS2_SUCCESS = "DELETE_FABRICCLASS2_SUCCESS";


export const GET_FABRICTYPE_REQUEST = "GET_FABRICTYPE_REQUEST";
export const GET_FABRICTYPE_ERROR = "GET_FABRICTYPE_ERROR";
export const GET_FABRICTYPE_SUCCESS = "GET_FABRICTYPE_SUCCESS";

export const ADD_FABRICTYPE_REQUEST = "ADD_FABRICTYPE_REQUEST";
export const ADD_FABRICTYPE_ERROR = "ADD_FABRICTYPE_ERROR";
export const ADD_FABRICTYPE_SUCCESS = "ADD_FABRICTYPE_SUCCESS";

export const UPDATE_FABRICTYPE_REQUEST = "UPDATE_FABRICTYPE_REQUEST";
export const UPDATE_FABRICTYPE_ERROR = "UPDATE_FABRICTYPE_ERROR";
export const UPDATE_FABRICTYPE_SUCCESS = "UPDATE_FABRICTYPE_SUCCESS";

export const DELETE_FABRICTYPE_REQUEST = "DELETE_FABRICTYPE_REQUEST";
export const DELETE_FABRICTYPE_ERROR = "DELETE_FABRICTYPE_ERROR";
export const DELETE_FABRICTYPE_SUCCESS = "DELETE_FABRICTYPE_SUCCESS";


export const GET_FABRICUSABLE_REQUEST = "GET_FABRICUSABLE_REQUEST";
export const GET_FABRICUSABLE_ERROR = "GET_FABRICUSABLE_ERROR";
export const GET_FABRICUSABLE_SUCCESS = "GET_FABRICUSABLE_SUCCESS";

export const ADD_FABRICUSABLE_REQUEST = "ADD_FABRICUSABLE_REQUEST";
export const ADD_FABRICUSABLE_ERROR = "ADD_FABRICUSABLE_ERROR";
export const ADD_FABRICUSABLE_SUCCESS = "ADD_FABRICUSABLE_SUCCESS";

export const UPDATE_FABRICUSABLE_REQUEST = "UPDATE_FABRICUSABLE_REQUEST";
export const UPDATE_FABRICUSABLE_ERROR = "UPDATE_FABRICUSABLE_ERROR";
export const UPDATE_FABRICUSABLE_SUCCESS = "UPDATE_FABRICUSABLE_SUCCESS";

export const DELETE_FABRICUSABLE_REQUEST = "DELETE_FABRICUSABLE_REQUEST";
export const DELETE_FABRICUSABLE_ERROR = "DELETE_FABRICUSABLE_ERROR";
export const DELETE_FABRICUSABLE_SUCCESS = "DELETE_FABRICUSABLE_SUCCESS";

export const ADD_CUSTOMER_UPLAOD_REQUEST = "ADD_CUSTOMER_UPLAOD_REQUEST";
export const ADD_CUSTOMER_UPLAOD_SUCCESS = "ADD_CUSTOMER_UPLAOD_SUCCESS";
export const ADD_CUSTOMER_UPLAOD_ERROR = "ADD_CUSTOMER_UPLAOD_ERROR";


export const UPDATE_CUSTOMER_UPLAOD_REQUEST = "UPDATE_CUSTOMER_UPLAOD_REQUEST";
export const UPDATE_CUSTOMER_UPLAOD_SUCCESS = "UPDATE_CUSTOMER_UPLAOD_SUCCESS";
export const UPDATE_CUSTOMER_UPLAOD_ERROR = "UPDATE_CUSTOMER_UPLAOD_ERROR";

export const GET_ALL_CUSTOMER_UPLAOD_REQUEST = "GET_ALL_CUSTOMER_UPLAOD_REQUEST";
export const GET_ALL_CUSTOMER_UPLAOD_SUCCESS = "GET_ALL_CUSTOMER_UPLAOD_SUCCESS";
export const GET_ALL_CUSTOMER_UPLAOD_ERROR = "GET_ALL_CUSTOMER_UPLAOD_ERROR";

export const GET_CUSTOMER_UPLAOD_REQUEST = "GET_CUSTOMER_UPLAOD_REQUEST";
export const GET_CUSTOMER_UPLAOD_SUCCESS = "GET_CUSTOMER_UPLAOD_SUCCESS";
export const GET_CUSTOMER_UPLAOD_ERROR = "GET_CUSTOMER_UPLAOD_ERROR";

export const DOWNLOAD_CUSTOMER_FILE_REQUEST = "DOWNLOAD_CUSTOMER_FILE_REQUEST";
export const DOWNLOAD_CUSTOMER_FILE_SUCCESS = "DOWNLOAD_CUSTOMER_FILE_SUCCESS";
export const DOWNLOAD_CUSTOMER_FILE_ERROR = "DOWNLOAD_CUSTOMER_FILE_ERROR";


export const GET_COMMITCRITERIA_REQUEST = "GET_COMMITCRITERIA_REQUEST";
export const GET_COMMITCRITERIA_ERROR = "GET_COMMITCRITERIA_ERROR";
export const GET_COMMITCRITERIA_SUCCESS = "GET_COMMITCRITERIA_SUCCESS";

export const ADD_COMMITCRITERIA_REQUEST = "ADD_COMMITCRITERIA_REQUEST";
export const ADD_COMMITCRITERIA_ERROR = "ADD_COMMITCRITERIA_ERROR";
export const ADD_COMMITCRITERIA_SUCCESS = "ADD_COMMITCRITERIA_SUCCESS";

export const UPDATE_COMMITCRITERIA_REQUEST = "UPDATE_COMMITCRITERIA_REQUEST";
export const UPDATE_COMMITCRITERIA_ERROR = "UPDATE_COMMITCRITERIA_ERROR";
export const UPDATE_COMMITCRITERIA_SUCCESS = "UPDATE_COMMITCRITERIA_SUCCESS";

export const DELETE_COMMITCRITERIA_REQUEST = "DELETE_COMMITCRITERIA_REQUEST";
export const DELETE_COMMITCRITERIA_ERROR = "DELETE_COMMITCRITERIA_ERROR";
export const DELETE_COMMITCRITERIA_SUCCESS = "DELETE_COMMITCRITERIA_SUCCESS";

export const GET_ONE_CHARGEBACKTYPE_REQUEST = "GET_ONE_CHARGEBACKTYPE_REQUEST";
export const GET_ONE_CHARGEBACKTYPE_ERROR = "GET_ONE_CHARGEBACKTYPE_ERROR";
export const GET_ONE_CHARGEBACKTYPE_SUCCESS = "GET_ONE_CHARGEBACKTYPE_SUCCESS";

export const GET_CHARGEBACKTYPE_REQUEST = "GET_CHARGEBACKTYPE_REQUEST";
export const GET_CHARGEBACKTYPE_ERROR = "GET_CHARGEBACKTYPE_ERROR";
export const GET_CHARGEBACKTYPE_SUCCESS = "GET_CHARGEBACKTYPE_SUCCESS";

export const ADD_CHARGEBACKTYPE_REQUEST = "ADD_CHARGEBACKTYPE_REQUEST";
export const ADD_CHARGEBACKTYPE_ERROR = "ADD_CHARGEBACKTYPE_ERROR";
export const ADD_CHARGEBACKTYPE_SUCCESS = "ADD_CHARGEBACKTYPE_SUCCESS";

export const UPDATE_CHARGEBACKTYPE_REQUEST = "UPDATE_CHARGEBACKTYPE_REQUEST";
export const UPDATE_CHARGEBACKTYPE_ERROR = "UPDATE_CHARGEBACKTYPE_ERROR";
export const UPDATE_CHARGEBACKTYPE_SUCCESS = "UPDATE_CHARGEBACKTYPE_SUCCESS";

export const DELETE_CHARGEBACKTYPE_REQUEST = "DELETE_CHARGEBACKTYPE_REQUEST";
export const DELETE_CHARGEBACKTYPE_ERROR = "DELETE_CHARGEBACKTYPE_ERROR";
export const DELETE_CHARGEBACKTYPE_SUCCESS = "DELETE_CHARGEBACKTYPE_SUCCESS";


export const GET_CHARGEBACKTYPE_METHOD_REQUEST = "GET_CHARGEBACKTYPE_REQUEST";
export const GET_CHARGEBACKTYPE_METHOD_ERROR = "GET_CHARGEBACKTYPE_ERROR";
export const GET_CHARGEBACKTYPE_METHOD_SUCCESS = "GET_CHARGEBACKTYPE_SUCCESS";

export const ADD_CHARGEBACKTYPE_METHOD_REQUEST = "ADD_CHARGEBACKTYPE_REQUEST";
export const ADD_CHARGEBACKTYPE_METHOD_ERROR = "ADD_CHARGEBACKTYPE_ERROR";
export const ADD_CHARGEBACKTYPE_METHOD_SUCCESS = "ADD_CHARGEBACKTYPE_SUCCESS";

export const UPDATE_CHARGEBACKTYPE_METHOD_REQUEST = "UPDATE_CHARGEBACKTYPE_REQUEST";
export const UPDATE_CHARGEBACKTYPE_METHOD_ERROR = "UPDATE_CHARGEBACKTYPE_ERROR";
export const UPDATE_CHARGEBACKTYPE_METHOD_SUCCESS = "UPDATE_CHARGEBACKTYPE_SUCCESS";

export const DELETE_CHARGEBACKTYPE_METHOD_REQUEST = "DELETE_CHARGEBACKTYPE_REQUEST";
export const DELETE_CHARGEBACKTYPE_METHOD_ERROR = "DELETE_CHARGEBACKTYPE_ERROR";
export const DELETE_CHARGEBACKTYPE_METHOD_SUCCESS = "DELETE_CHARGEBACKTYPE_SUCCESS";

export const GET_AR_ACCOUTN_REQUEST = "GET_AR_ACCOUTN_REQUEST";
export const GET_AR_ACCOUTN_SUCCESS = "GET_AR_ACCOUTN_SUCCESS";
export const GET_AR_ACCOUTN_ERROR = "GET_AR_ACCOUTN_ERROR";

export const GET_ADMIN_PAYMENT_TYPES_REQUEST = "GET_ADMIN_PAYMENT_TYPES_REQUEST";
export const GET_ADMIN_PAYMENT_TYPES_SUCCESS = "GET_ADMIN_PAYMENT_TYPES_SUCCESS";
export const GET_ADMIN_PAYMENT_TYPES_ERROR = "GET_ADMIN_PAYMENT_TYPES_ERROR";

export const ADD_PAY_CUSTOMER_PAYMENT_HEADER_REQUEST = "ADD_PAY_CUSTOMER_PAYMENT_HEADER_REQUEST";
export const ADD_PAY_CUSTOMER_PAYMENT_HEADER_SUCCESS = "ADD_PAY_CUSTOMER_PAYMENT_HEADER_SUCCESS";
export const ADD_PAY_CUSTOMER_PAYMENT_HEADER_ERROR = "ADD_PAY_CUSTOMER_PAYMENT_HEADER_ERROR";

export const GETALL_PAY_CUSTOMER_PAYMENT_HEADER_REQUEST = "GETALL_PAY_CUSTOMER_PAYMENT_HEADER_REQUEST";
export const GETALL_PAY_CUSTOMER_PAYMENT_HEADER_SUCCESS = "GETALL_PAY_CUSTOMER_PAYMENT_HEADER_SUCCESS";
export const GETALL_PAY_CUSTOMER_PAYMENT_HEADER_ERROR = "GETALL_PAY_CUSTOMER_PAYMENT_HEADER_ERROR";

export const GET_ONE_PAY_CUSTOMER_PAYMENT_HEADER_REQUEST = "GET_ONE_PAY_CUSTOMER_PAYMENT_HEADER_REQUEST";
export const GET_ONE_PAY_CUSTOMER_PAYMENT_HEADER_SUCCESS = "GET_ONE_PAY_CUSTOMER_PAYMENT_HEADER_SUCCESS";
export const GET_ONE_PAY_CUSTOMER_PAYMENT_HEADER_ERROR = "GET_ONE_PAY_CUSTOMER_PAYMENT_HEADER_ERROR";

export const DELETE_PAY_CUSTOMER_PAYMENT_HEADER_REQUEST = "DELETE_PAY_CUSTOMER_PAYMENT_HEADER_REQUEST";
export const DELETE_PAY_CUSTOMER_PAYMENT_HEADER_SUCCESS = "DELETE_PAY_CUSTOMER_PAYMENT_HEADER_SUCCESS";
export const DELETE_PAY_CUSTOMER_PAYMENT_HEADER_ERROR = "DELETE_PAY_CUSTOMER_PAYMENT_HEADER_ERROR";

export const UPDATE_PAY_CUSTOMER_PAYMENT_HEADER_REQUEST = "UPDATE_PAY_CUSTOMER_PAYMENT_HEADER_REQUEST";
export const UPDATE_PAY_CUSTOMER_PAYMENT_HEADER_SUCCESS = "UPDATE_PAY_CUSTOMER_PAYMENT_HEADER_SUCCESS";
export const UPDATE_PAY_CUSTOMER_PAYMENT_HEADER_ERROR = "UPDATE_PAY_CUSTOMER_PAYMENT_HEADER_ERROR";

export const ADD_PAY_CUSTOMER_PAYMENT_LINE_REQUEST = "ADD_PAY_CUSTOMER_PAYMENT_LINE_REQUEST";
export const ADD_PAY_CUSTOMER_PAYMENT_LINE_SUCCESS = "ADD_PAY_CUSTOMER_PAYMENT_LINE_SUCCESS";
export const ADD_PAY_CUSTOMER_PAYMENT_LINE_ERROR = "ADD_PAY_CUSTOMER_PAYMENT_LINE_ERROR";

export const GET_ONE_PAY_CUSTOMER_PAYMENT_LINE_REQUEST = "GET_ONE_PAY_CUSTOMER_PAYMENT_LINE_REQUEST";
export const GET_ONE_PAY_CUSTOMER_PAYMENT_LINE_SUCCESS = "GET_ONE_PAY_CUSTOMER_PAYMENT_LINE_SUCCESS";
export const GET_ONE_PAY_CUSTOMER_PAYMENT_LINE_ERROR = "GET_ONE_PAY_CUSTOMER_PAYMENT_LINE_ERROR";

export const UPDATE_PAY_CUSTOMER_PAYMENT_LINE_REQUEST = "UPDATE_PAY_CUSTOMER_PAYMENT_LINE_REQUEST";
export const UPDATE_PAY_CUSTOMER_PAYMENT_LINE_SUCCESS = "UPDATE_PAY_CUSTOMER_PAYMENT_LINE_SUCCESS";
export const UPDATE_PAY_CUSTOMER_PAYMENT_LINE_ERROR = "UPDATE_PAY_CUSTOMER_PAYMENT_LINE_ERROR";

export const DELETE_PAY_CUSTOMER_PAYMENT_LINE_REQUEST = "DELETE_PAY_CUSTOMER_PAYMENT_LINE_REQUEST";
export const DELETE_PAY_CUSTOMER_PAYMENT_LINE_SUCCESS = "DELETE_PAY_CUSTOMER_PAYMENT_LINE_SUCCESS";
export const DELETE_PAY_CUSTOMER_PAYMENT_LINE_ERROR = "DELETE_PAY_CUSTOMER_PAYMENT_LINE_ERROR";

export const GET_CUSTOMER_PAYMENT_DETAIL_REQUEST = "GET_CUSTOMER_PAYMENT_DETAIL_REQUEST";
export const GET_CUSTOMER_PAYMENT_DETAIL_SUCCESS = "GET_CUSTOMER_PAYMENT_DETAIL_SUCCESS";
export const GET_CUSTOMER_PAYMENT_DETAIL_ERROR = "GET_CUSTOMER_PAYMENT_DETAIL_ERROR";


export const GET_PAYMENT_TYPE_REQUEST = "GET_PAYMENT_TYPE_REQUEST";
export const GET_PAYMENT_TYPE_ERROR = "GET_PAYMENT_TYPE_ERROR";
export const GET_PAYMENT_TYPE_SUCCESS = "GET_PAYMENT_TYPE_SUCCESS";

export const ADD_PAYMENT_TYPE_REQUEST = "ADD_PAYMENT_TYPE_REQUEST";
export const ADD_PAYMENT_TYPE_ERROR = "ADD_PAYMENT_TYPE_ERROR";
export const ADD_PAYMENT_TYPE_SUCCESS = "ADD_PAYMENT_TYPER_SUCCESS";

export const UPDATE_PAYMENT_TYPE_REQUEST = "UPDATE_PAYMENT_TYPER_REQUEST";
export const UPDATE_PAYMENT_TYPE_SUCCESS = "UPDATE_PAYMENT_TYPE_SUCCESS";
export const UPDATE_PAYMENT_TYPE_ERROR = "UPDATE_PAYMENT_TYPE_ERROR";

export const DELETE_PAYMENT_TYPE_REQUEST = "DELETE_PAYMENT_TYPE_REQUEST";
export const DELETE_PAYMENT_TYPE_ERROR = "DELETE_PAYMENT_TYPE_ERROR";
export const DELETE_PAYMENT_TYPE_SUCCESS = "DELETE_PAYMENT_TYPE_SUCCESS";



export const GET_ADMIN_MODULE_REQUEST = "GET_ADMIN_MODULE_REQUEST";
export const GET_ADMIN_MODULE_SUCCESS = "GET_ADMIN_MODULE_SUCCESS";
export const GET_ADMIN_MODULE_ERROR = "GET_ADMIN_MODULE_ERROR";

export const ADD_ADMIN_MODULE_REQUEST = "ADD_ADMIN_MODULE_REQUEST";
export const ADD_ADMIN_MODULE_ERROR = "ADD_ADMIN_MODULE_ERROR";
export const ADD_ADMIN_MODULE_SUCCESS = "ADD_ADMIN_MODULE_SUCCESS";

export const UPDATE_ADMIN_MODULE_REQUEST = "UPDATE_ADMIN_MODULE_REQUEST";
export const UPDATE_ADMIN_MODULE_ERROR = "UPDATE_ADMIN_MODULE_ERROR";
export const UPDATE_ADMIN_MODULE_SUCCESS = "UPDATE_ADMIN_MODULE_SUCCESS";

export const DELETE_ADMIN_MODULE_REQUEST = "DELETE_ADMIN_MODULE_REQUEST";
export const DELETE_ADMIN_MODULE_ERROR = "DELETE_ADMIN_MODULE_ERROR";
export const DELETE_ADMIN_MODULE_SUCCESS = "DELETE_ADMIN_MODULE_SUCCESS";


export const ADD_PAY_CUSTOMER_PAYMENT_NOTE_LINE_REQUEST = "ADD_PAY_CUSTOMER_PAYMENT_NOTE_LINE_REQUEST";
export const ADD_PAY_CUSTOMER_PAYMENT_NOTE_LINE_SUCCESS = "ADD_PAY_CUSTOMER_PAYMENT_NOTE_LINE_SUCCESS";
export const ADD_PAY_CUSTOMER_PAYMENT_NOTE_LINE_ERROR = "ADD_PAY_CUSTOMER_PAYMENT_NOTE_LINE_ERROR";
export const GET_ONE_PAY_CUSTOMER_PAYMENT_NOTE_LINE_ERROR = "GET_ONE_PAY_CUSTOMER_PAYMENT_NOTE_LINE_ERROR";
export const GET_ONE_PAY_CUSTOMER_PAYMENT_NOTE_LINE_SUCCESS = "GET_ONE_PAY_CUSTOMER_PAYMENT_NOTE_LINE_SUCCESS";
export const GET_ONE_PAY_CUSTOMER_PAYMENT_NOTE_LINE_REQUEST = "GET_ONE_PAY_CUSTOMER_PAYMENT_NOTE_LINE_REQUEST";
export const UPDATE_PAY_CUSTOMER_PAYMENT_NOTE_LINE_REQUEST = "UPDATE_PAY_CUSTOMER_PAYMENT_NOTE_LINE_REQUEST";
export const UPDATE_PAY_CUSTOMER_PAYMENT_NOTE_LINE_SUCCESS = "UPDATE_PAY_CUSTOMER_PAYMENT_NOTE_LINE_SUCCESS";
export const UPDATE_PAY_CUSTOMER_PAYMENT_NOTE_LINE_ERROR = "UPDATE_PAY_CUSTOMER_PAYMENT_NOTE_LINE_ERROR";
export const DELETE_PAY_CUSTOMER_PAYMENT_NOTE_LINE_REQUEST = "DELETE_PAY_CUSTOMER_PAYMENT_NOTE_LINE_REQUEST";
export const DELETE_PAY_CUSTOMER_PAYMENT_NOTE_LINE_SUCCESS = "DELETE_PAY_CUSTOMER_PAYMENT_NOTE_LINE_SUCCESS";
export const DELETE_PAY_CUSTOMER_PAYMENT_NOTE_LINE_ERROR = "DELETE_PAY_CUSTOMER_PAYMENT_NOTE_LINE_ERROR";


export const GET_CUSTOMER_TYPE_REQUEST = "GET_CUSTOMER_TYPE_REQUEST";
export const GET_CUSTOMER_TYPE_SUCCESS = "GET_CUSTOMER_TYPE_SUCCESS";
export const GET_CUSTOMER_TYPE_ERROR = "GET_CUSTOMER_TYPE_ERROR";

export const ADD_CUSTOMER_TYPE_REQUEST = "ADD_CUSTOMER_TYPE_REQUEST";
export const ADD_CUSTOMER_TYPE_ERROR = "ADD_CUSTOMER_TYPE_ERROR";
export const ADD_CUSTOMER_TYPE_SUCCESS = "ADD_CUSTOMER_TYPE_SUCCESS";

export const UPDATE_CUSTOMER_TYPE_REQUEST = "UPDATE_CUSTOMER_TYPE_REQUEST";
export const UPDATE_CUSTOMER_TYPE_ERROR = "UPDATE_CUSTOMER_TYPE_ERROR";
export const UPDATE_CUSTOMER_TYPE_SUCCESS = "UPDATE_CUSTOMER_TYPE_SUCCESS";

export const DELETE_CUSTOMER_TYPE_REQUEST = "DELETE_CUSTOMER_TYPE_REQUEST";
export const DELETE_CUSTOMER_TYPE_ERROR = "DELETE_CUSTOMER_TYPE_ERROR";
export const DELETE_CUSTOMER_TYPE_SUCCESS = "DELETE_CUSTOMER_TYPE_SUCCESS";



export const GET_ONE_SALE_ORDER_RELATED_RECORD_REQUEST = "GET_ONE_SALE_ORDER_RELATED_RECORD_REQUEST";
export const GET_ONE_SALE_ORDER_RELATED_RECORD_SUCCESS = "GET_ONE_SALE_ORDER_RELATED_RECORD_SUCCESS";
export const GET_ONE_SALE_ORDER_RELATED_RECORD_ERROR = "GET_ONE_SALE_ORDER_RELATED_RECORD_ERROR";


export const GET_GLOBAL_SEARCH_REQUEST = "GET_GLOBAL_SEARCH_REQUEST";
export const GET_GLOBAL_SEARCH_SUCCESS = "GET_GLOBAL_SEARCH_SUCCESS";
export const GET_GLOBAL_SEARCH_ERROR = "GET_GLOBAL_SEARCH_ERROR";



export const GET_TERM_FOR_PRINT_REQUEST = "GET_TERM_FOR_PRINT_REQUEST";
export const GET_TERM_FOR_PRINT_SUCCESS = "GET_TERM_FOR_PRINT_SUCCESS";
export const GET_TERM_FOR_PRINT_ERROR = "GET_TERM_FOR_PRINT_ERROR";

export const ADD_TERM_FOR_PRINT_REQUEST = "ADD_TERM_FOR_PRINT_REQUEST";
export const ADD_TERM_FOR_PRINT_ERROR = "ADD_TERM_FOR_PRINT_ERROR";
export const ADD_TERM_FOR_PRINT_SUCCESS = "ADD_TERM_FOR_PRINT_SUCCESS";

export const UPDATE_TERM_FOR_PRINT_REQUEST = "UPDATE_TERM_FOR_PRINT_REQUEST";
export const UPDATE_TERM_FOR_PRINT_ERROR = "UPDATE_TERM_FOR_PRINT_ERROR";
export const UPDATE_TERM_FOR_PRINT_SUCCESS = "UPDATE_TERM_FOR_PRINT_SUCCESS";

export const DELETE_TERM_FOR_PRINT_REQUEST = "DELETE_TERM_FOR_PRINT_REQUEST";
export const DELETE_TERM_FOR_PRINT_ERROR = "DELETE_TERM_FOR_PRINT_ERROR";
export const DELETE_TERM_FOR_PRINT_SUCCESS = "DELETE_TERM_FOR_PRINT_SUCCESS";

export const GET_OMS_REPORT_DATA_REQUEST = "GET_OMS_REPORT_DATA_REQUEST";
export const GET_OMS_REPORT_DATA_SUCCESS = "GET_OMS_REPORT_DATA_SUCCESS ";
export const GET_OMS_REPORT_DATA_ERROR = "GET_OMS_REPORT_DATA_ERROR";

export const GET_SELL_ORDER_REGION_STATE_REPORT_REQUEST = "GET_SELL_ORDER_REGION_STATE_REPORT_REQUEST";
export const GET_SELL_ORDER_REGION_STATE_REPORT_SUCCESS = "GET_SELL_ORDER_REGION_STATE_REPORT_SUCCESS";
export const GET_SELL_ORDER_REGION_STATE_REPORT_ERROR = "GET_SELL_ORDER_REGION_STATE_REPORT_ERROR";

export const GET_REGION_STATE_REPORT_REQUEST = "GET_REGION_STATE_REPORT_REQUEST";
export const GET_REGION_STATE_REPORT_SUCCESS = "GET_REGION_STATE_REPORT_SUCCESS";
export const GET_REGION_STATE_REPORT_ERROR = "GET_REGION_STATE_REPORT_ERROR";

export const GET_REGION_STATE_REPORT_BY_CUSTOMER_REQUEST = "GET_REGION_STATE_REPORT_BY_CUSTOMER_REQUEST";
export const GET_REGION_STATE_REPORT_BY_CUSTOMER_SUCCESS = "GET_REGION_STATE_REPORT_BY_CUSTOMER_SUCCESS";
export const GET_REGION_STATE_REPORT_BY_CUSTOMER_ERROR = "GET_REGION_STATE_REPORT_BY_CUSTOMER_ERROR";


export const GET_ADMIN_JOB_REQUEST = "GET_ADMIN_JOB_REQUEST";
export const GET_ADMIN_JOB_ERROR = "GET_ADMIN_JOB_ERROR";
export const GET_ADMIN_JOB_SUCCESS = "GET_ADMIN_JOB_SUCCESS";


export const GET_ADMIN_CURRENCY_REQUEST = "GET_ADMIN_CURRENCY_REQUEST";
export const GET_ADMIN_CURRENCY_SUCCESS = "GET_ADMIN_CURRENCY_SUCCESS";
export const GET_ADMIN_CURRENCY_ERROR = "GET_ADMIN_CURRENCY_ERROR";

export const ADD_ADMIN_CURRENCY_REQUEST = "ADD_ADMIN_CURRENCY_REQUEST";
export const ADD_ADMIN_CURRENCY_ERROR = "ADD_ADMIN_CURRENCY_ERROR";
export const ADD_ADMIN_CURRENCY_SUCCESS = "ADD_ADMIN_CURRENCY_SUCCESS";

export const UPDATE_ADMIN_CURRENCY_REQUEST = "UPDATE_ADMIN_CURRENCY_REQUEST";
export const UPDATE_ADMIN_CURRENCY_ERROR = "UPDATE_ADMIN_CURRENCY_ERROR";
export const UPDATE_ADMIN_CURRENCY_SUCCESS = "UPDATE_ADMIN_CURRENCY_SUCCESS";

export const DELETE_ADMIN_CURRENCY_REQUEST = "DELETE_ADMIN_CURRENCY_REQUEST";
export const DELETE_ADMIN_CURRENCY_ERROR = "DELETE_ADMIN_CURRENCY_ERROR";
export const DELETE_ADMIN_CURRENCY_SUCCESS = "DELETE_ADMIN_CURRENCY_SUCCESS";

export const ADD_ADMIN_JOB_REQUEST = "ADD_ADMIN_JOB_REQUEST";
export const ADD_ADMIN_JOB_ERROR = "ADD_ADMIN_JOB_ERROR";
export const ADD_ADMIN_JOB_SUCCESS = "ADD_ADMIN_JOB_SUCCESS";

export const UPDATE_ADMIN_JOB_REQUEST = "UPDATE_ADMIN_JOB_REQUEST";
export const UPDATE_ADMIN_JOB_ERROR = "UPDATE_ADMIN_JOB_ERROR";
export const UPDATE_ADMIN_JOB_SUCCESS = "UPDATE_ADMIN_JOB_SUCCESS";

export const DELETE_ADMIN_JOB_REQUEST = "DELETE_ADMIN_JOB_REQUEST";
export const DELETE_ADMIN_JOB_ERROR = "DELETE_ADMIN_JOB_ERROR";
export const DELETE_ADMIN_JOB_SUCCESS = "DELETE_ADMIN_JOB_SUCCESS";

export const GET_ADMIN_DISCOUNT_REQUEST = "GET_ADMIN_DISCOUNT_REQUEST";
export const GET_ADMIN_DISCOUNT_ERROR = "GET_ADMIN_DISCOUNT_ERROR";
export const GET_ADMIN_DISCOUNT_SUCCESS = "GET_ADMIN_DISCOUNT_SUCCESS";

export const ADD_ADMIN_DISCOUNT_REQUEST = "ADD_ADMIN_DISCOUNT_REQUEST";
export const ADD_ADMIN_DISCOUNT_ERROR = "ADD_ADMIN_DISCOUNT_ERROR";
export const ADD_ADMIN_DISCOUNT_SUCCESS = "ADD_ADMIN_DISCOUNT_SUCCESS";

export const UPDATE_ADMIN_DISCOUNT_REQUEST = "UPDATE_ADMIN_DISCOUNT_REQUEST";
export const UPDATE_ADMIN_DISCOUNT_ERROR = "UPDATE_ADMIN_DISCOUNT_ERROR";
export const UPDATE_ADMIN_DISCOUNT_SUCCESS = "UPDATE_ADMIN_DISCOUNT_SUCCESS";

export const DELETE_ADMIN_DISCOUNT_REQUEST = "DELETE_ADMIN_DISCOUNT_REQUEST";
export const DELETE_ADMIN_DISCOUNT_ERROR = "DELETE_ADMIN_DISCOUNT_ERROR";
export const DELETE_ADMIN_DISCOUNT_SUCCESS = "DELETE_ADMIN_DISCOUNT_SUCCESS";

export const GET_FACTOR_REQUEST = "GET_FACTOR_REQUEST";
export const GET_FACTOR_ERROR = "GET_FACTOR_ERROR";
export const GET_FACTOR_SUCCESS = "GET_FACTOR_SUCCESS";

export const ADD_FACTOR_REQUEST = "ADD_FACTOR_REQUEST";
export const ADD_FACTOR_ERROR = "ADD_FACTOR_ERROR";
export const ADD_FACTOR_SUCCESS = "ADD_FACTOR_SUCCESS";

export const UPDATE_FACTOR_REQUEST = "UPDATE_FACTOR_REQUEST";
export const UPDATE_FACTOR_ERROR = "UPDATE_FACTOR_ERROR";
export const UPDATE_FACTOR_SUCCESS = "UPDATE_FACTOR_SUCCESS";

export const DELETE_FACTOR_REQUEST = "DELETE_FACTOR_REQUEST";
export const DELETE_FACTOR_ERROR = "DELETE_FACTOR_ERROR";
export const DELETE_FACTOR_SUCCESS = "DELETE_FACTOR_SUCCESS";


export const GET_ADMIN_MENU_TYPE_REQUEST = "GET_ADMIN_MENU_TYPE_REQUEST";
export const GET_ADMIN_MENU_TYPE_ERROR = "GET_ADMIN_MENU_TYPE_ERROR";
export const GET_ADMIN_MENU_TYPE_SUCCESS = "GET_ADMIN_MENU_TYPE_SUCCESS";

export const ADD_ADMIN_MENU_TYPE_REQUEST = "ADD_ADMIN_MENU_TYPE_REQUEST";
export const ADD_ADMIN_MENU_TYPE_ERROR = "ADD_ADMIN_MENU_TYPE_ERROR";
export const ADD_ADMIN_MENU_TYPE_SUCCESS = "ADD_ADMIN_MENU_TYPE_SUCCESS";

export const UPDATE_ADMIN_MENU_TYPE_REQUEST = "UPDATE_ADMIN_MENU_TYPE_REQUEST";
export const UPDATE_ADMIN_MENU_TYPE_ERROR = "UPDATE_ADMIN_MENU_TYPE_ERROR";
export const UPDATE_ADMIN_MENU_TYPE_SUCCESS = "UPDATE_ADMIN_MENU_TYPE_SUCCESS";

export const DELETE_ADMIN_MENU_TYPE_REQUEST = "DELETE_ADMIN_MENU_TYPE_REQUEST";
export const DELETE_ADMIN_MENU_TYPE_ERROR = "DELETE_ADMIN_MENU_TYPE_ERROR";
export const DELETE_ADMIN_MENU_TYPE_SUCCESS = "DELETE_ADMIN_MENU_TYPE_SUCCESS";

export const GET_PAYMENTMETHODTYPE_REQUEST = "GET_PAYMENTMETHODTYPE_REQUEST";
export const GET_PAYMENTMETHODTYPE_ERROR = "GET_PAYMENTMETHODTYPE_ERROR";
export const GET_PAYMENTMETHODTYPE_SUCCESS = "GET_PAYMENTMETHODTYPE_SUCCESS";

export const ADD_PAYMENTMETHODTYPE_REQUEST = "ADD_PAYMENTMETHODTYPE_REQUEST";
export const ADD_PAYMENTMETHODTYPE_ERROR = "ADD_PAYMENTMETHODTYPE_ERROR";
export const ADD_PAYMENTMETHODTYPE_SUCCESS = "ADD_PAYMENTMETHODTYPE_SUCCESS";

export const UPDATE_PAYMENTMETHODTYPE_REQUEST = "UPDATE_PAYMENTMETHODTYPE_REQUEST";
export const UPDATE_PAYMENTMETHODTYPE_ERROR = "UPDATE_PAYMENTMETHODTYPE_ERROR";
export const UPDATE_PAYMENTMETHODTYPE_SUCCESS = "UPDATE_PAYMENTMETHODTYPE_SUCCESS";

export const DELETE_PAYMENTMETHODTYPE_REQUEST = "DELETE_PAYMENTMETHODTYPE_REQUEST";
export const DELETE_PAYMENTMETHODTYPE_ERROR = "DELETE_PAYMENTMETHODTYPE_ERROR";
export const DELETE_PAYMENTMETHODTYPE_SUCCESS = "DELETE_PAYMENTMETHODTYPE_SUCCESS";

export const GET_BARGAN_PAYMENT_TERM_REQUEST = "GET_BARGAN_PAYMENT_TERM_REQUEST";
export const GET_BARGAN_PAYMENT_TERM_ERROR = "GET_BARGAN_PAYMENT_TERM_ERROR";
export const GET_BARGAN_PAYMENT_TERM_SUCCESS = "GET_BARGAN_PAYMENT_TERM_SUCCESS";

export const ADD_BARGAN_PAYMENT_TERM_REQUEST = "ADD_BARGAN_PAYMENT_TERM_REQUEST";
export const ADD_BARGAN_PAYMENT_TERM_ERROR = "ADD_BARGAN_PAYMENT_TERM_ERROR";
export const ADD_BARGAN_PAYMENT_TERM_SUCCESS = "ADD_BARGAN_PAYMENT_TERM_SUCCESS";

export const UPDATE_BARGAN_PAYMENT_TERM_REQUEST = "UPDATE_BARGAN_PAYMENT_TERM_REQUEST";
export const UPDATE_BARGAN_PAYMENT_TERM_ERROR = "UPDATE_BARGAN_PAYMENT_TERM_ERROR";
export const UPDATE_BARGAN_PAYMENT_TERM_SUCCESS = "UPDATE_BARGAN_PAYMENT_TERM_SUCCESS";

export const DELETE_BARGAN_PAYMENT_TERM_REQUEST = "DELETE_BARGAN_PAYMENT_TERM_REQUEST";
export const DELETE_BARGAN_PAYMENT_TERM_ERROR = "DELETE_BARGAN_PAYMENT_TERM_ERROR";
export const DELETE_BARGAN_PAYMENT_TERM_SUCCESS = "DELETE_BARGAN_PAYMENT_TERM_SUCCESS";

export const GET_OMS_PENDING_REPORT_DATA_ERROR = "GET_OMS_PENDING_REPORT_DATA_ERROR  ";
export const GET_OMS_PENDING_REPORT_DATA_REQUEST = "GET_OMS_PENDING_REPORT_DATA_REQUEST";
export const GET_OMS_PENDING_REPORT_DATA_SUCCESS = "GET_OMS_PENDING_REPORT_DATA_SUCCESS";

export const GET_SELL_ORDER_PENDING_STATE_REPORT_ERROR = "GET_SELL_ORDER_PENDING_STATE_REPORT_ERROR";
export const GET_SELL_ORDER_PENDING_STATE_REPORT_REQUEST = "GET_SELL_ORDER_PENDING_STATE_REPORT_REQUEST";
export const GET_SELL_ORDER_PENDING_STATE_REPORT_SUCCESS = "GET_SELL_ORDER_PENDING_STATE_REPORT_SUCCESS";

export const GET_REGION_PENDING_STATE_REPORT_ERROR = "GET_REGION_PENDING_STATE_REPORT_ERROR";
export const GET_REGION_PENDING_STATE_REPORT_REQUEST = "GET_REGION_PENDING_STATE_REPORT_REQUEST";
export const GET_REGION_PENDING_STATE_REPORT_SUCCESS = "GET_REGION_PENDING_STATE_REPORT_SUCCESS";

export const GET_REGION_PENDING_STATE_REPORT_BY_CUSTOMER_REQUEST = "GET_REGION_PENDING_STATE_REPORT_BY_CUSTOMER_REQUEST";
export const GET_REGION_PENDING_STATE_REPORT_BY_CUSTOMER_SUCCESS = "GET_REGION_PENDING_STATE_REPORT_BY_CUSTOMER_SUCCESS";
export const GET_REGION_PENDING_STATE_REPORT_BY_CUSTOMER_ERROR = "GET_REGION_PENDING_STATE_REPORT_BY_CUSTOMER_ERROR";





export const GET_USER_TYPE_REUQEST = "GET_USER_TYPE_REUQEST";
export const GET_USER_TYPE_SUCCESS = "GET_USER_TYPE_SUCCESS";
export const GET_USER_TYPE_ERROR = "GET_USER_TYPE_ERROR";

export const GET_BUSSINESS_DASHBOARD_REQUEST = "GET_BUSSINESS_DASHBOARD_REQUEST";
export const GET_BUSSINESS_DASHBOARD_SUCCESS = "GET_BUSSINESS_DASHBOARD_SUCCESS";
export const GET_BUSSINESS_DASHBOARD_ERROR = "GET_BUSSINESS_DASHBOARD_ERROR";


export const GET_OMS_SHIP_REPORT_REQUEST = "GET_OMS_SHIP_REPORT_REQUEST";
export const GET_OMS_SHIP_REPORT_SUCCESS = "GET_OMS_SHIP_REPORT_SUCCESS";
export const GET_OMS_SHIP_REPORT_ERROR = "GET_OMS_SHIP_REPORT_ERROR";

export const GET_SELL_SHIP_REGION_STATE_REPORT_REQUEST = "GET_SELL_SHIP_REGION_STATE_REPORT_REQUEST";
export const GET_SELL_SHIP_REGION_STATE_REPORT_SUCCESS = "GET_SELL_SHIP_REGION_STATE_REPORT_SUCCESS";
export const GET_SELL_SHIP_REGION_STATE_REPORT_ERROR = "GET_SELL_SHIP_REGION_STATE_REPORT_ERROR";

export const GET_SHIP_REGION_STATE_REPORT_REQUEST = "GET_SHIP_REGION_STATE_REPORT_REQUEST";
export const GET_SHIP_REGION_STATE_REPORT_SUCCESS = "GET_SHIP_REGION_STATE_REPORT_SUCCESS";
export const GET_SHIP_REGION_STATE_REPORT_ERROR = "GET_SHIP_REGION_STATE_REPORT_ERROR";

export const GET_SHIP_REGION_STATE_REPORT_BY_CUSTOMER_REQUEST = "GET_SHIP_REGION_STATE_REPORT_BY_CUSTOMER_REQUEST";
export const GET_SHIP_REGION_STATE_REPORT_BY_CUSTOMER_SUCCESS = "GET_SHIP_REGION_STATE_REPORT_BY_CUSTOMER_SUCCESS";
export const GET_SHIP_REGION_STATE_REPORT_BY_CUSTOMER_ERROR = "GET_SHIP_REGION_STATE_REPORT_BY_CUSTOMER_ERROR ";


export const GET_OMS_PENDING_POD_REPORT_DATA_REQUEST = "GET_OMS_PENDING_POD_REPORT_DATA_REQUEST";
export const GET_OMS_PENDING_POD_REPORT_DATA_SUCCESS = "GET_OMS_PENDING_POD_REPORT_DATA_SUCCESS ";
export const GET_OMS_PENDING_POD_REPORT_DATA_ERROR = "GET_OMS_PENDING_POD_REPORT_DATA_ERROR";

export const GET_PENDING_POD_REGION_STATE_REPORT_REQUEST = "GET_PENDING_POD_REGION_STATE_REPORT_REQUEST";
export const GET_PENDING_POD_REGION_STATE_REPORT_SUCCESS = "GET_PENDING_POD_REGION_STATE_REPORT_SUCCESS";
export const GET_PENDING_POD_REGION_STATE_REPORT_ERROR = "GET_PENDING_POD_REGION_STATE_REPORT_ERROR";

export const GET_POD_STATE_REPORT_REQUEST = "GET_POD_STATE_REPORT_REQUEST";
export const GET_POD_STATE_REPORT_SUCCESS = "GET_POD_STATE_REPORT_SUCCESS";
export const GET_POD_STATE_REPORT_ERROR = "GET_POD_STATE_REPORT_ERROR";

export const GET_POD_REGION_STATE_REPORT_BY_CUSTOMER_REQUEST = "GET_POD_REGION_STATE_REPORT_BY_CUSTOMER_REQUEST";
export const GET_POD_REGION_STATE_REPORT_BY_CUSTOMER_SUCCESS = "GET_POD_REGION_STATE_REPORT_BY_CUSTOMER_SUCCESS";
export const GET_POD_REGION_STATE_REPORT_BY_CUSTOMER_ERROR = "GET_POD_REGION_STATE_REPORT_BY_CUSTOMER_ERROR";





export const GET_SALES_REPORT_ORDER_ITEM_REQUEST = "GET_SALES_REPORT_ORDER_ITEM_REQUEST";
export const GET_SALES_REPORT_ORDER_ITEM_SUCCESS = "GET_SALES_REPORT_ORDER_ITEM_SUCCESS";
export const GET_SALES_REPORT_ORDER_ITEM_ERROR = "GET_SALES_REPORT_ORDER_ITEM_ERROR";

export const GET_SALES_REPORT_ORDER_PRINT_REQUEST = "GET_SALES_REPORT_ORDER_PRINT_REQUEST";
export const GET_SALES_REPORT_ORDER_PRINT_SUCCESS = "GET_SALES_REPORT_ORDER_PRINT_SUCCESS ";
export const GET_SALES_REPORT_ORDER_PRINT_ERROR = "GET_SALES_REPORT_ORDER_PRINT_ERROR";

export const GET_SALES_REPORT_ORDER_COLUMN_REQUEST = "GET_SALES_REPORT_ORDER_COLUMN_REQUEST";
export const GET_SALES_REPORT_ORDER_COLUMN_SUCCESS = "GET_SALES_REPORT_ORDER_COLUMN_SUCCESS ";
export const GET_SALES_REPORT_ORDER_COLUMN_ERROR = "GET_SALES_REPORT_ORDER_COLUMN_ERROR";



export const GET_ONE_BILL_PAYMENT_REQUEST = "GET_ONE_BILL_PAYMENT_REQUEST";
export const GET_ONE_BILL_PAYMENT_SUCCESS = "GET_ONE_BILL_PAYMENT_SUCCESS";
export const GET_ONE_BILL_PAYMENT_ERROR = "GET_ONE_BILL_PAYMENT_ERROR";

export const GET_BILL_PAYMENT_REQUEST = "GET_BILL_PAYMENT_REQUEST";
export const GET_BILL_PAYMENT_SUCCESS = "GET_BILL_PAYMENT_SUCCESS";
export const GET_BILL_PAYMENT_ERROR = "GET_BILL_PAYMENT_ERROR";

export const ADD_BILL_PAYMENT_REQUEST = "ADD_BILL_PAYMENT_REQUEST";
export const ADD_BILL_PAYMENT_SUCCESS = "ADD_BILL_PAYMENT_SUCCESS";
export const ADD_BILL_PAYMENT_ERROR = "ADD_BILL_PAYMENT_ERROR";

export const UPDATE_BILL_PAYMENT_REQUEST = "UPDATE_BILL_PAYMENT_REQUEST";
export const UPDATE_BILL_PAYMENT_SUCCESS = "UPDATE_BILL_PAYMENT_SUCCESS";
export const UPDATE_BILL_PAYMENT_ERROR = "UPDATE_BILL_PAYMENT_ERROR";

export const DELETE_BILL_PAYMENT_REQUEST = "DELETE_BILL_PAYMENT_REQUEST";
export const DELETE_BILL_PAYMENT_SUCCESS = "DELETE_BILL_PAYMENT_SUCCESS";
export const DELETE_BILL_PAYMENT_ERROR = "DELETE_BILL_PAYMENT_ERROR";



export const GET_PAY_VENDOR_BILL_FOR_PAYMENT_REQUEST = "GET_PAY_VENDOR_BILL_FOR_PAYMENT_REQUEST";
export const GET_PAY_VENDOR_BILL_FOR_PAYMENT_SUCCESS = "GET_PAY_VENDOR_BILL_FOR_PAYMENT_SUCCESS";
export const GET_PAY_VENDOR_BILL_FOR_PAYMENT_ERROR = "GET_PAY_VENDOR_BILL_FOR_PAYMENT_ERROR";



export const GET_ONE_BILL_PAYMENT_LINE_REQUEST = "GET_ONE_BILL_PAYMENT_LINE_REQUEST";
export const GET_ONE_BILL_PAYMENT_LINE_SUCCESS = "GET_ONE_BILL_PAYMENT_LINE_SUCCESS";
export const GET_ONE_BILL_PAYMENT_LINE_ERROR = "GET_ONE_BILL_PAYMENT_LINE_ERROR";

export const ADD_BILL_PAYMENT_LINE_REQUEST = "ADD_BILL_PAYMENT_LINE_REQUEST";
export const ADD_BILL_PAYMENT_LINE_SUCCESS = "ADD_BILL_PAYMENT_LINE_SUCCESS";
export const ADD_BILL_PAYMENT_LINE_ERROR = "ADD_BILL_PAYMENT_LINE_ERROR";

export const UPDATE_BILL_PAYMENT_LINE_REQUEST = "UPDATE_BILL_PAYMENT_LINE_REQUEST";
export const UPDATE_BILL_PAYMENT_LINE_SUCCESS = "UPDATE_BILL_PAYMENT_LINE_SUCCESS";
export const UPDATE_BILL_PAYMENT_LINE_ERROR = "UPDATE_BILL_PAYMENT_LINE_ERROR";

export const DELETE_BILL_PAYMENT_LINE_REQUEST = "DELETE_BILL_PAYMENT_LINE_REQUEST";
export const DELETE_BILL_PAYMENT_LINE_SUCCESS = "DELETE_BILL_PAYMENT_LINE_SUCCESS";
export const DELETE_BILL_PAYMENT_LINE_ERROR = "DELETE_BILL_PAYMENT_LINE_ERROR";

export const ADD_CUSTOMER_REFUND_HEADER_REQUEST = "ADD_CUSTOMER_REFUND_HEADER_REQUEST";
export const ADD_CUSTOMER_REFUND_HEADER_SUCCESS = "ADD_CUSTOMER_REFUND_HEADER_SUCCESS";
export const ADD_CUSTOMER_REFUND_HEADER_ERROR = "ADD_CUSTOMER_REFUND_HEADER_ERROR";

export const GETALL_CUSTOMER_REFUND_HEADER_REQUEST = "GETALL_CUSTOMER_REFUND_HEADER_REQUEST";
export const GETALL_CUSTOMER_REFUND_HEADER_SUCCESS = "GETALL_CUSTOMER_REFUND_HEADER_SUCCESS";
export const GETALL_CUSTOMER_REFUND_HEADER_ERROR = "GETALL_CUSTOMER_REFUND_HEADER_ERROR";

export const GET_ONE_CUSTOMER_REFUND_HEADER_REQUEST = "GET_ONE_CUSTOMER_REFUND_HEADER_REQUEST";
export const GET_ONE_CUSTOMER_REFUND_HEADER_SUCCESS = "GET_ONE_CUSTOMER_REFUND_HEADER_SUCCESS";
export const GET_ONE_CUSTOMER_REFUND_HEADER_ERROR = "GET_ONE_CUSTOMER_REFUND_HEADER_ERROR";

export const DELETE_CUSTOMER_REFUND_HEADER_REQUEST = "DELETE_CUSTOMER_REFUND_HEADER_REQUEST";
export const DELETE_CUSTOMER_REFUND_HEADER_SUCCESS = "DELETE_CUSTOMER_REFUND_HEADER_SUCCESS";
export const DELETE_CUSTOMER_REFUND_HEADER_ERROR = "DELETE_CUSTOMER_REFUND_HEADER_ERROR";

export const UPDATE_CUSTOMER_REFUND_HEADER_REQUEST = "UPDATE_CUSTOMER_REFUND_HEADER_REQUEST";
export const UPDATE_CUSTOMER_REFUND_HEADER_SUCCESS = "UPDATE_CUSTOMER_REFUND_HEADER_SUCCESS";
export const UPDATE_CUSTOMER_REFUND_HEADER_ERROR = "UPDATE_CUSTOMER_REFUND_HEADER_ERROR";

export const GET_CUSTOMER_REFUND_FORM_DATA_SUCCESS = "GET_CUSTOMER_REFUND_FORM_DATA_SUCCESS";
export const GET_CUSTOMER_REFUND_FORM_DATA_REQUEST = "GET_CUSTOMER_REFUND_FORM_DATA_REQUEST";
export const GET_CUSTOMER_REFUND_FORM_DATA_ERROR = "GET_CUSTOMER_REFUND_FORM_DATA_ERROR";

export const ADD_CUSTOMER_REFUND_LINE_REQUEST = "ADD_CUSTOMER_REFUND_LINE_REQUEST";
export const ADD_CUSTOMER_REFUND_LINE_SUCCESS = "ADD_CUSTOMER_REFUND_LINE_SUCCESS";
export const ADD_CUSTOMER_REFUND_LINE_ERROR = "ADD_CUSTOMER_REFUND_LINE_ERROR";
export const GET_ONE_CUSTOMER_REFUND_LINE_ERROR = "GET_ONE_CUSTOMER_REFUND_LINE_ERROR";
export const GET_ONE_CUSTOMER_REFUND_LINE_SUCCESS = "GET_ONE_CUSTOMER_REFUND_LINE_SUCCESS";
export const GET_ONE_CUSTOMER_REFUND_LINE_REQUEST = "GET_ONE_CUSTOMER_REFUND_LINE_REQUEST";
export const GET_CUSTOMER_REFUND_DETAIL_REQUEST = "GET_CUSTOMER_REFUND_DETAIL_REQUEST";
export const GET_CUSTOMER_REFUND_DETAIL_SUCCESS = "GET_CUSTOMER_REFUND_DETAIL_SUCCESS";
export const GET_CUSTOMER_REFUND_DETAIL_ERROR = "GET_CUSTOMER_REFUND_DETAIL_ERROR";
export const UPDATE_CUSTOMER_REFUND_LINE_REQUEST = "UPDATE_CUSTOMER_REFUND_LINE_REQUEST";
export const UPDATE_CUSTOMER_REFUND_LINE_SUCCESS = "UPDATE_CUSTOMER_REFUND_LINE_SUCCESS";
export const UPDATE_CUSTOMER_REFUND_LINE_ERROR = "UPDATE_CUSTOMER_REFUND_LINE_ERROR";
export const DELETE_CUSTOMER_REFUND_LINE_REQUEST = "DELETE_CUSTOMER_REFUND_LINE_REQUEST";
export const DELETE_CUSTOMER_REFUND_LINE_SUCCESS = "DELETE_CUSTOMER_REFUND_LINE_SUCCESS";
export const DELETE_CUSTOMER_REFUND_LINE_ERROR = "DELETE_CUSTOMER_REFUND_LINE_ERROR";

export const GET_SALES_REPORT_ORDER_COLUMNSVALUE_REQUEST = "GET_SALES_REPORT_ORDER_COLUMNSVALUE_REQUEST";
export const GET_SALES_REPORT_ORDER_COLUMNSVALUE_SUCCESS = "GET_SALES_REPORT_ORDER_COLUMNSVALUE_SUCCESS ";
export const GET_SALES_REPORT_ORDER_COLUMNSVALUE_ERROR = "GET_SALES_REPORT_ORDER_COLUMNSVALUE_ERROR";


export const GET_ALL_SCM_BRAND_REQUEST = "GET_SCM_BREND_REQUEST";
export const GET_ALL_SCM_BRAND_ERROR = "GET_SCM_BREND_ERROR";
export const GET_ALL_SCM_BRAND_SUCCESS = "GET_SCM_BREND_SUCCESS";

export const GET_SCM_BRAND_REQUEST = "GET_SCM_BREND_REQUEST";
export const GET_SCM_BRAND_ERROR = "GET_SCM_BREND_ERROR";
export const GET_SCM_BRAND_SUCCESS = "GET_SCM_BREND_SUCCESS";

export const ADD_SCM_BRAND_REQUEST = "ADD_SCM_BRAND_REQUEST";
export const ADD_SCM_BRAND_ERROR = "ADD_SCM_BRAND_ERROR";
export const ADD_SCM_BRAND_SUCCESS = "ADD_SCM_BRAND_SUCCESS";

export const UPDATE_SCM_BRAND_REQUEST = "UPDATE_SCM_BRAND_REQUEST";
export const UPDATE_SCM_BRAND_ERROR = "UPDATE_SCM_BRAND_ERROR";
export const UPDATE_SCM_BRAND_SUCCESS = "UPDATE_SCM_BRAND_SUCCESS";

export const DELETE_SCM_BRAND_REQUEST = "DELETE_SCM_BRAND_REQUEST";
export const DELETE_SCM_BRAND_ERROR = "DELETE_SCM_BRAND_ERROR";
export const DELETE_SCM_BRAND_SUCCESS = "DELETE_SCM_BRAND_SUCCESS";



export const GET_OMS_RETURN_REPORT_REQUEST = "GET_OMS_RETURN_REPORT_REQUEST";
export const GET_OMS_RETURN_REPORT_SUCCESS = "GET_OMS_RETURN_REPORT_SUCCESS";
export const GET_OMS_RETURN_REPORT_ERROR = "GET_OMS_RETURN_REPORT_ERROR";

export const GET_RETURN_REGION_STATE_REPORT_REQUEST = "GET_RETURN_REGION_STATE_REPORT_REQUEST";
export const GET_RETURN_REGION_STATE_REPORT_SUCCESS = "GET_RETURN_REGION_STATE_REPORT_SUCCESS";
export const GET__RETURN_REGION_STATE_REPORT_ERROR = "GET__RETURN_REGION_STATE_REPORT_ERROR";

export const GET_RETURN_REGION_REPORT_REQUEST = "GET_RETURN_REGION_REPORT_REQUEST";
export const GET_RETURN_REGION_REPORT_SUCCESS = "GET_RETURN_REGION_REPORT_SUCCESS";
export const GET_RETURN_REGION_REPORT_ERROR = "GET_RETURN_REGION_REPORT_ERROR";





export const GET_WISE_STOCK_REPORT_REQUEST = "GET_WISE_STOCK_REPORT_REQUEST";
export const GET_WISE_STOCK_REPORT_SUCCESS = "GET_WISE_STOCK_REPORT_SUCCESS";
export const GET_WISE_STOCK_REPORT_ERROR = "GET_WISE_STOCK_REPORT_ERROR ";



export const GET_OMS_STOCK_SUMMARY_REPORT_REQUEST = "GET_OMS_STOCK_SUMMARY_REPORT_REQUEST";
export const GET_OMS_STOCK_SUMMARY_REPORT_SUCCESS = "GET_OMS_STOCK_SUMMARY_REPORT_SUCCESS ";
export const GET_OMS_STOCK_SUMMARY_REPORT_ERROR = "GET_OMS_STOCK_SUMMARY_REPORT_ERROR";



export const GET_RETURN_REGION_STATE_REPORT_BY_CUSTOMER_ERROR = "GET_RETURN_REGION_STATE_REPORT_BY_CUSTOMER_ERROR";
export const GET_RETURN_REGION_STATE_REPORT_BY_CUSTOMER_REQUEST = "GET_RETURN_REGION_STATE_REPORT_BY_CUSTOMER_REQUEST";
export const GET_RETURN_REGION_STATE_REPORT_BY_CUSTOMER_SUCCESS = "GET_RETURN_REGION_STATE_REPORT_BY_CUSTOMER_SUCCESS ";

export const GET_RETURN_REGION_STATE_REPORT_CUSTOMER_ERROR = "GET_RETURN_REGION_STATE_REPORT_CUSTOMER_ERROR";
export const GET_RETURN_REGION_STATE_REPORT_CUSTOMER_REQUEST = "GET_RETURN_REGION_STATE_REPORT_CUSTOMER_REQUEST";
export const GET_RETURN_REGION_STATE_REPORT_CUSTOMER_SUCCESS = "GET_RETURN_REGION_STATE_REPORT_CUSTOMER_SUCCESS ";

export const GET_SALE_RETURN_REGION_STATE_REPORT_CUSTOMER_ERROR = "GET_SALE_RETURN_REGION_STATE_REPORT_CUSTOMER_ERROR";
export const GET_SALE_RETURN_REGION_STATE_REPORT_CUSTOMER_REQUEST = "GET_SALE_RETURN_REGION_STATE_REPORT_CUSTOMER_REQUEST";
export const GET_SALE_RETURN_REGION_STATE_REPORT_CUSTOMER_SUCCESS = "GET_SALE_RETURN_REGION_STATE_REPORT_CUSTOMER_SUCCESS ";

export const GET_JOURNAL_VOUCHER_FORM_DATA_SUCCESS = "GET_JOURNAL_VOUCHER_FORM_DATA_SUCCESS";
export const GET_JOURNAL_VOUCHER_FORM_DATA_REQUEST = "GET_JOURNAL_VOUCHER_FORM_DATA_REQUEST";
export const GET_JOURNAL_VOUCHER_FORM_DATA_ERROR = "GET_JOURNAL_VOUCHER_FORM_DATA_ERROR ";

export const ADD_JOURNAL_VOUCHER_HEADER_REQUEST = "ADD_JOURNAL_VOUCHER_HEADER_REQUEST";
export const ADD_JOURNAL_VOUCHER_HEADER_SUCCESS = "ADD_JOURNAL_VOUCHER_HEADER_SUCCESS";
export const ADD_JOURNAL_VOUCHER_HEADER_ERROR = "ADD_JOURNAL_VOUCHER_HEADER_ERROR";

export const GET_ONE_JOURNAL_VOUCHER_HEADER_REQUEST = "GET_ONE_JOURNAL_VOUCHER_HEADER_REQUEST";
export const GET_ONE_JOURNAL_VOUCHER_HEADER_SUCCESS = "GET_ONE_JOURNAL_VOUCHER_HEADER_SUCCESS";
export const GET_ONE_JOURNAL_VOUCHER_HEADER_ERROR = "GET_ONE_JOURNAL_VOUCHER_HEADER_ERROR";

export const UPDATE_JOURNAL_VOUCHER_HEADER_REQUEST = "UPDATE_JOURNAL_VOUCHER_HEADER_REQUEST";
export const UPDATE_JOURNAL_VOUCHER_HEADER_SUCCESS = "UPDATE_JOURNAL_VOUCHER_HEADER_SUCCESS";
export const UPDATE_JOURNAL_VOUCHER_HEADER_ERROR = "UPDATE_JOURNAL_VOUCHER_HEADER_ERROR";

export const GETALL_JOURNAL_VOUCHER_HEADER_REQUEST = "GETALL_JOURNAL_VOUCHER_HEADER_REQUEST";
export const GETALL_JOURNAL_VOUCHER_HEADER_SUCCESS = "GETALL_JOURNAL_VOUCHER_HEADER_SUCCESS";
export const GETALL_JOURNAL_VOUCHER_HEADER_ERROR = "GETALL_JOURNAL_VOUCHER_HEADER_ERROR";

export const DELETE_JOURNAL_VOUCHER_HEADER_REQUEST = "DELETE_JOURNAL_VOUCHER_HEADER_REQUEST";
export const DELETE_JOURNAL_VOUCHER_HEADER_SUCCESS = "DELETE_JOURNAL_VOUCHER_HEADER_SUCCESS";
export const DELETE_JOURNAL_VOUCHER_HEADER_ERROR = "DELETE_JOURNAL_VOUCHER_HEADER_ERROR";

export const ADD_JOURNAL_VOUCHER_LINE_REQUEST = "ADD_JOURNAL_VOUCHER_LINE_REQUEST";
export const ADD_JOURNAL_VOUCHER_LINE_SUCCESS = "ADD_JOURNAL_VOUCHER_LINE_SUCCESS";
export const ADD_JOURNAL_VOUCHER_LINE_ERROR = "ADD_JOURNAL_VOUCHER_LINE_ERROR";
export const GET_ONE_JOURNAL_VOUCHER_LINE_ERROR = "GET_ONE_JOURNAL_VOUCHER_LINE_ERROR";
export const GET_ONE_JOURNAL_VOUCHER_LINE_SUCCESS = "GET_ONE_JOURNAL_VOUCHER_LINE_SUCCESS";
export const GET_ONE_JOURNAL_VOUCHER_LINE_REQUEST = "GET_ONE_JOURNAL_VOUCHER_LINE_REQUEST";
export const GET_JOURNAL_VOUCHER_DETAIL_REQUEST = "GET_JOURNAL_VOUCHER_DETAIL_REQUEST";
export const GET_JOURNAL_VOUCHER_DETAIL_SUCCESS = "GET_JOURNAL_VOUCHER_DETAIL_SUCCESS";
export const GET_JOURNAL_VOUCHER_DETAIL_ERROR = "GET_JOURNAL_VOUCHER_DETAIL_ERROR";
export const UPDATE_JOURNAL_VOUCHER_LINE_REQUEST = "UPDATE_JOURNAL_VOUCHER_LINE_REQUEST";
export const UPDATE_JOURNAL_VOUCHER_LINE_SUCCESS = "UPDATE_JOURNAL_VOUCHER_LINE_SUCCESS";
export const UPDATE_JOURNAL_VOUCHER_LINE_ERROR = "UPDATE_JOURNAL_VOUCHER_LINE_ERROR";
export const DELETE_JOURNAL_VOUCHER_LINE_REQUEST = "DELETE_JOURNAL_VOUCHER_LINE_REQUEST";
export const DELETE_JOURNAL_VOUCHER_LINE_SUCCESS = "DELETE_JOURNAL_VOUCHER_LINE_SUCCESS";
export const DELETE_JOURNAL_VOUCHER_LINE_ERROR = "DELETE_JOURNAL_VOUCHER_LINE_ERROR";

export const GET_NEW_SEASON_DATA_ERROR = "GET_NEW_SEASON_DATA_ERROR";
export const GET_NEW_SEASON_DATA_REQUEST = "GET_NEW_SEASON_DATA_REQUEST";
export const GET_NEW_SEASON_DATA_SUCCESS = "GET_NEW_SEASON_DATA_SUCCESS";


export const GET_ONE_BILL_DIRECT_PAYMENT_HEADER_REQUEST = "GET_ONE_BILL_DIRECT_PAYMENT_HEADER_REQUEST";
export const GET_ONE_BILL_DIRECT_PAYMENT_HEADER_SUCCESS = "GET_ONE_BILL_DIRECT_PAYMENT_HEADER_SUCCESS";
export const GET_ONE_BILL_DIRECT_PAYMENT_HEADER_ERROR = "GET_ONE_BILL_DIRECT_PAYMENT_HEADER_ERROR";

export const GET_ALL_BILL_DIRECT_PAYMENT_HEADER_REQUEST = "GET_ALL_BILL_DIRECT_PAYMENT_HEADER_REQUEST";
export const GET_ALL_BILL_DIRECT_PAYMENT_HEADER_SUCCESS = "GET_ALL_BILL_DIRECT_PAYMENT_HEADER_SUCCESS";
export const GET_ALL_BILL_DIRECT_PAYMENT_HEADER_ERROR = "GET_ALL_BILL_DIRECT_PAYMENT_HEADER_ERROR";

export const ADD_BILL_DIRECT_PAYMENT_HEADER_REQUEST = "ADD_BILL_DIRECT_PAYMENT_HEADER_REQUEST";
export const ADD_BILL_DIRECT_PAYMENT_HEADER_SUCCESS = "ADD_BILL_DIRECT_PAYMENT_HEADER_SUCCESS";
export const ADD_BILL_DIRECT_PAYMENT_HEADER_ERROR = "ADD_BILL_DIRECT_PAYMENT_HEADER_ERROR";

export const UPDATE_BILL_DIRECT_PAYMENT_HEADER_REQUEST = "UPDATE_BILL_DIRECT_PAYMENT_HEADER_REQUEST";
export const UPDATE_BILL_DIRECT_PAYMENT_HEADER_SUCCESS = "UPDATE_BILL_DIRECT_PAYMENT_HEADER_SUCCESS";
export const UPDATE_BILL_DIRECT_PAYMENT_HEADER_ERROR = "UPDATE_BILL_DIRECT_PAYMENT_HEADER_ERROR";

export const DELETE_BILL_DIRECT_PAYMENT_HEADER_REQUEST = "DELETE_BILL_DIRECT_PAYMENT_HEADER_REQUEST";
export const DELETE_BILL_DIRECT_PAYMENT_HEADER_SUCCESS = "DELETE_BILL_DIRECT_PAYMENT_HEADER_SUCCESS";
export const DELETE_BILL_DIRECT_PAYMENT_HEADER_ERROR = "DELETE_BILL_DIRECT_PAYMENT_HEADER_ERROR";


export const GET_ONE_BILL_DIRECT_PAYMENT_LINE_REQUEST = "GET_ONE_BILL_DIRECT_PAYMENT_LINE_REQUEST";
export const GET_ONE_BILL_DIRECT_PAYMENT_LINE_SUCCESS = "GET_ONE_BILL_DIRECT_PAYMENT_LINE_SUCCESS";
export const GET_ONE_BILL_DIRECT_PAYMENT_LINE_ERROR = "GET_ONE_BILL_DIRECT_PAYMENT_LINE_ERROR";

export const GET_ALL_BILL_DIRECT_PAYMENT_LINE_REQUEST = "GET_ALL_BILL_DIRECT_PAYMENT_LINE_REQUEST";
export const GET_ALL_BILL_DIRECT_PAYMENT_LINE_SUCCESS = "GET_ALL_BILL_DIRECT_PAYMENT_LINE_SUCCESS";
export const GET_ALL_BILL_DIRECT_PAYMENT_LINE_ERROR = "GET_ALL_BILL_DIRECT_PAYMENT_LINE_ERROR";

export const ADD_BILL_DIRECT_PAYMENT_LINE_REQUEST = "ADD_BILL_DIRECT_PAYMENT_LINE_REQUEST";
export const ADD_BILL_DIRECT_PAYMENT_LINE_SUCCESS = "ADD_BILL_DIRECT_PAYMENT_LINE_SUCCESS";
export const ADD_BILL_DIRECT_PAYMENT_LINE_ERROR = "ADD_BILL_DIRECT_PAYMENT_LINE_ERROR";

export const UPDATE_BILL_DIRECT_PAYMENT_LINE_REQUEST = "UPDATE_BILL_DIRECT_PAYMENT_LINE_REQUEST";
export const UPDATE_BILL_DIRECT_PAYMENT_LINE_SUCCESS = "UPDATE_BILL_DIRECT_PAYMENT_LINE_SUCCESS";
export const UPDATE_BILL_DIRECT_PAYMENT_LINE_ERROR = "UPDATE_BILL_DIRECT_PAYMENT_LINE_ERROR";

export const DELETE_BILL_DIRECT_PAYMENT_LINE_REQUEST = "DELETE_BILL_DIRECT_PAYMENT_LINE_REQUEST";
export const DELETE_BILL_DIRECT_PAYMENT_LINE_SUCCESS = "DELETE_BILL_DIRECT_PAYMENT_LINE_SUCCESS";
export const DELETE_BILL_DIRECT_PAYMENT_LINE_ERROR = "DELETE_BILL_DIRECT_PAYMENT_LINE_ERROR";

export const GET_BILL_DIRECT_PAYMENT_LINE_REQUEST = "GET_BILL_DIRECT_PAYMENT_LINE_REQUEST";
export const GET_BILL_DIRECT_PAYMENT_LINE_SUCCESS = "GET_BILL_DIRECT_PAYMENT_LINE_SUCCESS";
export const GET_BILL_DIRECT_PAYMENT_LINE_ERROR = "GET_BILL_DIRECT_PAYMENT_LINE_ERROR";


export const GET_BILL_DIRECT_PAYMENT_ITEM_REQUEST = "GET_BILL_DIRECT_PAYMENT_ITEM_REQUEST";
export const GET_BILL_DIRECT_PAYMENT_ITEM_SUCCESS = "GET_BILL_DIRECT_PAYMENT_ITEM_SUCCESS";
export const GET_BILL_DIRECT_PAYMENT_ITEM_ERROR = "GET_BILL_DIRECT_PAYMENT_ITEM_ERROR";

export const GET_ONE_BILL_DIRECT_PAYMENT_ITEM_REQUEST = "GET_ONE_BILL_DIRECT_PAYMENT_ITEM_REQUEST";
export const GET_ONE_BILL_DIRECT_PAYMENT_ITEM_SUCCESS = "GET_ONE_BILL_DIRECT_PAYMENT_ITEM_SUCCESS";
export const GET_ONE_BILL_DIRECT_PAYMENT_ITEM_ERROR = "GET_ONE_BILL_DIRECT_PAYMENT_ITEM_ERROR";

export const ADD_BILL_DIRECT_PAYMENT_ITEM_REQUEST = "ADD_BILL_DIRECT_PAYMENT_ITEM_REQUEST";
export const ADD_BILL_DIRECT_PAYMENT_ITEM_SUCCESS = "ADD_BILL_DIRECT_PAYMENT_ITEM_SUCCESS";
export const ADD_BILL_DIRECT_PAYMENT_ITEM_ERROR = "ADD_BILL_DIRECT_PAYMENT_ITEM_ERROR";

export const UPDATE_BILL_DIRECT_PAYMENT_ITEM_REQUEST = "UPDATE_BILL_DIRECT_PAYMENT_ITEM_REQUEST";
export const UPDATE_BILL_DIRECT_PAYMENT_ITEM_SUCCESS = "UPDATE_BILL_DIRECT_PAYMENT_ITEM_SUCCESS";
export const UPDATE_BILL_DIRECT_PAYMENT_ITEM_ERROR = "UPDATE_BILL_DIRECT_PAYMENT_ITEM_ERROR";

export const DELETE_BILL_DIRECT_PAYMENT_ITEM_REQUEST = "DELETE_BILL_DIRECT_PAYMENT_ITEM_REQUEST";
export const DELETE_BILL_DIRECT_PAYMENT_ITEM_SUCCESS = "DELETE_BILL_DIRECT_PAYMENT_ITEM_SUCCESS";
export const DELETE_BILL_DIRECT_PAYMENT_ITEM_ERROR = "DELETE_BILL_DIRECT_PAYMENT_ITEM_ERROR";
export const GET_PAYMENT_DATA_REQUEST = "GET_PAYMENT_DATA_REQUEST";
export const GET_PAYMENT_DATA_SUCCESS = "GET_PAYMENT_DATA_SUCCESS ";
export const GET_PAYMENT_DATA_ERROR = "GET_PAYMENT_DATA_ERROR";

export const GET_PAYMENT_STATE_REPORT_REQUEST = "GET_PAYMENT_STATE_REPORT_REQUEST";
export const GET_PAYMENT_STATE_REPORT_SUCCESS = "GET_PAYMENT_STATE_REPORT_SUCCESS";
export const GET_PAYMENT_STATE_REPORT_ERROR = "GET_PAYMENT_STATE_REPORT_ERROR";
export const PUT_INVOICE_LINE_DATA_ERROR = "PUT_INVOICE_LINE_DATA_ERROR";
export const PUT_INVOICE_LINE_DATA_REQUEST = "PUT_INVOICE_LINE_DATA_REQUEST";
export const PUT_INVOICE_LINE_DATA_SUCCESS = "PUT_INVOICE_LINE_DATA_SUCCESS";

export const GET_ONE_INVOICE_LINE_DATA_ERROR = "GET_ONE_INVOICE_LINE_DATA_ERROR";
export const GET_ONE_INVOICE_LINE_DATA_REQUEST = "GET_ONE_INVOICE_LINE_DATA_REQUEST";
export const GET_ONE_INVOICE_LINE_DATA_SUCCESS = "GET_ONE_INVOICE_LINE_DATA_SUCCESS";


export const GET_FUND_TRANSFER_REQUEST = "GET_FUND_TRANSFER_REQUEST";
export const GET_FUND_TRANSFER_SUCCESS = "GET_FUND_TRANSFER_SUCCESS";
export const GET_FUND_TRANSFER_ERROR = "GET_FUND_TRANSFER_ERROR";

export const GET_ONE_FUND_TRANSFER_REQUEST = "GET_ONE_FUND_TRANSFER_REQUEST";
export const GET_ONE_FUND_TRANSFER_SUCCESS = "GET_ONE_FUND_TRANSFER_SUCCESS";
export const GET_ONE_FUND_TRANSFER_ERROR = "GET_ONE_FUND_TRANSFER_ERROR";

export const ADD_FUND_TRANSFER_REQUEST = "ADD_FUND_TRANSFER_REQUEST";
export const ADD_FUND_TRANSFER_SUCCESS = "ADD_FUND_TRANSFER_SUCCESS";
export const ADD_FUND_TRANSFER_ERROR = "ADD_FUND_TRANSFER_ERROR";

export const UPDATE_FUND_TRANSFER_REQUEST = "UPDATE_FUND_TRANSFER_REQUEST";
export const UPDATE_FUND_TRANSFER_SUCCESS = "UPDATE_FUND_TRANSFER_SUCCESS";
export const UPDATE_FUND_TRANSFER_ERROR = "UPDATE_FUND_TRANSFER_ERROR";

export const DELETE_FUND_TRANSFER_REQUEST = "DELETE_FUND_TRANSFER_REQUEST";
export const DELETE_FUND_TRANSFER_SUCCESS = "DELETE_FUND_TRANSFER_SUCCESS";
export const DELETE_FUND_TRANSFER_ERROR = "DELETE_FUND_TRANSFER_ERROR";

export const GET_SALE_TARGET_REPORT_REQUEST = "GET_SALE_TARGET_REPORT_REQUEST";
export const GET_SALE_TARGET_REPORT_SUCCESS = "GET_SALE_TARGET_REPORT_SUCCESS";
export const GET_SALE_TARGET_REPORT_ERROR = "GET_SALE_TARGET_REPORT_ERROR";

export const GET_ALL_CREDIT_NOTE_INVOICE_REQUEST = "GET_ALL_CREDIT_NOTE_INVOICE_REQUEST";
export const GET_ALL_CREDIT_NOTE_INVOICE_SUCCESS = "GET_ALL_CREDIT_NOTE_INVOICE_SUCCESS";
export const GET_ALL_CREDIT_NOTE_INVOICE_ERROR = "GET_ALL_CREDIT_NOTE_INVOICE_ERROR";

export const CREATE_CREDIT_NOTE_INVOICE_REQUEST = "CREATE_CREDIT_NOTE_INVOICE_REQUEST";
export const CREATE_CREDIT_NOTE_INVOICE_SUCCESS = "CREATE_CREDIT_NOTE_INVOICE_SUCCESS";
export const CREATE_CREDIT_NOTE_INVOICE_ERROR = "CREATE_CREDIT_NOTE_INVOICE_ERROR";

export const ADD_DEPOSIT_HEADER_REQUEST = "ADD_DEPOSIT_HEADER_REQUEST";
export const ADD_DEPOSIT_HEADER_SUCCESS = "ADD_DEPOSIT_HEADER_SUCCESS";
export const ADD_DEPOSIT_HEADER_ERROR = "ADD_DEPOSIT_HEADER_ERROR";

export const GETALL_DEPOSIT_HEADER_REQUEST = "GETALL_DEPOSIT_HEADER_REQUEST";
export const GETALL_DEPOSIT_HEADER_SUCCESS = "GETALL_DEPOSIT_HEADER_SUCCESS";
export const GETALL_DEPOSIT_HEADER_ERROR = "GETALL_DEPOSIT_HEADER_ERROR";

export const GET_ONE_DEPOSIT_HEADER_REQUEST = "GET_ONE_DEPOSIT_HEADER_REQUEST";
export const GET_ONE_DEPOSIT_HEADER_SUCCESS = "GET_ONE_DEPOSIT_HEADER_SUCCESS";
export const GET_ONE_DEPOSIT_HEADER_ERROR = "GET_ONE_DEPOSIT_HEADER_ERROR";

export const DELETE_DEPOSIT_HEADER_REQUEST = "DELETE_DEPOSIT_HEADER_REQUEST";
export const DELETE_DEPOSIT_HEADER_SUCCESS = "DELETE_DEPOSIT_HEADER_SUCCESS";
export const DELETE_DEPOSIT_HEADER_ERROR = "DELETE_DEPOSIT_HEADER_ERROR";

export const UPDATE_DEPOSIT_HEADER_REQUEST = "UPDATE_DEPOSIT_HEADER_REQUEST";
export const UPDATE_DEPOSIT_HEADER_SUCCESS = "UPDATE_DEPOSIT_HEADER_SUCCESS";
export const UPDATE_DEPOSIT_HEADER_ERROR = "UPDATE_DEPOSIT_HEADER_ERROR";

export const GET_DEPOSIT_FORM_DATA_SUCCESS = "GET_DEPOSIT_FORM_DATA_SUCCESS";
export const GET_DEPOSIT_FORM_DATA_REQUEST = "GET_DEPOSIT_FORM_DATA_REQUEST";
export const GET_DEPOSIT_FORM_DATA_ERROR = "GET_DEPOSIT_FORM_DATA_ERROR";

export const GET_CUSTOMER_PAYMENT_DATA_REQUEST = "GET_CUSTOMER_PAYMENT_DATA_REQUEST";
export const GET_CUSTOMER_PAYMENT_DATA_SUCCESS = "GET_CUSTOMER_PAYMENT_DATA_SUCCESS ";
export const GET_CUSTOMER_PAYMENT_DATA_ERROR = "GET_CUSTOMER_PAYMENT_DATA_ERROR";

export const GET_PO_LOOKUP_REQUEST = "GET_PO_LOOKUP_REQUEST";
export const GET_PO_LOOKUP_SUCCESS = "GET_PO_LOOKUP_SUCCESS";
export const GET_PO_LOOKUP_ERROR = "GET_PO_LOOKUP_ERROR";


export const ADD_INVENTORYADJUSTMENT_HEADER_ERROR = "ADD_INVENTORYADJUSTMENT_HEADER_ERROR";
export const ADD_INVENTORYADJUSTMENT_HEADER_REQUEST = "ADD_INVENTORYADJUSTMENT_HEADER_REQUEST";
export const ADD_INVENTORYADJUSTMENT_HEADER_SUCCESS = "ADD_INVENTORYADJUSTMENT_HEADER_SUCCESS";
export const DELETE_INVENTORYADJUSTMENT_HEADER_ERROR = "DELETE_INVENTORYADJUSTMENT_HEADER_ERROR";
export const DELETE_INVENTORYADJUSTMENT_HEADER_REQUEST = "DELETE_INVENTORYADJUSTMENT_HEADER_REQUEST";
export const DELETE_INVENTORYADJUSTMENT_HEADER_SUCCESS = "DELETE_INVENTORYADJUSTMENT_HEADER_SUCCESS";
export const GET_ALL_INVENTORYADJUSTMENT_HEADER_ERROR = "GET_ALL_INVENTORYADJUSTMENT_HEADER_ERROR";
export const GET_ALL_INVENTORYADJUSTMENT_HEADER_REQUEST = "GET_ALL_INVENTORYADJUSTMENT_HEADER_REQUEST";
export const GET_ALL_INVENTORYADJUSTMENT_HEADER_SUCCESS = "GET_ALL_INVENTORYADJUSTMENT_HEADER_SUCCESS";
export const GET_ITEM_INVENTORYADJUSTMENT_HEADER_FORM_DATA_ERROR = "GET_ITEM_INVENTORYADJUSTMENT_HEADER_FORM_DATA_ERROR";
export const GET_ITEM_INVENTORYADJUSTMENT_HEADER_FORM_DATA_REQUEST = "GET_ITEM_INVENTORYADJUSTMENT_HEADER_FORM_DATA_REQUEST";
export const GET_INVENTORYADJUSTMENT_HEADER_FORM_DATA_SUCCESS = "GET_INVENTORYADJUSTMENT_HEADER_FORM_DATA_SUCCESS";
export const GET_ONE_INVENTORYADJUSTMENT_HEADER_DETAIL_ERROR = "GET_ONE_INVENTORYADJUSTMENT_HEADER_DETAIL_ERROR";
export const GET_ONE_INVENTORYADJUSTMENT_HEADER_DETAIL_REQUEST = "GET_ONE_INVENTORYADJUSTMENT_HEADER_DETAIL_REQUEST";
export const GET_ONE_INVENTORYADJUSTMENT_HEADER_DETAIL_SUCCESS = "GET_ONE_INVENTORYADJUSTMENT_HEADER_DETAIL_SUCCESS";
export const GET_ONE_INVENTORYADJUSTMENT_HEADER_ERROR = "GET_ONE_INVENTORYADJUSTMENT_HEADER_ERROR";
export const GET_ONE_INVENTORYADJUSTMENT_HEADER_REQUEST = "GET_ONE_INVENTORYADJUSTMENT_HEADER_REQUEST";
export const GET_ONE_INVENTORYADJUSTMENT_HEADER_SUCCESS = "GET_ONE_INVENTORYADJUSTMENT_HEADER_SUCCESS";
export const UPDATE_INVENTORYADJUSTMENT_HEADER_ERROR = "UPDATE_INVENTORYADJUSTMENT_HEADER_ERROR";
export const UPDATE_INVENTORYADJUSTMENT_HEADER_REQUEST = "UPDATE_INVENTORYADJUSTMENT_HEADER_REQUEST";
export const UPDATE_INVENTORYADJUSTMENT_HEADER_SUCCESS = "UPDATE_INVENTORYADJUSTMENT_HEADER_SUCCESS";
export const GET_ALL_ADJUSTMENTACCOUNT_HEADER_SUCCESS = "GET_ALL_ADJUSTMENTACCOUNT_HEADER_SUCCESS";
export const GET_ALL_ADJUSTMENTACCOUNT_HEADER_ERROR = "GET_ALL_ADJUSTMENTACCOUNT_HEADER_ERROR";
export const GET_ALL_ADJUSTMENTACCOUNT_HEADER_REQUEST = "GET_ALL_ADJUSTMENTACCOUNT_HEADER_REQUEST";


export const ADD_INVENTORY_STOCK_ADJUSTMENT_LINE_ERROR = "ADD_INVENTORY_STOCK_ADJUSTMENT_LINE_ERROR";
export const ADD_INVENTORY_STOCK_ADJUSTMENT_LINE_REQUEST = "ADD_INVENTORY_STOCK_ADJUSTMENT_LINE_REQUEST";
export const ADD_INVENTORY_STOCK_ADJUSTMENT_LINE_SUCCESS = "ADD_INVENTORY_STOCK_ADJUSTMENT_LINE_SUCCESS";
export const DELETE_INVENTORY_STOCK_ADJUSTMENT_LINE_ERROR = "DELETE_INVENTORY_STOCK_ADJUSTMENT_LINE_ERROR";
export const DELETE_INVENTORY_STOCK_ADJUSTMENT_LINE_REQUEST = "DELETE_INVENTORY_STOCK_ADJUSTMENT_LINE_REQUEST";
export const DELETE_INVENTORY_STOCK_ADJUSTMENT_LINE_SUCCESS = "DELETE_INVENTORY_STOCK_ADJUSTMENT_LINE_SUCCESS";
export const GET_ONE_INVENTORY_STOCK_ADJUSTMENT_LINE_DETAIL_ERROR = "GET_ONE_INVENTORY_STOCK_ADJUSTMENT_LINE_DETAIL_ERROR";
export const GET_ONE_INVENTORY_STOCK_ADJUSTMENT_LINE_DETAIL_REQUEST = "GET_ONE_INVENTORY_STOCK_ADJUSTMENT_LINE_DETAIL_REQUEST";
export const GET_ONE_INVENTORY_STOCK_ADJUSTMENT_LINE_DETAIL_SUCCESS = "GET_ONE_INVENTORY_STOCK_ADJUSTMENT_LINE_DETAIL_SUCCESS";
export const GET_ONE_INVENTORY_STOCK_ADJUSTMENT_LINE_ERROR = "GET_ONE_INVENTORY_STOCK_ADJUSTMENT_LINE_ERROR";
export const GET_ONE_INVENTORY_STOCK_ADJUSTMENT_LINE_REQUEST = "GET_ONE_INVENTORY_STOCK_ADJUSTMENT_LINE_REQUEST";
export const GET_ONE_INVENTORY_STOCK_ADJUSTMENT_LINE_SUCCESS = "GET_ONE_INVENTORY_STOCK_ADJUSTMENT_LINE_SUCCESS";
export const UPDATE_INVENTORY_STOCK_ADJUSTMENT_LINE_ERROR = "UPDATE_INVENTORY_STOCK_ADJUSTMENT_LINE_ERROR";
export const UPDATE_INVENTORY_STOCK_ADJUSTMENT_LINE_REQUEST = "UPDATE_INVENTORY_STOCK_ADJUSTMENT_LINE_REQUEST";
export const UPDATE_INVENTORY_STOCK_ADJUSTMENT_LINE_SUCCESS = "UPDATE_INVENTORY_STOCK_ADJUSTMENT_LINE_SUCCESS";

export const GET_NAME_REQUEST = "GET_NAME_REQUEST";
export const GET_NAME_SUCCESS = "GET_NAME_SUCCESS";
export const GET_NAME_ERROR = "GET_NAME_ERROR";

export const GET_PAYMENT_METHOD_REQUEST = "GET_PAYMENT_METHOD_REQUEST";
export const GET_PAYMENT_METHOD_SUCCESS = "GET_PAYMENT_METHOD_SUCCESS";
export const GET_PAYMENT_METHOD_ERROR = "GET_PAYMENT_METHOD_ERROR";

export const ADD_DEPOSIT_LINE_REQUEST = "ADD_DEPOSIT_LINE_REQUEST";
export const ADD_DEPOSIT_LINE_SUCCESS = "ADD_DEPOSIT_LINE_SUCCESS";
export const ADD_DEPOSIT_LINE_ERROR = "ADD_DEPOSIT_LINE_ERROR";
export const GET_ONE_DEPOSIT_LINE_ERROR = "GET_ONE_DEPOSIT_LINE_ERROR";
export const GET_ONE_DEPOSIT_LINE_SUCCESS = "GET_ONE_DEPOSIT_LINE_SUCCESS";
export const GET_ONE_DEPOSIT_LINE_REQUEST = "GET_ONE_DEPOSIT_LINE_REQUEST";
export const GET_DEPOSIT_DETAIL_REQUEST = "GET_DEPOSIT_DETAIL_REQUEST";
export const GET_DEPOSIT_DETAIL_SUCCESS = "GET_DEPOSIT_DETAIL_SUCCESS";
export const GET_DEPOSIT_DETAIL_ERROR = "GET_DEPOSIT_DETAIL_ERROR";
export const UPDATE_DEPOSIT_LINE_REQUEST = "UPDATE_DEPOSIT_LINE_REQUEST";
export const UPDATE_DEPOSIT_LINE_SUCCESS = "UPDATE_DEPOSIT_LINE_SUCCESS";
export const UPDATE_DEPOSIT_LINE_ERROR = "UPDATE_DEPOSIT_LINE_ERROR";
export const DELETE_DEPOSIT_LINE_REQUEST = "DELETE_DEPOSIT_LINE_REQUEST";
export const DELETE_DEPOSIT_LINE_SUCCESS = "DELETE_DEPOSIT_LINE_SUCCESS";
export const DELETE_DEPOSIT_LINE_ERROR = "DELETE_DEPOSIT_LINE_ERROR";




export const GET_STOCK_STYLE_REQUEST = "GET_STOCK_STYLE_REQUEST";
export const GET_STOCK_STYLE_SUCCESS = "GET_STOCK_STYLE_SUCCESS";
export const GET_STOCK_STYLE_ERROR = "GET_STOCK_STYLE_ERROR";




export const GET_GL_IMPACT_REQUEST = "GET_GL_IMPACT_REQUEST";
export const GET_GL_IMPACT_SUCCESS = "GET_GL_IMPACT_SUCCESS";
export const GET_GL_IMPACT_ERROR = "GET_GL_IMPACT_ERROR";



export const GET_CUSTOMER_CATEGORIES_REQUEST = "GET_CUSTOMER_CATEGORIES_REQUEST";
export const GET_CUSTOMER_CATEGORIES_SUCCESS = "GET_CUSTOMER_CATEGORIES_SUCCESS";
export const GET_CUSTOMER_CATEGORIES_ERROR = "GET_CUSTOMER_CATEGORIES_ERROR";

export const CREATE_CUSTOMER_CATEGORY_REQUEST = "CREATE_CUSTOMER_CATEGORY_REQUEST";
export const CREATE_CUSTOMER_CATEGORY_SUCCESS = "CREATE_CUSTOMER_CATEGORY_SUCCESS";
export const CREATE_CUSTOMER_CATEGORY_ERROR = "CREATE_CUSTOMER_CATEGORY_ERROR";

export const UPDATE_CUSTOMER_CATEGORY_REQUEST = "UPDATE_CUSTOMER_CATEGORY_REQUEST";
export const UPDATE_CUSTOMER_CATEGORY_SUCCESS = "UPDATE_CUSTOMER_CATEGORY_SUCCESS ";
export const UPDATE_CUSTOMER_CATEGORY_ERROR = "UPDATE_CUSTOMER_CATEGORY_ERROR";

export const DELETE_CUSTOMER_CATEGORY_REQUEST = "DELETE_CUSTOMER_CATEGORY_REQUEST";
export const DELETE_CUSTOMER_CATEGORY_SUCCESS = "DELETE_CUSTOMER_CATEGORY_SUCCESS";
export const DELETE_CUSTOMER_CATEGORY_ERROR = "DELETE_CUSTOMER_CATEGORY_ERROR";

export const GET_ONE_CUSTOMER_CATEGORY_REQUEST = " GET_ONE_CUSTOMER_CATEGORY_REQUEST";
export const GET_ONE_CUSTOMER_CATEGORY_SUCCESS = "GET_ONE_CUSTOMER_CATEGORY_SUCCESS";
export const GET_ONE_CUSTOMER_CATEGORY_ERROR = " GET_ONE_CUSTOMER_CATEGORY_ERROR";

export const GET_ALL_PRODUCT_FOR_INT_REQUEST = "GET_ALL_PRODUCT_FOR_INT_REQUEST";
export const GET_ALL_PRODUCT_FOR_INT_SUCCESS = "GET_ALL_PRODUCT_FOR_INT_SUCCESS";
export const GET_ALL_PRODUCT_FOR_INT_ERROR = "GET_ALL_PRODUCT_FOR_INT_ERROR";

export const GET_SALE_RETURN_REPORT_REQUEST = "GET_SALE_RETURN_REPORT_REQUEST";
export const GET_SALE_RETURN_REPORT_SUCCESS = "GET_SALE_RETURN_REPORT_SUCCESS";
export const GET_SALE_RETURN_REPORT_ERROR = "GET_SALE_RETURN_REPORT_ERROR";

export const GET_SALE_INVOICE_REPORT_REQUEST = "GET_SALE_INVOICE_REPORT_REQUEST";
export const GET_SALE_INVOICE_REPORT_SUCCESS = "GET_SALE_INVOICE_REPORT_SUCCESS";
export const GET_SALE_INVOICE_REPORT_ERROR = "GET_SALE_INVOICE_REPORT_ERROR";

export const GET_UPC_SETUP_REQUEST = "GET_UPC_SETUP_REQUEST";
export const GET_UPC_SETUP_SUCCESS = "GET_UPC_SETUP_SUCCESS";
export const GET_UPC_SETUP_ERROR = "GET_UPC_SETUP_ERROR";

export const GET_ALL_STYLE_BOOKED_WISE_REQUEST = "GET_ALL_BOOKED_STYLE_WISE_REQUEST";
export const GET_ALL_STYLE_BOOKED_WISE_SUCCESS = "GET_ALL_BOOKED_STYLE_WISE_SUCCESS";
export const GET_ALL_STYLE_BOOKED_WISE_ERROR = "GET_ALL_BOOKED_STYLE_WISE_ERROR";

export const GET_ALL_STYLE_PENDING_REPORT_REQUEST = "GET_ALL_STYLE_PENDING_REPORT_REQUEST";
export const GET_ALL_STYLE_PENDING_REPORT_ERROR = "GET_ALL_STYLE_PENDING_REPORT_ERROR";
export const GET_ALL_STYLE_PENDING_REPORT_SUCESS = "GET_ALL_STYLE_PENDING_REPORT_SUCESS";

export const GET_ALL_STYLE_SHIPPED_REPORT_REQUEST = "GET_ALL_STYLE_SHIPPED_REPORT_REQUEST"
export const GET_ALL_STYLE_SHIPPED_REPORT_ERROR = "GET_ALL_STYLE_SHIPPED_REPORT_ERROR"
export const GET_ALL_STYLE_SHIPPED_REPORT_SUCESS = "GET_ALL_STYLE_SHIPPED_REPORT_SUCESS"

export const GET_ALL_QUERY_REQUEST = "GET_ALL_QUERY_REQUEST";
export const GET_ALL_QUERY_ERROR = "GET_ALL_QUERY_ERROR";
export const GET_ALL_QUERY_SUCCESS = "GET_ALL_QUERY_SUCCESS";
export const GET_ORDER_SHIP_TO_DETAIL_SUCCESS = "GET_ORDER_SHIP_TO_DETAIL_SUCCESS";
export const GET_ORDER_SHIP_TO_DETAIL_REQUEST = "GET_ORDER_SHIP_TO_DETAIL_REQUEST";
export const GET_ORDER_SHIP_TO_DETAIL_ERROR = "GET_ORDER_SHIP_TO_DETAIL_ERROR";

export const GET_ORDER_BILL_TO_DETAIL_SUCCESS = "GET_ORDER_BILL_TO_DETAIL_SUCCESS";
export const GET_ORDER_BILL_TO_DETAIL_REQUEST = "GET_ORDER_BILL_TO_DETAIL_REQUEST";
export const GET_ORDER_BILL_TO_DETAIL_ERROR = "GET_ORDER_BILL_TO_DETAIL_ERROR";

export const GET_BOOK_SUMMARY_REQUEST = "GET_BOOK_SUMMARY_REQUEST";
export const GET_BOOK_SUMMARY_SUCCESS = "GET_BOOK_SUMMARY_SUCCESS";
export const GET_BOOK_SUMMARY_ERROR = "GET_BOOK_SUMMARY_ERROR";
export const GET_ONE_B_GRADE_STOCK_SUCCESS = "GET_ONE_B_GRADE_STOCK_SUCCESS";
export const GET_ONE_B_GRADE_STOCK_REQUEST = "GET_ONE_B_GRADE_STOCK_REQUEST";
export const GET_ONE_B_GRADE_STOCK_ERROR = "GET_ONE_B_GRADE_STOCK_ERROR";

export const GET_PRODUCT_REPORT_SUCCES = "GET_PRODUCT_REPORT_SUCCES";
export const GET_PRODUCT_REPORT_REQUEST = "GET_PRODUCT_REPORT_REQUEST";
export const GET_PRODUCT_REPORT_ERROR = "GET_PRODUCT_REPORT_ERROR";

export const GET_OMS_DETAIL_REPORT_REQUEST = 'GET_OMS_DETAIL_REPORT_REQUEST';
export const GET_OMS_DETAIL_REPORT_SUCCESS = 'GET_OMS_DETAIL_REPORT_SUCCESS';
export const GET_OMS_DETAIL_REPORT_ERROR = 'GET_OMS_DETAIL_REPORT_ERROR';

export const GET_PENDING_SUMMARY_REQUEST = "GET_PENDING_SUMMARY_REQUEST";
export const GET_PENDING_SUMMARY_SUCCESS = "GET_PENDING_SUMMARY_SUCCESS";
export const GET_PENDING_SUMMARY_ERROR = "GET_PENDING_SUMMARY_ERROR";

export const GET_SHIPPED_SUMMARY_REQUEST = "GET_SHIPPED_SUMMARY_REQUEST"
export const GET_SHIPPED_SUMMARY_SUCCESS = "GET_SHIPPED_SUMMARY_SUCCESS"
export const GET_SHIPPED_SUMMARY_ERROR = "GET_SHIPPED_SUMMARY_ERROR"

export const GET_RETURN_SUMMARY_REQUEST = "GET_RETURN_SUMMARY_REQUEST"
export const GET_RETURN_SUMMARY_SUCCESS = "GET_RETURN_SUMMARY_SUCCESS"
export const GET_RETURN_SUMMARY_ERROR = "GET_RETURN_SUMMARY_ERROR"

export const GET_PENDING_DETAIL_REPORT_REQUEST = 'GET_PENDING_DETAIL_REPORT_REQUEST';
export const GET_PENDING_DETAIL_REPORT_SUCCESS = 'GET_PENDING_DETAIL_REPORT_SUCCESS';
export const GET_PENDING_DETAIL_REPORT_ERROR = 'GET_PENDING_DETAIL_REPORT_ERROR';

export const GET_SHIPPED_DETAIL_REPORT_REQUEST = 'GET_SHIPPED_DETAIL_REPORT_REQUEST';
export const GET_SHIPPED_DETAIL_REPORT_SUCCESS = 'GET_SHIPPED_DETAIL_REPORT_SUCCESS';
export const GET_SHIPPED_DETAIL_REPORT_ERROR = 'GET_SHIPPED_DETAIL_REPORT_ERROR';

export const GET_ONE_SALEORDERADJUSTMENT_REQUEST = 'GET_ONE_SALEORDERADJUSTMENT_REQUEST';
export const GET_ONE_SALEORDERADJUSTMENT_SUCCESS = 'GET_ONE_SALEORDERADJUSTMENT_SUCCESS';
export const GET_ONE_SALEORDERADJUSTMENT_ERROR = 'GET_ONE_SALEORDERADJUSTMENT_ERROR';

export const GET_ALL_PRODUCT_REQUEST = 'GET_ALL_PRODUCT_REQUEST';
export const GET_ALL_PRODUCT_SUCCESS = 'GET_ALL_PRODUCT_SUCCESS';
export const GET_ALL_PRODUCT_ERROR = 'GET_ALL_PRODUCT_ERROR';

export const GET_ALL_RELOCATEITEMS_SUCCESS = 'GET_ALL_RELOCATEITEMS_SUCCESS';
export const GET_ALL_RELOCATEITEMS_REQUEST = 'GET_ALL_RELOCATEITEMS_REQUEST';
export const GET_ALL_RELOCATEITEMS_ERROR = 'GET_ALL_RELOCATEITEMS_ERROR';

export const ADD_ALL_RELOCATEITEMS_SUCCESS = 'ADD_ALL_RELOCATEITEMS_SUCCESS';
export const ADD_ALL_RELOCATEITEMS_REQUEST = 'ADD_ALL_RELOCATEITEMS_REQUEST';
export const ADD_ALL_RELOCATEITEMS_ERROR = 'ADD_ALL_RELOCATEITEMS_ERROR';

export const ADD_CUSTOMER_ADREESS_REQUEST = 'ADD_CUSTOMER_ADREESS_REQUEST'
export const ADD_CUSTOMER_ADREESS_SUCCESS = 'ADD_CUSTOMER_ADREESS_SUCCESS'
export const ADD_CUSTOMER_ADREESS_ERROR = 'ADD_CUSTOMER_ADREESS_ERROR'

export const GET_ONE_CUSTOMER_ADREESS_REQUEST = 'GET_ONE_CUSTOMER_ADREESS_REQUEST'
export const GET_ONE_CUSTOMER_ADREESS_SUCCESS = 'GET_ONE_CUSTOMER_ADREESS_SUCCESS'
export const GET_ONE_CUSTOMER_ADREESS_ERROR = 'GET_ONE_CUSTOMER_ADREESS_ERROR'

export const GET_PRODUCT_BALANCE_REQUEST = "GET_PRODUCT_BALANCE_REQUEST"
export const GET_PRODUCT_BALANCE_SUCCESS = "GET_PRODUCT_BALANCE_SUCCESS"
export const GET_PRODUCT_BALANCE_ERROR = "GET_PRODUCT_BALANCE_ERROR"
export const GET_PICKTICKET_SUMMARY_REQUEST = "GET_PICKTICKET_SUMMARY_REQUEST";
export const GET_PICKTICKET_SUMMARY_SUCCESS = "GET_PICKTICKET_SUMMARY_SUCCESS";
export const GET_PICKTICKET_SUMMARY_ERROR = "GET_PICKTICKET_SUMMARY_ERROR";
export const DELETE_SALESORDER_ADJ_LINE_REQUEST = "DELETE_SALESORDER_ADJ_LINE_REQUEST";
export const DELETE_SALESORDER_ADJ_LINE_SUCCESS = "DELETE_SALESORDER_ADJ_LINE_SUCCESS";
export const DELETE_SALESORDER_ADJ_LINE_ERROR = "DELETE_SALESORDER_ADJ_LINE_ERROR";
export const GET_ONE_CUSTOMER_CATEGORIES_REQUEST = "GET_ONE_CUSTOMER_CATEGORIES_REQUEST";
export const GET_ONE_CUSTOMER_CATEGORIES_SUCCESS = "GET_ONE_CUSTOMER_CATEGORIES_SUCCESS";
export const GET_ONE_CUSTOMER_CATEGORIES_ERROR = "GET_ONE_CUSTOMER_CATEGORIES_ERROR";



export const ADD_ALL_TABLESCHEMA_SUCCESS = 'ADD_ALL_TABLESCHEMA_SUCCESS';
export const ADD_ALL_TABLESCHEMA_REQUEST = 'ADD_ALL_TABLESCHEMA_REQUEST';
export const ADD_ALL_TABLESCHEMA_ERROR = 'ADD_ALL_TABLESCHEMA_ERROR';

export const ADD_ALL_COLUMNSCHEMA_SUCCESS = 'ADD_ALL_COLUMNSCHEMA_SUCCESS';
export const ADD_ALL_COLUMNSCHEMA_REQUEST = 'ADD_ALL_COLUMNSCHEMA_REQUEST';
export const ADD_ALL_COLUMNSCHEMA_ERROR = 'ADD_ALL_COLUMNSCHEMA_ERROR';

export const UPDATE_TABLE_SUCCESS = 'UPDATE_TABLE_SUCCESS';
export const UPDATE_TABLE_REQUEST = 'UPDATE_TABLE_REQUEST';
export const UPDATE_TABLE_ERROR = 'UPDATE_TABLE_ERROR';

export const UPDATE_CUSTOMER_ADREESS_SUCCESS = 'UPDATE_CUSTOMER_ADREESS_SUCCESS';
export const UPDATE_CUSTOMER_ADREESS_REQUEST = 'UPDATE_CUSTOMER_ADREESS_REQUEST';
export const UPDATE_CUSTOMER_ADREESS_ERROR = 'UPDATE_CUSTOMER_ADREESS_ERROR';

export const DELETE_CUSTOMER_ADREESS_REQUEST = "DELETE_CUSTOMER_ADREESS_REQUEST";
export const DELETE_CUSTOMER_ADREESS_SUCCESS = "DELETE_CUSTOMER_ADREESS_SUCCESS";
export const DELETE_CUSTOMER_ADREESS_ERROR = "DELETE_CUSTOMER_ADREESS_ERROR";

export const GET_ALL_PENDING_POD_SUMMARY_SUCCESS = 'GET_ALL_PENDING_POD_SUMMARY_SUCCESS';
export const GET_ALL_PENDING_POD_SUMMARY_REQUEST = 'GET_ALL_PENDING_POD_SUMMARY_REQUEST';
export const GET_ALL_PENDING_POD_SUMMARY_ERROR = 'GET_ALL_PENDING_POD_SUMMARY_ERROR';
export const GET_ONE_PENDINGORDERADJLINE_REQUEST = "GET_ONE_PENDINGORDERADJLINE_REQUEST";
export const GET_ONE_PENDINGORDERADJLINE_SUCCESS = "GET_ONE_PENDINGORDERADJLINE_SUCCESS";
export const GET_ONE_PENDINGORDERADJLINE_ERROR = "GET_ONE_PENDINGORDERADJLINE_ERROR";

export const UPDATE_SALE_ORDER_ISCLOSED_REQUEST = "UPDATE_SALE_ORDER_ISCLOSED_REQUEST";
export const UPDATE_SALE_ORDER_ISCLOSED_SUCCESS = "UPDATE_SALE_ORDER_ISCLOSED_SUCCESS";
export const UPDATE_SALE_ORDER_ISCLOSED_ERROR = "UPDATE_SALE_ORDER_ISCLOSED_ERROR";

export const GET_ALL_PT_SUMMARY_SUCCESS = 'GET_ALL_PT_SUMMARY_SUCCESS';
export const GET_ALL_PT_SUMMARY_REQUEST = 'GET_ALL_PT_SUMMARY_REQUEST';
export const GET_ALL_PT_SUMMARY_ERROR = 'GET_ALL_PT_SUMMARY_ERROR';

export const GET_ALL_ORDER_ADJ_SUMMARY_SUCCESS = 'GET_ALL_ORDER_ADJ_SUMMARY_SUCCESS';
export const GET_ALL_ORDER_ADJ_SUMMARY_REQUEST = 'GET_ALL_ORDER_ADJ_SUMMARY_REQUEST';
export const GET_ALL_ORDER_ADJ_SUMMARY_ERROR = 'GET_ALL_ORDER_ADJ_SUMMARY_ERROR';

export const GET_ALL_ORDER_ADJ_DETAIL_SUCCESS = 'GET_ALL_ORDER_ADJ_DETAIL_SUCCESS';
export const GET_ALL_ORDER_ADJ_DETAIL_REQUEST = 'GET_ALL_ORDER_ADJ_DETAIL_REQUEST';
export const GET_ALL_ORDER_ADJ_DETAIL_ERROR = 'GET_ALL_ORDER_ADJ_DETAIL_ERROR';

export const GET_ALL_PT_DETAIL_SUCCESS = 'GET_ALL_PT_DETAIL_SUCCESS';
export const GET_ALL_PT_DETAIL_REQUEST = 'GET_ALL_PT_DETAIL_REQUEST';
export const GET_ALL_PT_DETAIL_ERROR = 'GET_ALL_PT_DETAIL_ERROR';

export const GET_PRODUCTAVAILABLE_REQUEST = "GET_PRODUCTAVAILABLE_REQUEST";
export const GET_PRODUCTAVAILABLE_SUCCESS = "GET_PRODUCTAVAILABLE_SUCCESS";
export const GET_PRODUCTAVAILABLE_ERROR = "GET_PRODUCTAVAILABLE_ERROR";


export const DELETE_SALE_RETURN_LINE_REQUEST = "DELETE_SALE_RETURN_LINE_REQUEST";
export const DELETE_SALE_RETURN_LINE_SUCCESS = "DELETE_SALE_RETURN_LINE_SUCCESS";
export const DELETE_SALE_RETURN_LINE_ERROR = "DELETE_SALE_RETURN_LINE_ERROR";



export const GET_PICK_TICKET_PRINT_REQUEST = "GET_PICK_TICKET_PRINT_REQUEST";
export const GET_PICK_TICKET_PRINT_SUCCESS = "GET_PICK_TICKET_PRINT_SUCCESS ";
export const GET_PICK_TICKET_PRINT_ERROR = "GET_PICK_TICKET_PRINT_ERROR";


export const GET_PICK_TICKET_COLUMN_REQUEST = "GET_PICK_TICKET_COLUMN_REQUEST";
export const GET_PICK_TICKET_COLUMN_SUCCESS = "GET_PICK_TICKET_COLUMN_SUCCESS ";
export const GET_PICK_TICKET_COLUMN_ERROR = "GET_PICK_TICKET_COLUMN_ERROR";

export const GET_PICK_TICKET_COLUMN_VALUE_REQUEST = "GET_PICK_TICKET_COLUMN_VALUE_REQUEST";
export const GET_PICK_TICKET_COLUMN_VALUE_SUCCESS = "GET_PICK_TICKET_COLUMN_VALUE_SUCCESS ";
export const GET_PICK_TICKET_COLUMN_VALUE_ERROR = "GET_PICK_TICKET_COLUMN_VALUE_ERROR";

export const GET_PICK_TICKET_ITEM_REQUEST = "GET_PICK_TICKET_ITEM_REQUEST";
export const GET_PICK_TICKET_ITEM_SUCCESS = "GET_PICK_TICKET_ITEM_SUCCESS";
export const GET_PICK_TICKET_ITEM_ERROR = "GET_PICK_TICKET_ITEM_ERROR";

export const GET_CREDIT_OPEN_INVOICE_DETAIL_REPORT_REQUEST = 'GET_CREDIT_OPEN_INVOICE_DETAIL_REPORT_REQUEST';
export const GET_CREDIT_OPEN_INVOICE_DETAIL_REPORT_SUCCESS = 'GET_CREDIT_OPEN_INVOICE_DETAIL_REPORT_SUCCESS';
export const GET_CREDIT_OPEN_INVOICE_DETAIL_REPORT_ERROR = 'GET_CREDIT_OPEN_INVOICE_DETAIL_REPORT_ERROR';



export const GET_SALE_ADJUSTMENT_PRINT_REQUEST = "GET_SALE_ADJUSTMENT_PRINT_REQUEST";
export const GET_SALE_ADJUSTMENT_PRINT_SUCCESS = "GET_SALE_ADJUSTMENT_PRINT_SUCCESS ";
export const GET_SALE_ADJUSTMENT_PRINT_ERROR = "GET_SALE_ADJUSTMENT_PRINT_ERROR";

export const GET_SALE_ADJUSTMENT_COLUMN_REQUEST = "GET_SALE_ADJUSTMENT_COLUMN_REQUEST";
export const GET_SALE_ADJUSTMENT_COLUMN_SUCCESS = "GET_SALE_ADJUSTMENT_COLUMN_SUCCESS ";
export const GET_SALE_ADJUSTMENT_COLUMN_ERROR = "GET_SALE_ADJUSTMENT_COLUMN_ERROR";

export const GET_SALE_ADJUSTMENT_COLUMN_VALUE_REQUEST = "GET_SALE_ADJUSTMENT_COLUMN_VALUE_REQUEST";
export const GET_SALE_ADJUSTMENT_COLUMN_VALUE_SUCCESS = "GET_SALE_ADJUSTMENT_COLUMN_VALUE_SUCCESS ";
export const GET_SALE_ADJUSTMENT_COLUMN_VALUE_ERROR = "GET_SALE_ADJUSTMENT_COLUMN_VALUE_ERROR";

export const GET_SALE_ADJUSTMENT_ITEM_REQUEST = "GET_SALE_ADJUSTMENT_ITEM_REQUEST";
export const GET_SALE_ADJUSTMENT_ITEM_SUCCESS = "GET_SALE_ADJUSTMENT_ITEM_SUCCESS";
export const GET_SALE_ADJUSTMENT_ITEM_ERROR = "GET_SALE_ADJUSTMENT_ITEM_ERROR";

export const GET_ADJUSTMENT_ACCOUNT_REQUEST = "GET_ADJUSTMENT_ACCOUNT_REQUEST";
export const GET_ADJUSTMENT_ACCOUNT_SUCCESS = "GET_ADJUSTMENT_ACCOUNT_SUCCESS";
export const GET_ADJUSTMENT_ACCOUNT_ERROR = "GET_ADJUSTMENT_ACCOUNT_ERROR";


export const GET_SALE_RA_PRINT_REQUEST = "GET_SALE_RA_PRINT_REQUEST";
export const GET_SALE_RA_PRINT_SUCCESS = "GET_SALE_RA_PRINT_SUCCESS ";
export const GET_SALE_RA_PRINT_ERROR = "GET_SALE_RA_PRINT_ERROR";

export const GET_SALE_RA_COLUMN_REQUEST = "GET_SALE_RA_COLUMN_REQUEST";
export const GET_SALE_RA_COLUMN_SUCCESS = "GET_SALE_RA_COLUMN_SUCCESS ";
export const GET_SALE_RA_COLUMN_ERROR = "GET_SALE_RA_COLUMN_ERROR";

export const GET_SALE_RA_COLUMN_VALUE_REQUEST = "GET_SALE_RA_COLUMN_VALUE_REQUEST";
export const GET_SALE_RA_COLUMN_VALUE_SUCCESS = "GET_SALE_RA_COLUMN_VALUE_SUCCESS ";
export const GET_SALE_RA_COLUMN_VALUE_ERROR = "GET_SALE_RA_COLUMN_VALUE_ERROR";

export const GET_SALE_RA_ITEM_REQUEST = "GET_SALE_RA_ITEM_REQUEST";
export const GET_SALE_RA_ITEM_SUCCESS = "GET_SALE_RA_ITEM_SUCCESS";
export const GET_SALE_RA_ITEM_ERROR = "GET_SALE_RA_ITEM_ERROR";


export const SEND_EMAIL_SUCCESS = "SEND_EMAIL_SUCCESS";
export const SEND_EMAIL_ERROR = "SEND_EMAIL_ERROR";
export const SEND_EMAIL_REQUEST = "SEND_EMAIL_REQUEST";

export const GET_CONTROL_SHEET_REQUEST = "GET_CONTROL_SHEET_REQUEST";
export const GET_CONTROL_SHEET_SUCCESS = "GET_CONTROL_SHEET_SUCCESS ";
export const GET_CONTROL_SHEET_ERROR = "GET_CONTROL_SHEET_ERROR";

export const GET_INVOICE_REPORT_REQUEST = "GET_INVOICE_REPORT_REQUEST";
export const GET_INVOICE_REPORT_SUCCESS = "GET_INVOICE_REPORT_SUCCESS ";
export const GET_INVOICE_REPORT_ERROR = "GET_INVOICE_REPORT_ERROR ";

export const GET_INVOICE_PRINT_REQUEST = "GET_INVOICE_PRINT_REQUEST";
export const GET_INVOICE_PRINT_SUCCESS = "GET_INVOICE_PRINT_SUCCESS ";
export const GET_INVOICE_PRINT_ERROR = "GET_INVOICE_PRINT_ERROR ";

export const GET_INVOICE_COLUMNS_REQUEST = "GET_INVOICE_COLUMNS_REQUEST";
export const GET_INVOICE_COLUMNS_SUCCESS = "GET_INVOICE_COLUMNS_SUCCESS ";
export const GET_INVOICE_COLUMNS_ERROR = "GET_INVOICE_COLUMNS_ERROR ";

export const GET_INVOICE_COLUMNSVALUES_REQUEST = "GET_INVOICE_COLUMNSVALUES_REQUEST";
export const GET_INVOICE_COLUMNSVALUES_SUCCESS = "GET_INVOICE_COLUMNSVALUES_SUCCESS ";
export const GET_INVOICE_COLUMNSVALUES_ERROR = "GET_INVOICE_COLUMNSVALUES_ERROR ";

export const GET_ALL_STYLE_WISE_SHIPPED_REPORT_REQUEST = "GET_ALL_STYLE_WISE_SHIPPED_REPORT_REQUEST";
export const GET_ALL_STYLE_WISE_SHIPPED_REPORT_ERROR = "GET_ALL_STYLE_WISE_SHIPPED_REPORT_ERROR";
export const GET_ALL_STYLE_WISE_SHIPPED_REPORT_SUCCESS = "GET_ALL_STYLE_WISE_SHIPPED_REPORT_SUCCESS";


export const GET_TRACKING_REQUEST = "GET_TRACKING_REQUEST";
export const GET_TRACKING_SUCCESS = "GET_TRACKING_SUCCESS ";
export const GET_TRACKING_ERROR = "GET_TRACKING_ERROR ";


export const GET_GENDER_CATEGORY_REQUEST = "GET_GENDER_CATEGORY_REQUEST";
export const GET_GENDER_CATEGORY_SUCCESS = "GET_GENDER_CATEGORY_SUCCESS ";
export const GET_GENDER_CATEGORY_ERROR = "GET_GENDER_CATEGORY_ERROR ";

export const GET_STYLE_REQUEST = "GET_STYLE_REQUEST";
export const GET_STYLE_SUCCESS = "GET_STYLE_SUCCESS ";
export const GET_STYLE_ERROR = "GET_STYLE_ERROR ";

export const GET_SKU_REQUEST = "GET_SKU_REQUEST";
export const GET_SKU_SUCCESS = "GET_SKU_SUCCESS ";
export const GET_SKU_ERROR = "GET_SKU_ERROR ";

export const GET_CUSTOMER_REPORT_REQUEST = "GET_CUSTOMER_REPORT_REQUEST";
export const GET_CUSTOMER_REPORT_SUCCESS = "GET_CUSTOMER_REPORT_SUCCESS ";
export const GET_CUSTOMER_REPORT_ERROR = "GET_CUSTOMER_REPORT_ERROR ";

export const GET_PRODUCT_INFORMATION_REPORT_REQUEST = "GET_PRODUCT_INFORMATION_REPORT_REQUEST";
export const GET_PRODUCT_INFORMATION_REPORT_SUCCESS = "GET_PRODUCT_INFORMATION_REPORT_SUCCESS ";
export const GET_PRODUCT_INFORMATION_REPORT_ERROR = "GET_PRODUCT_INFORMATION_REPORT_ERROR ";

export const GET_ALL_POD_STYLE_WISE_REQUEST = "GET_ALL_POD_STYLE_WISE_REQUEST";
export const GET_ALL_POD_STYLE_WISE_SUCCESS = "GET_ALL_POD_STYLE_WISE_SUCCESS";
export const GET_ALL_POD_STYLE_WISE_ERROR = "GET_ALL_POD_STYLE_WISE_ERROR";


export const GET_OMS_AVAILABLE_BALANCE_REQUEST = "GET_OMS_AVAILABLE_BALANCE_REQUEST";
export const GET_OMS_AVAILABLE_BALANCE_SUCCESS = "GET_OMS_AVAILABLE_BALANCE_SUCCESS";
export const GET_OMS_AVAILABLE_BALANCE_ERROR = "GET_OMS_AVAILABLE_BALANCE_ERROR";

export const GET_ALL_RETURN_STYLE_WISE_REQUEST = "GET_ALL_RETURN_STYLE_WISE_REQUEST";
export const GET_ALL_RETURN_STYLE_WISE_SUCCESS = "GET_ALL_RETURN_STYLE_WISE_SUCCESS";
export const GET_ALL_RETURN_STYLE_WISE_ERROR = "GET_ALL_RETURN_STYLE_WISE_ERROR";

export const GET_ALL_RETURN_DETAIL_REQUEST = "GET_ALL_RETURN_DETAIL_REQUEST";
export const GET_ALL_RETURN_DETAIL_SUCCESS = "GET_ALL_RETURN_DETAIL_SUCCESS";
export const GET_ALL_RETURN_DETAIL_ERROR = "GET_ALL_RETURN_DETAIL_ERROR";


export const GET_CUSTOMER_PAYMENT_REQUEST = "GET_CUSTOMER_PAYMENT_REQUEST";
export const GET_CUSTOMER_PAYMENT_SUCCESS = "GET_CUSTOMER_PAYMENT_SUCCESS";
export const GET_CUSTOMER_PAYMENT_ERROR = "GET_CUSTOMER_PAYMENT_ERROR";

export const ADD_BILL_CREDIT_HEADER_ERROR = "ADD_BILL_CREDIT_HEADER_ERROR";
export const ADD_BILL_CREDIT_HEADER_REQUEST = "ADD_BILL_CREDIT_HEADER_REQUEST";
export const ADD_BILL_CREDIT_HEADER_SUCCESS = "ADD_BILL_CREDIT_HEADER_SUCCESS";

export const DELETE_BILL_CREDIT_HEADER_ERROR = "DELETE_BILL_CREDIT_HEADER_ERROR";
export const DELETE_BILL_CREDIT_HEADER_REQUEST = "DELETE_BILL_CREDIT_HEADER_REQUEST";
export const DELETE_BILL_CREDIT_HEADER_SUCCESS = "DELETE_BILL_CREDIT_HEADER_SUCCESS";

export const GET_ALL_BILL_CREDIT_ERROR = "GET_ALL_BILL_CREDIT_ERROR";
export const GET_ALL_BILL_CREDIT_REQUEST = "GET_ALL_BILL_CREDIT_REQUEST";
export const GET_ALL_BILL_CREDIT_SUCCESS = "GET_ALL_BILL_CREDIT_SUCCESS";

export const GET_BILL_CREDIT_FORM_DATA_ERROR = "GET_BILL_CREDIT_FORM_DATA_ERROR";
export const GET_BILL_CREDIT_FORM_DATA_REQUEST = "GET_BILL_CREDIT_FORM_DATA_REQUEST";
export const GET_BILL_CREDIT_FORM_DATA_SUCCESS = "GET_BILL_CREDIT_FORM_DATA_SUCCESS";

export const GET_ONE_BILL_CREDIT_HEADER_DETAIL_ERROR = "GET_ONE_BILL_CREDIT_HEADER_DETAIL_ERROR";
export const GET_ONE_BILL_CREDIT_HEADER_DETAIL_REQUEST = "GET_ONE_BILL_CREDIT_HEADER_DETAIL_REQUEST";
export const GET_ONE_BILL_CREDIT_HEADER_DETAIL_SUCCESS = "GET_ONE_BILL_CREDIT_HEADER_DETAIL_SUCCESS";

export const GET_ONE_BILL_CREDIT_HEADER_ERROR = "GET_ONE_BILL_CREDIT_HEADER_ERROR";
export const GET_ONE_BILL_CREDIT_HEADER_REQUEST = "GET_ONE_BILL_CREDIT_HEADER_REQUEST";
export const GET_ONE_BILL_CREDIT_HEADER_SUCCESS = "GET_ONE_BILL_CREDIT_HEADER_SUCCESS";

export const UPDATE_BILL_CREDIT_HEADER_ERROR = "UPDATE_BILL_CREDIT_HEADER_ERROR";
export const UPDATE_BILL_CREDIT_HEADER_REQUEST = "UPDATE_BILL_CREDIT_HEADER_REQUEST";
export const UPDATE_BILL_CREDIT_HEADER_SUCCESS = "UPDATE_BILL_CREDIT_HEADER_SUCCESS";

export const ADD_BILL_CREDIT_ITEM_LINE_ERROR = "ADD_BILL_CREDIT_ITEM_LINE_ERROR";
export const ADD_BILL_CREDIT_ITEM_LINE_REQUEST = "ADD_BILL_CREDIT_ITEM_LINE_REQUEST";
export const ADD_BILL_CREDIT_ITEM_LINE_SUCCESS = "ADD_BILL_CREDIT_ITEM_LINE_SUCCESS";

export const DELETE_BILL_CREDIT_ITEM_LINE_ERROR = "DELETE_BILL_CREDIT_ITEM_LINE_ERROR";
export const DELETE_BILL_CREDIT_ITEM_LINE_REQUEST = "DELETE_BILL_CREDIT_ITEM_LINE_REQUEST";
export const DELETE_BILL_CREDIT_ITEM_LINE_SUCCESS = "DELETE_BILL_CREDIT_ITEM_LINE_SUCCESS";

export const GET_ONE_BILL_CREDIT_ITEM_LINE_DETAIL_ERROR = "GET_ONE_BILL_CREDIT_ITEM_LINE_DETAIL_ERROR";
export const GET_ONE_BILL_CREDIT_ITEM_LINE_DETAIL_REQUEST = "GET_ONE_BILL_CREDIT_ITEM_LINE_DETAIL_REQUEST";
export const GET_ONE_BILL_CREDIT_ITEM_LINE_DETAIL_SUCCESS = "GET_ONE_BILL_CREDIT_ITEM_LINE_DETAIL_SUCCESS";

export const GET_ONE_BILL_CREDIT_ITEM_LINE_ERROR = "GET_ONE_BILL_CREDIT_ITEM_LINE_ERROR";
export const GET_ONE_BILL_CREDIT_ITEM_LINE_REQUEST = "GET_ONE_BILL_CREDIT_ITEM_LINE_REQUEST";
export const GET_ONE_BILL_CREDIT_ITEM_LINE_SUCCESS = "GET_ONE_BILL_CREDIT_ITEM_LINE_SUCCESS";

export const UPDATE_BILL_CREDIT_ITEM_LINE_ERROR = "UPDATE_BILL_CREDIT_ITEM_LINE_ERROR";
export const UPDATE_BILL_CREDIT_ITEM_LINE_REQUEST = "UPDATE_BILL_CREDIT_ITEM_LINE_REQUEST";
export const UPDATE_BILL_CREDIT_ITEM_LINE_SUCCESS = "UPDATE_BILL_CREDIT_ITEM_LINE_SUCCESS";

export const ADD_BILL_CREDIT_EXPENSE_LINE_ERROR = "ADD_BILL_CREDIT_EXPENSE_LINE_ERROR";
export const ADD_BILL_CREDIT_EXPENSE_LINE_REQUEST = "ADD_BILL_CREDIT_EXPENSE_LINE_REQUEST";
export const ADD_BILL_CREDIT_EXPENSE_LINE_SUCCESS = "ADD_BILL_CREDIT_EXPENSE_LINE_SUCCESS";

export const DELETE_BILL_CREDIT_EXPENSE_LINE_ERROR = "DELETE_BILL_CREDIT_EXPENSE_LINE_ERROR";
export const DELETE_BILL_CREDIT_EXPENSE_LINE_REQUEST = "DELETE_BILL_CREDIT_EXPENSE_LINE_REQUEST";
export const DELETE_BILL_CREDIT_EXPENSE_LINE_SUCCESS = "DELETE_BILL_CREDIT_EXPENSE_LINE_SUCCESS";

export const GET_ONE_BILL_CREDIT_EXPENSE_LINE_DETAIL_ERROR = "GET_ONE_BILL_CREDIT_EXPENSE_LINE_DETAIL_ERROR";
export const GET_ONE_BILL_CREDIT_EXPENSE_LINE_DETAIL_REQUEST = "GET_ONE_BILL_CREDIT_EXPENSE_LINE_DETAIL_REQUEST";
export const GET_ONE_BILL_CREDIT_EXPENSE_LINE_DETAIL_SUCCESS = "GET_ONE_BILL_CREDIT_EXPENSE_LINE_DETAIL_SUCCESS";

export const UPDATE_BILL_CREDIT_EXPENSE_LINE_ERROR = "UPDATE_BILL_CREDIT_EXPENSE_LINE_ERROR";
export const UPDATE_BILL_CREDIT_EXPENSE_LINE_REQUEST = "UPDATE_BILL_CREDIT_EXPENSE_LINE_REQUEST";
export const UPDATE_BILL_CREDIT_EXPENSE_LINE_SUCCESS = "UPDATE_BILL_CREDIT_EXPENSE_LINE_SUCCESS";

export const GET_PAY_VENDOR_BILL_CREDIT_REQUEST = "GET_PAY_VENDOR_BILL_CREDIT_REQUEST";
export const GET_PAY_VENDOR_BILL_CREDIT_SUCCESS = "GET_PAY_VENDOR_BILL_CREDIT_SUCCESS";
export const GET_PAY_VENDOR_BILL_CREDIT_ERROR = "GET_PAY_VENDOR_BILL_CREDIT_ERROR";

export const GET_ONE_BILL_CREDIT_APPLY_LINE_REQUEST = "GET_ONE_BILL_CREDIT_APPLY_LINE_REQUEST"
export const GET_ONE_BILL_CREDIT_APPLY_LINE_SUCCESS = "GET_ONE_BILL_CREDIT_APPLY_LINE_SUCCESS"
export const GET_ONE_BILL_CREDIT_APPLY_LINE_ERROR = "GET_ONE_BILL_CREDIT_APPLY_LINE_ERROR"

export const ADD_BILL_CREDIT_APPLY_LINE_REQUEST = "ADD_BILL_CREDIT_APPLY_LINE_REQUEST"
export const ADD_BILL_CREDIT_APPLY_LINE_SUCCESS = "ADD_BILL_CREDIT_APPLY_LINE_SUCCESS"
export const ADD_BILL_CREDIT_APPLY_LINE_ERROR = "ADD_BILL_CREDIT_APPLY_LINE_ERROR"

export const UPDATE_BILL_CREDIT_APPLY_LINE_REQUEST = "UPDATE_BILL_CREDIT_APPLY_LINE_REQUEST"
export const UPDATE_BILL_CREDIT_APPLY_LINE_SUCCESS = "UPDATE_BILL_CREDIT_APPLY_LINE_SUCCESS"
export const UPDATE_BILL_CREDIT_APPLY_LINE_ERROR = "UPDATE_BILL_CREDIT_APPLY_LINE_ERROR"

export const DELETE_BILL_CREDIT_APPLY_LINE_REQUEST = "DELETE_BILL_CREDIT_APPLY_LINE_REQUEST"
export const DELETE_BILL_CREDIT_APPLY_LINE_SUCCESS = "DELETE_BILL_CREDIT_APPLY_LINE_SUCCESS"
export const DELETE_BILL_CREDIT_APPLY_LINE_ERROR = "DELETE_BILL_CREDIT_APPLY_LINE_ERROR"

export const ADD_CREDIT_MEMO_HEADER_ERROR = "ADD_CREDIT_MEMO_HEADER_ERROR";
export const ADD_CREDIT_MEMO_HEADER_REQUEST = "ADD_CREDIT_MEMO_HEADER_REQUEST";
export const ADD_CREDIT_MEMO_HEADER_SUCCESS = "ADD_CREDIT_MEMO_HEADER_SUCCESS";

export const DELETE_CREDIT_MEMO_HEADER_ERROR = "DELETE_CREDIT_MEMO_HEADER_ERROR";
export const DELETE_CREDIT_MEMO_HEADER_REQUEST = "DELETE_CREDIT_MEMO_HEADER_REQUEST";
export const DELETE_CREDIT_MEMO_HEADER_SUCCESS = "DELETE_CREDIT_MEMO_HEADER_SUCCESS";

export const GET_ALL_CREDIT_MEMO_ERROR = "GET_ALL_CREDIT_MEMO_ERROR";
export const GET_ALL_CREDIT_MEMO_REQUEST = "GET_ALL_CREDIT_MEMO_REQUEST";
export const GET_ALL_CREDIT_MEMO_SUCCESS = "GET_ALL_CREDIT_MEMO_SUCCESS";

export const GET_CREDIT_MEMO_FORM_DATA_ERROR = "GET_CREDIT_MEMO_FORM_DATA_ERROR";
export const GET_CREDIT_MEMO_FORM_DATA_REQUEST = "GET_CREDIT_MEMO_FORM_DATA_REQUEST";
export const GET_CREDIT_MEMO_FORM_DATA_SUCCESS = "GET_CREDIT_MEMO_FORM_DATA_SUCCESS";

export const GET_ONE_CREDIT_MEMO_HEADER_DETAIL_ERROR = "GET_ONE_CREDIT_MEMO_HEADER_DETAIL_ERROR";
export const GET_ONE_CREDIT_MEMO_HEADER_DETAIL_REQUEST = "GET_ONE_CREDIT_MEMO_HEADER_DETAIL_REQUEST";
export const GET_ONE_CREDIT_MEMO_HEADER_DETAIL_SUCCESS = "GET_ONE_CREDIT_MEMO_HEADER_DETAIL_SUCCESS";

export const GET_ONE_CREDIT_MEMO_HEADER_ERROR = "GET_ONE_CREDIT_MEMO_HEADER_ERROR";
export const GET_ONE_CREDIT_MEMO_HEADER_REQUEST = "GET_ONE_CREDIT_MEMO_HEADER_REQUEST";
export const GET_ONE_CREDIT_MEMO_HEADER_SUCCESS = "GET_ONE_CREDIT_MEMO_HEADER_SUCCESS";

export const UPDATE_CREDIT_MEMO_HEADER_ERROR = "UPDATE_CREDIT_MEMO_HEADER_ERROR";
export const UPDATE_CREDIT_MEMO_HEADER_REQUEST = "UPDATE_CREDIT_MEMO_HEADER_REQUEST";
export const UPDATE_CREDIT_MEMO_HEADER_SUCCESS = "UPDATE_CREDIT_MEMO_HEADER_SUCCESS";

export const GET_CREDIT_MEMO_LINE_REQUEST = "GET_CREDIT_MEMO_LINE_REQUEST";
export const GET_CREDIT_MEMO_LINE_SUCCESS = "GET_CREDIT_MEMO_LINE_SUCCESS";
export const GET_CREDIT_MEMO_LINE_ERROR = "GET_CREDIT_MEMO_LINE_ERROR";

export const GET_ONE_CREDIT_MEMO_LINE_REQUEST = "GET_ONE_CREDIT_MEMO_LINE_REQUEST";
export const GET_ONE_CREDIT_MEMO_LINE_SUCCESS = "GET_ONE_CREDIT_MEMO_LINE_SUCCESS";
export const GET_ONE_CREDIT_MEMO_LINE_ERROR = "GET_ONE_CREDIT_MEMO_LINE_ERROR";

export const ADD_CREDIT_MEMO_LINE_REQUEST = "ADD_CREDIT_MEMO_LINE_REQUEST";
export const ADD_CREDIT_MEMO_LINE_SUCCESS = "ADD_CREDIT_MEMO_LINE_SUCCESS";
export const ADD_CREDIT_MEMO_LINE_ERROR = "ADD_CREDIT_MEMO_LINE_ERROR";

export const UPDATE_CREDIT_MEMO_LINE_REQUEST = "UPDATE_CREDIT_MEMO_LINE_REQUEST";
export const UPDATE_CREDIT_MEMO_LINE_SUCCESS = "UPDATE_CREDIT_MEMO_LINE_SUCCESS";
export const UPDATE_CREDIT_MEMO_LINE_ERROR = "UPDATE_CREDIT_MEMO_LINE_ERROR";

export const DELETE_CREDIT_MEMO_LINE_REQUEST = "DELETE_CREDIT_MEMO_LINE_REQUEST";
export const DELETE_CREDIT_MEMO_LINE_SUCCESS = "DELETE_CREDIT_MEMO_LINE_SUCCESS";
export const DELETE_CREDIT_MEMO_LINE_ERROR = "DELETE_CREDIT_MEMO_LINE_ERROR";


export const GET_CREDIT_MEMO_PRINT_REQUEST = "GET_CREDIT_MEMO_PRINT_REQUEST";
export const GET_CREDIT_MEMO_PRINT_SUCCESS = "GET_CREDIT_MEMO_PRINT_SUCCESS ";
export const GET_CREDIT_MEMO_PRINT_ERROR = "GET_CREDIT_MEMO_PRINT_ERROR ";

export const GET_SALE_COMPARISION_MONTH_WISE_REQUEST = "GET_SALE_COMPARISION_MONTH_WISE_REQUEST";
export const GET_SALE_COMPARISION_MONTH_WISE_SUCCESS = "GET_SALE_COMPARISION_MONTH_WISE_SUCCESS ";
export const GET_SALE_COMPARISION_MONTH_WISE_ERROR = "GET_SALE_COMPARISION_MONTH_WISE_ERROR ";

export const GET_SALE_COMPARISION_CUSTOMER_WISE_REQUEST = "GET_SALE_COMPARISION_CUSTOMER_WISE_REQUEST";
export const GET_SALE_COMPARISION_CUSTOMER_WISE_SUCCESS = "GET_SALE_COMPARISION_CUSTOMER_WISE_SUCCESS ";
export const GET_SALE_COMPARISION_CUSTOMER_WISE_ERROR = "GET_SALE_COMPARISION_CUSTOMER_WISE_ERROR ";

export const GET_ALL_PENDING_BILL_CREADIT_REQUEST = "GET_ALL_PENDING_BILL_CREADIT_REQUEST";
export const GET_ALL_PENDING_BILL_CREADIT_SUCCESS = "GET_ALL_PENDING_BILL_CREADIT_SUCCESS ";
export const GET_ALL_PENDING_BILL_CREADIT_ERROR = "GET_ALL_PENDING_BILL_CREADIT_ERROR ";


export const GET_ONE_CREDIT_MEMO_INVOICE_REQUEST = "GET_ONE_CREDIT_MEMO_INVOICE_REQUEST";
export const GET_ONE_CREDIT_MEMO_INVOICE_SUCCESS = "GET_ONE_CREDIT_MEMO_INVOICE_SUCCESS";
export const GET_ONE_CREDIT_MEMO_INVOICE_ERROR = "GET_ONE_CREDIT_MEMO_INVOICE_ERROR";

export const DELETE_CREDIT_MEMO_INVOICE_REQUEST = "DELETE_CREDIT_MEMO_INVOICE_REQUEST";
export const DELETE_CREDIT_MEMO_SUCCESS = "DELETE_CREDIT_MEMO_SUCCESS";
export const DELETE__CREDIT_MEMO_ERROR = "DELETE__CREDIT_MEMO_ERROR";

export const UPDATE_CREDIT_MEMO_INVOICE_REQUEST = "UPDATE_CREDIT_MEMO_INVOICE_REQUEST";
export const UPDATE_CREDIT_MEMO_INVOICE_SUCCESS = "UPDATE_CREDIT_MEMO_INVOICE_SUCCESS";
export const UPDATE_CREDIT_MEMO_INVOICE_ERROR = "UPDATE_CREDIT_MEMO_INVOICE_ERROR";


export const GET_INTRANSIT_REPORT_REQUEST = "GET_INTRANSIT_REPORT_REQUEST";
export const GET_INTRANSIT_REPORT_SUCCESS = "GET_INTRANSIT_REPORT_SUCCESS";
export const GET_INTRANSIT_REPORT_ERROR = "GET_INTRANSIT_REPORT_ERROR";

export const GET_SYSTEM_LOG_REQUEST = "GET_SYSTEM_LOG_REQUEST";
export const GET_SYSTEM_LOG_ERROR = "GET_SYSTEM_LOG_ERROR";
export const GET_SYSTEM_LOG_SUCCESS = "GET_SYSTEM_LOG_SUCCESS";

export const GET_ACCOUNT_PAYABLE_REQUEST = "GET_SYSTEM_LOG_REQUEST";
export const GET_ACCOUNT_PAYABLE_ERROR = "GET_ACCOUNT_PAYABLE_ERROR";
export const GET_ACCOUNT_PAYABLE_SUCCESS = "GET_ACCOUNT_PAYABLE_SUCCESS";

export const GET_PRODUCTION_REPORT_REQUEST = "GET_PRODUCTION_REPORT_REQUEST";
export const GET_PRODUCTION_REPORT_SUCCESS = "GET_PRODUCTION_REPORT_SUCCESS";
export const GET_PRODUCTION_REPORT_ERROR = "GET_PRODUCTION_REPORT_ERROR";

export const GET_SKULOOKUP_REPORT_REQUEST = "GET_SKULOOKUP_REPORT_REQUEST";
export const GET_SKULOOKUP_REPORT_SUCCESS = "GET_SKULOOKUP_REPORT_SUCCESS";
export const GET_SKULOOKUP_REPORT_ERROR = "GET_SKULOOKUP_REPORT_ERROR";


export const GET_CHART_ACCOUT_REQUEST = "GET_CHART_ACCOUTN_REQUEST";
export const GET_CHART_ACCOUT_SUCCESS = "GET_CHART_ACCOUTN_SUCCESS";
export const GET_CHART_ACCOUT_ERROR = "GET_CHART_ACCOUTN_ERROR";

export const GET_ALL_CUSTOMER_VENDOR_ERROR = "GET_ALL_CUSTOMER_VENDOR_ERROR";
export const GET_ALL_CUSTOMER_VENDOR_SUCCESS = "GET_ALL_CUSTOMER_VENDOR_SUCCESS";
export const GET_ALL_CUSTOMER_VENDOR_REQUEST = "GET_ALL_CUSTOMER_VENDOR_REQUEST";

export const ADD_CUSTOMER_VENDOR_REQUEST = "ADD_CUSTOMER_VENDOR_REQUEST";
export const ADD_CUSTOMER_VENDOR_ERROR = "ADD_CUSTOMER_VENDOR_ERROR";
export const ADD_CUSTOMER_VENDOR_SUCCES = "ADD_CUSTOMER_VENDOR_SUCCES";

export const DELETE_CUSTOMER_VENDOR_REQUEST = "DELETE_CUSTOMER_VENDOR_REQUEST";
export const DELETE_CUSTOMER_VENDOR_SUCCESS = "DELETE_CUSTOMER_VENDOR_SUCCESS";
export const DELETE_CUSTOMER_VENDOR_ERROR = "DELETE_CUSTOMER_VENDOR_ERROR";

export const UPDATE_CUSTOMER_VENDOR_REQUEST = "UPDATE_CUSTOMER_VENDOR_REQUEST";
export const UPDATE_CUSTOMER_VENDOR_SUCCESS = "UPDATE_CUSTOMER_VENDOR_SUCCESS";
export const UPDATE_CUSTOMER_VENDOR_ERROR = "UPDATE_CUSTOMER_VENDOR_ERROR";


export const SEND_PICK_TICKET_EMAIL_SUCCESS = "SEND_PICK_TICKET_EMAIL_SUCCESS";
export const SEND_PICK_TICKET_EMAIL_ERROR = "SEND_PICK_TICKET_EMAIL_ERROR";
export const SEND_PICK_TICKET_EMAIL_REQUEST = "SEND_PICK_TICKET_EMAIL_REQUEST";

export const GET_OTS_DASHBOARD_REQUEST = "GET_COTS_DASHBOARD_REQUEST";
export const GET_OTS_DASHBOARD_SUCCESS = "GET_COTS_DASHBOARD_SUCCESS";
export const GET_OTS_DASHBOARD_ERROR = "GET_COTS_DASHBOARD_ERROR";

export const GET_BILL_NUMBER_REQUEST = "GET_BILL_NUMBER_REQUEST";
export const GET_BILL_NUMBER_SUCCESS = "GET_BILL_NUMBER_SUCCESS";
export const GET_BILL_NUMBER_ERROR = "GET_BILL_NUMBER_ERROR";

export const ADD_CUSTOMER_PAYMENT_IMPACT_REQUEST = "ADD_CUSTOMER_PAYMENT_IMPACT_REQUEST";
export const ADD_CUSTOMER_PAYMENT_IMPACT_SUCCESS = "ADD_CUSTOMER_PAYMENT_IMPACT_SUCCESS";
export const ADD_CUSTOMER_PAYMENT_IMPACT_ERROR = "ADD_CUSTOMER_PAYMENT_IMPACT_ERROR";

export const SEND_TO_BERGEN_REQUEST = "SEND_TO_BERGEN_REQUEST";
export const SEND_TO_BERGEN_SUCCESS = "SEND_TO_BERGEN_SUCCESS";
export const SEND_TO_BERGEN_ERROR = "SEND_TO_BERGEN_ERROR";

export const GET_ALL_PENDING_POD_SUMMARY_AR_SUCCESS = 'GET_ALL_PENDING_POD_SUMMARY_AR_SUCCESS';
export const GET_ALL_PENDING_POD_SUMMARY_AR_REQUEST = 'GET_ALL_PENDING_POD_SUMMARY_AR_REQUEST';
export const GET_ALL_PENDING_POD_SUMMARY_AR_ERROR = 'GET_ALL_PENDING_POD_SUMMARY_AR_ERROR';

export const GET_CUSTOMER_BALANCES_REQUEST = "GET_CUSTOMER_BALANCES_REQUEST";
export const GET_CUSTOMER_BALANCES_SUCCESS = "GET_CUSTOMER_BALANCES_SUCCESS";
export const GET_CUSTOMER_BALANCES_ERROR = "GET_CUSTOMER_BALANCES_ERROR";

export const GET_ALL_CUSTOMERS_LIST_REQUEST = "GET_ALL_CUSTOMERS_LIST_REQUEST";
export const GET_ALL_CUSTOMERS_LIST_SUCCESS = "GET_ALL_CUSTOMERS_LIST_SUCCESS";
export const GET_ALL_CUSTOMERS_LIST_ERROR = "GET_ALL_CUSTOMERS_LIST_ERROR";

export const GET_ALL_INTERNATIONAL_ORDER_REQUEST = "GET_ALL_INTERNATIONAL_ORDER_REQUEST";
export const GET_ALL_INTERNATIONAL_ORDER_SUCCESS = "GET_ALL_INTERNATIONAL_ORDER_SUCCESS";
export const GET_ALL_INTERNATIONAL_ORDER_ERROR = "GET_ALL_INTERNATIONAL_ORDER_ERROR";

export const GET_ALL_CANADA_ORDER_REQUEST = "GET_ALL_CANADA_ORDER_REQUEST";
export const GET_ALL_CANADA_ORDER_SUCCESS = "GET_ALL_CANADA_ORDER_SUCCESS";
export const GET_ALL_CANADA_ORDER_ERROR = "GET_ALL_CANADA_ORDER_ERROR";

export const ADD_PURCHASE_BILL_REQUEST = "ADD_PURCHASE_BILL_REQUEST";
export const ADD_PURCHASE_BILL_SUCCESS = "ADD_PURCHASE_BILL_SUCCESS";
export const ADD_PURCHASE_BILL_ERROR = "ADD_PURCHASE_BILL_ERROR";

export const GET_CIT_REQUEST = "GET_CIT_REQUEST";
export const GET_CIT_SUCCESS = "GET_CIT_SUCCESS ";
export const GET_CIT_ERROR = "GET_CIT_ERROR ";

export const GET_CREDIT_PRINT_REQUEST = "GET_CREDIT_PRINT_REQUEST";
export const GET_CREDIT_PRINT_SUCCESS = "GET_CREDIT_PRINT_SUCCESS ";
export const GET_CREDIT_PRINT_ERROR = "GET_CREDIT_PRINT_ERROR ";

export const ADD_CREATE_BILL_REQUEST = "ADD_CREATE_BILL_REQUEST";
export const ADD_CREATE_BILL_SUCCESS = "ADD_CREATE_BILL_SUCCESS";
export const ADD_CREATE_BILL_ERROR = "ADD_CREATE_BILL_ERROR";

export const GET_DL_SHOWROOM_REQUEST = "GET_DL_SHOWROOM_REQUEST";
export const GET_DL_SHOWROOM_SUCCESS = "GET_DL_SHOWROOM_SUCCESS ";
export const GET_DL_SHOWROOM_ERROR = "GET_DL_SHOWROOM_ERROR ";

export const ASN_TO_BERGEN_REQUEST = "ASN_TO_BERGEN_REQUEST";
export const ASN_TO_BERGEN_SUCCESS = "ASN_TO_BERGEN_SUCCESS";
export const ASN_TO_BERGEN_ERROR = "ASN_TO_BERGEN_ERROR";

export const GET_INVENTORY_REPORT_REQUEST = "GET_INVENTORY_REPORT_REQUEST";
export const GET_INVENTORY_REPORT_SUCCESS = "GET_INVENTORY_REPORT_SUCCESS ";
export const GET_INVENTORY_REPORT_ERROR = "GET_INVENTORY_REPORT_ERROR ";

export const GET_DSCO_INVOICE_REPORT_REQUEST = "GET_DSCO_INVOICE_REPORT_REQUEST";
export const GET_DSCO_INVOICE_REPORT_SUCCESS = "GET_DSCO_INVOICE_REPORT_SUCCESS ";
export const GET_DSCO_INVOICE_REPORT_ERROR = "GET_DSCO_INVOICE_REPORT_ERROR ";

export const GET_ALL_POD_DETAIL_SUCCESS = 'GET_ALL_POD_DETAIL_SUCCESS';
export const GET_ALL_POD_DETAIL_REQUEST = 'GET_ALL_POD_DETAIL_REQUEST';
export const GET_ALL_POD_DETAIL_ERROR = 'GET_ALL_POD_DETAIL_ERROR';

export const GET_NEW_SALES_REPORT_SUCCESS = 'GET_NEW_SALES_REPORT_SUCCESS';
export const GET_NEW_SALES_REPORT_REQUEST = 'GET_NEW_SALES_REPORT_REQUEST';
export const GET_NEW_SALES_REPORT_ERROR = 'GET_NEW_SALES_REPORT_ERROR';

export const GET_SEASON_YEAR_REQUEST = "GET_SEASON_YEAR_REQUEST";
export const GET_SEASON_YEAR_ERROR = "GET_SEASON_YEAR_ERROR";
export const GET_SEASON_YEAR_SUCCESS = "GET_SEASON_YEAR_SUCCESS";

export const ADD_SEASON_YEAR_REQUEST = "ADD_SEASON_YEAR_REQUEST";
export const ADD_SEASON_YEAR_ERROR = "ADD_SEASON_YEAR_ERROR";
export const ADD_SEASON_YEAR_SUCCESS = "ADD_SEASON_YEAR_SUCCESS";

export const UPDATE_SEASON_YEAR_REQUEST = "UPDATE_SEASON_YEAR_REQUEST";
export const UPDATE_SEASON_YEAR_ERROR = "UPDATE_SEASON_YEAR_ERROR";
export const UPDATE_SEASON_YEAR_SUCCESS = "UPDATE_SEASON_YEAR_SUCCESS";

export const DELETE_SEASON_YEAR_REQUEST = "DELETE_SEASON_YEAR_REQUEST";
export const DELETE_SEASON_YEAR_ERROR = "DELETE_SEASON_YEAR_ERROR";
export const DELETE_SEASON_YEAR_SUCCESS = "DELETE_SEASON_YEAR_SUCCESS";

export const SEND_EMAIL_INVOICE_SUCCESS = "SEND_EMAIL_INVOICE_SUCCESS";
export const SEND_EMAIL_INVOICE_ERROR = "SEND_EMAIL_INVOICE_ERROR";
export const SEND_EMAIL_INVOICE_REQUEST = "SEND_EMAIL_INVOICE_REQUEST";


export const GET_INVOICE_EMAIL_REQUEST = "GET_INVOICE_EMAIL_REQUEST";
export const GET_INVOICE_EMAIL_SUCCESS = "GET_INVOICE_EMAIL_SUCCESS";
export const GET_INVOICE_EMAIL_ERROR = "GET_INVOICE_EMAIL_ERROR";




export const CREATE_INVOICE_EMAIL_REQUEST = "CREATE_INVOICE_EMAIL_REQUEST";
export const CREATE_INVOICE_EMAIL_SUCCESS = "CREATE_INVOICE_EMAIL_SUCCESS";
export const CREATE_INVOICE_EMAIL_ERROR = "CREATE_INVOICE_EMAIL_ERROR";


export const UPDATE_ITEMS_REQUEST = "UPDATE_ITEMS_REQUEST";
export const UPDATE_ITEMS_ERROR = "UPDATE_ITEMS_ERROR";
export const UPDATE_ITEMS_SUCCESS = "UPDATE_ITEMS_SUCCESS";


export const GET_CREDIT_NOTE_APPLY_REQUEST = "GET_CREDIT_NOTE_APPLY_REQUEST";
export const GET_CREDIT_NOTE_APPLY_SUCCESS = "GET_CREDIT_NOTE_APPLY_SUCCESS";
export const GET_CREDIT_NOTE_APPLY_ERROR = "GET_CREDIT_NOTE_APPLY_ERROR";

export const UPDATE_CREDIT_NOTE_APPLY_REQUEST = "UPDATE_CREDIT_NOTE_APPLY_REQUEST";
export const UPDATE_CREDIT_NOTE_APPLY_SUCCESS = "UPDATE_CREDIT_NOTE_APPLY_SUCCESS";
export const UPDATE_CREDIT_NOTE_APPLY_ERROR = "UPDATE_CREDIT_NOTE_APPLY_ERROR";


export const DELETE_CREDIT_NOTE_APPLY_REQUEST = "DELETE_CREDIT_NOTE_APPLY_REQUEST";
export const DELETE_CREDIT_NOTE_APPLY_SUCCESS = "DELETE_CREDIT_NOTE_APPLY_SUCCESS";
export const DELETE_CREDIT_NOTE_APPLY_ERROR = "DELETE_CREDIT_NOTE_APPLY_ERROR";


export const CUSTOMER_SELECTED_INVOICE_REQUEST = "CUSTOMER_SELECTED_INVOICE_REQUEST";
export const CUSTOMER_SELECTED_INVOICE_SUCCESS = "CUSTOMER_SELECTED_INVOICE_SUCCESS";
export const CUSTOMER_SELECTED_INVOICE_ERROR = "CUSTOMER_SELECTED_INVOICE_ERROR";

export const GET_EXPENSE_ACCOUTN_REQUEST = "GET_EXPENSE_ACCOUTN_REQUEST";
export const GET_EXPENSE_ACCOUTN_SUCCESS = "GET_EXPENSE_ACCOUTN_SUCCESS";
export const GET_EXPENSE_ACCOUTN_ERROR = "GET_EXPENSE_ACCOUTN_ERROR";

export const GET_OMS_STOCK_SUMMARY_REPORT_REQUEST2 = "GET_OMS_STOCK_SUMMARY_REPORT_REQUEST2";
export const GET_OMS_STOCK_SUMMARY_REPORT_SUCCESS2 = "GET_OMS_STOCK_SUMMARY_REPORT_SUCCESS2";
export const GET_OMS_STOCK_SUMMARY_REPORT_ERROR2 = "GET_OMS_STOCK_SUMMARY_REPORT_ERROR2";

export const ADD_CUSTOMER_REFUND_IMPACT_REQUEST = "ADD_CUSTOMER_REFUND_IMPACT_REQUEST";
export const ADD_CUSTOMER_REFUND_IMPACT_SUCCESS = "ADD_CUSTOMER_REFUND_IMPACT_SUCCESS";
export const ADD_CUSTOMER_REFUND_IMPACT_ERROR = "ADD_CUSTOMER_REFUND_IMPACT_ERROR";

export const GET_LOCATION_WISE_REPORT_REQUEST = "GET_LOCATION_WISE_REPORT_REQUEST";
export const GET_LOCATION_WISE_REPORT_SUCCESS = "GET_LOCATION_WISE_REPORT_SUCCESS";
export const GET_LOCATION_WISE_REPORT_ERROR = "GET_LOCATION_WISE_REPORT_ERROR";

export const GET_COMMISSION_REPORT_REQUEST = "GET_COMMISSION_REPORT_REQUEST";
export const GET_COMMISSION_REPORT_SUCCESS = "GET_COMMISSION_REPORT_SUCCESS ";
export const GET_COMMISSION_REPORT_ERROR = "GET_COMMISSION_REPORT_ERROR ";

export const GET_PRODUCT_INFORMATION_BY_SIZE_REPORT_REQUEST = "GET_PRODUCT_INFORMATION_BY_SIZE_REPORT_REQUEST";
export const GET_PRODUCT_INFORMATION_BY_SIZE_REPORT_SUCCESS = "GET_PRODUCT_INFORMATION_BY_SIZE_REPORT_SUCCESS ";
export const GET_PRODUCT_INFORMATION_BY_SIZE_REPORT_ERROR = "GET_PRODUCT_INFORMATION_BY_SIZE_REPORT_ERROR ";

export const GET_GOOD_REQUIRED_REPORT_REQUEST = "GET_GOOD_REQUIRED_REPORT_REQUEST";
export const GET_GOOD_REQUIRED_REPORT_SUCCESS = "GET_GOOD_REQUIRED_REPORT_SUCCESS ";
export const GET_GOOD_REQUIRED_REPORT_ERROR = "GET_GOOD_REQUIRED_REPORT_ERROR ";

export const GET_TRANSACTION_NUMBER_REQUEST = "GET_TRANSACTION_NUMBER_REQUEST";
export const GET_TRANSACTION_NUMBER_SUCCESS = "GET_TRANSACTION_NUMBER_SUCCESS";
export const GET_TRANSACTION_NUMBER_ERROR = "GET_TRANSACTION_NUMBER_ERROR";

export const GET_AR_AGING_SUMMARY_REQUEST = "GET_AR_AGING_SUMMARY_REQUEST";
export const GET_AR_AGING_SUMMARY_SUCCESS = "GET_AR_AGING_SUMMARY_SUCCESS";
export const GET_AR_AGING_SUMMARY_ERROR = "GET_AR_AGING_SUMMARY_ERROR";

export const GET_SKULOOKUPNU_REPORT_REQUEST = "GET_SKULOOKUPNU_REPORT_REQUEST";
export const GET_SKULOOKUPNU_REPORT_SUCCESS = "GET_SKULOOKUPNU_REPORT_SUCCESS";
export const GET_SKULOOKUPNU_REPORT_ERROR = "GET_SKULOOKUPNU_REPORT_ERROR";

export const GET_INTERLOG_ERROR = "GET_INTERLOG_ERROR";
export const GET_INTERLOG_REQUEST = "GET_INTERLOG_REQUEST";
export const GET_INTERLOG_SUCCESS = "GET_INTERLOG_SUCCESS";

export const GET_TOTAL_OTS_BY_SIZE_REQUEST = "GET_TOTAL_OTS_BY_SIZE_REQUEST";
export const GET_TOTAL_OTS_BY_SIZE_SUCCESS = "GET_TOTAL_OTS_BY_SIZE_SUCCESS";
export const GET_TOTAL_OTS_BY_SIZE_ERROR = "GET_TOTAL_OTS_BY_SIZE_ERROR";


export const GET_PENDINGSKULOOKUP_REPORT_REQUEST = "GET_PENDINGSKULOOKUP_REPORT_REQUEST";
export const GET_PENDINGSKULOOKUP_REPORT_SUCCESS = "GET_PENDINGSKULOOKUP_REPORT_SUCCESS";
export const GET_PENDINGSKULOOKUP_REPORT_ERROR = "GET_PENDINGSKULOOKUP_REPORT_ERROR";

export const GET_INVOICE_PENDING_REQUEST = "GET_INVOICE_PENDING_REQUEST";
export const GET_INVOICE_PENDING_SUCCESS = "GET_INVOICE_PENDING_SUCCESS";
export const GET_INVOICE_PENDING_ERROR = "GET_INVOICE_PENDING_ERROR";

export const CREATE_INVOICE_PENDING_REQUEST = "CREATE_INVOICE_PENDING_REQUEST";
export const CREATE_INVOICE_PENDING_SUCCESS = "CREATE_INVOICE_PENDING_SUCCESS";
export const CREATE_INVOICE_PENDING_ERROR = "CREATE_INVOICE_PENDING_ERROR";

export const GET_INCOME_STATEMENT_REQUEST = "GET_INCOME_STATEMENT_REQUEST";
export const GET_INCOME_STATEMENT_SUCCESS = "GET_INCOME_STATEMENT_SUCCESS";
export const GET_INCOME_STATEMENT_ERROR = "GET_INCOME_STATEMENT_ERROR";

export const GET_ALL_SELECT_QUERY_SUCCESS = 'GET_ALL_SELECT_QUERY_SUCCESS';
export const GET_ALL_SELECT_QUERY_REQUEST = 'GET_ALL_SELECT_QUERY_REQUEST';
export const GET_ALL_SELECT_QUERY_ERROR = 'GET_ALL_SELECT_QUERY_ERROR';

export const GET_ONE_INVOICE_RELATED_RECORD_REQUEST = 'GET_ONE_INVOICE_RELATED_RECORD_REQUEST';
export const GET_ONE_INVOICE_RELATED_RECORD_SUCCESS = 'GET_ONE_INVOICE_RELATED_RECORD_SUCCESS';
export const GET_ONE_INVOICE_RELATED_RECORD_ERROR = 'GET_ONE_INVOICE_RELATED_RECORD_ERROR';

export const GET_SKULOOKUP_DATA_REQUEST = 'GET_SKULOOKUP_DATA_REQUEST';
export const GET_SKULOOKUP_DATA_SUCCESS = 'GET_SKULOOKUP_DATA_SUCCESS';
export const GET_SKULOOKUP_DATA_ERROR = 'GET_SKULOOKUP_DATA_ERROR';

export const GET_INCOME_DETAIL_REQUEST = "GET_INCOME_DETAIL_REQUEST";
export const GET_INCOME_DETAIL_SUCCESS = "GET_INCOME_DETAIL_SUCCESS";
export const GET_INCOME_DETAIL_ERROR = "GET_INCOME_DETAIL_ERROR";

export const GET_VENDOR_LEDGER_REQUEST = "GET_VENDOR_LEDGER_REQUEST";
export const GET_VENDOR_LEDGER_SUCCESS = "GET_VENDOR_LEDGER_SUCCESS";
export const GET_VENDOR_LEDGER_ERROR = "GET_VENDOR_LEDGER_ERROR";

export const GET_BALANCE_SHEET_REQUEST = "GET_BALANCE_SHEET_REQUEST";
export const GET_BALANCE_SHEET_SUCCESS = "GET_BALANCE_SHEET_SUCCESS";
export const GET_BALANCE_SHEET_ERROR = "GET_BALANCE_SHEET_ERROR";

export const GET_ALL_QUERY_BUILDER_SUCCESS = 'GET_ALL_QUERY_BUILDER_SUCCESS';
export const GET_ALL_QUERY_BUILDER_REQUEST = 'GET_ALL_QUERY_BUILDER_REQUEST';
export const GET_ALL_QUERY_BUILDER_ERROR = 'GET_ALL_QUERY_BUILDER_ERROR';
export const GET_UPC_REPORT_REQUEST = "GET_UPC_REPORT_REQUEST";
export const GET_UPC_REPORT_SUCCESS = "GET_UPC_REPORT_SUCCESS";
export const GET_UPC_REPORT_ERROR = "GET_UPC_REPORT_ERROR";

export const GET_BALANCE_DETAIL_REQUEST = "GET_BALANCE_DETAIL_REQUEST";
export const GET_BALANCE_DETAIL_SUCCESS = "GET_BALANCE_DETAIL_SUCCESS";
export const GET_BALANCE_DETAIL_ERROR = "GET_BALANCE_DETAIL_ERROR";


export const GET_NEW_PT_REPORT_SUCCESS = "GET_NEW_PT_REPORT_SUCCESS";
export const GET_NEW_PT_REPORT_REQUEST = "GET_NEW_PT_REPORT_REQUEST";
export const GET_NEW_PT_REPORT_ERROR = "GET_NEW_PT_REPORT_ERROR";

export const GET_CONTROL_SHEET_LOG_REQUEST = "GET_CONTROL_SHEET_LOG_REQUEST";
export const GET_CONTROL_SHEET_LOG_SUCCESS = "GET_CONTROL_SHEET_LOG_SUCCESS";
export const GET_CONTROL_SHEET_LOG_ERROR = "GET_CONTROL_SHEET_LOG_ERROR";

export const GET_SEARCH_PRODUCT_REQUEST = "GET_SEARCH_PRODUCT_REQUEST";
export const GET_SEARCH_PRODUCT_SUCCESS = "GET_SEARCH_PRODUCT_SUCCESS";
export const GET_SEARCH_PRODUCT_ERROR = "GET_SEARCH_PRODUCT_ERROR";


export const GET_SEND_PRODUCT_REQUEST = "GET_SEND_PRODUCT_REQUEST";
export const GET_SEND_PRODUCT_SUCCESS = "GET_SEND_PRODUCT_SUCCESS";
export const GET_SEND_PRODUCT_ERROR = "GET_SEND_PRODUCT_ERROR";

export const GET_VENDOR_LEDGER_2_REQUEST = "GET_VENDOR_LEDGER_2_REQUEST";
export const GET_VENDOR_LEDGER_2_SUCCESS = "GET_VENDOR_LEDGER_2_SUCCESS";
export const GET_VENDOR_LEDGER_2_ERROR = "GET_VENDOR_LEDGER_2_ERROR";

export const GET_ONE_SALE_RETURN_RELATED_RECORD_REQUEST = "GET_ONE_SALE_RETURN_RELATED_RECORD_REQUEST";
export const GET_ONE_SALE_RETURN_RELATED_RECORD_SUCCESS = "GET_ONE_SALE_RETURN_RELATED_RECORD_SUCCESS";
export const GET_ONE_SALE_RETURN_RELATED_RECORD_ERROR = "GET_ONE_SALE_RETURN_RELATED_RECORD_ERROR";

export const GET_PAYMENT_SUMMARY_REPORT_REQUEST = "GET_PAYMENT_SUMMARY_REPORT_REQUEST";
export const GET_PAYMENT_SUMMARY_REPORT_SUCCESS = "GET_PAYMENT_SUMMARY_REPORT_SUCCESS ";
export const GET_PAYMENT_SUMMARY_REPORT_ERROR = "GET_PAYMENT_SUMMARY_REPORT_ERROR";

export const GET_OMS_STYLE_REPORT_REQUEST = "GET_OMS_STYLE_REPORT_REQUEST";
export const GET_OMS_STYLE_REPORT_SUCCESS = "GET_OMS_STYLE_REPORT_SUCCESS ";
export const GET_OMS_STYLE_REPORT_ERROR = "GET_OMS_STYLE_REPORT_ERROR";

export const GET_OMS_WASH_REPORT_REQUEST = "GET_OMS_WASH_REPORT_REQUEST";
export const GET_OMS_WASH_REPORT_SUCCESS = "GET_OMS_WASH_REPORT_SUCCESS ";
export const GET_OMS_WASH_REPORT_ERROR = "GET_OMS_WASH_REPORT_ERROR";

export const GET_SEARCH_PACKING_LIST_REQUEST = "GET_SEARCH_PACKING_LIST_REQUEST";
export const GET_SEARCH_PACKING_LIST_SUCCESS = "GET_SEARCH_PACKING_LIST_SUCCESS";
export const GET_SEARCH_PACKING_LIST_ERROR = "GET_SEARCH_PACKING_LIST_ERROR";

export const GET_SEND_BERGEN_EDI_REQUEST = "GET_SEND_BERGEN_EDI_REQUEST";
export const GET_SEND_BERGEN_EDI_SUCCESS = "GET_SEND_BERGEN_EDI_SUCCESS";
export const GET_SEND_BERGEN_EDI_ERROR = "GET_SEND_BERGEN_EDI_ERROR";

export const GET_SEND_STITCH_FIX_EDI_REQUEST = "GET_SEND_STITCH_FIX_EDI_REQUEST";
export const GET_SEND_STITCH_FIX_EDI_SUCCESS = "GET_SEND_STITCH_FIX_EDI_SUCCESS";
export const GET_SEND_STITCH_FIX_EDI_ERROR = "GET_SEND_BERGEN_EDI_ERROR";

export const GET_SEND_PACKING_LIST_REQUEST = "GET_SEND_PACKING_LIST_REQUEST";
export const GET_SEND_PACKING_LIST_SUCCESS = "GET_SEND_PACKING_LIST_SUCCESS";
export const GET_SEND_PACKING_LIST_ERROR = "GET_SEND_PACKING_LIST_ERROR";

export const GET_STYLE_LEDGER_REPORT_REQUEST = "GET_STYLE_LEDGER_REPORT_REQUEST";
export const GET_STYLE_LEDGER_REPORT_SUCCESS = "GET_STYLE_LEDGER_REPORT_SUCCESS";
export const GET_STYLE_LEDGER_REPORT_ERROR = "GET_STYLE_LEDGER_REPORT_ERROR";

export const GET_ALL_STYLE_LEDGER_REPORT_REQUEST = "GET_ALL_STYLE_LEDGER_REPORT_REQUEST";
export const GET_ALL_STYLE_LEDGER_REPORT_SUCCESS = "GET_ALL_STYLE_LEDGER_REPORT_SUCCESS";
export const GET_ALL_STYLE_LEDGER_REPORT_ERROR = "GET_ALL_STYLE_LEDGER_REPORT_ERROR";


export const GET_SEARCH_PTUK_REQUEST = "GET_SEARCH_PTUK_REQUEST";
export const GET_SEARCH_PTUK_SUCCESS = "GET_SEARCH_PTUK_SUCCESS";
export const GET_SEARCH_PTUK_ERROR = "GET_SEARCH_PTUK_ERROR";


export const GET_SEND_PTUK_REQUEST = "GET_SEND_PTUK_REQUEST";
export const GET_SEND_PTUK_SUCCESS = "GET_SEND_PTUK_SUCCESS";
export const GET_SEND_PTUK_ERROR = "GET_SEND_PTUK_ERROR";

export const GET_CHART_OF_ACCOUNT_REPORT_REQUEST = "GET_CHART_OF_ACCOUNT_REPORT_REQUEST";
export const GET_CHART_OF_ACCOUNT_REPORT_SUCCESS = "GET_CHART_OF_ACCOUNT_REPORT_SUCCESS ";
export const GET_CHART_OF_ACCOUNT_REPORT_ERROR = "GET_CHART_OF_ACCOUNT_REPORT_ERROR ";


export const SEND_BERGEN_EMAIL_REQUEST = "SEND_BERGEN_EMAIL_REQUEST";
export const SEND_BERGEN_EMAIL_SUCCESS = "SEND_BERGEN_EMAIL_SUCCESS ";
export const SEND_BERGEN_EMAIL_ERROR = "SEND_BERGEN_EMAIL_ERROR ";


export const GET_STYLE_SHOT_REQUEST = "GET_STYLE_SHOT_REQUEST";
export const GET_STYLE_SHOT_SUCCESS = "GET_STYLE_SHOT_SUCCESS";
export const GET_STYLE_SHOT_ERROR = "GET_STYLE_SHOT_ERROR";


export const GET_STYLE_DISTRO_REQUEST = "GET_STYLE_DISTRO_REQUEST";
export const GET_STYLE_DISTRO_SUCCESS = "GET_STYLE_DISTRO_SUCCESS";
export const GET_STYLE_DISTRO_ERROR = "GET_STYLE_DISTRO_ERROR";

export const GET_VENDOR_SUMMARY_REQUEST = "GET_VENDOR_SUMMARY_REQUEST";
export const GET_VENDOR_SUMMARY_SUCCESS = "GET_VENDOR_SUMMARY_SUCCESS";
export const GET_VENDOR_SUMMARY_ERROR = "GET_VENDOR_SUMMARY_ERROR";

export const GET_EXPENSE_REQUEST = "GET_EXPENSE_REQUEST";
export const GET_EXPENSE_SUCCESS = "GET_EXPENSE_SUCCESS";
export const GET_EXPENSE_ERROR = "GET_EXPENSE_ERROR";

export const GET_SEARCH_RA_REQUEST = "GET_SEARCH_RA_REQUEST";
export const GET_SEARCH_RA_SUCCESS = "GET_SEARCH_RA_SUCCESS";
export const GET_SEARCH_RA_ERROR = "GET_SEARCH_RA_ERROR";

export const GET_SEND_RA_REQUEST = "GET_SEND_RA_REQUEST";
export const GET_SEND_RA_SUCCESS = "GET_SEND_RA_SUCCESS";
export const GET_SEND_RA_ERROR = "GET_SEND_RA_ERROR";

export const GET_AR_INVENTORY_REQUEST = "GET_AR_INVENTORY_REQUEST";
export const GET_AR_INVENTORY_SUCCESS = "GET_AR_INVENTORY_SUCCESS";
export const GET_AR_INVENTORY_ERROR = "GET_AR_INVENTORY_ERROR";

export const GET_INVENTORY_VALUATION_REQUEST = "GET_INVENTORY_VALUATION_REQUEST";
export const GET_INVENTORY_VALUATION_SUCCESS = "GET_INVENTORY_VALUATION_SUCCESS";
export const GET_INVENTORY_VALUATION_ERROR = "GET_INVENTORY_VALUATION_ERROR";

export const GET_SALESPERSONLIST_REQUEST = "GET_SALESPERSONLIST_REQUEST";
export const GET_SALESPERSONLIST_SUCCESS = "GET_SALESPERSONLIST_SUCCESS";
export const GET_SALESPERSONLIST_ERROR = "GET_SALESPERSONLIST_ERROR";

export const GET_JOURNAL_LEDGER_REQUEST = "GET_JOURNAL_LEDGER_REQUEST";
export const GET_JOURNAL_LEDGER_SUCCESS = "GET_JOURNAL_LEDGER_SUCCESS";
export const GET_JOURNAL_LEDGER_ERROR = "GET_JOURNAL_LEDGER_ERROR";

export const GET_SIZE_CHART_REQUEST = "GET_SIZE_CHART_REQUEST";
export const GET_SIZE_CHART_ERROR = "GET_SIZE_CHART_ERROR";
export const GET_SIZE_CHART_SUCCESS = "GET_SIZE_CHART_SUCCESS";

export const GET_PICK_TICKET_DETAIL_REQUEST = "GET_PICK_TICKET_DETAIL_REQUEST";
export const GET_PICK_TICKET_DETAIL_SUCCESS = "GET_PICK_TICKET_DETAIL_SUCCESS";
export const GET_PICK_TICKET_DETAIL_ERROR = "GET_PICK_TICKET_DETAIL_ERROR";

export const GET_STYLE_WISE_STOCK_REPORT_REQUEST = "GET_STYLE_WISE_STOCK_REPORT_REQUEST";
export const GET_STYLE_WISE_STOCK_REPORT_SUCCESS = "GET_STYLE_WISE_STOCK_REPORT_SUCCESS";
export const GET_STYLE_WISE_STOCK_REPORT_ERROR = "GET_STYLE_WISE_STOCK_REPORT_ERROR ";

export const GET_CUSTOMER_AGING_REPORT_REQUEST = "GET_CUSTOMER_AGING_REPORT_REQUEST";
export const GET_CUSTOMER_AGING_REPORT_SUCCESS = "GET_CUSTOMER_AGING_REPORT_SUCCESS ";
export const GET_CUSTOMER_AGING_REPORT_ERROR = "GET_CUSTOMER_AGING_REPORT_ERROR";

export const GET_BALANCE_SHEET_OLD_REQUEST = "GET_BALANCE_SHEET_OLD_REQUEST";
export const GET_BALANCE_SHEET_OLD_SUCCESS = "GET_BALANCE_SHEET_OLD_SUCCESS";
export const GET_BALANCE_SHEET_OLD_ERROR = "GET_BALANCE_SHEET_OLD_ERROR";

export const ADD_PAYABLE_PAYMENT_CREDIT_TAKE_REQUEST = "ADD_PAYABLE_PAYMENT_CREDIT_TAKE_REQUEST";
export const ADD_PAYABLE_PAYMENT_CREDIT_TAKE_SUCCESS = "ADD_PAYABLE_PAYMENT_CREDIT_TAKE_SUCCESS";
export const ADD_PAYABLE_PAYMENT_CREDIT_TAKE_ERROR = "ADD_PAYABLE_PAYMENT_CREDIT_TAKE_ERROR";

export const GET_AP_AGING_DETAIL_REQUEST = "GET_AP_AGING_DETAIL_REQUEST";
export const GET_AP_AGING_DETAIL_SUCCESS = "GET_AP_AGING_DETAIL_SUCCESS";
export const GET_AP_AGING_DETAIL_ERROR = "GET_AP_AGING_DETAIL_ERROR";

export const UPDATE_TRANSFER_RATE_REQUEST = "UPDATE_TRANSFER_RATE_REQUEST";
export const UPDATE_TRANSFER_RATE_ERROR = "UPDATE_TRANSFER_RATE_ERROR";
export const UPDATE_TRANSFER_RATE_SUCCESS = "UPDATE_TRANSFER_RATE_SUCCESS";

export const GET_AVALARA_INVOICE_REQUEST = "GET_AVALARA_INVOICE_REQUEST";
export const GET_AVALARA_INVOICE_SUCCESS = "GET_AVALARA_INVOICE_SUCCESS";
export const GET_AVALARA_INVOICE_ERROR = "GET_AVALARA_INVOICE_ERROR";

export const GET_ALL_SALEPERSON_ACTIVE_REQUEST = "GET_ALL_SALEPERSON_ACTIVE_REQUEST";
export const GET_ALL_SALEPERSON_ACTIVE_SUCCESS = "GET_ALL_SALEPERSON_ACTIVE_SUCCESS";
export const GET_ALL_SALEPERSON_ACTIVE_ERROR = "GET_ALL_SALEPERSON_ACTIVE_ERROR";

export const GET_ALL_CUSTOMER_TYPE_ACTIVE_REQUEST = "GET_ALL_CUSTOMER_TYPE_ACTIVE_REQUEST";
export const GET_ALL_CUSTOMER_TYPE_ACTIVE_SUCCESS = "GET_ALL_CUSTOMER_TYPE_ACTIVE_SUCCESS";
export const GET_ALL_CUSTOMER_TYPE_ACTIVE_ERROR = "GET_ALL_CUSTOMER_TYPE_ACTIVE_ERROR";

export const GET_ALL_REGION_ACTIVE_REQUEST = "GET_ALL_REGION_ACTIVE_REQUEST";
export const GET_ALL_REGION_ACTIVE_SUCCESS = "GET_ALL_REGION_ACTIVE_SUCCESS";
export const GET_ALL_REGION_ACTIVE_ERROR = "GET_ALL_REGION_ACTIVE_ERROR";


export const GET_CRM_SKU_WISE_REQUEST = "GET_CRM_SKU_WISE_REQUEST";
export const GET_CRM_SKU_WISE_SUCCESS = "GET_CRM_SKU_WISE_SUCCESS";
export const GET_CRM_SKU_WISE_ERROR = "GET_CRM_SKU_WISE_ERROR";


export const GET_TRANSACTION_LOCK_REQUEST = "GET_TRANSACTION_LOCK_REQUEST";
export const GET_TRANSACTION_LOCK_SUCCESS = "GET_TRANSACTION_LOCK_SUCCESS";
export const GET_TRANSACTION_LOCK_ERROR = "GET_TRANSACTION_LOCK_ERROR";

export const ADD_TRANSACTION_LOCK_REQUEST = "ADD_TRANSACTION_LOCK_REQUEST";
export const ADD_TRANSACTION_LOCK_SUCCESS = "ADD_TRANSACTION_LOCK_SUCCESS";
export const ADD_TRANSACTION_LOCK_ERROR = "ADD_TRANSACTION_LOCK_ERROR";



export const GET_ALL_TRANSACTION_LOCK_REQUEST = "GET_ALL_TRANSACTION_LOCK_REQUEST";
export const GET_ALL_TRANSACTION_LOCK_SUCCESS = "GET_ALL_TRANSACTION_LOCK_SUCCESS";
export const GET_ALL_TRANSACTION_LOCK_ERROR = "GET_ALL_TRANSACTION_LOCK_ERROR";

export const UPDATE_TRANSACTION_LOCK_REQUEST = "UPDATE_TRANSACTION_LOCK_REQUEST";
export const UPDATE_TRANSACTION_LOCK_SUCCESS = "UPDATE_TRANSACTION_LOCK_SUCCESS";
export const UPDATE_TRANSACTION_LOCK_ERROR = "UPDATE_TRANSACTION_LOCK_ERROR";

export const DELETE_TRANSACTION_LOCK_REQUEST = "DELETE_TRANSACTION_LOCK_REQUEST";
export const DELETE_TRANSACTION_LOCK_SUCCESS = "DELETE_TRANSACTION_LOCK_SUCCESS";
export const DELETE_TRANSACTION_LOCK_ERROR = "DELETE_TRANSACTION_LOCK_ERROR";


export const TRANSACTION_LOCK_LOG_REQUEST = "TRANSACTION_LOCK_LOG_REQUEST";
export const TRANSACTION_LOCK_LOG_SUCCESS = "TRANSACTION_LOCK_LOG_SUCCESS";
export const TRANSACTION_LOCK_LOG_ERROR = "TRANSACTION_LOCK_LOG_ERROR";

export const BILL_PAYMENT_IMPACT_REQUEST = "BILL_PAYMENT_IMPACT_REQUEST";
export const BILL_PAYMENT_IMPACT_SUCCESS = "BILL_PAYMENT_IMPACT_SUCCESS";
export const BILL_PAYMENT_IMPACT_ERROR = "BILL_PAYMENT_IMPACT_ERROR";

export const GET_SALE_TARGET_SALEPERSON_REPORT_REQUEST = "GET_SALE_TARGET_SALEPERSON_REPORT_REQUEST";
export const GET_SALE_TARGET_SALEPERSON_REPORT_SUCCESS = "GET_SALE_TARGET_SALEPERSON_REPORT_SUCCESS";
export const GET_SALE_TARGET_SALEPERSON_REPORT_ERROR = "GET_SALE_TARGET_SALEPERSON_REPORT_ERROR";


export const GET_CHARGE_BACK_DETAIL_REQUEST = "GET_CHARGE_BACK_DETAIL_REQUEST";
export const GET_CHARGE_BACK_DETAIL_SUCCESS = "GET_CHARGE_BACK_DETAIL_SUCCESS";
export const GET_CHARGE_BACK_DETAIL_ERROR = "GET_CHARGE_BACK_DETAIL_ERROR";


export const CUSTOMER_PAYMENT_ACCESS_REQUEST = "CUSTOMER_PAYMENT_ACCESS_REQUEST";
export const CUSTOMER_PAYMENT_ACCESS_SUCCESS = "CUSTOMER_PAYMENT_ACCESS_SUCCESS";
export const CUSTOMER_PAYMENT_ACCESS_ERROR = "CUSTOMER_PAYMENT_ACCESS_ERROR";


export const GET_UPC_TO_ITEMCODE_REQUEST = "GET_UPC_TO_ITEMCODE_REQUEST";
export const GET_UPC_TO_ITEMCODE_SUCCESS = "GET_UPC_TO_ITEMCODE_SUCCESS";
export const GET_UPC_TO_ITEMCODE_ERROR = "GET_UPC_TO_ITEMCODE_ERROR";

export const GET_NET_SHIP_STYLE_WISE_REQUEST = "GET_NET_SHIP_STYLE_WISE_REQUEST";
export const GET_NET_SHIP_STYLE_WISE_SUCCESS = "GET_NET_SHIP_STYLE_WISE_SUCCESS";
export const GET_NET_SHIP_STYLE_WISE_ERROR   = "GET_NET_SHIP_STYLE_WISE_ERROR";

export const GET_MISSING_UPC_REQUEST = "GET_MISSING_UPC_REQUEST";
export const GET_MISSING_UPC_SUCCESS = "GET_MISSING_UPC_SUCCESS";
export const GET_MISSING_UPC_ERROR   = "GET_MISSING_UPC_ERROR";


export const GET_CUSTOMER_PAYMENT_DATE_WISE_REQUEST = "GET_CUSTOMER_PAYMENT_DATE_WISE_REQUEST";
export const GET_CUSTOMER_PAYMENT_DATE_WISE_SUCCESS = "GET_CUSTOMER_PAYMENT_DATE_WISE_SUCCESS ";
export const GET_CUSTOMER_PAYMENT_DATE_WISE_ERROR   = "GET_CUSTOMER_PAYMENT_DATE_WISE_ERROR";

export const GET_PENDING_ORDER_AVAILABILTY_BY_SIZE_REQUEST = "GET_PENDING_ORDER_AVAILABILTY_BY_SIZE_REQUEST";
export const GET_PENDING_ORDER_AVAILABILTY_BY_SIZE_SUCCESS = "GET_PENDING_ORDER_AVAILABILTY_BY_SIZE_SUCCESS";
export const GET_PENDING_ORDER_AVAILABILTY_BY_SIZE_ERROR   = "GET_PENDING_ORDER_AVAILABILTY_BY_SIZE_ERROR";

export const GET_NEGATIVE_OTS_ORDER_WISE_REQUEST = "GET_NEGATIVE_OTS_ORDER_WISE_REQUEST";
export const GET_NEGATIVE_OTS_ORDER_WISE_SUCCESS = "GET_NEGATIVE_OTS_ORDER_WISE_SUCCESS";
export const GET_NEGATIVE_OTS_ORDER_WISE_ERROR   = "GET_NEGATIVE_OTS_ORDER_WISE_ERROR";

export const GET_TRACKING_STATUS_REQUEST = "GET_TRACKING_STATUS_REQUEST";
export const GET_TRACKING_STATUS_SUCCESS = "GET_TRACKING_STATUS_SUCCESS";
export const GET_TRACKING_STATUS_ERROR   = "GET_TRACKING_STATUS_ERROR";