import { GET_ORDER_SHIP_TO_DETAIL_ERROR, GET_ORDER_SHIP_TO_DETAIL_REQUEST, GET_ORDER_SHIP_TO_DETAIL_SUCCESS, } from "../actions/types";
export default function (state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case GET_ORDER_SHIP_TO_DETAIL_REQUEST:
      return {
        loading: true,
      };
    case GET_ORDER_SHIP_TO_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        payload,
      };
    case GET_ORDER_SHIP_TO_DETAIL_ERROR:
      return {
        ...state,
        loading: false,
        payload,
      };
    default:
      return state;
  }
}
