import {
    GET_ITEMTYPE_ERROR, GET_ITEMTYPE_REQUEST, GET_ITEMTYPE_SUCCESS,
    ADD_ITEMTYPE_ERROR, ADD_ITEMTYPE_REQUEST, ADD_ITEMTYPE_SUCCESS,
    DELETE_ITEMTYPE_REQUEST, DELETE_ITEMTYPE_SUCCESS, DELETE_ITEMTYPE_ERROR,
    UPDATE_ITEMTYPE_ERROR, UPDATE_ITEMTYPE_REQUEST, UPDATE_ITEMTYPE_SUCCESS

} from "../actions/types"
export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case GET_ITEMTYPE_REQUEST:
            return {
                loading: true,
            };
        case GET_ITEMTYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_ITEMTYPE_ERROR:
            return {
                ...state,
                loading: false
            };
        case ADD_ITEMTYPE_REQUEST:
            return {
                loading: true,
            };
        case ADD_ITEMTYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case ADD_ITEMTYPE_ERROR:
            return {
                ...state,
                loading: false
            };
        case DELETE_ITEMTYPE_REQUEST:
            return {
                loading: true,
            };
        case DELETE_ITEMTYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case DELETE_ITEMTYPE_ERROR:
            return {
                ...state,
                loading: false
            };
        case UPDATE_ITEMTYPE_REQUEST:
            return {
                loading: true,
            };
        case UPDATE_ITEMTYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case UPDATE_ITEMTYPE_ERROR:
            return {
                ...state,
                loading: false
            };

        default:
            return state;
    }
}