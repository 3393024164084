import {
    GET_PAYMENT_TYPE_REQUEST, GET_PAYMENT_TYPE_SUCCESS, GET_PAYMENT_TYPE_ERROR,
    ADD_PAYMENT_TYPE_REQUEST, ADD_PAYMENT_TYPE_SUCCESS, ADD_PAYMENT_TYPE_ERROR,
    UPDATE_PAYMENT_TYPE_REQUEST, UPDATE_PAYMENT_TYPE_SUCCESS, UPDATE_PAYMENT_TYPE_ERROR,
    DELETE_PAYMENT_TYPE_REQUEST, DELETE_PAYMENT_TYPE_SUCCESS, DELETE_PAYMENT_TYPE_ERROR,
} from "./types";

import { BASEURL } from "../../constant/config.js";

const axios = require("axios");

export const getPaymentType = (token, menu_id) => (dispatch) => {
    dispatch({ type: GET_PAYMENT_TYPE_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/paymentType`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { menu_id }
    })
        .then((response) => {
            return dispatch({ type: GET_PAYMENT_TYPE_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: GET_PAYMENT_TYPE_ERROR,
                payload: error.response,
            });
        });
};

export const addPaymentType = (token, data, menu_id) => (dispatch) => {

    dispatch({ type: ADD_PAYMENT_TYPE_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}create/paymentType`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { payment_type_name: data.paymentTypeName, is_active: data.check, menu_id },
    })
        .then((response) => {

            return dispatch({ type: ADD_PAYMENT_TYPE_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: ADD_PAYMENT_TYPE_ERROR,
                payload: error.response,
            });
        });
};

export const updatePaymentType = (token, data, id) => (dispatch) => {


    dispatch({ type: UPDATE_PAYMENT_TYPE_REQUEST });
    return axios({
        method: "PUT",
        url: `${BASEURL}update/paymentType/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { payment_type_name: data.paymentTypeName, is_active: data.check },
    })
        .then((response) => {

            return dispatch({ type: UPDATE_PAYMENT_TYPE_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: UPDATE_PAYMENT_TYPE_ERROR,
                payload: error.response,
            });
        });
};

export const deletePaymentType = (token, id) => (dispatch) => {
    dispatch({ type: DELETE_PAYMENT_TYPE_REQUEST });
    return axios({
        method: "DELETE",
        url: `${BASEURL}delete/paymentType/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
    })
        .then((response) => {
            return dispatch({ type: DELETE_PAYMENT_TYPE_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: DELETE_PAYMENT_TYPE_ERROR,
                payload: error.response,
            });
        });
};
