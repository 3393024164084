import {
  SEND_BERGEN_EMAIL_REQUEST,
SEND_BERGEN_EMAIL_SUCCESS,
SEND_BERGEN_EMAIL_ERROR
} from "./types";
import axios from "axios";
import { BASEURL } from "../../constant/config.js";

///Post Bussiness dashboard
export const sendBergenEmail = (token, values) => (dispatch) => {
  dispatch({ type: SEND_BERGEN_EMAIL_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}communication/sendBergenEmail`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data:  {ship_header_id: values} ,
  })
    .then((response) => {
      return dispatch({
        type: SEND_BERGEN_EMAIL_SUCCESS,
        payload: response,
      });
    })
    .catch((error) => {
      dispatch({
        type: SEND_BERGEN_EMAIL_ERROR,
        payload: [],
      });
    });
};
