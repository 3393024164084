import {
    GET_VENDORS_REQUEST, GET_VENDORS_ERROR, GET_VENDORS_SUCCESS, ADD_VENDOR_REQUEST, ADD_VENDOR_ERROR, ADD_VENDOR_SUCCESS,
    UPDATE_VENDOR_REQUEST, UPDATE_VENDOR_ERROR, UPDATE_VENDOR_SUCCESS, DELETE_VENDOR_REQUEST, DELETE_VENDOR_ERROR, DELETE_VENDOR_SUCCESS, GET_ONE_VENDOR_REQUEST, GET_ONE_VENDOR_SUCCESS, GET_ONE_VENDOR_ERROR
} from "../actions/types";


export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case GET_VENDORS_REQUEST:
            return {
                loading: true,
            };
        case GET_VENDORS_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_VENDORS_ERROR:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_ONE_VENDOR_REQUEST:
            return {
                loading: true,
            };
        case GET_ONE_VENDOR_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_ONE_VENDOR_ERROR:
            return {
                ...state,
                loading: false,
                payload,
            };
        case UPDATE_VENDOR_REQUEST:
            return {
                loading: true,
            };
        case UPDATE_VENDOR_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case UPDATE_VENDOR_ERROR:
            return {
                ...state,
                loading: false,
            };

        case ADD_VENDOR_REQUEST:
            return {
                loading: true,
            };
        case ADD_VENDOR_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case ADD_VENDOR_ERROR:
            return {
                ...state,
                loading: false,
            };

        case DELETE_VENDOR_REQUEST:
            return {
                loading: true,
            };
        case DELETE_VENDOR_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case DELETE_VENDOR_ERROR:
            return {
                ...state,
                loading: false,
            };

        default:
            return state;
    }
}
