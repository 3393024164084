import { GET_PO_LOOKUP_ERROR, GET_PO_LOOKUP_REQUEST, GET_PO_LOOKUP_SUCCESS } from "../actions/types";
import { BASEURL } from "../../constant/config.js";
import moment from "moment";

const axios = require("axios");

export const getPoLookup = (token, data) => (dispatch) => {
    dispatch({ type: GET_PO_LOOKUP_REQUEST });

    return axios({
        method: "POST",
        url: `${BASEURL}getAll/funcGetSaleOrderDetailByPoOmsReport`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: {
            po_no: data.PO,
        }
    })
        .then((response) => {
            return dispatch({ type: GET_PO_LOOKUP_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            if (error.response.data.data === "No Record Found !") {
                return dispatch({
                    type: GET_PO_LOOKUP_ERROR,
                    payload: [],
                });
            }
            return dispatch({
                type: GET_PO_LOOKUP_ERROR,
                payload: error.response,
            });
        });
};
