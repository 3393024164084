import {
	GET_GENDER_HEADER_DATA_REQUEST,
	GET_GENDER_HEADER_DATA_SUCCESS,
	GET_GENDER_HEADER_DATA_ERROR,
	GET_BILLINGSHIPPINGDETAILS_REQUEST,
	GET_BILLINGSHIPPINGDETAILS_SUCCESS,
	GET_BILLINGSHIPPINGDETAILS_ERROR,
} from "./types";
import { BASEURL } from "../../constant/config.js";

const axios = require("axios");

export const getSaleOrderGenderHeader = (token, gender, customerId) => (dispatch) => {
	dispatch({ type: GET_GENDER_HEADER_DATA_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}getAll/headerGender`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: { gender, customerId },
	})
		.then((response) => {
			return dispatch({ type: GET_GENDER_HEADER_DATA_SUCCESS, payload: response.data });
		})
		.catch((error) => {
			return dispatch({
				type: GET_GENDER_HEADER_DATA_ERROR,
				payload: error.response,
			});
		});
};

export const getBillinShippingDetails = (token, customerId) => (dispatch) => {
	dispatch({ type: GET_BILLINGSHIPPINGDETAILS_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}getAll/getBillingShipping`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: { customerId },
	})
		.then((response) => {
			return dispatch({ type: GET_BILLINGSHIPPINGDETAILS_SUCCESS, payload: response.data });
		})
		.catch((error) => {
			return dispatch({
				type: GET_BILLINGSHIPPINGDETAILS_ERROR,
				payload: error.response,
			});
		});
};