import {
	GET_FUND_TRANSFER_REQUEST,
	GET_FUND_TRANSFER_SUCCESS,
	GET_FUND_TRANSFER_ERROR,
	GET_ONE_FUND_TRANSFER_REQUEST,
	GET_ONE_FUND_TRANSFER_SUCCESS,
	GET_ONE_FUND_TRANSFER_ERROR,
	ADD_FUND_TRANSFER_REQUEST,
	ADD_FUND_TRANSFER_SUCCESS,
	ADD_FUND_TRANSFER_ERROR,
	UPDATE_FUND_TRANSFER_REQUEST,
	UPDATE_FUND_TRANSFER_SUCCESS,
	UPDATE_FUND_TRANSFER_ERROR,
	DELETE_FUND_TRANSFER_REQUEST,
	DELETE_FUND_TRANSFER_SUCCESS,
	DELETE_FUND_TRANSFER_ERROR
} from "./types";

import { BASEURL } from "../../constant/config.js";
import moment from "moment";
const axios = require("axios");
export const getFundTransfer = (token, data) => (dispatch) => {
	dispatch({ type: GET_FUND_TRANSFER_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}getAll/FundTransfer`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: {
			transfer_header_no: data.transferNo === "" ? null : data.transferNo,
			from_date: moment(data.from_date).format("MM-DD-YYYY"),
			to_date: moment(data.to_date).format("MM-DD-YYYY"),
			from_account_name: data.fromAccount === "" ? null : data.fromAccount,
			to_account_name: data.toAccount === "" ? null : data.toAccount,
			company_name: data.subsidiary === "" ? null : data.subsidiary,
			from_amount: data.fromamount === "" ? null : data.fromamount,
			gl_period_name: data.postingPeriod === "" ? null : data.postingPeriod,
			to_amount: data.toamount === "" ? null : data.toamount,
			company_id: 2

		},
	})
		.then((response) => {
			return dispatch({
				type: GET_FUND_TRANSFER_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_FUND_TRANSFER_ERROR,
				payload: error.response,
			});
		});
};

export const getOneFundTransfer = (token, id) => (dispatch) => {
	dispatch({ type: GET_ONE_FUND_TRANSFER_REQUEST });
	return axios({
		method: "GET",
		url: `${BASEURL}getOne/FundTransfer/${id}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
	})
		.then((response) => {
			return dispatch({
				type: GET_ONE_FUND_TRANSFER_SUCCESS,
				payload: response?.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_ONE_FUND_TRANSFER_ERROR,
				payload: error.response,
			});
		});
};

export const addFundTransfer = (token, formData, companyId) => (dispatch) => {

	dispatch({ type: ADD_FUND_TRANSFER_REQUEST });
	let newData = {
		transfer_date: moment(formData.date).format("MM-DD-YYYY"),
		from_account_id: formData.fromAccount,
		to_account_id: formData.ToAccount,
		gl_period_id: formData.postingPeriod,
		transfer_amount: formData.amount,
		memo: formData.memo,
		company_id: formData.subsidiary,
	};
	return axios({
		method: "POST",
		url: `${BASEURL}create/FundTransfer`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: newData,
	})
		.then((response) => {
			return dispatch({
				type: ADD_FUND_TRANSFER_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: ADD_FUND_TRANSFER_ERROR,
				payload: error.response,
			});
		});
};

export const updateFundTransfer = (token, formData, id) => (dispatch) => {
	let newData = {
		transfer_date: moment(formData.date).format("MM-DD-YYYY"),
		from_account_id: formData.fromAccount,
		to_account_id: formData.ToAccount,
		gl_period_id: formData.postingPeriod,
		transfer_amount: formData.amount,
		memo: formData.memo,
		company_id: formData.subsidiary,
	};
	dispatch({ type: UPDATE_FUND_TRANSFER_REQUEST });
	return axios({
		method: "PUT",
		url: `${BASEURL}update/FundTransfer/${id}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: newData,
	})
		.then((response) => {
			return dispatch({
				type: UPDATE_FUND_TRANSFER_SUCCESS,
				payloadUpdate: response?.data?.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: UPDATE_FUND_TRANSFER_ERROR,
				payloadUpdate: error.response,
			});
		});
};

export const deleteFundTransfer = (token, id) => (dispatch) => {
	dispatch({ type: DELETE_FUND_TRANSFER_REQUEST });
	return axios({
		method: "DELETE",
		url: `${BASEURL}delete/FundTransfer/${id}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
	})
		.then((response) => {
			return dispatch({
				type: DELETE_FUND_TRANSFER_SUCCESS,
				payload: response?.data.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: DELETE_FUND_TRANSFER_ERROR,
				payload: error.response,
			});
		});
};
