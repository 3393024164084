import React, { useState, useEffect, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getBilling, deleteBiller, addBiller, updateBiller } from "../../redux/actions/billing";
import { getRights } from "../../redux/actions/userRights.action";
import { loadCountry } from "../../redux/actions/adminCountry";
import { loadState } from "../../redux/actions/adminState";
import { loadCity } from "../../redux/actions/adminCity";

import Tables from "../../components/Table/Tables";
import { Button, Form, Input, Modal, Select, Checkbox, Row, Col, Space } from "antd";
import { PlusOutlined, ExclamationCircleOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { ReadCookie } from "../../utils/readCookie";
import notification from "../../utils/notification";
import { Row as Brow, Col as Bcol } from "reactstrap";
import { type } from "@testing-library/user-event/dist/type";
import { addVendorBiller, deleteVendorBiller, getVendorBilling, updateVendorBiller } from "../../redux/actions/vendor_bill.action";

const { confirm } = Modal;
const { Option } = Select;

export default function VendorBilling({ customer_id, token, formType, addressDataBill, cusID }) {
  const [showTable, setShowTable] = useState(false);
  const menuId = ReadCookie("menuId");
  const [form] = Form.useForm();

  
  const [customer_idForm, setcustomer_idForm] = useState("");
  const [billingAddress, setBillingAddress] = useState([]);
  const [biliingObj, setBillingObj] = useState(null);
  const [countryForm, setCountry] = useState({});
  const [visible, setVisible] = useState(false);
  const [stateForm, setState] = useState({});
  const [billID, setBillID] = useState(null);
  const [cityForm, setCity] = useState({});
  const [editForm, setEditForm] = useState("");
  const [eventType, setEventType] = useState("");
  const [count, setCount] = useState(0);
  const [check, setCheck] = useState(true);


  const dispatch = useDispatch();
  const { vendorBill, City, State, userRights, country, } = useSelector(
    (state) => state
  );

  const billingColumns = [
    {
      title: "Country",
      dataIndex: "country_name",
      key: "country_id",
    },
    {
      title: "State",
      dataIndex: "state_name",
      key: "state_id"
    },
    {
      title: "City",
      dataIndex: "city_name",
      key: "city_id",

    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space>
          {(userRights?.payload?.can_edit !== false && formType !== "add") &&
            <Button
              size="small"
              icon={<EditOutlined />}
              onClick={() => hanldeSubItems(record)}
              disabled={!(formType === "add" || formType === "edit")} />
          }
          {userRights?.payload?.can_delete !== false &&
            <Button
              size="small"
              icon={<DeleteOutlined />}
              onClick={() => hanldeDeleteItems(record)}
              disabled={!(formType === "add" || formType === "edit")} />
          }
        </Space>
      ),
    },
  ];

  useLayoutEffect(() => {
    dispatch(getRights(token, menuId)).then((res) => {
      if (res.type === "GET_USER_RIGHTS_SUCCESS") {
        if (!res?.payload.can_add) {
          
        }
      }
    });
  }, []);

  useEffect(() => {
    if (formType === "add") {
      return setBillingAddress([])
    }
    else if (customer_id !== "" && formType === "formView") {

      dispatch(getVendorBilling(token, customer_id)).then((res) => {
        setBillingAddress(res?.payload?.data)

        setBillID(res?.payload?.data[0].vendor_bill_to_id)


      })
      setShowTable(true);
    } else {
      setShowTable(false);
    }
  }, [customer_id, formType]);

  const hanldeSubItems = (record) => {
    setEventType("edit")
    if (formType === "edit") {
      setEditForm("edit");
      setBillID(record?.vendor_bill_to_id);
    }
    setVisible(true);
    form.setFieldsValue({
      addressee: record.addressee,
      address_1: record.address_1,
      address_2: record.address_2,
      address_3: record.address_3,
      email: record.email,
      fax: record.fax,
      phone: record.phone,
      zip_code: record.zip_code,
      city: record.country_id,
      state: record.state_id,
      country: record.country_id,
      is_active: record.is_active
    });

    dispatch(loadCity(token, record.country_id));
    dispatch(loadState(token, record.state_id));
  };

  const hanldeDeleteItems = (record) => {
    if (customer_id == "") {
      const dataSource = [...billingAddress]
      const filtered = dataSource.filter((item) => item.key !== record.key)
      setBillingAddress(filtered)
    } else {
      confirm({
        title: "Do you Want to delete these items?",
        icon: <ExclamationCircleOutlined />,
        content: "Delete Billing Record",
        okText: "Yes",
        okType: "danger",
        cancelText: "No",
        onOk() {
          setShowTable(true);

          dispatch(deleteVendorBiller(token, record.vendor_bill_to_id)).then(
            (res) => {
              if (res.type === "DELETE_VENDOR_BILLING_SUCCESS") {
                notification(
                  "success",
                  "Billing deleted",
                  "Billing deleted successfully!"
                );

                dispatch(getVendorBilling(token, customer_id)).then((res) => {
                  setBillingAddress(res?.payload?.data)
                })
              }
              if (res.type === "DELETE_VENDOR_BILLING_ERROR") {
                notification(
                  "error",
                  res?.payload?.request?.statusText,
                  res?.payload?.data?.data
                );
              }
            }
          );
          setcustomer_idForm(null);
          setBillID(null);
          setShowTable(true);
        },
        onCancel() {

        },
      });
    }
  };

  const onChangeCountry = (value, obj) => {
    setCountry(obj);
    dispatch(loadCity(token, `${value}`));
    dispatch(loadState(token, `${value}`));
  };

  const onChangeCity = (value, obj) => {
    setCity(obj);
  };

  const onChangeState = (value, obj) => {
    setState(obj);
  };

  const onSearch = (val) => {

  }

  const handleOk = async (e) => {
    form.setFieldsValue({ [e.target.name]: e.target.value });
    await form.validateFields().then((values) => {

      if (formType === "add" && eventType === "add") {



        const dummy = [
          {
            address_1: values.address_1,
            address_2: values.address_2,
            address_3: values.address_3,
            addressee: values.addressee,
            country_id: values.country,
            state_id: values.state,
            city_id: values.city,
            email: values.email,
            phone: values.phone,
            key: count,
            fax: values.fax,
            zip_code: values.zip_code,
            is_deleted: 0,
            is_active: values.is_active
          }]
        setBillingAddress([...billingAddress, {
          ...dummy[0], country_name: countryForm.children, country_id: countryForm.value,
          city_name: cityForm.children, city_id: cityForm.value, state_name: stateForm.children, state_id: stateForm.value
        }])
        addressDataBill([...billingAddress, dummy[0]])
        setCount(count + 1)
      }

      else if (formType === "edit" && eventType === "add") {



        const dummy = {
          address_1: values.address_1,
          address_2: values.address_2,
          address_3: values.address_3,
          addressee: values.addressee,
          country_id: values.country,
          state_id: values.state,
          city_id: values.city,
          email: values.email,
          phone: values.phone,
          fax: values.fax,
          zip_code: values.zip_code,
          is_deleted: 0,
        }
        const upodateValue = { ...dummy, vendor_id: customer_id }
        dispatch(addVendorBiller(token, upodateValue, "edit")).then((res) => {
          dispatch(getVendorBilling(token, customer_id)).then((res) => {
            setBillingAddress(res?.payload?.data)
          })

        })

      }

      else if (editForm === "edit" || billID !== null) {



        const update = {
          addressee: values.addressee,
          address_1: values.address_1,
          address_2: values.address_2,
          address_3: values.address_3,
          country_id: values.country,
          state_id: values.state,
          city_id: values.city,
          email: values.email,
          phone: values.phone,
          fax: values.fax,
          zip_code: values.zip_code,
        }


        dispatch(updateVendorBiller(token, update, billID)).then((res) => {
          if (res.type === "UPDATE_VENDOR_BILLING_SUCCESS") {
            dispatch(getVendorBilling(token, customer_id)).then((res) => {
              setBillingAddress(res?.payload?.data)
            })
          }
        })
      }
    });
    setVisible(false)

    form.setFieldsValue({
      addressee: null,
      address_1: null,
      address_2: null,
      address_3: null,
      email: null,
      fax: null,
      phone: null,
      zip_code: null,
      city: null,
      state: null,
      country: null,
      active: null
    })
  }


  const handleCancel = () => {
    setVisible(false);
    form.setFieldsValue({
      addressee: null,
      address_1: null,
      address_2: null,
      address_3: null,
      email: null,
      fax: null,
      phone: null,
      zip_code: null,
      city: null,
      state: null,
      country: null,
      active: null
    });
  };

  const getModalData = () => {
    dispatch(loadCountry(token))
    setEventType("add")
    setVisible(true)
  }

  
  
  
  
  return (
    <>
      <div>
        <Modal
          
          className="modal-40"
          visible={visible}
          title="Billing Form"
          okText="Submit"
          onOk={(data) => handleOk(data)}
          onCancel={handleCancel}>
          <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
            initialValues={{ modifier: "public" }}>


            <div className="container-fluid p-0 modal-form-main">
              <Brow>
                <Bcol lg={6} xl={6} xxl={6}>
                  <Form.Item name="addressee" label="Addressee " rules={[{ message: "addressee is required", required: true }]}
                  >
                    <Input size="small" className="b-radius-10" />
                  </Form.Item>
                </Bcol>
                <Bcol lg={6} xl={6} xxl={6}>
                  <Form.Item name="address_1" label="Address"  >
                    <Input size="small" className="b-radius-10" />
                  </Form.Item>
                </Bcol>
                <Bcol lg={6} xl={6} xxl={6}>
                  <Form.Item name="address_2" label="Address">
                    <Input size="small" className="b-radius-10" />
                  </Form.Item>
                </Bcol>
                <Bcol lg={6} xl={6} xxl={6}>
                  <Form.Item name="address_3" label="Address">
                    <Input size="small" className="b-radius-10" />
                  </Form.Item>
                </Bcol>
                <Bcol lg={6} xl={6} xxl={6}>
                  <Form.Item name="country" label="Country">
                    <Select
                      size="small"
                      
                      showSearch
                      
                      optionFilterProp="children"
                      value={countryForm.value}
                      onChange={onChangeCountry}
                      onSearch={onSearch}
                    
                    >
                      {country?.payload?.data &&
                        country?.payload?.data.map((country) => (
                          <Option value={country.country_id}>
                            {country.country_name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Bcol>
                <Bcol lg={6} xl={6} xxl={6}>
                  <Form.Item name="city" label="City">
                    <Select
                      size="small"
                      
                      showSearch
                      
                      optionFilterProp="children"
                      value={cityForm}
                      onChange={onChangeCity}
                      onSearch={onSearch}
                    
                    >
                      {City?.payload?.data &&
                        City?.payload?.data.map((city) => (
                          <Option value={city.city_id}>{city.city_name}</Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Bcol>
                <Bcol lg={6} xl={6} xxl={6}>
                  <Form.Item name="state" label="State">
                    <Select
                      size="small"
                      showSearch
                      
                      optionFilterProp="children"
                      
                      value={stateForm}
                      onChange={onChangeState}
                      onSearch={onSearch}
                    
                    >
                      {State?.payload?.data &&
                        State?.payload?.data.map((state) => (
                          <Option value={state.state_id}>{state.state_name}</Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Bcol>
                <Bcol lg={6} xl={6} xxl={6}>
                  <Form.Item
                    name="email"
                    label="Email"
                    rules={[{ type: "email", message: "The input is not valid E-mail!" }]}>
                    <Input size="small" className="b-radius-10" />
                  </Form.Item>
                </Bcol>
                <Bcol lg={6} xl={6} xxl={6}>
                  <Form.Item name="fax" label="Fax">
                    <Input size="small" className="b-radius-10" />
                  </Form.Item>
                </Bcol>
                <Bcol lg={6} xl={6} xxl={6}>
                  <Form.Item
                    name="phone"
                    label="Phone" rules={[{ pattern: new RegExp(/^[0-9\b]+$/), message: "Must be a Number" }]}>
                    <Input size="small" className="b-radius-10" />
                  </Form.Item>
                </Bcol>
                <Bcol lg={6} xl={6} xxl={6}>
                  <Form.Item
                    name="zip_code"
                    label="Zip"
                    rules={[{ pattern: new RegExp(/^[0-9\b]+$/), message: "Must be a Number", required: true }]}>
                    <Input size="small" className="b-radius-10" />
                  </Form.Item>
                </Bcol>
                <Bcol lg={6} xl={6} xxl={6}>
                  <Form.Item
                    label="Active"
                    name="is_active"

                  >
                    <Checkbox className="styleInput" checked defaultChecked />
                  </Form.Item>
                </Bcol>
              </Brow>
            </div>

          </Form>
        </Modal>
      </div >

      <Button
        disabled={!(formType === "add" || formType === "edit")}
        className="iconBtn crudBTN float-right mbpx-5"
        shape="circle"
        icon={<PlusOutlined />}
        onClick={() => getModalData()}
      />
      <Tables
        type="billing"
        billingObj={setBillingObj}
        scroll={600}
        scrollX={"45rem"}
        
        source={billingAddress}
        col={billingColumns}
        load={vendorBill.loading}
        paginate
      />
      {/* </div> */}
    </>
  );
}
