import {
    GET_GOOD_REQUIRED_REPORT_REQUEST,
    GET_GOOD_REQUIRED_REPORT_SUCCESS,
    GET_GOOD_REQUIRED_REPORT_ERROR

} from "./types";
import axios from "axios";
import { BASEURL } from "../../constant/config.js";
import moment from "moment";


export const getGoodRequired = (token, data, isChecked2) => (dispatch) => {
   
    dispatch({ type: GET_GOOD_REQUIRED_REPORT_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/GoodRequired`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: {
            product_status_id: isChecked2 !== undefined && isChecked2.length > 0 ? isChecked2.toString() : null,
            sku: data ? data.sku : null,
            gender_id: data ? data.gender_id : null,
            to_date: data ? moment(data.to_date).format("MM-DD-YYYY") : moment().endOf("year").format("MM-DD-YYYY")
        },
    })
        .then((response) => {
            return dispatch({
                type: GET_GOOD_REQUIRED_REPORT_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) => {
            dispatch({
                type: GET_GOOD_REQUIRED_REPORT_ERROR,
                payload: [],
            });
            
            
            
            
            
            
            
            
            
            
        });
};
