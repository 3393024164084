import React, { useState, useEffect, useLayoutEffect } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { deleteItemTransferHeader, getOneItemTransferHeader, getItemTransferFormData } from "../../redux/actions/itemTransfer.action";
import CrudButtons from "./components/CrudButtons";
import ItemTransferForm from "./components/Form/itemTransferForm";
import PageHeader from "../../components/PageHeader/PageHeader";
import { Button } from "antd";
import { ReadCookie } from "../../utils/readCookie";
import notification from "../../utils/notification";
import "../../screens/Product/product.css";
import "../Style/style.css";
import { getRights } from "../../redux/actions/userRights.action";
import { TabTitle } from "../../utils/GeneralFunction";
import { getTransationLock } from "../../redux/actions/transactionLock.action";
import moment from "moment";
export default function ItemTransfer({ token }) {
	TabTitle("Inventory Transfer")
	const dispatch = useDispatch();
	const history = useHistory();
	const { id } = useParams();

	const dCompanyId = ReadCookie("defaultCompany");
	const { log, itemTransfer } = useSelector((state) => state);
	const [minimize, setMinimize] = useState(false);
	const [formState, setFormState] = useState("view");
	const [flag, setFlag] = useState(false);

	const [companyId, setCompanyId] = useState(ReadCookie("defaultCompany"));
	const [disable, setDisable] = useState(false);
	const [submit, setSubmit] = useState(false);
	const queryParams = new URLSearchParams(window.location.search);
	const mode = queryParams.get("mode");
	const [userRights, setUserRights] = useState({})


	const [transactionLock, setTransactionLock] = useState(false)

	useLayoutEffect(() => {
		dispatch(getRights(token, 195)).then((res) => {

			if (res.type === "GET_USER_RIGHTS_SUCCESS") {
				setUserRights(res?.payload)
			}
		});
	}, [token])

	const filterReset = () => {
		setFormState("view");
	};

	const handleDelete = () => {
		dispatch(deleteItemTransferHeader(token, id)).then((res) => {
			if (res.type === "DELETE_ITEM_TRANSFER_HEADER_SUCCESS") {
				notification("success", "Item Transfer deleted", res.payload);
				setFlag(true);
				history.push("/item-transfer/list");
			}
		});
	};

	const formSave = () => {
		setSubmit(true);
		setDisable(true)
	};
	useEffect(() => {
		if (mode === "add") {
			setFormState("add");
		}
	}, [mode]);

	useEffect(() => {
		dispatch(getItemTransferFormData(token));
	}, []);

	useEffect(() => {
		if (id) {
			setFormState("formView");
			dispatch(getOneItemTransferHeader(token, id)).then((res) => {
				if (res?.type === "GET_ONE_ITEM_TRANSFER_HEADER_SUCCESS") {
					if (res?.payload?.data[0]?.transfer_date) {
						let transaction_date = moment(res?.payload.data[0]?.transfer_date).format("YYYY-MM-DD");
						dispatch(getTransationLock(token, transaction_date)).then((r) => {
							if (r.payload.data.length > 0) {
								setTransactionLock(true);
							}
						});
					}
				}
			});
		}
	}, [id]);

	return (
		<>
			<div className="styleContainer">
				<div className="styleBody h-100">
					<PageHeader title={"Item Transfers"} minimize={setMinimize} check={minimize} />
					<div className="bodyStyle">
						<div className="crudDiv">
							<CrudButtons
								id={id}
								name={"saleOrder"}
								reset={filterReset}
								userRights={userRights}
								formType={formState}
								save={formSave}
								disable={disable}
								formState={setFormState}
								onDelete={handleDelete}
								saleOrderID={id}
								formData={itemTransfer?.payload?.data}
								transactionLock={transactionLock}
							/>
							<div className="mainProductContainer">
								<ItemTransferForm
									setDisable={setDisable}
									dCompany={dCompanyId}
									defaultCompany={companyId}
									companyId={setCompanyId}
									submit={setSubmit}
									val={submit}
									formData={formState === "formView" || formState === "edit" ? itemTransfer?.payload?.data[0] : null}
									formState={formState}
									changeState={setFormState}
									token={token}
									checkFlag={flag}
									setFlag={setFlag}
									logs={log}
									saleOrderID={id}
								/>
							</div>
						</div>
					</div>
				</div>
				{minimize && (
					<div className="styleFooter">
						{" "}
						<Button className="minimize-button-style" onClick={() => setMinimize(false)}>
							{" "}
							Item Transfer{" "}
						</Button>{" "}
					</div>
				)}
			</div>
		</>
	);
}
