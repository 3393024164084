import {
  GET_ACCOUNT_TYPE_REQUEST,
  GET_ACCOUNT_TYPE_ERROR,
  GET_ACCOUNT_TYPE_SUCCESS,
  ADD_ACCOUNT_TYPE_ERROR,
  ADD_ACCOUNT_TYPE_REQUEST,
  ADD_ACCOUNT_TYPE_SUCCESS,
  UPDATE_ACCOUNT_TYPE_ERROR,
  UPDATE_ACCOUNT_TYPE_REQUEST,
  UPDATE_ACCOUNT_TYPE_SUCCESS,
  DELETE_ACCOUNT_TYPE_ERROR,
  DELETE_ACCOUNT_TYPE_REQUEST,
  DELETE_ACCOUNT_TYPE_SUCCESS,
} from "../actions/types";

export default function (state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case GET_ACCOUNT_TYPE_REQUEST:
      return {
        loading: true,
      };
    case GET_ACCOUNT_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        payload,
      };
    case GET_ACCOUNT_TYPE_ERROR:
      return {
        ...state,
        loading: false,
        payload,
      };
    case ADD_ACCOUNT_TYPE_REQUEST:
      return {
        loading: true,
      };
    case ADD_ACCOUNT_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: [payload],
      };
    case ADD_ACCOUNT_TYPE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_ACCOUNT_TYPE_REQUEST:
      return {
        loading: true,
      };
    case UPDATE_ACCOUNT_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: [payload],
      };
    case UPDATE_ACCOUNT_TYPE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case DELETE_ACCOUNT_TYPE_REQUEST:
      return {
        loading: true,
      };
    case DELETE_ACCOUNT_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: [payload],
      };
    case DELETE_ACCOUNT_TYPE_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
