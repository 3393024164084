import {
	GET_ALL_CREDIT_NOTE_INVOICE_REQUEST, GET_ALL_CREDIT_NOTE_INVOICE_SUCCESS, GET_ALL_CREDIT_NOTE_INVOICE_ERROR,
	CREATE_CREDIT_NOTE_INVOICE_REQUEST, CREATE_CREDIT_NOTE_INVOICE_SUCCESS, CREATE_CREDIT_NOTE_INVOICE_ERROR,
} from "./types";

import { BASEURL } from "../../constant/config.js";
const axios = require("axios");

export const creaditNoteInvoice = (token, object) => (dispatch) => {
	dispatch({ type: GET_ALL_CREDIT_NOTE_INVOICE_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}getAll/funcGetCreditNoteForInvoiceKnockof`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: object,
	})
		.then((response) => {
			return dispatch({
				type: GET_ALL_CREDIT_NOTE_INVOICE_SUCCESS,
				payload: response?.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_ALL_CREDIT_NOTE_INVOICE_ERROR,
				payload: error.response,
			});
		});
};


export const createCreaditNoteInvoice = (token, object) => (dispatch) => {
	dispatch({ type: CREATE_CREDIT_NOTE_INVOICE_REQUEST });



	return axios({
		method: "POST",
		url: `${BASEURL}create/payCustomerPaymentNote`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: { data: object },
	})
		.then((response) => {
			return dispatch({
				type: CREATE_CREDIT_NOTE_INVOICE_SUCCESS,
				payload: response?.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: CREATE_CREDIT_NOTE_INVOICE_ERROR,
				payload: error.response,
			});
		});
};