import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Form, Select, DatePicker, Spin, Table } from "antd";
import { useLocation, Link, useHistory, useParams } from "react-router-dom";
import { getSaleTargetReport, getSaleComparisionReport, getSaleComparisionCustomerWiseReport } from "../../redux/actions/saleTargetReport.action.js";
import { getCustomerType } from "../../redux/actions/customerType.action";
import { getAllSaleperson } from "../../redux/actions/salesPerson";
import { getReportIP } from "../../redux/actions/reportIP.action";
import { getGender } from "../../redux/actions/gender.action";
import { getRegion } from "../../redux/actions/region.action";
import { ReadCookie } from "../../utils/readCookie";
import numeral from "numeral";

import moment from "moment";

import logo from "../../assets/images/logo.png";
import xlsx from "../../assets/images/ico_xls_sm.gif";
import "../ProducList/productList.css";
import { TabTitle } from "../../utils/GeneralFunction.js";
import { useDownloadExcel } from "react-export-table-to-excel";
import { getAllActiveCustomerType, getAllActiveRegion, getAllActiveSaleperson } from "../../redux/actions/getAllActive.js";
import { format } from "crypto-js";

const SalesTargetRetport = ({ token }) => {
  TabTitle("Sale Target")
  const { gender, region, saleTargetReport, saleComparisionReport, saleComparisionCustomerWiseReport, salePersonNew, customerTypeNew, RegionNew } = useSelector((state) => state);
  const [salePersonName, setSalePersonName] = useState(null);
  const [customerType, setCustomerType] = useState(null);
  const [genderID, setGenderID] = useState(null);
  const [regionID, setRegionID] = useState(null);
  const menuId = ReadCookie("menuId");
  const userName = ReadCookie("name");
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { Option } = Select;
  const history = useHistory();
  var date = moment();

  const [dateTypeId, setDateTypeId] = useState("mte");
  const [monthId, setMonthId] = useState(date.format("MM"));
  const [yearId, setYearId] = useState(date.format("Y"));
  const [currentYearStartDate, setCurrentYearStartDate] = useState(moment().startOf("year").format("MM/DD/YYYY"));
  const [currentYearEndDate, setCurrentYearEndDate] = useState(moment().format("MM/DD/YYYY"));
  const [lastYearStartDate, setLastYearStartDate] = useState(moment().startOf("year").subtract(1, "year").format("MM/DD/YYYY"));
  const [lastYearEndDate, setLastYearEndDate] = useState(moment().subtract(1, "year").format("MM/DD/YYYY"));
  const currentYearStartDateYte = moment().startOf("year").format("MM/DD/YYYY");
  const currentYearEndDateYte = moment().endOf("year").format("MM/DD/YYYY");
  const currentYearEndDateYtd = moment().format("MM/DD/YYYY");
  const lastYearStartDateYte = moment().startOf("year").subtract(1, "year").format("MM/DD/YYYY");
  const lastYearEndDateYte = moment().endOf("year").subtract(1, "year").format("MM/DD/YYYY");
  const lastYearEndDateYtd = moment().subtract(1, "year").format("MM/DD/YYYY");
  const currentYearStartDateMte = monthId + "/" + "01" + "/" + yearId;
  const currentYearEndDateMte = monthId + "/" + moment(`${yearId} / ${monthId}`, "YYYY/MM").daysInMonth() + "/" + yearId;
  const lastYearStartDateMte = monthId + "/" + "01" + "/" + (yearId - 1);
  const lastYearEndDateMte = monthId + "/" + moment(`${yearId - 1} / ${monthId}`, "YYYY/MM").daysInMonth() + "/" + (yearId - 1);
  const [formState, setFormState] = useState({});
  const [loading, setLoading] = useState(false);
  const { submited } = useParams();

  const tableRef = useRef(null);
  const tableRef2 = useRef(null);
  const tableRef3 = useRef(null);


  const { onDownload: onDownloadReport1 } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Sales Target Report",
    sheet: "Sales Target Report",
  });

  const { onDownload: onDownloadReport2 } = useDownloadExcel({
    currentTableRef: tableRef2.current,
    filename: "Sales Target Monthly",
    sheet: "Sales Target Monthly Sheet",
  });
  const { onDownload: onDownloadReport3 } = useDownloadExcel({
    currentTableRef: tableRef3.current,
    filename: "Sales Target Detail",
    sheet: "Sales Target Detail Sheet",
  });


  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();
  let check = query.get("submited");
  let cy_start_date = query.get("cy_start_date");
  let cy_end_date = query.get("cy_end_date");
  let ly_start_date = query.get("ly_start_date");
  let ly_end_date = query.get("ly_end_date");
  let region_id = query.get("region_id");
  let gender_id = query.get("gender_id");
  let customerTypeID = query.get("customer_type_id");
  let salePersonId = query.get("salesperson_id");
  let selected_id = query.get("selected_id");
  let report = query.get("report");
  let condition = query.get("condition");




  const inputDate = formState?.cy_end_date;
  const inputFormat = 'MM/DD/YYYY';
  const momentDate = moment(inputDate, inputFormat);
  momentDate.month(11);
  momentDate.date(31);
  const cyEndDate = momentDate.format(inputFormat);

  


  const inputDate1 = formState?.ly_end_date;
  const momentDate1 = moment(inputDate1, inputFormat);
  momentDate1.month(11);
  momentDate1.date(31);
  const LyEndDate = momentDate1.format(inputFormat);

  

  const inputDate2 = formState?.cy_start_date;
  const momentDate2 = moment(inputDate2, inputFormat);
  momentDate2.month(0);
  momentDate2.date(1);
  const cyStartDate = momentDate2.format(inputFormat);


  
  const inputDate3 = formState?.ly_start_date;
  const momentDate3 = moment(inputDate3, inputFormat);
  momentDate3.month(0);
  momentDate3.date(1);
  const LyStartDate = momentDate3.format(inputFormat);

  

  const columns = [
    {
      title: "#",

      render: (text, record, index) => {
        return index + 1
      },
    },
    {
      title: "Sales Person",
      dataIndex: "salerperson_name",
      key: "salesperson_id",

      className: " b_left",
      sorter: (a, b) => {
        return a.salerperson_name.localeCompare(b.salerperson_name)
      },
    },
    {
      title: "Region Name",
      dataIndex: "region_name",
      key: "region_id",
   
      className: " b_left",
      render: (text, record) => {
        return (
          <>
            <Link
              style={{ color: "blue", textDecoration: "underline" }}
              target="_blank" rel="noopener noreferrer"
              to={{
                pathname: `/sales-target-report`,
                search: `?submited=${true}&report=${3}&region_id=${record.region_id}&gender_id=${record.gender_id}&customer_type_id=${formState.hasOwnProperty("customer_type_id") ? formState.customer_type_id : formState?.customerTypeID}&cy_start_date=${formState.hasOwnProperty("cy_start_date") ? formState.cy_start_date : formState?.currentYearStartDate}&cy_end_date=${formState.hasOwnProperty("cy_end_date") ? formState.cy_end_date : formState?.currentYearEndDate}&ly_start_date=${formState.hasOwnProperty("ly_start_date") ? formState.ly_start_date : formState?.lastYearStartDate}&ly_end_date=${formState.hasOwnProperty("ly_end_date") ? formState.ly_end_date : formState?.lastYearEndDate}&condition=${dateTypeId}`,
              }}
            >
              {record.region_name}
            </Link>
          </>
        );
      },
      sorter: (a, b) => {
        return a.region_name.localeCompare(b.region_name)
      },
    },
    {
      title: "LY",
      children: [
        {
          title: "Shipped ",
          dataIndex: "shipped_amount_ly",
          key: "shipped_amount_ly",
        
          className: "b_left b_right_none",
          align: "right",
          render: (text, record) => {
       
            return {
            
              children: <div>${
                numeral(record.shipped_amount_ly).format("0,00")
              }</div>
            };
           
          },
          sorter: (a, b) => {
            return a.shipped_amount_ly - b.shipped_amount_ly
          },
        },
        {
          title: "Return",
          dataIndex: "returned_amount_ly",
          key: "returned_amount_ly",
         
          align: "right",
          className: "b_left",
          render: (text, record) => {
           
            return {
              
              children: <div>
                ${
                  numeral(record.returned_amount_ly).format("0,00")
                }
               
              </div>
            }
          },
          sorter: (a, b) => {
            return a.returned_amount_ly - b.returned_amount_ly
          },
        },
        {
          title: "Net",
          
          align: "right",
          className: "b_right b_left ",

          render: (text, record) => {
            return {
              props: {
                style: { background: '#E8F2FC' }
              },
              children:
                <div>
                  ${
                    numeral(record.shipped_amount_ly - record.returned_amount_ly).format("0,00")
                  }
                </div>
            }
          },
          sorter: (a, b) => {
            return (a.shipped_amount_ly - a.returned_amount_ly) - (b.shipped_amount_ly - b.returned_amount_ly)
          },
        },
      ],
    },
    {
      title: "PLAN",
      children: [
        {
          title: "PLAN",
          dataIndex: "plan_amount",
          key: "plan_amount",
          align: "right",
          className: "b_left b_right_none",
          
          render: (text, record) => {
            return {
              props: {
                style: { background: '#fffadf' }
              },
              children:
                <div>
                  <Link
                    style={{ color: "blue", textDecoration: "underline" }}
                    target="_blank" rel="noopener noreferrer"
                    to={{
                      pathname: `/sales-target-report`,
                      search: `?submited=${true}&report=${2}&region_id=${record.region_id}&gender_id=${record.gender_id}&customer_type_id=${formState.hasOwnProperty("customer_type_id") ? formState.customer_type_id : formState?.customerTypeID}&cy_start_date=${cyStartDate}&cy_end_date=${cyEndDate}&ly_start_date=${LyStartDate}&ly_end_date=${LyEndDate}&condition=${dateTypeId}`,
                    }}
                  >
                    ${numeral(record.plan_amount).format("0,00")}
                  </Link>
                </div>
            }
          },
          sorter: (a, b) => {
            return a.plan_amount - b.plan_amount
          },
        },
        {
          title: "PL/LY",
          dataIndex: "pl_ly",
          key: "pl_ly",
          align: "right",
       
          className: "b_left",
          render: (text, record) => {
            return {
              props: {
                style: { background: '#fffadf' }
              },
              children:
                <div>
                  {numeral(((record.plan_amount / (record.shipped_amount_ly - record.returned_amount_ly)) - 1) * 100).format("0") === "NaN" ? 0 :
                    numeral(((record.plan_amount / (record.shipped_amount_ly - record.returned_amount_ly)) - 1) * 100).format("0")
                  }%
                </div>
            }
          },
          sorter: (a, b) => {
            return a.pl_ly - b.pl_ly
          },
        },
      ],
    },
    {
      title: "TY NET",
      children: [
        {
          title: "Shipped ",
          dataIndex: "shipped_amount_cy",
          key: "shipped_amount_cy",
          align: "right",
       
          className: "b_left b_right_none",
          render: (text, record) => {
            return (
              <>
                ${
                  numeral(record.shipped_amount_cy).format("0,00")
                }
              </>
            );
          },
          sorter: (a, b) => {
            return a.shipped_amount_cy - b.shipped_amount_cy
          },
        },
        {
          title: "Return",
          dataIndex: "returned_amount_cy",
          key: "returned_amount_cy",
          align: "right",
  
          className: " b_left",
          render: (text, record) => {
            return (
              <>
                ${
                  numeral(record.returned_amount_cy).format("0,00")
                }
              </>
            );
          },
          sorter: (a, b) => {
            return a.returned_amount_cy - b.returned_amount_cy
          },
        },
        {
          title: "Net",
      
          align: "right",
          className: " b_left",
          render: (text, record) => {
            return {
              props: {
                style: { background: '#E8F2FC', }
              },
              children:
                <div>
                  ${
                    numeral(record.shipped_amount_cy - record.returned_amount_cy).format("0,00")
                  }
                </div>
            }
          },
          sorter: (a, b) => {
            return (a.shipped_amount_cy - a.returned_amount_cy) - (b.shipped_amount_cy - b.returned_amount_cy)
          },
        },
        {
          title: "% PLAN",
          dataIndex: "percent_plan_ty",
          key: "percent_plan_ty",
          align: "right",
        
          className: " b_left",
          render: (text, record) => {
            return (
              <>
                {(record.percent_plan_ty)}%
              </>
            );
          },
          sorter: (a, b) => {
            return a.percent_plan_ty - b.percent_plan_ty
          },
        },
        {
          title: "$ Miss Plan",
  
          align: "right",
          className: " b_left",
          render: (text, record) => {
            return {
              props: {
                style: { background: '#fffadf' }
              },
              children:
                <div>
                  {
                    numeral(record.miss_plan_ty).format("0,00")
                  }
                </div>
            }
          },
          sorter: (a, b) => {
            return a.miss_plan_ty - b.miss_plan_ty
          },
        },
      ],
    },
    {
      title: "TY Total Sales",
      children: [
        {
          title: "POD",
          dataIndex: "pod_amount",
          key: "pod_amount",
          align: "right",
  
          className: "b_left b_right_none",
          render: (text, record) => {
            return (
              <>
                ${
                  numeral(record.pod_amount).format("0,00")
                }
              </>
            );
          },
          sorter: (a, b) => {
            return a.pod_amount - b.pod_amount
          },
        },
        {
          title: "Pending",
          dataIndex: "pending_amount",
          key: "pending_amount",
          align: "right",
  
          className: " b_left",
          render: (text, record) => {
            return (
              <>
                ${
                  numeral(record.pending_amount).format("0,00")
                }
              </>
            );
          },
          sorter: (a, b) => {
            return a.pending_amount - b.pending_amount
          },
        },
        {
          title: "Total Sales",
          dataIndex: "total_sales",
          key: "total_sales",
          align: "right",
      
          className: " b_left",
          render: (text, record) => {
            return {
              props: {
                style: { background: '#E8F2FC' }
              },
              children:
                <div>
                  ${
                    numeral(record.total_sales).format("0,00")
                  }
                </div>
            }
          },
          sorter: (a, b) => {
            return a.total_sales - b.total_sales
          },
        },
        {
          title: "% PLAN",
          dataIndex: "total_sale_percent_plan",
          key: "total_sale_percent_plan",
        
          align: "right",
          className: " b_left",
          render: (text, record) => {
            return (
              <>
                {(record.total_sale_percent_plan)}%
              </>
            );
          },
          sorter: (a, b) => {
            return a.total_sale_percent_plan - b.total_sale_percent_plan
          },
        },
        {
          title: "$ Miss Plan",
          dataIndex: "total_sale_miss_plan",
          key: "total_sale_miss_plan",
      
          align: "right",
          className: " b_left",
          render: (text, record) => {
            return {
              props: {
                style: { background: '#fffadf' }
              },
              children:
                <div>
                  {
                    numeral(record.total_sale_miss_plan).format("0,00")
                  }
                </div>
            }
          },
          sorter: (a, b) => {
            return a.total_sale_miss_plan - b.total_sale_miss_plan
          },
        },

      ],
    },
    {
      title: "TY/LY",
      dataIndex: "ty_ly",
      key: "ty_ly",
   
      align: "right",
      className: "b_left",
      render: (text, record) => {
        return (
          <>
            {
              numeral(record.ty_ly).format("00")
            }%
          </>
        );
      },
      sorter: (a, b) => {
        return a.ty_ly - b.ty_ly
      },
    },
  ];

  const columns2 = [
    {
      title: "#",

      render: (text, record, index) => {
        return index + 1
      },
    },

    {
      title: "Months",
      dataIndex: "tranmonth",
      key: "tranmonth",
      className: "b_left",
    
      sorter: (a, b) => {
        return a.tranmonth.localeCompare(b.tranmonth)
      },
    },
    {
      title: "LY",
      children: [
        {
          title: "Shipped ",
          dataIndex: "shipped_amount_ly",
          key: "shipped_amount_ly",
       
          align: "right",
          className: "b_left",
          render: (text, record) => {
            return (
              <>
                ${
                  numeral(record.shipped_amount_ly).format("0,00")
                }
              </>
            );
          },
          sorter: (a, b) => {
            return a.shipped_amount_ly - b.shipped_amount_ly
          },
        },
        {
          title: "Return",
          dataIndex: "returned_amount_ly",
          key: "returned_amount_ly",
        
          align: "right",
          className: "b_left",
          render: (text, record) => {
            return (
              <>
                ${
                  numeral(record.returned_amount_ly).format("0,00")
                }
              </>
            );
          },
          sorter: (a, b) => {
            return a.returned_amount_ly - b.returned_amount_ly
          },
        },
        {
          title: "Net",
       
          align: "right",
          className: "b_left",
          render: (text, record) => {
            return {
              props: {
                style: { background: '#E8F2FC' }
              },
              children:
                <div>
                  ${
                    numeral(record.shipped_amount_ly - record.returned_amount_ly).format("0,00")
                  }
                </div>
            }
          },
          sorter: (a, b) => {
            return (a.shipped_amount_ly - a.returned_amount_ly) - (b.shipped_amount_ly - b.returned_amount_ly)
          },
        },
      ],
    },
    {
      title: "PLAN",
      children: [
        {
          title: "PLAN",
          dataIndex: "plan_amount",
          key: "plan_amount",
          align: "right",
       
          className: "b_left",
          render: (text, record) => {
            return {
              props: {
                style: { background: '#fffadf' }
              },
              children:
                <div>
               
                  ${numeral(record.plan_amount).format("0,00")}
                </div>
            }
          },
          sorter: (a, b) => {
            return a.plan_amount - b.plan_amount
          },
        },
        {
          title: "PL/LY",
          dataIndex: "pl_ly",
          key: "pl_ly",
          align: "right",
          className: "b_left",
       
          render: (text, record) => {
            return {
              props: {
                style: { background: '#fffadf' }
              },
              children:
                <div className={record.pl_ly < 0 ? 'color_red' : ''}>
                  {
                    numeral(record.pl_ly).format("00.00")
                  }%
                </div>
            }
          },
          sorter: (a, b) => {
            return a.pl_ly - b.pl_ly
          },
        },
      ],
    },
    {
      title: "NET SHIP",
      children: [
        {
          title: "Shipped ",
          dataIndex: "shipped_amount_cy",
          key: "shipped_amount_cy",
          align: "right",
        
          className: "b_left",
          render: (text, record) => {
            return (
              <>
                ${
                  numeral(record.shipped_amount_cy).format("0,00")
                }
              </>
            );
          },
          sorter: (a, b) => {
            return a.shipped_amount_cy - b.shipped_amount_cy
          },
        },
        {
          title: "Return",
          dataIndex: "returned_amount_cy",
          key: "returned_amount_cy",
          align: "right",
          className: "b_left",
        
          render: (text, record) => {
            return (
              <>
                ${
                  numeral(record.returned_amount_cy).format("0,00")
                }
              </>
            );
          },
          sorter: (a, b) => {
            return a.returned_amount_cy - b.returned_amount_cy
          },
        },

        {
          title: "Net",
          align: "right",
          className: "b_left",
          render: (text, record) => {
            return {
              props: {
                style: { background: '#E8F2FC' }
              },
              children:
                <div>
                  ${
                    numeral(record.shipped_amount_cy - record.returned_amount_cy).format("0,00")
                  }
                </div>
            }
          },
          sorter: (a, b) => {
            return (a.shipped_amount_cy - a.returned_amount_cy) - (b.shipped_amount_cy - b.returned_amount_cy)
          },
        },
        {
          title: "% PLAN",
          dataIndex: "percent_plan_ty",
          key: "percent_plan_ty",
          align: "right",
          className: "b_left",
          render: (text, record) => {
            return (
              <>
                {(record.percent_plan_ty)}%
              </>
            );
          },
          sorter: (a, b) => {
            return a.percent_plan_ty - b.percent_plan_ty
          },
        },
        {
          title: "$ Miss Plan",
          align: "right",
          className: "b_left",
          render: (text, record) => {
            return {
              props: {
                style: { background: '#fffadf' }
              },
              children:
                <div className={record.miss_plan_ty < 0 ? 'color_red' : ''}>
                  {
                    numeral(record.miss_plan_ty).format("0,00")
                  }
                </div>
            }
          },
          sorter: (a, b) => {
            return a.miss_plan_ty - b.miss_plan_ty
          },
        },
      ],
    },
    {
      title: "TOTAL SALES",
      children: [
        {
          title: "POD",
          dataIndex: "pod_amount",
          key: "pod_amount",
          align: "right",
          className: "b_left",
          render: (text, record) => {
            return (
              <>
                ${
                  numeral(record.pod_amount).format("0,00")
                }
              </>
            );
          },
          sorter: (a, b) => {
            return a.pod_amount - b.pod_amount
          },
        },
        {
          title: "Pending",
          dataIndex: "pending_amount",
          key: "pending_amount",
          className: "b_left",
          align: "right",
          render: (text, record) => {
            return (
              <>
                ${
                  numeral(record.pending_amount).format("0,00")
                }
              </>
            );
          },
          sorter: (a, b) => {
            return a.pending_amount - b.pending_amount
          },
        },
        {
          title: "Total Sales",
          dataIndex: "total_sales",
          key: "total_sales",
          align: "right",
          className: "b_left",
          render: (text, record) => {
            return {
              props: {
                style: { background: '#E8F2FC' }
              },
              children:
                <div>
                  ${
                    numeral(record.total_sales).format("0,00")
                  }
                </div>
            }
          },
          sorter: (a, b) => {
            return a.total_sales - b.total_sales
          },
        },
        {
          title: "% Miss Plan",
          dataIndex: "total_sale_percent_plan",
          key: "total_sale_percent_plan",
          align: "right",
          className: "b_left",
          render: (text, record) => {
            return (
              <>
                {(record.total_sale_percent_plan)}%
              </>
            );
          },
          sorter: (a, b) => {
            return a.total_sale_percent_plan - b.total_sale_percent_plan
          },
        },
        {
          title: "$ Miss Plan",
          dataIndex: "total_sale_miss_plan",
          key: "total_sale_miss_plan",
          align: "right",
          className: "b_left",
          render: (text, record) => {
            return {
              props: {
                style: { background: '#fffadf' }
              },
              children:
                <div className={record.total_sale_miss_plan < 0 ? 'color_red' : ''}>
                  {
                    numeral(record.total_sale_miss_plan).format("0,00")
                  }
                </div>
            }
          },
          sorter: (a, b) => {
            return a.total_sale_miss_plan - b.total_sale_miss_plan
          },
        },

      ],
    },
    {
      title: "TY/LY",
      dataIndex: "ty_ly",
      key: "ty_ly",
      align: "right",
      className: "b_left",
      render: (text, record) => {
        return (
          <div className={record.ty_ly < 0 ? 'color_red' : ''}>
            {
              numeral(record.ty_ly).format("0.00")
            }%
          </div>
        );
      },
      sorter: (a, b) => {
        return a.ty_ly - b.ty_ly
      },
    },
  ];

  const totalTTLSLS = saleComparisionCustomerWiseReport?.payload?.data.reduce((acc, item, index) => acc + parseFloat(item.ty_yte_ttl), 0)

  

  const columns3 = [
    {
      title: "#",
      render: (text, record, index) => {
        return index + 1
      },
    },
    {
      title: "Customer",
      dataIndex: "customer_name",
      key: "customer_name",
      className: "b_left",
      sorter: (a, b) => {
        return a.customer_name.localeCompare(b.customer_name)
      },
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
      className: "b_left",
      sorter: (a, b) => {
        return a.state.localeCompare(b.state)
      },
    },
    {
      title: "% TTL",
      dataIndex: "ttl",
      key: "ttl",
      align: "right",
      className: "b_left",
      render: (text, record) => {
        return (
          <>
            {parseFloat(
              numeral((record.ty_yte_ttl / totalTTLSLS) * 100).format("0,00"),
            )}%
          </>
        );
      },
      sorter: (a, b) => {
        return a.ttl - b.ttl
      },
    },
    {
      title: "LY - NET SHP",
      children: [
        {
          title: "MTE ",
          dataIndex: "ly_mte",
          key: "ly_mte",
          align: "right",
          className: "b_left",
          render: (text, record) => {
            return {
              props: {
                style: { background: '#E8F2FC' }
              },
              children:
                <div className={record.ly_mte < 0 ? 'color_red' : ''}>
                  ${
                    numeral(record.ly_mte).format("0,00")
                  }
                </div>
            }
          },
          sorter: (a, b) => {
            return a.ly_mte - b.ly_mte
          },
        },
        {
          title: "YTD",
          dataIndex: "ly_ytd",
          key: "ly_ytd",
          align: "right",
          className: "b_left",
          render: (text, record) => {
            return {
              props: {
                style: { background: '#fffadf' }
              },
              children:
                <div className={record.ly_ytd < 0 ? 'color_red' : ''}>
                  ${
                    numeral(record.ly_ytd).format("0,00")
                  }
                </div>
            }
          },
          sorter: (a, b) => {
            return a.ly_ytd - b.ly_ytd
          },
        },
        {
          title: "YTE",
          dataIndex: "ly_yte",
          key: "ly_yte",
          align: "right",
          className: "b_left",
          render: (text, record) => {
            return {
              props: {
                style: { background: '#e4ffdf' }
              },
              children:
                <div className={record.ly_yte < 0 ? 'color_red' : ''}>
                  ${
                    numeral(record.ly_yte).format("0,00")
                  }
                </div>
            }
          },
          sorter: (a, b) => {
            return a.ly_yte - b.ly_yte
          },
        },
      ],
    },
    {
      title: "TY - MTE",
      children: [
        {
          title: "NET SHP",
          dataIndex: "ty_mte_net_amount",
          key: "ty_mte_net_amount",
          align: "right",
          className: "b_left",
          render: (text, record) => {
            return (
              <div className={record.ty_mte_net_amount < 0 ? 'color_red' : ''}>
                ${numeral(record.ty_mte_net_amount).format("0,00")}
              </div>
            );
          },
          sorter: (a, b) => {
            return a.ty_mte_net_amount - b.ty_mte_net_amount
          },
        },
        {
          title: "TTL SLS",
          dataIndex: "ty_mte_ttl",
          key: "ty_mte_ttl",
          align: "right",
          className: "b_left",
          render: (text, record) => {
            return {
              props: {
                style: { background: '#E8F2FC' }
              },
              children:
                <div className={record.ty_mte_ttl < 0 ? 'color_red' : ''}>
                  ${
                    numeral(record.ty_mte_ttl).format("0,00")
                  }
                </div>
            }
          },
          sorter: (a, b) => {
            return a.ty_mte_ttl - b.ty_mte_ttl
          },
        },
        {
          title: "TY/LY",
          dataIndex: "ty_mte_difference",
          key: "ty_mte_difference",
          align: "right",
          className: "b_left",
          render: (text, record) => {
            return (
              <div className={record.ty_mte_difference < 0 ? 'color_red' : ''}>
                {parseFloat(
                  numeral(record.ty_mte_difference).format("00.00"),
                )}%
              </div>
            );
          },
          sorter: (a, b) => {
            return a.ty_mte_difference - b.ty_mte_difference
          },
        },
      ],
    },
    {
      title: "TY YTD",
      children: [
        {
          title: "NET SHP",
          dataIndex: "ty_ytd_net_amount",
          key: "ty_ytd_net_amount",
          align: "right",
          className: "b_left",
          render: (text, record) => {
            return (
              <div className={record.ty_ytd_net_amount < 0 ? 'color_red' : ''}>
                ${numeral(record.ty_ytd_net_amount).format("0,00")}
              </div>
            );
          },
          sorter: (a, b) => {
            return a.ty_ytd_net_amount - b.ty_ytd_net_amount
          },
        },
        {
          title: "TTL SLS",
          dataIndex: "ty_ytd_ttl",
          key: "ty_ytd_ttl",
          align: "right",
          className: "b_left",
          render: (text, record) => {
            return {
              props: {
                style: { background: '#fffadf' }
              },
              children:
                <div className={record.ty_ytd_ttl < 0 ? 'color_red' : ''}>
                  ${
                    numeral(record.ty_ytd_ttl).format("0,00")
                  }
                </div>
            }
          },
          sorter: (a, b) => {
            return a.ty_ytd_ttl - b.ty_ytd_ttl
          },
        },
        {
          title: "TY/LY",
          dataIndex: "ty_ytd_difference",
          key: "ty_ytd_difference",
          align: "right",
          className: "b_left",
          render: (text, record) => {
            return (
              <div className={record.ty_ytd_difference < 0 ? 'color_red' : ''}>
                {parseFloat(
                  numeral(record.ty_ytd_difference).format("00.00"),
                )}%
              </div>
            );
          },
          sorter: (a, b) => {
            return a.ty_ytd_difference - b.ty_ytd_difference
          },
        },
      ],
    },
    {
      title: "THIS YEAR - YTE",
      children: [
        {
          title: "NET SHP",
          dataIndex: "ty_yte_net_amount",
          key: "ty_yte_net_amount",
          align: "right",
          className: "b_left",
          render: (text, record) => {
            return (
              <div className={record.ty_yte_net_amount < 0 ? 'color_red' : ''}>
                ${numeral(record.ty_yte_net_amount).format("0,00")}
              </div>
            );
          },
          sorter: (a, b) => {
            return a.ty_yte_net_amount - b.ty_yte_net_amount
          },
        },
        {
          title: "TTL SLS",
          dataIndex: "ty_yte_ttl",
          key: "ty_yte_ttl",
          align: "right",
          className: "b_left",
          render: (text, record) => {
            return {
              props: {
                style: { background: '#e4ffdf' }
              },
              children:
                <div className={record.ty_yte_ttl < 0 ? 'color_red' : ''}>
                  ${
                    numeral(record.ty_yte_ttl).format("0,00")
                  }
                </div>
            }
          },
          sorter: (a, b) => {
            return a.ty_yte_ttl - b.ty_yte_ttl
          },
        },
        {
          title: "TY/LY",
          dataIndex: "ty_yte_difference",
          key: "ty_yte_difference",
          align: "right",
          className: "b_left",
          render: (text, record) => {
            return (
              <div className={record.ty_yte_difference < 0 ? 'color_red' : ''}>
                {parseFloat(
                  numeral(record.ty_yte_difference).format("00.00"),
                )}%
              </div>
            );
          },
          sorter: (a, b) => {
            return a.ty_yte_difference - b.ty_yte_difference
          },
        },
      ],
    }
  ];

  function getNewState() {
    return {
      currentYearStartDate: currentYearStartDate,
      currentYearEndDate: currentYearEndDate,
      currentYearEndDateYtd: currentYearEndDateYtd,
      currentYearStartDateMte: currentYearStartDateMte,
      currentYearEndDateMte: currentYearEndDateMte,
      lastYearStartDate: lastYearStartDate,
      lastYearEndDate: lastYearEndDate,
      lastYearEndDateYtd: lastYearEndDateYtd,
      lastYearStartDateMte: lastYearStartDateMte,
      lastYearEndDateMte: lastYearEndDateMte,
      customerTypeID: customerType,
      regionId: regionID,
      salePersonId: salePersonName,
      genderId: genderID,
    };
  }

  let newState;

  useEffect(() => {
    newState = getNewState();
    setFormState(newState);
  }, [customerType, regionID, salePersonName, genderID]);


  useEffect(() => {
    let year = yearId;
    let month = monthId;
    let currentYearStartDate = moment().startOf("year").format("MM/DD/YYYY");
    let currentYearEndDate = moment().endOf("year").format("MM/DD/YYYY");
    let lastYearStartDate = moment()
      .startOf("year")
      .subtract(1, "year")
      .format("MM/DD/YYYY");
    let lastYearEndDate = moment()
      .endOf("year")
      .subtract(1, "year")
      .format("MM/DD/YYYY");

    if (dateTypeId === "yte") {
      currentYearStartDate = moment().startOf("year").format("MM/DD/") + year;
      currentYearEndDate = moment().endOf("year").format("MM/DD/") + year;
      lastYearStartDate = moment().startOf("year").subtract(1, "year").format("MM/DD/") + (year - 1);
      lastYearEndDate = moment().subtract(1, "year").endOf("year").format("MM/DD/") + (year - 1);
    } else if (dateTypeId === "ytd") {
      currentYearStartDate = moment().startOf("year").format("MM/DD/") + year;
      currentYearEndDate = moment().format("MM/DD/") + year;
      lastYearStartDate = moment().startOf("year").subtract(1, "year").format("MM/DD/") + (year - 1);
      lastYearEndDate = moment().subtract(1, "year").format("MM/DD/") + (year - 1);
    } else if (dateTypeId === "mtd") {
      currentYearStartDate = moment().format("MM") + "/" + "01" + "/" + year;
      currentYearEndDate = moment().format("MM") + "/" + moment().format("DD") + "/" + year;
      lastYearStartDate = moment().format("MM") + "/" + "01" + "/" + (year - 1);
      lastYearEndDate = moment().format("MM") + "/" + moment().format("DD") + "/" + (year - 1);
    } else if (dateTypeId === "mte") {
      currentYearStartDate = month + "/" + "01" + "/" + year;
      currentYearEndDate = month + "/" + moment(`${year} / ${month}`, "YYYY/MM").daysInMonth() + "/" + year;
      lastYearStartDate = month + "/" + "01" + "/" + (year - 1);
      lastYearEndDate = month + "/" + moment(`${year - 1} / ${month}`, "YYYY/MM").daysInMonth() + "/" + (year - 1);
    } else if (dateTypeId === "spring") {
      currentYearStartDate = "01" + "/" + "01" + "/" + year;
      currentYearEndDate = "06" + "/" + "30" + "/" + year;
      lastYearStartDate = "01" + "/" + "01" + "/" + (year - 1);
      lastYearEndDate = "06" + "/" + "30" + "/" + (year - 1);
    } else if (dateTypeId === "fall") {
      currentYearStartDate = "07" + "/" + "01" + "/" + year;
      currentYearEndDate = "12" + "/" + "31" + "/" + year;
      lastYearStartDate = "07" + "/" + "01" + "/" + (year - 1);
      lastYearEndDate = "12" + "/" + "31" + "/" + (year - 1);
    }
    setCurrentYearStartDate(currentYearStartDate);
    setCurrentYearEndDate(currentYearEndDate);
    setLastYearStartDate(lastYearStartDate);
    setLastYearEndDate(lastYearEndDate);

    const newState = {
      cy_start_date: currentYearStartDate,
      cy_end_date: currentYearEndDate,
      cy_end_date_ytd: currentYearEndDateYtd,
      cy_start_date_mte: currentYearStartDateMte,
      cy_end_date_mte: currentYearEndDateMte,

      ly_start_date: lastYearStartDate,
      ly_end_date: lastYearEndDate,
      ly_end_date_ytd: lastYearEndDateYtd,
      ly_start_date_mte: lastYearStartDateMte,
      ly_send_date_mte: lastYearEndDateMte,

      customer_type_id: customerType,
      region_id: regionID,
      sales_person_id: salePersonName,
      gender_id: genderID,
    };
    setFormState(newState);
  }, [dateTypeId, monthId, yearId]);


  useEffect(() => {
    dispatch(getAllActiveSaleperson(token, menuId));
    dispatch(getGender(token, menuId));
    dispatch(getAllActiveRegion(token, menuId));
    dispatch(getAllActiveCustomerType(token));
  }, []);


  const onFinish = () => {
    setLoading(true);
    history.push({
      pathname: "/sales-target-report",
      search: `?submited=${true}&report=${1}&region_id=${formState.region_id}&gender_id=${formState.gender_id}&customer_type_id=${formState.hasOwnProperty("customer_type_id") ? formState.customer_type_id : formState?.customerTypeID}&cy_start_date=${formState.hasOwnProperty("cy_start_date") ? formState.cy_start_date : formState?.currentYearStartDate}&cy_end_date=${formState.hasOwnProperty("cy_end_date") ? formState.cy_end_date : formState?.currentYearEndDate}&ly_start_date=${formState.hasOwnProperty("ly_start_date") ? formState.ly_start_date : formState?.lastYearStartDate}&ly_end_date=${formState.hasOwnProperty("ly_end_date") ? formState.ly_end_date : formState?.lastYearEndDate}&condition=${dateTypeId}`
    });
    dispatch(getSaleTargetReport(token, formState)).then((res) => {
      if (res.type === "GET_SALE_TARGET_REPORT_SUCCESS") {
        setLoading(false);
      }
    });
  };


  useEffect(() => {
    if (report == 2) {
      const values = {
        cy_start_date: cy_start_date,
        cy_end_date: cy_end_date,
        ly_start_date: ly_start_date,
        ly_end_date: ly_end_date,
        customer_type_id: customerTypeID === "undefined" ? null : customerTypeID,
        region_id: region_id === "undefined" ? null : region_id,
        gender_id: gender_id === "undefined" ? null : gender_id,
        sales_person_id: null,
      };
      setLoading(true);
      dispatch(getSaleComparisionReport(token, values)).then((res) => {
        setLoading(false);
      })
    } else if (report == 3) {
      const values = {
        cy_start_date: cy_start_date,
        cy_end_date: cy_end_date,
        ly_start_date: ly_start_date,
        ly_end_date: ly_end_date,
        customer_type_id: customerTypeID === "undefined" ? null : customerTypeID,
        region_id: region_id === "undefined" ? null : region_id,
        gender_id: gender_id === "undefined" ? null : gender_id,
        sales_person_id: null,
      };
      setLoading(true);
      dispatch(getSaleComparisionCustomerWiseReport(token, values)).then((res) => {
        setLoading(false);
      })
    }
    else {
      let data = {
        currentYearStartDate: "06/01/2024",
        currentYearEndDate: "06/30/2024",
        lastYearStartDate: "06/01/2023",
        lastYearEndDate: "06/30/2023",
        customer_type_id: null,
        region_id: null,
        gender_id: null,
        sales_person_id: null,
      }

      setLoading(true);
      history.push({
        pathname: "/sales-target-report",
        search: `?submited=${true}&report=${1}&region_id=${data.region_id}&gender_id=${data.gender_id}&customer_type_id=${data.hasOwnProperty("customer_type_id") ? data.customer_type_id : data?.customerTypeID}&cy_start_date=${data.hasOwnProperty("cy_start_date") ? data.cy_start_date : data?.currentYearStartDate}&cy_end_date=${data.hasOwnProperty("cy_end_date") ? data.cy_end_date : data?.currentYearEndDate}&ly_start_date=${data.hasOwnProperty("ly_start_date") ? data.ly_start_date : data?.lastYearStartDate}&ly_end_date=${data.hasOwnProperty("ly_end_date") ? data.ly_end_date : data?.lastYearEndDate}&condition=${dateTypeId}`
      });
      dispatch(getSaleTargetReport(token, data)).then((res) => {
        if (res.type === "GET_SALE_TARGET_REPORT_SUCCESS") {
          setLoading(false);
        }
      });
    }
  }, [report])


  const clearFields = (event, type) => {
    event.preventDefault();
    if (type === "customerType") {
      setCustomerType("All");
    } if (type === "region") {
      setRegionID("All");
    } else if (type === "salesPerson") {
      setSalePersonName("All");
    } else if (type === "gender") {
      setGenderID("All");
    }
  };

  const handlePrint = () => {
    window.print();
  };


  const staticValues = {
    ytd: "Year To Date",
    yte: "Year To End",
    mtd: "Month To Date",
    mte: "Month To End",
    spring: "Spring",
    fall: "Fall",
  };


  return (
    <>
      <div className="container-fluid">
        {report < 2 &&
          <div className="row">
            <div className="col">
              <div className="report-wrapper-main header_class">
                <div className="row justify-content-between pb-0">
                  <div className="col-3 d-flex align-items-start  box-space">
                    <div className="logobrand">
                      <img id="ImgLogo" alt="logo" src={logo} />
                    </div>
                  </div>

                  <div className="col-3 box-space right-content-header text-right">
                    <div className=" d-flex justify-content-end">
                      <div className=" d-flex justify-content-end printing_class">
                        <div className="print_icon printing_class">
                          <i
                            id="Image1"
                            title="Print"
                            alt="Print"
                            className="fa-solid fa-print printing_class"
                            onClick={handlePrint}
                          ></i>
                        </div>
                        <div className="print_icon printing_class">
                          {saleTargetReport?.payload?.data ? (
                            <input
                              type="image"
                              name="ImgExcel"
                              alt="Export to excel"
                              id="ImgExcel"
                              title="Export to excel"
                              src={xlsx}
                              onClick={onDownloadReport1}
                              style={{
                                borderWidth: "0px",
                                height: "14px",
                                cursor: "pointer",
                              }}
                            />
                          ) : (
                            <input
                              type="image"
                              name="ImgExcel"
                              alt="Export to excel"
                              id="ImgExcel"
                              title="Export to excel"
                              src={xlsx}
                              style={{ borderWidth: "0px", height: "14px" }}
                            />
                          )}
                        </div>
                      </div>
                      <span style={{ fontWeight: "bolder" }}>
                        Sales Target
                        <br />
                        Rpt # 006
                        <br />
                        Login as:
                        <span id="lblSalesPerson" style={{ fontWeight: "bold" }}>
                          {" "}
                          {userName}
                        </span>

                      </span>
                    </div>
                  </div>
                </div>
                {
                  report < 2 &&
                  <div className="row pb-2">
                    <div className="col">
                      <Form
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 18 }}
                        layout="horizontal"
                        form={form}
                        name="control-hooks"
                        onFinish={onFinish}
                      >
                        <div className="main_form row">
                          <div className=" col-6">
                            <div className=" d-flex flex-row align-items-baseline">
                              <label htmlFor="gender_id" className="labels width30">
                                Condition:
                              </label>
                              <div style={{ marginBottom: "-2px" }} className=" w-100">
                                <Form.Item
                                  name="dateTypeId"
                                  className="formFullWidth"
                                >
                                  <Select
                                    allowClear
                                    size="small"
                                    showSearch
                                    optionFilterProp="children"
                                    className={"select_Inputs"}
                                    defaultValue={dateTypeId}
                                    value={dateTypeId}
                                    onChange={(a) => {
                                      setDateTypeId(a);
                                      setYearId(date.format("Y"));
                                    }}
                                  >
                                    <Option key={"ytd"} value={"ytd"}>
                                      Year To Date
                                    </Option>
                                    <Option key={"yte"} value={"yte"}>
                                      Year To End
                                    </Option>
                                    <Option key={"mtd"} value={"mtd"}>
                                      Month To Date
                                    </Option>
                                    <Option key={"mte"} value={"mte"}>
                                      Month To End
                                    </Option>
                                    <Option key={"spring"} value={"spring"}>
                                      Spring
                                    </Option>
                                    <Option key={"fall"} value={"fall"}>
                                      Fall
                                    </Option>
                                  </Select>
                                </Form.Item>
                              </div>
                            </div>
                            <div className=" d-flex flex-row align-items-baseline">
                              <label htmlFor="gender_id" className="labels width30">
                                Year:
                              </label>
                              <div style={{ marginBottom: "2px" }} className=" w-100">

                                <DatePicker
                                  size="small"
                                  className="filter-fields full_date"
                                  value={moment(yearId)}
                                  defaultValue={moment(yearId)}
                                  onChange={(e) =>
                                    setYearId(moment(e).format("YYYY"))
                                  }
                                  picker="year"
                                />

                              </div>
                            </div>
                            <div className=" d-flex flex-row align-items-baseline">
                              <label htmlFor="" className="labels width30">Sale Person</label>
                              <div style={{ marginBottom: "-5px" }} className=" w-100">
                                <Form.Item
                                  name="salePersonName"
                                  initialValue={"All"}
                                  className="formFullWidth"
                                >
                                  <Select
                                    allowClear
                                    size="small"
                                    showSearch
                                    optionFilterProp="children"
                                    onChange={(e) => setSalePersonName(e)}
                                    className="filter-fields"
                                    loading={salePersonNew?.loading}
                                    defaultValue="All"
                                    value={salePersonName}
                                    filterSort={(optionA, optionB) =>
                                      optionB?.value !== null ?
                                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()) : ''
                                    }
                                  >
                                    <Option value={null}>All</Option>
                                    {salePersonNew?.payload?.data.map((item) => {
                                      return (
                                        <Option
                                          defaultValue={"All"}
                                          key={item.sales_person_id}
                                          value={item.sales_person_id}
                                        >
                                          {item.salesperson_name}
                                        </Option>
                                      );
                                    })}
                                  </Select>
                                </Form.Item>
                              </div>
                            </div>
                            <div className=" d-flex flex-row align-items-baseline">
                              <label htmlFor="" className="labels width30">Region</label>
                              <div className=" w-100">
                                <Form.Item
                                  name="regionID"
                                  initialValue={"All"}
                                  className="formFullWidth"

                                >
                                  <Select
                                    allowClear
                                    size="small"
                                    showSearch
                                    optionFilterProp="children"
                                    defaultValue="All"
                                    onChange={(e) => setRegionID(e)}
                                    className="filter-fields"
                                    loading={RegionNew?.loading}
                                    value={regionID}
                                    filterSort={(optionA, optionB) =>
                                      optionB?.value !== null ?
                                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()) : ''
                                    }
                                  >
                                    <Option value={null}>All</Option>
                                    {RegionNew?.payload?.data.map((item) => {
                                      return (
                                        <Option
                                          defaultValue={"All"}
                                          key={item.region_id}
                                          value={item.region_id}
                                        >
                                          {item.region_name}
                                        </Option>
                                      );
                                    })}
                                  </Select>
                                </Form.Item>
                              </div>
                            </div>
                          </div>
                          <div className=" col-6">
                            <div className=" d-flex flex-row align-items-baseline">
                              <label className="labels width30">Month:</label>
                              <div style={{ marginBottom: "2px" }} className=" w-100">
                                <DatePicker
                                  size="small"
                                  className="filter-fields full_date"
                                  value={moment(monthId)}
                                  disabled={dateTypeId === "ytd"}
                                  onChange={(e) =>
                                    setMonthId(moment(e).format("MM"))
                                  }
                                  picker="month"
                                  format={"MM"}
                                  mode="month"
                                />
                              </div>
                            </div>
                            <div className=" d-flex flex-row align-items-baseline">
                              <label htmlFor="" className="labels width30">Customer Type</label>
                              <div style={{ marginBottom: "-5px" }} className=" w-100">
                                <Form.Item
                                  name="customerType"
                                  initialValue={"All"}
                                  className="formFullWidth"
                                  style={{ marginBottom: "0px" }}
                                >
                                  <Select
                                    allowClear
                                    size="small"
                                    showSearch
                                    optionFilterProp="children"
                                    onChange={(e) => setCustomerType(e)}
                                    className="filter-fields"
                                    loading={customerTypeNew?.loading}
                                    value={customerType}
                                    defaultValue={
                                      form.getFieldValue().customer_type_id
                                    }
                                    filterSort={(optionA, optionB) =>
                                      optionB?.value !== null ?
                                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()) : ''
                                    }
                                  >
                                    <Option value={null}>All</Option>
                                    {customerTypeNew?.payload?.data.map((item) => {
                                      return (
                                        <Option
                                          defaultValue={"All"}
                                          key={item.customer_type_id}
                                          value={item.customer_type_id}
                                        >
                                          {item.customer_type_name}
                                        </Option>
                                      );
                                    })}
                                  </Select>
                                </Form.Item>
                              </div>
                            </div>
                            <div className=" d-flex flex-row align-items-baseline">
                              <label htmlFor="" className="labels width30">Gender</label>
                              <div style={{ marginBottom: "2px" }} className=" w-100">
                                <Form.Item
                                  name="genderID"
                                  initialValue={"All"}
                                  className="formFullWidth"

                                >
                                  <Select
                                    allowClear
                                    size="small"
                                    showSearch
                                    optionFilterProp="children"
                                    onChange={(e) => setGenderID(e)}
                                    className="filter-fields"
                                    loading={gender?.loading}
                                    defaultValue="All"
                                    value={genderID}
                                    filterSort={(optionA, optionB) =>
                                      optionB?.value !== null ?
                                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()) : ''
                                    }
                                  >
                                    <Option value={null}>All</Option>
                                    {gender?.payload?.data.map((item) => {
                                      return (
                                        <Option
                                          defaultValue={"All"}
                                          key={item.gender_id}
                                          value={item.gender_id}
                                        >
                                          {item.gender_name}
                                        </Option>
                                      );
                                    })}
                                  </Select>
                                </Form.Item>
                              </div>
                            </div>
                            <div style={{ marginTop: "0px", display: "flex", justifyContent: "end" }} >
                              <Button type="primary" htmlType="submit" className='submit-btn-btm m-0' disabled={loading}>
                                Search
                              </Button>
                            </div>
                          </div>
                        </div>
                      </Form>
                    </div>
                  </div>}
              </div>
            </div>
          </div>
        }
      </div>

      {report < 2 &&
        <div className="table_content"
          style={{ width: '1380px' }}

          ref={tableRef}
        >
          <div className="">
            {loading ? (
              <div className="d-flex align-items-center justify-content-center spin-style mt-3">
                <Spin />
              </div>
            ) : saleTargetReport?.payload?.data.length ? (
              <Table
                style={{ fontSize: "10.3px" }}
                className="
                test04 mt-3 sales_target_report_table sales_report_res_table sales_target_report_table_dashboard"
                size="small"
                pagination={false}
                summary={pageData => {
                  let shippLY = pageData.reduce(function (previousValue, currentValue) {
                    return previousValue += parseFloat(currentValue.shipped_amount_ly.replace(/,/g, ''))
                  }, 0);
                  let returnLY = pageData.reduce(function (previousValue, currentValue) {
                    return previousValue += parseFloat(currentValue.returned_amount_ly.replace(/\$|,/g, ''))
                  }, 0);
                  let netLY = pageData.reduce(function (previousValue, currentValue) {
                    return previousValue += parseFloat(currentValue.shipped_amount_ly - currentValue.returned_amount_ly)
                  }, 0);
                  let planLY = pageData.reduce(function (previousValue, currentValue) {
                    return previousValue += parseFloat(currentValue.plan_amount)
                  }, 0);
                  let plLY = pageData.reduce(function (previousValue, currentValue) {
                    return previousValue += parseFloat(currentValue.pl_ly)
                  }, 0);
                  let shipCY = pageData.reduce(function (previousValue, currentValue) {
                    return previousValue += parseFloat(currentValue.shipped_amount_cy)
                  }, 0);
                  let returnCY = pageData.reduce(function (previousValue, currentValue) {
                    return previousValue += parseFloat(currentValue.returned_amount_cy)
                  }, 0);
                  let netCY = pageData.reduce(function (previousValue, currentValue) {
                    return previousValue += parseFloat(currentValue.shipped_amount_cy - currentValue.returned_amount_cy)
                  }, 0);
                  let PlanCY = pageData.reduce(function (previousValue, currentValue) {
                    return previousValue += parseFloat(currentValue.percent_plan_ty)
                  }, 0);
                  let missPlanCY = pageData.reduce(function (previousValue, currentValue) {
                    return previousValue += parseFloat(currentValue.miss_plan_ty)
                  }, 0);
                  let pod = pageData.reduce(function (previousValue, currentValue) {
                    return previousValue += parseFloat(currentValue.pod_amount)
                  }, 0);
                  let pending = pageData.reduce(function (previousValue, currentValue) {
                    return previousValue += parseFloat(currentValue.pending_amount)
                  }, 0);
                  let totalSales = pageData.reduce(function (previousValue, currentValue) {
                    return previousValue += parseFloat(currentValue.total_sales)
                  }, 0);
                  let missPlanTS = pageData.reduce(function (previousValue, currentValue) {
                    return previousValue += parseFloat(currentValue.total_sale_percent_plan)
                  }, 0);
                  let planTS = pageData.reduce(function (previousValue, currentValue) {
                    return previousValue += parseFloat(currentValue.total_sale_miss_plan)
                  }, 0);
                  let tyly = pageData.reduce(function (previousValue, currentValue) {
                    return previousValue += parseFloat(currentValue.ty_ly)
                  }, 0);
                  return (
                    <>
                      <Table.Summary.Row>
                        <Table.Summary.Cell>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell className="sales_table_summary">
                          <p style={{ paddingTop: "10px", fontWeight: "bolder" }}>Total</p>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell className="sales_table_summary">
                          <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                            $ {numeral(shippLY.toLocaleString("en-US")).format("0,00")}
                          </p>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell className="sales_table_summary">
                          <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                            $ {numeral(returnLY.toLocaleString("en-US")).format("0,00")}
                          </p>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell className="sales_table_summary">
                          <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                            $ {numeral(netLY.toLocaleString("en-US")).format("0,00")}
                          </p>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell className="sales_table_summary">
                          <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                            $ {numeral(planLY.toLocaleString("en-US")).format("0,00")}
                          </p>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell className="sales_table_summary">
                          <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                            {numeral((((planLY / netLY) - 1) * 100).toLocaleString("en-US")).format("0,00")} %
                          </p>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell className="sales_table_summary">
                          <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                            $ {numeral(shipCY.toLocaleString("en-US")).format("0,00")}
                          </p>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell className="sales_table_summary">
                          <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                            $ {numeral(returnCY.toLocaleString("en-US")).format("0,00")}
                          </p>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell className="sales_table_summary">
                          <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                            $ {numeral(netCY.toLocaleString("en-US")).format("0,00")}
                          </p>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell className="sales_table_summary">
                          <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                            {numeral(((netCY / planLY) * 100).toLocaleString("en-US")).format("0,00")} %
                          </p>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell className="sales_table_summary">
                          <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                            {numeral((netCY - planLY).toLocaleString("en-US")).format("0,00")}
                          </p>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell className="sales_table_summary">
                          <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                            $ {numeral(pod.toLocaleString("en-US")).format("0,00")}
                          </p>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell className="sales_table_summary">
                          <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                            $ {numeral(pending.toLocaleString("en-US")).format("0,00")}
                          </p>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell className="sales_table_summary">
                          <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                            $ {numeral(totalSales.toLocaleString("en-US")).format("0,00")}
                          </p>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell className="sales_table_summary">
                          <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                            {numeral(((totalSales / planLY) * 100).toLocaleString("en-US")).format("0,00")} %
                          </p>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell className="sales_table_summary">
                          <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                            {numeral((totalSales - planLY).toLocaleString("en-US")).format("0,00")}
                          </p>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell className="sales_table_summary">
                          <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                            {numeral(((totalSales / netLY) - 1).toLocaleString("en-US")).format("0,00")} %
                          </p>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </>
                  );
                }}
                bordered
                columns={columns}
                dataSource={saleTargetReport?.payload?.data}
              />
            ) : (
              <div
                style={{
                  backgroundColor: "#CCCCCC",
                  borderWidth: "1pt",
                  borderStyle: "Dashed",
                  fontWeight: "bold",
                  height: "25px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                No record(s)
              </div>
            )}
          </div>
        </div>}
      {report === "2" && (

        <div>
          <div className="row">
            <div className="col">
              <div className="report-wrapper-main header_class">
                <div className="row justify-content-between pb-0">
                  <div className="col-3 d-flex align-items-start  box-space">
                    <div className="logobrand">
                      <img id="ImgLogo" alt="logo" src={logo} />
                    </div>
                  </div>
                  <div className="col-3 box-space right-content-header text-right">
                    <div className=" d-flex justify-content-end">
                      <div className=" d-flex justify-content-end printing_class">
                        <div className="print_icon printing_class">
                          <i
                            id="Image1"
                            title="Print"
                            alt="Print"
                            className="fa-solid fa-print printing_class"
                            onClick={handlePrint}
                          ></i>
                        </div>
                        <div className="print_icon printing_class">
                          {saleComparisionReport?.payload?.data ? (
                            <input
                              type="image"
                              name="ImgExcel"
                              alt="Export to excel"
                              id="ImgExcel"
                              title="Export to excel"
                              src={xlsx}
                              onClick={onDownloadReport2}
                              style={{
                                borderWidth: "0px",
                                height: "14px",
                                cursor: "pointer",
                              }}
                            />
                          ) : (
                            <input
                              type="image"
                              name="ImgExcel"
                              alt="Export to excel"
                              id="ImgExcel"
                              title="Export to excel"
                              src={xlsx}
                              style={{ borderWidth: "0px", height: "14px" }}
                            />
                          )}
                        </div>
                      </div>
                      <span style={{ fontWeight: "bolder" }}>
                        Sales Target Monthly
                        <br />
                        Rpt # 006
                        <br />
                        Login as:
                        <span id="lblSalesPerson" style={{ fontWeight: "bold" }}>
                          {" "}
                          {userName}
                        </span>

                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="table_content"
            style={{ width: 'fit-content' }}
            ref={tableRef2}
          >
            <div className="">
              {loading ? (
                <div className="d-flex align-items-center justify-content-center spin-style mt-3">
                  <Spin />
                </div>
              ) : saleComparisionReport?.payload?.data.length ? (

                <>
                  <div className="data_Table">
                    <div >
                      <div
                        style={{ margin: "0 auto", paddingTop: "10px" }}
                      >
                        <table id="Table1" cellPadding="3" cellSpacing="0" align="center" className="tablestyleControls" style={{ fontSize: "10px" }}>
                          <tbody>
                            <tr>
                              <td className="font_weight" style={{ width: "150px" }}>
                                Region Name :
                              </td>
                              <td style={{ width: "200px" }} colSpan={1}>
                                <b>
                                  {saleComparisionReport?.payload?.data[0]?.region_name}
                                </b>
                              </td>
                              <td className="font_weight" style={{ width: "150px" }}>
                                SalesPerson Name :
                              </td>
                              <td colSpan={1} style={{ width: "200px" }}>
                                <b>
                                  {saleComparisionReport?.payload?.data[0]?.salerperson_name}
                                </b>
                              </td>
                              <td className="font_weight" style={{ width: "150px" }}>
                                Condition :
                              </td>
                              <td colSpan={1} style={{ width: "200px" }}>
                                <b>
                                  {staticValues.hasOwnProperty(condition) ? staticValues[condition] : null}
                                </b>
                              </td>
                            </tr>
                          </tbody >
                        </table >
                      </div>
                    </div>
                  </div>
                  <Table
                    style={{ fontSize: "10.3px" }}
                    className="
                  test04 mt-3 sales_target_report_table sales_report_res_table sales_target_report_table_dashboard"
                    size="small"
                    pagination={false}
                    summary={pageData => {
                      let shippLY = pageData.reduce(function (previousValue, currentValue) {
                        return previousValue += parseFloat(currentValue.shipped_amount_ly.replace(/,/g, ''))
                      }, 0);
                      let returnLY = pageData.reduce(function (previousValue, currentValue) {
                        return previousValue += parseFloat(currentValue.returned_amount_ly.replace(/\$|,/g, ''))
                      }, 0);
                      let netLY = pageData.reduce(function (previousValue, currentValue) {
                        return previousValue += parseFloat(currentValue.shipped_amount_ly - currentValue.returned_amount_ly)
                      }, 0);
                      let planLY = pageData.reduce(function (previousValue, currentValue) {
                        return previousValue += parseFloat(currentValue.plan_amount)
                      }, 0);
                      let plLY = pageData.reduce(function (previousValue, currentValue) {
                        return previousValue += parseFloat(currentValue.pl_ly)
                      }, 0);
                      let shipCY = pageData.reduce(function (previousValue, currentValue) {
                        return previousValue += parseFloat(currentValue.shipped_amount_cy)
                      }, 0);
                      let returnCY = pageData.reduce(function (previousValue, currentValue) {
                        return previousValue += parseFloat(currentValue.returned_amount_cy)
                      }, 0);
                      let netCY = pageData.reduce(function (previousValue, currentValue) {
                        return previousValue += parseFloat(currentValue.shipped_amount_cy - currentValue.returned_amount_cy)
                      }, 0);
                      let PlanCY = pageData.reduce(function (previousValue, currentValue) {
                        return previousValue += parseFloat(currentValue.percent_plan_ty)
                      }, 0);
                      let missPlanCY = pageData.reduce(function (previousValue, currentValue) {
                        return previousValue += parseFloat(currentValue.miss_plan_ty)
                      }, 0);
                      let pod = pageData.reduce(function (previousValue, currentValue) {
                        return previousValue += parseFloat(currentValue.pod_amount)
                      }, 0);
                      let pending = pageData.reduce(function (previousValue, currentValue) {
                        return previousValue += parseFloat(currentValue.pending_amount)
                      }, 0);
                      let totalSales = pageData.reduce(function (previousValue, currentValue) {
                        return previousValue += parseFloat(currentValue.total_sales)
                      }, 0);
                      let missPlanTS = pageData.reduce(function (previousValue, currentValue) {
                        return previousValue += parseFloat(currentValue.total_sale_percent_plan)
                      }, 0);
                      let planTS = pageData.reduce(function (previousValue, currentValue) {
                        return previousValue += parseFloat(currentValue.total_sale_miss_plan)
                      }, 0);
                      let tyly = pageData.reduce(function (previousValue, currentValue) {
                        return previousValue += parseFloat(currentValue.ty_ly)
                      }, 0);
                      return (
                        <>
                          <Table.Summary.Row>
                            <Table.Summary.Cell>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell>
                              <p style={{ paddingTop: "11.3px", fontWeight: "bolder" }}>Total</p>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell>
                              <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                                $ {shippLY.toLocaleString("en-US")}
                              </p>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell>
                              <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                                $ {returnLY.toLocaleString("en-US")}
                              </p>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell>
                              <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                                $ {netLY.toLocaleString("en-US")}
                              </p>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell>
                              <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                                $ {planLY.toLocaleString("en-US")}
                              </p>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell>
                              <p className={plLY < 0 ? 'color_red' : ''} style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                                {plLY.toLocaleString("en-US")} %
                              </p>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell>
                              <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                                $ {shipCY.toLocaleString("en-US")}
                              </p>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell>
                              <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                                $ {returnCY.toLocaleString("en-US")}
                              </p>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell>
                              <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                                $ {netCY.toLocaleString("en-US")}
                              </p>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell>
                              <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                                {PlanCY.toLocaleString("en-US")} %
                              </p>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell>
                              <p className={missPlanCY < 0 ? 'color_red' : ''} style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                                {missPlanCY.toLocaleString("en-US")}
                              </p>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell>
                              <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                                $ {pod.toLocaleString("en-US")}
                              </p>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell>
                              <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                                $ {pending.toLocaleString("en-US")}
                              </p>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell>
                              <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                                $ {totalSales.toLocaleString("en-US")}
                              </p>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell>
                              <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                                {missPlanTS.toLocaleString("en-US")} %
                              </p>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell>
                              <p className={planTS < 0 ? 'color_red' : ''} style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                                {planTS.toLocaleString("en-US")}
                              </p>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell>
                              <p className={tyly < 0 ? 'color_red' : ''} style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "10px" }}>
                                {tyly.toLocaleString("en-US")} %
                              </p>
                            </Table.Summary.Cell>
                          </Table.Summary.Row>
                        </>
                      );
                    }}
                    bordered
                    columns={columns2}
                    dataSource={saleComparisionReport?.payload?.data}
                  />
                </>
              ) : (
                <div
                  style={{
                    backgroundColor: "#CCCCCC",
                    borderWidth: "1pt",
                    borderStyle: "Dashed",
                    fontWeight: "bold",
                    height: "25px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  No record(s)
                </div>
              )}
            </div>
          </div>
        </div>

      )}
      {report === "3" && (
        <>
          <div>
            <div className="row">
              <div className="col">
                <div className="report-wrapper-main header_class">
                  <div className="row justify-content-between pb-0">
                    <div className="col-3 d-flex align-items-start  box-space">
                      <div className="logobrand">
                        <img id="ImgLogo" alt="logo" src={logo} />
                      </div>
                    </div>
                    <div className="col-3 box-space right-content-header text-right">
                      <div className=" d-flex justify-content-end">
                        <div className=" d-flex justify-content-end printing_class">
                          <div className="print_icon printing_class">
                            <i
                              id="Image1"
                              title="Print"
                              alt="Print"
                              className="fa-solid fa-print printing_class"
                              onClick={handlePrint}
                            ></i>
                          </div>
                          <div className="print_icon printing_class">
                            {saleComparisionCustomerWiseReport?.payload?.data ? (
                              <input
                                type="image"
                                name="ImgExcel"
                                alt="Export to excel"
                                id="ImgExcel"
                                title="Export to excel"
                                src={xlsx}
                                onClick={onDownloadReport3}
                                style={{
                                  borderWidth: "0px",
                                  height: "14px",
                                  cursor: "pointer",
                                }}
                              />
                            ) : (
                              <input
                                type="image"
                                name="ImgExcel"
                                alt="Export to excel"
                                id="ImgExcel"
                                title="Export to excel"
                                src={xlsx}
                                style={{ borderWidth: "0px", height: "14px" }}
                              />
                            )}
                          </div>
                        </div>
                        <span style={{ fontWeight: "bolder" }}>
                          Sales Target Detail
                          <br />
                          Rpt # 006
                          <br />
                          Login as:
                          <span id="lblSalesPerson" style={{ fontWeight: "bold" }}>
                            {" "}
                            {userName}
                          </span>

                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="table_content maxWidth1430
          // max_width_sales_target"
              style={{ width: 'fit-content' }} ref={tableRef3} >
              <div className="">
                {loading ? (
                  <div className="d-flex align-items-center justify-content-center spin-style mt-3">
                    <Spin />
                  </div>
                ) : saleComparisionCustomerWiseReport?.payload?.data.length ? (
                  <>
                    <div className="data_Table">
                      <div >
                        <div
                          style={{ margin: "0 auto", paddingTop: "10px" }}
                        >
                          <table id="Table1" cellPadding="3" cellSpacing="0" align="center" className="tablestyleControls" style={{ fontSize: "10px" }}>
                            <tbody>
                              <tr>
                                <td className="font_weight" style={{ width: "auto" }}>
                                  Region Name :
                                </td>
                                <td style={{ width: "auto" }} colSpan={1}>
                                  <b>
                                    {saleComparisionCustomerWiseReport?.payload?.data[0]?.region_name}
                                  </b>
                                </td>
                                <td className="font_weight" style={{ width: "auto" }}>
                                  SalesPerson Name :
                                </td>
                                <td colSpan={1} style={{ width: "auto" }}>
                                  <b>
                                    {saleComparisionCustomerWiseReport?.payload?.data[0]?.salerperson_name}
                                  </b>
                                </td>
                                <td className="font_weight" style={{ width: "auto" }}>
                                  Condition :
                                </td>
                                <td colSpan={1} style={{ width: "auto" }}>
                                  <b>
                                    {staticValues.hasOwnProperty(condition) ? staticValues[condition] : null}
                                  </b>
                                </td>
                              </tr>
                            </tbody >
                          </table >
                        </div>
                      </div>
                    </div>

                    <Table
                      style={{ fontSize: "10.3px", width: "fit-content" }}
                      className="
                    test04 mt-3 sales_target_report_table sales_report_res_table sales_target_report_table_dashboard"
                      size="small"
                      pagination={false}
                      summary={pageData => {
                        let ttl = pageData.reduce(function (previousValue, currentValue) {
                          return previousValue += parseFloat(currentValue.ttl.replace(/,/g, ''))
                        }, 0);
                        let lyMte = pageData.reduce(function (previousValue, currentValue) {
                          return previousValue += parseFloat(currentValue.ly_mte.replace(/\$|,/g, ''))
                        }, 0);
                        let lyYtd = pageData.reduce(function (previousValue, currentValue) {
                          return previousValue += parseFloat(currentValue.ly_ytd.replace(/\$|,/g, ''))
                        }, 0);
                        let lyYte = pageData.reduce(function (previousValue, currentValue) {
                          return previousValue += parseFloat(currentValue.ly_yte)
                        }, 0);
                        let mteNet = pageData.reduce(function (previousValue, currentValue) {
                          return previousValue += parseFloat(currentValue.ty_mte_net_amount)
                        }, 0);
                        let mteTtl = pageData.reduce(function (previousValue, currentValue) {
                          return previousValue += parseFloat(currentValue.ty_mte_ttl)
                        }, 0);
                        let mteTyLY = pageData.reduce(function (previousValue, currentValue) {
                          return previousValue += parseFloat(currentValue.ty_mte_difference)
                        }, 0);
                        let ytdNet = pageData.reduce(function (previousValue, currentValue) {
                          return previousValue += parseFloat(currentValue.ty_ytd_net_amount)
                        }, 0);
                        let ytdTtl = pageData.reduce(function (previousValue, currentValue) {
                          return previousValue += parseFloat(currentValue.ty_ytd_ttl)
                        }, 0);
                        let ytdTyLY = pageData.reduce(function (previousValue, currentValue) {
                          return previousValue += parseFloat(currentValue.ty_ytd_difference)
                        }, 0);
                        let yteNet = pageData.reduce(function (previousValue, currentValue) {
                          return previousValue += parseFloat(currentValue.ty_yte_net_amount)
                        }, 0);
                        let yteTtl = pageData.reduce(function (previousValue, currentValue) {
                          return previousValue += parseFloat(currentValue.ty_yte_ttl)
                        }, 0);
                        let yteTyLY = pageData.reduce(function (previousValue, currentValue) {
                          return previousValue += parseFloat(currentValue.ty_yte_difference)
                        }, 0);
                        return (
                          <>
                            <Table.Summary.Row>
                              <Table.Summary.Cell>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell>
                                <p style={{ paddingTop: "10px", fontWeight: "bolder" }}>Total</p>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell>
                                <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                                  {((yteTtl / yteTtl) * 100).toLocaleString("en-US")}%
                                </p>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell>
                                <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                                  ${lyMte.toLocaleString("en-US")}
                                </p>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell>
                                <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                                  $ {lyYtd.toLocaleString("en-US")}
                                </p>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell>
                                <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                                  $ {lyYte.toLocaleString("en-US")}
                                </p>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell>
                                <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                                  $ {mteNet.toLocaleString("en-US")}
                                </p>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell>
                                <p className={mteTtl < 0 ? 'color_red' : ''} style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                                  $ {mteTtl.toLocaleString("en-US")}
                                </p>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell>
                                <p className={mteTyLY < 0 ? 'color_red' : ''} style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                                  {mteTyLY.toLocaleString("en-US")}%
                                </p>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell>
                                <p className={ytdNet < 0 ? 'color_red' : ''} style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                                  $ {ytdNet.toLocaleString("en-US")}
                                </p>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell>
                                <p className={ytdTtl < 0 ? 'color_red' : ''} style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                                  $ {ytdTtl.toLocaleString("en-US")}
                                </p>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell>
                                <p className={ytdTyLY < 0 ? 'color_red' : ''} style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                                  {ytdTyLY.toLocaleString("en-US")}%
                                </p>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell>
                                <p className={yteNet < 0 ? 'color_red' : ''} style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                                  $ {yteNet.toLocaleString("en-US")}
                                </p>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell>
                                <p className={yteTtl < 0 ? 'color_red' : ''} style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                                  $ {yteTtl.toLocaleString("en-US")}
                                </p>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell>
                                <p className={yteTyLY < 0 ? 'color_red' : ''} style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black", fontSize: "11.3px" }}>
                                  {yteTyLY.toLocaleString("en-US")}%
                                </p>
                              </Table.Summary.Cell>
                            </Table.Summary.Row>
                          </>
                        );
                      }}
                      bordered
                      columns={columns3}
                      dataSource={saleComparisionCustomerWiseReport?.payload?.data}
                    />
                  </>
                ) : (
                  <div
                    style={{
                      backgroundColor: "#CCCCCC",
                      borderWidth: "1pt",
                      borderStyle: "Dashed",
                      fontWeight: "bold",
                      height: "25px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    No record(s)
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}

    </>
  );
};

export default SalesTargetRetport;
