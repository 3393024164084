import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAllReceiptHeader } from "../../redux/actions/receipt.action";

import SkeletonLoad from "../../components/Skeleton/SkeletonLoad";
import PageHeader from "../../components/PageHeader/PageHeader";

import Tables from "../../components/Table/Tables";
import { receiptColumns } from "../../utils/cols";
import moment from "moment";
import { checkDate } from "../../components/CheckDate/checkDate";
import "../../screens/Product/product.css";
import "../Style/style.css";
import { FilterFilled, SearchOutlined, UndoOutlined, FilterOutlined, CaretRightOutlined, FileAddOutlined, CloudDownloadOutlined, CloudUploadOutlined } from "@ant-design/icons";
import { Modal, Button, Form, Input, Checkbox, Tabs, Tooltip, Divider, Select, DatePicker, Collapse, Row, Col, Space, Upload } from "antd";
import Swal from "sweetalert2";
import { TabTitle } from "../../utils/GeneralFunction";


export default function ItemReceiptListNew({ token }) {
	TabTitle("Item Receipt")
	const [form] = Form.useForm();
	const [dateOP, setDateOP] = useState("");
	const dispatch = useDispatch();
	const { receiptList } = useSelector((state) => state);
	const [startDate, setStartDate] = useState("01-1-2022");
	const [endDate, setEndDate] = useState("06-01-2022");
	const [isModalVisible, setIsModalVisible] = useState(false);
	const { RangePicker } = DatePicker;
	const dateFormat = "MM-DD-YYYY";
	const [loading, setLoading] = useState(true);
	const [receiptNo, setReceiptNo] = useState(null);
	const [asnNo, setAsnNo] = useState(null);
	const [postingPeriod, setPostingPeriod] = useState(null);
	const [customerName, setCustomerName] = useState(null);
	const [from_date, setFromDate] = useState(moment(`1-1-${new Date().getFullYear()}`));
	const [to_date, setToDate] = useState(moment(`12-31-${new Date().getFullYear()}`));

	const [show, setShow] = useState(false);
	const [filteredCustomers, setFilteredCustomers] = [];
	const [dataLen, setDataLen] = useState(0);
	const [filteredInfo, setFilteredInfo] = useState(null);
	const [sortedInfo, setSortedInfo] = useState(null);
	const [InvoiceNo, setInvoiceNo] = useState(null);
	const [raNo, setRaNo] = useState(null);
	const [lotNo, setlotNo] = useState(null);

	const { Panel } = Collapse;


	const onFinishFilter = (values) => {
		if (moment(from_date).isAfter(to_date)) {
			checkDate(to_date, from_date, setFromDate);
		} else if (moment(from_date).isSame(to_date)) {
			dispatch(getAllReceiptHeader(token, InvoiceNo, raNo, lotNo, receiptNo, asnNo, from_date, to_date, postingPeriod, customerName)).then((res) => {
				
				setDataLen(res?.payload?.data);
			});
		} else if (moment(from_date).isBefore(to_date)) {
			dispatch(getAllReceiptHeader(token,InvoiceNo, raNo, lotNo, receiptNo, asnNo, from_date, to_date, postingPeriod, customerName)).then((res) => {
				
				setDataLen(res?.payload?.data);
			});
		}
	};

	const handleFilter = (pagination, filters, sorter, extra) => {
		setFilteredInfo(filters);
		setSortedInfo(sorter);
		setShow(true);
	};

	return (
		<div className="styleContainer">
			<div className="styleBody h-100">
				<PageHeader title={"Item Receipt List"} />
				<div className="bodyStyle">
				
					<Collapse style={{ width: "99%", margin: "10px" }} bordered={false} defaultActiveKey={["1"]} expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}>
						<Panel header="Filters" key="1" className="site-collapse-custom-panel" extra={<FilterOutlined />}>
							<Form
								labelCol={{
									span: 8,
								}}
								wrapperCol={{
									span: 16,
								}}
								initialValues={{
									remember: true,
								}}
								onFinish={onFinishFilter}
								
								autoComplete="off"
							>
								<Row>
									<Col xs={24} xl={8}>
										<Form.Item name="InvoiceNo" label="Invoice#" value={InvoiceNo}>
											<Input
												allowClear
												onChange={(e) => setInvoiceNo(e.target.value)}
												
												size="small"
											/>
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item name="raNo" label="RA#" value={raNo}>
											<Input
												allowClear
												onChange={(e) => setRaNo(e.target.value)}
												
												size="small"
											/>
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item name="lotNo" label="LOT#" value={lotNo}>
											<Input
												allowClear
												onChange={(e) => setlotNo(e.target.value)}
												
												size="small"
											/>
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item name="receiptNo" label="Receipt#" value={receiptNo}>
											<Input
												allowClear
												onChange={(e) => setReceiptNo(e.target.value)}
												
												size="small"
											/>
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item
											label="From Date"
											name="from_date"
											value={form.getFieldValue().from_date}
											initialValue={from_date}
											
											id={"from_date"}
										>
											<DatePicker
												size="small"
												format="MM-DD-YYYY"
												placeholder={from_date}
												value={form.getFieldValue().from_date}
												allowClear
												className="dateInput customInputs"
												
												onChange={(e) => {
													setFromDate(moment(e));
													form.setFieldsValue({
														from_date: moment(e),
													});
													setDateOP("fromdate");
												}}
											/>
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item label="To Date" name="to_date" value={form.getFieldValue().to_date} initialValue={to_date} id={"to_date"}>
											<DatePicker
												size="small"
												format="MM-DD-YYYY"
												value={form.getFieldValue().to_date}
												
												allowClear
												className="dateInput customInputs"
												onChange={(e) => {
													setToDate(moment(e));
													form.setFieldsValue({
														to_date: moment(e),
													});
													setDateOP("todate");
												}}
											
											/>
										</Form.Item>
									</Col>
								</Row>
								<Row>
									<Col xs={24} xl={8}>
										<Form.Item name="postingPeriod" label="Posting Period" value={postingPeriod}>
											<Input
												allowClear
												onChange={(e) => setPostingPeriod(e.target.value)}
												
												size="small"
											/>
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item name="customerName" label="Customer Name" value={customerName}>
											<Input
												allowClear
												onChange={(e) => setCustomerName(e.target.value)}
												
												size="small"
											/>
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item name="asnNo" label="Asn #" value={asnNo}>
											<Input
												allowClear
												onChange={(e) => setAsnNo(e.target.value)}
												
												size="small"
											/>
										</Form.Item>
									</Col>
								</Row>
								<div style={{ display: "flex", justifyContent: "flex-end" }}>
									<Button type="primary" htmlType="submit" disabled={receiptList.loading}>
										Search
									</Button>
								</div>
							</Form>
						</Panel>
					</Collapse>

					{receiptList.loading ? (
						<div className="mainProductContainer">
							<SkeletonLoad />
						</div>
					) : (
						<div className="mainProductContainer">
							<div className="productTable">
								<Tables
									dataLen={dataLen}
									setDataLen={setDataLen}
									show={show}
									handleFilter={handleFilter}
									loadHeight={40}
									loadWidth={40}
									type={"customer"}
									
									
									scroll={570}
									perPage={25}
									scrollX={"80rem"}
									col={receiptColumns}
									source={receiptList?.payload?.data}
									load={receiptList.loading}
									paginate
								/>
								{/* <span className="records-text">
                      {dataLen === 0 ? <>No of Records:  <span className="fw-500">  0</span> </> : < > No of Records: <span className="fw-500"> {show ? dataLen : dataLen ? dataLen : "0"}</span>  </>}
                    </span> */}
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}



























































