import {
  GET_BILLING_ERROR,
  GET_BILLING_REQUEST,
  GET_BILLING_SUCCESS,
  ADD_BILLING_REQUEST,
  ADD_BILLING_SUCCESS,
  ADD_BILLING_ERROR,
  DELETE_BILLING_REQUEST,
  DELETE_BILLING_SUCCESS,
  DELETE_BILLING_ERROR,
  UPDATE_BILLING_REQUEST,
  UPDATE_BILLING_SUCCESS,
  UPDATE_BILLING_ERROR,

} from "../actions/types";

export default function (state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case GET_BILLING_REQUEST:
      return {
        loading: true,
      };
    case GET_BILLING_SUCCESS:
      return {
        ...state,
        loading: false,
        payload,
      };
    case GET_BILLING_ERROR:
      return {
        ...state,
        loading: false,
        payload,
      };

    case ADD_BILLING_REQUEST:
      return {
        loading: true,
      };
    case ADD_BILLING_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: [payload],
      };
    case ADD_BILLING_ERROR:
      return {
        ...state,
        loading: false,
      };

      case DELETE_BILLING_REQUEST:
      return {
        loading: true,
      };
    case DELETE_BILLING_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: [payload],
      };
    case DELETE_BILLING_ERROR:
      return {
        ...state,
        loading: false,
      };

      case UPDATE_BILLING_REQUEST:
      return {
        loading: true,
      };
    case UPDATE_BILLING_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: [payload],
      };
    case UPDATE_BILLING_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
