import { GET_FABRICCLASS2_REQUEST, GET_FABRICCLASS2_SUCCESS, GET_FABRICCLASS2_ERROR, ADD_FABRICCLASS2_REQUEST, ADD_FABRICCLASS2_ERROR, ADD_FABRICCLASS2_SUCCESS, UPDATE_FABRICCLASS2_REQUEST, UPDATE_FABRICCLASS2_SUCCESS, UPDATE_FABRICCLASS2_ERROR, DELETE_FABRICCLASS2_ERROR, DELETE_FABRICCLASS2_REQUEST, DELETE_FABRICCLASS2_SUCCESS } from "./types"

import { BASEURL } from "../../constant/config.js";

const axios = require("axios");

export const getFabricClass2 = (token, menu_id) => (dispatch) => {

    dispatch({ type: GET_FABRICCLASS2_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/fabricClass2`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { menu_id }
    }).then((response) => {
        return dispatch({ type: GET_FABRICCLASS2_SUCCESS, payload: response.data })
    }).catch((error) => {
        return dispatch({ type: GET_FABRICCLASS2_ERROR, payload: error.response })
    })
}

export const addFabricClass2 = (token, data) => (dispatch) => {
    
    dispatch({ type: ADD_FABRICCLASS2_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}create/fabricClass2`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { fabric_class_name2: data.fabricClass2Name, is_active: data.check },
    })
        .then((response) => {
            
            return dispatch({ type: ADD_FABRICCLASS2_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: ADD_FABRICCLASS2_ERROR,
                payload: error.response,
            });
        });
};
export const updateFabricClass2 = (token, data, id) => (dispatch) => {
    dispatch({ type: UPDATE_FABRICCLASS2_REQUEST });
    return axios({
        method: "PUT",
        url: `${BASEURL}update/fabricClass2/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { fabric_class_name2: data.fabricClass2Name, is_active: data.check },
    })
        .then((response) => {

            return dispatch({ type: UPDATE_FABRICCLASS2_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: UPDATE_FABRICCLASS2_ERROR,
                payload: error.response,
            });
        });
};

export const deleteFabricClass2 = (token, id) => (dispatch) => {
    dispatch({ type: DELETE_FABRICCLASS2_REQUEST });
    return axios({
        method: "DELETE",
        url: `${BASEURL}delete/fabricClass2/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
    })
        .then((response) => {
            
            return dispatch({ type: DELETE_FABRICCLASS2_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: DELETE_FABRICCLASS2_ERROR,
                payload: error.response,
            });
        });
};
