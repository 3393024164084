import {

    GET_ADMIN_MENU_FORM_REQUEST, GET_ADMIN_MENU_FORM_SUCCESS, GET_ADMIN_MENU_FORM_ERROR
} from "../actions/types"
export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case GET_ADMIN_MENU_FORM_REQUEST:
            return {
                loading: true,
            };
        case GET_ADMIN_MENU_FORM_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_ADMIN_MENU_FORM_ERROR:
            return {
                ...state,
                loading: false
            };

        default:
            return state;
    }
}