import React from "react";
import { Row as Brow, Col as Bcol } from "reactstrap";

export default function ({ orderItems, shippingCost, expenseItems, lineItems }) {



	const summary = () => {
		let orderSummary = orderItems;
		let amount = 0;
		let quantity = 0;
		let discount = 0;
		let tax = 0;
		let net = 0;
		let payment_amount = 0;
		let expenseTotal = 0
		if (orderSummary && orderSummary.length > 0) {
			orderSummary?.forEach(function (item) {
				if (item.hasOwnProperty("rate")) {
					amount += parseFloat(item.gross_amount);
					net += parseFloat(item.rate) * parseFloat(item.quantity);
				}
				if (item.hasOwnProperty("quantity")) {
					quantity += parseFloat(item.quantity);
				}
			});
		}

		if (expenseItems && expenseItems.length > 0) {
			expenseItems?.forEach(function (item) {
				if (item.hasOwnProperty("amount")) {
					expenseTotal += parseFloat(item.amount);

				}
			});
		}

		if (lineItems && lineItems.length > 0) {
			lineItems?.forEach(function (item) {
				if (item.hasOwnProperty("payment_amount")) {
					payment_amount += parseFloat(item.payment_amount);

				}
			});
		}

		return {
			quantity: quantity,
			amount: parseFloat(amount),
			discount: parseFloat(discount).toFixed(2),
			tax: parseFloat(tax).toFixed(2),
			shippingCost: parseFloat(shippingCost),
			net: parseFloat(parseFloat(amount) + parseFloat(shippingCost)),
			expenseTotal: parseFloat(expenseTotal).toFixed(2),
			payment_amount: parseFloat(payment_amount).toFixed(2)
		};
	};
	return (
		<div className="firstSectionRo">
			<div className="order-summary">
				<div className="summary-title">Bill Summary</div>
				<Brow>
					<Bcol className="summary-heading" col={6}>
						<p className="mb-0">Total Quantity:</p>
					</Bcol>
					<Bcol className="col-6">
						<p className="mb-0 summary-value">{summary().quantity}</p>
					</Bcol>
				</Brow>
				<Brow>
					<Bcol className="summary-heading" col={6}>
						<p className="mb-0">Total Item Amount:</p>
					</Bcol>
					<Bcol className="col-6">
						<p className="mb-0 summary-value">{summary().net}</p>
					</Bcol>
				</Brow>
				<Brow>
					<Bcol className="summary-heading" col={6}>
						<p className="mb-0">Total Expense Amount:</p>
					</Bcol>
					<Bcol className="col-6">

						<p className="mb-0 summary-value">{summary().expenseTotal}</p>
					</Bcol>
				</Brow>

				
				{/* <Brow>
					<Bcol className="summary-heading" col={6}>
						<p className="mb-0">Total Shipping:</p>
					</Bcol>
					<Bcol className="col-6">
						<p className="mb-0 summary-value">${summary().shippingCost}</p>
					</Bcol>
				</Brow>

				<Brow>
					<Bcol className="summary-heading" col={6}>
						<p className="mb-0">Total Discount:</p>
					</Bcol>
					<Bcol className="col-6">
						<p className="mb-0 summary-value">${summary().discount}</p>
					</Bcol>
				</Brow>
				<Brow>
					<Bcol className="summary-heading" col={6}>
						<p className="mb-0">Total Amount:</p>
					</Bcol>
					<Bcol className="col-6">
						<p className="mb-0 summary-value">${summary().amount}</p>
					</Bcol>
				</Brow>
				<Brow>
					<Bcol className="summary-heading" col={6}>
						<p className=" mb-0">Net:</p>
					</Bcol>
					<Bcol className="col-6">
						<p className="mb-0 summary-value">${summary().net}</p>
					</Bcol>
				</Brow> */}
			</div>
		</div>
	);
}