import { notification } from 'antd';
notification.config({ duration: 2 });

const sendNotification = (type, title, msg) => {
    return (notification[type]({
        message: title,
        description: msg,
    }))
};

export default sendNotification;