import {
    GET_PAY_VENDOR_BILL_FOR_PAYMENT_REQUEST,
    GET_PAY_VENDOR_BILL_FOR_PAYMENT_SUCCESS,
    GET_PAY_VENDOR_BILL_FOR_PAYMENT_ERROR,  
} from "../actions/types"

export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case GET_PAY_VENDOR_BILL_FOR_PAYMENT_REQUEST:
            return {
                loading: true,
            };
        case GET_PAY_VENDOR_BILL_FOR_PAYMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_PAY_VENDOR_BILL_FOR_PAYMENT_ERROR:
            return {
                ...state,
                loading: false
            };
        default:
            return state;
    }
}
