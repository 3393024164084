import {
    GET_COUNTRY_ERROR,
    GET_COUNTRY_REQUEST,
    GET_COUNTRY_SUCCESS,
    ADD_COUNTRY_ERROR,ADD_COUNTRY_REQUEST,ADD_COUNTRY_SUCCESS,UPDATE_COUNTRY_ERROR,UPDATE_COUNTRY_REQUEST,UPDATE_COUNTRY_SUCCESS,DELETE_COUNTRY_ERROR,DELETE_COUNTRY_REQUEST,DELETE_COUNTRY_SUCCESS
  } from "../actions/types";
  
  export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
      case GET_COUNTRY_REQUEST:
        return {
          loading: true,
        };
      case GET_COUNTRY_SUCCESS:
        return {
          ...state,
          loading: false,
          payload,
        };
      case GET_COUNTRY_ERROR:
        return {
          ...state,
          loading: false,
          payload,
        };
        case ADD_COUNTRY_REQUEST:
              return {
                  loading: true,
              };
          case ADD_COUNTRY_SUCCESS:
              return {
                  ...state,
                  loading: false,
                  payload: [payload],
              };
          case ADD_COUNTRY_ERROR:
              return {
                  ...state,
                  loading: false
              };
              case UPDATE_COUNTRY_REQUEST:
                return {
                    loading: true,
                };
            case UPDATE_COUNTRY_SUCCESS:
                return {
                    ...state,
                    loading: false,
                    payload: [payload],
                };
            case UPDATE_COUNTRY_ERROR:
                return {
                    ...state,
                    loading: false
                };
                case DELETE_COUNTRY_REQUEST:
                  return {
                      loading: true,
                  };
              case DELETE_COUNTRY_SUCCESS:
                  return {
                      ...state,
                      loading: false,
                      payload: [payload],
                  };
              case DELETE_COUNTRY_ERROR:
                  return {
                      ...state,
                      loading: false
                  };
    

      default:
        return state;
    }
  }
  