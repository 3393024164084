import { GET_FITCATEGORY_ERROR, GET_FITCATEGORY_REQUEST, GET_FITCATEGORY_SUCCESS, ADD_FITCATEGORY_REQUEST, ADD_FITCATEGORY_SUCCESS, ADD_FITCATEGORY_ERROR, DELETE_FITCATEGORY_REQUEST, DELETE_FITCATEGORY_SUCCESS, DELETE_FITCATEGORY_ERROR, UPDATE_FITCATEGORY_ERROR, UPDATE_FITCATEGORY_SUCCESS, UPDATE_FITCATEGORY_REQUEST } from "../actions/types";


export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case GET_FITCATEGORY_REQUEST:
            return {
                loading: true,
            };
        case GET_FITCATEGORY_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_FITCATEGORY_ERROR:
            return {
                ...state,
                loading: false
            };
        case ADD_FITCATEGORY_REQUEST:
            return {
                loading: true,
            };
        case ADD_FITCATEGORY_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case ADD_FITCATEGORY_ERROR:
            return {
                ...state,
                loading: false
            };
        case DELETE_FITCATEGORY_REQUEST:
            return {
                loading: true,
            };
        case DELETE_FITCATEGORY_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case DELETE_FITCATEGORY_ERROR:
            return {
                ...state,
                loading: false
            };
        case UPDATE_FITCATEGORY_REQUEST:
            return {
                loading: true,
            };
        case UPDATE_FITCATEGORY_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case UPDATE_FITCATEGORY_ERROR:
            return {
                ...state,
                loading: false
            };

        default:
            return state;
    }
}