import {
    GET_FACTOR_REQUEST, GET_FACTOR_ERROR, GET_FACTOR_SUCCESS,
    ADD_FACTOR_REQUEST, ADD_FACTOR_ERROR, ADD_FACTOR_SUCCESS,
    UPDATE_FACTOR_REQUEST, UPDATE_FACTOR_ERROR, UPDATE_FACTOR_SUCCESS,
    DELETE_FACTOR_REQUEST, DELETE_FACTOR_ERROR, DELETE_FACTOR_SUCCESS
} from "./types";
import { BASEURL } from "../../constant/config.js";

const axios = require("axios");

export const getFactor = (token, menu_id) => (dispatch) => {
    dispatch({ type: GET_FACTOR_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/adminFactor`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { menu_id }
    })
        .then((response) => {
            return dispatch({ type: GET_FACTOR_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: GET_FACTOR_ERROR,
                payload: error.response,
            });
        });
};

export const addFactor = (token, data, menu_id) => (dispatch) => {
    dispatch({ type: ADD_FACTOR_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}create/adminFactor`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { factor_name: data.factorMethodTypeName, is_active: data.check, menu_id },
    })
        .then((response) => {

            return dispatch({ type: ADD_FACTOR_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: ADD_FACTOR_ERROR,
                payload: error.response,
            });
        });
};

export const updateFactor = (token, data, id) => (dispatch) => {
    dispatch({ type: UPDATE_FACTOR_REQUEST });
    return axios({
        method: "PUT",
        url: `${BASEURL}update/adminFactor/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { factor_name: data.factorMethodTypeName, is_active: data.check },
    })
        .then((response) => {

            return dispatch({ type: UPDATE_FACTOR_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: UPDATE_FACTOR_ERROR,
                payload: error.response,
            });
        });
};

export const deleteFactor = (token, id) => (dispatch) => {
    dispatch({ type: DELETE_FACTOR_REQUEST });
    return axios({
        method: "DELETE",
        url: `${BASEURL}delete/adminFactor/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
    })
        .then((response) => {

            return dispatch({ type: DELETE_FACTOR_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: DELETE_FACTOR_ERROR,
                payload: error.response,
            });
        });
};
