import { GET_PRODUCTAVAILABLE_REQUEST, GET_PRODUCTAVAILABLE_SUCCESS, GET_PRODUCTAVAILABLE_ERROR } from "./types"
import { BASEURL } from "../../constant/config.js";
const axios = require("axios");




export const getAvailableProduct = (token, company_id, item_code, locationid ,locationID) => (dispatch) => {
    
    let location_id = locationid || locationID ;
    dispatch({ type: GET_PRODUCTAVAILABLE_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/productAvailForInvAdj`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { company_id, item_code, only_parent: 'N', location_id}
    })
        .then((response) => {
            return dispatch({ type: GET_PRODUCTAVAILABLE_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: GET_PRODUCTAVAILABLE_ERROR,
                payload: error.response,
            });
        });
};