import {
    GET_CUT_REQUEST, GET_CUT_SUCCESS, GET_CUT_ERROR, ADD_CUT_ERROR, ADD_CUT_REQUEST, ADD_CUT_SUCCESS, UPDATE_CUT_ERROR, UPDATE_CUT_REQUEST, UPDATE_CUT_SUCCESS, DELETE_CUT_REQUEST, DELETE_CUT_SUCCESS, DELETE_CUT_ERROR
} from "../actions/types"

export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case GET_CUT_REQUEST:
            return {
                loading: true,
            }
        case GET_CUT_SUCCESS:
            return {
                ...state,
                loading: false,
                payload
            }
        case GET_CUT_ERROR:
            return {
                ...state,
                loading: false
            }
        case ADD_CUT_REQUEST:
            return {
                loading: true,
            };
        case ADD_CUT_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case ADD_CUT_ERROR:
            return {
                ...state,
                loading: false
            };
        case DELETE_CUT_REQUEST:
            return {
                loading: true,
            };
        case DELETE_CUT_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case DELETE_CUT_ERROR:
            return {
                ...state,
                loading: false
            };
        case UPDATE_CUT_REQUEST:
            return {
                loading: true,
            };
        case UPDATE_CUT_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case UPDATE_CUT_ERROR:
            return {
                ...state,
                loading: false
            };

        default:
            return state;
    }

}