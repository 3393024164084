import { SearchOutlined, FilterFilled } from '@ant-design/icons';
import { Input } from "antd";
import moment from 'moment';
import numeral from 'numeral';

import { Link } from "react-router-dom";
const saleOrderNewColumns = [
    {
        title: '#',
        dataIndex: 'index',
        key: 'index',
        width: 50,
        render: (text, record, index) => {
            return index + 1;
        },
    },
    {
        title: "Order ID",
        dataIndex: "order_header_id",
        key: "order_header_id",
        render: (text, record) => (
            <Link to={`sale-order?mode=${"edit"}&order_id=${record.order_header_id}&customer_id=${record.customer_id}`}>{text}</Link>
        ),
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        allowClear
                        autoFocus
                        className='pop-search-gen pop-11em'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.order_header_id
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
    },

    {
        title: "Order No",
        dataIndex: "order_header_no",
        key: "order_header_no",
        render: (text, record) => (
            <Link to={`sale-order?mode=${"edit"}&order_id=${record.order_header_id}&customer_id=${record.customer_id}`}>{text}</Link>
        ),
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        allowClear
                        autoFocus
                        className='pop-search-gen pop-11em'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) => {
            return record.order_header_no.toLowerCase().includes(value.toLowerCase());
        },
    },
    {
        title: "Order Date",
        dataIndex: "order_date",
        key: "order_date",
        render: (text, record) => (
            <Link to={`sale-order?mode=${"edit"}&order_id=${record.order_header_id}&customer_id=${record.customer_id}`}>{moment(text).format("MM-DD-YYYY")}</Link>
        ),
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        allowClear
                        autoFocus
                        className='pop-search-gen pop-11em'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.order_date
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
    },
    {
        title: "PO #",
        dataIndex: "reference_no",
        key: "reference_no",
        render: (text, record) => (
            <Link to={`sale-order?mode=${"edit"}&order_id=${record.order_header_id}&customer_id=${record.customer_id}`}>{text}</Link>
        ),
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        allowClear
                        autoFocus
                        className='pop-search-gen pop-11em'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record.reference_no
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
    },
    {
        title: "Customer",
        dataIndex: "customer_name",
        key: "customer_name",
        render: (text, record) => (
            <Link to={`sale-order?mode=${"edit"}&order_id=${record.order_header_id}&customer_id=${record.customer_id}`}>{text}</Link>
        ),
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        allowClear
                        autoFocus
                        className='pop-search-gen pop-11em'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record?.customer_name?.toString()?.toLowerCase()?.includes(value.toLowerCase()),
    },


    {
        title: "Term",
        dataIndex: "term_name",
        key: "term_name",
        render: (text, record) => (
            <Link to={`sale-order?mode=${"edit"}&order_id=${record.order_header_id}&customer_id=${record.customer_id}`}>{text}</Link>
        ),
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        allowClear
                        autoFocus
                        className='pop-search-gen pop-11em'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record?.term_name?.toString()?.toLowerCase()?.includes(value.toLowerCase()),
    },

    {
        title: "Region Name",
        dataIndex: "region_name",
        key: "region_name",
        render: (text, record) => (
            <Link to={`sale-order?mode=${"edit"}&order_id=${record.order_header_id}&customer_id=${record.customer_id}`}>{text}</Link>
        ),
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        allowClear
                        autoFocus
                        className='pop-search-gen pop-11em'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record?.region_name?.toString()?.toLowerCase()?.includes(value.toLowerCase()),
    },

    {
        title: "SalePerson Name",
        dataIndex: "salesperson_name",
        key: "salesperson_name",
        render: (text, record) => (
            <Link to={`sale-order?mode=${"edit"}&order_id=${record.order_header_id}&customer_id=${record.customer_id}`}>{text}</Link>
        ),
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        allowClear
                        autoFocus
                        className='pop-search-gen pop-11em'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record?.salesperson_name?.toString()?.toLowerCase()?.includes(value.toLowerCase()),
    },

    {
        title: "Gender Name",
        dataIndex: "gender_name",
        key: "gender_name",
        render: (text, record) => (
            <Link to={`sale-order?mode=${"edit"}&order_id=${record.order_header_id}&customer_id=${record.customer_id}`}>{text}</Link>
        ),
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => {
            return (
                <>
                    <Input
                        allowClear
                        autoFocus
                        className='pop-search-gen pop-11em'
                        placeholder="Type text here"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    />
                </>
            );
        },
        filterIcon: (filtered) =>
            filtered ? <FilterFilled /> : <SearchOutlined />,
        onFilter: (value, record) =>
            record?.gender_name?.toString()?.toLowerCase()?.includes(value.toLowerCase()),
    },
];


export { saleOrderNewColumns };
