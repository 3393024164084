import {
    GET_ALL_RELOCATEITEMS_ERROR, GET_ALL_RELOCATEITEMS_REQUEST, GET_ALL_RELOCATEITEMS_SUCCESS, ADD_ALL_RELOCATEITEMS_SUCCESS, ADD_ALL_RELOCATEITEMS_REQUEST, ADD_ALL_RELOCATEITEMS_ERROR
} from "./types";

import { BASEURL } from "../../constant/config.js";

const axios = require("axios");


export const getAllRelocateItems = (token, data) => (dispatch) => {
    dispatch({ type: GET_ALL_RELOCATEITEMS_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/GetReAllocation`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: data
    })
        .then((response) => {
            return dispatch({ type: GET_ALL_RELOCATEITEMS_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: GET_ALL_RELOCATEITEMS_ERROR,
                payload: error.response,
            });
        });
};

export const addRelocateItems = (token, data) => (dispatch) => {
    dispatch({ type: ADD_ALL_RELOCATEITEMS_REQUEST });
    return axios({
        method: "PUT",
        url: `${BASEURL}update/updateSaleOrderLines/1150`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { dataLine: data }
    })
        .then((response) => {
            return dispatch({ type: ADD_ALL_RELOCATEITEMS_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: ADD_ALL_RELOCATEITEMS_ERROR,
                payload: error.response,
            });
        });
};