import { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";

import { createAdminMenu, deleteAdminMenu, getAllAdminMenu, getAllParentMenu, updateAdminMenu } from '../../redux/actions/adminMenu.action';
import { getAdminMenuForm } from "../../redux/actions/adminMenuForm.action"
import { ReadCookie } from "../../utils/readCookie";
import { getRights } from "../../redux/actions/userRights.action";

import CrudButtons from "../../components/CrudButtons/CrudButtons";
import PageHeader from "../../components/PageHeader/PageHeader";
import Tables from '../../components/Table/Tables';

import { Input, Select, Checkbox, Row, Col, Form } from 'antd';
import notification from '../../utils/notification';
import { menuColumns } from "../../utils/cols"

import "../../screens/Product/product.css";
import "../Style/style.css";
import { TabTitle } from '../../utils/GeneralFunction';

const { Option } = Select;

export default function SaleOrder({ token }) {
    TabTitle("Admin Menu")
    const menuNameInput = useRef(null);
    const menuId = ReadCookie('menuId')
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const { adminMenuForm, userRights, adminMenu, parentMenu } = useSelector(state => state);
    const [formState, setFormState] = useState('view');
    const [disable, setDisable] = useState(false);
    const [menuObj, setMenuObj] = useState(null);
    const [menuName, setMenuName] = useState('')
    const [adminModule, setAdminModule] = useState(null)
    const [adminMenuType, setAdminMenuType] = useState(null)
    const [adminMenuSelect, setAdminMenuSelect] = useState(null)
    const [sequence, setSequence] = useState(null)
    const [runtimeName, setRuntimeName] = useState(null)
    const [parentMenuSelect, setParentMenuSelect] = useState(null)
    const [check, setCheck] = useState(true);
    const [menuID, setMenuID] = useState(null);
    const [adminMenus, setAdminMenus] = useState(false);

    function onChange(value, type) {

        if (type === "adminModule") {
            setAdminModule(value)
        }
        if (type === "adminMenuType") {
            setAdminMenuType(value)
            if (adminMenuType === 1) {
                setParentMenuSelect(null)
            }

        }
        if (type === "adminMenuSelect") {
            setAdminMenuSelect(value)
        }
        if (type === "parentMenu") {
            setParentMenuSelect(value)
        }
    }

    function onSearch(val) {

    }

    const handleDelete = () => {
        if (menuObj) {
            dispatch(deleteAdminMenu(token, menuObj.menu_id)).then((res) => {
                if (res.type === "DELETE_ADMIN_MENU_SUCCESS") {
                    notification("success", "Admin Menu ", res.payload?.data);
                }
                if (res.type === "DELETE_ADMIN_MENU_ERROR") {
                    notification("error", " Admin Menu Error");
                }
                form.setFieldsValue({
                    AdminMenuType: null,
                    adminMenu: null,
                    adminModule: null,
                    menuName: null,
                    sequence: null,
                    runtimeName: null,
                    active: null

                });
                setMenuObj(null)
                dispatch(getAllAdminMenu(token)).then((res) => {
                    setAdminMenus(res?.payload?.data)
                })
            })
        }
    }

    const formSave = async () => {
        if (formState === "edit") {
            dispatch(updateAdminMenu(token, menuName, adminModule, adminMenuType, adminMenuSelect, check, sequence, runtimeName, parentMenuSelect, menuID)).then((res) => {
                if (res.type === "UPDATE_ADMIN_MENU_SUCCESS") {
                    dispatch(getAllAdminMenu(token)).then((res) => {
                        setAdminMenus(res?.payload?.data)
                    })
                }
            })

        }

        if (formState === "add") {
            await form.validateFields()
            dispatch(createAdminMenu(token, { menuName, adminModule, adminMenuType, adminMenuSelect, check, sequence, parentMenuSelect, runtimeName })).then((res) => {
                if (res.type === "ADD_ADMIN_MENU_ERROR") {
                    notification("error", "Admin Menu", "Failed")
                }
                if (res.type === "ADD_ADMIN_MENU_SUCCESS") {
                    form.setFieldsValue({
                        AdminMenuType: null,
                        adminMenu: null,
                        adminModule: null,
                        menuName: null,
                        sequence: null,
                        runtimeName: null,
                        parentMenu: null
                    });
                    setFormState("view")
                    setMenuObj(null)
                    setCheck(false)
                    dispatch(getAllAdminMenu(token)).then((res) => {
                        setAdminMenus(res?.payload?.data)
                    })
                    notification("success", "Admin Menu", res?.payload?.data)
                }
            })
        }
    }

    const filterReset = () => {
        setFormState("view")
        setMenuObj(null)

    }

    useEffect(() => {
        dispatch(getAdminMenuForm(token))
        dispatch(getAllAdminMenu(token)).then((res) => {
            setAdminMenus(res?.payload?.data)
        })
    }, [])

    useEffect(() => {
        if (formState === "add") {
            menuNameInput.current.focus();
            form.setFieldsValue({
                menuName: null,
                runtimeName: null,
                sequence: null,
                AdminMenuType: null,
                adminMenu: null,
                adminModule: null,
                parentMenu: null
            })
            setMenuObj(null)
        }
    }, [formState]);

    useLayoutEffect(() => {
        dispatch(getRights(token, menuId)).then((res) => {
            if (res.type === "GET_USER_RIGHTS_SUCCESS") {
                if (!res?.payload.can_add) {
                    setFormState("table")
                }
            }
        });
    }, [])

    useEffect(() => {
        if (menuName === "") {
            setDisable(true)
        } else {
            setDisable(false)
        }
    }, [menuName])

    useEffect(() => {
        if (formState === "add" || formState === "edit") {
            dispatch(getAllParentMenu(token, { adminModule, adminMenuType }))
            form.setFieldsValue({
                parentMenu: null,
            });
        }

    }, [adminMenuType, adminModule])

    useEffect(() => {
        const type = "view"
        if (menuObj) {

            setFormState('view')
            dispatch(getAllParentMenu(token, menuObj, type))
            form.setFieldsValue({
                menuName: menuObj.menu_name,
                sequence: menuObj.seq,
                runtimeName: menuObj.runtime_name,
                active: menuObj.is_active,
                AdminMenuType: menuObj.menu_type_id,
                parentMenu: menuObj.parent_menu_id,
                adminModule: menuObj.module_id
            });
            setCheck(menuObj.is_active)
            setMenuID(menuObj.menu_id)
            setMenuName(menuObj.menu_name)
            setAdminMenuType(menuObj.menu_type_id)
            setAdminModule(menuObj.module_id)
            setSequence(menuObj.seq)
            setRuntimeName(menuObj.runtime_name)
        }
    }, [menuObj]);


    return <>
        <div className='styleContainer' >
            <div className='styleBody h-100'>
                <PageHeader
                    title={"Admin Menu"} />
                <div className='bodyStyle'>
                    <div className='crudDiv' >
                        <CrudButtons
                            rights={userRights?.payload}
                            formType={formState}
                            save={formSave}
                            formState={setFormState}
                            onDelete={handleDelete}
                            disable={disable}
                            reset={filterReset} />

                        <Row className='tables-main-row-new' >
                            <Col xs={24} sm={24} md={24} lg={{ span: 16, order: 2 }} xl={{ span: 16, order: 2 }} >

                                <Form
                                    onFinish={formSave}
                                    form={form}>

                                    <Form.Item
                                        label="Menu Name"
                                        name="menuName"
                                        initialValue={form.getFieldValue().menuName}
                                        labelCol={{ xs: { span: 24 }, sm: { span: 24 }, md: { span: 8 }, lg: { span: 8 }, xl: { span: 6 } }}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Menu Name is required"
                                            }
                                        ]}>
                                        <Input
                                            size='small'
                                            allowClear
                                            disabled={!(formState === "add" || formState === "edit")}
                                            onChange={(e) => setMenuName(e.target.value)}
                                            className='customInputs no-border-first'
                                            ref={menuNameInput}
                                            value={menuName} />
                                    </Form.Item>

                                    <Form.Item
                                        label="Admin Module"
                                        labelCol={{ xs: { span: 24 }, sm: { span: 24 }, md: { span: 8 }, lg: { span: 8 }, xl: { span: 6 } }}
                                        name="adminModule"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please select Admin module"
                                            }
                                        ]}>
                                        <Select
                                            size='small'
                                            allowClear
                                            disabled={!(formState === "add" || formState === "edit")}
                                            showSearch

                                            optionFilterProp="children"
                                            onChange={(e) => onChange(e, "adminModule")}
                                            onSearch={onSearch}
                                            defaultValue={form.getFieldValue().adminModule}
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            filterSort={(optionA, optionB) =>
                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                            }
                                        >
                                            {adminMenuForm?.payload?.adminModule.map((item) => <Option value={item.module_id} key={item.module_id}>{item.module_name}</Option>)}
                                        </Select>
                                    </Form.Item>

                                    <Form.Item
                                        label="Admin Menu Type"
                                        name="AdminMenuType"
                                        labelCol={{ xs: { span: 24 }, sm: { span: 24 }, md: { span: 8 }, lg: { span: 8 }, xl: { span: 6 } }}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please Select Admin Menu Type"
                                            }
                                        ]}>
                                        <Select
                                            size='small'
                                            allowClear
                                            disabled={!(formState === "add" || formState === "edit") || adminModule === null}
                                            showSearch
                                            optionFilterProp="children"
                                            onChange={(e) => onChange(e, "adminMenuType")}
                                            filterSort={(optionA, optionB) =>
                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                                            onSearch={onSearch}
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            defaultValue={form.getFieldValue().AdminMenuType}>
                                            {adminMenuForm?.payload?.adminMenuType.map((item) => <Option value={item.menu_type_id} key={item.menu_type_id}>{item.menu_type_name}</Option>)}

                                        </Select>
                                    </Form.Item>

                                    <Form.Item
                                        label="Parent Menu"
                                        name="parentMenu"
                                        labelCol={{ xs: { span: 24 }, sm: { span: 24 }, md: { span: 8 }, lg: { span: 8 }, xl: { span: 6 } }}>
                                        <Select
                                            size='small'
                                            loading={parentMenu?.loading}
                                            allowClear
                                            disabled={(!(formState === "add" || formState === "edit")) || adminMenuType === 1 || parentMenu?.loading}
                                            showSearch
                                            optionFilterProp="children"
                                            onChange={(e) => onChange(e, "parentMenu")}
                                            onSearch={onSearch}
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            defaultValue={form.getFieldValue().parentMenu}
                                            filterSort={(optionA, optionB) =>
                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                                        >
                                            {parentMenu?.payload?.map((item) => <Option value={item.menu_id}>{item.menu_name}</Option>)}
                                        </Select>
                                    </Form.Item >

                                    <Form.Item
                                        label="Sequence"
                                        name="sequence"
                                        initialValue={form.getFieldValue().sequence}
                                        labelCol={{ xs: { span: 24 }, sm: { span: 24 }, md: { span: 8 }, lg: { span: 8 }, xl: { span: 6 } }}
                                        rules={[
                                            {
                                                required: true,
                                                pattern: new RegExp(/\d+/g),
                                                message: "Must be a Number!"
                                            }
                                        ]}>
                                        <Input
                                            size='small'
                                            allowClear
                                            disabled={!(formState === "add" || formState === "edit")}
                                            onChange={(e) => setSequence(e.target.value)} value={sequence}
                                            className='customInputs no-border-first'
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        allowClear
                                        label="Runtime Name "
                                        name="runtimeName"
                                        initialValue={form.getFieldValue().runtimeName}
                                        labelCol={{ xs: { span: 24 }, sm: { span: 24 }, md: { span: 8 }, lg: { span: 8 }, xl: { span: 6 } }}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Runtime Name is required "
                                            }
                                        ]}>
                                        <Input
                                            size='small'
                                            disabled={!(formState === "add" || formState === "edit")}
                                            onChange={(e) => setRuntimeName(e.target.value)} value={runtimeName}
                                            className='customInputs'
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        label="Active "
                                        name="active"
                                        labelCol={{ xs: { span: 24 }, sm: { span: 24 }, md: { span: 8 }, lg: { span: 8 }, xl: { span: 6 } }}>
                                        <Checkbox disabled={!(formState === "add" || formState === "edit")} onChange={(e) => setCheck(e.target.checked)} checked={check} ></Checkbox>
                                    </Form.Item>

                                </Form>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={{ span: 8, order: 1 }} xl={{ span: 6, order: 1 }} >
                                <Tables
                                    formState={formState}
                                    type="adminMenu"
                                    menuObj={setMenuObj}
                                    scrollX={"20rem"}
                                    scroll={620}
                                    perPage={20}
                                    col={menuColumns}
                                    source={adminMenus}
                                    load={adminMenu.loading}
                                    paginate
                                />
                            </Col>


                        </Row>
                    </div>
                </div>
            </div>
        </div>
    </>;
}
