import {
	ADD_EXPENSE_LINE_REQUEST,
	ADD_EXPENSE_LINE_SUCCESS,
	ADD_EXPENSE_LINE_ERROR,
	UPDATE_EXPENSE_LINE_REQUEST,
	UPDATE_EXPENSE_LINE_SUCCESS,
	UPDATE_EXPENSE_LINE_ERROR,
	DELETE_EXPENSE_LINE_ERROR,
	DELETE_EXPENSE_LINE_SUCCESS,
	DELETE_EXPENSE_LINE_REQUEST,
	GET_ONE_EXPENSE_LINE_DETAIL_REQUEST,
	GET_ONE_EXPENSE_LINE_DETAIL_SUCCESS,
	GET_ONE_EXPENSE_LINE_DETAIL_ERROR,
} from "../actions/types";

export default function (state = [], action) {
	const { type, payload } = action;
	switch (type) {
		case ADD_EXPENSE_LINE_REQUEST:
			return {
				loading: true,
			};
		case ADD_EXPENSE_LINE_SUCCESS:
			return {
				...state,
				loading: false,
				payload: payload,
			};
		case ADD_EXPENSE_LINE_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};

		case UPDATE_EXPENSE_LINE_REQUEST:
			return {
				loading: true,
			};
		case UPDATE_EXPENSE_LINE_SUCCESS:
			return {
				...state,
				loading: false,
				payload: payload,
			};
		case UPDATE_EXPENSE_LINE_ERROR:
			return {
				...state,
				loading: false,
				payload: payload,
			};

		case DELETE_EXPENSE_LINE_REQUEST:
			return {
				loading: true,
			};
		case DELETE_EXPENSE_LINE_SUCCESS:
			return {
				...state,
				loading: false,
				payload,
			};
		case DELETE_EXPENSE_LINE_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};

		case GET_ONE_EXPENSE_LINE_DETAIL_REQUEST:
			return {
				loading: true,
			};
		case GET_ONE_EXPENSE_LINE_DETAIL_SUCCESS:
			return {
				...state,
				loading: false,
				payload,
			};
		case GET_ONE_EXPENSE_LINE_DETAIL_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};
		default:
			return state;
	}
}