import React from 'react'
import { Skeleton, Space } from 'antd';
import "./skeleton.css";

export default function SkeletonLoad() {
    return (
        <div className='skeleton'>
            {/* <div className='btnSkeleton'>
                <Skeleton.Avatar active={true} shape='circle' />
            </div> */}
            <div className='headerSkeleton'>
                <div className='w-10-percent'><Skeleton.Button className='b-radius-10' active={true} block /></div>
                <div className='w-10-percent'><Skeleton.Button className='b-radius-10' active={true} block /></div>
                <div className='w-10-percent'><Skeleton.Button className='b-radius-10' active={true} block /></div>
                <div className='w-10-percent'><Skeleton.Button className='b-radius-10' active={true} block /></div>
                <div className='w-10-percent'><Skeleton.Button className='b-radius-10' active={true} block /></div>
                <div className='w-10-percent'><Skeleton.Button className='b-radius-10' active={true} block /></div>
                <div className='w-10-percent'><Skeleton.Button className='b-radius-10' active={true} block /></div>
                <div className='w-10-percent'><Skeleton.Button className='b-radius-10' active={true} block /></div>
                <div className='w-10-percent'><Skeleton.Button className='b-radius-10' active={true} block /></div>
            </div>
            <div className='bodySkeleton'>
                <Skeleton.Input className='b-radius-10 widthmax mb-1' active={true} />             
                <Skeleton.Input className='b-radius-10 w-100-percent mb-1 widthmax' active={true} />
                <Skeleton.Input className='b-radius-10 w-100-percent mb-1 widthmax' active={true} />
                <Skeleton.Input className='b-radius-10 w-100-percent mb-1 widthmax' active={true} />
                <Skeleton.Input className='b-radius-10 w-100-percent mb-1 widthmax' active={true} />
                <Skeleton.Input className='b-radius-10 w-100-percent mb-1 widthmax' active={true} />
                <Skeleton.Input className='b-radius-10 w-100-percent mb-1 widthmax' active={true} />
                <Skeleton.Input className='b-radius-10 w-100-percent mb-1 widthmax' active={true} />
                <Skeleton.Input className='b-radius-10 w-100-percent mb-1 widthmax' active={true} />
                <Skeleton.Input className='b-radius-10 w-100-percent mb-1 widthmax' active={true} />
                <Skeleton.Input className='b-radius-10 w-100-percent mb-1 widthmax' active={true} />
                <Skeleton.Input className='b-radius-10 w-100-percent mb-1 widthmax' active={true} />
            </div>
        </div >
    )
}
