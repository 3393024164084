import {
  GET_CONTROL_SHEET_LOG_REQUEST,
  GET_CONTROL_SHEET_LOG_SUCCESS,
  GET_CONTROL_SHEET_LOG_ERROR
} from "./types";
import axios from "axios";
import { BASEURL } from "../../constant/config.js";
import moment from "moment";

export const getcontrolSheetLog = (token, values) => (dispatch) => {
  dispatch({ type: GET_CONTROL_SHEET_LOG_REQUEST });
  const newData = {
    ship_header_no: (values.pt_no === undefined || values.pt_no === "") ? null : values.pt_no,
    log_date: moment(values.log_date).format("YYYY-MM-DD"),
};
  return axios({
    method: "POST",
    url: `${BASEURL}getAll/ControlSheetLog`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: newData
  })
    .then((response) => {
      return dispatch({
        type: GET_CONTROL_SHEET_LOG_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_CONTROL_SHEET_LOG_ERROR,
        payload: [],
      });
    });
};
