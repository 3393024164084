import PageHeader from '../../components/PageHeader/PageHeader';
import "../Style/style.css";
import { Row, Col, Form, Input, Tabs, Divider, Select, DatePicker } from 'antd';
import CrudButtons from "../../components/CrudButtons/CrudButtons";
import { useState, useLayoutEffect, useRef, useEffect } from 'react';
import { getRights } from "../../redux/actions/userRights.action";
import { useSelector, useDispatch } from 'react-redux';
import { ReadCookie, } from '../../utils/readCookie';
import Tables from "../../components/Table/Tables"
import { logColumns, salesTargetColumn } from "../../utils/cols";
import { getLog } from "../../redux/actions/log.action"
import notification from '../../utils/notification'
import { getGender } from '../../redux/actions/gender.action';
import { getRegion } from '../../redux/actions/region.action';
import { getAllScmBrand } from '../../redux/actions/scmBrand.action';
import { getScmFigure } from '../../redux/actions/scmFigure.action';
import { getScmTargetMonth } from '../../redux/actions/scmTargetMonth.action';
import { getScmTargetYear } from '../../redux/actions/scmTargetYear.action';
import { addSalesTarget, getSalesTarget, updateSalesTarget, deleteSalesTarget } from '../../redux/actions/salesTarget.action';
import '../User/user.css'
import { TabTitle } from '../../utils/GeneralFunction';
import moment from 'moment';


const { TabPane } = Tabs;
const { Option } = Select


const SalesTarget = ({ token }) => {
    TabTitle("Sales Target")
    const { userRights, log, gender, scmBrand: brand, scmFigure: fig, scmMonth: month, scmYear: year, region: reg, salesTarget: scmTarget, } = useSelector(state => state)
    const [minimize, setMinimize] = useState(false);
    const [formState, setFormState] = useState('view');
    const [form] = Form.useForm();
    const targetInputRef = useRef(null);
    const [brand_id, setBrand] = useState(null)
    const [figure, setFigure] = useState(null)
    const [region_id, setRegion] = useState(null)
    const [gender_id, setGender] = useState(null)
    const [targer_year, setYear] = useState(null)
    const [target_month, setMonth] = useState("")
    const [target_amount, setAmount] = useState(null)
    const [sales_targets, setTargets] = useState("")
    const [target_date, setDate] = useState("")
    const [disable, setDisable] = useState(false);
    const [locationObj, setLocationObj] = useState(null);
    const [targetObj, setTargetObj] = useState(null)
    const dispatch = useDispatch();
    const id = ReadCookie('menuId');



    useEffect(() => {
        dispatch(getSalesTarget(token, id))
    }, [token])
    useEffect(() => {
        dispatch(getGender(token, id))
    }, [token])
    useEffect(() => {
        dispatch(getRegion(token, id))
    }, [token])
    useEffect(() => {
        dispatch(getAllScmBrand(token, id))
    }, [token])
    useEffect(() => {
        dispatch(getScmFigure(token, id))
    }, [token])
    useEffect(() => {
        dispatch(getScmTargetMonth(token, id))
    }, [token])
    useEffect(() => {
        dispatch(getScmTargetYear(token, id))
    }, [token])

    const handleDelete = () => {
        if (targetObj) {
            dispatch(deleteSalesTarget(token, targetObj.target_id)).then((res) => {
                if (res.type === "DELETE_SALES_TARGET_SUCCESS") {
                    notification("success", "Sale Target Deleted", "Sales Target deleted successfully!");
                }
                if (res.type === "DELETE_SALES_TARGET_ERROR") {
                    notification("error", res?.payload?.request?.statusText, res?.payload?.data?.data);
                }
                form.setFieldsValue({
                    bid: null,
                    rid: null,
                    fid: null,
                    mid: "",
                    yid: null,
                    gid: null,
                    target: "",
                    date: "",
                    amount: null
                })
                setBrand(null)
                setFigure(null)
                setRegion("")
                setAmount(null)
                setTargets(null)
                setGender(null)
                setDate("")
                setYear(null)
                setMonth("")
            })
        }
        setFormState("view")
        dispatch(getSalesTarget(token, id));
    }

    useEffect(() => {
        if (targetObj) {
            setFormState('view')
            form.setFieldsValue({
                id: targetObj.target_id,
                bid: parseInt(targetObj.brand_id),
                fid: parseInt(targetObj.figure),
                rid: targetObj.region_id,
                gid: parseInt(targetObj.gender_id),
                mid: targetObj.target_month,
                yid: targetObj.targer_year,
                amount: targetObj.target_amount,
                
                
            });
            setBrand(targetObj.brand_id)
            setFigure(parseInt(targetObj.figure))
            setGender(parseInt(targetObj.gender_id))
            setRegion(targetObj.region_id)
            setMonth(targetObj.target_month)
            setYear(targetObj.targer_year)
            setAmount(targetObj.target_amount)
            setDate(targetObj.target_date)

        }

    }, [targetObj]);

    useEffect(() => {
        if (brand_id === '' || brand_id === null) {
            setDisable(true)
        } else {
            setDisable(false)
        }
    }, [brand_id])




    useLayoutEffect(() => {
        dispatch(getRights(token, id)).then((res) => {
            if (res.type === "GET_USER_RIGHTS_SUCCESS") {
                if (!res?.payload.can_add) {
                    setFormState("table")
                }
            }
        });
    }, [])
    useEffect(() => {
        dispatch(getLog(token))
    }, [dispatch, token])

    const selectHandlerforBrand = (e) => {
        setBrand(e)
    }
    const selectHandlerforFigure = (e) => {
        setFigure(e)
    }
    const selectHandlerforRegion = (e) => {
        setRegion(e)
    }
    const selectHandlerforGender = (e) => {
        setGender(e)
    }
    const selectHandlerforMonth = (e) => {
        setMonth(e)
    }
    const selectHandlerforYear = (e) => {
        setYear(e)
    }


    const formSave = async () => {

        await form.validateFields().then((values) => {
            if (formState === "add") {
                dispatch(addSalesTarget(token, { brand_id, region_id, gender_id, figure, target_amount, target_month, targer_year, target_date }, id)).then((res) => {

                    if (res.type === "ADD_SALES_TARGET_ERROR") {
                        notification("error", "Not Created", res?.payload?.data?.data)
                    }
                    if (res.type === "ADD_SALES_TARGET_SUCCESS") {
                        notification("success", "Sales Target Created", "Target created successfully!");
                        form.setFieldsValue({
                            bid: null,
                            rid: null,
                            fid: null,
                            mid: "",
                            yid: null,
                            gid: null,
                            target: "",
                            date: "",
                            amount: null
                        })
                        setBrand(null)
                        setFigure(null)
                        setRegion(null)
                        setAmount(null)
                        setTargets("")
                        setGender(null)
                        setDate("")
                        setYear(null)
                        setMonth("")
                    }
                    dispatch(getSalesTarget(token, id))

                })

            }
            if (formState === "edit") {
                dispatch(updateSalesTarget(token, { brand_id, figure, region_id, gender_id, targer_year, target_month, target_amount, target_date }, targetObj.target_id)).then((res) => {
                    if (res.type === "UPDATE_SALES_TARGET_ERROR") {
                        notification("error", "Update", "Something went wrong")
                    }
                    if (res.type === "UPDATE_SALES_TARGET_SUCCESS") {
                        notification("success", "Sales Target Updated", "Record updated successfully")
                    }
                    dispatch(getSalesTarget(token, id))
                })
            }

        }).catch((err) => {

        })
    }
    useEffect(() => {
        if (formState === "add") {
            targetInputRef.current.focus()
        }
    }, [formState])




    const filterReset = () => {
        setFormState("view")
        setLocationObj(null)
        form.setFieldsValue({
            bid: null,
            rid: null,
            fid: null,
            mid: "",
            yid: null,
            gid: null,
            target: "",
            date: null,
            amount: null
        })
        setBrand(null)
        setFigure(null)
        setRegion(null)
        setAmount(null)
        setTargets("")
        setGender(null)
        setDate(null)
        setYear(null)
        setMonth("")
    }



    return (
        <div className='styleContainer'>
            <div className='styleBody h-100'>
                <PageHeader title={"Sales Target"} minimize={setMinimize} check={minimize} />
                {
                    !minimize &&
                    <div className='bodyStyle' >
                        <div className='crudDiv' ><CrudButtons formType={formState} rights={userRights?.payload} formState={setFormState} disable={disable} reset={filterReset} save={formSave} onDelete={handleDelete} /></div>
                        <div className='styleContent' >
                            <Row className='tables-main-row-new' >
                                <Col xs={24} sm={24} md={10} lg={8} xl={6}>
                                    <div className='styleTable' >
                                        <Tables type="target" formState={formState} targetObj={setTargetObj} scrollX={"20rem"} scroll={500} perPage={10} source={scmTarget?.payload?.data} col={salesTargetColumn} load={scmTarget.loading} paginate />
                                    </div>
                                </Col>
                                <Col xs={24} sm={24} md={14} lg={16} xl={18}>
                                    <div className='styleForm w-100'   >
                                        <div className='testStyle' >
                                            <Form
                                                form={form}
                                                name="basic"
                                            
                                            >
                                                <div className='customRow styleFormUpper ' ></div>
                                                <div className='userForm' >
                                                    <div className="container-fluid pbpx-10 ptpx-15" style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                                                        {locationObj !== null && !(formState === "add") &&
                                                            <div className='customCols slc'>
                                                                <Form.Item
                                                                    label="ID"
                                                                    name="id"
                                                                    initialValue={form.getFieldValue().id}
                                                                    labelCol={{ span: 10 }}
                                                                    wrapperCol={{ span: 14 }}

                                                                >
                                                                    <Input size='small' className='styleInput id-field-style' readOnly />

                                                                </Form.Item>
                                                            </div>


                                                        }


                                                        <div className='customCols slc'>
                                                            <Form.Item
                                                                name="bid"
                                                                label="Brand"
                                                                labelAlign='left'
                                                                labelCol={{ span: 8 }}
                                                                wrapperCol={{ span: 16 }}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: "Country id is required",
                                                                    },
                                                                ]}
                                                            >
                                                                <Select
                                                                    size='small'
                                                                    showSearch
                                                                    disabled={
                                                                        !(formState === "add" || formState === "edit")
                                                                    }
                                                                    optionFilterProp="children"

                                                                    
                                                                    ref={targetInputRef}
                                                                    loading={brand?.loading}
                                                                    filterOption={(input, option) =>
                                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                    }

                                                                    allowClear
                                                                    onSelect={(e) => selectHandlerforBrand(e)}
                                                                >
                                                                    {brand?.payload?.data?.map((brd) => {
                                                                        return (
                                                                            <Option value={brd.brand_id}>
                                                                                {brd.brand_name}
                                                                            </Option>
                                                                        );
                                                                    })}
                                                                </Select>
                                                            </Form.Item>
                                                        </div>
                                                        <div className='customCols slc'>
                                                            <Form.Item
                                                                name="rid"
                                                                label="Region"
                                                                labelAlign='left'
                                                                labelCol={{ span: 8 }}
                                                                wrapperCol={{ span: 16 }}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: "Country id is required",
                                                                    },
                                                                ]}
                                                            >
                                                                <Select
                                                                    size='small'
                                                                    showSearch

                                                                    disabled={
                                                                        !(formState === "add" || formState === "edit")
                                                                    }
                                                                    optionFilterProp="children"

                                                                    
                                                                    

                                                                    filterOption={(input, option) =>
                                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                    }

                                                                    allowClear
                                                                    onSelect={(e) => selectHandlerforRegion(e)}

                                                                >
                                                                    {reg?.payload?.data.map((re) => {
                                                                        return (
                                                                            <Option value={re.region_id}>
                                                                                {re.region_name}
                                                                            </Option>
                                                                        );
                                                                    })}
                                                                </Select>
                                                            </Form.Item>
                                                        </div>
                                                        <div className='customCols slc'>
                                                            <Form.Item
                                                                name="fid"
                                                                label="Figure"
                                                                labelAlign='left'
                                                                labelCol={{ span: 8 }}
                                                                wrapperCol={{ span: 16 }}
                                                            >
                                                                <Select
                                                                    size='small'
                                                                    showSearch
                                                                    loading={fig?.loading}
                                                                    disabled={
                                                                        !(formState === "add" || formState === "edit")
                                                                    }
                                                                    optionFilterProp="children"

                                                                    
                                                                    filterSort={(optionA, optionB) =>
                                                                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                                    }

                                                                    allowClear
                                                                    onSelect={(e) => selectHandlerforFigure(e)}
                                                                >
                                                                    {fig?.payload?.data?.map((fi) => {
                                                                        return (
                                                                            <Option value={fi.figure_id}>
                                                                                {fi.figure_name}
                                                                            </Option>
                                                                        );
                                                                    })}
                                                                </Select>
                                                            </Form.Item>
                                                        </div>
                                                        <div className='customCols slc'>
                                                            <Form.Item
                                                                name="mid"
                                                                label="Month"
                                                                labelAlign='left'
                                                                labelCol={{ span: 8 }}
                                                                wrapperCol={{ span: 16 }}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: "Country id is required",
                                                                    },
                                                                ]}
                                                            >
                                                                <Select
                                                                    size='small'
                                                                    showSearch

                                                                    disabled={
                                                                        !(formState === "add" || formState === "edit")
                                                                    }
                                                                    optionFilterProp="children"

                                                                    
                                                                    
                                                                    
                                                                    

                                                                    onSelect={(e) => selectHandlerforMonth(e)}


                                                                    allowClear

                                                                >
                                                                    {month?.payload?.data?.map((mon) => {
                                                                        return (
                                                                            <Option value={mon.month_name}>
                                                                                {mon.month_name}
                                                                            </Option>
                                                                        );
                                                                    })}
                                                                </Select>
                                                            </Form.Item>
                                                        </div>
                                                        <div className='customCols slc'>
                                                            <Form.Item
                                                                name="yid"
                                                                label="Year"
                                                                labelAlign='left'
                                                                labelCol={{ span: 8 }}
                                                                wrapperCol={{ span: 16 }}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: "Country id is required",
                                                                    },
                                                                ]}
                                                            >
                                                                <Select
                                                                    size='small'
                                                                    showSearch

                                                                    disabled={
                                                                        !(formState === "add" || formState === "edit")
                                                                    }
                                                                    optionFilterProp="children"

                                                                    
                                                                    

                                                                    filterOption={(input, option) =>
                                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                    }



                                                                    allowClear
                                                                    onSelect={(e) => selectHandlerforYear(e)}

                                                                >
                                                                    {year?.payload?.data.map((yr) => {
                                                                        return (
                                                                            <Option value={yr.year_id}>
                                                                                {yr.year_name}
                                                                            </Option>
                                                                        );
                                                                    })}
                                                                </Select>
                                                            </Form.Item>
                                                        </div>
                                                        <div className='customCols slc'>
                                                            <Form.Item
                                                                name="gid"

                                                                label="Gender"
                                                                labelAlign='left'
                                                                labelCol={{ span: 8 }}
                                                                wrapperCol={{ span: 16 }}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: "Country id is required",
                                                                    },
                                                                ]}
                                                            >
                                                                <Select
                                                                    size='small'
                                                                    showSearch

                                                                    disabled={
                                                                        !(formState === "add" || formState === "edit")
                                                                    }
                                                                    loading={gender?.loading}
                                                                    optionFilterProp="children"

                                                                    
                                                                    

                                                                    filterOption={(input, option) =>
                                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                    }

                                                                    onSelect={(e) => selectHandlerforGender(e)}


                                                                    allowClear

                                                                >
                                                                    {gender?.payload?.data.map((gen) => {
                                                                        return (
                                                                            <Option value={gen.gender_id}>
                                                                                {gen.gender_name}
                                                                            </Option>
                                                                        );
                                                                    })}
                                                                </Select>
                                                            </Form.Item>
                                                        </div>
                                                        <div className='customCols slc'>
                                                            <Form.Item
                                                                label="Target Date"
                                                                name="date"
                                                                labelAlign='left'
                                                                initialValue={form.getFieldValue().city}
                                                                labelCol={{ span: 8 }}
                                                                wrapperCol={{ span: 16 }}
                                                            >

                                                                <DatePicker format={"DD/MM/YYYY"} disabled={!(formState === "add" || formState === "edit")} onChange={(dateString) => setDate(dateString._d)} size="small" style={{ width: "100%", padding: "2px", paddingLeft: "5px" }} />
                                                            </Form.Item>
                                                        </div>
                                                        <div className='customCols slc'>
                                                            <Form.Item
                                                                label="Sales Target"
                                                                name="target"
                                                                initialValue={form.getFieldValue().city}
                                                                labelAlign="left"
                                                                labelCol={{ span: 8 }}
                                                                wrapperCol={{ span: 16 }}
                                                            >

                                                                <Input size='small' disabled={!(formState === "add" || formState === "edit")} onChange={(e) => setTargets(e.target.value)} />
                                                            </Form.Item>
                                                        </div>
                                                        <div className='customCols slc'>
                                                            <Form.Item
                                                                label="Target Amount"
                                                                name="amount"
                                                                labelAlign='left'
                                                                initialValue={form.getFieldValue().city}
                                                                labelCol={{ span: 8 }}
                                                                wrapperCol={{ span: 16 }}
                                                            >

                                                                <Input size='small' disabled={!(formState === "add" || formState === "edit")} onChange={(e) => setAmount(e.target.value)} />
                                                            </Form.Item>
                                                        </div>
                                                    </div>
                                                </div>

                                            </Form>
                                        </div>

                                        <div className='modalStyle' >
                                        </div>
                                        <Divider />
                                        <div className='styleTab' >
                                            <Tabs type='card' tabBarGutter={2} defaultActiveKey="1" centered   >
                                                <TabPane tab="Log Info" key="logs"  >
                                                    <div className='tabs' >
                                                        <Tables type="company" scrollX={"50rem"} scroll={800} perPage={10} source={log?.payload?.data} col={logColumns} load={log.loading} paginate />
                                                    </div>
                                                </TabPane>
                                            </Tabs>
                                        </div>
                                    </div>
                                </Col>

                            </Row>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}


export default SalesTarget