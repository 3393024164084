import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';

import { getAllItem } from "../../redux/actions/item.action"
import { getRights } from "../../redux/actions/userRights.action";
import { ReadCookie } from "../../utils/readCookie"

import Tables from '../../components/Table/Tables';
import SkeletonLoad from '../../components/Skeleton/SkeletonLoad';
import SubTitle from "../../components/SubTitle/SubTitle";
import Loader from "react-loader-spinner";
import PageHeader from "../../components/PageHeader/PageHeader";

import { Button, Input } from 'antd';
import { FilterFilled, SearchOutlined, CloseCircleOutlined } from "@ant-design/icons";

import "../Style/style.css";
import "./chargeBackType.css";

export default function ChargeBackTypeList({ token }) {
  const history = useHistory();
  const menuId = ReadCookie('menuId');

  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const { item, userRights } = useSelector(state => state);

  const [itemId, setItemId] = useState(null);
  const [filteredInfo, setFilteredInfo] = useState(null);
  const [sortedInfo, setSortedInfo] = useState(null);
  const [dataLen, setDataLen] = useState(0);

  useEffect(() => {
    dispatch(getRights(token, menuId));
    dispatch(getAllItem(token, "Y"))
  }, [])
  const colTest = [
    {
      title: "SKU", dataIndex: "SKU", key: "SKU", width: 130,
      filteredValue: filteredInfo ? filteredInfo.SKU || null : null,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <Input
            allowClear
            autoFocus
            className="search-pop-style"
            
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false })
            }}
            onPressEnter={() => { confirm() }}
            onBlur={() => { confirm() }}
          />
        )
      },
      filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
      onFilter: (value, record) => {
        if (record.SKU) return record.SKU.toLowerCase().includes(value.toLowerCase())
      },
      sortOrder: sortedInfo ? sortedInfo.columnKey === 'SKU' && sortedInfo.order : null,
      sorter: (a, b) => a.SKU - b.SKU,
    },
    {
      title: "Style/Fit", dataIndex: "Style_Fit", key: "Style_Fit", width: 130,
      filteredValue: filteredInfo ? filteredInfo.Style_Fit || null : null,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <Input
            allowClear
            autoFocus
            className="search-pop-style"
            
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false })
            }}
            onPressEnter={() => { confirm() }}
            onBlur={() => { confirm() }}
          />
        )
      },
      filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
      onFilter: (value, record) => {
        if (record.Style_Fit) return record.Style_Fit.toLowerCase().includes(value.toLowerCase())
      },
      sortOrder: sortedInfo ? sortedInfo.columnKey === 'Style_Fit' && sortedInfo.order : null,
      sorter: (a, b) => a.Style_Fit.localeCompare(b.Style_Fit),
    },
    {
      title: "Cut", dataIndex: "Cut", key: "Cut", width: 130,
      filteredValue: filteredInfo ? filteredInfo.Cut || null : null,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <Input
            allowClear
            autoFocus
            className="search-pop-style"
            
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false })
            }}
            onPressEnter={() => { confirm() }}
            onBlur={() => { confirm() }}
          />
        )
      },
      filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
      onFilter: (value, record) => {
        if (record.Cut) return record.Cut.toLowerCase().includes(value.toLowerCase())
      },
      sortOrder: sortedInfo ? sortedInfo.columnKey === 'Cut' && sortedInfo.order : null,
      sorter: (a, b) => a.Cut.localeCompare(b.Cut),
    },
    {
      title: "Wash", dataIndex: "Wash", key: "Wash", width: 130,
      filteredValue: filteredInfo ? filteredInfo.Wash || null : null,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <Input
            allowClear
            autoFocus
            className="search-pop-style"
            
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false })
            }}
            onPressEnter={() => { confirm() }}
            onBlur={() => { confirm() }}
          />
        )
      },
      filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
      onFilter: (value, record) => {
        if (record.Wash) return record.Wash.toLowerCase().includes(value.toLowerCase())
      },
      sortOrder: sortedInfo ? sortedInfo.columnKey === 'Wash' && sortedInfo.order : null,
      sorter: (a, b) => a.Wash.localeCompare(b.Wash),

    },
    {
      title: "Wash Type", dataIndex: "Wash_Type", key: "Wash_Type", width: 130,
      filteredValue: filteredInfo ? filteredInfo.Wash_Type || null : null,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <Input
            allowClear
            autoFocus
            className="search-pop-style"
            
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false })
            }}
            onPressEnter={() => { confirm() }}
            onBlur={() => { confirm() }}
          />
        )
      },
      filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
      onFilter: (value, record) => {
        if (record.Wash_Type) return record.Wash_Type.toLowerCase().includes(value.toLowerCase())
      },
      sortOrder: sortedInfo ? sortedInfo.columnKey === 'Wash_Type' && sortedInfo.order : null,
      sorter: (a, b) => a.Wash_Type.localeCompare(b.Wash_Type),

    },
    {
      title: "Fabric", dataIndex: "Fabric", key: "Fabric", width: 130,
      filteredValue: filteredInfo ? filteredInfo.Fabric || null : null,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <Input
            allowClear
            autoFocus
            className="search-pop-style"
            
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false })
            }}
            onPressEnter={() => { confirm() }}
            onBlur={() => { confirm() }}
          />
        )
      },
      filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
      onFilter: (value, record) => {
        if (record.Fabric) return record.Fabric.toLowerCase().includes(value.toLowerCase())
      },
      sortOrder: sortedInfo ? sortedInfo.columnKey === 'Fabric' && sortedInfo.order : null,
      sorter: (a, b) => a.Fabric.localeCompare(b.Fabric),
    },
    {
      title: "Gender", dataIndex: "Gender", key: "Gender", width: 130,
      filteredValue: filteredInfo ? filteredInfo.Gender || null : null,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <Input
            allowClear
            autoFocus
            className="search-pop-style"
            
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false })
            }}
            onPressEnter={() => { confirm() }}
            onBlur={() => { confirm() }}
          />
        )
      },
      filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
      onFilter: (value, record) => {
        if (record.Gender) return record.Gender.toLowerCase().includes(value.toLowerCase())
      },

      sortOrder: sortedInfo ? sortedInfo.columnKey === 'Gender' && sortedInfo.order : null,
      sorter: (a, b) => a.Gender.localeCompare(b.Gender),

    },
    {
      title: "Product Status", dataIndex: "Product_Status", key: "Product_Status", width: 130,
      filteredValue: filteredInfo ? filteredInfo.Product_Status || null : null,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <Input
            allowClear
            autoFocus
            className="search-pop-style"
            
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false })
            }}
            onPressEnter={() => { confirm() }}
            onBlur={() => { confirm() }}
          />
        )
      },
      filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
      onFilter: (value, record) => {
        if (record.Product_Status) return record.Product_Status.toLowerCase().includes(value.toLowerCase())
      },
      sortOrder: sortedInfo ? sortedInfo.columnKey === 'Product_Status' && sortedInfo.order : null,
      sorter: (a, b) => a.Product_Status.localeCompare(b.Product_Status),
    },
    {
      title: "Active", dataIndex: "Active", key: "Active", width: 130,
      render: (text, row) => row.Active ? "True" : "False",
      filteredValue: filteredInfo ? filteredInfo.Active || null : null,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <Input
            allowClear
            autoFocus
            className="search-pop-style"
            
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false })
            }}
            onPressEnter={() => { confirm() }}
            onBlur={() => { confirm() }}
          />
        )
      },
      filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,

      onFilter: (value, record) => {
        if (record.Active !== undefined) return record.Active.toString().toLowerCase().includes(value.toLowerCase())
      },
      sortOrder: sortedInfo ? sortedInfo.columnKey === 'Active' && sortedInfo.order : null,
      sorter: (a, b) => a.Active.localeCompare(b.Active),


    },
    {
      title: "Retail Price", dataIndex: "Retail_Price", key: "Retail_Price", width: 130,
      filteredValue: filteredInfo ? filteredInfo.Retail_Price || null : null,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <Input
            allowClear
            autoFocus
            className="search-pop-style"
            
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false })
            }}
            onPressEnter={() => { confirm() }}
            onBlur={() => { confirm() }}
          />
        )
      },
      filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
      onFilter: (value, record) => {
        if (record.Retail_Price) return record.Retail_Price.toLowerCase().includes(value.toLowerCase())
      },
      sortOrder: sortedInfo ? sortedInfo.columnKey === 'Retail_Price' && sortedInfo.order : null,
      sorter: (a, b) => a.Retail_Price - b.Retail_Price,
    },
    {
      title: "Cost Price", dataIndex: "Cost_Price", key: "Cost_Price", width: 130,
      filteredValue: filteredInfo ? filteredInfo.Cost_Price || null : null,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <Input
            allowClear
            autoFocus
            className="search-pop-style"
            
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false })
            }}
            onPressEnter={() => { confirm() }}
            onBlur={() => { confirm() }}
          />
        )
      },
      filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
      onFilter: (value, record) => {
        if (record.Cost_Price) return record.Cost_Price.toLowerCase().includes(value.toLowerCase())
      },
      sortOrder: sortedInfo ? sortedInfo.columnKey === 'Cost_Price' && sortedInfo.order : null,
      sorter: (a, b) => a.Cost_Price - b.Cost_Price,
    },
    {
      title: "Item Id", dataIndex: "item_id", key: "item_id", width: 130,
      filteredValue: filteredInfo ? filteredInfo.item_id || null : null,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <Input
            allowClear
            autoFocus
            className="search-pop-style"
            
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false })
            }}
            onPressEnter={() => { confirm() }}
            onBlur={() => { confirm() }}
          />
        )
      },
      filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
      onFilter: (value, record) => {
        if (record.item_id) return record.item_id.toString().toLowerCase().includes(value.toLowerCase())
      },
      sortOrder: sortedInfo ? sortedInfo.columnKey === 'item_id' && sortedInfo.order : null,
      sorter: (a, b) => a.item_id - b.item_id,
    },
  ]
  const handleFilter = (pagination, filters, sorter, extra) => {
    setFilteredInfo(filters)
    setSortedInfo(sorter)
    setShow(true)
  }
  const clearFilters = () => {
    if (show) {
      setFilteredInfo(null)
      setSortedInfo(null)
      setShow(false)
    }
    if (!show) {
      setShow(!show)
    }
  };

  useEffect(() => {
    if (!show) {
      setDataLen(0)
    }
    if (show) {
      setDataLen(item?.payload?.data.length)
    }


  }, [show])

  return (
    <>
      <div className='styleContainer' >
        <div className='styleBody h-100'>
          <div className='mainProductContainer new-style'>
            <PageHeader userRights={userRights?.payload} title={"Product Information"} type={"table"} />
            <div className='productListDiv' >
              <SubTitle name={"Product List"} />
              {userRights?.payload?.can_add &&
                <Button className="pos-right-10 iconBtn crudBTN" shape='circle' icon={<CloseCircleOutlined className='color-red' />} onClick={() => history.push({ pathname: "/product-info" })} />
              }
            </div>
            {
              item.loading ?
                <div className='h-100 relative'>
                  <div className='loaderDiv'  >
                    <Loader
                      type="Grid"
                      color="#212121"
                      height={40}
                      width={40}
                    />
                  </div>
                  <SkeletonLoad />
                </div>
                :
                <div className='productTable pt-0'>
                  <div className='pbpx-10'>
                    <Button className='btn-main mlpx-5' type="primary" onClick={() => clearFilters()} >{show ? "Clear All" : "Show All"}</Button>
                  </div>
                  <Tables dataLen={dataLen} setDataLen={setDataLen} show={show} handleFilter={handleFilter} loadHeight={40} loadWidth={40} itemId={setItemId} col={colTest} load={item?.loading} scroll={570} perPage={25} source={show ? item?.payload?.data : []} paginate scrollX={"80rem"} type={"item"} />
                  <span className='records-count'>
                    {
                      dataLen === 0 ?
                        <>No of Records:  <span className='fw-500'>  0</span> </> :
                        < > No of Records: <span className='fw-500'> {show ? dataLen : dataLen ? dataLen : "0"}</span>  </>
                    }
                  </span>
                </div>
            }
          </div>
        </div>
      </div>
    </>
  )
}
