import { GET_GL_IMPACT_REQUEST, GET_GL_IMPACT_SUCCESS, GET_GL_IMPACT_ERROR } from "../actions/types";

export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case GET_GL_IMPACT_REQUEST:
            return {
                loading: true,
            };
        case GET_GL_IMPACT_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_GL_IMPACT_ERROR:
            return {
                ...state,
                loading: false,
                payload,
            };
        default:
            return state;
    }
}
