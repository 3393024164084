import {
    GET_COMMITCRITERIA_REQUEST,
    GET_COMMITCRITERIA_SUCCESS,
    GET_COMMITCRITERIA_ERROR,
    ADD_COMMITCRITERIA_ERROR,
    ADD_COMMITCRITERIA_REQUEST,
    ADD_COMMITCRITERIA_SUCCESS,
    UPDATE_COMMITCRITERIA_REQUEST,
    UPDATE_COMMITCRITERIA_SUCCESS,
    UPDATE_COMMITCRITERIA_ERROR,
    DELETE_COMMITCRITERIA_REQUEST,
    DELETE_COMMITCRITERIA_SUCCESS,
    DELETE_COMMITCRITERIA_ERROR
  } from "../actions/types";
  
  export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
      case GET_COMMITCRITERIA_REQUEST:
        return {
          loading: true,
        };
      case GET_COMMITCRITERIA_SUCCESS:
        return {
          ...state,
          loading: false,
          payload,
        };
      case GET_COMMITCRITERIA_ERROR:
        return {
          ...state,
          loading: false,
          payload,
        };
      case ADD_COMMITCRITERIA_REQUEST:
        return {
          loading: true,
        };
      case ADD_COMMITCRITERIA_SUCCESS:
        return {
          ...state,
          loading: false,
          payload: [payload],
        };
      case ADD_COMMITCRITERIA_ERROR:
        return {
          ...state,
          loading: false
        };
      case UPDATE_COMMITCRITERIA_REQUEST:
        return {
          loading: true,
        };
      case UPDATE_COMMITCRITERIA_SUCCESS:
        return {
          ...state,
          loading: false,
          payload: [payload],
        };
      case UPDATE_COMMITCRITERIA_ERROR:
        return {
          ...state,
          loading: false
        };
      case DELETE_COMMITCRITERIA_REQUEST:
        return {
          loading: true,
        };
      case DELETE_COMMITCRITERIA_SUCCESS:
        return {
          ...state,
          loading: false,
          payload: [payload],
        };
      case DELETE_COMMITCRITERIA_ERROR:
        return {
          ...state,
          loading: false
        };
  
  
  
      default:
        return state;
    }
  }
  