
import {

    GET_BILL_DIRECT_PAYMENT_LINE_REQUEST, GET_BILL_DIRECT_PAYMENT_LINE_SUCCESS, GET_BILL_DIRECT_PAYMENT_LINE_ERROR,
    GET_ONE_BILL_DIRECT_PAYMENT_LINE_REQUEST,
    GET_ONE_BILL_DIRECT_PAYMENT_LINE_SUCCESS,
    GET_ONE_BILL_DIRECT_PAYMENT_LINE_ERROR,
    ADD_BILL_DIRECT_PAYMENT_LINE_REQUEST,
    ADD_BILL_DIRECT_PAYMENT_LINE_SUCCESS,
    ADD_BILL_DIRECT_PAYMENT_LINE_ERROR,
    UPDATE_BILL_DIRECT_PAYMENT_LINE_REQUEST,
    UPDATE_BILL_DIRECT_PAYMENT_LINE_SUCCESS,
    UPDATE_BILL_DIRECT_PAYMENT_LINE_ERROR,
    DELETE_BILL_DIRECT_PAYMENT_LINE_REQUEST,
    DELETE_BILL_DIRECT_PAYMENT_LINE_SUCCESS,
    DELETE_BILL_DIRECT_PAYMENT_LINE_ERROR
} from "./types";
import { BASEURL } from "../../constant/config.js";
const axios = require("axios");

export const addBillDirectPaymentLine = (token, rest, journalVoucherId, id) => (dispatch) => {
    let data = rest;


    data.forEach((val) => val.direct_payment_header_id = journalVoucherId);
    data = data.map((item) => {
        if (item.amount === '') {
            item.amount = 0
        }
        if (item.customer_id === '') {
            item.customer_id = null
        }

        return item;
    })
    dispatch({ type: ADD_BILL_DIRECT_PAYMENT_LINE_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}create/PayBillDirectPaymentExpense`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { data }
    })
        .then((response) => {
            return dispatch({
                type: ADD_BILL_DIRECT_PAYMENT_LINE_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) => {
            return dispatch({
                type: ADD_BILL_DIRECT_PAYMENT_LINE_ERROR,
                payload: error.response,
            });
        });
};
export const addBillDirectPaymentLines = (token, data) => (dispatch) => {
    data = data.map((item) => {
        if (item.amount === '') {
            item.amount = 0
        }
        if (item.customer_id === '') {
            item.customer_id = null
        }
        return item;
    }
    )

    dispatch({ type: ADD_BILL_DIRECT_PAYMENT_LINE_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}create/PayBillDirectPaymentExpense`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { data }
    })
        .then((response) => {
            return dispatch({
                type: ADD_BILL_DIRECT_PAYMENT_LINE_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) => {
            return dispatch({
                type: ADD_BILL_DIRECT_PAYMENT_LINE_ERROR,
                payload: error.response,
            });
        });
};

export const getOneBillDirectPaymentLine = (token, headerId) => (dispatch) => {

    dispatch({ type: GET_ONE_BILL_DIRECT_PAYMENT_LINE_REQUEST });
    return axios({
        method: "GET",
        url: `${BASEURL}getOne/PayBillDirectPaymentExpense/${headerId}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
    })
        .then((response) => {
            return dispatch({
                type: GET_ONE_BILL_DIRECT_PAYMENT_LINE_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) => {
            return dispatch({
                type: GET_ONE_BILL_DIRECT_PAYMENT_LINE_ERROR,
                payload: error.response,
            });
        });
};

export const getBillDirectPaymentLine = (token, customerID) => (dispatch) => {
    dispatch({ type: GET_BILL_DIRECT_PAYMENT_LINE_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/PayBillDirectPaymentExpense`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
    })
        .then((response) => {
            return dispatch({
                type: GET_BILL_DIRECT_PAYMENT_LINE_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) => {
            return dispatch({
                type: GET_BILL_DIRECT_PAYMENT_LINE_ERROR,
                payload: error.response,
            });
        });
};


export const updateBillPaymentLine = (token, rest, journalVoucherId) => (dispatch) => {

    let data = rest


    data.map((item) => {
        delete item.customer_name
        if (item.amount === '') {
            item.amount = 0
        }

        return {
            account_id: item.account_id,
            credit: item.credit,
            customer_id: item.customer_id === "" ? null : item.customer_id,
            debit: item.debit,
            memo: item.memo,
            direct_payment_header_id: item.direct_payment_header_id,
        };
    });
    dispatch({ type: UPDATE_BILL_DIRECT_PAYMENT_LINE_REQUEST });
    return axios({
        method: "PUT",
        url: `${BASEURL}update/PayBillDirectPaymentExpense/${journalVoucherId}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { data },

    })
        .then((response) => {
            return dispatch({
                type: UPDATE_BILL_DIRECT_PAYMENT_LINE_SUCCESS,
                payload: response?.data,
            });
        })
        .catch((error) => {
            return dispatch({
                type: UPDATE_BILL_DIRECT_PAYMENT_LINE_ERROR,
                payload: error.response,
            });
        });
};


export const deleteBillDirectPaymentLine = (token, Lineidd, data) => (dispatch) => {

    dispatch({ type: DELETE_BILL_DIRECT_PAYMENT_LINE_REQUEST });
    return axios({
        method: "DELETE",
        url: `${BASEURL}delete/PayBillDirectPaymentExpense/${Lineidd}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { data: data }
    })
        .then((response) => {
            return dispatch({
                type: DELETE_BILL_DIRECT_PAYMENT_LINE_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) => {
            return dispatch({
                type: DELETE_BILL_DIRECT_PAYMENT_LINE_ERROR,
                payload: error.response,
            });
        });
};