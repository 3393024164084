import {
    ADD_ALL_COLUMNSCHEMA_SUCCESS,
    ADD_ALL_COLUMNSCHEMA_REQUEST,
    ADD_ALL_COLUMNSCHEMA_ERROR,
  } from "../actions/types";
  
  export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
      case ADD_ALL_COLUMNSCHEMA_REQUEST:
        return {
          loading: true,
        };
      case ADD_ALL_COLUMNSCHEMA_SUCCESS:
        return {
          ...state,
          loading: false,
          payload: payload,
        };
      case ADD_ALL_COLUMNSCHEMA_ERROR:
        return {
          ...state,
          loading: false,
          payload,
        };
      default:
        return state;
    }
  }
  