import {
  GET_SALE_COMPARISION_MONTH_WISE_REQUEST,
  GET_SALE_COMPARISION_MONTH_WISE_SUCCESS,
  GET_SALE_COMPARISION_MONTH_WISE_ERROR 
  } from "../actions/types";
  
  export const saleComparisionReport = (state = [], action) => {
    const { type, payload } = action;
    switch (type) {
      case GET_SALE_COMPARISION_MONTH_WISE_REQUEST:
        return {
          loading: true,
        };
      case GET_SALE_COMPARISION_MONTH_WISE_SUCCESS:
        return {
          ...state,
          loading: false,
          payload,
        };
      case GET_SALE_COMPARISION_MONTH_WISE_ERROR:
        return {
          ...state,
          loading: false,
          payload,
        };
  
      default: {
        return state;
      }
    }
  };
  