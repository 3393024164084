import {
    GET_ADMIN_USER_REQUEST, GET_ADMIN_USER_ERROR, GET_ADMIN_USER_SUCCESS,
    ADD_ADMIN_USER_ERROR, ADD_ADMIN_USER_REQUEST, ADD_ADMIN_USER_SUCCESS,
    UPDATE_ADMIN_USER_ERROR, UPDATE_ADMIN_USER_REQUEST, UPDATE_ADMIN_USER_SUCCESS,
    DELETE_ADMIN_USER_ERROR, DELETE_ADMIN_USER_REQUEST, DELETE_ADMIN_USER_SUCCESS,
} from "./types";
import { BASEURL } from "../../constant/config.js";
import axios from "axios";




export const loadAdminUser = (token) => dispatch => {
    dispatch({ type: GET_ADMIN_USER_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getall/user`,
        headers: {
            Accept: "application/json",
            'Authorization': 'Bearer ' + token
        }
    })
        .then(response => {
            return dispatch({ type: GET_ADMIN_USER_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            if (error.response.data.data === "No Record Found !") {
                return dispatch({
                    type: GET_ADMIN_USER_ERROR,
                    payload: [],
                });
            }
            return dispatch({
                type: GET_ADMIN_USER_ERROR,
                payload: error.response,
            });
        })
};



export const addAdminUser = (token, data, id) => (dispatch) => {
    dispatch({ type: ADD_ADMIN_USER_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}register/user`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data,
    })
        .then((response) => {

            return dispatch({ type: ADD_ADMIN_USER_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: ADD_ADMIN_USER_ERROR,
                payload: error.response,
            });
        });
};




export const updateAdminUser = (token, data, id) => (dispatch) => {
    dispatch({ type: UPDATE_ADMIN_USER_REQUEST });
    return axios({
        method: "PUT",
        url: `${BASEURL}update/user/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data,
    })
        .then((response) => {

            return dispatch({ type: UPDATE_ADMIN_USER_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: UPDATE_ADMIN_USER_ERROR,
                payload: error.response,
            });
        });
};


export const deleteAdminUser = (token, id) => (dispatch) => {
    dispatch({ type: DELETE_ADMIN_USER_REQUEST });
    return axios({
        method: "DELETE",
        url: `${BASEURL}delete/user/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
    })
        .then((response) => {
            return dispatch({ type: DELETE_ADMIN_USER_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: DELETE_ADMIN_USER_ERROR,
                payload: error.response,
            });
        });
};

