import {
    GET_VENDOR_BILLING_ERROR,
    GET_VENDOR_BILLING_REQUEST,
    GET_VENDOR_BILLING_SUCCESS,
    ADD_VENDOR_BILLING_REQUEST,
    ADD_VENDOR_BILLING_SUCCESS,
    ADD_VENDOR_BILLING_ERROR,
    DELETE_VENDOR_BILLING_REQUEST,
    DELETE_VENDOR_BILLING_SUCCESS,
    DELETE_VENDOR_BILLING_ERROR,
    UPDATE_VENDOR_BILLING_REQUEST,
    UPDATE_VENDOR_BILLING_SUCCESS,
    UPDATE_VENDOR_BILLING_ERROR,

} from "../actions/types";

export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case GET_VENDOR_BILLING_REQUEST:
            return {
                loading: true,
            };
        case GET_VENDOR_BILLING_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_VENDOR_BILLING_ERROR:
            return {
                ...state,
                loading: false,
                payload,
            };

        case ADD_VENDOR_BILLING_REQUEST:
            return {
                loading: true,
            };
        case ADD_VENDOR_BILLING_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case ADD_VENDOR_BILLING_ERROR:
            return {
                ...state,
                loading: false,
            };

        case DELETE_VENDOR_BILLING_REQUEST:
            return {
                loading: true,
            };
        case DELETE_VENDOR_BILLING_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case DELETE_VENDOR_BILLING_ERROR:
            return {
                ...state,
                loading: false,
            };

        case UPDATE_VENDOR_BILLING_REQUEST:
            return {
                loading: true,
            };
        case UPDATE_VENDOR_BILLING_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case UPDATE_VENDOR_BILLING_ERROR:
            return {
                ...state,
                loading: false,
            };

        default:
            return state;
    }
}
