import { GET_BILL_DIRECT_PAYMENT_ITEM_REQUEST, 
         GET_BILL_DIRECT_PAYMENT_ITEM_SUCCESS, 
         GET_BILL_DIRECT_PAYMENT_ITEM_ERROR,      
         GET_ONE_BILL_DIRECT_PAYMENT_ITEM_REQUEST, 
         GET_ONE_BILL_DIRECT_PAYMENT_ITEM_SUCCESS, 
         GET_ONE_BILL_DIRECT_PAYMENT_ITEM_ERROR,  
         ADD_BILL_DIRECT_PAYMENT_ITEM_REQUEST,     
         ADD_BILL_DIRECT_PAYMENT_ITEM_SUCCESS,     
         ADD_BILL_DIRECT_PAYMENT_ITEM_ERROR,       
         UPDATE_BILL_DIRECT_PAYMENT_ITEM_REQUEST,  
         UPDATE_BILL_DIRECT_PAYMENT_ITEM_SUCCESS,  
         UPDATE_BILL_DIRECT_PAYMENT_ITEM_ERROR,    
         DELETE_BILL_DIRECT_PAYMENT_ITEM_REQUEST,  
         DELETE_BILL_DIRECT_PAYMENT_ITEM_SUCCESS,  
         DELETE_BILL_DIRECT_PAYMENT_ITEM_ERROR  
} from "../actions/types";
export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case GET_BILL_DIRECT_PAYMENT_ITEM_REQUEST:
            return {
                loading: true,
            }
        case GET_BILL_DIRECT_PAYMENT_ITEM_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            }
        case GET_BILL_DIRECT_PAYMENT_ITEM_ERROR:
            return {
                ...state,
                loading: false,
            }

        case GET_ONE_BILL_DIRECT_PAYMENT_ITEM_REQUEST:
            return {
                loading: true,
            };
        case GET_ONE_BILL_DIRECT_PAYMENT_ITEM_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_ONE_BILL_DIRECT_PAYMENT_ITEM_ERROR:
            return {
                ...state,
                loading: false,
                payload,
            };

        case ADD_BILL_DIRECT_PAYMENT_ITEM_REQUEST:
            return {
                loading: true,
            };
        case ADD_BILL_DIRECT_PAYMENT_ITEM_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: payload,
            };
        case ADD_BILL_DIRECT_PAYMENT_ITEM_ERROR:
            return {
                ...state,
                loading: false,
                payload,
            };

        case UPDATE_BILL_DIRECT_PAYMENT_ITEM_REQUEST:
            return {
                loading: true,
            };
        case UPDATE_BILL_DIRECT_PAYMENT_ITEM_SUCCESS:
            return {
                ...state,
                loading: false,
                payloadLine: payload,
            };
        case UPDATE_BILL_DIRECT_PAYMENT_ITEM_ERROR:
            return {
                ...state,
                loading: false,
                payloadLine: payload,
            };

        case DELETE_BILL_DIRECT_PAYMENT_ITEM_REQUEST:
            return {
                loading: true,
            };
        case DELETE_BILL_DIRECT_PAYMENT_ITEM_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case DELETE_BILL_DIRECT_PAYMENT_ITEM_ERROR:
            return {
                ...state,
                loading: false,
                payload,
            };   
        default:
            return state
    }
}