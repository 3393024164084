import {
	GET_ONE_SALE_ORDER_RELATED_RECORD_REQUEST,
	GET_ONE_SALE_ORDER_RELATED_RECORD_SUCCESS,
	GET_ONE_SALE_ORDER_RELATED_RECORD_ERROR,
} from "../actions/types";

export default function (state = [], action) {
	const { type, payload } = action;
	switch (type) {
		case GET_ONE_SALE_ORDER_RELATED_RECORD_REQUEST:
			return {
				loading: true,
			};
		case GET_ONE_SALE_ORDER_RELATED_RECORD_SUCCESS:
			return {
				...state,
				loading: false,
				payload,
			};
		case GET_ONE_SALE_ORDER_RELATED_RECORD_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};
		default:
			return state;
	}
}
