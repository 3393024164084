import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getOneCreditHeader, addCreditHeader, updateCreditHeader } from "../../../../redux/actions/credit.action";
import { getOneCreditLine, getOneCreditLineDetail, addCreditLine, updateCreditLine } from "../../../../redux/actions/creditLine.action";
import { getOneInvoiceGlPeriod } from "../../../../redux/actions/invoiceHeader.action";
import { getLog } from "../../../../redux/actions/log.action";
import notification from "../../../../utils/notification";
import Tables from "../../../../components/Table/Tables";
import { logColumns } from "../../../../utils/cols";
import SubTitles from "../../../../components/SubTitle/SubTitle";
import Loader from "react-loader-spinner";
import moment from "moment";
import { DatePicker, Input, Tabs, Button, Form, Table, Select, InputNumber } from "antd";
import { Row as Brow, Col as Bcol } from "reactstrap";
import "../../../../screens/SaleOrder/salesOrder.css";
import Swal from "sweetalert2";
import { getGLImpact } from "../../../../redux/actions/gl_impact.action";

const { TabPane } = Tabs;
const { Option } = Select;

export default function ItemCreditForm({ formData, val, token, submit, checkFlag, setFlag, edit, saleReturnId, loading: deleteLoading, formState, receipt_header_id,  }) {

	const submitButton = useRef(null);
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const { log, invoiceGlPeriod, glImpact } = useSelector((state) => state);
	const [invoiceDate, setInvoiceDate] = useState(moment());
	const [visibleItems, setVisibleItems] = useState([]);
	const [loading, setLoading] = useState(false);
	const history = useHistory();
	const startDateList = "01-1-2022";
	const endDateList = "06-01-2022";

	useEffect(() => {
		dispatch(getOneInvoiceGlPeriod(token));
		
	}, []);

	useEffect(() => {
		setLoading(deleteLoading);
	}, [deleteLoading]);

	const columns = [
		{
			title: "Item Code",
			dataIndex: "item_code",
		},

		{
			title: "Cost Price",
			dataIndex: "rate",
			editable: true,
		},
		{
			title: "Balance Quantity",
			dataIndex: "balance_quantity",
			editable: true,
		},
		{
			title: "Quantity",
			dataIndex: "quantity",
			editable: true,
			render: (text, record) => {
				return (
					<InputNumber
						size="small"
						min="0"
						
						onStep={(value, info) => updateQuantity(info.type, record.item_code, value)}
						value={record.quantity}
						disabled={!(formState === "add" || formState === "edit")}
						onChange={(value) => updateQuantity("up", record.item_code, value)}
						defaultValue={0}
					/>
				);
			},
		},
		{
			title: "Amount",
			render: (_, record) => parseFloat(record.quantity * record.rate).toFixed(2),
		},


	];

	const glImpcatCols = [
		{
			title: "Transaction ID",
			dataIndex: "transaction_header_id",
		},
		{
			title: "Transaction Type",
			dataIndex: "transaction_type",
		},
		{
			title: "Credit",
			dataIndex: "credit",
		},
		{
			title: "Debit",
			dataIndex: "debit",
		},
		{
			title: "created_date",
			dataIndex: "created_date",
		}
	]


	const updateQuantity = (type, id, value) => {
		let arr = [...visibleItems];
		value = parseInt(value);
		const filter = arr.map((item) => {

			if (item.item_code === id) {
				let balance = parseInt(item.balance_quantity);
				if (value > balance) {
					Swal.fire({
						icon: "error",
						title: "Oops...",
						text: "Maxiumum Quantity Exceed",
					});
				}
				if (type === "up") {
					
					item.quantity = value > balance ? balance : value;
					item.amount = parseFloat(item.quantity * item.rate).toFixed(2);
					return item;
				}
				
				if (type === "down") {
					
					item.quantity = value > balance ? balance : value;
					item.amount = parseFloat(item.quantity * item.rate).toFixed(2);
					return item;
				}
			} else {
				return item;
			}
		});

		setVisibleItems(filter);
	};

	useEffect(() => {
		setLoading(true);
		if (edit) {
			dispatch(getOneCreditLineDetail(token, receipt_header_id)).then((res) => {
				if (res.type === "GET_ONE_CREDIT_LINE_SUCCESS") {
					setLoading(false);
					setVisibleItems(res.payload);
				} else if (res.type === "GET_ONE_CREDIT_LINE_ERROR") {
					setLoading(false);
					notification("warning", "No Items Found", res?.payload?.data.data);
				}
			});
		} else {
			dispatch(getOneCreditLineDetail(token, receipt_header_id)).then((res) => {
				if (res.type === "GET_ONE_CREDIT_LINE_DETAIL_SUCCESS") {
					setLoading(false);
					setVisibleItems(res.payload);
				} else if (res.type === "GET_ONE_CREDIT_LINE_DETAIL_ERROR") {
					setLoading(false);
					notification("warning", "No Items Found", res?.payload?.data.data);
				}
			});
		}
	}, []);



	const onFinish = async (e) => {
		setLoading(true);
		submit(false);
		await form.validateFields().then((values) => {
			if (edit) {
				dispatch(updateCreditHeader(token, values, formData)).then((res) => {
					if (res.type === "UPDATE_CREDIT_HEADER_SUCCESS") {
						let items = visibleItems;
						dispatch(
							updateCreditLine(
								token,
								saleReturnId,
								items.map((item) => {
									delete item.balance_quantity;
									delete item.amount;
									delete item.item_code;
									delete item.parent_item_id;
									delete item.receipt_line_id;
									delete item.ship_line_id;
									delete item.order_line_id;
									delete item.invoice_line_id;
									delete item.return_line_id;
									item.net_amount = item.gross_amount;
									return item;
								})
							)
						).then((res) => {
							if (res.type === "UPDATE_CREDIT_LINE_SUCCESS") {
								dispatch(getOneCreditLine(token, saleReturnId)).then((res) => {
									if (res.type === "GET_ONE_CREDIT_LINE_SUCCESS") {
										setVisibleItems(res.payload);
									} else if (res.type === "GET_ONE_CREDIT_LINE_ERROR") {
										notification("warning", "No Items Found", res?.payload?.data.data);
									}
								});
								setLoading(false);
								notification("success", "CREDIT Updated Successfully", res?.payload?.data);
							} else if (res.type === "UPDATE_CREDIT_LINE_ERROR") {
								setLoading(false);
								Swal.fire({
									icon: "error",
									title: "Oops...",
									text: res?.payload?.data?.data,
								});
							}
						});
					}
				});
			} else {
				dispatch(addCreditHeader(token, values, formData)).then((res) => {
					if (res.type === "ADD_CREDIT_HEADER_SUCCESS") {
						let headerId = res.payload.note_header_id;

						let items = visibleItems.map((items) => {
							return {
								...items,
								note_header_id: res.payload.note_header_id,
							};
						});
						dispatch(
							addCreditLine(
								token,
								items.map((item) => {
									delete item.balance_quantity;
									delete item.amount;
									delete item.return_header_id;
									delete item.parent_item_id;
									delete item.created_by;
									delete item.created_date;
									delete item.receipt_header_id;
									delete item.order_line_id;
									item.net_amount = item.gross_amount;
									return item;
								})
							)
						).then((res) => {
							if (res.type === "ADD_CREDIT_LINE_SUCCESS") {
								
								dispatch(getOneCreditHeader(token, headerId));
								notification("success", "Receipt Created", res?.payload?.data);
								setLoading(false);
								
							} else if (res.type === "ADD_CREDIT_HEADER_ERROR") {
								setLoading(false);
								Swal.fire({
									icon: "error",
									title: "Oops...",
									text: res?.payload?.data?.data,
								});
							}
						});
					} else if (res.type === "UPDATE_CREDIT_HEADER_ERROR") {
						setLoading(false);
						Swal.fire({
							icon: "error",
							title: "Oops...",
							text: res?.payload?.data?.data,
						});
					}
				});
			}
		});
	};

	useEffect(() => {

		if (formData) {
			form.setFieldsValue({
				receiptNo: formData.note_header_no,
				postingPeriod: formData.gl_period_id,
				noteDate: moment(formData.note_date),
				customerName: formData.customer_name,
				locationName: formData.location_name,
				memo: formData.memo,
				receiptHeaderId: formData.receipt_header_id,
				noteHeaderId: formData.note_header_id,
				RaNumber: formData.return_header_no,
				invoiceNo: formData.invoice_header_no,
				recieptNo: formData.receipt_header_no
			});
		}
		dispatch(getGLImpact(token, { "transaction_header_id": saleReturnId, "transaction_type": "CREDITNOTE" }));

	}, [formData]);

	useEffect(() => {
		if (val) {
			submitButton.current.click();
		}
	}, [val]);

	const handleTab = (key) => {
		if (key === "logs") {
			dispatch(
				getLog(token, {
					inv_item_size: "inv_item_size",
					inv_item_inseam: "inv_item_inseam",
					inv_item: "inv_item",
				})
			);
		}
	};

	useEffect(() => {
		if (checkFlag === true) {
			form.setFieldsValue({
				receiptNo: null,
				customerName: null,
				locationName: null,
				postingPeriod: null,
				returnDate: null,
				memo: null,
				asnNo: null,
			});
			setVisibleItems([]);
			setFlag(false);
		}
	}, [checkFlag]);

	const onFinishFailed = () => {
		let errors = form.getFieldsError().filter((item) => item.errors.length > 0);
		Swal.fire({
			icon: "error",
			title: "Oops...",
			text: errors[0]?.errors[0],
		});
	};

	const summary = () => {
		let orderSummary = visibleItems;
		let amount = 0;
		let quantity = 0;
		let discount = 0;
		let tax = 0;
		let shippingCost = form.getFieldValue().shippingCost ? form.getFieldValue().shippingCost : 0;
		if (orderSummary.length > 0) {
			orderSummary?.forEach(function (item) {
				if (item.hasOwnProperty("rate")) {
					amount += parseFloat(item.rate) * parseFloat(item.quantity);
				}
				if (item.hasOwnProperty("quantity")) {
					quantity += parseFloat(item.quantity);
				}
				
				
			});
		}

		
		return {
			quantity: quantity,
			amount: parseFloat(amount).toFixed(2),
			discount: parseFloat(discount).toFixed(2),
			tax: parseFloat(tax).toFixed(2),
			shippingCost: parseFloat(shippingCost).toFixed(2),
			net: parseFloat(parseFloat(amount) + parseFloat(shippingCost)).toFixed(2),
		};
	};

	return (
		<>
			<Form requiredMark={false} autoComplete="off" onFinish={onFinish} form={form} onFinishFailed={onFinishFailed} name="basic">
				{loading && (
					<div className="loaderDiv">
						<Loader type="Grid" color="#212121" height={40} width={40} />
					</div>
				)}
				<div className="saleOrderForm">
					<SubTitles name={"Primary Information"} />
					<div className="primaryInfo container-fluid pbpx-0 ptpx-15">
						<Brow>
							<Bcol md={12} xl={9} xxl={10} className="pbpx-10">
								<div className="firstSectionRo d-none">
									<Form.Item name="receiptHeaderId" label="receiptHeaderId" labelAlign="left" labelCol={{ span: 6 }}>
										<Input size="small" disabled className="customInputs" />
									</Form.Item>
									<Form.Item name="noteHeaderId" label="noteHeaderId" labelAlign="left" labelCol={{ span: 6 }}>
										<Input size="small" disabled className="customInputs" />
									</Form.Item>
								</div>

								<Brow>
									<Bcol lg={6} xxl={4}>
										<div className="firstSectionRo">
											<Form.Item name="receiptNo" label="Note #" labelAlign="left" labelCol={{ span: 6 }}>
												<Input size="small" disabled className="customInputs" />
											</Form.Item>
										</div>
									</Bcol>
									<Bcol lg={6} xxl={4}>
										<div className="firstSectionRo">
											<Form.Item label="Note Date" name="noteDate" labelAlign="left" labelCol={{ span: 6 }}>
												<DatePicker
													size="small"
													defaultValue={moment(invoiceDate)}
													format="MM-DD-YYYY"
													
													disabled={!(formState === "add" || formState === "edit")}
													allowClear
													className="dateInput customInputs"
													onChange={(e) => {
														form.setFieldsValue({
															noteDate: e,
														});
														setInvoiceDate(moment(e));
													}}
												/>
											</Form.Item>
										</div>
									</Bcol>
									<Bcol lg={6} xxl={4}>
										<div className="firstSectionRo">
											<Form.Item label="Posting Period" name="postingPeriod" labelAlign="left" labelCol={{ span: 6 }} defaultValue={form.getFieldValue().postingPeriod}>
												<Select
													size="small"
													tabIndex={18}
													autoComplete={"nope"}
													allowClear
													loading={invoiceGlPeriod.loading}
													defaultValue={form.getFieldValue().postingPeriod}
													
													disabled={!(formState === "add" || formState === "edit")}
													onChange={(value) => {
														form.setFieldsValue({
															postingPeriod: value,
														});
													}}
												>
													{invoiceGlPeriod?.payload?.map((item) => {
														return (
															<Option key={item.period_id} value={item.period_id}>
																{item.period_name}
															</Option>
														);
													})}
												</Select>
											</Form.Item>
										</div>
									</Bcol>
									<Bcol lg={6} xxl={4}>
										<div className="firstSectionRo">
											<Form.Item name="customerName" label="Customer" labelAlign="left" labelCol={{ span: 6 }}>
												<Input size="small" tabIndex={1} className="customInputs" disabled={!(formState === "add" || formState === "edit")} />
											</Form.Item>
										</div>
									</Bcol>
									<Bcol lg={6} xxl={4}>
										<div className="firstSectionRo">
											<Form.Item name="locationName" label="Location" labelAlign="left" labelCol={{ span: 6 }}>
												<Input size="small" tabIndex={1} className="customInputs" disabled={!(formState === "add" || formState === "edit")} />
											</Form.Item>
										</div>
									</Bcol>

									<Bcol lg={6} xxl={4}>
										<div className="firstSectionRo">
											<Form.Item name="memo" label="Memo" labelAlign="left" labelCol={{ span: 6 }}>
												<Input size="small" tabIndex={1} className="customInputs" disabled={!(formState === "add" || formState === "edit")} />
											</Form.Item>
										</div>
									</Bcol>
									<Bcol lg={6} xxl={4}>
										<div className="firstSectionRo">
											<Form.Item name="RaNumber" label="RA #" labelAlign="left" labelCol={{ span: 6 }}>
												<Input size="small" tabIndex={1} className="customInputs" disabled={!(formState === "add" || formState === "edit")} />
											</Form.Item>
										</div>
									</Bcol>
									<Bcol lg={6} xxl={4}>
										<div className="firstSectionRo">
											<Form.Item name="invoiceNo" label="Invoice #" labelAlign="left" labelCol={{ span: 6 }}>
												<Input size="small" tabIndex={1} className="customInputs" disabled={!(formState === "add" || formState === "edit")} />
											</Form.Item>
										</div>
									</Bcol>
									<Bcol lg={6} xxl={4}>
										<div className="firstSectionRo">
											<Form.Item name="recieptNo" label="Reciept #" labelAlign="left" labelCol={{ span: 6 }}>
												<Input size="small" tabIndex={1} className="customInputs" disabled={!(formState === "add" || formState === "edit")} />
											</Form.Item>
										</div>
									</Bcol>
								</Brow>
							</Bcol>
							<Bcol md={4} xl={3} xxl={2}>
								<div className="firstSectionRo">
									<div className="order-summary">
										<div className="summary-title">Order Summary</div>
										<Brow>
											<Bcol className="summary-heading" col={6}>
												<p className="mb-0">Total Quantity:</p>
											</Bcol>
											<Bcol className="col-6">
												<p className="mb-0 summary-value">{summary().quantity}</p>
											</Bcol>
										</Brow>
										<Brow>
											<Bcol className="summary-heading" col={6}>
												<p className="mb-0">Total Shipping:</p>
											</Bcol>
											<Bcol className="col-6">
												<p className="mb-0 summary-value">${summary().shippingCost}</p>
											</Bcol>
										</Brow>
										<Brow>
											<Bcol className="summary-heading" col={6}>
												<p className="mb-0">Total Discount:</p>
											</Bcol>
											<Bcol className="col-6">
												<p className="mb-0 summary-value">${summary().discount}</p>
											</Bcol>
										</Brow>
										<Brow>
											<Bcol className="summary-heading" col={6}>
												<p className="mb-0">Total Amount:</p>
											</Bcol>
											<Bcol className="col-6">
												<p className="mb-0 summary-value">${summary().amount}</p>
											</Bcol>
										</Brow>
										<Brow>
											<Bcol className="summary-heading" col={6}>
												<p className="mb-0">Net:</p>
											</Bcol>
											<Bcol className="col-6">
												<p className="mb-0 summary-value">${summary().net}</p>
											</Bcol>
										</Brow>
									</div>
								</div>
							</Bcol>
						</Brow>
						<Button htmlType="submit" style={{ display: "none" }} ref={submitButton} onClick={onFinish} />
					</div>
					<Tabs type="card" size="small" defaultActiveKey="1" centered onChange={(key) => handleTab(key)}>
						<TabPane tab="Product Information" key="1">
							{visibleItems.length > 0 && (
								<Table
									type={"company"}
									
									scroll={({ x: 600 }, { y: 400 })}
									size="small"
									bordered
									dataSource={visibleItems}
									columns={columns}
									pagination={false}
								/>
							)}
						</TabPane>
						<TabPane tab="GL Impact" key="gl_impact">
							<div className="tabs">
								<Tables
									type="company"
									scrollX={"50rem"}
									scroll={360}
									perPage={15}
									source={glImpact?.payload?.data}
									col={glImpcatCols}
									load={glImpact.loading}
									paginate
								/>
							</div>
						</TabPane>
						<TabPane tab="System Information" key="logs">
							<div className="tabs">
								<Tables type="company" scrollX={"50rem"} scroll={360} perPage={15} source={log?.payload?.data} col={logColumns} load={log.loading} paginate />
							</div>
						</TabPane>
					</Tabs>
				</div>
			</Form>
		</>
	);
}