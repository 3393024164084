import {
  GET_STYLE_SHOT_REQUEST,
  GET_STYLE_SHOT_SUCCESS,
  GET_STYLE_SHOT_ERROR,
  GET_STYLE_DISTRO_REQUEST,
  GET_STYLE_DISTRO_SUCCESS,
  GET_STYLE_DISTRO_ERROR
} from "./types";
import axios from "axios";
import { BASEURL } from "../../constant/config.js";
import moment from "moment";

export const getStyleShot = (token, data, report) => (dispatch) => {
  dispatch({ type: GET_STYLE_SHOT_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}getAll/styleShot`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: {
      from_date: moment(data.from_date).format("MM-DD-YYYY"),
      to_date: moment(data.to_date).format("MM-DD-YYYY"),
      sku: data.sku === "All" ? null : data.sku,
      gender_id: data.gender_id === undefined || data.gender_id === "All" ? null : data.gender_id,
      new_season_id: data.current_season_id === undefined || data.current_season_id === "All" ? null : data.current_season_id,
      season_year_id: data.current_season_year_id === undefined || data.current_season_year_id === "All" ? null : data.current_season_year_id,
      style_id: data.style_id === undefined || data.style_id === "All" ? null : data.style_id,
      wash_id: data.wash_id === undefined || data.wash_id === "All" ? null : data.wash_id,
      report_id: report.length > 0 ? report.toString() : null,
      customer_id: data.customer === undefined || data.customer === "All" ? null : data.customer,
    },
  })
    .then((response) => {
      return dispatch({
        type: GET_STYLE_SHOT_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_STYLE_SHOT_ERROR,
        payload: [],
      });
    });
};

export const getStyleDistro = (token, data, report) => (dispatch) => {
  dispatch({ type: GET_STYLE_DISTRO_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}getAll/styleDistro`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: {
      from_date: moment(data.from_date).format("MM-DD-YYYY"),
      to_date: moment(data.to_date).format("MM-DD-YYYY"),
      sku: data.sku === "All" ? null : data.sku,
      gender_id: data.gender_id === undefined || data.gender_id === "All" ? null : data.gender_id,
      new_season_id: data.current_season_id === undefined || data.current_season_id === "All" ? null : data.current_season_id,
      season_year_id: data.current_season_year_id === undefined || data.current_season_year_id === "All" ? null : data.current_season_year_id,
      style_id: data.style_id === undefined || data.style_id === "All" ? null : data.style_id,
      wash_id: data.wash_id === undefined || data.wash_id === "All" ? null : data.wash_id,
      report_id: report.length > 0 ? report.toString() : null,
      customer_type_id: data.acc_type === undefined || data.acc_type === "All" ? null : data.acc_type,
      region_id: data.region === undefined || data.region === "All" ? null : data.region,
      sales_person_id: data.salesperson === undefined || data.salesperson === "All" ? null : data.salesperson,
      customer_id: data.customer === undefined || data.customer === "All" ? null : data.customer,
    },
  })
    .then((response) => {
      return dispatch({
        type: GET_STYLE_DISTRO_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_STYLE_DISTRO_ERROR,
        payload: [],
      });
    });
};
