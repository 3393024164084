import { useEffect, useCallback, useRef, useState } from "react";
import "./BusinessDashboard.css";
import { Select, Form, Spin, Button, Space } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { ReadCookie } from "../../utils/readCookie";
import { getRegion } from "../../redux/actions/region.action";
import { getPaymentTerm } from "../../redux/actions/paymentterm.action";
import { getGender } from "../../redux/actions/gender.action";
import { getAllCompany } from "../../redux/actions/companyAccess";
import { getAllScmBrand } from "../../redux/actions/scmBrand.action";
import { getCustomerType } from "../../redux/actions/customerType.action";
import { loadCustomers } from "../../redux/actions/customerForm";
import { getBussinessDashboard } from "../../redux/actions/bussinessDashboard.action";
import logo from "../../assets/images/logo.png";
import btnPrint from "../../assets/images/button_print.gif";
import xlsx from "../../assets/images/ico_xls_sm.gif";
import { TabTitle } from "../../utils/GeneralFunction";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const { Option } = Select;

const BusinessDashboard = ({ token }) => {
  TabTitle("Business Dashboard")
  const userName = ReadCookie("name");
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const focusInput = useRef(null);
  const [loader, setLoader] = useState(false);
  const {
    gender,
    region,
    companyAll,
    scmBrand,
    customerForms,
    getBussinessDashboardReducer,
  } = useSelector((state) => state);
  const menuId = ReadCookie("menuId");
  const [customer, setCustomer] = useState("All");

  let result =
    getBussinessDashboardReducer &&
    getBussinessDashboardReducer?.payload?.data?.reduce(function (r, a) {
      r[a.tran_type] = r[a.tran_type] || [];
      r[a.tran_type].push(a);
      return r;
    }, Object.create(null));

  const bussinessdashboard = result && Object.values(result);

  function debounce(cb, delay = 1000) {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        cb(...args);
      }, delay);
    };
  }

  const handlePrint = () => {
    window.print();
  };

  const handleChanges = (val) => {
    if (val === "" || val === "All") return;
    setCustomer(val);
    dispatch(
      loadCustomers(
        token,
        null,
        null,
        null,
        null,
        null,
        val,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null
      )
    ).then((res) => {
      if (res?.type === "GET_ALL_CUSTOMERS_SUCCESS") {
        setCustomer(res?.payload?.data);
      }
    });
  };

  const optimizedFn = useCallback(debounce(handleChanges), []);

  const onFinish = async (e) => {
    await form.validateFields().then((values) => {
      
      let lastIndex = null;
      let lastYear = null;
      if (values.current_year !== null) {
        
        lastIndex = values.current_year;

        
        lastYear = lastIndex - 1;
      }
      
      values.last_year = lastYear;
      
      dispatch(getBussinessDashboard(token, values));
      setLoader(true);
    });
  };

  useEffect(() => {
    dispatch(getRegion(token, menuId));
    dispatch(getGender(token, menuId));
    dispatch(getPaymentTerm(token, menuId));
    dispatch(getAllCompany(token));
    dispatch(getAllScmBrand(token));
    dispatch(getCustomerType(token));
  }, [dispatch, menuId, token]);

  const [currentCustomer, setCurrentCustomer] = useState()
  


  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col">
          <div className="header_class business_class full-width">
            {/* inputs Container */}

            <div className="row business_header justify-content-between pb-0 marginAuto">
              {/**** / Logo *****/}
              <div className="col-3 d-flex align-items-start box-space">
                <div className="logobrand">
                  <img id="ImgLogo" alt="logo" src={logo} />
                </div>
              </div>
              {/* <div className="logo_Container">
            <img src="http:
          </div> */}
              {/* Items */}
              {/* <div className="col-sm-6 box-space pl-sm-0">
                <div className="brand-para">
                  <div>DL1961 Premium Denim Inc.</div>
                  <div>121 Varick Street</div>
                  <div>4th Floor</div>
                  <div>New York, NY 10013</div>
                  <div>(P) 646-514-9736</div>
                  <div>(F) 212-991-9989</div>
                  <div>(E) sales@DL1961.com</div>
                </div>
              </div> */}
              <div className="col-4 box-space right-content-header text-right pb-0">
                <div className=" d-flex justify-content-end">
                  <div className=" d-flex printing_class mr-8">
                    <div className="print_icon printing_class">
                      <i
                        id="Image1"
                        title="Print"
                        alt="Print"
                        src={btnPrint}
                        className="fa-solid fa-print printing_class"
                        onClick={handlePrint}
                      ></i>
                    </div>
                    <div
                      className="print_icon printing_class"
                      name="ImgExcel"
                      alt="Export to excel"
                      id="ImgExcel"
                      title="Export to excel"
                      src={xlsx}
                    >
                      <i className="fa-solid fa-file-excel"></i>
                    </div>
                  </div>
                  <span style={{ fontSize: "small", fontWeight: "bolder" }}>
                    Buisness Dashboard
                    <br />
                    Rpt # 489
                    <br />
                    Login as:
                    <span id="lblSalesPerson" style={{ fontWeight: "bold" }}>
                      {" "}
                      {userName}
                    </span>
                  </span>
                </div>
              </div>
            </div>

            {/* Filters Table */}
            <div className="col business_header marginAuto">
              <Form form={form} name="control-hooks" onFinish={onFinish}>
                <div className="main_form row">
                  <div className="col-6">
                    <div className=" d-flex flex-row align-items-baseline">
                      <label htmlFor="gender_id" className="labels width30">
                        Gender
                      </label>
                      <div className=" w-100">
                        <Form.Item initialValue={"All"} name="gender_id">
                          <Select
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            showSearch
                            
                            size="small"
                            className={"select_Inputs"}
                            defaultValue={form.getFieldValue().productType}
                            filterSort={(optionA, optionB) =>
                              optionB?.value !== null ?
                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()) : ''
                            }
                          >
                            <Option defaultValue={null}>All</Option>
                            {gender &&
                              gender?.payload?.data.map((gender, i) => {
                                return (
                                  <Option key={gender?.gender_id} value={gender?.gender_id}>
                                    {gender?.gender_name}
                                  </Option>
                                );
                              })}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                    <div className=" d-flex flex-row align-items-baseline">
                      <label className="labels width30">Customer:</label>
                      <div className=" w-100">
                        <Form.Item name="customer_id" initialValue={"All"}>
                          <Select
                            showSearch
                            optionFilterProp="children"
                            className={"select_Inputs"}
                            loading={customerForms.loading}
                            
                            size="small"
                            onSearch={(value) => {
                              optimizedFn(value);
                            }}
                            onSelect={(e) => {
                              setCurrentCustomer(e)
                            }}
                            ref={focusInput}
                            defaultValue="All"
                            value={customer}
                          
                          
                          
                          
                          >
                            <Option defaultValue={null}>All</Option>
                            {customerForms?.payload?.data.map((item) => {
                              return (
                                <Option
                                  key={item.customer_name}
                                  value={item.customer_id}
                                >
                                  {" "}
                                  {`${item.customer_code} ${item.customer_name}`}
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                    <div className=" d-flex flex-row align-items-baseline">
                      <label className="labels width30">Brand:</label>
                      <div className=" w-100">
                        <Form.Item name="company_id" initialValue={2}>
                          <Select
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            showSearch
                            
                            defaultValue={form.getFieldValue().Brand}
                            size="small"
                            className={"select_Inputs"}
                            filterSort={(optionA, optionB) =>
                              optionB?.value !== null ?
                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()) : ''
                            }
                          >
                            <Option defaultValue={null}>All</Option>
                            {companyAll &&
                              companyAll?.payload?.data.map((company, i) => {
                                return (
                                  <Option key={i} value={company.company_id}>
                                    {company.company_name}
                                  </Option>
                                );
                              })}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className=" d-flex flex-row align-items-baseline">
                      <label htmlFor="gender_id" className="labels width30">
                        Region:
                      </label>
                      <div className=" w-100">
                        <Form.Item name="region_id" initialValue={"All"}>
                          <Select
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            showSearch
                            
                            size="small"
                            className={"select_Inputs"}
                            filterSort={(optionA, optionB) =>
                              optionB?.value !== null ?
                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()) : ''
                            }
                            defaultValue={form.getFieldValue().Region}
                          >
                            <Option defaultValue={null}>All</Option>
                            {region &&
                              region?.payload?.data.map((region, i) => {
                                return (
                                  <Option key={region.region_id} value={region.region_id}>
                                    {region.region_name}
                                  </Option>
                                );
                              })}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                    <div className=" d-flex flex-row align-items-baseline">
                      <label className="labels width30">Year:</label>
                      <div className=" w-100">
                        <Form.Item name="current_year" initialValue={"2024"}>
                          <Select
                            style={{ width: "70px" }}
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            showSearch
                            
                            defaultValue={form.getFieldValue().Year}
                            size="small"
                            filterSort={(optionA, optionB) =>
                              optionB?.value !== null ?
                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()) : ''
                            }
                          >
                            <Option defaultValue={null}>All</Option>
                            <Option value="2020">2020</Option>
                            <Option value="2021">2021</Option>
                            <Option value="2022">2022</Option>
                            <Option value="2023">2023</Option>
                            <Option value="2023">2024</Option>
                          </Select>
                        </Form.Item>
                      </div>
                    </div>

                    <div className=" d-flex flex-row align-items-baseline justify-content-end">
                      <label className=" no_visibility">none</label>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="submit-btn-btm m-0"
                      >
                        Generate
                      </Button>
                    </div>
                  </div>
                </div>

                {/* <table
                  id="Table1"
                  cellPadding="3"
                  cellSpacing="0"
                  align="center"
                  className="tablestyleControls"
                >
                  <tbody>
                    <tr>
                      <td width="103">ProductType:</td>
                      <td>
                        <Form.Item initialValue={"All"} name="gender_id">
                          <Select
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            showSearch
                            placeholder="Select Prduct Type"
                            size="small"
                            className={"select_Inputs"}
                            defaultValue={form.getFieldValue().productType}
                          >
                            <Option defaultValue={null}>All</Option>
                            {gender &&
                              gender?.payload?.data.map((gender, i) => {
                                return (
                                  <Option key={i} value={gender?.gender_id}>
                                    {gender?.gender_name}
                                  </Option>
                                );
                              })}
                          </Select>
                        </Form.Item>
                      </td>
                      <td width="103">Region:</td>
                      <td>
                        <Form.Item name="region_id" initialValue={"All"}>
                          <Select
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            showSearch
                            placeholder="Select Prduct Type"
                            size="small"
                            className={"select_Inputs"}
                            defaultValue={form.getFieldValue().Region}
                          >
                            <Option defaultValue={null}>All</Option>
                            {region &&
                              region?.payload?.data.map((region, i) => {
                                return (
                                  <Option key={i} value={region.region_id}>
                                    {region.region_name}
                                  </Option>
                                );
                              })}
                          </Select>
                        </Form.Item>
                      </td>
                    </tr>
                    <tr>
                      <td width="103">Customer:</td>
                      <td>
                        <Form.Item name="customer_id" initialValue={"All"}>
                          <Select
                            showSearch
                            optionFilterProp="children"
                            className={"select_Inputs"}
                            loading={customerForms.loading}
                            placeholder="Select Customers"
                            size="small"
                            onSearch={(value) => {
                              optimizedFn(value);
                            }}
                            ref={focusInput}
                            defaultValue="All"
                            value={customer}
                          >
                            <Option defaultValue={null}>All</Option>
                            {customerForms?.payload?.data.map((item) => {
                              return (
                                <Option
                                  key={item.customer_id}
                                  value={item.customer_id}
                                >
                                  {" "}
                                  {`${item.customer_code} ${item.customer_name}`}
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </td>
                      <td width="103">
                        <Form.Item name="current_year" initialValue={"2021"}>
                          <Select
                            style={{ width: "70px" }}
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            showSearch
                            placeholder="Select Year"
                            defaultValue={form.getFieldValue().Year}
                            size="small"
                          >
                            <Option defaultValue={null}>All</Option>
                            <Option value="2020">2020</Option>
                            <Option value="2021">2021</Option>
                            <Option value="2022">2022</Option>
                            <Option value="2023">2023</Option>
                          </Select>
                        </Form.Item>
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td width="103">Brand:</td>
                      <td>
                        <Form.Item name="company_id" initialValue={2}>
                          <Select
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            showSearch
                            placeholder="Select Prduct Type"
                            defaultValue={form.getFieldValue().Brand}
                            size="small"
                            className={"select_Inputs"}
                          >
                            <Option defaultValue={null}>All</Option>
                            {companyAll &&
                              companyAll?.payload?.data.map((company, i) => {
                                return (
                                  <Option key={i} value={company.company_id}>
                                    {company.company_name}
                                  </Option>
                                );
                              })}
                          </Select>
                        </Form.Item>
                      </td>
                      <td width="103"></td>
                      <td>
                        <div className="generateButton">
                          <Button type="primary" htmlType="submit">
                            Generate
                          </Button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table> */}
              </Form>
            </div>

            {/* Render Data Table */}
            <div className="table_content full-table-width scrollbar-style business-dashboard-table-fit">
              <div className="max-1400-width">
                {bussinessdashboard
                  ? bussinessdashboard.map((bussiness, index) => {
                    return (
                      <div className="data_Table fit-content-width">
                        <div key={index}>
                     
                          <table 
                          
                          className="table full-width business-table-main width-initial"
                          

                          >
                            <tbody className="stripedTable">
                              <tr>
                              <td style={{paddingLeft:"0px"}} colSpan={17}>
                              <div 
                          className="table_Heading-new"
                          
                            style={{ width: "1120px" }}
                          >
                            {bussiness[0].tran_type}
                          </div>
                          </td>
                              </tr>
                              <tr
                              
                              >
                                <td
                                  className="serial_No fontBold"
                                  style={{ width: "26px", minWidth: "26px" }}
                                >
                                  #
                                </td>
                                <td
                                 style={{ width: "70px", minWidth: "70px" }}
                                ></td>
                                <td className="fontBold" 
                                style={{ 
                                  width: "65px", minWidth: "65px",
                                   textAlign: "right", paddingRight: "3px" }}
                                >JAN</td>
                                <td className="fontBold" 
                                style={{ 
                                  width: "65px", minWidth: "65px",
                                   textAlign: "right", paddingRight: "3px" }}
                                >FEB</td>
                                <td className="fontBold" 
                                style={{ 
                                  width: "65px", minWidth: "65px",
                                   textAlign: "right", paddingRight: "3px" }}
                                >MAR</td>
                                <td className="fontBold" 
                                style={{ 
                                  width: "65px", minWidth: "65px",
                                   textAlign: "right", paddingRight: "3px" }}
                                >APR</td>
                                <td className="fontBold" 
                                style={{ 
                                  width: "65px", minWidth: "65px",
                                   textAlign: "right", paddingRight: "3px" }}
                                >MAY</td>
                                <td className="fontBold" 
                                style={{ 
                                  width: "65px", minWidth: "65px",
                                   textAlign: "right", paddingRight: "3px" }}
                                >JUN</td>
                                <td
                                  
                                  style={{ 
                                    width: "80px", minWidth: "65px",
                                     textAlign: "right", paddingRight: "3px" }
                                }
                                  className="spring fontBold"
                                >
                                  SPRING
                                </td>
                                <td className="fontBold" 
                                style={{ 
                                  width: "65px", minWidth: "65px",
                                   textAlign: "right", paddingRight: "3px" }}
                                >JUL</td>
                                <td className="fontBold" 
                                style={{ 
                                  width: "65px", minWidth: "65px",
                                   textAlign: "right", paddingRight: "3px" }}
                                >AUG</td>
                                <td className="fontBold" 
                                style={{ 
                                  width: "65px", minWidth: "65px",
                                   textAlign: "right", paddingRight: "3px" }}
                                >SEP</td>
                                <td className="fontBold" 
                                style={{ 
                                  width: "65px", minWidth: "65px",
                                   textAlign: "right", paddingRight: "3px" }}
                                >OCT</td>
                                <td className="fontBold" 
                                style={{ 
                                  width: "65px", minWidth: "65px",
                                   textAlign: "right", paddingRight: "3px" }}
                                >NOV</td>
                                <td className="fontBold" 
                                style={{ 
                                  width: "65px", minWidth: "65px",
                                   textAlign: "right", paddingRight: "3px" }}
                                >DEC</td>
                                <td
                                  style={{ 
                                    width: "80px", minWidth: "80px",
                                   textAlign: "right", paddingRight: "3px" }}
                                  className="fall fontBold"
                                >
                                  FALL
                                </td>
                                <td
                                  style={{ 
                                    width: "80px", minWidth: "80px",
                                   textAlign: "right", paddingRight: "3px" }}
                                  className="total fontBold"
                                >
                                  TOTAL
                                </td>
                              </tr>
                              {/* Table data */}
                              {bussiness.map((data, index) => {
                                return (
                                  <tr key={index}>
                                    <td className="serial_No color_black">
                                      {index + 1}</td>
                                    <td className="color_black">{data && data.yearr}</td>

                                    <td className={`${(data.jan < 0) ? "color_red" : (data.yearr == '% VS LY') ? "color_green" : ""}`} style={{ textAlign: "right", paddingRight: "3px" }}>
                                      {data && (data.tran_type === "PENDING ORDERS" || data.tran_type === "PENDING POD") ? (
                                        <Link style={{ color: "blue", textDecoration: "underline" }}
                                          to={{
                                            pathname: `${data.tran_type === "PENDING ORDERS" ? "/pending-order-summary-report" : "/Pending-POD-summary-report"}`,
                                            search: `?submited=${true}&from_date=${"01/01/2024"}&to_date=${"01/31/2024"}&customer_name=${(customerForms?.payload?.data?.find(x => x?.customer_id === currentCustomer)?.customer_name) === undefined ? "" : customerForms?.payload?.data?.find(x => x?.customer_id === currentCustomer)?.customer_name}&region_id=${form.getFieldValue("region_id") === null ? "All" : form.getFieldValue("region_id")}&gender_id=${form.getFieldValue("gender_id") === null ? "All" : form.getFieldValue("gender_id")}`,
                                          }}
                                          target="_blank"
                                        >
                                          {data && bussiness[0].tran_type.includes('$') && data.yearr != '% VS LY' && data.yearr != 'PLAN ACHVD' ? '$' : ""}
                                          {data && data.jan}
                                          {data && data.yearr == '% VS LY' || data.yearr == 'PLAN ACHVD' ? '%' : ""}
                                        </Link>
                                      ) : (
                                        <>
                                          {data && bussiness[0].tran_type.includes('$') && data.yearr != '% VS LY' && data.yearr != 'PLAN ACHVD' ? '$' : ""}
                                          {data && data.jan}
                                          {data && data.yearr == '% VS LY' || data.yearr == 'PLAN ACHVD' ? '%' : ""}
                                        </>
                                      )}
                                    </td>
                                    <td className={`${data.feb < 0 ? "color_red" : (data.yearr == '% VS LY') ? "color_green" : ""}`} style={{ textAlign: "right", paddingRight: "3px" }}>
                                      {data && (data.tran_type === "PENDING ORDERS" || data.tran_type === "PENDING POD") ? (
                                        <Link style={{ color: "blue", textDecoration: "underline" }}
                                          to={{
                                            pathname: `${data.tran_type === "PENDING ORDERS" ? "/pending-order-summary-report" : "/Pending-POD-summary-report"}`,
                                            search: `?submited=${true}&from_date=${"02/01/2024"}&to_date=${"02/29/2024"}&customer_name=${(customerForms?.payload?.data?.find(x => x?.customer_id === currentCustomer)?.customer_name) === undefined ? "" : customerForms?.payload?.data?.find(x => x?.customer_id === currentCustomer)?.customer_name}&region_id=${form.getFieldValue("region_id") === null ? "All" : form.getFieldValue("region_id")}&gender_id=${form.getFieldValue("gender_id") === null ? "All" : form.getFieldValue("gender_id")}`,
                                          }}
                                          target="_blank"
                                        >
                                          {data && bussiness[0].tran_type.includes('$') && data.yearr != '% VS LY' && data.yearr != 'PLAN ACHVD' ? '$' : ""}
                                          {data && data.feb}
                                          {data && data.yearr == '% VS LY' || data.yearr == 'PLAN ACHVD' ? '%' : ""}
                                        </Link>
                                      ) : (
                                        <>
                                          {data && bussiness[0].tran_type.includes('$') && data.yearr != '% VS LY' && data.yearr != 'PLAN ACHVD' ? '$' : ""}
                                          {data && data.feb}
                                          {data && data.yearr == '% VS LY' || data.yearr == 'PLAN ACHVD' ? '%' : ""}
                                        </>
                                      )}
                                    </td>
                                    <td className={`${data.mar < 0 ? "color_red" : (data.yearr == '% VS LY') ? "color_green" : ""}`} style={{ textAlign: "right", paddingRight: "3px" }}>
                                      {data && (data.tran_type === "PENDING ORDERS" || data.tran_type === "PENDING POD") ? (
                                        <Link style={{ color: "blue", textDecoration: "underline" }}
                                          to={{
                                            pathname: `${data.tran_type === "PENDING ORDERS" ? "/pending-order-summary-report" : "/Pending-POD-summary-report"}`,
                                            search: `?submited=${true}&from_date=${"03/01/2024"}&to_date=${"03/31/2024"}&customer_name=${(customerForms?.payload?.data?.find(x => x?.customer_id === currentCustomer)?.customer_name) === undefined ? "" : customerForms?.payload?.data?.find(x => x?.customer_id === currentCustomer)?.customer_name}&region_id=${form.getFieldValue("region_id") === null ? "All" : form.getFieldValue("region_id")}&gender_id=${form.getFieldValue("gender_id") === null ? "All" : form.getFieldValue("gender_id")}`,
                                          }}
                                          target="_blank"
                                        >
                                          {data && bussiness[0].tran_type.includes('$') && data.yearr != '% VS LY' && data.yearr != 'PLAN ACHVD' ? '$' : ""}
                                          {data && data.mar}
                                          {data && data.yearr == '% VS LY' || data.yearr == 'PLAN ACHVD' ? '%' : ""}
                                        </Link>
                                      ) : (
                                        <>
                                          {data && bussiness[0].tran_type.includes('$') && data.yearr != '% VS LY' && data.yearr != 'PLAN ACHVD' ? '$' : ""}
                                          {data && data.mar}
                                          {data && data.yearr == '% VS LY' || data.yearr == 'PLAN ACHVD' ? '%' : ""}
                                        </>
                                      )}
                                    </td>
                                    <td className={`${data.apr < 0 ? "color_red" : (data.yearr == '% VS LY') ? "color_green" : ""}`} style={{ textAlign: "right", paddingRight: "3px" }}>
                                      {data && (data.tran_type === "PENDING ORDERS" || data.tran_type === "PENDING POD") ? (
                                        <Link style={{ color: "blue", textDecoration: "underline" }}
                                          to={{
                                            pathname: `${data.tran_type === "PENDING ORDERS" ? "/pending-order-summary-report" : "/Pending-POD-summary-report"}`,
                                            search: `?submited=${true}&from_date=${"04/01/2024"}&to_date=${"04/30/2024"}&customer_name=${(customerForms?.payload?.data?.find(x => x?.customer_id === currentCustomer)?.customer_name) === undefined ? "" : customerForms?.payload?.data?.find(x => x?.customer_id === currentCustomer)?.customer_name}&region_id=${form.getFieldValue("region_id") === null ? "All" : form.getFieldValue("region_id")}&gender_id=${form.getFieldValue("gender_id") === null ? "All" : form.getFieldValue("gender_id")}`,
                                          }}
                                          target="_blank"
                                        >
                                          {data && bussiness[0].tran_type.includes('$') && data.yearr != '% VS LY' && data.yearr != 'PLAN ACHVD' ? '$' : ""}
                                          {data && data.apr}
                                          {data && data.yearr == '% VS LY' || data.yearr == 'PLAN ACHVD' ? '%' : ""}
                                        </Link>
                                      ) : (
                                        <>
                                          {data && bussiness[0].tran_type.includes('$') && data.yearr != '% VS LY' && data.yearr != 'PLAN ACHVD' ? '$' : ""}
                                          {data && data.apr}
                                          {data && data.yearr == '% VS LY' || data.yearr == 'PLAN ACHVD' ? '%' : ""}
                                        </>
                                      )}
                                    </td>
                                    <td className={`${data.may < 0 ? "color_red" : (data.yearr == '% VS LY') ? "color_green" : ""}`} style={{ textAlign: "right", paddingRight: "3px" }}>
                                      {data && (data.tran_type === "PENDING ORDERS" || data.tran_type === "PENDING POD") ? (
                                        <Link style={{ color: "blue", textDecoration: "underline" }}
                                          to={{
                                            pathname: `${data.tran_type === "PENDING ORDERS" ? "/pending-order-summary-report" : "/Pending-POD-summary-report"}`,
                                            search: `?submited=${true}&from_date=${"05/01/2024"}&to_date=${"05/31/2024"}&customer_name=${(customerForms?.payload?.data?.find(x => x?.customer_id === currentCustomer)?.customer_name) === undefined ? "" : customerForms?.payload?.data?.find(x => x?.customer_id === currentCustomer)?.customer_name}&region_id=${form.getFieldValue("region_id") === null ? "All" : form.getFieldValue("region_id")}&gender_id=${form.getFieldValue("gender_id") === null ? "All" : form.getFieldValue("gender_id")}`,
                                          }}
                                          target="_blank"
                                        >
                                          {data && bussiness[0].tran_type.includes('$') && data.yearr != '% VS LY' && data.yearr != 'PLAN ACHVD' ? '$' : ""}
                                          {data && data.may}
                                          {data && data.yearr == '% VS LY' || data.yearr == 'PLAN ACHVD' ? '%' : ""}
                                        </Link>
                                      ) : (
                                        <>
                                          {data && bussiness[0].tran_type.includes('$') && data.yearr != '% VS LY' && data.yearr != 'PLAN ACHVD' ? '$' : ""}
                                          {data && data.may}
                                          {data && data.yearr == '% VS LY' || data.yearr == 'PLAN ACHVD' ? '%' : ""}
                                        </>
                                      )}
                                    </td>
                                    <td className={`${data.jun < 0 ? "color_red" : (data.yearr == '% VS LY') ? "color_green" : ""}`} style={{ textAlign: "right", paddingRight: "3px" }}>
                                      {data && (data.tran_type === "PENDING ORDERS" || data.tran_type === "PENDING POD") ? (
                                        <Link style={{ color: "blue", textDecoration: "underline" }}
                                          to={{
                                            pathname: `${data.tran_type === "PENDING ORDERS" ? "/pending-order-summary-report" : "/Pending-POD-summary-report"}`,
                                            search: `?submited=${true}&from_date=${"06/01/2024"}&to_date=${"06/30/2024"}&customer_name=${(customerForms?.payload?.data?.find(x => x?.customer_id === currentCustomer)?.customer_name) === undefined ? "" : customerForms?.payload?.data?.find(x => x?.customer_id === currentCustomer)?.customer_name}&region_id=${form.getFieldValue("region_id") === null ? "All" : form.getFieldValue("region_id")}&gender_id=${form.getFieldValue("gender_id") === null ? "All" : form.getFieldValue("gender_id")}`,
                                          }}
                                          target="_blank"
                                        >
                                          {data && bussiness[0].tran_type.includes('$') && data.yearr != '% VS LY' && data.yearr != 'PLAN ACHVD' ? '$' : ""}
                                          {data && data.jun}
                                          {data && data.yearr == '% VS LY' || data.yearr == 'PLAN ACHVD' ? '%' : ""}
                                        </Link>
                                      ) : (
                                        <>
                                          {data && bussiness[0].tran_type.includes('$') && data.yearr != '% VS LY' && data.yearr != 'PLAN ACHVD' ? '$' : ""}
                                          {data && data.jun}
                                          {data && data.yearr == '% VS LY' || data.yearr == 'PLAN ACHVD' ? '%' : ""}
                                        </>
                                      )}
                                    </td>
                                    <td className={`${data.spring < 0 ? "color_red" : (data.yearr == '% VS LY') ? "color_green" : ""} spring`} style={{ textAlign: "right", paddingRight: "3px" }}>
                                      {data && bussiness[0].tran_type.includes('$') && data.yearr != '% VS LY' && data.yearr != 'PLAN ACHVD' ? '$' : ""}{data && data.spring}{data && data.yearr == '% VS LY' || data.yearr == 'PLAN ACHVD' ? '%' : ""}
                                    </td>
                                    <td className={`${data.jul < 0 ? "color_red" : (data.yearr == '% VS LY') ? "color_green" : ""}`} style={{ textAlign: "right", paddingRight: "3px" }}>
                                      {data && (data.tran_type === "PENDING ORDERS" || data.tran_type === "PENDING POD") ? (
                                        <Link style={{ color: "blue", textDecoration: "underline" }}
                                          to={{
                                            pathname: `${data.tran_type === "PENDING ORDERS" ? "/pending-order-summary-report" : "/Pending-POD-summary-report"}`,
                                            search: `?submited=${true}&from_date=${"07/01/2024"}&to_date=${"07/31/2024"}&customer_name=${(customerForms?.payload?.data?.find(x => x?.customer_id === currentCustomer)?.customer_name) === undefined ? "" : customerForms?.payload?.data?.find(x => x?.customer_id === currentCustomer)?.customer_name}&region_id=${form.getFieldValue("region_id") === null ? "All" : form.getFieldValue("region_id")}&gender_id=${form.getFieldValue("gender_id") === null ? "All" : form.getFieldValue("gender_id")}`,
                                          }}
                                          target="_blank"
                                        >
                                          {data && bussiness[0].tran_type.includes('$') && data.yearr != '% VS LY' && data.yearr != 'PLAN ACHVD' ? '$' : ""}
                                          {data && data.jul}
                                          {data && data.yearr == '% VS LY' || data.yearr == 'PLAN ACHVD' ? '%' : ""}
                                        </Link>
                                      ) : (
                                        <>
                                          {data && bussiness[0].tran_type.includes('$') && data.yearr != '% VS LY' && data.yearr != 'PLAN ACHVD' ? '$' : ""}
                                          {data && data.jul}
                                          {data && data.yearr == '% VS LY' || data.yearr == 'PLAN ACHVD' ? '%' : ""}
                                        </>
                                      )}
                                    </td>
                                    <td className={`${data.aug < 0 ? "color_red" : (data.yearr == '% VS LY') ? "color_green" : ""}`} style={{ textAlign: "right", paddingRight: "3px" }}>
                                      {data && (data.tran_type === "PENDING ORDERS" || data.tran_type === "PENDING POD") ? (
                                        <Link style={{ color: "blue", textDecoration: "underline" }}
                                          to={{
                                            pathname: `${data.tran_type === "PENDING ORDERS" ? "/pending-order-summary-report" : "/Pending-POD-summary-report"}`,
                                            search: `?submited=${true}&from_date=${"08/01/2024"}&to_date=${"08/31/2024"}&customer_name=${(customerForms?.payload?.data?.find(x => x?.customer_id === currentCustomer)?.customer_name) === undefined ? "" : customerForms?.payload?.data?.find(x => x?.customer_id === currentCustomer)?.customer_name}&region_id=${form.getFieldValue("region_id") === null ? "All" : form.getFieldValue("region_id")}&gender_id=${form.getFieldValue("gender_id") === null ? "All" : form.getFieldValue("gender_id")}`,
                                          }}
                                          target="_blank"
                                        >
                                          {data && bussiness[0].tran_type.includes('$') && data.yearr != '% VS LY' && data.yearr != 'PLAN ACHVD' ? '$' : ""}
                                          {data && data.aug}
                                          {data && data.yearr == '% VS LY' || data.yearr == 'PLAN ACHVD' ? '%' : ""}
                                        </Link>
                                      ) : (
                                        <>
                                          {data && bussiness[0].tran_type.includes('$') && data.yearr != '% VS LY' && data.yearr != 'PLAN ACHVD' ? '$' : ""}
                                          {data && data.aug}
                                          {data && data.yearr == '% VS LY' || data.yearr == 'PLAN ACHVD' ? '%' : ""}
                                        </>
                                      )}
                                    </td>
                                    <td className={`${data.sep < 0 ? "color_red" : (data.yearr == '% VS LY') ? "color_green" : ""}`} style={{ textAlign: "right", paddingRight: "3px" }}>
                                      {data && (data.tran_type === "PENDING ORDERS" || data.tran_type === "PENDING POD") ? (
                                        <Link style={{ color: "blue", textDecoration: "underline" }}
                                          to={{
                                            pathname: `${data.tran_type === "PENDING ORDERS" ? "/pending-order-summary-report" : "/Pending-POD-summary-report"}`,
                                            search: `?submited=${true}&from_date=${"09/01/2024"}&to_date=${"09/30/2024"}&customer_name=${(customerForms?.payload?.data?.find(x => x?.customer_id === currentCustomer)?.customer_name) === undefined ? "" : customerForms?.payload?.data?.find(x => x?.customer_id === currentCustomer)?.customer_name}&region_id=${form.getFieldValue("region_id") === null ? "All" : form.getFieldValue("region_id")}&gender_id=${form.getFieldValue("gender_id") === null ? "All" : form.getFieldValue("gender_id")}`,
                                          }}
                                          target="_blank"
                                        >
                                          {data && bussiness[0].tran_type.includes('$') && data.yearr != '% VS LY' && data.yearr != 'PLAN ACHVD' ? '$' : ""}
                                          {data && data.sep}
                                          {data && data.yearr == '% VS LY' || data.yearr == 'PLAN ACHVD' ? '%' : ""}
                                        </Link>
                                      ) : (
                                        <>
                                          {data && bussiness[0].tran_type.includes('$') && data.yearr != '% VS LY' && data.yearr != 'PLAN ACHVD' ? '$' : ""}
                                          {data && data.sep}
                                          {data && data.yearr == '% VS LY' || data.yearr == 'PLAN ACHVD' ? '%' : ""}
                                        </>
                                      )}
                                    </td>
                                    <td className={`${data.oct < 0 ? "color_red" : (data.yearr == '% VS LY') ? "color_green" : ""}`} style={{ textAlign: "right", paddingRight: "3px" }}>
                                      {data && (data.tran_type === "PENDING ORDERS" || data.tran_type === "PENDING POD") ? (
                                        <Link style={{ color: "blue", textDecoration: "underline" }}
                                          to={{
                                            pathname: `${data.tran_type === "PENDING ORDERS" ? "/pending-order-summary-report" : "/Pending-POD-summary-report"}`,
                                            search: `?submited=${true}&from_date=${"10/01/2024"}&to_date=${"10/31/2024"}&customer_name=${(customerForms?.payload?.data?.find(x => x?.customer_id === currentCustomer)?.customer_name) === undefined ? "" : customerForms?.payload?.data?.find(x => x?.customer_id === currentCustomer)?.customer_name}&region_id=${form.getFieldValue("region_id") === null ? "All" : form.getFieldValue("region_id")}&gender_id=${form.getFieldValue("gender_id") === null ? "All" : form.getFieldValue("gender_id")}`,
                                          }}
                                          target="_blank"
                                        >
                                          {data && bussiness[0].tran_type.includes('$') && data.yearr != '% VS LY' && data.yearr != 'PLAN ACHVD' ? '$' : ""}
                                          {data && data.oct}
                                          {data && data.yearr == '% VS LY' || data.yearr == 'PLAN ACHVD' ? '%' : ""}
                                        </Link>
                                      ) : (
                                        <>
                                          {data && bussiness[0].tran_type.includes('$') && data.yearr != '% VS LY' && data.yearr != 'PLAN ACHVD' ? '$' : ""}
                                          {data && data.oct}
                                          {data && data.yearr == '% VS LY' || data.yearr == 'PLAN ACHVD' ? '%' : ""}
                                        </>
                                      )}
                                    </td>
                                    <td className={`${data.nov < 0 ? "color_red" : (data.yearr == '% VS LY') ? "color_green" : ""}`} style={{ textAlign: "right", paddingRight: "3px" }}>
                                      {data && (data.tran_type === "PENDING ORDERS" || data.tran_type === "PENDING POD") ? (
                                        <Link style={{ color: "blue", textDecoration: "underline" }}
                                          to={{
                                            pathname: `${data.tran_type === "PENDING ORDERS" ? "/pending-order-summary-report" : "/Pending-POD-summary-report"}`,
                                            search: `?submited=${true}&from_date=${"11/01/2024"}&to_date=${"11/30/2024"}&customer_name=${(customerForms?.payload?.data?.find(x => x?.customer_id === currentCustomer)?.customer_name) === undefined ? "" : customerForms?.payload?.data?.find(x => x?.customer_id === currentCustomer)?.customer_name}&region_id=${form.getFieldValue("region_id") === null ? "All" : form.getFieldValue("region_id")}&gender_id=${form.getFieldValue("gender_id") === null ? "All" : form.getFieldValue("gender_id")}`,
                                          }}
                                          target="_blank"
                                        >
                                          {data && bussiness[0].tran_type.includes('$') && data.yearr != '% VS LY' && data.yearr != 'PLAN ACHVD' ? '$' : ""}
                                          {data && data.nov}
                                          {data && data.yearr == '% VS LY' || data.yearr == 'PLAN ACHVD' ? '%' : ""}
                                        </Link>
                                      ) : (
                                        <>
                                          {data && bussiness[0].tran_type.includes('$') && data.yearr != '% VS LY' && data.yearr != 'PLAN ACHVD' ? '$' : ""}
                                          {data && data.nov}
                                          {data && data.yearr == '% VS LY' || data.yearr == 'PLAN ACHVD' ? '%' : ""}
                                        </>
                                      )}
                                    </td>
                                    <td className={`${data.decc < 0 ? "color_red" : (data.yearr == '% VS LY') ? "color_green" : ""}`} style={{ textAlign: "right", paddingRight: "3px" }}>
                                      {data && (data.tran_type === "PENDING ORDERS" || data.tran_type === "PENDING POD") ? (
                                        <Link style={{ color: "blue", textDecoration: "underline" }}
                                          to={{
                                            pathname: `${data.tran_type === "PENDING ORDERS" ? "/pending-order-summary-report" : "/Pending-POD-summary-report"}`,
                                            search: `?submited=${true}&from_date=${"12/01/2024"}&to_date=${"12/31/2024"}&customer_name=${(customerForms?.payload?.data?.find(x => x?.customer_id === currentCustomer)?.customer_name) === undefined ? "" : customerForms?.payload?.data?.find(x => x?.customer_id === currentCustomer)?.customer_name}&region_id=${form.getFieldValue("region_id") === null ? "All" : form.getFieldValue("region_id")}&gender_id=${form.getFieldValue("gender_id") === null ? "All" : form.getFieldValue("gender_id")}`,
                                          }}
                                          target="_blank"
                                        >
                                          {data && bussiness[0].tran_type.includes('$') && data.yearr != '% VS LY' && data.yearr != 'PLAN ACHVD' ? '$' : ""}
                                          {data && data.decc}
                                          {data && data.yearr == '% VS LY' || data.yearr == 'PLAN ACHVD' ? '%' : ""}
                                        </Link>
                                      ) : (
                                        <>
                                          {data && bussiness[0].tran_type.includes('$') && data.yearr != '% VS LY' && data.yearr != 'PLAN ACHVD' ? '$' : ""}
                                          {data && data.decc}
                                          {data && data.yearr == '% VS LY' || data.yearr == 'PLAN ACHVD' ? '%' : ""}
                                        </>
                                      )}
                                    </td>
                                    <td className={`${data.fall < 0 ? "color_red" : (data.yearr == '% VS LY') ? "color_green" : ""} fall`} style={{ textAlign: "right", paddingRight: "3px" }}>
                                      {data && bussiness[0].tran_type.includes('$') && data.yearr != '% VS LY' && data.yearr != 'PLAN ACHVD' ? '$' : ""}{data && data.fall}{data && data.yearr == '% VS LY' || data.yearr == 'PLAN ACHVD' ? '%' : ""}
                                    </td>
                                    <td className={`${data.total < 0 ? "color_red" : (data.yearr == '% VS LY') ? "color_green" : ""} total`} style={{ textAlign: "right", paddingRight: "3px" }}>
                                      {data && bussiness[0].tran_type.includes('$') && data.yearr != '% VS LY' && data.yearr != 'PLAN ACHVD' ? '$' : ""}{data && data.total}{data && data.yearr == '% VS LY' || data.yearr == 'PLAN ACHVD' ? '%' : ""}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    );
                  })
                  : loader && (
                    <div className="loader">
                      <Space size="middle">
                        <Spin />
                      </Space>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessDashboard;