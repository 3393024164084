import React, { useEffect, useRef, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getOneSaleReturnHeader, addSaleReturnHeader, getSaleReturneHeaderFormData, updateSaleReturnHeader } from "../../../../redux/actions/salesReturn.action";
import { getShipping, getSingleShipping } from "../../../../redux/actions/shipping";
import { getOneCustomer } from "../../../../redux/actions/CustomerFormById";
import { CaretRightOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";

import { getBilling, getSingleBilling } from "../../../../redux/actions/billing";
import { getOneSalesLine, getOneSalesLineDetail, addSalesReturnLine, updateSalesReturnLine } from "../../../../redux/actions/salesReturnLine.action";
import { getSaleOrderGenderHeader } from "../../../../redux/actions/saleOrderGenderHeader.action";
import { getAllCompany } from "../../../../redux/actions/companyAccess";
import { getLog } from "../../../../redux/actions/log.action";
import { ReadCookie } from "../../../../utils/readCookie";
import notification from "../../../../utils/notification";
import Tables from "../../../../components/Table/Tables";
import { logColumns } from "../../../../utils/cols";
import SubTitles from "../../../../components/SubTitle/SubTitle";
import Loader from "react-loader-spinner";
import moment from "moment";
import { DatePicker, Input, Tabs, Button, Form, Select, Card, Row, Col, Table, InputNumber, Collapse, Tooltip, Popconfirm } from "antd";
import { Row as Brow, Col as Bcol } from "reactstrap";
import "../../../../screens/SaleOrder/salesOrder.css";
import Swal from "sweetalert2";
import EditableTable from "../../EditableTable";
import { getGLImpact } from "../../../../redux/actions/gl_impact.action";
import { getSaleOrderFormData } from "../../../../redux/actions/salesOrder.action";
import { getGender } from "../../../../redux/actions/gender.action";

const { Option } = Select;
const { TabPane } = Tabs;
const { Panel } = Collapse;
const { TextArea } = Input;

export default function SaleReturnForm({ formData, val, token, formState, submit, shipHeaderId, edit }) {
	const submitButton = useRef(null);
	const [form] = Form.useForm();
	const menuId = ReadCookie("menuId");
	const dispatch = useDispatch();
	const { log, salesReturnFormDataReducer, Shipping, Billing, glImpact, customerForms, saleOrderFormData, gender } = useSelector((state) => state);
	const companyId = ReadCookie("defaultCompany");
	const [gender1, setGender1] = useState(null);
	const [customer, setCustomer] = useState("All");

	
	
	const [oDate, setOrderDate] = useState(moment());
	const [sDate, setStartDate] = useState(moment());
	const [eDate, setEndDate] = useState(moment());
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [invoiceDate, setInvoiceDate] = useState(moment());
	
	const [visibleItems, setVisibleItems] = useState([]);
	const [orderItems, setOrderItems] = useState([]);

	const [isShipComplete, setIsShipComplete] = useState(false);
	const [isClosed, setIsClosed] = useState(false);
	const [loading, setLoading] = useState(false);
	const history = useHistory();
	const startDateList = "01-1-2022"
	const endDateList = "06-01-2022";
	const focusInput = useRef(null);

	
	
	
	useEffect(() => {
		dispatch(getGender(token, false));
	}, []);

	const glImpcatCols = [
		{
			title: "Transaction ID",
			dataIndex: "transaction_header_id",
		},
		{
			title: "Transaction Type",
			dataIndex: "transaction_type",
		},
		{
			title: "Credit",
			dataIndex: "credit",
		},
		{
			title: "Debit",
			dataIndex: "debit",
		},
		{
			title: "created_date",
			dataIndex: "created_date",
		}
	]

	const handleDeleteOrder = (record) => {
		const dataSource = [...visibleItems];
		if (dataSource.length > 0) {
			setVisibleItems(dataSource.filter((item) => item.item_id !== record.item_id));
			
			
			
			
		} else {
			const dataSource = [...visibleItems];
			setOrderItems(dataSource.filter((item) => item.item_id !== record.item_id));

		}
		notification("success", "Item Deleted");
	};

	const columns = [
		{
			title: "Item Code",
			dataIndex: "item_code",
		},
		{
			title: "Item ID",
			dataIndex: "item_id",
		},
		{
			title: "Cost Price",
			dataIndex: "rate",
			editable: true,
		},
		{
			title: "Balance Quantity",
			dataIndex: "balance_quantity",
			editable: true,
		},
		{
			title: "Quantity",
			dataIndex: "quantity",
			editable: true,
			render: (text, record) => {
				return (
					<InputNumber
						size="small"
						min="0"
						
						onStep={(value, info) => updateQuantity(info.type, record.item_code, value)}
						value={record.quantity}
						onChange={(value) => updateQuantity("up", record.item_code, value)}
						defaultValue={0}
					/>
				);
			},
		},
		{
			title: "Amount",
			render: (_, record) => parseFloat(record.quantity * record.rate).toFixed(2),
		},
		{
			title: "Operation",
			dataIndex: "operation",
			render: (_, record, index) => (
				<Tooltip title="Delete" color={"white"}>
					{formState === "edit" ? (
						<>
							<Popconfirm
								title={`Are you sure to delete the item ${record.item_id}?`}
								onConfirm={() => handleDeleteOrder(record)}
							>
								<DeleteOutlined className={`delete-Button`} />
							</Popconfirm>
							{/* <EditOutlined
								className={`edit-Button`}
								onClick={() => {
									showEditModal(record, index)
									setItemIndex(index)
								}}
							
							/> */}
						</>
					) : (
						<>
							<DeleteOutlined className={`delete-Button opacity-40`} />
							{/* <EditOutlined className={`edit-Button opacity-40`} /> */}
						</>
					)}
				</Tooltip>
			),
		},
	];

	const updateQuantity = (type, id, value) => {
		let arr = [...visibleItems];
		value = parseInt(value);
		const filter = arr.map((item) => {
			if (item.item_code === id) {
				let balance = parseInt(item.balance_quantity);
				if (value > balance) {
					Swal.fire({
						icon: "error",
						title: "Oops...",
						text: "Maxiumum Quantity Exceed",
					});
				}
				if (type === "up") {
					item.quantity = value > balance ? balance : value;
					item.amount = parseFloat(item.quantity * item.rate).toFixed(2);
					return item;
				}

				if (type === "down") {
					item.quantity = value > balance ? balance : value;
					item.amount = parseFloat(item.quantity * item.rate).toFixed(2);
					return item;
				}
			} else {
				return item;
			}
		});

		setVisibleItems(filter);
	};

	function handleChange(value, type) {
		if (type === "company") {
			companyId(value);
			form.setFieldsValue({
				company: value,
			});
		}

		if (type === "factor") {
			form.setFieldsValue({
				factor: value,
			});
		}

		if (type === "gender") {
			form.setFieldsValue({
				category: null,
				region: null,
				salesPerson: null,
			});
			if (customer) {
				setGender1(value);
				dispatch(getSaleOrderGenderHeader(token, value, customer)).then((res) => {
					if (res.type === "GET_GENDER_HEADER_DATA_SUCCESS" && res?.payload.length > 0) {
						form.setFieldsValue({
							category: res.payload[0].sp_region_category_id,
							region: res.payload[0].region_id,
							salesPerson: res.payload[0].salesperson_id,
						});
					}
				});
			} else {
				setGender1(null);
			}
		}
	}

	
	
	
	
	
	
	
	
	
	

	
	
	
	
	
	
	
	
	
	

	useEffect(() => {
		if (formState === "add") {
			focusInput.current.focus();
			form.setFieldsValue({
				customer: null,
				company: null,
				startDate: null,
				orderDate: null,
				cancelDate: null,
				endDate: null,
				orderNumber: null,
				category: null,
				gender1: null,
				genderId: null,
				orderStatus: null,
				location: null,
				store: null,
				salesPerson: null,
				region: null,
				memo: null,
				resaleCertificateNo: null,
				externalRefID: null,
				shippingMethodId: null,
				shippingCost: null,
				shippingTaxCode: null,
				shippingTaxRate: null,
				shippingAddressID: null,
				shipViaId: null,
				termForPrint: null,
				paymentTermId: null,
				freightTermId: null,
				freightAmount: null,
				freightCharges: null,
				isShipComplete: null,
				isClosed: null,
				deptDescription: null,
				divisionCode: null,
				dc: null,
				reference_no: null,
				divisionDescription: null,
				markForCode: null,
				markForCompanyName: null,
				markForAddress1: null,
				markForAddress2: null,
				markForCity: null,
				markForState: null,
				markForZip: null,
				markForCountry: null,
				markForEmail: null,
				markForPhone: null,
				factor: null,
				shippingAccountNo: null,
				berganBillingId: null,
				sourceType: null,
				shipHeaderId: null,
				return_date: null,
				invoiceNo: null,
			});

		}
	}, [formState]);

	const onFinish = async (e) => {
		submit(false);
		await form.validateFields().then((values) => {
			setLoading(true);
			
			if (!edit) {

				dispatch(addSaleReturnHeader(token, formData, values)).then((res) => {
					let returnHeaderId = res?.payload?.return_header_id;

					if (res.type === "ADD_SALE_RETURN_HEADER_SUCCESS") {
						let items = visibleItems.map((items) => {
							return {
								...items,
								return_header_id: res.payload.return_header_id,
							};
						});
						dispatch(
							addSalesReturnLine(
								token,
								items.map((item) => {
									delete item.balance_quantity;
									delete item.amount;
									item.net_amount = item.gross_amount;
									return item;
								})
							)
						).then((res) => {
							if (res.type === "ADD_SALES_RETURN_LINE_SUCCESS") {
								dispatch(getOneSaleReturnHeader(token, returnHeaderId)).then((res) => {
									form.setFieldsValue({ invoiceNo: "Abc" })
								});
								setLoading(false);
								notification("success", "Sale Return Created", res?.payload?.data);
								
							} else if (res.type === "ADD_SALE_RETURN_HEADER_ERROR") {
								Swal.fire({
									icon: "error",
									title: "Oops...",
									text: res?.payload?.data?.data,
								});
							}
						});
					} else if (res.type === "ADD_SALE_RETURN_HEADER_ERROR") {
						setLoading(false);
						Swal.fire({
							icon: "error",
							title: "Oops...",
							text: res?.payload?.data?.data,
						});
					}
				});

			} else {
				let items = visibleItems;
				dispatch(updateSaleReturnHeader(token, formData, values, shipHeaderId))

				dispatch(
					updateSalesReturnLine(
						token,
						shipHeaderId,
						items.map((item) => {
							delete item.balance_quantity;
							delete item.amount;
							delete item.item_code;
							delete item.parent_item_id;
							delete item.return_header_id;
							delete item.last_updated_by;
							delete item.last_updated_date;
							delete item.created_date;
							delete item.created_by;
							delete item.deleted_date;
							delete item.deleted_by;
							item.net_amount = item.gross_amount;
							return item;
						})
					)

				).then((res) => {
					if (res.type === "UPDATE_SALES_RETURN_LINE_SUCCESS") {
						setLoading(false);
						dispatch(getOneSaleReturnHeader(token, shipHeaderId)).then((res) => {
							form.setFieldsValue({ invoiceNo: "Abc" })
						});
						notification("success", "Sale Return Created", res?.payload?.data);
						
					} else if (res.type === "UPDATE_SALES_RETURN_LINE_ERROR") {
						setLoading(false);
						Swal.fire({
							icon: "error",
							title: "Oops...",
							text: res?.payload?.data?.data,
						});
					}
				});
			}
		});
	};

	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	

	
	
	

	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	

	useEffect(() => {
		if (val) {
			submitButton.current.click();
		}
	}, [val]);

	const handleTab = (key) => {
		if (key === "logs") {
			dispatch(
				getLog(token, {
					inv_item_size: "inv_item_size",
					inv_item_inseam: "inv_item_inseam",
					inv_item: "inv_item",
				})
			);
		}
	};

	const onChange = (date, dateString) => {
		setEndDate(dateString.toString());
		if (dateString) {
			form.setFieldsValue({
				cancelDate: moment(dateString),
			});
		}
	};

	const onCustomerSelect = (e, valid) => {
		if (e) {
			setCustomer(e);
			dispatch(getBilling(token, e)).then((res) => {
				if (res.type === "GET_BILLING_SUCCESS") {
					res.payload?.data.find((item) => {

						if (item.default_bill_to) {
							form.setFieldsValue({
								addressOneBilling: `${item?.address_1_bill_to} ${item?.address_2_bill_to}`,
								billingCustomer: `${item?.addressee_bill_to}-${item?.zip_code_bill_to}`,
								billingEmail: item.email_bill_to === null ? "N/A" : item?.email_bill_to,
								zipBilling: item.zip_code_bill_to,
								stateBilling: item.bill_to_state_name,
								telBilling: item.phone_bill_to ? item.phone_bill_to : "N/A",
								cityBilling: item.bill_to_city_name,
								countryBilling: item.bill_to_country_name,
							});

							if (formState === "add") {
								form.setFieldsValue({
									location: 1,
									orderStatus: 1,
								});
							}
						}

						if (item.default_ship_to) {
							form.setFieldsValue({
								shippingCustomer: `${item?.address_1_bill_to} ${item?.address_2_bill_to}`,
								shippingEmail: `${item?.addressee_bill_to}-${item?.zip_code_bill_to}`,
								shippingEmail: item.email_bill_to === null ? "N/A" : item?.email_bill_to,
								zipShipping: item.zip_code_bill_to,
								stateShipping: item.bill_to_state_name,
								telShipping: item.phone_bill_to ? item.phone_bill_to : "N/A",
								cityShipping: item.bill_to_city_name,
								countryShipping: item.bill_to_country_name,
								
							});

							if (formState === "add") {
								form.setFieldsValue({
									location: 1,
									orderStatus: 1,
								});
							}
						}

					});
				}
			});
			
			
			
			
			
			
			
			
			
			
			
			
			
			
			
			
			
			
			dispatch(getOneCustomer(token, e)).then((customer) => {
				if (customer.type === "GET_ONE_CUSTOMER_SUCCESS") {
					if (valid) {
						form.setFieldsValue({
							shippingMethodId: customer.payload.shipping_method_id,
							freightTermId: customer.payload.freight_term_id,
							shippingAccountNo: customer.payload.shipping_account_no,
							shipViaId: customer.payload.courier_service_id,
							isShipComplete: customer.payload.is_ship_complete,
							berganBillingId: customer.payload.bergan_billing_id,
						});
						setIsShipComplete(customer.payload.is_ship_complete);
					}
				} else if (customer.type === "GET_ONE_CUSTOMER_ERROR") {
					notification("error", "Customer", customer?.payload?.data);
				}
			});
		}
	};

	const onBillingNameSelect = (e) => {
		dispatch(getSingleBilling(token, e)).then((res) => {

			if (res.type === "GET_CUSTOMER_SINGLE_BILLING_SUCCESS") {
				form.setFieldsValue({
					countryBilling: res.payload?.data.country_name,
					billingCustomer: res.payload?.data.customer_bill_to_id,
					cityBilling: res.payload?.data.city_name,
					billingEmail: res.payload?.dataphone_bill_to === null ? "NA" : res?.phone_bill_to,
					stateBilling: res.payload?.data.state_name,
					zipBilling: res.payload?.data.zip_code,
					addressOneBilling: `${res.payload?.data.address_1},${res.payload?.data.address_2}, ${res.payload?.data.address_3 === null ? "" : res.payload?.data.address_3} `,
				});
			}
		});
	};

	const onShippingNameSelect = (e) => {
		dispatch(getSingleShipping(token, e)).then((res) => {
			if (res.type === "GET_CUSTOMER_SINGLE_SHIPPING_SUCCESS") {
				form.setFieldsValue({
					countryShipping: res.payload?.data[0].country_name,
					shippingCustomer: res.payload?.data[0].customer_ship_to_id,
					cityShipping: res.payload?.data[0].city_name,
					shippingEmail: res.payload?.data[0].email,
					stateShipping: res.payload?.data[0].state_name,
					zipShipping: res.payload?.data[0].zip_code,
					addressOneShipping: `${res.payload?.data[0].address_1 ? res.payload?.data[0].address_1 : ""}, ${res.payload?.data[0].address_2 ? res.payload?.data[0].address_2 : ""
						}, ${res.payload?.data[0].address_3 ? res.payload?.data[0].address_3 : ""} `,
				});
			}
		});
	};

	useEffect(() => {
		if (formState === "add") {
			form.setFieldsValue({
				countryBilling: null,
				billingCustomer: null,
				cityBilling: null,
				billingEmail: null,
				stateBilling: null,
				zipBilling: null,
				countryShipping: null,
				shippingCustomer: null,
				cityShipping: null,
				shippingEmail: null,
				stateShipping: null,
				zipShipping: null,
				addressOneBilling: null,
				company: null,
				startDate: null,
				orderDate: null,
				cancelDate: null,
				endDate: null,
				category: null,
				gender1: null,
				orderStatus: null,
				customer: null,
				location: null,
				store: null,
				salesPerson: null,
				region: null,
				memo: null,
				resaleCertificateNo: null,
				externalRefID: null,
				billingAddressID: null,
				shippingMethodId: null,
				shippingCost: null,
				shippingTaxCode: null,
				shippingTaxRate: null,
				shippingAddressID: null,
				shipViaId: null,
				termForPrint: null,
				paymentTermId: null,
				freightTermId: null,
				freightAmount: null,
				freightCharges: null,
				isShipComplete: false,
				deptDescription: null,
				divisionCode: null,
				dc: null,
				po: null,
				divisionDescription: null,
				isClosed: false,
				shippingAccountNo: null,
				berganBillingId: null,
				sourceType: null,
			});
			setVisibleItems([]);
			
		}
	}, [formState]);

	function onDateSelect(val) {
		
		setEndDate(moment(val).format("MM-DD-YYYY"));
	}

	const onFinishFailed = () => {
		let errors = form.getFieldsError().filter((item) => item.errors.length > 0);
		Swal.fire({
			icon: "error",
			title: "Oops...",
			text: errors[0]?.errors[0],
		});
	};

	const summary = () => {
		let orderSummary = visibleItems;
		let amount = 0;
		let quantity = 0;
		let discount = 0;
		let tax = 0;
		let shippingCost = form.getFieldValue().shippingCost ? form.getFieldValue().shippingCost : 0;
		if (orderSummary.length > 0) {
			orderSummary?.forEach(function (item) {
				if (item.hasOwnProperty("rate")) {
					amount += parseFloat(item.rate) * parseFloat(item.quantity);
				}
				if (item.hasOwnProperty("quantity")) {
					quantity += parseFloat(item.quantity);
				}
			});
		}
		return {
			quantity: quantity,
			amount: parseFloat(amount).toFixed(2),
			discount: parseFloat(discount).toFixed(2),
			tax: parseFloat(tax).toFixed(2),
			shippingCost: parseFloat(shippingCost).toFixed(2),
			net: parseFloat(parseFloat(amount) + parseFloat(shippingCost)).toFixed(2),
		};
	};

	const showModal = () => {
		setIsModalVisible(true);
	};
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	

	
	
	
	
	
	
	
	
	
	
	
	
	

	const addedItems = (value) => {
		
		const fillArr = [...visibleItems];
		
		
		const onlyKey = [...value].map((sItem) => sItem.item_code);
		const indexofArr = fillArr.findIndex((f) => { return onlyKey.find((i) => i === f.item_code) });
		fillArr.splice(indexofArr, 1);
		if (visibleItems.length > 0) {
			let uniqueOrderItems = [...visibleItems, ...value];
			
			uniqueOrderItems = uniqueOrderItems.filter((v, i, a) => a.findIndex((t) => t.item_code === v.item_code) === i);


			
			setVisibleItems(uniqueOrderItems)


			
			
			
			
			
			
			
			
			
		} else {
			
			let uniqueOrderItems = [...visibleItems, ...value];
			uniqueOrderItems = uniqueOrderItems.filter(
				(v, i, a) => a.findIndex((t) => t.item_code === v.item_code) === i
			);

			
			setVisibleItems(uniqueOrderItems)

			
			
			
			
			
			
			
			
			
			
		}
		notification("success", "Item Added Successfully");
	};

	function debounce(cb, delay = 1000) {
		let timeout;
		return (...args) => {
			clearTimeout(timeout);
			timeout = setTimeout(() => {
				cb(...args);
			}, delay);
		};
	}

	const handleChanges = (val) => {

		setCustomer(val);
		dispatch(getSaleOrderFormData(token, companyId, menuId, val, "")).then(
			(res) => {
				if (
					res.type === "GET_GENDER_HEADER_DATA_SUCCESS" &&
					res?.payload.length === 0
				) {
					notification("warning", "Empty List");
				}
				if (res?.payload?.customerDetail?.length === 0) {
					
				}
			}
		);
	};
	const optimizedFn = useCallback(debounce(handleChanges), []);


	return (
		<>
			<EditableTable
				token={token}
				companyId={companyId}
				modalValue={isModalVisible}
				setIsModalVisible={setIsModalVisible}
				setOrderItems={addedItems}
				orderItems={orderItems}
				
				
				formState={formState}
				setVisibleItems={setVisibleItems}
				visibleItems={visibleItems}
			/>
			<Form requiredMark={false} autoComplete="off" onFinish={onFinish} form={form} onFinishFailed={onFinishFailed} name="basic">
				{salesReturnFormDataReducer.loading ||
					(loading && (
						<div className="loaderDiv">
							<Loader type="Grid" color="#212121" height={40} width={40} />
						</div>
					))}
				<div className="saleOrderForm">
					<SubTitles name={"Primary Information"} />
					<div className="primaryInfo container-fluid pbpx-0 ptpx-15">
						<Brow>
							<Bcol md={12} xl={9} xxl={10} className="pbpx-10">
								<Brow>
									<Bcol lg={6} xxl={4}>
										<div className="firstSectionRo">
											<Form.Item
												name="invoiceNo"
												label="RA #"
												labelAlign="left"
												labelCol={{
													span: 6,
												}}
											>
												<Input size="small" disabled className="customInputs without_border" />
											</Form.Item>
										</div>
									</Bcol>

									<Bcol lg={6} xxl={4}>
										<div className="firstSectionRo">
											<Form.Item
												label="Return Date"
												name="return_date"
												labelAlign="left"
												labelCol={{
													span: 6,
												}}
											>
												<DatePicker
													size="small"
													
													
													format="MM-DD-YYYY"

													
													allowClear
													className="dateInput customInputs"
													onChange={(e) => setInvoiceDate(moment(e))}
												/>
											</Form.Item>
										</div>
									</Bcol>
									<Bcol lg={6} xxl={4}>
										<div className="firstSectionRo">
											<Form.Item
												label="Order Date "
												name="orderDate"
												labelAlign="left"
												labelCol={{
													span: 6,
												}}
											>
												<DatePicker
													size="small"
													
													
													format="MM-DD-YYYY"
													
													allowClear
													className="dateInput customInputs"
													onChange={(e) => setOrderDate(moment(e))}
												/>
											</Form.Item>
										</div>
									</Bcol>

									<Bcol lg={6} xxl={4}>
										<div className="firstSectionRo">
											{/* <Form.Item
												name="customer"
												label="Customer"
												labelAlign="left"
												labelCol={{ span: 6 }}
											>
												<Input
												 size="small" tabIndex={1} className="customInputs" />
											</Form.Item> */}
											<Form.Item
												name="customer"
												label="Customer"
												labelAlign="left"
												labelCol={{ span: 5 }}
												rules={[
													{ required: true, message: "Please Select Customer" },
												]}
											>
												<Select
													notFoundContent={saleOrderFormData.loading ? <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
														<Loader type="Oval" color="#3c4b58" height={20} width={20} />
													</div> : <div style={{ display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center" }}>No Data</div>}
													size="small"
													autoComplete={"nope"}
													allowClear
													showSearch
													optionFilterProp="children"
													
													
													
													
													loading={saleOrderFormData?.loading}
													onSelect={(data) => onCustomerSelect(data, true)}
													disabled={
														!(formState === "add" || formState === "edit")
													}
													defaultValue={form.getFieldValue().customer}
													
													onSearch={(value) => {
														optimizedFn(value);
													}}
													
													ref={focusInput}
												>
													{saleOrderFormData?.payload?.customerDetail.map(
														(item) => {
															return (
																<Option
																	key={item.customer_id}
																	value={item.customer_id}
																>
																	{" "}
																	{`${item.customer_code} - ${item.customer_name}`}
																</Option>
															);
														}
													)}
												</Select>
											</Form.Item>
										</div>
									</Bcol>

									<Bcol lg={6} xxl={4}>
										<div className="firstSectionRo">
											<Form.Item
												name="reference_no"
												label="P.O #"
												labelAlign="left"
												labelCol={{
													span: 6,
												}}
											>
												<Input size="small" tabIndex={1} className="customInputs" />
											</Form.Item>
										</div>
									</Bcol>
									<Bcol lg={6} xxl={4}>
										<div className="firstSectionRo">
											<Form.Item
												name="orderNumber"
												label="Invoice #"
												labelAlign="left"
												labelCol={{
													span: 6,
												}}
											>
												<Input size="small" disabled className="customInputs" />
											</Form.Item>
										</div>
									</Bcol>
								</Brow>
							</Bcol>
							<Bcol md={4} xl={3} xxl={2}>
								<div className="firstSectionRo">
									<div className="order-summary">
										<div className="summary-title">Order Summary</div>
										<Brow>
											<Bcol className="summary-heading" col={6}>
												<p className="mb-0">Total Quantity:</p>
											</Bcol>
											<Bcol className="col-6">
												<p className="mb-0 summary-value">{summary().quantity}</p>
											</Bcol>
										</Brow>
										<Brow>
											<Bcol className="summary-heading" col={6}>
												<p className="mb-0">Total Shipping:</p>
											</Bcol>
											<Bcol className="col-6">
												<p className="mb-0 summary-value">${summary().shippingCost}</p>
											</Bcol>
										</Brow>
										<Brow>
											<Bcol className="summary-heading" col={6}>
												<p className="mb-0">Total Discount:</p>
											</Bcol>
											<Bcol className="col-6">
												<p className="mb-0 summary-value">${summary().discount}</p>
											</Bcol>
										</Brow>
										<Brow>
											<Bcol className="summary-heading" col={6}>
												<p className="mb-0">Total Amount:</p>
											</Bcol>
											<Bcol className="col-6">
												<p className="mb-0 summary-value">${summary().amount}</p>
											</Bcol>
										</Brow>
										<Brow>
											<Bcol className="summary-heading" col={6}>
												<p className="mb-0">Net:</p>
											</Bcol>
											<Bcol className="col-6">
												<p className="mb-0 summary-value">${summary().net}</p>
											</Bcol>
										</Brow>
									</div>
								</div>
							</Bcol>
						</Brow>
						<Brow className="secondSectio">
							<Bcol xl={6} xxl={6}>
								<Card size="large" className="cardTitle cardDesignNew" title="Billing Information">
									<div className="secondSectionRow">
										<Row justify="space-between">
											<Col xs={24} sm={24} md={24} lg={24}>
												<Form.Item
													name="billingCustomer"
													label="Name"
													className="name-field-form"
													labelAlign="left"
													labelCol={{
														xs: {
															span: 24,
														},
														sm: {
															span: 4,
														},
														md: {
															span: 4,
														},
														lg: {
															span: 2,
														},
													}}
												>
													<Select
														size="small"
														tabIndex={4}
														autoComplete={"nope"}
														
														showSearch={true}
														className="customInputs fw-400"
														
														loading={Billing?.loading}
														onSelect={onBillingNameSelect}
													>
														{Billing.payload?.data?.map((item) => {

															return <Option value={item.customer_bill_to_id}> {item.addressee_bill_to}{item.zip_code_bill_to}</Option>;
														})}
													</Select>
												</Form.Item>
											</Col>
											<Col xs={24} sm={24} md={24} lg={12}>
												<Form.Item
													name="billingEmail"
													label="Email"
													labelAlign="left"
													labelCol={{
														span: 4,
													}}
												>
													<Input
														disabled={true} bordered={false} readOnly className="customInputs without_bg fw-400" />
												</Form.Item>
											</Col>
											<Col xs={24} sm={24} md={24} lg={12}>
												<Form.Item
													name="addressOneBilling"
													label="Address"
													labelAlign="left"
													labelCol={{
														span: 4,
													}}
												>
													<TextArea
														autoSize={true}
														bordered={false}
														readOnly
														className="customInputs without_bg fw-400"
													
													
													/>
												</Form.Item>
											</Col>
											<Col xs={24} sm={24} md={24} lg={12}>
												<Form.Item
													name="cityBilling"
													label="City"
													labelAlign="left"
													labelCol={{
														span: 4,
													}}
												>
													<Input bordered={false} readOnly className="customInputs without_bg fw-400" />
												</Form.Item>
											</Col>
											<Col xs={24} sm={24} md={24} lg={12}>
												<Form.Item
													name="telBilling"
													label="Tel"
													labelAlign="left"
													labelCol={{
														span: 4,
													}}
												>
													<Input bordered={false} readOnly className="customInputs without_bg fw-400" />
												</Form.Item>
											</Col>
											<Col xs={24} sm={24} md={24} lg={12}>
												<Form.Item
													name="stateBilling"
													label="State"
													labelAlign="left"
													labelCol={{
														span: 4,
													}}
												>
													<Input bordered={false} readOnly className="customInputs without_bg fw-400" />
												</Form.Item>
											</Col>
											<Col xs={24} sm={24} md={24} lg={12}>
												<Form.Item
													name="zipBilling"
													label="Zip"
													labelAlign="left"
													labelCol={{
														span: 4,
													}}
												>
													<Input disabled={true} bordered={false} readOnly className="customInputs without_bg fw-400" />
												</Form.Item>
											</Col>
											<Col xs={24} sm={24} md={24} lg={12}>
												<Form.Item
													name="countryBilling"
													label="Country"
													labelAlign="left"
													labelCol={{
														span: 4,
													}}
												>
													<Input readOnly bordered={false} className="customInputs without_bg fw-400" />
												</Form.Item>
											</Col>
										</Row>
									</div>
								</Card>
							</Bcol>
							<Bcol xl={6} xxl={6}>
								<Card size="large" className="cardTitle cardDesignNew" title="Shipping Information">
									<div className="secondSectionRow">
										<Row justify="space-between">
											<Col xs={24} sm={24} md={24} lg={24}>
												<Form.Item
													name="shippingCustomer"
													label="Name"
													labelAlign="left"
													className="name-field-form"
													labelCol={{
														xs: {
															span: 24,
														},
														sm: {
															span: 4,
														},
														md: {
															span: 4,
														},
														lg: {
															span: 2,
														},
													}}
												>
													<Select
														size="small"
														tabIndex={5}
														autoComplete={"nope"}
														showSearch={true}
														className="customInputs fw-400"
														
														loading={Shipping.loading}
														onSelect={onShippingNameSelect}
														disabled={true}
													>
														{Shipping.payload?.data?.map((item) => {

															return <Option value={item.customer_ship_to_id}> {item.addressee} (${item.zip_code})</Option>;
														})}
													</Select>
												</Form.Item>
											</Col>
											<Col xs={24} sm={24} md={24} lg={12}>
												<Form.Item
													name="shippingEmail"
													label="Email"
													labelAlign="left"
													labelCol={{
														span: 4,
													}}
												>
													<Input bordered={false} readOnly className="customInputs without_bg fw-400" />
												</Form.Item>
											</Col>
											<Col xs={24} sm={24} md={24} lg={12}>
												<Form.Item
													name="addressOneShipping"
													label="Address"
													labelAlign="left"
													labelCol={{
														span: 4,
													}}
												>
													<TextArea
														disabled={true}
														autoSize={true}
														bordered={false}
														readOnly
														className="customInputs without_bg"
													
													/>
												</Form.Item>
											</Col>
											<Col xs={24} sm={24} md={24} lg={12}>
												<Form.Item
													name="cityShipping"
													label="City"
													labelAlign="left"
													labelCol={{
														span: 4,
													}}
												>
													<Input bordered={false} readOnly className="customInputs without_bg fw-400" />
												</Form.Item>
											</Col>
											<Col xs={24} sm={24} md={24} lg={12}>
												<Form.Item
													name="telShipping"
													label="Tel"
													labelAlign="left"
													labelCol={{
														span: 4,
													}}
												>
													<Input bordered={false} readOnly className="customInputs without_bg fw-400" />
												</Form.Item>
											</Col>
											<Col xs={24} sm={24} md={24} lg={12}>
												<Form.Item
													name="stateShipping"
													label="State"
													labelAlign="left"
													labelCol={{
														span: 4,
													}}
												>
													<Input bordered={false} readOnly className="customInputs without_bg fw-400" />
												</Form.Item>
											</Col>
											<Col xs={24} sm={24} md={24} lg={12}>
												<Form.Item
													name="zipShipping"
													label="Zip"
													labelAlign="left"
													labelCol={{
														span: 4,
													}}
												>
													<Input bordered={false} readOnly className="customInputs without_bg fw-400" />
												</Form.Item>
											</Col>
											<Col xs={24} sm={24} md={24} lg={12}>
												<Form.Item
													name="countryShipping"
													label="Country"
													labelAlign="left"
													labelCol={{
														span: 4,
													}}
												>
													<Input bordered={false} readOnly className="customInputs without_bg fw-400" />
												</Form.Item>
											</Col>
										</Row>
									</div>
								</Card>
							</Bcol>
						</Brow>
						<Button
							htmlType="submit"
							style={{
								display: "none",
							}}
							ref={submitButton}
							onClick={onFinish}
						/>
					</div>
					<SubTitles name={"Date"} />
					<div className="container-fluid pbpx-5 ptpx-5">
						<Brow>
							<Bcol lg={6} xxl={3}>
								<div className="firstSectionRo">
									<Form.Item
										label="Start Date"
										name="startDate"
										labelAlign="left"
										labelCol={{
											span: 6,
										}}
										rules={[
											{
												required: true,
												message: "Start Date is required",
											},
										]}
									>
										<DatePicker
											size="small"
											tabIndex={6}
											
											format="MM-DD-YYYY"
											disabled={true}
											
											allowClear
											className="dateInput customInputs"
											onChange={(e) => setStartDate(moment(e).format("MM-DD-YYYY"))}
											defaultValue={moment(sDate)}
										/>
									</Form.Item>
								</div>
							</Bcol>
							<Bcol lg={6} xxl={3}>
								<div className="firstSectionRo">
									<Form.Item
										name="endDate"
										rules={[
											{
												required: true,
												message: "End Date is required",
											},
										]}
										label="End Date"
										labelAlign="left"
										labelCol={{
											span: 6,
										}}
									>
										<DatePicker
											size="small"
											tabIndex={7}
											disabledDate={(d) => !d || d.isBefore(sDate)}
											format="MM-DD-YYYY"
											
											allowClear
											className="dateInput customInputs"
											disabled={true}
											onChange={(e, s) => onChange(e, s)}
											onSelect={(e) => onDateSelect(e)}
										/>
									</Form.Item>
								</div>
							</Bcol>
							<Bcol lg={6} xxl={3}>
								<div className="firstSectionRo">
									<Form.Item
										name="cancelDate"
										label="Cancel Date"
										labelAlign="left"
										labelCol={{
											span: 6,
										}}
										rules={[
											{
												required: true,
												message: "Cancel Date is required",
											},
										]}
									>
										<DatePicker
											size="small"
											tabIndex={8}
											disabledDate={(d) => !d || d.isBefore(eDate)}
											format="MM-DD-YYYY"
											
											allowClear
											className="dateInput customInputs"
											disabled={true}
										/>
									</Form.Item>
								</div>
							</Bcol>
						</Brow>
					</div>

					<SubTitles name={"Sales Information"} />
					<div className="container-fluid pbpx-5 ptpx-5">
						<Brow>
							<Bcol lg={6} xxl={3}>
								<div className="firstSectionRo">
									<Form.Item
										name="gender"
										label="Gender"
										labelAlign="left"
										labelCol={{
											span: 6,
										}}
										rules={[
											{
												required: true,
												message: "Gender is required",
											},
										]}
									>
										<Input size="small" tabIndex={11} readOnly className="customInputs" />
									</Form.Item>
								</div>
							</Bcol>
							<Bcol lg={6} xxl={3}>
								<div className="firstSectionRo">
									<Form.Item
										name="region"
										label="Region"
										labelAlign="left"
										labelCol={{
											span: 6,
										}}
										rules={[
											{
												required: true,
												message: "Region is required",
											},
										]}
									>
										<Input size="small" tabIndex={11} readOnly className="customInputs" />
									</Form.Item>
								</div>
							</Bcol>
							<Bcol lg={6} xxl={3}>
								<div className="firstSectionRo">
									<Form.Item
										name="category"
										label="Category"
										labelAlign="left"
										labelCol={{
											span: 6,
										}}
									>
										<Input size="small" tabIndex={11} readOnly className="customInputs" />
									</Form.Item>
								</div>
							</Bcol>
							<Bcol lg={6} xxl={3}>
								<div className="firstSectionRo">
									<Form.Item
										name="location"
										label="Location"
										labelAlign="left"
										labelCol={{
											span: 6,
										}}
										rules={[
											{
												required: true,
												message: "Location is required",
											},
										]}
									>
										<Input size="small" tabIndex={11} readOnly className="customInputs" />
									</Form.Item>
								</div>
							</Bcol>
							<Bcol lg={6} xxl={3}>
								<div className="firstSectionRo">
									<Form.Item
										name="salesPerson"
										label="Sales Person"
										labelAlign="left"
										labelCol={{
											span: 6,
										}}
										rules={[
											{
												required: true,
												message: "Sales Person is required",
											},
										]}
									>
										<Input size="small" tabIndex={11} readOnly className="customInputs" />
									</Form.Item>
								</div>
							</Bcol>
						</Brow>
					</div>
					<Collapse
						bordered={false}
						defaultActiveKey={1}
						className="pbpx-10 bg-white"
						expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
					>
						<Panel header="Classification" key="1">
							<Tabs type="card" size="small" defaultActiveKey="shipping" centered onChange={(key) => handleTab(key)}>
								<TabPane tab="Billing" key="shipping">
									<div className="container-fluid pbpx-0 ptpx-0">
										<Brow>
											<Bcol lg={6} xl={4} xxl={3}>
												<div className="firstSectionRo">
													<Form.Item
														name="shippingAccountNo"
														label="Shippnig Account No"
														labelAlign="left"
														labelCol={{
															span: 10,
														}}
													
													
													
													
													
													
													>
														<Input
															size="small"
															tabIndex={14}
															
															className="customInputs"
															disabled={true}
														/>
													</Form.Item>
												</div>
											</Bcol>
											<Bcol lg={6} xl={4} xxl={3}>
												<div className="firstSectionRo">
													<Form.Item
														name="freightTermId"
														label="Freight Term"
														labelAlign="left"
														labelCol={{
															span: 10,
														}}
														rules={[
															{
																required: true,
																message: "Please Select Freight Term",
															},
														]}
													>
														<Select
															size="small"
															tabIndex={20}
															autoComplete={"nope"}
															allowClear
															loading={salesReturnFormDataReducer.loading}
															defaultValue={form.getFieldValue().freightTermId}
															
															onChange={(value) => handleChange(value)}
															disabled={true}
														>
															{salesReturnFormDataReducer?.payload?.freightTerm.map((item) => {
																return (
																	<Option key={item.freight_term_id} disabled={!item.is_active} value={item.freight_term_id}>
																		{item.freight_term_name}
																	</Option>
																);
															})}
														</Select>
													</Form.Item>
												</div>
											</Bcol>

											<Bcol lg={6} xl={4} xxl={3}>
												<div className="firstSectionRo">
													<Form.Item
														name="paymentTermId"
														label="Payment Term"
														labelAlign="left"
														labelCol={{
															span: 10,
														}}
													>
														<Select
															size="small"
															tabIndex={24}
															autoComplete={"nope"}
															allowClear
															loading={salesReturnFormDataReducer.loading}
															disabled={true}
															defaultValue={form.getFieldValue().paymentTermId}
															
															onChange={(value) => handleChange(value, "paymentTermId")}
														>
															{salesReturnFormDataReducer?.payload?.paymentTerm.map((item) => {
																return (
																	<Option key={item.term_id} disabled={!item.is_active} value={item.term_id}>
																		{item.term_name}
																	</Option>
																);
															})}
														</Select>
													</Form.Item>
												</div>
											</Bcol>
										</Brow>
									</div>
								</TabPane>
							</Tabs>
						</Panel>
					</Collapse>
					<Tabs type="card" size="small" defaultActiveKey="1" centered onChange={(key) => handleTab(key)}>

						<TabPane tab="Product Information" key="1">
							<div className="pbpx-20">
								<Button
									type="primary"
									className="modalButton"
									disabled={!(formState === "edit" || formState === "add")}
									onClick={showModal}
								>
									Add Item
								</Button>
							</div>
							{visibleItems.length > 0 && (
								<Table
									type={"company"}
									
									scroll={({ x: 600, }, { y: 400 })}
									size="small"
									bordered
									dataSource={visibleItems}
									columns={columns}
									pagination={false}
								/>
							)}
						</TabPane>
						<TabPane tab="GL Impact" key="gl_impact">
							<div className="tabs">
								<Tables
									type="company"
									scrollX={"50rem"}
									scroll={360}
									perPage={15}
									source={glImpact?.payload?.data}
									col={glImpcatCols}
									load={glImpact.loading}
									paginate
								/>
							</div>
						</TabPane>

						<TabPane tab="System Information" key="logs">
							<div className="tabs">
								<Tables
									type="company"
									scrollX={"50rem"}
									scroll={360}
									perPage={15}
									source={log?.payload?.data}
									col={logColumns}
									load={log.loading}
									paginate
								/>
							</div>
						</TabPane>
					</Tabs>
				</div>
			</Form>
		</>
	);
}
