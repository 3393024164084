
import {
    GET_SEND_STITCH_FIX_EDI_REQUEST,
    GET_SEND_STITCH_FIX_EDI_SUCCESS,
    GET_SEND_STITCH_FIX_EDI_ERROR
  } from "../actions/types";
  
  export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
      case GET_SEND_STITCH_FIX_EDI_REQUEST:
        return {
          loading: true,
        };
      case GET_SEND_STITCH_FIX_EDI_SUCCESS:
        return {
          ...state,
          loading: false,
          payload,
        };
      case GET_SEND_STITCH_FIX_EDI_ERROR:
        return {
          ...state,
          loading: false,
          payload,
        };
  
      default: {
        return state;
      }
    }
  };