import { GET_INVOICE_REPORT_ERROR, GET_INVOICE_REPORT_REQUEST, GET_INVOICE_REPORT_SUCCESS } from "../actions/types";
export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case GET_INVOICE_REPORT_REQUEST:
            return { loading: true }
        case GET_INVOICE_REPORT_SUCCESS:
            return { ...state, loading: false, payload: payload };
        case GET_INVOICE_REPORT_ERROR:
            return { ...state, loading: false, payload };
        default:
            return state;
    }
}
