import { GET_PRODUCTSALE_REQUEST, GET_PRODUCTSALE_SUCCESS, GET_PRODUCTSALE_ERROR } from "../actions/types";
export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case GET_PRODUCTSALE_REQUEST:
            return {
                loading: true,
            }
        case GET_PRODUCTSALE_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            }
        case GET_PRODUCTSALE_ERROR:
            return {
                ...state,
                loading: false,
            }
        default:
            return state
    }
}