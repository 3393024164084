import {
    GET_ALL_QUERY_ERROR,
    GET_ALL_QUERY_REQUEST,
    GET_ALL_QUERY_SUCCESS,
} from "../actions/types";

export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {

        case GET_ALL_QUERY_REQUEST:
            return {
                loading: true,
            };
        case GET_ALL_QUERY_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case GET_ALL_QUERY_ERROR:
            return {
                ...state,
                loading: false
            };
        default:
            return state;
    }
}