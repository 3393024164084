import {
	ADD_INVENTORYADJUSTMENT_HEADER_ERROR,
	ADD_INVENTORYADJUSTMENT_HEADER_REQUEST,
	ADD_INVENTORYADJUSTMENT_HEADER_SUCCESS,
	
} from "../actions/types";


export default function (state = [], action) {
	const { type, payload } = action;
	switch (type) {
		
		case ADD_INVENTORYADJUSTMENT_HEADER_REQUEST:
			return {
				loading: true,
			};
		case ADD_INVENTORYADJUSTMENT_HEADER_SUCCESS:
			return {
				...state,
				loading: false,
				payloadSuccess: payload,
			};
		case ADD_INVENTORYADJUSTMENT_HEADER_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};
		default:
			return state;
	}
}
