
import {
  GET_SEARCH_PRODUCT_REQUEST,
  GET_SEARCH_PRODUCT_SUCCESS,
  GET_SEARCH_PRODUCT_ERROR
} from "../actions/types";

export default function (state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case GET_SEARCH_PRODUCT_REQUEST:
      return {
        loading: true,
      };
    case GET_SEARCH_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        payload,
      };
    case GET_SEARCH_PRODUCT_ERROR:
      return {
        ...state,
        loading: false,
        payload,
      };

    default: {
      return state;
    }
  }
};