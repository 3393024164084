import {
  GET_ALL_SALEPERSON_ACTIVE_REQUEST,
  GET_ALL_SALEPERSON_ACTIVE_SUCCESS,
  GET_ALL_SALEPERSON_ACTIVE_ERROR,
  GET_ALL_CUSTOMER_TYPE_ACTIVE_REQUEST,
  GET_ALL_CUSTOMER_TYPE_ACTIVE_SUCCESS,
  GET_ALL_CUSTOMER_TYPE_ACTIVE_ERROR,
  GET_ALL_REGION_ACTIVE_REQUEST,
  GET_ALL_REGION_ACTIVE_SUCCESS,
  GET_ALL_REGION_ACTIVE_ERROR
} from "./types.js";
import axios from "axios";
import { BASEURL } from "../../constant/config.js";
import moment from "moment";

export const getAllActiveSaleperson = (token, id) => dispatch => {
  dispatch({ type: GET_ALL_SALEPERSON_ACTIVE_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}getAll/salePersonActive`,
    headers: {
      Accept: "application/json",
      'Authorization': 'Bearer ' + token
    }
  })
    .then(response => {
      return dispatch({ type: GET_ALL_SALEPERSON_ACTIVE_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      return dispatch({
        type: GET_ALL_SALEPERSON_ACTIVE_ERROR,
        payload: error.response,
      });
    })
}

export const getAllActiveCustomerType = (token, id) => dispatch => {
  dispatch({ type: GET_ALL_CUSTOMER_TYPE_ACTIVE_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}getAll/customerTypeActive`,
    headers: {
      Accept: "application/json",
      'Authorization': 'Bearer ' + token
    }
  })
    .then(response => {
      return dispatch({ type: GET_ALL_CUSTOMER_TYPE_ACTIVE_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      return dispatch({
        type: GET_ALL_CUSTOMER_TYPE_ACTIVE_ERROR,
        payload: error.response,
      });
    })
}

export const getAllActiveRegion = (token, id) => dispatch => {
  dispatch({ type: GET_ALL_REGION_ACTIVE_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}getAll/regionActive`,
    headers: {
      Accept: "application/json",
      'Authorization': 'Bearer ' + token
    }
  })
    .then(response => {
      return dispatch({ type: GET_ALL_REGION_ACTIVE_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      return dispatch({
        type: GET_ALL_REGION_ACTIVE_ERROR,
        payload: error.response,
      });
    })
}


