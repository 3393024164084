import {
  ADD_TRANSACTION_LOCK_REQUEST,
  ADD_TRANSACTION_LOCK_SUCCESS,
  ADD_TRANSACTION_LOCK_ERROR,
  GET_ALL_TRANSACTION_LOCK_REQUEST,
  GET_ALL_TRANSACTION_LOCK_SUCCESS,
  GET_ALL_TRANSACTION_LOCK_ERROR,
  UPDATE_TRANSACTION_LOCK_REQUEST,
  UPDATE_TRANSACTION_LOCK_SUCCESS,
  UPDATE_TRANSACTION_LOCK_ERROR,
  DELETE_TRANSACTION_LOCK_REQUEST,
  DELETE_TRANSACTION_LOCK_SUCCESS,
  DELETE_TRANSACTION_LOCK_ERROR,
  TRANSACTION_LOCK_LOG_REQUEST,
  TRANSACTION_LOCK_LOG_SUCCESS,
  TRANSACTION_LOCK_LOG_ERROR
} from "../actions/types";

export const transactionLock = (state = [], action) => {
  const { type, payload } = action;
  switch (type) {
    case ADD_TRANSACTION_LOCK_REQUEST:
      return {
        loading: true,
      };
    case ADD_TRANSACTION_LOCK_SUCCESS:
      return {
        ...state,
        loading: false,
        payload,
      };
    case ADD_TRANSACTION_LOCK_ERROR:
      return {
        ...state,
        loading: false,
        payload,
      };
    case GET_ALL_TRANSACTION_LOCK_REQUEST:
      return {
        loading: true,
      };
    case GET_ALL_TRANSACTION_LOCK_SUCCESS:
      return {
        ...state,
        loading: false,
        payload,
      };
    case GET_ALL_TRANSACTION_LOCK_ERROR:
      return {
        ...state,
        loading: false,
        payload,
      };
    case UPDATE_TRANSACTION_LOCK_REQUEST:
      return {
        loading: true,
      };
    case UPDATE_TRANSACTION_LOCK_SUCCESS:
      return {
        ...state,
        loading: false,
        payload,
      };
    case UPDATE_TRANSACTION_LOCK_ERROR:
      return {
        ...state,
        loading: false,
        payload,
      };
    case DELETE_TRANSACTION_LOCK_REQUEST:
      return {
        loading: true,
      };
    case DELETE_TRANSACTION_LOCK_SUCCESS:
      return {
        ...state,
        loading: false,
        payload,
      };
    case DELETE_TRANSACTION_LOCK_ERROR:
      return {
        ...state,
        loading: false,
        payload,
      };
    case TRANSACTION_LOCK_LOG_REQUEST:
      return {
        loading: true,
      };
    case TRANSACTION_LOCK_LOG_SUCCESS:
      return {
        ...state,
        loading: false,
        payload,
      };
    case TRANSACTION_LOCK_LOG_ERROR:
      return {
        ...state,
        loading: false,
        payload,
      };

    default: {
      return state;
    }
  }
};
