import {
    GET_RISE_ERROR,
    GET_RISE_REQUEST,
    GET_RISE_SUCCESS,
    ADD_RISE_ERROR,
    ADD_RISE_REQUEST,
    ADD_RISE_SUCCESS,
    DELETE_RISE_REQUEST,
    DELETE_RISE_SUCCESS,
    DELETE_RISE_ERROR,
    UPDATE_RISE_ERROR,
    UPDATE_RISE_REQUEST,
    UPDATE_RISE_SUCCESS,
} from "./types";

import { BASEURL } from "../../constant/config.js";

const axios = require("axios");

export const getRise = (token, menu_id) => (dispatch) => {
    dispatch({ type: GET_RISE_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/rise`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { menu_id }
    })
        .then((response) => {
            return dispatch({ type: GET_RISE_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: GET_RISE_ERROR,
                payload: error.response,
            });
        });
};

export const addRise = (token, data, menu_id) => (dispatch) => {
    dispatch({ type: ADD_RISE_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}create/rise`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { rise_name: data.riseName, is_active: data.check, menu_id },
    })
        .then((response) => {
            return dispatch({ type: ADD_RISE_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: ADD_RISE_ERROR,
                payload: error.response,
            });
        });
};

export const updateRise = (token, data, id) => (dispatch) => {
    dispatch({ type: UPDATE_RISE_REQUEST });
    return axios({
        method: "PUT",
        url: `${BASEURL}update/rise/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { rise_name: data.riseName, is_active: data.check },
    })
        .then((response) => {
            return dispatch({ type: UPDATE_RISE_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: UPDATE_RISE_ERROR,
                payload: error.response,
            });
        });
};

export const deleteRise = (token, id) => (dispatch) => {
    dispatch({ type: DELETE_RISE_REQUEST });
    return axios({
        method: "DELETE",
        url: `${BASEURL}delete/rise/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
    })
        .then((response) => {
            return dispatch({ type: DELETE_RISE_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: DELETE_RISE_ERROR,
                payload: error.response,
            });
        });
};
