import {
    ADD_JOURNAL_VOUCHER_LINE_REQUEST, ADD_JOURNAL_VOUCHER_LINE_SUCCESS, ADD_JOURNAL_VOUCHER_LINE_ERROR,
    GET_ONE_JOURNAL_VOUCHER_LINE_ERROR, GET_ONE_JOURNAL_VOUCHER_LINE_SUCCESS, GET_ONE_JOURNAL_VOUCHER_LINE_REQUEST,
    GET_JOURNAL_VOUCHER_DETAIL_REQUEST, GET_JOURNAL_VOUCHER_DETAIL_SUCCESS, GET_JOURNAL_VOUCHER_DETAIL_ERROR,
    UPDATE_JOURNAL_VOUCHER_LINE_REQUEST, UPDATE_JOURNAL_VOUCHER_LINE_SUCCESS, UPDATE_JOURNAL_VOUCHER_LINE_ERROR,
    DELETE_JOURNAL_VOUCHER_LINE_REQUEST, DELETE_JOURNAL_VOUCHER_LINE_SUCCESS, DELETE_JOURNAL_VOUCHER_LINE_ERROR,
} from "./types";
import { BASEURL } from "../../constant/config.js";
const axios = require("axios");

export const addJournalVoucherLine = (token, rest, journalVoucherId, id) => (dispatch) => {
    let data = rest;


    data.forEach((val) => val.voucher_header_id = journalVoucherId);
    data = data.map((item) => {
        if (item.customer_id === '') {
            item.customer_id = null
        }
        if (item.debit === '') {
            item.debit = 0
        }
        if (item.credit === '') {
            item.credit = 0
        }
        return item;
    })
    dispatch({ type: ADD_JOURNAL_VOUCHER_LINE_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}create/glvoucherline`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { data }
    })
        .then((response) => {
            return dispatch({
                type: ADD_JOURNAL_VOUCHER_LINE_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) => {
            return dispatch({
                type: ADD_JOURNAL_VOUCHER_LINE_ERROR,
                payload: error.response,
            });
        });
};
export const addJournalVoucherLines = (token, data) => (dispatch) => {
    data = data.map((item) => {
        if (item.customer_id === '') {
            item.customer_id = null
        }

        if (item.debit === '') {
            item.debit = 0
        }
        return item;
    }

    )

    dispatch({ type: ADD_JOURNAL_VOUCHER_LINE_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}create/glvoucherline`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { data }
    })
        .then((response) => {
            return dispatch({
                type: ADD_JOURNAL_VOUCHER_LINE_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) => {
            return dispatch({
                type: ADD_JOURNAL_VOUCHER_LINE_ERROR,
                payload: error.response,
            });
        });
};

export const getOneJournalVoucherLine = (token, headerId) => (dispatch) => {

    dispatch({ type: GET_ONE_JOURNAL_VOUCHER_LINE_REQUEST });
    return axios({
        method: "GET",
        url: `${BASEURL}getone/glvoucherline/${headerId}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
    })
        .then((response) => {
            return dispatch({
                type: GET_ONE_JOURNAL_VOUCHER_LINE_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) => {
            return dispatch({
                type: GET_ONE_JOURNAL_VOUCHER_LINE_ERROR,
                payload: error.response,
            });
        });
};

export const getJournalVoucherDetails = (token, customerID) => (dispatch) => {
    dispatch({ type: GET_JOURNAL_VOUCHER_DETAIL_REQUEST });
    let newData = {
        customer_id: customerID,
    };
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/glvoucherline`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: newData,
    })
        .then((response) => {
            return dispatch({
                type: GET_JOURNAL_VOUCHER_DETAIL_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) => {
            return dispatch({
                type: GET_JOURNAL_VOUCHER_DETAIL_ERROR,
                payload: error.response,
            });
        });
};


export const updateJournalVoucherLine = (token, rest, journalVoucherId) => (dispatch) => {

    let data = rest
    // 
    data.map((item) => {

        if (item.debit === '') {
            item.debit = 0
        }
        if (item.credit === '') {
            item.credit = 0
        }
        return {
            voucher_line_id: item.voucher_line_id,
            debit: item.debit,
            credit: item.credit
        }
    });
    dispatch({ type: UPDATE_JOURNAL_VOUCHER_LINE_REQUEST });
    return axios({
        method: "PUT",
        url: `${BASEURL}update/glvoucherline/${journalVoucherId}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { data },

    })
        .then((response) => {
            return dispatch({
                type: UPDATE_JOURNAL_VOUCHER_LINE_SUCCESS,
                payload: response?.data,
            });
        })
        .catch((error) => {
            return dispatch({
                type: UPDATE_JOURNAL_VOUCHER_LINE_ERROR,
                payload: error.response,
            });
        });
};


export const deleteJournalVoucherLine = (token, Lineidd,deletedItems) => (dispatch) => {

    dispatch({ type: DELETE_JOURNAL_VOUCHER_LINE_REQUEST });
    return axios({
        method: "DELETE",
        url: `${BASEURL}delete/glvoucherline/${Lineidd}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data:deletedItems
    })
        .then((response) => {
            return dispatch({
                type: DELETE_JOURNAL_VOUCHER_LINE_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) => {
            return dispatch({
                type: DELETE_JOURNAL_VOUCHER_LINE_ERROR,
                payload: error.response,
            });
        });
};