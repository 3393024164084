
import {
    GET_INTERLOG_ERROR,
    GET_INTERLOG_REQUEST,
    GET_INTERLOG_SUCCESS
} from "../actions/types";


export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case GET_INTERLOG_REQUEST:
            return {
                loading: true,
            };
        case GET_INTERLOG_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_INTERLOG_ERROR:
            return {
                ...state,
                loading: false
            };

        default:
            return state;
    }
}