import {
  ADD_SALEORDER_HEADER_SUCCESS,
  ADD_SALEORDER_HEADER_ERROR,
  ADD_SALEORDER_HEADER_REQUEST,
  GET_ALL_ORDER_SHIPMENT_REQUEST,
  GET_ALL_ORDER_SHIPMENT_SUCCESS,
  GET_ALL_ORDER_SHIPMENT_ERROR,
  DELETE_SALEORDERHEADER_REQUEST,
  DELETE_SALEORDERHEADER_SUCCESS,
  DELETE_SALEORDERHEADER_ERROR,
  UPDATE_SALEORDER_HEADER_REQUEST,
  UPDATE_SALEORDER_HEADER_ERROR,
  UPDATE_SALEORDER_HEADER_SUCCESS,
  GET_ONE_ORDER_SHIPMENT_SUCCESS,
  GET_ONE_ORDER_SHIPMENT_REQUEST,
  GET_ONE_ORDER_SHIPMENT_ERROR,
  GET_ONE_ORDER_SHIPMENT_DETAIL_REQUEST,
  GET_ONE_ORDER_SHIPMENT_DETAIL_SUCCESS,
  GET_ONE_ORDER_SHIPMENT_DETAIL_ERROR,
  MARK_SHIP_REQUEST,
  MARK_SHIP_SUCCESS,
  MARK_SHIP_ERROR
} from "../actions/types";

export default function (state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case ADD_SALEORDER_HEADER_REQUEST:
      return {
        loading: true,
      };
    case ADD_SALEORDER_HEADER_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: [payload],
      };
    case ADD_SALEORDER_HEADER_ERROR:
      return {
        ...state,
        loading: false,
        payload,
      };
    case GET_ALL_ORDER_SHIPMENT_REQUEST:
      return {
        loading: true,
      };
    case GET_ALL_ORDER_SHIPMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: [payload],
      };
    case GET_ALL_ORDER_SHIPMENT_ERROR:
      return {
        ...state,
        loading: false,
        payload,
      };
    case UPDATE_SALEORDER_HEADER_REQUEST:
      return {
        loading: true,
      };
    case UPDATE_SALEORDER_HEADER_SUCCESS:
      return {
        ...state,
        loading: false,
        payloadSuccess: payload,
      };
    case UPDATE_SALEORDER_HEADER_ERROR:
      return {
        ...state,
        loading: false,
        errorPayload: payload,
      };
    case DELETE_SALEORDERHEADER_REQUEST:
      return {
        loading: true,
      };
    case DELETE_SALEORDERHEADER_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: [payload],
      };
    case DELETE_SALEORDERHEADER_ERROR:
      return {
        ...state,
        loading: false,
        payload,
      };
    case GET_ONE_ORDER_SHIPMENT_REQUEST:
      return {
        loading: true,
      };
    case GET_ONE_ORDER_SHIPMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: payload,
      };
    case GET_ONE_ORDER_SHIPMENT_ERROR:
      return {
        ...state,
        loading: false,
        payload,
      };

    case MARK_SHIP_REQUEST:
      return {
        loading: true,
      };
    case MARK_SHIP_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: payload,
      };
    case MARK_SHIP_ERROR:
      return {
        ...state,
        loading: false,
        payload,
      };

    case GET_ONE_ORDER_SHIPMENT_DETAIL_REQUEST:
      return {
        loading: true,
      };
    case GET_ONE_ORDER_SHIPMENT_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: payload.data[0],
      };
    case GET_ONE_ORDER_SHIPMENT_DETAIL_ERROR:
      return {
        ...state,
        loading: false,
        payload,
      };

    default:
      return state;
  }
}
