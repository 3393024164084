import {
  GET_SEND_PACKING_LIST_REQUEST,
  GET_SEND_PACKING_LIST_SUCCESS,
  GET_SEND_PACKING_LIST_ERROR
} from "./types";
import axios from "axios";
import { BASEURL } from "../../constant/config";
import moment from "moment";

export const sendPackingList = (token, values) => (dispatch) => {
  dispatch({ type: GET_SEND_PACKING_LIST_REQUEST });

  return axios({
    method: "POST",
    url: `${BASEURL}create/SendPackingList`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: values
  })
    .then((response) => {
      return dispatch({
        type: GET_SEND_PACKING_LIST_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_SEND_PACKING_LIST_ERROR,
        payload: [],
      });
    });
};
