import {
    GET_STYLES_ERROR, GET_STYLES_REQUEST, GET_STYLES_SUCCESS,
    ADD_STYLES_ERROR, ADD_STYLES_REQUEST, ADD_STYLES_SUCCESS,
    DELETE_STYLES_REQUEST, DELETE_STYLES_SUCCESS, DELETE_STYLES_ERROR,
    UPDATE_STYLES_ERROR, UPDATE_STYLES_REQUEST, UPDATE_STYLES_SUCCESS

} from "../actions/types"
export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case GET_STYLES_REQUEST:
            return {
                loading: true,
            };
        case GET_STYLES_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_STYLES_ERROR:
            return {
                ...state,
                loading: false
            };
        case ADD_STYLES_REQUEST:
            return {
                loading: true,
            };
        case ADD_STYLES_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case ADD_STYLES_ERROR:
            return {
                ...state,
                loading: false
            };
        case DELETE_STYLES_REQUEST:
            return {
                loading: true,
            };
        case DELETE_STYLES_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case DELETE_STYLES_ERROR:
            return {
                ...state,
                loading: false
            };
        case UPDATE_STYLES_REQUEST:
            return {
                loading: true,
            };
        case UPDATE_STYLES_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case UPDATE_STYLES_ERROR:
            return {
                ...state,
                loading: false
            };

        default:
            return state;
    }
}