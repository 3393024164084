import { GET_OMS_AVAILABLE_BALANCE_REQUEST, GET_OMS_AVAILABLE_BALANCE_SUCCESS, GET_OMS_AVAILABLE_BALANCE_ERROR } from "./types";
import { BASEURL } from "../../constant/config.js"
import axios from "axios";
import moment from "moment";


export const getOmsAvailableBalance = (token, location_id, item_id) => (dispatch) => {
    dispatch({ type: GET_OMS_AVAILABLE_BALANCE_REQUEST });
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    

    return axios({
        method: "POST",
        url: `${BASEURL}getAll/OmsAvailBalance`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: {
            location_id,
            item_id
        },
    })
        .then((response) => {
            return dispatch({
                type: GET_OMS_AVAILABLE_BALANCE_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) => {
            dispatch({
                type: GET_OMS_AVAILABLE_BALANCE_ERROR,
                payload: [],
            });

        });
};
