import { GET_COURIER_SERVICE_ERROR,GET_COURIER_SERVICE_SUCCESS,GET_COURIER_SERVICE_REQUEST,
    ADD_COURIER_SERVICE_ERROR,ADD_COURIER_SERVICE_REQUEST,ADD_COURIER_SERVICE_SUCCESS,
    UPDATE_COURIER_SERVICE_ERROR,UPDATE_COURIER_SERVICE_REQUEST,UPDATE_COURIER_SERVICE_SUCCESS,
    DELETE_COURIER_SERVICE_ERROR,DELETE_COURIER_SERVICE_REQUEST,DELETE_COURIER_SERVICE_SUCCESS
} from "../actions/types";




export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case GET_COURIER_SERVICE_REQUEST:
            return {
                loading: true,
            };
        case GET_COURIER_SERVICE_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_COURIER_SERVICE_ERROR:
            return {
                ...state,
                loading: false
            };
        case ADD_COURIER_SERVICE_REQUEST:
            return {
                loading: true,
            };
        case ADD_COURIER_SERVICE_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case ADD_COURIER_SERVICE_ERROR:
            return {
                ...state,
                loading: false
            };
        case DELETE_COURIER_SERVICE_REQUEST:
            return {
                loading: true,
            };
        case DELETE_COURIER_SERVICE_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case DELETE_COURIER_SERVICE_ERROR:
            return {
                ...state,
                loading: false
            };
        case UPDATE_COURIER_SERVICE_REQUEST:
            return {
                loading: true,
            };
        case UPDATE_COURIER_SERVICE_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case UPDATE_COURIER_SERVICE_ERROR:
            return {
                ...state,
                loading: false
            };

        default:
            return state;
    }
}
