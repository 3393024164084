import {
    GET_WASHTYPE_REQUEST,
    GET_WASHTYPE_SUCCESS,
    GET_WASHTYPE_ERROR,
    ADD_WASHTYPE_REQUEST,
    ADD_WASHTYPE_SUCCESS,
    ADD_WASHTYPE_ERROR,
    UPDATE_WASHTYPE_REQUEST,
    UPDATE_WASHTYPE_SUCCESS,
    UPDATE_WASHTYPE_ERROR,
    DELETE_WASHTYPE_REQUEST,
    DELETE_WASHTYPE_SUCCESS,
    DELETE_WASHTYPE_ERROR,
} from "./types";
import { BASEURL } from "../../constant/config.js";

const axios = require("axios");

export const getWashType = (token, menu_id) => (dispatch) => {
    dispatch({ type: GET_WASHTYPE_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/wash_type`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { menu_id }
    })
        .then((response) => {
            return dispatch({ type: GET_WASHTYPE_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: GET_WASHTYPE_ERROR,
                payload: error.response,
            });
        });
};

export const addWashType = (token, data, menu_id) => (dispatch) => {
    dispatch({ type: ADD_WASHTYPE_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}create/wash_type`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { wash_type_name: data.washName, is_active: data.check, menu_id },
    })
        .then((response) => {

            return dispatch({ type: ADD_WASHTYPE_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: ADD_WASHTYPE_ERROR,
                payload: error.response,
            });
        });
};

export const updateWashType = (token, data, id) => (dispatch) => {
    dispatch({ type: UPDATE_WASHTYPE_REQUEST });
    return axios({
        method: "PUT",
        url: `${BASEURL}update/wash_type/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { wash_type_name: data.washName, is_active: data.check },
    })
        .then((response) => {

            return dispatch({ type: UPDATE_WASHTYPE_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: UPDATE_WASHTYPE_ERROR,
                payload: error.response,
            });
        });
};

export const deleteWashType = (token, id) => (dispatch) => {
    dispatch({ type: DELETE_WASHTYPE_REQUEST });
    return axios({
        method: "DELETE",
        url: `${BASEURL}delete/wash_type/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
    })
        .then((response) => {

            return dispatch({ type: DELETE_WASHTYPE_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: DELETE_WASHTYPE_ERROR,
                payload: error.response,
            });
        });
};
