import {
    GET_ORDERSTATUS_REQUEST, GET_ORDERSTATUS_SUCCESS, GET_ORDERSTATUS_ERROR,
    ADD_ORDERSTATUS_REQUEST, ADD_ORDERSTATUS_SUCCESS, ADD_ORDERSTATUS_ERROR,
    DELETE_ORDERSTATUS_REQUEST, DELETE_ORDERSTATUS_SUCCESS, DELETE_ORDERSTATUS_ERROR,
    UPDATE_ORDERSTATUS_REQUEST, UPDATE_ORDERSTATUS_ERROR, UPDATE_ORDERSTATUS_SUCCESS,

} from "../actions/types"
export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case GET_ORDERSTATUS_REQUEST:
            return {
                loading: true,
            };
        case GET_ORDERSTATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_ORDERSTATUS_ERROR:
            return {
                ...state,
                loading: false
            };
        case ADD_ORDERSTATUS_REQUEST:
            return {
                loading: true,
            };
        case ADD_ORDERSTATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case ADD_ORDERSTATUS_ERROR:
            return {
                ...state,
                loading: false
            };
        case DELETE_ORDERSTATUS_REQUEST:
            return {
                loading: true,
            };
        case DELETE_ORDERSTATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case DELETE_ORDERSTATUS_ERROR:
            return {
                ...state,
                loading: false
            };
        case UPDATE_ORDERSTATUS_REQUEST:
            return {
                loading: true,
            };
        case UPDATE_ORDERSTATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case UPDATE_ORDERSTATUS_ERROR:
            return {
                ...state,
                loading: false
            };

        default:
            return state;
    }
}