import {
  GET_ALL_CUSTOMERS_ERROR, GET_ALL_CUSTOMERS_SUCCESS, GET_ALL_CUSTOMERS_REQUEST, ADD_CUSTOMER_REQUEST, ADD_CUSTOMER_ERROR, ADD_CUSTOMER_SUCCESS,
  DELETE_CUSTOMER_REQUEST, DELETE_CUSTOMER_SUCCESS, DELETE_CUSTOMER_ERROR, UPDATE_CUSTOMER_REQUEST, UPDATE_CUSTOMER_SUCCESS, UPDATE_CUSTOMER_ERROR,
} from "../actions/types";

export default function (state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case GET_ALL_CUSTOMERS_REQUEST:
      return {
        loading: true,
      };
    case GET_ALL_CUSTOMERS_SUCCESS:
      return {
        ...state,
        loading: false,
        payload,
      };
    case GET_ALL_CUSTOMERS_ERROR:
      return {
        ...state,
        loading: false,
        payload,
      };
    case UPDATE_CUSTOMER_REQUEST:
      return {
        loading: true,
      };
    case UPDATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: [payload],
      };
    case UPDATE_CUSTOMER_ERROR:
      return {
        ...state,
        loading: false,
      };

    case ADD_CUSTOMER_REQUEST:
      return {
        loading: true,
      };
    case ADD_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: [payload],
      };
    case ADD_CUSTOMER_ERROR:
      return {
        ...state,
        loading: false,
      };

    case DELETE_CUSTOMER_REQUEST:
      return {
        loading: true,
      };
    case DELETE_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: [payload],
      };
    case DELETE_CUSTOMER_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
