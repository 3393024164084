import {
    GET_UPC_REPORT_REQUEST,
    GET_UPC_REPORT_SUCCESS,
    GET_UPC_REPORT_ERROR
} from "./types";
import { BASEURL } from "../../constant/config.js";
import moment from "moment";

const axios = require("axios");
export const getUPCReport = (token, data, statusID) => (dispatch) => {

    dispatch({ type: GET_UPC_REPORT_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/UpcReport`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: {
            product_status_id: statusID.length > 0 ? statusID.toString() : null,
            gender_id: data.gender_id === "All" ? null : data.gender_id,
            gender_category_id: data.category_id === "All" ? null : data.category_id,
            style_id: data.style_id === "All" ? null : data.style_id,
            item_code: data.sku === "All" ? null : data.sku,
            wash_id: null
        }
    })
        .then((response) => {
            return dispatch({ type: GET_UPC_REPORT_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            if (error.response.data.data === "No Record Found !") {
                return dispatch({
                    type: GET_UPC_REPORT_ERROR,
                    payload: [],
                });
            }
            return dispatch({
                type: GET_UPC_REPORT_ERROR,
                payload: error.response,
            });
        });
};
