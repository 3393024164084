import { Button, Tooltip, Popconfirm, Divider, Tag } from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined, FileDoneOutlined, UndoOutlined, SearchOutlined } from "@ant-design/icons";
import "../../../components/CrudButtons/crud.css";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export default function CrudButtons({ save, disable, formState, formType, onDelete, reset, name, flag, rights, saleOrderID, userRights, formData, user_type, transactionLock }) {
	const history = useHistory();
	const confirm = () => {
		onDelete();
	};


	function disableBtns() {
		let result = false;



		if (!userRights?.can_edit || formState === "add") {
			result = true;
		}
		if (user_type !== "PK") {
			result = true;
		}
		else {
			result = false;
		}
		return result;
	}




	return (
		<>
			<div className="crudContainer">
				<div>
					{formType === "" ? null : (
						<div className="buttons-div">
							<Tag color={"green"} key={1}>
								{formType.toUpperCase()}
							</Tag>
							{
								transactionLock && <Tag color={"red"} key={2}>LOCKED</Tag>
							}
							{formType !== "view" && <Button onClick={() => reset()} className="iconBtn" shape="circle" icon={<UndoOutlined className="fspx-12" />} />}
						</div>
					)}
				</div>
				<div>
					{formType === "formView" ? (
						<Tooltip placement="bottom" title="Refund" color="#fbfbfb">
							{console.log("userRights", userRights)}
							<Button
								disabled={formType === "add" ? true : false || userRights.can_add === true ? false : true || (userRights.can_edit === true ? false : true) || disableBtns()}
								type="primary"
								onClick={() => history.push(`/customer-refund/add?mode=add`, { state: formData })}
								className="modalButton"
							// disabled={disableBtns()}

							>
								Refund
							</Button>
							{
								userRights.can_print ? (
									<Link
										style={{ paddingLeft: "10px", textDecoration: "none" }}
										target="_blank"
										rel="noopener noreferrer"
										to={{
											pathname: `/credit-print-report`,
											search: `?return_header_id=${formData?.memo_header_id}`,
										}}
									>
										View Report
									</Link>
								) : ""
							}

						</Tooltip>
					) : <></>}
					<Tooltip placement="bottom" title="Search" color="#fbfbfb">
						<Button
							disabled={userRights.can_view == true ? false : true}
							onClick={() => history.push("/credit-memo/list")} className="iconBtn crudBTN" shape="circle" icon={<SearchOutlined />} />
					</Tooltip>
					{formType !== "add" ? <>

						{(formType === "view" || formType === "formView") && (

							<Tooltip placement="bottom" title="Add" color="#fbfbfb">
								<Button
									disabled={formType === "add" ? true : false || userRights.can_add === true ? false : true}
									onClick={() => {
										if (formType === "add") {
											formState("add");
										} else {
											history.push("/credit-memo/add?mode=add");
											formState("add");
										}
									}}
									className="iconBtn crudBTN"
									shape="circle"
									icon={<PlusOutlined />}
								/>
							</Tooltip>
						)}
						{(formType == "formView" || formType == "view") && (
							<Tooltip placement="bottom" title="Edit" color="#fbfbfb">
								<Button
									disabled={(userRights.can_edit === true ? false : true) || (disable || formType === "view" || formType === "add") || transactionLock}

									onClick={() => formState("edit")}
									className="iconBtn crudBTN"
									shape="circle"
									icon={<EditOutlined />}
								/>
							</Tooltip>
						)}
						<Tooltip placement="bottom" title="Delete" color="#fbfbfb">
							{rights?.can_delete === false ? (
								<Button className="iconBtn crudBTN" shape="circle" icon={<DeleteOutlined />} />
							) : (formType == "formView" || formType == "view") && (
								<Popconfirm
									disabled={(userRights.can_delete === true ? false : true) || (disable || formType === "view" || formType === "add") || transactionLock}
									placement="topLeft"
									title={`Are you sure to delete this ${saleOrderID}?`}
									onConfirm={confirm}
									okText="Yes"
									cancelText="No"
								>
									<Button
										disabled={(userRights.can_delete === true ? false : true) || (disable || formType === "view" || formType === "add") || transactionLock}
										className="iconBtn crudBTN" shape="circle" icon={<DeleteOutlined />} />
								</Popconfirm>
							)}
						</Tooltip>
					</> : null}
					<Tooltip placement="bottom" title="Save" color="#fbfbfb">
						<Button
							disabled={!((formType === "add" || formType === "edit") && (name === "product" ? (formType === "edit" ? true : flag ? true : false) : !disable)) || transactionLock}
							onClick={() => save()}
							className="iconBtn crudBTN"
							shape="circle"
							icon={<FileDoneOutlined />}
						/>
					</Tooltip>
				</div>
			</div>
			<Divider />
		</>
	);
}