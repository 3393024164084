import React, { useEffect, useState } from "react";
import { Table, Input, InputNumber, Form, Button, Modal } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { getExpenseBill } from "../../redux/actions/expenseBill.action";

const EditExpenseTable = ({ token, companyId, modalValue, setIsModalVisible, setOrderItems }) => {
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const { expenseBill } = useSelector((state) => state);
	const [data, setData] = useState([]);
	const [selectedId, setSelectedId] = useState(null);
	const [selectedItems, setSelectedItems] = useState([]);
	const [selected, setSelected] = useState(false);
	const [filteredItems, setFilteredItems] = useState([]);
	const [selectedRowKey, setSelectedRowKey] = useState([]);

	const updateAmount = (type, id, value) => {
		let arr = [...data];
		const filter = arr.map((item) => {
			if (item.account_id === id) {
				if (type === "up") {
					item.amount = value;
					return item;
				}
				if (type === "down") {
					item.amount = value;
					return item;
				}
			} else {
				return item;
			}
		});
		let selectItem = filter.filter((f) => f.isSelected);
		setFilteredItems(selectItem);
		setData(filter);
		setSelected(false);
	};

	useEffect(() => {
		let arr = [...data];
		let newArr = [...selectedItems];
		let onlyKey = [...newArr].map((sItem) => sItem.account_id);
		arr = [...arr].map((item) => {
			if (onlyKey.indexOf(item.account_id) != -1) {
				return {
					...newArr[onlyKey.indexOf(item.account_id)],
				};
			}
			return item;
		});
		setData(arr);
	}, [selectedItems]);

	useEffect(() => {
		let arr = [...data];
		arr = [...arr].map((item, i) => {
			if (selectedRowKey.indexOf(item.account_id) === -1) {
				item.isSelected = false;
				return item;
			} else {
				item.isSelected = true;
				return item;
			}
		});
	}, [selectedRowKey]);

	useEffect(() => {
		if (!modalValue) {
			setData([]);
			setSelectedId(null);
			setSelectedItems([]);
		}
	}, [modalValue]);

	const handleGenerate = () => {
		dispatch(getExpenseBill(token, companyId, selectedId)).then((res) => {
			if (res.type === "GET_EXPENSE_BILL_SUCCESS") {
				setData(res.payload);
			}
		});
	};

	const handleOk = () => {
		setIsModalVisible(false);
		addOrderItems();
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	const addOrderItems = () => {
		setOrderItems(filteredItems);
		setData([]);
		setSelectedId(null);
	};

	const rowSelection = {
		onChange: (selectedRowKeys, selectedRows) => {
			for (let i = 0; i < selectedRows.length; i++) {
				selectedRows[i].isSelected = true;
				selectedRows[i].amount = selectedRows[i].hasOwnProperty("amount") ? selectedRows[i].amount : 0;
			}
			setSelected(false);
			setSelectedItems(selectedRows);
			setFilteredItems(selectedRows);
			setSelectedRowKey(selectedRowKeys);
		},
	};

	const columns = [
		{
			title: "Account Id",
			dataIndex: "account_id",
		},
		{
			title: "Account No",
			dataIndex: "account_no",
		},
		{
			title: "Full Name",
			dataIndex: "full_name",
		},

		{
			title: "Amount",
			dataIndex: "amount",
			editable: true,
			render: (text, record) => {
				return (
					<InputNumber
						size="small"
						disabled={!record?.isSelected}
						placeholder="Amount"
						onStep={(value, info) => updateAmount(info.type, record.account_id, value)}
						onChange={(value) => updateAmount("up", record.account_id, value)}
						defaultValue={0}
					/>
				);
			},
		},
	];

	return (
		<Modal
			okButtonProps={{
				style: {
					display: selectedItems.length > 0 ? "" : "none",
				},
			}}
			okText="Add Expense"
			destroyOnClose={true}
			width={1200}
			centered={true}
			title="Expense Selection"
			visible={modalValue}
			onOk={handleOk}
			onCancel={handleCancel}
		>
			<Form form={form} component={false}>
				<div style={{ padding: "10px 0", display: "flex", gap: "2px" }}>
					<Input
						size="small"
						onPressEnter={(e) => {
							if (e.target.value.length) {
								handleGenerate();
							}
						}}
						className="w-100"
						value={selectedId}
						placeholder="Search Item"
						name="searchItem"
						onChange={(e) => {
							setSelectedId(e.target.value);
						}}
					/>
					<Button className="customInputs" type="primary" disabled={!selectedId} onClick={() => handleGenerate()}>
						View
					</Button>
				</div>

				<Table
					size="small"
					loading={expenseBill.loading}
					scroll={{ x: "50rem", y: 360 }}
					rowSelection={{ ...rowSelection }}
					bordered
					dataSource={data.map((it) => ({ ...it, key: it.account_id }))}
					columns={columns}
					rowClassName="editable-row"
					pagination={false}
				/>
			</Form>
		</Modal>
	);
};
export default EditExpenseTable;