import {
    GET_INSEAMLABEL_ERROR,
    GET_INSEAMLABEL_REQUEST,
    GET_INSEAMLABEL_SUCCESS,
    ADD_INSEAMLABEL_ERROR,
    ADD_INSEAMLABEL_REQUEST,
    ADD_INSEAMLABEL_SUCCESS,
    DELETE_INSEAMLABEL_REQUEST,
    DELETE_INSEAMLABEL_SUCCESS,
    DELETE_INSEAMLABEL_ERROR,
    UPDATE_INSEAMLABEL_ERROR,
    UPDATE_INSEAMLABEL_REQUEST,
    UPDATE_INSEAMLABEL_SUCCESS,
} from "./types";

import { BASEURL } from "../../constant/config.js";

const axios = require("axios");

export const getInseamLabel = (token, menu_id) => (dispatch) => {
    dispatch({ type: GET_INSEAMLABEL_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/inseamLabel`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { menu_id }
    })
        .then((response) => {
            return dispatch({ type: GET_INSEAMLABEL_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: GET_INSEAMLABEL_ERROR,
                payload: error.response,
            });
        });
};

export const addInseamLabel = (token, data, menu_id) => (dispatch) => {
    dispatch({ type: ADD_INSEAMLABEL_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}create/inseamLabel`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { inseam_label_name: data.inseamLabelName, is_active: data.check, menu_id },
    })
        .then((response) => {
            return dispatch({ type: ADD_INSEAMLABEL_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: ADD_INSEAMLABEL_ERROR,
                payload: error.response,
            });
        });
};
export const updateInseamLabel = (token, data, id) => (dispatch) => {
    dispatch({ type: UPDATE_INSEAMLABEL_REQUEST });
    return axios({
        method: "PUT",
        url: `${BASEURL}update/inseamLabel/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { inseam_label_name: data.inseamLabelName, is_active: data.check },
    })
        .then((response) => {
            return dispatch({ type: UPDATE_INSEAMLABEL_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: UPDATE_INSEAMLABEL_ERROR,
                payload: error.response,
            });
        });
};

export const deleteInseamLabel = (token, id) => (dispatch) => {
    dispatch({ type: DELETE_INSEAMLABEL_REQUEST });
    return axios({
        method: "DELETE",
        url: `${BASEURL}delete/inseamLabel/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
    })
        .then((response) => {
            return dispatch({ type: DELETE_INSEAMLABEL_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: DELETE_INSEAMLABEL_ERROR,
                payload: error.response,
            });
        });
};
