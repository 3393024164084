import { GET_SYSTEM_LOG_ERROR, GET_SYSTEM_LOG_REQUEST, GET_SYSTEM_LOG_SUCCESS } from "./types";



import { BASEURL } from "../../constant/config.js";

const axios = require("axios");


export const getSystemLog = (token, data) => dispatch => {
  dispatch({ type: GET_SYSTEM_LOG_REQUEST });
  
  return axios({
    method: "POST",
    url: `${BASEURL}getAll/systemLogs`,
    headers: {
      Accept: "application/json",
      'Authorization': 'Bearer ' + token
    },
    data
  })
    .then(response => {
      return dispatch({ type: GET_SYSTEM_LOG_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      return dispatch({
        type: GET_SYSTEM_LOG_ERROR,
        payload: [],
      });
    });
};
