import {
	ADD_SALEORDERADJUSTMENT_HEADER_SUCCESS,
	ADD_SALEORDERADJUSTMENT_HEADER_ERROR,
	ADD_SALEORDERADJUSTMENT_HEADER_REQUEST,
	GET_ALL_SALEORDERADJUSTMENT_REQUEST,
	GET_ALL_SALEORDERADJUSTMENT_SUCCESS,
	GET_ALL_SALEORDERADJUSTMENT_ERROR,
	DELETE_SALEORDERADJUSTMENTHEADER_REQUEST,
	DELETE_SALEORDERADJUSTMENTHEADER_SUCCESS,
	DELETE_SALEORDERADJUSTMENTHEADER_ERROR,
	UPDATE_SALEORDERADJUSTMENT_HEADER_REQUEST,
	UPDATE_SALEORDERADJUSTMENT_HEADER_ERROR,
	UPDATE_SALEORDERADJUSTMENT_HEADER_SUCCESS,
	GET_ONE_SALEORDERADJUSTMENTHEADER_SUCCESS,
	GET_ONE_SALEORDERADJUSTMENTHEADER_REQUEST,
	GET_ONE_SALEORDERADJUSTMENTHEADER_ERROR,
	DELETE_SALESORDER_ADJ_LINE_REQUEST,
	DELETE_SALESORDER_ADJ_LINE_SUCCESS,
	DELETE_SALESORDER_ADJ_LINE_ERROR,
	GET_ONE_PENDINGORDERADJLINE_REQUEST,
	GET_ONE_PENDINGORDERADJLINE_SUCCESS,
	GET_ONE_PENDINGORDERADJLINE_ERROR,
} from "../actions/types";

export default function (state = [], action) {
	const { type, payload } = action;
	switch (type) {
		case ADD_SALEORDERADJUSTMENT_HEADER_REQUEST:
			return {
				loading: true,
			};
		case ADD_SALEORDERADJUSTMENT_HEADER_SUCCESS:
			return {
				...state,
				loading: false,
				payload: [payload],
			};
		case ADD_SALEORDERADJUSTMENT_HEADER_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};
		case GET_ALL_SALEORDERADJUSTMENT_REQUEST:
			return {
				loading: true,
			};
		case GET_ALL_SALEORDERADJUSTMENT_SUCCESS:
			
			
			return {
				...state,
				loading: false,
				payload: [payload],
			};
		case GET_ALL_SALEORDERADJUSTMENT_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};
		case UPDATE_SALEORDERADJUSTMENT_HEADER_REQUEST:
			return {
				loading: true,
			};
		case UPDATE_SALEORDERADJUSTMENT_HEADER_SUCCESS:
			return {
				...state,
				loading: false,
				payloadSuccess: payload,
			};
		case UPDATE_SALEORDERADJUSTMENT_HEADER_ERROR:
			return {
				...state,
				loading: false,
				errorPayload: payload,
			};
		case DELETE_SALEORDERADJUSTMENTHEADER_REQUEST:
			return {
				loading: true,
			};
		case DELETE_SALEORDERADJUSTMENTHEADER_SUCCESS:
			return {
				...state,
				loading: false,
				payload: [payload],
			};
		case DELETE_SALEORDERADJUSTMENTHEADER_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};
		case GET_ONE_SALEORDERADJUSTMENTHEADER_REQUEST:
			return {
				loading: true,
			};
		case GET_ONE_SALEORDERADJUSTMENTHEADER_SUCCESS:
			return {
				...state,
				loading: false,
				payload: payload,
			};
		case GET_ONE_SALEORDERADJUSTMENTHEADER_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};
		case GET_ONE_PENDINGORDERADJLINE_REQUEST:
			return {
				loading: true,
			};
		case GET_ONE_PENDINGORDERADJLINE_SUCCESS:
			return {
				...state,
				loading: false,
				payload: payload,
			};
		case GET_ONE_PENDINGORDERADJLINE_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};
		case DELETE_SALESORDER_ADJ_LINE_REQUEST:
			return {
				loading: true,
			};
		case DELETE_SALESORDER_ADJ_LINE_SUCCESS:
			return {
				...state,
				loading: false,
				payload: payload,
			};
		case DELETE_SALESORDER_ADJ_LINE_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};
		default:
			return state;
	}
}