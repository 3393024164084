import {
    ADD_ALL_COLUMNSCHEMA_SUCCESS,
    ADD_ALL_COLUMNSCHEMA_REQUEST,
    ADD_ALL_COLUMNSCHEMA_ERROR,
  } from "./types";
  
  import { BASEURL } from "../../constant/config.js";
  import axios from "axios";
  
  export const getColumnSchema = (token, payload) => (dispatch) => {
    dispatch({ type: ADD_ALL_COLUMNSCHEMA_REQUEST });
    return axios({
      method: "POST",
      url: `${BASEURL}getAll/getColumnSchema`,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
      data: payload,
    })
      .then((response) => {
        return dispatch({
          type: ADD_ALL_COLUMNSCHEMA_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        return dispatch({
          type: ADD_ALL_COLUMNSCHEMA_ERROR,
          payload: error.response,
        });
      });
  };
  