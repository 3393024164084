import {
  ADD_ALL_TABLESCHEMA_SUCCESS,
  ADD_ALL_TABLESCHEMA_REQUEST,
  ADD_ALL_TABLESCHEMA_ERROR,
} from "../actions/types";

export default function (state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case ADD_ALL_TABLESCHEMA_REQUEST:
      return {
        loading: true,
      };
    case ADD_ALL_TABLESCHEMA_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: payload,
      };
    case ADD_ALL_TABLESCHEMA_ERROR:
      return {
        ...state,
        loading: false,
        payload,
      };
    default:
      return state;
  }
}
