import {
  GET_AR_AGING_SUMMARY_REQUEST,
  GET_AR_AGING_SUMMARY_SUCCESS,
  GET_AR_AGING_SUMMARY_ERROR
} from "./types";
import axios from "axios";
import { BASEURL } from "../../constant/config.js";
import moment from "moment";

export const getArAgingSummary = (token, values) => (dispatch) => {
  
  dispatch({ type: GET_AR_AGING_SUMMARY_REQUEST });
  const newData = {
    from_date: moment(values.from_date).format("YYYY-MM-DD"),
    to_date: moment(values.to_date).format("YYYY-MM-DD"),
    gender_id: values.gender === "All" ? null : values.gender,
    sales_person_id: values.sale_person === "All" ? null : values.sale_person,
    customer_id: (values.customer_id === "All" || values.customer_id === undefined)  ? null : values.customer_id,
    peyment_term_id: values.terms === "All" ? null : values.terms,
    factor_id: (values.paymentClass === "All" || values.paymentClass === undefined) ? null : values.paymentClass,
  };

  return axios({
    method: "POST",
    url: `${BASEURL}getAll/CustomerAging`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: newData
  })
    .then((response) => {
      return dispatch({
        type: GET_AR_AGING_SUMMARY_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_AR_AGING_SUMMARY_ERROR,
        payload: [],
      });
    });
};

