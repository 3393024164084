import {
    GET_PAYMENT_TERMS_REQUEST, GET_PAYMENT_TERMS_ERROR, GET_PAYMENT_TERMS_SUCCESS, ADD_PAYMENT_TERMS_ERROR, ADD_PAYMENT_TERMS_REQUEST, ADD_PAYMENT_TERMS_SUCCESS,
    UPDATE_PAYMENT_TERMS_ERROR, UPDATE_PAYMENT_TERMS_REQUEST, UPDATE_PAYMENT_TERMS_SUCCESS, DELETE_PAYMENT_TERMS_REQUEST, DELETE_PAYMENT_TERMS_SUCCESS, DELETE_PAYMENT_TERMS_ERROR
} from "./types";


import { BASEURL } from "../../constant/config.js";

const axios = require("axios");




export const getPaymentTerm = (token, id) => (dispatch) => {
    dispatch({ type: GET_PAYMENT_TERMS_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/customerTerm`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { id }
    })
        .then((response) => {
            return dispatch({ type: GET_PAYMENT_TERMS_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: GET_PAYMENT_TERMS_ERROR,
                payload: error.response,
            });
        });
};


export const addPaymentTerm = (token, data, id) => (dispatch) => {
    dispatch({ type: ADD_PAYMENT_TERMS_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}create/customerTerm`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data,
    })
        .then((response) => {

            return dispatch({ type: ADD_PAYMENT_TERMS_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: ADD_PAYMENT_TERMS_ERROR,
                payload: error.response,
            });
        });
};


export const updatePaymentTerm = (token, data, id) => (dispatch) => {
    dispatch({ type: UPDATE_PAYMENT_TERMS_REQUEST });
    return axios({
        method: "PUT",
        url: `${BASEURL}update/customerTerm/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data,
    })
        .then((response) => {

            return dispatch({ type: UPDATE_PAYMENT_TERMS_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: UPDATE_PAYMENT_TERMS_ERROR,
                payload: error.response,
            });
        });
};


export const deletePaymentTerm = (token, id) => (dispatch) => {
    dispatch({ type: DELETE_PAYMENT_TERMS_REQUEST });
    return axios({
        method: "DELETE",
        url: `${BASEURL}delete/customerTerm/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
    })
        .then((response) => {
            return dispatch({ type: DELETE_PAYMENT_TERMS_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: DELETE_PAYMENT_TERMS_ERROR,
                payload: error.response,
            });
        });
};
