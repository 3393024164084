import {
    GET_ONE_SALEPERSON_REQUEST,
    GET_ONE_SALEPERSON_SUCCESS,
    GET_ONE_SALEPERSON_ERROR,

  } from "../types";
  import axios from "axios";
  import { BASEURL } from "../../../constant/config.js";
  
  export const getOneSaleperson = (token, id) => dispatch => {
      dispatch({ type: GET_ONE_SALEPERSON_REQUEST });
      return axios({
          method: "GET",
          url: `${BASEURL}getOne/salesPerson/${id}`,
          headers: {
              Accept: "application/json",
              'Authorization': 'Bearer ' + token
          }
      })
          .then(response => {
              return dispatch({ type: GET_ONE_SALEPERSON_SUCCESS, payload: response.data.data[0] });
          })
          .catch((error) => {
              return dispatch({
                  type: GET_ONE_SALEPERSON_ERROR,
                  payload: error.response,
              });
          })
  }
