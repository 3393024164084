import {
    GET_RETURN_REGION_STATE_REPORT_CUSTOMER_ERROR,
    GET_RETURN_REGION_STATE_REPORT_CUSTOMER_REQUEST,
    GET_RETURN_REGION_STATE_REPORT_CUSTOMER_SUCCESS,
} from "./types";
import { BASEURL } from "../../constant/config.js";

const axios = require("axios");

export const getOmsReturnReportByCustomer = (token, data) => (dispatch) => {
    
    const values = {
        from_date: data.from_date,
        to_date: data.to_date,
        gender_id: data.gender_id === "All" ? null : data.gender_id,
        region_id: data.id === "All" ? null : data.id,
        customer_type_id: data.customer_type_id === "All" ? null : data.customer_type_id,
        company_id: data.company_id ? data.company_id : null,
        customer_id: data.customer_id ? data.customer_id : null,
        
        state_id: data.state_id
    }
    dispatch({ type: GET_RETURN_REGION_STATE_REPORT_CUSTOMER_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/getSaleReturnOmsCustomerByReport`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: values
    })
        .then((response) => {

            return dispatch({ type: GET_RETURN_REGION_STATE_REPORT_CUSTOMER_SUCCESS, payload: response.data });
        })
        .catch((error) => {

            return dispatch({
                type: GET_RETURN_REGION_STATE_REPORT_CUSTOMER_ERROR,
                payload: error.response,
            });
        });
};
