import {GET_CREDIT_OPEN_INVOICE_DETAIL_REPORT_REQUEST, GET_CREDIT_OPEN_INVOICE_DETAIL_REPORT_SUCCESS, GET_CREDIT_OPEN_INVOICE_DETAIL_REPORT_ERROR } from "../actions/types"
export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case GET_CREDIT_OPEN_INVOICE_DETAIL_REPORT_REQUEST:
            return {
                loading: true,
            };
        case GET_CREDIT_OPEN_INVOICE_DETAIL_REPORT_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_CREDIT_OPEN_INVOICE_DETAIL_REPORT_ERROR:
            return {
                ...state,
                loading: false,
                payload,
            };

        default: {
            return state;
        }
    }
};
