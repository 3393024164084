import {
	GET_ALL_SCM_BRAND_REQUEST,
	GET_ALL_SCM_BRAND_ERROR,
	GET_ALL_SCM_BRAND_SUCCESS,
	GET_SCM_BRAND_REQUEST,
	GET_SCM_BRAND_ERROR,
	GET_SCM_BRAND_SUCCESS,
	ADD_SCM_BRAND_REQUEST,
	ADD_SCM_BRAND_ERROR,
	ADD_SCM_BRAND_SUCCESS,
	UPDATE_SCM_BRAND_REQUEST,
	UPDATE_SCM_BRAND_ERROR,
	UPDATE_SCM_BRAND_SUCCESS,
	DELETE_SCM_BRAND_REQUEST,
	DELETE_SCM_BRAND_ERROR,
	DELETE_SCM_BRAND_SUCCESS,
} from "../actions/types";

export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
      
        case GET_ALL_SCM_BRAND_REQUEST:
            return {
                loading: true,
            };
        case GET_ALL_SCM_BRAND_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_ALL_SCM_BRAND_ERROR:
            return {
                ...state,
                loading: false
            };
        case GET_SCM_BRAND_REQUEST: 
        return{
            loading: true,
        };
        case GET_SCM_BRAND_SUCCESS:
            return{
                ...state,
                loading: false,
                payload,
            }
        case GET_SCM_BRAND_ERROR:
            return{
                ...state,
                loading: false
            }
        case ADD_SCM_BRAND_REQUEST:
            return{
                loading: true,
            }
        case ADD_SCM_BRAND_SUCCESS:
            return{
            ...state,
            loading: false,
            payload:[payload],
            }
        case ADD_SCM_BRAND_ERROR:
            return{
                ...state,
                loading: false
            }
        case UPDATE_SCM_BRAND_REQUEST:
            return{
                loading: true,
            }
        case UPDATE_SCM_BRAND_SUCCESS:
            return{
                ...state,
                loading: false,
                payload,
            }
        case UPDATE_SCM_BRAND_ERROR:
            return{
                ...state,
                loading: false
            }
        case DELETE_SCM_BRAND_REQUEST:
            return{
                loading: true,
            }
        case DELETE_SCM_BRAND_SUCCESS:
            return{
                ...state,
                loading: false,
                payload,
            }
        case DELETE_SCM_BRAND_ERROR:
            return{
                ...state,
                loading: false
            }
            default:
            return state;
        
    }
  }
  