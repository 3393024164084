import {
    GET_OMS_AVAILABLE_BALANCE_REQUEST,
    GET_OMS_AVAILABLE_BALANCE_SUCCESS,
    GET_OMS_AVAILABLE_BALANCE_ERROR,
} from "../actions/types"
export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case GET_OMS_AVAILABLE_BALANCE_REQUEST:
            return {
                loading: true,
            };
        case GET_OMS_AVAILABLE_BALANCE_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_OMS_AVAILABLE_BALANCE_ERROR:
            return {
                ...state,
                loading: false,
                payload,
            };

        default: {
            return state;
        }
    }
};
