import { GET_CHART_ACCOUT_REQUEST, GET_CHART_ACCOUT_SUCCESS, GET_CHART_ACCOUT_ERROR } from "../actions/types";



export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case GET_CHART_ACCOUT_REQUEST:
            return {
                loading: true,
            };
        case GET_CHART_ACCOUT_SUCCESS:
            return {
                ...state,
                loading: false,
                payload
            };
        case GET_CHART_ACCOUT_ERROR:
            return {
                ...state,
                loading: false,
                payload,
            };
        default:
            return state;
    }
}
