import {
    GET_ACCOUNT_PAYABLE_REQUEST,
    GET_ACCOUNT_PAYABLE_SUCCESS,
    GET_ACCOUNT_PAYABLE_ERROR,
} from "../actions/types";



export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {


        case GET_ACCOUNT_PAYABLE_REQUEST:
            return {
                loading: true,
            };
        case GET_ACCOUNT_PAYABLE_SUCCESS:
            return {
                ...state,
                loading: false,
                payload
            };
        case GET_ACCOUNT_PAYABLE_ERROR:
            return {
                ...state,
                loading: false,
                payload,
            };
        default:
            return state;
    }
}
