import { useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Form, DatePicker, Spin } from "antd";
import { useParams } from "react-router-dom";
import { ReadCookie } from "../../utils/readCookie";
import numeral from "numeral";
import moment from "moment";
import logo from "../../assets/images/logo.png";
import xlsx from "../../assets/images/ico_xls_sm.gif";
import "../ProducList/productList.css";
import { TabTitle } from "../../utils/GeneralFunction.js";
import { useDownloadExcel } from "react-export-table-to-excel";
import { getApAgingDetail } from "../../redux/actions/apAgingDetail.action.js";
const APAgingDetail = ({ token }) => {
  TabTitle("A/P Aging Detail")
  const { balanceSheet, apAgingDetail } = useSelector((state) => state);
  const menuId = ReadCookie("menuId");
  const userName = ReadCookie("name");
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [formState, setFormState] = useState({});
  const [loading, setLoading] = useState(false);
  const [balanceSheetDataset, setBalanceSheetDataset] = useState([]);
  const { submited } = useParams();

  const tableRef = useRef(null);

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "A/P Aging Detail",
    sheet: "A/P Aging Detail",
  });

  const handlePrint = () => {
    window.print();
  };


  const onFinish = async (e) => {
    setLoading(true);
    await form.validateFields().then((data) => {
      
      dispatch(getApAgingDetail(token, data)).then((res) => {
        if (res?.type === "GET_AP_AGING_DETAIL_SUCCESS") {
          setBalanceSheetDataset(res?.payload?.data);
          setLoading(false);
        }
      });
    });
  };

  

  let totalSumVendor = filterVendors(balanceSheetDataset).vendors.map((v) => v.total_Vendor_Balance).reduce((acc, val) => acc + parseFloat(val), 0)
  let totalSumNonVendor = filterVendors(balanceSheetDataset).nonVendor.map((v) => v.total_Vendor_Balance).reduce((acc, val) => acc + parseFloat(val), 0)


  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <div className="report-wrapper-main header_class">
              <div className="row justify-content-between pb-0">
                <div className="col-3 d-flex align-items-start  box-space">
                  <div className="logobrand">
                    <img id="ImgLogo" alt="logo" src={logo} />
                  </div>
                </div>
                <div className="col-3 box-space right-content-header text-right">
                  <div className=" d-flex justify-content-end">
                    <div className=" d-flex justify-content-end printing_class">
                      <div className="print_icon printing_class">
                        <i
                          id="Image1"
                          title="Print"
                          alt="Print"
                          className="fa-solid fa-print printing_class"
                          onClick={handlePrint}
                        ></i>
                      </div>
                      <div className="print_icon printing_class">
                        {balanceSheetDataset ? (
                          <input
                            type="image"
                            name="ImgExcel"
                            alt="Export to excel"
                            id="ImgExcel"
                            title="Export to excel"
                            src={xlsx}
                            onClick={onDownload}
                            style={{
                              borderWidth: "0px",
                              height: "14px",
                              cursor: "pointer",
                            }}
                          />
                        ) : (
                          <input
                            type="image"
                            name="ImgExcel"
                            alt="Export to excel"
                            id="ImgExcel"
                            title="Export to excel"
                            src={xlsx}
                            style={{ borderWidth: "0px", height: "14px" }}
                          />
                        )}
                      </div>
                    </div>
                    <span style={{ fontWeight: "bolder" }}>
                      A/P Aging Detail
                      <br />
                      Rpt # 006
                      <br />
                      Login as:
                      <span id="lblSalesPerson" style={{ fontWeight: "bold" }}>
                        {" "}
                        {userName}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              {
                <div className="row pb-2">
                  <hr className="my-1" />
                  <div className="col">
                    <Form
                      labelCol={{ span: 6 }}
                      wrapperCol={{ span: 18 }}
                      layout="horizontal"
                      form={form}
                      name="control-hooks"
                      onFinish={onFinish}
                    >
                      <div className="main_form row">
                        <div className=" col-6">
                          <div className=" d-flex flex-row align-items-baseline">
                            <label htmlFor="gender_id" className="labels width30">
                              Year To:
                            </label>
                            <div style={{ marginBottom: "2px" }} className=" w-100">
                              <Form.Item
                                name="to_date"
                                className="full_date"
                                initialValue={moment().endOf('year')}
                              >
                                <DatePicker
                                  size="small"
                                  format={"MM-DD-YYYY"}
                                
                                />
                              </Form.Item>
                            </div>
                          </div>
                        </div>
                        <div className=" col-6">
                          <div className=" d-flex flex-row align-items-baseline">
                            <div style={{ marginTop: "4px", display: "flex" }} >
                              <Button type="primary" htmlType="submit" className='submit-btn-btm m-0'
                              >
                                Search
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>}
            </div>
          </div>
        </div>
      </div>
      {
        <div style={{ display: "none" }} >
          <div className="report-box-main" ref={tableRef} >
            {
              balanceSheetDataset ? (
                balanceSheetDataset.length > 0 ? (
                  <>
                    {
                      balanceSheetDataset && (
                        <>
                          <table className="report-table-main" style={{ fontSize: "10px" }} >
                            <tr className="report-row-head">
                              <td className="report-column-head" style={{ backgroundColor: "#d3d3d3", fontWeight: 900, width: "500px" }}>Vendor</td>
                              <td className="report-column-head" style={{ backgroundColor: "#d3d3d3", fontWeight: 900, width: "500px" }}>Transaction Type</td>
                              <td className="report-column-head" style={{ backgroundColor: "#d3d3d3", fontWeight: 900 }}>Date</td>
                              <td className="report-column-head" style={{ backgroundColor: "#d3d3d3", fontWeight: 900, textAlign: "right" }}>Document#</td>
                              <td className="report-column-head" style={{ backgroundColor: "#d3d3d3", fontWeight: 900 }}>Due Date</td>
                              <td className="report-column-head" style={{ backgroundColor: "#d3d3d3", fontWeight: 900, textAlign: "right" }}>Age</td>
                              <td className="report-column-head" style={{ backgroundColor: "#d3d3d3", fontWeight: 900, textAlign: "right" }}>Open Balance</td>
                            </tr>
                            <table>
                              <tr>
                                <td style={{ fontWeight: 900 }}>Vendor</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                              {

                                filterVendors(balanceSheetDataset)?.vendors?.map((val, idx) => {
                                  
                                  return (<>
                                    <tr className="report-hd" style={{ fontWeight: 700 }}>
                                      <td style={{ width: "500px", padding: "0 16px"}}>{val?.vendor_name}</td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      {/* <td style={{ width: "500px", textAlign: "right" }}>{val?.total_Vendor_Balance}</td> */}
                                    </tr>
                                    {
                                      val?.SubVendor?.map((v, index) => {
                                        
                                        return (
                                          <tr>
                                            <td></td>
                                            <td style={{ width: "500px",marginLeft: 10, fontWeight: 500 }}>{v.transtype}</td>
                                            <td style={{ width: "500px" }}>{v.transaction_date}</td>
                                            <td style={{ width: "500px" }}>{v.transaction_header_no}</td>
                                            <td style={{ width: "500px" }}>{v.due_date}</td>
                                            <td style={{ width: "500px" }}>{v.age}</td>
                                            <td style={{ width: "500px" }}>{v.balance}</td>
                                          </tr>
                                        )
                                      })
                                    }
                                      <tr className="report-hd" style={{ fontWeight: 700 }}>
                                      <td style={{ width: "500px", padding: "0 16px"}}>Total-{val?.vendor_name}</td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td style={{ width: "500px", textAlign: "right" }}>{val?.total_Vendor_Balance}</td>
                                    </tr>
                                  </>
                                  )
                                })
                              }
                            </table>
                            <table>
                              <tr>
                                <td style={{ fontWeight: 900 }}>NonVendor</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                            {
                              filterVendors(balanceSheetDataset)?.nonVendor?.map((val, idx) => {
                                
                                return ( <>
                                    <tr className="report-hd" style={{ fontWeight: 700 }}>
                                      <td style={{ width: "500px", padding: "0 16px", }}>{val?.vendor_name}</td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      {/* <td style={{ width: "500px", textAlign: "right" }}>{val?.total_Vendor_Balance}</td> */}
                                    </tr>
                                    {
                                      val?.SubVendor?.map((v, index) => {
                                        
                                        return (
                                          <tr>
                                            <td></td>
                                            <td style={{ marginLeft: 10, fontWeight: 500 }}>{v.transtype}</td>
                                            <td style={{ width: "500px" }}>{v.transaction_date}</td>
                                            <td style={{ width: "500px" }}>{v.transaction_header_no}</td>
                                            <td style={{ width: "500px" }}>{v.due_date}</td>
                                            <td style={{ width: "500px" }}>{v.age}</td>
                                            <td style={{ width: "500px" }}>{v.balance}</td>
                                          </tr>
                                        )
                                      })
                                    }
                                    <tr className="report-hd" style={{ fontWeight: 700 }}>
                                      <td style={{ width: "500px", padding: "0 16px", }}>Total {val?.vendor_name}</td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td style={{ width: "500px", textAlign: "right" }}>{val?.total_Vendor_Balance}</td>
                                    </tr>
                                  </>)
                              })
                            }
                            </table>
                          </table>
                        </>
                      )
                    }
                  </>) :
                  (
                    <div className="no-Data">
                      <h1>No Data</h1>
                    </div>
                  )
              )
                : (
                  loading && (
                    <div className="loader" style={{ marginTop: "20px" }}>
                      <Spin size="medium" />
                    </div>
                  )
                )
            }
          </div>
        </div>}
      <div className="table_content international_order"
        style={{ maxWidth: '1150px' }}>
        {apAgingDetail?.payload?.data !== undefined ?
          (
            apAgingDetail?.payload?.data.length > 0 ? (
              <>
                <div style={{
                  display: "flex",
                  paddingRight: "5px",
                  alignItems: "center",
                  background: "grey", color: "white",
                  height: "30px",
                  position: "sticky",
                  top: "0"
                }} >
                  <div style={{ width: "40px" }}>
                  </div>
                  <div style={{ width: "500px", textAlign: "left" }}>
                    Vendor
                  </div>
                  <div style={{ width: "150px" }}>
                    Transaction Type
                  </div>
                  <div style={{ width: "110px" }}>
                    Date
                  </div>
                  <div style={{ width: "110px" }}>
                    Document#
                  </div>
                  <div style={{ width: "110px" }}>
                    Due Date
                  </div>
                  <div style={{ width: "110px" }}>
                    Age
                  </div>
                  <div style={{ width: "110px" }}>
                    Open Balance
                  </div>
                </div>
                <>
                  <Accordion
                    balanceSheetDataset={balanceSheetDataset}
                    totalSumVendor={numeral(totalSumVendor).format("0,00.00")}
                  />
                  <Accordion1
                    balanceSheetDataset={balanceSheetDataset}
                    totalSumNonVendor={numeral(totalSumNonVendor).format("0,00.00")}
                  />
                </>
              </>
            ) : (
              <div className="no-Data">
                <h1>No Data</h1>
              </div>
            )
          ) : (
            loading && (
              <div className="loader" style={{ marginTop: "20px" }}>
                <Spin size="medium" />
              </div>
            )
          )
        }
      </div>
     
    </>
  );
};

export default APAgingDetail;


const filterVendors = (data) => {
  let vendorsArray = []
  let nonVendorsArray = []
  data.map((e) => {
    if (e?.vendor_name === null) {
      nonVendorsArray.push(e)
    } else {
      vendorsArray.push(e)
    }
  })
  return { vendors: vendorsArray, nonVendor: nonVendorsArray }
}



const Accordion = ({ balanceSheetDataset, totalSumVendor }) => {
  const [isActive, setIsActive] = useState(true);
  return (
    <div>
      <div className="accordion-title" style={{ display: "flex", padding: "5px 5px 4px 6px", fontSize: "20px", alignItems: 'center', height: "26px" }} onClick={() => setIsActive(!isActive)}>
        <div style={{ width: "40px", cursor: "pointer" }}>{isActive ? '-' : '+'}</div>
        <div style={{ width: "960px", fontWeight: "bold", fontSize: 14 }} className="fontBold">Vendor</div>

        {!isActive &&
          <div style={{ fontWeight: 800, fontSize: 14, marginLeft: 42, display: "flex", flexDirection: "row" }} className="fontBold">
            {"$" + totalSumVendor}
          </div>
        }
      </div>
      {isActive && <div className="accordion-content">
        {
          filterVendors(balanceSheetDataset)?.vendors?.map((value, index) => {
            return (
              <>
                <AccordionSubInner
                  headVendorName={value.vendor_name}
                  tableRow={filterVendors(balanceSheetDataset)?.vendors[index]?.SubVendor}
                  headVendorBalance={filterVendors(balanceSheetDataset)?.vendors[index]?.total_Vendor_Balance}
                />
              </>
            )
          })
        }
        <hr style={{ margin: "0px" }} />
        <div style={{ fontWeight: 800, fontSize: 14, marginLeft: 40, display: "flex", flexDirection: "row" }} className="fontBold">
          <p className="fontBold" style={{ width: "990px" }}>
            {"Total" + " " + "Vendor"}
          </p>
          <p className="fontBold" style={{ width: "120px", textAlign: "right" }}>
            {"$" + totalSumVendor}
          </p>
        </div>
      </div>}
    </div>
  );
};

const AccordionSubInner = ({ headVendorName, headVendorBalance, tableRow }) => {
  
  const [isActive1, setIsActive1] = useState(true);
  return (
    <div>
      <div className="accordion-title fontBold" style={{ display: "flex", padding: "5px 5px 4px 6px", fontSize: "20px", fontWeight: 'bold', alignItems: 'center', height: "26px" }} onClick={() => setIsActive1(!isActive1)}>
        <div style={{ width: "10px" }}></div>
        <div style={{ width: "40px", cursor: "pointer" }}>{isActive1 ? '-' : '+'}</div>
        <div
          style={{ width: "950px", fontSize: 14, fontWeight: 900 }}
          className="fontBold"
        >
          {headVendorName}
        </div>
        {!isActive1 &&
          <div style={{ width: "100px", fontWeight: 800, fontSize: 14, marginLeft: 62, display: "flex", flexDirection: "row", textAlign: "right", justifyContent: "flex-end" }} className="fontBold">
            <p className="fontBold" style={{ textAlign: "right" }}>
              {"$" + headVendorBalance}
            </p>
          </div>
        }
      </div>
      {isActive1 && <div className="accordion-content">
        {tableRow?.map((v, index) => {
          return (
            <>
              <div style={{ marginLeft: "500px", display: "flex" }}>
                <div style={{ width: "150px", textAlign: "left" }}>
                  {v.transtype}
                </div>
                <div style={{ width: "110px", textAlign: "left" }}>
                  {moment(v.transaction_date).format("MM-DD-YYYY")}
                </div>
                <div style={{ width: "110px", textAlign: "left" }}>
                  {v.transaction_header_no}
                </div>
                <div style={{ width: "110px", textAlign: "left" }}>
                  {moment(v.due_date).format("MM-DD-YYYY")}
                </div>
                <div style={{ width: "110px", textAlign: "left" }}>
                  {v.age}
                </div>
                <div style={{ width: "110px", textAlign: "right" }}>
                  {"$" + v.balance}
                </div>
              </div>
            </>
          )
        })}
        <hr style={{ margin: "0px" }} />
        <div style={{ fontWeight: 800, fontSize: 14, marginLeft: 55, display: "flex", flexDirection: "row" }} className="fontBold">
          <p className="fontBold" style={{ width: "990px" }}>
            {"Total" + " " + headVendorName}
          </p>
          <p className="fontBold" style={{ width: "105px", textAlign: "right" }}>
            {"$" + headVendorBalance}
          </p>
        </div>
      </div>}
    </div>
  );
};

const Accordion1 = ({ balanceSheetDataset, totalSumNonVendor }) => {
  const [isActivee, setIsActivee] = useState(true);
  return (
    <div>
      <div className="accordion-title" style={{ display: "flex", padding: "5px 5px 4px 6px", fontSize: "20px", alignItems: 'center', height: "26px" }} onClick={() => setIsActivee(!isActivee)}>
        <div style={{ width: "40px", cursor: "pointer" }}>{isActivee ? '-' : '+'}</div>
        <div style={{ width: "960px", fontWeight: "bold", fontSize: 14 }} className="fontBold">NonVendor</div>

        {!isActivee &&
          <div style={{ width: "100px", fontWeight: 800, fontSize: 14, marginLeft: 42, display: "flex", flexDirection: "row" }} className="fontBold">
            {"$" + totalSumNonVendor}
          </div>
        }
      </div>
      {isActivee && <div className="accordion-content">
        {
          filterVendors(balanceSheetDataset)?.nonVendor?.map((value, index) => {
            return (
              <>
                <AccordionSubInner1
                  headVendorName={value.vendor_name}
                  tableRow={filterVendors(balanceSheetDataset)?.nonVendor[index]?.SubVendor}
                  headVendorBalance={filterVendors(balanceSheetDataset)?.nonVendor[index]?.total_Vendor_Balance}
                />
              </>
            )
          })
        }
        <hr style={{ margin: "0px" }} />
        <div style={{ fontWeight: 800, fontSize: 14, marginLeft: 40, display: "flex", flexDirection: "row" }} className="fontBold">
          <p className="fontBold" style={{ width: "990px" }}>
            {"Total" + " " + "NonVendor"}
          </p>
          <p className="fontBold" style={{ width: "120px", textAlign: "right" }}>
            {"$" + totalSumNonVendor}
          </p>
        </div>
      </div>}
    </div>
  );
};

const AccordionSubInner1 = ({ headVendorName, headVendorBalance, tableRow }) => {
  
  const [isActive11, setIsActive11] = useState(true);
  return (
    <div>
      <div className="accordion-title fontBold" style={{ display: "flex", padding: "5px 5px 4px 6px", fontSize: "20px", fontWeight: 'bold', alignItems: 'center', height: "26px" }} onClick={() => setIsActive11(!isActive11)}>
        <div style={{ width: "10px" }}></div>
        <div style={{ width: "40px", cursor: "pointer" }}>{isActive11 ? '-' : '+'}</div>
        <div
          style={{ width: "950px", fontSize: 14, fontWeight: 900 }}
          className="fontBold"
        >
          {headVendorName}
        </div>
        {!isActive11 &&
          <div style={{ fontWeight: 800, fontSize: 14, marginLeft: 62, display: "flex", flexDirection: "row", textAlign: "right" }} className="fontBold">
            {/* <p className="fontBold" style={{ textAlign: "right" }}> */}
            {"$" + headVendorBalance}
            {/* </p> */}
          </div>
        }
      </div>
      {isActive11 && <div className="accordion-content">
        {tableRow?.map((v, index) => {
          return (
            <>
              <div style={{ marginLeft: "500px", display: "flex" }}>
                <div style={{ width: "150px", textAlign: "left" }}>
                  {v.transtype}
                </div>
                <div style={{ width: "110px", textAlign: "left" }}>
                  {moment(v.transaction_date).format("MM-DD-YYYY")}
                </div>
                <div style={{ width: "110px", textAlign: "left" }}>
                  {v.transaction_header_no}
                </div>
                <div style={{ width: "110px", textAlign: "left" }}>
                  {moment(v.due_date).format("MM-DD-YYYY")}
                </div>
                <div style={{ width: "110px", textAlign: "left" }}>
                  {v.age}
                </div>
                <div style={{ width: "110px", textAlign: "right" }}>
                  {"$" + v.balance}
                </div>
              </div>
            </>
          )
        })}
        <hr style={{ margin: "0px" }} />
        <div style={{ fontWeight: 800, fontSize: 14, marginLeft: 55, display: "flex", flexDirection: "row" }} className="fontBold">
          <p className="fontBold" style={{ width: "990px" }}>
            {"Total"}
          </p>
          <p className="fontBold" style={{ width: "105px", textAlign: "right" }}>
            {"$" + headVendorBalance}
          </p>
        </div>
      </div>}
    </div>
  );
};

