export let header = `<div className="report-wrapper-main header_class" 
style ="margin: 0 auto;"
>
<div 
style = "margin-top: 5px; display: flex;width: 100%; justify-content: space-between" 
>
<div className="col-sm-3 d-flex align-items-sm-center box-space" 
style="display:flex;align-items: center;width:20%;"
>
<div className="logobrand">
<img src="https:
</div>
</div> 
<div className="col-sm-6 box-space pl-sm-0" 
style ="width:55%"
>
  <p className="brand-para" style=" font-family: system-ui">
    DL1961 Premium Denim Inc.
    <br />
    121 Varick Street
    <br />
    4th Floor
    <br />
    New York, NY 10013
    <br />
    (P) 646-745-1524
    <br />
    (F) 212-991-9989
    <br />
    (E) sales@DL1961.com
    <br />
  </p>
</div>
    <div style ="width = 25%; margin-top:10px; font-family: system-ui; font-weight: bolder;">
    <span>
    <br />
    Region-Wise Booking
    <br />
    Rpt # 006
    </span>
    </div>
</div>
</div>`