import React, { useState, useEffect, useRef, useLayoutEffect } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { getPaymentType, addPaymentType, deletePaymentType, updatePaymentType } from "../../redux/actions/paymentType.action";
import { getDefaultCompany, getOneDefaultCompany } from "../../redux/actions/defaultCompany";
import { getCompanyAccess, updateCompanyAccess, deleteCompanyAccess, getAllCompany, createCompanyAccess } from "../../redux/actions/companyAccess"
import { getLog } from "../../redux/actions/log.action"
import { getRights } from "../../redux/actions/userRights.action";

import PageHeader from "../../components/PageHeader/PageHeader"
import CrudButtons from "../../components/CrudButtons/CrudButtons";
import Tables from "../../components/Table/Tables"
import { Button, Row, Col, Form, Input, Checkbox, Tabs, Tooltip, Popconfirm, Modal, Divider } from 'antd';
import { CloseCircleOutlined, DeleteOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { styleColumns, logColumns, paymentTypeColumns } from "../../utils/cols";
import notification from '../../utils/notification';
import { ReadCookie } from '../../utils/readCookie';
import "../Style/style.css";
import { TabTitle } from '../../utils/GeneralFunction';
const { TabPane } = Tabs;
export default function PaymentType({ token }) {
    TabTitle("Payment Type")
    const menuId = ReadCookie('menuId')
    const dCompanyId = ReadCookie('defaultCompany')
    const paymentTypeNameInput = useRef(null);
    const [visible, setVisible] = useState(false);
    const [form] = Form.useForm();
    const [paymentTypeObj, setPaymentTypeObj] = useState(null);
    const dispatch = useDispatch();
    const { paymentType, getDemoDefaultCompany, companyAccess, companyAll, log, userRights } = useSelector(state => state)

    
    const [minimize, setMinimize] = useState(false);
    const [check, setCheck] = useState(false);
    const [paymentTypeName, setPaymentTypeName] = useState('');
    const [disable, setDisable] = useState(false);
    const [formState, setFormState] = useState('view');


    useEffect(() => {
        dispatch(getPaymentType(token, menuId))
        dispatch(getLog(token, { admin_payment_type: "admin_payment_type" }))
    }, [token])

    useLayoutEffect(() => {
        dispatch(getRights(token, menuId)).then((res) => {
            if (res.type === "GET_USER_RIGHTS_SUCCESS") {
                if (!res?.payload.can_add) {
                    setFormState("table")
                }
            }
        });
    }, [])

    useEffect(() => {

        if (paymentTypeObj) {

            setFormState('view')
            form.setFieldsValue({
                id: paymentTypeObj.payment_type_id,
                paymentType: paymentTypeObj.payment_type_name,
                active: paymentTypeObj.is_active
            });
            setCheck(paymentTypeObj.is_active)

            setPaymentTypeName(paymentTypeObj.payment_type_name)

            dispatch(getCompanyAccess(token, paymentTypeObj.payment_type_id, menuId))

        }
    }, [paymentTypeObj]);

    useEffect(() => {
        if (paymentTypeName === '') {
            setDisable(true)
        } else {
            setDisable(false)
        }
    }, [paymentTypeName])

    const showModal = () => {
        dispatch(getAllCompany(token));
        setVisible(true);
    };

    const handleOk = () => {
        setVisible(false);
    };

    const handleCancel = () => {
        setVisible(false);
    };
    const handleTab = (key) => {

    }

    const formSave = () => {
        if (formState === "add") {
            dispatch(addPaymentType(token, { paymentTypeName, check }, menuId)).then((res) => {
                notification("success", "Payment Created", "Payment created successfully!");
                if (res.type === "ADD_PAYMENT_TYPE_ERROR") {
                    notification("error", res?.payload?.request?.statusText, res?.payload?.data?.data)
                }
                if (res.type === "ADD_PAYMENT_TYPE_SUCCESS") {
                    let data = {

                        company_id: dCompanyId
                    }
                    dispatch(createCompanyAccess(token, data, res?.payload?.payment_type_id, menuId)).then((res) => dispatch(getCompanyAccess(token, paymentTypeObj.payment_type_id, menuId)))
                    form.setFieldsValue({
                        style: ""
                    })
                    setFormState("")
                    
                    setPaymentTypeName("")
                    setPaymentTypeObj(null)
                }
                dispatch(getPaymentType(token, menuId))

            })

        }

        if (formState === "edit") {
            dispatch(updatePaymentType(token, { paymentTypeName, check }, paymentTypeObj.payment_type_id)).then((res) => {
                if (res.type === "UPDATE_PAYMENT_TYPE_ERROR") {
                    notification("error", "Update", "Something went wrong")
                }
                if (res.type === "UPDATE_PAYMENT_TYPE_SUCCESS") {
                    notification("success", "Payment Updated", "Record updated successfully")
                }
                dispatch(getPaymentType(token, menuId))
            })
        }
    }

    const handleDelete = () => {
        if (paymentTypeObj) {

            dispatch(deletePaymentType(token, paymentTypeObj.payment_type_id)).then((res) => {
                if (res.type === "DELETE_PAYMENT_TYPE_SUCCESS") {
                    notification("success", "Payment Deleted", "Payment deleted successfully!");
                }
                if (res.type === "DELETE_PAYMENT_TYPE_ERROR") {
                    notification("error", res?.payload?.request?.statusText, res?.payload?.data?.data);
                }
                form.setFieldsValue({
                    paymentType: ""
                })
                
                setPaymentTypeName("")
                setPaymentTypeObj(null)
                setFormState('')
                dispatch(getPaymentType(token, menuId))

            })
        }
    }

    useEffect(() => {
        if (formState === "add") {
            dispatch(getOneDefaultCompany(token, dCompanyId));
            paymentTypeNameInput.current.focus();
            form.setFieldsValue({
                paymentType: ""
            })
            setCheck(true)
            setPaymentTypeName("")
            setPaymentTypeObj(null)
        }
    }, [formState]);

    const filterReset = () => {
        setFormState("view")
    }
    return (
        <div className='styleContainer' >
            <div className='styleBody h-100'  >
                <PageHeader title={"Payment Type"} minimize={setMinimize} check={minimize} />
                {
                    !minimize &&
                    <div className='bodyStyle' >
                        <div className='crudDiv' ><CrudButtons rights={userRights?.payload} reset={filterReset} formType={formState} save={formSave} disable={disable} formState={setFormState} onDelete={handleDelete} /></div>

                        <div className='styleContent' >
                            <Row className='tables-main-row-new' >
                                <Col xs={24} sm={24} md={10} lg={8} xl={6}>
                                    <div className='styleTable' >
                                        <Tables type="payment" paymentTypeObj={setPaymentTypeObj} scrollX={"20rem"} scroll={602} perPage={25} source={paymentType?.payload?.data} col={paymentTypeColumns} paginate load={paymentType.loading} />
                                    </div>
                                </Col>
                                <Col xs={24} sm={24} md={14} lg={16} xl={18}>
                                    <div className='styleForm'  >
                                        <Form
                                            form={form}
                                            name="basic"
                                            labelCol={{ span: 8 }}
                                        >
                                            <div className='customRow styleFormUpper ' >
                                                {paymentTypeObj !== null && !(formState === "add") &&
                                                    <div className='customCols slc'>
                                                        <Form.Item
                                                            label="ID"
                                                            name="id"
                                                            labelCol={{ span: 10 }}
                                                            wrapperCol={{ span: 14 }}
                                                            initialValue={form.getFieldValue().id}
                                                        >
                                                            <Input size='small' className='styleInput id-field-style' readOnly />

                                                        </Form.Item>
                                                    </div>
                                                }
                                                <div className='customCols slc'>
                                                    <Form.Item
                                                        label="PaymentType"
                                                        name="paymentType"
                                                        labelCol={{ span: 10 }}
                                                        wrapperCol={{ span: 14 }}
                                                        initialValue={form.getFieldValue().paymentType}
                                                    >

                                                        <Input size='small' disabled={!(formState === "add" || formState === "edit")} ref={paymentTypeNameInput} onChange={(e) => setPaymentTypeName(e.target.value)} className='styleInput' />
                                                    </Form.Item>
                                                </div>
                                                <div className='customCols slc'>
                                                    <Form.Item
                                                        label="Active"
                                                        name="active"
                                                        labelCol={{ span: 10 }}
                                                        wrapperCol={{ span: 14 }}
                                                    >
                                                        <Checkbox disabled={!(formState === "add" || formState === "edit")} checked={check} onChange={(e) => setCheck(e.target.checked)} />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Form>
                                        <div className='modalStyle' >

                                        </div>
                                        <Divider />
                                        <div className='styleTab' >
                                            <Tabs type='card' tabBarGutter={2} defaultActiveKey="1" centered >

                                                <TabPane tab="Log Info" key="logs"  >
                                                    <div className='tabs' >
                                                        <Tables scrollX={"50rem"} type="company" scroll={360} perPage={15} source={log?.payload?.data} col={logColumns} load={log.loading} paginate />
                                                    </div>
                                                </TabPane>
                                            </Tabs>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                }

            </div>
            {
                minimize &&
                <div className='styleFooter' >
                    <Button className='minimize-button-style' onClick={() => setMinimize(false)}  >Style</Button>
                </div>
            }
        </div >
    )
}