import {
    GET_ORDER_STATUS_REQUEST,
    GET_ORDER_SUCCESS,
    GET_ORDER_ERROR,
    GET_CUSTOMER_TYPE_REQUEST,
    GET_CUSTOMER_TYPE_SUCCESS,
    GET_CUSTOMER_TYPE_ERROR,

} from "./types"
import axios from "axios";
import { BASEURL } from "../../constant/config.js"



























export const loadOrderStatus = (token) => dispatch => {
    dispatch({ type: GET_ORDER_STATUS_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/orderStatus`,
        headers: {
            Accept: "application/json",
            'Authorization': 'Bearer ' + token
        }
    })
        .then(response => {

            return dispatch({ type: GET_ORDER_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: GET_ORDER_ERROR,
                payload: error.response,
            });
        })
}





























