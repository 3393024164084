import { GET_LOG_ERROR, GET_LOG_REQUEST, GET_LOG_SUCCESS } from "../actions/types";

export default function (state = [], action) {
	const { type, payload } = action;
	switch (type) {
		case GET_LOG_REQUEST:
			return {
				loading: true,
			};
		case GET_LOG_SUCCESS:
			return {
				...state,
				loading: false,
				payload,
			};
		case GET_LOG_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};
		default:
			return state;
	}
}
