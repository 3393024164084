import {
    GET_RETURN_REGION_STATE_REPORT_CUSTOMER_ERROR,
    GET_RETURN_REGION_STATE_REPORT_CUSTOMER_REQUEST,
    GET_RETURN_REGION_STATE_REPORT_CUSTOMER_SUCCESS,
} from "../actions/types";

export const getReturnRegionStateCustomer = (state = [], action) => {
    const { type, payload } = action;
    switch (type) {

        case GET_RETURN_REGION_STATE_REPORT_CUSTOMER_REQUEST:
            return {
                loading: true,
            };
        case GET_RETURN_REGION_STATE_REPORT_CUSTOMER_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_RETURN_REGION_STATE_REPORT_CUSTOMER_ERROR:
            return {
                ...state,
                loading: false
            };
        default:
            return state;
    }
}