import {

     GET_REGION_REQUEST,
     GET_REGION_SUCCESS,
     GET_REGION_ERROR, 
  } from "../actions/types";
  
  export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
      case GET_REGION_REQUEST:
        return {
          loading: true,
        };
      case GET_REGION_SUCCESS:
        return {
          ...state,
          loading: false,
          payload,
        };
      case GET_REGION_ERROR:
        return {
          ...state,
          loading: false,
          payload,
        };

      default:
        return state;
    }
  }
  