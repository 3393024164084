import React, { useState, useEffect, useRef, useLayoutEffect } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { getOneDefaultCompany } from "../../redux/actions/defaultCompany";
import { getCompanyAccess, updateCompanyAccess, deleteCompanyAccess, getAllCompany, createCompanyAccess } from "../../redux/actions/companyAccess"
import { getLog } from "../../redux/actions/log.action"
import { getRights } from "../../redux/actions/userRights.action";

import PageHeader from "../../components/PageHeader/PageHeader"
import CrudButtons from "../../components/CrudButtons/CrudButtons";
import { ReadCookie } from "../../utils/readCookie";
import Tables from "../../components/Table/Tables"
import { Button, Row, Col, Form, Input, Checkbox, Tabs, Tooltip, Popconfirm, Modal, Divider } from 'antd';
import { CloseCircleOutlined, DeleteOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { logColumns, shipStatusColumns } from "../../utils/cols";
import notification from '../../utils/notification';
import "../Style/style.css";
import { addShipStatus, deleteShipStatus, getShipStatus, updateShipStatus } from '../../redux/actions/shipStatus.action';
import {TabTitle} from "../../utils/GeneralFunction";
const { TabPane } = Tabs;
export default function ShipStatus({ token }) {
    TabTitle("Ship Status")
    const menuId = ReadCookie('menuId')
    const dCompanyId = ReadCookie('defaultCompany')
    const ShipStatusNameInput = useRef(null);
    const [visible, setVisible] = useState(false);
    const [form] = Form.useForm();
    const [shipStatusObj, setShipStatusObj] = useState(null);
    const dispatch = useDispatch();
    const { getDemoDefaultCompany, companyAccess, companyAll, log, userRights, shipStatus } = useSelector(state => state)
    
    const [check, setCheck] = useState(false);
    const [shipStatusName, setShipStatusName] = useState('');
    const [disable, setDisable] = useState(false);
    const [formState, setFormState] = useState('view');

    const handleEdit = (record, type) => {
        if (type === "active") {
            dispatch(updateCompanyAccess(token, record)).then((res) => dispatch(getCompanyAccess(token, shipStatusObj.ship_status_id, menuId)));
        }
        if (type === "delete") {
            dispatch(deleteCompanyAccess(token, record)).then((res) => dispatch(getCompanyAccess(token, shipStatusObj.ship_status_id, menuId)));
        }
    }

    const createAssignCompany = (record) => {
        dispatch(createCompanyAccess(token, record, shipStatusObj.ship_status_id, menuId)).then((res) => dispatch(getCompanyAccess(token, shipStatusObj.ship_status_id, menuId)))
    }

    const companyCol = [
        { title: 'Company ID', dataIndex: 'company_id', key: 'company_id', },
        { title: 'Company Name', dataIndex: 'company_name', key: 'company_name', },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <>
                    <Tooltip title="Minimize" color="#fbfbfb"  >
                    </Tooltip>
                    {
                        record.is_active ?
                            <Tooltip title="Activate" color="#fbfbfb"  ><CheckCircleOutlined className='color-green' onClick={() => handleEdit(record, "active")} /></Tooltip> : <Tooltip title="Deactivate" color="#fbfbfb"  ><CloseCircleOutlined className="color-red" onClick={() => handleEdit(record, "active")} /></Tooltip>}
                    {
                        dCompanyId === record.company_id.toString() ?
                            <Tooltip title="Default company can't be deleted" color="#fbfbfb"  >
                                <DeleteOutlined className='delete-Button' disabled twoToneColor="red" />
                            </Tooltip> :
                            <Tooltip title="delete" color="#fbfbfb"  >
                                <Popconfirm placement="top" title={"Are you sure to delete this?"} onConfirm={() => handleEdit(record, "delete")} okText="Yes" cancelText="No">
                                    <DeleteOutlined className='delete-Button' twoToneColor="red" />
                                </Popconfirm>
                            </Tooltip>
                    }
                </>
            ),
        },
    ];

    const companyAccessCol = [
        { title: 'ID', dataIndex: 'company_id', key: 'company_id', },
        { title: 'Company Name', dataIndex: 'company_name', key: 'company_name', },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <>
                    {
                        companyAccess?.payload?.data?.find(m => m.company_id === record?.company_id) ?
                            <>
                                <Tooltip title="Already assigned" color="#fbfbfb"  >
                                    <Button className='modalActionBTN' disabled shape='circle' icon={<CheckCircleOutlined className="color-red" />} />
                                </Tooltip>
                            </> :

                            <Tooltip title="Click to assign" color="#fbfbfb"  >
                                <Button className='modalActionBTN' onClick={() => createAssignCompany(record)} shape='circle' icon={<CloseCircleOutlined className='color-green' />} />
                            </Tooltip>
                    }
                </>
            ),
        },
    ];

    useEffect(() => {
        dispatch(getShipStatus(token, menuId))
    }, [token]);

    useLayoutEffect(() => {
        dispatch(getRights(token, menuId)).then((res) => {
            if (res.type === "GET_USER_RIGHTS_SUCCESS") {
                if (!res?.payload.can_add) {
                    setFormState("table")
                }
            }
        });
    }, [])

    useEffect(() => {

        if (shipStatusObj) {
            setFormState('view')
            form.setFieldsValue({
                id: shipStatusObj.ship_status_id,
                shipStatus: shipStatusObj.ship_status_name,
                active: shipStatusObj.is_active
            });
            setShipStatusName(shipStatusObj.ship_status_name)
            setCheck(shipStatusObj.is_active)
            dispatch(getCompanyAccess(token, shipStatusObj.ship_status_id, menuId))

        }
    }, [shipStatusObj]);

    useEffect(() => {
        if (shipStatusName === '') {
            setDisable(true)
        } else {
            setDisable(false)
        }
    }, [shipStatusName])

    const showModal = () => {
        dispatch(getAllCompany(token));
        setVisible(true);
    };

    const handleOk = () => {
        setVisible(false);
    };

    const handleCancel = () => {
        setVisible(false);
    };

    const handleTab = (key) => {
        if (key === "logs") {
            
            dispatch(getLog(token, { inv_fabric: "inv_fabric" }))
        }
    }

    const formSave = () => {
        if (formState === "add") {
            dispatch(addShipStatus(token, { shipStatusName, check }, menuId)).then((res) => {

                if (res.type === "ADD_SHIPSTATUS_ERROR") {
                    notification("error", res?.payload?.data, "Adding Ship Status Failed")
                }
                if (res.type === "ADD_SHIPSTATUS_SUCCESS") {
                    let data = {
                        company_id: dCompanyId
                    }
                    dispatch(createCompanyAccess(token, data, res?.payload.ship_status_id, menuId)).then((res) => dispatch(getCompanyAccess(token, shipStatusObj.ship_status_id, menuId)))
                    notification("success", res?.payload?.data, "Ship Status created successfully!");
                    form.setFieldsValue({
                        shipStatus: ""
                    })
                    setFormState("")
                    
                    setShipStatusName("")
                    setShipStatusObj(null)
                }
                dispatch(getShipStatus(token, menuId))
            })
        }
        if (formState === "edit") {
            dispatch(updateShipStatus(token, { shipStatusName, check }, shipStatusObj.ship_status_id)).then((res) => {
                if (res.type === "UPDATE_SHIPSTATUS_ERROR") {
                    notification("error", "Update", "Something went wrong")
                }
                if (res.type === "UPDATE_SHIPSTATUS_SUCCESS") {
                    notification("success", "Ship Status Update", "Record updated successfully")
                }
                dispatch(getShipStatus(token, menuId))
            })


        }
    }

    const handleDelete = () => {
        if (shipStatusObj) {
            dispatch(deleteShipStatus(token, shipStatusObj.ship_status_id)).then((res) => {
                if (res.type === "DELETE_SHIPSTATUS_SUCCESS") {
                    notification("success", "Ship Status Deleted", "Ship Status deleted successfully!");
                }
                if (res.type === "DELETE_SHIPSTATUS_ERROR") {
                    notification("error", res?.payload?.request?.statusText, res?.payload?.data?.data);
                }
                form.setFieldsValue({
                    shipStatus: ""
                })
                
                setShipStatusName("")
                setShipStatusObj(null)
                setFormState('')
                dispatch(getShipStatus(token, menuId))

            })
        }
    }

    useEffect(() => {
        if (formState === "add") {
            dispatch(getOneDefaultCompany(token, dCompanyId));
            ShipStatusNameInput.current.focus();
            form.setFieldsValue({
                shipStatus: ""
            })
            setCheck(true)
            setShipStatusName("")
            setShipStatusObj(null)
        }
    }, [formState]);

    const filterReset = () => {
        setFormState("view")
    }

    return (
        <div className='styleContainer'>
            <div className='styleBody h-100'  >
                <PageHeader title={"Ship Status"} />

                <div className='bodyStyle' >
                    <div className='crudDiv' ><CrudButtons rights={userRights?.payload} reset={filterReset} formType={formState} save={formSave} disable={disable} formState={setFormState} onDelete={handleDelete} /></div>
                    <div className='styleContent' >
                        <Row className='tables-main-row-new' >
                            <Col xs={24} sm={24} md={10} lg={8} xl={6}>
                                <div className='styleTable' >
                                    <Tables type="shipStatus" shipStatusObj={setShipStatusObj} scrollX={"20rem"} scroll={602} perPage={25} source={shipStatus?.payload?.data} col={shipStatusColumns} paginate load={shipStatus.loading} />
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={14} lg={16} xl={18}>
                                <div className='styleForm'  >
                                    <Form
                                        form={form}
                                        name="basic"
                                        labelCol={{ span: 8 }}
                                    >
                                        <div className='customRow styleFormUpper ' >
                                            {shipStatusObj !== null && !(formState === "add") &&
                                                <div className='customCols slc'>
                                                    <Form.Item
                                                        label="ID"
                                                        name="id"
                                                        initialValue={form.getFieldValue().id}
                                                        labelCol={{ span: 10 }}
                                                        wrapperCol={{ span: 14 }}
                                                    >
                                                        <Input size='small' className='styleInput id-field-style' readOnly />
                                                    </Form.Item>
                                                </div>
                                            }
                                            <div className='customCols slc'>
                                                <Form.Item
                                                    label="Ship Status"
                                                    name="shipStatus"
                                                    labelCol={{ span: 10 }}
                                                    wrapperCol={{ span: 14 }}
                                                    initialValue={form.getFieldValue().shipStatus}
                                                >

                                                    <Input size='small' disabled={!(formState === "add" || formState === "edit")} ref={ShipStatusNameInput} onChange={(e) => setShipStatusName(e.target.value)} className='styleInput' />
                                                </Form.Item>
                                            </div>
                                            <div className='customCols slc'>
                                                <Form.Item
                                                    label="Active"
                                                    name="active"
                                                    labelCol={{ span: 10 }}
                                                    wrapperCol={{ span: 14 }}
                                                >
                                                    <Checkbox disabled={!(formState === "add" || formState === "edit")} checked={check} onChange={(e) => setCheck(e.target.checked)} />
                                                </Form.Item>
                                            </div>
                                        </div>
                                    </Form>
                                    <div className='modalStyle' >

                                        {shipStatusObj === null ?
                                            <Tooltip title="Please select style first" color="#fbfbfb"  >
                                                <Button disabled={shipStatusObj === null ? true : false} className='' onClick={showModal} >Assign Companies</Button>
                                            </Tooltip>
                                            : <Button type="primary" className='modalButton' onClick={showModal} >Assign Companies</Button>}

                                    </div>
                                    <Divider />
                                    <div className='styleTab' >
                                        <Tabs type='card' tabBarGutter={2} defaultActiveKey="1" centered onChange={(key) => handleTab(key)}>
                                            <TabPane tab="Companies" key="1">
                                                <div className='tabs' >
                                                    <Tables
                                                        type="company" scroll={340} scrollX={"25rem"}
                                                        source={(formState === "add") ? getDemoDefaultCompany?.payload?.data : (formState === "edit" || formState === "view" ? companyAccess?.payload?.data : [])}
                                                        loading={(formState === "add") ? getDemoDefaultCompany.loading : companyAccess.loading} col={companyCol} />
                                                </div>
                                            </TabPane>
                                            <TabPane tab="Log Info" key="logs"  >
                                                <div className='tabs' >
                                                    <Tables type="company" scrollX={"50rem"} scroll={360} perPage={15} source={log?.payload?.data} col={logColumns} load={log.loading} paginate />
                                                </div>
                                            </TabPane>
                                        </Tabs>
                                    </div>
                                    <div className='modalForm' >
                                        <Modal
                                            title="Assign Companies"
                                            visible={visible}
                                            onOk={handleOk}
                                            onCancel={handleCancel}
                                            footer={null}
                                        >
                                            <Tables type="companyAccess" scroll={334} perPage={12} source={companyAll?.payload?.data} col={companyAccessCol} load={companyAll.loading} />
                                        </Modal>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </div >
    )
}