import {
  GET_ALL_CUSTOMER_VENDOR_ERROR,
  GET_ALL_CUSTOMER_VENDOR_SUCCESS,
  GET_ALL_CUSTOMER_VENDOR_REQUEST,
  ADD_CUSTOMER_VENDOR_REQUEST,
  ADD_CUSTOMER_VENDOR_SUCCES,
  ADD_CUSTOMER_VENDOR_ERROR,
  DELETE_CUSTOMER_VENDOR_REQUEST,
  DELETE_CUSTOMER_VENDOR_SUCCESS,
  DELETE_CUSTOMER_VENDOR_ERROR,
  UPDATE_CUSTOMER_VENDOR_REQUEST,
  UPDATE_CUSTOMER_VENDOR_SUCCESS,
  UPDATE_CUSTOMER_VENDOR_ERROR,
} from "./types";
import axios from "axios";
import { BASEURL } from "../../constant/config.js";

export const loadCustomerVendor = (token, customerCode, regionID, customerTypeID, salePersonID, paymentTerm, customerName, shippingMethodID, freightTerm, shippingAccount, resalecertificate, orderStatusID, email, billAddress, apfirstname, aplastname, active, ishold, customerid) => (dispatch) => {
  dispatch({ type: GET_ALL_CUSTOMER_VENDOR_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}getAll/Customervendor`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: {
      
      
      
      
      customer_name: customerName ? customerName : null,
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      
    }
  })
    .then((response) => {
      return dispatch({
        type: GET_ALL_CUSTOMER_VENDOR_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_ALL_CUSTOMER_VENDOR_ERROR,
        payload: [],
      });
    });
};




























































































































































