import React, { useState, useEffect, useLayoutEffect } from "react";
import { useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { deleteSaleOrderAdjustmentHeader, getOneSaleOrderAdjustmentHeader } from "../../redux/actions/saleOrderAdjustment.action";
import CrudButtons from "./components/CrudButtons";
import SaleOrderAdjustmentNewForm from "./components/Form/saleOrderAdjustmentNewForm";
import PageHeader from "../../components/PageHeader/PageHeader";
import { Button } from "antd";
import { ReadCookie } from "../../utils/readCookie";
import notification from "../../utils/notification";

import "../../screens/Product/product.css";
import "../Style/style.css";
import Loader from "react-loader-spinner";
import { getOneOrderShipmentHeader } from "../../redux/actions/orderShipment.action";
import { getRights } from "../../redux/actions/userRights.action";
import { TabTitle } from "../../utils/GeneralFunction";

export default function SaleOrderAdjustmentNew({ token }) {
  TabTitle("Sale Order Adjustment")
  const { id, customerCode } = useParams();
  const dispatch = useDispatch();
  const dCompanyId = ReadCookie("defaultCompany");
  const { saleOrderAdjustmentFormData, log, salesOrder } = useSelector((state) => {
    return state;
  });
  const [minimize, setMinimize] = useState(false);
  const [formState, setFormState] = useState("view");
  const [formData, setFormData] = useState(null);
  const saleOrderID = id;
  const [flag, setFlag] = useState(false);

  const [companyId, setCompanyId] = useState(ReadCookie("defaultCompany"));
  const [disable, setDisable] = useState(false);
  const [submit, setSubmit] = useState(false);
  const queryParams = new URLSearchParams(window.location.search);
  const mode = queryParams.get("mode");
  const ID = queryParams.get("order_id");
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [userRights, setUserRights] = useState({});

  const[menuId, setMenuId] = useState(54);


  
  const filterReset = () => {
    setFormState("formView");
  };

  
  useLayoutEffect(() => {
    dispatch(getRights(token, menuId)).then((res) => {
      if (res.type === "GET_USER_RIGHTS_SUCCESS") {
        setUserRights(res?.payload)
      }
    });
  }, [token])

  
  useEffect(() => {
    if (mode === "edit") {
      dispatch(getOneSaleOrderAdjustmentHeader(token, ID)).then((res) => setFormData(res?.payload));
      setFormState("formView");
    }
    else if (mode === "add") {
      dispatch(getOneOrderShipmentHeader(token, ID)).then((res) => setFormData(res?.payload));
      setFormState("add");
    }
  }, [mode])

  
  const handleDelete = () => {
    setLoading(true);
    dispatch(deleteSaleOrderAdjustmentHeader(token, ID)).then((res) => {
      if (res.type === "DELETE_SALEORDERADJUSTMENTHEADER_SUCCESS") {
        setLoading(false);
        setFlag(true);
        notification("success", "Sale Order deleted", res.payload);
        history.push("/sale-order-adjustment-new-list");
      }
    });
  };

  
  const formSave = () => {
    setSubmit(true);
  };

  return (
    <>
      <div className="styleContainer">
        <div className="styleBody h-100">
          <PageHeader
            title={"Sales Order Adjustment"}
            minimize={setMinimize}
            check={minimize}
          />


          <div className="bodyStyle">

            {!formData || loading ?
              <div className='loaderDiv' >
                <Loader
                  type="Grid"
                  color="#212121"
                  height={40}
                  width={40}
                />
              </div> :
              null}
            <div className="crudDiv">
              <CrudButtons
                reportTittle="Sale Order"
                reportName="rpt_sale_order_print.rptdesign"
                id={id}
                customerCode={customerCode}
                name={"saleOrder"}
                reset={filterReset}
                userRights={userRights}
                formType={formState}
                save={formSave}
                disable={disable}
                formState={setFormState}
                onDelete={handleDelete}
                saleOrderID={saleOrderID}
                ID={ID}
                formData={formData}
              />
              <div className="mainProductContainer">
                <SaleOrderAdjustmentNewForm
                  dCompany={dCompanyId}
                  defaultCompany={companyId}
                  companyId={setCompanyId}
                  submit={setSubmit}
                  val={submit}
                  formData={formState === "add" || formState === "formView" || formState === "edit" ? formData : null}
                  formState={formState}
                  data={saleOrderAdjustmentFormData}
                  changeState={setFormState}
                  token={token}
                  checkFlag={flag}
                  setFlag={setFlag}
                  logs={log}
                  saleOrderID={ID}
                  customerCode={customerCode}
                  mode={mode}
                  setDisable={setDisable}
                />
              </div>
            </div>
          </div>
        </div>
        {minimize && (
          <div className="styleFooter">
            {" "}
            <Button
              className='minimize-button-style'
              onClick={() => setMinimize(false)}
            >
              {" "}
              Sales Order{" "}
            </Button>{" "}
          </div>
        )}
      </div>
    </>
  );
}
