import {
  GET_CHART_ACCOUT_REQUEST,
  GET_CHART_ACCOUT_SUCCESS,
  GET_CHART_ACCOUT_ERROR,
} from "./types";
import { BASEURL } from "../../constant/config.js";

const axios = require("axios");


export const getChartOfAccount = (token) => (dispatch) => {
  dispatch({ type: GET_CHART_ACCOUT_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}getAll/ChartOfAccount`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {
      return dispatch({ type: GET_CHART_ACCOUT_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      return dispatch({
        type: GET_CHART_ACCOUT_ERROR,
        payload: error.response,
      });
    });
};
