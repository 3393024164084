import {
  GET_SALES_REPORT_ORDER_ITEM_REQUEST,
  GET_SALES_REPORT_ORDER_ITEM_SUCCESS,
  GET_SALES_REPORT_ORDER_ITEM_ERROR,

  } from "../actions/types";

  export const salesReportOrderItem = (state = [], action) => {
    const { type, payload } = action;
    switch (type) {
      case GET_SALES_REPORT_ORDER_ITEM_REQUEST:
        return {
          loading: true,
        };
      case GET_SALES_REPORT_ORDER_ITEM_SUCCESS:
        return {
          ...state,
          loading: false,
          payload,
        };
      case GET_SALES_REPORT_ORDER_ITEM_ERROR:
        return {
          ...state,
          loading: false,
          payload,
        };
  
      default: {
        return state;
      }
    }
  };
  

  