import {
    GET_SUPERVISOR_REQUEST,
    GET_SUPERVISOR_SUCCESS,
    GET_SUPERVISOR_ERROR,

} from "../types"
import axios from "axios";
import { BASEURL } from "../../../constant/config.js"


///GET CUSTOMER TYPE
export const loadSupervisor = (token) => dispatch => {
    dispatch({ type: GET_SUPERVISOR_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getall/user`,
        headers: {
            Accept: "application/json",
            'Authorization': 'Bearer ' + token
        }
    })
        .then(response => {

            return dispatch({ type: GET_SUPERVISOR_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            if (error.response.data.data === "No Record Found !") {
                return dispatch({
                    type: GET_SUPERVISOR_ERROR,
                    payload: [],
                });
            }
            return dispatch({
                type: GET_SUPERVISOR_ERROR,
                payload: error.response,
            });
        })
};

