import { GET_SCM_TARGET_MONTH_REQUEST,GET_SCM_TARGET_MONTH_ERROR,GET_SCM_TARGET_MONTH_SUCCESS } from "./types";
import { BASEURL } from "../../constant/config.js";
import axios from "axios";






export const getScmTargetMonth = (token, id) => (dispatch) => {
    dispatch({ type: GET_SCM_TARGET_MONTH_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/scmTargetMonth`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { id }
    })
        .then((response) => {
            return dispatch({ type: GET_SCM_TARGET_MONTH_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: GET_SCM_TARGET_MONTH_ERROR,
                payload: error.response,
            });
        });
};
