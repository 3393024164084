import {
  GET_SALESPERSONLIST_ERROR,
  GET_SALESPERSONLIST_SUCCESS,
  GET_SALESPERSONLIST_REQUEST,
} from "../types.js";
import axios from "axios";
import { BASEURL } from "../../../constant/config.js";

export const salePersonList = (token, data) => (dispatch) => {
  dispatch({ type: GET_SALESPERSONLIST_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}getAll/salesPersonInfo`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: {
      employeeId : data?.employeeId,
      name : data?.name,
      email : data?.email,
      hireDate: null,
      is_salesperson : data?.is_salesperson == 'All' ? null : data?.is_salesperson,
    }
  })
    .then((response) => {
      return dispatch({
        type: GET_SALESPERSONLIST_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_SALESPERSONLIST_ERROR,
        payload: error.response,
      });
    });
};

