import { Button, Tooltip, Popconfirm, Divider, Tag } from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined, FileDoneOutlined, UndoOutlined, SearchOutlined } from "@ant-design/icons";
import "../../../components/CrudButtons/crud.css";
import { useHistory } from "react-router-dom";

export default function CrudButtons({ save, disable, formState, formType, onDelete, reset, name, flag, customerRefundId, id, customerCode, formData, userRights, transactionLock }) {
	const history = useHistory();
	const confirm = () => {
		onDelete();
	};
	return (
		<>
			<div className="crudContainer">
				<div>
					{formType === "" ? null : (
						<div className="buttons-div">
							<Tag color={"green"} key={1}>
								{formType.toUpperCase()}
							</Tag>
							{
								transactionLock && <Tag color={"red"} key={2}>LOCKED</Tag>
							}
							{formType !== "view" && <Button onClick={() => reset()} className="iconBtn" shape="circle" icon={<UndoOutlined className="fspx-12" />} />}
						</div>
					)}
				</div>
				<div>
					<Tooltip placement="bottom" title="Search" color="#fbfbfb">
						<Button
							
							onClick={() => history.push("/customer-refund/list")} className="iconBtn crudBTN" shape="circle" icon={<SearchOutlined />} />
					</Tooltip>
					{formType !== "add" ? <>
						{(formType === "view" || formType === "formView") && (<Tooltip placement="bottom" title="Add" color="#fbfbfb">
							<Button
								disabled={formType === "add" ? true : false || userRights?.can_add === true ? false : true}
								onClick={() => {
									history.push("/customer-refund/add?mode=add");
								}}
								className="iconBtn crudBTN"
								shape="circle"
								icon={<PlusOutlined />}
							/>
						</Tooltip>)}
						{(formType === "view" || formType === "formView") && (<Tooltip placement="bottom" title="Edit" color="#fbfbfb">
							<Button
								disabled={
									(disable ||
									formType === "view" ||
									formType === "add" ||
									(userRights?.can_edit === true ? false : true)) || transactionLock
								  }
								onClick={() => formState("edit")}
								className="iconBtn crudBTN"
								shape="circle"
								icon={<EditOutlined />}
							/>
						</Tooltip>)}
						<Tooltip placement="bottom" title="Delete" color="#fbfbfb">
							{userRights?.can_delete === false ? (
								<Button className="iconBtn crudBTN" shape="circle" icon={<DeleteOutlined />} disabled={true} />
							) : (formType === "view" || formType === "formView") && (
								<Popconfirm
									disabled={(disable || formType === "add" || formType === "view") || transactionLock}
									placement="topLeft"
									title={`Are you sure to delete this ${customerRefundId}?`}
									onConfirm={confirm}
									okText="Yes"
									cancelText="No"
								>
									<Button disabled={(disable || formType === "add" || formType === "view" || userRights?.can_delete === true ? false : true) || transactionLock} className="iconBtn crudBTN" shape="circle" icon={<DeleteOutlined />} />
								</Popconfirm>
							)}
						</Tooltip>
					</> : null}
					<Tooltip placement="bottom" title="Save" color="#fbfbfb">
						<Button
							disabled={
								!(
								(formType === "add" || formType === "edit") &&
								(name === "product"
									? formType === "edit"
									? true
									: flag
										? true
										: false
									: !disable)
								) || transactionLock
							}
							
							onClick={() => save()}
							className="iconBtn crudBTN"
							shape="circle"
							icon={<FileDoneOutlined />}
						/>
					</Tooltip>
				</div>
			</div>
			<Divider />
		</>
	);
}
