import {
	GET_ONE_BILL_PAYMENT_REQUEST,
	GET_ONE_BILL_PAYMENT_SUCCESS,
	GET_ONE_BILL_PAYMENT_ERROR,
	GET_BILL_PAYMENT_REQUEST,
	GET_BILL_PAYMENT_SUCCESS,
	GET_BILL_PAYMENT_ERROR,
	ADD_BILL_PAYMENT_REQUEST,
	ADD_BILL_PAYMENT_SUCCESS,
	ADD_BILL_PAYMENT_ERROR,
	UPDATE_BILL_PAYMENT_REQUEST,
	UPDATE_BILL_PAYMENT_SUCCESS,
	UPDATE_BILL_PAYMENT_ERROR,
	DELETE_BILL_PAYMENT_REQUEST,
	DELETE_BILL_PAYMENT_SUCCESS,
	DELETE_BILL_PAYMENT_ERROR
} from "../actions/types";

export default function (state = [], action) {
	const { type, payload } = action;
	switch (type) {
		case GET_ONE_BILL_PAYMENT_REQUEST:
			return {
				loading: true,
			};
		case GET_ONE_BILL_PAYMENT_SUCCESS:
			return {
				...state,
				loading: false,
				payload,
			};
		case GET_ONE_BILL_PAYMENT_ERROR:
			return {
				...state,
				loading: false
			};
		case GET_BILL_PAYMENT_REQUEST:
			return {
				loading: true,
			};
		case GET_BILL_PAYMENT_SUCCESS:
			return {
				...state,
				loading: false,
				payload,
			};
		case GET_BILL_PAYMENT_ERROR:
			return {
				...state,
				loading: false
			};
		case ADD_BILL_PAYMENT_REQUEST:
			return {
				loading: true,
			};
		case ADD_BILL_PAYMENT_SUCCESS:
			return {
				...state,
				loading: false,
				payload: [payload],
			};
		case ADD_BILL_PAYMENT_ERROR:
			return {
				...state,
				loading: false
			};
		case DELETE_BILL_PAYMENT_REQUEST:
			return {
				loading: true,
			};
		case DELETE_BILL_PAYMENT_SUCCESS:
			return {
				...state,
				loading: false,
				payload: [payload],
			};
		case DELETE_BILL_PAYMENT_ERROR:
			return {
				...state,
				loading: false
			};
		case UPDATE_BILL_PAYMENT_REQUEST:
			return {
				loading: true,
			};
		case UPDATE_BILL_PAYMENT_SUCCESS:
			return {
				...state,
				loading: false,
				payload: [payload],
			};
		case UPDATE_BILL_PAYMENT_ERROR:
			return {
				...state,
				loading: false
			};
		default:
			return state;
	}
}
