import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getOneDefaultCompany } from "../../redux/actions/defaultCompany";
import { getCompanyAccess, getAllCompany } from "../../redux/actions/companyAccess";

import { addScmFigure, getScmFigure, deleteScmFigure, updateScmFigure } from "../../redux/actions/scmFigure.action";
import { getRights } from "../../redux/actions/userRights.action";
import { getLog } from "../../redux/actions/log.action";

import CrudButtons from "../../components/CrudButtons/CrudButtons";
import PageHeader from "../../components/PageHeader/PageHeader";

import { Row, Col, Form, Input, Checkbox, Tabs, Divider } from "antd";
import { logColumns, ScmFigureColumn } from "../../utils/cols";
import Tables from "../../components/Table/Tables";
import { ReadCookie } from "../../utils/readCookie";
import notification from "../../utils/notification";
import "../Style/style.css";

const { TabPane } = Tabs;
export default function ScmFigure({ token }) {
	const menuId = ReadCookie("menuId");
	const dCompanyId = ReadCookie("defaultCompany");
	const scmFigureNameInput = useRef(null);
	const [form] = Form.useForm();
	const [scmFigureObj, setScmFigureObj] = useState(null);
	const dispatch = useDispatch();
	const { scmFigure, log, userRights } = useSelector((state) => state);
	const [minimize, setMinimize] = useState(false);
	const [check, setCheck] = useState(false);
	const [scmFigureTypeName, setScmFigureTypeName] = useState("");
	const [disable, setDisable] = useState(false);
	const [formState, setFormState] = useState("view");

	useEffect(() => {
		dispatch(getScmFigure(token, menuId));
		dispatch(getLog(token, { scm_figure: "scm_figure" }));
	}, [token, menuId]);

	useLayoutEffect(() => {
		dispatch(getRights(token, menuId)).then((res) => {
			if (res.type === "GET_USER_RIGHTS_SUCCESS") {
				if (!res?.payload.can_add) {
					setFormState("table");
				}
			}
		});
	}, []);

	useEffect(() => {
		if (scmFigureObj) {
			setFormState("view");
			form.setFieldsValue({
				id: scmFigureObj.figure_id,
				scmFigName: scmFigureObj.figure_name,
				active: scmFigureObj.is_active,
			});
			setScmFigureTypeName(scmFigureObj.figure_name);
			setCheck(scmFigureObj.is_active);
			dispatch(getCompanyAccess(token, scmFigureObj.figure_id, menuId));
		}
	}, [scmFigureObj]);

	useEffect(() => {
		if (scmFigureTypeName === "") {
			setDisable(true);
		} else {
			setDisable(false);
		}
	}, [scmFigureTypeName]);

	const formSave = () => {
		if (formState === "add") {
			dispatch(addScmFigure(token, { scmFigureTypeName, check }, menuId)).then((res) => {
				
				if (res.type === "ADD_SCM_FIGURE_ERROR") {
					notification("error", res?.payload?.request?.statusText, res?.payload?.data?.data);
				}
				if (res.type === "ADD_SCM_FIGURE_SUCCESS") {
					notification("success", "SCM Figure", "SCM Figure created successfully!");
					
					let data = {
						company_id: dCompanyId,
					};
					
					form.setFieldsValue({
						scmFigName: "",
					});
					setFormState("");
					
					setScmFigureTypeName("");
					setScmFigureObj(null);
				}
				dispatch(getScmFigure(token, menuId));
			});
		}

		if (formState === "edit") {
			dispatch(updateScmFigure(token, { scmFigureTypeName, check }, scmFigureObj.figure_id)).then((res) => {
				if (res.type === "UPDATE_SCM_FIGURE_ERROR") {
					notification("error", "Update", "Something went wrong");
				}
				if (res.type === "UPDATE_SCM_FIGURE_SUCCESS") {
					notification("success", "Scm Figure update", "Record updated successfully");
				}
				dispatch(getScmFigure(token, menuId));
			});
		}
	};

	const handleDelete = () => {
		if (scmFigureObj) {
			dispatch(deleteScmFigure(token, scmFigureObj.figure_id)).then((res) => {


				if (res.type === "DELETE_SCM_FIGURE_SUCCESS") {
					notification("success", "SCM Figure", "Figure deleted successfully!");

				}
				else if (res.type === "DELETE_SCM_FIGURE_ERROR") {
					notification("error", res?.payload?.request?.statusText, res?.payload?.data?.data);
				}
				form.setFieldsValue({
					scmFigName: "",
				});
				dispatch(getScmFigure(token, menuId));
				
				scmFigureTypeName("");
				setScmFigureObj(null);
				setFormState("");

			});
		}
	};

	useEffect(() => {
		if (formState === "add") {
			dispatch(getOneDefaultCompany(token, dCompanyId));
			scmFigureNameInput.current.focus();
			form.setFieldsValue({
				scmFigName: "",
			});
			setCheck(true);
			setScmFigureTypeName("");
			setScmFigureObj(null);
		}
	}, [formState]);

	const filterReset = () => {
		setFormState("view");
	};
	
	return (
		<div className="styleContainer">
			<div className="styleBody h-100">
				<PageHeader title={"Scm Figure"} minimize={setMinimize} check={minimize} />
				{!minimize && (
					<div className="bodyStyle">
						<div className="crudDiv">
							<CrudButtons rights={userRights?.payload} reset={filterReset} formType={formState} save={formSave} disable={disable} formState={setFormState} onDelete={handleDelete} />
						</div>
						<div className="styleContent">
							<Row className="tables-main-row-new">
								<Col xs={24} sm={24} md={10} lg={8} xl={6}>
									<div className="styleTable">
										<Tables
											type="ScmFigureTabName"
											scmFigureObj={setScmFigureObj}
											scrollX={"20rem"}
											scroll={602}
											perPage={25}
											source={scmFigure?.payload?.data}
											col={ScmFigureColumn}
											paginate
											load={scmFigure.loading}
										/>
									</div>
								</Col>
								<Col xs={24} sm={24} md={14} lg={16} xl={18}>
									<div className="styleForm">
										<Form form={form} name="basic" labelCol={{ span: 8 }}>
											<div className="customRow styleFormUpper ">
												{scmFigureObj !== null && !(formState === "add") && (
													<div className="customCols slc">
														<Form.Item label="ID" labelCol={{ span: 10 }} wrapperCol={{ span: 14 }} name="id" initialValue={form.getFieldValue().id}>
															<Input className="styleInput id-field-style" readOnly />
														</Form.Item>
													</div>
												)}
												<div className="customCols slc">
													<Form.Item label="Figure Name" name="scmFigName" labelCol={{ span: 10 }} wrapperCol={{ span: 14 }} initialValue={form.getFieldValue().scmFigName}>
														<Input
															size="small"
															disabled={!(formState === "add" || formState === "edit")}
															ref={scmFigureNameInput}
															onChange={(e) => setScmFigureTypeName(e.target.value)}
															className="styleInput"
														/>
													</Form.Item>
												</div>
												<div className="customCols slc">
													<Form.Item label="Active" name="active" labelCol={{ span: 10 }} wrapperCol={{ span: 14 }}>
														<Checkbox disabled={!(formState === "add" || formState === "edit")} checked={check} onChange={(e) => setCheck(e.target.checked)} />
													</Form.Item>
												</div>
											</div>
										</Form>
										<Divider />
										<div className="styleTab">
											<Tabs type="card" tabBarGutter={2} defaultActiveKey="logs" centered>
												<TabPane tab="Log Info" key="logs">
													<div className="tabs">
														<Tables type="company" scrollX={"50rem"} scroll={360} perPage={15} source={log?.payload?.data} col={logColumns} load={log.loading} paginate />
													</div>
												</TabPane>
											</Tabs>
										</div>
									</div>
								</Col>
							</Row>
						</div>
					</div>
				)}
			</div>
		</div>
	);
}
