import { GET_AR_ACCOUTN_REQUEST, GET_AR_ACCOUTN_SUCCESS, GET_AR_ACCOUTN_ERROR } from "../actions/types";
export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case GET_AR_ACCOUTN_REQUEST:
            return {
                loading: true,
            };
        case GET_AR_ACCOUTN_SUCCESS:
            return {
                ...state,
                loading: false,
                payload
            };
        case GET_AR_ACCOUTN_ERROR:
            return {
                ...state,
                loading: false,
                payload,
            };
        default:
            return state;
    }
}
