import { GET_ALL_SALEORDERADJUSTMENT_REQUEST, GET_ALL_SALEORDERADJUSTMENT_SUCCESS, GET_ALL_SALEORDERADJUSTMENT_ERROR
,GET_ONE_SALEORDERADJUSTMENT_REQUEST, GET_ONE_SALEORDERADJUSTMENT_SUCCESS, GET_ONE_SALEORDERADJUSTMENT_ERROR
} from "../actions/types";
export default function (state = [], action) {
	const { type, payload } = action;
	switch (type) {
		case GET_ALL_SALEORDERADJUSTMENT_REQUEST:
			return {
				loading: true,
			};
		case GET_ALL_SALEORDERADJUSTMENT_SUCCESS:
			return {
				...state,
				loading: false,
				payload: [payload],
			};
		case GET_ALL_SALEORDERADJUSTMENT_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};

			case GET_ONE_SALEORDERADJUSTMENT_REQUEST:
			return {
				loading: true,
			};
		case GET_ONE_SALEORDERADJUSTMENT_SUCCESS:
			return {
				...state,
				loading: false,
				payload: [payload],
			};
		case GET_ONE_SALEORDERADJUSTMENT_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};

		default:
			return state;
	}
}
