import {
  GET_CONTROL_SHEET_REQUEST,
  GET_CONTROL_SHEET_SUCCESS,
  GET_CONTROL_SHEET_ERROR,
  } from "./types";
  import axios from "axios";
  import { BASEURL } from "../../constant/config.js";
  import moment from "moment";

  export const getControlSheet = (token) => (dispatch) => {
    dispatch({ type: GET_CONTROL_SHEET_REQUEST });
    return axios({
      method: "POST",
      url: `${BASEURL}getAll/controlSheet`,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        return dispatch({
          type: GET_CONTROL_SHEET_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_CONTROL_SHEET_ERROR,
          payload: [],
        });
      });
  };
  