import {
    GET_CREDIT_NOTE_APPLY_REQUEST,
    GET_CREDIT_NOTE_APPLY_SUCCESS,
    GET_CREDIT_NOTE_APPLY_ERROR,
    UPDATE_CREDIT_NOTE_APPLY_REQUEST,
    UPDATE_CREDIT_NOTE_APPLY_SUCCESS,
    UPDATE_CREDIT_NOTE_APPLY_ERROR,
    DELETE_CREDIT_NOTE_APPLY_REQUEST,
    DELETE_CREDIT_NOTE_APPLY_SUCCESS,
    DELETE_CREDIT_NOTE_APPLY_ERROR,

} from "./types";
import { BASEURL } from "../../constant/config.js";
import axios from "axios";




export const getCreaditNoteApply = (token, id) => dispatch => {
    dispatch({ type: GET_CREDIT_NOTE_APPLY_REQUEST });
    return axios({
        method: "GET",
        url: `${BASEURL}getOne/arcreditnoteinvoice/${id}`,
        headers: {
            Accept: "application/json",
            'Authorization': 'Bearer ' + token
        }
    })
        .then(response => {

            return dispatch({ type: GET_CREDIT_NOTE_APPLY_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: GET_CREDIT_NOTE_APPLY_ERROR,
                payload: error.response,
            });
        })
};




export const updateCreaditNoteApply = (token, data, id) => (dispatch) => {
    dispatch({ type: UPDATE_CREDIT_NOTE_APPLY_REQUEST })
    return axios({
        method: "PUT",
        url: `${BASEURL}update/arcreditnoteinvoice/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token
        },
        data
    }).then((response) => {

        return dispatch({ type: UPDATE_CREDIT_NOTE_APPLY_SUCCESS, payload: response.data })
    }).catch((error) => {
        return dispatch({
            type: UPDATE_CREDIT_NOTE_APPLY_ERROR,
            payload: error.response
        })
    })
}


export const deleteCreaditNoteApply = (token, data, id) => (dispatch) => {
    dispatch({ type: DELETE_CREDIT_NOTE_APPLY_REQUEST })
    return axios({
        method: "DELETE",
        url: `${BASEURL}delete/arcreditnoteinvoice/${id}`,
        headers: {
            type: "application/json",
            Authorization: "Bearer " + token
        },
        data: { data: data }
    }).then((response) => {

        return dispatch({ type: DELETE_CREDIT_NOTE_APPLY_SUCCESS, payload: response.data })
    }).catch((err) => {
        return dispatch({
            type: DELETE_CREDIT_NOTE_APPLY_ERROR,
            payload: err.response
        })
    })

}