import {
    GET_GLOBAL_SEARCH_REQUEST, GET_GLOBAL_SEARCH_SUCCESS, GET_GLOBAL_SEARCH_ERROR

} from "../actions/types";

import { BASEURL } from "../../constant/config.js";

const axios = require("axios");
export const getGloabalSearch = (token, search_string) => (dispatch) => {
    dispatch({ type: GET_GLOBAL_SEARCH_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/globalSearch`,
        headers: { Accept: "application/json", Authorization: "Bearer " + token },
        data: { search_string }
    })
        .then((response) => {
            return dispatch({ type: GET_GLOBAL_SEARCH_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            if (error.response.data.data === "No Record Found !") {
                return dispatch({
                    type: GET_GLOBAL_SEARCH_ERROR,
                    payload: [],
                });
            }
            return dispatch({
                type: GET_GLOBAL_SEARCH_ERROR,
                payload: error.response,
            });
        });
};

