import { GET_CUSTOMER_TERM_REQUEST, GET_CUSTOMER_TERM_SUCCESS, GET_CUSTOMER_TERM_ERROR } from "../actions/types"

export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case GET_CUSTOMER_TERM_REQUEST:
            return {
                loading: true,
            };
        case GET_CUSTOMER_TERM_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_CUSTOMER_TERM_ERROR:
            return {
                ...state,
                loading: false
            };

        default:
            return state;
    }
}