import { GET_PAYMENT_TERMS_REQUEST,GET_PAYMENT_TERMS_ERROR,GET_PAYMENT_TERMS_SUCCESS,ADD_PAYMENT_TERMS_ERROR,ADD_PAYMENT_TERMS_REQUEST,ADD_PAYMENT_TERMS_SUCCESS,
    UPDATE_PAYMENT_TERMS_ERROR,UPDATE_PAYMENT_TERMS_REQUEST,UPDATE_PAYMENT_TERMS_SUCCESS,DELETE_PAYMENT_TERMS_REQUEST,DELETE_PAYMENT_TERMS_SUCCESS,DELETE_PAYMENT_TERMS_ERROR } from "../actions/types";


export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case GET_PAYMENT_TERMS_REQUEST:
            return {
                loading: true,
            };
        case GET_PAYMENT_TERMS_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_PAYMENT_TERMS_ERROR:
            return {
                ...state,
                loading: false
            };
        case ADD_PAYMENT_TERMS_REQUEST:
            return {
                loading: true,
            };
        case ADD_PAYMENT_TERMS_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case ADD_PAYMENT_TERMS_ERROR:
            return {
                ...state,
                loading: false
            };
        case DELETE_PAYMENT_TERMS_REQUEST:
            return {
                loading: true,
            };
        case DELETE_PAYMENT_TERMS_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case DELETE_PAYMENT_TERMS_ERROR:
            return {
                ...state,
                loading: false
            };
        case UPDATE_PAYMENT_TERMS_REQUEST:
            return {
                loading: true,
            };
        case UPDATE_PAYMENT_TERMS_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case UPDATE_PAYMENT_TERMS_ERROR:
            return {
                ...state,
                loading: false
            };

        default:
            return state;
    }
}