import {
    GET_ALL_STYLE_WISE_SHIPPED_REPORT_REQUEST,
    GET_ALL_STYLE_WISE_SHIPPED_REPORT_ERROR,
    GET_ALL_STYLE_WISE_SHIPPED_REPORT_SUCCESS

} from "./types";
import axios from "axios";
import { BASEURL } from "../../constant/config.js";

///Post Bussiness dashboard
export const getStyleWiseShippedReport = (token, values) => (dispatch) => {
    dispatch({ type: GET_ALL_STYLE_WISE_SHIPPED_REPORT_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/shippedstylewise`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { ...values },
    })
        .then((response) => {
            return dispatch({
                type: GET_ALL_STYLE_WISE_SHIPPED_REPORT_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) => {
            dispatch({
                type: GET_ALL_STYLE_WISE_SHIPPED_REPORT_ERROR,
                payload: [],
            });
        });
};
