import { GET_SYSTEM_LOG_ERROR, GET_SYSTEM_LOG_REQUEST, GET_SYSTEM_LOG_SUCCESS } from "../actions/types";



export default function (state = [], action) {
	const { type, payload } = action;
	switch (type) {
		case GET_SYSTEM_LOG_REQUEST:
			return {
				loading: true,
			};
		case GET_SYSTEM_LOG_SUCCESS:
			return {
				...state,
				loading: false,
				payload,
			};
		case GET_SYSTEM_LOG_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};
		default:
			return state;
	}
}
