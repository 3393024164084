import {
	ADD_INVOICE_HEADER_SUCCESS,
	ADD_INVOICE_HEADER_ERROR,
	ADD_INVOICE_HEADER_REQUEST,
	GET_ALL_INVOICE_HEADER_REQUEST,
	GET_ALL_INVOICE_HEADER_SUCCESS,
	GET_ALL_INVOICE_HEADER_ERROR,
	DELETE_INVOICE_HEADER_REQUEST,
	DELETE_INVOICE_HEADER_SUCCESS,
	DELETE_INVOICE_HEADER_ERROR,
	UPDATE_INVOICE_HEADER_REQUEST,
	UPDATE_INVOICE_HEADER_ERROR,
	UPDATE_INVOICE_HEADER_SUCCESS,
	GET_ONE_INVOICE_HEADER_SUCCESS,
	GET_ONE_INVOICE_HEADER_REQUEST,
	GET_ONE_INVOICE_HEADER_ERROR,
	GET_ONE_INVOICE_HEADER_DETAIL_REQUEST,
	GET_ONE_INVOICE_HEADER_DETAIL_SUCCESS,
	GET_ONE_INVOICE_HEADER_DETAIL_ERROR,
} from "../actions/types";
export default function (state = [], action) {
	const { type, payload } = action;
	switch (type) {
		case ADD_INVOICE_HEADER_REQUEST:
			return {
				loading: true,
			};
		case ADD_INVOICE_HEADER_SUCCESS:
			return {
				...state,
				loading: false,
				payloadSuccess: [payload],
			};
		case ADD_INVOICE_HEADER_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};
		case GET_ALL_INVOICE_HEADER_REQUEST:
			return {
				loading: true,
			};
		case GET_ALL_INVOICE_HEADER_SUCCESS:
			return {
				...state,
				loading: false,
				payload: payload,
			};
		case GET_ALL_INVOICE_HEADER_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};
		case UPDATE_INVOICE_HEADER_REQUEST:
			return {
				loading: true,
			};
		case UPDATE_INVOICE_HEADER_SUCCESS:
			return {
				...state,
				loading: false,
				payloadSuccess: payload,
			};
		case UPDATE_INVOICE_HEADER_ERROR:
			return {
				...state,
				loading: false,
				errorPayload: payload,
			};
		case DELETE_INVOICE_HEADER_REQUEST:
			return {
				loading: true,
			};
		case DELETE_INVOICE_HEADER_SUCCESS:
			return {
				...state,
				loading: false,
				payload: [payload],
			};
		case DELETE_INVOICE_HEADER_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};
		case GET_ONE_INVOICE_HEADER_REQUEST:
			return {
				loading: true,
			};
		case GET_ONE_INVOICE_HEADER_SUCCESS:
			return {
				...state,
				loading: false,
				payload: payload[0],
			};
		case GET_ONE_INVOICE_HEADER_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};
		case GET_ONE_INVOICE_HEADER_DETAIL_REQUEST:
			return {
				loading: true,
			};
		case GET_ONE_INVOICE_HEADER_DETAIL_SUCCESS:
			return {
				...state,
				loading: false,
				payload: payload[0],
			};
		case GET_ONE_INVOICE_HEADER_DETAIL_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};
		default:
			return state;
	}
}