import {
    GET_SALESPERSON_ERROR,
    GET_SALESPERSON_SUCCESS,
    GET_SALESPERSON_REQUEST,
    ADD_SALEPERSON_REQUEST,
    ADD_SALEPERSON_SUCCESS,
    ADD_SALEPERSON_ERROR,
    DELETE_SALEPERSON_REQUEST,
    DELETE_SALEPERSON_SUCCESS,
    DELETE_SALEPERSON_ERROR,
    UPDATE_SALEPERSON_REQUEST,
    UPDATE_SALEPERSON_SUCCESS,
    UPDATE_SALEPERSON_ERROR,
  } from "../../actions/types";
  
  
  export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case GET_SALESPERSON_REQUEST:
          return {
            loading: true,
          };
        case GET_SALESPERSON_SUCCESS:
          return {
            ...state,
            loading: false,
            payload,
          };
        case GET_SALESPERSON_ERROR:
          return {
            ...state,
            loading: false,
            payload,
          };
          case UPDATE_SALEPERSON_REQUEST:
          return {
            loading: true,
          };
        case UPDATE_SALEPERSON_SUCCESS:
          return {
            ...state,
            loading: false,
            payload: [payload],
          };
        case UPDATE_SALEPERSON_ERROR:
          return {
            ...state,
            loading: false,
          };

          case ADD_SALEPERSON_REQUEST:
            return {
              loading: true,
            };
          case ADD_SALEPERSON_SUCCESS:
            return {
              ...state,
              loading: false,
              payload:  [payload],
            };
          case ADD_SALEPERSON_ERROR:
            return {
              ...state,
              loading: false,
            };

          case DELETE_SALEPERSON_REQUEST:
            return {
              loading: true,
            };
          case DELETE_SALEPERSON_SUCCESS:
            return {
              ...state,
              loading: false,
              payload:[payload],
            };
          case DELETE_SALEPERSON_ERROR:
            return {
              ...state,
              loading: false,
            };
    
      default:
        return state;
    }
  }
  