import {
  GET_ORDER_SUCCESS,
  GET_ORDER_STATUS_REQUEST,
  GET_ORDER_ERROR, 

  

} from "../actions/types";

export default function (state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case GET_ORDER_STATUS_REQUEST:
      return {
        loading: true,
      };
    case GET_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        payload,
      };
    case GET_ORDER_ERROR:
      return {
        ...state,
        loading: false,
        payload,
      };
  
    default:
      return state;
  }
}
