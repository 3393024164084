import {


    GET_ONE_CUSTOMER_REQUEST,
    GET_ONE_CUSTOMER_ERROR,
    GET_ONE_CUSTOMER_SUCCESS,



  } from "../actions/types";
  export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
          case GET_ONE_CUSTOMER_REQUEST:
            return {
                loading: true,
            };
        case GET_ONE_CUSTOMER_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_ONE_CUSTOMER_ERROR:
            return {
                ...state,
                loading: false,
                payload
            };
    
      default:
        return state;
    }
  }
  