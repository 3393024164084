import {
    GET_ADMIN_REGION_ERROR, GET_ADMIN_REGION_REQUEST, GET_ADMIN_REGION_SUCCESS, ADD_ADMIN_REGION_ERROR, ADD_ADMIN_REGION_REQUEST, ADD_ADMIN_REGION_SUCCESS, UPDATE_ADMIN_REGION_ERROR, UPDATE_ADMIN_REGION_REQUEST,
    UPDATE_ADMIN_REGION_SUCCESS, DELETE_ADMIN_REGION_ERROR, DELETE_ADMIN_REGION_REQUEST, DELETE_ADMIN_REGION_SUCCESS
}
    from './types'



import { BASEURL } from "../../constant/config.js";

const axios = require("axios");



export const getRegion = (token, id) => (dispatch) => {
    dispatch({ type: GET_ADMIN_REGION_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/adminRegion`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { id }
    })
        .then((response) => {
            return dispatch({ type: GET_ADMIN_REGION_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: GET_ADMIN_REGION_ERROR,
                payload: error.response,
            });
        });
};


export const addRegion = (token, data, id) => (dispatch) => {
    dispatch({ type: ADD_ADMIN_REGION_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}create/adminRegion`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data,
    })
        .then((response) => {

            return dispatch({ type: ADD_ADMIN_REGION_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: ADD_ADMIN_REGION_ERROR,
                payload: error.response,
            });
        });
};


export const deleteRegion = (token, id) => (dispatch) => {
    dispatch({ type: DELETE_ADMIN_REGION_REQUEST });
    return axios({
        method: "DELETE",
        url: `${BASEURL}delete/adminRegion/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
    })
        .then((response) => {
            return dispatch({ type: DELETE_ADMIN_REGION_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: DELETE_ADMIN_REGION_ERROR,
                payload: error.response,
            });
        });
};

export const updateRegion = (token, data, id) => (dispatch) => {
    dispatch({ type: UPDATE_ADMIN_REGION_REQUEST });
    return axios({
        method: "PUT",
        url: `${BASEURL}update/adminRegion/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data,
    })
        .then((response) => {

            return dispatch({ type: UPDATE_ADMIN_REGION_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: UPDATE_ADMIN_REGION_ERROR,
                payload: error.response,
            });
        });
};
