import {
  GET_SEARCH_RA_REQUEST,
  GET_SEARCH_RA_SUCCESS,
  GET_SEARCH_RA_ERROR
} from "./types";
import axios from "axios";
import { BASEURL } from "../../constant/config.js";
import moment from "moment";

export const searchRA = (token, values) => (dispatch) => {
  dispatch({ type: GET_SEARCH_RA_REQUEST });
  const newData = {
    ra: values.ra
};
  return axios({
    method: "POST",
    url: `${BASEURL}getAll/SearchRA`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: newData
  })
    .then((response) => {
      return dispatch({
        type: GET_SEARCH_RA_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_SEARCH_RA_ERROR,
        payload: [],
      });
    });
};
