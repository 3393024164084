import {
  GET_SALE_TARGET_REPORT_REQUEST,
  GET_SALE_TARGET_REPORT_SUCCESS,
  GET_SALE_TARGET_REPORT_ERROR  ,
  } from "../actions/types";
  
  export const saleTargetReport = (state = [], action) => {
    const { type, payload } = action;
    switch (type) {
      case GET_SALE_TARGET_REPORT_REQUEST:
        return {
          loading: true,
        };
      case GET_SALE_TARGET_REPORT_SUCCESS:
        return {
          ...state,
          loading: false,
          payload,
        };
      case GET_SALE_TARGET_REPORT_ERROR:
        return {
          ...state,
          loading: false,
          payload,
        };
  
      default: {
        return state;
      }
    }
  };
  