import {
  GET_AR_INVENTORY_REQUEST,
  GET_AR_INVENTORY_SUCCESS,
  GET_AR_INVENTORY_ERROR
} from "./types";
import axios from "axios";
import { BASEURL } from "../../constant/config.js";
import moment from "moment";

export const getARInventory = (token, data, report) => (dispatch) => {
  dispatch({ type: GET_AR_INVENTORY_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}getAll/styleShot`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: {
      from_date: moment(data.from_date).format("MM-DD-YYYY"),
      to_date: moment(data.to_date).format("MM-DD-YYYY"),
    },
  })
    .then((response) => {
      return dispatch({
        type: GET_AR_INVENTORY_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_AR_INVENTORY_ERROR,
        payload: [],
      });
    });
};