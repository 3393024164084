import React, { useState, useEffect, useLayoutEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getAllReceiptHeader, deleteReceiptHeader, getOneReceiptHeader, getOneReceiptHeaderDetail } from "../../redux/actions/receipt.action";
import CrudButtons from "./components/CrudButtons";
import ItemReceiptForm from "./components/Form/ItemReceiptForm";
import PageHeader from "../../components/PageHeader/PageHeader";
import { Button } from "antd";
import { ReadCookie } from "../../utils/readCookie";
import notification from "../../utils/notification";
import { useHistory } from "react-router-dom";
import "../../screens/Product/product.css";
import "../Style/style.css";
import Loader from "react-loader-spinner";
import { getRights } from "../../redux/actions/userRights.action";
import { getTransationLock } from "../../redux/actions/transactionLock.action";
import moment from "moment";
import { TabTitle } from "../../utils/GeneralFunction";


export default function ItemReceiptNew({ token }) {
	const { saleReturnId } = useParams();
	TabTitle("Item Reciept");
	const dispatch = useDispatch();
	const dCompanyId = ReadCookie("defaultCompany");
	const { salesReturnFormDataReducer, log, receipt, userType } = useSelector((state) => state);
	const [minimize, setMinimize] = useState(false);
	const [formState, setFormState] = useState("formView");
	const [formData, setFormData] = useState(null);
	const [saleOrderID, setSaleOrderID] = useState(saleReturnId);
	const [flag, setFlag] = useState(false);
	const [companyId, setCompanyId] = useState(ReadCookie("defaultCompany"));
	const [disable, setDisable] = useState(false);
	const [submit, setSubmit] = useState(false);

	const [userRights, setUserRights] = useState({})
	const queryParams = new URLSearchParams(window.location.search);
	const mode = queryParams.get("mode");
	const edit = queryParams.get("edit");
	const return_id = queryParams.get("return_id");
	const receipt_header_id = queryParams.get("receipt_header_id");

	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const startDateList = "01-1-2022";
	const endDateList = "06-01-2022";

	const [menuId, setMenuId] = useState(188);
	const [transactionLock, setTransactionLock] = useState(false)

	const filterReset = () => {
		setFormState("view");
	};




	useLayoutEffect(() => {
		dispatch(getRights(token, menuId)).then((res) => {
			
			if (res.type === "GET_USER_RIGHTS_SUCCESS") {
				setUserRights(res?.payload)
			}
		});
	}, [token])

	const handleDelete = () => {
		setLoading(true);
		dispatch(deleteReceiptHeader(token, saleReturnId)).then((res) => {
			if (res.type === "DELETE_RECEIPT_HEADER_SUCCESS") {
				setLoading(false);
				dispatch(getAllReceiptHeader(token, startDateList, endDateList));
				notification("success", "Receipt Header deleted", res.payload);
				setFlag(true);
				history.push("/item-receipt/list");
			}
		});
	};

	const formSave = () => {
		setSubmit(true);
	};

	useEffect(() => {
		if (mode === "add") {
			setFormState("add");
		}
		if (mode === "return") {
			setFormState("Return");
		}
	}, []);



	useEffect(() => {

		if (return_id) {
			if (mode === "return") {
				dispatch(getOneReceiptHeader(token, return_id)).then((res) => {
					
					setFormData(res?.payload?.data[0]);
				})

			}
			else if (mode === "edit" || formState === "add") {
				dispatch(getOneReceiptHeaderDetail(token, return_id)).then((res) => {
					setFormData(res?.payload);
					
					if (res?.payload?.receipt_date) {
						let transaction_date = moment(res?.payload?.receipt_date).format("YYYY-MM-DD")
						dispatch(getTransationLock(token, transaction_date)).then((r)=> {
							if (r.payload.data.length > 0) {
								setTransactionLock(true)
							}
						});
					}
				})
			}
			
			
			
		}
	}, [return_id]);

	return (
		<>
			<div className="styleContainer">
				<div className="styleBody" style={minimize ? {} : { height: "100%" }}>
					<PageHeader title={"Item Receipt"} minimize={setMinimize} check={minimize} />
					<div className="bodyStyle">

						<div className="crudDiv">
							<CrudButtons
								user_type={userType?.payload?.data[0]?.coalesce}
								userRights={userRights}
								receipt_header_id={receipt_header_id}
								return_id={return_id}
								mode={mode}
								name={"invoice"}
								reset={filterReset}
								formType={formState}
								save={formSave}
								disable={disable}
								setDisable={setDisable}
								formState={setFormState}
								onDelete={handleDelete}
								saleReturnId={saleOrderID}
								edit={edit}
								formData={formData}
								loading={loading}
								transactionLock= {transactionLock}
							/>
							<div className="mainProductContainer">
								<ItemReceiptForm
									mode={mode}
									return_id={return_id}
									loading={loading}
									dCompany={dCompanyId}
									defaultCompany={companyId}
									companyId={setCompanyId}
									submit={setSubmit}
									setDisable={setDisable}
									val={submit}
									formData={formData}
									formState={formState}
									setFormData={setFormData}
									data={salesReturnFormDataReducer}
									changeState={setFormState}
									token={token}
									checkFlag={flag}
									setFlag={setFlag}
									logs={log}
									edit={edit}
									saleReturnId={saleReturnId}
								/>
							</div>
						</div>
					</div>
				</div>
				{minimize && (
					<div className="styleFooter">
						{" "}
						<Button className="fw-600 b-radius-5" onClick={() => setMinimize(false)}>
							{" "}
							Sales Order{" "}
						</Button>{" "}
					</div>
				)}
			</div>
		</>
	);
}