import { SEND_EMAIL_ERROR, SEND_EMAIL_SUCCESS, SEND_EMAIL_REQUEST } from "./types";
import { BASEURL } from "../../constant/config.js";
const axios = require("axios");

export const sendEmail = (token, data) => dispatch => {
    dispatch({ type: SEND_EMAIL_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}communication/PickTicketEmail`,
        
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data
    })
        .then(response => {
            return dispatch({
                type: SEND_EMAIL_SUCCESS,
                payload: response.data,
            });
        })
        .catch(error => {
            return dispatch({ type: SEND_EMAIL_ERROR, payload: error.response });
        });
};

