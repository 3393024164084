import { Button, Form, Select, DatePicker, Table, Spin } from "antd";
import { useState, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row as Brow, Col as Bcol } from "reactstrap";
import { CSVLink } from "react-csv";
import numeral from "numeral";
import moment from "moment";
import csv from "../../assets/images/csv.png";


import { getBookSummary } from "../../redux/actions/bookSummary.action";
import PageHeader from "../../components/PageHeader/PageHeader";
import { ReadCookie } from "../../utils/readCookie";
import { TabTitle } from "../../utils/GeneralFunction";
import { loadCustomers } from "../../redux/actions/customerForm";
import { getPendingSummary } from "../../redux/actions/pendingSummary.action";
import { getPendingPodSummary } from "../../redux/actions/pendingPodSummary.action";
import { getShippedSummary } from "../../redux/actions/shippedSummary.action";
import { getReturnSummary } from "../../redux/actions/returnSummary.action";
import { columns, columnsPending, columnsPodSummary, columnShippedSummary, columnsReturnSummary } from "./column"
const { Option } = Select;

const CRMReport = () => {
  TabTitle("CRM Report")
  const [form] = Form.useForm();
  const [minimize, setMinimize] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const menuId = ReadCookie("menuId");
  const token = ReadCookie("token");
  const queryParams = new URLSearchParams(window.location.search);
  const customerID = queryParams.get("customer_id");
  const tableRef = useRef(null);
  const companyId = ReadCookie("defaultCompany");
  const { customerForms, getBookSummaryReducer, getPendingSummaryReducer, pendingPodSummary, getShippedSummaryReducer, getReturnSummaryReducer } = useSelector((state) => state);
  const focusInput = useRef(null);
  const [customer, setCustomer] = useState("All");

  const [bookSummaryList, setBookSummaryList] = useState([]);
  const [pendingSummaryList, setPendingSummaryList] = useState([]);
  const [pendingPODSummaryList, setPendingPODSummaryList] = useState([]);
  const [shippedSummaryList, setShippedSummaryList] = useState([]);
  const [returnSummaryList, setReturnSummaryList] = useState([]);


  const [bookSummaryExcelData, setBookSummaryExcelData] = useState([]);
  const [pendingSummaryExcelData, setPendingSummaryExcelData] = useState([]);
  const [pendingPODSummaryExcelData, setPendingPODSummaryExcelData] = useState([]);
  const [shippedSummaryExcelData, setShippedSummaryExcelData] = useState([]);
  const [returnSummaryExcelData, setReturnSummaryExcelData] = useState([]);




  function downloadExcelFileData(data, type) {
    if (type === 'bookSummary') {
      setBookSummaryExcelData(
        data.map((v) => {
          return {
            "Order #": v.order_header_no,
            "Order Date": moment(v.order_date).format("MM-DD-YY"),
            "PO #": v.reference_no,
            "Start Date": moment(v.start_date).format("MM-DD-YY"),
            "Cancel Date": moment(v.cancel_date).format("MM-DD-YY"),
            "Customer": v.customer_name,
            "Billing Addressee": v.billing_addressee,
            "Payment Term": v.payment_term,
            "Quantity": parseFloat(v.quantity).toFixed(0),
            "Net Amount": numeral(v.net).format("0,0.00"),
            "CIT Status": v.factor_decision,
            "Source": v.source_type,
            "Status": v.status,
            "Loaction": v.location_name,
            "Store": v.store_id,
            "DC #": v.dc_no,
            "Department code": v.department_code,
            "Gender": v.gender_name,
            "Sale person Name": v.salesperson_name,
            "Region": v.region_name,
            "State": v.state_name,
            "Resale Expiry": moment(v.resaleexpiry).format("MM-DD-YY"),
            "Resale #": v.resaleno,
          };
        })
      );
    } else if (type === 'pendingSummary') {
      setPendingSummaryExcelData(
        data.map((v) => {
          return {
            "Order #": v.order_header_no,
            "Order Date": moment(v.order_date).format("MM-DD-YY"),
            "PO #": v.reference_no,
            "Customer": v.customer_name,
            "Billing Addressee": v.billing_addressee,
            "Start Date": moment(v.start_date).format("MM-DD-YY"),
            "Cancel Date": moment(v.cancel_date).format("MM-DD-YY"),
            "Pending Quantity": parseFloat(v.quantity).toFixed(0),
            "Commited": parseFloat(v.quantity_committed).toFixed(0),
            "Pending Amount": numeral(v.net).format("0,0.00"),
            "Term": v.payment_term,
            "CIT Status": v.factor_decision,
            "Order Status": v.status,
            "Hold Status": v.holdstatus,
            "Region": v.region_name,
            "Loaction": v.location_name,
            "Sale person Name": v.salesperson_name,
            "Gender": v.gender_name,
            "Customer Type": v.customer_type_name,
            "Billing State": v.state_name,
            "Resale #": v.resaleno,
            "Resale Expiry": moment(v.resaleexpiry).format("MM-DD-YY"),
            "Store": v.store_id,
            "Shipping Zip": v.shipping_zip,
            "Shipper": v.ship_via_description,
            "Bergan Billing": v.bergan_billing_name,
          };
        })
      );
    } else if (type === 'pendingPODSummary') {
      setPendingPODSummaryExcelData(
        data.map((v) => {
          return {
            "PT #": v.ship_header_no,
            "PT Date": moment(v.ship_date).format("MM-DD-YY"),
            "Cancel Date": moment(v.cancel_date).format("MM-DD-YY"),
            "Customer": v.billingcustomer,
            "Billing Addressee": v.billing_addressee,
            "SO #": v.order_header_no,
            "PO #": v.reference_no,
            "PT Qty": numeral(v.quantity).format("0,00"),
            "Amount": parseFloat(v.amount).toFixed(2),
            "Term": v.payment_term,
            "Sale person": v.salesperson_name,
            "Region": v.region_name,
            "PT Status": v.pt_status_name,
            "NS Status": v.ship_status_name,
            "Loaction": v.location_name,
          };
        })
      );
    } else if (type === 'shippedSummary') {
      setShippedSummaryExcelData(
        data.map((v) => {
          return {
            "invoice_no": v.invoice_header_no,
            "Invoice Date": moment(v.invoice_date).format("MM-DD-YYYY"),
            "PT #": v.ship_header_no,
            "Order #": v.order_header_no,
            "PO #": v.reference_no,
            "Billing Customer": v.billingcustomer,
            "Payment Term": v.payment_term,
            "Billing City": v.city_name,
            "Billing State": v.bill_state_name,
            "Shipping State": v.ship_state_name,
            "Customer Category": v.customer_category,
            "Status": v.status,
            "Quantity": parseFloat(v.quantity).toFixed(0),
            "Amount": parseFloat(v.net).toFixed(2),
            "Due Date": moment(v.due_date).format("MM-DD-YYYY"),
            "Gender": v.gender_name,
            "EDI #": v.edi,
            "Region": v.region_name,
            "Salesperson": v.salesperson_name,
            "Scf No": v.scf_no,
            "Store": v.store_id,
            "Order Date": moment(v.order_date).format("MM-DD-YYYY"),
            "Payment Class": v.factor_name,
          };
        })
      );
    } else if (type === 'returnSummary') {
      setReturnSummaryExcelData(
        data.map((v) => {
          return {
            "CN #": `CN-${v.note_header_no}`,
            "CN Date": moment(v.note_date).format("MM-DD-YYYY"),
            "RA #": v.return_header_no,
            "INV #": v.invoice_header_no,
            "PO #": v.reference_no,
            "Billing Customer": v.billingcustomer,
            "Quantity": parseFloat(v.quantity).toFixed(0),
            "Amount": Number(v.net).toFixed(2),
            "Payment Term": v.payment_term,
            "Region": v.region_name,
            "Gender": v.gender_name,
            "PT #": v.ship_header_no,
            "ASN #": v.asn_no,
            "RA Approval #": v.ra_aproval,
            "Sale person Name": v.salesperson_name,
            "Country": v.country_name,
            "City": v.city_name,
            "Location": v.location_name,
            "State": v.state_name,
            "Address": v.full_address,
          };
        })
      );
    }
  }

  function debounce(cb, delay = 1000) {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        cb(...args);
      }, delay);
    };
  }

  const handleChanges = (val) => {
    if (val === "" || val === "All") return;
    setCustomer(val);
    dispatch(loadCustomers(token, null, null, null, null, null, val, null, null, null, null, null, null, null, null, null, null, null, null)).then(
      (res) => {
        if (res?.type === "GET_ALL_CUSTOMERS_SUCCESS") {
          setCustomer(res?.payload?.data);
        }
      }
    );
  };

  const optimizedFn = useCallback(debounce(handleChanges), []);


  const onFinish = async () => {
    setLoading(true);
    try {
      const values = await form.validateFields();
      const summaryRequests = [
        getBookSummary(token, values),
        getPendingSummary(token, values),
        getPendingPodSummary(token, values),
        getShippedSummary(token, values),
        getReturnSummary(token, values)
      ];

      const [
        bookSummaryRes,
        pendingSummaryRes,
        pendingPODSummaryRes,
        shippedSummaryRes,
        returnSummaryRes
      ] = await Promise.all(summaryRequests.map(dispatch));

      setBookSummaryList(bookSummaryRes?.payload?.data);
      setPendingSummaryList(pendingSummaryRes?.payload?.data);
      setPendingPODSummaryList(pendingPODSummaryRes?.payload?.data);
      setShippedSummaryList(shippedSummaryRes?.payload?.data);
      setReturnSummaryList(returnSummaryRes?.payload?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  let sumQtyBook = bookSummaryList.reduce(function (previousValue, currentValue) { return previousValue += Number(currentValue.quantity) }, 0);
  let sumAmountBook = bookSummaryList.reduce(function (previousValue, currentValue) { return previousValue += Number(currentValue.net); }, 0);
  let sumQtyPending = pendingSummaryList.reduce(function (previousValue, currentValue) { return previousValue += Number(currentValue.quantity); }, 0);
  let sumAmountPending = pendingSummaryList.reduce(function (previousValue, currentValue) { return previousValue += Number(currentValue.net); }, 0);
  let sumQtyPOD = pendingPODSummaryList.reduce(function (previousValue, currentValue) { return previousValue += Number(currentValue.quantity); }, 0);
  let sumAmountPOD = pendingPODSummaryList.reduce(function (previousValue, currentValue) { return previousValue += Number(currentValue.amount); }, 0);
  let sumQtyShipped = shippedSummaryList.reduce(function (previousValue, currentValue) { return previousValue += Number(currentValue.quantity); }, 0);
  let sumAmountShipped = shippedSummaryList.reduce(function (previousValue, currentValue) { return previousValue += Number(currentValue.net); }, 0);
  let sumQtyReturn = returnSummaryList.reduce(function (previousValue, currentValue) { return previousValue += Number(currentValue.quantity); }, 0);
  let sumAmountReturn = returnSummaryList.reduce(function (previousValue, currentValue) { return previousValue += Number(currentValue.net); }, 0);


  return (
    <div className="styleContainer">
      <div className="styleBodyPendingSummary">
        <PageHeader
          title={"CRM Report"}
          minimize={setMinimize}
          check={minimize}
        />
        {!minimize && (
          <div className="bodyStyle">
            <div className="formProduct p-2">
              <Form onFinish={onFinish} form={form} name="control-hooks">
                <div className="container-fluid pbpx-5 ptpx-5">
                  <Brow>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div className="">
                        <div className="relative">
                          <Form.Item
                            name="from_date"
                            label="From Date:"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            initialValue={moment().startOf('year')}
                          >
                            <DatePicker
                              size='small'
                              className="styleInput w-100"
                              format={"MM-DD-YYYY"}
                              defaultValue={moment().format("MM-DD-YYYY")}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div >
                        <div className="relative">
                          <Form.Item
                            name="to_date"
                            label="To Date:"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            initialValue={moment().endOf('year')}
                          >
                            <DatePicker
                              size='small'
                              className="styleInput w-100"
                              format={"MM-DD-YYYY"}
                              defaultValue={moment().format("MM-DD-YYYY")}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div >
                        <div className="relative">
                          <Form.Item
                            name="customerNew"
                            label="Customer:"
                            labelAlign="left"
                            rules={[
                              {
                                required: true,
                                message: "Customer is Required",
                              },
                            ]}
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                          >
                            <Select
                              showSearch
                              allowClear
                              optionFilterProp="children"
                              loading={customerForms.loading}
                              size="small"
                              onSearch={(value) => {
                                optimizedFn(value);
                              }}
                              ref={focusInput}
                              value={customer}
                            >
                              {customerForms?.payload?.data.map((item) => {
                                return (
                                  <Option
                                    key={item.customer_id} value={item.customer_id}
                                  >
                                    {" "}
                                    {`${item.customer_code} ${item.customer_name}`}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol>
                  </Brow>
                </div>
              </Form>
              <Button
                htmlType="submit"
                type="primary"
                className="submit-btn-btm mb-2 mrpx-10"
                onClick={onFinish}
              >
                Submit
              </Button>
            </div>
          </div>
        )}
      </div>
      <div style={{ marginTop: 10 }}>
        {
          loading ? (
            <div className=" report-wrapper-main d-flex align-items-center justify-content-center spin-style mt-3">
              <Spin size='large' />
            </div>
          ) : (
            bookSummaryList && bookSummaryList.length > 0 ? (
              <>
                <tr style={{ justifyContent: "space-between", display: "flex", flexDirection: "row", width: "100%" }}>
                  <td style={{ fontSize: "16px", textDecoration: "underline", textDecorationThickness: 2 }}>Booked Summary</td>
                  <td>
                    <CSVLink
                      filename={"booked-summary.csv"}
                      data={bookSummaryExcelData}
                      onClick={() => downloadExcelFileData(bookSummaryList, 'bookSummary')}
                    >
                      <span
                        className="print_icon printing_class">
                        <input style={{ height: "30px" }}
                          type="image"
                          name="ImgExcel"
                          alt="Export to excel"
                          id="ImgExcel"
                          title="Export to excel"
                          src={csv}
                        />
                      </span>
                    </CSVLink></td>
                </tr>
                <Table
                  ref={tableRef}
                  size="small"
                  bordered={false}
                  type="company"
                  scroll={{ x: 'max-content' }}
                  rowKey="order_header_id"
                  dataSource={bookSummaryList}
                  columns={columns}
                  loading={getBookSummaryReducer.loading}
                  
                  pagination={{
                    defaultCurrent: 1,
                    defaultPageSize: 5,
                    pageSizeOptions: [5, 20, 50, 100, 200]
                  }}
                  summary={(pageData) => {
                    let sumQty = pageData.reduce(function (
                      previousValue,
                      currentValue
                    ) {
                      return (previousValue += parseFloat(
                        currentValue.quantity
                      ));
                    },
                      0);
                    let Net = pageData.reduce(function (
                      previousValue,
                      currentValue
                    ) {
                      return (previousValue += parseFloat(
                        currentValue.net
                      ));
                    },
                      0);
                    return (
                      <>
                        <Table.Summary.Row>
                          <Table.Summary.Cell colSpan={2}>Total Quantity : {numeral(sumQtyBook).format("0,00,0")}
                          </Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p style={{ textAlign: "right" }} className="totalquantity">
                              {sumQty.toLocaleString("en-US")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p style={{ textAlign: "right" }} className="totalquantity">
                              $ {numeral(Net).format('0,0.00')}
                            </p>
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                        <Table.Summary.Row>
                          <Table.Summary.Cell colSpan={2} style={{ paddingTop: "10px", fontWeight: "bolder" }}>Total Amount : ${numeral(sumAmountBook).format("0,0.00")}
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      </>
                    );
                  }}
                />
                <tr style={{ justifyContent: "space-between", display: "flex", flexDirection: "row", width: "100%" }}>
                  <td style={{ fontSize: "16px", textDecoration: "underline", textDecorationThickness: 2 }}>Pending Summary</td>
                  <td>
                    <CSVLink
                      filename={"Pending-Summary.csv"}
                      data={pendingSummaryExcelData}
                      onClick={() => downloadExcelFileData(pendingSummaryList, 'pendingSummary')}
                    >
                      <span
                        className="print_icon printing_class">
                        <input style={{ height: "30px" }}
                          type="image"
                          name="ImgExcel"
                          alt="Export to excel"
                          id="ImgExcel"
                          title="Export to excel"
                          src={csv}
                        />
                      </span>
                    </CSVLink></td>
                </tr>
                <Table
                  ref={tableRef}
                  size="small"
                  bordered={false}
                  type="company"
                  scroll={{ x: 'max-content' }}
                  perPage={5}
                  dataSource={pendingSummaryList}
                  columns={columnsPending}
                  loading={getPendingSummaryReducer.loading}
                  pagination={{
                    defaultCurrent: 1,
                    defaultPageSize: 5,
                    pageSizeOptions: [5, 20, 50, 100, 200]
                  }}
                  summary={(pageData) => {
                    let PendingQuantity = pageData.reduce(function (
                      previousValue,
                      currentValue
                    ) {
                      return (previousValue += parseFloat(
                        currentValue.quantity
                      ));
                    },
                      0);
                    let Commited = pageData.reduce(function (
                      previousValue,
                      currentValue
                    ) {
                      return (previousValue += parseFloat(
                        currentValue.quantity_committed
                      ));
                    },
                      0);
                    let PendingAmount = pageData.reduce(function (
                      previousValue,
                      currentValue
                    ) {
                      return (previousValue += parseFloat(
                        currentValue.net
                      ));
                    },
                      0);
                    return (
                      <>
                        <Table.Summary.Row>
                          <Table.Summary.Cell colSpan={2}>Total Quantity : {numeral(sumQtyPending).format("0,00,0")}
                          </Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p style={{ textAlign: "right" }} className="totalquantity">
                              {PendingQuantity.toLocaleString("en-US")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p style={{ textAlign: "right" }} className="totalquantity">
                              {Commited.toLocaleString("en-US")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p style={{ textAlign: "right" }} className="totalquantity">
                              $ {numeral(PendingAmount).format('0,0.00')}
                            </p>
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                        <Table.Summary.Row>
                          <Table.Summary.Cell colSpan={2} style={{ paddingTop: "10px", fontWeight: "bolder" }}>Total Amount : ${numeral(sumAmountPending).format("0,0.00")}
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      </>
                    );
                  }}
                />
                <tr style={{ justifyContent: "space-between", display: "flex", flexDirection: "row", width: "100%" }}>
                  <td style={{ fontSize: "16px", textDecoration: "underline", textDecorationThickness: 2 }}>Pending POD Summary</td>
                  <td>
                    <CSVLink
                      filename={"Pending-Pod-Summary.csv"}
                      data={pendingPODSummaryExcelData}
                      onClick={() => downloadExcelFileData(pendingPODSummaryList, 'pendingPODSummary')}
                    >
                      <span
                        className="print_icon printing_class">
                        <input style={{ height: "30px" }}
                          type="image"
                          name="ImgExcel"
                          alt="Export to excel"
                          id="ImgExcel"
                          title="Export to excel"
                          src={csv}
                        />
                      </span>
                    </CSVLink></td>
                </tr>
                <Table
                  ref={tableRef}
                  size="small"
                  bordered={false}
                  type="company"
                  scroll={{ x: 'max-content' }}
                  perPage={5}
                  pagination={{
                    defaultCurrent: 1,
                    defaultPageSize: 5,
                    pageSizeOptions: [5, 20, 50, 100, 200]
                  }}
                  summary={(pageData) => {
                    let sumQty = pageData.reduce(function (
                      previousValue,
                      currentValue
                    ) {
                      return (previousValue += parseFloat(
                        currentValue.quantity
                      ));
                    },
                      0);
                    let Amount = pageData.reduce(function (
                      previousValue,
                      currentValue
                    ) {
                      return (previousValue += parseFloat(
                        Number(currentValue.amount)
                      ));
                    },
                      0);
                    return (
                      <>
                        <Table.Summary.Row>
                          <Table.Summary.Cell colSpan={2}>Total Quantity : {numeral(sumQtyPOD).format("0,00,0")}
                          </Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p style={{ textAlign: "right" }} className="totalquantity">
                              {sumQty.toLocaleString("en-US")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p style={{ textAlign: "right" }} className="totalquantity">
                              $ {numeral(Amount).format('0,0.00')}
                            </p>
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                        <Table.Summary.Row>
                          <Table.Summary.Cell colSpan={2} style={{ paddingTop: "10px", fontWeight: "bolder" }}>Total Amount : ${numeral(sumAmountPOD).format("0,0.00")}
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      </>
                    );
                  }}
                  dataSource={pendingPODSummaryList}
                  columns={columnsPodSummary}
                  loading={pendingPodSummary.loading}
                  paginate
                />
                <tr style={{ justifyContent: "space-between", display: "flex", flexDirection: "row", width: "100%" }}>
                  <td style={{ fontSize: "16px", textDecoration: "underline", textDecorationThickness: 2 }}>Shipped Summary</td>
                  <td>
                    <CSVLink
                      filename={"Shipped-Summary.csv"}
                      data={shippedSummaryExcelData}
                      onClick={() => downloadExcelFileData(shippedSummaryList, 'shippedSummary')}
                    >
                      <span
                        className="print_icon printing_class">
                        <input style={{ height: "30px" }}
                          type="image"
                          name="ImgExcel"
                          alt="Export to excel"
                          id="ImgExcel"
                          title="Export to excel"
                          src={csv}
                        />
                      </span>
                    </CSVLink></td>
                </tr>
                <Table
                  ref={tableRef}
                  
                  size="small"
                  bordered={false}
                  type="company"
                  scroll={{ x: 'max-content' }}
                  perPage={5}
                  pagination={{
                    defaultCurrent: 1,
                    defaultPageSize: 5,
                    pageSizeOptions: [5, 20, 50, 100, 200]
                  }}
                  summary={(pageData) => {
                    let sumQty = pageData.reduce(function (
                      previousValue,
                      currentValue
                    ) {
                      return (previousValue += parseFloat(
                        currentValue.quantity
                      ));
                    },
                      0);
                    let Amount = pageData.reduce(function (
                      previousValue,
                      currentValue
                    ) {
                      return (previousValue += parseFloat(
                        Number(currentValue.net)
                      ));
                    },
                      0);
                    return (
                      <>
                        <Table.Summary.Row>
                          <Table.Summary.Cell colSpan={2}>Total Quantity : {numeral(sumQtyShipped).format("0,00,0")}
                          </Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p style={{ textAlign: "right" }} className="totalquantity">
                              {sumQty.toLocaleString("en-US")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p style={{ textAlign: "right" }} className="totalquantity">
                              $ {numeral(Amount).format('0,0.00')}
                            </p>
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                        <Table.Summary.Row>
                          <Table.Summary.Cell colSpan={2} style={{ paddingTop: "10px", fontWeight: "bolder" }}>Total Amount : ${numeral(sumAmountShipped).format("0,0.00")}
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      </>
                    );
                  }}
                  dataSource={shippedSummaryList}
                  columns={columnShippedSummary}
                  loading={getShippedSummaryReducer.loading}
                />
                <tr style={{ justifyContent: "space-between", display: "flex", flexDirection: "row", width: "100%" }}>
                  <td style={{ fontSize: "16px", textDecoration: "underline", textDecorationThickness: 2 }}>Return Summary</td>
                  <td>
                    <CSVLink
                      filename={"Return-Summary.csv"}
                      data={returnSummaryExcelData}
                      onClick={() => downloadExcelFileData(returnSummaryList, 'returnSummary')}
                    >
                      <span
                        className="print_icon printing_class">
                        <input style={{ height: "30px" }}
                          type="image"
                          name="ImgExcel"
                          alt="Export to excel"
                          id="ImgExcel"
                          title="Export to excel"
                          src={csv}
                        />
                      </span>
                    </CSVLink></td>
                </tr>
                <Table
                  size="small"
                  bordered={false}
                  type="company"
                  scroll={{ x: 'max-content' }}
                  perPage={5}
                  pagination={{
                    defaultCurrent: 1,
                    defaultPageSize: 5,
                    pageSizeOptions: [5, 20, 50, 100, 200]
                  }}
                  summary={(pageData) => {
                    let qty = pageData.reduce(function (
                      previousValue,
                      currentValue
                    ) {
                      return (previousValue += parseFloat(
                        currentValue.quantity
                      ));
                    },
                      0);
                    let net = pageData.reduce(function (
                      previousValue,
                      currentValue
                    ) {
                      return (previousValue += parseFloat(
                        currentValue.net === null ? 0 : currentValue.net
                      ));
                    },
                      0);

                    return (
                      <>
                        <Table.Summary.Row>
                          <Table.Summary.Cell colSpan={2}>Total Quantity : {numeral(sumQtyReturn).format("0,00,0")}
                          </Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p style={{ textAlign: "right" }} className="totalquantity">
                              {qty.toLocaleString("en-US")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p style={{ textAlign: "right" }} className="totalquantity">
                              $ {numeral(net).format('0,0.00')}
                            </p>
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                        <Table.Summary.Row>
                          <Table.Summary.Cell colSpan={2} style={{ paddingTop: "10px", fontWeight: "bolder" }}>Total Amount : ${numeral(sumAmountReturn).format("0,0.00")}
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      </>
                    );
                  }
                  }
                  dataSource={returnSummaryList}
                  columns={columnsReturnSummary}
                  loading={getReturnSummaryReducer.loading}
                />
              </>) : <div
                style={{
                  backgroundColor: "#CCCCCC",
                  borderWidth: "1pt",
                  borderStyle: "Dashed",
                  fontWeight: "bold",
                  height: "25px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
              No record(s)
            </div>
          )
        }
      </div>

    </div>
  )
}

export default CRMReport;