import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { CaretRightOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  DatePicker,
  Input,
  Checkbox,
  Tabs,
  Button,
  Form,
  Select,
  Tooltip,
  Card,
  Row,
  Col,
  Table,
  Popconfirm,
  InputNumber,
  Collapse,
} from "antd";
import { Row as Brow, Col as Bcol } from "reactstrap";
import Swal from "sweetalert2";
import Loader from "react-loader-spinner";
import moment from "moment";
import numeral from "numeral";
import {
  getAllSalesOrderAdjustmentHeader,
  addSaleOrderAdjustmentHeader,
  updateSaleOrderAdjustmentHeader,
  getSaleOrderAdjustmentFormData,
  getOneSaleOrderAdjustmentHeader,
  getOneSalesOrderAdjLine,
} from "../../../../redux/actions/saleOrderAdjustment.action";
import {
  getShipping,
  getSingleShipping,
} from "../../../../redux/actions/shipping";
import { getOneCustomer } from "../../../../redux/actions/CustomerFormById";
import {
  getBilling,
  getSingleBilling,
} from "../../../../redux/actions/billing";
import {
  getOneSalesOrderLine,
  deleteSalesOrderLine,
} from "../../../../redux/actions/saleOrderLine.action";
import { getSaleOrderGenderHeader } from "../../../../redux/actions/saleOrderGenderHeader.action";
import { getAllCompany } from "../../../../redux/actions/companyAccess";
import { getLog } from "../../../../redux/actions/log.action";
import { ReadCookie } from "../../../../utils/readCookie";
import EditableTable from "../../EditableTable";
import notification from "../../../../utils/notification";
import Tables from "../../../../components/Table/Tables";
import { logColumns } from "../../../../utils/cols";
import SubTitles from "../../../../components/SubTitle/SubTitle";
import "../../../../screens/SaleOrder/salesOrder.css";
import { getOneOrderShipmentLine } from "../../../../redux/actions/orderShipmentLine.action";

const { Option } = Select;
const { TabPane } = Tabs;
const { Panel } = Collapse;
const { TextArea } = Input;

export default function SaleOrderAdjustmentForm({
  formData,
  val,
  token,
  formState,
  submit,
  dCompany,
  saleOrderID,
  checkFlag,
  setFlag,
  customerCode,
  loading: deleteLoading,
}) {
  const focusInput = useRef(null);
  const submitButton = useRef(null);
  const [form] = Form.useForm();
  const menuId = ReadCookie("menuId");
  const dispatch = useDispatch();
  const { log, saleOrderFormData, Shipping, Billing, ProductSale, saleOrderAdjustmentList } =
    useSelector((state) => state);
  const companyId = ReadCookie("defaultCompany");
  const [gender, setGender] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [resaleExpDate, setResaleExpDate] = useState(moment());
  const [oDate, setOrderDate] = useState(moment());
  const [sDate, setStartDate] = useState(moment());
  const [eDate, setEndDate] = useState(moment());
  const [shippingCharges, setShippingCharges] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [visibleItems, setVisibleItems] = useState([]);
  const [orderItems, setOrderItems] = useState([]);
  const [showGrid, setShowGrid] = useState(false);
  const [isShipComplete, setIsShipComplete] = useState(false);
  const [isClosed, setIsClosed] = useState(false);
  const [enableEDI, setEnableeEDI] = useState(true);
  const [loading, setLoading] = useState(false);
  const [discountBasis, setDiscountBasis] = useState("");
  const [deletedItems, setDeletedItems] = useState([]);
  const history = useHistory();

  const startDateList = "01-1-2022";
  const endDateList = "06-01-2022";



  const handleDeleteOrder = (record) => {
    const dataSource = [...visibleItems];
    const deleteSource = [...deletedItems];
    deleteSource.push(record)
    
    
    
    
    

    setDeletedItems(deleteSource);

    setVisibleItems(
      dataSource.filter((item) => item.item_id !== record.item_id)
    );
    notification("success", "Item Deleted");
  };


  const updateQuantity = (up, itemCode, value, index) => {

    visibleItems[index].quantity = value;

  }

  const columns = [
    {
      title: "Item Code",
      dataIndex: "item_code",
    },
    {
      title: "Item ID",
      dataIndex: "item_id",
    },
    {
      title: "Cost Price",
      dataIndex: "rate",
      editable: true,
      render: (text, record) => {
        return <>{numeral(record.rate).format("0.00")}</>;
      },
    },
    {
      title: "Balance",
      editable: false,
      render: (text, record) => {
        return <>{numeral(record.balance).format("0.00")}</>;
      },
    },
    {
      title: "Available Quantity",
      editable: false,
      render: (text, record) => {
        return <>{numeral(record.available_quantity).format("0.00")}</>;
      },
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      editable: true,
      render: (text, record, index) => {
        return (
          <InputNumber

            size="small"
            min="0"
            
            
            
            
            defaultValue={record.quantity}
            onChange={(value) => updateQuantity("up", record.item_code, value, index)}
          
          />
        );
      },
    },
    {
      title: "Amount",
      render: (_, record) =>
        parseFloat(record.quantity * record.rate).toFixed(2),
    },
    {
      title: "Discount Percent",
      render: (_, record) =>
        parseFloat(record.discount_percent || 0).toFixed(2) + "%",
    },
    {
      title: "Discount Amount",
      render: (_, record) => parseFloat(record.discount || 0).toFixed(2),
    },

    {
      title: "Tax Percent",
      render: (_, record) => parseFloat(record.tax_percent || 0).toFixed(2) + "%",
    },
    {
      title: "Tax Amount",
      render: (_, record) => parseFloat(record.tax_amount || 0).toFixed(2),
    },

    {
      title: "Commit Status",
      editable: false,
      render: (text, record) => {
        return (
          <>
            {record.commit_status === "C"
              ? "Available Quantity"
              : "Do Not Commit"}
          </>
        );
      },
    },
    {
      title: "Operation",
      dataIndex: "operation",
      render: (_, record) => (
        <Tooltip title="Delete" color={"white"}>
          <Popconfirm
            title={`Are you sure to delete the item ${record.item_id}?`}
            onConfirm={() => handleDeleteOrder(record)}
          >
            <DeleteOutlined className="delete-Button" />
          </Popconfirm>
        </Tooltip>
      ),
    },
  ];

  function handleChange(value, type) {
    if (type === "company") {
      companyId(value);
      form.setFieldsValue({ company: value });
    }

    if (type === "factor") {
      form.setFieldsValue({ factor: value });
    }

    if (type === "paymentTermId") {
      const Item = saleOrderFormData?.payload?.customerTerm.filter(
        (item) => item.term_id === value
      );
      const factorId = Item[0]?.factor_id;
      form.setFieldsValue({ factor: factorId });
    }

    if (type === "gender") {
      form.setFieldsValue({
        category: null,
        region: null,
        salesPerson: null,
      });
      if (customer) {
        setGender(value);
        dispatch(getSaleOrderGenderHeader(token, value, customer)).then(
          (res) => {
            if (
              res.type === "GET_GENDER_HEADER_DATA_SUCCESS" &&
              res?.payload.length > 0
            ) {
              form.setFieldsValue({
                category: res.payload[0].sp_region_category_id,
                region: res.payload[0].region_id,
                salesPerson: res.payload[0].salesperson_id,
              });
            }
          }
        );
      } else {
        setGender(null);
      }
    }
  }

  function handleCheckBox(e) {
    form.setFieldsValue({ [e.target.name]: e.target.checked });
    if (e.target.name === "isShipComplete") {
      setIsShipComplete(e.target.checked);
    } else if (e.target.name === "isClosed") {
      setIsClosed(e.target.checked);
    }
  }

  function handleGenderChange(value) {
    if (customer) {
      setGender(value);
      dispatch(getSaleOrderGenderHeader(token, value, customer)).then((res) => {
        if (
          res.type === "GET_GENDER_HEADER_DATA_SUCCESS" &&
          res?.payload.length > 0
        ) {
          form.setFieldsValue({
            category: res.payload[0].sp_region_category_id,
            region: res.payload[0].region_id,
            salesPerson: res.payload[0].salesperson_id,
          });
        } else if (
          res.type === "GET_GENDER_HEADER_DATA_SUCCESS" &&
          res?.payload?.length === 0
        ) {
          form.setFieldsValue({
            category: null,
            region: null,
            salesPerson: null,
          });
          setGender(null);
        }
      });
    }
  }

  useEffect(() => {
    if (formState === "add") {
      focusInput.current.focus();
      form.setFieldsValue({
        endDate: eDate,
        freightAmount: 0,
        freightCharges: 0,
        orderDate: oDate,
        shippingAccountNo: 0,
        shippingCost: 0,
        startDate: sDate,
      });
      
      
      
    } else if (formState === "view") {
      dispatch(getAllCompany(token)).then(() => {
        form.setFieldsValue({
          company: parseInt(dCompany),
        });
      });
    } else if (formState === "formView") {
      dispatch(getOneSalesOrderAdjLine(token, saleOrderID)).then((res) => {
        if (res.type === "GET_ONE_SALEORDERADJUSTMENT_SUCCESS") {

          
          
          
          
          
          
          
          
          
          
          
          
          
          
          setVisibleItems(res?.payload);
          orderItems(res?.payload);
        }
        else if (res.type === "GET_ONE_SALEORDERADJUSTMENT_ERROR") {
          dispatch(getOneSalesOrderLine(token, saleOrderID)).then((res) => {
            setVisibleItems(res?.payload?.data);
            orderItems(res?.payload?.data);
          })
        }

      })
    } else if (formState === "formView") {
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      
    }
  }, [formState]);



  const onFinish = async (e) => {
    submit(false);
    await form.validateFields().then((values) => {
      setLoading(true);
      values.order_header_id = formData.order_header_id;
      if (formState === "formView") {
        dispatch(
          addSaleOrderAdjustmentHeader(
            token,
            values,
            companyId,
            visibleItems.map((item) => {
              item.discount = null;
              item.tax_amount = "0";
              item.tax_percent = "0";
              item.order_header_id = formData.order_header_id;
              return item;
            })
          )
        ).then((res) => {
          if (res.type === "ADD_SALEORDERADJUSTMENT_HEADER_SUCCESS") {
            
            notification("success", "sale Order", res?.payload?.data);
            setLoading(false);
            form.setFieldsValue({ orderAdjNumber: res?.payload?.order_header_adj_id });
            dispatch(getOneSaleOrderAdjustmentHeader(token, res?.payload?.order_header_adj_id))


            
          } else if (res.type === "ADD_SALEORDERADJUSTMENT_HEADER_ERROR") {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: res?.payload?.data?.data,
            });
          }
        });
      } else if (formState === "edit") {

        dispatch(deleteSalesOrderLine(token, 123, deletedItems)).then(
          (res) => {
            notification("success", "Sale Order Adjustment Updated");

            
            
            
            
            
            
            
            
          }
        );
        dispatch(
          updateSaleOrderAdjustmentHeader(
            token,
            values,
            saleOrderID,
            companyId,
            visibleItems.map((item) => {
              
              item.order_header_adj_id = formData.order_header_adj_id;
              return item;
            })
          )
        ).then((res) => {
          if (res.type === "UPDATE_SALEORDERADJUSTMENT_HEADER_SUCCESS") {
            dispatch(getOneSaleOrderAdjustmentHeader(token, saleOrderID))
            setLoading(false);
            notification("success", "Sale Order Update", res?.payload);
            
          } else if (res.type === "UPDATE_SALEORDERADJUSTMENT_HEADER_ERROR") {
            setLoading(false);
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: res?.payload?.data?.data,
            });
          }
        });
      }
    });
  };

  useEffect(() => {
    if (formData) {
      setGender(formData.gender_id);
      dispatch(getSingleBilling(token, formData.billing_address_id)).then(
        (res) => {
          if (res.type === "GET_CUSTOMER_SINGLE_BILLING_SUCCESS") {

            onCustomerSelect(res.payload?.data.customer_id, false);
            form.setFieldsValue({
              countryBilling: res.payload?.data.country_name,
              billingCustomer: `${res.payload?.data.addressee} (${res.payload?.data.zip_code})`,
              cityBilling: res.payload?.data.city_name,
              billingEmail: res.payload?.data.email,
              stateBilling: res.payload?.data.state_name,
              zipBilling: res.payload?.data.zip_code,
              addressOneBilling: `${res.payload?.data.address_1 ? res.payload?.data.address_1 : ""
                }, ${res.payload?.data.address_2 ? res.payload?.data.address_2 : ""
                }, ${res.payload?.data.address_3 ? res.payload?.data.address_3 : ""
                } `,
            });
          }
        }
      );
      dispatch(getSingleShipping(token, formData.shipping_address_id)).then(
        (res) => {
          if (res.type === "GET_CUSTOMER_SINGLE_SHIPPING_SUCCESS") {
            form.setFieldsValue({
              countryShipping: res.payload?.data[0].country_name,
              shippingCustomer: `${res.payload?.data[0].addressee} (${res.payload?.data[0].zip_code})`,
              cityShipping: res.payload?.data[0].city_name,
              shippingEmail: res.payload?.data[0].email,
              stateShipping: res.payload?.data[0].state_name,
              zipShipping: res.payload?.data[0].zip_code,
              addressOneShipping: `${res.payload?.data[0].address_1
                ? res.payload?.data[0].address_1
                : ""
                }, ${res.payload?.data[0].address_2
                  ? res.payload?.data[0].address_2
                  : ""
                }, ${res.payload?.data[0].address_3
                  ? res.payload?.data[0].address_3
                  : ""
                } `,
            });
          }
        }
      );

      setCustomer(formData.customer_id);
      setIsShipComplete(formData.is_ship_complete);
      setIsClosed(formData.is_closed);
      setDiscountBasis(formData.discount_basis);
      if (formData.source_type === "bergan-edi") {
        setEnableeEDI(false);
      }

      form.setFieldsValue({
        berganBilling: formData.bergan_billing_id,
        berganBillingId: formData.bergan_billing_id,
        berganPaymentTermId: formData.bergan_payment_term_id,
        cancelDate: moment(formData.cancel_date),
        category: formData.sp_region_category_id,
        company: formData.company_id,
        customer: formData.customer_id,
        dc: formData.dc_no,
        deptDescription: formData.department_description,
        divisionCode: formData.division_code,
        divisionDescription: formData.division_description,
        discountBasis: formData.discount_basis,
        discountId: formData.discount_id,
        discountCode: formData.discount_code,
        discount: formData.discount_amount,
        discountPercent: formData.discount_percent,
        endDate: moment(formData.end_date),
        externalRefID: formData.reference_no,
        factor: formData.factor_id,
        freightAmount: formData.freight_amount,
        freightCharges: formData.freight_charges,
        freightTermId: formData.freight_term_id,
        gender: formData.gender_id,
        isClosed: formData.is_closed,
        isShipComplete: formData.is_ship_complete,
        location: formData.location_id,
        markForAddress1: formData.mark_for_address_1,
        markForAddress2: formData.mark_for_address_2,
        markForCity: formData.mark_for_city,
        markForCode: formData.mark_for_code,
        markForCompanyName: formData.mark_for_company_name,
        markForCountry: formData.mark_for_country,
        markForEmail: formData.mark_email,
        markForPhone: formData.mark_for_phone,
        markForState: formData.mark_for_state,
        markForZip: formData.mark_for_zip,
        memo: formData.remarks,
        orderDate: moment(formData.order_date),
        orderAdjNumber: formData.order_header_adj_id,
        orderNumber: formData.order_header_no,
        orderStatus: formData.order_status_id,
        paymentTermId: formData.payment_term_id,
        po: formData.reference_no,
        region: formData.region_id,
        resaleCertificateDate: moment(resaleExpDate),
        resaleCertificateNo: formData.release_certificate_no,
        salesPerson: formData.sales_person_id,
        shippingAccountNo: formData.ship_account_no,
        shippingAddressID: formData.shipping_address_id,
        shippingCost: formData.shipping_cost,
        shippingMethodId: formData.shipping_method_id,
        shippingTaxCode: parseInt(formData.shipping_tax_code),
        shippingTaxRate: formData.shipping_tax_rate,
        shipViaId: formData.ship_via_id,
        sourceType: formData.source_type,
        startDate: moment(formData.start_date),
        store: formData.customer_store_no,
        termForPrint: formData.term_for_print,
      });
    }
  }, [formData]);

  


  useEffect(() => {
    if (val) {
      submitButton.current.click();
    }
  }, [val]);

  
  
  

  const handleTab = (key) => {
    if (key === "logs") {
      dispatch(
        getLog(token, {
          inv_item_size: "inv_item_size",
          inv_item_inseam: "inv_item_inseam",
          inv_item: "inv_item",
        })
      );
    }
  };

  const onChange = (date, dateString) => {
    setEndDate(dateString.toString());
    if (dateString) {
      form.setFieldsValue({ cancelDate: moment(dateString) });
    }
  };

  const discountAmount = (amount, orderItem = []) => {
    let orderIt = orderItems.length > 0 ? orderItems : visibleItems;
    if (orderItem.length > 0) {
      orderIt = orderItem;
    }
    const sum = orderIt
      .map((item) => item.amount)
      .reduce((prev, curr) => parseFloat(prev) + parseFloat(curr), 0);
    if (amount !== null || amount !== undefined) {
      if (orderIt.length > 0) {
        let calculateDiscount = orderIt.map((item) => {
          item.discount =
            (item.amount / 100) * parseFloat((amount / sum) * 100).toFixed(2);
          item.discount_percent = parseFloat((amount / sum) * 100).toFixed(2);
          item.net_amount =
            item.amount -
            (item.amount / 100) * parseFloat((amount / sum) * 100).toFixed(2);
          return item;
        });

        setOrderItems(calculateDiscount);
      }
    }
  };

  const discountPercent = (percent, orderItem = []) => {
    let orderIt = orderItems.length > 0 ? orderItems : visibleItems;
    if (orderItem.length > 0) {
      orderIt = orderItem;
    }
    if (percent !== null || percent !== undefined) {
      if (orderIt.length > 0) {
        let calculateDiscount = orderIt.map((item) => {
          item.discount_percent = parseFloat(percent).toFixed(2) + "%";
          item.discount = parseFloat((percent / 100) * item.amount).toFixed(2);
          item.net_amount =
            item.amount - parseFloat((percent / 100) * item.amount).toFixed(2);
          return item;
        });
        setOrderItems(calculateDiscount);
      }
    }
  };

  const handleDiscountBasis = (discountBasis) => {
    const discount = form?.getFieldValue()?.discount
      ? form.getFieldValue().discount
      : 0;
    const discountPercentage = form?.getFieldValue()?.discountPercent
      ? form.getFieldValue().discountPercent
      : 0;
    let orderItem = orderItems.length > 0 ? orderItems : visibleItems;
    if (discountBasis === "A") {
      if (discount !== null || discount !== undefined) {
        discountAmount(parseFloat(discount).toFixed(0), orderItem);
      }
      setDiscountBasis("A");
    } else if (discountBasis === "P") {
      if (discountPercentage !== null || discountPercentage !== undefined) {
        discountPercent(parseFloat(discountPercentage).toFixed(0), orderItem);
      }
      setDiscountBasis("P");
    } else {
      setDiscountBasis("");
    }
  };

  const onSearch = (val) => {
    setCustomer(val);
    if (val.length > 2) {
      dispatch(getSaleOrderAdjustmentFormData(token, companyId, menuId, val, "")).then(
        (res) => {
          if (
            res.type === "GET_GENDER_HEADER_DATA_SUCCESS" &&
            res?.payload.length === 0
          ) {
            notification("warning", "empty List");
          }

          if (res?.payload?.customerDetail?.length === 0) {
            notification("warning", "Customer Not Found");
          }
        }
      );
    }
  };

  const onCustomerSelect = (e, valid) => {
    if (e) {
      setCustomer(e);
      dispatch(getBilling(token, e)).then((res) => {
        if (res.type === "GET_BILLING_SUCCESS") {
          res.payload?.data.find((item) => {
            if (item.is_default) {
              form.setFieldsValue({
                addressOneBilling: `${item?.address_1 ? item?.address_1 : item?.addressee
                  } ${item?.address_2 ? item?.address_2 : ""} ${item?.address_3 ? item?.address_3 : ""
                  } `,
                billingEmail: item.email,
                zipBilling: item.zip_code,
                stateBilling: item.state_name,
                telBilling: item.phone,
                cityBilling: item.city_name,
                countryBilling: item.country_name,
                billingCustomer: item.customer_bill_to_id,
              });

              if (formState === "add") {
                form.setFieldsValue({
                  location: 1,
                  orderStatus: 1,
                });
              }
            }
          });
        }
      });
      dispatch(getShipping(token, e)).then((res) => {
        if (res.type === "GET_SHIPPING_SUCCESS") {
          res.payload?.data.find((item) => {
            if (item.is_default) {
              form.setFieldsValue({
                addressOneShipping: `${item.address_1 ? item.address_1 + "," : ""
                  }${item.address_2 ? item.address_2 : ""}, ${item.address_3 ? item.address_3 : ""
                  } `,
                billingEmail: item.email,
                zipShipping: item.zip_code,
                stateShipping: item.state_name,
                telShipping: item.phone,
                cityShipping: item.city_name,
                countryShipping: item.country_name,
                shippingCustomer: item.customer_ship_to_id,
              });
            }
          });
        }
      });
      dispatch(getOneCustomer(token, e)).then((customer) => {
        if (customer.type === "GET_ONE_CUSTOMER_SUCCESS") {
          if (valid) {
            form.setFieldsValue({
              berganBilling: customer.payload.bergan_billing_id,
              berganBillingId: customer.payload.bergan_billing_id,
              freightTermId: customer.payload.freight_term_id,
              isShipComplete: customer.payload.is_ship_complete,
              paymentTermId: customer.payload.term_id,
              shippingAccountNo: customer.payload.shipping_account_no,
              shippingMethodId: customer.payload.shipping_method_id,
              shipViaId: customer.payload.courier_service_id,
            });
            setIsShipComplete(customer.payload.is_ship_complete);
          }
        } else if (customer.type === "GET_ONE_CUSTOMER_ERROR") {
          notification("error", "Customer", customer?.payload?.data);
        }
      });
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const onBillingNameSelect = (e) => {
    dispatch(getSingleBilling(token, e)).then((res) => {
      if (res.type === "GET_CUSTOMER_SINGLE_BILLING_SUCCESS") {
        form.setFieldsValue({
          countryBilling: res.payload?.data.country_name,
          billingCustomer: res.payload?.data.customer_bill_to_id,
          cityBilling: res.payload?.data.city_name,
          billingEmail: res.payload?.data.email,
          stateBilling: res.payload?.data.state_name,
          zipBilling: res.payload?.data.zip_code,
          addressOneBilling: `${res.payload?.data.address_1}, ${res.payload?.data.address_2}, ${res.payload?.data.address_3} `,
        });
      }
    });
  };

  const onShippingNameSelect = (e) => {
    dispatch(getSingleShipping(token, e)).then((res) => {
      if (res.type === "GET_CUSTOMER_SINGLE_SHIPPING_SUCCESS") {
        form.setFieldsValue({
          countryShipping: res.payload?.data[0].country_name,
          shippingCustomer: res.payload?.data[0].customer_ship_to_id,
          cityShipping: res.payload?.data[0].city_name,
          shippingEmail: res.payload?.data[0].email,
          stateShipping: res.payload?.data[0].state_name,
          zipShipping: res.payload?.data[0].zip_code,
          addressOneShipping: `${res.payload?.data[0].address_1 ? res.payload?.data[0].address_1 : ""
            }, ${res.payload?.data[0].address_2 ? res.payload?.data[0].address_2 : ""
            }, ${res.payload?.data[0].address_3 ? res.payload?.data[0].address_3 : ""
            } `,
        });
      }
    });
  };

  useEffect(() => {
    let filtered = orderItems.filter((f) => f.isSelected === true);
    setVisibleItems(filtered);
  }, [orderItems]);


  useEffect(() => {
    if (checkFlag === true) {
      form.setFieldsValue({
        addressOneBilling: null,
        berganBilling: null,
        billingAddressID: null,
        billingCustomer: null,
        billingEmail: null,
        cancelDate: null,
        category: null,
        cityBilling: null,
        cityShipping: null,
        company: null,
        countryBilling: null,
        countryShipping: null,
        customer: null,
        dc: null,
        deptDescription: null,
        divisionCode: null,
        divisionDescription: null,
        endDate: null,
        externalRefID: null,
        freightAmount: null,
        freightCharges: null,
        freightTermId: null,
        gender: null,
        isClosed: false,
        isShipComplete: false,
        location: null,
        memo: null,
        orderDate: null,
        orderStatus: null,
        paymentTermId: null,
        po: null,
        region: null,
        resaleCertificateNo: null,
        salesPerson: null,
        shippingAccountNo: null,
        shippingAddressID: null,
        shippingCost: null,
        shippingCustomer: null,
        shippingEmail: null,
        shippingMethodId: null,
        shippingTaxCode: null,
        shippingTaxRate: null,
        shipViaId: null,
        sourceType: null,
        startDate: null,
        stateBilling: null,
        stateShipping: null,
        store: null,
        termForPrint: null,
        zipBilling: null,
        zipShipping: null,
      });
      setVisibleItems([]);
      setFlag(false);
    }
  }, [checkFlag]);

  const addedItems = (value) => {
    const fillArr = [...orderItems];
    const discount = form?.getFieldValue()?.discount
      ? form.getFieldValue().discount
      : 0;
    const discountPercentage = form?.getFieldValue()?.discountPercent
      ? form.getFieldValue().discountPercent
      : 0;
    const onlyKey = [...value].map((sItem) => sItem.item_code);
    const indexofArr = fillArr.findIndex((f) => {
      return onlyKey.find((i) => i === f.item_code);
    });
    fillArr.splice(indexofArr, 1);
    if (orderItems.length > 0) {
      let uniqueOrderItems = [...orderItems, ...value];
      
      uniqueOrderItems = uniqueOrderItems.filter(
        (v, i, a) => a.findIndex((t) => t.item_code === v.item_code) === i
      );
      setOrderItems(uniqueOrderItems);
      if (discountBasis === "A") {
        if (discount !== null || discount !== undefined) {
          discountAmount(parseFloat(discount).toFixed(0), uniqueOrderItems);
        }
      } else if (discountBasis === "P") {
        if (discountPercentage !== null || discountPercentage !== undefined) {
          discountPercent(
            parseFloat(discountPercentage).toFixed(0),
            uniqueOrderItems
          );
        }
      }
    } else {
      
      let uniqueOrderItems = [...visibleItems, ...value];
      uniqueOrderItems = uniqueOrderItems.filter(
        (v, i, a) => a.findIndex((t) => t.item_code === v.item_code) === i
      );
      setOrderItems(uniqueOrderItems);

      if (discountBasis === "A") {
        if (discount !== null || discount !== undefined) {
          discountAmount(
            discount,
            uniqueOrderItems.map((item) => {
              return item;
            })
          );
        }
      } else if (discountBasis === "P") {
        if (discountPercentage !== null || discountPercentage !== undefined) {
          discountPercent(
            parseFloat(discountPercentage).toFixed(0),
            uniqueOrderItems
          );
        }
      }
    }
    notification("success", "Item Added Successfully");
  };

  function onDateSelect(val) {
    setEndDate(moment(val).format("MM-DD-YYYY"));
  }

  const onFinishFailed = () => {
    let errors = form.getFieldsError().filter((item) => item.errors.length > 0);
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: errors[0]?.errors[0],
    });
  };

  const sourceTypeChange = (e) => {
    if (e.target.value === "bergan-edi") setEnableeEDI(false);
    else setEnableeEDI(true);
  };

  const summary = () => {
    let orderSummary = orderItems.length > 0 ? orderItems : visibleItems;
    let amount = 0;
    let quantity = 0;
    let discount = 0;
    let tax = 0;
    let shippingCost = form.getFieldValue().shippingCost
      ? form.getFieldValue().shippingCost
      : 0;
    let net = 0;
    if (orderSummary.length > 0) {
      orderSummary?.forEach(function (item) {
        if (item.hasOwnProperty("rate")) {
          amount += parseFloat(item.rate) * parseFloat(item.quantity);
          net += parseFloat(item.rate) * parseFloat(item.quantity);
        }
        if (item.hasOwnProperty("quantity")) {
          quantity += parseFloat(item.quantity);
        }

        if (item.hasOwnProperty("discount")) {
          discount += parseFloat(item.discount);
        }
      });
    }
    return {
      quantity: quantity,
      amount: parseFloat(amount).toFixed(2),
      discount: parseFloat(discount || 0).toFixed(2),
      tax: parseFloat(tax).toFixed(2),
      shippingCost: parseFloat(shippingCost).toFixed(2),
      net: parseFloat(
        numeral(amount).value() +
        numeral(shippingCost).value() -
        numeral(discount).value()
      ).toFixed(2),
    };
  };

  return (
    <>
      <EditableTable
        token={token}
        companyId={companyId}
        modalValue={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        setOrderItems={addedItems}
        orderItems={orderItems}
        setShowGrid={setShowGrid}
      />
      <Form
        requiredMark={false}
        autoComplete="off"
        onFinish={onFinish}
        form={form}
        onFinishFailed={onFinishFailed}
        name="basic"
      >
        {deleteLoading ||
          saleOrderFormData.loading ||
          (loading && (
            <div className="loaderDiv">
              <Loader type="Grid" color="#212121" height={40} width={40} />
            </div>
          ))}
        <div className="saleOrderForm">
          <SubTitles name={"Primary Information"} />
          <div className="primaryInfo container-fluid pbpx-0 ptpx-10">
            <Brow>
              <Bcol md={12} xl={9} xxl={10} className="pbpx-10">
                <Brow>
                  <Bcol lg={6} xxl={4}>
                    <div className="firstSectionRo">
                      <Form.Item
                        name="orderAdjNumber"
                        label="Order Adj #"
                        labelAlign="left"
                        labelCol={{ span: 5 }}
                      >
                        <Input
                          size="small"
                          
                          disabled
                          className={`customInputs`}
                        />
                      </Form.Item>
                    </div>
                  </Bcol>
                  <Bcol lg={6} xxl={4}>
                    <div className="firstSectionRo">
                      <Form.Item
                        name="orderNumber"
                        label="Order #"
                        labelAlign="left"
                        labelCol={{ span: 5 }}
                      >
                        <Input
                          size="small"
                          
                          disabled
                          className={`customInputs`}
                        />
                      </Form.Item>
                    </div>
                  </Bcol>
                  <Bcol lg={6} xxl={4}>
                    <div className="firstSectionRo">
                      <Form.Item
                        label="Order Date"
                        name="orderDate"
                        labelAlign="left"
                        labelCol={{ span: 5 }}
                        rules={[
                          { required: true, message: "Order Date is required" },
                        ]}
                      >
                        <DatePicker
                          size="small"
                          disabled={
                            !(formState === "add" || formState === "edit")
                          }
                          defaultValue={
                            formState === "edit"
                              ? form.getFieldValue().orderDate
                              : moment(oDate)
                          }
                          format="MM-DD-YYYY"
                          
                          allowClear
                          className={`dateInput customInputs`}
                          onChange={(e) => setOrderDate(moment(e))}
                        />
                      </Form.Item>
                    </div>
                  </Bcol>
                  <Bcol lg={6} xxl={4}>
                    <div className="firstSectionRo">
                      <Form.Item
                        name="customer"
                        label="Customer"
                        labelAlign="left"
                        labelCol={{ span: 5 }}
                        rules={[
                          { required: true, message: "Please Select Customer" },
                        ]}
                      >
                        <Select
                          
                          
                          
                          
                          size="small"
                          tabIndex={1}
                          autoComplete={"nope"}
                          allowClear
                          showSearch
                          optionFilterProp="children"
                          onChange={(value) => {
                            onCustomerSelect(value, true);
                          }}
                          
                          loading={saleOrderFormData.loading}
                          onSelect={(data) => onCustomerSelect(data, true)}
                          disabled={
                            !(formState === "add" || formState === "edit")
                          }
                          defaultValue={form.getFieldValue().customer}
                          
                          onSearch={onSearch}
                          ref={focusInput}
                        >
                          {saleOrderFormData?.payload?.customerDetail.map(
                            (item) => {
                              return (
                                <Option
                                  key={item.customer_id}
                                  value={item.customer_id}
                                >
                                  {" "}
                                  {`${item.customer_code} - ${item.customer_name}`}
                                </Option>
                              );
                            }
                          )}
                        </Select>
                      </Form.Item>
                    </div>
                  </Bcol>
                  <Bcol lg={6} xxl={4}>
                    <div className="firstSectionRo">
                      <Form.Item
                        name="po"
                        label="P.O #"
                        labelAlign="left"
                        labelCol={{ span: 5 }}
                        rules={[
                          { required: true, message: "PO#  is required" },
                        ]}
                      >
                        <Input
                          tabIndex={1}
                          
                          size="small"
                          className={`customInputs`}
                          disabled={
                            !(formState === "add" || formState === "edit")
                          }
                        />
                      </Form.Item>
                    </div>
                  </Bcol>
                  <Bcol lg={6} xxl={4}>
                    <div className="firstSectionRo">
                      <Form.Item
                        name="sourceType"
                        label="Source Type"
                        labelAlign="left"
                        labelCol={{ span: 5 }}
                      
                      
                      
                      >
                        <Input
                          size="small"
                          onChange={sourceTypeChange}
                          
                          className={`customInputs`}
                          disabled={
                            !(formState === "add" || formState === "edit")
                          }
                        />
                      </Form.Item>
                    </div>
                  </Bcol>
                </Brow>
              </Bcol>
              <Bcol md={4} xl={3} xxl={2}>
                <div className="firstSectionRo">
                  <div className="order-summary">
                    <div className="summary-title">Order Summary</div>
                    <Brow>
                      <Bcol className="summary-heading" col={6}>
                        <p className="mb-0">Total Quantity:</p>
                      </Bcol>
                      <Bcol className="col-6">
                        <p className="mb-0 summary-value">
                          {summary().quantity}
                        </p>
                      </Bcol>
                    </Brow>
                    <Brow>
                      <Bcol className="summary-heading" col={6}>
                        <p className="mb-0">Total Shipping:</p>
                      </Bcol>
                      <Bcol className="col-6">
                        <p className="mb-0 summary-value">
                          ${summary().shippingCost}
                        </p>
                      </Bcol>
                    </Brow>

                    <Brow>
                      <Bcol className="summary-heading" col={6}>
                        <p className="mb-0">Total Discount:</p>
                      </Bcol>
                      <Bcol className="col-6">
                        <p className="mb-0 summary-value">
                          ${summary().discount}
                        </p>
                      </Bcol>
                    </Brow>
                    <Brow>
                      <Bcol className="summary-heading" col={6}>
                        <p className="mb-0">Total Amount:</p>
                      </Bcol>
                      <Bcol className="col-6">
                        <p className="mb-0 summary-value">
                          ${summary().amount}
                        </p>
                      </Bcol>
                    </Brow>
                    <Brow>
                      <Bcol className="summary-heading" col={6}>
                        <p className=" mb-0">Net:</p>
                      </Bcol>
                      <Bcol className="col-6">
                        <p className="mb-0 summary-value">${summary().net}</p>
                      </Bcol>
                    </Brow>
                  </div>
                </div>
              </Bcol>
            </Brow>
            <Brow className="secondSectio">
              <Bcol xl={6} xxl={6}>
                <Card
                  size="large"
                  className="cardTitle cardDesignNew"
                  title="Billing Information"
                >
                  <div className="secondSectionRow">
                    <Row justify="space-between">
                      <Col xs={24} sm={24} md={24} lg={24}>
                        <Form.Item
                          name="billingCustomer"
                          label="Name"
                          className="name-field-form"
                          labelAlign="left"
                          labelCol={{
                            xs: { span: 24 },
                            sm: { span: 4 },
                            md: { span: 4 },
                            lg: { span: 2 },
                          }}
                        >
                          <Select
                            size="small"
                            tabIndex={4}
                            autoComplete={"nope"}
                            disabled={
                              !(formState === "add" || formState === "edit")
                            }
                            showSearch={true}
                            className="customInputs fw-400"
                            
                            loading={Billing?.loading}
                            onSelect={onBillingNameSelect}
                          >
                            {Billing.payload?.data?.map((item) => {
                              return (
                                <Option value={item.customer_bill_to_id}>
                                  {" "}
                                  {/* {item.addressee} */}
                                  {`${item.addressee} (${item.zip_code})`}
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12}>
                        <Form.Item
                          name="billingEmail"
                          label="Email"
                          labelAlign="left"
                          labelCol={{ span: 4 }}
                        >
                          <Input
                            
                            
                            
                            bordered={false}
                            readOnly
                            className={`customInputs fw-400 `}
                          
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12}>
                        <Form.Item
                          name="addressOneBilling"
                          label="Address"
                          labelAlign="left"
                          labelCol={{ span: 4 }}
                        >
                          <TextArea
                            autoSize={true}
                            bordered={false}
                            readOnly
                            className={`customInputs fw-400 `}
                          
                          
                          
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12}>
                        <Form.Item
                          name="cityBilling"
                          label="City"
                          labelAlign="left"
                          labelCol={{ span: 4 }}
                        >
                          <Input
                            bordered={false}
                            readOnly
                            className={`customInputs fw-400 `}
                          
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12}>
                        <Form.Item
                          name="telBilling"
                          label="Tel"
                          labelAlign="left"
                          labelCol={{ span: 4 }}
                        >
                          <Input
                            bordered={false}
                            readOnly
                            className={`customInputs fw-400 `}
                          
                          
                          
                          
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12}>
                        <Form.Item
                          name="stateBilling"
                          label="State"
                          labelAlign="left"
                          labelCol={{ span: 4 }}
                        >
                          <Input
                            bordered={false}
                            readOnly
                            className={`customInputs fw-400 `}
                          
                          
                          
                          
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12}>
                        <Form.Item
                          name="zipBilling"
                          label="Zip"
                          labelAlign="left"
                          labelCol={{ span: 4 }}
                        >
                          <Input
                            bordered={false}
                            readOnly
                            className={`customInputs fw-400 `}
                          
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12}>
                        <Form.Item
                          name="countryBilling"
                          label="Country"
                          labelAlign="left"
                          labelCol={{ span: 4 }}
                        >
                          <Input
                            bordered={false}
                            readOnly
                            className={`customInputs fw-400 `}
                          
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                </Card>
              </Bcol>
              <Bcol xl={6} xxl={6}>
                <Card
                  size="large"
                  className="cardTitle cardDesignNew"
                  title="Shipping Information"
                >
                  <div className="secondSectionRow">
                    <Row justify="space-between">
                      <Col xs={24} sm={24} md={24} lg={24}>
                        <Form.Item
                          name="shippingCustomer"
                          label="Name"
                          labelAlign="left"
                          className="name-field-form"
                          labelCol={{
                            xs: { span: 24 },
                            sm: { span: 4 },
                            md: { span: 4 },
                            lg: { span: 2 },
                          }}
                        >
                          <Select
                            size="small"
                            tabIndex={5}
                            autoComplete={"nope"}
                            showSearch={true}
                            className="customInputs fw-400 "
                            
                            loading={Shipping.loading}
                            onSelect={onShippingNameSelect}
                            disabled={
                              !(formState === "add" || formState === "edit")
                            }
                          >
                            {Shipping.payload?.data?.map((item) => {
                              return (
                                <Option value={item.customer_ship_to_id}>
                                  {" "}
                                  {`${item.addressee} (${item.zip_code})`}
                                  {/* {item.addressee} */}
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12}>
                        <Form.Item
                          name="shippingEmail"
                          label="Email"
                          labelAlign="left"
                          labelCol={{ span: 4 }}
                        >
                          <Input
                            bordered={false}
                            readOnly
                            className={`customInputs fw-400 `}
                          
                          
                          
                          
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12}>
                        <Form.Item
                          name="addressOneShipping"
                          label="Address"
                          labelAlign="left"
                          labelCol={{ span: 4 }}
                        >
                          <TextArea
                            autoSize={true}
                            bordered={false}
                            readOnly
                            className={`customInputs fw-400 `}
                          
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12}>
                        <Form.Item
                          name="cityShipping"
                          label="City"
                          labelAlign="left"
                          labelCol={{ span: 4 }}
                        >
                          <Input
                            bordered={false}
                            readOnly
                            className={`customInputs fw-400 `}
                          
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12}>
                        <Form.Item
                          name="telShipping"
                          label="Tel"
                          labelAlign="left"
                          labelCol={{ span: 4 }}
                        >
                          <Input
                            bordered={false}
                            readOnly
                            className={`customInputs fw-400 text-center `}
                          
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12}>
                        <Form.Item
                          name="stateShipping"
                          label="State"
                          labelAlign="left"
                          labelCol={{ span: 4 }}
                        >
                          <Input
                            bordered={false}
                            readOnly
                            className={`customInputs fw-400 `}
                          
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12}>
                        <Form.Item
                          name="zipShipping"
                          label="Zip"
                          labelAlign="left"
                          labelCol={{ span: 4 }}
                        >
                          <Input
                            bordered={false}
                            readOnly
                            className={`customInputs fw-400 `}
                          
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12}>
                        <Form.Item
                          name="countryShipping"
                          label="Country"
                          labelAlign="left"
                          labelCol={{ span: 4 }}
                        >
                          <Input
                            bordered={false}
                            readOnly
                            className={`customInputs fw-400 `}
                          
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                </Card>
              </Bcol>
            </Brow>
            <Button
              htmlType="submit"
              style={{ display: "none" }}
              ref={submitButton}
              onClick={onFinish}
            />
          </div>

          <SubTitles name={"Date"} />
          <div className="container-fluid pbpx-5 ptpx-5">
            <Brow>
              <Bcol lg={6} xxl={3}>
                <div className="firstSectionRo">
                  <Form.Item
                    label="Start Date"
                    name="startDate"
                    labelAlign="left"
                    labelCol={{ span: 6 }}
                    rules={[
                      { required: true, message: "Start Date is required" },
                    ]}
                  >
                    <DatePicker
                      size="small"
                      tabIndex={6}
                      
                      format="MM-DD-YYYY"
                      disabled={!(formState === "add" || formState === "edit")}
                      
                      allowClear
                      className={`dateInput customInputs fw-400 `}
                      onChange={(e) =>
                        setStartDate(moment(e).format("MM-DD-YYYY"))
                      }
                      defaultValue={moment(sDate)}
                    />
                  </Form.Item>
                </div>
              </Bcol>
              <Bcol lg={6} xxl={3}>
                <div className="firstSectionRo">
                  <Form.Item
                    name="endDate"
                    rules={[
                      { required: true, message: "End Date is required" },
                    ]}
                    label="End Date"
                    labelAlign="left"
                    labelCol={{ span: 6 }}
                  >
                    <DatePicker
                      size="small"
                      tabIndex={7}
                      disabledDate={(d) => !d || d.isBefore(sDate)}
                      format="MM-DD-YYYY"
                      
                      allowClear
                      className={`dateInput customInputs fw-400  `}
                      disabled={!(formState === "add" || formState === "edit")}
                      onChange={(e, s) => onChange(e, s)}
                      onSelect={(e) => onDateSelect(e)}
                    />
                  </Form.Item>
                </div>
              </Bcol>
              <Bcol lg={6} xxl={3}>
                <div className="firstSectionRo">
                  <Form.Item
                    name="cancelDate"
                    label="Cancel Date"
                    labelAlign="left"
                    labelCol={{ span: 6 }}
                    rules={[
                      {
                        required: true,
                        message: "Cancel Date is required",
                      },
                    ]}
                  >
                    <DatePicker
                      size="small"
                      tabIndex={8}
                      disabledDate={(d) => !d || d.isBefore(eDate)}
                      format="MM-DD-YYYY"
                      
                      allowClear
                      className={`dateInput customInputs fw-400  `}
                      disabled={!(formState === "add" || formState === "edit")}
                    />
                  </Form.Item>
                </div>
              </Bcol>
            </Brow>
          </div>
          <SubTitles name={"Sales Information"} />
          <div className="container-fluid pbpx-5 ptpx-5">
            <Brow>
              <Bcol lg={6} xxl={3}>
                <div className="firstSectionRo">
                  <Form.Item
                    name="gender"
                    label="Gender"
                    labelAlign="left"
                    labelCol={{ span: 6 }}
                    rules={[{ required: true, message: "Gender is required" }]}
                  >
                    <Select

                      size="small"
                      tabIndex={9}
                      autoComplete={"nope"}
                      filterOption={(input, option) =>
                        option?.children
                          ?.toLowerCase()
                          .indexOf(input?.toLowerCase()) >= 0
                      }
                      showSearch
                      allowClear
                      loading={saleOrderFormData.loading}
                      disabled={!(formState === "add" || formState === "edit")}
                      defaultValue={
                        formState === "edit"
                          ? form.getFieldValue().gender
                          : null
                      }
                      
                      onChange={(value) => handleGenderChange(value)}
                    >
                      {saleOrderFormData?.payload?.gender.map((item) => {
                        return (
                          <Option
                            key={item.gender_id}
                            disabled={!item.is_active}
                            value={item.gender_id}
                          >
                            {item.gender_name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div>
              </Bcol>
              <Bcol lg={6} xxl={3}>
                <div className="firstSectionRo">
                  <Form.Item
                    name="region"
                    label="Region"
                    labelAlign="left"
                    labelCol={{ span: 6 }}
                    rules={[{ required: true, message: "Region is required" }]}
                  >
                    <Select

                      size="small"
                      tabIndex={10}
                      autoComplete={"nope"}
                      filterOption={(input, option) =>
                        option?.children
                          ?.toLowerCase()
                          .indexOf(input?.toLowerCase()) >= 0
                      }
                      showSearch
                      allowClear
                      loading={saleOrderFormData.loading}
                      disabled={!(formState === "add" || formState === "edit")}
                      defaultValue={form.getFieldValue().region}
                    
                    >
                      {saleOrderFormData?.payload?.adminRegionDetail.map(
                        (item) => {
                          return (
                            <Option key={item.region_id} value={item.region_id}>
                              {item.region_name}
                            </Option>
                          );
                        }
                      )}
                    </Select>
                  </Form.Item>
                </div>
              </Bcol>
              <Bcol lg={6} xxl={3}>
                <div className="firstSectionRo">
                  <Form.Item
                    name="category"
                    label="Category"
                    labelAlign="left"
                    labelCol={{ span: 6 }}
                  >
                    <Input
                      size="small"
                      tabIndex={11}
                      readOnly
                      
                      className={`customInputs fw-400  `}
                      disabled={true}
                    />
                  </Form.Item>
                </div>
              </Bcol>
              <Bcol lg={6} xxl={3}>
                <div className="firstSectionRo">
                  <Form.Item
                    name="location"
                    label="Location"
                    labelAlign="left"
                    labelCol={{ span: 6 }}
                    rules={[
                      { required: true, message: "Location is required" },
                    ]}
                  >
                    <Select

                      size="small"
                      autoComplete={"nope"}
                      filterOption={(input, option) =>
                        option?.children
                          ?.toLowerCase()
                          .indexOf(input?.toLowerCase()) >= 0
                      }
                      showSearch
                      allowClear
                      loading={saleOrderFormData.loading}
                      disabled={!(formState === "add" || formState === "edit")}
                      defaultValue={form.getFieldValue().location}
                      

                      
                      onChange={(value) => handleChange(value)}
                    >
                      {saleOrderFormData?.payload?.locationDetail.map(
                        (item) => {
                          return (
                            <Option
                              key={item.location_id}
                              value={item.location_id}
                            >
                              {item.location_name}
                            </Option>
                          );
                        }
                      )}
                    </Select>
                  </Form.Item>
                </div>
              </Bcol>
              <Bcol lg={6} xxl={3}>
                <div className="firstSectionRo">
                  <Form.Item
                    name="salesPerson"
                    label="Sales Person"
                    labelAlign="left"
                    labelCol={{ span: 6 }}
                    rules={[
                      { required: true, message: "Sales Person is required" },
                    ]}
                  >
                    <Select

                      size="small"
                      tabIndex={12}
                      autoComplete={"nope"}
                      filterOption={(input, option) =>
                        option?.children
                          ?.toLowerCase()
                          .indexOf(input?.toLowerCase()) >= 0
                      }
                      showSearch
                      allowClear
                      loading={saleOrderFormData.loading}
                      disabled={!(formState === "add" || formState === "edit")}
                    
                    >
                      {saleOrderFormData?.payload?.salesPersonDetail.map(
                        (item) => {
                          return (
                            <Option
                              key={item.sales_person_id}
                              value={item.sales_person_id}
                            >
                              {item.salesperson_name}
                            </Option>
                          );
                        }
                      )}
                    </Select>
                  </Form.Item>
                </div>
              </Bcol>
            </Brow>
          </div>

          <Collapse
            bordered={false}
            defaultActiveKey={1}
            className="pbpx-10 bg-white"
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
          >
            <Panel header="Classification" key="1">
              <Tabs
                size="small"
                type="card"
                tabBarGutter={1}
                defaultActiveKey="shipping"
                centered
                onChange={(key) => handleTab(key)}
              >
                <TabPane tab="Shipping" key="shipping">
                  <div className="container-fluid pbpx-0 ptpx-5">
                    <Brow>
                      <Bcol lg={6} xl={4} xxl={3}>
                        <div className="firstSectionRo">
                          <Form.Item
                            name="shippingAccountNo"
                            label="Shipping Account No"
                            labelAlign="left"
                            labelCol={{ span: 10 }}
                            rules={[
                              {
                                required: true,
                                message: "Shipping Account No is required",
                              },
                            ]}
                          >
                            <Input
                              defaultValue={0}
                              size="small"
                              tabIndex={14}
                              
                              className={`customInputs fw-400 `}
                            
                            
                            
                            />
                          </Form.Item>
                        </div>
                      </Bcol>
                      <Bcol lg={6} xl={4} xxl={3}>
                        <div className="firstSectionRo">
                          <Form.Item
                            name="shipViaId"
                            label="Ship Via"
                            labelAlign="left"
                            labelCol={{ span: 10 }}
                            rules={[
                              {
                                required: true,
                                message: "Ship Via is required",
                              },
                            ]}
                          >
                            <Select


                              size="small"
                              tabIndex={15}
                              autoComplete={"nope"}
                              
                              
                              
                              allowClear
                              loading={saleOrderFormData.loading}
                              defaultValue={form.getFieldValue().shipViaId}
                              
                              onChange={(value) => handleChange(value)}
                            >
                              {saleOrderFormData?.payload?.shipVia.map(
                                (item) => {
                                  return (
                                    <Option
                                      key={item.courier_service_id}
                                      disabled={!item.is_active}
                                      value={item.courier_service_id}
                                    >
                                      {item.service_name}
                                    </Option>
                                  );
                                }
                              )}
                            </Select>
                          </Form.Item>
                        </div>
                      </Bcol>
                      <Bcol lg={6} xl={4} xxl={3}>
                        <div className="firstSectionRo">
                          <Form.Item
                            name="shippingMethodId"
                            label="Shipping Method"
                            labelAlign="left"
                            labelCol={{ span: 10 }}
                            rules={[
                              {
                                required: true,
                                message: "Please select Shipping Method",
                              },
                            ]}
                          >
                            <Select
                              className={``}
                              size="small"
                              tabIndex={16}
                              autoComplete={"nope"}
                              allowClear
                              loading={saleOrderFormData.loading}
                              disabled={
                                !(formState === "add" || formState === "edit")
                              }
                              defaultValue={
                                form.getFieldValue().shippingMethodId
                              }
                              
                              onChange={(e) => handleChange(e)}
                            >
                              {saleOrderFormData?.payload?.adminShipMethod.map(
                                (item) => {
                                  return (
                                    <Option
                                      key={item.shipping_method_id}
                                      disabled={!item.is_active}
                                      value={item.shipping_method_id}
                                    >
                                      {item.shipping_method_name}
                                    </Option>
                                  );
                                }
                              )}
                            </Select>
                          </Form.Item>
                        </div>
                      </Bcol>
                      <Bcol lg={6} xl={4} xxl={3}>
                        <div className="firstSectionRo">
                          <Form.Item
                            name="shippingCost"
                            label="Shipping Cost"
                            labelAlign="left"
                            labelCol={{ span: 10 }}
                            rules={[
                              {
                                required: true,
                                message: "Shipping Cost is required",
                              },
                            ]}
                          
                          
                          
                          
                          >
                            <InputNumber
                              defaultValue={0}
                              size="small"
                              onInput={(val) => {
                                form.setFieldsValue({ shippingCost: val });
                                setShippingCharges(val);
                              }}
                              
                              
                              
                              

                              
                              tabIndex={17}
                              disabled={
                                !(formState === "add" || formState === "edit")
                              }
                              
                              className={`customInputs fw-400 `}

                            />
                          </Form.Item>
                        </div>
                      </Bcol>

                      <Bcol lg={6} xl={4} xxl={3}>
                        <div className="firstSectionRo">
                          <Form.Item
                            name="shippingTaxCode"
                            label="Shipping Tax Code"
                            labelAlign="left"
                            labelCol={{ span: 10 }}
                            rules={[
                              {
                                required: true,
                                message: "Shipping Tax Code is required",
                              },
                            ]}
                          >
                            <Select

                              size="small"
                              tabIndex={18}
                              autoComplete={"nope"}
                              allowClear
                              loading={saleOrderFormData.loading}
                              disabled={
                                !(formState === "add" || formState === "edit")
                              }
                              defaultValue={
                                form.getFieldValue().shippingTaxCode
                              }
                              
                              onChange={(value) => handleChange(value)}
                            >
                              {saleOrderFormData?.payload?.adminShipTaxCode.map(
                                (item) => {
                                  return (
                                    <Option
                                      key={item.ship_tax_id}
                                      disabled={!item.is_active}
                                      value={item.ship_tax_id}
                                    >
                                      {item.ship_tax_description}
                                    </Option>
                                  );
                                }
                              )}
                            </Select>
                          </Form.Item>
                        </div>
                      </Bcol>
                      <Bcol lg={6} xl={4} xxl={3}>
                        <div className="firstSectionRo">
                          <Form.Item
                            name="shippingTaxRate"
                            label="Shipping Tax Rate"
                            labelAlign="left"
                            labelCol={{ span: 10 }}
                            rules={[
                              {
                                required: true,
                                message: "Shipping Tax Rate is required",
                              },
                            ]}
                          >
                            <InputNumber
                              size="small"
                              tabIndex={19}
                              
                              className={`w-100 customInputs fw-400 `}
                            
                            
                            
                            />
                          </Form.Item>
                        </div>
                      </Bcol>
                      <Bcol lg={6} xl={4} xxl={3}>
                        <div className="firstSectionRo">
                          <Form.Item
                            name="freightTermId"
                            label="Freight Term"
                            labelAlign="left"
                            labelCol={{ span: 10 }}
                            rules={[
                              {
                                required: true,
                                message: "Please Select Freight Term",
                              },
                            ]}
                          >
                            <Select

                              size="small"
                              tabIndex={20}
                              autoComplete={"nope"}
                              allowClear
                              loading={saleOrderFormData.loading}
                              disabled={
                                !(formState === "add" || formState === "edit")
                              }
                              defaultValue={form.getFieldValue().freightTermId}
                              
                              onChange={(value) => handleChange(value)}
                            >
                              {saleOrderFormData?.payload?.freightTerm.map(
                                (item) => {
                                  return (
                                    <Option
                                      key={item.freight_term_id}
                                      disabled={!item.is_active}
                                      value={item.freight_term_id}
                                    >
                                      {item.freight_term_name}
                                    </Option>
                                  );
                                }
                              )}
                            </Select>
                          </Form.Item>
                        </div>
                      </Bcol>
                      <Bcol lg={6} xl={4} xxl={3}>
                        <div className="firstSectionRo">
                          <Form.Item
                            name="freightAmount"
                            label="Freight Amount"
                            labelAlign="left"
                            labelCol={{ span: 10 }}
                            rules={[
                              {
                                required: true,
                                message: "Freight Amount is required",
                              },
                            ]}
                          >
                            <InputNumber
                              defaultValue={0}
                              size="small"
                              tabIndex={21}
                              
                              
                              
                              
                              className={`customInputs w-100 `}
                            />
                          </Form.Item>
                        </div>
                      </Bcol>

                      <Bcol lg={6} xl={4} xxl={3}>
                        <div className="firstSectionRo">
                          <Form.Item
                            name="freightCharges"
                            label="Freight Charges"
                            labelAlign="left"
                            labelCol={{ span: 10 }}
                            rules={[
                              {
                                required: true,
                                message: "Freight Charges is required",
                              },
                            ]}
                          >
                            <InputNumber
                              defaultValue={0}
                              size="small"
                              tabIndex={22}
                              
                              className={`customInputs w-100 `}
                            
                            
                            
                            />
                          </Form.Item>
                        </div>
                      </Bcol>

                      <Bcol lg={6} xl={4} xxl={3}>
                        <div className="firstSectionRo">
                          <Form.Item
                            name="berganBillingId"
                            label="Bergan Billing"
                            labelAlign="left"
                            labelCol={{ span: 10 }}
                            rules={[
                              {
                                required: true,
                                message: "Please Select Bergan Billing",
                              },
                            ]}
                          >
                            <Select

                              size="small"
                              name="berganBillingIad"
                              autoComplete={"nope"}
                              allowClear
                              loading={saleOrderFormData.loading}
                              
                              
                              
                              defaultValue={
                                form.getFieldValue().berganBillingId
                              }
                              
                              onChange={(value) =>
                                handleChange(value, "berganBillingId")
                              }
                            >
                              {saleOrderFormData?.payload?.berganBilling.map(
                                (item) => {
                                  return (
                                    <Option
                                      key={item.bergan_billing_id}
                                      disabled={!item.is_active}
                                      value={item.bergan_billing_id}
                                    >
                                      {item.bergan_billing_name}
                                    </Option>
                                  );
                                }
                              )}
                            </Select>
                          </Form.Item>
                        </div>
                      </Bcol>

                      <Bcol lg={6} xl={4} xxl={3}>
                        <div className="firstSectionRo">
                          <Form.Item
                            name="berganPaymentTermId"
                            label="Bergan Payment Term"
                            labelAlign="left"
                            labelCol={{ span: 10 }}
                            rules={[
                              {
                                required: true,
                                message: "Please Select Bergan Payment Term",
                              },
                            ]}
                          >
                            <Select

                              size="small"
                              name="py"
                              autoComplete={"nope"}
                              allowClear
                              loading={saleOrderFormData.loading}
                              
                              
                              
                              defaultValue={form.getFieldValue().paymentTermId}
                              
                              onChange={(value) =>
                                handleChange(value, "paymentTermId")
                              }
                            >
                              {saleOrderFormData?.payload?.berganPaymentTerm.map(
                                (item) => {
                                  return (
                                    <Option
                                      key={item.bergen_payment_term_id}
                                      disabled={!item.is_active}
                                      value={item.bergen_payment_term_id}
                                    >
                                      {item.term_description}
                                    </Option>
                                  );
                                }
                              )}
                            </Select>
                          </Form.Item>
                        </div>
                      </Bcol>
                      <Bcol lg={6} xl={4} xxl={3}>
                        <div className="firstSectionRo">
                          <Form.Item
                            name="isShipComplete"
                            label="Ship Complete"
                            labelAlign="left"
                            labelCol={{ span: 10 }}
                          >
                            <Checkbox
                              tabIndex={23}
                              name="isShipComplete"
                              checked={isShipComplete}
                              onChange={(e) => handleCheckBox(e)}
                              disabled={
                                !(formState === "add" || formState === "edit")
                              }
                            ></Checkbox>
                          </Form.Item>
                        </div>
                      </Bcol>
                      <Bcol lg={6} xl={4} xxl={3}></Bcol>
                    </Brow>
                  </div>
                </TabPane>
                <TabPane tab="Billing" key="billing">
                  <div className="container-fluid pbpx-0 ptpx-15">
                    <Brow>
                      <Bcol lg={6} xl={4} xxl={3}>
                        <div className="firstSectionRo">
                          <Form.Item
                            name="termForPrint"
                            label="Term For Print"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                          >
                            <Select

                              size="small"
                              autoComplete={"nope"}
                              allowClear
                              loading={saleOrderFormData.loading}
                              disabled={true}
                              defaultValue={form.getFieldValue().termForPrint}
                              
                              onChange={(value) => handleChange(value)}
                            >
                              {saleOrderFormData?.payload?.termForPrint.map(
                                (item) => {
                                  return (
                                    <Option
                                      key={item.term_id}
                                      disabled={!item.is_active}
                                      value={item.term_id}
                                    >
                                      {item.term_name}
                                    </Option>
                                  );
                                }
                              )}
                            </Select>
                          </Form.Item>
                        </div>
                      </Bcol>
                      <Bcol lg={6} xl={4} xxl={3}>
                        <div className="firstSectionRo">
                          <Form.Item
                            name="paymentTermId"
                            label="Payment Term"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            rules={[
                              {
                                required: true,
                                message: "Please Select Payment Term",
                              },
                            ]}
                          >
                            <Select

                              size="small"
                              name="paymentTermId"
                              autoComplete={"nope"}
                              allowClear
                              loading={saleOrderFormData.loading}
                              disabled={
                                !(formState === "add" || formState === "edit")
                              }
                              defaultValue={form.getFieldValue().paymentTermId}
                              
                              onChange={(value) =>
                                handleChange(value, "paymentTermId")
                              }
                            >
                              {saleOrderFormData?.payload?.customerTerm.map(
                                (item) => {
                                  return (
                                    <Option
                                      key={item.term_id}
                                      disabled={!item.is_active}
                                      value={item.term_id}
                                    >
                                      {item.term_name}
                                    </Option>
                                  );
                                }
                              )}
                            </Select>
                          </Form.Item>
                        </div>
                      </Bcol>
                    </Brow>
                  </div>
                </TabPane>
                <TabPane disabled={enableEDI} tab="EDI" key="edi">
                  <div className="firstSection">
                    <div className="firstSectionRow">
                      <Form.Item
                        name="markForCode"
                        label="Code"
                        labelAlign="left"
                        labelCol={{ span: 10 }}
                        rules={[
                          { required: !enableEDI, message: "Code is required" },
                        ]}
                      >
                        <Input
                          size="small"
                          
                          className={`customInputs `}
                          disabled={
                            !(formState === "add" || formState === "edit") ||
                            enableEDI
                          }
                        />
                      </Form.Item>
                    </div>
                    <div className="firstSectionRow">
                      <Form.Item
                        name="markForCompanyName"
                        label="Company Name"
                        labelAlign="left"
                        labelCol={{ span: 10 }}
                        rules={[
                          {
                            required: !enableEDI,
                            message: "Company Name is required",
                          },
                        ]}
                      >
                        <Input
                          size="small"
                          
                          className={`customInputs`}
                          disabled={
                            !(formState === "add" || formState === "edit") ||
                            enableEDI
                          }
                        />
                      </Form.Item>
                    </div>
                    <div className="firstSectionRow">
                      <Form.Item
                        name="markForAddress1"
                        label="Address 1"
                        labelAlign="left"
                        labelCol={{ span: 10 }}
                        rules={[
                          {
                            required: !enableEDI,
                            message: "Address 1 is required",
                          },
                        ]}
                      >
                        <Input
                          size="small"
                          
                          className={`customInputs`}
                          disabled={
                            !(formState === "add" || formState === "edit") ||
                            enableEDI
                          }
                        />
                      </Form.Item>
                    </div>
                    <div className="firstSectionRow">
                      <Form.Item
                        name="markForAddress2"
                        label="Address 2"
                        labelAlign="left"
                        labelCol={{ span: 10 }}
                        rules={[
                          {
                            required: !enableEDI,
                            message: "Address 2 is required",
                          },
                        ]}
                      >
                        <Input
                          size="small"
                          
                          className={`customInputs`}
                          disabled={
                            !(formState === "add" || formState === "edit") ||
                            enableEDI
                          }
                        />
                      </Form.Item>
                    </div>
                    <div className="firstSectionRow">
                      <Form.Item
                        name="markForCity"
                        label="City"
                        labelAlign="left"
                        labelCol={{ span: 10 }}
                        rules={[
                          { required: !enableEDI, message: "City is required" },
                        ]}
                      >
                        <Input
                          size="small"
                          
                          className={`customInputs`}
                          disabled={
                            !(formState === "add" || formState === "edit") ||
                            enableEDI
                          }
                        />
                      </Form.Item>
                    </div>
                    <div className="firstSectionRow">
                      <Form.Item
                        name="markForState"
                        label="State"
                        labelAlign="left"
                        labelCol={{ span: 10 }}
                        rules={[
                          {
                            required: !enableEDI,
                            message: "State is required",
                          },
                        ]}
                      >
                        <Input
                          size="small"
                          
                          className={`customInputs`}
                          disabled={
                            !(formState === "add" || formState === "edit") ||
                            enableEDI
                          }
                        />
                      </Form.Item>
                    </div>
                    <div className="firstSectionRow">
                      <Form.Item
                        name="markForZip"
                        label="Zip"
                        labelAlign="left"
                        labelCol={{ span: 10 }}
                        rules={[
                          { required: !enableEDI, message: "Zip is required" },
                        ]}
                      >
                        <Input
                          size="small"
                          
                          className={`customInputs`}
                          disabled={
                            !(formState === "add" || formState === "edit") ||
                            enableEDI
                          }
                        />
                      </Form.Item>
                    </div>
                    <div className="firstSectionRow">
                      <Form.Item
                        name="markForCountry"
                        label="Country"
                        labelAlign="left"
                        labelCol={{ span: 10 }}
                        rules={[
                          {
                            required: !enableEDI,
                            message: "Country is required",
                          },
                        ]}
                      >
                        <Input
                          size="small"
                          
                          className={`customInputs`}
                          disabled={
                            !(formState === "add" || formState === "edit") ||
                            enableEDI
                          }
                        />
                      </Form.Item>
                    </div>
                    <div className="firstSectionRow">
                      <Form.Item
                        name="markForEmail"
                        label="Email"
                        labelAlign="left"
                        labelCol={{ span: 10 }}
                        rules={[
                          {
                            required: !enableEDI,
                            message: "Email is required",
                          },
                          {
                            type: "email",
                            message: "Only email allowed",
                          },
                        ]}
                      >
                        <Input
                          size="small"
                          type="email"
                          
                          className={`customInputs`}
                          disabled={
                            !(formState === "add" || formState === "edit") ||
                            enableEDI
                          }
                        />
                      </Form.Item>
                    </div>
                    <div className="firstSectionRow">
                      <Form.Item
                        name="markForPhone"
                        label="Phone"
                        labelAlign="left"
                        labelCol={{ span: 10 }}
                        rules={[
                          {
                            required: !enableEDI,
                            message: "Phone is required",
                          },
                        ]}
                      >
                        <Input
                          size="small"
                          
                          className={`customInputs`}
                          disabled={
                            !(formState === "add" || formState === "edit") ||
                            enableEDI
                          }
                        />
                      </Form.Item>
                    </div>
                  </div>
                </TabPane>
                <TabPane tab="Other" key="other">
                  <div className="container-fluid pbpx-5 ptpx-15">
                    <Brow>
                      <Bcol lg={6} xl={4} xxl={3}>
                        <div className="firstSectionRo">
                          <Form.Item
                            name="orderStatus"
                            label="Order Status"
                            labelAlign="left"
                            labelCol={{ span: 10 }}
                            rules={[
                              {
                                required: true,
                                message: "Please Select Order Status",
                              },
                            ]}
                          >
                            <Select

                              size="small"
                              autoComplete={"nope"}
                              allowClear
                              loading={saleOrderFormData.loading}
                              disabled={
                                !(formState === "add" || formState === "edit")
                              }
                              defaultValue={form.getFieldValue().orderStatus}
                            
                            >
                              {saleOrderFormData?.payload?.orderStatusDetail.map(
                                (item) => {
                                  return (
                                    <Option
                                      key={item.order_status_id}
                                      value={item.order_status_id}
                                    >
                                      {item.status_name}
                                    </Option>
                                  );
                                }
                              )}
                            </Select>
                          </Form.Item>
                        </div>
                      </Bcol>
                      <Bcol lg={6} xl={4} xxl={3}>
                        <div className="firstSectionRo">
                          <Form.Item
                            name="memo"
                            label="Remarks"
                            labelAlign="left"
                            labelCol={{ span: 10 }}
                          >
                            <Input
                              size="small"
                              
                              className={`customInputs`}
                              disabled={
                                !(formState === "add" || formState === "edit")
                              }
                            />
                          </Form.Item>
                        </div>
                      </Bcol>
                      <Bcol lg={6} xl={4} xxl={3}>
                        <div className="firstSectionRo">
                          <Form.Item
                            name="factor"
                            label="Factor"
                            labelAlign="left"
                            labelCol={{ span: 10 }}
                          >
                            <Select

                              size="small"
                              autoComplete={"nope"}
                              filterOption={(input, option) =>
                                option?.children
                                  ?.toLowerCase()
                                  .indexOf(input?.toLowerCase()) >= 0
                              }
                              showSearch
                              allowClear
                              loading={saleOrderFormData.loading}
                              disabled={
                                !(formState === "add" || formState === "edit")
                              }
                              defaultValue={
                                formState === "edit"
                                  ? form.getFieldValue().factor
                                  : null
                              }
                              
                              onChange={(value) => handleChange(value)}
                            >
                              {saleOrderFormData?.payload?.factorDetail.map(
                                (item) => {
                                  return (
                                    <Option
                                      key={item.factor_id}
                                      disabled={!item.is_active}
                                      value={item.factor_id}
                                    >
                                      {item.factor_name}
                                    </Option>
                                  );
                                }
                              )}
                            </Select>
                          </Form.Item>
                        </div>
                      </Bcol>
                      <Bcol lg={6} xl={4} xxl={3}>
                        <div className="firstSectionRo">
                          <Form.Item
                            name="dc"
                            label="DC#"
                            labelAlign="left"
                            labelCol={{ span: 10 }}
                          >
                            <InputNumber
                              size="small"
                              
                              className={`customInputs fw-400 `}
                              disabled={
                                !(formState === "add" || formState === "edit")
                              }
                            />
                          </Form.Item>
                        </div>
                      </Bcol>
                      <Bcol lg={6} xl={4} xxl={3}>
                        <div className="firstSectionRo">
                          <Form.Item
                            name="resaleCertificateNo"
                            label="Resale Certificate"
                            labelAlign="left"
                            labelCol={{ span: 10 }}
                          >
                            <Input
                              size="small"
                              
                              className={`customInputs fw-400 `}
                              disabled={
                                !(formState === "add" || formState === "edit")
                              }
                            />
                          </Form.Item>
                        </div>
                      </Bcol>
                      <Bcol lg={6} xl={4} xxl={3}>
                        <div className="firstSectionRo">
                          <Form.Item
                            label="Resale Expiry Date"
                            name="resaleCertificateDate"
                            labelAlign="left"
                            labelCol={{ span: 10 }}
                          >
                            <DatePicker
                              size="small"
                              disabled={
                                !(formState === "add" || formState === "edit")
                              }
                              defaultValue={
                                formState === "edit"
                                  ? form.getFieldValue().resaleCertificateDate
                                  : null
                              }
                              format="MM-DD-YYYY"
                              
                              allowClear
                              className="dateInput customInputs"
                              onChange={(e) => setResaleExpDate(moment(e))}
                            />
                          </Form.Item>
                        </div>
                      </Bcol>
                      <Bcol lg={6} xl={4} xxl={3}>
                        <div className="firstSectionRo">
                          <Form.Item
                            name="store"
                            label="Store"
                            labelAlign="left"
                            labelCol={{ span: 10 }}
                          >
                            <Input
                              size="small"
                              disabled={
                                !(formState === "add" || formState === "edit")
                              }
                              
                              className={`customInputs fw-400 `}
                            />
                          </Form.Item>
                        </div>
                      </Bcol>
                      <Bcol lg={6} xl={4} xxl={3}>
                        <div className="firstSectionRo">
                          <Form.Item
                            name="deptDescription"
                            label="Department Desc"
                            labelAlign="left"
                            labelCol={{ span: 10 }}
                          >
                            <Input
                              size="small"
                              disabled={
                                !(formState === "add" || formState === "edit")
                              }
                              
                              className={`customInputs`}
                            />
                          </Form.Item>
                        </div>
                      </Bcol>
                      <Bcol lg={6} xl={4} xxl={3}>
                        <div className="firstSectionRo">
                          <Form.Item
                            name="divisionDescription"
                            label="Division Desc"
                            labelAlign="left"
                            labelCol={{ span: 10 }}
                          >
                            <Input
                              size="small"
                              disabled={
                                !(formState === "add" || formState === "edit")
                              }
                              
                              className={`customInputs fw-400 `}
                            />
                          </Form.Item>
                        </div>
                      </Bcol>
                      <Bcol lg={6} xl={4} xxl={3}>
                        <div className="firstSectionRo">
                          <Form.Item
                            name="divisionCode"
                            label="Division Code"
                            labelAlign="left"
                            labelCol={{ span: 10 }}
                          >
                            <Input
                              size="small"
                              disabled={
                                !(formState === "add" || formState === "edit")
                              }
                              
                              className={`customInputs fw-400 `}
                            />
                          </Form.Item>
                        </div>
                      </Bcol>
                      <Bcol lg={6} xl={4} xxl={3}>
                        <div className="firstSectionRo">
                          <Form.Item
                            name="isClosed"
                            label="Closed"
                            labelAlign="left"
                            labelCol={{ span: 10 }}
                          >
                            <Checkbox
                              checked={isClosed}
                              name="isClosed"
                              onChange={(e) => handleCheckBox(e)}
                              disabled={
                                !(formState === "add" || formState === "edit")
                              }
                            ></Checkbox>
                          </Form.Item>
                        </div>
                      </Bcol>
                    </Brow>
                  </div>
                </TabPane>
              </Tabs>
            </Panel>
          </Collapse>
          <SubTitles name={"Discount"} />
          <div className="container-fluid pbpx-5 ptpx-5">
            <Brow>
              <Bcol lg={6} xxl={3}>
                <div className="firstSectionRo">
                  <Form.Item
                    name="discountId"
                    label="Discount"
                    labelAlign="left"
                    labelCol={{ span: 6 }}
                    rules={[
                      { required: true, message: "Sales Person is required" },
                    ]}
                  >
                    <Select

                      size="small"
                      tabIndex={12}
                      autoComplete={"nope"}
                      filterOption={(input, option) =>
                        option?.children
                          ?.toLowerCase()
                          .indexOf(input?.toLowerCase()) >= 0
                      }
                      showSearch
                      allowClear
                      loading={saleOrderFormData.loading}
                      
                      
                      defaultValue={form.getFieldValue().discountId}
                    >
                      {saleOrderFormData?.payload?.adminDiscount &&
                        saleOrderFormData?.payload?.adminDiscount.map(
                          (item) => {
                            return (
                              <Option
                                key={item.discount_id}
                                value={item.discount_id}
                              >
                                {item.discount_name}
                              </Option>
                            );
                          }
                        )}
                    </Select>
                  </Form.Item>
                </div>
              </Bcol>
              <Bcol lg={6} xxl={3}>
                <div className="firstSectionRo">
                  <Form.Item
                    name="discountCode"
                    label="Discount Code"
                    labelAlign="left"
                    labelCol={{ span: 6 }}
                  >
                    <Input
                      size="small"
                      
                      className={`customInputs `}
                      disabled={!(formState === "add" || formState === "edit")}
                    />
                  </Form.Item>
                </div>
              </Bcol>
              <Bcol lg={6} xxl={3}>
                <div className="firstSectionRo">
                  <Form.Item
                    name="discountBasis"
                    label="Discount Type"
                    labelAlign="left"
                    labelCol={{ span: 6 }}
                  >
                    <Select

                      size="small"
                      tabIndex={9}
                      autoComplete={"nope"}
                      filterOption={(input, option) =>
                        option?.children
                          ?.toLowerCase()
                          .indexOf(input?.toLowerCase()) >= 0
                      }
                      showSearch
                      allowClear
                      loading={saleOrderFormData.loading}
                      disabled={!(formState === "add" || formState === "edit")}
                      defaultValue={
                        formState === "edit"
                          ? form.getFieldValue().discountBasis
                          : null
                      }
                      
                      onChange={(value) => handleDiscountBasis(value)}
                    >
                      <Option key={1} value={"A"}>
                        Amount
                      </Option>
                      <Option key={2} value={"P"}>
                        Percent
                      </Option>
                    </Select>
                  </Form.Item>
                </div>
              </Bcol>
              <Bcol
                className={discountBasis === "A" ? "d-block" : "d-none"}
                lg={6}
                xxl={3}
              >
                <div className="firstSectionRo">
                  <Form.Item
                    name="discount"
                    label="Discount Amount"
                    labelAlign="left"
                    labelCol={{ span: 6 }}
                  >
                    <InputNumber
                      min={0}
                      defaultValue={0}
                      size="small"
                      onInput={(val) => {
                        form.setFieldsValue({ discount: val });
                      }}
                      onChange={discountAmount}
                      disabled={!(formState === "add" || formState === "edit")}
                      
                      className={`customInputs fw-400  `}
                    />
                  </Form.Item>
                </div>
              </Bcol>
              <Bcol
                className={discountBasis === "P" ? "d-block" : "d-none"}
                lg={6}
                xxl={3}
              >
                <div className="firstSectionRo">
                  <Form.Item
                    name="discountPercent"
                    label="Discount Percent"
                    labelAlign="left"
                    labelCol={{ span: 6 }}
                  >
                    <InputNumber
                      min={0}
                      defaultValue={0}
                      size="small"
                      onInput={(val) => {
                        form.setFieldsValue({ discountPercent: val });
                      }}
                      onChange={discountPercent}
                      disabled={!(formState === "add" || formState === "edit")}
                      
                      className={`customInputs fw-400  `}
                    />
                  </Form.Item>
                </div>
              </Bcol>
            </Brow>
          </div>
          <Tabs
            size="small"
            type="card"
            tabBarGutter={2}
            defaultActiveKey="productInfo"
            centered
            onChange={(key) => handleTab(key)}
          >
            <TabPane tab="Product Information" key="productInfo">
              <div className="pbpx-20">
                {/* <Button
                  type="primary"
                  className="modalButton"
                  disabled={!(formState === "edit" || formState === "add")}
                  onClick={showModal}
                >
                  Add Item
                </Button> */}
              </div>
              {(orderItems.length > 0 || visibleItems.length > 0) && (
                <Table
                  type={"company"}
                  loading={ProductSale.loading}
                  scroll={({ x: 600 }, { y: 400 })}
                  size="small"
                  bordered
                  dataSource={visibleItems}
                  columns={columns}
                  pagination={false}
                />
              )}
            </TabPane>
            <TabPane tab="System Information" key="systemInfo">
              <div className="tabs">
                <Tables
                  type="company"
                  scrollX={"50rem"}
                  scroll={360}
                  perPage={15}
                  source={log?.payload?.data}
                  col={logColumns}
                  load={log.loading}
                  paginate
                />
              </div>
            </TabPane>
          </Tabs>
        </div>
      </Form>
    </>
  );
}
