import { GET_SCM_TARGET_YEAR_REQUEST,GET_SCM_TARGET_YEAR_SUCCESS,GET_SCM_TARGET_YEAR_ERROR } from "./types";
import axios from "axios";
import { BASEURL } from "../../constant/config.js";



export const getScmTargetYear = (token, id) => (dispatch) => {
    dispatch({ type: GET_SCM_TARGET_YEAR_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/scmTargetYear`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { id }
    })
        .then((response) => {
            return dispatch({ type: GET_SCM_TARGET_YEAR_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: GET_SCM_TARGET_YEAR_ERROR,
                payload: error.response,
            });
        });
};
