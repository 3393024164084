import React, { useState } from "react";
import { Button, Tooltip, Divider, Tag, Popconfirm, notification } from "antd";
import { PlusOutlined, EditOutlined, FileDoneOutlined, UndoOutlined, SearchOutlined, DeleteOutlined } from "@ant-design/icons";
import "../../../components/CrudButtons/crud.css";
import { useHistory, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { deleteSaleOrderHeader } from "../../../redux/actions/salesOrder.action";
import { ReadCookie } from "../../../utils/readCookie";
import Swal from "sweetalert2";
import { fetchIPAddress } from "../../../utils/fetchIP";


export default function CrudButtons({ save, disable, formState, formType, reset, name, flag, rights, saleOrderNewID, customerCode, formData, userRights, user_type, transactionLock }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const token = ReadCookie("token");
  const queryParams = new URLSearchParams(window.location.search);
  const orderID = queryParams.get("order_id");
  
  
  
  


  
  const confirm = () => {
    if (formData && formData.ptid === 0 || null) {
      
      dispatch(deleteSaleOrderHeader(token, orderID)).then((res) => {
        if (res.type === "DELETE_SALEORDERHEADER_SUCCESS") {
          
          history.push("/sale-order-list");
          
          
          notification.success({
            message:"Sale Order Deleted",
            description:res.payload,
          });
          
          
        }
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Pick Ticket",
        text: "Pick Ticket is Already Created!",
      });
    }
  };

  
  
  
  
  
  
  
  
  
  

  function disableBtns() {
    let result = false;
    
    
    
    
    if (!userRights?.can_edit || formState === "add") {
      result = true;
    }
    if (user_type !== "PK") {
      result = true;
    }
    else {
      result = false;
    }
    return result;

  }

  
  const handleFormState = () => {
    if (formType === "view") {
      return (
        <>
          <Tooltip placement="bottom" title="Search" color="#fbfbfb">
            <Button
              disabled={userRights.can_view === true ? false : true}
              onClick={() => history.push("/sale-order-list")}
              className="iconBtn crudBTN"
              shape="circle"
              icon={<SearchOutlined />}
            />
          </Tooltip>
          <Tooltip placement="bottom" title="Add" color="#fbfbfb">
            <Button
              disabled={userRights.can_add === true ? false : true}
              onClick={() => {
                if (formType === "add") {
                  formState("add");
                } else {
                  history.push("/sale-order?mode=add");
                  formState("add");
                }
              }}
              className="iconBtn crudBTN"
              shape="circle"
              icon={<PlusOutlined />}
            />
          </Tooltip>
        </>
      )
    }

    else if (formType === "add") {
      return (
        <>
          <Tooltip placement="bottom" title="Search" color="#fbfbfb">
            <Button
              disabled={userRights.can_view === true ? false : true}
              onClick={() => history.push("/sale-order-list")}
              className="iconBtn crudBTN"
              shape="circle"
              icon={<SearchOutlined />}
            />
          </Tooltip>
          <Tooltip placement="bottom" title="Save" color="#fbfbfb">
            <Button
              disabled={
                !(
                  (formType === "add" || formType === "edit") &&
                  (name === "product"
                    ? formType === "edit"
                      ? true
                      : flag
                        ? true
                        : false
                    : !disable)
                ) || transactionLock
              }
              onClick={() => save()}
              className="iconBtn crudBTN"
              shape="circle"
              icon={<FileDoneOutlined />}
            />
          </Tooltip>
        </>
      )
    }
    else if (formType === "formView" || formType === "edit") {
      return (
        <>
          <Tooltip placement="bottom" title="Order Adjustment" color="#fbfbfb">
            <Button
              disabled={(formData?.status === "Closed" || !formData?.adjallowed) || disableBtns()}
              
              type="primary"
              className="modalButton cursor-pointer  mr-5"
              onClick={() => {
                history.push({
                  pathname: `/sale-order-adjustment-new/?mode=${"add"}&order_id=${saleOrderNewID}`,
                  state: { detail: 'editt' }
                })
              }}
            >
              Order Adjustment
            </Button>
          </Tooltip>
          {/*Fulfillment Button*/}
          <Tooltip placement="bottom" title="Fulfillment " color="#fbfbfb">
            <Button
              disabled={(formData?.status === "Closed" || formData?.order_status_id === 4 || formData?.order_hold || !formData?.allowed ) || disableBtns()}
              
              type="primary"
              onClick={() => {
                history.push({
                  pathname: `/order-shipment/?mode=${"add"}&order_id=${saleOrderNewID}`,
                  state: { detail: 'editt' }
                })
              }}
              className="modalButton"
            >
              Fulfillment
            </Button>
          </Tooltip>


          {
            userRights.can_print ? (
              <Link

                style={{ paddingLeft: "10px", textDecoration: "none" }}
                target="_blank"
                rel="noopener noreferrer"
                to={{
                  pathname: `/new-sales-report`,
                  search: `?order_header_id=${saleOrderNewID}`,
                }}
              >
                View Report
              </Link>
            ) : ""
          }

          {formType === "formView" && (
            <Tooltip placement="bottom" title="Edit" color="#fbfbfb">
              <Button

                
                disabled={(userRights.can_edit === true ? false : true) || (disable || formType === "view" || formType === "add") || transactionLock}

                onClick={() => formState("edit")}
                className="iconBtn crudBTN"
                shape="circle"
                icon={<EditOutlined />}
              />
            </Tooltip>
          )
          }

          <Tooltip placement="bottom" title="Search" color="#fbfbfb">
            <Button
              disabled={userRights.can_view == true ? false : true}
              onClick={() => history.push("/sale-order-list")}
              className="iconBtn crudBTN"
              shape="circle"
              icon={<SearchOutlined />}
            />
          </Tooltip>
          {
            formType === "view" || formType === "formView" && (
              <Tooltip placement="bottom" title="Add" color="#fbfbfb">
                <Button
                  disabled={userRights.can_add === true ? false : true}


                  onClick={() => {
                    if (formType === "add") {
                      formState("add");
                    } else {
                      history.push("/sale-order?mode=add");
                      formState("add");
                    }
                  }}
                  className="iconBtn crudBTN"
                  shape="circle"
                  icon={<PlusOutlined />}
                />
              </Tooltip>
            )
          }


          {
            formType === "formView" && (
              <Tooltip placement="bottom" title="Delete" color="#fbfbfb"   >
                {rights?.can_delete === false ?
                  <Button disabled={true} className="iconBtn crudBTN" shape="circle" icon={<DeleteOutlined />} /> :
                  <Popconfirm
                    
                    disabled={((disable || (formType === "add" || formType === "view" || formData?.status === "Closed")) || userRights.can_delete === true ? false : true) || transactionLock}
                    placement="topLeft"
                    title={`Are you sure to delete this Item ${formData?.order_header_no}?`}
                    onConfirm={confirm}
                    okText="Yes"

                    cancelText="No">
                    <Button
                      disabled={((disable || (formType === "add" || formType === "view" || formData?.status === "Closed")) || userRights.can_delete === true ? false : true) || transactionLock}
                      className="iconBtn crudBTN"
                      shape="circle"
                      icon={<DeleteOutlined />}
                    />
                  </Popconfirm>
                }
              </Tooltip>
            )
          }

          {
            formType === "Add" || formType === "edit" && (
              <Tooltip placement="bottom" title="Save" color="#fbfbfb">
                <Button
                  disabled={
                    !(
                      (formType === "add" || formType === "edit") &&
                      (name === "product"
                        ? formType === "edit"
                          ? true
                          : flag
                            ? true
                            : false
                        : disable ? false : true)
                    ) || transactionLock
                  }
                  onClick={() => save()}
                  className="iconBtn crudBTN"
                  shape="circle"
                  icon={<FileDoneOutlined />}
                />
              </Tooltip>
            )
          }
        </>
      )

    }
  }

  return (

    <>
      <div className="crudContainer">
        <div>
          {formType === "" ? null : (
            <div className="buttons-div">
              <Tag color={"green"} >
                {formType.toUpperCase()}
              </Tag>
              {
                transactionLock && <Tag color={"red"} key={2}>LOCKED</Tag>
              }
              {formType !== "view" && (
                <Button
                  onClick={() => reset()}
                  className="iconBtn"
                  shape="circle"
                  icon={<UndoOutlined className="fspx-12" />}
                />
              )}
              {
                formType === "add" ? (
                  <Tag color={"green"} >
                    N/A
                  </Tag>
                ) : (
                  <Tag color={"green"} >
                    {formData ? formData?.status : "No Status"}
                  </Tag>
                )
              }
            </div>
          )}
        </div>
        <div>
          {handleFormState()}
        </div>
      </div>
      <Divider />
    </>
  );
}


