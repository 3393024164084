import {
  GET_SEASON_YEAR_ERROR,
  GET_SEASON_YEAR_REQUEST,
  GET_SEASON_YEAR_SUCCESS,
  ADD_SEASON_YEAR_ERROR,
  ADD_SEASON_YEAR_REQUEST,
  ADD_SEASON_YEAR_SUCCESS,
  DELETE_SEASON_YEAR_REQUEST,
  DELETE_SEASON_YEAR_SUCCESS,
  DELETE_SEASON_YEAR_ERROR,
  UPDATE_SEASON_YEAR_ERROR,
  UPDATE_SEASON_YEAR_REQUEST,
  UPDATE_SEASON_YEAR_SUCCESS,

  
  
  
  

} from "./types";
import { BASEURL } from "../../constant/config.js";

const axios = require("axios");

export const getSeasonYear = (token, menu_id) => (dispatch) => {
  dispatch({ type: GET_SEASON_YEAR_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}getAll/seasonYear`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: { menu_id, company_id: 2 }
  })
    .then((response) => {
      return dispatch({ type: GET_SEASON_YEAR_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      return dispatch({
        type: GET_SEASON_YEAR_ERROR,
        payload: error.response,
      });
    });
};

export const addSeasonYear = (token, data, menu_id) => (dispatch) => {
  
  dispatch({ type: ADD_SEASON_YEAR_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}create/seasonYear`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: { season_year: data.seasonYears, is_active: data.check, menu_id:22 },
  })
    .then((response) => {

      return dispatch({ type: ADD_SEASON_YEAR_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      return dispatch({
        type: ADD_SEASON_YEAR_ERROR,
        payload: error.response,
      });
    });
};

export const updateSeasonYear = (token, data, id) => (dispatch) => {
  dispatch({ type: UPDATE_SEASON_YEAR_REQUEST });
  return axios({
    method: "PUT",
    url: `${BASEURL}update/seasonYear/${id}`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: { season_year: data.seasonYears, is_active: data.check },
  })
    .then((response) => {

      return dispatch({ type: UPDATE_SEASON_YEAR_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      return dispatch({
        type: UPDATE_SEASON_YEAR_ERROR,
        payload: error.response,
      });
    });
};

export const deleteSeasonYear = (token, id) => (dispatch) => {
  dispatch({ type: DELETE_SEASON_YEAR_REQUEST });
  return axios({
    method: "DELETE",
    url: `${BASEURL}delete/seasonYear/${id}`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {

      return dispatch({ type: DELETE_SEASON_YEAR_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      return dispatch({
        type: DELETE_SEASON_YEAR_ERROR,
        payload: error.response,
      });
    });
};


























