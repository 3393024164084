import {
    ADD_CUSTOMER_REFUND_IMPACT_REQUEST,
    ADD_CUSTOMER_REFUND_IMPACT_SUCCESS,
    ADD_CUSTOMER_REFUND_IMPACT_ERROR
} from "./types";

import { BASEURL } from "../../constant/config.js";

const axios = require("axios");



export const addCustomerRefundImpact = (token, data) => (dispatch) => {
    dispatch({ type: ADD_CUSTOMER_REFUND_IMPACT_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}create/customerrefundimpact`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: {refund_header_id: data}
    })

        .then((response) => {
            return dispatch({ type: ADD_CUSTOMER_REFUND_IMPACT_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: ADD_CUSTOMER_REFUND_IMPACT_ERROR,
                payload: error.response,
            });
        });
};
