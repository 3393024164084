
import {
    GET_INVOICE_PRINT_ERROR, GET_INVOICE_PRINT_REQUEST, GET_INVOICE_PRINT_SUCCESS,
} from "../actions/types";

export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case GET_INVOICE_PRINT_REQUEST:
            return {
                loading: true,
            };
        case GET_INVOICE_PRINT_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_INVOICE_PRINT_ERROR:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
}
