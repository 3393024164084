import {
	GET_ALL_CREDIT_MEMO_REQUEST,
	GET_ALL_CREDIT_MEMO_SUCCESS,
	GET_ALL_CREDIT_MEMO_ERROR
} from "../actions/types";
export default function (state = [], action) {
	const { type, payload } = action;
	switch (type) {
		case GET_ALL_CREDIT_MEMO_REQUEST:
			return {
				loading: true,
			};
		case GET_ALL_CREDIT_MEMO_SUCCESS:
			return {
				...state,
				loading: false,
				payload: payload,
			};
		case GET_ALL_CREDIT_MEMO_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};
		default:
			return state;
	}
}
