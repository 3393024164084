import { GET_DEFAULT_COMPANY_ERROR, GET_DEFAULT_COMPANY_REQUEST, GET_DEFAULT_COMPANY_SUCCESS } from "../actions/types"

export default function (state = null, action) {
    const { type, payload } = action;
    switch (type) {
        case GET_DEFAULT_COMPANY_REQUEST:
            return {
                loading: true,
            };
        case GET_DEFAULT_COMPANY_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_DEFAULT_COMPANY_ERROR:
            return {
                ...state,
                loading: false,
                payload
            };

        default:
            return state;
    }
}