import {
    GET_PRODUCTSTATUS_ERROR, GET_PRODUCTSTATUS_REQUEST, GET_PRODUCTSTATUS_SUCCESS,
    ADD_PRODUCTSTATUS_ERROR, ADD_PRODUCTSTATUS_REQUEST, ADD_PRODUCTSTATUS_SUCCESS,
    DELETE_PRODUCTSTATUS_REQUEST, DELETE_PRODUCTSTATUS_SUCCESS, DELETE_PRODUCTSTATUS_ERROR,
    UPDATE_PRODUCTSTATUS_ERROR, UPDATE_PRODUCTSTATUS_REQUEST, UPDATE_PRODUCTSTATUS_SUCCESS

} from "../actions/types"
export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case GET_PRODUCTSTATUS_REQUEST:
            return {
                loading: true,
            };
        case GET_PRODUCTSTATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_PRODUCTSTATUS_ERROR:
            return {
                ...state,
                loading: false
            };
        case ADD_PRODUCTSTATUS_REQUEST:
            return {
                loading: true,
            };
        case ADD_PRODUCTSTATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case ADD_PRODUCTSTATUS_ERROR:
            return {
                ...state,
                loading: false
            };
        case DELETE_PRODUCTSTATUS_REQUEST:
            return {
                loading: true,
            };
        case DELETE_PRODUCTSTATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case DELETE_PRODUCTSTATUS_ERROR:
            return {
                ...state,
                loading: false
            };
        case UPDATE_PRODUCTSTATUS_REQUEST:
            return {
                loading: true,
            };
        case UPDATE_PRODUCTSTATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case UPDATE_PRODUCTSTATUS_ERROR:
            return {
                ...state,
                loading: false
            };

        default:
            return state;
    }
}