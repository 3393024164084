import {
    GET_VENDOR_CATEGORY_REQUEST, GET_VENDOR_CATEGORY_SUCCESS, GET_VENDOR_CATEGORY_ERROR, ADD_VENDOR_CATEGORY_REQUEST, ADD_VENDOR_CATEGORY_SUCCESS, ADD_VENDOR_CATEGORY_ERROR,
    UPDATE_VENDOR_CATEGORY_REQUEST, UPDATE_VENDOR_CATEGORY_SUCCESS, UPDATE_VENDOR_CATEGORY_ERROR, DELETE_VENDOR_CATEGORY_REQUEST, DELETE_VENDOR_CATEGORY_ERROR, DELETE_VENDOR_CATEGORY_SUCCESS,
} from "./types";
import { BASEURL } from "../../constant/config.js";

const axios = require("axios");

export const getVendorCategory = (token) => (dispatch) => {
    dispatch({ type: GET_VENDOR_CATEGORY_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/scmVendorCategory`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },

    })
        .then((response) => {
            return dispatch({ type: GET_VENDOR_CATEGORY_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: GET_VENDOR_CATEGORY_ERROR,
                payload: error.response,
            });
        });
};

export const addVendorCategory = (token, data) => (dispatch) => {
    dispatch({ type: ADD_VENDOR_CATEGORY_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}create/scmVendorCategory`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { category_name: data.vendorCatName, is_active: data.check, },
    })
        .then((response) => {

            return dispatch({ type: ADD_VENDOR_CATEGORY_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: ADD_VENDOR_CATEGORY_ERROR,
                payload: error.response,
            });
        });
};

export const updateVendorCategory = (token, data, id) => (dispatch) => {
    dispatch({ type: UPDATE_VENDOR_CATEGORY_REQUEST });
    return axios({
        method: "PUT",
        url: `${BASEURL}update/scmVendorCategory/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { category_name: data.vendorCatName, is_active: data.check },
    })
        .then((response) => {

            return dispatch({ type: UPDATE_VENDOR_CATEGORY_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: UPDATE_VENDOR_CATEGORY_ERROR,
                payload: error.response,
            });
        });
};

export const deleteVendorCategory = (token, id) => (dispatch) => {
    dispatch({ type: DELETE_VENDOR_CATEGORY_REQUEST });
    return axios({
        method: "DELETE",
        url: `${BASEURL}delete/scmVendorCategory/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
    })
        .then((response) => {

            return dispatch({ type: DELETE_VENDOR_CATEGORY_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: DELETE_VENDOR_CATEGORY_ERROR,
                payload: error.response,
            });
        });
};
