import {
  GET_ACCOUNT_PAYABLE_REQUEST,
  GET_ACCOUNT_PAYABLE_SUCCESS,
  GET_ACCOUNT_PAYABLE_ERROR,
} from "./types";
import { BASEURL } from "../../constant/config.js";

const axios = require("axios");


export const getAccountPayable = (token) => (dispatch) => {
  dispatch({ type: GET_ACCOUNT_PAYABLE_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}getAll/Accountpayable`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {
      return dispatch({ type: GET_ACCOUNT_PAYABLE_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      return dispatch({
        type: GET_ACCOUNT_PAYABLE_ERROR,
        payload: error.response,
      });
    });
};

