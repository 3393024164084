import {
    GET_TOTAL_OTS_BY_SIZE_REQUEST,
    GET_TOTAL_OTS_BY_SIZE_SUCCESS,
    GET_TOTAL_OTS_BY_SIZE_ERROR
} from "./types";
import { BASEURL } from "../../constant/config.js";
import moment from "moment";

const axios = require("axios");
export const getTotalOTSbySize = (token, values) => (dispatch) => {

    

    dispatch({ type: GET_TOTAL_OTS_BY_SIZE_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/getImediateOtsBySize`,

        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { ...values }
    })
        .then((response) => {
            return dispatch({ type: GET_TOTAL_OTS_BY_SIZE_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            if (error.response.data.data === "No Record Found !") {
                return dispatch({
                    type: GET_TOTAL_OTS_BY_SIZE_ERROR,
                    payload: [],
                });
            }
            return dispatch({
                type: GET_TOTAL_OTS_BY_SIZE_ERROR,
                payload: error.response,
            });
        });
};
