import React from 'react'
import "./dashboard.css"
import { Line } from 'react-chartjs-2';

import { TwitterOutlined } from "@ant-design/icons"
import SaleYearly from '../../components/Charts/SalesByYearly'
import MultiTypeChart from '../../components/Charts/MultiTypeChart';
export default function Dashboard() {
    return (
        <>
            {/* <h2>ERP</h2> */}
            {/* <div className='main' >
                <div className='drop'  >
                    <TwitterOutlined />
                </div>
            </div> */}
            <div style={{ width: "50%", height: "40%" }}>
                {/* <div style={{display:"flex", alignItems:"center", justifyContent:"center"}}> */}
                {/* <SaleYearly /> */}
                {/* <MultiTypeChart /> */}
                {/* </div> */}
            </div>
        </>
    )
}
