import {
    GET_BARGAN_PAYMENT_TERM_REQUEST,GET_BARGAN_PAYMENT_TERM_ERROR,GET_BARGAN_PAYMENT_TERM_SUCCESS,   
    ADD_BARGAN_PAYMENT_TERM_REQUEST,ADD_BARGAN_PAYMENT_TERM_ERROR,ADD_BARGAN_PAYMENT_TERM_SUCCESS,   
    UPDATE_BARGAN_PAYMENT_TERM_REQUEST,UPDATE_BARGAN_PAYMENT_TERM_ERROR,UPDATE_BARGAN_PAYMENT_TERM_SUCCESS,
    DELETE_BARGAN_PAYMENT_TERM_REQUEST,DELETE_BARGAN_PAYMENT_TERM_ERROR,DELETE_BARGAN_PAYMENT_TERM_SUCCESS,

} from "../actions/types"
export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case GET_BARGAN_PAYMENT_TERM_REQUEST:
            return {
                loading: true,
            };
        case GET_BARGAN_PAYMENT_TERM_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_BARGAN_PAYMENT_TERM_ERROR:
            return {
                ...state,
                loading: false
            };
        case ADD_BARGAN_PAYMENT_TERM_REQUEST:
            return {
                loading: true,
            };
        case ADD_BARGAN_PAYMENT_TERM_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case ADD_BARGAN_PAYMENT_TERM_ERROR:
            return {
                ...state,
                loading: false
            };
        case DELETE_BARGAN_PAYMENT_TERM_REQUEST:
            return {
                loading: true,
            };
        case DELETE_BARGAN_PAYMENT_TERM_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case DELETE_BARGAN_PAYMENT_TERM_ERROR:
            return {
                ...state,
                loading: false
            };
        case UPDATE_BARGAN_PAYMENT_TERM_REQUEST:
            return {
                loading: true,
            };
        case UPDATE_BARGAN_PAYMENT_TERM_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case UPDATE_BARGAN_PAYMENT_TERM_ERROR:
            return {
                ...state,
                loading: false
            };

        default:
            return state;
    }
}