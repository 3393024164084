import {
    GET_PICKTICKET_STATUS_REQUEST, GET_PICKTICKET_STATUS_ERROR, GET_PICKTICKET_STATUS_SUCCESS, ADD_PICKTICKET_STATUS_REQUEST, ADD_PICKTICKET_STATUS_ERROR, ADD_PICKTICKET_STATUS_SUCCESS,
    UPDATE_PICKTICKET_STATUS_REQUEST, UPDATE_PICKTICKET_STATUS_ERROR, UPDATE_PICKTICKET_STATUS_SUCCESS, DELETE_PICKTICKET_STATUS_REQUEST, DELETE_PICKTICKET_STATUS_ERROR, DELETE_PICKTICKET_STATUS_SUCCESS
} from "../actions/types"
export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case GET_PICKTICKET_STATUS_REQUEST:
            return {
                loading: true,
            };
        case GET_PICKTICKET_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_PICKTICKET_STATUS_ERROR:
            return {
                ...state,
                loading: false
            };
        case ADD_PICKTICKET_STATUS_REQUEST:
            return {
                loading: true,
            };
        case ADD_PICKTICKET_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case ADD_PICKTICKET_STATUS_ERROR:
            return {
                ...state,
                loading: false
            };
        case DELETE_PICKTICKET_STATUS_REQUEST:
            return {
                loading: true,
            };
        case DELETE_PICKTICKET_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case DELETE_PICKTICKET_STATUS_ERROR:
            return {
                ...state,
                loading: false
            };
        case UPDATE_PICKTICKET_STATUS_REQUEST:
            return {
                loading: true,
            };
        case UPDATE_PICKTICKET_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case UPDATE_PICKTICKET_STATUS_ERROR:
            return {
                ...state,
                loading: false
            };

        default:
            return state;
    }
}