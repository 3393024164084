import {
    GET_CUSTOMER_BALANCES_REQUEST,
    GET_CUSTOMER_BALANCES_SUCCESS,
    GET_CUSTOMER_BALANCES_ERROR
} from "../actions/types";
import { BASEURL } from "../../constant/config.js";
const axios = require("axios");

export const getCustomerBalances = (token, data) => (dispatch) => {
    dispatch({ type: GET_CUSTOMER_BALANCES_REQUEST });

    return axios({
        method: "POST",
        url: `${BASEURL}getAll/CustomerBalances`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: {
            customer_name: (data.customer === "All" || data.customer === undefined) ? null : data.customer,
        }
    })
        .then((response) => {
            return dispatch({ type: GET_CUSTOMER_BALANCES_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            if (error.response.data.data === "No Record Found !") {
                return dispatch({
                    type: GET_CUSTOMER_BALANCES_ERROR,
                    payload: [],
                });
            }
            return dispatch({
                type: GET_CUSTOMER_BALANCES_ERROR,
                payload: error.response,
            });
        });
};
