import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import PageHeader from "../../components/PageHeader/PageHeader";
import { Row as Brow, Col as Bcol } from "reactstrap";
import { Button, Form, Table, Spin, Input } from "antd";
import moment from "moment";
import { ReadCookie } from "../../utils/readCookie";
import numeral from "numeral";
import { CSVLink } from "react-csv";
import { TabTitle } from "../../utils/GeneralFunction";
import { searchProduct } from "../../redux/actions/searchProduct.action";
import { sendProduct } from "../../redux/actions/sendProductToBergen.action";
import notification from "../../utils/notification";
import { SearchOutlined, FilterFilled } from "@ant-design/icons";
import Swal from "sweetalert2";
import { searchPTUK } from "../../redux/actions/searchPTUK.action";
import { sendPtUK } from "../../redux/actions/sendPtUKToBergen.action";

const PtToBergen = () => {
  TabTitle("PT to Bergen");
  const [form] = Form.useForm();
  const [minimize, setMinimize] = useState(false);
  const dispatch = useDispatch();
  const token = ReadCookie("token");
  const [controlSheet, setControlSheet] = useState([]);
  const [loading, setLoading] = useState(false);
  const tableRef = useRef(null);
  const { controlLog } = useSelector((state) => state);
  const [excelData, setExcelData] = useState([]);

  const columns = [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: "PickticketNo",
      dataIndex: "PickticketNo",
      key: "PickticketNo",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              allowClear
              className="pop-search-gen pop-170"
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.PickticketNo.toString()
          .toLowerCase()
          .includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.PickticketNo - b.PickticketNo;
      },
    },
    
    
    
    
    
    {
      title: "WareHouse",
      dataIndex: "WAREHOUSE",
      key: "WAREHOUSE",
    },
    {
      title: "OrderDate",
      render: (text, record) => {
        return (
          <>
            {moment(record.OrderDate).format("MM-DD-YYYY")}
          </>
        );
      },
    },
    {
      title: "StartDate",
      dataIndex: "StartDate",
      key: "StartDate",
      render: (text, record) => {
        return (
          <>
            {moment(record.StartDate).format("MM-DD-YYYY")}
          </>
        );
      },
    },
    {
      title: "CancelDate",
      dataIndex: "CancelDate",
      key: "CancelDate",
      render: (text, record) => {
        return (
          <>
            {moment(record.CancelDate).format("MM-DD-YYYY")}
          </>
        );
      },
    },
    {
      title: "ShipDate",
      dataIndex: "ShipDate",
      key: "ShipDate",
      render: (text, record) => {
        return (
          <>
            {moment(record.ShipDate).format("MM-DD-YYYY")}
          </>
        );
      },
    },
    {
      title: "Payment Term",
      dataIndex: "PaymentTerm",
      key: "PaymentTerm",
    },
    {
      title: "Certified Funds",
      dataIndex: "CertifiedFunds",
      key: "CertifiedFunds",
    },
    {
      title: "Department",
      dataIndex: "Department",
      key: "Department",
    },
    {
      title: "Store",
      dataIndex: "Store",
      key: "Store",
    },
    {
      title: "Ship Via",
      // dataIndex: "ShipVia",
      // key: "ShipVia",
      render: (text, record) => {
        return (
          <>
            {form.getFieldValue().ShipVia}
          </>
        );
      },
    },
    {
      title: "Ship Via Account Number",
      dataIndex: "ShipViaAccountNumber",
      key: "ShipViaAccountNumber",
    },
    {
      title: "Ship Via Service",
      
      
      render: (text, record) => {
        return (
          <>
            {form.getFieldValue().ShipViaService}
          </>
        );
      },
    },
    {
      title: "Billing Option",
      
      
      render: (text, record) => {
        return (
          <>
            {form.getFieldValue().BillingOption}
          </>
        );
      },
    },
    {
      title: "Declared Value Percentage",
      dataIndex: "DeclaredValuePercentage",
      key: "DeclaredValuePercentage",
    },
    {
      title: "Special Instructions",
      dataIndex: "SpecialInstructions",
      key: "SpecialInstructions",
    },
    {
      title: "Customer Po Number",
      dataIndex: "CustomerPoNumber",
      key: "CustomerPoNumber",
    },
    {
      title: "Authorization Number",
      dataIndex: "AuthorizationNumber",
      key: "AuthorizationNumber",
    },
    {
      title: "Order Number",
      dataIndex: "OrderNumber",
      key: "OrderNumber",
    },
    {
      title: "Taken By",
      dataIndex: "TakenBy",
      key: "TakenBy",
    },
    {
      title: "Bill to Code",
      dataIndex: "BilltoCode",
      key: "BilltoCode",
    },
    {
      title: "Bill To First Name",
      dataIndex: "BillToFirstName",
      key: "BillToFirstName",
    },
    {
      title: "Bill to Last Name",
      dataIndex: "BilltoLastName",
      key: "BilltoLastName",
    },
    {
      title: "Bill to Company Name",
      dataIndex: "BilltoCompanyName",
      key: "BilltoCompanyName",
    },
    {
      title: "BillToAddress1",
      dataIndex: "BillToAddress1",
      key: "BillToAddress1",
    },
    {
      title: "BillToAddress2",
      dataIndex: "BillToAddress2",
      key: "BillToAddress2",
    },
    {
      title: "BillToCity",
      dataIndex: "BillToCity",
      key: "BillToCity",
    },
    {
      title: "BilltoState",
      dataIndex: "BilltoState",
      key: "BilltoState",
    },
    {
      title: "BilltoZip",
      dataIndex: "BilltoZip",
      key: "BilltoZip",
    },
    {
      title: "BilltoCountry",
      dataIndex: "BilltoCountry",
      key: "BilltoCountry",
    },
    {
      title: "BillToNon_US_Region",
      dataIndex: "BillToNon_US_Region",
      key: "BillToNon_US_Region",
    },
    {
      title: "BilltoPhone",
      dataIndex: "BilltoPhone",
      key: "BilltoPhone",
    },
    {
      title: "BilltoEmail",
      dataIndex: "BilltoEmail",
      key: "BilltoEmail",
    },
    {
      title: "ShipToCode",
      dataIndex: "ShipToCode",
      key: "ShipToCode",
    },
    {
      title: "ShiptoFirstName",
      dataIndex: "ShiptoFirstName",
      key: "ShiptoFirstName",
    },
    {
      title: "ShiptoLastName",
      dataIndex: "ShiptoLastName",
      key: "ShiptoLastName",
    },
    {
      title: "ShipToCompanyName",
      dataIndex: "ShipToCompanyName",
      key: "ShipToCompanyName",
    },
    {
      title: "ShiptoAddress1",
      dataIndex: "ShiptoAddress1",
      key: "ShiptoAddress1",
    },
    {
      title: "ShiptoAddress2",
      dataIndex: "ShiptoAddress2",
      key: "ShiptoAddress2",
    },
    {
      title: "ShipToCity",
      dataIndex: "ShipToCity",
      key: "ShipToCity",
    },
    {
      title: "ShipToState",
      dataIndex: "ShipToState",
      key: "ShipToState",
    },
    {
      title: "ShipToZip",
      dataIndex: "ShipToZip",
      key: "ShipToZip",
    },
    {
      title: "ShiptoCountry",
      dataIndex: "ShiptoCountry",
      key: "ShiptoCountry",
    },
    {
      title: "ShipToNon_US_Region",
      dataIndex: "ShipToNon_US_Region",
      key: "ShipToNon_US_Region",
    },
    {
      title: "ShipToPhone",
      dataIndex: "ShipToPhone",
      key: "ShipToPhone",
    },
    {
      title: "ShipToEmail",
      dataIndex: "ShipToEmail",
      key: "ShipToEmail",
    },
    {
      title: "LineItemsTotalAmount",
      dataIndex: "LineItemsTotalAmount",
      key: "LineItemsTotalAmount",
    },
    {
      title: "LineItemsTotalQuantity",
      dataIndex: "LineItemsTotalQuantity",
      key: "LineItemsTotalQuantity",
    },
    {
      title: "LineItemSeason",
      dataIndex: "LineItemSeason",
      key: "LineItemSeason",
    },
    {
      title: "LineItemStyle",
      dataIndex: "LineItemStyle",
      key: "LineItemStyle",
    },
    {
      title: "LineItemDescription",
      dataIndex: "LineItemDescription",
      key: "LineItemDescription",
    },
    {
      title: "LineItemColor",
      dataIndex: "LineItemColor",
      key: "LineItemColor",
    },
    {
      title: "LineItemSize",
      dataIndex: "LineItemSize",
      key: "LineItemSize",
    },
    {
      title: "LineItemUpc",
      dataIndex: "LineItemUpc",
      key: "LineItemUpc",
    },
    {
      title: "LineItemUnitPrice",
      dataIndex: "LineItemUnitPrice",
      key: "LineItemUnitPrice",
    },
    {
      title: "LineItemQuantity",
      dataIndex: "LineItemQuantity",
      key: "LineItemQuantity",
    },
  ];

  useEffect(() => {
    form.setFieldsValue({
      BillingOption: "PREPAID"
    });
  }, []);
  const onFinish = async () => {
    await form
      .validateFields()
      .then((values) => {
        if (!values.sku) {
          
          Swal.fire({
            icon: "error",
            title: "OOPS",
            text: `Please Insert PT No.`,
            confirmButtonColor: "#3c4b58",
          });
        } else {
          setLoading(true);
          dispatch(searchPTUK(token, values)).then((res) => {
            
            setLoading(false);
              if (res?.payload?.data) {
                setLoading(false);
                setControlSheet(res?.payload?.data);
                downloadExcelFileData(res?.payload?.data);
              }

          })
          .catch((err)=> {
            
            setLoading(false);
          });
        }
      })
      .catch((error) => {
        setLoading(false);
      });
    
    
    
    
    
    
    
    
    
    
    
    
    
  };

  const onSearch = async () => {
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
  };

  function downloadExcelFileData(data) {
    
    setExcelData(
      data.map((v) => {
        return {
          "PickticketNo": v.PickticketNo,
          "WareHouse": v.WAREHOUSE,
          "OrderDate": v.OrderDate,
          "StartDate": v.StartDate,
          "CancelDate": v.CancelDate,
          "ShipDate": v.ShipDate,
          "PaymentTerm": v.PaymentTerm,
          "CertifiedFunds": v.CertifiedFunds,
          "Store": v.Store,
          "ShipVia": v.ShipVia,
          "ShipViaAccountNumber": v.ShipViaAccountNumber,
          "ShipViaService": v.ShipViaService,
          "BillingOption": v.BillingOption,
          "DeclaredValuePercentage": v.DeclaredValuePercentage,
          "SpecialInstructions": v.SpecialInstructions,
          "CustomerPoNumber": v.CustomerPoNumber,
          "AuthorizationNumber": v.AuthorizationNumber,
          "OrderNumber": v.OrderNumber,
          "TakenBy": v.TakenBy,
          "BilltoCode": v.BilltoCode,
          "BillToFirstName": v.BillToFirstName,
          "BilltoLastName": v.BilltoLastName,
          "BilltoCompanyName": v.BilltoCompanyName,
          "BillToAddress1": v.BillToAddress1,
          "BillToAddress2": v.BillToAddress2,
          "BillToCity": v.BillToCity,
          "BilltoState": v.BilltoState,
          "BilltoZip": v.BilltoZip,
          "BilltoCountry": v.BilltoCountry,
          "BillToNon_US_Region": v.BillToNon_US_Region,
          "BilltoPhone": v.BilltoPhone,
          "BilltoEmail": v.BilltoEmail,
          "ShipToCode": v.ShipToCode,
          "ShiptoFirstName": v.ShiptoFirstName,
          "ShiptoLastName": v.ShiptoLastName,
          "ShipToCompanyName": v.ShipToCompanyName,
          "ShiptoAddress1": v.ShiptoAddress1,
          "ShiptoAddress2": v.ShiptoAddress2,
          "ShipToCity": v.ShipToCity,
          "ShipToState": v.ShipToState,
          "ShipToZip": v.ShipToZip,
          "ShiptoCountry": v.ShiptoCountry,
          "ShipToNon_US_Region": v.ShipToNon_US_Region,
          "ShipToPhone": v.ShipToPhone,
          "ShipToEmail": v.ShipToEmail,
          "LineItemsTotalAmount": v.LineItemsTotalAmount,
          "LineItemsTotalQuantity": v.LineItemsTotalQuantity,
          "LineItemSeason": v.LineItemSeason,
          "LineItemStyle": v.LineItemStyle,
          "LineItemDescription": v.LineItemDescription,
          "LineItemColor": v.LineItemColor,
          "LineItemSize": v.LineItemSize,
          "LineItemUpc": v.LineItemUpc,
          "LineItemUnitPrice": v.LineItemUnitPrice,
          "LineItemQuantity": v.LineItemQuantity
        };
      })
    );
  }

  const onSubmit = async () => {
    await form
      .validateFields()
      .then((values) => {
        if (!values.sku) {
          
          Swal.fire({
            icon: "error",
            title: "OOPS",
            text: `Please Insert PT No.`,
            confirmButtonColor: "#3c4b58",
          });
        } else {
          
          
          let final_data = controlSheet.map((e)=> {
            e.ShipViaService = form.getFieldValue().ShipViaService;
            e.ShipVia = form.getFieldValue().ShipVia;
            e.BillingOption = form.getFieldValue().BillingOption;
            return e
          })

          dispatch(sendPtUK(token, final_data)).then((res) => {
            if (res.type == "GET_SEND_PTUK_SUCCESS") {
              notification("success", "PT Send Successfully");
            }
            
            
            downloadExcelFileData(res?.payload?.data);
          });
          
        }
      })
      .catch((error) => {
        setLoading(false);
      });

    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
  };

  return (
    <div className="styleContainer">
      <div className="styleBody h-100">
        <PageHeader
          title={"Send PT to Bergen"}
          minimize={setMinimize}
          check={minimize}
        />
        {!minimize && (
          <div className="bodyStyle">
            <div className="formProduct p-2 d-flex align-items-baseline">
              <Form
                onFinish={onFinish}
                form={form}
                name="control-hooks"
                style={{ width: "100%" }}
              >
                <div className="container-fluid pbpx-5 ptpx-5">
                  <Brow>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div>
                        <div className="relative">
                          <Form.Item
                            name="sku"
                            label="PT No"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                          >
                            <Input size="small" className="styleInput" />
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div>
                        <div className="relative">
                          <Form.Item
                            name="ShipViaService"
                            label="Ship Via Service"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            
                          >
                            {/* <Input size="small" className="styleInput" /> */}
                            <Input
                              size="small"
                              
                              className={`styleInput customInputs`}
                              
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div>
                        <div className="relative">
                          <Form.Item
                            name="ShipVia"
                            label="Ship Via"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            
                          >
                            {/* <Input size="small" className="styleInput" /> */}
                            <Input
                              size="small"
                              
                              className={`styleInput customInputs`}
                              
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div>
                        <div className="relative">
                          <Form.Item
                            name="BillingOption"
                            label="Billing Option"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            
                          >
                            {/* <Input size="small" className="styleInput" /> */}
                            <Input
                              size="small"
                              
                              className={`styleInput customInputs`}
                              
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol>
                    {/* <Bcol lg={6} xl={4} xxl={3}>
                      <div>
                        <div className="relative">
                          <Form.Item
                            labelAlign="left"
                            labelCol={{ span: 2 }}
                            wrapperCol={{ span: 2 }}
                          >
                            <Button
                              type="primary"
                              className=""
                              onClick={onSearch}
                            >
                              <span className="px-2">Search</span>
                            </Button>
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol> */}
                  </Brow>
                </div>
              </Form>
              <Button
                htmlType="submit"
                type="primary"
                className="ant-btn ant-btn-primary mrpx-20"
                onClick={onFinish}
              >
                <span className="px-2">Search</span>
              </Button>

              {controlSheet.length > 0 && (
                <Button
                  htmlType="submit"
                  type="primary"
                  className="ant-btn ant-btn-primary mrpx-20 mb-2"
                  onClick={onSubmit}
                >
                  <span className="px-2">Send</span>
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
      <div style={{ marginTop: 10 }}>
        {loading ? (
          <div className=" report-wrapper-main d-flex align-items-center justify-content-center spin-style mt-3">
            <Spin size="large" />
          </div>
        ) : controlSheet.length > 0 ? (
          <>
            <Table
              title={() => (
                <CSVLink
                  filename={"PTData.csv"}
                  data={excelData}
                  onClick={() => {}}
                >
                  Download Excel
                </CSVLink>
              )}
              ref={tableRef}
              size="small"
              bordered={false}
              type="company"
              perPage={15}
              scroll={{ x: "max-content" }}
              dataSource={controlSheet}
              columns={columns}
              loading={controlLog.loading}
              pagination={{
                defaultCurrent: 1,
                defaultPageSize: 100,
                pageSizeOptions: [100, 500, 1000, 2000, 2500, 3000],
              }}
            />
          </>
        ) : (
          
          <div
            style={{
              backgroundColor: "#CCCCCC",
              borderWidth: "1pt",
              borderStyle: "Dashed",
              fontWeight: "bold",
              height: "25px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            No record(s)
          </div>
        )}
      </div>
    </div>
  );
};

export default PtToBergen;
