



  

  



















  




  

import {
  GET_CUSTOMER_TYPE_ERROR, GET_CUSTOMER_TYPE_REQUEST, GET_CUSTOMER_TYPE_SUCCESS,
  ADD_CUSTOMER_TYPE_ERROR, ADD_CUSTOMER_TYPE_REQUEST, ADD_CUSTOMER_TYPE_SUCCESS,
  DELETE_CUSTOMER_TYPE_REQUEST, DELETE_CUSTOMER_TYPE_SUCCESS, DELETE_CUSTOMER_TYPE_ERROR,
  UPDATE_CUSTOMER_TYPE_ERROR, UPDATE_CUSTOMER_TYPE_REQUEST, UPDATE_CUSTOMER_TYPE_SUCCESS

} from "../actions/types"
export default function (state = [], action) {
  const { type, payload } = action;
  switch (type) {
      case GET_CUSTOMER_TYPE_REQUEST:
          return {
              loading: true,
          };
      case GET_CUSTOMER_TYPE_SUCCESS:
          return {
              ...state,
              loading: false,
              payload,
          };
      case GET_CUSTOMER_TYPE_ERROR:
          return {
              ...state,
              loading: false
          };
      case ADD_CUSTOMER_TYPE_REQUEST:
          return {
              loading: true,
          };
      case ADD_CUSTOMER_TYPE_SUCCESS:
          return {
              ...state,
              loading: false,
              payload: [payload],
          };
      case ADD_CUSTOMER_TYPE_ERROR:
          return {
              ...state,
              loading: false
          };
      case DELETE_CUSTOMER_TYPE_REQUEST:
          return {
              loading: true,
          };
      case DELETE_CUSTOMER_TYPE_SUCCESS:
          return {
              ...state,
              loading: false,
              payload: [payload],
          };
      case DELETE_CUSTOMER_TYPE_ERROR:
          return {
              ...state,
              loading: false
          };
      case UPDATE_CUSTOMER_TYPE_REQUEST:
          return {
              loading: true,
          };
      case UPDATE_CUSTOMER_TYPE_SUCCESS:
          return {
              ...state,
              loading: false,
              payload: [payload],
          };
      case UPDATE_CUSTOMER_TYPE_ERROR:
          return {
              ...state,
              loading: false
          };

      default:
          return state;
  }
}