import {
GET_RETURNTYPE_REQUEST, GET_RETURNTYPE_SUCCESS, GET_RETURNTYPE_ERROR,
    ADD_RETURNTYPE_REQUEST, ADD_RETURNTYPE_SUCCESS, ADD_RETURNTYPE_ERROR,
    DELETE_RETURNTYPE_REQUEST, DELETE_RETURNTYPE_SUCCESS, DELETE_RETURNTYPE_ERROR,
    UPDATE_RETURNTYPE_REQUEST, UPDATE_RETURNTYPE_ERROR, UPDATE_RETURNTYPE_SUCCESS,

} from "../actions/types"
export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case GET_RETURNTYPE_REQUEST:
            return {
                loading: true,
            };
        case GET_RETURNTYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_RETURNTYPE_ERROR:
            return {
                ...state,
                loading: false
            };
        case ADD_RETURNTYPE_REQUEST:
            return {
                loading: true,
            };
        case ADD_RETURNTYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case ADD_RETURNTYPE_ERROR:
            return {
                ...state,
                loading: false
            };
        case DELETE_RETURNTYPE_REQUEST:
            return {
                loading: true,
            };
        case DELETE_RETURNTYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case DELETE_RETURNTYPE_ERROR:
            return {
                ...state,
                loading: false
            };
        case UPDATE_RETURNTYPE_REQUEST:
            return {
                loading: true,
            };
        case UPDATE_RETURNTYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case UPDATE_RETURNTYPE_ERROR:
            return {
                ...state,
                loading: false
            };

        default:
            return state;
    }
}