import {
  GET_ALL_SALES_RETURN_LINE_REQUEST, GET_ALL_SALES_RETURN_LINE_SUCCESS, GET_ALL_SALES_RETURN_LINE_ERROR, DELETE_SALES_RETURN_LINE_ERROR, DELETE_SALES_RETURN_LINE_SUCCESS, DELETE_SALES_RETURN_LINE_REQUEST, GET_ONE_SALES_RETURN_LINE_REQUEST, GET_ONE_SALES_RETURN_LINE_SUCCESS, GET_ONE_SALES_RETURN_LINE_ERROR, ADD_SALES_RETURN_LINE_REQUEST, ADD_SALES_RETURN_LINE_SUCCESS, ADD_SALES_RETURN_LINE_ERROR, UPDATE_SALES_RETURN_LINE_REQUEST, UPDATE_SALES_RETURN_LINE_SUCCESS, UPDATE_SALES_RETURN_LINE_ERROR, GET_ONE_SALES_RETURN_LINE_DETAIL_REQUEST, GET_ONE_SALES_RETURN_LINE_DETAIL_SUCCESS, GET_ONE_SALES_RETURN_LINE_DETAIL_ERROR,
  GET_ONE_SALE_RETURN_RELATED_RECORD_REQUEST,
  GET_ONE_SALE_RETURN_RELATED_RECORD_SUCCESS,
  GET_ONE_SALE_RETURN_RELATED_RECORD_ERROR
} from "./types";

import { BASEURL } from "../../constant/config.js";
const axios = require("axios");

export const getAllOrderShipmentLine = (token) => (dispatch) => {
  dispatch({ type: GET_ALL_SALES_RETURN_LINE_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}getAll/SalesLine`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: {
      startDate: "1-1-2022",
      endDate: "1-31-2022",
    },
  })
    .then((response) => {
      return dispatch({
        type: GET_ALL_SALES_RETURN_LINE_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_ALL_SALES_RETURN_LINE_ERROR,
        payload: error.response,
      });
    });
};

export const addSalesReturnLine = (token, object) => (dispatch) => {
  dispatch({ type: ADD_SALES_RETURN_LINE_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}create/saleReturnLine`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: { data: object },
  })
    .then((response) => {
      return dispatch({
        type: ADD_SALES_RETURN_LINE_SUCCESS,
        payload: response?.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: ADD_SALES_RETURN_LINE_ERROR,
        payload: error.response,
      });
    });
};

export const updateSalesReturnLine =
  (token, shipHeaderId, object) => (dispatch) => {
    dispatch({ type: UPDATE_SALES_RETURN_LINE_REQUEST });
    return axios({
      method: "PUT",
      url: `${BASEURL}update/saleReturnLine/${shipHeaderId}`,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
      data: {
        data: object,
      },
    })
      .then((response) => {
        return dispatch({
          type: UPDATE_SALES_RETURN_LINE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        return dispatch({
          type: UPDATE_SALES_RETURN_LINE_ERROR,
          payload: error.response,
        });
      });
  };

export const getOneSalesLine = (token, id) => (dispatch) => {
  dispatch({ type: GET_ONE_SALES_RETURN_LINE_REQUEST });
  return axios({
    method: "GET",
    url: `${BASEURL}getOne/saleReturnLine/${id}`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {
      return dispatch({
        type: GET_ONE_SALES_RETURN_LINE_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_ONE_SALES_RETURN_LINE_ERROR,
        payload: error.response,
      });
    });
};

export const getOneSalesLineDetail = (token, id) => (dispatch) => {
  dispatch({ type: GET_ONE_SALES_RETURN_LINE_DETAIL_REQUEST });
  return axios({
    method: "GET",
    url: `${BASEURL}getOne/saleReturnLines/${id}`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {
      return dispatch({
        type: GET_ONE_SALES_RETURN_LINE_DETAIL_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_ONE_SALES_RETURN_LINE_DETAIL_ERROR,
        payload: error.response,
      });
    });
};

export const getOnesaleReturnRelatedRecord = (token, id) => (dispatch) => {
  dispatch({ type: GET_ONE_SALE_RETURN_RELATED_RECORD_REQUEST });
  return axios({
    method: "GET",
    url: `${BASEURL}getOne/SaleReturnRelatedTransaction/${id}`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {
      return dispatch({
        type: GET_ONE_SALE_RETURN_RELATED_RECORD_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_ONE_SALE_RETURN_RELATED_RECORD_ERROR,
        payload: error.response,
      });
    });
};


export const deleteSalesLine = (token, id) => (dispatch) => {
  dispatch({ type: DELETE_SALES_RETURN_LINE_REQUEST });
  return axios({
    method: "DELETE",
    url: `${BASEURL}delete/saleReturnLine/${id}`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {
      return dispatch({
        type: DELETE_SALES_RETURN_LINE_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: DELETE_SALES_RETURN_LINE_ERROR,
        payload: error.response,
      });
    });
};
