import {
    GET_INVOICE_EMAIL_REQUEST,
    GET_INVOICE_EMAIL_SUCCESS,
    GET_INVOICE_EMAIL_ERROR,
    CREATE_INVOICE_EMAIL_REQUEST,
    CREATE_INVOICE_EMAIL_SUCCESS,
    CREATE_INVOICE_EMAIL_ERROR,
  } from "../actions/types";

  export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
      case GET_INVOICE_EMAIL_REQUEST:
        return {
          loading: true,
        };
      case GET_INVOICE_EMAIL_SUCCESS:
        return {
          ...state,
          loading: false,
          payload,
        };
      case GET_INVOICE_EMAIL_ERROR:
        return {
          ...state,
          loading: false,
        };
      case CREATE_INVOICE_EMAIL_REQUEST:
        return {
          loading: true,
        };
      case CREATE_INVOICE_EMAIL_SUCCESS:
        return {
          ...state,
          loading: false,
          payload,
        };
      case CREATE_INVOICE_EMAIL_ERROR:
        return {
          ...state,
          loading: false,
        };
      default:
        return state;
    }
  }
  