
import {
  GET_OMS_STYLE_REPORT_REQUEST,
  GET_OMS_STYLE_REPORT_SUCCESS,
  GET_OMS_STYLE_REPORT_ERROR
} from "../actions/types";

export default function (state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case GET_OMS_STYLE_REPORT_REQUEST:
      return {
        loading: true,
      };
    case GET_OMS_STYLE_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        payload,
      };
    case GET_OMS_STYLE_REPORT_ERROR:
      return {
        ...state,
        loading: false,
        payload,
      };

    default: {
      return state;
    }
  }
};