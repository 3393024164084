import {
    GET_ALL_STYLE_BOOKED_WISE_REQUEST, GET_ALL_STYLE_BOOKED_WISE_SUCCESS, GET_ALL_STYLE_BOOKED_WISE_ERROR
} from "../actions/types";

export const getBookedStyleWise = (state = [], action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_ALL_STYLE_BOOKED_WISE_REQUEST:
            return {
                loading: true,
            };
        case GET_ALL_STYLE_BOOKED_WISE_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_ALL_STYLE_BOOKED_WISE_ERROR:
            return {
                ...state,
                loading: false,
                payload,
            };

        default: {
            return state;
        }
    }
};
