import {
	GET_ONE_B_GRADE_STOCK_REQUEST,
	GET_ONE_B_GRADE_STOCK_SUCCESS,
	GET_ONE_B_GRADE_STOCK_ERROR,
} from "./types";
import moment from "moment";
import { BASEURL } from "../../constant/config.js";
const axios = require("axios");


export const getOneBGradeStock = (token) => (dispatch) => {
	dispatch({ type: GET_ONE_B_GRADE_STOCK_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}getAll/BgradeStock`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
	})
		.then((response) => {
			return dispatch({
				type: GET_ONE_B_GRADE_STOCK_SUCCESS,
				payload: response?.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_ONE_B_GRADE_STOCK_ERROR,
				payload: error.response,
			});
		});
};

