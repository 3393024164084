import {
  GET_WASH_REQUEST,
  GET_WASH_SUCCESS,
  GET_WASH_ERROR,
  DELETE_WASH_REQUEST,
  DELETE_WASH_SUCCESS,
  DELETE_WASH_ERROR,
  UPDATE_WASH_REQUEST,
  UPDATE_WASH_SUCCESS,
  UPDATE_WASH_ERROR,
  ADD_WASH_REQUEST,
  ADD_WASH_SUCCESS,
  ADD_WASH_ERROR,
} from "./types";
import { BASEURL } from "../../constant/config.js";
const axios = require("axios");

export const getWashName = (token, menu_id) => (dispatch) => {

  dispatch({ type: GET_WASH_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}getAll/wash`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: { menu_id }
  })
    .then((response) => {
      return dispatch({ type: GET_WASH_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      return dispatch({
        type: GET_WASH_ERROR,
        payload: error.response,
      });
    });
};

export const addWashName = (token, data, menu_id) => (dispatch) => {
  dispatch({ type: ADD_WASH_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}create/wash`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: { wash_name: data.washName, is_active: data.check, menu_id },
  })
    .then((response) => {
      return dispatch({ type: ADD_WASH_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      return dispatch({
        type: ADD_WASH_ERROR,
        payload: error.response,
      });
    });
};

export const updateWashName = (token, data, id) => (dispatch) => {
  dispatch({ type: UPDATE_WASH_REQUEST });
  return axios({
    method: "PUT",
    url: `${BASEURL}update/wash/${id}`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: { wash_name: data.washName, is_active: data.check },
  })
    .then((response) => {
      return dispatch({ type: UPDATE_WASH_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      return dispatch({
        type: UPDATE_WASH_ERROR,
        payload: error.response,
      });
    });
};

export const deleteWashName = (token, id) => (dispatch) => {
  dispatch({ type: DELETE_WASH_REQUEST });
  return axios({
    method: "DELETE",
    url: `${BASEURL}delete/wash/${id}`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {
      return dispatch({ type: DELETE_WASH_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      return dispatch({
        type: DELETE_WASH_ERROR,
        payload: error.response,
      });
    });
};

