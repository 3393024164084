import {
    GET_SHIPPING_METHOD_REQUEST,
    GET_SHIPPING_METHOD_SUCCESS,
    GET_SHIPPING_METHOD_ERROR,
    ADD_SHIPPING_METHOD_ERROR,
    ADD_SHIPPING_METHOD_REQUEST,
    ADD_SHIPPING_METHOD_SUCCESS,
    UPDATE_SHIPPING_METHOD_REQUEST,
    UPDATE_SHIPPING_METHOD_SUCCESS,
    UPDATE_SHIPPING_METHOD_ERROR,
    DELETE_SHIPPING_METHOD_REQUEST,
    DELETE_SHIPPING_METHOD_SUCCESS,
    DELETE_SHIPPING_METHOD_ERROR

} from "./types"
import axios from "axios";
import { BASEURL } from "../../constant/config.js"



export const loadShippingMethod = (token) => dispatch => {
    dispatch({ type: GET_SHIPPING_METHOD_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/adminShippingMethod`,
        headers: {
            Accept: "application/json",
            'Authorization': 'Bearer ' + token
        }
    })
        .then(response => {
            return dispatch({ type: GET_SHIPPING_METHOD_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            if (error.response.data.data === "No Record Found !") {
                return dispatch({
                    type: GET_SHIPPING_METHOD_ERROR,
                    payload: [],
                });
            }
            return dispatch({
                type: GET_SHIPPING_METHOD_ERROR,
                payload: error.response,
            });
        })
};



export const addShippingMethod = (token, data, id) => (dispatch) => {
    dispatch({ type: ADD_SHIPPING_METHOD_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}create/adminShippingMethod`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data,
    })
        .then((response) => {
            return dispatch({ type: ADD_SHIPPING_METHOD_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: ADD_SHIPPING_METHOD_ERROR,
                payload: error.response,
            });
        });
};




export const updateShippingMethod = (token, data, id) => (dispatch) => {
    dispatch({ type: UPDATE_SHIPPING_METHOD_REQUEST });
    return axios({
        method: "PUT",
        url: `${BASEURL}update/adminShippingMethod/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data,
    })
        .then((response) => {
            return dispatch({ type: UPDATE_SHIPPING_METHOD_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: UPDATE_SHIPPING_METHOD_ERROR,
                payload: error.response,
            });
        });
};



export const deleteShippingMethod = (token, id) => (dispatch) => {
    dispatch({ type: DELETE_SHIPPING_METHOD_REQUEST });
    return axios({
        method: "DELETE",
        url: `${BASEURL}delete/adminShippingMethod/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
    })
        .then((response) => {
            return dispatch({ type: DELETE_SHIPPING_METHOD_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: DELETE_SHIPPING_METHOD_ERROR,
                payload: error.response,
            });
        });
};
