import { GET_GL_IMPACT_REQUEST, GET_GL_IMPACT_SUCCESS, GET_GL_IMPACT_ERROR } from "../actions/types";

import { BASEURL } from "../../constant/config.js";

const axios = require("axios");
export const getGLImpact = (token, data) => (dispatch) => {

    dispatch({ type: GET_GL_IMPACT_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/TransactionAccounting`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data
    })
        .then((response) => {
            return dispatch({ type: GET_GL_IMPACT_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            if (error.response.data.data === "No Record Found !") {
                return dispatch({
                    type: GET_GL_IMPACT_ERROR,
                    payload: [],
                });
            }
            return dispatch({
                type: GET_GL_IMPACT_ERROR,
                payload: error.response,
            });
        });
};

