import {
    GET_TERM_FOR_PRINT_REQUEST,
    GET_TERM_FOR_PRINT_SUCCESS,
    GET_TERM_FOR_PRINT_ERROR,
    ADD_TERM_FOR_PRINT_REQUEST,
    ADD_TERM_FOR_PRINT_ERROR,
    ADD_TERM_FOR_PRINT_SUCCESS,
    UPDATE_TERM_FOR_PRINT_REQUEST,
    UPDATE_TERM_FOR_PRINT_ERROR,
    UPDATE_TERM_FOR_PRINT_SUCCESS,
    DELETE_TERM_FOR_PRINT_REQUEST,
    DELETE_TERM_FOR_PRINT_ERROR,
    DELETE_TERM_FOR_PRINT_SUCCESS,
} from "./types"

import { BASEURL } from "../../constant/config.js";
const axios = require("axios");
export const getTermForPrint = (token, menu_id) => (dispatch) => {
    dispatch({ type: GET_TERM_FOR_PRINT_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/TermForprint`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: {
            menu_id
        }
    })

        .then((response) => {
            return dispatch({ type: GET_TERM_FOR_PRINT_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: GET_TERM_FOR_PRINT_ERROR,
                payload: error.response,
            });
        });
};

export const addTermForPrint = (token, data) => (dispatch) => {

    dispatch({ type: ADD_TERM_FOR_PRINT_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}create/TermForprint`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { term_name: data.termForPrintName, is_active: data.check, menu_id: data.menu, scm_term_id: data.term_id },
    })
        .then((response) => {
            return dispatch({ type: ADD_TERM_FOR_PRINT_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: ADD_TERM_FOR_PRINT_ERROR,
                payload: error.response,
            });
        });
};

export const updateTermForPrint = (token, data, id) => (dispatch) => {
    // 

    dispatch({ type: UPDATE_TERM_FOR_PRINT_REQUEST });
    return axios({
        method: "PUT",
        url: `${BASEURL}update/TermForprint/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { term_name: data.termForPrintName, is_active: data.check, scm_term_id: data.term_id },
    })
        .then((response) => {
            return dispatch({ type: UPDATE_TERM_FOR_PRINT_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: UPDATE_TERM_FOR_PRINT_ERROR,
                payload: error.response,
            });
        });
};

export const deleteTermForPrint = (token, id) => (dispatch) => {
    dispatch({ type: DELETE_TERM_FOR_PRINT_REQUEST });
    return axios({
        method: "DELETE",
        url: `${BASEURL}delete/TermForprint/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
    })
        .then((response) => {
            return dispatch({ type: DELETE_TERM_FOR_PRINT_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: DELETE_TERM_FOR_PRINT_ERROR,
                payload: error.response,
            });
        });
};
