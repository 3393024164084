import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import PageHeader from "../../components/PageHeader/PageHeader";
import { Row as Brow, Col as Bcol } from "reactstrap";
import {
  Button,
  Form,
  Select,
  Table, Spin, DatePicker, Input, InputNumber
} from "antd";
import { ReadCookie } from "../../utils/readCookie";
import numeral from "numeral";
import { CSVLink } from "react-csv";
import { getAdminLocation } from "../../redux/actions/adminLocation.action";
import { getinventory } from "../../redux/actions/inventoryReport.action";
import { getSaleOrderFormData } from "../../redux/actions/salesOrder.action";
import { useCallback } from "react";
import moment from "moment";
import Loader from "react-spinners/BeatLoader";
import { getDscoInvoiceReport } from "../../redux/actions/dscoInvoiceReport.action";
import { TabTitle } from "../../utils/GeneralFunction";
const { Option } = Select;

const DscoInvoiceReport = () => {
  TabTitle("DSCO Invoices")
  const [form] = Form.useForm();
  const [minimize, setMinimize] = useState(false);
  const [toogle, setToogle] = useState(false);
  const submitButton = useRef(null);
  const dispatch = useDispatch();
  const menuId = ReadCookie("menuId");
  const token = ReadCookie("token");
  const [dscoInvoiceList, setDscoInvoiceList] = useState([]);
  const [loading, setLoading] = useState(false);
  const tableRef = useRef(null);
  const queryParams = new URLSearchParams(window.location.search);
  const companyId = ReadCookie("defaultCompany");
  const company_id = ReadCookie("defaultCompany");
  const {
    saleOrderFormData,
    dscoInvoice
  } = useSelector((state) => state);
  const [excelData, setExcelData] = useState([])

  function debounce(cb, delay = 1000) {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        cb(...args);
      }, delay);
    };
  }

  const handleChanges = (val) => {
    dispatch(getSaleOrderFormData(token, company_id, menuId, val, ""));
  };

  const optimizedFn = useCallback(debounce(handleChanges), []);


  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      
      render: (text, record, index) => {
        return index + 1;
      },
      width: 40,
    },
    {
      title: "invoice_id",
      dataIndex: "invoice_id",
      key: "invoice_id",
      
    },
    {
      title: "po_number",
      dataIndex: "po_number",
      key: "po_number",
      
    },
    {
      title: "line_item_unit_price",
      dataIndex: "line_item_unit_price",
      key: "line_item_unit_price",
      render: (text, record) => {
        return <>$ {numeral(text).format("0,00.00")}</>
      },
      align: "right"
    },
    {
      title: "invoice_total_amount",
      dataIndex: "invoice_total_amount",
      key: "invoice_total_amount",
      
      render: (text, record) => {
        return <>$ {numeral(text).format("0,00.00")}</>
      },
      align: "right"
    },
    {
      title: "invoice_number_of_line_items",
      dataIndex: "invoice_number_of_line_items",
      key: "invoice_number_of_line_items",
      
      align: "right",
      render: (text, record) => {
        return <>{numeral(text).format("0,00.00")}</>
      },
    },
    {
      title: "line_item_line_number",
      dataIndex: "line_item_line_number",
      key: "line_item_line_number",
      
      align: "right"
    },
    {
      title: "line_item_sku",
      dataIndex: "line_item_sku",
      key: "line_item_sku",
      
    },
    {
      title: "line_item_quantity",
      dataIndex: "line_item_quantity",
      key: "line_item_quantity",
      
      align: "right"
    },
    {
      title: " invoice_date",
      dataIndex: "invoice_date",
      key: "invoice_date",
      render: (text,record)=>{
        return moment(text).format("MM/DD/YY")
      }
      
    },
  ];

  const onFinish = async () => {
    setLoading(true);
    await form.validateFields().then((values) => {
      dispatch(getDscoInvoiceReport(token, values)).then((res) => {
        setToogle(true);
        setLoading(false);
        setDscoInvoiceList(res?.payload?.data);
        downloadExcelFileData(res?.payload?.data);
      });
    }).catch((error) => {
      setLoading(false);
    })
  }

  function downloadExcelFileData(data) {
    setExcelData(
      data.map((v) => {
        return {
          "invoice_id": v.invoice_id,
          "po_number": v.po_number,
          "line_item_unit_price": v.line_item_unit_price,
          "invoice_total_amount": v.invoice_total_amount,
          "invoice_number_of_line_items": v.invoice_number_of_line_items,
          "Item line_item_line_number": v.line_item_line_number,
          "line_item_sku": v.line_item_sku,
          "line_item_quantity": v.line_item_quantity,
          "invoice_date": moment(v.invoice_date).format("MM/DD/YY"),
        };
      })
    )
  }

  const totalQuantity = () => {
    let sumQty = dscoInvoiceList.reduce(function (previousValue, currentValue) {
      return previousValue += Number(currentValue.total_qty);
    }, 0);
    return sumQty;
  }

  const totalSum = () => {
    let sumAmount = dscoInvoiceList.reduce(function (previousValue, currentValue) {
      return previousValue += Number(currentValue.cost_price);
    }, 0);
    return sumAmount;
  }


  return (
    <div className="styleContainer">
      <div className="styleBody h-100">
        <PageHeader
          title={"DSCO Invoice Report"}
          minimize={setMinimize}
          check={minimize}
        />
        {!minimize && (
          <div className="bodyStyle">
            <div className="formProduct p-0">
              <Form onFinish={onFinish} form={form} name="control-hooks">
                <div className="container-fluid pbpx-5 ptpx-5">
                  <Brow>
                  <Bcol lg={6} xl={4} xxl={3}>
                      <div className="">
                        <div className="relative">
                          <Form.Item
                            name="from_date"
                            label="From Date:"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            initialValue={moment().startOf('year')}
                          >
                            <DatePicker
                              size='small'
                              className="styleInput w-100"
                              format={"MM-DD-YYYY"}
                              defaultValue={moment().format("MM-DD-YYYY")}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div >
                        <div className="relative">
                          <Form.Item
                            name="to_date"
                            label="To Date:"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            initialValue={moment().endOf('year')}
                          >
                            <DatePicker
                              size='small'
                              className="styleInput w-100"
                              format={"MM-DD-YYYY"}
                              defaultValue={moment().format("MM-DD-YYYY")}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div className="firstSectionRo">
                      <Form.Item 
                      name="customer" 
                      label="Customer"
                      labelAlign="left"
                      labelCol={{ span: 8 }}
                      wrapperCol={{ span: 16 }}
                      initialValue={"All"}>
                        <Select
                          notFoundContent={
                            saleOrderFormData.loading ? (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <Loader
                                  type="Oval"
                                  color="#3c4b58"
                                  height={20}
                                  width={20}
                                />
                              </div>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  textAlign: "center",
                                }}
                              >
                                No Data
                              </div>
                            )
                          }
                          
                          autoComplete={"nope"}
                          allowClear
                          size="small"
                          showSearch
                          optionFilterProp="children"
                          loading={saleOrderFormData.loading}
                          
                          defaultValue={form.getFieldValue().customer}
                          onSearch={(value) => {
                            optimizedFn(value);
                          }}
                          
                        >
                          <Option value="All">All</Option>
                          {saleOrderFormData?.payload?.customerDetail.map(
                            (item) => {
                              return (
                                <Option
                                  defaultValue={"All"}
                                  key={item.customer_id}
                                  value={item.customer_id}
                                >
                                  {" "}
                                  {`${item.customer_code} - ${item.customer_name}`}
                                </Option>
                              );
                            }
                          )}
                        </Select>
                      </Form.Item>
                      </div>
                    </Bcol>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div className="">
                        <div >
                          <Form.Item
                            name="invoice"
                            label="Invoice #"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                          >
                            <Input
                              size='small'
                              className="styleInput"
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol>
                  </Brow>
                </div>
              </Form>
              <Button
                htmlType="submit"
                type="primary"
                className="submit-btn-btm "
                onClick={onFinish}
              >
                Submit
              </Button>
            </div>
          </div>
        )}
      </div>
      <div style={{ marginTop: 10 }}>
        {
          loading ? (
            <div className=" report-wrapper-main d-flex align-items-center justify-content-center spin-style mt-3">
              <Spin size='large' />
            </div>
          ) : (
            dscoInvoiceList.length > 0 ? (
              <Table
                title={() =>
                  <CSVLink
                    filename={"DSCO-Invoice-Report.csv"}
                    data={excelData}
                    onClick={() => {

                    }}
                  >
                    Download Excel
                  </CSVLink>
                }
                ref={tableRef}
                size="small"
                bordered={false}
                type="company"
                perPage={15}
                scroll={{
                  x: 'calc(200px + 90%)',
                  y: 450,
                }}
                
                dataSource={dscoInvoiceList}
                columns={columns}
                loading={dscoInvoice.loading}
                pagination={{
                  defaultCurrent: 1,
                  defaultPageSize: 100,
                  pageSizeOptions: [100, 500, 1000, 2000, 2500, 3000]
                }}
                
                
                
                
                
                
                
                
                
                
                
                
                
                
                
                
                
                
                
                
                
                
                
                
                
                
                
                
                
                
                
                
                
                
                
                
                
                
                
                
                
                
              />
            ) : <div
              style={{
                backgroundColor: "#CCCCCC",
                borderWidth: "1pt",
                borderStyle: "Dashed",
                fontWeight: "bold",
                height: "25px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              No record(s)
            </div>
          )
        }
      </div>
    </div>
  )
}

export default DscoInvoiceReport;
