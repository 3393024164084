import React, { useEffect, useState, useMemo } from "react";

import { Spin, Select, DatePicker, Checkbox, Form, Input, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { getOmsStockSummary } from "../../redux/actions/omsStockSummary.action";
import { getGenderCategory } from "../../redux/actions/genderCategory.action";
import { getProductStatus } from "../../redux/actions/productStatus.actions";
import { getProductClass } from "../../redux/actions/productClass.action";
import { getFitCategory } from "../../redux/actions/fitCategory.action";
import { getProductType } from "../../redux/actions/productType.action";
import { getSeason } from "../../redux/actions/season.action";
import { getGender } from "../../redux/actions/gender.action";
import { getSize } from "../../redux/actions/size.action";

import { ReadCookie } from "../../utils/readCookie";
import { getStyle } from "../../redux/actions/style";
import moment from 'moment';

import btnPrint from "../../assets/images/button_print.gif";
import xlsx from "../../assets/images/ico_xls_sm.gif";
import logo from "../../assets/images/logo.png";
import "../Distributor/Distributor.css";
import { Link, useLocation } from "react-router-dom";


const OmsStockSummaryStyle = ({ token }) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const { style, getWiseStockReport, productType, gender, genderCategory, season, productStatus, productClass, size, fitCategory, omsStockSummary } = useSelector((state) => state);
    const menuId = ReadCookie("menuId");
    const userName = ReadCookie("name");
    const [productClassID, setProductClassID] = useState([]);
    const [seasonID, setSeasonID] = useState([]);
    const [isChecked, setIsChecked] = useState([]);
    const [allSesion, setAllSesion] = useState(true);
    const [isPCChecked, setIsPCChecked] = useState([]);
    const [allPC, setAllPC] = useState(true);
    const [genderID, setGenderID] = useState(null);

    const [BgradeCheck, setBgradeCheck] = useState(false);

    function useQuery() {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    let query = useQuery();
    let style_id = query.get("style_id");
    let as_on_date = query.get("as_on_date");
    let gender_category_id = query.get("gender_category_id");
    let gender_id = query.get("gender_id");
    let openStock = query.get("openStock");
    let sku = query.get("sku");
    let fit_category_id = query.get("fit_category_id");
    let product_status_id = query.get("product_status_id");

    let data = {
        product_status_id: product_status_id,
        as_on_date: moment(as_on_date),
        gender_id: gender_id,
        openStock: openStock,
        style_id: style_id,
        sku: sku,
        fit_category_id: fit_category_id,
        gender_category_id: gender_category_id,
    }

    const filterGender = gender.payload?.data.find((item) => {

        return item.gender_id === Number(gender_id)
    })
    const filterStyle = style.payload?.data.find((item) => {

        return item.style_id === Number(style_id)

    })
    const filterproductStatus = productStatus.payload?.data.find((item) => {

        return item.product_status_id === Number(product_status_id)

    })
    const filterproductCat = genderCategory.payload?.data.find((item) => {

        return item.gender_category_id === Number(gender_category_id)

    })
    const filterFitCategory = fitCategory.payload?.data.find((item) => {

        return item.fit_category_id === Number(fit_category_id)

    })



    useEffect(() => {
        dispatch(getOmsStockSummary(token, data, isChecked, isPCChecked, BgradeCheck))
    }, [])

    let result = getWiseStockReport && getWiseStockReport?.payload?.data?.reduce(function (r, a) {
        r[a.item_code] = r[a.item_code] || [];
        r[a.item_code].push(a);
        return r;
    }, Object.create(null));

    const wiseStockReport = result && Object.values(result);

    const values = {
        item_id: null,
        location_id: null,
    };

    const { Option } = Select;
    let pendingDate = "";

    const onChangeDate = (date, dateString) => {
        pendingDate = dateString;
    };

    const sesionSelectAll = (e) => {
        setAllSesion(e.target.checked);
    };

    const PCSelectAll = (e) => {
        if (allPC) {
            setAllPC(e.target.checked);
        }
        else if (!allPC) {
            setAllPC()
        }

    };

    const isCheckboxChecked = (id, value) => {
        const filterCheckBox =
            season && season?.payload?.data.find((val) => val.season_id === id);
        const checkId = isChecked.includes(id);
        if (!checkId) {
            setIsChecked((current) => [...current, filterCheckBox.season_id]);
        }
        if (!value) {
            const removeUnCheck = isChecked.filter(
                (item) => item !== filterCheckBox.season_id
            );
            setIsChecked(removeUnCheck);
        }
        setAllSesion(false);
    };

    const isPCBoxChecked = (id, value) => {
        const filterCheckBox =
            productClass && productClass?.payload?.data.find((val) => val.product_class_id === id);
        const checkId = isPCChecked.includes(id);
        if (!checkId) {
            setIsPCChecked((current) => [...current, filterCheckBox.product_class_id]);
        }
        if (!value) {
            const removeUnCheck = isPCChecked.filter(
                (item) => item !== filterCheckBox.product_class_id
            );
            setAllPC(true)
            setIsPCChecked(removeUnCheck);
        }

        setAllPC(false);
    };

    const onFinish = async (e) => {
        await form.validateFields().then((values) => {

            dispatch(getOmsStockSummary(token, values, isChecked, isPCChecked, BgradeCheck))

        });
    };

    useEffect(() => {
        dispatch(getStyle(token, menuId));
        dispatch(getProductType(token, values));
        dispatch(getGenderCategory(token, menuId));
        dispatch(getSeason(token, menuId));
        dispatch(getProductStatus(token, menuId));
        dispatch(getProductClass(token, menuId))
        dispatch(getSize(token, menuId))
        dispatch(getGender(token, menuId))
        dispatch(getFitCategory(token, menuId))
    }, [dispatch, menuId, token]);


    return (
        <div className="distributor header_class business_header_oms">
            <div className="row business_class_oms">
                <div className="col-sm-3 d-flex align-items-sm-center box-space">
                    <div className="logobrand">
                        <img id="ImgLogo" alt="logo" src={logo} />
                    </div>
                </div>
                <div className="col-sm-6 box-space pl-sm-0">
                    <div className="brand-para">
                        <div>DL1961 Premium Denim Inc.</div>
                        <div>121 Varick Street</div>
                        <div>4th Floor</div>
                        <div>New York, NY 10013</div>
                        <div>(P) 646-745-1524</div>
                        <div>(F) 212-991-9989</div>
                        <div>(E) sales@DL1961.com</div>
                    </div>
                </div>
                <div className="col-sm-3 box-space right-content-header text-sm-right">
                    <span style={{ fontSize: "small", fontWeight: "bolder" }}>
                        Stock Report Summary
                        <br />
                        Rpt # 489
                        <br />
                        <p className=" mb-2">Rpt # 001_V4</p>
                        <div className=' d-flex justify-content-end'>
                            <div className='print_icon'>
                                <i id="Image1" title="Print" alt='Print' src={btnPrint} className="fa-solid fa-print"></i>
                            </div>
                            <div className='print_icon' name="ImgExcel" alt='Export to excel' id="ImgExcel" title="Export to excel" src={xlsx}>
                                <i className="fa-solid fa-file-excel"></i>
                            </div>
                        </div>
                    </span>
                </div>
            </div>
            <div className="row business_class_oms">
                <div className="col text-sm-right pb-1">
                    Login as: {" "}
                    <span id="lblSalesPerson" style={{ fontWeight: "bold" }}>
                        {userName}
                    </span>
                </div>
            </div>

            <div className='row'>
                <div className='col'>
                    <table id="Table1" cellPadding="3" cellSpacing="0" align="center" className="tablestyleControls">
                        <tbody>
                            <tr>
                                <td width="103">
                                    Produc Category:
                                </td>
                                <td>
                                    <b>
                                        {(filterproductCat === undefined || filterproductCat == null) ? "All" : filterproductCat.gender_category_name}
                                    </b>
                                </td>
                                <td width="103">
                                    Gender:
                                </td>
                                <td>
                                    <b>
                                        {(filterGender === undefined || filterGender === null) ? "All" : filterGender.gender_name}
                                    </b>
                                </td>
                            </tr>
                            <tr>
                                <td >
                                    Style:
                                </td>
                                <td>
                                    <b>
                                        {filterStyle === undefined || filterStyle === undefined ? "All" : filterStyle.style_name}
                                    </b>
                                </td>
                                <td style={{ width: "20px" }} className="no-border-btm" >
                                    Style Category:
                                </td>
                                <td>
                                    <b>
                                        {(filterFitCategory === undefined || filterFitCategory === null) ? "All" : filterFitCategory.fit_category_name}
                                    </b>

                                </td>
                            </tr>
                            <tr>
                                <td>
                                    SKU:
                                </td>
                                <td>
                                    <b>
                                        {(sku === "" || sku === null) ? "-" : sku}
                                    </b>

                                </td>
                                <td style={{ width: "60px" }} className="no-border-btm" >
                                    Pending Till:
                                </td>
                                <td>
                                    <b>
                                        {as_on_date}
                                    </b>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ width: "80px" }}>
                                    Product Status:
                                </td>
                                <td>
                                    <b>
                                        {(filterproductStatus === undefined || filterproductStatus === null) ? "All" : filterproductStatus.product_status_name}
                                    </b>
                                </td>
                                <td className="no-border-btm" >
                                    Sort on:
                                </td>
                                <td>
                                    <b>
                                        {openStock === "SOS" ? "Style/Open Stock" : "Open Stock"}
                                    </b>
                                </td>
                            </tr>
                        </tbody >
                    </table >
                </div>
            </div>
            <div className="business_class_oms mb-4">
                <Form form={form} name="control-hooks" onFinish={onFinish}>
                    <div className='main_form row'>
                        <div className="col-12">
                        </div>
                    </div>
                </Form>
            </div >
            <div>


                <div className=""
                    style={{ overflow: "auto", }}
                >
                    {omsStockSummary?.loading ?
                        <>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}>
                                <div >
                                    <Spin size="medium" />
                                </div>
                            </div>
                        </> :

                        <>
                            {omsStockSummary?.payload?.data ?
                                <table className="table_distributor-data stock_table table_content_oms" style={{ margin: "10px 0px" }}>
                                    <tbody>
                                        <tr style={{ background: "#3c4b58", border: " 2px solid #3c4b58 ", height: " 34px", color: "white" }}>
                                            <td>#</td>
                                            <td>Gender</td>
                                            <td><div>Gender Category</div></td>
                                            <td><div> Current Season</div></td>
                                            <td>Fabric</td>
                                            <td>Inseam</td>
                                            <td>SKU</td>
                                            <td>Style</td>
                                            <td>Wash</td>
                                            <td><div>Fit Category</div></td>
                                            <td>Production</td>
                                            <td>PK</td>
                                            <td>Transit</td>
                                            <td>Bergan</td>
                                            <td>Total</td>
                                            <td>Pending</td>
                                            <td>Intl</td>
                                            <td>OTS</td>
                                            {/* <td>BGS</td> */}
                                            <td><div>Retail</div></td>
                                            <td><div>Cost Price</div></td>

                                        </tr>
                                        {
                                            omsStockSummary?.payload?.data.map((data, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{data.gender}</td>
                                                        <td>{data.gender_cateogry}</td>
                                                        <td>{data.current_season}</td>
                                                        <td>{data.fabric}</td>
                                                        <td>{data.inseam}</td>
                                                        <td>
                                                            <Link style={{ color: "blue", textDecoration: "underline" }} to={{
                                                                pathname: `/oms-stock-detail`,
                                                                search: `?sku=${data.style_id}&date_till=${moment(form.getFieldValue().as_on_date).format("MM-DD-YYYY")}`,
                                                            }}>
                                                                {data.item_code}
                                                            </Link>
                                                        </td>
                                                        {/* <td>{data.style}</td> */}
                                                        <td>
                                                            {data.style}
                                                        </td>
                                                        <td>{data.wash}</td>
                                                        <td>{data.fit_category}</td>
                                                        <td>{data.production}</td>
                                                        <td>{data.pakistan}</td>
                                                        <td>{data.transit}</td>
                                                        <td>{data.bergan}</td>
                                                        <td>{data.production + data.pakistan + data.transit + data.bergan}</td>
                                                        <td>{data.pending}</td>
                                                        <td>{data.international}</td>
                                                        <td>{data.ots}</td>
                                                        {/* <td>{data.bgs}</td> */}
                                                        <td>{data.retail_price}</td>
                                                        <td>{data.ws_price}</td>


                                                    </tr>
                                                )
                                            })}
                                    </tbody>
                                </table> :
                                <tbody>
                                    <tr style={{
                                        backgroundColor: "#CCCCCC", borderWidth: "1pt", borderStyle: "Dashed", fontWeight: "bold"
                                    }} >
                                        <td colSpan="5">
                                            No record(s)</td>
                                    </tr>
                                </tbody>}
                        </>
                    }
                </div >
            </div>
        </div >
    );
};

export default OmsStockSummaryStyle;
