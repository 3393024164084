import {
    ADD_CUSTOMER_UPLAOD_REQUEST, ADD_CUSTOMER_UPLAOD_SUCCESS, ADD_CUSTOMER_UPLAOD_ERROR, GET_CUSTOMER_UPLAOD_REQUEST, GET_CUSTOMER_UPLAOD_SUCCESS,
    GET_CUSTOMER_UPLAOD_ERROR, DOWNLOAD_CUSTOMER_FILE_REQUEST, DOWNLOAD_CUSTOMER_FILE_SUCCESS, DOWNLOAD_CUSTOMER_FILE_ERROR
} from "../actions/types";

export default function (state = [], action) {
    const { type, payload } = action;

    switch (type) {
        case GET_CUSTOMER_UPLAOD_REQUEST:
            return {
                loading: true,
            };
        case GET_CUSTOMER_UPLAOD_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case GET_CUSTOMER_UPLAOD_ERROR:
            return {
                ...state,
                payload,
                loading: false,
            };
        case DOWNLOAD_CUSTOMER_FILE_REQUEST:
            return {
                loading: true,
            };
        case DOWNLOAD_CUSTOMER_FILE_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case DOWNLOAD_CUSTOMER_FILE_ERROR:
            return {
                ...state,
                payload,
                loading: false,
            };
        case ADD_CUSTOMER_UPLAOD_REQUEST:
            return {
                loading: true,
            };
        case ADD_CUSTOMER_UPLAOD_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case ADD_CUSTOMER_UPLAOD_ERROR:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
}
