import { GET_TAX_SETUP_REQUEST,GET_TAX_SETUP_SUCCESS,GET_TAX_SETUP_ERROR,ADD_TAX_SETUP_ERROR,ADD_TAX_SETUP_REQUEST,ADD_TAX_SETUP_SUCCESS,
    DELETE_TAX_SETUP_ERROR,DELETE_TAX_SETUP_REQUEST,DELETE_TAX_SETUP_SUCCESS,UPDATE_TAX_SETUP_ERROR,UPDATE_TAX_SETUP_REQUEST,UPDATE_TAX_SETUP_SUCCESS } from "../actions/types";


    export default function (state = [], action) {
        const { type, payload } = action;
        switch (type) {
          case GET_TAX_SETUP_REQUEST:
            return {
              loading: true,
            };
          case GET_TAX_SETUP_SUCCESS:
            return {
              ...state,
              loading: false,
              payload,
            };
          case GET_TAX_SETUP_ERROR:
            return {
              ...state,
              loading: false,
              payload,
            };
            case ADD_TAX_SETUP_REQUEST:
              return {
                  loading: true,
              };
          case ADD_TAX_SETUP_SUCCESS:
              return {
                  ...state,
                  loading: false,
                  payload: [payload],
              };
          case ADD_TAX_SETUP_ERROR:
              return {
                  ...state,
                  loading: false
              };
              case UPDATE_TAX_SETUP_REQUEST:
                return {
                    loading: true,
                };
            case UPDATE_TAX_SETUP_SUCCESS:
                return {
                    ...state,
                    loading: false,
                    payload: [payload],
                };
            case UPDATE_TAX_SETUP_ERROR:
                return {
                    ...state,
                    loading: false
                };
                case DELETE_TAX_SETUP_REQUEST:
                  return {
                      loading: true,
                  };
              case DELETE_TAX_SETUP_SUCCESS:
                  return {
                      ...state,
                      loading: false,
                      payload: [payload],
                  };
              case DELETE_TAX_SETUP_ERROR:
                  return {
                      ...state,
                      loading: false
                  };
    
    
          default:
            return state;
        }
      }
      