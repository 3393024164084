import React, { useEffect, useMemo, useRef } from "react";
import "./OrderSalesReport.css";
import OrderReportHeader from "./OrderReportHeader/OrderReportHeader";
import OrderReportFooter from "./OrderReportFooter/OrderReportFooter";
import { useDispatch, useSelector } from "react-redux";
import { ReadCookie } from "../../utils/readCookie";
import {
  getPickTicketPrint,
  getPickTicketColumn,
  getPickTicketColumnValue,
  getPickTicketItem
} from "../../redux/actions/pickTicketReport.action"
import { Spin } from "antd";
import { useLocation } from "react-router-dom";

const OrderReport = () => {
  const dispatch = useDispatch();
  const menuId = ReadCookie("menuId");
  const token = ReadCookie("token");
  const {
    PickTicketPrint,
    PickTicketColumnValue,
    PickTicketColumn,
    PickTicketItem
  } = useSelector((state) => state);

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();

  let ship_header_id = query.get("ship_header_id")

  useEffect(() => {
    dispatch(getPickTicketItem(token, values));
    dispatch(getPickTicketPrint(token, values));
    dispatch(getPickTicketColumnValue(token, values));
    dispatch(getPickTicketColumn(token, values));
  }, []);

  const footerData =
    PickTicketPrint && PickTicketPrint?.payload?.data[0];

  let groupValues =
    PickTicketColumnValue &&
    PickTicketColumnValue?.payload?.data.reduce(function (r, a) {
      r[a.gender_category_id] = r[a.gender_category_id] || [];
      r[a.gender_category_id].push(a);
      return r;
    }, Object.create(null));

  const groupedColumnValue = groupValues && Object.values(groupValues);

  const values = {
    ship_header_id: ship_header_id,
  };


  const sum = PickTicketColumnValue?.payload?.data.reduce((acc, item, index) => acc + parseInt(item.quantity), 0)


  const myData = useMemo(() => {
    return PickTicketColumn?.payload?.data.map((it, i) => {
      return {
        column: it,
        detail: groupedColumnValue && groupedColumnValue[i],
      };
    });
  }, [groupedColumnValue, PickTicketColumn]);

  return (
    <div>
      {
        myData ? (
          <div
            className="order_report_view"
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <table>
              <thead>
                <tr>
                  <td className="footer">
                    <div>
                      <OrderReportHeader />
                    </div>
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className="content-block">
                      <div className="sales-report-Table-data">
                        {myData?.map((size, index) => {
                          return (
                            <table className="sales-report-table">
                              <tbody>
                                <tr>
                                  <td
                                    className="font_weight"
                                    style={{
                                      width: "36px",
                                    }}
                                  >
                                    S.No
                                  </td>
                                  <td
                                    className="font_weight"
                                    style={{
                                      
                                      width: "148px",
                                    }}
                                  >
                                    SKU #
                                  </td>
                                  <td
                                    className="font_weight"
                                    style={{
                                      
                                      width: "340px",
                                    }}
                                  >
                                    Style/Desc
                                    <span style={{ marginLeft: "5px" }}>
                                      {size?.column?.gender_category_name}
                                    </span>
                                  </td>
                                  <td
                                    key={index}
                                    className="sales_table_col font_weight text-center pl-0"
                                  >
                                    {size.column.hq23 && size.column.hq23}
                                  </td>
                                  <td className="sales_table_col font_weight text-center pl-0">
                                    {size.column.hq24 && size.column.hq24}
                                  </td>
                                  <td className="sales_table_col font_weight text-center pl-0">
                                    {size.column.hq25 && size.column.hq25}
                                  </td>
                                  <td className="sales_table_col font_weight text-center pl-0">
                                    {size.column.hq26 && size.column.hq26}
                                  </td>
                                  <td className="sales_table_col font_weight text-center pl-0">
                                    {size.column.hq27 && size.column.hq27}
                                  </td>
                                  <td className="sales_table_col font_weight text-center pl-0">
                                    {size.column.hq28 && size.column.hq28}
                                  </td>
                                  <td className="sales_table_col font_weight text-center pl-0">
                                    {size.column.hq29 && size.column.hq29}
                                  </td>
                                  <td className="sales_table_col font_weight text-center pl-0">
                                    {size.column.hq30 && size.column.hq30}
                                  </td>
                                  <td className="sales_table_col font_weight text-center pl-0">
                                    {size.column.hq31 && size.column.hq31}
                                  </td>
                                  <td className="sales_table_col font_weight text-center pl-0">
                                    {size.column.hq32 && size.column.hq32}
                                  </td>
                                  <td className="sales_table_col font_weight text-center pl-0">
                                    {size.column.hq33 && size.column.hq33}
                                  </td>
                                  <td className="sales_table_col font_weight text-center pl-0">
                                    {size.column.hq34 && size.column.hq34}
                                  </td>
                                  <td className="sales_table_col font_weight text-center pl-0">
                                    {size.column.hq35 && size.column.hq35}
                                  </td>
                                  <td className="sales_table_col font_weight text-center pl-0">
                                    {size.column.hq36 && size.column.hq36}
                                  </td>
                                  <td className="sales_table_col font_weight text-center pl-0">
                                    {size.column.hq37 && size.column.hq37}
                                  </td>
                                  <td className="sales_table_col font_weight text-center pl-0">
                                    {size.column.hq38 && size.column.hq38}
                                  </td>
                                  <td
                                    className="font_weight"
                                    style={{
                                      width: "50px",
                                    }}
                                  >
                                    Qty
                                  </td>
                                  <td
                                    className="font_weight"
                                    style={{
                                      
                                      width: "90px",
                                    }}
                                  >
                                    Rate
                                  </td>
                                  <td
                                    className="font_weight"
                                    style={{
                                      width: "80px",
                                    }}
                                  >
                                    Amount
                                  </td>
                                </tr>
                                {/* dummy data */}

                                {size.detail?.map((it, index) => {

                                  return (
                                    <tr>
                                      <td>{index + 1}</td>
                                      <td
                                        style={{
                                          marginLeft: "0",
                                        }}
                                      >
                                        {it.item_code}
                                      </td>
                                      <td>
                                        {it.description}
                                      </td>
                                      <td className="text-center pl-0">
                                        {it.q23 && it.q23}
                                      </td>
                                      <td className="text-center pl-0">
                                        {it.q24 && it.q24}
                                      </td>
                                      <td className="text-center pl-0">
                                        {it.q25 && it.q25}
                                      </td>
                                      <td className="text-center pl-0">
                                        {it.q26 && it.q26}
                                      </td>
                                      <td className="text-center pl-0">
                                        {it.q27 && it.q27}
                                      </td>
                                      <td className="text-center pl-0">
                                        {it.q28 && it.q28}
                                      </td>
                                      <td className="text-center pl-0">
                                        {it.q29 && it.q29}
                                      </td>
                                      <td className="text-center pl-0">
                                        {it.q30 && it.q30}
                                      </td>
                                      <td className="text-center pl-0">
                                        {it.q31 && it.q31}
                                      </td>
                                      <td className="text-center pl-0">
                                        {it.q32 && it.q32}
                                      </td>
                                      <td className="text-center pl-0">
                                        {it.q33 && it.q33}
                                      </td>
                                      <td className="text-center pl-0">
                                        {it.q34 && it.q34}
                                      </td>
                                      <td className="text-center pl-0">
                                        {it.q35 && it.q35}
                                      </td>
                                      <td className="text-center pl-0">
                                        {it.q36 && it.q36}
                                      </td>
                                      <td className="text-center pl-0">
                                        {it.q37 && it.q37}
                                      </td>
                                      <td className="text-center pl-0">
                                        {it.q38 && it.q38}
                                      </td>
                                      <td>
                                        {it.quantity && Number(it.quantity).toFixed(0)}
                                      </td>

                                      <td>$ {it.rate && Number(it.rate).toFixed(2)}</td>
                                      <td>$ {it.amount && Number(it.amount).toFixed(2)}</td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          );
                        })}

                        {/* <div className="total_qty">Total Quantity = {sum}</div> */}
                      </div>
                    </div>
                    <table className="sales-report-table-total">
                      <tfoot>
                        <tr className="font_weight ">
                          <td
                            style={{
                              width: "100px",
                              textAlign: "right",
                              paddingRight: "10px",
                            }}
                            className="font_weight total_cell">Total Quantity</td>
                          <td className="sum_cell"
                            style={{
                              width: "48px",
                            }}
                          >
                            {sum}
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                    {/* {footerData && (footerData.term === "CREDIT CARD" || footerData.term === "PRE-PAYMENT" || footerData.term === "WIRE TRANSFER") ? */}
                      <table className="sales-report-table-total">
                        <tbody>
                          <tr style={{
                            width: "100px",
                            textAlign: "right",
                            paddingRight: "10px",
                          }} className="font_weight total_cell">{footerData.term} ORDER</tr>
                          <tr className="font_weight">
                            <td
                              style={{
                                width: "100px",
                                textAlign: "right",
                                paddingRight: "10px",
                              }}
                              className="font_weight total_cell"
                            >
                              Amount
                            </td>
                            <td
                              className="sum_cell"
                              style={{
                                width: "48px",
                              }}
                            >
                              {footerData && Number(footerData.sum_gross_amount).toFixed(2)}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                width: "100px",
                                textAlign: "right",
                                paddingRight: "10px",
                              }}
                              className="font_weight"
                            >
                              Disc %
                            </td>
                            <td>{footerData && footerData.discount} %</td>
                          </tr>
                          <tr>
                            <td
                              className="font_weight"
                              style={{
                                width: "100px",
                                textAlign: "right",
                                paddingRight: "10px",
                              }}
                            >
                              Freight Amount
                            </td>
                            <td>$ {footerData && footerData.freight_amount}</td>
                          </tr>
                          <tr>
                            <td
                              className="font_weight"
                              style={{
                                width: "100px",
                                textAlign: "right",
                                paddingRight: "10px",
                              }}
                            >
                              Net Amount
                            </td>
                            <td>$ {footerData && Number(footerData.net_amount).toFixed(2)}</td>
                          </tr>
                        </tbody>
                      </table>
                      {/* : <></>} */}
                    {/* <div>
                  <div className="signature">
                    Authorized Signature
                  </div>
                </div> */}
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td className="footer2">

                    <div className="sales-report-footer">
                      <OrderReportFooter />
                    </div>
                  </td>
                </tr>
              </tfoot>
            </table>
            {/* <div className="sales-report-footer">
              <OrderReportFooter />
            </div> */}
          </div>

        ) : (
          <div className="spin">
            <Spin size="large" />
          </div>
        )
      }

      {/* <div className="sales-report-footer">
        <OrderReportFooter />
      </div> */}
    </div>
  );
};

export default OrderReport;
