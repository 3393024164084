import {
	ADD_BILL_CREDIT_ITEM_LINE_ERROR,
	ADD_BILL_CREDIT_ITEM_LINE_REQUEST,
	ADD_BILL_CREDIT_ITEM_LINE_SUCCESS,
	DELETE_BILL_CREDIT_ITEM_LINE_ERROR,
	DELETE_BILL_CREDIT_ITEM_LINE_REQUEST,
	DELETE_BILL_CREDIT_ITEM_LINE_SUCCESS,
	GET_ONE_BILL_CREDIT_ITEM_LINE_DETAIL_ERROR,
	GET_ONE_BILL_CREDIT_ITEM_LINE_DETAIL_REQUEST,
	GET_ONE_BILL_CREDIT_ITEM_LINE_DETAIL_SUCCESS,
	GET_ONE_BILL_CREDIT_ITEM_LINE_ERROR,
	GET_ONE_BILL_CREDIT_ITEM_LINE_REQUEST,
	GET_ONE_BILL_CREDIT_ITEM_LINE_SUCCESS,
	UPDATE_BILL_CREDIT_ITEM_LINE_ERROR,
	UPDATE_BILL_CREDIT_ITEM_LINE_REQUEST,
	UPDATE_BILL_CREDIT_ITEM_LINE_SUCCESS,

} from "./types";
import { BASEURL } from "../../constant/config.js";
const axios = require("axios");

export const addBillCreditLine = (token, object) => (dispatch) => {
	dispatch({ type: ADD_BILL_CREDIT_ITEM_LINE_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}create/paypurchasebillcredititem`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: object,
	})
		.then((response) => {
			return dispatch({
				type: ADD_BILL_CREDIT_ITEM_LINE_SUCCESS,
				payload: response?.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: ADD_BILL_CREDIT_ITEM_LINE_ERROR,
				payload: error.response,
			});
		});
};

export const updateBillCreditLine = (token, object, BillId) => (dispatch) => {
	dispatch({ type: UPDATE_BILL_CREDIT_ITEM_LINE_REQUEST });
	return axios({
		method: "PUT",
		url: `${BASEURL}update/paypurchasebillcredititem/${BillId}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: {
			dataLine: object,
		},
	})
		.then((response) => {
			return dispatch({
				type: UPDATE_BILL_CREDIT_ITEM_LINE_SUCCESS,
				payload: response?.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: UPDATE_BILL_CREDIT_ITEM_LINE_ERROR,
				payload: error.response,
			});
		});
};

export const getOneBillCreditLineDetail = (token, id) => (dispatch) => {
	dispatch({ type: GET_ONE_BILL_CREDIT_ITEM_LINE_DETAIL_REQUEST });
	return axios({
		method: "GET",
		url: `${BASEURL}getOne/paypurchasebillcredititem/${id}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
	})
		.then((response) => {
			return dispatch({
				type: GET_ONE_BILL_CREDIT_ITEM_LINE_DETAIL_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_ONE_BILL_CREDIT_ITEM_LINE_DETAIL_ERROR,
				payload: error.response,
			});
		});
};

export const getOneBillCreditLine = (token, id) => (dispatch) => {
	dispatch({ type: GET_ONE_BILL_CREDIT_ITEM_LINE_REQUEST });
	return axios({
		method: "GET",
		url: `${BASEURL}getOne/paypurchasebillcredititem/${id}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
	})
		.then((response) => {

			return dispatch({
				type: GET_ONE_BILL_CREDIT_ITEM_LINE_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_ONE_BILL_CREDIT_ITEM_LINE_ERROR,
				payload: error.response,
			});
		});
};

export const deleteBillCreditLine = (token, id, deletedItems) => (dispatch) => {
	
	dispatch({ type: DELETE_BILL_CREDIT_ITEM_LINE_REQUEST });
	return axios({
		method: "DELETE",
		url: `${BASEURL}delete/paypurchasebillcredititem/${id}`,
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + token,
		},
		data: deletedItems
	})
		.then((response) => {
			return dispatch({
				type: DELETE_BILL_CREDIT_ITEM_LINE_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: DELETE_BILL_CREDIT_ITEM_LINE_ERROR,
				payload: error.response,
			});
		});
};