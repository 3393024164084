import {
    GET_POD_STATE_REPORT_ERROR,
    GET_POD_STATE_REPORT_SUCCESS,
    GET_POD_STATE_REPORT_REQUEST,
} from "../actions/types"
export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {

        case GET_POD_STATE_REPORT_REQUEST:
            return {
                loading: true,
            };
        case GET_POD_STATE_REPORT_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_POD_STATE_REPORT_ERROR:
            return {
                ...state,
                loading: false
            };
        default:
            return state;
    }
}