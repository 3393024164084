import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { getWashName } from "../../redux/actions/wash.action";
import { Button, Form, Input, Select, Radio } from 'antd';
import { getStyle } from '../../redux/actions/style';
import { ReadCookie } from "../../utils/readCookie";
import { CloseCircleOutlined } from '@ant-design/icons';
import { getGenderCategory } from '../../redux/actions/genderCategory.action';
import { getFabric } from '../../redux/actions/fabric.action';
import { getGender } from '../../redux/actions/gender.action';
import { getFitCategory } from '../../redux/actions/fitCategory.action';
import { getSeason } from '../../redux/actions/season.action';
import { useHistory } from "react-router-dom";
import "./productList.css";
import { Row as Brow, Col as Bcol } from "reactstrap";
import { getReportIP } from '../../redux/actions/reportIP.action';
import { TabTitle } from '../../utils/GeneralFunction';

const ProductReportList = ({ token }) => {
    TabTitle("Product List")
    const { style, wash, fabric, gender, fitCategory, season, genderCategory } = useSelector(state => state)
    const [styleID, setStyleID] = useState('All')
    const [washID, setWashID] = useState('All')
    
    const [fabricID, setFabricID] = useState('All')
    const [seasonID, setSeasonID] = useState('All')
    const [genderID, setGenderID] = useState('All')
    const [genderCatID, setGenCatID] = useState('All')
    const [fitCatID, setFitCatID] = useState('All')
    const [active, setActive] = useState(true)
    const [status, setStatus] = useState(1)
    const menuId = ReadCookie('menuId')
    const userName = ReadCookie('name')
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [sku, setSku] = useState('')
    const [reportIP, setReportIP] = useState('');
    const { Option } = Select;
    let history = useHistory();
    

    useEffect(() => {
        
        dispatch(getReportIP(token)).then((res) => {
            setReportIP(res.payload.data[0].report_server_ip)
        });
        dispatch(getGender(token, menuId));
        dispatch(getGenderCategory(token, menuId));
        dispatch(getStyle(token, menuId));
        dispatch(getFabric(token, menuId));
        dispatch(getFitCategory(token, menuId));
        dispatch(getSeason(token, menuId));
        dispatch(getWashName(token, menuId))
    }, [])

    const onFinish = (values) => {
        window.open(`${reportIP}birt/frameset?__report=ProductReport.rptdesign&__format=html&__svg=true&__locale=en_US&__timezone=PLT&__masterpage=true&__rtl=false&__cubememsize=10&__resourceFolder=C%3A%5CUsers%5Cadm%5Ceclipse%5Crcp-2021-09%5Ceclipse%5Cworkspace%5CERP-Reports&__parameterpage=false&-632080003&${sku === '' ? "__isnull=p_sku" : `p_sku=${sku}`}&${styleID === 'All' ? "__isnull=p_style_id" : `p_style_id=${styleID}`}&${washID === 'All' ? "__isnull=p_wash_id" : `p_wash_id=${washID}`}&${genderID === 'All' ? "__isnull=p_gender_id" : `p_gender_id=${genderID}`}&${fabricID === 'All' ? "__isnull=p_fabric_id" : `p_fabric_id=${fabricID}`}&${genderCatID === 'All' ? "__isnull=p_gender_category_id" : `p_gender_category_id=${genderCatID}`}&${fitCatID === 'All' ? "__isnull=p_fit_category_id" : `p_fit_category_id=${fitCatID}`}&${seasonID === 'All' ? "__isnull=p_season_id" : `p_season_id=${seasonID}`}&${`p_product_status_id=${status}`}&${`p_is_Active=${active}`}&user_name=${userName}`)

    };

    const clearFields = (event, type) => {
        event.preventDefault()
        if (type === 'sku') {
            setSku('')
        }
        else if (type === 'style') {
            setStyleID('All')
        }
        else if (type === 'fabric') {
            setFabricID('All')
        }
        else if (type === 'wash') {
            setWashID('All')
        }
        else if (type === 'gender') {
            setGenderID('All')
        }
        else if (type === 'genderCat') {
            setGenCatID('All')
        }
        else if (type === 'fitCat') {
            setFitCatID('All')
        }
        else if (type === 'season') {
            setSeasonID('All')
        }
    };

    return (
        <div className='product-detail-main-wrapper' style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        }}>
            <div className='product-detail-main' style={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}>
                <div className='pageHead ' >
                    <h4 className='prod-det-title-hd mb-0'>Product Details</h4>
                    <div>
                        <Button className="pos-right-10 iconBtn crudBTN" shape='circle' icon={<CloseCircleOutlined className='color-red' />} onClick={() => history.push("/")} />
                    </div>
                </div>
                <div className='product-detail-content'>
                    <Form
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 18 }}
                        layout="horizontal"
                        form={form} name="control-hooks" onFinish={onFinish}>
                        <Brow>
                            <Bcol lg={8} xl={8} xxl={8}>
                                <div className="date-filter">
                                    <h3 className="filter-title">Product Status</h3>
                                    <Form.Item
                                        name="productStatus"
                                    >
                                        <Radio.Group onChange={(e) => setStatus(e.target.value)} defaultValue={status} style={{ width: 375 }}>
                                            <Radio value={1}>Active line</Radio>
                                            <Radio value={2}>Discountinued</Radio>
                                            <Radio value={3}>Off Price</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                </div>
                                <div className="date-filter">
                                    <h3 className="filter-title">Filter</h3>
                                    <Form.Item
                                        name="gender"
                                        label="Gender"
                                    >
                                        <Select
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            filterSort={(optionA, optionB) =>
                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                            }
                                            defaultValue="Women"
                                            onChange={(e) => setGenderID(e)}
                                            className="filter-fields"
                                            
                                            loading={gender?.loading}
                                            value={genderID}
                                        >
                                            {gender?.payload?.data.map((item) => {
                                                return <Option defaultValue={"All"} key={item.gender_id} value={item.gender_id}>{item.gender_name}</Option>
                                            })
                                            }
                                        </Select>
                                        <button className="auto-complete-text-btn" onClick={(e) => clearFields(e, "gender")}>CLEAR</button>
                                    </Form.Item>
                                    <Form.Item
                                        name="currentSeason"
                                        label="Current Season"
                                    >
                                        <Select
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            filterSort={(optionA, optionB) =>
                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                            }
                                            onChange={(e) => setSeasonID(e)}
                                            className="filter-fields"
                                            
                                            loading={season?.loading}
                                            defaultValue="All"
                                            value={seasonID}
                                        >
                                            {
                                                season?.payload?.data.map((item) => {
                                                    return <Option defaultValue="All" key={item.season_id} value={item.season_id}>{item.season_name}</Option>
                                                })
                                            }
                                        </Select>
                                        <button className="auto-complete-text-btn" onClick={(e) => clearFields(e, "season")}>CLEAR</button>
                                    </Form.Item>
                                    <Form.Item
                                        name="fabric"
                                        label="Fabric"
                                    >
                                        <Select
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            filterSort={(optionA, optionB) =>
                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                            }
                                            onChange={(e) => setFabricID(e)}
                                            className="filter-fields"
                                            
                                            loading={fabric?.loading}
                                            defaultValue="All"
                                            value={fabricID}
                                        >
                                            {
                                                fabric?.payload?.data.map((item) => {
                                                    return <Option defaultValue="All" key={item.fabric_id} value={item.fabric_id}>{item.fabric_name}</Option>
                                                })
                                            }
                                        </Select>
                                        <button className="auto-complete-text-btn" onClick={(e) => clearFields(e, "fabric")}>CLEAR</button>
                                    </Form.Item>
                                    <Form.Item
                                        name="genderCategory"
                                        label="Gender Category"
                                    >
                                        <Select
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            filterSort={(optionA, optionB) =>
                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                            }
                                            defaultValue="All"
                                            onChange={(e) => setGenCatID(e)}
                                            className="filter-fields"
                                            
                                            loading={genderCategory?.loading}
                                            value={genderCatID}

                                        >
                                            {
                                                genderCategory?.payload?.data.map((item) => {
                                                    return <Option defaultValue="All" key={item.gender_category_id} value={item.gender_category_id}>{item.gender_category_name}</Option>
                                                })
                                            }
                                        </Select>
                                        <button className="auto-complete-text-btn" onClick={(e) => clearFields(e, "genderCat")}>CLEAR</button>
                                    </Form.Item>
                                    <Form.Item
                                        name="fitCategory"
                                        label="Fit Category"
                                    >
                                        <Select
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            filterSort={(optionA, optionB) =>
                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                            }
                                            onChange={(e) => setFitCatID(e)}
                                            className="filter-fields"
                                            
                                            loading={fitCategory?.loading}
                                            defaultValue="All"
                                            value={fitCatID}
                                        >
                                            {
                                                fitCategory?.payload?.data.map((item) => {
                                                    return <Option defaultValue="All" key={item.fit_category_id} value={item.fit_category_id}>{item.fit_category_name}</Option>
                                                })
                                            }
                                        </Select>
                                        <button className="auto-complete-text-btn" onClick={(e) => clearFields(e, "fitCat")}>CLEAR</button>
                                    </Form.Item>
                                    <Form.Item
                                        name="style"
                                        label="Style"
                                    >
                                        <Select
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            filterSort={(optionA, optionB) =>
                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                            }
                                            onChange={(e) => setStyleID(e)}
                                            className="filter-fields"
                                            
                                            loading={style?.loading}
                                            defaultValue="All"
                                            value={styleID}

                                        >
                                            {
                                                style?.payload?.data.map((item) => {
                                                    return <Option defaultValue="All" key={item.style_id} value={item.style_id}>{item.style_name}</Option>
                                                })
                                            }
                                        </Select>
                                        <button className="auto-complete-text-btn" onClick={(e) => clearFields(e, "style")}>CLEAR</button>
                                    </Form.Item>
                                    <Form.Item
                                        name="wash"
                                        label="Wash"
                                    >
                                        <Select
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            filterSort={(optionA, optionB) =>
                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                            }
                                            onChange={(e) => setWashID(e)}
                                            className="filter-fields"
                                            
                                            loading={wash?.loading}
                                            defaultValue="All"
                                            value={washID}
                                        >
                                            {
                                                wash?.payload?.data.map((item) => {
                                                    return <Option defaultValue="All" key={item.wash_id} value={item.wash_id}>{item.wash_name}</Option>
                                                })
                                            }
                                        </Select>
                                        <button className="auto-complete-text-btn" onClick={(e) => clearFields(e, "wash")}>CLEAR</button>
                                    </Form.Item>
                                    <Form.Item
                                        name="sku"
                                        label="SKU #"
                                    >
                                        {/* <Input className="filter-fields" placeholder="Enter SKU" onChange={(e) => setSku(e.target.value)} value={sku} /> */}
                                        <button className="auto-complete-text-btn" onClick={(e) => clearFields(e, "sku")}>CLEAR</button>
                                    </Form.Item>
                                </div>
                            </Bcol>
                            <Bcol lg={4} xl={4} xxl={4}>
                                <div className="date-filter">
                                    <h3 className="filter-title">Active</h3>
                                    <Form.Item
                                        name="active"                                    >
                                        <Radio.Group onChange={(e) => setActive(e.target.value)} defaultValue={active} style={{ width: 500 }}>
                                            <Radio value={true}>Yes</Radio>
                                            <Radio value={false}>No</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                </div>
                                <div>
                                    <Form.Item >
                                        <Button type="primary" htmlType="submit" className='w-100'>
                                            Search
                                        </Button>
                                    </Form.Item>
                                </div>
                            </Bcol>
                        </Brow>
                    </Form>
                </div>
            </div>
        </div >
    );
};

export default ProductReportList;