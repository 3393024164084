import { GET_FABRIC_REQUEST, GET_FABRIC_SUCCESS, GET_FABRIC_ERROR, ADD_FABRIC_REQUEST, ADD_FABRIC_ERROR, ADD_FABRIC_SUCCESS, UPDATE_FABRIC_REQUEST, UPDATE_FABRIC_SUCCESS, UPDATE_FABRIC_ERROR, DELETE_FABRIC_ERROR, DELETE_FABRIC_REQUEST, DELETE_FABRIC_SUCCESS } from "./types"
import { BASEURL } from "../../constant/config.js";

const axios = require("axios");

export const getFabric = (token, menu_id) => (dispatch) => {

    dispatch({ type: GET_FABRIC_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/fabric`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { menu_id }
    }).then((response) => {
        return dispatch({ type: GET_FABRIC_SUCCESS, payload: response.data })
    }).catch((error) => {
        return dispatch({ type: GET_FABRIC_ERROR, payload: error.response })
    })
}

export const addFabric = (token, data, menu_id) => (dispatch) => {
    dispatch({ type: ADD_FABRIC_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}create/fabric`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { fabric_name: data.fabricName, fabric_class_id1: data.fabricClassID, fabric_class_id2: data.fabricClassID2, fabric_type_id: data.fabricTypeID, fabric_useable_id: data.fabricUsableID, fabric_composition_id: data.fabricCompositionID, is_active: data.check, menu_id },
    })
        .then((response) => {


            return dispatch({ type: ADD_FABRIC_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: ADD_FABRIC_ERROR,
                payload: error.response,
            });
        });
};

export const updateFabric = (token, data, id) => (dispatch) => {
    dispatch({ type: UPDATE_FABRIC_REQUEST });
    return axios({
        method: "PUT",
        url: `${BASEURL}update/fabric/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { fabric_name: data.fabricName, fabric_class_id1: data.fabricClassID, fabric_class_id2: data.fabricClassID2, fabric_type_id: data.fabricTypeID, fabric_useable_id: data.fabricUsableID, fabric_composition_id: data.fabricCompositionID, is_active: data.check },
    })
        .then((response) => {

            return dispatch({ type: UPDATE_FABRIC_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: UPDATE_FABRIC_ERROR,
                payload: error.response,
            });
        });
};

export const deleteFabric = (token, id) => (dispatch) => {
    dispatch({ type: DELETE_FABRIC_REQUEST });
    return axios({
        method: "DELETE",
        url: `${BASEURL}delete/fabric/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
    })
        .then((response) => {

            return dispatch({ type: DELETE_FABRIC_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: DELETE_FABRIC_ERROR,
                payload: error.response,
            });
        });
};
