





































































































import React, { useState, useEffect, useRef, useLayoutEffect } from 'react'
import { useSelector, useDispatch } from "react-redux";
import PageHeader from "../../components/PageHeader/PageHeader"
import { Button, Row, Col, Form, Input, Checkbox, Tabs, Divider } from 'antd';
import "../Style/style.css";
import { upcSetup } from '../../redux/actions/upcSetup';
import * as XLSX from "xlsx"
import notification from "../../utils/notification";
import { Colors } from 'chart.js';
import style from 'tween-one/es/plugins/StylePlugin';
import { TabTitle } from '../../utils/GeneralFunction';
import { InboxOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';

const { Dragger } = Upload;
const { TabPane } = Tabs;

export default function UpcSetup({ token }) {
	
	TabTitle("Upc Setup")
	const dispatch = useDispatch();
	const { factor, log, userRights } = useSelector(state => state)

	const [minimize, setMinimize] = useState(false);

	const [excelFile, setExcelFile] = useState(null);
	const [excelFile1, setExcelFile1] = useState(null);
	const [excelFileError, setExcelFileError] = useState(null);
	const fileType = ['text/csv'];

	const handleFile = (e) => {
		let selectedFile = e.target.files[0];
		if (selectedFile) {
			if (selectedFile && fileType.includes(selectedFile.type)) {
				let reader = new FileReader();
				reader.readAsArrayBuffer(selectedFile);
				reader.onload = (e) => {
					setExcelFileError(null);
					setExcelFile(e.target.result);
				}
			}
			else {
				setExcelFile(null);
				setExcelFileError('Please select only excel file types');
			}
		}
		else {

		}
	}

	const props = {
		name: 'file',
		accept: ".xls,.xlsx",
		multiple: true,
		action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
		onChange(info, files, filesList) {


			

			
			
			
			
			

			
			
			
			
			
			
			

			
			
			
			
			
			
			
			const { status } = info.file;
			if (status !== 'uploading') {
				let reader = new FileReader();
				reader.readAsArrayBuffer(info.file);
				reader.onload = (e) => {
					const workbook = XLSX.read(e.target.result, { type: 'buffer' });
					
					const worksheetName = workbook.SheetNames[0];
					
					const worksheet = workbook.Sheets[worksheetName];
					
					const data = XLSX.utils.sheet_to_json(worksheet);
					
					dispatch(upcSetup(token, data)).then((res) => {

						if (res.type === "GET_UPC_SETUP_SUCCESS") {
							notification("success", "File Uploaded Successfully", res?.payload?.data);
							

							
							
							
							
						}

					})
				}



				
				
				
				
				
				
				

				
			}
			if (status === 'done') {
				message.success(`${info.file.name} file uploaded successfully.`);
			} else if (status === 'error') {
				message.error(`${info.file.name} file upload failed.`);
			}
		}

	}
	const handleSubmit = (e) => {
		e.preventDefault();
		if (excelFile !== null) {
			
			const workbook = XLSX.read(excelFile, { type: 'buffer' });
			const worksheetName = workbook.SheetNames[0];
			const worksheet = workbook.Sheets[worksheetName];
			const data = XLSX.utils.sheet_to_json(worksheet);
			dispatch(upcSetup(token, data)).then((res) => {

				if (res.type === "GET_UPC_SETUP_SUCCESS") {
					notification("success", "File Uploaded Successfully", res?.payload?.data);
					

					
					
					
					
				}

			})
		}
		else {
			
			

		}
	}
	
	return (
		<div className='styleContainer' >
			<div className='styleBody h-100'  >
				<PageHeader title={"Setup"} minimize={setMinimize} check={minimize} />
				<div style={{ padding: "10px" }}>
					<text >Click here to <a href={window.location.origin + '/Template_UPC_Upload.csv'} download="Template_UPC_Upload.csv" style={{ color: 'blue', textDecoration: "underline", fontSize: "16px" }} >Download</a> Bulk UPC Upload Template</text>
				</div>
				{/* <text>Click here to <a href={window.location.origin + '/Template_UPC_Upload.csv'} download="Template_UPC_Upload.csv" style={{ color: 'blue', textDecoration: "underline" }} >Download</a> Bulk UPC Upload Template</text>
				<div className="firstSectionRo">
					<Form.Item >
						<Input type="file" ClassName="form-control" onChange={handleFile} />
						{excelFileError && <div className="text-danger">{excelFileError}</div>}
					</Form.Item>
					<Button type="submit" className="btn btn-success" style={{ marginTop: 5 + 'px' }} onClick={handleSubmit}>Submit</Button>
				</div> */}
				<Dragger {...props}
					beforeUpload={() => false}
				
				

				
				
				
				
				

				
				
				
				>
					<p className="ant-upload-drag-icon">
						<InboxOutlined />
					</p>
					<p className="ant-upload-text">Click or drag file to this area to upload</p>
					<p className="ant-upload-hint">
						Support for a single or bulk upload. Strictly prohibited from uploading company data or other
						banned files.
					</p>
				</Dragger>
			</div>
		</div >
	)
}








































