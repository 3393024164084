import React, { useState, useLayoutEffect, useEffect } from 'react'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from "react-redux";
import { ReadCookie } from "../../utils/readCookie";
import { Table, Spin } from 'antd';

import { getOmsReportByCustomer } from '../../redux/actions/omsReportByCustomer'
import { getPaymentTerm } from '../../redux/actions/paymentterm.action'
import { getAllCompany } from '../../redux/actions/companyAccess'
import { getRegion } from '../../redux/actions/region.action'
import { getGender } from '../../redux/actions/gender.action'

import "./styleSheet.css"

const OmsReportByCustomer = ({ token, id, reportTittle, reportName }) => {
    const menuId = ReadCookie("menuId");
    const dispatch = useDispatch();
    const [data, setData] = useState([])
    const { gender, region, companyAll, omsReportByCustomer } = useSelector((state) => state);
    const [submit, setSubmit] = useState(false)
    const [loading, setLoading] = useState(false)
    const [values, setValues] = useState(null)
    const history = useHistory();

    function useQuery() {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    let query = useQuery();
    let company_id = query.get("company_id")
    let date_type = query.get("date_type")
    let gender_id = query.get("gender_id")
    let term_id = query.get("term_id")
    let from_date = query.get("from_date")
    let to_date = query.get("to_date")
    let region_id = query.get("region_id")
    let selected_Region = query.get("selected_Region")
    let selected_id = query.get("selected_id")
    let select = query.get("select")
    let state_id = query.get("state_id")
    let customer_id = query.get("customer_id")
    let customer_name = query.get("customer_name")
    let state_name = query.get("state_name")


    useLayoutEffect(() => {
        setValues(history?.location?.values)
        setLoading(true)
        dispatch(getOmsReportByCustomer(token, { id, company_id, date_type, gender_id, from_date, from_date, to_date, term_id, region_id, selected_Region, select, customer_id, state_id })).then((res) => {
            setSubmit(true)
            setLoading(false)
        });

    }, []);

    useEffect(() => {
        dispatch(getRegion(token, menuId));
        dispatch(getGender(token, menuId));
        dispatch(getPaymentTerm(token, menuId))
        dispatch(getAllCompany(token));
    }, [dispatch, menuId, token])

    const columns = [
        {
            title: "#",
            dataIndex: "serial_no",
            key: 'serial_no',
            align: 'left',
            width: 50,
            render: (text, object, index) => index + 1,
        },

        {
            title: 'Order #',
            dataIndex: 'order_header_no',
            key: 'order_header_no',
            align: 'left',
            width: 100,
            render: (record, text) => {
                return <Link style={{ color: "blue", textDecoration: "underline" }} target="_blank" rel="noopener noreferrer"
                    to={{
                        pathname: `/sales-report`,
                        search: `?order_header_id=${text.order_header_id}`
                    }
                    }
                > {record}</ Link >
            }

        },
        {
            title: 'Order Date',
            dataIndex: 'order_date',
            key: 'order_date',
            align: 'left',
            width: 130,
        },
        {
            title: 'Start Date',
            dataIndex: 'start_date',
            key: 'start_date',
            align: 'left',
            width: 130,
        },
        {
            title: 'Cancel Date',
            dataIndex: 'cancel_date',
            key: 'cancel_date',
            align: 'left',
            width: 140,
        },
        {
            title: 'PO #',
            dataIndex: 'reference_no',
            key: 'reference_no',
            align: 'left',
            width: 180,
        },
        {
            title: 'Sales Person',
            dataIndex: 'salesperson_name',
            key: 'salesperson_name',
            align: 'left',
            width: 180,
            sorter: (a, b) => a.salesperson_name - b.salesperson_name,

        },
        {
            title: 'Term',
            dataIndex: 'term_name',
            key: 'term_name',
            align: 'left',
            width: 100
        },

        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
            width: 120,
            align: "right",
            sorter: (a, b) => a.quantity.localeCompare(b.quantity),

        },
        {
            title: 'Amount',
            dataIndex: 'net_amount',
            key: 'net_amount',
            width: 130,
            align: "right",
            render: text => <span style={{ textAlign: "right" }}> {text}</span >,
            sorter: (a, b) => a.net_amount.localeCompare(b.net_amount),
        },
    ];

    const filterGender = gender.payload?.data.find((item) => {
        return item.gender_id === Number(gender_id)
    })

    const filterRegion = region.payload?.data.find((item) => {
        return item.region_id === Number(selected_id)
    })
    return (
        <>
            <div className='row'>
                <div className='col'>
                    <table id="Table1" cellPadding="3" cellSpacing="0" align="center" className="tablestyleControls">
                        <tbody>
                            <tr>
                                <td width="103">
                                    Featuring:
                                </td>
                                <td>
                                    <b>
                                        {gender_id === "All" ? "All" : filterGender && filterGender.gender_name}
                                    </b>
                                </td>
                                <td width="103">
                                    State:
                                </td>
                                <td>
                                    <b>
                                        {select === "true" ? "All" : state_name}
                                    </b>
                                </td>
                            </tr>
                            <tr>
                                <td >
                                    Payment Term:
                                </td>
                                <td>
                                    <b>
                                        {term_id === "0" ? "Without FOC" : "FOC"}
                                    </b>
                                </td>
                                <td style={{ width: "20px" }} className="no-border-btm" >
                                    Region:
                                </td>
                                <td>
                                    <b>
                                        {filterRegion && filterRegion.region_name}
                                    </b>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    From:
                                </td>
                                <td>
                                    <b>
                                        {from_date}
                                    </b>
                                </td>
                                <td style={{ width: "60px" }} className="no-border-btm" >
                                    To:
                                </td>
                                <td>
                                    <b>
                                        {to_date}
                                    </b>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ width: "80px" }}>
                                    Show Orders By:
                                </td>
                                <td>
                                    <b>
                                        {date_type === "B" ? "Booking Date" : "Start Date"}
                                    </b>
                                </td>
                                <td className="no-border-btm" >
                                    Brand :
                                </td>
                                <td>
                                    <b>
                                        {company_id === "2" ? "DL1961 : DL1961(USA) " : ""}
                                    </b>
                                </td>
                            </tr>
                        </tbody >
                    </table >
                    <table className="tablestyleControls" style={{ marginTop: "5px" }} cellpadding="3">
                        <tbody>
                            <tr >
                                <td >
                                    Selected Customer:
                                </td>
                                <td rowSpan={"15"}>
                                    <b>
                                        {customer_name}
                                    </b>
                                </td>
                            </tr>
                        </tbody >
                    </table >
                </div>
            </div>
            {loading ?
                <div className='w-100 d-flex align-items-center justify-content-center spin-style mt-3' >
                    <Spin />
                </div> :
                <>{(submit) ?
                    <div className='w-100 mt-3'>
                        <Table
                            scroll={{ x: 200 }}
                            className='w-100 test04'
                            size='small'
                            pagination={{ pageSize: 3000 }}
                            summary={pageData => {
                                let sumQty = pageData.reduce(function (previousValue, currentValue) {
                                    return previousValue += parseFloat(currentValue.quantity.replace(/,/g, ''))
                                }, 0);
                                let sumAmount = pageData.reduce(function (previousValue, currentValue) {
                                    return previousValue += parseFloat(currentValue.net_amount.replace(/\$|,/g, ''))
                                }, 0);
                                return (
                                    <>
                                        <Table.Summary.Row >
                                            <Table.Summary.Cell>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>

                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p style={{ fontWeight: "bolder" }}>Total</p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p style={{
                                                    fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black",
                                                    borderBottom: "1px solid black"
                                                }}>{sumQty.toLocaleString("en-US")}</p>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <p style={{ fontWeight: "bolder", textAlign: "right", width: "100%", borderTop: "1px solid black", borderBottom: "1px solid black" }}>$ {sumAmount.toLocaleString("en-US")}</p>
                                            </Table.Summary.Cell>
                                        </Table.Summary.Row>
                                    </>
                                );
                            }}
                            bordered
                            columns={columns}
                            dataSource={omsReportByCustomer?.payload?.data}
                            sortDirections={["ascend", "descend", "ascend"]}
                        />
                    </div> : <table className="tablestyle" cellSpacing="0" cellPadding="2" align="Center" rules="all" border="1" id="GridView1" style={{
                        fontSize: "8pt", width: "100%", borderCollapse: "collapse", marginTop: "20px"
                    }} >
                        <tbody>
                            <tr style={{
                                backgroundColor: "#CCCCCC", borderWidth: "1pt", borderStyle: "Dashed", fontWeight: "bold"
                            }} >
                                <td colSpan="5">
                                    No record(s)</td>
                            </tr>
                        </tbody>
                    </table>}
                </>
            }
        </>
    )
}
export default OmsReportByCustomer;
