import { useState, useEffect, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getRights } from "../../redux/actions/userRights.action";
import { loadCountry } from "../../redux/actions/adminCountry";
import { loadState } from "../../redux/actions/adminState";
import { loadCity } from "../../redux/actions/adminCity";

import Tables from "../../components/Table/Tables";
import { Button, Form, Input, Modal, Select, Switch, Tooltip, Popconfirm } from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined, FilterFilled, SearchOutlined } from "@ant-design/icons";
import { ReadCookie } from "../../utils/readCookie";
import { Row as Brow, Col as Bcol } from "reactstrap";
import { getOneCustomerAddress } from "../../redux/actions/customerAddress.action";

const { confirm } = Modal;
const { Option } = Select;

export default function BillingSection({ customer_id, token, formType, addressDataBill, cusID, billingAddress, setBillingAddress, deletedBillingLine, setDeletedBillingLine, test, setTest, oldTruee, formState }) {
  const { Billing, City, State, userRights, country, customerAddress } = useSelector((state) => state);
  const [biliingObj, setBillingObj] = useState(null);
  const [showTable, setShowTable] = useState(false);
  const [eventType, setEventType] = useState("");
  const [countryForm, setCountry] = useState({});
  const [visible, setVisible] = useState(false);
  const [editForm, setEditForm] = useState("");
  const [stateForm, setState] = useState({});
  const [billID, setBillID] = useState(null);
  const [cityForm, setCity] = useState({});
  const [count, setCount] = useState(0);
  const menuId = ReadCookie("menuId");
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [isDefaultActive, setIsDefaultActive] = useState(false);
  const [isDefaultBill, setIsDefaultBill] = useState(false);
  const [isDefaultShip, setIsDefaultShip] = useState(false);
  const [customerAddressId, setCustomerAddressId] = useState(null);
  const [itemIndex, setItemIndex] = useState(0);
  const [Index, setIndex] = useState(null);



  const onChange = (checked) => {
    
  };

  const billingColumns = [
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "first_name",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-170"
              
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.first_name &&
        record.first_name
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.first_name - b.first_name;
      },
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      key: "last_name",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-170"
              
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.last_name &&
        record.last_name.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.last_name - b.last_name;
      },
    },
    {
      title: "Addressee",
      dataIndex: "address_addressee",
      key: "address_addressee",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-170"
              
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.addressee
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.addressee - b.addressee;
      },
    },
    {
      title: "Address 1",
      dataIndex: "address_address_1",
      key: "address_address_1",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-170"
              
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.address_1
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.address_1 - b.address_1;
      },
    },
    {
      title: "Address 2",
      dataIndex: "address_address_2",
      key: "address_address_2",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-170"
              
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.address_2
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.address_2 - b.address_2;
      },
    },
    {
      title: "City",
      dataIndex: "address_city_name",
      key: "address_city_name",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-170"
              
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.city_name
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.city_name - b.city_name;
      },
    },
    {
      title: "State",
      dataIndex: "address_state_name",
      key: "address_state_name",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-170"
              
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.state_id
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.state_id - b.state_id;
      },
    },
    {
      title: "Zip",
      dataIndex: "address_zip_code",
      key: "address_zip_code",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-170"
              
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.zip_code
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.zip_code - b.zip_code;
      },
    },
    {
      title: "Country",
      dataIndex: "address_country_name",
      key: "address_country_name",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className="pop-search-gen pop-170"
              
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: (filtered) =>
        filtered ? <FilterFilled /> : <SearchOutlined />,
      onFilter: (value, record) =>
        record.bill_to_country_name
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()),
      sorter: (a, b) => {
        return a.country_name - b.country_name;
      },
    },
    {
      title: "Active",
      key: "action",
      render: (text, record) => {
        return (
          <>
            {record.is_active === false ? "False" : "True"}
          </>
        )
      }
    },
    {
      title: "Default Billing",
      
      key: "action",
      render: (text, record) => {


        return (
          <>
            {record.default_bill_to === false ? "False" : "True"}
          </>
        )
      }
    },
    {
      title: "Default Shipping",
      key: "action",
      render: (text, record) => (
        <>
          {record.default_ship_to === false ? "False" : "True"}
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record, index) => {
        
        return (
          <>
            {
              formType !== "formView" && (
                <>
                  <Tooltip title="Edit" color={"white"}>
                    <EditOutlined
                      className={`edit-Button`}
                      onClick={() => hanldeSubItems(record, index)}
                    />
                  </Tooltip>
                  <Tooltip title="Delete" color={"white"}>
                    <Popconfirm
                      title={`Are you sure to delete?`}
                      onConfirm={() => hanldeDeleteItems(record, billingAddress.findIndex((v) => v.customer_address_id === record.customer_address_id))}
                    >
                      <DeleteOutlined className={`delete-Button`} />
                    </Popconfirm>
                  </Tooltip>
                </>
              )
            }
            {/* <Space>
                {userRights?.payload?.can_edit !== false && formType !== "add" && (
                  <Button
                    size="small"
                    icon={<EditOutlined />}
                    onClick={() => hanldeSubItems(record, index)}
                    disabled={!(formType === "add" || formType === "edit")}
                  />
                )}
                {userRights?.payload?.can_delete !== false && (
                  <Button
                    size="small"
                    icon={<DeleteOutlined />}
                    onClick={() => hanldeDeleteItems(record, index)}
                    disabled={!(formType === "add" || formType === "edit")}
                  />
                )}
              </Space> */}
          </>
        )
      },
    }
  ];

  useLayoutEffect(() => {
    dispatch(getRights(token, menuId)).then((res) => {
      if (res.type === "GET_USER_RIGHTS_SUCCESS") {
        if (!res?.payload.can_add) {
        }
      }
    });
  }, []);

  useEffect(() => {
    if (formType === "add") {
      return setBillingAddress([]);
    }
    if (customer_id !== "" && formType === "formView") {
      setTimeout(() => {
        dispatch(getOneCustomerAddress(token, customer_id)).then((res) => {
          setBillingAddress(res?.payload?.data);
          setBillID(res?.payload?.data[0].customer_address_id);
        });
      }, 2000);
      setShowTable(true);
    } else {
      setShowTable(false);
    }
  }, [customer_id]);

  const hanldeSubItems = (record, index) => {
    
    dispatch(loadCountry(token));
    setEventType("edit");
    if (formType === "edit") {
      setEditForm("edit");
      setBillID(record?.customer_address_id);
      setCustomerAddressId(record?.customer_address_id);
    } else if (formType === "add") {
      setIndex(record?.index);
    }
    setVisible(true);
    

    form.setFieldsValue({
      addressee: record.address_addressee,
      address_1: record.address_address_1,
      address_2: record.address_address_2,
      address_3: record.address_3,
      email: record.address_email,
      fax: record.address_fax,
      phone: record.address_phone,
      zip_code: record.address_zip_code,
      city: parseInt(record.address_city_id),
      state: parseInt(record.address_state_id),
      country: parseInt(record.address_country_id),
      isActive: record.is_active,
      fname: record.first_name,
      lname: record.last_name,
      defaultBillTo: record.default_bill_to,
      defaultShipTo: record.default_ship_to,
    });
    setIsDefaultActive(record.is_active);
    setIsDefaultBill(record.default_bill_to);
    setIsDefaultShip(record.default_ship_to);

    dispatch(loadCity(token, record.address_city_id));
    dispatch(loadState(token, record.address_state_id));
  };

  const hanldeDeleteItems = (record, index) => {
    const dataSource = [...billingAddress];
    const deleteDataSource = [...deletedBillingLine];
    deleteDataSource.push(record);
    setDeletedBillingLine(deleteDataSource)
    setBillingAddress(dataSource.filter((item, i) => i !== index));

  };

  const onChangeCountry = (value, obj) => {
    setCountry(obj);
    dispatch(loadCity(token, `${value}`));
    dispatch(loadState(token, `${value}`));
  };

  const onChangeCity = (value, obj) => {
    setCity(obj);
  };

  const onChangeState = (value, obj) => {
    setState(obj);
  };

  const onSearch = (val) => { };



  const handleOk = async (data) => {

    if (eventType === "add") {

      
      await form.validateFields().then((values) => {
        if (isDefaultBill) {
          billingAddress.map((v) => v.default_bill_to = false);
        }
        if (isDefaultShip) {
          billingAddress.map((v) => v.default_ship_to = false);
        }
        values.defaultBillTo = isDefaultBill;
        values.defaultShipTo = isDefaultShip;
        values.isActive = isDefaultActive;

        setItemIndex((prev) => prev + 1);
        const dummy = {
          address_addressee: values.addressee,
          customer_full_address: `${values.address_1 !== null ? values.address_1 : null} ${values.address_2 !== null ? values.address_2 : null}`,
          address_address_1: values.address_1,
          address_address_2: values.address_2,
          address_address_3: null,
          first_name: values.fname,
          last_name: values.lname,
          address_country_id: values.country,
          address_country_name: countryForm.children,
          address_state_id: values.state,
          address_state_name: stateForm.children,
          address_city_id: values.city,
          address_city_name: cityForm.children,
          address_email: values.email,
          address_phone: values.phone,
          
          address_fax: values.fax,
          address_zip_code: values.zip_code,
          is_active: values.isActive,
          default_bill_to: values.defaultBillTo,
          default_ship_to: values.defaultShipTo,
          
          customer_id: customer_id,
          index: itemIndex
        };

        setBillingAddress([...billingAddress, dummy]);



        if (formType === "edit" && eventType === "edit" && editForm === "edit" && billID !== null) {

          const update = {
            customer_addressee: values.addressee,
            address_1: values.address_1,
            address_2: values.address_2,
            address_3: values.address_3,
            country_id: values.country,
            state_id: values.state,
            city_id: values.city,
            customer_email: values.email,
            phone: values.phone,
            fax: values.fax,
            zip_code: values.zip_code,
            is_deleted: 0,
            customer_first_name: values.fname,
            customer_last_name: values.lname,
            is_default: isDefaultBill,
            default_ship_to: isDefaultShip,
            is_active: isDefaultActive,
          };

        }
        setVisible(false);
      });
    } else if (eventType === "edit") {

      await form.validateFields().then((values) => {
        
        let findObj;
        if (formType === "add") {
          findObj = billingAddress.find((v, i) => v.index === Index);
        } else if (formType === "edit") {
          findObj = billingAddress.find((v) => v.customer_address_id === customerAddressId);
        }
        
        



        
        

        let oldTrue = billingAddress.filter((v) => {
          if (isDefaultBill === true) {
            return v.default_bill_to;
          }
          if (isDefaultShip === true) {
            return v.default_ship_to;
          }
        })
        oldTruee.push(oldTrue)
        

        billingAddress.map((v) => {
          if (isDefaultBill) {
            v.default_bill_to = false
          }
          if (isDefaultShip) {
            v.default_ship_to = false
          }
        }
        )        
        let findCountry = country?.payload?.data.find((v) => v.country_id === values.country)
        let findCity = City?.payload?.data.find((v) => v.city_id === values.city)
        let findState = State?.payload?.data.find((v) => v.state_id === values.state)



        
        if (findObj) {
          findObj.first_name = values.fname;
          findObj.last_name = values.lname;
          findObj.address_addressee = values.addressee;
          findObj.address_address_1 = values.address_1;
          findObj.address_address_2 = values.address_2;
          findObj.address_country_name = findCountry?.country_name;
          findObj.address_state_name = findState?.state_name;
          findObj.address_city_name = findCity?.city_name;
          findObj.address_email = values.email;
          findObj.address_phone = values.phone;
          findObj.address_fax = values.fax;
          findObj.address_zip_code = values.zip_code;
          
          findObj.is_active = values.isActive;
          findObj.default_bill_to = values.defaultBillTo;
          findObj.default_ship_to = values.defaultShipTo;
        }

        
        setVisible(false);
      });

    }
  };

  const handleCancel = () => {
    setVisible(false);
    form.setFieldsValue({
      addressee: null,
      address_1: null,
      address_2: null,
      address_3: null,
      email: null,
      fax: null,
      phone: null,
      zip_code: null,
      city: null,
      state: null,
      country: null,
      isActive: false,
      fname: null,
      lname: null,
      defaultBillTo: false,
      defaultShipTo: false,
    });
    setIsDefaultActive(false);
    setIsDefaultBill(false);
    setIsDefaultShip(false);
  };

  const getModalData = () => {
    dispatch(loadCountry(token));
    form.setFieldsValue({
      addressee: null,
      address_1: null,
      address_2: null,
      address_3: null,
      email: null,
      fax: null,
      phone: null,
      zip_code: null,
      city: null,
      state: null,
      country: null,
      isActive: false,
      fname: null,
      lname: null,
      defaultBillTo: false,
      defaultShipTo: false,
    });
    setEventType("add");
    setVisible(true);
  };
  return (
    <>
      <div>
        <Modal
          className="modal-40"
          visible={visible}
          title="Address Form"
          okText="Submit"
          onOk={(data) => handleOk(data)}
          onCancel={handleCancel}
        >
          <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
            initialValues={{ modifier: "public" }}
          >
            <div className="container-fluid p-0 modal-form-main">
              <Brow>
                <Bcol lg={6} xl={6} xxl={6}>
                  <Form.Item name="fname" label=" First Name ">
                    <Input
                      size="small"
                      className="b-radius-10"
                    
                    />
                  </Form.Item>
                </Bcol>
                <Bcol lg={6} xl={6} xxl={6}>
                  <Form.Item
                    name="lname"
                    label="Last Name "
                  >
                    <Input
                      size="small"
                      className="b-radius-10"
                    
                    />
                  </Form.Item>
                </Bcol>
                <Bcol lg={6} xl={6} xxl={6}>
                  <Form.Item
                    name="addressee"
                    label="Addressee"
                    rules={[
                      { message: "Addressee is required", required: true },
                    ]}
                  >
                    <Input
                      size="small"
                      className="b-radius-10"
                    
                    />
                  </Form.Item>
                </Bcol>
                <Bcol lg={6} xl={6} xxl={6}>
                  <Form.Item name="address_1" label="Address 1">
                    <Input
                      size="small"
                      className="b-radius-10"
                    
                    />
                  </Form.Item>
                </Bcol>
                <Bcol lg={6} xl={6} xxl={6}>
                  <Form.Item name="address_2" label="Address 2">
                    <Input
                      size="small"
                      className="b-radius-10"
                    
                    />
                  </Form.Item>
                </Bcol>
                <Bcol lg={6} xl={6} xxl={6}>
                  <Form.Item name="country" label="Country">
                    <Select
                      size="small"
                      showSearch
                      
                      optionFilterProp="children"
                      value={
                        formType === "edit"
                          ? form.getFieldValue().country
                          : null
                      }
                      onChange={onChangeCountry}
                      onSearch={onSearch}
                      loading={country.loading}
                    
                    >
                      {country?.payload?.data &&
                        country?.payload?.data.map((country) => {
                          
                          return (
                            <Option
                              key={country.country_id}
                              value={country.country_id}
                            >
                              {country.country_name}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Bcol>
                <Bcol lg={6} xl={6} xxl={6}>
                  <Form.Item name="city" label="City">
                    <Select
                      size="small"
                      showSearch
                      
                      optionFilterProp="children"
                      value={cityForm}
                      onChange={onChangeCity}
                      onSearch={onSearch}
                      loading={City.loading}
                      defaultValue={
                        formType === "edit" ? form.getFieldValue().city : null
                      }
                    >
                      {City?.payload?.data &&
                        City?.payload?.data.map((city) => (
                          <Option key={city.city_id} value={city.city_id}>
                            {city.city_name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Bcol>
                <Bcol lg={6} xl={6} xxl={6}>
                  <Form.Item name="state" label="State">
                    <Select
                      size="small"
                      showSearch
                      
                      optionFilterProp="children"
                      value={stateForm}
                      onChange={onChangeState}
                      onSearch={onSearch}
                      loading={State.loading}
                    >
                      {State?.payload?.data &&
                        State?.payload?.data.map((state) => (
                          <Option key={state.state_id} value={state.state_id}>
                            {state.state_name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Bcol>
                <Bcol lg={6} xl={6} xxl={6}>
                  <Form.Item
                    name="email"
                    label="Email"
                    rules={[
                      {
                        type: "email",
                        message: "The input is not valid E-mail!",
                      },
                    ]}
                  >
                    <Input
                      size="small"
                      className="b-radius-10"
                    
                    />
                  </Form.Item>
                </Bcol>
                <Bcol lg={6} xl={6} xxl={6}>
                  <Form.Item name="fax" label="Fax">
                    <Input
                      size="small"
                      className="b-radius-10"
                    
                    />
                  </Form.Item>
                </Bcol>
                <Bcol lg={6} xl={6} xxl={6}>
                  <Form.Item
                    name="phone"
                    label="Phone"
                  >
                    <Input
                      size="small"
                      className="b-radius-10"
                    
                    />
                  </Form.Item>
                </Bcol>
                <Bcol lg={6} xl={6} xxl={6}>
                  <Form.Item
                    name="zip_code"
                    label="Zip"
                  >
                    <Input
                      size="small"
                      className="b-radius-10"
                    
                    />
                  </Form.Item>
                </Bcol>
                <Bcol lg={3} xl={3} xxl={3}>
                  <Form.Item label="Active" name="isActive">
                    <Switch
                      size="small"
                      checked={isDefaultActive}
                      onChange={(e) => setIsDefaultActive(e)}
                    />
                  </Form.Item>
                </Bcol>
                <Bcol lg={3} xl={3} xxl={3}>
                  <Form.Item label="Default Billing" name="defaultBillTo">
                    <Switch
                      size="small"
                      checked={isDefaultBill}
                      onChange={(e) => setIsDefaultBill(e)}
                    />
                  </Form.Item>
                </Bcol>
                <Bcol lg={3} xl={3} xxl={3}>
                  <Form.Item label="Default shipping" name="defaultShipTo">
                    <Switch
                      checked={isDefaultShip}
                      onChange={(e) => setIsDefaultShip(e)}
                      size="small"
                    />
                  </Form.Item>
                </Bcol>
              </Brow>
            </div>
          </Form>
        </Modal>
      </div>
      <Button
        disabled={!(formType === "add" || formType === "edit")}
        className="iconBtn crudBTN float-right mbpx-5"
        shape="circle"
        icon={<PlusOutlined />}
        onClick={() => getModalData()}
      />

      <Tables
        type="billing"
        billingObj={setBillingObj}
        scroll={520}
        perPage={5}
        scrollX={"90rem"}
        source={JSON.parse(JSON.stringify(billingAddress.sort((a, b) => {
          if (a.default_bill_to === b.default_bill_to) {
            
            return a.default_ship_to === b.default_ship_to ? 0 : a.default_ship_to ? -1 : 1;
          } else {
            return a.default_bill_to ? -1 : 1;
          }
        })))}
        col={billingColumns}
        load={Billing.loading}
        paginate
      />
    </>
  );
}
