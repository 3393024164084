import {
    GET_NEW_SEASON_DATA_ERROR,
    GET_NEW_SEASON_DATA_REQUEST,
    GET_NEW_SEASON_DATA_SUCCESS,
  } from "./types";
  import axios from "axios";
  import { BASEURL } from "../../constant/config.js";
  
  ///Post Bussiness dashboard
  export const getNewSeason = (token, menu_id) => (dispatch) => {
    dispatch({ type: GET_NEW_SEASON_DATA_REQUEST });
 
    return axios({
      method: "POST",
      url: `${BASEURL}getAll/getNewSeason`,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
      data: { menu_id },
    })
      .then((response) => {
        return dispatch({
          type: GET_NEW_SEASON_DATA_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_NEW_SEASON_DATA_ERROR,
          payload: [],
        });
     
      });
  };
  