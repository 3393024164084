import {
  GET_CREDIT_PRINT_REQUEST,
  GET_CREDIT_PRINT_SUCCESS,
  GET_CREDIT_PRINT_ERROR,

} from "./types";
import axios from "axios";
import { BASEURL } from "../../constant/config.js";

export const getCreditPrintReport = (token, values) => (dispatch) => {
  dispatch({ type: GET_CREDIT_PRINT_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}getAll/CreditMemoPrint`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: values
  })
    .then((response) => {
      return dispatch({
        type: GET_CREDIT_PRINT_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_CREDIT_PRINT_ERROR,
        payload: [],
      });
    });
};
