import { GET_ONE_DEFAULT_COMPANY_REQUEST, GET_ONE_DEFAULT_COMPANY_SUCCESS, GET_ONE_DEFAULT_COMPANY_ERROR } from "../actions/types"

export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case GET_ONE_DEFAULT_COMPANY_REQUEST:
            return {
                loading: true,
            };
        case GET_ONE_DEFAULT_COMPANY_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_ONE_DEFAULT_COMPANY_ERROR:
            return {
                ...state,
                loading: false,
                payload
            };
        default:
            return state;
    }
}