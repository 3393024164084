import {
    GET_REPORT_CURRENCY_REQUEST,
    GET_REPORT_CURRENCY_SUCCESS,
    GET_REPORT_CURRENCY_ERROR,

} from "../types"
import axios from "axios";
import { BASEURL } from "../../../constant/config.js"



export const loadReportCurrency = (token) => dispatch => {
    dispatch({ type: GET_REPORT_CURRENCY_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/adminCurrency`,
        headers: {
            Accept: "application/json",
            'Authorization': 'Bearer ' + token
        }
    })
        .then(response => {

            return dispatch({ type: GET_REPORT_CURRENCY_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            if (error.response.data.data === "No Record Found !") {
                return dispatch({
                    type: GET_REPORT_CURRENCY_ERROR,
                    payload: [],
                });
            }
            return dispatch({
                type: GET_REPORT_CURRENCY_ERROR,
                payload: error.response,
            });
        })
};

