import React, { useEffect, useState } from "react";
import {
  Table,
  Input,
  Select,
  InputNumber,
  Form,
  Button,
  Modal,
  Empty,
  Checkbox,
} from "antd";
import { useSelector, useDispatch } from "react-redux";
import { getProductSale } from "../../redux/actions/productSale.action";
import { getCommitCriteria } from "../../redux/actions/commitCriteria.action";
import numeral from "numeral";
const { Option } = Select;

const EditableTable = ({
  token,
  companyId,
  modalValue,
  setIsModalVisible,
  setOrderItems,
  setShowGrid,
  orderItems,
  locationID,
  filteredItems,
  setFilteredItems,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { ProductSale, commitCriteria } = useSelector((state) => state);
  const [data, setData] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [arr, setArr] = useState([]);
  const [rate, setRate] = useState("");
  const [costPrice, setCostPrice] = useState(null);

  useEffect(() => {
    dispatch(getCommitCriteria(token));
  }, []);

  
  const updateCostPrice = (type, id, value) => {
    setCostPrice(value);
    let arr = [...data];
    const filter = arr.map((item) => {
      if (item.item_code === id) {
        if (type === "up") {
          item.rate = value;
          item.amount = parseFloat(item.quantity * item.rate).toFixed(2);
          return item;
        }
        if (type === "down") {
          item.rate = value;
          item.amount = parseFloat(item.quantity * item.rate).toFixed(2);
          return item;
        }
      } else {
        return item;
      }
    });
    let selectItem = filter.filter((f) => f.isSelected);
    setFilteredItems(selectItem);
    setData(filter);
  };

  
  const updateQuantity = (type, id, value) => {
    let arr = [...data];
    const filter = arr.map((item) => {
      if (item.item_code === id) {
        if (type === "up") {
          item.quantity = value;
          item.amount = numeral(item.quantity * item.rate).format("0.00");
          if (item.commit_status == 3) {
            item.quantity_backorder = item.quantity;
          } else {
            item.quantity_backorder =
              parseInt(item.quantity_available) < 0
                ? parseInt(item.quantity)
                : (parseInt(item.quantity) - parseInt(item.quantity_available) >
                  0
                ? parseInt(item.quantity) - parseInt(item.quantity_available)
                : 0);
          }

          if (item.commit_status == 3) {
            item.quantity_committed = 0;
          } else {
            item.quantity_committed =
              parseInt(item.quantity) -
              (parseInt(item.quantity) - parseInt(item.quantity_available) > 0
                ? parseInt(item.quantity) - parseInt(item.quantity_available)
                : 0);
          }

          return item;
        }
        if (type === "down") {
          item.quantity = value;
          item.amount = numeral(item.quantity * item.rate).format("0,0.00");

          if (item.commit_status == 3) {
            item.quantity_backorder = item.quantity;
          } else {
            item.quantity_backorder =
              parseInt(item.quantity) - parseInt(item.quantity_available) > 0
                ? parseInt(item.quantity) - parseInt(item.quantity_available)
                : 0;
          }
          if (item.commit_status == 3) {
            item.quantity_committed = 0;
          } else {
            item.quantity_committed =
              parseInt(item.quantity) -
              (parseInt(item.quantity) - parseInt(item.quantity_available) > 0
                ? parseInt(item.quantity) - parseInt(item.quantity_available)
                : 0);
          }

          return item;
        }
      } else {
        return item;
      }
    });
    let selectItem = filter.filter((f) => f.isSelected);
    setFilteredItems(selectItem);
    setData(filter);
  };

  
  useEffect(() => {
    let arr = [...data];
    let newArr = [...selectedItems];
    let onlyKey = [...newArr].map((sItem) => sItem.item_code);

    
    arr = [...arr].map((item) => {
      if (onlyKey.indexOf(item.item_code) != -1) {
        return {
          ...newArr[onlyKey.indexOf(item.item_code)],
        };
      }
      return item;
    });
    setData(arr);
  }, [selectedItems]);

  useEffect(() => {
    if (!modalValue) {
      setData([]);
      setSelectedId(null);
      setSelectedItems([]);
    }
  }, [modalValue]);

  
  const handleGenerate = async () => {
    dispatch(getProductSale(token, companyId, selectedId, locationID)).then(
      (res) => {
        if (res.type === "GET_PRODUCTSALE_SUCCESS") {
          const arr = res?.payload?.map((items) => {
            let found = orderItems.find((f) => f.item_id === items.item_id);
            if (found) {
              found.quantity_available = items.quantity_available;
              found.size = items.size;
              found.gender_category_name = items.gender_category_name;
              found.style_name = items.style_name;
              found.wash_name = items.wash_name;
              found.already = true;
              return found;
            } else {
              items.commit_status = 1;
              return items;
            }
          });
          setData(arr);
        }
      }
    );
  };

  
  const handleRate = (rate) => {
    let arr = [...data];
    arr.map((item) => {
      item.rate = rate;
    });
    setData(arr);
    setFilteredItems(filteredItems);
    setRate(rate);
  };

  
  const handleCommitStatus = (id, value) => {
    let arr = [...data];
    const filter = arr.map((item) => {
      if (item.item_code === id) {
        item.commit_status = value;
        if (value == 3) {
          item.quantity_backorder = item.quantity;
          item.quantity_committed = 0;
        }
        return item;
      } else {
        return item;
      }
    });
    let selectItem = filter.filter((f) => f.isSelected);
    setFilteredItems(selectItem);
    setData(filter);
  };

  
  const handleOk = () => {
    
    addOrderItems();
  };

  
  const handleCancel = () => {
    setIsModalVisible(false);
    setRate("");
  };

  
  const addOrderItems = () => {
    setOrderItems(filteredItems);
    setData([]);
    setSelectedId(null);
    setShowGrid(true);
  };

  
  const rowSelection = (event, record, index) => {
    let copyArr = [...data];
    arr.push(record);
    if (event.target.checked) {
      copyArr[index].isSelected = true;
      copyArr[index].quantity = copyArr[index].hasOwnProperty("quantity")
        ? copyArr[index].quantity
        : 1;
      copyArr[index].tax_amount = copyArr[index].hasOwnProperty("tax_amount")
        ? copyArr[index].tax_amount
        : 0;
      copyArr[index].discount = copyArr[index].hasOwnProperty("discount")
        ? copyArr[index].discount
        : 0;
      copyArr[index].discount_percent = copyArr[index].hasOwnProperty(
        "discount_percent"
      )
        ? copyArr[index].discount_percent
        : 0;
      copyArr[index].tax_percent = copyArr[index].hasOwnProperty("taxt_percent")
        ? copyArr[index].tax_percent
        : 0;
      copyArr[index].amount = numeral(
        copyArr[index].quantity * copyArr[index].rate
      ).format("0.00");
      copyArr[index].net_amount = numeral(
        copyArr[index].quantity * copyArr[index].rate
      ).format("0.00");

      copyArr[index].quantity = 1;

      let avail = copyArr[index].quantity_available;

      if (avail > copyArr[index].quantity) {
        copyArr[index].quantity_backorder = 0;
        copyArr[index].quantity_committed = copyArr[index].quantity;
      } else if (Number(avail) < 0) {
        copyArr[index].quantity_backorder = copyArr[index].quantity;
        copyArr[index].quantity_committed = 0;
      } else if (avail <= copyArr[index].quantity) {
        copyArr[index].quantity_backorder = copyArr[index].quantity - avail;
        copyArr[index].quantity_committed = avail;
      }
    } else {
      
      const deletefilterItem = filteredItems.filter(
        (v) => v.item_id !== record.item_id
      );
      
      setFilteredItems(deletefilterItem);
      copyArr[index].quantity = 0;
      copyArr[index].quantity_committed = 0;
      copyArr[index].isSelected = false;
    }
    const selectedItem = copyArr.filter((v) => v.isSelected === true);
    setData(copyArr);
    setFilteredItems(selectedItem);
  };

  
  const columns = [
    {
      title: "Selected",
      render: (text, record, index) => {
        return (
          <Checkbox
            defaultChecked={record.already}
            disabled={record.already}
            onChange={(event) => rowSelection(event, record, index)}
          ></Checkbox>
        );
      },
    },
    {
      title: "Item Code",
      dataIndex: "item_code",
    },
    {
      title: "Style Name",
      dataIndex: "style_name",
    },
    {
      title: "Wash Name",
      dataIndex: "wash_name",
    },
    {
      title: "Gender Category",
      dataIndex: "gender_category_name",
    },
    {
      title: "Inseam",
      dataIndex: "inseam",
    },
    {
      title: "Size",
      dataIndex: "size",
    },
    {
      title: "Available",
      editable: false,
      render: (text, record) => {
        return <>{record.quantity_available}</>;
      },
    },
    {
      title: "Cost Price",
      dataIndex: "rate",
      width: 75,
      render: (text, record, index) => {
        return (
          <InputNumber
            readOnly
            size="small"
            disabled={!record?.isSelected || record.already}
            value={rate ? record.rate : costPrice}
            defaultValue={rate ? rate : record.rate}
            onChange={(value) =>
              updateCostPrice("up", record.item_code, value, index)
            }
          />
        );
      },
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      editable: true,
      width: 75,
      render: (text, record, index) => {
        return (
          <InputNumber
            size="small"
            disabled={!record?.isSelected}
            onChange={(value) =>
              updateQuantity("up", record.item_code, value, index)
            }
            value={data.length > 0 && data[index]?.quantity}
          />
        );
      },
    },
    {
      title: "Amount",
      editable: false,
      render: (text, record) => {
        return (
          <>
            {parseFloat(
              record.quantity ? record.quantity * record.rate : 0
            ).toFixed(2)}
          </>
        );
      },
    },
    {
      title: "Commit Status",
      width: "10%",
      dataIndex: "commit_status",
      editable: true,
      width: 80,
      render: (text, record) => {
        return (
          <Select
            disabled={!record?.isSelected || record.already}
            size="small"
            style={{ width: 150 }}
            autoComplete={"nope"}
            defaultValue={Number(record.commit_status)}
            onChange={(value) => handleCommitStatus(record.item_code, value)}
          >
            {commitCriteria?.payload?.data?.map((item) => {
              return (
                <Option
                  key={item.commit_criteria_id}
                  value={item.commit_criteria_id}
                >
                  {item.commit_criteria_name}
                </Option>
              );
            })}
          </Select>
        );
      },
    },
    {
      title: "Commited",
      editable: false,
      render: (text, record) => {
        let commited;
        if (record.commit_status == 3) {
          commited = 0;
        } else {
          commited =
            record.quantity_available < 0
              ? 0
              : record.quantity < record.quantity_available
              ? record.quantity
              : record.quantity_available;
        }
        return (
          <>
            {
              record.quantity_committed < 0
                ? 0
                : numeral(record.quantity_committed).format("0")
              
            }
          </>
        );
      },
    },
    {
      title: "Back Order",
      editable: false,
      render: (text, record) => {
        let backOrder;
        if (record.commit_status == 3) {
          backOrder = record.quantity;
        } else {
          
          backOrder =
            Number(record.quantity_available) < 0
              ? record.quantity
              : record.quantity - record.quantity_available;
        }
        return <>{backOrder > 0 ? backOrder : 0}</>;
      },
    },
  ];

  return (
    <Modal
      okButtonProps={{
        style: {
          display: arr.length > 0 ? "" : "none",
        },
      }}
      okText="Add Order"
      destroyOnClose={true}
      width={"100%"}
      centered={true}
      title="Products Selection"
      visible={modalValue}
      onOk={handleOk}
      cancelText="Close"
      onCancel={handleCancel}
    >
      <Form form={form} component={false}>
        <div style={{ padding: "10px 0", display: "flex", gap: "2px" }}>
          <Input
            size="small"
            onPressEnter={(e) => {
              if (e.target.value.length) {
                handleGenerate();
              }
            }}
            className="w-100"
            value={selectedId}
            name="searchItem"
            onChange={(e) => {
              setSelectedId(e.target.value);
            }}
          />

          <Input
            size="small"
            className="w-100"
            value={rate}
            name="searchItem"
            onChange={(e) => {
              handleRate(e.target.value);
            }}
          />
          <Button
            className="customInputs"
            type="primary"
            disabled={!selectedId}
            onClick={() => handleGenerate()}
          >
            Views
          </Button>
        </div>
        <Table
          size="small"
          loading={ProductSale.loading}
          bordered
          dataSource={data.map((it) => ({ ...it, key: it.item_code }))}
          columns={columns}
          rowClassName="editable-row"
          pagination={false}
        />
      </Form>
    </Modal>
  );
};
export default EditableTable;
