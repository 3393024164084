import {
  GET_SHIP_VIA_REQUEST,
  GET_SHIP_VIA_SUCCESS,
  GET_SHIP_VIA_ERROR,
  ADD_SHIP_VIA_ERROR,
  ADD_SHIP_VIA_REQUEST,
  ADD_SHIP_VIA_SUCCESS,
  UPDATE_SHIP_VIA_REQUEST,
  UPDATE_SHIP_VIA_SUCCESS,
  UPDATE_SHIP_VIA_ERROR,
  DELETE_SHIP_VIA_REQUEST,
  DELETE_SHIP_VIA_SUCCESS,
  DELETE_SHIP_VIA_ERROR
} from "../actions/types";

export default function (state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case GET_SHIP_VIA_REQUEST:
      return {
        loading: true,
      };
    case GET_SHIP_VIA_SUCCESS:
      return {
        ...state,
        loading: false,
        payload,
      };
    case GET_SHIP_VIA_ERROR:
      return {
        ...state,
        loading: false,
        payload,
      };
    case ADD_SHIP_VIA_REQUEST:
      return {
        loading: true,
      };
    case ADD_SHIP_VIA_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: [payload],
      };
    case ADD_SHIP_VIA_ERROR:
      return {
        ...state,
        loading: false
      };
    case UPDATE_SHIP_VIA_REQUEST:
      return {
        loading: true,
      };
    case UPDATE_SHIP_VIA_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: [payload],
      };
    case UPDATE_SHIP_VIA_ERROR:
      return {
        ...state,
        loading: false
      };
    case DELETE_SHIP_VIA_REQUEST:
      return {
        loading: true,
      };
    case DELETE_SHIP_VIA_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: [payload],
      };
    case DELETE_SHIP_VIA_ERROR:
      return {
        ...state,
        loading: false
      };



    default:
      return state;
  }
}
