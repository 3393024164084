import {
    GET_RISELABEL_ERROR, GET_RISELABEL_REQUEST, GET_RISELABEL_SUCCESS,
    ADD_RISELABEL_ERROR, ADD_RISELABEL_REQUEST, ADD_RISELABEL_SUCCESS,
    DELETE_RISELABEL_REQUEST, DELETE_RISELABEL_SUCCESS, DELETE_RISELABEL_ERROR,
    UPDATE_RISELABEL_ERROR, UPDATE_RISELABEL_REQUEST, UPDATE_RISELABEL_SUCCESS

} from "../actions/types"
export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case GET_RISELABEL_REQUEST:
            return {
                loading: true,
            };
        case GET_RISELABEL_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_RISELABEL_ERROR:
            return {
                ...state,
                loading: false
            };
        case ADD_RISELABEL_REQUEST:
            return {
                loading: true,
            };
        case ADD_RISELABEL_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case ADD_RISELABEL_ERROR:
            return {
                ...state,
                loading: false
            };
        case DELETE_RISELABEL_REQUEST:
            return {
                loading: true,
            };
        case DELETE_RISELABEL_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case DELETE_RISELABEL_ERROR:
            return {
                ...state,
                loading: false
            };
        case UPDATE_RISELABEL_REQUEST:
            return {
                loading: true,
            };
        case UPDATE_RISELABEL_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case UPDATE_RISELABEL_ERROR:
            return {
                ...state,
                loading: false
            };

        default:
            return state;
    }
}