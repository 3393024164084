import {
	GET_ONE_ITEM_TRANSFER_HEADER_REQUEST,
	GET_ONE_ITEM_TRANSFER_HEADER_SUCCESS,
	GET_ONE_ITEM_TRANSFER_HEADER_ERROR,
	GET_ONE_ITEM_TRANSFER_HEADER_DETAIL_REQUEST,
	GET_ONE_ITEM_TRANSFER_HEADER_DETAIL_SUCCESS,
	GET_ONE_ITEM_TRANSFER_HEADER_DETAIL_ERROR,
	DELETE_ITEM_TRANSFER_HEADER_REQUEST,
	DELETE_ITEM_TRANSFER_HEADER_SUCCESS,
	DELETE_ITEM_TRANSFER_HEADER_ERROR,
	ADD_ITEM_TRANSFER_HEADER_REQUEST,
	ADD_ITEM_TRANSFER_HEADER_SUCCESS,
	ADD_ITEM_TRANSFER_HEADER_ERROR,
	UPDATE_ITEM_TRANSFER_HEADER_REQUEST,
	UPDATE_ITEM_TRANSFER_HEADER_SUCCESS,
	UPDATE_ITEM_TRANSFER_HEADER_ERROR,
} from "../actions/types";

export default function (state = [], action) {
	const { type, payload } = action;
	switch (type) {
		case ADD_ITEM_TRANSFER_HEADER_REQUEST:
			return {
				loading: true,
			};
		case ADD_ITEM_TRANSFER_HEADER_SUCCESS:
			return {
				...state,
				loading: false,
				payloadSuccess: [payload],
			};
		case ADD_ITEM_TRANSFER_HEADER_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};
		case UPDATE_ITEM_TRANSFER_HEADER_REQUEST:
			return {
				loading: true,
			};
		case UPDATE_ITEM_TRANSFER_HEADER_SUCCESS:
			return {
				...state,
				loading: false,
				payloadUpdate: [payload],
			};
		case UPDATE_ITEM_TRANSFER_HEADER_ERROR:
			return {
				...state,
				loading: false,
				payload: [payload],
			};


		case DELETE_ITEM_TRANSFER_HEADER_REQUEST:
			return {
				loading: true,
			};
		case DELETE_ITEM_TRANSFER_HEADER_SUCCESS:
			return {
				...state,
				loading: false,
				payloadDelete: [payload],
			};
		case DELETE_ITEM_TRANSFER_HEADER_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};
		case GET_ONE_ITEM_TRANSFER_HEADER_REQUEST:
			return {
				loading: true,
			};
		case GET_ONE_ITEM_TRANSFER_HEADER_SUCCESS:
			return {
				...state,
				loading: false,
				payload: payload,
			};
		case GET_ONE_ITEM_TRANSFER_HEADER_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};
		case GET_ONE_ITEM_TRANSFER_HEADER_DETAIL_REQUEST:
			return {
				loading: true,
			};
		case GET_ONE_ITEM_TRANSFER_HEADER_DETAIL_SUCCESS:
			return {
				...state,
				loading: false,
				payload: payload?.data[0],
			};
		case GET_ONE_ITEM_TRANSFER_HEADER_DETAIL_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};
		default:
			return state;
	}
}
