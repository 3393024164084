import { GET_CREDIT_OPEN_INVOICE_DETAIL_REPORT_REQUEST, GET_CREDIT_OPEN_INVOICE_DETAIL_REPORT_SUCCESS, GET_CREDIT_OPEN_INVOICE_DETAIL_REPORT_ERROR } from "./types";
import { BASEURL } from "../../constant/config.js"
import axios from "axios";
import moment from "moment";

export const getCreditOpenInvoice = (token, values) => (dispatch) => {
    let cleanedVal = values.customer === undefined ? null : values.customer.includes("'") ? values.customer.replace(/'/g, "''") : values.customer;
    let cleanedVal1 = values.billing_addressee === undefined ? null : values.billing_addressee.includes("'") ? values.billing_addressee.replace(/'/g, "''") : values.billing_addressee;
    
    dispatch({ type: GET_CREDIT_OPEN_INVOICE_DETAIL_REPORT_REQUEST });
    const newData = {
        
        date_from: moment(values.from_date).format("MM-DD-YYYY"),
        date_to: moment(values.to_date).format("MM-DD-YYYY"),
        customer: cleanedVal === undefined || cleanedVal === "" ? null : cleanedVal,
        bill_addressee: cleanedVal1 === undefined || cleanedVal1 === "" ? null : cleanedVal1,
        sku: values.parent_sku === undefined || values.parent_sku === "" ? null : values.parent_sku,
        location_id: values.location === undefined || values.location === "All" ? null : values.location,
        company_id: null

    };

    return axios({
        method: "POST",
        url: `${BASEURL}getAll/OmsCreditOpenInvoice`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: newData,
    })
        .then((response) => {
            return dispatch({
                type: GET_CREDIT_OPEN_INVOICE_DETAIL_REPORT_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) => {
            dispatch({
                type: GET_CREDIT_OPEN_INVOICE_DETAIL_REPORT_ERROR,
                payload: [],
            });

        });
};
