import {
    GET_FABRICUSABLE_REQUEST,
    GET_FABRICUSABLE_SUCCESS,
    GET_FABRICUSABLE_ERROR,
    ADD_FABRICUSABLE_REQUEST,
    ADD_FABRICUSABLE_ERROR,
    ADD_FABRICUSABLE_SUCCESS,
    UPDATE_FABRICUSABLE_REQUEST,
    UPDATE_FABRICUSABLE_SUCCESS,
    UPDATE_FABRICUSABLE_ERROR,
    DELETE_FABRICUSABLE_ERROR,
    DELETE_FABRICUSABLE_REQUEST,
    DELETE_FABRICUSABLE_SUCCESS,
  } from "../actions/types";
  export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
      case GET_FABRICUSABLE_REQUEST:
        return {
          loading: true,
        };
      case GET_FABRICUSABLE_SUCCESS:
        return {
          ...state,
          loading: false,
          payload,
        };
      case GET_FABRICUSABLE_ERROR:
        return {
          ...state,
          loading: false,
        };
      case ADD_FABRICUSABLE_REQUEST:
        return {
          loading: true,
        };
      case ADD_FABRICUSABLE_SUCCESS:
        return {
          ...state,
          loading: false,
          payload: [payload],
        };
      case ADD_FABRICUSABLE_ERROR:
        return {
          ...state,
          loading: false,
        };
      case DELETE_FABRICUSABLE_REQUEST:
        return {
          loading: true,
        };
      case DELETE_FABRICUSABLE_SUCCESS:
        return {
          ...state,
          loading: false,
          payload: [payload],
        };
      case DELETE_FABRICUSABLE_ERROR:
        return {
          ...state,
          loading: false,
        };
      case UPDATE_FABRICUSABLE_REQUEST:
        return {
          loading: true,
        };
      case UPDATE_FABRICUSABLE_SUCCESS:
        return {
          ...state,
          loading: false,
          payload: [payload],
        };
      case UPDATE_FABRICUSABLE_ERROR:
        return {
          ...state,
          loading: false,
        };
  
      default:
        return state;
    }
  }
  