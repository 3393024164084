import {
    GET_ADMIN_COMPANY_REQUEST, GET_ADMIN_COMPANY_SUCCESS, GET_ADMIN_COMPANY_ERROR, ADD_ADMIN_COMPANY_ERROR, ADD_ADMIN_COMPANY_REQUEST, ADD_ADMIN_COMPANY_SUCCESS,
    UPDATE_ADMIN_COMPANY_ERROR, UPDATE_ADMIN_COMPANY_REQUEST, UPDATE_ADMIN_COMPANY_SUCCESS, DELETE_ADMIN_COMPANY_ERROR, DELETE_ADMIN_COMPANY_REQUEST, DELETE_ADMIN_COMPANY_SUCCESS
} from "./types";

import { BASEURL } from "../../constant/config.js";
import axios from "axios";


export const getAdminCompany = (token, id) => dispatch => {
    dispatch({ type: GET_ADMIN_COMPANY_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/adminUserCompany`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { id }
    })
        .then((response) => {
            return dispatch({ type: GET_ADMIN_COMPANY_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: GET_ADMIN_COMPANY_ERROR,
                payload: error.response,
            });
        });
};




export const addAdminCompany = (token, data, id) => (dispatch) => {
    dispatch({ type: ADD_ADMIN_COMPANY_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}create/adminUserCompany`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data,
    })
        .then((response) => {

            return dispatch({ type: ADD_ADMIN_COMPANY_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: ADD_ADMIN_COMPANY_ERROR,
                payload: error.response,
            });
        });
};




export const updateAdminCompany = (token, data, id) => (dispatch) => {
    dispatch({ type: UPDATE_ADMIN_COMPANY_REQUEST });
    return axios({
        method: "PUT",
        url: `${BASEURL}update/adminUserCompany/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data,
    })
        .then((response) => {

            return dispatch({ type: UPDATE_ADMIN_COMPANY_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: UPDATE_ADMIN_COMPANY_ERROR,
                payload: error.response,
            });
        });
};


export const deleteAdminCompany = (token, id) => (dispatch) => {
    dispatch({ type: DELETE_ADMIN_COMPANY_REQUEST });
    return axios({
        method: "DELETE",
        url: `${BASEURL}delete/adminUserCompany/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
    })
        .then((response) => {
            return dispatch({ type: DELETE_ADMIN_COMPANY_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: DELETE_ADMIN_COMPANY_ERROR,
                payload: error.response,
            });
        });
};
