import React, { useEffect} from "react";
import "./SalesReport.css";
import { useDispatch, useSelector } from "react-redux";
import { ReadCookie } from "../../utils/readCookie";
import { Spin } from "antd";
import { useLocation } from "react-router-dom";
import numeral from "numeral";
import { getCreditPrintReport } from "../../redux/actions/creditPrintReport.action";
import CreditPrintReportHeader from "./CreditPrintReportHeader/CreditPrintReportHeader";
import CreditPrintReportFooter from "./CreditPrintReportFooter/CreditPrintReportFooter";

const CreditPrintReport = () => {
  const dispatch = useDispatch();
  const menuId = ReadCookie("menuId");
  const token = ReadCookie("token");
  const {creditPrintReport } = useSelector((state) => state);

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();

  let return_header_id = query.get("return_header_id");

  const data = creditPrintReport && creditPrintReport?.payload?.data;

  const values = {
    
    return_header_id: return_header_id,
  };

  useEffect(() => {
    dispatch(getCreditPrintReport(token, values));
  }, []);

  let sumAmount = creditPrintReport?.payload?.data.reduce(function (
    previousValue,
    currentValue
  ) {
    return (previousValue += parseFloat(currentValue.amount));
  },
  0);


  return (
    <div>
      {data ? (
        <div
          className="order_report_view"
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <table>
            <thead>
              <tr>
                <td className="footer">
                  <div>
                    <CreditPrintReportHeader />
                  </div>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className="content-block">
                    <div className="sales-report-Table-data">
                      <table className="sales-report-table">
                        <tbody>
                          <tr>
                            <td
                              className="font_weight"
                              style={{
                                width: "88px",
                                border: "none",
                                height: "30px",
                                backgroundColor: "rgb(184, 184, 184)",
                              }}
                            >
                              Quantity
                            </td>
                            <td
                              className="font_weight"
                              style={{
                                width: "300px",
                                border: "none",
                                backgroundColor: "rgb(184, 184, 184)",
                              }}
                            >
                              Item
                            </td>
                            <td
                              className="font_weight"
                              style={{
                                width: "360px",
                                border: "none",
                                backgroundColor: "rgb(184, 184, 184)",
                              }}
                            >
                              Description
                            </td>
                            <td
                              className="font_weight"
                              style={{
                                width: "140px",
                                border: "none",
                                textAlign: "right",
                                backgroundColor: "rgb(184, 184, 184)",
                              }}
                            >
                              Rate
                            </td>
                            <td
                              className="font_weight"
                              style={{
                                width: "140px",
                                border: "none",
                                textAlign: "right",
                                paddingRight: "5px",
                                backgroundColor: "rgb(184, 184, 184)",
                              }}
                            >
                              Amount
                            </td>
                          </tr>
                          {data?.map((size, index) => {
                            return (
                              <tr>
                                <td style={{border: "none",height: "20px",}}>{numeral(size.quantity).format("0")}</td>
                                <td
                                  style={{
                                    marginLeft: "0",
                                    border: "none",
                                  }}
                                >
                                  {size.sku}
                                </td>
                                <td style={{border: "none",}}>{size.description}</td>
                                {/* <td className="text-center pl-0">$ {size.rate && size.rate}</td> */}
                                <td style={{border: "none",textAlign: "right",}}>${size.rate && size.rate}</td>
                                <td style={{border: "none",textAlign: "right",paddingRight: "5px",}}>${size.amount && size.amount}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                  <hr/>
                    </div>
                  </div>

                  <table className="sales-report-table-total2">
                    <tbody>
                      <tr className="font_weight">
                        <td
                          style={{
                            width: "118px",
                            textAlign: "right",
                            paddingRight: "10px",
                            border: "none",
                            backgroundColor: "rgb(184, 184, 184)" 
                          }}
                          className="font_weight total_cell"
                        >
                          Total
                        </td>
                        <td
                          className="sum_cell_credit"
                          style={{
                            width: "150px",
                            border: "none",
                            textAlign: "right",
                            paddingRight: "5px",
                            backgroundColor: "rgb(184, 184, 184)" 
                          }}
                        >
                          ${sumAmount && numeral(sumAmount).format("00.00")}
                          
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
            <tfoot>
                <tr>
                  <td className="footer2">

                    <div className="sales-report-footer">
                      <CreditPrintReportFooter />
                    </div>
                  </td>
                </tr>
              </tfoot>
          </table>
        </div>
      ) : (
        <div className="spin">
          <Spin size="large" />
        </div>
      )}
    </div>
  );
};

export default CreditPrintReport;
