import {
    GET_REGIONCATG_REQUEST,
    GET_REGIONCATG_SUCCESS,
    GET_REGIONCATG_ERROR,
    ADD_REGIONCATG_ERROR,
    ADD_REGIONCATG_REQUEST,
    ADD_REGIONCATG_SUCCESS,
    DELETE_REGIONCATG_ERROR,
    DELETE_REGIONCATG_REQUEST,
    DELETE_REGIONCATG_SUCCESS,
    UPDATE_REGIONCATG_ERROR,
    UPDATE_REGIONCATG_REQUEST,
    UPDATE_REGIONCATG_SUCCESS

} from "./types"
import axios from "axios";
import { BASEURL } from "../../constant/config.js"


///GET CUSTOMER TYPE
export const loadRegionCategory = (token) => dispatch => {
    dispatch({ type: GET_REGIONCATG_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/regionCategory`,
        headers: {
            Accept: "application/json",
            'Authorization': 'Bearer ' + token
        }
    })
        .then(response => {

            return dispatch({ type: GET_REGIONCATG_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            if (error.response.data.data === "No Record Found !") {
                return dispatch({
                    type: GET_REGIONCATG_ERROR,
                    payload: [],
                });
            }
            return dispatch({
                type: GET_REGIONCATG_ERROR,
                payload: error.response,
            });
        })
};


export const addRegionCategory = (token, data, id) => (dispatch) => {
    dispatch({ type: ADD_REGIONCATG_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}create/regionCategory`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data,
    })
        .then((response) => {

            return dispatch({ type: ADD_REGIONCATG_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: ADD_REGIONCATG_ERROR,
                payload: error.response,
            });
        });
};



export const updateRegionCategory = (token, data, id) => (dispatch) => {
    dispatch({ type: UPDATE_REGIONCATG_REQUEST });
    return axios({
        method: "PUT",
        url: `${BASEURL}update/regionCategory/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data,
    })
        .then((response) => {

            return dispatch({ type: UPDATE_REGIONCATG_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: UPDATE_REGIONCATG_ERROR,
                payload: error.response,
            });
        });
};


export const deleteRegionCategory = (token, id) => (dispatch) => {
    dispatch({ type: DELETE_REGIONCATG_REQUEST });
    return axios({
        method: "DELETE",
        url: `${BASEURL}delete/regionCategory/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
    })
        .then((response) => {
            return dispatch({ type: DELETE_REGIONCATG_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: DELETE_REGIONCATG_ERROR,
                payload: error.response,
            });
        });
};
