import { GET_ALL_CREDIT_REQUEST, GET_ALL_CREDIT_SUCCESS, GET_ALL_CREDIT_ERROR } from "../actions/types";

export default function (state = [], action) {
	const { type, payload } = action;
	switch (type) {
		case GET_ALL_CREDIT_REQUEST:
			return {
				loading: true,
			};
		case GET_ALL_CREDIT_SUCCESS:
			return {
				...state,
				loading: false,
				payload
						};
		case GET_ALL_CREDIT_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};

		default:
			return state;
	}
}
