import {
    GET_PRODUCTCLASS_ERROR, GET_PRODUCTCLASS_REQUEST, GET_PRODUCTCLASS_SUCCESS,
    ADD_PRODUCTCLASS_ERROR, ADD_PRODUCTCLASS_REQUEST, ADD_PRODUCTCLASS_SUCCESS,
    DELETE_PRODUCTCLASS_REQUEST, DELETE_PRODUCTCLASS_SUCCESS, DELETE_PRODUCTCLASS_ERROR,
    UPDATE_PRODUCTCLASS_ERROR, UPDATE_PRODUCTCLASS_REQUEST, UPDATE_PRODUCTCLASS_SUCCESS

} from "../actions/types"
export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case GET_PRODUCTCLASS_REQUEST:
            return {
                loading: true,
            };
        case GET_PRODUCTCLASS_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_PRODUCTCLASS_ERROR:
            return {
                ...state,
                loading: false
            };
        case ADD_PRODUCTCLASS_REQUEST:
            return {
                loading: true,
            };
        case ADD_PRODUCTCLASS_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case ADD_PRODUCTCLASS_ERROR:
            return {
                ...state,
                loading: false
            };
        case DELETE_PRODUCTCLASS_REQUEST:
            return {
                loading: true,
            };
        case DELETE_PRODUCTCLASS_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case DELETE_PRODUCTCLASS_ERROR:
            return {
                ...state,
                loading: false
            };
        case UPDATE_PRODUCTCLASS_REQUEST:
            return {
                loading: true,
            };
        case UPDATE_PRODUCTCLASS_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case UPDATE_PRODUCTCLASS_ERROR:
            return {
                ...state,
                loading: false
            };

        default:
            return state;
    }
}