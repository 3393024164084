import {
    CREATE_ITEM_INSEAM_REQUEST, CREATE_ITEM_INSEAM_SUCCESS, CREATE_ITEM_INSEAM_ERROR,
    CREATE_ITEM_SIZE_REQUEST, CREATE_ITEM_SIZE_SUCCESS, CREATE_ITEM_SIZE_ERROR,
    CREATE_COMBINATION_REQUEST, CREATE_COMBINATION_SUCCESS, CREATE_COMBINATION_ERROR,
    GET_COMBINATION_ERROR, GET_COMBINATION_REQUEST, GET_COMBINATION_SUCCESS
} from "../actions/types"

export default function (state = null, action) {
    const { type, payload } = action;
    switch (type) {
        case GET_COMBINATION_REQUEST:
            return {
                loading: true,
            };
        case GET_COMBINATION_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_COMBINATION_ERROR:
            return {
                ...state,
                loading: false,
                payload
            };
        case CREATE_COMBINATION_REQUEST:
            return {
                loading: true,
            };
        case CREATE_COMBINATION_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case CREATE_COMBINATION_ERROR:
            return {
                ...state,
                loading: false,
                payload
            };
        case CREATE_ITEM_SIZE_REQUEST:
            return {
                loading: true,
            };
        case CREATE_ITEM_SIZE_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case CREATE_ITEM_SIZE_ERROR:
            return {
                ...state,
                loading: false,
                payload
            };
        case CREATE_ITEM_INSEAM_REQUEST:
            return {
                loading: true,
            };
        case CREATE_ITEM_INSEAM_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case CREATE_ITEM_INSEAM_ERROR:
            return {
                ...state,
                loading: false,
                payload
            };
        default:
            return state;
    }
}