import {
    GET_ITEMTYPE_ERROR,
    GET_ITEMTYPE_REQUEST,
    GET_ITEMTYPE_SUCCESS,
    ADD_ITEMTYPE_ERROR,
    ADD_ITEMTYPE_REQUEST,
    ADD_ITEMTYPE_SUCCESS,
    DELETE_ITEMTYPE_REQUEST,
    DELETE_ITEMTYPE_SUCCESS,
    DELETE_ITEMTYPE_ERROR,
    UPDATE_ITEMTYPE_ERROR,
    UPDATE_ITEMTYPE_REQUEST,
    UPDATE_ITEMTYPE_SUCCESS,
} from "./types";

import { BASEURL } from "../../constant/config.js";

const axios = require("axios");

export const getItemType = (token, menu_id) => (dispatch) => {
    dispatch({ type: GET_ITEMTYPE_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/item_type`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { menu_id }
    })
        .then((response) => {
            return dispatch({ type: GET_ITEMTYPE_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: GET_ITEMTYPE_ERROR,
                payload: error.response,
            });
        });
};

export const addItemType = (token, data, menu_id) => (dispatch) => {



    dispatch({ type: ADD_ITEMTYPE_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}create/item_type`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { item_type_name: data.itemTypeName, is_active: data.check, menu_id },
    })
        .then((response) => {

            return dispatch({ type: ADD_ITEMTYPE_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: ADD_ITEMTYPE_ERROR,
                payload: error.response,
            });
        });
};

export const updateItemType = (token, data, id) => (dispatch) => {
    dispatch({ type: UPDATE_ITEMTYPE_REQUEST });
    return axios({
        method: "PUT",
        url: `${BASEURL}update/item_type/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { item_type_name: data.itemTypeName, is_active: data.check },
    })
        .then((response) => {

            return dispatch({ type: UPDATE_ITEMTYPE_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: UPDATE_ITEMTYPE_ERROR,
                payload: error.response,
            });
        });
};

export const deleteItemType = (token, id) => (dispatch) => {
    dispatch({ type: DELETE_ITEMTYPE_REQUEST });
    return axios({
        method: "DELETE",
        url: `${BASEURL}delete/item_type/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
    })
        .then((response) => {

            return dispatch({ type: DELETE_ITEMTYPE_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: DELETE_ITEMTYPE_ERROR,
                payload: error.response,
            });
        });
};
