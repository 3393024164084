import {
    ADD_SALES_TARGET_REQUEST, ADD_SALES_TARGET_SUCCESS, ADD_SALES_TARGET_ERROR, GET_SALES_TARGET_ERROR, GET_SALES_TARGET_REQUEST, GET_SALES_TARGET_SUCCESS,
    UPDATE_SALES_TARGET_REQUEST, UPDATE_SALES_TARGET_SUCCESS, UPDATE_SALES_TARGET_ERROR, DELETE_SALES_TARGET_REQUEST, DELETE_SALES_TARGET_SUCCESS, DELETE_SALES_TARGET_ERROR
} from "./types";
import axios from "axios";
import { BASEURL } from "../../constant/config.js";



export const getSalesTarget = (token, id) => (dispatch) => {
    dispatch({ type: GET_SALES_TARGET_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/scmSaleTarget`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: { id }
    })
        .then((response) => {
            return dispatch({ type: GET_SALES_TARGET_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: GET_SALES_TARGET_ERROR,
                payload: error.response,
            });
        });
};






export const addSalesTarget = (token, data, id) => (dispatch) => {
    dispatch({ type: ADD_SALES_TARGET_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}create/scmSaleTarget`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data,
    })
        .then((response) => {

            return dispatch({ type: ADD_SALES_TARGET_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: ADD_SALES_TARGET_ERROR,
                payload: error.response,
            });
        });
};

export const updateSalesTarget = (token, data, id) => (dispatch) => {
    dispatch({ type: UPDATE_SALES_TARGET_REQUEST });
    return axios({
        method: "PUT",
        url: `${BASEURL}update/scmSaleTarget/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data,
    })
        .then((response) => {
            return dispatch({ type: UPDATE_SALES_TARGET_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: UPDATE_SALES_TARGET_ERROR,
                payload: error.response,
            });
        });
};




export const deleteSalesTarget = (token, id) => (dispatch) => {
    dispatch({ type: DELETE_SALES_TARGET_REQUEST });
    return axios({
        method: "DELETE",
        url: `${BASEURL}delete/scmSaleTarget/${id}`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
    })
        .then((response) => {
            return dispatch({ type: DELETE_SALES_TARGET_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            return dispatch({
                type: DELETE_SALES_TARGET_ERROR,
                payload: error.response,
            });
        });
};
