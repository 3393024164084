import { GET_GLOBAL_SEARCH_REQUEST, GET_GLOBAL_SEARCH_SUCCESS, GET_GLOBAL_SEARCH_ERROR } from "../actions/types";

export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case GET_GLOBAL_SEARCH_REQUEST:
            return {
                loading: true,
            };
        case GET_GLOBAL_SEARCH_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_GLOBAL_SEARCH_ERROR:
            return {
                ...state,
                loading: false,
                payload,
            };
        default:
            return state;
    }
}
