import {
  GET_AR_ACCOUTN_REQUEST,
  GET_AR_ACCOUTN_SUCCESS,
  GET_AR_ACCOUTN_ERROR,
  GET_CUSTOMER_PAYMENT_REQUEST,
  GET_CUSTOMER_PAYMENT_SUCCESS,
  GET_CUSTOMER_PAYMENT_ERROR,
} from "./types";
import { BASEURL } from "../../constant/config.js";

const axios = require("axios");


export const getARAccounts = (token) => (dispatch) => {
  dispatch({ type: GET_AR_ACCOUTN_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}getAll/arAccount`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {
      return dispatch({ type: GET_AR_ACCOUTN_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      return dispatch({
        type: GET_AR_ACCOUTN_ERROR,
        payload: error.response,
      });
    });
};

export const getCustomerPayment = (token) => (dispatch) => {
  dispatch({ type: GET_CUSTOMER_PAYMENT_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}getAll/custpayment`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {
      return dispatch({ type: GET_CUSTOMER_PAYMENT_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      return dispatch({
        type: GET_CUSTOMER_PAYMENT_ERROR,
        payload: error.response,
      });
    });
};
