import { GET_MENU_RIGHTS_REQUEST,GET_MENU_RIGHTS_ERROR,GET_MENU_RIGHTS_SUCCESS,UPDATE_MENU_RIGHTS_ERROR,UPDATE_MENU_RIGHTS_REQUEST,UPDATE_MENU_RIGHTS_SUCCESS,
    ADD_MENU_RIGHTS_ERROR,ADD_MENU_RIGHTS_REQUEST,ADD_MENU_RIGHTS_SUCCESS  } from "../actions/types";




export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
            case GET_MENU_RIGHTS_REQUEST:
            return {
                loading: true,
            };
        case GET_MENU_RIGHTS_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_MENU_RIGHTS_ERROR:
            return {
                ...state,
                loading: false
            };
            case ADD_MENU_RIGHTS_REQUEST:
                return {
                    loading: true,
                };
            case ADD_MENU_RIGHTS_SUCCESS:
                return {
                    ...state,
                    loading: false,
                    payload: [payload],
                };
            case ADD_MENU_RIGHTS_ERROR:
                return {
                    ...state,
                    loading: false
                };
            case UPDATE_MENU_RIGHTS_REQUEST:
            return {
                loading: true,
            };
        case UPDATE_MENU_RIGHTS_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case UPDATE_MENU_RIGHTS_ERROR:
            return {
                ...state,
                loading: false
            };
        default:
            return state;
    }
}