import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { getWashName } from "../../redux/actions/wash.action";
import { Button, Form, Select, DatePicker, Radio, Input } from 'antd';
import { ReadCookie } from "../../utils/readCookie";
import { CloseCircleOutlined } from '@ant-design/icons';
import { getFabric } from '../../redux/actions/fabric.action';
import { getFitCategory } from '../../redux/actions/fitCategory.action';
import { getSeason } from '../../redux/actions/season.action';
import { useHistory } from "react-router-dom";
import "../ProducList/productList.css";
import moment from 'moment';
import { Row as Brow, Col as Bcol } from "reactstrap";
import { loadCustomers } from '../../redux/actions/customerForm';
import { getOrderStatus } from '../../redux/actions/orderStatus.action';
import { getAllSaleperson } from '../../redux/actions/salesPerson';
import { getWashType } from '../../redux/actions/washType.action';
import { getReportIP } from '../../redux/actions/reportIP.action';
import { getSaleOrderFormData } from '../../redux/actions/salesOrder.action';
import { getGender } from '../../redux/actions/gender.action';
import { getStyle } from '../../redux/actions/style';
import { getCut } from '../../redux/actions/cut.action';

const { RangePicker } = DatePicker;
const OrderReprot = ({ token }) => {
    const { wash, fabric, fitCategory, season, salePerson, washType, customerForms, gender, style, cut } = useSelector(state => state)
    const [washID, setWashID] = useState('All')
    const focusInput = useRef(null);

    const [washTypeID, setWashTypeID] = useState('All')
    const [date, setDate] = useState([moment().format("MM/DD/YYYY"), moment().format("MM/DD/YYYY")])
    const [fabricID, setFabricID] = useState('All')
    const [genderID, setGenderID] = useState('All')
    const [fitCatID, setFitCatID] = useState('All')
    const [seasonID, setSeasonID] = useState('All')
    const [customer, setCustomer] = useState('All')
    const [styleID, setStyle] = useState("All")
    const [status, setStatus] = useState("All")
    const [sku, setSKU] = useState("")
    const [salePersonName, setSalePersonName] = useState("All")
    const menuId = ReadCookie('menuId')
    const userName = ReadCookie('name')
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [showGroup, setShowGroup] = useState(false)
    const [reportStatus, setReportStatus] = useState('booked')
    const [groupBY, setGroupBy] = useState(1)
    const [reportIP, setReportIP] = useState('');
    const [groupName, setGroupName] = useState('Sales Person');
    const { Option } = Select;
    let history = useHistory();

    useEffect(() => {
        dispatch(getReportIP(token)).then((res) => { setReportIP(res.payload.data[0].report_server_ip) });
        
        dispatch(getOrderStatus(token, menuId));
        dispatch(getAllSaleperson(token, menuId));
        dispatch(getFabric(token, menuId));
        dispatch(getFitCategory(token, menuId));
        dispatch(getSeason(token, menuId));
        dispatch(getWashName(token, menuId));
        dispatch(getWashType(token, menuId));
        dispatch(getGender(token, menuId));
        dispatch(getStyle(token, menuId));
        dispatch(getCut(token, menuId));


    }, [])

    
    
    
    

    const getReportName = () => {
        if (reportStatus === "booked" && showGroup) {
            return "sale_order_group_report.rptdesign"
        }
        else if (reportStatus === "shipped" && showGroup) {
            return "ShipGroupBy.rptdesign"
        }
        else if (reportStatus === "pickTicket" && showGroup) {
            return "Pick_Ticket_GroupBy.rptdesign"
        }
        else if (reportStatus === "pending" && showGroup) {
            return "PendingGroupReport.rptdesign"
        }
        else if (reportStatus === "booked") {
            return "OrderReport.rptdesign"
        }

        else if (reportStatus === "pending") {
            return "salePendingReport.rptdesign"
        }

        else if (reportStatus === "pickTicket") {
            return "Pick_Ticket_Report.rptdesign"
        }

        else if (reportStatus === "shipped") {
            return "SaleShipReport.rptdesign"
        }
    }

    const handleChanges = (val) => {
        if (val === "" || val === 'All') return
        setCustomer(val);
        dispatch(loadCustomers(token, null, null, null, null, null, val, null, null, null, null, null, null, null, null, null, null, null)).then(
            (res) => {
                if (res?.type === "GET_ALL_CUSTOMERS_SUCCESS") {
                    setCustomer(res?.payload?.data)
                }
            }
        );
    };

    useEffect(() => {
        if (groupBY === 1) {
            return setGroupName("Sales Person")
        }
        else if (groupBY === 2) {
            return setGroupName("Wash Code")
        }
        else if (groupBY === 3) {
            return setGroupName("Customer")
        }
        else if (groupBY === 4) {
            return setGroupName("Base Sku")
        }
        else if (groupBY === 5) {
            return setGroupName("Style")
        }
        else if (groupBY === 6) {
            return setGroupName("Gender")
        }
        else if (groupBY === 7) {
            return setGroupName("Fabric")
        }
        else if (groupBY === 8) {
            return setGroupName("FIT")
        }
    }, [groupName, groupBY])

    const onFinish = (values) => {
        window.open(`${reportIP}birt/frameset?__report=${getReportName()}&__format=html&__svg=true&__locale=en_US&__timezone=PLT&__masterpage=true&__rtl=false&__cubememsize=10&__resourceFolder=C%3A%5CUsers%5Cadm%5Ceclipse%5Crcp-2021-09%5Ceclipse%5Cworkspace%5CERP-Reports&__parameterpage=false&-632080003&${`p_from_date=${date[0]}`}&${`p_to_date=${date[1]}`}&${customer === 'All' ? "__isnull=p_customer_id" : `p_customer_id=${customer}`}&${salePersonName === 'All' ? "__isnull=p_salesperson_id" : `p_salesperson_id=${salePersonName}`}&${washID === 'All' ? "__isnull=p_wash_name_id" : `p_wash_name_id=${washID}`}&${styleID === 'All' ? "__isnull=p_style_id" : `p_style_id=${styleID}`}&${sku === "" ? "__isnull=p_sku" : `p_sku=${sku}`}&${washTypeID === 'All' ? "__isnull=p_wash_type_id" : `p_wash_type_id=${washTypeID}`}&${fitCatID === 'All' ? "__isnull=p_fit_id" : `p_fit_id=${fitCatID}`}&${fabricID === 'All' ? "__isnull=p_fabric_id" : `p_fabric_id=${fabricID}`}&${genderID === 'All' ? "__isnull=p_season_id" : `p_season_id=${genderID}`}&${showGroup && `p_group_id=${groupBY}`}&${showGroup && `p_group_name=${groupName}`}&user_name=${userName}`)
    };

    function debounce(cb, delay = 1000) {
        let timeout
        return (...args) => {
            clearTimeout(timeout)
            timeout = setTimeout(() => {
                cb(...args)
            }, delay)
        }
    }

    const clearFields = (event, type) => {
        event.preventDefault()
        if (type === 'status') {
            setStatus('All')
        }
        else if (type === 'customer') {
            setCustomer('All')
        }
        else if (type === 'fabric') {
            setFabricID('All')
        }
        else if (type === 'washName') {
            setWashID('All')
        }
        else if (type === 'style') {
            setStyle('All')
        }
        else if (type === 'sku') {
            setSKU('')
        }
        else if (type === 'washType') {
            setWashTypeID('All')
        }
        else if (type === 'salesPerson') {
            setSalePersonName('All')
        }

        else if (type === 'fit') {
            setFitCatID('All')
        }
        else if (type === 'gender') {
            setGenderID('All')
        }
    };

    const optimizedFn = useCallback(debounce(handleChanges), []);
    return (
        <div className='product-detail-main-wrapper'
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}>
            <div className='product-detail-main' style={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}>
                <div className='pageHead ' >
                    <h4 className='prod-det-title-hd mb-0'>Orders Summary</h4>
                    <div>
                        <Button className="pos-right-10 iconBtn crudBTN" shape='circle' icon={<CloseCircleOutlined className='color-red' />} onClick={() => history.push("/")} />
                    </div>
                </div>
                <div className='product-detail-content'>
                    <Form
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 18 }}
                        layout="horizontal"
                        form={form} name="control-hooks" onFinish={onFinish}>
                        <Brow>
                            <Bcol lg={8} xl={8} xxl={8}>
                                <div className="date-filter">
                                    <h3 className="filter-title">Date Range</h3>
                                    <Form.Item
                                        name="date"
                                        label="Date"
                                    >
                                        <RangePicker
                                            defaultValue={[moment(date[0]), moment(date[1])]}
                                            format="MM/DD/YYYY" onChange={(dates) => {

                                                let sDate = moment(dates[0]._d).format('MM/DD/YYYY');
                                                let eDate = moment(dates[1]._d).format('MM/DD/YYYY');
                                                setDate([sDate, eDate])
                                            }} />
                                    </Form.Item>
                                </div>
                                <div className="date-filter">
                                    <h3 className="filter-title">Report Type</h3>
                                    <Form.Item
                                        name="reportType">
                                        <Radio.Group onChange={(e) => setReportStatus(e.target.value)} defaultValue={reportStatus} style={{ width: 500 }}>
                                            <Radio value="booked">Booked</Radio>
                                            <Radio value="shipped">Shipped</Radio>
                                            <Radio value="pending">Pending</Radio>
                                            <Radio value="pickTicket">Pick Ticket</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                </div>
                                <div className="date-filter">
                                    <h3 className="filter-title">Filters</h3>
                                    <Form.Item
                                        name="customer"
                                        label="Customersss"
                                    >
                                        <Select
                                            showSearch
                                            optionFilterProp="children"
                                            className="filter-fields"
                                            onSelect={(e) => setCustomer(e)}
                                            loading={customerForms.loading}
                                            
                                            onSearch={(value) => {
                                                optimizedFn(value)
                                            }}
                                            ref={focusInput}
                                            defaultValue="All"
                                            value={customer}
                                        >
                                            {customerForms?.payload?.data.map(
                                                (item) => {
                                                    return (
                                                        <Option
                                                            key={item.customer_id}
                                                            value={item.customer_id}
                                                        >
                                                            {" "}
                                                            {`${item.customer_code} ${item.customer_name}`}
                                                        </Option>
                                                    );
                                                }
                                            )}
                                        </Select>
                                        <button className="auto-complete-text-btn" onClick={(e) => clearFields(e, "customer")}>CLEAR</button>
                                    </Form.Item>
                                    <Form.Item
                                        name="sp"
                                        label="Sale Person"
                                    >
                                        <Select
                                            showSearch
                                            optionFilterProp="children"
                                            onChange={(e) => setSalePersonName(e)}
                                            className="filter-fields"
                                            
                                            loading={salePerson?.loading}
                                            defaultValue="All"
                                            value={salePersonName}
                                        >
                                            {
                                                salePerson?.payload?.data.map((item) => {
                                                    return <Option defaultValue="All" key={item.sales_person_id} value={item.sales_person_id}>{item.salesperson_name}</Option>
                                                })
                                            }
                                        </Select>
                                        <button className="auto-complete-text-btn" onClick={(e) => clearFields(e, "salesPerson")}>CLEAR</button>
                                    </Form.Item>
                                    <Form.Item
                                        name="washType"
                                        label="Wash Type"
                                    >
                                        <Select
                                            showSearch
                                            optionFilterProp="children"
                                            onChange={(e) => setWashTypeID(e)}
                                            className="filter-fields"
                                            
                                            loading={washType?.loading}
                                            defaultValue="All"
                                            value={washTypeID}
                                        >
                                            {
                                                washType?.payload?.data.map((item) => {
                                                    return <Option defaultValue="All" key={item.wash_type_id} value={item.wash_type_id}>{item.wash_type_name}</Option>
                                                })
                                            }
                                        </Select>
                                        <button className="auto-complete-text-btn" onClick={(e) => clearFields(e, "washType")}>CLEAR</button>
                                    </Form.Item>
                                    <Form.Item
                                        name="washName"
                                        label="Wash Name"
                                    >
                                        <Select
                                            showSearch
                                            optionFilterProp="children"
                                            onChange={(e) => setWashID(e)}
                                            className="filter-fields"
                                            
                                            loading={wash?.loading}
                                            defaultValue="All"
                                            value={washID}
                                        >
                                            {
                                                wash?.payload?.data.map((item) => {
                                                    return <Option defaultValue="All" key={item.wash_id} value={item.wash_id}>{item.wash_name}</Option>
                                                })
                                            }
                                        </Select>
                                        <button className="auto-complete-text-btn" onClick={(e) => clearFields(e, "washName")}>CLEAR</button>
                                    </Form.Item>
                                    <Form.Item
                                        name="fit"
                                        label="Fit/Cut"
                                    >
                                        <Select
                                            showSearch
                                            optionFilterProp="children"
                                            onChange={(e) => setFitCatID(e)}
                                            className="filter-fields"
                                            
                                            loading={cut?.loading}
                                            defaultValue="All"
                                            value={fitCatID}
                                        >
                                            {
                                                cut?.payload?.data.map((item) => {
                                                    return <Option defaultValue="All" key={item.cut_id} value={item.cut_id}>{item.cut_name}</Option>
                                                })
                                            }
                                        </Select>
                                        <button className="auto-complete-text-btn" onClick={(e) => clearFields(e, "fit")}>CLEAR</button>
                                    </Form.Item>
                                    <Form.Item
                                        name="fabric"
                                        label="Fabric"
                                    >
                                        <Select
                                            showSearch
                                            optionFilterProp="children"
                                            onChange={(e) => setFabricID(e)}
                                            className="filter-fields"
                                            
                                            loading={fabric?.loading}
                                            defaultValue="All"
                                            value={fabricID}
                                        >
                                            {
                                                fabric?.payload?.data.map((item) => {
                                                    return <Option defaultValue="All" key={item.fabric_id} value={item.fabric_id}>{item.fabric_name}</Option>
                                                })
                                            }
                                        </Select>
                                        <button className="auto-complete-text-btn" onClick={(e) => clearFields(e, "fabric")}>CLEAR</button>
                                    </Form.Item>
                                    <Form.Item
                                        name="gender"
                                        label="Gender"
                                    >
                                        <Select
                                            showSearch
                                            optionFilterProp="children"
                                            onChange={(e) => setGenderID(e)}
                                            className="filter-fields"
                                            
                                            loading={gender?.loading}
                                            defaultValue="All"
                                            value={genderID}
                                        >
                                            {
                                                gender?.payload?.data.map((item) => {
                                                    return <Option defaultValue="All" key={item.gender_id} value={item.gender_id}>{item.gender_name}</Option>
                                                })
                                            }
                                        </Select>
                                        <button className="auto-complete-text-btn" onClick={(e) => clearFields(e, "gender")}>CLEAR</button>
                                    </Form.Item>
                                    <Form.Item
                                        name="style"
                                        label="Style"
                                    >
                                        <Select
                                            showSearch
                                            optionFilterProp="children"
                                            defaultValue="All"
                                            onChange={(e) => setStyle(e)}
                                            className="filter-fields"
                                            
                                            loading={style?.loading}
                                            value={styleID}
                                        >
                                            {style?.payload?.data.map((item) => {
                                                return <Option defaultValue={"All"} key={item.style_id} value={item.style_id}>{item.style_name}</Option>
                                            })
                                            }
                                        </Select>
                                        <button className="auto-complete-text-btn" onClick={(e) => clearFields(e, "style")}>CLEAR</button>
                                    </Form.Item>
                                    <Form.Item
                                        name="sku"
                                        label="SKU"
                                    >
                                        <Input
                                            
                                            className="filter-fields"
                                            onChange={(e) => setSKU(e.target.value)}
                                            value={sku}
                                        />
                                        <button className="auto-complete-text-btn" onClick={(e) => clearFields(e, "sku")}>CLEAR</button>
                                    </Form.Item>

                                </div>
                            </Bcol>
                            <Bcol lg={4} xl={4} xxl={4}>
                                <div className="date-filter">
                                    <h3 className="filter-title">Group</h3>
                                    <Form.Item
                                        name="group">
                                        <Radio.Group onChange={(e) => setShowGroup(e.target.value)} defaultValue={showGroup} style={{ width: 500 }}>
                                            <Radio value={true}>Yes</Radio>
                                            <Radio value={false}>No</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                </div>
                                {showGroup && <div className="date-filter">
                                    <h3 className="filter-title">Group By</h3>
                                    <Form.Item name="productStatus">
                                        <Radio.Group onChange={(e) => setGroupBy(e.target.value)} defaultValue={groupBY} >
                                            <div style={{ display: "flex", flexDirection: "column" }}>
                                                <Radio value={1}>Sale Person</Radio>
                                                <Radio value={2}>Wash Code</Radio>
                                                <Radio value={3}>Customer</Radio>
                                                <Radio value={4}>Base SKU</Radio>
                                                <Radio value={5}>Style</Radio>
                                                <Radio value={6}>Gender</Radio>
                                                <Radio value={7}>Fabric</Radio>
                                                <Radio value={8}>Fit/Cut</Radio>
                                            </div>
                                        </Radio.Group>
                                    </Form.Item>
                                </div>}

                                <div>
                                    <Form.Item >
                                        <Button type="primary" htmlType="submit" className='w-100'>
                                            Search
                                        </Button>
                                    </Form.Item>
                                </div>
                            </Bcol>
                        </Brow>
                    </Form>
                </div>
            </div>
        </div >
    );
};

export default OrderReprot;
