import {
    GET_ADMIN_JOB_REQUEST,   
    GET_ADMIN_JOB_ERROR,     
    GET_ADMIN_JOB_SUCCESS,   
    ADD_ADMIN_JOB_REQUEST,   
    ADD_ADMIN_JOB_ERROR,     
    ADD_ADMIN_JOB_SUCCESS,   
    UPDATE_ADMIN_JOB_REQUEST,
    UPDATE_ADMIN_JOB_ERROR,  
    UPDATE_ADMIN_JOB_SUCCESS,
    DELETE_ADMIN_JOB_REQUEST,
    DELETE_ADMIN_JOB_ERROR,  
    DELETE_ADMIN_JOB_SUCCESS,

} from "../actions/types"
export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case GET_ADMIN_JOB_REQUEST:
            return {
                loading: true,
            };
        case GET_ADMIN_JOB_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_ADMIN_JOB_ERROR:
            return {
                ...state,
                loading: false
            };
        case ADD_ADMIN_JOB_REQUEST:
            return {
                loading: true,
            };
        case ADD_ADMIN_JOB_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case ADD_ADMIN_JOB_ERROR:
            return {
                ...state,
                loading: false
            };
        case DELETE_ADMIN_JOB_REQUEST:
            return {
                loading: true,
            };
        case DELETE_ADMIN_JOB_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case DELETE_ADMIN_JOB_ERROR:
            return {
                ...state,
                loading: false
            };
        case UPDATE_ADMIN_JOB_REQUEST:
            return {
                loading: true,
            };
        case UPDATE_ADMIN_JOB_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case UPDATE_ADMIN_JOB_ERROR:
            return {
                ...state,
                loading: false
            };

        default:
            return state;
    }
}