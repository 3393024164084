import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAllSalesReturnHeader } from "../../redux/actions/salesReturn.action";

import SkeletonLoad from "../../components/Skeleton/SkeletonLoad";
import PageHeader from "../../components/PageHeader/PageHeader";

import Tables from "../../components/Table/Tables";
import { saleReturnColumns } from "../../utils/cols";
import moment from "moment";
import "../../screens/Product/product.css";
import "../Style/style.css";
import { useHistory } from "react-router-dom";
import { FilterFilled, SearchOutlined, UndoOutlined, FilterOutlined, CaretRightOutlined, FileAddOutlined, CloudDownloadOutlined, CloudUploadOutlined, PlusOutlined } from "@ant-design/icons";
import { Modal, Button, Form, Input, Checkbox, Tabs, Tooltip, Divider, Select, DatePicker, Collapse, Row, Col, Space, Upload } from "antd";


export default function SaleReturnList({ token }) {
	const dispatch = useDispatch();
	const { salesReturnList } = useSelector((state) => {
		return state;
	});
	const [invoiceNo, setInvoiceNo] = useState(null)
	const [returnNo, setReturnNo] = useState(null)
	const [customer, setCustomer] = useState(null)
	const [region, setRegion] = useState(null)
	const [po, setPo] = useState(null)
	const [orderNumber, setOrderNumber] = useState(null)
	const [gender, setGender] = useState(null)
	const [fromdate, setFromDate] = useState(moment("12-01-2021"))
	const [todate, setToDate] = useState(moment("12-01-2022"))
	const [location, setLocation] = useState(null)
	const [salesPerson, setSalesPerson] = useState(null)
	const [paymentTermId, setPaymentTermId] = useState(null)

	const [show, setShow] = useState(false);
	const [filteredInfo, setFilteredInfo] = useState(null);
	const [sortedInfo, setSortedInfo] = useState(null);
	const [customerAllId, setCustomerAllId] = useState(null);
	const [fileList, setFileList] = useState([]);
	const { Panel } = Collapse;
	const [dataLen, setDataLen] = useState(0);
	const { RangePicker } = DatePicker;
	const dateFormat = "MM-DD-YYYY";
	const [loading, setLoading] = useState(true);
	const [filteredCustomers, setFilteredCustomers] = ([])
	const history = useHistory();
	
	
	
	
	
	

	
	
	

	
	
	
	

	
	
	
	

	
	
	
	const onFinishFilter = (values) => {
		dispatch(getAllSalesReturnHeader(token, fromdate, todate, returnNo, invoiceNo, customer, region, po, orderNumber, gender, location, salesPerson, paymentTermId)).then((res) => {
			setFilteredCustomers(res?.payload?.data)
			setDataLen(res?.payload?.data)
		});
	};
	const handleFilter = (pagination, filters, sorter, extra) => {
		setFilteredInfo(filters);
		setSortedInfo(sorter);
		setShow(true);
	};
	const handleClose = () => {
		
	};

	return (
		<div className="styleContainer">
			<div className="styleBody h-100">
				<PageHeader title={"Sale Return List"} />
				<div className="bodyStyle">
					<Collapse
						style={{ width: "99%", margin: "10px" }}
						bordered={false}
						defaultActiveKey={['1']}
						expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
					>

						<Panel header="Filters" key="1" className="site-collapse-custom-panel" extra={
							<Button
								
								onClick={() => {
									history.push("/sale-return/add?mode=add");
								}}
								className="iconBtn crudBTN"
								shape="circle"
								icon={<PlusOutlined />}
							/>}>

							<Form
								labelCol={{
									span: 8,
								}}
								wrapperCol={{
									span: 16,
								}}
								initialValues={{
									remember: true,
								}}
								onFinish={onFinishFilter}
								
								autoComplete="off"
							>
								<Row>
									<Col xs={24} xl={8}>
										<Form.Item
											name="returnNo"
											label="Return#"
											value={returnNo}
										>
											<Input

												allowClear
												onChange={(e) => setReturnNo(e.target.value)}
												
												size="small" />
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item
											name="invoiceNo"
											label="Invoice#"
											value={invoiceNo}
										>
											<Input

												allowClear
												onChange={(e) => setInvoiceNo(e.target.value)}
												
												size="small" />
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item
											name="orderNumber"
											label="Order#"
											value={orderNumber}
										>
											<Input

												allowClear
												onChange={(e) => setOrderNumber(e.target.value)}
												
												size="small" />
										</Form.Item>
									</Col>

									<Col xs={24} xl={8}>
										<Form.Item
											label="From Date"
											name="fromdate"
											value={fromdate}
											initialValue={fromdate}
										>
											<DatePicker
												size="small"
												format={dateFormat}
												allowClear
												value={fromdate}
												className="dateInput customInputs"
												onChange={(e) => setFromDate(moment(e))}
											/>
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item
											label="To Date"
											name="todate"
											value={todate}
											initialValue={todate}
										>
											<DatePicker
												size="small"
												
												
												format={dateFormat}
												allowClear
												className="dateInput customInputs"
												onChange={(e) => setToDate(moment(e))}
											/>
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item
											name="customer"
											label="Customer Name"
											value={customer}
										>
											<Input

												allowClear
												onChange={(e) => setCustomer(e.target.value)}
												
												size="small" />
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item label="Gender" name="gender" value={gender}>
											<Input
												allowClear
												
												size="small"
												onChange={(e) => setGender(e.target.value)}
											/>
										</Form.Item>
									</Col>

									<Col xs={24} xl={8}>
										<Form.Item
											name="region"
											label="Region"
											value={region}
										>
											<Input

												allowClear
												onChange={(e) => setRegion(e.target.value)}
												
												size="small" />
										</Form.Item>
									</Col>

									<Col xs={24} xl={8}>
										<Form.Item
											name="po"
											label="P.O #"
											value={po}
										>
											<Input

												allowClear
												onChange={(e) => setPo(e.target.value)}
												
												size="small" />
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item
											name="location"
											label="Location"
											value={location}
										>
											<Input

												allowClear
												onChange={(e) => setLocation(e.target.value)}
												
												size="small" />
										</Form.Item>
									</Col>

									<Col xs={24} xl={8}>
										<Form.Item
											name="salesPerson"
											label="Sales Person"
											value={salesPerson}
										>
											<Input

												allowClear
												onChange={(e) => setSalesPerson(e.target.value)}
												
												size="small" />
										</Form.Item>
									</Col>
									<Col xs={24} xl={8}>
										<Form.Item label="PaymentTerm" name="paymentTermId" value={paymentTermId}>
											<Input
												allowClear
												
												size="small"
												onChange={(e) => setPaymentTermId(e.target.value)}
											/>
										</Form.Item>
									</Col>
								</Row>
								<div style={{ display: "flex", justifyContent: "flex-end" }}>
									<Button type="primary" htmlType="submit" disabled={salesReturnList.loading}>
										Search
									</Button>
								</div>
							</Form>
						</Panel>
					</Collapse>

					{salesReturnList.loading ? (
						<div className="mainProductContainer">
							<SkeletonLoad />
						</div>
					) : (
						<div className="mainProductContainer">
							<div className="productTable">
								<Tables
									dataLen={dataLen}
									setDataLen={setDataLen}
									show={show}
									handleFilter={handleFilter}
									loadHeight={40}
									loadWidth={40}
									type={"salesReturn"}
									
									
									scroll={570}

									perPage={50}
									scrollX={"80rem"}
									col={saleReturnColumns}
									source={salesReturnList ? salesReturnList?.payload?.data : []}
									load={salesReturnList.loading}
									paginate
								/>
								{/* <span className="records-text">
                      {dataLen === 0 ? <>No of Records:  <span className="fw-500">  0</span> </> : < > No of Records: <span className="fw-500"> {show ? dataLen : dataLen ? dataLen : "0"}</span>  </>}
                    </span> */}
							</div>
						</div>
					)}

				</div>
			</div>
		</div >
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
	);
}