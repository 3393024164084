import { GET_PAYMENT_METHOD_REQUEST, GET_PAYMENT_METHOD_SUCCESS, GET_PAYMENT_METHOD_ERROR } from "./types";
import { BASEURL } from "../../constant/config.js";

const axios = require("axios");
export const getPaymentMethod = token => dispatch => {
  dispatch({ type: GET_PAYMENT_METHOD_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}getAll/PaymentMethod`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then(response => {
      return dispatch({ type: GET_PAYMENT_METHOD_SUCCESS, payload: response.data });
    })
    .catch(error => {
      return dispatch({
        type: GET_PAYMENT_METHOD_ERROR,
        payload: error.response,
      });
    });
};
