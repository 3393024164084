import {
    GET_REGIONCATG_BYID_REQUEST,
    GET_REGIONCATG_BYID_SUCCESS,
    GET_REGIONCATG_BYID_ERROR,
   
     } from "../actions/types";
     
     export default function (state = [], action) {
       const { type, payload } = action;
       switch (type) {
         case GET_REGIONCATG_BYID_REQUEST:
           return {
             loading: true,
           };
         case GET_REGIONCATG_BYID_SUCCESS:
           return {
             ...state,
             loading: false,
             payload,
           };
         case GET_REGIONCATG_BYID_ERROR:
           return {
             ...state,
             loading: false,
             payload,
           };
   
         default:
           return state;
       }
     }
     