import { GET_ITEM_INVENTORYADJUSTMENT_HEADER_FORM_DATA_ERROR,
	GET_ITEM_INVENTORYADJUSTMENT_HEADER_FORM_DATA_REQUEST,
	GET_INVENTORYADJUSTMENT_HEADER_FORM_DATA_SUCCESS } from "../actions/types";

export default function (state = [], action) {
	const { type, payload } = action;
	switch (type) {
		case GET_ITEM_INVENTORYADJUSTMENT_HEADER_FORM_DATA_REQUEST:
			return {
				loading: true,
			};
		case GET_INVENTORYADJUSTMENT_HEADER_FORM_DATA_SUCCESS:
			return {
				...state,
				loading: false,
				payload,
			};
		case GET_ITEM_INVENTORYADJUSTMENT_HEADER_FORM_DATA_ERROR:
			return {
				...state,
				loading: false,
			};
		default:
			return state;
	}
}