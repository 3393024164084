import {
  GET_ADJUSTMENT_ACCOUNT_ERROR,
  GET_ADJUSTMENT_ACCOUNT_REQUEST,
  GET_ADJUSTMENT_ACCOUNT_SUCCESS,

} from "../actions/types";

export default function (state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case GET_ADJUSTMENT_ACCOUNT_REQUEST:
      return {
        loading: true,
      };
    case GET_ADJUSTMENT_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        payload,
      };
    case GET_ADJUSTMENT_ACCOUNT_ERROR:
      return {
        ...state,
        loading: false,
        payload,
      };
    default:
      return state;
  }
}
