import {
    ADD_PAY_CUSTOMER_PAYMENT_HEADER_REQUEST, ADD_PAY_CUSTOMER_PAYMENT_HEADER_SUCCESS, ADD_PAY_CUSTOMER_PAYMENT_HEADER_ERROR,
    GET_ONE_PAY_CUSTOMER_PAYMENT_HEADER_REQUEST, GET_ONE_PAY_CUSTOMER_PAYMENT_HEADER_SUCCESS, GET_ONE_PAY_CUSTOMER_PAYMENT_HEADER_ERROR, UPDATE_PAY_CUSTOMER_PAYMENT_HEADER_REQUEST, UPDATE_PAY_CUSTOMER_PAYMENT_HEADER_ERROR, UPDATE_PAY_CUSTOMER_PAYMENT_HEADER_SUCCESS,
    CUSTOMER_SELECTED_INVOICE_REQUEST,
    CUSTOMER_SELECTED_INVOICE_SUCCESS,
    CUSTOMER_SELECTED_INVOICE_ERROR
} from "../actions/types"
export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case ADD_PAY_CUSTOMER_PAYMENT_HEADER_REQUEST:
            return {
                loading: true,
            };
        case ADD_PAY_CUSTOMER_PAYMENT_HEADER_SUCCESS:
            return {
                ...state,
                loading: false,
                payload: [payload],
            };
        case ADD_PAY_CUSTOMER_PAYMENT_HEADER_ERROR:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_ONE_PAY_CUSTOMER_PAYMENT_HEADER_REQUEST:
            return {
                loading: true,
            };
        case GET_ONE_PAY_CUSTOMER_PAYMENT_HEADER_SUCCESS:
            return {
                ...state,
                loading: false,
                payload,
            };
        case GET_ONE_PAY_CUSTOMER_PAYMENT_HEADER_ERROR:
            return {
                ...state,
                loading: false,
                payload,
            };
        case UPDATE_PAY_CUSTOMER_PAYMENT_HEADER_REQUEST:
            return {
                loading: true,
            };
        case UPDATE_PAY_CUSTOMER_PAYMENT_HEADER_SUCCESS:
            return {
                ...state,
                loading: false,
                payloadUpdate: [payload],
            };
        case UPDATE_PAY_CUSTOMER_PAYMENT_HEADER_ERROR:
            return {
                ...state,
                loading: false,
                payloadUpdate: [payload],
            };

        case CUSTOMER_SELECTED_INVOICE_REQUEST:
            return {
                loading: true,
            };
        case CUSTOMER_SELECTED_INVOICE_SUCCESS:
            return {
                ...state,
                loading: false,
                payloadUpdate: [payload],
            };
        case CUSTOMER_SELECTED_INVOICE_ERROR:
            return {
                ...state,
                loading: false,
                payloadUpdate: [payload],
            };

        default:
            return state;
    }
}
