import {
    GET_AP_AGING_DETAIL_REQUEST,
    GET_AP_AGING_DETAIL_SUCCESS,
    GET_AP_AGING_DETAIL_ERROR
} from "./types";
import axios from "axios";
import { BASEURL } from "../../constant/config.js";
import moment from "moment";

export const getApAgingDetail = (token, data) => (dispatch) => {
    dispatch({ type: GET_AP_AGING_DETAIL_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/apAgging`,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: {
            date_to: moment(data.to_date).format("MM-DD-YYYY"),
        }
    })
        .then((response) => {
            return dispatch({
                type: GET_AP_AGING_DETAIL_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) => {
            dispatch({
                type: GET_AP_AGING_DETAIL_ERROR,
                payload: [],
            });
        });
};
