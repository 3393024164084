import {
  GET_FABRICCOMPOSITION_REQUEST,
  GET_FABRICCOMPOSITION_SUCCESS,
  GET_FABRICCOMPOSITION_ERROR,
  ADD_FABRICCOMPOSITION_REQUEST,
  ADD_FABRICCOMPOSITION_ERROR,
  ADD_FABRICCOMPOSITION_SUCCESS,
  UPDATE_FABRICCOMPOSITION_REQUEST,
  UPDATE_FABRICCOMPOSITION_SUCCESS,
  UPDATE_FABRICCOMPOSITION_ERROR,
  DELETE_FABRICCOMPOSITION_ERROR,
  DELETE_FABRICCOMPOSITION_REQUEST,
  DELETE_FABRICCOMPOSITION_SUCCESS,
} from "../actions/types";
export default function (state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case GET_FABRICCOMPOSITION_REQUEST:
      return {
        loading: true,
      };
    case GET_FABRICCOMPOSITION_SUCCESS:
      return {
        ...state,
        loading: false,
        payload,
      };
    case GET_FABRICCOMPOSITION_ERROR:
      return {
        ...state,
        loading: false,
      };
    case ADD_FABRICCOMPOSITION_REQUEST:
      return {
        loading: true,
      };
    case ADD_FABRICCOMPOSITION_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: [payload],
      };
    case ADD_FABRICCOMPOSITION_ERROR:
      return {
        ...state,
        loading: false,
      };
    case DELETE_FABRICCOMPOSITION_REQUEST:
      return {
        loading: true,
      };
    case DELETE_FABRICCOMPOSITION_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: [payload],
      };
    case DELETE_FABRICCOMPOSITION_ERROR:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_FABRICCOMPOSITION_REQUEST:
      return {
        loading: true,
      };
    case UPDATE_FABRICCOMPOSITION_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: [payload],
      };
    case UPDATE_FABRICCOMPOSITION_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
