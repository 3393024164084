import {
    GET_SHIP_VIA_REQUEST,
    GET_SHIP_VIA_SUCCESS,
    GET_SHIP_VIA_ERROR,

} from "./types"
import axios from "axios";
import { BASEURL } from "../../constant/config.js"



export const loadShipVia = (token) => dispatch => {
    dispatch({ type: GET_SHIP_VIA_REQUEST });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/adminCourierService`,
        headers: {
            Accept: "application/json",
            'Authorization': 'Bearer ' + token
        }
    })
        .then(response => {
            return dispatch({ type: GET_SHIP_VIA_SUCCESS, payload: response.data });
        })
        .catch((error) => {
            if (error.response.data.data === "No Record Found !") {
                return dispatch({
                    type: GET_SHIP_VIA_ERROR,
                    payload: [],
                });
            }
            return dispatch({
                type: GET_SHIP_VIA_ERROR,
                payload: error.response,
            });
        })
};

