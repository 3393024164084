import {
    GET_CRM_SKU_WISE_REQUEST,
    GET_CRM_SKU_WISE_SUCCESS,
    GET_CRM_SKU_WISE_ERROR
} from "../actions/types";

export default function (state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case GET_CRM_SKU_WISE_REQUEST:
            return {
                loading: true,
            };
        case GET_CRM_SKU_WISE_SUCCESS:
            return {
                ...state,
                loading: false,
                payload
            };
        case GET_CRM_SKU_WISE_ERROR:
            return {
                ...state,
                loading: false,
                payload,
            };

        default:
            return state;
    }
}
