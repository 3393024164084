import React, { useEffect, useState } from "react";
import {
  Spin,
  Select,
  DatePicker,
  Form,
  Button,
  Table,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { ReadCookie } from "../../utils/readCookie";
import moment from "moment";
import btnPrint from "../../assets/images/button_print.gif";
import xlsx from "../../assets/images/ico_xls_sm.gif";
import logo from "../../assets/images/logo.png";
import "../Distributor/Distributor.css";
import { useRef } from "react";
import { CSVLink } from "react-csv";
import { TabTitle } from "../../utils/GeneralFunction";
import { getFactor } from "../../redux/actions/factor.action";
import { getNetShipStyleWise } from "../../redux/actions/netShipStyleWise.action";
const { Option } = Select;

const NetShippedStyleWise = ({ token }) => {
  TabTitle("Net Shipped");
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const {
    netShipStyleWise,
    factor
  } = useSelector((state) => state);
  const menuId = ReadCookie("menuId");
  const userName = ReadCookie("name");
  const tableRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [intransitList, setIntransitList] = useState([]);
  const [excelData, setExcelData] = useState([]);
  const initialDate = moment("2026-12-31");

  

  const onFinish = async (e) => {
    setLoading(true);
    await form.validateFields().then((data) => {
      
      dispatch(getNetShipStyleWise(token, data)).then((res) => {
        
        setLoading(false);
        setIntransitList(res?.payload?.data);
        downloadExcelFileData(res?.payload?.data);
      });
    });
  };

  useEffect(() => {
    dispatch(getFactor(token, menuId));
  }, [dispatch, menuId, token]);

  const handlePrint = () => {
    window.print();
  };

  const colums = [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
      width: 40,
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Customer Name",
      dataIndex: "customer_name",
      key: "customer_name",
      sorter: (a, b) => {
        if ((a.customer_name !== null) & (b.customer_name !== null)) {
          return a.customer_name.localeCompare(b.customer_name);
        }
      },
    },
    {
      title: "Ship Qty",
      dataIndex: "ship_quantity",
      key: "ship_quantity",
      align: "right",
      sorter: (a, b) => {
        return a.ship_quantity.replace(",","") - b.ship_quantity.replace(",","");
      },
    },
    {
      title: "Ship Amount",
      dataIndex: "ship_amount",
      key: "ship_amount",
      render: (text, record) => {
        return (
          <>
            ${(record.ship_amount)}
          </>
        );
      },
      align: "right",
      sorter: (a, b) => {
        return a.ship_amount.replace(",","") - b.ship_amount.replace(",","");
      },
    },
    {
      title: "Return Qty",
      dataIndex: "return_quantity",
      key: "return_quantity",
      align: "right",
      sorter: (a, b) => {
        return a.return_quantity.replace(",","") - b.return_quantity.replace(",","");
      },
    },
    {
      title: "Return Amount",
      dataIndex: "return_amount",
      key: "return_amount",
      align: "right",
      render: (text, record) => {
        return (
          <>
            ${(record.return_amount)}
          </>
        );
      },
      sorter: (a, b) => {
        return a.return_amount.replace(",","") - b.return_amount.replace(",","");
      },
    },
    {
      title: "Net Qty",
      dataIndex: "net_quantity",
      key: "net_quantity",
      align: "right",
      sorter: (a, b) => {
        return a.net_quantity.replace(",","") - b.net_quantity.replace(",","");
      },
    },
    {
      title: "Net Amount",
      dataIndex: "net_amount",
      key: "net_amount",
      align: "right",
      render: (text, record) => {
        return (
          <>
            ${(record.net_amount)}
          </>
        );
      },
      sorter: (a, b) => {
        return a.net_amount.replace(",","") - b.net_amount.replace(",","");
      },
    },
    {
      title: "Status",
      dataIndex: "factor_name",
      key: "factor_name",
      align: "center",
      sorter: (a, b) => {
        if ((a.factor_name !== null) & (b.factor_name !== null)) {
          return a.factor_name.localeCompare(b.factor_name);
        }
      },
    },
  ];


  function downloadExcelFileData(data) {
    setExcelData(
      data.map((v) => {
        return {
          "Customer Name": v.customer_name,
          "Ship Qty": v.ship_quantity,
          "Ship Amount": v.ship_amount,
          "Return Qty": v.return_quantity,
          "Return Amount": v.return_amount,
          "Net Qty": v.net_quantity,
          "Net Amount": v.net_amount,
          "Status": v.factor_name,
        };
      })
    );
  }


  return (
    <div className="header_class business_header_oms intransit">
      <div className="row justify-content-between business_class_oms distributor">
        <div className="col-3 d-flex align-items-start box-space">
          <div className="logobrand">
            <img id="ImgLogo" alt="logo" src={logo} />
          </div>
        </div>
        <div className="col-4 box-space right-content-header text-right">
          <div className=" d-flex justify-content-end">
            <div className=" d-flex justify-content-end mr-8">
              <div className="print_icon printing_class">
                <i
                  onClick={handlePrint}
                  id="Image1"
                  title="Print"
                  alt="Print"
                  src={btnPrint}
                  className="fa-solid fa-print"
                ></i>
              </div>
              <div className="print_icon printing_class">
                <CSVLink
                  filename={"Net-Shipped-Customer-Wise.csv"}
                  data={excelData}
                  onClick={() => { }}
                >
                  <input
                    type="image"
                    name="ImgExcel"
                    alt="Export to excel"
                    id="ImgExcel"
                    title="Export to excel"
                    src={xlsx}
                    
                    
                    style={{ borderWidth: "0px", height: "14px" }}
                  />
                </CSVLink>
              </div>
            </div>
            <span style={{ fontSize: "small", fontWeight: "bolder" }}>
              Net Shipped Customer Wise
              <br />
              Rpt # 489
              <br />
              <p className=" mb-0">Rpt # 001_V4</p>
              Login as:{" "}
              <span id="lblSalesPerson" style={{ fontWeight: "bold" }}>
                {userName}
              </span>
            </span>
          </div>
        </div>
      </div>
      <div className="business_class_oms mb-2 distributor">
        <Form form={form} name="control-hooks" onFinish={onFinish}>
          <div className="main_form row">
            <div className="col-12">
              <hr className="my-1" />
              <div className="row">
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width30" htmlFor="">
                      From
                    </label>
                    <div className="w-100">
                      <Form.Item
                        name="from_date"
                        className="full_date"
                        initialValue={moment().startOf("year")}
                      >
                        <DatePicker
                          size="small"
                          format={"MM-DD-YYYY"}
                          defaultValue={moment().format("MM-DD-YYYY")}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width30" htmlFor="">
                      To
                    </label>
                    <div className="w-100">
                      <Form.Item
                        name="to_date"
                        className="full_date"
                        initialValue={initialDate}
                      >
                        <DatePicker
                          size="small"
                          format={"MM-DD-YYYY"}
                          defaultValue={moment().format("MM-DD-YYYY")}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width40" htmlFor="">
                     Factor Type
                    </label>
                    <div className="w-100">
                      <Form.Item
                        
                        name="paymentClass"
                        className="full_date"
                        initialValue={"All"}
                      >
                        <Select
                          size="small"
                          autoComplete={"nope"}
                          filterOption={(input, option) => option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0}
                          showSearch
                          loading={factor.loading}
                        >
                          <Option value={null}>All</Option>

                          {factor?.payload?.data.map(
                            (item) => {
                              return (
                                <Select.Option
                                  key={item.factor_id}
                                  
                                  value={item.factor_id}
                                >
                                  {item.factor_name}
                                </Select.Option>
                              );
                            }
                          )}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-2">
                  <div style={{ marginTop: "4px" }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="submit-btn-btm m-0"
                    >
                      Generate
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>
      <div
        
        className="table_content international_order"
        style={{ maxWidth: "1280px" }}
      >
        <div>
          {netShipStyleWise?.payload?.data !== undefined ? (
            netShipStyleWise?.payload?.data.length > 0 ? (
              <Table
                className="
                
                test04 mt-3 sales_report_res_table_intransit omsStockSummaryTableNewHeader omsStockSummaryTableNewHeader2"
                ref={tableRef}
                size="small"
                bordered={false}
                type="company"
                headerClassName="abc"
                dataSource={intransitList}
                columns={colums}
                loading={netShipStyleWise.loading}
                scroll={{ x: "max-content" }}
                pagination={{
                  defaultCurrent: 1,
                  defaultPageSize: 100,
                  pageSizeOptions: [100, 500, 1000, 2000, 2500, 3000]
                }}
                summary={(pageData) => {
                  
                  let ship_Qty = pageData.reduce(function (
                    previousValue,
                    currentValue
                  ) {
                    return (previousValue += 
                      parseFloat(currentValue.ship_quantity.replace(",",""))
                    );
                  },
                    0);
                  let ship_Amount = pageData.reduce(function (
                    previousValue,
                    currentValue
                  ) {
                    return (previousValue += parseFloat(
                      currentValue.ship_amount.replace(",","")
                    ));
                  },
                    0);
                  let return_Qty = pageData.reduce(function (
                    previousValue,
                    currentValue
                  ) {
                    return (previousValue += parseFloat(
                      currentValue.return_quantity.replace(",","")
                    ));
                  },
                    0);
                  let return_Amount = pageData.reduce(function (
                    previousValue,
                    currentValue
                  ) {
                    return (previousValue += parseFloat(
                      currentValue.return_amount.replace(",","")
                    ));
                  },
                    0);
                  let net_Qty = pageData.reduce(function (
                    previousValue,
                    currentValue
                  ) {
                    return (previousValue += parseFloat(
                      currentValue.net_quantity.replace(",","")
                    ));
                  },
                    0);
                  let net_Amount = pageData.reduce(function (
                    previousValue,
                    currentValue
                  ) {
                    return (previousValue += parseFloat(
                      currentValue.net_amount.replace(",","")
                    ));
                  },
                    0);
                  return (
                    <>
                      <Table.Summary.Row>
                        {/* <Table.Summary.Cell colSpan={3} style={{ paddingTop: "10px", fontWeight: "bolder" }}>Total Amount : ${numeral(totalSum()).format("0,0.00")} */}
                        {/* </Table.Summary.Cell> */}
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <p style={{ textAlign: "right" }} className="totalquantity">
                             {ship_Qty.toLocaleString("en-US")}
                          </p>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <p style={{ textAlign: "right" }} className="totalquantity">
                            $ {ship_Amount.toLocaleString("en-US")}
                          </p>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <p style={{ textAlign: "right" }} className="totalquantity">
                            {return_Qty.toLocaleString("en-US")}
                          </p>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <p style={{ textAlign: "right" }} className="totalquantity">
                            $ {return_Amount.toLocaleString("en-US")}
                          </p>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <p style={{ textAlign: "right" }} className="totalquantity">
                             {net_Qty.toLocaleString("en-US")}
                          </p>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <p style={{ textAlign: "right" }} className="totalquantity">
                            $ {net_Amount.toLocaleString("en-US")}
                          </p>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </>
                  );
                }}
              />
            ) : (
              <div className="no-Data">
                {/* {} */}
                <h1>No Data</h1>
              </div>
            )
          ) : (
            loading && (
              <div className="loader" style={{ marginTop: "20px" }}>
                <Spin size="medium" />
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default NetShippedStyleWise;
