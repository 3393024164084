import {
  DELETE_SALESORDERLINE_ERROR,
  DELETE_SALESORDERLINE_REQUEST,
  DELETE_SALESORDERLINE_SUCCESS,
  GET_ALL_SALESORDERLINE_ERROR,
  GET_ALL_SALESORDERLINE_REQUEST,
  GET_ALL_SALESORDERLINE_SUCCESS,
  GET_ONE_SALESORDERLINE_ERROR,
  GET_ONE_SALESORDERLINE_REQUEST,
  GET_ONE_SALESORDERLINE_SUCCESS,
  GET_ONE_LINES_REQUEST,
  GET_ONE_LINES_ERROR,
  GET_ONE_LINES_SUCCESS
} from "../actions/types";

export default function (state = [], action) {
	const { type, payload } = action;
	switch (type) {
		case DELETE_SALESORDERLINE_REQUEST:
			return {
				loading: true,
			};
		case DELETE_SALESORDERLINE_SUCCESS:
			return {
				...state,
				loading: false,
				payload,
			};
		case DELETE_SALESORDERLINE_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};

		case GET_ALL_SALESORDERLINE_REQUEST:
			return {
				loading: true,
			};
		case GET_ALL_SALESORDERLINE_SUCCESS:
			return {
				...state,
				loading: false,
				payload,
			};
		case GET_ALL_SALESORDERLINE_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};
		case GET_ONE_SALESORDERLINE_REQUEST:
			return {
				loading: true,
			};
		case GET_ONE_SALESORDERLINE_SUCCESS:
			return {
				...state,
				loading: false,
				payload,
			};
		case GET_ONE_SALESORDERLINE_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};
		case GET_ONE_LINES_REQUEST:
			return {
				loading: true,
			};
		case GET_ONE_LINES_SUCCESS:
			return {
				...state,
				loading: false,
				payload,
			};
		case GET_ONE_LINES_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};
		default:
			return state;
	}
}
