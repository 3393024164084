import {
	ADD_BILL_CREDIT_ITEM_LINE_REQUEST,
	ADD_BILL_CREDIT_ITEM_LINE_SUCCESS,
	ADD_BILL_CREDIT_ITEM_LINE_ERROR,
	UPDATE_BILL_CREDIT_ITEM_LINE_REQUEST,
	UPDATE_BILL_CREDIT_ITEM_LINE_SUCCESS,
	UPDATE_BILL_CREDIT_ITEM_LINE_ERROR,
	DELETE_BILL_CREDIT_ITEM_LINE_ERROR,
	DELETE_BILL_CREDIT_ITEM_LINE_SUCCESS,
	DELETE_BILL_CREDIT_ITEM_LINE_REQUEST,
	GET_ONE_BILL_CREDIT_ITEM_LINE_REQUEST,
	GET_ONE_BILL_CREDIT_ITEM_LINE_SUCCESS,
	GET_ONE_BILL_CREDIT_ITEM_LINE_ERROR,
	GET_ONE_BILL_CREDIT_ITEM_LINE_DETAIL_REQUEST,
	GET_ONE_BILL_CREDIT_ITEM_LINE_DETAIL_SUCCESS,
	GET_ONE_BILL_CREDIT_ITEM_LINE_DETAIL_ERROR,

} from "../actions/types";

export default function (state = [], action) {
	const { type, payload } = action;
	switch (type) {
		case ADD_BILL_CREDIT_ITEM_LINE_REQUEST:
			return {
				loading: true,
			};
		case ADD_BILL_CREDIT_ITEM_LINE_SUCCESS:
			return {
				...state,
				loading: false,
				payload: payload,
			};
		case ADD_BILL_CREDIT_ITEM_LINE_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};

		case UPDATE_BILL_CREDIT_ITEM_LINE_REQUEST:
			return {
				loading: true,
			};
		case UPDATE_BILL_CREDIT_ITEM_LINE_SUCCESS:
			return {
				...state,
				loading: false,
				payloadLine: payload,
			};
		case UPDATE_BILL_CREDIT_ITEM_LINE_ERROR:
			return {
				...state,
				loading: false,
				payloadLine: payload,
			};

		case DELETE_BILL_CREDIT_ITEM_LINE_REQUEST:
			return {
				loading: true,
			};
		case DELETE_BILL_CREDIT_ITEM_LINE_SUCCESS:
			return {
				...state,
				loading: false,
				payload,
			};
		case DELETE_BILL_CREDIT_ITEM_LINE_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};
		case GET_ONE_BILL_CREDIT_ITEM_LINE_REQUEST:
			return {
				loading: true,
			};
		case GET_ONE_BILL_CREDIT_ITEM_LINE_SUCCESS:
			return {
				...state,
				loading: false,
				payload,
			};
		case GET_ONE_BILL_CREDIT_ITEM_LINE_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};

		case GET_ONE_BILL_CREDIT_ITEM_LINE_DETAIL_REQUEST:
			return {
				loading: true,
			};
		case GET_ONE_BILL_CREDIT_ITEM_LINE_DETAIL_SUCCESS:
			return {
				...state,
				loading: false,
				payload,
			};
		case GET_ONE_BILL_CREDIT_ITEM_LINE_DETAIL_ERROR:
			return {
				...state,
				loading: false,
				payload,
			};
		default:
			return state;
	}
}