import {
  GET_ALL_CUSTOMERS_LIST_ERROR, GET_ALL_CUSTOMERS_LIST_SUCCESS, GET_ALL_CUSTOMERS_LIST_REQUEST
} from "../actions/types";

export default function (state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case GET_ALL_CUSTOMERS_LIST_REQUEST:
      return {
        loading: true,
      };
    case GET_ALL_CUSTOMERS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        payload,
      };
    case GET_ALL_CUSTOMERS_LIST_ERROR:
      return {
        ...state,
        loading: false,
        payload,
      };
    default:
      return state;
  }
}
