import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import PageHeader from "../../components/PageHeader/PageHeader";
import { Row as Brow, Col as Bcol } from "reactstrap";
import { Button, Form, Table, Spin, Input, DatePicker } from "antd";
import moment from "moment";
import { ReadCookie } from "../../utils/readCookie";
import numeral from "numeral";
import { CSVLink } from "react-csv";
import { TabTitle } from "../../utils/GeneralFunction";
import { searchPackingList } from "../../redux/actions/searchPackingList.action";
import notification from "../../utils/notification";
import Swal from "sweetalert2";
import { sendPackingList } from "../../redux/actions/sendPackingList.action";

const AsnToBergen = () => {
  TabTitle("ASN to Bergen");
  const [form] = Form.useForm();
  const [minimize, setMinimize] = useState(false);
  const dispatch = useDispatch();
  const token = ReadCookie("token");
  const [controlSheet, setControlSheet] = useState([]);
  const [loading, setLoading] = useState(false);
  const tableRef = useRef(null);
  const { controlLog } = useSelector((state) => state);
  const [excelData, setExcelData] = useState([]);
  const [sDate, setStartDate] = useState(moment());
  
  const columns = [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Company Name",
      render: (text, record) => {
        return <>{form.getFieldValue().company}</>;
      },
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      title: "Address 1",
      render: (text, record) => {
        return <>{form.getFieldValue().address1}</>;
      },
    },
    {
      title: "Address 2",
      render: (text, record) => {
        return <>{form.getFieldValue().address2}</>;
      },
      
      
    },
    {
      title: "City",
      render: (text, record) => {
        return <>{form.getFieldValue().city}</>;
      },
      
      
    },
    {
      title: "State",
      dataIndex: "STATE",
      key: "STATE",
    },
    {
      title: "Zip",
      render: (text, record) => {
        return <>{form.getFieldValue().zip}</>;
      },
      
      
    },
    {
      title: "Country",
      render: (text, record) => {
        return <>{form.getFieldValue().country}</>;
      },
      
      
    },
    {
      title: "Non US Region",
      dataIndex: "Non_US_Region",
      key: "Non_US_Region",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Fax",
      dataIndex: "fax",
      key: "fax",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Expected Date",
      render: (text, record) => {
        return (
          <>
            {!form.getFieldValue().startDate
              ? moment().format("MM-DD-YYYY")
              : moment(form.getFieldValue().startDate).format("MM-DD-YYYY")}
          </>
        );
      },
    },
    {
      title: "Carrier",
      
      
      render: (text, record) => {
        return <>{form.getFieldValue().carrier}</>;
      },
    },
    {
      title: "Tracking Numbers",
      
      
      render: (text, record) => {
        return <>{form.getFieldValue().awb}</>;
      },
    },
    {
      title: "Driver Name",
      dataIndex: "DriverName",
      key: "DriverName",
    },
    {
      title: "License Plate",
      dataIndex: "LicensePlate",
      key: "LicensePlate",
    },
    {
      title: "Customer Po No",
      
      
      render: (text, record) => {
        return <>{form.getFieldValue().customer_po}</>;
      },
    },
    {
      title: "Authorized Number",
      dataIndex: "AuthorizedNumber",
      key: "AuthorizedNumber",
      align: "right",
    },
    {
      title: "Memo",
      
      
      render: (text, record) => {
        return <>{form.getFieldValue().memo}</>;
      },
    },
    {
      title: "Warehouse",
      dataIndex: "WAREHOUSE",
      key: "WAREHOUSE",
    },
    {
      title: "Shipment Type list",
      dataIndex: "ShipmentTypelist",
      key: "ShipmentTypelist",
    },
    {
      title: "Style",
      dataIndex: "Style",
      key: "Style",
    },
    {
      title: "Color",
      dataIndex: "COLOR",
      key: "COLOR",
    },
    {
      title: "Size",
      dataIndex: "Size",
      key: "Size",
    },
    {
      title: "Expected Quantity",
      dataIndex: "ExpectedQuantity",
      key: "ExpectedQuantity",
      align: "right",
    },
    {
      title: "Unit Cost",
      dataIndex: "UnitCost",
      key: "UnitCost",
      align: "right",
    },
    {
      title: "Product Description",
      dataIndex: "ProductDescription",
      key: "ProductDescription",
    },
    {
      title: "Product MSRP",
      dataIndex: "ProductMSRP",
      key: "ProductMSRP",
      align: "right",
    },
    {
      title: "Comments",
      dataIndex: "Comments",
      key: "Comments",
    },
    {
      title: "Shipment Type",
      dataIndex: "ShipmentType",
      key: "ShipmentType",
    },
    {
      title: "UPC",
      dataIndex: "UPC",
      key: "UPC",
    },
  ];

  useEffect(() => {
    form.setFieldsValue({
      company: "ARTISTIC DENIM MILLS.LTD",
      brand: "DL1961 PREMIUM DENIM",
      address1: "PLOT NO. 5-9,23-26, SECTOR-16",
      address2: "KORANGI INDUSTRIAL AREA",
      city: "KARACHI",
      zip: "",
      country: "PAKISTAN",
    });
  }, []);
  const onFinish = async () => {
    await form
      .validateFields()
      .then((values) => {
        if (!values.packing_id) {
          
          Swal.fire({
            icon: "error",
            title: "OOPS",
            text: `Please Insert Packing ID.`,
            confirmButtonColor: "#3c4b58",
          });
        } else {
          
          setLoading(true);
          dispatch(searchPackingList(token, values)).then((res) => {
            setLoading(false);
            setControlSheet(res?.payload?.data);
            
            downloadExcelFileData(res?.payload?.data);
          });
        }
      })
      .catch((error) => {
        setLoading(false);
      });
    
    
    
    
    
    
    
    
    
    
    
    
    
  };

  const totalQuantity = () => {
    let sumQty = controlSheet.reduce(function (previousValue, currentValue) {
      return (previousValue += Number(currentValue.ExpectedQuantity));
    }, 0);
    return sumQty;

    
  };

  const totalSum = () => {
    let sumAmount = controlSheet.reduce(function (previousValue, currentValue) {
      return (previousValue += Number(currentValue.amount));
    }, 0);
    return sumAmount;
  };

  const onSearch = async () => {
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
  };

  function downloadExcelFileData(data) {
    setExcelData(
      data.map((v) => {
        return {
          "Company Name": form.getFieldValue().company,
          "Address 1": form.getFieldValue().address1,
          "Address 2": form.getFieldValue().address2,
          City: form.getFieldValue().city,
          State: v.STATE,
          Zip: form.getFieldValue().zip,
          Country: form.getFieldValue().country,
          "Non US Region": v.Non_US_Region,
          Phone: v.phone,
          Fax: v.fax,
          Email: v.email,
          "Expected Date": !form.getFieldValue().startDate
            ? moment().format("MM-DD-YYYY")
            : moment(form.getFieldValue().startDate).format("MM-DD-YYYY"),
          Carrier: form.getFieldValue().carrier,
          "Tracking Numbers": form.getFieldValue().awb,
          "Driver Name": v.DriverName,
          "License Plate": v.LicensePlate,
          "Customer Po No": form.getFieldValue().customer_po,
          "Authorized Number": v.AuthorizedNumber,
          Memo: form.getFieldValue().memo,
          Warehouse: v.WAREHOUSE,
          "Shipment Type list": v.ShipmentTypelist,
          Style: v.Style,
          Color: v.COLOR,
          Size: v.Size,
          "Expected Quantity": v.ExpectedQuantity,
          "Unit Cost": v.UnitCost,
          "Product Description": v.ProductDescription,
          "Product MSRP": v.ProductMSRP,
          Comments: v.Comments,
          "Shipment Type": v.ShipmentType,
          UPC: v.UPC,

          
          
          
          
          
          
          
          
          
          
          
          
          
          
          
          
          
          
        };
      })
    );
  }

  const onSubmit = async () => {
    await form
      .validateFields()
      .then((values) => {
        if (!values.packing_id) {
          
          Swal.fire({
            icon: "error",
            title: "OOPS",
            text: `Please Insert SKU.`,
            confirmButtonColor: "#3c4b58",
          });
        } else {
        
          let final_data = controlSheet.map((e)=> {
            e.Company = form.getFieldValue().company;
            e.ADDRESS1 = form.getFieldValue().address1;
            e.ADDRESS2 = form.getFieldValue().address2;
            e.CITY = form.getFieldValue().city;
            e.zip = form.getFieldValue().zip;
            e.COUNTRY = form.getFieldValue().country;
            e.ExpectedDate = !form.getFieldValue().startDate
            ? moment().format("MM-DD-YYYY")
            : moment(form.getFieldValue().startDate).format("MM-DD-YYYY");
            e.Carrier = form.getFieldValue().carrier;
            e.Awbno = form.getFieldValue().awb;
            e.CustomerPoNo = form.getFieldValue().customer_po;
            e.Memo = form.getFieldValue().memo;

            return e
          })

          
          dispatch(sendPackingList(token, final_data)).then((res) => {
            if (res.type == "GET_SEND_PACKING_LIST_SUCCESS") {
              notification("success", "Packing List Send Successfully");
            }
            
            
            
          });
          
          
          
          
          
          
          
          
          
        }
      })
      .catch((error) => {
        setLoading(false);
      });

    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
  };

  return (
    <div className="styleContainer">
      <div className="styleBody h-100">
        <PageHeader
          title={"Packing List"}
          minimize={setMinimize}
          check={minimize}
        />
        {!minimize && (
          <div className="bodyStyle">
            <div className="formProduct p-0">
              <Form
                onFinish={onFinish}
                form={form}
                name="control-hooks"
                
              >
                <div className="container-fluid pbpx-5 ptpx-5">
                  <Brow>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div>
                        <div className="relative">
                          <Form.Item
                            name="packing_id"
                            label="F. Packing ID"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                          >
                            <Input size="small" className="styleInput" />
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div>
                        <div className="relative">
                          <Form.Item
                            label="Expected Date"
                            name="startDate"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            
                            
                            
                            
                            
                            
                          >
                            <DatePicker
                              size="small"
                              tabIndex={6}
                              format="MM-DD-YYYY"
                              allowClear
                              className={`dateInput customInputs fw-400 `}
                              onChange={(e) =>
                                setStartDate(moment(e).format("MM-DD-YYYY"))
                              }
                              defaultValue={moment(sDate)}
                              initialValue={moment(sDate)}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol>

                    <Bcol lg={6} xl={4} xxl={3}>
                      <div>
                        <div className="relative">
                          <Form.Item
                            name="carrier"
                            label="Carrier"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                          >
                            <Input size="small" className="styleInput" />
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol>

                    <Bcol lg={6} xl={4} xxl={3}>
                      <div>
                        <div className="relative">
                          <Form.Item
                            name="awb"
                            label="AWB #"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                          >
                            <Input size="small" className="styleInput" />
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol>

                    <Bcol lg={6} xl={4} xxl={3}>
                      <div>
                        <div className="relative">
                          <Form.Item
                            name="customer_po"
                            label="Customer PO#"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                          >
                            <Input size="small" className="styleInput" />
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol>

                    <Bcol lg={6} xl={4} xxl={3}>
                      <div>
                        <div className="relative">
                          <Form.Item
                            name="memo"
                            label="Memo"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                          >
                            <Input size="small" className="styleInput" />
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol>

                    <Bcol lg={6} xl={4} xxl={3}>
                      <div>
                        <div className="relative">
                          <Form.Item
                            name="company"
                            label="Company"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            
                          >
                            {/* <Input size="small" className="styleInput" /> */}
                            <Input
                              size="small"
                              
                              className={`styleInput customInputs`}
                              
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol>

                    <Bcol lg={6} xl={4} xxl={3}>
                      <div>
                        <div className="relative">
                          <Form.Item
                            name="address1"
                            label="Address 1"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            
                          >
                            {/* <Input size="small" className="styleInput" /> */}
                            <Input
                              size="small"
                              
                              className={`styleInput customInputs`}
                              
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div>
                        <div className="relative">
                          <Form.Item
                            name="address2"
                            label="Address 2"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            
                          >
                            {/* <Input size="small" className="styleInput" /> */}
                            <Input
                              size="small"
                              
                              className={`styleInput customInputs`}
                              
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol>

                    <Bcol lg={6} xl={4} xxl={3}>
                      <div>
                        <div className="relative">
                          <Form.Item
                            name="city"
                            label="City"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            
                          >
                            {/* <Input size="small" className="styleInput" /> */}
                            <Input
                              size="small"
                              
                              className={`styleInput customInputs`}
                              
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol>

                    <Bcol lg={6} xl={4} xxl={3}>
                      <div>
                        <div className="relative">
                          <Form.Item
                            name="zip"
                            label="Zip"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            
                          >
                            {/* <Input size="small" className="styleInput" /> */}
                            <Input
                              size="small"
                              
                              className={`styleInput customInputs`}
                              
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol>

                    <Bcol lg={6} xl={4} xxl={3}>
                      <div>
                        <div className="relative">
                          <Form.Item
                            name="country"
                            label="Country"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            
                          >
                            {/* <Input size="small" className="styleInput" /> */}
                            <Input
                              size="small"
                              
                              className={`styleInput customInputs`}
                              
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol>

                    {/* <Bcol lg={6} xl={4} xxl={3}>
                      <div>
                        <div className="relative">
                          <Form.Item
                            name="brand"
                            label="Brand"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            disabled={true}
                          >
                            <Input size="small" className="styleInput customInputs" disabled={true}/>
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol> */}

                    <Bcol lg={6} xl={4} xxl={3}>
                      <div>
                        <div className="relative">
                          <Form.Item
                            name="ctn_from"
                            label="CTN From"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            defaultValue={1}
                            initialValue={1}
                          >
                            <Input size="small" className="styleInput" />
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol>

                    <Bcol lg={6} xl={4} xxl={3}>
                      <div>
                        <div className="relative">
                          <Form.Item
                            name="ctn_to"
                            label="CTN To"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            defaultValue={100}
                            initialValue={100}
                          >
                            <Input size="small" className="styleInput" />
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div>
                        <div className="relative">
                          <Button
                            htmlType="submit"
                            type="primary"
                            className="ant-btn ant-btn-primary mrpx-20"
                            onClick={onFinish}
                          >
                            <span className="px-2">Search</span>
                          </Button>
                        </div>
                      </div>
                    </Bcol>
                  </Brow>
                </div>
              </Form>

              {/* {controlSheet.length > 0 && (
                <Button
                  htmlType="submit"
                  type="primary"
                  className="ant-btn ant-btn-primary mrpx-20 mb-2"
                  onClick={onSubmit}
                >
                  <span className="px-2">Send</span>
                </Button>
              )} */}
            </div>
          </div>
        )}
      </div>
      <div style={{ marginTop: 10 }}>
        {loading ? (
          <div className=" report-wrapper-main d-flex align-items-center justify-content-center spin-style mt-3">
            <Spin size="large" />
          </div>
        ) : controlSheet && controlSheet.length > 0 ? (
          <>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ display: "flex" }}>
                <div>Total Items: {controlSheet.length}</div>
                <div style={{ marginLeft: "20px" }}>
                  Total Quantity: {numeral(totalQuantity()).format("0,00,0")}
                </div>
              </div>
              <Button
                htmlType="submit"
                type="primary"
                className="ant-btn ant-btn-primary"
                onClick={onSubmit}
              >
                <span className="px-2">Send Data</span>
              </Button>
            </div>
            <Table
              title={() => (
                <CSVLink
                  filename={"Packing-List.csv"}
                  data={excelData}
                  onClick={() => {}}
                >
                  Download Excel
                </CSVLink>
              )}
              ref={tableRef}
              size="small"
              bordered={false}
              type="company"
              perPage={15}
              scroll={{ x: "max-content" }}
              dataSource={controlSheet}
              columns={columns}
              loading={controlLog.loading}
              pagination={{
                defaultCurrent: 1,
                defaultPageSize: 100,
                pageSizeOptions: [100, 500, 1000, 2000, 2500, 3000],
              }}
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              
              
            />
          </>
        ) : (
          
          <div
            style={{
              backgroundColor: "#CCCCCC",
              borderWidth: "1pt",
              borderStyle: "Dashed",
              fontWeight: "bold",
              height: "25px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            No record(s)
          </div>
        )}
      </div>
    </div>
  );
};

export default AsnToBergen;
