import { GET_OMS_STOCK_SUMMARY_REPORT_ERROR2, GET_OMS_STOCK_SUMMARY_REPORT_REQUEST2, GET_OMS_STOCK_SUMMARY_REPORT_SUCCESS2 } from "./types";
import { BASEURL } from "../../constant/config.js";
import moment from "moment";

const axios = require("axios");
export const imediateOtsBySize = (token, values) => (dispatch) => {



    dispatch({ type: GET_OMS_STOCK_SUMMARY_REPORT_REQUEST2 });
    return axios({
        method: "POST",
        url: `${BASEURL}getAll/getImediateOtsBySizeBergen`,

        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        data: { ...values }
    })
        .then((response) => {
            return dispatch({ type: GET_OMS_STOCK_SUMMARY_REPORT_SUCCESS2, payload: response.data });
        })
        .catch((error) => {
            if (error.response.data.data === "No Record Found !") {
                return dispatch({
                    type: GET_OMS_STOCK_SUMMARY_REPORT_ERROR2,
                    payload: [],
                });
            }
            return dispatch({
                type: GET_OMS_STOCK_SUMMARY_REPORT_ERROR2,
                payload: error.response,
            });
        });
};
